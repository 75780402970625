import {
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';

import React, { useState } from 'react';

type NumericMoneyOrPercentageProps = {
  label: string;
  value: number | undefined;
  oneHundredPercentValue: number;
  onChange?: (value: number | undefined, type: '$' | '%') => void;
  id: string;
  defaultIsPercentage?: boolean;
  error?: boolean;
  helperText?: string;
};

const NumericMoneyOrPercentage: React.FunctionComponent<
  NumericMoneyOrPercentageProps
> = ({
  label,
  value,
  oneHundredPercentValue,
  onChange,
  id,
  defaultIsPercentage = false,
  error = false,
  helperText
}: NumericMoneyOrPercentageProps) => {
  const [isPercentage, setIsPercentage] = useState(defaultIsPercentage);
  const [isEditing, setIsEditing] = useState(false);
  const handleChange = (event: { target: { name: string; value: string } }) => {
    const type = isPercentage ? '%' : '$';
    let eventValue = parseInt(event.target.value.replace(/\D/g, ''), 10);
    if (Number.isNaN(eventValue)) {
      eventValue = 0;
    }
    if (onChange) {
      if (event.target.value.length === 0) {
        onChange(undefined, type);
        return;
      }
      if (isPercentage && eventValue > 100) {
        eventValue = 100;
      }
      if (!isPercentage && eventValue > oneHundredPercentValue) {
        eventValue = oneHundredPercentValue;
      }
      if (eventValue < 0) {
        eventValue = 0;
      }

      onChange(eventValue, type);
    }
  };

  const handleIsPercentageChange = (
    event: React.MouseEvent<HTMLElement>,
    newIsPercentage: boolean
  ) => {
    if (newIsPercentage === null) {
      return;
    }

    setIsPercentage(newIsPercentage);
    const type = newIsPercentage ? '%' : '$';

    if (value) {
      let newValue = value;
      if (newIsPercentage && newValue > 100) {
        newValue = 100;
      }
      if (!newIsPercentage && newValue > oneHundredPercentValue) {
        newValue = oneHundredPercentValue;
      }
      if (onChange) {
        onChange(newValue, type);
      }
    }
  };

  const getValue = (type: 'display' | 'number') => {
    if (type === 'number') {
      return value === 0 ? null : value;
    }
    return isPercentage ? `${value}%` : `$${value}`;
  };

  let inputProps = {};

  if (isEditing) {
    if (isPercentage) {
      inputProps = {
        endAdornment: <InputAdornment position='end'>%</InputAdornment>,
        pattern: '[0-9]*'
      };
    } else {
      inputProps = {
        pattern: '[0-9]*',
        startAdornment: <InputAdornment position='start'>$</InputAdornment>
      };
    }
  }
  return (
    <div
      data-testid={`${id
        .toLocaleLowerCase()
        .replaceAll(' ', '-')}-edit-deferral-box`}
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '250px'
      }}>
      <div
        data-testid='numeric-percentage-buttons'
        style={{ margin: 'auto 15px auto 0' }}>
        <ToggleButtonGroup
          aria-label='outlined button group'
          color='primary'
          exclusive
          onChange={handleIsPercentageChange}
          size='medium'
          style={{ height: '30px' }}
          value={isPercentage}>
          <ToggleButton
            aria-label='left aligned'
            data-testid='dollar-value-button'
            value={false}>
            $
          </ToggleButton>
          <ToggleButton
            aria-label='centered'
            data-testid='percent-value-button'
            value={Boolean(true)}>
            %
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <TextField
        InputProps={{ ...inputProps }}
        error={error}
        helperText={helperText}
        id={id}
        label={label}
        onBlur={() => {
          if (!value) {
            handleChange({ target: { name: '', value: '0' } });
          } else {
            let newValue = value;
            if (isPercentage && newValue > 100) {
              newValue = 100;
            }
            if (!isPercentage && newValue > oneHundredPercentValue) {
              newValue = oneHundredPercentValue;
            }
            handleChange({ target: { name: '', value: String(newValue) } });
          }

          setIsEditing(false);
        }}
        onChange={handleChange}
        onFocus={() => setIsEditing(true)}
        onKeyDown={e => {
          if (e.nativeEvent.key !== 'Backspace') {
            if (/\D/g.test(e.nativeEvent.key)) {
              e.preventDefault();
            }
          }
        }}
        type={isEditing ? 'number' : 'text'}
        value={getValue(isEditing ? 'number' : 'display')}
        variant='standard'
      />
    </div>
  );
};

export default NumericMoneyOrPercentage;
