import { Alert, Typography } from '@mui/material';

import {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  memo,
  ReactNode,
  useCallback,
  useMemo,
  useState
} from 'react';
import { useDropzone } from 'react-dropzone';
import type { DropzoneOptions } from 'react-dropzone';

export type SimpleUploadProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  DropzoneOptions & {
    children?: ReactNode;
    disabled?: boolean;
    error?: ReactNode;
    multiple?: boolean;
    onSelect: (files: MemoryFile[] | File[]) => void | Promise<void>;
    showFileTypes?: boolean;
    selectRawFiles?: boolean;
  };

type MemoryFile = {
  data: ArrayBuffer;
  name: string;
  type: string;
};

export async function readFiles(files: File[]): Promise<MemoryFile[]> {
  const result = [];

  for (let i = 0; i < files.length; i++) {
    const file = await files[i].arrayBuffer();
    result.push({
      data: file,
      name: files[i].name,
      type: files[i].type
    } as MemoryFile);
  }

  return result;
}

const filterMsg = (msg: string, allowedExtentions: string[] | undefined) => {
  return msg.includes('File type must') && !!Array.isArray(allowedExtentions)
    ? `File type must be ${allowedExtentions.join(', ')}.`
    : msg;
};

export const SimpleUpload: FC<SimpleUploadProps> = memo<SimpleUploadProps>(
  (props: SimpleUploadProps) => {
    const {
      accept,
      children,
      disabled,
      error,
      multiple,
      onSelect,
      showFileTypes,
      selectRawFiles,
      ...extraProps
    } = props;

    const [readFailure, toggleReadyFailure] = useState(false);

    const fileTypesArray = useMemo(
      () =>
        showFileTypes &&
        Array.isArray(accept) &&
        accept
          .map(type => type.replace(/\./g, '').toUpperCase())
          .sort()
          .join(', '),
      [accept, showFileTypes]
    );

    const fileTypesObject = useMemo(
      () =>
        showFileTypes &&
        accept &&
        Array.from(Object.values(accept))
          .reduce((prev, curr) => [...prev, ...curr], [])
          .map(type => type.replace(/\./g, '').toUpperCase())
          .sort()
          .join(', '),
      [accept, showFileTypes]
    );

    const allowedExtentions = useMemo(
      () =>
        accept &&
        Array.from(Object.values(accept)).reduce(
          (prev, curr) => [...prev, ...curr],
          []
        ),
      [accept]
    );

    const onDropAccepted = useCallback(
      async (files: File[]) => {
        try {
          toggleReadyFailure(false);
          await onSelect(selectRawFiles ? files : await readFiles(files));
        } catch (e) {
          toggleReadyFailure(true);
        }
      },
      [onSelect, selectRawFiles]
    );

    const { getRootProps, getInputProps, fileRejections } = useDropzone({
      accept,
      disabled,
      multiple,
      onDropAccepted
    });

    return (
      <div
        className='flex flex-row align-center'
        data-testid='uploadZone'
        {...getRootProps()}
        {...extraProps}>
        <input {...getInputProps()} data-testid='uploadInput' />
        {children}
        {showFileTypes && fileTypesArray && (
          <Typography
            color='gray3'
            mb={0}
            ml={2}
            textAlign='center'
            variant='body2'>
            Accepted File Formats: {fileTypesArray}
          </Typography>
        )}
        {showFileTypes && fileTypesObject && (
          <Typography
            color='gray3'
            mb={0}
            ml={2}
            textAlign='center'
            variant='body2'>
            Accepted File Formats: {fileTypesObject}
          </Typography>
        )}
        {error && <Alert severity='error'>{error}</Alert>}
        {readFailure && (
          <Alert severity='error'>Failed to read your file</Alert>
        )}
        {fileRejections.map(({ file, errors }) => (
          <div key={file.name}>
            {errors.map(singleError => (
              <Alert key={singleError.code} severity='error'>
                {filterMsg(singleError.message, allowedExtentions)}
              </Alert>
            ))}
          </div>
        ))}
      </div>
    );
  }
);

SimpleUpload.displayName = 'SimpleUpload';
SimpleUpload.defaultProps = {
  children: "Drag 'n' drop your files here, or click to select files",
  multiple: false
};
