// this enum refers to data we fetch from the core database and not sub accounting core

enum FundingSource {
  SD = 'Salary Deferral',
  SH = 'Matching/Safe Harbor',
  PS = 'Profit Sharing',
  RC = 'Employee Roth',
  AT = 'Employee After-tax',
  SHM = 'Safe Harbor Match',
  SHNE = 'Safe Harbor Nonelective',
  SO = 'Pre-tax Rollover',
  RO = 'Roth Rollover',
  RR = 'Related Roth Rollover',
  QC = 'QNEC',
  QM = 'QMAC',
  EM = 'Employer Match',
  QSHM = 'QACA SH Match',
  QSHNE = 'QACA SH Nonelective',
  RS = 'Related Pretax Rollover',
  LN = 'Loan'
}

export default FundingSource;
