import AccessControl from '@/components/access-control/AccessControl.component';
import BasePath from '@/models/BasePath.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GppGoodIcon from '@mui/icons-material/GppGood';
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

interface OpsMenuItemProps {
  open: boolean;
  setOpen: (open: boolean) => unknown;
  activePage: string;
}

const accountsPath: BasePath = '/ops/accounts';
const alertsPath: BasePath = '/ops/alerts';
const reconExceptionsPath: BasePath = '/ops/recon-exceptions';
const investmentsPath: BasePath = '/ops/investments';
const actionCenterPath: BasePath = '/ops/action-center';
const securitiesPath: BasePath = '/ops/securities';
const bulkInvestmentPath: BasePath = '/ops/bulk-investment';
const modelUpdatePath: BasePath = '/ops/model-update';
const queueErrorsPath: BasePath = '/ops/queue-error';
const feeManagementPath: BasePath = '/ops/fee-management';
const fraudrankerPath: BasePath = '/ops/fraudranker';
const whitelabelManagementPath: BasePath = '/ops/whitelabel-management';
const conversionManagementPath: BasePath = '/ops/conversion-management';
const surpasPath: BasePath = '/ops/surpas';
const globalRatesPath: BasePath = '/ops/global-rates';

const opsMenuPaths: Array<BasePath> = [
  alertsPath,
  reconExceptionsPath,
  investmentsPath,
  actionCenterPath,
  accountsPath,
  securitiesPath,
  bulkInvestmentPath,
  queueErrorsPath,
  feeManagementPath
];

const OpsMenuItem = (props: OpsMenuItemProps): JSX.Element => {
  const { open, setOpen, activePage } = props;
  const [opsMenuOpen, opsMenuSetOpen] = React.useState(false);
  const opsMenuOpenClose = () => {
    if (open) {
      opsMenuSetOpen(prev => !prev);
    } else {
      setOpen(true);
      opsMenuSetOpen(true);
    }
  };

  useEffect(() => {
    if (!open) {
      opsMenuSetOpen(false);
    } else if (
      open &&
      opsMenuPaths.some(path => window.location.pathname.indexOf(path) >= 0)
    ) {
      opsMenuSetOpen(true);
    }
  }, [open]);

  useEffect(() => {
    if (!opsMenuPaths.some(path => path === activePage)) opsMenuSetOpen(false);
  }, [activePage]);

  return (
    <>
      <ListItem
        button
        key='Ops'
        onClick={opsMenuOpenClose}
        selected={
          (!open || !opsMenuOpen) &&
          opsMenuPaths.some(path => window.location.pathname.indexOf(path) >= 0)
        }>
        <ListItemIcon>
          <GppGoodIcon />
        </ListItemIcon>
        <ListItemText primary='Ops' />
        {opsMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={open && opsMenuOpen} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          <AccessControl requires={[FeatureLevelPermissions.READ_SUBA_ALERTS]}>
            <ListItem
              button
              component={Link}
              key='Alert Center'
              selected={window.location.pathname.indexOf(alertsPath) >= 0}
              to={alertsPath}>
              <ListItemText primary='Alert Center' />
            </ListItem>
          </AccessControl>
          <AccessControl requires={[FeatureLevelPermissions.READ_SUBA_RECON]}>
            <ListItem
              button
              component={Link}
              key='Recon'
              selected={
                window.location.pathname.indexOf(reconExceptionsPath) >= 0
              }
              to={reconExceptionsPath}>
              <ListItemText primary='Recon' />
            </ListItem>
          </AccessControl>
          <AccessControl
            requires={[FeatureLevelPermissions.WRITE_INVESTMENTS_MODIFY]}>
            <ListItem
              button
              component={Link}
              key='Investments'
              selected={window.location.pathname.indexOf(investmentsPath) >= 0}
              to={investmentsPath}>
              <ListItemText primary='Investments' />
            </ListItem>
          </AccessControl>
          <AccessControl
            requires={[FeatureLevelPermissions.READ_SUBA_ACCOUNTS]}>
            <ListItem
              button
              component={Link}
              key='Accounts'
              selected={window.location.pathname.indexOf('/ops/accounts') >= 0}
              to={accountsPath}>
              <ListItemText primary='Accounts' />
            </ListItem>
          </AccessControl>
          <AccessControl
            requiresOneOf={[
              FeatureLevelPermissions.READ_INVESTMENTS,
              FeatureLevelPermissions.READ_SUBA_ACCOUNTS,
              FeatureLevelPermissions.READ_SUBA_RECON
            ]}>
            <ListItem
              button
              component={Link}
              key='Accounts'
              selected={
                window.location.pathname.indexOf('/ops/securities') >= 0
              }
              to={securitiesPath}>
              <ListItemText primary='Securities' />
            </ListItem>
          </AccessControl>
          <AccessControl
            requiresOneOf={[
              FeatureLevelPermissions.WRITE_BULK_EMAIL_ACTIONS,
              FeatureLevelPermissions.WRITE_BULK_UPLOAD_ACTIONS,
              FeatureLevelPermissions.WRITE_STATE_IRA_EMPLOYER_BULK_UPLOAD
            ]}>
            <ListItemButton
              component={Link}
              key='ActionCenter'
              selected={window.location.pathname.indexOf(actionCenterPath) >= 0}
              to={actionCenterPath}>
              <ListItemText primary='Action Center' />
            </ListItemButton>
          </AccessControl>
          <AccessControl
            requires={[FeatureLevelPermissions.WRITE_REBALANCE_ACTION]}>
            <ListItemButton
              component={Link}
              key='Bulk Rebalance'
              selected={
                window.location.pathname.indexOf(bulkInvestmentPath) >= 0
              }
              to={bulkInvestmentPath}>
              <ListItemText primary='Bulk Investment Tools' />
            </ListItemButton>
          </AccessControl>
          <ListItemButton
            component={Link}
            key='Conversion Management'
            selected={
              window.location.pathname.indexOf(conversionManagementPath) >= 0
            }
            to={conversionManagementPath}>
            <ListItemText primary='Conversion Management' />
          </ListItemButton>
          <AccessControl
            requires={[FeatureLevelPermissions.WRITE_INVESTMENTS_MODIFY]}>
            <ListItemButton
              component={Link}
              key='Model Update Tool'
              selected={window.location.pathname.indexOf(modelUpdatePath) >= 0}
              to={modelUpdatePath}>
              <ListItemText primary='Model Update Tool' />
            </ListItemButton>
          </AccessControl>
          <ListItemButton
            component={Link}
            key='Queue Errors'
            selected={window.location.pathname.indexOf(queueErrorsPath) >= 0}
            to={queueErrorsPath}>
            <ListItemText primary='Queue Errors' />
          </ListItemButton>
          <ListItemButton
            component={Link}
            key='Fee Management'
            selected={window.location.pathname.indexOf(feeManagementPath) >= 0}
            to={feeManagementPath}>
            <ListItemText primary='Fee Management' />
          </ListItemButton>
          <ListItemButton
            component={Link}
            key='Fraudranker'
            selected={window.location.pathname.indexOf(fraudrankerPath) >= 0}
            to={fraudrankerPath}>
            <ListItemText primary='Fraudranker Report' />
          </ListItemButton>
          <ListItemButton
            component={Link}
            key='Surpas'
            selected={window.location.pathname.indexOf(surpasPath) >= 0}
            to={surpasPath}>
            <ListItemText primary='Surpas Data Lookup' />
          </ListItemButton>
          <ListItemButton
            component={Link}
            key='Surpas'
            selected={window.location.pathname.indexOf(globalRatesPath) >= 0}
            to={globalRatesPath}>
            <ListItemText primary='Global Rates' />
          </ListItemButton>
          <ListItemButton
            component={Link}
            key='Whitelabel'
            selected={
              window.location.pathname.indexOf(whitelabelManagementPath) >= 0
            }
            to={whitelabelManagementPath}>
            <ListItemText primary='White Label' />
          </ListItemButton>
        </List>
      </Collapse>
    </>
  );
};

export default OpsMenuItem;
