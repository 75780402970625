import MainLayout from '@/components/main-layout';
import { styled } from '@mui/material/styles';

import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useMeasure } from 'react-use';

import { DialogProvider } from './contexts/DialogContext';
import { OpsToolingProvider } from './contexts/OpsToolingContext';
import formatters from './utils/Formatters';

const StyledApp = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 3)
}));

const App: React.FunctionComponent<Record<string, unknown>> = () => {
  const [isGlobalSearchResultOpen, setIsGlobalSearchResultOpen] =
    useState(false);
  const checkGlobalSearchBarState = (e: React.MouseEvent) => {
    if (formatters.checkIfGlobalSearchResultIsClicked(e as unknown as Event)) {
      setIsGlobalSearchResultOpen(true);
    } else {
      setIsGlobalSearchResultOpen(false);
    }
  };

  const [appRef, appMeasures] = useMeasure();

  return (
    <OpsToolingProvider>
      <DialogProvider>
        <StyledApp
          className='App'
          onMouseDown={checkGlobalSearchBarState}
          ref={appRef}
          role='button'
          tabIndex={0}>
          <MainLayout
            appWidth={appMeasures.width - appMeasures.x}
            isGlobalSearchResultOpen={isGlobalSearchResultOpen}>
            <Outlet />
          </MainLayout>
        </StyledApp>
      </DialogProvider>
    </OpsToolingProvider>
  );
};

export default App;
