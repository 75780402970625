import { isString } from 'lodash';

class HoursOfServiceRequired {
  convertToApiState = (
    value: string | number | undefined,
    vestingMethod: string
  ): number | undefined => {
    if (vestingMethod !== 'Hours of Service' && value) {
      return undefined;
    }

    if (isString(value)) {
      return parseInt(value, 10);
    }

    return value;
  };

  disabled: boolean;

  output: number | undefined;

  setDisabled = (value: boolean): void => {
    this.disabled = value;
  };

  constructor(props: Record<string, any>) {
    this.disabled = props.vestingMethod !== 'Hours of Service';
    this.output = props.hoursOfServiceRequired;
  }
}

export default HoursOfServiceRequired;
