import { useField, useFormikContext } from 'formik';
import { FC, useEffect } from 'react';

const EmployeeContributionObserver: FC<{
  data: Record<string, any>;
}> = ({ data }: { data: Record<string, any> }) => {
  const { values }: { values: Record<string, any> } = useFormikContext();
  const [, , autoEnrollEffectiveDateHelpers] = useField(
    'autoEnrollEffectiveDate'
  );
  const [, , autoEscalateMaximumHelpers] = useField('autoEscalateMaximum');
  const [, , allowPermissibleWithdrawal] = useField(
    'allowPermissibleWithdrawal'
  );
  const [, , permissibleTimeframe] = useField('permissibleTimeframe');

  useEffect(() => {
    if (
      values.autoEnrollEffectiveDate === 'Invalid Date' &&
      values.autoEnrollAmount === 'None'
    ) {
      autoEnrollEffectiveDateHelpers.setValue('');
    } else if (
      !values.autoEnrollEffectiveDate &&
      values.autoEnrollAmount &&
      values.autoEnrollAmount !== 'None'
    ) {
      autoEnrollEffectiveDateHelpers.setValue('Invalid Date');
    }

    data.autoEnrollEffectiveDate.setDisabled(
      !values.autoEnrollAmount || values.autoEnrollAmount === 'None'
    );

    data.allowPermissibleWithdrawal.setDisabled(
      !values.autoEnrollAmount || values.autoEnrollAmount === 'None'
    );

    if (!values.autoEnrollAmount || values.autoEnrollAmount === 'None') {
      allowPermissibleWithdrawal.setValue('');
    }

    data.permissibleTimeframe.setDisabled(
      !values.autoEnrollAmount ||
        values.autoEnrollAmount === 'None' ||
        !values.allowPermissibleWithdrawal ||
        values.allowPermissibleWithdrawal === "Don't allow"
    );

    if (
      !values.autoEnrollAmount ||
      values.autoEnrollAmount === 'None' ||
      !values.allowPermissibleWithdrawal ||
      values.allowPermissibleWithdrawal === "Don't allow"
    ) {
      permissibleTimeframe.setValue('');
    }

    if (
      values.autoEscalateMaximum &&
      (!values.autoEscalateAmount || values.autoEscalateAmount === 'None')
    ) {
      autoEscalateMaximumHelpers.setValue('');
    }

    data.autoEscalateMaximum.setDisabled(
      !values.autoEscalateAmount || values.autoEscalateAmount === 'None'
    );
  }, [
    data,
    values,
    autoEnrollEffectiveDateHelpers,
    autoEscalateMaximumHelpers
  ]);

  return null;
};

export default EmployeeContributionObserver;
