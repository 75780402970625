import formatters from '@/utils/Formatters';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { useField, useFormikContext } from 'formik';
import React, { FunctionComponent } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

export type NumberFormatFormProps = TextFieldProps & {
  name: string;
  label?: string;
  numericFormatProps: NumericFormatProps & { 'data-testid'?: string };
};

type CustomProps = {
  onChange: (event: { target: { name: string; value: number } }) => void;
  name: string;
};

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: +values.value
            }
          });
        }}
      />
    );
  }
);

export const NumberFormatForm: FunctionComponent<
  NumberFormatFormProps
> = props => {
  const formik = useFormikContext();
  const [, meta] = useField(props.name);

  return (
    <TextField
      {...props}
      InputProps={{
        inputComponent: NumericFormatCustom as any,
        inputProps: {
          ...props.numericFormatProps,
          // off doesn't work and I get one password in the input
          autoComplete: 'new-password'
        }
      }}
      data-testid={
        props['data-testid'] ||
        formatters.textToDataTestId(props.label || props.name)
      }
      error={!!(meta.error !== undefined)}
      helperText={`${meta.error || ''}`}
      label={props.label}
      name={props.name}
      onChange={event => formik.setFieldValue(props.name, event.target.value)}
      type='text'
      value={meta.value === undefined || meta.value === null ? '' : meta.value}
    />
  );
};
