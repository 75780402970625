import AccessControl from '@/components/access-control/AccessControl.component';
import CardIconInfoField from '@/components/card-icon-info-field/CardIconInfoField.component';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUserToken } from '@/contexts/UserTokenContext';
import UpdateSponsorGeneralInformationPayload from '@/models/UpdateSponsorGeneralInformationPayload.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import SponsorService from '@/services/Sponsor.service';
import formatters from '@/utils/Formatters';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Box, Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQuery } from '@tanstack/react-query';

import React from 'react';
import * as yup from 'yup';

interface AuthorizedSignerTabProps {
  sponsorId: string | number;
  sponsorPlanId: string | number;
}

const useStyles = makeStyles(() => ({
  editButton: {
    marginLeft: 'auto',
    padding: '0px'
  }
}));

const AuthorizedSignerTab: React.FunctionComponent<AuthorizedSignerTabProps> = (
  props: AuthorizedSignerTabProps
) => {
  const classes = useStyles();
  const { openDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const { userHasValidToken } = useUserToken();
  const { sponsorId, sponsorPlanId } = props;

  const sponsorOnboardingMutation = useMutation(
    ['SponsorService.updateSponsorGeneralInformation'],
    (data: Partial<UpdateSponsorGeneralInformationPayload>) => {
      return SponsorService.updateSponsorGeneralInformation(
        sponsorPlanId,
        data
      );
    },
    {
      onError: () => {
        showSnackbar({ message: 'Failed!', severity: 'error' });
      },
      onSuccess: () => {
        showSnackbar({ message: 'Success!', severity: 'success' });
      }
    }
  );

  const sponsorOnboarding = useQuery(
    ['SponsorService.getOnboardingSponsorById', sponsorId],
    () => {
      return SponsorService.getOnboardingSponsorById(sponsorId);
    },
    {
      enabled: Boolean(userHasValidToken && sponsorId),
      staleTime: Infinity
    }
  );

  if (sponsorOnboarding.isFetching) {
    return (
      <Typography data-testid='plan-company-info-card-authorized-signer-tab-loading'>
        Loading...
      </Typography>
    );
  }

  if (sponsorOnboarding.isError) {
    return (
      <Typography data-testid='plan-company-info-card-authorized-signer-tab-error'>
        Error
      </Typography>
    );
  }

  const authSignerFullName = `${
    sponsorOnboarding.data?.fields?.authSignerFirstName || ''
  } ${sponsorOnboarding.data?.fields?.authSignerLastName || ''}`;

  return (
    <Box
      alignItems='baseline'
      data-testid='plan-company-info-card-authorized-signer-tab-content'
      display='flex'
      flexDirection='row'
      width='100%'>
      <Box
        display='flex'
        flexDirection='column'
        sx={{ '> *': { margin: '0 0 10px 0' } }}>
        <CardIconInfoField
          icon={<PersonOutlineOutlinedIcon />}
          title={formatters.displayValueOrEmptyFieldPlaceholder(
            authSignerFullName
          )}
          tooltip='Authorized Signer Name'
        />
        <CardIconInfoField
          icon={<EmailOutlinedIcon />}
          subtitle={formatters.displayValueOrEmptyFieldPlaceholder(
            sponsorOnboarding.data?.fields?.authorizedSignerEmail
          )}
          tooltip='Authorized Signer Email'
        />
      </Box>

      <AccessControl
        requiresOneOf={[FeatureLevelPermissions.WRITE_COMPANY_COMPANY_INFO]}>
        <Button
          className={classes.editButton}
          data-testid='edit-authorized-signer-button'
          onClick={() => {
            const validationSchema = yup.object().shape(
              {
                authSignerEmail: yup
                  .string()
                  .email('Email must be a valid email')
                  .max(50, 'Email must be at most 50 characters')
                  .optional(),
                authSignerFirstName: yup
                  .string()
                  .max(255, 'First Name must be at most 255 characters')
                  .when('authSignerLastName', {
                    is: (authSignerLastName: string) =>
                      authSignerLastName && authSignerLastName.length > 0,
                    otherwise: yup.string().optional(),
                    then: yup.string().required('First Name is required')
                  })
                  .matches(
                    /^[A-Za-z\s.,'-]+$/g,
                    'First Name cannot contain special characters '
                  ),
                authSignerLastName: yup
                  .string()
                  .max(255, 'Last Name must be at most 255 characters')
                  .when('authSignerFirstName', {
                    is: (authSignerFirstName: string) =>
                      authSignerFirstName && authSignerFirstName.length > 0,
                    otherwise: yup.string().optional(),
                    then: yup.string().required('Last Name is required')
                  })
                  .matches(
                    /^[A-Za-z\s.,'-]+$/g,
                    'Last Name cannot contain special characters '
                  )
              },
              [['authSignerFirstName', 'authSignerLastName']]
            );
            return openDialog({
              onSubmit: async values => {
                await sponsorOnboardingMutation.mutateAsync(values);
                sponsorOnboarding.refetch();
              },
              steps: [
                {
                  fields: {
                    authSignerFirstName: {
                      initialValue:
                        sponsorOnboarding.data?.fields?.authSignerFirstName ||
                        '',
                      label: 'First Name'
                    },
                    authSignerLastName: {
                      initialValue:
                        sponsorOnboarding.data?.fields?.authSignerLastName ||
                        '',
                      label: 'Last Name'
                    },
                    // eslint-disable-next-line sort-keys-plus/sort-keys
                    authSignerEmail: {
                      initialValue:
                        sponsorOnboarding.data?.fields?.authorizedSignerEmail ||
                        '',
                      label: 'Email'
                    }
                  },
                  title: 'Edit Authorized Signer Info'
                }
              ],
              validationSchema
            });
          }}
          variant='text'>
          Edit
        </Button>
      </AccessControl>
    </Box>
  );
};

export default AuthorizedSignerTab;
