import CircularLoading from '@/components/circular-loading';
import DatePicker from '@/components/date-picker';
import { PLAN_DATES_TYPES } from '@/consts/plan.constants';
import { PlanService } from '@/services/Plan.service';
import { InfoOutlined } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Popover,
  Typography
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';

import dayjs from 'dayjs';
import { Field, Form, Formik } from 'formik';
import React, { useMemo, useState } from 'react';
import { useToggle } from 'react-use';

interface PlanEmployeesScheduleEmailsProps {
  sponsorPlanId: string;
}

export const PlanEmployeesScheduleEmails: React.FC<
  PlanEmployeesScheduleEmailsProps
> = props => {
  const [isDialogOpen, toggleIsDialogOpen] = useToggle(false);
  const [infoPopoverRef, setInfoPopoverRef] =
    useState<HTMLButtonElement | null>(null);

  const welcomeEmailDatesQuery = useQuery(
    ['PlanService.getWelcomeEmailDates', props.sponsorPlanId],
    async () => PlanService.getWelcomeEmailDates(props.sponsorPlanId)
  );

  const { welcomeEmailScheduledDate, welcomeEmailSentDate } = useMemo(() => {
    const welcomeEmailScheduledDateObj = welcomeEmailDatesQuery?.data?.find(
      date => date.dateType === PLAN_DATES_TYPES.WELCOME_EMAILS_SCHEDULED
    );
    const welcomeEmailSentDateObj = welcomeEmailDatesQuery?.data?.find(
      date => date.dateType === PLAN_DATES_TYPES.WELCOME_EMAILS_SENT
    );
    return {
      welcomeEmailScheduledDate: welcomeEmailScheduledDateObj
        ? welcomeEmailScheduledDateObj.date
        : null,
      welcomeEmailSentDate: welcomeEmailSentDateObj
        ? welcomeEmailSentDateObj.date
        : null
    };
  }, [welcomeEmailDatesQuery.data]);

  const rescheduleWelcomeEmailsDate = useMutation({
    mutationFn: async (date: Date) => {
      await PlanService.deleteScheduledWelcomeEmailsDates(props.sponsorPlanId);
      await PlanService.createScheduledWelcomeEmailsDate(props.sponsorPlanId, {
        date
      });
      await welcomeEmailDatesQuery.refetch();
    }
  });

  const deleteScheduledWelcomeEmailsDates = useMutation({
    mutationFn: async () => {
      await PlanService.deleteScheduledWelcomeEmailsDates(props.sponsorPlanId);
      await welcomeEmailDatesQuery.refetch();
    }
  });

  const tomorrow = useMemo(() => {
    const today = new Date();
    const result = new Date(today);
    result.setDate(result.getDate() + 1);
    return result;
  }, []);

  const onSendScheduledEmails = async (data: { date: Date }) => {
    await rescheduleWelcomeEmailsDate.mutateAsync(data.date);

    toggleIsDialogOpen();
  };

  const onCancelScheduledEmails = async () => {
    await deleteScheduledWelcomeEmailsDates.mutateAsync();
  };

  const onInfoPopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setInfoPopoverRef(event.currentTarget);
  };

  const onInfoPopoverClose = () => {
    setInfoPopoverRef(null);
  };

  const isInfoPopoverOpen = Boolean(infoPopoverRef);
  const infoPopoverId = isInfoPopoverOpen ? 'info-popover' : undefined;

  return (
    <Box>
      {welcomeEmailDatesQuery.isFetching && (
        <Alert severity='info'>
          <Box sx={{ width: '1.5rem' }}>
            <CircularLoading size='1rem' />
          </Box>
        </Alert>
      )}
      {welcomeEmailDatesQuery.isError && (
        <Alert severity='error'>Failed to load welcome email dates</Alert>
      )}
      {!welcomeEmailDatesQuery.isFetching &&
        !welcomeEmailDatesQuery.isError && (
          <>
            {welcomeEmailSentDate && (
              <Alert severity='info'>
                Welcome Email sent on{' '}
                {dayjs(welcomeEmailSentDate).format('MM/DD/YYYY')}
              </Alert>
            )}

            {welcomeEmailScheduledDate && !welcomeEmailSentDate && (
              <Alert
                action={
                  <>
                    <Button
                      color='inherit'
                      onClick={toggleIsDialogOpen}
                      size='small'>
                      Edit
                    </Button>
                    <Button
                      color='inherit'
                      onClick={onCancelScheduledEmails}
                      size='small'>
                      Cancel
                    </Button>
                  </>
                }
                severity='info'>
                Welcome Email is scheduled for{' '}
                {dayjs(welcomeEmailScheduledDate).format('MM/DD/YYYY')}
              </Alert>
            )}

            {!welcomeEmailScheduledDate && !welcomeEmailSentDate && (
              <>
                <Button onClick={toggleIsDialogOpen} variant='contained'>
                  Schedule Welcome Email
                </Button>

                <IconButton
                  aria-describedby={infoPopoverId}
                  color='primary'
                  onClick={onInfoPopoverOpen}>
                  <InfoOutlined />
                </IconButton>

                <Popover
                  anchorEl={infoPopoverRef}
                  anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom'
                  }}
                  id={infoPopoverId}
                  onClose={onInfoPopoverClose}
                  open={isInfoPopoverOpen}>
                  <Typography sx={{ p: 2 }}>
                    Only unregistered participants will receive welcome emails.
                  </Typography>
                </Popover>
              </>
            )}
          </>
        )}

      <Dialog onClose={toggleIsDialogOpen} open={isDialogOpen}>
        <DialogTitle>Schedule Welcome Email</DialogTitle>
        <Formik
          enableReinitialize
          initialValues={{
            date: welcomeEmailScheduledDate
              ? welcomeEmailScheduledDate
              : tomorrow
          }}
          onSubmit={onSendScheduledEmails}>
          <Form>
            <DialogContent>
              <Typography sx={{ mb: 1 }}>
                Emails will be sent on the selected date.
              </Typography>
              <FormControl variant='outlined'>
                <Field
                  as={DatePicker}
                  autoCompete='off'
                  disableHighlightToday
                  label='Date'
                  minDate={dayjs(tomorrow)}
                  name='date'
                  size='small'
                  sx={{ width: 200 }}
                  variant='outlined'
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={toggleIsDialogOpen}>Cancel</Button>
              <Button
                disabled={rescheduleWelcomeEmailsDate.isLoading}
                type='submit'>
                {welcomeEmailScheduledDate ? 'Update' : 'Schedule Email'}
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    </Box>
  );
};
