import { useSnackbar } from '@/contexts/SnackBarContext';
import {
  ParticipantHoursToUpdateDto,
  UpdatedHoursOfServiceDto
} from '@/models';
import ParticipantService from '@/services/Participant.service';
import {
  useMutation,
  UseMutationResult,
  useQueryClient
} from '@tanstack/react-query';

import { checkAdditionalHoursValidations } from './utils';

const useUpdateHoursOfService = (
  participantId: string,
  planId?: number,
  updateEditId?: () => void,
  updateHoursInSubmit?: (id: number) => void
): UseMutationResult<
  UpdatedHoursOfServiceDto,
  Error,
  ParticipantHoursToUpdateDto,
  () => void
> => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation(
    ['ParticipantService.updateParticipantHoursOfService'],
    (data: ParticipantHoursToUpdateDto) => {
      return ParticipantService.updateParticipantHoursOfService(
        +participantId,
        planId,
        data
      );
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Failed!',
          severity: 'error'
        });
      },
      onSuccess: async updatedRow => {
        queryClient.invalidateQueries([
          'ParticipantService.getParticipantHoursOfService'
        ]);
        if (updateEditId) {
          updateEditId();
        }
        showSnackbar({
          autoHideDuration: 15000,
          message: 'Success! Information saved.',
          severity: 'success'
        });
        checkAdditionalHoursValidations(
          queryClient,
          participantId,
          showSnackbar
        );
        if (updateHoursInSubmit) {
          updateHoursInSubmit(updatedRow.data[0].employeeHoursTrackerId);
        }
      }
    }
  );
};

export default useUpdateHoursOfService;
