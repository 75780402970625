import CollapsibleTable from '@/components/collapsible-table';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import {
  TargetSeriesData,
  TargetSeriesModelProps
} from '@/routes/ops/investments/TargetSeries.component';
import formatters from '@/utils/Formatters';
import {
  alpha,
  Box,
  Card,
  CardContent,
  CardHeader,
  Theme
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GridColDef } from '@mui/x-data-grid-pro';

import Decimal from 'decimal.js';
import React, { useMemo, useState } from 'react';

import TargetSeriesTableCell from './TargetSeriesCell.component';
import { TargetSeriesFields } from './types';

const mapDataRow = (
  option: TargetSeriesModelProps,
  maxTickerLength: number
) => {
  const lastSubRowIndex = option.modelFunds.length - 1;
  return option.modelFunds.map((modelFund, index) => ({
    expenseRatio: new Decimal(modelFund?.expenseRatio || 0).toFixed(5),
    id: `${option.targetModelId}-${modelFund.fundId}`,
    maxTickerLength,
    modelName: !index ? option.modelName : '',
    security: {
      cusip: modelFund.cusip,
      fundName: modelFund.fundName,
      id: modelFund.symbol
    },
    targetYears: !index
      ? option.targetRetireYearLow + ' - ' + option.targetRetireYearHigh
      : '',
    totalAllocation: { [index]: modelFund.target },
    turnOffRowBorder: index !== lastSubRowIndex
  }));
};

const useStyles = makeStyles((theme: Theme) => ({
  card: { padding: theme.spacing(3) },
  cardContext: { padding: 0, paddingTop: theme.spacing(2.25) },
  cardHeaderRoot: { padding: 0 },
  cardHeaderTitle: { fontWeight: 500 },
  headerCell: {
    color: alpha(theme.palette.common.black, 0.6),
    fontSize: theme.spacing(1.75),
    fontWeight: 500,
    paddingBottom: theme.spacing(1.25),
    paddingTop: theme.spacing(1.25)
  }
}));

interface TargetSeriesTableProps {
  targetSeriesData?: TargetSeriesData;
  targetSeriesGridData?: TargetSeriesModelProps[];
}

const TargetSeriesTable: React.FC<TargetSeriesTableProps> = props => {
  const { targetSeriesData, targetSeriesGridData } = props;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const classes = useStyles();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        cellClassName: classes.headerCell,
        field: TargetSeriesFields.TARGET_YEARS,
        headerName: 'Target Years'
      },
      {
        cellClassName: classes.headerCell,
        field: TargetSeriesFields.MODEL_NAME,
        headerName: 'Model Name'
      },
      {
        cellClassName: classes.headerCell,
        field: TargetSeriesFields.SECURITY,
        headerName: 'Security'
      },
      {
        cellClassName: classes.headerCell,
        field: TargetSeriesFields.TOTAL_ALLOCATION,
        headerName: 'Total Allocation'
      },
      {
        cellClassName: classes.headerCell,
        field: TargetSeriesFields.EXPENSE_RATIO,
        headerName: 'Expense Ratio'
      }
    ],
    [classes]
  );

  const tableData = useMemo(() => {
    if (!targetSeriesGridData) return [];

    const maxTickerLength = Math.max(
      ...targetSeriesGridData.map(p =>
        Math.max(...p.modelFunds.map(e => e.symbol?.length || 0))
      )
    );
    return targetSeriesGridData.map(p => mapDataRow(p, maxTickerLength)).flat();
  }, [targetSeriesGridData]);

  const programDesc = [
    { label: 'Owner', value: 'Vestwell' },
    {
      label: 'Description',
      value: formatters.chopStringLongerThan(targetSeriesData?.description, 300)
    }
  ];

  return (
    <Box>
      <Card className={classes.card} elevation={0} variant='outlined'>
        <CardHeader
          classes={{
            root: classes.cardHeaderRoot,
            title: classes.cardHeaderTitle
          }}
          title={targetSeriesData?.name}
        />
        <CardContent className={classes.cardContext}>
          <TextStack direction='row' rowColumnWidth='dynamic'>
            {programDesc
              .filter(item => item.value)
              .map(item => (
                <TextStackItem key={item.label} maxWidth='100%'>
                  <TextLabel>{item.label}</TextLabel>
                  <TextValue>{item.value}</TextValue>
                </TextStackItem>
              ))}
          </TextStack>
        </CardContent>
      </Card>
      <CollapsibleTable
        backgroundPaperElevation={0}
        cellComponent={TargetSeriesTableCell}
        columns={columns}
        pager={{
          metaCount: tableData.length,
          onPageNumberChanged: newPage => setPageNumber(newPage + 1),
          onRowsPerPageChanged: newRowsPerPage =>
            setRowsPerPage(newRowsPerPage),
          pageNumber: pageNumber - 1,
          rowsPerPage: rowsPerPage
        }}
        primaryKey='targetSeriesId'
        rootPaperElevation={0}
        tableData={tableData.slice(
          rowsPerPage * pageNumber - rowsPerPage,
          rowsPerPage * pageNumber
        )}
        useSquareBottomContainer
      />
    </Box>
  );
};

export default TargetSeriesTable;
