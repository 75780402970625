import useHasPermissions from '@/components/access-control/useHasPermissions.hook';
import { SnackbarOptions } from '@/contexts/SnackBarContext';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { ActionCenterService } from '@/services/ActionCenter.service';
import { QueryClient, useMutation } from '@tanstack/react-query';

import dayjs from 'dayjs';
import { useMemo } from 'react';

import { ActionCenterDetailsBulkEmail } from './ActionCenterDetailsBulkEmail.component';
import {
  BulkActionsConfig,
  previewColumns
} from './useBulkActionCenterConfig.hook';

export const useBulkEmailConfig = (
  details: Record<string, any>,
  setDetails: React.Dispatch<React.SetStateAction<Record<string, any>>>,
  setActiveStep: React.Dispatch<React.SetStateAction<number>>,
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>,
  showSnackbar: (options: SnackbarOptions) => void,
  queryClient: QueryClient
) => {
  const permissions = useHasPermissions({
    requires: [FeatureLevelPermissions.WRITE_BULK_EMAIL_ACTIONS]
  });

  const bulkEmailPlansPreviewMutation = useMutation(
    ['ActionCenterService.bulkEmailPlansPreview'],
    (data: { effectiveDate: string }) =>
      ActionCenterService.bulkEmailPlansPreview(data.effectiveDate),
    {
      onError: () =>
        showSnackbar({
          message: 'There was an error previewing the plans',
          severity: 'error'
        }),
      onSuccess: () => setDialogOpen(true)
    }
  );

  const bulkEmailPlansMutation = useMutation(
    ['ActionCenterService.bulkEmailPlans'],
    (data: { effectiveDate: string }) =>
      ActionCenterService.bulkEmailPlans(
        data.effectiveDate,
        bulkEmailPlansPreviewMutation.data?.length || 0
      ),
    {
      onError: () => {
        setDialogOpen(false);
        showSnackbar({
          message: 'Your bulk action failed to submit',
          severity: 'error'
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries([
          'ActionCenterService.getBulkEmailActions'
        ]);
        showSnackbar({
          message: 'Your bulk action submitted successfully',
          severity: 'success'
        });
        setDialogOpen(false);
      }
    }
  );

  const bulkUploadFilesConfig = useMemo<BulkActionsConfig>(
    () => ({
      isPreviewLoading: bulkEmailPlansPreviewMutation.isLoading,
      noPreviewDataMessage: 'There are no plans with that effective date',
      onConfirm: () =>
        bulkEmailPlansMutation.mutate({
          effectiveDate: details.date
        }),
      onPreview: () =>
        bulkEmailPlansPreviewMutation.mutate({ effectiveDate: details.date }),
      previewColumns,
      previewData: bulkEmailPlansPreviewMutation.data,

      step2: (
        <ActionCenterDetailsBulkEmail
          details={details}
          setDetails={values => {
            setDetails(values);
            setActiveStep(dayjs().isAfter(values.date) ? 3 : 1);
          }}
        />
      ),
      step3: `Plans with at least one document uploaded on the effective date will receive this notification.`,

      visible: permissions.isAllowed
    }),
    [bulkEmailPlansMutation, bulkEmailPlansPreviewMutation, permissions]
  );

  return bulkUploadFilesConfig;
};
