import CircularLoading from '@/components/circular-loading';
import { useUserToken } from '@/contexts/UserTokenContext';
import { Close } from '@mui/icons-material';
import {
  Autocomplete,
  AutocompleteProps,
  FormControl,
  InputAdornment,
  TextField,
  Theme
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { Field, FormikProps } from 'formik';
import { identity, isEqual, omit } from 'lodash';
import React, { useState } from 'react';

type ShrinkingLabel = {
  keepLabelOnTop?: false | undefined;
  placeholder?: undefined;
};

type HighLabelWithPlaceholder = {
  keepLabelOnTop: true;
  placeholder: string;
};

type HighLabelWithoutPlaceholder = {
  keepLabelOnTop: true;
  placeholder?: undefined;
};

export type SimpleAutocompleteProps<T> = Pick<
  AutocompleteProps<T, boolean, boolean, boolean>,
  'getOptionLabel' | 'renderOption' | 'isOptionEqualToValue' | 'defaultValue'
> & {
  fieldName: string;
  onChange?: (event: any, choice: any) => void; // should be "choice: T", but some existing autocompletes would break
  fieldId: string;
  getFieldValues: (userInput: string) => Promise<T[]>;
  icon?: React.ReactNode;
  required?: boolean;
} & {
  width?: string;
} & (ShrinkingLabel | HighLabelWithPlaceholder | HighLabelWithoutPlaceholder);

const defaultGetOptionLabel = (obj: any) => JSON.stringify(obj);

const useStyles = makeStyles((theme: Theme) => ({
  textFieldAdornmentLoadingIcon: {
    marginRight: theme.spacing(1)
  }
}));

const SimpleAutocomplete = <T extends Record<string, any>>(
  props: SimpleAutocompleteProps<T>
): JSX.Element => {
  const {
    width,
    fieldId,
    fieldName,
    renderOption,
    getFieldValues,
    defaultValue,
    required,
    onChange,
    getOptionLabel = defaultGetOptionLabel,
    isOptionEqualToValue = isEqual
  } = props;

  const { placeholder = fieldName } = 'placeholder' in props ? props : {};
  const { keepLabelOnTop = undefined } = 'keepLabelOnTop' in props ? props : {};
  const { icon } = 'icon' in props ? props : { icon: null };

  const { userHasValidToken } = useUserToken();
  const [query, setQuery] = useState('');

  const classes = useStyles();

  const optionsQuery: UseQueryResult<T[]> = useQuery<T[]>(
    [
      'SimpleAutocomplete',
      'getFieldValues',
      getFieldValues.name,
      fieldId,
      fieldName,
      query
    ],
    () => getFieldValues(query),
    {
      enabled: userHasValidToken && Boolean(query.trim().length),
      keepPreviousData: false,
      staleTime: Infinity
    }
  );

  const options = optionsQuery.data || [];

  let noOptionsText = 'Type to search';
  const hasResults = options.length > 0;
  const hasQuery = query.trim().length > 0;

  if (hasQuery && !hasResults) {
    if (optionsQuery.isSuccess) {
      noOptionsText = `No results found for "${query}"`;
    } else if (optionsQuery.isError) {
      noOptionsText = `Encountered an error searching for "${query}"`;
    } else if (!optionsQuery.isFetching) {
      noOptionsText = 'Type to search';
    } else if (optionsQuery.isFetching) {
      noOptionsText = 'Searching...';
    }
  }

  const styles = width != null ? { width } : {};

  return (
    <FormControl component='fieldset' key={fieldId}>
      <Field name={fieldId}>
        {(fieldProps: { form: FormikProps<any> }) => {
          const isError = Boolean(
            fieldProps.form.touched[fieldId] && fieldProps.form.errors[fieldId]
          );

          return (
            <>
              <Autocomplete
                autoComplete
                autoHighlight
                clearIcon={<Close />}
                defaultValue={defaultValue as T}
                disablePortal={false}
                filterOptions={identity}
                fullWidth
                getOptionLabel={getOptionLabel}
                id={fieldId}
                isOptionEqualToValue={isOptionEqualToValue}
                noOptionsText={noOptionsText}
                onChange={
                  onChange ||
                  ((event, value) => {
                    fieldProps.form.setFieldValue(fieldId, value || '');
                  })
                }
                openOnFocus
                options={options}
                renderInput={params => {
                  return (
                    <TextField
                      required={required}
                      style={styles}
                      {...omit(params, 'InputLabelProps')}
                      InputLabelProps={{ shrink: keepLabelOnTop }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: optionsQuery.isFetching ? (
                          <InputAdornment
                            className={classes.textFieldAdornmentLoadingIcon}
                            position='end'>
                            <CircularLoading />
                          </InputAdornment>
                        ) : (
                          <InputAdornment position='end'>
                            {icon || params.InputProps.endAdornment}
                          </InputAdornment>
                        ),
                        error: isError,
                        placeholder,
                        style: { paddingRight: '0px' }
                      }}
                      error={isError}
                      label={fieldName}
                      onChange={event => setQuery(event.target.value)}
                    />
                  );
                }}
                renderOption={renderOption}
              />
            </>
          );
        }}
      </Field>
    </FormControl>
  );
};

export default SimpleAutocomplete;
