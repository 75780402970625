import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class Trustee {
  convertToApiState = Helper.convertValueToApiState;

  tpaUserOptions = ['Plan Sponsor', 'Other'];

  vwUserOptions = ['Vestwell Trust Company', 'Plan Sponsor', 'Other'];

  options: string[];

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.options = props.isTpaUser ? this.tpaUserOptions : this.vwUserOptions;
    this.output = props.trustee;
  }
}

export default Trustee;
