import { createSvgIcon, SvgIconProps } from '@mui/material';

import React, { FC } from 'react';

export const PayrollAggregatorIcon: FC<SvgIconProps> = createSvgIcon(
  <>
    <path
      d='M13.038 5c0-2.041-1.584-3.692-3.538-3.692-1.954 0-3.541 1.651-3.541 3.692 0 2.039 1.587 3.691 3.541 3.691S13.038 7.039 13.038 5zM13.182 5h3.578M2.24 5h3.577'
      fill='none'
      stroke='#2196F3'
      strokeLinecap='round'
      strokeWidth='2.25'
    />
  </>,
  'PayrollAggregatorIcon'
);

PayrollAggregatorIcon.defaultProps = {
  style: {
    borderRadius: 50,
    height: 20,
    width: 20
  },
  viewBox: '-1 -5 20 20'
};

PayrollAggregatorIcon.displayName = 'PayrollAggregatorIcon';
