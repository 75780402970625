// TODO: SUB-3079 — replace with @vestwell-sub-accounting reference once enum is published
export enum ProcessingWindow {
  DividendProcessing = 'DividendProcessing',
  CorrectionsProcessing = 'CorrectionsProcessing',
  ProcessingInvestContributionNscc = 'ProcessingInvestContributionNscc',
  ProcessingInvestContributionEtf = 'ProcessingInvestContributionEtf',
  ProcessingInvestContributionMixed = 'ProcessingInvestContributionMixed',
  ProcessingTradeRequestRebalanceNsccY = 'ProcessingTradeRequestRebalanceNsccY',
  ProcessingTradeRequestRebalanceNsccN = 'ProcessingTradeRequestRebalanceNsccN',
  ProcessingTradeRequestRebalanceEtf = 'ProcessingTradeRequestRebalanceEtf',
  ProcessingTradeRequestRebalanceMixed = 'ProcessingTradeRequestRebalanceMixed',
  ProcessingTradeRequestLiqOrWithNsccY = 'ProcessingTradeRequestLiqOrWithNsccY',
  ProcessingTradeRequestLiqOrWithNsccN = 'ProcessingTradeRequestLiqOrWithNsccN',
  ProcessingTradeRequestLiqOrWithNscc = 'ProcessingTradeRequestLiqOrWithNscc',
  ProcessingTradeRequestLiqOrWithEtf = 'ProcessingTradeRequestLiqOrWithEtf',
  ProcessingTradeRequestLiqOrWithMixed = 'ProcessingTradeRequestLiqOrWithMixed',
  ProcessingTradeRequestFlattenNscc = 'ProcessingTradeRequestFlattenNscc',
  ProcessingTradeRequestFlattenEtf = 'ProcessingTradeRequestFlattenEtf',
  ProcessingTradeRequestFlattenMixed = 'ProcessingTradeRequestFlattenMixed',
  OrderAggMatrixNscc = 'OrderAggMatrixNscc',
  CashTransactionsPosting = 'CashTransactionsPosting',
  SecurityTransactionPosting = 'SecurityTransactionPosting',
  ProcessingIndividualOrdersNscc = 'ProcessingIndividualOrdersNscc',
  ProcessingIndividualOrdersEtf = 'ProcessingIndividualOrdersEtf'
}
