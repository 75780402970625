import { CellComponentProps } from '@/components/collapsible-table';
import formatters from '@/utils/Formatters';
import { Box, TableCell, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  border: {
    borderLeft: `1px solid ${theme.palette.grey[300]}`
  },
  size: {
    fontSize: theme.spacing(2)
  }
}));

const ContributionTransactionsTableCell: React.FunctionComponent<
  CellComponentProps
> = (props: CellComponentProps) => {
  const classes = useStyles();
  const { row, column } = props;

  let field = <Box>{row[column.field]}</Box>;

  if (column.field === 'amount') {
    field = <Box>{formatters.formatDollars(row[column.field])}</Box>;
  } else if (column.field === 'tradeDate' || column.field === 'effectiveDate') {
    field = (
      <Box>
        {formatters.formatFromIsoDateCustom(row[column.field], 'M/DD/YY')}
      </Box>
    );
  }

  return (
    <TableCell className={clsx(classes.size)} component='th' scope='row'>
      <Box>{field}</Box>
    </TableCell>
  );
};

export default ContributionTransactionsTableCell;
