import LinearLoading from '@/components/linear-loading';
import NavigationBreadcrumbs from '@/components/navigation-breadcrumbs';
import { Program } from '@/models/ops/investments/Program.model';
import { ProgramEditPage } from '@/routes/ops/investments/ProgramEditPage.component';
import { ProgramService } from '@/services/ops/investments/Program.service';
import { useQuery } from '@tanstack/react-query';

import { useParams } from 'react-router-dom';

type ProgramEditRouteProps = {
  id: string;
  tab?: string;
};

const ProgramEditRoute = (): JSX.Element => {
  const { id, tab } = useParams<ProgramEditRouteProps>();

  const programId = parseInt(id, 10);

  const programQuery = useQuery<Program>(
    ['ProgramEdit.programQuery', 'program', programId],
    () => ProgramService.getProgram(programId),
    { cacheTime: 0 }
  );

  const loading = programQuery.isFetching;
  const canDisplay = !loading && programQuery.data !== undefined;
  const possibleError = !loading && !canDisplay;

  const paths = [
    {
      name: 'Ops',
      to: '/ops/investments'
    },
    {
      name: 'Investments',
      to: '/ops/investments'
    },
    {
      name: programQuery.data?.name || '',
      to: `/ops/investments/program/${programId}`
    }
  ];

  return (
    <>
      <NavigationBreadcrumbs paths={paths} />
      {loading && <LinearLoading />}
      {canDisplay && (
        <ProgramEditPage
          programData={programQuery.data}
          refetchProgram={() => programQuery.refetch()}
          tab={tab}
        />
      )}
      {possibleError && <div>Possible Error</div>}
    </>
  );
};

export default ProgramEditRoute;
