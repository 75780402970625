import AccessControl from '@/components/access-control/AccessControl.component';
import LinearLoading from '@/components/linear-loading';
import SimpleNumberField from '@/components/simple-number-field/SimpleNumberField';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { ParticipantAccountsDto } from '@/models';
import { PlanDesignDto } from '@/models/PlanDesign.model';
import { PsaDto, UpdateYearsVestingInfo } from '@/models/Psa.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { PsaService } from '@/services/Psa.service';
import { userService } from '@/services/User.service';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Theme,
  Tooltip,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
  UseQueryResult
} from '@tanstack/react-query';

import { Form, Formik } from 'formik';
import { isEqual } from 'lodash';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import * as yup from 'yup';

interface ParticipantVestingOverviewCardProps {
  planDesign?: PlanDesignDto;
  participantId: string;
  psaData?: PsaDto;
  accountInfo?: ParticipantAccountsDto;
  isLoading?: boolean;
  openEditNotesModal?: (query, mutation, afterSubmit?: () => void) => void;
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  vestingNotesQuery: {
    notesQuery: UseQueryResult;
    notesMutation: UseMutationResult;
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  card: {
    width: theme.spacing(55)
  },
  checkbox: {
    color: theme.palette.grey[400]
  },
  errorText: {
    color: 'red',
    fontSize: theme.spacing(1.5),
    marginTop: '5px'
  },
  footer: {
    color: theme.palette.grey[600],
    fontSize: theme.spacing(1.5),
    marginTop: theme.spacing(1.5)
  },
  gridSpacing: {
    display: 'flex',
    justifyContent: 'space-between',
    width: theme.spacing(50)
  },
  headerTitle: {
    color: theme.palette.grey[600],
    fontSize: theme.spacing(1.75),
    marginBottom: theme.spacing(0.5),
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  infoIcon: {
    fontSize: 'medium',
    marginLeft: theme.spacing(-1.5)
  },
  inputField: {
    '& .MuiInputBase-input': {
      height: theme.spacing(2.5),
      padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`
    },
    '& .MuiInputLabel-outlined': {
      fontSize: theme.spacing(2),
      lineHeight: theme.spacing(2),
      transform: 'translate(12px, 12px) scale(1)'
    },
    '& .MuiInputLabel-shrink': {
      fontSize: '1rem',
      lineHeight: '1.4375em',
      transform: 'translate(14px, -9px) scale(0.75)'
    },
    backgroundColor: theme.palette.common.white,
    marginRight: theme.spacing(2),
    maxWidth: 50
  },
  regFont: {
    color: 'black',
    marginLeft: theme.spacing(2)
  },
  spacing: {
    color: theme.palette.grey[600],
    marginLeft: theme.spacing(2)
  },
  title: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2)
  }
}));

const ParticipantVestingOverviewCard: React.FunctionComponent<
  ParticipantVestingOverviewCardProps
> = (props): JSX.Element => {
  const vestingYearsData: UpdateYearsVestingInfo = {
    historicalYos: props.psaData?.historicalYearsOfVesting || 0,
    partialPlanTermination: props.psaData?.partialPlanTermination || false,
    psaId: props.psaData?.psaId,
    yearsOfVesting:
      props.psaData?.yearsOfVesting ||
      (props.psaData?.yearsOfVesting === 0 ? 0 : EMPTY_FIELD_PLACEHOLDER)
  };
  const camelCaseToWords = (
    record: Record<string, boolean | number>
  ): string[] =>
    Object.keys(record).map(key =>
      key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())
    );

  const accountsWithOverrides = useMemo<Set<string>>(() => {
    return (
      props.accountInfo?.data
        .filter(
          account =>
            account.attributes?.vestingOverride &&
            Object.keys(account.attributes.vestingOverride).length > 0
        )
        .reduce((set, account) => {
          camelCaseToWords(account.attributes.vestingOverride).forEach(word =>
            set.add(word)
          );
          return set;
        }, new Set<string>()) || new Set<string>()
    );
  }, [props.accountInfo?.data]);

  const [terminationChecked, setTerminationChecked] = useState(
    vestingYearsData.partialPlanTermination
  );
  const [historyYearsChecked, setHistoryYearsChecked] = useState(
    vestingYearsData.historicalYos !== 0
  );
  const [totalYearsChecked, setTotalYearsChecked] = useState(
    vestingYearsData.yearsOfVesting !== EMPTY_FIELD_PLACEHOLDER
  );
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const hasWritePermissions = userService.hasPermission(
    FeatureLevelPermissions.WRITE_VESTING
  );

  const disableYearsOfService = useMemo(
    () =>
      props.accountInfo?.data?.some(
        account => account.attributes.vestedPercentOverride
      ) ?? false,
    [props.accountInfo?.data]
  );

  const historyYearsRef = useRef<HTMLInputElement>(null);
  const totalYearsRef = useRef<HTMLInputElement>(null);
  const historyBoxRef = useRef<HTMLInputElement>(null);
  const totalBoxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (
      historyYearsChecked &&
      historyYearsRef.current &&
      document.activeElement === historyBoxRef.current
    ) {
      historyYearsRef.current.focus();
      historyYearsRef.current.select();
    }

    if (
      totalYearsChecked &&
      totalYearsRef.current &&
      document.activeElement === totalBoxRef.current
    ) {
      totalYearsRef.current.focus();
      totalYearsRef.current.select();
    }
  }, [historyYearsChecked, totalYearsChecked]);

  const overrideVestingYearsQuery = useMutation(
    (updatePayload: UpdateYearsVestingInfo) => {
      return PsaService.updateVestingYears(+props.participantId, updatePayload);
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Failed!',
          severity: 'error'
        });
      },
      onSuccess: async () => {
        await queryClient.refetchQueries([
          'PsaService.getPsa',
          props.participantId?.toString()
        ]);

        showSnackbar({
          message:
            'Success! Vesting information saved. Click “Recalculate” to update vested balance now.',
          severity: 'success'
        });
      }
    }
  );

  const yearsValidationSchema = () => {
    return yup.object({
      historicalYos: yup
        .number()
        .integer('Historical years of service should be a non-decimal value')
        .test(
          'Historical Years of Service',
          'Historical years of service must be greater than or equal to zero',
          num => Number(num) >= 0
        ),
      yearsOfVesting: yup
        .mixed()
        .test(
          'Total Years of Vesting',
          'Total years of vesting must be greater than or equal to zero',
          num => num === '--' || Number(num) >= 0 || num === ''
        )
        .test(
          'Total Years of Vesting',
          'Total years of vesting should be a non-decimal value',
          num => num === '--' || Number.isInteger(+num)
        )
    });
  };

  return (
    <Card
      className={classes.card}
      data-testid='participant-vesting-overview-card'
      elevation={0}
      variant='outlined'>
      <Typography className={classes.title} variant='h5'>
        Overview
      </Typography>
      {props.isLoading && <LinearLoading />}
      <Typography className={classes.headerTitle}>Vesting Method</Typography>
      <Typography
        className={classes.regFont}
        data-testid='participant-vesting-overview-vesting-method'>
        {props.planDesign?.data.vestingPreferences?.vestingMethod || '--'}
      </Typography>
      {accountsWithOverrides.size > 0 && (
        <Box>
          <Typography className={classes.headerTitle}>
            Overrides in Effect
          </Typography>
          <Typography className={classes.regFont}>
            {[...accountsWithOverrides].sort().join(', ')}
          </Typography>
        </Box>
      )}
      <Typography className={classes.headerTitle}>Override Settings</Typography>

      <Formik
        enableReinitialize
        initialValues={vestingYearsData}
        onSubmit={() => {}}
        validationSchema={yearsValidationSchema}>
        {formik => {
          const hasChanges =
            !isEqual(
              +formik.initialValues.historicalYos,
              +formik.values.historicalYos
            ) ||
            !isEqual(
              formik.initialValues.yearsOfVesting,
              formik.values.yearsOfVesting
            ) ||
            !isEqual(
              formik.initialValues.partialPlanTermination,
              formik.values.partialPlanTermination
            );

          return (
            <Form>
              <FormControl className={classes.spacing}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={terminationChecked}
                      className={classes.checkbox}
                      data-testid='partial-plan-termination-checkbox'
                      disabled={!hasWritePermissions}
                      name='partialPlanTerminationCheckbox'
                      onChange={(e, checked) => {
                        setTerminationChecked(checked);
                        formik.setFieldValue('partialPlanTermination', checked);

                        if (Number(historyYearsRef.current?.value) === 0) {
                          setHistoryYearsChecked(false);
                        }

                        if (
                          totalYearsRef.current?.value ===
                          EMPTY_FIELD_PLACEHOLDER
                        ) {
                          setTotalYearsChecked(false);
                          props.setIsEditing(false);
                        }
                      }}
                      value={terminationChecked}
                    />
                  }
                  label='Impacted by Partial Plan Termination'
                />
                <Grid className={classes.gridSpacing} container>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={historyYearsChecked}
                          className={classes.checkbox}
                          color={
                            terminationChecked || totalYearsChecked
                              ? 'default'
                              : 'primary'
                          }
                          data-testid='historical-years-of-vesting-checkbox'
                          disabled={!hasWritePermissions}
                          id='historicalYosCheckbox'
                          inputRef={historyBoxRef}
                          name='historicalYosCheckbox'
                          onChange={(e, checked) => {
                            setHistoryYearsChecked(checked);

                            if (checked) {
                              formik.setFieldValue(
                                'historicalYos',
                                formik.initialValues.historicalYos
                              );
                            } else {
                              formik.setFieldValue('historicalYos', 0);
                            }
                          }}
                          value={historyYearsChecked}
                        />
                      }
                      label='Historical Years of Vesting'
                    />
                    <Tooltip
                      data-testid='historical-years-of-vesting-tooltip'
                      title='Historical Years of Service are the number of years the saver has historically worked. 
                      This number is to be combined with the years we calculate within our vesting calculation. 
                      Note that this generally does not affect elapsed time plans.'>
                      <InfoOutlinedIcon className={classes.infoIcon} />
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <SimpleNumberField
                      allowNegative={false}
                      className={classes.inputField}
                      error={Boolean(formik.errors.historicalYos)}
                      inputProps={{
                        'data-testid': 'historical-years-of-vesting-text-field'
                      }}
                      isDisabled={!hasWritePermissions}
                      name='historicalYos'
                      onChange={(value: string) => {
                        formik.setFieldValue('historicalYos', value, true);

                        if (+value > 0) {
                          setHistoryYearsChecked(true);
                        }
                      }}
                      ref={historyYearsRef}
                      value={formik.values.historicalYos.toString()}
                    />
                  </Grid>
                </Grid>
                <Grid className={classes.gridSpacing} container>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={totalYearsChecked}
                          className={classes.checkbox}
                          color={terminationChecked ? 'default' : 'primary'}
                          data-testid='total-years-of-vesting-checkbox'
                          disabled={
                            !hasWritePermissions ||
                            disableYearsOfService ||
                            (props.isEditing && !totalYearsChecked)
                          }
                          id='yearsOfVestingCheckbox'
                          inputRef={totalBoxRef}
                          name='yearsOfVestingCheckbox'
                          onChange={(e, checked) => {
                            setTotalYearsChecked(checked);

                            if (checked) {
                              formik.setFieldValue(
                                'yearsOfVesting',
                                formik.initialValues.yearsOfVesting
                              );
                              props.setIsEditing(true);
                            } else {
                              formik.setFieldValue(
                                'yearsOfVesting',
                                EMPTY_FIELD_PLACEHOLDER
                              );
                              props.setIsEditing(false);
                            }
                          }}
                          value={totalYearsChecked}
                        />
                      }
                      label='Total Years of Vesting'
                    />
                    <Tooltip
                      data-testid='total-years-of-vesting-tooltip'
                      title='Total Years of Service are the number of years the saver worked in total. 
                      This number is a complete override for vesting purposes.
                      Note that this generally does not affect elapsed time plans.'>
                      <InfoOutlinedIcon className={classes.infoIcon} />
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <SimpleNumberField
                      allowNegative={false}
                      className={classes.inputField}
                      error={Boolean(formik.errors.yearsOfVesting)}
                      inputProps={{
                        'data-testid': 'total-years-of-vesting-text-field'
                      }}
                      isDisabled={
                        !hasWritePermissions ||
                        disableYearsOfService ||
                        (props.isEditing && !totalYearsChecked)
                      }
                      name='yearsOfVesting'
                      onChange={(value: string) => {
                        formik.setFieldValue('yearsOfVesting', value, true);

                        if (value !== EMPTY_FIELD_PLACEHOLDER) {
                          setTotalYearsChecked(true);
                        }

                        if (totalYearsChecked) {
                          props.setIsEditing(true);
                        } else {
                          props.setIsEditing(false);
                        }
                      }}
                      ref={totalYearsRef}
                      value={formik.values.yearsOfVesting?.toString()}
                    />
                  </Grid>
                </Grid>
                <Typography
                  className={classes.errorText}
                  data-testid='historical-yos-error-message'>
                  {formik.errors?.historicalYos || ''}
                </Typography>

                <Typography
                  className={classes.errorText}
                  data-testid='years-of-vesting-error-message'>
                  {formik.errors?.yearsOfVesting || ''}
                </Typography>

                <AccessControl
                  requires={[FeatureLevelPermissions.WRITE_VESTING]}>
                  <Grid>
                    <Typography className={classes.footer}>
                      After saving, click “Recalculate” to trigger an update of
                      vesting information at this time. Otherwise, the vested
                      balance will update the following day.
                    </Typography>
                    <Button
                      className={classes.button}
                      data-testid='vesting-overview-save-btn'
                      disabled={
                        props.isLoading ||
                        !hasWritePermissions ||
                        !hasChanges ||
                        Boolean(formik.errors.historicalYos) ||
                        Boolean(formik.errors.yearsOfVesting)
                      }
                      onClick={() => {
                        if (props.psaData?.psaId) {
                          const body = {
                            ...vestingYearsData,
                            ...formik.values,
                            psaId: props.psaData?.psaId
                          };

                          if (body.yearsOfVesting === EMPTY_FIELD_PLACEHOLDER) {
                            delete body.yearsOfVesting;
                          }

                          if (hasWritePermissions) {
                            props.openEditNotesModal(
                              props.vestingNotesQuery.notesQuery,
                              props.vestingNotesQuery.notesMutation,
                              () => {
                                overrideVestingYearsQuery.mutate(body);
                                if (Number(formik.values.historicalYos) === 0) {
                                  setHistoryYearsChecked(false);
                                } else {
                                  setHistoryYearsChecked(true);
                                }

                                if (
                                  formik.values.yearsOfVesting ===
                                  EMPTY_FIELD_PLACEHOLDER
                                ) {
                                  setTotalYearsChecked(false);
                                } else {
                                  setTotalYearsChecked(true);
                                }
                                props.setIsEditing(false);
                              }
                            );
                          }
                        }
                      }}
                      role='button'
                      type='submit'>
                      Save
                    </Button>
                    {hasChanges && (
                      <Button
                        className={classes.button}
                        data-testid='vesting-overview-reset-btn'
                        onClick={() => {
                          formik.setValues(formik.initialValues);
                          setHistoryYearsChecked(
                            vestingYearsData.historicalYos !== 0
                          );
                          setTotalYearsChecked(
                            vestingYearsData.yearsOfVesting !==
                              EMPTY_FIELD_PLACEHOLDER
                          );
                          setTerminationChecked(
                            vestingYearsData.partialPlanTermination
                          );
                          props.setIsEditing(false);
                        }}>
                        Reset Changes
                      </Button>
                    )}
                  </Grid>
                </AccessControl>
              </FormControl>
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
};

ParticipantVestingOverviewCard.defaultProps = {
  isLoading: false,
  openEditNotesModal: () => {
    return;
  }
};
export default ParticipantVestingOverviewCard;
