import Constants from '@/consts/planDesign.constants';
import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class ProfitSharingType {
  options: string[];

  output: string | undefined;

  disabled: boolean;

  convertToApiState = (
    option: string | undefined
  ): string | null | undefined => {
    return Constants.profitSharingTypes.find(type => type.option === option)
      ?.value;
  };

  setDisabled = (allowEmployerProfitSharing: string): void => {
    this.disabled = !Helper.convertFromAllowDontAllowToBoolean(
      allowEmployerProfitSharing
    );
  };

  setOptions = (allowEmployerProfitSharing: string): void => {
    this.options = !Helper.convertFromAllowDontAllowToBoolean(
      allowEmployerProfitSharing
    )
      ? Constants.profitSharingTypes.map(({ option }) => option)
      : Constants.profitSharingTypes
          .filter(option => !!option.value)
          .map(({ option }) => option);
  };

  constructor(props: Record<string, any>) {
    const value = props.profitSharingStrategy || null;

    this.output = Constants.profitSharingTypes.find(
      type => type.value === value
    )?.option;

    this.disabled = !props.allowEmployerProfitSharing;

    this.setOptions(
      Helper.convertFromBooleanToAllowDontAllow(
        props.allowEmployerProfitSharing
      )
    );
  }
}

export default ProfitSharingType;
