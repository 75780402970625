export const ManagedAccountFields = {
  ACTUAL_ASSET_CLASS: 'actualAssetClass' as const,
  EXPENSE_RATIO: 'expenseRatio' as const,
  RECOMMENDED_ASSET_CLASS: 'recommendedAssetClass' as const,
  SECURITY: 'security' as const
};

export interface ManagedAccountRow {
  maxTickerLength: number;
  id: string;
  [ManagedAccountFields.RECOMMENDED_ASSET_CLASS]: string;
  [ManagedAccountFields.SECURITY]: {
    id: string;
    cusip: string;
    fundName: string;
  };
  [ManagedAccountFields.ACTUAL_ASSET_CLASS]: string;
  [ManagedAccountFields.EXPENSE_RATIO]: string;
}
