import { DividendDeclarationOverallStatus } from './DividendDeclarationOverallStatus.model';

export const dividendStatusColorMap: Record<
  DividendDeclarationOverallStatus,
  'info' | 'success' | 'warning' | 'neutral'
> = {
  failed: 'warning',
  finished: 'success',
  paid: 'info',
  pending: 'info',
  reversed: 'warning',
  varies: 'info'
};
