import AccessControl from '@/components/access-control/AccessControl.component';
import Badge from '@/components/badge';
import CollapsibleTable from '@/components/collapsible-table';
import CopyToClipboard from '@/components/copy-to-clipboard';
import LinearLoading from '@/components/linear-loading';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { HideContribution } from '@/routes/plans/plan-contribution-submission';
import ContributionService from '@/services/Contribution.service';
import formatters from '@/utils/Formatters';
import {
  Card,
  CardContent,
  Unstable_Grid2 as Grid,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';

import clsx from 'clsx';
import dayjs from 'dayjs';
import Decimal from 'decimal.js';
import { last } from 'lodash';
import React, { FC, useCallback, useMemo, useState } from 'react';

import PlanContributionLostGainTableCell from './PlanContributionLostGainTableCell.component';

type PlanContributionLostGainProps = {
  planId: string;
  ucid: string;
  search: string;
  sponsorId: string;
};

const useStyles = makeStyles(theme => ({
  border: {
    borderRight: `1px solid ${theme.palette.grey[300]}`
  },
  tableFooterText: {
    fontWeight: theme.typography.fontWeightBold
  },
  tableGroupedHeader: {
    paddingLeft: 0
  },
  tableGroupedHeaderText: {
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    fontWeight: theme.typography.fontWeightMedium
  },
  textGray: {
    color: theme.palette.grey[500]
  },
  textRight: {
    textAlign: 'right'
  }
}));

const PlanContributionLostGain: FC<PlanContributionLostGainProps> = props => {
  const { planId, ucid, search, sponsorId } = props;

  const classes = useStyles();
  const snackbar = useSnackbar();

  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const contribution = useQuery(
    [
      ContributionService.getContributionDetails.name,
      planId,
      ucid,
      search,
      pageNumber,
      rowsPerPage
    ],
    async () => {
      return ContributionService.getContributionDetails({
        pageNumber,
        pageSize: rowsPerPage,
        planId,
        search,
        sponsorId,
        ucid
      });
    },
    {
      enabled: !!ucid,
      keepPreviousData: true
    }
  );

  const rows = useMemo(
    () =>
      contribution.data?.participantData?.map(participant => ({
        contributionTotal: participant.contribution,
        employeeContributionTotal: Decimal.sum(
          participant.accountMeta?.at ?? 0,
          participant.accountMeta?.rc ?? 0,
          participant.accountMeta?.sd ?? 0
        ).toNumber(),
        employerContributionTotal: Decimal.sum(
          participant.accountMeta?.em ?? 0,
          participant.accountMeta?.sh ?? 0,
          participant.accountMeta?.ps ?? 0,
          participant.accountMeta?.qc ?? 0,
          participant.accountMeta?.qm ?? 0
        ).toNumber(),
        id: participant.participantId,
        name: `${participant.lastName}, ${participant.firstName}`,
        ...participant.accountMeta
      })) ?? [],
    [contribution.data]
  );

  const pngDate = useMemo(() => {
    const date = last(contribution?.data?.pngStatuses ?? [])?.createdAt;

    return date ? dayjs(date).format('MM/DD/YYYY HH:mm') : undefined;
  }, [contribution?.data?.pngStatuses]);

  const pngStatus = useMemo(
    () => last(contribution?.data?.pngStatuses ?? [])?.status,
    [contribution?.data?.pngStatuses]
  );

  const isErrorStatus = useMemo(
    () =>
      [
        'ACH_REQUEST_FAILED',
        'ACH_VALIDATION_ERROR',
        'REVERSAL_PROCESSING_ERROR',
        'FUNDING_ERROR',
        'TRADE_REQUEST_FAILED',
        'TRADE_REQUEST_VALIDATION_ERROR',
        'TRADE_REQUEST_PROCESSING_ERROR',
        'TRADE_REQUEST_PROCESSING_ABORTED',
        'DELIVERY_FAILED',
        'REVERSAL_DELIVERY_FAILED'
      ].includes(pngStatus),
    [pngStatus]
  );

  const isHideAvailable = useMemo(
    () =>
      ['COMPLETE', 'COMPLETED'].includes(
        contribution.data?.status?.toUpperCase()
      ) &&
      contribution.data?.recordkeeper === 'Vestwell Sub-Accounting Platform',
    [contribution.data]
  );

  const onHideSuccess = useCallback(() => {
    snackbar.showSnackbar({
      message: 'Lost Gains successfully hidden!',
      severity: 'success'
    });

    window.location.reload();
  }, []);

  return (
    <>
      {contribution.isFetching && <LinearLoading />}

      <Card data-testid={`lost-gains-${ucid}`} elevation={0} variant='outlined'>
        <CardContent>
          <Grid container justifyContent='space-between' my={3}>
            <Grid alignItems='center' container display='flex'>
              <Typography mr={2} variant='h4'>
                Lost Gain
              </Typography>
              <Badge
                color={isErrorStatus ? 'error' : 'success'}
                data-testid='lost-gain-png-status'>
                {pngStatus ?? '--'}
              </Badge>
              <Typography
                color={grey[700]}
                data-testid='lost-gain-png-date'
                ml={2}>
                {pngDate ?? '--'}
              </Typography>
            </Grid>
            <Grid>
              {isHideAvailable && (
                <AccessControl
                  requires={[FeatureLevelPermissions.WRITE_CONTRIBUTION_HIDE]}>
                  <HideContribution
                    flowSubtype={contribution.data?.key?.flowSubtype}
                    onSuccess={onHideSuccess}
                    sponsorPlanId={+planId}
                    ucid={ucid}
                  />
                </AccessControl>
              )}
            </Grid>
          </Grid>
          <Grid container direction='column'>
            <Grid alignItems='center' container display='flex'>
              <Grid xs={2}>
                <Typography color={grey[700]}>Sponsor Status</Typography>
              </Grid>
              <Badge color='primary' data-testid='lost-gain-sponsor-status'>
                {contribution.data?.status}
              </Badge>
            </Grid>
            <Grid alignItems='center' container>
              <Grid xs={2}>
                <Typography color={grey[700]}>Lost Gain ID</Typography>
              </Grid>
              <Grid alignItems='center' display='flex'>
                <Typography data-testid='lost-gain-ucid'>
                  {contribution.data?.ucid}
                </Typography>
                <CopyToClipboard
                  copyName='Lost Gain ID'
                  copyValue={contribution.data?.ucid}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CollapsibleTable
          cellComponent={PlanContributionLostGainTableCell}
          columns={[
            { field: 'name', headerName: 'Employee', width: 400 },
            {
              cellClassName: clsx(
                classes.border,
                classes.textRight,
                classes.textGray
              ),
              field: 'contributionTotal',
              headerName: 'Subtotal',
              width: 130
            },
            {
              cellClassName: classes.textRight,
              field: 'sd',
              headerName: 'Pre-Tax',
              width: 130
            },
            {
              cellClassName: classes.textRight,
              field: 'rc',
              headerName: 'Roth',
              width: 130
            },
            {
              cellClassName: classes.textRight,
              field: 'at',
              headerName: 'After-Tax',
              width: 130
            },
            {
              cellClassName: clsx(
                classes.border,
                classes.textRight,
                classes.textGray
              ),
              field: 'employeeContributionTotal',
              headerName: 'Subtotal',
              width: 130
            },
            {
              cellClassName: classes.textRight,
              field: 'sh',
              headerName: 'S. Harbor',
              width: 130
            },
            {
              cellClassName: classes.textRight,
              field: 'em',
              headerName: 'D. Match',
              width: 130
            },
            {
              cellClassName: classes.textRight,
              field: 'ps',
              headerName: 'P. Sharing',
              width: 130
            },
            {
              cellClassName: classes.textRight,
              field: 'qc',
              headerName: 'QNEC',
              width: 130
            },
            {
              cellClassName: classes.textRight,
              field: 'qm',
              headerName: 'QMAC',
              width: 130
            },
            {
              cellClassName: clsx(
                classes.border,
                classes.textRight,
                classes.textGray
              ),
              field: 'employerContributionTotal',
              headerName: 'Subtotal',
              width: 130
            },
            {
              cellClassName: classes.textRight,
              field: 'ln',
              headerName: 'Loan',
              width: 130
            }
          ]}
          footerComponent={
            <TableRow>
              <TableCell>
                <Typography className={classes.tableFooterText}>
                  Submitted Amount
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  align='right'
                  className={classes.tableFooterText}
                  color='primary'>
                  {formatters.formatDollars(contribution?.data?.totals?.total)}
                </Typography>
              </TableCell>
            </TableRow>
          }
          groupedHeader={
            <TableRow>
              <TableCell colSpan={2} />
              <TableCell
                align='center'
                className={classes.tableGroupedHeader}
                colSpan={4}>
                <Typography
                  className={classes.tableGroupedHeaderText}
                  color='GrayText'
                  variant='body2'>
                  Employee Contributions
                </Typography>
              </TableCell>
              <TableCell
                align='center'
                className={classes.tableGroupedHeader}
                colSpan={6}>
                <Typography
                  className={classes.tableGroupedHeaderText}
                  color='GrayText'
                  variant='body2'>
                  Employer Contributions
                </Typography>
              </TableCell>
              <TableCell className={classes.tableGroupedHeader} colSpan={1}>
                <span className={classes.tableGroupedHeaderText} />
              </TableCell>
            </TableRow>
          }
          pager={{
            metaCount: contribution?.data?.participantsCount,
            onPageNumberChanged: (zeroIndexedPageNumber: number) =>
              setPageNumber(zeroIndexedPageNumber + 1),
            onRowsPerPageChanged: (newRowsPerPage: number) =>
              setRowsPerPage(newRowsPerPage),
            pageNumber: pageNumber - 1,
            rowsPerPage
          }}
          tableData={rows}
        />
      </Card>
    </>
  );
};

export default PlanContributionLostGain;
