/* eslint-disable sort-keys-plus/sort-keys */
import AccessControl from '@/components/access-control/AccessControl.component';
import useHasPermissions from '@/components/access-control/useHasPermissions.hook';
import { CardPlaceholder } from '@/components/card';
import CardIconInfoField from '@/components/card-icon-info-field/CardIconInfoField.component';
import {
  FormContainer,
  FormDialog
} from '@/components/form-dialog/FormDialog.component';
import LinearLoading from '@/components/linear-loading';
import SimpleDropdown from '@/components/simple-dropdown';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { PlanService } from '@/services/Plan.service';
import {
  EmailOutlined,
  PersonOutline,
  PhoneOutlined
} from '@mui/icons-material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Button, Grid, Stack } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';

import { Field } from 'formik';
import { TextField } from 'formik-mui';
import React, { useEffect, useMemo, useState } from 'react';
import { useToggle } from 'react-use';
import { PatchTpaContact, PostTpaContact, TpaContact } from 'scala-sdk';
import * as yup from 'yup';

type TpaContactsTabProps = {
  sponsorPlanId: number;
  tpaId: number;
};

const contactTypes = [
  'Implementation Contact',
  'Ongoing Contact',
  'Distribution Contact',
  'Other'
];

export const TpaContactsTab: React.FC<TpaContactsTabProps> = props => {
  const [selectedTpaContact, setSelectedTpaContact] = useState<
    TpaContact | undefined
  >();
  const [selectedTpaContactEmail, setSelectedTpaContactEmail] = useState('');
  const [formDialogOpen, toggleFormDialogOpen] = useToggle(false);
  const [inviteDialogOpen, toggleInviteDialogOpen] = useToggle(false);
  const { showSnackbar } = useSnackbar();

  const perms = useHasPermissions({
    requires: [FeatureLevelPermissions.WRITE_COMPANY_TPA_CONTACT]
  });

  const tpaContactsQuery = useQuery(
    ['PlanService.getTpaContacts', props.sponsorPlanId],
    () => PlanService.getTpaContacts(+props.sponsorPlanId),
    {
      enabled: Boolean(props.sponsorPlanId)
    }
  );

  const addTpaContactMutation = useMutation(
    ['PlanService.createTpaContact', props.sponsorPlanId],
    (data: PostTpaContact) =>
      PlanService.createTpaContact(+props.sponsorPlanId, data),
    {
      onError: async () => {
        showSnackbar({
          message: `TPA Contact could not be added`,
          severity: 'error'
        });
      },
      onSuccess: async (_response, data) => {
        setSelectedTpaContactEmail(data.email);
        tpaContactsQuery.refetch();
        toggleFormDialogOpen();
        showSnackbar({
          message: `TPA Contact added successfully`,
          severity: 'success'
        });
      }
    }
  );

  const editTpaContactMutation = useMutation(
    ['PlanService.updateTpaContact', props.sponsorPlanId],
    (config: { tpaContactId: number; data: PatchTpaContact }) =>
      PlanService.updateTpaContact(
        +props.sponsorPlanId,
        config.tpaContactId,
        config.data
      ),
    {
      onError: async () => {
        showSnackbar({
          message: `TPA Contact could not be edited`,
          severity: 'error'
        });
      },
      onSuccess: async (_response, variables) => {
        if (selectedTpaContact.email !== variables.data.email) {
          setSelectedTpaContactEmail(variables.data.email);
        }
        setSelectedTpaContact(undefined);
        tpaContactsQuery.refetch();
        toggleFormDialogOpen();
        showSnackbar({
          message: `TPA Contact edited successfully`,
          severity: 'success'
        });
      }
    }
  );

  const deleteTpaContactMutation = useMutation(
    ['PlanService.updateTpaContact', props.sponsorPlanId],
    (data: { tpaContactId: number }) =>
      PlanService.deleteTpaContact(+props.sponsorPlanId, data.tpaContactId),
    {
      onError: async () => {
        showSnackbar({
          message: `TPA Contact could not be deleted`,
          severity: 'error'
        });
      },
      onSuccess: async () => {
        toggleInviteDialogOpen();
        setSelectedTpaContact(undefined);
        tpaContactsQuery.refetch();
        showSnackbar({
          message: `TPA Contact deleted successfully`,
          severity: 'success'
        });
      }
    }
  );

  const validationSchema = useMemo(
    () =>
      yup.object({
        name: yup.string().required('Name is required'),
        email: yup.string().email().required('Email is required'),
        phoneNumber: yup
          .string()
          .optional()
          .matches(/^(?:\d{3})-\d{3}-\d{4}$/, {
            message: 'Invalid format, please use: XXX-XXX-XXXX'
          }),
        contactType: yup
          .string()
          .required('Contact Type is required')
          .test(
            'type-exists',
            'An implementation contact already exist for this plan',
            value => {
              return (
                selectedTpaContact?.contactType === value ||
                !tpaContactsQuery.data.some(
                  tpaContact => tpaContact.contactType === value
                )
              );
            }
          )
      }),
    [selectedTpaContact, tpaContactsQuery.data]
  );

  useEffect(() => {
    if (selectedTpaContact) {
      toggleFormDialogOpen();
    }
  }, [selectedTpaContact]);

  useEffect(() => {
    if (selectedTpaContactEmail) {
      toggleInviteDialogOpen();
    }
  }, [selectedTpaContactEmail]);

  return (
    <>
      <FormDialog
        data-testid='invite-tpa-contact-dialog'
        fullWidth
        maxWidth='sm'
        onClose={() => {
          toggleInviteDialogOpen();
          setSelectedTpaContactEmail('');
        }}
        onSubmit={() => {
          toggleInviteDialogOpen();
          setSelectedTpaContactEmail('');
          window.open(`${window.location.origin}/tpa/${props.tpaId}`, '_blank');
        }}
        open={inviteDialogOpen}
        title='TPA Contact'>
        <FormContainer cancelButtonText='Skip' continueButtonText='INVITE'>
          <Stack spacing={2}>
            <span>
              Do you want to invite {selectedTpaContactEmail} to register as a
              user for the TPA Portal?
            </span>
            <span>
              If yes, please select <b>INVITE</b> below
            </span>
            <span>
              If no (or if user is already registered), please select{' '}
              <b>SKIP</b> below
            </span>
          </Stack>
        </FormContainer>
      </FormDialog>
      <FormDialog
        data-testid='tpa-contact-dialog'
        fullWidth
        initialValues={{ ...selectedTpaContact }}
        maxWidth='sm'
        onClose={() => {
          toggleFormDialogOpen();
          setSelectedTpaContact(undefined);
        }}
        onSubmit={async data => {
          selectedTpaContact
            ? editTpaContactMutation.mutateAsync({
                tpaContactId: selectedTpaContact.id,
                data
              })
            : addTpaContactMutation.mutateAsync(data);
        }}
        open={formDialogOpen}
        title='TPA Contact'
        validationSchema={validationSchema}>
        <FormContainer continueButtonText='Save'>
          <Field
            component={TextField}
            fullWidth
            inputProps={{ 'data-testid': 'name-input' }}
            label='Name'
            name='name'
          />
          <Field
            component={TextField}
            fullWidth
            inputProps={{ 'data-testid': 'email-input' }}
            label='Email'
            name='email'
            type='email'
          />
          <Field
            component={TextField}
            fullWidth
            inputProps={{ 'data-testid': 'phoneNumber-input' }}
            label='Phone Number'
            name='phoneNumber'
          />
          <SimpleDropdown
            fieldId='contactType'
            fieldName='Contact Type'
            fieldValues={contactTypes}
          />
        </FormContainer>
      </FormDialog>
      <Stack data-testid='tpa-contact-tab-content' flexGrow={1} spacing={1}>
        <LinearLoading fadeIn={tpaContactsQuery.isFetching} />
        {!tpaContactsQuery.data?.length ? (
          <CardPlaceholder
            actionLabel={perms.isAllowed ? 'Add' : ''}
            data-testid='no-tpa-contact-placeholder'
            icon={<PersonOutlineIcon fontSize='inherit' />}
            onClickAction={toggleFormDialogOpen}
            style={{ flex: 1 }}
            subtitle='No TPA Contact'
          />
        ) : (
          <Stack direction='row'>
            <Grid container data-testid='tpa-contacts-container'>
              {tpaContactsQuery.data.map(tpaContact => (
                <Grid
                  data-testid={`tpa-contact-${tpaContact.id}`}
                  item
                  key={tpaContact.id}
                  my={2}
                  xs={4}>
                  <CardIconInfoField
                    icon={<PersonOutline />}
                    subtitle={tpaContact.contactType}
                    sx={{ mb: 1 }}
                    title={tpaContact.name}
                    tooltip='Name'
                  />
                  <CardIconInfoField
                    icon={<EmailOutlined />}
                    subtitle={tpaContact.email}
                    tooltip='Email'
                  />
                  <CardIconInfoField
                    icon={<PhoneOutlined />}
                    subtitle={tpaContact.phoneNumber || EMPTY_FIELD_PLACEHOLDER}
                    tooltip='Phone Number'
                  />
                  <AccessControl
                    requires={[
                      FeatureLevelPermissions.WRITE_COMPANY_TPA_CONTACT
                    ]}>
                    <Grid item ml={3.5}>
                      <Button
                        data-testid={`tpa-contact-${tpaContact.id}-edit-button`}
                        onClick={() => setSelectedTpaContact(tpaContact)}
                        variant='text'>
                        EDIT
                      </Button>

                      <Button
                        data-testid={`tpa-contact-${tpaContact.id}-delete-button`}
                        onClick={() =>
                          deleteTpaContactMutation.mutateAsync({
                            tpaContactId: tpaContact.id
                          })
                        }
                        variant='text'>
                        Delete
                      </Button>
                    </Grid>
                  </AccessControl>
                </Grid>
              ))}
            </Grid>
            <div>
              <AccessControl
                requires={[FeatureLevelPermissions.WRITE_COMPANY_TPA_CONTACT]}>
                <Button
                  data-testid='tpa-contacts-add-button'
                  onClick={toggleFormDialogOpen}
                  variant='text'>
                  Add
                </Button>
              </AccessControl>
            </div>
          </Stack>
        )}
      </Stack>
    </>
  );
};
