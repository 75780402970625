import Badge from '@/components/badge';
import Card, {
  CardContent,
  CardHeader,
  CardPlaceholder
} from '@/components/card';
import CusipTickerSearch from '@/components/cusip-ticker-search/CusipTickerSearch';
import DataTable, {
  DataTableBadgeCell,
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import DatePicker from '@/components/date-picker/DatePicker';
import DownloadCSVButton from '@/components/download-csv-button/DownloadCSVButton.component';
import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import OpenInNewIcon from '@/components/icon/OpenInNewIcon';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { BreakageStatusColorMap } from '@/models/ops/recon/BreakageStatusColorMap.model';
import {
  ReconExceptionApiQueryServiceRequest,
  ReconExceptionDto
} from '@/models/ops/recon/ReconException.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import AuthZService from '@/services/AuthZ.service';
import ReconExceptionService from '@/services/ops/recon-exceptions/ReconException.service';
import { userService } from '@/services/User.service';
import { numericComparator } from '@/utils/Comparators';
import formatters from '@/utils/Formatters';
import { useUrlStateParams } from '@/utils/Url';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { BreakageDataType } from '@vestwell-sub-accounting/models/recon/BreakageDataType';
import { BreakageProcess } from '@vestwell-sub-accounting/models/recon/BreakageProcess';
import { BreakageStatus } from '@vestwell-sub-accounting/models/recon/BreakageStatus';

import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import dayjs from 'dayjs';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

type SearchFormValues = {
  cusipOrSymbol: string;
  id: string;
  parentAccountId: string;
  status: BreakageStatus[];
  dataType: BreakageDataType | '';
  process: BreakageProcess | '';
  lockAccountFlag: 'true' | 'false' | '';
  startDate?: string;
  endDate: string;
};

type SearchReconExceptionProps = {
  preventSearchInUrl?: boolean;
  parentAccountId?: string;
  planId?: string;
};

export const SearchReconExceptions: React.FC<
  SearchReconExceptionProps
> = props => {
  const { showSnackbar } = useSnackbar();

  const gridRef = useRef<AgGridReact>(null);

  const defaultStatuses = [BreakageStatus.Open, BreakageStatus.InProcess];

  const nonUrlDefaultQueryParams: ReconExceptionApiQueryServiceRequest = {
    endDate: dayjs().format('YYYY-MM-DD'),
    status: [...defaultStatuses]
  };

  const [isValidating, setIsValidating] = useState(false);
  const [isCusipValid, setIsCusipValid] = useState(true);

  const [query, setQuery] =
    useUrlStateParams<ReconExceptionApiQueryServiceRequest>(
      nonUrlDefaultQueryParams,
      'query',
      value => {
        if (props.preventSearchInUrl) {
          return '';
        }
        // remove any values that are the same as the default
        const cleanedQuery =
          formatters.objectDiff<ReconExceptionApiQueryServiceRequest>(
            value,
            nonUrlDefaultQueryParams
          );
        return JSON.stringify(cleanedQuery) !== '{}' // only add query if it's not empty
          ? encodeURIComponent(JSON.stringify(cleanedQuery))
          : '';
      },
      value => {
        try {
          const urlQuery = JSON.parse(decodeURIComponent(value));
          return {
            ...nonUrlDefaultQueryParams,
            ...urlQuery
          };
        } catch {
          return nonUrlDefaultQueryParams;
        }
      }
    );
  const [page, setPage] = useUrlStateParams(
    1,
    'page',
    value => (!props.preventSearchInUrl ? String(value) : ''),
    value => (!isNaN(Number(value)) ? Number(value) : 1)
  );
  const [pageSize, setPageSize] = useUrlStateParams(
    25,
    'pageSize',
    value => (!props.preventSearchInUrl ? String(value) : ''),
    value => (!isNaN(Number(value)) ? Number(value) : 25)
  );
  const [orderBy, setOrderBy] = useUrlStateParams<
    keyof ReconExceptionDto | undefined
  >(
    undefined,
    'orderBy',
    value => (!props.preventSearchInUrl ? String(value) : ''),
    value => (value ? (value as keyof ReconExceptionDto) : undefined)
  );
  const [orderByDirection, setOrderByDirection] = useUrlStateParams<
    'asc' | 'desc' | undefined
  >(
    undefined,
    'orderByDirection',
    value => (!props.preventSearchInUrl ? String(value) : ''),
    value => (value === 'asc' || value === 'desc' ? value : undefined)
  );

  const getVestwellStaffQuery = useQuery(
    ['AuthZService.getVestwellStaff'],
    async () => {
      const staff = await AuthZService.getVestwellStaff();
      return AuthZService.formatVestwellStaffList(staff);
    }
  );

  useEffect(() => {
    // runs after the vestwell staff have been fetched (doesn't use onSuccess because that doesn't fire on cache hit)
    // if an assignee is set in the query (likely from the url on init), set the assignee state from the matching vestwell staff
    if (assignee?.id) {
      const matchingStaff = getVestwellStaffQuery.data?.find(
        staff => staff.userId === assignee.id
      );
      if (matchingStaff?.userId) {
        setAssignee({
          id: matchingStaff.userId,
          label: matchingStaff.label
        });
      }
    }
    setAssignedToMeChecked(myUserId === assignee?.id);
  }, [getVestwellStaffQuery.data]);

  const fetchReconExceptions = (
    replacePage = page,
    replacePageSize = pageSize
  ) => {
    const queryWithPagination = {
      ...query,
      page: replacePage,
      pageSize: replacePageSize
    };
    if (orderBy) {
      queryWithPagination.orderBy = orderBy;
    }
    if (orderByDirection) {
      queryWithPagination.orderByDirection = orderByDirection;
    }
    if (props.planId) {
      // used on plan details page available under super omni parent accounts
      queryWithPagination.planId = props.planId;
    } else if (props.parentAccountId) {
      // when embedded on the Account Details, it will look for exceptions under current parent account
      queryWithPagination.parentAccountId = props.parentAccountId;
    }
    return ReconExceptionService.search(queryWithPagination);
  };

  const myUserId = userService.getUser()?.sub;
  const [assignee, setAssignee] = useState<{
    label: string;
    id: string;
  } | null>(
    query?.assignee ? { id: query.assignee, label: query.assignee } : null
  );
  const [assigneeInputValue, setAssigneeInputValue] = useState('');
  const [assignedToMeChecked, setAssignedToMeChecked] = useState(
    myUserId === assignee?.id
  );

  const searchReconExceptionsQuery = useQuery(
    [
      'ReconExceptionService.search',
      query,
      page,
      pageSize,
      orderBy,
      orderByDirection,
      props.parentAccountId
    ],
    () => fetchReconExceptions(),
    {
      keepPreviousData: true,
      onError: (err: any) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Exception search failed: ${message}`,
          severity: 'error'
        });
      },
      staleTime: Infinity
    }
  );

  const hasRequiredPermissions = userService.hasPermission(
    FeatureLevelPermissions.READ_SUBA_RECON
  );

  if (!hasRequiredPermissions) {
    return redirectToErrorPage(new Error('403'));
  }

  const handleSubmit = (formData: SearchFormValues) => {
    const newQuery = {} as ReconExceptionApiQueryServiceRequest;

    // the api will look up the cusip matching the provided cusipOrSymbol
    // but maybe we will eventually move the check to the frontend for some autocomplete
    // this would be a good spot to do that check
    if (formData.cusipOrSymbol) {
      newQuery.cusip = formData.cusipOrSymbol;
    }
    if (formData.id) {
      newQuery.id = Number(formData.id);
    }
    if (formData.parentAccountId) {
      newQuery.parentAccountId = formData.parentAccountId;
    }
    if (formData.status) {
      newQuery.status = formData.status;
    }
    if (formData.dataType) {
      newQuery.dataType = formData.dataType;
    }
    if (formData.process) {
      newQuery.process = formData.process;
    }
    if (
      formData.lockAccountFlag === 'true' ||
      formData.lockAccountFlag === 'false'
    ) {
      newQuery.lockAccountFlag = formData.lockAccountFlag === 'true';
    }
    if (formData.startDate) {
      newQuery.startDate = formData.startDate;
    }
    if (formData.endDate) {
      newQuery.endDate = formData.endDate;
    }

    // autocomplete doesn't really work with formik, so we have to track the assignee filter state separate from the formData
    if (assignee?.id) {
      newQuery.assignee = assignee.id;
      setAssignedToMeChecked(myUserId === assignee?.id);
    } else {
      setAssignedToMeChecked(false);
    }

    setPage(1);
    setQuery(newQuery);

    // always refetch when they submit the search form in case they made edits to exceptions in another browser tab
    searchReconExceptionsQuery.refetch();
  };

  /**
   * Used to convert the query object into the recognized props and value formats of the filter form.
   * Specifically the incoming query from the url on page load
   */
  const queryToInputValues = (
    incomingQuery: ReconExceptionApiQueryServiceRequest
  ): Partial<SearchFormValues> => {
    const inputValues: Partial<SearchFormValues> = {};
    if (!incomingQuery) {
      return inputValues;
    }
    if (incomingQuery.cusip) {
      inputValues.cusipOrSymbol = incomingQuery.cusip;
    }
    if (incomingQuery.id) {
      inputValues.id = String(incomingQuery.id);
    }
    if (incomingQuery.parentAccountId) {
      inputValues.parentAccountId = incomingQuery.parentAccountId;
    }
    if (incomingQuery.status) {
      inputValues.status = incomingQuery.status;
    }
    if (incomingQuery.dataType) {
      inputValues.dataType = incomingQuery.dataType;
    }
    if (incomingQuery.process) {
      inputValues.process = incomingQuery.process;
    }
    if (incomingQuery.lockAccountFlag !== undefined) {
      inputValues.lockAccountFlag = incomingQuery.lockAccountFlag
        ? 'true'
        : 'false';
    }
    if (incomingQuery.startDate) {
      inputValues.startDate = incomingQuery.startDate;
    }
    if (incomingQuery.endDate) {
      inputValues.endDate = incomingQuery.endDate;
    }
    return inputValues;
  };

  // these columns will be displayed when the component
  // is embedded on a parent account details "recon" tab
  const parentAccountColumnDefs: ColDef[] = [
    {
      cellRenderer: (cellData: { data: ReconExceptionDto }) => {
        return (
          <Link
            data-testid={`recon-exception-link-${cellData.data.id}`}
            href={`/ops/recon-exceptions/${cellData.data.id}`}
            target='_blank'>
            {cellData.data.id}
            <Box
              component='span'
              sx={{
                lineHeight: 1,
                pl: 0.5
              }}>
              <OpenInNewIcon sx={{ fontSize: 14 }} />
            </Box>
          </Link>
        );
      },
      field: 'id',
      headerName: 'ID',
      minWidth: 75,
      pinned: 'left',
      sortable: true
    },
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: ReconExceptionDto }) => {
        // fetch friendly display name for status
        const displayStatus = formatters.getValueKey(
          BreakageStatus,
          cellData.data.status
        );

        return (
          <DataTableBadgeCell
            color={
              BreakageStatusColorMap[cellData.data.status] as
                | 'neutral'
                | 'success'
            }>
            {formatters.displayCase(displayStatus)}
          </DataTableBadgeCell>
        );
      },
      field: 'status',
      headerName: 'Status',
      minWidth: 175,
      sortable: true
    },
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: ReconExceptionDto }) => {
        // fetch friendly display name for data type
        const displayDataType = formatters.getValueKey(
          BreakageDataType,
          cellData.data.dataType
        );
        // fetch friendly display name for process
        const displayProcess = formatters.getValueKey(
          BreakageProcess,
          cellData.data.process
        );
        return (
          <DataTableStackCell
            primary={formatters.displayCase(displayDataType)}
            secondary={formatters.displayCase(displayProcess)}
          />
        );
      },
      field: 'dataType',
      headerName: 'Break Type',
      minWidth: 255,
      sortable: true
    },
    {
      cellRenderer: (cellData: { data: ReconExceptionDto }) => {
        return (
          <DataTableStackCell
            primary={
              formatters.formatSecurityName(
                cellData.data.security?.symbol,
                cellData.data.security?.cusip
              ) || '\u2014'
            }
          />
        );
      },
      field: 'cusip',
      headerName: 'Ticker',
      minWidth: 125,
      sortable: true
    },
    {
      field: 'exceptionDate',
      headerName: 'Exception Date',
      minWidth: 200,
      sortable: true,
      valueFormatter: ({ value }: { value: string }) =>
        formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY')
    },
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: ReconExceptionDto }) => {
        const name =
          cellData.data.parentAccount?.accountType === 'SuperOmnibus' ||
          cellData.data.parentAccount?.accountType === 'House'
            ? cellData.data.parentAccount?.accountName
            : cellData.data.parentAccount?.plan?.name;

        return (
          <DataTableStackCell
            icon={
              cellData.data.lockAccountFlag ? (
                <Stack
                  alignItems='center'
                  justifyContent='center'
                  sx={{
                    background: theme => theme.palette.warning.lightBg,
                    borderRadius: 100,
                    color: theme => theme.palette.warning.dark,
                    fontSize: 12,
                    p: 0.75
                  }}>
                  <AcUnitIcon fontSize='inherit' />
                </Stack>
              ) : undefined
            }
            primary={name || ''}
            secondary={`ID: ${cellData.data.parentAccountId}`}
          />
        );
      },
      field: 'parentAccountId',
      headerName: 'Parent Account',
      minWidth: 255,
      sortable: true
    },
    {
      cellRenderer: (cellData: { data: ReconExceptionDto }) => {
        if (!cellData.data.assignee) {
          return null;
        }
        const matchedAssignee = getVestwellStaffQuery.data?.find(
          staffUser => staffUser.userId === cellData.data.assignee
        );
        return (
          <DataTableStackCell
            primary={matchedAssignee?.label || cellData.data.assignee}
          />
        );
      },
      field: 'assignee',
      headerName: 'Assignee',
      maxWidth: 160,
      sortable: false
    }
  ];

  const standAloneColumnDefs: ColDef[] = [
    {
      cellRenderer: (cellData: { data: ReconExceptionDto }) => {
        return (
          <Link
            component={RouterLink}
            state={{
              search: window.location.search
            }}
            to={`/ops/recon-exceptions/${cellData.data.id}`}>
            {cellData.data.id}
          </Link>
        );
      },
      field: 'id',
      headerName: 'ID',
      minWidth: 75,
      pinned: 'left',
      sortable: true
    },
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: ReconExceptionDto }) => {
        // fetch friendly display name for status
        const displayStatus = formatters.getValueKey(
          BreakageStatus,
          cellData.data.status
        );

        return (
          <DataTableBadgeCell
            color={
              BreakageStatusColorMap[cellData.data.status] as
                | 'neutral'
                | 'success'
            }>
            {formatters.displayCase(displayStatus)}
          </DataTableBadgeCell>
        );
      },
      field: 'status',
      headerName: 'Status',
      minWidth: 175,
      sortable: true
    },
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: ReconExceptionDto }) => {
        const name =
          cellData.data.parentAccount?.accountType === 'SuperOmnibus' ||
          cellData.data.parentAccount?.accountType === 'House'
            ? cellData.data.parentAccount?.accountName
            : cellData.data.parentAccount?.plan?.name;

        return (
          <DataTableStackCell
            icon={
              cellData.data.lockAccountFlag ? (
                <Stack
                  alignItems='center'
                  justifyContent='center'
                  sx={{
                    background: theme => theme.palette.warning.lightBg,
                    borderRadius: 100,
                    color: theme => theme.palette.warning.dark,
                    fontSize: 12,
                    p: 0.75
                  }}>
                  <AcUnitIcon fontSize='inherit' />
                </Stack>
              ) : undefined
            }
            primary={name || ''}
            secondary={
              cellData.data.parentAccount?.parentAccountId
                ? `ID: ${cellData.data.parentAccount?.parentAccountId}`
                : ''
            }
          />
        );
      },
      field: 'parentAccountId',
      headerName: 'Parent Account',
      minWidth: 255,
      sortable: true
    },
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: ReconExceptionDto }) => {
        // fetch friendly display name for data type
        const displayDataType = formatters.getValueKey(
          BreakageDataType,
          cellData.data.dataType
        );
        // fetch friendly display name for process
        const displayProcess = formatters.getValueKey(
          BreakageProcess,
          cellData.data.process
        );
        return (
          <DataTableStackCell
            primary={formatters.displayCase(displayDataType)}
            secondary={formatters.displayCase(displayProcess)}
          />
        );
      },
      field: 'dataType',
      headerName: 'Break Type / Break Process',
      minWidth: 255,
      sortable: true
    },
    {
      cellRenderer: (cellData: { data: ReconExceptionDto }) => {
        return (
          <DataTableStackCell
            primary={
              formatters.formatSecurityName(
                cellData.data.security?.symbol,
                cellData.data.security?.cusip
              ) || '\u2014'
            }
            secondary={cellData.data.security?.description}
          />
        );
      },
      field: 'cusip',
      headerName: 'Security',
      minWidth: 125,
      sortable: true
    },
    {
      field: 'exceptionDate',
      headerName: 'Exception Date',
      minWidth: 200,
      sortable: true,
      valueFormatter: ({ value }: { value: string }) =>
        formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY')
    },
    {
      field: 'notes',
      headerName: 'Notes',
      maxWidth: 320,
      minWidth: 125,
      sortable: true,
      tooltipField: 'notes'
    },
    {
      comparator: numericComparator,
      field: 'parentValue',
      headerName: 'Parent Value',
      minWidth: 75,
      sortable: true,
      type: 'numericColumn',
      valueFormatter: ({ value }: { value: string }) =>
        value === null ? '' : formatters.formatDollars(value || 0, 3)
    },
    {
      comparator: numericComparator,
      field: 'comparisonValue',
      headerName: 'Comparison Value',
      minWidth: 75,
      sortable: true,
      type: 'numericColumn',
      valueFormatter: ({ value }: { value: string }) =>
        value === null ? '' : formatters.formatDollars(value || 0, 3)
    },
    {
      comparator: numericComparator,
      field: 'valueDifference',
      headerName: 'Value Difference',
      minWidth: 75,
      sortable: true,
      type: 'numericColumn',
      valueFormatter: ({ value }: { value: string }) =>
        value === null ? '' : formatters.formatDollars(value || 0, 3)
    },
    {
      comparator: numericComparator,
      field: 'parentUnits',
      headerName: 'Parent Units',
      minWidth: 75,
      sortable: true,
      type: 'numericColumn',
      valueFormatter: ({ value }: { value: string }) =>
        value === null ? '' : formatters.formatDecimal(value || 0, 3)
    },
    {
      comparator: numericComparator,
      field: 'comparisonUnits',
      headerName: 'Comparison Units',
      minWidth: 75,
      sortable: true,
      type: 'numericColumn',
      valueFormatter: ({ value }: { value: string }) =>
        value === null ? '' : formatters.formatDecimal(value || 0, 3)
    },
    {
      comparator: numericComparator,
      field: 'unitsDifference',
      headerName: 'Units Difference',
      minWidth: 75,
      sortable: true,
      type: 'numericColumn',
      valueFormatter: ({ value }: { value: string }) =>
        value === null ? '' : formatters.formatDecimal(value || 0, 3)
    },
    {
      cellRenderer: (cellData: { data: ReconExceptionDto }) => {
        if (!cellData.data.assignee) {
          return null;
        }
        const matchedAssignee = getVestwellStaffQuery.data?.find(
          staffUser => staffUser.userId === cellData.data.assignee
        );
        return (
          <DataTableStackCell
            primary={matchedAssignee?.label || cellData.data.assignee}
          />
        );
      },
      field: 'assignee',
      headerName: 'Assignee',
      maxWidth: 160,
      sortable: false
    }
  ];

  const handleSortChanged = (
    newSort: { colId: string; sort?: 'asc' | 'desc' }[]
  ) => {
    if (!newSort || newSort.length === 0) {
      setOrderBy(undefined);
      setOrderByDirection(undefined);
    } else {
      setOrderBy(newSort[0].colId as keyof ReconExceptionDto);
      setOrderByDirection(newSort[0].sort);
    }
  };

  const handlePageChanged = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageSizeChanged = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const formatExceptions = (reconExceptions: ReconExceptionDto[]) => {
    return reconExceptions.map(reconException => {
      return {
        ID: reconException.id,
        Status: formatters.getValueKey(BreakageStatus, reconException.status),
        // eslint-disable-next-line sort-keys-plus/sort-keys
        'Parent Account': reconException.parentAccount.plan?.name,
        'Parent Account ID': reconException.parentAccountId,
        // eslint-disable-next-line sort-keys-plus/sort-keys
        'Break Type': formatters.displayCase(
          formatters.getValueKey(BreakageDataType, reconException.dataType)
        ),
        // eslint-disable-next-line sort-keys-plus/sort-keys
        'Break Process': formatters.displayCase(
          formatters.getValueKey(BreakageProcess, reconException.process)
        ),
        Ticker: reconException.security?.symbol,
        // eslint-disable-next-line sort-keys-plus/sort-keys
        CUSIP: reconException.security?.cusip,
        'Fund Name': reconException.security?.description,
        // eslint-disable-next-line sort-keys-plus/sort-keys
        'Exception Date': formatters.formatFromIsoDateCustom(
          reconException.exceptionDate,
          'MM/DD/YYYY'
        ),
        Notes: reconException.notes,
        'Parent Value': reconException.parentValue,
        // eslint-disable-next-line sort-keys-plus/sort-keys
        'Comparison Value': reconException.comparisonValue,
        'Value Difference': reconException.valueDifference,
        // eslint-disable-next-line sort-keys-plus/sort-keys
        'Parent Units': reconException.parentUnits,
        // eslint-disable-next-line sort-keys-plus/sort-keys
        'Comparison Units': reconException.comparisonUnits,
        'Units Difference': reconException.unitsDifference,
        // eslint-disable-next-line sort-keys-plus/sort-keys
        Assignee: reconException.assignee
      };
    });
  };

  const exportExceptions = async () => {
    const reconExceptions = await fetchReconExceptions(1, 133);
    return formatExceptions(reconExceptions.results);
  };

  return (
    <Card>
      <CardHeader
        data-testid='exceptions-header'
        loading={searchReconExceptionsQuery.isFetching}
        title='All Exceptions'>
        <FormControlLabel
          control={
            <Switch
              checked={assignedToMeChecked}
              inputProps={{ 'aria-label': 'assigned to me' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAssignedToMeChecked(event.target.checked);

                setPage(1);
                if (event.target.checked) {
                  setQuery(prev => {
                    return {
                      ...prev,
                      assignee: myUserId
                    };
                  });
                  const myUser = getVestwellStaffQuery.data?.find(
                    staffUser => staffUser.userId === myUserId
                  );
                  if (myUser?.userId) {
                    setAssignee({
                      id: myUser.userId,
                      label: myUser.label
                    });
                  }
                } else {
                  setQuery(prev => {
                    return {
                      ...prev,
                      assignee: undefined
                    };
                  });
                  setAssignee(null);
                }
              }}
              size='small'
            />
          }
          label={<Typography sx={{ fontSize: 14 }}>Assigned to me</Typography>}
        />

        <DownloadCSVButton
          buttonProps={{
            sx: {
              fontSize: 'small'
            },
            variant: 'outlined'
          }}
          fileName={`Exceptions-${props.parentAccountId || 'All'}.csv`}
          getInfo={exportExceptions}
          text='EXPORT CSV'
        />
      </CardHeader>
      <CardContent
        disablePadding
        overlayLoading={getVestwellStaffQuery.isInitialLoading}>
        <DataTable
          columnDefs={
            props.parentAccountId
              ? parentAccountColumnDefs
              : standAloneColumnDefs
          }
          data-testid='data-exceptions-table'
          emptyPlaceholderComponent={
            <Stack
              alignItems='center'
              data-testid='no-data-exceptions-table'
              justifyContent='center'
              sx={{ height: '100%' }}>
              <CardPlaceholder
                icon={<SearchIcon fontSize='inherit' />}
                subtitle={
                  !searchReconExceptionsQuery.data?.results
                    ? 'Search results will be displayed here.'
                    : 'No results found.'
                }
              />
            </Stack>
          }
          filterSidePanelComponent={
            <Formik
              initialValues={{
                cusipOrSymbol: '',
                dataType: '',
                endDate: dayjs().format('YYYY-MM-DD'),
                id: '',
                lockAccountFlag: '',
                parentAccountId: props.parentAccountId
                  ? props.parentAccountId
                  : '',
                process: '',
                status: [...defaultStatuses],
                ...queryToInputValues(query)
              }}
              onSubmit={handleSubmit}>
              {({
                values,
                isValid,
                setFieldValue,
                setFieldError,
                submitForm
              }) => (
                <Form data-testid='filter-form'>
                  <Stack
                    alignItems='flex-start'
                    justifyContent='flex-start'
                    spacing={2}>
                    <CusipTickerSearch
                      data-testid='cusip-symbol-input'
                      helperTextPlaceholder
                      initialValue={values.cusipOrSymbol}
                      onChange={value => {
                        setFieldValue('cusipOrSymbol', value);
                        setIsCusipValid(true);
                        if (value === '') submitForm();
                      }}
                      onError={err => {
                        setFieldError('cusipOrSymbol', err.message);
                        setIsCusipValid(false);
                      }}
                      onValidating={setIsValidating}
                    />

                    <FormControl fullWidth>
                      <Field
                        InputLabelProps={{ shrink: true }}
                        as={TextField}
                        data-testid='exception-id-input'
                        label='Exception ID'
                        name='id'
                        placeholder='Any'
                        size='small'
                      />
                    </FormControl>

                    {props.parentAccountId ? null : (
                      <FormControl fullWidth>
                        <Field
                          InputLabelProps={{ shrink: true }}
                          as={TextField}
                          data-testid='parent-account-id-input'
                          label='Parent Account ID'
                          name='parentAccountId'
                          placeholder='Any'
                          size='small'
                        />
                      </FormControl>
                    )}

                    <FormControl fullWidth size='small' sx={{ width: 240 }}>
                      <InputLabel id='menu-status-label' shrink>
                        Status
                      </InputLabel>
                      <Field
                        MenuProps={{
                          'data-testid': 'menu-status'
                        }}
                        as={Select}
                        data-testid='query-status'
                        displayEmpty
                        input={<OutlinedInput label='Status' notched />}
                        label='Status'
                        labelId='menu-status-label'
                        multiple
                        name='status'
                        renderValue={(selected: BreakageStatus[]) => {
                          if (selected.length === 0) {
                            return <>Any</>;
                          }

                          return (
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 0.5
                              }}>
                              {selected.map(value => {
                                const displayStatus = formatters.getValueKey(
                                  BreakageStatus,
                                  value
                                );
                                return (
                                  <Badge
                                    color={BreakageStatusColorMap[value]}
                                    key={value}
                                    size='small'>
                                    {formatters.displayCase(displayStatus)}
                                  </Badge>
                                );
                              })}
                            </Box>
                          );
                        }}>
                        {Object.values(BreakageStatus).map(value => {
                          const displayStatus = formatters.getValueKey(
                            BreakageStatus,
                            value
                          );
                          return (
                            <MenuItem key={value} value={value}>
                              <Checkbox
                                checked={values.status.includes(value)}
                                sx={{ py: 0 }}
                              />
                              <ListItemText>
                                {formatters.displayCase(displayStatus)}
                              </ListItemText>
                            </MenuItem>
                          );
                        })}
                      </Field>
                    </FormControl>

                    <FormControl fullWidth size='small'>
                      <InputLabel id='menu-data-type-label' shrink>
                        Break Type
                      </InputLabel>
                      <Field
                        MenuProps={{
                          'data-testid': 'menu-data-type'
                        }}
                        as={Select}
                        data-testid='query-data-type'
                        displayEmpty
                        input={<OutlinedInput label='Break Type' notched />}
                        label='Break Type'
                        labelId='menu-data-type-label'
                        name='dataType'>
                        <MenuItem value=''>Any</MenuItem>
                        {Object.values(BreakageDataType).map(value => {
                          const displayDataType = formatters.getValueKey(
                            BreakageDataType,
                            value
                          );
                          return (
                            <MenuItem key={value} value={value}>
                              {formatters.displayCase(displayDataType)}
                            </MenuItem>
                          );
                        })}
                      </Field>
                    </FormControl>

                    <FormControl fullWidth size='small'>
                      <InputLabel id='menu-process-label' shrink>
                        Break Process
                      </InputLabel>
                      <Field
                        MenuProps={{
                          'data-testid': 'menu-process'
                        }}
                        as={Select}
                        data-testid='query-process'
                        displayEmpty
                        input={<OutlinedInput label='Break Process' notched />}
                        label='Break Process'
                        labelId='menu-process-label'
                        name='process'>
                        <MenuItem value=''>Any</MenuItem>
                        {Object.values(BreakageProcess).map(value => {
                          const displayProcess = formatters.getValueKey(
                            BreakageProcess,
                            value
                          );
                          return (
                            <MenuItem key={value} value={value}>
                              {formatters.displayCase(displayProcess)}
                            </MenuItem>
                          );
                        })}
                      </Field>
                    </FormControl>

                    <FormControl fullWidth size='small'>
                      <InputLabel id='menu-lock-account-flag-label' shrink>
                        Frozen
                      </InputLabel>
                      <Field
                        MenuProps={{
                          'data-testid': 'menu-lock-account-flag'
                        }}
                        as={Select}
                        data-testid='query-lock-account-flag'
                        displayEmpty
                        input={<OutlinedInput label='Frozen' notched />}
                        label='Frozen'
                        labelId='menu-lock-account-flag-label'
                        name='lockAccountFlag'>
                        <MenuItem value=''>Any</MenuItem>
                        <MenuItem value='true'>Frozen</MenuItem>
                        <MenuItem value='false'>Not Frozen</MenuItem>
                      </Field>
                    </FormControl>

                    {getVestwellStaffQuery.data && (
                      <FormControl fullWidth size='small'>
                        <Autocomplete
                          data-testid='alert-assignee'
                          disablePortal
                          inputValue={assigneeInputValue}
                          isOptionEqualToValue={(option, value) =>
                            option.label === value.label
                          }
                          onChange={(
                            _event,
                            newValue: { label: string; id: string }
                          ) => {
                            setAssignee(newValue);
                          }}
                          onInputChange={(_event, newInputValue) => {
                            setAssigneeInputValue(newInputValue);
                          }}
                          options={getVestwellStaffQuery.data.map(user => ({
                            id: String(user.userId),
                            label: user.label
                          }))}
                          renderInput={params => (
                            <TextField
                              {...params}
                              InputLabelProps={{ shrink: true }}
                              label='Assignee'
                              placeholder='Any'
                            />
                          )}
                          size='small'
                          value={assignee}
                        />
                      </FormControl>
                    )}

                    <Divider />

                    <Typography variant='body2'>Exception Date</Typography>

                    <FormControl fullWidth>
                      <Field
                        as={DatePicker}
                        data-testid='start-date-input'
                        label='From'
                        name='startDate'
                        size='small'
                        variant='outlined'
                      />
                    </FormControl>

                    <FormControl fullWidth>
                      <Field
                        as={DatePicker}
                        data-testid='end-date-input'
                        label='To'
                        name='endDate'
                        size='small'
                        variant='outlined'
                      />
                    </FormControl>

                    <Button
                      disabled={!isValid || isValidating || !isCusipValid}
                      type='submit'
                      variant='outlined'>
                      Apply
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          }
          gridRef={gridRef}
          onPageChanged={handlePageChanged}
          onPageSizeChanged={handlePageSizeChanged}
          onSortChanged={handleSortChanged}
          page={page}
          pageSize={pageSize}
          pagination
          paginationSource='server'
          paginationTotal={searchReconExceptionsQuery.data?.pagination?.total}
          rowData={searchReconExceptionsQuery.data?.results || []}
          sort={
            orderBy
              ? [
                  {
                    colId: orderBy,
                    sort: orderByDirection
                  }
                ]
              : []
          }
        />
      </CardContent>
    </Card>
  );
};
