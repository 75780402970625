import * as yup from 'yup';

const formattedSSN = /^(?!(000))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/;
const plainSSN = /^(?!(000))\d{3}(?!00)\d{2}(?!0000)\d{4}$/;
const blacklistedSSNs = ['123-45-6789', '987-65-4321'];
const einRegex = new RegExp(`((^\\d{9}$)|(^\\d{2}-\\d{7}$))`);
const blackListedEINs = [
  '000000000',
  '111111111',
  '222222222',
  '333333333',
  '444444444',
  '555555555',
  '666666666',
  '777777777',
  '888888888',
  '999999999',
  '123456789',
  '987654321'
];

export const einValidation = yup
  .string()
  .matches(einRegex, 'Please enter a valid EIN')
  .test({
    test(value) {
      if (value && blackListedEINs.includes(value.replaceAll('-', ''))) {
        return this.createError({
          message: 'Please enter a valid EIN',
          path: this.path
        });
      }
      return true;
    }
  });

export const phoneNumberValidation = yup
  .string()
  .matches(
    /^(?:\d{3})-\d{3}-\d{4}$/,
    'Invalid format, please use: XXX-XXX-XXXX'
  );

export const ssnValidation = yup
  .string()
  .test(
    'Check for valid SSN format',
    'Please enter a valid SSN',
    (value?: string) => {
      if (value) {
        const ssnNoDashes = value.replace(/-/g, '');
        const ssnDigits = new Set(ssnNoDashes.split(''));
        return (
          (formattedSSN.test(value) || plainSSN.test(value)) &&
          ssnDigits.size > 1 &&
          !blacklistedSSNs.includes(ssnNoDashes)
        );
      }
      return true;
    }
  );

export const zipCodeValidation = yup
  .string()
  .required('Zip Code is Required')
  .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Must be a valid zip code');

export const taxIdValidation = yup
  .string()
  .matches(/^\d{9}$/, 'Must be a valid Tax ID');

export const email = yup
  .string()
  .trim()
  .email('Please provide a valid email')
  .matches(
    /^(([a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*)|(\.?[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    'Please use standard email format'
  );

export const alphaNumericComaSeparated = yup
  .string()
  .matches(
    /^^([a-zA-Z0-9]+)(,\s*[a-zA-Z0-9]+)*$/,
    'Please just use alphanumeric characters'
  );
