import Constants from '@/consts/planDesign.constants';
import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class ExcludeRollover {
  convertToApiState = Helper.convertFromYesNoToBoolean;

  disabled: boolean;

  options = Constants.yesNoOptions;

  output: string | undefined;

  setDisabled = (value: boolean): void => {
    this.disabled = value;
  };

  constructor(props: Record<string, any>) {
    this.disabled = !props.isAllowed;

    this.output = Helper.convertFromBooleanToYesNo(props.excludeRollover);
  }
}

export default ExcludeRollover;
