import CollapsibleTable, {
  CellComponentProps
} from '@/components/collapsible-table';
import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import LinearLoading from '@/components/linear-loading';
import ModelFactsheetDownloadButton from '@/components/model-factsheet-download-button/ModelFactsheetDownloadButton.component';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { ProgramModelFactsheet } from '@/models/ops/investments/Program.model';
import { ProgramDocumentsProps } from '@/routes/ops/investments/ProgramDocumentsTab.component';
import { ModelService } from '@/services/ops/investments/Model.service';
import { ProgramService } from '@/services/ops/investments/Program.service';
import formatters from '@/utils/Formatters';
import { Delete, Search } from '@mui/icons-material';
import {
  Box,
  IconButton,
  InputAdornment,
  Paper,
  TableCell,
  TextField,
  Theme,
  Typography
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import React, { useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { UploadButton } from './UploadButton.component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    createdDateWidth: {
      width: '15%'
    },
    fileNameWidth: {
      width: '45%'
    },
    header: {
      fontSize: theme.spacing(3)
    },
    modelIdWidth: {
      width: '10%'
    },
    modelNameWidth: {
      width: '18%'
    },
    modelTypeWidth: {
      width: '12%'
    },
    paper: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(4),
      width: '100%'
    },
    searchInput: {
      marginTop: theme.spacing(3),
      width: theme.spacing(65)
    },
    searchSection: {
      marginBottom: theme.spacing(3),
      marginLeft: theme.spacing(3),
      marginTop: theme.spacing(3)
    },
    size: {
      fontSize: theme.spacing(2)
    }
  })
);

const ProgramFactsheets = (props: ProgramDocumentsProps): JSX.Element => {
  const { programId } = props;

  const classes = useStyles();
  const snackbar = useSnackbar();
  const { openDialog } = useDialog();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const modelIdRef = useRef<number>(0);

  const factsheetsTableColumns: GridColDef[] = [
    {
      cellClassName: classes.modelIdWidth,
      field: 'modelId',
      headerName: 'Model ID'
    },
    {
      cellClassName: classes.modelNameWidth,
      field: 'modelName',
      headerName: 'Model Name'
    },
    {
      cellClassName: classes.modelTypeWidth,
      field: 'modelType',
      headerName: 'Model Type'
    },
    {
      cellClassName: classes.fileNameWidth,
      field: 'factsheet',
      headerName: 'File Name'
    },
    {
      cellClassName: classes.createdDateWidth,
      field: 'updatedAt',
      headerName: 'Created Date'
    },
    { field: 'upload', headerName: 'Factsheet' },
    { field: 'delete' }
  ];

  const factsheetsQuery = useQuery<ProgramModelFactsheet[]>(
    ['ProgramService.getProgramModelFactsheets', programId],
    async () => ProgramService.getProgramModelFactsheets(programId),
    {
      enabled: Boolean(programId)
    }
  );

  const factsheetsData = factsheetsQuery.data || [];
  const factsheetsWithUUID = factsheetsData
    .map((slot: ProgramModelFactsheet) => {
      return {
        id: uuidv4(),
        ...slot
      };
    })
    .sort((a, b) => {
      return a.modelName.toLowerCase().localeCompare(b.modelName.toLowerCase());
    });

  if (factsheetsQuery.isError) {
    const { error } = factsheetsQuery;
    return redirectToErrorPage(error as Error);
  }

  const uploadFactsheet = async (document: FormData) => {
    try {
      setIsLoading(true);
      const modelId = modelIdRef.current;
      await ModelService.uploadModelFactsheet(modelId, document);
      setIsLoading(false);
      factsheetsQuery.refetch();
      snackbar.showSnackbar({
        message: `Upload was successful`,
        severity: 'success'
      });
    } catch (e) {
      setIsLoading(false);
      snackbar.showSnackbar({
        message: 'Failed to upload file!',
        severity: 'error'
      });
    }
  };

  const handleUploadFactsheet = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file, file.name);
      uploadFactsheet(formData);
    } else {
      snackbar.showSnackbar({
        message: 'File was not selected. Try again',
        severity: 'error'
      });
    }
    event.target.value = '';
  };

  const handleDeleteFactsheet = (modelId: number) => {
    setIsLoading(true);
    ModelService.deleteModelFactsheet(modelId)
      .then(() => {
        setIsLoading(false);
        factsheetsQuery.refetch();
        snackbar.showSnackbar({
          message: `Factsheet was deleted`,
          severity: 'success'
        });
      })
      .catch(() => {
        setIsLoading(false);
        snackbar.showSnackbar({
          message: 'Factsheet was NOT deleted',
          severity: 'error'
        });
      });
  };

  const factsheetsTableCell: React.FunctionComponent<CellComponentProps> = (
    cellProps: CellComponentProps
  ) => {
    const { row, column } = cellProps;
    let field;

    if (column.field === 'modelType') {
      field = formatters.capitalizeFirstChar(row[column.field]);
    } else if (column.field === 'updatedAt') {
      field = row[column.field] ? (
        <Box className={classes.size}>
          {formatters.formatFromIsoDateCustom(
            row[column.field],
            'MM/DD/YY HH:mm'
          )}
        </Box>
      ) : (
        ''
      );
    } else if (column.field === 'factsheet') {
      field = (
        <ModelFactsheetDownloadButton
          modelId={row.modelId}
          uploadPath={row.factsheet}
        />
      );
    } else if (column.field === 'upload') {
      field = (
        <UploadButton
          data-testid={`upload-file-button-${row.modelId}`}
          onChange={handleUploadFactsheet}
          onClick={() => {
            modelIdRef.current = row.modelId;
          }}
        />
      );
    } else if (column.field === 'delete') {
      field = (
        <IconButton
          aria-label='delete'
          color='error'
          data-testid={`delete-file-button-${row.modelId}`}
          disabled={!row.factsheet}
          id={`delete-btn-id-${row.modelId}`}
          onClick={() =>
            row.factsheet &&
            openDialog({
              actionButtons: {
                cancelButton: {
                  children: 'CANCEl'
                },
                submitButton: {
                  children: 'DELETE'
                }
              },
              onSubmit: () => {
                handleDeleteFactsheet(row.modelId);
              },
              steps: [
                {
                  contentText: 'The factsheet will be deleted!',
                  title: 'Delete Model Factsheet'
                }
              ]
            })
          }>
          <Delete />
        </IconButton>
      );
    } else field = row[column.field];

    return (
      <TableCell className={classes.size} component='th' scope='row'>
        <Box>{field}</Box>
      </TableCell>
    );
  };

  return (
    <Paper className={classes.paper} elevation={0} variant='outlined'>
      <div className={classes.searchSection}>
        <Typography className={classes.header} variant='h5'>
          Factsheets
        </Typography>
        <TextField
          InputProps={{
            onChange: event => setSearchTerm(event.target.value),
            placeholder: 'Search factsheets',
            startAdornment: (
              <InputAdornment position='start'>
                <Search />
              </InputAdornment>
            )
          }}
          className={classes.searchInput}
          data-testid='search-factsheets'
          value={searchTerm}
          variant='outlined'
        />
      </div>
      {(isLoading || factsheetsQuery.isFetching) && <LinearLoading />}
      <CollapsibleTable
        cellComponent={factsheetsTableCell}
        columns={factsheetsTableColumns}
        pager={{
          metaCount: factsheetsData.length,
          onPageNumberChanged: (zeroIndexedPageNumber: number) => {
            return setPageNumber(zeroIndexedPageNumber + 1);
          },
          onRowsPerPageChanged: (newRowsPerPage: number) => {
            return setRowsPerPage(newRowsPerPage);
          },
          pageNumber: pageNumber - 1,
          rowsPerPage
        }}
        tableData={(factsheetsWithUUID || []).slice(
          rowsPerPage * pageNumber - rowsPerPage,
          rowsPerPage * pageNumber
        )}
      />
    </Paper>
  );
};

export default ProgramFactsheets;
