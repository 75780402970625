import { Typography } from '@mui/material';

import React from 'react';

interface ErrorMessageProps {
  error: string;
}

const ErrorMessage: React.FunctionComponent<ErrorMessageProps> = (
  props: ErrorMessageProps
) => {
  const { error } = props;
  return <Typography variant='body2'>{error}</Typography>;
};

export default ErrorMessage;
