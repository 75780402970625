import InvestmentFundLineup from '@/routes/ops/investments/fund-lineup/InvestmentFundLineup.component';
import InvestmentGoalSeries from '@/routes/ops/investments/goal-series/InvestmentGoalSeries.component';
import InvestmentsModels from '@/routes/ops/investments/models/InvestmentModels.component';
import InvestmentsPrograms from '@/routes/ops/investments/programs/InvestmentPrograms.component';
import { userService } from '@/services/User.service';
import { Search } from '@mui/icons-material';
import { TabContext } from '@mui/lab';
import {
  Box,
  InputAdornment,
  Paper,
  Tab,
  Tabs,
  TextField,
  Theme
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerSearchTitle: {
      color: theme.palette.grey[700],
      margin: theme.spacing(3)
    },
    paper: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(4),
      width: '100%'
    },
    radio: {
      color: theme.palette.grey[700],
      paddingRight: theme.spacing(2)
    },
    searchInput: {
      width: theme.spacing(60)
    },
    searchInputBox: {
      padding: theme.spacing(2)
    },
    searchSection: {
      display: 'flex',
      flexDirection: 'column'
    }
  })
);

type SearchType = 'models' | 'programs' | 'fundLineup' | 'goalSeries';

const searchTypeToSearchDisplay = (searchType: SearchType) => {
  switch (searchType) {
    case 'models':
      return 'Search Models';
    case 'programs':
      return 'Search Programs';
    case 'fundLineup':
      return 'Search Fund Lineup';
    case 'goalSeries':
      return 'Search Managed Account';
    default:
      return 'Unknown';
  }
};

const availableTabs = [
  {
    dataTestId: 'search-investment-programs-tab',
    label: 'Programs',
    value: 'programs'
  },
  {
    dataTestId: 'search-investment-models-tab',
    label: 'Model Series',
    value: 'models'
  },
  {
    dataTestId: 'search-investment-fund-lineup-tab',
    label: 'Fund Lineup',
    value: 'fundLineup'
  },
  {
    dataTestId: 'search-investment-goal-series-tab',
    label: 'Managed Account',
    value: 'goalSeries'
  }
];

const ListInvestments = (): JSX.Element => {
  const classes = useStyles();

  const [isUserAuthenticated, updateIsUserAuthenticated] = useState(false);
  const [searchType, setSearchType] = useState<SearchType>('programs');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const subscriptionId = 'ListInvestments';

    userService.subscribe(subscriptionId, ({ isAuthenticated, token }) => {
      updateIsUserAuthenticated(isAuthenticated && !!token);
    });

    return () => userService.unsubscribe(subscriptionId);
  }, []);

  const updateSearchType = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: any
  ) => {
    setSearchType(newValue);
  };

  const searchInvestments = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;
    setSearchTerm(search);
  };

  return (
    <div>
      <>
        <Paper className={classes.paper} elevation={0} variant='outlined'>
          <div className={classes.searchSection}>
            <TabContext value={searchType}>
              <Tabs
                onChange={updateSearchType}
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider'
                }}
                value={searchType}>
                {availableTabs.map(tab => (
                  <Tab
                    data-testid={tab.dataTestId}
                    key={tab.value}
                    label={tab.label}
                    value={tab.value}
                  />
                ))}
              </Tabs>
            </TabContext>
            <Box className={classes.searchInputBox}>
              <TextField
                InputProps={{
                  onChange: searchInvestments,
                  placeholder: searchTypeToSearchDisplay(searchType),
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Search />
                    </InputAdornment>
                  )
                }}
                className={classes.searchInput}
                data-testid='search-investment-field'
                variant='outlined'
              />
            </Box>
          </div>
          {searchType === 'models' && (
            <InvestmentsModels
              isUserAuthenticated={isUserAuthenticated}
              searchTerm={searchTerm}
            />
          )}
          {searchType === 'programs' && (
            <InvestmentsPrograms
              isUserAuthenticated={isUserAuthenticated}
              searchTerm={searchTerm}
            />
          )}
          {searchType === 'fundLineup' && (
            <InvestmentFundLineup
              isUserAuthenticated={isUserAuthenticated}
              searchTerm={searchTerm}
            />
          )}
          {searchType === 'goalSeries' && (
            <InvestmentGoalSeries
              isUserAuthenticated={isUserAuthenticated}
              searchTerm={searchTerm}
            />
          )}
        </Paper>
      </>
    </div>
  );
};

export default ListInvestments;
