import { SubaErrors } from '@/hooks/useSubaErrors.hook';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Unstable_Grid2 as Grid,
  Typography
} from '@mui/material';

import { map } from 'lodash';
import { Dispatch, FC, SetStateAction } from 'react';

export type ForceModalProps = {
  backendErrors: SubaErrors;
  isLoading: boolean;
  showModal: boolean;
  showModalStateHandler: Dispatch<SetStateAction<boolean>>;
  submitHandler: () => void;
};

export const WithdrawalSkipLocationValidationModal: FC<ForceModalProps> = (
  props: ForceModalProps
) => {
  const onClose = () => {
    props.showModalStateHandler(false);
  };

  return (
    <Dialog fullWidth maxWidth='md' onClose={onClose} open={props.showModal}>
      <DialogTitle> Address validation errors were found</DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid container spacing={2} xs={12}>
            {map(
              props.backendErrors.indexedErrors?.['disbursementAddress'],
              (value, key) =>
                !!value && (
                  <Grid key={key} xs={12}>
                    <Alert severity='error'>{value.error}</Alert>
                  </Grid>
                )
            )}
          </Grid>
          <Grid xs={12}>
            <Typography variant='h6'>
              Are you sure you want to submit?
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={props.isLoading}
          onClick={props.submitHandler}
          variant='contained'>
          Suppress validation and submit
        </LoadingButton>
        <Button data-testid='tpa-plans-modal-cancel-btn' onClick={onClose}>
          CANCEL
        </Button>
      </DialogActions>
    </Dialog>
  );
};
