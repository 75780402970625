import { PlanV2Dto } from '@/models';
import { ScheduledChange } from '@/models/ScheduledChangesDTO.model';
import { PlanService } from '@/services/Plan.service';
import SponsorService from '@/services/Sponsor.service';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useCallback, useMemo } from 'react';

export const useGetPlanScheduledChanges = (config: {
  planId: number;
  applied?: boolean;
  suspense?: boolean;
  enabled?: boolean;
}) => {
  const queryClient = useQueryClient();

  const sponsorPlanQuery = useQuery<PlanV2Dto>(
    ['PlanService.getPlanById', +config.planId],
    () => {
      return PlanService.getPlanById(config.planId);
    }
  );

  const sponsorId = useMemo(
    () => sponsorPlanQuery.data?.data.relationships.sponsor.data.id,
    [sponsorPlanQuery.data]
  );

  const scheduledChangesPlan = useQuery(
    ['PlanService.getScheduledChanges', config.planId, !!config.applied],
    () => PlanService.getScheduledChanges(config.planId, !!config.applied),
    {
      enabled:
        !!config.planId &&
        (config.enabled !== undefined ? config.enabled : true),
      staleTime: 1000 * 60 * 15, // 15 minutes,
      suspense: config.suspense
    }
  );

  const scheduledChangesSponsor = useQuery(
    ['SponsorService.getScheduledChanges', +sponsorId, !!config.applied],
    () => SponsorService.getScheduledChanges(+sponsorId, !!config.applied),
    {
      enabled:
        !!sponsorId && (config.enabled !== undefined ? config.enabled : true),
      staleTime: 1000 * 60 * 15, // 15 minutes,
      suspense: config.suspense
    }
  );

  const data = useMemo(() => {
    return [
      ...(scheduledChangesPlan.data?.data || []),
      ...(scheduledChangesSponsor.data || [])
    ];
  }, [scheduledChangesPlan, scheduledChangesSponsor]);

  const invalidate = useCallback(
    (changeType: ScheduledChange['changeType']) => {
      switch (changeType) {
        case 'plan_design':
          queryClient.invalidateQueries([
            'PlanService.getScheduledChanges',
            +config.planId,
            !!config.applied
          ]);
          break;
        case 'company':
          queryClient.invalidateQueries([
            'SponsorService.getScheduledChanges',
            +sponsorId,
            !!config.applied
          ]);
          break;
        default:
          break;
      }
    },
    [config.planId, !!config.applied, sponsorId]
  );

  return {
    data,
    invalidate,
    isFetching:
      scheduledChangesPlan.isFetching || scheduledChangesSponsor.isFetching
  };
};
