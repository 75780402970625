import { ArrowForward } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Unstable_Grid2 as Grid,
  Typography
} from '@mui/material';

import { FC, MouseEvent } from 'react';

type ApproveRejectModalProps = {
  toggleModal: (event: MouseEvent<HTMLElement>) => void;
  isModalOpen: boolean;
  view: 'reject' | 'approve';
  handleSubmit: (event: MouseEvent<HTMLElement>) => void;
  isSubmitting: boolean;
  data: {
    newFund: {
      symbol: string;
      cusip: string;
      fundName: string;
    };
    oldFund: {
      symbol: string;
      cusip: string;
      fundName: string;
    };
  };
};

export const ApproveRejectModal: FC<ApproveRejectModalProps> = props => {
  return (
    <Dialog maxWidth='lg' onClose={props.toggleModal} open={props.isModalOpen}>
      <DialogTitle>
        {props.view === 'reject' ? 'Reject' : 'Approve'} fund change request?
      </DialogTitle>

      <DialogContent dividers>
        <Grid alignItems='center' container>
          <Grid lg={5}>
            <Typography variant='body1'>
              {props.data.oldFund.symbol} | {props.data.oldFund.cusip}
            </Typography>
            <Typography variant='body2'>
              {props.data.oldFund.fundName}
            </Typography>
          </Grid>
          <Grid lg={2}>
            <ArrowForward />
          </Grid>
          <Grid lg={5}>
            <Typography variant='body1'>
              {props.data.newFund.symbol} | {props.data.newFund.cusip}
            </Typography>
            <Typography variant='body2'>
              {props.data.newFund.fundName}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={props.isSubmitting} onClick={props.toggleModal}>
          Cancel
        </Button>
        <Button
          color={props.view === 'reject' ? 'error' : 'primary'}
          disabled={props.isSubmitting}
          onClick={props.handleSubmit}
          type='submit'
          variant='contained'>
          {props.view === 'reject' ? 'Reject' : 'Approve & Submit'}
          {props.isSubmitting ? '...' : ''}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ApproveRejectModal.displayName = 'ApproveRejectModal';
