import Constants from '@/consts/planDesign.constants';
import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class EACAPermissibleWithdrawal {
  convertToApiState = Helper.convertFromAllowDontAllowToBoolean;

  disabled: boolean;

  setDisabled = (value: boolean): void => {
    this.disabled = value;
  };

  options = Constants.allowDontAllowOptions;

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.disabled = !props.autoEnrollAmount;
    this.output = Helper.convertFromBooleanToAllowDontAllow(
      props.allowPermissibleWithdrawal
    );
  }
}

export default EACAPermissibleWithdrawal;
