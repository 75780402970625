import LinearLoading from '@/components/linear-loading';
import useShowMore from '@/hooks/useShowMore';
import {
  GlobalSearchDto,
  GlobalSearchTargetSeriesDto
} from '@/models/GlobalSearchDTO.model';
import GlobalSearchService from '@/services/GlobalSearch.service';
import { DateRange } from '@mui/icons-material';
import {
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Theme,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  GLOBAL_SEARCH_RESULT_PAGE_SIZE,
  GLOBAL_SEARCH_TARGET_SERIES_TYPE
} from './constants';

interface GlobalSearchTargetSeriesResultProps {
  setSearchTerm: (search: string) => void;
  searchTerm: string;
  isFocused: boolean;
  abortSignal?: AbortSignal;
}

const useStyles = makeStyles((theme: Theme) => ({
  globalSearchShowMoreBtn: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5)
  },
  globalSearchTargetSeriesContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  globalSearchTargetSeriesField: {
    color: theme.palette.text.secondary,
    fontSize: theme.spacing(1.5),
    minHeight: theme.spacing(1.5)
  },
  globalSearchTargetSeriesHeader: {
    fontSize: theme.spacing(1.5),
    letterSpacing: '0.4px',
    marginLeft: theme.spacing(1.5),
    marginTop: theme.spacing(2)
  },
  globalSearchTargetSeriesIdField: {
    color: theme.palette.text.secondary,
    fontSize: theme.spacing(1.2),
    paddingTop: theme.spacing(1.5),
    textAlign: 'right',
    width: '25%'
  },
  globalSearchTargetSeriesImg: {
    color: '#ff9980'
  },
  globalSearchTargetSeriesItem: {
    '&:hover': {
      backgroundColor: grey[200]
    },
    cursor: 'pointer'
  },
  noResults: {
    paddingLeft: theme.spacing(2)
  }
}));

const GlobalSearchTargetSeriesResult = (
  props: GlobalSearchTargetSeriesResultProps | any
): JSX.Element => {
  const classes = useStyles();

  const { setSearchTerm, searchTerm, abortSignal } = props;

  const navigate = useNavigate();

  const redirectToTargetSeriesPage = (id: number) => {
    setSearchTerm('');
    navigate(`/ops/investments/target-series/${id}`);
  };

  const globalSearchTargetSeriesQuery = useShowMore<
    GlobalSearchDto,
    GlobalSearchTargetSeriesDto
  >(
    ['globalSearchTargetSeriesResultQuery', searchTerm],
    page =>
      GlobalSearchService.getGlobalSearchResult(
        searchTerm,
        page,
        GLOBAL_SEARCH_RESULT_PAGE_SIZE,
        GLOBAL_SEARCH_TARGET_SERIES_TYPE,
        abortSignal
      ),
    Boolean(searchTerm),
    data => data.data.targetSeries.data,
    data => Boolean(data?.data.targetSeries.links.next),
    'targetSeriesId'
  );

  let loader;

  if (globalSearchTargetSeriesQuery.isLoading || searchTerm === '') {
    loader = <LinearLoading />;
  } else {
    loader = null;
  }

  if (globalSearchTargetSeriesQuery.isError) {
    return <p>Error</p>;
  }

  return (
    <>
      <Typography
        className={classes.globalSearchTargetSeriesHeader}
        id='global-search-targetSeries-header'>
        TARGET DATE SERIES
      </Typography>
      {loader || (
        <div>
          {!globalSearchTargetSeriesQuery.isLoading &&
          !!globalSearchTargetSeriesQuery.data?.length ? (
            <List data-testid='gs-targetSeries' id='global-search-targetSeries'>
              {globalSearchTargetSeriesQuery.data.map(
                (item: GlobalSearchTargetSeriesDto) => {
                  return (
                    <ListItem
                      className={classes.globalSearchTargetSeriesItem}
                      id='global-search-targetSeries-item'
                      key={item.targetSeriesId}
                      onClick={() =>
                        redirectToTargetSeriesPage(item.targetSeriesId)
                      }>
                      <ListItemAvatar
                        className={classes.globalSearchTargetSeriesImg}>
                        <DateRange id='global-search-targetSeries-image' />
                      </ListItemAvatar>
                      <div
                        className={classes.globalSearchTargetSeriesContainer}
                        id='global-search-targetSeries-container'>
                        <div id='global-search-targetSeries-wrapper'>
                          <ListItemText
                            id='global-search-targetSeries-name'
                            primary={`${item.name}`}
                          />
                          <Typography
                            className={classes.globalSearchTargetSeriesField}
                            id='global-search-advisors-firm'>
                            {item?.description
                              ? `Description: ${item?.description}`
                              : ''}
                          </Typography>
                        </div>
                        <Typography
                          className={classes.globalSearchTargetSeriesIdField}
                          id='global-search-targetSeries-id'>
                          TARGET SERIES ID: {item.targetSeriesId}
                        </Typography>
                      </div>
                    </ListItem>
                  );
                }
              )}
            </List>
          ) : (
            <p className={classes.noResults}>No results</p>
          )}
          {!globalSearchTargetSeriesQuery.isLoading &&
            !!globalSearchTargetSeriesQuery.data?.length &&
            globalSearchTargetSeriesQuery.isPaginated && (
              <Button
                className={classes.globalSearchShowMoreBtn}
                disabled={globalSearchTargetSeriesQuery.isPaginationLoading}
                id='global-search-targetSeries-show-more-btn'
                onClick={globalSearchTargetSeriesQuery.showMore}>
                SHOW MORE
              </Button>
            )}
        </div>
      )}
    </>
  );
};

export default GlobalSearchTargetSeriesResult;
