import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

import React from 'react';

interface NoInvestmentOptionLinkedProps {
  typeName: string;
}

const NoInvestmentOptionLinked: React.FC<NoInvestmentOptionLinkedProps> = ({
  typeName
}) => (
  <Box
    alignItems='center'
    data-testid='no-investment-option-linked-placeholder'
    display='flex'
    flexDirection='column'
    height='200px'
    justifyContent='center'
    sx={{ backgroundColor: grey[50] }}
    width='100%'>
    <Typography variant='body1'>{`No ${typeName} linked`}</Typography>
    <Typography variant='body2'>Search for one and add</Typography>
  </Box>
);

export default NoInvestmentOptionLinked;
