import CollapsibleTable, {
  CellComponentProps,
  Order
} from '@/components/collapsible-table';
import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import ModelProgramsModal from '@/routes/ops/investments/models/ModelProgramsModal.component';
import { FundLineupService } from '@/services/ops/investments/FundLineup.service';
import { Box, Link, Modal, TableCell, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export interface FundLineupSearchData {
  fundLineupId: number;
  name: string;
  description?: string;
  createdAt?: string;
  programCount?: number;
}

interface InvestmentFundLineupProps {
  isUserAuthenticated: boolean;
  searchTerm: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    createdDateWidth: {
      width: '15%'
    },
    fundLineupIdWidth: {
      width: '15%'
    },
    fundLineupNameWidth: {
      width: '25%'
    },
    size: {
      fontSize: theme.spacing(2)
    }
  })
);

const InvestmentFundLineup = (
  props: InvestmentFundLineupProps
): JSX.Element => {
  const { isUserAuthenticated, searchTerm } = props;
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => setPageNumber(1), [searchTerm]);

  const [openModelProgramsModal, setOpenModelProgramsModal] = useState(false);
  const [selectedModel, setSelectedModel] = useState<FundLineupSearchData>(
    {} as FundLineupSearchData
  );
  const handleOpenModelProgramsModal = (model: FundLineupSearchData) => {
    setSelectedModel(model);
    setOpenModelProgramsModal(true);
  };
  const handleCloseModal = () => {
    setSelectedModel({} as FundLineupSearchData);
    setOpenModelProgramsModal(false);
  };

  const fundLineupTableColumns: GridColDef[] = [
    {
      cellClassName: classes.fundLineupIdWidth,
      field: 'fundLineupId',
      headerName: 'ID'
    },
    {
      cellClassName: classes.fundLineupNameWidth,
      field: 'name',
      headerName: 'Name'
    },
    {
      cellClassName: classes.createdDateWidth,
      field: 'createdAt',
      headerName: 'Created Date'
    },
    { field: 'programCount', headerName: 'Programs' }
  ];

  const fundLineupSearchResult = useQuery<{
    data: FundLineupSearchData[];
    meta: { count: number };
  }>(
    [
      'FundLineupService.getFundLineupPage',
      pageNumber,
      rowsPerPage,
      searchTerm,
      orderBy,
      order
    ],
    async () => {
      const dto = await FundLineupService.getFundLineupPage(
        pageNumber,
        rowsPerPage,
        orderBy,
        order,
        searchTerm || ''
      );
      return dto;
    },
    {
      enabled: isUserAuthenticated
    }
  );

  const fundLineupData = fundLineupSearchResult.data?.data || [];

  const { error } = fundLineupSearchResult;
  if (error) {
    return redirectToErrorPage(error as Error);
  }

  const FundLineupTableCell: React.FunctionComponent<CellComponentProps> = (
    cellProps: CellComponentProps
  ) => {
    const { row, column } = cellProps;
    let field;

    if (column.field === 'name') {
      field = (
        <Link
          component={RouterLink}
          to={`/ops/investments/fund-lineup/${row.fundLineupId}`}
          underline='hover'>
          {row[column.field]}
        </Link>
      );
    } else if (column.field === 'programCount' && row[column.field] !== 0) {
      field = (
        <Link
          className={classes.size}
          data-testid='modal-program-link'
          onClick={() => handleOpenModelProgramsModal(row)}
          underline='hover'>
          {row[column.field]}
        </Link>
      );
    } else field = row[column.field];

    return (
      <TableCell className={classes.size} component='th' scope='row'>
        <Box>{field}</Box>
      </TableCell>
    );
  };

  return (
    <>
      <Modal onClose={handleCloseModal} open={openModelProgramsModal}>
        <Box>
          <ModelProgramsModal
            handleCloseModal={handleCloseModal}
            investmentOptionId={selectedModel.fundLineupId}
            modalTitle={'Programs with '
              .concat(selectedModel.name)
              .concat(' Fund Lineup Investment Type')}
            modelType='fundLineup'
          />
        </Box>
      </Modal>
      <CollapsibleTable
        cellComponent={FundLineupTableCell}
        columns={fundLineupTableColumns}
        pager={{
          metaCount: fundLineupSearchResult.data?.meta.count,
          onPageNumberChanged: (zeroIndexedPageNumber: number) => {
            return setPageNumber(zeroIndexedPageNumber + 1);
          },
          onRowsPerPageChanged: (newRowsPerPage: number) => {
            return setRowsPerPage(newRowsPerPage);
          },
          pageNumber: pageNumber - 1,
          rowsPerPage
        }}
        sorter={{
          onSortOrderChanged: (newOrderBy: string, newOrder: Order) => {
            setOrderBy(newOrderBy);
            setOrder(newOrder);
          },
          order,
          orderBy
        }}
        tableData={fundLineupData}
      />
    </>
  );
};

export default InvestmentFundLineup;
