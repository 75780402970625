import { PooledPlan } from '@/models/PooledPlanDTO.model';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Link, Stack } from '@mui/material';

import { FC, useState } from 'react';

import { ScheduledChangesDrawer } from './ScheduledChangesDrawer.component';
import { useGetPlanScheduledChanges } from './useGetPlanScheduledChanges';

interface PlanScheduledChangesInfoProps {
  planId: number | string;
  pooledPlanData?: PooledPlan;
  isEsa?: boolean;
}

const PlanScheduledChangesInfo: FC<PlanScheduledChangesInfoProps> = props => {
  const [scheduledChangesOpen, setScheduledChangesOpen] = useState(false);

  const scheduledChanges = useGetPlanScheduledChanges({
    planId: +props.planId,
    suspense: true
  });

  return (
    <>
      {scheduledChanges.data && (
        <ScheduledChangesDrawer
          isEsa={props.isEsa}
          onClose={() => setScheduledChangesOpen(false)}
          open={scheduledChangesOpen}
          planId={+props.planId}
          pooledPlanData={props.pooledPlanData}
        />
      )}
      <Stack alignItems='center' direction='row' spacing={1}>
        <AccessTimeIcon color='primary' fontSize='small' />
        <Link
          onClick={() => setScheduledChangesOpen(open => !open)}
          sx={{ cursor: 'pointer' }}
          underline='none'>
          {scheduledChanges.data?.length || 0} scheduled changes
        </Link>
      </Stack>
    </>
  );
};

export default PlanScheduledChangesInfo;
