import LinearLoading from '@/components/linear-loading';
import { useDialog } from '@/contexts/DialogContext';
import { PlanService } from '@/services/Plan.service';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

interface EmailMessagePreviewDialogProps {
  messageId: number;
}

const EmailMessagePreviewDialog: React.FC<EmailMessagePreviewDialogProps> = ({
  messageId
}: EmailMessagePreviewDialogProps) => {
  const { closeDialog } = useDialog();
  const { data: emailPreview, isLoading } = useQuery(
    ['PlanService.getEmailMessagePreviewById', messageId],
    () => PlanService.getEmailMessagePreviewById(messageId),
    {
      staleTime: Infinity
    }
  );
  return (
    <>
      <DialogTitle>Email preview</DialogTitle>
      <DialogContent
        data-testid={`dialog-content-email-message-preview-${messageId}`}
        style={{ padding: 0 }}>
        {isLoading && <LinearLoading />}
        <div
          dangerouslySetInnerHTML={{
            __html:
              emailPreview?.data
                ?.toString()
                .replace('height:100vh;width:100vw', '') || ''
          }}
          data-testid='preview-email-container'></div>
      </DialogContent>
      <DialogActions>
        <Button data-testid='cancel-btn' onClick={closeDialog} variant='text'>
          CLOSE
        </Button>
      </DialogActions>
    </>
  );
};

export default EmailMessagePreviewDialog;
