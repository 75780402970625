import { useSnackbar } from '@/contexts/SnackBarContext';
import { AlertSearchRequest } from '@/models/ops/alerts/AlertSearchRequest.model';
import AlertService from '@/services/ops/alerts/Alert.service';
import { useQuery } from '@tanstack/react-query';

import { AxiosError } from 'axios';

type SearchAlertTypeTotalsOptions = {
  enabled: boolean;
};

export const useSearchAlertTypeTotals = (
  query: AlertSearchRequest,
  options?: SearchAlertTypeTotalsOptions
) => {
  const { showSnackbar } = useSnackbar();
  return useQuery(
    ['AlertService.searchTypeTotals', { ...query, alertType: undefined }],
    () => AlertService.searchTypeTotals({ ...query, alertType: undefined }),
    {
      ...options,
      keepPreviousData: true,
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Alert search type totals failed: ${message}`,
          severity: 'error'
        });
      }
    }
  );
};
