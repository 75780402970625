import { DataGridFilters } from '@/components/data-grid-filters/DataGridFilters.component';
import {
  initialPlanEmployeeFilters,
  PlanEmployeeFilters,
  PlanParticipantsInfo
} from '@/models/PlanParticipantsDTO.model';
import { Search } from '@mui/icons-material';
import CachedIcon from '@mui/icons-material/Cached';
import {
  Autocomplete,
  Button,
  Checkbox,
  Divider,
  FormControl,
  InputAdornment,
  MenuItem,
  Stack,
  TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { Field, Form, Formik } from 'formik';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';

interface PlanEmployeesFilterProps {
  filters: PlanEmployeeFilters;
  participantData: PlanParticipantsInfo;
  updateFilters: (filters: PlanEmployeeFilters) => void;
  isStateIRA: boolean;
}

const StyledBtnStack = styled(Stack)(() => ({
  flexDirection: 'row',
  gap: 2,
  justifyContent: 'space-between',
  width: '100%'
}));

export const PlanEmployeesFilter = (
  props: PlanEmployeesFilterProps
): JSX.Element => {
  const handleSubmit = useCallback((values: PlanEmployeeFilters) => {
    props.updateFilters(values);
  }, []);

  const filters = useMemo(
    () => ({
      preTaxDeferralEnd: props.filters.preTaxDeferralEnd,
      preTaxDeferralStart: props.filters.preTaxDeferralStart,
      rothDeferralEnd: props.filters.rothDeferralEnd,
      rothDeferralStart: props.filters.rothDeferralStart,
      statuses: props.filters.statuses
    }),
    [props.filters]
  );

  const prevFilters = useRef(initialPlanEmployeeFilters);

  useEffect(() => {
    if (!isEqual(prevFilters.current, filters)) {
      prevFilters.current = filters;
      props.updateFilters(filters);
    }
  }, [filters]);

  const statusTypes =
    Array.from(
      new Set(props?.participantData?.participants.map(p => p.elStatus))
    ) || [];

  return (
    <DataGridFilters>
      <Formik
        initialValues={initialPlanEmployeeFilters}
        onSubmit={handleSubmit}>
        {formProps => {
          const areFiltersEmpty = isEqual(
            formProps.values,
            formProps.initialValues
          );

          return (
            <Form data-testid='plan-employees-filter-form'>
              <Stack gap={2} width={250}>
                <FormControl fullWidth size='small'>
                  <Autocomplete
                    data-testid='employee-status-filter'
                    disableCloseOnSelect
                    multiple
                    onChange={(_event, newValues) => {
                      formProps.setFieldValue('statuses', newValues);
                    }}
                    options={statusTypes}
                    renderInput={params => (
                      <TextField
                        {...params}
                        data-testid='employee-status-filter-option'
                        label='Eligibility Status'
                      />
                    )}
                    renderOption={(rootProps, option, { selected }) => (
                      <MenuItem
                        {...rootProps}
                        data-testid={`menu-item-${option}`}
                        data-value={option}
                        style={{ whiteSpace: 'small' }}>
                        <Checkbox
                          checked={selected}
                          data-value={option}
                          sx={{ py: 0 }}
                        />
                        {option}
                      </MenuItem>
                    )}
                    size='small'
                    value={formProps.values.statuses}
                  />
                </FormControl>

                {!props.isStateIRA && (
                  <>
                    <Divider style={{ width: '100%' }} textAlign='left'>
                      Pre-tax Deferral
                    </Divider>

                    <FormControl fullWidth>
                      <Field
                        InputProps={{
                          'aria-placeholder': 'Filter by Pre-tax Deferral',
                          endAdornment: (
                            <InputAdornment position='end'>
                              <Search />
                            </InputAdornment>
                          ),
                          placeholder: 'From',
                          sx: { '& .MuiOutlinedInput-input': { pb: 1, pt: 1 } }
                        }}
                        as={TextField}
                        data-testid='filter-pretax-deferral-start'
                        id='preTaxDeferralStart'
                        name='preTaxDeferralStart'
                        variant='outlined'
                      />
                    </FormControl>

                    <FormControl fullWidth>
                      <Field
                        InputProps={{
                          'aria-placeholder': 'Filter by Pre-tax Deferral',
                          endAdornment: (
                            <InputAdornment position='end'>
                              <Search />
                            </InputAdornment>
                          ),
                          placeholder: 'To',
                          sx: { '& .MuiOutlinedInput-input': { pb: 1, pt: 1 } }
                        }}
                        as={TextField}
                        data-testid='filter-pretax-deferral-end'
                        id='filter-pretax-deferral-end'
                        name='preTaxDeferralEnd'
                        variant='outlined'
                      />
                    </FormControl>
                  </>
                )}

                {props.isStateIRA ? (
                  <Divider style={{ width: '100%' }} textAlign='left'>
                    Deferral Rate
                  </Divider>
                ) : (
                  <Divider style={{ width: '100%' }} textAlign='left'>
                    Roth Deferral
                  </Divider>
                )}

                <FormControl fullWidth>
                  <Field
                    InputProps={{
                      'aria-placeholder': 'Filter by Roth Deferral',
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Search />
                        </InputAdornment>
                      ),
                      placeholder: 'From',
                      sx: { '& .MuiOutlinedInput-input': { pb: 1, pt: 1 } }
                    }}
                    as={TextField}
                    data-testid='filter-roth-deferral-start'
                    id='filter-roth-deferral-start'
                    name='rothDeferralStart'
                    variant='outlined'
                  />
                </FormControl>

                <FormControl fullWidth>
                  <Field
                    InputProps={{
                      'aria-placeholder': 'Filter by Roth Deferral',
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Search />
                        </InputAdornment>
                      ),
                      placeholder: 'To',
                      sx: { '& .MuiOutlinedInput-input': { pb: 1, pt: 1 } }
                    }}
                    as={TextField}
                    data-testid='filter-roth-deferral-end'
                    id='filter-roth-deferral-end'
                    name='rothDeferralEnd'
                    variant='outlined'
                  />
                </FormControl>

                <StyledBtnStack>
                  <Button
                    data-testid='submit'
                    disabled={!formProps.isValid}
                    type='submit'
                    variant='outlined'>
                    Apply
                  </Button>
                  {!areFiltersEmpty && (
                    <Button
                      data-testid='reset'
                      onClick={() => {
                        formProps.resetForm();
                        props.updateFilters(initialPlanEmployeeFilters);
                      }}
                      startIcon={<CachedIcon />}
                      variant='text'>
                      Reset
                    </Button>
                  )}
                </StyledBtnStack>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </DataGridFilters>
  );
};
