import { CardPlaceholder } from '@/components/card';
import CollapsibleTable from '@/components/collapsible-table';
import { FormattedRolloverDto } from '@/models/RolloversDTO.model';
import { PlanService } from '@/services/Plan.service';
import { Card, CardHeader, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import PlanRolloverTableCell from './PlanRolloverTableCell.component';

interface PlanRolloversTabProps {
  sponsorPlanId: number;
  setQueryData: React.Dispatch<React.SetStateAction<Record<string, any>>>;
}

const columns = [
  { field: 'id', headerName: 'Rollover Request ID', width: 130 },
  { field: 'participantName', headerName: 'Participant Name', width: 130 },
  { field: 'participantId', headerName: 'Participant ID', width: 130 },
  { field: 'status', headerName: 'Status', width: 130 },
  { field: 'accountType', headerName: 'Account Type', width: 130 },
  { field: 'pretaxAmount', headerName: 'Pre-tax Amount', width: 130 },
  { field: 'rothAmount', headerName: 'Roth Amount', width: 130 },
  { field: 'createdAt', headerName: 'Created At Date', width: 130 },
  { field: 'updatedAt', headerName: 'Last Updated Date', width: 130 }
];

// todo: add query params once endpoints are ready
const PlanRolloversTab = (props: PlanRolloversTabProps): JSX.Element => {
  const rolloversQuery = useQuery<FormattedRolloverDto[]>(
    ['PlanService.getRollovers', props.sponsorPlanId],
    () => PlanService.getRollovers(props.sponsorPlanId),
    {
      staleTime: Infinity
    }
  );

  if (rolloversQuery.isSuccess) props.setQueryData(rolloversQuery.data);

  if (rolloversQuery.isFetching) {
    return <Typography>Loading...</Typography>;
  }
  if (rolloversQuery.isError === undefined) {
    return <Typography>Error retrieving rollovers</Typography>;
  }
  if (rolloversQuery.data && rolloversQuery.data.length === 0) {
    return (
      <Card
        elevation={0}
        sx={{
          fontSize: theme => theme.spacing(2.4),
          padding: theme => theme.spacing(2),
          textAlign: 'center'
        }}>
        <Typography
          sx={{ marginBottom: theme => theme.spacing(1), textAlign: 'left' }}
          variant='h5'>
          Rollovers
        </Typography>
        <CardPlaceholder
          data-testid='no-data-rollovers-plan'
          subtitle='No data for this plan'
        />
      </Card>
    );
  }

  return (
    <>
      <Card className='Plan-Contributions_Data' elevation={0}>
        <CardHeader title='Rollovers' />
        <CollapsibleTable
          backgroundPaperElevation={0}
          cellComponent={PlanRolloverTableCell}
          columns={columns}
          rootPaperElevation={0}
          tableData={rolloversQuery.data}
        />
      </Card>
    </>
  );
};

export default PlanRolloversTab;
