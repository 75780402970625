import DatePicker from '@/components/date-picker/DatePicker';
import EARLIEST_POSSIBLE_DATE from '@/consts/dates.constants';
import { FormControl, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import dayjs from 'dayjs';
import React from 'react';

interface ActionCenterDetailsBulkEmailProps {
  setDetails: (details: Record<string, any>) => void;
  details: Record<string, any>;
}

const useStyles = makeStyles((theme: Theme) => ({
  datePicker: {
    width: theme.spacing(25)
  }
}));

export const ActionCenterDetailsBulkEmail: React.FunctionComponent<
  ActionCenterDetailsBulkEmailProps
> = ({ details, setDetails }) => {
  const classes = useStyles();

  return (
    <div data-testid='action-center-details-filter-bulk-email'>
      <FormControl fullWidth size='small'>
        <DatePicker
          className={classes.datePicker}
          data-testid='action-center-bulk-email-effective-date'
          disableFuture
          format='MM/DD/YYYY'
          helperText='Effective Date'
          label='Effective Date'
          maxDate={dayjs()}
          minDate={dayjs(EARLIEST_POSSIBLE_DATE)}
          name='effectiveDate'
          onChange={e => {
            setDetails({ date: e.target.value });
          }}
          size='small'
          value={details.date}
          variant='outlined'
        />
      </FormControl>
    </div>
  );
};
