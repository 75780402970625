import { ClientSuccessManager as ClientSuccessManagerInterface } from '@/models/ClientSuccessManager.model';
import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

import { isArray } from 'lodash';

class ClientSuccessManager {
  convertToApiState = Helper.convertValueToApiState;

  csms: ClientSuccessManagerInterface[] | undefined;

  options: string[] | undefined;

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.output = props.clientSuccessManagerName;
    this.csms = props.csms;
    this.options = (isArray(props.csms) ? [] : []).map(
      (csm: ClientSuccessManagerInterface) => csm.name
    );
  }
}

export default ClientSuccessManager;
