import { useSnackbar } from '@/contexts/SnackBarContext';
import { ParentAccountOrderDto } from '@/models/ops/parent-account-orders/ParentAccountOrderDTO.model';
import ParentAccountOrderService from '@/services/ops/parent-account-orders/ParentAccountOrder.service';
import formatters from '@/utils/Formatters';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SecurityOrderType } from '@vestwell-sub-accounting/models/common/SecurityOrderType';
import { TradeType } from '@vestwell-sub-accounting/models/common/TradeType';

import React from 'react';

type DeferParentAccountOrderDialogProps = {
  open: boolean;
  onClose: () => void;
  parentAccountOrder: ParentAccountOrderDto;
  onDeferredParentAccountOrders?: () => void;
};

const DeferParentAccountOrderDialog = ({
  open,
  onClose,
  parentAccountOrder,
  onDeferredParentAccountOrders
}: DeferParentAccountOrderDialogProps): JSX.Element => {
  const { showSnackbar } = useSnackbar();

  const queryClient = useQueryClient();
  const deferParentAccountOrderMutation = useMutation(
    ['ParentAccountOrderService.defer'],
    (id: number) => {
      return ParentAccountOrderService.defer(id);
    },
    {
      onError: (error: any) => {
        console.error(error);
        showSnackbar({
          message: 'Error deferring Parent Account Order',
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: 'Parent Account Order deferred',
          severity: 'success'
        });
        // Invalidate all queries to ensure any related components reflect these changes
        queryClient.invalidateQueries();
        onClose();
        if (typeof onDeferredParentAccountOrders === 'function') {
          onDeferredParentAccountOrders();
        }
      }
    }
  );

  let displayUnitsAmount = '';
  if (
    [SecurityOrderType.DOLLAR_ALL, SecurityOrderType.DOLLAR_ORDER].includes(
      parentAccountOrder.securityOrderType
    )
  ) {
    displayUnitsAmount = parentAccountOrder.orderAmount
      ? formatters.formatDollars(parentAccountOrder.orderAmount)
      : '';
  } else {
    displayUnitsAmount = parentAccountOrder.orderUnits
      ? formatters.formatDecimal(parentAccountOrder.orderUnits, 3)
      : '';
  }

  // fetch friendly display name for tradeType
  const displayTradeType = formatters.getValueKey(
    TradeType,
    parentAccountOrder?.tradeType
  );

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      onClose={() => {
        onClose();
      }}
      open={open}>
      <DialogTitle
        sx={{
          pb: 1 // there is an unknown rule somewhere setting 8px important padding to the top of DialogContent so we have to compensate here
        }}>
        Defer Parent Account Order
      </DialogTitle>
      <DialogContent
        sx={{
          p: 0
        }}>
        <Box
          data-testid='parent-account-order-defer-summary'
          sx={{
            bgcolor: theme => theme.palette.primary.light,
            px: 3.25,
            py: 2
          }}>
          <Typography>
            {displayUnitsAmount} {formatters.displayCase(displayTradeType)}{' '}
            order for {parentAccountOrder.security.symbol} (
            {parentAccountOrder.security.cusip})
            {parentAccountOrder.tradeDate
              ? `, traded on ${formatters.formatFromIsoDateCustom(
                  parentAccountOrder.tradeDate,
                  'MM/DD/YYYY'
                )}`
              : ''}
          </Typography>
          <Typography
            sx={{ color: theme => theme.palette.grey[600], fontSize: 14 }}>
            Order ID: {parentAccountOrder.ourParentOrderId}
          </Typography>
        </Box>
        <Box sx={{ p: 3 }}>
          <Typography sx={{ mb: 1 }}>
            Deferring this order will close this order and all the related
            pending transactions will be cancelled. This order should only be
            deferred if you know that it will NOT be executed by the
            custodian/fund company.
          </Typography>
          <Typography>
            This action only affects the selected order, if you are trying to
            defer an entire request you should defer via the Conductor tab.
          </Typography>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions
        sx={{
          px: 3,
          py: 2.25
        }}>
        <Button
          onClick={() => {
            onClose();
          }}>
          Nevermind
        </Button>

        <LoadingButton
          loading={deferParentAccountOrderMutation.isLoading}
          onClick={() => {
            deferParentAccountOrderMutation.mutate(parentAccountOrder.id);
          }}
          variant='contained'>
          Defer
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default DeferParentAccountOrderDialog;
