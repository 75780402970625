import CollapsibleTable from '@/components/collapsible-table';
import LinearLoading from '@/components/linear-loading';
import SimpleDropdown from '@/components/simple-dropdown';
import ConversionDetailsCollapsibleComponent from '@/routes/ops/conversion/ConversionDetailsCollapsible.component';
import ConversionsTableCell from '@/routes/ops/conversion/ConversionsTableCell.component';
import { PlanService } from '@/services/Plan.service';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Paper, Stack, TextField, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { Form, Formik } from 'formik';
import { useState } from 'react';

const columns = [
  { field: 'planName', headerName: 'Name', width: 130 },
  { field: 'blackoutDate', headerName: 'Blackout End Date', width: 130 },
  { field: 'planAvailableBalance', headerName: 'Plan Balance', width: 130 },
  { field: 'yos', headerName: 'YoS', width: 130 },
  { field: 'rothBasis', headerName: '# Roth Basis', width: 130 },
  { field: 'cashAllocationStatus', headerName: 'Cash Allocation', width: 130 },
  { field: 'loanStatus', headerName: 'Loan', width: 130 },
  { field: 'action', headerName: 'Action', width: 130 }
];

const ConversionManagementRoute = (): JSX.Element => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [byPlan, setByPlan] = useState(undefined);
  const [status, setStatus] = useState(undefined);

  const conversionsQuery = useQuery(
    ['PlanService.getPageConversions', rowsPerPage, pageNumber, byPlan, status],
    async () => {
      return PlanService.getPageConversions(
        rowsPerPage,
        pageNumber,
        byPlan,
        status
      );
    }
  );

  const handleSubmit = values => {
    setByPlan(values.planId || undefined);
    setStatus(values.status || undefined);
    setPageNumber(1);
  };

  return (
    <>
      <Typography my={4} variant='h4'>
        Conversion
      </Typography>

      <Paper sx={{ my: 2 }} variant='outlined'>
        <div>
          <Formik
            initialValues={{
              planId: '',
              status: ''
            }}
            onSubmit={handleSubmit}>
            {formik => (
              <Form data-testid='query-form'>
                <Stack direction='row' maxWidth='80%' mx={2} my={2} spacing={2}>
                  <TextField
                    InputProps={{
                      endAdornment: <SearchIcon color='action' />
                    }}
                    data-testid='search-plans'
                    fullWidth
                    name='planId'
                    onChange={formik.handleChange}
                    placeholder='Find plan by ID or name'
                    size='small'
                    title='Find plan by ID or name'
                    value={formik.values.planId}
                    variant='outlined'
                  />
                  <SimpleDropdown
                    fieldId='fileOwner'
                    fieldName='File Owner'
                    fieldOptions={[]}
                    onChange={event => {
                      formik.setFieldValue('fileOwner', event.target.value);
                      setPageNumber(1);
                    }}
                    size='small'
                  />
                  <SimpleDropdown
                    fieldId='status'
                    fieldName='Status'
                    fieldOptions={[]}
                    onChange={event => {
                      formik.setFieldValue('status', event.target.value);
                      setPageNumber(1);
                    }}
                    size='small'
                  />
                  <Button
                    data-testid='billing-apply-button'
                    type='submit'
                    variant='outlined'>
                    Apply
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </div>
        {conversionsQuery.isLoading ? (
          <LinearLoading />
        ) : (
          <CollapsibleTable
            cellComponent={ConversionsTableCell}
            collapsibleComponent={ConversionDetailsCollapsibleComponent}
            columns={columns}
            data-testid='conversion-management-table-component'
            pager={{
              metaCount: conversionsQuery.data?.meta.count,
              onPageNumberChanged: (zeroIndexedPageNumber: number) => {
                return setPageNumber(zeroIndexedPageNumber + 1);
              },
              onRowsPerPageChanged: (newRowsPerPage: number) => {
                return setRowsPerPage(newRowsPerPage);
              },
              pageNumber: pageNumber - 1,
              rowsPerPage
            }}
            tableData={conversionsQuery?.data?.data || []}
          />
        )}
      </Paper>
    </>
  );
};

export default ConversionManagementRoute;
