import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { IngestionActivity } from '@/models/BulkEmployer.model';
import { ActionCenterService } from '@/services/ActionCenter.service';
import InMemoryFileDownloadService from '@/services/InMemoryFileDownloadService.service';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Button, Link, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import Grid from '@mui/material/Unstable_Grid2';
import { useMutation } from '@tanstack/react-query';

import { useToggle } from 'react-use';

import { BulkEmployerPreviewDialog } from './BulkEmployerPreviewDialog.component';

interface ActionHistoryEmployerTableCollapsibleComponentProps {
  row: IngestionActivity;
}

export const ActionHistoryEmployerTableCollapsibleComponent = (
  props: ActionHistoryEmployerTableCollapsibleComponentProps
): JSX.Element => {
  const { row } = props;
  const [open, toggleOpen] = useToggle(false);

  const { mutate: downloadDocument } = useMutation(
    ['ActionCenterService.getDocument'],
    (documentId: number) => {
      return ActionCenterService.getDocument(documentId);
    },
    {
      onSuccess: data => {
        InMemoryFileDownloadService.triggerFileDownload(
          data.base64Data,
          data.originalFileName
        );
      }
    }
  );

  const downloadEmployerListReportMutation = useMutation(
    ['ActionCenterService.downloadEmployerListReport'],
    (documentId: number) =>
      ActionCenterService.downloadEmployerListReport(documentId),
    {
      onSuccess: data => {
        InMemoryFileDownloadService.triggerFileDownload(
          data.data,
          data.fileName
        );
      }
    }
  );

  return (
    <>
      <Grid container padding={[4, 4]} sx={{ backgroundColor: grey[100] }}>
        <Grid xs={6}>
          <Stack
            data-testid={`action-history-table-collapsible-action-${row.documentUploadId}`}
            spacing={2}>
            <Typography variant='subtitle1'>Uploaded File</Typography>
            <TextStack direction='column'>
              <TextStackItem>
                <TextLabel>Uploaded File</TextLabel>
                <TextValue>
                  <Stack direction='column'>
                    {row.originalFileName}
                    <Link
                      color='primary'
                      onClick={() =>
                        row.documentUploadId
                          ? downloadDocument(row.documentUploadId)
                          : null
                      }
                      sx={{ cursor: 'pointer' }}>
                      <Stack direction='row'>
                        Download
                        <FileDownloadOutlinedIcon fontSize='small' />
                      </Stack>
                    </Link>
                  </Stack>
                </TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Results</TextLabel>
                <TextValue>
                  <Stack direction='column'>
                    {`${row.originalFileName.replace(' ', '_').substring(0, row.originalFileName.lastIndexOf('.'))}_${row.status}_report.csv`}
                    <Link
                      color='primary'
                      onClick={() =>
                        downloadEmployerListReportMutation.mutate(row.id)
                      }
                      sx={{ cursor: 'pointer' }}>
                      <Stack direction='row'>
                        Download
                        <FileDownloadOutlinedIcon fontSize='small' />
                      </Stack>
                    </Link>
                  </Stack>
                </TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>New Employers</TextLabel>
                <TextValue>{row.summary.newRows}</TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Existing Employers</TextLabel>
                <TextValue>{row.summary.existingRows}</TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Errors</TextLabel>
                <TextValue>{row.summary.invalidRows}</TextValue>
              </TextStackItem>
            </TextStack>
          </Stack>
        </Grid>
        {row.status === 'validated' && (
          <Grid xs={6}>
            <Typography mb={2} variant='subtitle1'>
              Ingestion Results
            </Typography>
            <Button onClick={toggleOpen} variant='contained'>
              Resume
            </Button>
          </Grid>
        )}
      </Grid>
      <BulkEmployerPreviewDialog
        data={{ id: row.id, summary: row.summary }}
        handleClose={toggleOpen}
        onConfirm={toggleOpen}
        open={open}
      />
    </>
  );
};
