import {
  GLOBAL_SEARCH_ADVISORS_TYPE,
  GLOBAL_SEARCH_PARTICIPANTS_PLANS_TYPE,
  GLOBAL_SEARCH_SPONSORS_TYPE
} from '@/components/main-layout/GlobalSearch/GlobalSearchResults/constants';
import useShowMore from '@/hooks/useShowMore';
import {
  GlobalSearchAdvisorDto,
  GlobalSearchDto,
  GlobalSearchParticipantDto,
  GlobalSearchPlanDto,
  GlobalSearchSponsorDto
} from '@/models/GlobalSearchDTO.model';
import { UserAssociationsDto } from '@/models/UserAssociationsDTO.model';
import GlobalSearchService from '@/services/GlobalSearch.service';
import { Search } from '@mui/icons-material';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import ClearIcon from '@mui/icons-material/Clear';
import CollectionsBookmarkOutlinedIcon from '@mui/icons-material/CollectionsBookmarkOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { InputAdornment, Paper, TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import React, { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';

import AssociateSearchList from './AssociateSearchList.component';
import AssociateSearchItem from './AssosiateSearchItem.component';

const useStyles = makeStyles((theme: Theme) => ({
  advisorIcon: {
    color: theme.palette.error.light
  },
  businessIcon: {
    color: theme.palette.error.light
  },
  clearItem: {
    cursor: 'pointer',
    paddingRight: theme.spacing(1.2)
  },
  emailResult: {
    backgroundColor: '#FFFFFF',
    borderRadius: '0 0 2px 2px',
    maxHeight: '350px',
    minHeight: '50px',
    overflowX: 'auto',
    position: 'absolute',
    width: theme.spacing(50),
    zIndex: 1000
  },
  noResults: {
    marginTop: theme.spacing(2)
  },
  root: {
    backgroundColor: 'rgba(33, 150, 243, 0.04)',
    border: '1px solid #E0E0E0',
    borderRadius: theme.spacing(0.5),
    height: 148,
    marginTop: theme.spacing(5.5),
    width: '100%'
  },
  rootSelected: {
    backgroundColor: 'rgba(33, 150, 243, 0.04)',
    border: '1px solid #E0E0E0',
    borderRadius: theme.spacing(0.5),
    height: 227,
    marginTop: theme.spacing(5.5),
    width: '100%'
  },
  saverIcon: {
    color: theme.palette.success.dark
  },
  searchIcon: {
    paddingLeft: theme.spacing(1.2)
  },
  searchInput: {
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
    height: theme.spacing(5),
    paddingTop: theme.spacing(0.8),
    width: theme.spacing(50)
  }
}));

interface UserManagementAssociateSearchProps {
  onAdd: (
    item: GlobalSearchPlanDto &
      GlobalSearchParticipantDto &
      GlobalSearchAdvisorDto,
    id: number,
    type: 'sponsor' | 'advisor' | 'participant'
  ) => void;
  hideValues?: UserAssociationsDto;
  canWriteUserManagementParticipant: boolean;
  canWriteUserManagement: boolean;
}

const UserManagementAssociateSearch: React.FunctionComponent<
  UserManagementAssociateSearchProps
> = ({
  onAdd,
  hideValues = { advisor: [], participant: [], sponsor: [] },
  canWriteUserManagementParticipant,
  canWriteUserManagement
}): JSX.Element => {
  const classes = useStyles();

  const [searchTerm, setSearchTerm] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [abortController, setAbortController] = useState(new AbortController());
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  const resultsDivRef = useRef<any>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        resultsDivRef.current &&
        !resultsDivRef.current.contains(event.target)
      ) {
        setIsFocused(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [resultsDivRef]);

  const {
    isLoading: isBusinessLoading,
    isPaginated: isBusinessPaginated,
    isPaginationLoading: isBusinessPaginationLoading,
    showMore: showMoreBusiness,
    data: businessData,
    isError: isBusinessError,
    lastDataChunk: lastBusinessDataChunk
  } = useShowMore<GlobalSearchDto, GlobalSearchSponsorDto>(
    ['searchBusinessResultQuery', debouncedSearchTerm],
    page =>
      GlobalSearchService.getGlobalSearchResult(
        debouncedSearchTerm,
        page,
        2,
        GLOBAL_SEARCH_SPONSORS_TYPE
      ),
    Boolean(debouncedSearchTerm),
    data => data.data.sponsors.data,
    data => Boolean(data?.data.sponsors.links.next)
  );

  const {
    isLoading: isSaversLoading,
    isPaginated: isSaversPaginated,
    isPaginationLoading: isSaversPaginationLoading,
    showMore: showMoreSavers,
    data: saversData,
    isError: isSaversError,
    lastDataChunk: lastSaversDataChunk
  } = useShowMore<GlobalSearchDto, GlobalSearchParticipantDto>(
    ['searchSaversResultQuery', debouncedSearchTerm],
    page =>
      GlobalSearchService.getGlobalSearchResult(
        debouncedSearchTerm,
        page,
        2,
        GLOBAL_SEARCH_PARTICIPANTS_PLANS_TYPE,
        abortController?.signal
      ),
    Boolean(debouncedSearchTerm),
    data => data.data.participants.data,
    data => Boolean(data?.data.participants.links.next)
  );

  const {
    isLoading: isAdvisorsLoading,
    isPaginated: isAdvisorsPaginated,
    isPaginationLoading: isAdvisorsPaginationLoading,
    showMore: showMoreAdvisors,
    data: advisorsData,
    lastDataChunk: lastAdvisorsDataChunk,
    isError: isAdvisorsError
  } = useShowMore<GlobalSearchDto, GlobalSearchAdvisorDto>(
    ['searchAdvisorsResultQuery', debouncedSearchTerm],
    page =>
      GlobalSearchService.getGlobalSearchResult(
        debouncedSearchTerm,
        page,
        2,
        GLOBAL_SEARCH_ADVISORS_TYPE
      ),
    Boolean(debouncedSearchTerm),
    data => data.data.advisors.data,
    data => Boolean(data?.data.advisors.links.next)
  );

  const businessDataShow = businessData?.filter(
    business => !hideValues.sponsor.includes(business.sponsorId)
  );

  const saversDataShow = saversData?.filter(
    participant => !hideValues.participant.includes(participant.participantId)
  );
  const advisorsDataShow = advisorsData?.filter(
    advisor => !hideValues.advisor.includes(advisor.advisorId)
  );

  useEffect(() => {
    const lastAdvisorsDataChunkShow = lastAdvisorsDataChunk?.filter(
      advisor => !hideValues.advisor.includes(advisor.advisorId)
    );
    if (
      advisorsData &&
      (!advisorsDataShow?.length || !lastAdvisorsDataChunkShow?.length) &&
      !isAdvisorsLoading &&
      !isAdvisorsPaginationLoading &&
      isAdvisorsPaginated &&
      !isAdvisorsError
    ) {
      showMoreAdvisors();
    }
  }, [
    isAdvisorsLoading,
    isAdvisorsPaginationLoading,
    advisorsData,
    isAdvisorsPaginated,
    showMoreAdvisors,
    isAdvisorsError,
    hideValues.advisor,
    lastAdvisorsDataChunk,
    advisorsDataShow?.length
  ]);

  useEffect(() => {
    const lastBusinessDataChunkShow = lastBusinessDataChunk?.filter(
      business => !hideValues.sponsor.includes(business.sponsorId)
    );
    if (
      businessData &&
      (!businessDataShow?.length || !lastBusinessDataChunkShow?.length) &&
      !isBusinessLoading &&
      !isBusinessPaginationLoading &&
      isBusinessPaginated &&
      !isBusinessError
    ) {
      showMoreBusiness();
    }
  }, [
    isBusinessLoading,
    isBusinessPaginationLoading,
    businessData,
    isBusinessPaginated,
    showMoreBusiness,
    isBusinessError,
    hideValues.sponsor,
    lastBusinessDataChunk,
    businessDataShow?.length
  ]);

  useEffect(() => {
    const lastSaversDataChunkShow = lastSaversDataChunk?.filter(
      participant => !hideValues.participant.includes(participant.participantId)
    );
    if (
      saversData &&
      (!saversDataShow?.length || !lastSaversDataChunkShow?.length) &&
      !isSaversLoading &&
      !isSaversPaginationLoading &&
      isSaversPaginated &&
      !isSaversError
    ) {
      showMoreSavers();
    }
  }, [
    isSaversLoading,
    isSaversPaginationLoading,
    saversData,
    isSaversPaginated,
    showMoreSavers,
    isSaversError,
    hideValues.participant,
    lastSaversDataChunk,
    saversDataShow?.length,
    hideValues.sponsor
  ]);
  return (
    <div data-testid='user-management-associate-search-search'>
      <div ref={resultsDivRef} style={{ display: 'inline-block' }}>
        <TextField
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment
                className={classes.clearItem}
                onClick={() => setSearchTerm('')}
                position='end'>
                <ClearIcon />
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment className={classes.searchIcon} position='start'>
                <Search />
              </InputAdornment>
            )
          }}
          className={classes.searchInput}
          data-testid='user-management-associate-search-input'
          onChange={e => {
            abortController.abort();
            setAbortController(new AbortController());
            setSearchTerm(e.target.value);
          }}
          onFocus={() => {
            setIsFocused(true);
          }}
          placeholder='Search persona to associate user with'
          size='small'
          value={searchTerm}
          variant='standard'
        />

        {isFocused && searchTerm.trim().length > 0 && (
          <Paper
            className={classes.emailResult}
            data-testid='user-management-associate-search-result'
            elevation={2}>
            <AssociateSearchList
              isError={isAdvisorsError}
              isLoading={isAdvisorsLoading || isAdvisorsPaginationLoading}
              isNoResults={
                !isAdvisorsLoading &&
                !isAdvisorsError &&
                advisorsDataShow?.length === 0
              }
              isPaginated={isAdvisorsPaginated && !isAdvisorsLoading}
              isPaginationLoading={isAdvisorsPaginationLoading}
              listTitle='ADVISORS'
              onShowMore={showMoreAdvisors}>
              {advisorsDataShow?.length &&
                advisorsDataShow.map(advisor => (
                  <AssociateSearchItem
                    hideAddButton={!canWriteUserManagement}
                    icon={
                      <CardTravelIcon
                        className={classes.advisorIcon}
                        style={{ color: '#ED6C02' }}
                      />
                    }
                    item={advisor}
                    itemDescriptions={[advisor.firmName]}
                    itemId={advisor.advisorId}
                    itemIdText='ADVISOR ID:'
                    key={advisor.advisorId}
                    onItemAdd={item => {
                      onAdd(item, item.advisorId, 'advisor');
                    }}
                    primaryItemText={`${advisor.firstName} ${advisor.lastName}`}
                  />
                ))}
            </AssociateSearchList>

            <AssociateSearchList
              isError={isBusinessError}
              isLoading={isBusinessLoading || isBusinessPaginationLoading}
              isNoResults={
                !isBusinessLoading &&
                !isBusinessError &&
                businessDataShow?.length === 0
              }
              isPaginated={isBusinessPaginated && !isBusinessLoading}
              isPaginationLoading={isBusinessPaginationLoading}
              listTitle='BUSINESSES'
              onShowMore={() => {
                showMoreBusiness();
              }}>
              {businessDataShow?.length &&
                businessDataShow.map(business => (
                  <AssociateSearchItem
                    hideAddButton={!canWriteUserManagement}
                    icon={
                      <CollectionsBookmarkOutlinedIcon
                        className={classes.businessIcon}
                      />
                    }
                    item={business}
                    itemDescriptions={[
                      business.primaryContactEmail,
                      business.doingBusinessAs || '--'
                    ]}
                    itemId={business.sponsorId}
                    itemIdText='SPONSOR ID:'
                    key={business.sponsorId}
                    onItemAdd={item => {
                      onAdd(item, item.sponsorId, 'sponsor');
                    }}
                    primaryItemText={business.companyName}
                  />
                ))}
            </AssociateSearchList>

            <AssociateSearchList
              isError={isSaversError}
              isLoading={isSaversLoading || isSaversPaginationLoading}
              isNoResults={
                !isSaversLoading &&
                !isSaversError &&
                saversDataShow?.length === 0
              }
              isPaginated={isSaversPaginated && !isSaversLoading}
              isPaginationLoading={isSaversPaginationLoading}
              listTitle='SAVERS'
              onShowMore={showMoreSavers}>
              {saversDataShow?.length &&
                saversDataShow.map(participant => (
                  <AssociateSearchItem
                    hideAddButton={!canWriteUserManagementParticipant}
                    icon={<PersonOutlineIcon className={classes.saverIcon} />}
                    item={participant}
                    itemDescriptions={[
                      participant.workEmail,
                      `Plan: ${participant.planName}`
                    ]}
                    itemId={participant.participantId}
                    itemIdText='PARTICIPANT ID:'
                    key={participant.participantId}
                    onItemAdd={item => {
                      onAdd(item, item.participantId, 'participant');
                    }}
                    primaryItemText={`${participant.firstName} ${
                      participant.middleName || ''
                    } ${participant.lastName}`}
                  />
                ))}
            </AssociateSearchList>
          </Paper>
        )}
      </div>
    </div>
  );
};

export default UserManagementAssociateSearch;
