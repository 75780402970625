import { ACRONYMS } from '@/consts/acronyms.constants';

export const cleanTemplateName = (name: string): string =>
  name
    .split(' ')
    .map(w => (ACRONYMS.includes(w.toUpperCase()) ? w.toUpperCase() : w))
    .join(' ');

export const cleanEmailSubject = (subject: string): string =>
  subject?.replaceAll('{', '')?.replaceAll('}', '');
