import AppConfig from '@/App.config';
import AccessControl from '@/components/access-control/AccessControl.component';
import Badge from '@/components/badge';
import LinearLoading from '@/components/linear-loading';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUserToken } from '@/contexts/UserTokenContext';
import useSponsor from '@/hooks/useSponsor';
import { UserRegistrationLinkResponseDto } from '@/models/CreateSponsorUserInviteDTO.model';
import { SponsorTeammateData } from '@/models/SponsorTeammates.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import SponsorService from '@/services/Sponsor.service';
import GroupIcon from '@mui/icons-material/Group';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Tooltip } from '@mui/material';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Sponsors } from '@vestwell-api/scala';

import React from 'react';

import AddAdminUsersButton from './ActionButtons/AddAdminUsersButton.component';
import PlanUsersActionButtons from './ActionButtons/PlanUsersActionButtons.component';
import PlanUsersTableCell from './PlanUsersTableCell';

interface PlanUsersTableProps {
  sponsorId: number;
  isVoyaPlan: boolean;
  isStateIRA: boolean;
}

interface Data {
  userId: string;
  email: string;
  status: string;
  action: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

export const DEFAULT_SPONSOR_ROLES = [
  'login',
  'payroll-administrator',
  'benefits-administrator',
  'company-administrator'
];

export const OWNER_SPONSOR_ROLES = [
  ...DEFAULT_SPONSOR_ROLES,
  'owner-and-creator'
];

const headCells: HeadCell[] = [
  {
    disablePadding: true,
    id: 'userId',
    label: 'ID',
    numeric: false
  },
  { disablePadding: false, id: 'email', label: 'Email', numeric: true },
  { disablePadding: false, id: 'status', label: 'Status', numeric: true },
  { disablePadding: false, id: 'action', label: 'Action', numeric: true }
];

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1)
    }
  })
);

const TableToolbar = () => {
  const classes = useToolbarStyles();

  return (
    <Toolbar className={classes.root}>
      <Typography component='div' id='tableTitle' variant='h5'>
        Users
      </Typography>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      fontSize: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold
    },
    noUsersIcon: {
      color: theme.palette.grey[400],
      fontSize: '48px'
    },
    paper: {
      marginBottom: theme.spacing(2),
      width: '100%'
    },
    root: {
      width: '100%'
    },
    tableRow: {
      '&:hover': {
        backgroundColor: theme.palette.grey[100]
      }
    },
    toastContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(1)
    }
  })
);

const PlanUsersTable = ({
  sponsorId,
  isStateIRA,
  isVoyaPlan
}: PlanUsersTableProps): JSX.Element => {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const regex = /^[a-zA-Z0-9]{6}$/;

  const sponsorQuery = useSponsor(sponsorId.toString());

  const sponsorUserInvitesData =
    useQuery<Sponsors.GetUserInvitesV2.ResponseBody>(
      ['SponsorService.getSponsorUserInvitesV2', sponsorId.toString()],
      () => SponsorService.getSponsorUserInvitesV2(sponsorId)
    );

  const getSponsorUserInvite = async () => {
    const queryData = await queryClient.fetchQuery(
      [
        'SponsorService.getSponsorUserInvites',
        sponsorId.toString(),
        sponsorQuery.data?.data?.attributes?.primaryContact?.email
      ],
      () =>
        SponsorService.getSponsorUserInvites(
          sponsorId,
          sponsorQuery.data?.data?.attributes?.primaryContact?.email
        )
    );
    return queryData;
  };

  const expiredUserInvites = new Map();
  sponsorUserInvitesData.data?.forEach(
    (userInvite: Sponsors.GetUserInvitesV2.ResponseBody[0]) => {
      if (new Date() > new Date(userInvite?.expiresAt || '')) {
        expiredUserInvites.set(userInvite?.inviteCode, true);
      }
    }
  );

  const notExpiredUserInvites = new Map();
  sponsorUserInvitesData.data?.forEach(
    (userInvite: Sponsors.GetUserInvitesV2.ResponseBody[0]) => {
      if (new Date() <= new Date(userInvite?.expiresAt || '')) {
        notExpiredUserInvites.set(userInvite?.inviteCode, userInvite);
      }
    }
  );

  const lockedUserInvites = new Map();
  sponsorUserInvitesData.data?.forEach(
    (userInvite: Sponsors.GetUserInvitesV2.ResponseBody[0]) => {
      if (userInvite?.verificationAttempts === 0) {
        lockedUserInvites.set(userInvite?.inviteCode, true);
      }
    }
  );

  const sponsorUserInvite = sponsorUserInvitesData.data?.filter(
    invite =>
      invite.email ===
      sponsorQuery.data?.data?.attributes?.primaryContact?.email
  )[0];

  const {
    data: sponsorTeammates,
    isFetching,
    isSuccess,
    isError,
    ...sponsorTeammatesQuery
  } = useQuery<SponsorTeammateData[]>(
    ['SponsorService.getSponsorTeammates', sponsorId],
    () => SponsorService.getSponsorTeammates(sponsorId),
    {
      enabled: Boolean(sponsorId),
      staleTime: Infinity
    }
  );

  const { token, userHasValidToken } = useUserToken();

  const refetchUsers = () => {
    sponsorUserInvitesData.refetch();
    sponsorTeammatesQuery.refetch();
  };

  const userRoles = useQuery(
    ['SponsorService.getUserRoles'],
    () => SponsorService.getUserRoles(),
    {
      enabled: userHasValidToken,
      staleTime: Infinity
    }
  );

  const deleteUserSponsorRelationshipsMutation = useMutation(
    ['SponsorService.deleteUserSponsorRelationship'],
    (data: { userId: string | number; entityId: number }) => {
      return SponsorService.deleteUserSponsorRelationship(data.userId, {
        entityId: data.entityId
      });
    },
    {
      onError: () => {
        showSnackbar({
          message: `Failed!`,
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: `User has been removed`,
          severity: 'success'
        });
        refetchUsers();
      }
    }
  );

  const createUserInvite = useMutation(
    ['SponsorService.createSponsorUserInviteAndSendEmail'],
    (data: { id: string; email: string; userRoles: string[] | undefined }) => {
      return SponsorService.createSponsorUserInviteAndSendEmail(
        data.id,
        data.email,
        data.userRoles
      );
    },
    {
      onSuccess() {
        refetchUsers();
      }
    }
  );

  const removeInviteMutation = useMutation(
    ['SponsorService.deleteSponsorUserInvite'],
    (inviteId: string) => {
      return SponsorService.deleteSponsorUserInvite(inviteId);
    },
    {
      onSuccess() {
        refetchUsers();
      }
    }
  );

  const createSponsorWelcomeInviteMutation = useMutation(
    ['SponsorService.createSponsorPrimaryContactInvite'],
    () =>
      SponsorService.createSponsorPrimaryContactInvite(sponsorId, {
        deliveryMethod: 'email',
        email: sponsorQuery.data?.data?.attributes?.primaryContact?.email || ''
      })
  );

  const sendSponsorWelcomeInviteMutation = useMutation(
    ['SponsorService.sendSponsorWelcomeInvite'],
    () =>
      SponsorService.sendSponsorWelcomeInvite(
        sponsorId,
        +sponsorQuery.data?.data?.relationships?.plans?.data?.[0]?.id,
        sponsorQuery.data?.data?.attributes?.primaryContact?.email || '',
        sponsorUserInvite?.inviteCode || ''
      )
  );

  const removeTeamMember = async (userKey: string) => {
    const [sponsorTeammate] = (sponsorTeammates || []).filter(
      record => record.key === userKey
    );

    const userInvite = sponsorTeammate.relationships['user-invite']?.data?.id;

    if (sponsorTeammate.relationships?.user?.data?.id) {
      await deleteUserSponsorRelationshipsMutation.mutateAsync({
        entityId: sponsorId,
        userId: sponsorTeammate?.relationships?.user?.data?.id
      });
    }

    if (!sponsorTeammate.relationships?.user?.data?.id && !!userInvite) {
      await removeInviteMutation.mutateAsync(userInvite);
    }
  };

  const createInvite = async (
    email: string
  ): Promise<UserRegistrationLinkResponseDto> => {
    const newTeammate = {
      email,
      id: String(sponsorId),
      userRoles: userRoles?.data?.data
        ?.filter(role => DEFAULT_SPONSOR_ROLES.includes(role.attributes?.name))
        .map(role => role.id)
    };
    return createUserInvite.mutateAsync(newTeammate);
  };

  const removeRecordsForEmail = async (email: string) => {
    const records = (sponsorTeammates || []).filter(
      t => t.attributes?.email === email
    );
    const userAlreadyInvitedOrRegistered = records.length > 0;

    if (userAlreadyInvitedOrRegistered) {
      records.forEach(record => {
        if (record.key) {
          removeTeamMember(record.key);
        }
      });
    }
  };

  const resendInvite = async (email: string, isNewInvite?: boolean) => {
    try {
      await removeRecordsForEmail(email);
      const { registrationUrl } = await createInvite(email);

      showSnackbar({
        message: isNewInvite
          ? 'Invite was successfully sent!'
          : 'Invite has been sent',
        severity: 'success'
      });

      if (AppConfig.environment !== 'production') {
        console.log(`Registration url is: ${registrationUrl}`);
      }
    } catch (e) {
      showSnackbar({
        message: 'Something went wrong. Please try again',
        severity: 'error'
      });
    }
    refetchUsers();
  };

  const resendMainContactWelcomeEmail = async () => {
    const queryData = await getSponsorUserInvite();

    if (!queryData?.sponsorUserInviteCode) {
      await createSponsorWelcomeInviteMutation.mutateAsync();
    } else if (
      new Date() > new Date(queryData?.sponsorUserInviteExpiresAt || '')
    ) {
      await removeInviteMutation.mutateAsync(queryData?.sponsorUserInviteCode);
      await createSponsorWelcomeInviteMutation.mutateAsync();
    } else {
      await sendSponsorWelcomeInviteMutation.mutateAsync();
    }

    refetchUsers();

    showSnackbar({
      message: `Welcome Email sent to ${
        sponsorQuery.data?.data?.attributes?.primaryContact?.email || ''
      }`,
      severity: 'success'
    });
  };

  // TODO: Remove 'user:write'
  const hasWritePermission = token?.permissions?.includes(
    FeatureLevelPermissions.WRITE_COMPANY_TEAM_SETUP
  );

  const permissionFilteredCells = headCells.filter(
    cell => hasWritePermission || cell.id !== 'action'
  );

  return (
    <div className={classes.root} data-testid='users-table-card'>
      <Paper className={classes.paper} elevation={0} variant='outlined'>
        {isFetching && <LinearLoading />}
        <Box
          alignItems='baseline'
          display='flex'
          flexDirection='row'
          width='100%'>
          <TableToolbar />
          {isSuccess && !isError && (
            <AccessControl
              requiresOneOf={[
                FeatureLevelPermissions.WRITE_COMPANY_TEAM_SETUP,
                'user:write'
              ]}>
              <AddAdminUsersButton
                resendInvite={resendInvite}
                sponsorTeammates={sponsorTeammates || []}
              />
            </AccessControl>
          )}
        </Box>
        {isSuccess && !isError && !!sponsorQuery.data && (
          <TableContainer data-testid='users-table'>
            <Table aria-label='users table' aria-labelledby='tableTitle'>
              <TableHead>
                <TableRow>
                  {permissionFilteredCells.map(headCell => (
                    <TableCell className={classes.body} key={headCell.id}>
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {Boolean(sponsorTeammates?.length) && (
                <TableBody>
                  {(sponsorTeammates || [])
                    .sort(
                      (a, b) =>
                        Number(!!a.relationships?.user?.data?.id) -
                          Number(!!b.relationships?.user?.data?.id) ||
                        a.attributes.email.localeCompare(b.attributes?.email)
                    )
                    .sort(
                      (a, b) =>
                        Number(
                          regex.test(
                            b.relationships['user-invite']?.data?.id || ''
                          )
                        ) -
                        Number(
                          regex.test(
                            a.relationships['user-invite']?.data?.id || ''
                          )
                        )
                    )
                    .map(sponsorTeammate => {
                      const isRegistered =
                        !!sponsorTeammate.relationships?.user?.data?.id;
                      const isMainContact =
                        sponsorTeammate.attributes?.email ===
                          sponsorQuery.data?.data?.attributes?.primaryContact
                            ?.email &&
                        !isStateIRA &&
                        !isVoyaPlan;
                      const isExpired = expiredUserInvites.get(
                        sponsorTeammate.relationships['user-invite']?.data?.id
                      );
                      const isLocked = lockedUserInvites.get(
                        sponsorTeammate.relationships['user-invite']?.data?.id
                      );
                      const isSnailInvite = regex.test(
                        sponsorTeammate.relationships['user-invite']?.data
                          ?.id || ''
                      );
                      const invite = notExpiredUserInvites.get(
                        sponsorTeammate.relationships['user-invite']?.data?.id
                      );

                      return (
                        <TableRow
                          className={classes.tableRow}
                          data-testid='users-table-row'
                          key={sponsorTeammate.key}>
                          <PlanUsersTableCell
                            component='th'
                            id={sponsorTeammate.key}
                            scope='row'>
                            {sponsorTeammate.relationships?.user?.data?.id ??
                              EMPTY_FIELD_PLACEHOLDER}
                          </PlanUsersTableCell>
                          <PlanUsersTableCell>
                            {isSnailInvite && !isRegistered ? (
                              <Box
                                alignItems='center'
                                display='flex'
                                flexDirection='row'
                                width='100%'>
                                Access Code -{' '}
                                {
                                  sponsorTeammate.relationships['user-invite']
                                    ?.data?.id
                                }
                                <Tooltip
                                  data-testid='access-code-tooltip'
                                  title='First invite sent to employer. Code must be used with Employer FEIN to register.'>
                                  <InfoOutlinedIcon
                                    color='primary'
                                    data-testid='access-code-info-icon'
                                    sx={{ marginLeft: '0.25rem' }}
                                  />
                                </Tooltip>
                              </Box>
                            ) : (
                              <>
                                {sponsorTeammate.attributes?.email}
                                {isMainContact ? (
                                  <Typography variant='body2'>
                                    Main Contact
                                  </Typography>
                                ) : (
                                  ''
                                )}
                              </>
                            )}
                          </PlanUsersTableCell>
                          <PlanUsersTableCell>
                            <Box
                              alignItems='center'
                              display='flex'
                              flexDirection='row'
                              width='100%'>
                              <Badge
                                color={
                                  isSnailInvite
                                    ? isRegistered
                                      ? 'success'
                                      : isLocked
                                        ? 'warning'
                                        : 'neutral'
                                    : isRegistered
                                      ? 'success'
                                      : 'neutral'
                                }
                                size='medium'>
                                {isSnailInvite
                                  ? isRegistered
                                    ? 'Registered'
                                    : isLocked
                                      ? 'Locked'
                                      : 'Invitation Sent'
                                  : isRegistered
                                    ? 'Registered'
                                    : isExpired
                                      ? 'Expired'
                                      : 'Invitation Sent'}
                              </Badge>
                              {isSnailInvite && isLocked && (
                                <Tooltip
                                  data-testid='locked-tooltip'
                                  title='Employer entered the incorrect FEIN on 3 consecutive attempts.'>
                                  <InfoOutlinedIcon
                                    color='primary'
                                    data-testid='locked-info-icon'
                                    sx={{ marginLeft: '0.25rem' }}
                                  />
                                </Tooltip>
                              )}
                            </Box>
                          </PlanUsersTableCell>

                          <AccessControl
                            requiresOneOf={[
                              FeatureLevelPermissions.WRITE_COMPANY_TEAM_SETUP,
                              'user:write'
                            ]}>
                            <PlanUsersTableCell>
                              <PlanUsersActionButtons
                                firmId={
                                  sponsorQuery.data.data.relationships.firm.data
                                    .id
                                }
                                isExpired={isExpired}
                                isLocked={isLocked}
                                isMainContact={isMainContact}
                                isRegistered={isRegistered}
                                isSnailInvite={isSnailInvite}
                                refetchUsers={refetchUsers}
                                registrationInvite={invite}
                                removeTeamMember={removeTeamMember}
                                resendInvite={resendInvite}
                                resendMainContactWelcomeEmail={
                                  resendMainContactWelcomeEmail
                                }
                                rowKey={sponsorTeammate.key}
                                sponsorId={sponsorId}
                                sponsorName={
                                  sponsorQuery.data?.data?.attributes?.name
                                }
                                sponsorTeammates={sponsorTeammates || []}
                                sponsorUserInviteCode={
                                  sponsorTeammate.relationships['user-invite']
                                    ?.data.id
                                }
                                teammate={sponsorTeammate}
                              />
                            </PlanUsersTableCell>
                          </AccessControl>
                        </TableRow>
                      );
                    })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        )}
        {isSuccess && !isError && !sponsorTeammates?.length && (
          <Box
            alignItems='center'
            data-testid='no-users-container'
            display='flex'
            flexDirection='column'
            height='200px'
            justifyContent='center'
            width='100%'>
            <GroupIcon className={classes.noUsersIcon} />
            <Typography variant='body1'>No Users</Typography>
          </Box>
        )}
      </Paper>
    </div>
  );
};

export default PlanUsersTable;
