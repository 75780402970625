import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import React from 'react';

interface SortingIconProps {
  sort: null | 'asc' | 'desc';
}

const SortingIcon: React.FC<SortingIconProps> = props => {
  return (
    props.sort &&
    (props.sort === 'asc' ? (
      <ArrowUpwardIcon data-testid='asc-sorting-icon' fontSize='small' />
    ) : (
      <ArrowDownwardIcon data-testid='desc-sorting-icon' fontSize='small' />
    ))
  );
};

export default SortingIcon;
