import { CellComponentProps } from '@/components/collapsible-table';
import { RebalanceHistoryDto } from '@/models/RebalanceDTO.model';
import formatters from '@/utils/Formatters';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import React from 'react';

interface ParticipantRebalanceCollapsibleProps {
  row: RebalanceHistoryDto;
}

const headerCells = ['Account ID', 'Funding Source', 'Tracer ID', 'As-of Date'];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      ...theme.typography.caption,
      color: theme.palette.grey[700],
      fontSize: theme.spacing(2),
      height: theme.spacing(6),
      padding: '0',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    bold: {
      fontWeight: 'bold'
    },
    lastRowNoBorder: {
      '&:last-child th, &:last-child td': {
        borderBottom: 0
      }
    },
    table: {
      width: '50%'
    }
  })
);

const ParticipantRebalanceHistoryCell: React.FunctionComponent<
  CellComponentProps
> = (props: CellComponentProps) => {
  const { row, column } = props;

  let field = <Box>{row[column.field] || '--'}</Box>;

  if (column.field === 'createdAt' || column.field === 'updatedAt') {
    field = (
      <Box>
        {formatters.formatFromIsoDateCustom(row[column.field], 'M/DD/YY')}
      </Box>
    );
  }

  return (
    <TableCell
      component='th'
      key={`${row.createdAt},${row.updatedAt},${row.status}`}
      scope='row'
      sx={{ fontSize: theme => theme.spacing(2) }}>
      <Box>{field}</Box>
    </TableCell>
  );
};

export const ParticipantRebalanceCollapsible = (
  props: ParticipantRebalanceCollapsibleProps
): JSX.Element => {
  const classes = useStyles();
  const { row } = props;

  return (
    <TableContainer className={classes.table} data-testid='rebalance-table'>
      <Table>
        <TableHead>
          <TableRow>
            {headerCells.map(cellName => {
              return (
                <TableCell className={classes.bold} key={cellName}>
                  {cellName}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {row.rebalanceHistory.map(rebalance => {
            return (
              <TableRow className={classes.lastRowNoBorder} key={rebalance.id}>
                <TableCell>{rebalance.accountId}</TableCell>
                <TableCell>{rebalance.fundingSource || '--'}</TableCell>
                <TableCell>{rebalance.tracerId}</TableCell>
                <TableCell>{rebalance.asOfDate || '--'}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ParticipantRebalanceHistoryCell;
