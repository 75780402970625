/* eslint-disable sort-keys-plus/sort-keys */
import AccessControl from '@/components/access-control/AccessControl.component';
import CardInfoField from '@/components/card-info-field';
import CheckBoxForm from '@/components/checkbox-form/CheckBoxForm.component';
import CircularLoading from '@/components/circular-loading';
import SimpleDropdown from '@/components/simple-dropdown';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import STATE_CODES from '@/consts/states.constants';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import ParticipantInfo from '@/models/ParticipantInfo.model';
import { UpdateParticipantInfoDto } from '@/models/UpdateParticipantInfoDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { ParticipantAddressVerificationDialog } from '@/routes/participants/participant-detail/ParticipantAddressVerificationDialog.component';
import { getStatusLabelAndColor } from '@/routes/plans/plan-detail/PlanActivitiesTab/PlanCommunicationsTab/utils';
import ParticipantService from '@/services/Participant.service';
import { PlanService } from '@/services/Plan.service';
import formatters from '@/utils/Formatters';
import createParticipantContactValidationSchema from '@/utils/validations/ParticipantContactInfoValidationSchema';
import {
  CheckCircle,
  HelpOutlined,
  PendingOutlined,
  Warning
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Stack,
  Theme,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import dayjs from 'dayjs';
import { isEmpty, isEqual } from 'lodash';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';
import { ParticipantAddVerificationServiceResultResponse } from 'scala-sdk';

interface ParticipantContactInfo {
  workEmail: string;
  personalEmail: string;
  loginEmail?: string;
  phoneNumber: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  sponsorId: string;
  zip: string;
  mailingAddress1: string;
  mailingAddress2: string;
  mailingCity: string;
  mailingState: string;
  mailingZip: string;
}

interface ParticipantContactCardProps {
  participant: ParticipantInfo;
  isStateIRA: boolean;
  isVoyaPlan: boolean;
  planName: string;
  planType: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  contentBody: {
    paddingBottom: 0
  },
  editBtn: {
    margin: theme.spacing(0, 0, 1, 1)
  }
}));

const ParticipantContactCard: React.FunctionComponent<
  ParticipantContactCardProps
> = (props: ParticipantContactCardProps) => {
  const classes = useStyles();
  const { openDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { participant, isStateIRA, isVoyaPlan, planName, planType } = props;
  const [open, toggleOpen] = useToggle(false);
  const navigate = useNavigate();

  const addressVerification =
    useQuery<ParticipantAddVerificationServiceResultResponse>(
      ['ParticipantService.getAddressVerification', participant.participantId],
      () => {
        return ParticipantService.getAddressVerification(
          participant.participantId
        );
      },
      {
        enabled: Boolean(participant.participantId)
      }
    );

  const lastSentMail = useQuery(
    [
      'PlanService.getPlanParticipantsMailerLetters',
      participant.sponsorPlanId,
      participant.participantId
    ],
    () =>
      PlanService.getPlanParticipantsMailerLetters(participant.sponsorPlanId, {
        limit: 1,
        offset: 0,
        filter: participant.participantId
      }),
    {
      enabled: Boolean(participant.participantId),
      select: data => data.messages?.[0]
    }
  );

  const participantContact: ParticipantContactInfo = {
    address1: participant.address?.address1 || '',
    address2: participant.address?.address2 || '',
    city: participant.address?.city || '',
    loginEmail: participant.userEmail || EMPTY_FIELD_PLACEHOLDER,
    mailingAddress1: participant.mailingAddress?.address1 || '',
    mailingAddress2: participant.mailingAddress?.address2 || '',
    mailingCity: participant.mailingAddress?.city || '',
    mailingState: participant.mailingAddress?.state || '',
    mailingZip: participant.mailingAddress?.zip || '',
    personalEmail: participant.personalEmail || EMPTY_FIELD_PLACEHOLDER,
    phoneNumber: participant.phoneNumber || '',
    sponsorId: String(participant.relationships?.sponsor.data[0].id) || '',
    state: participant.address?.state || '',
    workEmail: participant.workEmail || EMPTY_FIELD_PLACEHOLDER,
    zip: participant.address?.zip || ''
  };

  const updateInfo = useMutation(
    (data: UpdateParticipantInfoDto) =>
      ParticipantService.updateContactInfo(
        participant.participantId as number,
        participant.sponsorPlanId as number,
        data
      ),
    {
      onError: () => {
        showSnackbar({
          message: 'Error!',
          severity: 'error'
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries([
          'ParticipantService.getParticipantById',
          String(participant.participantId)
        ]);
        queryClient.invalidateQueries([
          'ParticipantService.getAddressVerification',
          participant.participantId
        ]);
        showSnackbar({
          message: 'Contact Information has been saved!',
          severity: 'success'
        });
      }
    }
  );

  const onSeeAllClick = useCallback(() => {
    navigate(`/plans/${participant.sponsorPlanId}/activities`, {
      replace: true
    });
  }, [participant.sponsorPlanId]);

  const showMailingAddressWhen = (values: {
    mailingAddressEqualsPhysical: any;
  }) => !values.mailingAddressEqualsPhysical;

  const mailingAddressFields = isStateIRA
    ? {
        mailingAddress1: {
          initialValue: participant.mailingAddress?.address1,
          label: 'Mailing Address 1',
          showWhen: showMailingAddressWhen
        },
        mailingAddress2: {
          initialValue: participant.mailingAddress?.address2,
          label: 'Mailing Address 2',
          showWhen: showMailingAddressWhen
        },
        mailingCity: {
          initialValue: participant.mailingAddress?.city,
          label: 'Mailing City',
          showWhen: showMailingAddressWhen
        },
        mailingState: {
          component: (
            <SimpleDropdown
              fieldId='mailingState'
              fieldName='Mailing State'
              fieldValues={STATE_CODES}
            />
          ),
          initialValue: participant.mailingAddress?.state,
          label: 'Mailing State',
          showWhen: showMailingAddressWhen
        },
        mailingZip: {
          initialValue: participant.mailingAddress?.zip,
          label: 'Mailing Zip',
          showWhen: showMailingAddressWhen
        }
      }
    : null;

  const editParticipantInformationModalContentText = (
    <span style={{ display: 'block', textAlign: 'center' }}>
      Are you sure you want to edit information for
      <b>
        {' ' + participant.firstName}, {participant.lastName}
      </b>{' '}
      (-{participant.ssn?.slice(-4)}) from {planName} {planType} plan?
      <br /> <br />
      <b>Any changes made will trigger an email to the participant</b>
    </span>
  );

  const openEditDialog = () => {
    const participantAddressForEqualityCheck = {
      ...participant.address
    };
    delete participantAddressForEqualityCheck.address2;

    const addressAndMailingEqual = isEqual(
      participant.address,
      participant.mailingAddress
    );
    // alex: this additional check is done to equalize the same addresses but with differently saved "address2" field
    // physicalAddress2 = '' and mailingAddress2 = null
    const address2AreFalsy =
      isEqual(participantAddressForEqualityCheck, participant.mailingAddress) &&
      participant.address?.address2 === '';

    const checkForAddressEquality = addressAndMailingEqual || address2AreFalsy;
    const toggleCheckboxState = isStateIRA ? checkForAddressEquality : true;
    const editWarning = [];
    if (!isStateIRA && !isVoyaPlan) {
      editWarning.push({
        contentText: editParticipantInformationModalContentText,
        title: 'Edit Participant Information'
      });
    }

    return openDialog({
      actionButtons: {
        cancelButton: {
          children: 'CANCEl'
        },
        submitButton: {
          children: 'SAVE'
        }
      },
      disableSubmitWhenInvalid: isStateIRA,
      hideBackOnFirstStep: true,
      onSubmit: async values => {
        const updatedParticipantInfo = {} as ParticipantContactInfo;
        const keys = Object.keys(participantContact);
        keys.forEach(key => {
          const participantKey = key as keyof ParticipantContactInfo;
          const previousContactInfo = participantContact[participantKey];
          const submittedContactInfo = values[participantKey];

          if (
            participantKey.includes('mailing') &&
            submittedContactInfo !== undefined &&
            !values.mailingAddressEqualsPhysical &&
            previousContactInfo !== submittedContactInfo
          ) {
            updatedParticipantInfo[participantKey] =
              submittedContactInfo.trim();
          }

          if (
            participantKey.includes('mailing') &&
            submittedContactInfo !== undefined &&
            values.mailingAddressEqualsPhysical &&
            previousContactInfo !== submittedContactInfo
          ) {
            updatedParticipantInfo[participantKey] =
              submittedContactInfo.trim();
          }

          if (
            participantKey.includes('mailing') &&
            submittedContactInfo !== undefined &&
            values.mailingAddressEqualsPhysical &&
            !checkForAddressEquality
          ) {
            updatedParticipantInfo[participantKey] =
              submittedContactInfo.trim();
            updatedParticipantInfo.mailingAddress1 =
              participantContact.address1.trim();
            updatedParticipantInfo.mailingAddress2 =
              participantContact.address2.trim();
            updatedParticipantInfo.mailingCity = participantContact.city.trim();
            updatedParticipantInfo.mailingState =
              participantContact.state.trim();
            updatedParticipantInfo.mailingZip = participantContact.zip.trim();
          }

          if (
            !participantKey.includes('mailing') &&
            submittedContactInfo !== undefined &&
            previousContactInfo !== submittedContactInfo
          ) {
            updatedParticipantInfo[participantKey] =
              submittedContactInfo.trim();
          }
        });

        let sameAddress = false;
        if (
          isStateIRA &&
          values.mailingAddressEqualsPhysical &&
          !(addressAndMailingEqual || address2AreFalsy)
        ) {
          // alex: workaround to trigger physicalSameAsMailing if no address is changed
          // isabelle: added the || '' to allow the checkbox to save the mailing address same as physical
          updatedParticipantInfo.mailingAddress2 = values?.address2 || '';
          sameAddress = true;
        }

        if (isEmpty(updatedParticipantInfo) && !sameAddress) {
          return;
        }

        const body = {
          data: {
            attributes: {
              ...updatedParticipantInfo,
              physicalSameAsMailing: isStateIRA
                ? values.mailingAddressEqualsPhysical
                : false
            },
            id: participant.participantId as number,
            personalEmail:
              updatedParticipantInfo.personalEmail || participant.personalEmail,
            sponsorId: participant.relationships?.sponsor.data[0].id,
            type: 'participant',
            workEmail:
              updatedParticipantInfo.workEmail || participant.workEmail,
            shouldSendNotification: !isStateIRA && !isVoyaPlan
          }
        };

        await updateInfo.mutateAsync(body);
      },
      steps: [
        ...editWarning,
        {
          fields: {
            workEmail: {
              initialValue: participant.workEmail,
              label: isStateIRA ? 'Email' : 'Work Email',
              helperText: isStateIRA
                ? 'Optional, if mobile phone number is provided.  Registration invite will be sent to this email.'
                : ''
            },
            ...(isStateIRA
              ? {}
              : {
                  personalEmail: {
                    initialValue: participant.personalEmail,
                    label: 'Personal Email'
                  }
                }),
            phoneNumber: {
              initialValue: participant.phoneNumber,
              label: isStateIRA ? 'Mobile Phone' : 'Primary Phone',
              helperText: isStateIRA ? 'Optional, if email is provided.' : ''
            },
            address1: {
              initialValue: participant.address?.address1,
              label: 'Address Line 1'
            },
            address2: {
              initialValue: participant.address?.address2,
              label: 'Address Line 2'
            },
            city: {
              initialValue: participant.address?.city,
              label: 'City'
            },
            state: {
              component: (
                <SimpleDropdown
                  fieldId='state'
                  fieldName='State'
                  fieldValues={STATE_CODES}
                />
              ),
              initialValue: participant.address?.state,
              label: 'State'
            },
            zip: {
              initialValue: participant.address?.zip,
              label: 'Zip Code'
            },
            mailingAddressEqualsPhysical: {
              component: (
                <CheckBoxForm
                  data-testid='toggle-checkbox'
                  label='Mailing address is the same as physical address'
                  name='mailingAddressEqualsPhysical'
                />
              ),
              initialValue: toggleCheckboxState,
              label: 'Mailing address is the same as physical address',
              showWhen: () => isStateIRA
            },
            ...mailingAddressFields
          },
          stepValidationSchema:
            createParticipantContactValidationSchema(isStateIRA),
          title: 'Edit Contact Info'
        }
      ]
    });
  };

  return (
    <div data-testid='participant-contact-card'>
      <Card elevation={0} variant='outlined'>
        <CardContent className={classes.contentBody}>
          <Typography variant='h5'>Contact</Typography>
          <CardInfoField
            fieldName={isStateIRA ? 'Email' : 'Work Email'}
            fieldValue={participantContact.workEmail}
          />
          {!isStateIRA && (
            <CardInfoField
              fieldName='Personal Email'
              fieldValue={participantContact.personalEmail}
            />
          )}
          <CardInfoField
            fieldName={isStateIRA ? 'Mobile Phone' : 'Primary Phone'}
            fieldValue={
              participantContact.phoneNumber || EMPTY_FIELD_PLACEHOLDER
            }
          />
          <Divider />
          <Stack direction='row' justifyContent='space-between'>
            <Box>
              <CardInfoField
                fieldName='Physical Address'
                fieldValue={
                  participant.address
                    ? formatters.formatAddress(participant.address)
                    : EMPTY_FIELD_PLACEHOLDER
                }
              />
              <Stack
                alignItems='center'
                data-testid='addressVerification'
                direction='row'
                divider={<Divider flexItem orientation='vertical' />}
                spacing={1}>
                <Stack alignItems='center' direction='row' spacing={1}>
                  {addressVerification.isLoading ? (
                    <CircularLoading />
                  ) : addressVerification.isError ? (
                    <>
                      <HelpOutlined color='disabled' fontSize='small' />
                      <Typography variant='body2'>Unverified</Typography>
                    </>
                  ) : addressVerification.data?.isDeliverable ? (
                    <>
                      <CheckCircle color='success' fontSize='small' />
                      <Typography variant='body2'>Verified</Typography>
                    </>
                  ) : (
                    <>
                      <Warning color='warning' fontSize='small' />
                      <Typography variant='body2'>May Not Reach</Typography>
                    </>
                  )}
                </Stack>
                <Button
                  data-testid='verifyAddressButton'
                  onClick={toggleOpen}
                  variant='text'>
                  {addressVerification.isError
                    ? 'Verify Address'
                    : 'View Details'}
                </Button>
              </Stack>
            </Box>
            {lastSentMail.data?.createdAt && (
              <Box>
                <CardInfoField
                  fieldName='Last Sent Mail'
                  fieldValue={
                    lastSentMail.data?.description
                      ? lastSentMail.data?.description
                      : EMPTY_FIELD_PLACEHOLDER
                  }
                />
                <Stack
                  alignItems='center'
                  data-testid='physicalMailInfo'
                  direction='row'
                  divider={<Divider flexItem orientation='vertical' />}
                  spacing={1}>
                  <Stack alignItems='center' direction='row' spacing={1}>
                    {lastSentMail.isLoading ? (
                      <CircularLoading />
                    ) : getStatusLabelAndColor(
                        lastSentMail.data?.letterEventType
                      ).color === 'neutral' ? (
                      <>
                        <PendingOutlined color='disabled' fontSize='small' />
                        <Typography variant='body2'>
                          {
                            getStatusLabelAndColor(
                              lastSentMail.data?.letterEventType
                            ).label
                          }
                        </Typography>
                      </>
                    ) : getStatusLabelAndColor(
                        lastSentMail.data?.letterEventType
                      ).color === 'success' ? (
                      <>
                        <CheckCircle color='success' fontSize='small' />
                        <Typography variant='body2'>
                          {
                            getStatusLabelAndColor(
                              lastSentMail.data?.letterEventType
                            ).label
                          }
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Warning color='warning' fontSize='small' />
                        <Typography variant='body2'>
                          {
                            getStatusLabelAndColor(
                              lastSentMail.data?.letterEventType
                            ).label
                          }
                        </Typography>
                      </>
                    )}
                  </Stack>
                  <Typography variant='body2'>
                    {dayjs(lastSentMail.data?.createdAt).format('MM/DD/YYYY')}
                  </Typography>
                  <Button
                    data-testid='seeAllButton'
                    onClick={onSeeAllClick}
                    variant='text'>
                    See all
                  </Button>
                </Stack>
              </Box>
            )}
          </Stack>
          {open && (
            <ParticipantAddressVerificationDialog
              address={participant.address}
              addressVerificationDetails={addressVerification.data}
              isVerified={!addressVerification.isError}
              onClose={toggleOpen}
              open={open}
              participantId={participant.participantId}
            />
          )}
          {isStateIRA && (
            <CardInfoField
              fieldName='Mailing Address'
              fieldValue={
                participant.mailingAddress
                  ? formatters.formatAddress(participant.mailingAddress)
                  : EMPTY_FIELD_PLACEHOLDER
              }
            />
          )}
        </CardContent>
        <AccessControl
          requires={[FeatureLevelPermissions.WRITE_PERSONAL_CONTACT]}>
          <CardActions>
            <Button
              className={classes.editBtn}
              data-testid='edit-contact-info-btn'
              onClick={openEditDialog}>
              EDIT
            </Button>
          </CardActions>
        </AccessControl>
      </Card>
    </div>
  );
};

export default ParticipantContactCard;
