import { useSnackbar } from '@/contexts/SnackBarContext';
import { CreateParticipantHours, ParticipantHoursCreatedDto } from '@/models';
import ParticipantService from '@/services/Participant.service';
import {
  useMutation,
  UseMutationResult,
  useQueryClient
} from '@tanstack/react-query';

import { checkAdditionalHoursValidations } from './utils';

const useCreateHoursOfService = (
  participantId: string,
  closeCreateHours: () => void,
  planId?: number
): UseMutationResult<
  ParticipantHoursCreatedDto,
  Error,
  CreateParticipantHours,
  () => void
> => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation(
    ['ParticipantService.createParticipantHoursOfService'],
    (data: CreateParticipantHours) => {
      return ParticipantService.createParticipantHoursOfService(
        +participantId,
        planId,
        data
      );
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Failed!',
          severity: 'error'
        });
      },
      onSuccess: async () => {
        queryClient.invalidateQueries([
          'ParticipantService.getParticipantHoursOfService'
        ]);
        closeCreateHours();
        showSnackbar({
          autoHideDuration: 15000,
          message: 'Success! Information saved.',
          severity: 'success'
        });
        checkAdditionalHoursValidations(
          queryClient,
          participantId,
          showSnackbar
        );
      }
    }
  );
};

export default useCreateHoursOfService;
