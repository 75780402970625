import { BreakageStatus } from '@vestwell-sub-accounting/models/recon/BreakageStatus';

export const BreakageStatusColorMap: Record<
  string,
  'info' | 'neutral' | 'success' | 'warning'
> = {
  [BreakageStatus.Open]: 'warning',
  [BreakageStatus.InProcess]: 'neutral',
  [BreakageStatus.Waiting]: 'neutral',
  [BreakageStatus.Closed]: 'success',
  [BreakageStatus.ClosedCarriedForward]: 'success'
};
