import Badge from '@/components/badge';
import { CellComponentProps } from '@/components/collapsible-table';
import { colorByPlanStatus } from '@/consts/plan.constants';
import { Box, Link, TableCell, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { usePlanType } from '../../plan-detail/usePlanType.hook';

const useStyles = makeStyles((theme: Theme) => ({
  size: {
    fontSize: theme.spacing(2)
  }
}));

const PlanTableCell: React.FunctionComponent<CellComponentProps> = (
  props: CellComponentProps
) => {
  const classes = useStyles();
  const { row, column } = props;

  const planType = usePlanType({
    recordkeeper: row.recordkeeper,
    type: row.type
  });

  const cssClasses = clsx(classes.size, column.cellClassName);

  const content = useMemo(() => {
    if (column.field === 'planName' && !row.disableLink) {
      return (
        <Link
          component={RouterLink}
          to={`/${planType.urlPlansType}/${row.sponsorPlanId}`}
          underline='hover'>
          {row[column.field]}
        </Link>
      );
    }

    if (column.field === 'status') {
      return (
        <Badge color={colorByPlanStatus[row[column.field]]} size='small'>
          {row[column.field]}
        </Badge>
      );
    }

    if (column.field === 'effectivePlanDate') {
      return row[column.field]
        ? dayjs(new Date(+row[column.field])).format('MM/DD/YYYY')
        : '--';
    }

    return (
      (props.column.valueGetter
        ? //@ts-ignore we just need the row value for the value getter
          props.column.valueGetter({
            row
          })
        : row[column.field]) || '--'
    );
  }, [column, row]);

  return (
    <TableCell className={cssClasses} component='th' scope='row'>
      <Box>{content}</Box>
    </TableCell>
  );
};

export default PlanTableCell;
