import Card from '@/components/card';
import VerticalTabs from '@/components/vertical-tabs/VerticalTabs.component';
import documentCategories from '@/consts/documentCategories.constants';

import React from 'react';

import PlanDocumentsGroup from './PlanDocumentsGroup.component';

interface PlanDocumentsCardProps {
  sponsorPlanId: number;
}

const PlanDocumentsCard: React.FunctionComponent<PlanDocumentsCardProps> = ({
  sponsorPlanId
}) => {
  const tabs = documentCategories.map(category => {
    return {
      component: (
        <PlanDocumentsGroup
          categoryId={category.id}
          categoryName={category.categoryName}
          parentDocKey={category.parentDocKey}
          sponsorPlanId={sponsorPlanId}
          useEffectiveDate={category.useEffectiveDate}
        />
      ),
      label: category.categoryName
    };
  });

  return (
    <Card
      data-testid='plan-documents-card'
      sx={{ marginBottom: '25px', padding: '0px' }}>
      <VerticalTabs data-testid='plan-documents-card-tabs' tabs={tabs || []} />
    </Card>
  );
};
export default PlanDocumentsCard;
