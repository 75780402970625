import { ParticipantInfo } from '@/models';
import { Box } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { FC, useCallback, useState } from 'react';

import AutoRebalanceSettingHistoryCard from './AutoRebalanceSettingHistoryCard.component';
import { HoldingsCard } from './HoldingsCard.component';
import { ParticipantInvestmentElectionHistoryCard } from './ParticipantInvestmentElectionHistoryCard.component';

export const Investments: FC<{ participantId: number }> = props => {
  const [asOfDate, setAsOfDate] = useState<string | undefined>(undefined);
  const queryClient = useQueryClient();

  const previousParticipant = queryClient.getQueryData<ParticipantInfo>([
    'ParticipantService.getParticipantById',
    props.participantId.toString()
  ]);

  const handleAsOfDateChange = useCallback(event => {
    setAsOfDate(event.target.value || undefined);
  }, []);

  return (
    <>
      <HoldingsCard
        asOfDate={asOfDate}
        handleSetAsOfDate={handleAsOfDateChange}
        participantId={props.participantId}
      />
      <Box display='flex' flexDirection='row' marginTop='25px' width='100%'>
        <Box flexDirection='row' marginRight='25px' width='100%'>
          <ParticipantInvestmentElectionHistoryCard
            asOfDate={asOfDate}
            modelId={previousParticipant?.investmentElection?.modelId}
            participantId={props.participantId}
          />
        </Box>
        <Box width='100%'>
          <AutoRebalanceSettingHistoryCard
            participantId={props.participantId}
          />
        </Box>
      </Box>
    </>
  );
};

Investments.displayName = 'Investments';
