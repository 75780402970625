export const RiskSeriesFields = {
  EXPENSE_RATIO: 'expenseRatio' as const,
  SECURITY: 'security' as const,
  TOTAL_ALLOCATION: 'totalAllocation' as const
};

export interface RiskSeriesRow {
  maxTickerLength: number;
  id: string;
  [RiskSeriesFields.SECURITY]: {
    id: string;
    cusip: string;
    fundName: string;
  };
  [RiskSeriesFields.TOTAL_ALLOCATION]: string;
  [RiskSeriesFields.EXPENSE_RATIO]: string;
}
