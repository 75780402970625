import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';

import { FC } from 'react';
import { useToggle } from 'react-use';

import { OffCycle } from './components/OffCycle.component';

type StartOffCycleContributionProps = {
  planId: number;
  sponsorId: number;
};

export const StartOffCycleContribution: FC<
  StartOffCycleContributionProps
> = props => {
  const [isOpen, toggleIsOpen] = useToggle(false);

  return (
    <div>
      <Button
        data-testid='startOffCycleContributionButton'
        onClick={toggleIsOpen}
        startIcon={<Add />}
        variant='outlined'>
        OFF CYCLE
      </Button>
      <OffCycle
        isOpen={isOpen}
        onClose={toggleIsOpen}
        planId={props.planId}
        sponsorId={props.sponsorId}
      />
    </div>
  );
};
