import Card, { CardContent, CardHeader } from '@/components/card';
import {
  ParticipantAccountsDto,
  ParticipantAccountTotalBalance
} from '@/models/ParticipantAccountsDTO.model';
import formatters from '@/utils/Formatters';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import clsx from 'clsx';
import React, { FC } from 'react';

type EsaParticipantBalancesProps = {
  accountsResponseData: ParticipantAccountsDto;
};

const useStyles = makeStyles((theme: Theme) => ({
  bold: {
    fontWeight: 'bold'
  },
  date: {
    fontSize: theme.spacing(1.2),
    paddingLeft: theme.spacing(4)
  },
  groupedCell: {
    paddingLeft: theme.spacing(5.5)
  },
  groupedHeader: {
    paddingLeft: theme.spacing(4)
  },
  rowColor: {
    backgroundColor: theme.palette.grey[100]
  },
  totalRowColor: {
    backgroundColor: theme.palette.grey[300]
  }
}));

const getTestId = (id: string) =>
  `${id.toLocaleLowerCase().split(' ').join('-')}-row`;

export const EsaParticipantBalances: FC<
  EsaParticipantBalancesProps
> = props => {
  const classes = useStyles();

  const accountBalance: ParticipantAccountTotalBalance = {
    accountFields: [
      {
        amount: formatters.formatDollars(
          props.accountsResponseData.stats.balance.employee
        ),
        field: 'Employee Contributions Balance'
      },
      {
        amount: formatters.formatDollars(
          props.accountsResponseData.stats.balance.employer
        ),
        field: 'Employer Contributions'
      },
      {
        amount: formatters.formatDollars(
          props.accountsResponseData.stats.balance.withdrawal
        ),
        field: 'Withdrawals'
      },
      {
        amount: formatters.formatDollars(
          props.accountsResponseData.stats.balance.interestPayment
        ),
        field: 'Interest Payment'
      }
    ],
    total: formatters.formatDollars(
      props.accountsResponseData.stats.balance.total
    )
  };

  const isGroupedCell = (fieldName: string): boolean =>
    [
      'Employer Contributions',
      'Employee Contributions Balance',
      'Withdrawals',
      'Interest Payment'
    ].includes(fieldName);

  return (
    <Card data-testid='participant-balances-card'>
      <CardHeader
        data-testid='balances-header'
        disableDivider
        title='Balances'
      />
      <CardContent>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow className={classes.totalRowColor}>
                <TableCell className={classes.bold} component='th' scope='row'>
                  Total
                  <span className={classes.date}>
                    AS OF {props.accountsResponseData.stats.balance.updatedOn}
                  </span>
                </TableCell>
                <TableCell
                  align='right'
                  className={classes.bold}
                  component='th'
                  data-testid='total-value-cell'
                  scope='row'>
                  {formatters.formatDollars(
                    formatters.unformatCurrency(accountBalance.total)
                  )}
                </TableCell>
              </TableRow>
              {accountBalance.accountFields.map(balancesRow =>
                !isGroupedCell(balancesRow.field) ? (
                  <></>
                ) : (
                  <TableRow
                    data-testid={getTestId(balancesRow.field)}
                    key={balancesRow.field}>
                    <TableCell
                      className={clsx({
                        [[
                          classes.groupedHeader,
                          classes.bold,
                          classes.rowColor
                        ].join(' ')]: isGroupedCell(balancesRow.field),
                        [classes.groupedCell]: !isGroupedCell(balancesRow.field)
                      })}>
                      {balancesRow.field}
                    </TableCell>
                    <TableCell
                      align='right'
                      className={clsx({
                        [[
                          classes.groupedHeader,
                          classes.bold,
                          classes.rowColor
                        ].join(' ')]: isGroupedCell(balancesRow.field),
                        [classes.groupedCell]: !isGroupedCell(balancesRow.field)
                      })}
                      data-testid={`${getTestId(balancesRow.field)}-value`}>
                      {balancesRow.amount}
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};
