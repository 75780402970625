import AppConfig from '@/App.config';
import { ErrorDto } from '@/models/ErrorDTO.model';

import axios, { AxiosError, AxiosResponse } from 'axios';

import { userService } from './User.service';

const getAuthorizationHeader = (): Record<string, string> => {
  const token = userService.getAccessToken();
  return { Authorization: `Bearer ${token}` };
};

const makeHeaders = (): Record<string, string> => {
  return {
    'Content-Type': 'application/json',
    ...getAuthorizationHeader()
  };
};

const makeUrl = (path: string): string => {
  const separator = path.startsWith('/') ? '' : '/';

  const url = `${AppConfig.apiHost}${separator}${path}`;

  return url;
};

export type ApiServiceError = ErrorDto & {
  statusCode?: number;
};

class ApiService {
  static async getJson<T>(
    path: string,
    queryParams: Record<string, unknown> = {},
    signal?: AbortSignal
  ): Promise<T> {
    const url = makeUrl(path);

    const response = await axios.get(url, {
      headers: makeHeaders(),
      params: queryParams,
      signal
    });

    return response.data;
  }

  // david: this is only used for user id lookup on login
  static async getJsonWithoutHeaders<T>(
    path: string,
    queryParams: Record<string, unknown> = {}
  ): Promise<T> {
    const url = makeUrl(path);

    const response = await axios.get(url, {
      params: queryParams
    });

    return response.data;
  }

  static async getArrayBuffer<T>(
    path: string,
    queryParams: Record<string, unknown> = {}
  ): Promise<T> {
    const url = makeUrl(path);

    const response = await axios.get(url, {
      headers: makeHeaders(),
      params: queryParams,
      responseType: 'arraybuffer'
    });

    // Axios 0.x wraps the ArrayBuffer in a Buffer when running in Node; may not be an issue in Axios 1.x
    // https://github.com/axios/axios/blob/72f14ceef7dae917057f1d5c221713610a65217b/lib/adapters/http.js#L89-L99
    // https://github.com/axios/axios/issues/3517
    return response.data;
  }

  static async post<ReturnType>(path: string): Promise<ReturnType> {
    const url = makeUrl(path);

    const response = await axios
      .post<undefined, AxiosResponse<ReturnType>>(url, undefined, {
        headers: makeHeaders()
      })
      .catch(this.handleError);

    return response.data;
  }

  static async postJson<BodyType, ReturnType>(
    path: string,
    body: BodyType
  ): Promise<ReturnType> {
    const url = makeUrl(path);

    const response = await axios
      .post<BodyType, AxiosResponse<ReturnType>>(url, body, {
        headers: makeHeaders()
      })
      .catch(this.handleError);

    return response.data;
  }

  static async deleteJson<ReturnType>(
    path: string,
    queryParams: Record<string, unknown> = {}
  ): Promise<ReturnType> {
    const url = makeUrl(path);

    const response = await axios
      .delete<ReturnType>(url, {
        headers: makeHeaders(),
        params: queryParams
      })
      .catch(this.handleError);

    return response.data;
  }

  static async patchJson<BodyType, ReturnType>(
    path: string,
    body: BodyType
  ): Promise<ReturnType> {
    const url = makeUrl(path);
    const response = await axios
      .patch<BodyType, AxiosResponse<ReturnType>>(url, body, {
        headers: makeHeaders()
      })
      .catch(this.handleError);

    return response.data;
  }

  static async putJson<BodyType, ReturnType>(
    path: string,
    body: BodyType
  ): Promise<ReturnType> {
    const url = makeUrl(path);
    const response = await axios
      .put<BodyType, AxiosResponse<ReturnType>>(url, body, {
        headers: makeHeaders()
      })
      .catch(this.handleError);

    return response.data;
  }

  static async postFormData<ReturnType>(
    path: string,
    body: FormData
  ): Promise<ReturnType> {
    const url = makeUrl(path);
    const response = await axios
      .post<FormData, AxiosResponse<ReturnType>>(url, body, {
        headers: { ...getAuthorizationHeader() }
      })
      .catch(this.handleError);

    return response.data;
  }

  private static handleError(error: AxiosError<ErrorDto | string>) {
    const newError: ErrorDto = {};

    if (typeof error.response?.data === 'string') {
      newError.message = error.response?.data;
    } else {
      newError.details = error.response?.data?.details;
      newError.message = newError.details
        ? String(newError.details)
        : error.response?.data?.message ||
          'an error occurred trying to parse incoming http response';
      newError.data = error.response?.data;
    }

    return Promise.reject({
      ...newError,
      error,
      statusCode: error.response?.status
    });
  }
}

export default ApiService;
