import CollapsibleTable from '@/components/collapsible-table';
import { useUserToken } from '@/contexts/UserTokenContext';
import { SponsorDto } from '@/models';
import { AdvisorMeta } from '@/models/AdvisorDTO.model';
import { ParticipantMeta } from '@/models/ParticipantDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { UserAssociationsDto, UsersItemDto } from '@/models/UsersDTO.model';
import { VssStateSaverDto } from '@/models/VssStateSaver.model';
import AdvisorService from '@/services/Advisor.service';
import ParticipantService from '@/services/Participant.service';
import SponsorService from '@/services/Sponsor.service';
import UserManagementService from '@/services/UserManagement.service';
import { Search } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Grid,
  InputAdornment,
  LinearProgress,
  Paper,
  TextField,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useQueries, useQuery } from '@tanstack/react-query';

import { sortBy } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';

import UserManagementAssociationsCollapsibleTableCell from './UserManagementAssociationsCollapsibleTableCell.component';

interface UserManagementAssociationsProps {
  selectedUser: UsersItemDto;
}

interface QueryResponse {
  data: Record<any, any>;
  dataUpdatedAt: number;
  isError: boolean;
  isFetched: boolean;
  isFetchedAfterMount: boolean;
  isFetching: boolean;
  isLoading: boolean;
  isLoadingError: boolean;
  isPlaceholderData: boolean;
  isPreviousData: boolean;
  isRefetchError: boolean;
  isStale: boolean;
  isSuccess: boolean;
}

const advisorColumns: GridColDef[] = [
  { field: 'id', headerName: 'ID' },
  {
    field: 'name',
    headerName: 'Name'
  },
  {
    field: 'email',
    headerName: 'Email'
  },
  {
    field: 'company',
    headerName: 'Company'
  },
  {
    field: 'firmId',
    headerName: 'Firm ID'
  },
  {
    field: 'firmName',
    headerName: 'Firm Name'
  },
  {
    field: 'actions',
    headerName: ''
  }
];

const sponsorColumns: GridColDef[] = [
  { field: 'id', headerName: 'ID' },
  {
    field: 'name',
    headerName: 'Name'
  },
  {
    field: 'email',
    headerName: 'Email'
  },
  {
    field: 'dba',
    headerName: 'DBA'
  },
  {
    field: 'actions',
    headerName: ''
  }
];

const participantColumns: GridColDef[] = [
  { field: 'id', headerName: 'ID' },
  {
    field: 'name',
    headerName: 'Name'
  },
  {
    field: 'email',
    headerName: 'Email'
  },
  {
    field: 'plan',
    headerName: 'Plan Name'
  },
  {
    field: 'actions',
    headerName: ''
  }
];

const stateSaversColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name'
  },
  {
    field: 'ssn',
    headerName: 'SSN'
  },
  {
    field: 'dateOfBirth',
    headerName: 'DOB'
  },
  { field: 'id', headerName: 'Customer ID' },
  {
    field: 'actions',
    headerName: ''
  }
];

enum Associations {
  ADVISORS = 'advisors',
  BUSINESSES = 'businesses',
  SAVERS = 'savers',
  VSS_STATE_SAVERS = 'vss_state_savers'
}

const useStyles = makeStyles((theme: Theme) => ({
  clearItem: {
    cursor: 'pointer',
    paddingRight: theme.spacing(1.2)
  },
  noData: {
    padding: theme.spacing(2)
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%'
  },
  root: {
    padding: theme.spacing(2)
  },
  searchIcon: {
    paddingLeft: theme.spacing(1.2)
  },
  searchInput: {
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
    height: theme.spacing(5),
    paddingTop: theme.spacing(0.8),
    width: theme.spacing(50)
  },
  toggleButton: {
    '&.Mui-disabled, &:hover': {
      backgroundColor: 'rgba(33, 150, 243, 0.04)',
      color: theme.palette.primary.main
    },
    padding: theme.spacing(0.9),
    width: theme.spacing(17)
  }
}));

const TWO_HRS_IN_MILLISECONDS = 1000 * 60 * 60 * 2;

export const UserManagementAssociations: FC<
  UserManagementAssociationsProps
> = props => {
  const classes = useStyles();

  const [toggleChoice, setToggleChoice] = useState<Associations>(
    Associations.ADVISORS
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  const handleSearchTermChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const search = event.target.value;
    setPageNumber(1);
    setSearchTerm(search);
  };
  const { token } = useUserToken();

  const associationToPermissionMapping = useMemo(
    () => ({
      advisors: Boolean(
        token?.permissions.includes(
          FeatureLevelPermissions.READ_USER_MANAGEMENT_ADVISOR
        )
      ),
      businesses: Boolean(
        token?.permissions.includes(
          FeatureLevelPermissions.READ_USER_MANAGEMENT_SPONSOR
        )
      ),
      savers: Boolean(
        token?.permissions.includes(
          FeatureLevelPermissions.READ_USER_MANAGEMENT_PARTICIPANT
        )
      ),
      vss_state_savers: Boolean(
        token?.permissions.includes(
          FeatureLevelPermissions.READ_USER_MANAGEMENT_VSS_STATE_SAVER
        )
      )
    }),
    [token?.permissions]
  );

  const handleToggleChoiceChange = useCallback(
    (_: React.MouseEvent<HTMLElement>, newChoice: Associations) => {
      setToggleChoice(newChoice);
      setPageNumber(1);
      setRowsPerPage(25);
    },
    []
  );

  const userAssociationsQuery = useQuery<UserAssociationsDto[]>(
    ['UserManagementService.getUserAssociations', props.selectedUser?.userId],
    () => {
      return UserManagementService.getUserAssociations(
        props.selectedUser?.userId
      );
    },
    {
      cacheTime: TWO_HRS_IN_MILLISECONDS,
      enabled: Boolean(props.selectedUser?.userId),
      staleTime: TWO_HRS_IN_MILLISECONDS
    }
  );

  const mergeData = (associations: QueryResponse[]): any[] => {
    const mergedByYearsData: any[] = [];
    associations.forEach((association: QueryResponse) => {
      if (association.isSuccess) {
        mergedByYearsData.push(association.data as any);
      }
      return association;
    });
    return mergedByYearsData.flat();
  };

  const participantAssociations =
    userAssociationsQuery.data?.filter(item => item.type === 'participant') ||
    [];
  const sponsorAssociations =
    userAssociationsQuery.data?.filter(item => item.type === 'sponsor') || [];
  const advisorAssociations =
    userAssociationsQuery.data?.filter(item => item.type === 'advisor') || [];
  const vssStateSaversAssociations =
    userAssociationsQuery.data?.filter(item => item.type === 'state_saver') ||
    [];

  const advisorAssociationsQueries = useQueries({
    queries: advisorAssociations.map(advisor => ({
      enabled:
        Boolean(advisorAssociations.length) &&
        toggleChoice === Associations.ADVISORS,
      queryFn: () => AdvisorService.getInfoById(advisor.advisorId),
      queryKey: ['AdvisorService.getInfoById', advisor.advisorId],
      staleTime: Infinity
    }))
  });

  const participantAssociationsQueries = useQueries({
    queries: participantAssociations.map(participant => ({
      enabled:
        Boolean(participantAssociations.length) &&
        toggleChoice === Associations.SAVERS,
      queryFn: () => ParticipantService.getInfoById(participant.participantId),
      queryKey: ['ParticipantService.getInfoById', participant.participantId],
      staleTime: Infinity
    }))
  });

  const sponsorAssociationsQueries = useQueries({
    queries: sponsorAssociations.map(sponsor => ({
      enabled:
        Boolean(sponsorAssociations.length) &&
        toggleChoice === Associations.BUSINESSES,
      queryFn: () => SponsorService.getSponsorById(sponsor.sponsorId),
      queryKey: [
        'SponsorService.getSponsorById',
        sponsor.sponsorId?.toString()
      ],
      staleTime: Infinity
    }))
  });

  const vssStateSaverAssociationsQueries = useQueries({
    queries: vssStateSaversAssociations.map(vssStateSaver => ({
      enabled:
        Boolean(vssStateSaversAssociations.length) &&
        toggleChoice === Associations.VSS_STATE_SAVERS,
      queryFn: () =>
        UserManagementService.getVssStateSaver(vssStateSaver.surpasId),
      queryKey: [
        'UserManagementService.getVssStateSavers',
        vssStateSaver.surpasId
      ],
      staleTime: Infinity
    }))
  });

  const formattedParticipants = mergeData(
    participantAssociationsQueries as QueryResponse[]
  ).map((participant: ParticipantMeta) => {
    return {
      email: participant.email,
      id: Number(participant.id),
      name: `${participant.firstName} ${participant.middleName || ''} ${
        participant.lastName
      }`,
      plan: participant.planName,
      selectedUserEmail: props.selectedUser.email,
      selectedUserHasPassword: Boolean(props.selectedUser.password),
      selectedUserId: String(props.selectedUser.userId),
      type: 'participant',
      userAssociationsQueryData: userAssociationsQuery.data
    };
  });

  const formattedSponsors = mergeData(
    sponsorAssociationsQueries as QueryResponse[]
  ).map((sponsor: SponsorDto) => {
    return {
      dba: sponsor.data.attributes.doingBusinessAs,
      email: sponsor.data.attributes.primaryContact.email,
      id: Number(sponsor.data.id),
      name: sponsor.data.attributes.name,
      selectedUserEmail: props.selectedUser.email,
      selectedUserHasPassword: Boolean(props.selectedUser.password),
      selectedUserId: String(props.selectedUser.userId),
      type: 'sponsor',
      userAssociationsQueryData: userAssociationsQuery.data
    };
  });

  const formattedAdvisors = mergeData(
    advisorAssociationsQueries as QueryResponse[]
  ).map((advisor: AdvisorMeta) => {
    return {
      company: advisor.companyName,
      email: advisor.email,
      firmId: advisor.firmId,
      firmName: advisor.firmName,
      id: Number(advisor.id),
      name: advisor.name,
      selectedUserEmail: props.selectedUser.email,
      selectedUserHasPassword: Boolean(props.selectedUser.password),
      selectedUserId: String(props.selectedUser.userId),
      type: 'advisor',
      userAssociationsQueryData: userAssociationsQuery.data
    };
  });

  const formattedVssStateSavers = mergeData(
    vssStateSaverAssociationsQueries as QueryResponse[]
  )
    .filter(Boolean)
    .map((saver: VssStateSaverDto) => {
      return {
        dateOfBirth: `••/••/${saver.dateOfBirth}`,
        id: saver.customerId,
        loginUrl: saver.loginUrl,
        name: saver.name,
        selectedUserId: String(props.selectedUser.userId),
        ssn: saver.ssn,
        type: 'vss_state_saver'
      };
    });

  const sortedParticipants = sortBy(formattedParticipants, p => p.name);
  const sortedSponsors = sortBy(formattedSponsors, s => s.name);
  const sortedAdvisors = sortBy(formattedAdvisors, a => a.name);
  const sortedVssStateSavers = sortBy(formattedVssStateSavers, a => a.name);

  const noResult = (
    <Grid
      alignItems='center'
      className={classes.noData}
      container
      justifyContent='center'>
      <Grid item>
        <Typography>No existing associations</Typography>
      </Grid>
    </Grid>
  );

  const renderCollapsibleTable = (
    columns: GridColDef[],
    formattedData: any[],
    isLoading: boolean
  ): JSX.Element => {
    const filterTerm = debouncedSearchTerm.toLowerCase();
    const formattedDataSearch = [...formattedData].filter(data => {
      const searchableObject = { ...data };
      delete searchableObject.selectedUserHasPassword;
      delete searchableObject.selectedUserId;
      delete searchableObject.userAssociationsQueryData;
      delete searchableObject.selectedUserEmail;
      return (
        JSON.stringify(searchableObject).toLowerCase().indexOf(filterTerm) > -1
      );
    });

    return (
      <>
        {isLoading && <LinearProgress />}
        {!isLoading && formattedDataSearch.length ? (
          <div>
            <CollapsibleTable
              cellComponent={UserManagementAssociationsCollapsibleTableCell}
              columns={columns}
              pager={{
                metaCount: formattedDataSearch.length,
                onPageNumberChanged: (zeroIndexedPageNumber: number) => {
                  return setPageNumber(zeroIndexedPageNumber + 1);
                },
                onRowsPerPageChanged: (newRowsPerPage: number) => {
                  return setRowsPerPage(newRowsPerPage);
                },
                pageNumber: pageNumber - 1,
                rowsPerPage
              }}
              tableData={(formattedDataSearch || []).slice(
                rowsPerPage * pageNumber - rowsPerPage,
                rowsPerPage * pageNumber
              )}
              useDivAsBackground
            />
          </div>
        ) : (
          ''
        )}
        {!isLoading && formattedDataSearch.length === 0 && noResult}
      </>
    );
  };

  useEffect(() => {
    const entity = Object.keys(associationToPermissionMapping).find(entity => {
      return associationToPermissionMapping[entity];
    });

    if (entity) {
      setToggleChoice(Associations[entity.toUpperCase()]);
    }
  }, [associationToPermissionMapping, token?.permissions]);

  return (
    <Paper className={classes.paper}>
      <Grid
        alignItems='flex-start'
        className={classes.root}
        container
        flexDirection='column'
        justifyContent='center'
        spacing={3}
        wrap='nowrap'>
        <Grid item>
          <Typography data-testid='associations-title' variant='h5'>
            Existing associations
          </Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <TextField
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment
                      className={classes.clearItem}
                      onClick={() => setSearchTerm('')}
                      position='end'>
                      <ClearIcon />
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment
                      className={classes.searchIcon}
                      position='start'>
                      <Search />
                    </InputAdornment>
                  )
                }}
                className={classes.searchInput}
                data-testid='associations-input'
                onChange={handleSearchTermChange}
                placeholder='Filter associations'
                size='small'
                value={searchTerm}
                variant='standard'
              />
            </Grid>
            <Grid item>
              <ToggleButtonGroup
                data-testid='associations-toggle-buttons'
                exclusive
                onChange={handleToggleChoiceChange}
                size='small'
                value={toggleChoice}>
                {Object.keys(Associations).map(persona => {
                  const value = persona.toLowerCase();
                  return associationToPermissionMapping[value] ? (
                    <ToggleButton
                      className={classes.toggleButton}
                      data-testid={`${value}-toggle`}
                      disabled={toggleChoice === value}
                      key={value}
                      value={value}>
                      <Typography>
                        {persona === 'VSS_STATE_SAVERS'
                          ? 'STATE SAVERS'
                          : persona}
                      </Typography>
                    </ToggleButton>
                  ) : null;
                })}
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {toggleChoice === Associations.ADVISORS &&
        renderCollapsibleTable(
          advisorColumns,
          sortedAdvisors,
          advisorAssociationsQueries.some(result => result.isFetching) ||
            userAssociationsQuery.isLoading
        )}
      {toggleChoice === Associations.BUSINESSES &&
        renderCollapsibleTable(
          sponsorColumns,
          sortedSponsors,
          sponsorAssociationsQueries.some(result => result.isFetching) ||
            userAssociationsQuery.isLoading
        )}
      {toggleChoice === Associations.SAVERS &&
        renderCollapsibleTable(
          participantColumns,
          sortedParticipants,
          participantAssociationsQueries.some(result => result.isFetching) ||
            userAssociationsQuery.isLoading
        )}
      {toggleChoice === Associations.VSS_STATE_SAVERS &&
        renderCollapsibleTable(
          stateSaversColumns,
          sortedVssStateSavers,
          vssStateSaverAssociationsQueries.some(result => result.isFetching) ||
            userAssociationsQuery.isLoading
        )}
    </Paper>
  );
};
