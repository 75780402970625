import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUserToken } from '@/contexts/UserTokenContext';
import { PendingTransactionSearchRequest } from '@/models/ops/transactions/PendingTransactionSearchRequest.model';
import { TransactionService } from '@/services/ops/transactions/Transaction.service';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { AxiosError } from 'axios';

export const useSearchPendingTransactionsQuery = (
  params: PendingTransactionSearchRequest,
  useQueryOptions?: UseQueryOptions<
    Awaited<ReturnType<typeof TransactionService.searchConfirmed>>,
    AxiosError
  >
) => {
  const { showSnackbar } = useSnackbar();
  const { userHasValidToken } = useUserToken();

  return useQuery(
    ['TransactionService.searchPending', ...Object.values(params)],
    () => TransactionService.searchPending(params),
    {
      ...(useQueryOptions || {}),
      enabled:
        useQueryOptions?.enabled !== undefined
          ? useQueryOptions.enabled && userHasValidToken
          : userHasValidToken,
      onError: (err: AxiosError) => {
        showSnackbar({
          message: `Failed to search pending transactions: ${err.response?.data || err.message}`,
          severity: 'error'
        });
      }
    }
  );
};
