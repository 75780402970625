import {
  FormControl,
  FormControlProps,
  FormHelperText,
  FormLabel,
  RadioGroup
} from '@mui/material';

import { useField } from 'formik';
import React, { ReactNode } from 'react';

type RadioGroupFormProps = {
  children: ReactNode;
  label: string;
  name: string;
  radioGroupProps?: FormControlProps;
};

export const RadioGroupForm: React.FC<RadioGroupFormProps> = props => {
  const [field, meta, helper] = useField(props.name);

  return (
    <FormControl error={!!meta.error && meta.touched}>
      <FormLabel>{props.label}</FormLabel>
      <RadioGroup
        onBlur={field.onBlur}
        onChange={e => helper.setValue(e.target.value)}
        value={field.value || ''}
        {...props.radioGroupProps}>
        {props.children}
      </RadioGroup>
      {meta.touched && meta.error && (
        <FormHelperText>{meta.error}</FormHelperText>
      )}
    </FormControl>
  );
};
