import AccessControl from '@/components/access-control/AccessControl.component';
import Card, { CardContent } from '@/components/card';
import CardInfoField from '@/components/card-info-field';
import {
  FeatureLevelPermissions,
  Permissions
} from '@/models/UserPermissions.model';
import { Button, CardHeader, SxProps, Theme, useTheme } from '@mui/material';

type NotesInfoCardProps = {
  title: 'withdrawal' | 'loan' | 'vesting' | 'election' | 'billing';
  notes?: string;
  permission?: Permissions;
  emptyMessage?: string;
  isLoading?: boolean;
  sourceId?: number;
  sx?: SxProps<Theme>;
  onClickEdit: () => void;
};

export const NotesInfoCard: React.FC<NotesInfoCardProps> = props => {
  const theme = useTheme();
  const emptyPlaceholder =
    props.emptyMessage ?? `No notes for this ${props.title}`;

  return (
    <Card data-testid='participant-vesting-notes-card' sx={props.sx}>
      <CardHeader
        action={
          <AccessControl
            requires={[
              props.permission ??
                FeatureLevelPermissions.WRITE_WITHDRAWALS_ACTION
            ]}>
            <Button
              color='primary'
              data-testid='participant-vesting-notes-edit-button'
              variant='outlined'>
              EDIT
            </Button>
          </AccessControl>
        }
        onClick={props.onClickEdit}
        sx={{ borderBottom: `1px solid ${theme.palette.grey[300]}` }}
        title='Notes'
      />
      <CardContent
        data-testid='participant-vesting-notes-card-content'
        loading={props.isLoading}>
        <CardInfoField
          fieldName=''
          fieldValue={props.notes || emptyPlaceholder}
        />
      </CardContent>
    </Card>
  );
};

NotesInfoCard.displayName = 'NotesInfoCard';
