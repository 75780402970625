import CardInfoField from '@/components/card-info-field';
import CircularLoading from '@/components/circular-loading';
import { ContributionYTD } from '@/models/ContributionDTO.model';
import ParticipantService from '@/services/Participant.service';
import formatters from '@/utils/Formatters';
import { Card, CardContent, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';

import dayjs from 'dayjs';
import React, { useMemo } from 'react';

import CardBarChart from './CardBarChart.component';

interface ParticipantContributionsCardProps {
  isStateIRA: boolean;
  isEsa?: boolean;
  participantId: number;
  annualMax?: number;
}

interface ChartProps {
  title: string;
  description: string;
  numerator: number;
  denominator: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  barGraphField: {
    '& > *': {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      paddingBottom: 0
    },
    marginTop: theme.spacing(2)
  },
  contentBody: {
    paddingBottom: 0
  },
  contributionProgressBar: {
    height: 8,
    transform: 'none'
  },
  heavyCaption: {
    fontWeight: 500
  },
  lightFont: {
    opacity: 0.5
  }
}));

const ParticipantContributionsCard: React.FunctionComponent<
  ParticipantContributionsCardProps
> = (props: ParticipantContributionsCardProps) => {
  const classes = useStyles();

  const thisYear: number = dayjs().year();
  const { isStateIRA, participantId, annualMax } = props;

  const contributionsYTD = useQuery<ContributionYTD>(
    ['ParticipantService.getContributionYTD', participantId],
    async () => {
      return ParticipantService.getContributionYTD(participantId, thisYear);
    },
    {
      enabled: Boolean(participantId),
      staleTime: Infinity
    }
  );

  const isLoading = contributionsYTD.isFetching && !contributionsYTD.data;

  const employeeChartProps: ChartProps = useMemo(() => {
    const employeeContribution = isStateIRA
      ? contributionsYTD.data?.meta?.stateEmployeeYTDContributions
      : contributionsYTD.data?.meta?.employeeYTDContributions;

    return {
      denominator: annualMax ?? 0,
      description: `${formatters.formatDollars(
        employeeContribution
      )} / ${formatters.formatDollars(annualMax)}`,
      numerator: employeeContribution || 0,
      title: `${thisYear} Employee Contributions`
    };
  }, [annualMax, contributionsYTD.data, isStateIRA, thisYear]);

  const employerChartProps: ChartProps = useMemo(() => {
    return {
      denominator: annualMax ?? 0,
      description: `${formatters.formatDollars(
        contributionsYTD.data?.meta?.employerYTDContributions
      )}`,
      numerator: contributionsYTD.data?.meta?.employerYTDContributions || 0,
      title: `${thisYear} Employer Contributions`
    };
  }, [annualMax, contributionsYTD.data, thisYear]);

  return (
    <div data-testid='participant-contributions-card'>
      <Card elevation={0} variant='outlined'>
        <CardContent className={classes.contentBody}>
          <Typography variant='h5'>Contributions</Typography>
          {isLoading && (
            <div
              data-testid='contribution-card-loading'
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                minHeight: '180px'
              }}>
              <CircularLoading size='40px' />
            </div>
          )}
          {!isLoading && !props.isEsa && (
            <CardBarChart
              denominator={employeeChartProps.denominator}
              numerator={employeeChartProps.numerator}
              title={employeeChartProps.title}>
              <Typography component='span' style={{ display: 'flex' }}>
                <Typography component='span'>
                  {formatters.formatDollars(employeeChartProps.numerator)} /
                  &nbsp;
                </Typography>
                <Typography
                  className={
                    employeeChartProps.numerator ===
                    employeeChartProps.denominator
                      ? ''
                      : classes.lightFont
                  }
                  component='span'>
                  {formatters.formatDollars(employeeChartProps.denominator)}
                </Typography>
              </Typography>
            </CardBarChart>
          )}
          {!isLoading && props.isEsa && (
            <CardInfoField
              fieldName={employeeChartProps.title}
              fieldValue={`${formatters.formatDollars(
                employeeChartProps.numerator
              )}`}
            />
          )}
          {!isLoading && !isStateIRA && (
            <CardInfoField
              fieldName={employerChartProps.title}
              fieldValue={`${formatters.formatDollars(
                employerChartProps.numerator
              )}`}
            />
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default ParticipantContributionsCard;
