import CollapsibleTable from '@/components/collapsible-table';
import LinearLoading from '@/components/linear-loading';
import { ContributionDetailsParticipantData } from '@/models/ContributionDetailsDTO.model';
import PlanContributionProcessedList from '@/routes/plans/plan-detail/PlanContributionsTab/PlanContributionProcessed/PlanContributionProcessedList.component';
import PlanContributionProcessedTableCell from '@/routes/plans/plan-detail/PlanContributionsTab/PlanContributionProcessed/PlanContributionProcessedTableCell.component';
import ContributionService from '@/services/Contribution.service';
import formatters from '@/utils/Formatters';
import { Search } from '@mui/icons-material';
import {
  Card,
  CardContent,
  InputAdornment,
  List,
  TextField,
  Theme,
  Typography
} from '@mui/material';
import Switch from '@mui/material/Switch';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';

import clsx from 'clsx';
import Decimal from 'decimal.js';
import React, { useCallback, useMemo, useState } from 'react';
import { useDebounce, useToggle } from 'react-use';

import PlanContributionProcessedGroupedHeader from './PlanContributionProcessedGroupedHeader.component';

interface PlanContributionProcessedProps {
  isStateIRA: boolean;
  setSearch: (value: string) => void;
  search?: string;
  ucid: string;
  planId: string;
  sponsorId: string;
  participantData: ContributionDetailsParticipantData[];
}

const useStyles = makeStyles((theme: Theme) => ({
  border: {
    borderLeft: `1px solid ${theme.palette.grey[300]}`
  },
  checkbox: {
    display: 'flex',
    marginBottom: theme.spacing(1.7)
  },
  checkboxText: {
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(0.9),
    paddingTop: theme.spacing(1.5)
  },
  filters: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  mainListItem: {
    color: theme.palette.text.secondary,
    letterSpacing: theme.spacing(1 / 32),
    margin: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    paddingTop: theme.spacing(0)
  },
  noData: {
    fontSize: theme.spacing(2.4),
    paddingBottom: theme.spacing(2),
    textAlign: 'center'
  },
  root: {
    paddingBottom: theme.spacing(2),
    width: '100%'
  },
  searchInput: {
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.info.main,
    color: theme.palette.info.main,
    marginBottom: theme.spacing(3.5),
    marginLeft: theme.spacing(1.7),
    width: theme.spacing(35)
  },
  title: {
    marginBottom: theme.spacing(1),
    textAlign: 'left'
  },
  toggle: {
    marginTop: theme.spacing(0.6)
  }
}));

const PlanContributionProcessed = (
  props: PlanContributionProcessedProps
): JSX.Element => {
  const classes = useStyles();
  const [isFilterZeros, toggleFilterZeroes] = useToggle(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState('');

  const contribution = useQuery(
    [
      ContributionService.getContributionDetails.name,
      props.planId,
      props.ucid,
      props.search,
      isFilterZeros,
      pageNumber,
      rowsPerPage
    ],
    async () => {
      return ContributionService.getContributionDetails({
        filterZeros: isFilterZeros,
        pageNumber,
        pageSize: rowsPerPage,
        planId: props.planId,
        search: props.search,
        sponsorId: props.sponsorId,
        ucid: props.ucid
      });
    },
    {
      enabled: !!props.ucid,
      keepPreviousData: true
    }
  );

  const isVestwellSubaccounting = useMemo(
    () =>
      contribution.data?.recordkeeper === 'Vestwell Sub-Accounting Platform',
    [contribution.data?.recordkeeper]
  );

  const rows = useMemo(
    () =>
      formatters
        .formatParticipantContributionData(
          contribution.data?.participantData ?? []
        )
        .map(d => ({ ...d, isVestwellSubaccounting })),
    [contribution.data]
  );

  const columns = useMemo(() => {
    const columns = [
      { field: 'employee', headerName: 'Employee', width: 130 },
      {
        field: 'total',
        headerName: 'Total Contribution',
        recordkeepers: [
          'Voya',
          'Vestwell Sub-Accounting Platform',
          'Vestwell (RK) - Folio'
        ],
        width: 130
      },
      {
        field: 'esaEmployeeGroup',
        headerName: 'Employee Group',
        recordkeepers: ['Vestwell ESA'],
        width: 130
      },
      {
        cellClassName: classes.border,
        field: 'preTax',
        headerName: 'Pre-Tax',
        recordkeepers: [
          'Voya',
          'Vestwell Sub-Accounting Platform',
          'Vestwell (RK) - Folio'
        ],
        width: 130
      },
      {
        field: 'roth',
        headerName: 'Roth',
        recordkeepers: [
          'Voya',
          'Vestwell Sub-Accounting Platform',
          'Surpas (State IRA)',
          'Vestwell (RK) - Folio'
        ],
        width: 130
      },
      {
        field: 'afterTax',
        headerName: 'After-Tax',
        recordkeepers: [
          'Voya',
          'Vestwell Sub-Accounting Platform',
          'Vestwell (RK) - Folio'
        ],
        width: 130
      },
      {
        field: 'employeeContribution',
        headerName: 'Total Employee Contribution',
        recordkeepers: [
          'Voya',
          'Vestwell Sub-Accounting Platform',
          'Vestwell (RK) - Folio'
        ],
        width: 130
      },
      {
        cellClassName: classes.border,
        field: 'safeHarbor',
        headerName: 'Safe Harbor',
        recordkeepers: [
          'Voya',
          'Vestwell Sub-Accounting Platform',
          'Vestwell (RK) - Folio'
        ],
        width: 130
      },
      {
        field: 'discretionaryMatch',
        headerName: 'Discretionary Match',
        recordkeepers: [
          'Voya',
          'Vestwell Sub-Accounting Platform',
          'Vestwell (RK) - Folio'
        ],
        width: 130
      },
      {
        field: 'profitSharing',
        headerName: 'Profit Sharing',
        recordkeepers: [
          'Voya',
          'Vestwell Sub-Accounting Platform',
          'Vestwell (RK) - Folio'
        ],
        width: 130
      },
      {
        field: 'qnec',
        headerName: 'QNEC',
        recordkeepers: [
          'Voya',
          'Vestwell Sub-Accounting Platform',
          'Vestwell (RK) - Folio'
        ],
        width: 130
      },
      {
        field: 'qmac',
        headerName: 'QMAC',
        recordkeepers: [
          'Voya',
          'Vestwell Sub-Accounting Platform',
          'Vestwell (RK) - Folio'
        ],
        width: 130
      },
      {
        field: 'companyContribution',
        headerName: 'Total Company Contribution',
        recordkeepers: [
          'Voya',
          'Vestwell Sub-Accounting Platform',
          'Vestwell (RK) - Folio'
        ],
        width: 130
      },
      {
        cellClassName: classes.border,
        field: 'loanRepayment',
        headerName: 'Loan Repayment',
        recordkeepers: [
          'Voya',
          'Vestwell Sub-Accounting Platform',
          'Vestwell (RK) - Folio'
        ],
        width: 130
      },
      {
        cellClassName: classes.border,
        field: 'employeeDeferral',
        headerName: 'Employee Contribution',
        recordkeepers: ['Vestwell ESA'],
        width: 130
      },
      {
        cellClassName: classes.border,
        field: 'employerMatch',
        headerName: 'Employer Match',
        recordkeepers: ['Vestwell ESA'],
        width: 130
      },
      {
        field: 'initialDepositBonus',
        headerName: 'Initial Deposit',
        recordkeepers: ['Vestwell ESA'],
        width: 130
      },
      {
        field: 'milestoneBonus',
        headerName: 'Milestone Deposit',
        recordkeepers: ['Vestwell ESA'],
        width: 130
      }
    ];

    return columns.filter(
      column =>
        !column.recordkeepers ||
        column.recordkeepers.includes(contribution.data?.recordkeeper)
    );
  }, [classes, contribution.data?.recordkeeper]);

  useDebounce(() => props.setSearch(search), 500, [search]);

  const processedItemList = useMemo(() => {
    const employeeContribution = {
      nestedItems: [
        {
          nestedItemText: `Pre-Tax: ${formatters.formatDollars(
            contribution.data?.totals?.totalSd
          )}`,
          recordkeepers: [
            'Voya',
            'Vestwell Sub-Accounting Platform',
            'Vestwell (RK) - Folio'
          ]
        },
        {
          nestedItemText: `Roth: ${formatters.formatDollars(
            contribution.data?.totals?.totalRc
          )}`,
          recordkeepers: [
            'Voya',
            'Vestwell Sub-Accounting Platform',
            'Surpas (State IRA)',
            'Vestwell (RK) - Folio'
          ]
        },
        {
          nestedItemText: `After Tax: ${formatters.formatDollars(
            contribution.data?.totals?.totalAt
          )}`,
          recordkeepers: [
            'Voya',
            'Vestwell Sub-Accounting Platform',
            'Vestwell (RK) - Folio'
          ]
        }
      ],
      text: `Employee Contribution: ${formatters.formatDollars(
        contribution.data?.recordkeeper === 'Vestwell ESA'
          ? contribution.data?.totals?.totalEsaEmployeeDeferral ?? 0
          : Decimal.sum(
              contribution.data?.totals?.totalSd ?? 0,
              contribution.data?.totals?.totalRc ?? 0,
              contribution.data?.totals?.totalAt ?? 0
            ).toNumber()
      )}`
    };

    const companyContribution = {
      nestedItems: [
        {
          nestedItemText: `Safe Harbor: ${formatters.formatDollars(
            contribution.data?.totals?.totalSh
          )}`,
          recordkeepers: [
            'Voya',
            'Vestwell Sub-Accounting Platform',
            'Vestwell (RK) - Folio'
          ]
        },
        {
          nestedItemText: `Discretionary Match: ${formatters.formatDollars(
            contribution.data?.totals?.totalEm
          )}`,
          recordkeepers: [
            'Voya',
            'Vestwell Sub-Accounting Platform',
            'Vestwell (RK) - Folio'
          ]
        },
        {
          nestedItemText: `QNEC: ${formatters.formatDollars(
            contribution.data?.totals?.totalQc
          )}`,
          recordkeepers: [
            'Voya',
            'Vestwell Sub-Accounting Platform',
            'Vestwell (RK) - Folio'
          ]
        },
        {
          nestedItemText: `QMAC: ${formatters.formatDollars(
            contribution.data?.totals?.totalQm
          )}`,
          recordkeepers: [
            'Voya',
            'Vestwell Sub-Accounting Platform',
            'Vestwell (RK) - Folio'
          ]
        },
        {
          nestedItemText: `Profit Sharing: ${formatters.formatDollars(
            contribution.data?.totals?.totalPs
          )}`,
          recordkeepers: [
            'Voya',
            'Vestwell Sub-Accounting Platform',
            'Vestwell (RK) - Folio'
          ]
        },
        {
          nestedItemText: `Employer Match: ${formatters.formatDollars(
            contribution.data?.totals?.totalEsaEmployerMatch
          )}`,
          recordkeepers: ['Vestwell ESA']
        },
        {
          nestedItemText: `Initial Deposit: ${formatters.formatDollars(
            contribution.data?.totals?.totalEsaInitialDepositBonus
          )}`,
          recordkeepers: ['Vestwell ESA']
        },
        {
          nestedItemText: `Milestone Deposit: ${formatters.formatDollars(
            contribution.data?.totals?.totalEsaMilestoneBonus
          )}`,
          recordkeepers: ['Vestwell ESA']
        }
      ],
      text: `Company Contribution: ${formatters.formatDollars(
        contribution.data?.recordkeeper === 'Vestwell ESA'
          ? Decimal.sum(
              contribution.data?.totals?.totalEsaMilestoneBonus ?? 0,
              contribution.data?.totals?.totalEsaInitialDepositBonus ?? 0,
              contribution.data?.totals?.totalEsaEmployerMatch ?? 0
            ).toNumber()
          : Decimal.sum(
              contribution.data?.totals?.totalSh ?? 0,
              contribution.data?.totals?.totalEm ?? 0,
              contribution.data?.totals?.totalQc ?? 0,
              contribution.data?.totals?.totalQm ?? 0,
              contribution.data?.totals?.totalPs ?? 0
            ).toNumber()
      )}`
    };

    const loanContribution = {
      nestedItems: [],
      text: `Loan Repayment: ${formatters.formatDollars(contribution.data?.totals?.totalLn)}`
    };

    return [
      ...([
        'Voya',
        'Vestwell Sub-Accounting Platform',
        'Vestwell (RK) - Folio',
        'Surpas (State IRA)',
        'Vestwell ESA'
      ].includes(contribution.data?.recordkeeper ?? '')
        ? [
            {
              ...employeeContribution,
              nestedItems: employeeContribution.nestedItems?.filter(item =>
                item.recordkeepers?.includes(contribution.data?.recordkeeper)
              )
            }
          ]
        : []),
      ...([
        'Voya',
        'Vestwell Sub-Accounting Platform',
        'Vestwell (RK) - Folio',
        'Vestwell ESA'
      ].includes(contribution.data?.recordkeeper ?? '')
        ? [
            {
              ...companyContribution,
              nestedItems: companyContribution.nestedItems?.filter(item =>
                item.recordkeepers?.includes(contribution.data?.recordkeeper)
              )
            }
          ]
        : []),
      ...([
        'Voya',
        'Vestwell Sub-Accounting Platform',
        'Vestwell (RK) - Folio'
      ].includes(contribution.data?.recordkeeper ?? '')
        ? [loanContribution]
        : [])
    ];
  }, [contribution.data?.recordkeeper]);

  const searchEmployees = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPageNumber(1);
      setSearch(event.target?.value ?? '');
    },
    [setPageNumber, setSearch]
  );

  return (
    <Card className={classes.root} elevation={0} variant='outlined'>
      <div className='Plan-Contribution-Processed_Tile'>
        <CardContent>
          <Typography
            className={clsx(
              classes.title,
              'Plan-Contribution-Processed_Header'
            )}
            variant='h5'>
            Processed Contribution
          </Typography>
          <List
            aria-labelledby='nested-list-subheader'
            className='Plan-Contribution-Processed_List'
            component='nav'>
            {processedItemList.map(item => (
              <PlanContributionProcessedList item={item} key={item.text} />
            ))}
          </List>
        </CardContent>
        <div className='Plan-Contribution-Processed_Table'>
          <div className={classes.filters}>
            <TextField
              InputProps={{
                'aria-placeholder': 'Search employees',
                onChange: searchEmployees,
                placeholder: 'Search employees',
                startAdornment: (
                  <InputAdornment position='start'>
                    <Search />
                  </InputAdornment>
                )
              }}
              className={classes.searchInput}
              id='search-employees'
              variant='outlined'
            />
            <div className={classes.checkbox}>
              <Typography className={classes.checkboxText}>
                Hide Employees without contributions
              </Typography>
              <Switch
                className={classes.toggle}
                data-testid='filter-zeroes'
                defaultChecked
                onChange={toggleFilterZeroes}
              />
            </div>
          </div>
          {contribution.isFetching && <LinearLoading />}
          <CollapsibleTable
            cellComponent={PlanContributionProcessedTableCell}
            columns={columns}
            groupedHeader={
              <PlanContributionProcessedGroupedHeader
                recordkeeper={contribution.data?.recordkeeper}
              />
            }
            pager={{
              metaCount: contribution.data?.participantsCount,
              onPageNumberChanged: (zeroIndexedPageNumber: number) =>
                setPageNumber(zeroIndexedPageNumber + 1),
              onRowsPerPageChanged: (newRowsPerPage: number) =>
                setRowsPerPage(newRowsPerPage),
              pageNumber: pageNumber - 1,
              rowsPerPage
            }}
            tableData={rows}
          />
        </div>
      </div>
    </Card>
  );
};

export default PlanContributionProcessed;
