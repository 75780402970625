import LinearLoading from '@/components/linear-loading';
import { useSnackbar } from '@/contexts/SnackBarContext';
import useShowMore from '@/hooks/useShowMore';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { UsersDto, UsersItemDto } from '@/models/UsersDTO.model';
import { userService } from '@/services/User.service';
import UserManagementService from '@/services/UserManagement.service';
import { Button, Grid, List, ListItem, Theme, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

interface UserManagementEmailResultProps {
  selectUser: (user: any) => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  searchTerm: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  centerVertically: {
    'align-items': 'center',
    display: 'flex',
    'justify-content': 'center',
    marginTop: theme.spacing(1.5),
    padding: theme.spacing(0, 1)
  },
  showMoreBtn: {
    fontWeight: 'bold',
    marginLeft: theme.spacing(1.5),
    marginTop: theme.spacing(1)
  },
  userEmailResultItem: {
    '&:hover': {
      backgroundColor: grey[200]
    },
    cursor: 'pointer'
  }
}));

const SEARCH_RESULT_PAGE_SIZE = 8;

const UserManagementEmailResult = (
  props: UserManagementEmailResultProps
): JSX.Element => {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { selectUser, searchTerm, setOpen } = props;

  const getUsersQuery = useShowMore<UsersDto, UsersItemDto>(
    ['UserManagementService.getUser', searchTerm],
    page =>
      UserManagementService.getUsers(
        searchTerm.trim(),
        page,
        SEARCH_RESULT_PAGE_SIZE
      ),
    Boolean(searchTerm),
    data => data.data,
    data => Boolean(data?.links.next),
    'userId'
  );

  const validationSchema = yup.object({
    email: yup.string().email()
  });

  const canWriteUserManagement = userService.hasPermission(
    FeatureLevelPermissions.WRITE_USER_MANAGEMENT
  );
  const handleCreateUser = async (email: string) => {
    const isEmailValid = await validationSchema.isValid({ email });
    if (!isEmailValid) {
      return showSnackbar({
        message: 'Please enter a valid email.',
        severity: 'error'
      });
    }
    if (!canWriteUserManagement) {
      return showSnackbar({
        message: 'This email does not exist with any user.',
        severity: 'error'
      });
    }
    return setOpen(true);
  };

  let loader;

  if (getUsersQuery.isLoading || searchTerm === '') {
    loader = <LinearLoading />;
  } else {
    loader = null;
  }

  if (getUsersQuery.isError) {
    return <p>Error</p>;
  }

  if (!getUsersQuery.data?.length) {
    return (
      loader || (
        <Grid
          alignItems='center'
          container
          id='user-management-email-result-empty'
          justifyContent='space-between'>
          <Grid className={classes.centerVertically} item>
            <Typography
              color='textSecondary'
              id='user-management-email-result-no-user'
              variant='body1'>
              No user found.
            </Typography>
          </Grid>
          <Grid className={classes.centerVertically} item>
            {canWriteUserManagement && (
              <Button
                id='user-management-email-result-create-user-btn'
                onClick={() => handleCreateUser(searchTerm)}
                style={{ lineHeight: 0, marginTop: '0px' }}>
                CREATE USER
              </Button>
            )}
          </Grid>
        </Grid>
      )
    );
  }

  return (
    loader || (
      <List
        data-testid='user-management-email-result-list'
        id='user-management-email-result-list'>
        {getUsersQuery.data.map(user => (
          <ListItem
            className={classes.userEmailResultItem}
            data-testid='user-management-email-result-list-item'
            id='user-management-email-result-list-item'
            key={user.userId}
            onClick={() => {
              selectUser(user);
              navigate(`/user-management/${user.userId}/user`);
            }}>
            <Typography>{user.email}</Typography>
          </ListItem>
        ))}
        {!getUsersQuery.isLoading && getUsersQuery.isPaginated && (
          <Button
            className={classes.showMoreBtn}
            disabled={getUsersQuery.isPaginationLoading}
            id='user-email-search-show-more-btn'
            onClick={getUsersQuery.showMore}>
            SHOW MORE
          </Button>
        )}
      </List>
    )
  );
};

export default UserManagementEmailResult;
