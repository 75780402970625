import VisibilityToggle from '@/components/visibility-toggle';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { FormattedParticipantInfo } from '@/models/ParticipantInfo.model';
import formatters from '@/utils/Formatters';
import { Box, Typography } from '@mui/material';
import { formatSsn } from '@vestwell-frontend/helpers';

import React, { useMemo, useState } from 'react';

export interface ParticipantInfoCardProps {
  participant: FormattedParticipantInfo;
}

const ParticipantInfoCard: React.FunctionComponent<
  ParticipantInfoCardProps
> = ({ participant }: ParticipantInfoCardProps) => {
  const [isMaskActive, setIsMaskActive] = useState(true);

  const ssn = useMemo(() => {
    if (!participant.ssn) {
      return EMPTY_FIELD_PLACEHOLDER;
    }

    const formattedSSN = formatSsn(participant.ssn);

    return isMaskActive ? formatters.maskSSN(formattedSSN) : formattedSSN;
  }, [isMaskActive, participant]);

  return (
    <>
      <Typography variant='body1'>
        {participant.firstName} {participant.lastName}
      </Typography>
      <Typography sx={{ mb: 1 }} variant='caption'>
        {participant.isBeneficiary
          ? `Primary - ${participant.relationship}`
          : 'Participant'}
      </Typography>
      {!participant.isBeneficiary && (
        <Typography color='textSecondary' variant='body1'>
          ID: {participant.id}
        </Typography>
      )}
      <Box sx={{ alignItems: 'center', display: 'flex' }}>
        <Typography color='textSecondary' variant='body1'>
          SSN: {ssn}
        </Typography>
        <VisibilityToggle
          component='div'
          isMasked={isMaskActive}
          toggleMask={() => setIsMaskActive(!isMaskActive)}
        />
      </Box>
      <Typography color='textSecondary' variant='body1'>
        {participant.address.address1}
      </Typography>
      <Typography color='textSecondary' variant='body1'>
        {[
          participant.address.city,
          participant.address.state,
          participant.address.zip
        ]
          .filter(str => str?.trim() !== '')
          .join(', ')}
      </Typography>
    </>
  );
};

export default ParticipantInfoCard;
