import useHasPermissions from '@/components/access-control/useHasPermissions.hook';
import {
  GlobalSearchAdvisorDto,
  GlobalSearchParticipantDto,
  GlobalSearchPlanDto
} from '@/models/GlobalSearchDTO.model';
import { Entity } from '@/models/UserAssociationsDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { VssStateSaverDto } from '@/models/VssStateSaver.model';
import UserManagementService from '@/services/UserManagement.service';
import { Search } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { InputAdornment, Paper, styled, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';

import AssociateSearchList from './AssociateSearchList.component';
import AssociateSearchItem from './AssosiateSearchItem.component';

const ClearItem = styled(InputAdornment)(({ theme }) => ({
  cursor: 'pointer',
  paddingRight: theme.spacing(1.2)
}));

const EmailResult = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '0 0 2px 2px',
  maxHeight: theme.spacing(43.75),
  minHeight: theme.spacing(6.25),
  overflowX: 'auto',
  position: 'absolute',
  width: theme.spacing(50),
  zIndex: 1000
}));

const Root = styled('div')(() => ({
  display: 'inline-block'
}));

const SaverIcon = styled(PersonOutlineIcon)(({ theme }) => ({
  color: theme.palette.success.dark
}));

const SearchIcon = styled(InputAdornment)(({ theme }) => ({
  paddingLeft: theme.spacing(1.2)
}));

const SearchInput = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  border: '1px solid rgba(0, 0, 0, 0.23)',
  borderRadius: theme.spacing(0.5),
  height: theme.spacing(5),
  paddingTop: theme.spacing(0.8),
  width: theme.spacing(50)
}));

interface UserManagementStateSaversAssociateSearchProps {
  onAdd: (
    item: GlobalSearchPlanDto &
      GlobalSearchParticipantDto &
      GlobalSearchAdvisorDto,
    id: number,
    type: Entity
  ) => void;
  vssStateSavers: string[];
}

const TWO_HRS_IN_MILLISECONDS = 1000 * 60 * 60 * 2;

export const UserManagementStateSaversAssociateSearch: FC<
  UserManagementStateSaversAssociateSearchProps
> = ({ onAdd, vssStateSavers }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [abortController, setAbortController] = useState(new AbortController());
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  const resultsDivRef = useRef(null);
  const canWriteUserManagementPersonaAssociation = useHasPermissions({
    requires: [
      FeatureLevelPermissions.WRITE_USER_MANAGEMENT_PERSONA_ASSOCIATION
    ]
  });

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        resultsDivRef.current &&
        !resultsDivRef.current.contains(event.target)
      ) {
        setIsFocused(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [resultsDivRef]);

  const {
    isLoading: isVssStateSaversLoading,
    data: vssStateSaversData,
    isError: isVssStateSaversError
  } = useQuery<VssStateSaverDto>(
    ['UserManagementService.getVssStateSaver', debouncedSearchTerm],
    () => UserManagementService.getVssStateSaver(debouncedSearchTerm),
    {
      cacheTime: TWO_HRS_IN_MILLISECONDS,
      enabled: Boolean(debouncedSearchTerm),
      staleTime: TWO_HRS_IN_MILLISECONDS
    }
  );

  const stateSaversDataShow = useMemo(
    () => !vssStateSavers?.some(id => id === vssStateSaversData?.customerId),
    [vssStateSavers, vssStateSaversData]
  );

  return (
    <div data-testid='user-management-state-savers-associate-search'>
      <Root ref={resultsDivRef}>
        <SearchInput
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <ClearItem onClick={() => setSearchTerm('')} position='end'>
                <ClearIcon />
              </ClearItem>
            ),
            startAdornment: (
              <SearchIcon position='start'>
                <Search />
              </SearchIcon>
            )
          }}
          data-testid='user-management-state-savers-associate-search-input'
          onChange={e => {
            abortController.abort();
            setAbortController(new AbortController());
            setSearchTerm(e.target.value);
          }}
          onFocus={() => {
            setIsFocused(true);
          }}
          placeholder='Search persona to associate user with'
          size='small'
          value={searchTerm}
          variant='standard'
        />

        {isFocused && searchTerm.trim().length > 0 && (
          <EmailResult
            data-testid='user-management-state-savers-associate-search-result'
            elevation={2}>
            <AssociateSearchList
              isError={isVssStateSaversError}
              isLoading={isVssStateSaversLoading}
              isNoResults={
                !isVssStateSaversError &&
                !isVssStateSaversLoading &&
                !vssStateSaversData
              }
              listTitle='STATE SAVERS'>
              {stateSaversDataShow && (
                <AssociateSearchItem
                  hideAddButton={
                    !canWriteUserManagementPersonaAssociation.isAllowed
                  }
                  icon={<SaverIcon />}
                  item={vssStateSaversData}
                  itemDescriptions={[
                    `SSN: ***-**${vssStateSaversData?.ssn}`,
                    `DOB: ••/••/${vssStateSaversData?.dateOfBirth?.toString()}`,
                    `Customer ID: ${vssStateSaversData?.customerId}`
                  ]}
                  itemId={vssStateSaversData?.customerId}
                  key={vssStateSaversData?.customerId}
                  onItemAdd={item => {
                    onAdd(item, item.customerId, 'state_saver');
                  }}
                  primaryItemText={`${vssStateSaversData?.name}`}
                />
              )}
            </AssociateSearchList>
          </EmailResult>
        )}
      </Root>
    </div>
  );
};
