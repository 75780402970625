import NavigationBreadcrumbs from '@/components/navigation-breadcrumbs';
import { Skeleton, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import React, { FC, useMemo } from 'react';

type HeaderProps = {
  date: string;
  flowSubtype: string;
  payGroupName: string;
  payGroupCode?: string;
  isLoading?: boolean;
  planId: number;
  planName?: string;
};

export const Header: FC<HeaderProps> = props => {
  const type = useMemo(
    () =>
      ({
        'off-cycle_supplemental_pay': 'Off-Cycle',
        regular: 'Regular'
      })[props.flowSubtype],
    [props.flowSubtype]
  );

  const paths = useMemo(
    () => [
      {
        name: 'Plans',
        to: '/plans'
      },
      {
        name: props.planName ?? '--',
        to: `/plans/${props.planId}/contributions`
      }
    ],
    [props.planId, props.planName]
  );

  return (
    <Grid data-testid='header'>
      <NavigationBreadcrumbs data-testid='header-breadcrumbs' paths={paths} />
      <Typography data-testid='header-title' variant='h4'>
        {props.isLoading ? (
          <Skeleton width={600} />
        ) : (
          `${props.date} ${type} Contribution`
        )}
      </Typography>
      <Typography data-testid='header-paygroup'>
        {props.isLoading ? (
          <Skeleton width={300} />
        ) : (
          `Paygroup: ${props.payGroupName} (code: ${props.payGroupCode || 'null'})`
        )}
      </Typography>
    </Grid>
  );
};
