import DatePicker from '@/components/date-picker';
import LinearLoading from '@/components/linear-loading';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { ParticipantHoursOfService } from '@/models';
import ParticipantService from '@/services/Participant.service';
import formatters from '@/utils/Formatters';
import {
  alpha,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import { blue } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';

import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSharedStyles } from './styles';
import {
  DATA_MAIN_FORMAT,
  getCurrentDate,
  getDefaultStartDate,
  getYearsRange
} from './utils';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 180
    }
  }
};

const useStyles = makeStyles((theme: Theme) => ({
  calculationInfo: {
    backgroundColor: alpha(blue[200], 0.16),
    padding: `${theme.spacing(1.75)} ${theme.spacing(2)}`
  },
  removeGap: {
    gap: theme.spacing(0)
  },
  root: {
    alignItems: 'flex-start',
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    width: 262
  },
  toggleBtn: {
    '& .Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, 0.04)
    },
    borderColor: alpha(theme.palette.primary.main, 0.5),
    color: theme.palette.primary.main,
    padding: theme.spacing(0.75),
    width: '50%'
  },
  toggleGroup: {
    width: '100%'
  }
}));

interface HoursFilterCalculatorProps {
  startDate: string;
  endDate: string;
  participantId: string;
  planId?: number;
  isCalculationDisabled: boolean;
  wasDataUpdated: boolean;
  earliestHours?: ParticipantHoursOfService[];
  isLoading: boolean;
  updateStartDate: (date: string) => void;
  updateEndDate: (date: string) => void;
}

enum HoursFilterType {
  YEAR = 'YEAR',
  CUSTOM = 'CUSTOM'
}

interface HoursPerYear {
  year: number;
  hours: string;
}

const HoursFilterCalculator: React.FunctionComponent<
  HoursFilterCalculatorProps
> = (props: HoursFilterCalculatorProps) => {
  const {
    startDate,
    endDate,
    updateEndDate,
    updateStartDate,
    participantId,
    planId,
    earliestHours,
    isLoading,
    isCalculationDisabled,
    wasDataUpdated
  } = props;
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const { showSnackbar } = useSnackbar();

  const [filterType, setFilterType] = useState<HoursFilterType>(
    HoursFilterType.YEAR
  );
  const [totalHours, setTotalHours] = useState('');
  const [hoursPerYear, setHoursPerYear] = useState<HoursPerYear[]>([]);
  const [isCalculationOn, setCalculationOn] = useState(false);
  const [isStartDateInvalid, setStartDateInvalid] = useState(false);
  const [isEndDateInvalid, setEndDateInvalid] = useState(false);
  const selectedYear = dayjs(endDate).year();

  const earliestStartDate = useMemo(
    () =>
      earliestHours?.length ? dayjs(earliestHours[0].startDate) : undefined,
    [earliestHours]
  );
  const yearsRange = useMemo(
    () =>
      earliestStartDate && earliestStartDate.isValid()
        ? getYearsRange(earliestStartDate.year())
        : getYearsRange(),
    [earliestStartDate]
  );

  const areControlsDisabled = isCalculationOn || isCalculationDisabled;

  const handleFilterChange = (
    event: React.MouseEvent<HTMLElement>,
    newType: HoursFilterType
  ) => {
    if (newType === filterType || !newType) return;
    setStartDateInvalid(false);
    setEndDateInvalid(false);
    setFilterType(newType);
    const defaultEndDate = getCurrentDate();
    updateEndDate(getCurrentDate());
    if (dayjs(defaultEndDate).year() === earliestStartDate?.year()) {
      return updateStartDate(earliestStartDate.format(DATA_MAIN_FORMAT));
    }
    updateStartDate(getDefaultStartDate());
  };

  const updateFilterByYear = (event: SelectChangeEvent<number>) => {
    updateEndDate(`${event.target.value}-12-31`);
    if (event.target.value === earliestStartDate?.year()) {
      return updateStartDate(earliestStartDate.format(DATA_MAIN_FORMAT));
    }
    updateStartDate(`${event.target.value}-01-01`);
  };

  const updateFilterStartDate = (event: {
    target: { name: string; value: string };
  }) => {
    setStartDateInvalid(false);
    updateStartDate(event.target.value);
  };

  const updateFilterEndDate = (event: {
    target: { name: string; value: string };
  }) => {
    setEndDateInvalid(false);
    updateEndDate(event.target.value);
  };

  const setDefaultCalcs = useCallback(() => {
    setTotalHours('');
    setHoursPerYear([]);
  }, []);

  const calculateHours = async () => {
    const newHoursPerYear: Promise<HoursPerYear>[] = [];
    const startYear = dayjs(startDate).year();
    const endYear = dayjs(endDate).year();

    setCalculationOn(true);

    try {
      const participantTotalHoursPerPeriod =
        await ParticipantService.getParticipantTotalHours(
          +participantId,
          planId,
          startDate,
          endDate
        );

      if (startYear >= endYear) {
        setHoursPerYear([]);
      } else {
        newHoursPerYear.push(
          ParticipantService.getParticipantTotalHours(
            +participantId,
            planId,
            startDate,
            `${startYear}-12-31`
          ).then(res => ({
            hours: formatters.formatDecimal(res, 2),
            year: startYear
          }))
        );
        for (let i = startYear + 1; i < endYear; i++) {
          newHoursPerYear.push(
            ParticipantService.getParticipantTotalHours(
              +participantId,
              planId,
              `${i}-01-01`,
              `${i}-12-31`
            ).then(res => ({
              hours: formatters.formatDecimal(res, 2),
              year: i
            }))
          );
        }
        newHoursPerYear.push(
          ParticipantService.getParticipantTotalHours(
            +participantId,
            planId,
            `${endYear}-01-01`,
            endDate
          ).then(res => ({
            hours: formatters.formatDecimal(res, 2),
            year: endYear
          }))
        );
        await Promise.all(newHoursPerYear).then(res => setHoursPerYear(res));
      }

      setTotalHours(
        formatters.formatDecimal(participantTotalHoursPerPeriod, 2)
      );
      setCalculationOn(false);
    } catch (e) {
      setCalculationOn(false);
      setDefaultCalcs();
      showSnackbar({ message: 'Failed!', severity: 'error' });
    }
  };

  useEffect(() => {
    setDefaultCalcs();
  }, [startDate, endDate, wasDataUpdated, setDefaultCalcs]);

  if (isLoading) {
    return (
      <Box className={classes.root}>
        <LinearLoading />
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <ToggleButtonGroup
        className={classes.toggleGroup}
        color='primary'
        exclusive
        onChange={handleFilterChange}
        value={filterType}>
        <ToggleButton
          className={classes.toggleBtn}
          data-testid='year-filter-toggle'
          value={HoursFilterType.YEAR}>
          {HoursFilterType.YEAR}
        </ToggleButton>
        <ToggleButton
          className={classes.toggleBtn}
          data-testid='custom-filter-toggle'
          value={HoursFilterType.CUSTOM}>
          {HoursFilterType.CUSTOM}
        </ToggleButton>
      </ToggleButtonGroup>
      {filterType === HoursFilterType.YEAR ? (
        <FormControl fullWidth>
          <InputLabel id='year'>Year</InputLabel>
          <Select
            MenuProps={MenuProps}
            data-testid='year-filter-dropdown'
            disabled={areControlsDisabled}
            id='year'
            inputProps={{ className: sharedClasses.pickerInput }}
            label='Year'
            labelId='year'
            onChange={updateFilterByYear}
            value={selectedYear}>
            {yearsRange.map(y => (
              <MenuItem key={y} value={y}>
                {y}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Box className={classes.removeGap}>
          <Box sx={{ paddingBottom: '30px' }}>
            <DatePicker
              className={sharedClasses.datePicker}
              data-testid='custom-filter-start-date'
              disabled={areControlsDisabled}
              format='MM/DD/YYYY'
              handleError={error => {
                if (error === 'invalidDate') {
                  setStartDateInvalid(true);
                }
              }}
              inputProps={{
                autoComplete: 'off',
                className: sharedClasses.pickerInput
              }}
              label='Start date'
              maxDate={dayjs(endDate)}
              name='startDate'
              onChange={updateFilterStartDate}
              value={startDate}
              variant='outlined'
            />
          </Box>
          <DatePicker
            className={sharedClasses.datePicker}
            data-testid='custom-filter-end-date'
            disabled={areControlsDisabled}
            format='MM/DD/YYYY'
            handleError={error => {
              if (error === 'invalidDate') {
                setEndDateInvalid(true);
              }
            }}
            inputProps={{
              autoComplete: 'off',
              className: sharedClasses.pickerInput
            }}
            label='End date'
            minDate={dayjs(startDate)}
            name='endDate'
            onChange={updateFilterEndDate}
            value={endDate}
            variant='outlined'
          />
        </Box>
      )}
      <Box
        alignItems='flex-start'
        display='flex'
        flexDirection='column'
        gap={1}>
        <Button
          data-testid='calculate-hours-button'
          disabled={
            areControlsDisabled || isStartDateInvalid || isEndDateInvalid
          }
          onClick={calculateHours}>
          CALCULATE
        </Button>
        {!!totalHours && (
          <Box
            className={classes.calculationInfo}
            data-testid='total-hours-calculation-result'>
            <Typography variant='body2'>
              <b>{totalHours}</b> hours worked in the above range
            </Typography>
            {hoursPerYear.length > 1 && (
              <Box marginTop={3}>
                {hoursPerYear.map(y => (
                  <Typography key={y.year} variant='body2'>
                    {y.year}: {y.hours} hours
                  </Typography>
                ))}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default HoursFilterCalculator;
