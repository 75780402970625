import dayjs from 'dayjs';
import * as yup from 'yup';

const formtattedSSN = /^(?!(000))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/;
const plainSSN = /^(?!(000))\d{3}(?!00)\d{2}(?!0000)\d{4}$/;
const blacklistedSSNs = ['123456789', '987654321'];

const createParticipantIdentificationValidationSchema = (
  dateOfHire?: string
): yup.AnyObjectSchema =>
  yup.object({
    birthDate: yup
      .string()
      .test('Date format', 'Please enter a valid date as mm/dd/yyyy', value =>
        dayjs(value, 'MM/DD/YYYY', true).isValid()
      )
      .test(
        'Date future/past',
        'Date of birth cannot be a future/unfeasible date',
        value => {
          const enteredDate = new Date(value as string);
          const pastDate = new Date('12/31/1900');
          let comparedDate;
          if (dateOfHire) {
            comparedDate = new Date(dateOfHire);
          } else {
            comparedDate = new Date();
          }
          return (
            enteredDate.getTime() < comparedDate.getTime() &&
            enteredDate.getTime() > pastDate.getTime()
          );
        }
      ),
    firstName: yup
      .string()
      .trim()
      .required('First Name is required')
      .matches(/^[a-zA-Z\s',.-]+$/, 'Name cannot contain special characters')
      .test('Letter check', 'Name must contain at least one letter', value =>
        /[a-zA-Z]/.test(value as string)
      )
      .test(
        'Last and first letter check',
        'Name cannot contain special characters',
        value => {
          const regexCheck = /[\s',-]/;
          if (value) {
            return (
              !regexCheck.test(value[0]) &&
              !regexCheck.test(value[value.length - 1])
            );
          }
          return true;
        }
      ),
    lastName: yup
      .string()
      .trim()
      .required('Last Name is required')
      .matches(/^[a-zA-Z\s',.-]+$/, 'Name cannot contain special characters')
      .test('Letter check', 'Name must contain at least one letter', value =>
        /[a-zA-Z]/.test(value as string)
      )
      .test(
        'Last and first letter check',
        'Name cannot contain special characters',
        value => {
          const regexCheck = /[\s',-]/;
          if (value) {
            return (
              !regexCheck.test(value[0]) &&
              !regexCheck.test(value[value.length - 1])
            );
          }
          return true;
        }
      ),
    middleName: yup
      .string()
      .trim()
      .matches(/^[a-zA-Z\s',.-]+$/, 'Name cannot contain special characters')
      .test('Letter check', 'Name must contain at least one letter', value =>
        /[a-zA-Z-]/.test(value as string)
      )
      .test(
        'Last and first letter check',
        'Name cannot contain special characters',
        value => {
          const regexCheck = /[\s',]/;
          if (value) {
            return (
              !regexCheck.test(value[0]) &&
              !regexCheck.test(value[value.length - 1])
            );
          }
          return true;
        }
      ),
    ssn: yup
      .string()
      .required('SSN is required')
      .test(
        'Check for valid SSN format',
        'Please enter a valid SSN',
        (value?: string) => {
          if (value) {
            const ssnNoDashes = value.replace(/-/g, '');
            const ssnDigits = new Set(ssnNoDashes.split(''));
            return (
              (formtattedSSN.test(value) || plainSSN.test(value)) &&
              ssnDigits.size > 1 &&
              !blacklistedSSNs.includes(ssnNoDashes)
            );
          }
          return true;
        }
      )
  });

export default createParticipantIdentificationValidationSchema;
