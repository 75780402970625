import CardInfoField from '@/components/card-info-field';
import FileUploadTable, {
  ExtendedColDef,
  ExtendedGroupColDef,
  FileUploadTableError
} from '@/components/file-upload-table/FileUploadTable.component';
import ActionTableForm from '@/routes/plans/plan-detail/PlanActionTableV2/ConversionMainComponents/ActionTableForm.component';
import UploadButton from '@/routes/plans/plan-detail/PlanActionTableV2/ConversionMainComponents/UploadButton.component';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  styled,
  Typography
} from '@mui/material';

import { FormikContextType, FormikErrors } from 'formik';
import { isString, times } from 'lodash';
import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';

type FileUploadTableModalProps = {
  validationSchema: yup.ArraySchema<yup.AnyObjectSchema>;
  submitTitle: string;
  subtitle?: string;
  emptyRows?: number;
  recordTypeName?: string;
  columnDefs: (ExtendedGroupColDef | ExtendedColDef)[];
  onSubmit: (csvRows: Record<string, unknown>[]) => Promise<unknown>;
  maxWidth?: DialogProps['maxWidth'];
  buttonProps?: ButtonProps;
};

const useFileUploadTable = (params: {
  onSubmit: (rows: Record<string, unknown>[]) => Promise<unknown>;
}) => {
  const form = useRef<FormikContextType<unknown>>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [gridRows, setGridRows] = useState<Record<string, unknown>[]>([]);
  const [errors, setErrors] = useState<FormikErrors<unknown>>([]);

  const handleRowsChange = useCallback(
    (newRows: Record<string, unknown>[]) => {
      form.current?.setValues(newRows);
      setGridRows(newRows);
    },
    [setGridRows, form.current]
  );

  const clear = useCallback(() => handleRowsChange([]), []);

  const handleLoad = useCallback(
    (rows: Record<string, unknown>[]) => {
      clear();
      form.current?.setValues([
        ...rows.map(row => ({
          ...row,
          uuid: uuidv4()
        })),
        { pristine: true, uuid: uuidv4() }
      ]);
    },
    [form.current]
  );

  const gridErrors = useMemo(() => {
    const rowsData = gridRows;
    return Object.keys(errors).length
      ? rowsData.map<FileUploadTableError>((row, i) => {
          return errors[i]
            ? Object.keys(errors[i] ?? ({} as FileUploadTableError)).reduce(
                (acc, field: string) => {
                  const formError = errors[i] ?? {};
                  return {
                    ...acc,
                    ...{ [field]: formError[field] }
                  } as FileUploadTableError;
                },
                {} as FileUploadTableError
              )
            : {};
        })
      : [];
  }, [errors, gridRows]);

  const handleErrors = useCallback(errorsObject => {
    setErrors(
      times(
        Array.isArray(form.current?.values) ? form.current?.values?.length : 0,
        index => {
          return errorsObject[index] ?? {};
        }
      )
    );
  }, []);

  const handleCellChange = useCallback(
    (key: string, value) => {
      if (/[^[]+(?=])/.test(key) && key.split('.').at(-1) === 'state') {
        const valueToSave = isString(value)
          ? value.toUpperCase().slice(0, 2)
          : value;

        form.current?.setFieldValue(key, valueToSave);

        return;
      }

      if (key.match(/\[\d*].[a-zA-Z]*/)) {
        form.current?.setFieldValue(key, value);
      }
    },
    [form]
  );

  const errorsCount = useMemo(() => {
    return (gridErrors || []).reduce((acc, field, index) => {
      return gridRows[index].pristine ? acc : acc + Object.keys(field).length;
    }, 0);
  }, [gridErrors, gridRows]);

  const allPristine = useMemo(() => {
    return gridRows.every(r => r.pristine);
  }, [gridRows]);

  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(async () => {
    setSubmitting(true);
    await params.onSubmit(gridRows.filter(r => !r.pristine));
    setSubmitting(false);
  }, [params.onSubmit, gridRows, setSubmitting]);

  return {
    allPristine,
    clear,
    errorsCount,
    form,
    gridErrors,
    gridRows,
    handleCellChange,
    handleErrors,
    handleLoad,
    handleRowsChange,
    handleSubmit,
    isDialogOpen,
    rowCount: gridRows.length - 1, // last row is "Add new row" button
    setGridRows,
    setIsDialogOpen,
    submitting
  };
};

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialogContent-root': {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0
  }
}));

interface FileUploadTableHeaderProps {
  title: string;
  subtitle?: string;
  onUpload: (data: Record<string, unknown>[]) => void;
  columnDefs: (ExtendedGroupColDef | ExtendedColDef)[];
  checkFileNameExists?: (fileName: string) => Promise<unknown>;
  viewMode?: boolean;
  errors: number;
}

const FileUploadTableHeader: FC<FileUploadTableHeaderProps> = props => {
  return (
    <DialogTitle
      component={Stack}
      direction='row'
      justifyContent='space-between'>
      <Box>
        <Typography component='h2' variant='h5'>
          {props.title}
        </Typography>
        {props.subtitle && (
          <Typography
            color={theme => theme.palette.grey[700]}
            component='h3'
            data-testid='file-upload-table-subtitle'
            variant='caption'>
            {props.subtitle}
          </Typography>
        )}
      </Box>
      <Stack alignContent='space-between' justifyContent='flex-end' spacing={2}>
        {!props.viewMode && (
          <UploadButton
            columnDefs={props.columnDefs}
            onUpload={props.onUpload}
          />
        )}
        <Stack direction='row' spacing={1}>
          {props.errors > 0 ? (
            <WarningAmberIcon color='error' />
          ) : (
            <CheckCircleOutlineIcon color='success' />
          )}
          <Typography>{props.errors} Errors</Typography>
        </Stack>
      </Stack>
    </DialogTitle>
  );
};

export const FileUploadTableModal: FC<FileUploadTableModalProps> = props => {
  const upload = useFileUploadTable({
    onSubmit: props.onSubmit
  });

  const columns = useMemo(
    () => props.columnDefs.map(c => ({ suppressMenu: true, ...c })),
    [props.columnDefs]
  );

  return (
    <>
      <StyledDialog
        fullWidth
        maxWidth={props.maxWidth}
        onClose={() => {
          upload.setIsDialogOpen(false);
          upload.clear();
        }}
        open={upload.isDialogOpen}
        style={{ display: upload.isDialogOpen ? 'block' : 'none' }}>
        <FileUploadTableHeader
          columnDefs={columns}
          errors={upload.errorsCount}
          onUpload={value => {
            upload.handleLoad(value);
          }}
          subtitle={props.subtitle}
          title={props.submitTitle}
        />
        <DialogContent>
          <ActionTableForm
            emptyRowsCount={props.emptyRows + 1}
            initialValues={upload.gridRows}
            onChange={upload.setGridRows}
            onErrors={upload.handleErrors}
            onSubmit={upload.handleSubmit}
            ref={upload.form}
            validateOnChange
            validateOnMount
            validationSchema={props.validationSchema}>
            <FileUploadTable
              columnDefs={columns}
              errors={upload.gridErrors}
              onCellChanged={upload.handleCellChange}
              onRowsChanged={upload.handleRowsChange}
              rowData={upload.gridRows}
            />
          </ActionTableForm>
        </DialogContent>
        <Divider />
        <DialogActions>
          {upload.gridRows.length > 0 && (
            <Grid container>
              <CardInfoField
                fieldName={`Number of ${props.recordTypeName || 'row'}s`}
                fieldValue={`${upload.rowCount}`}
              />
            </Grid>
          )}
          <Button
            disabled={upload.submitting}
            onClick={() => {
              upload.setIsDialogOpen(false);
              upload.clear();
            }}
            variant='text'>
            Cancel
          </Button>
          <Button
            disabled={
              upload.submitting ||
              upload.allPristine ||
              upload.errorsCount > 0 ||
              upload.rowCount === 0
            }
            onClick={async () => {
              await upload.handleSubmit();
              upload.setIsDialogOpen(false);
              upload.clear();
            }}
            variant='contained'>
            Submit
          </Button>
        </DialogActions>
      </StyledDialog>
      <Button
        {...props.buttonProps}
        onClick={() => {
          upload.setIsDialogOpen(true);
        }}>
        {props.submitTitle}
      </Button>
    </>
  );
};

FileUploadTableModal.defaultProps = {
  maxWidth: 'lg'
};
