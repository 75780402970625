import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { AuditLog, TableTriggeredFilters } from '@/models/AuditLogs.model';
import { Box, Button, Unstable_Grid2 as Grid, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { GridRowParams, useGridApiContext } from '@mui/x-data-grid-pro';

import { useMemo } from 'react';

const formatSyntaxHighlight = json =>
  JSON.stringify(json, null, 2)
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
      match => {
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            return `"<span style="color: #5E00F8;">${match.slice(1, match.length - 2)}</span>":`;
          } else {
            return `"<span style="color: #0E77CB;">${match.slice(1, match.length - 1)}</span>"`;
          }
        }
      }
    );

type DetailPanelProps = GridRowParams<AuditLog> & {
  lastRowId: number;
  triggerFiltersFromTable: (newFilters: Partial<TableTriggeredFilters>) => void;
};

export const DetailPanel: React.FC<DetailPanelProps> = props => {
  const apiRef = useGridApiContext();

  const borderRule = useMemo(
    () => ({
      [apiRef.current.getExpandedDetailPanels().includes(props.id) &&
      props.lastRowId === props.id
        ? 'borderTop'
        : 'borderBottom']: `1px solid ${grey[300]}`
    }),
    [props.id, props.lastRowId]
  );

  return (
    <Grid
      {...borderRule}
      container
      gap={2}
      height='100%'
      pb={5}
      pl={7.5}
      pr={2}
      pt={1}>
      <Grid
        alignContent='flex-start'
        alignItems='flex-start'
        container
        gap={2}
        lg={4}
        md={5}
        xs={3}>
        <Grid container xs={12}>
          <Grid xs={6}>
            <Typography variant='body2'>Initiator</Typography>
          </Grid>
          <Grid xs={6}>
            <Typography color='black' variant='body2'>
              {props.row.initiatedByUserId || EMPTY_FIELD_PLACEHOLDER}
            </Typography>
          </Grid>
        </Grid>
        <Grid>
          <Button
            disabled={!props.row.initiatedByUserId}
            onClick={() => {
              props.triggerFiltersFromTable({
                search: props.row.initiatedByUserId
              });
            }}
            sx={{ p: 0 }}
            variant='text'>
            Filter by this user
          </Button>
        </Grid>
        <Grid>
          <Button
            onClick={() => {
              props.triggerFiltersFromTable({
                eventTypeNames: [props.row.eventTypeName]
              });
            }}
            sx={{ p: 0 }}
            variant='text'>
            Filter by similar actions
          </Button>
        </Grid>
      </Grid>
      <Grid container flexDirection='column' gap={1} lg={5} md={6} xs={4}>
        <Typography variant='body2'>Context</Typography>
        <Box
          bgcolor={grey[50]}
          border={`1px solid ${grey[300]}`}
          borderRadius={1}
          color='#989898'
          dangerouslySetInnerHTML={{
            __html: formatSyntaxHighlight(props.row.context)
          }}
          fontFamily='monospace'
          padding={2}
          whiteSpace='pre-wrap'
        />
      </Grid>
    </Grid>
  );
};
