import {
  Box,
  CardHeaderProps,
  LinearProgress,
  CardHeader as MuiCardHeader
} from '@mui/material';

/**
 * Wrapper of same-named MUI component to apply props common to cards in the expanded alert details drawer
 *
 * API:
 *
 * - [Card Header API](https://mui.com/material-ui/api/card-header/)
 */
type ExtendedCardHeaderProps = {
  loading?: boolean;
};
export const CardHeader = (
  props: CardHeaderProps & ExtendedCardHeaderProps
): JSX.Element => {
  const { loading, ...restProps } = props;

  return (
    <Box position='relative'>
      <MuiCardHeader
        sx={{
          '& .MuiCardHeader-action': {
            alignSelf: 'center'
          },
          py: 1.5
        }}
        titleTypographyProps={{
          alignItems: 'center',
          display: 'flex',
          gap: 1,
          variant: 'h6'
        }}
        {...restProps}
      />
      {loading && (
        <LinearProgress
          sx={{ bottom: 0, left: 0, position: 'absolute', right: 0 }}
        />
      )}
    </Box>
  );
};
