import { useSnackbar } from '@/contexts/SnackBarContext';
import {
  CreateSponsorExemptionDto,
  CreateSponsorExemptionPayload
} from '@/models';
import SponsorService from '@/services/Sponsor.service';
import {
  useMutation,
  UseMutationResult,
  useQueryClient
} from '@tanstack/react-query';

const createSponsorExemption = (
  createSponsorExemptionDto: CreateSponsorExemptionDto
) => {
  return SponsorService.createSponsorExemption(
    createSponsorExemptionDto.data.relationships.sponsor.data.id,
    createSponsorExemptionDto
  );
};

const useCreateSponsorExemption = (): UseMutationResult<
  CreateSponsorExemptionPayload,
  Error,
  CreateSponsorExemptionDto,
  () => void
> => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation<
    CreateSponsorExemptionPayload,
    Error,
    CreateSponsorExemptionDto,
    () => void
  >(
    createSponsorExemptionDto =>
      createSponsorExemption(createSponsorExemptionDto),
    {
      onError: () => {
        showSnackbar({ message: 'Failed!', severity: 'error' });
      },
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries([
          'SponsorService.getSponsorById',
          variables.data.relationships.sponsor.data.id.toString()
        ]);

        showSnackbar({ message: 'Success!', severity: 'success' });
      }
    }
  );
};

export default useCreateSponsorExemption;
