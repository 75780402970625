import CollapsibleTable from '@/components/collapsible-table';
import { useDialog } from '@/contexts/DialogContext';
import { FileUploadTableModal } from '@/hooks/file-upload-table/useFileUploadTable.hook';
import { BulkWelcomeEmailsPlansSchema } from '@/services/ActionCenter.service';
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  TableCell,
  Theme,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { GridColDef } from '@mui/x-data-grid-pro';
import { UseMutationResult } from '@tanstack/react-query';

import clsx from 'clsx';
import dayjs from 'dayjs';
import { orderBy } from 'lodash';
import { FC, useCallback, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as yup from 'yup';

const useStyles = makeStyles((theme: Theme) => ({
  margin: {
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(3)
  },
  planIdCellWidth: {
    width: theme.spacing(20)
  },
  planNameCellWidth: {
    width: theme.spacing(90)
  },
  size: {
    fontSize: theme.spacing(2)
  },
  textField: {
    width: '50ch'
  }
}));

const ErrorPlansCell = (props: {
  row: { planId: number };
  column: { field: 'planId' | 'planName'; cellClassName: string };
}): JSX.Element => {
  const classes = useStyles();

  return props.column.field === 'planName' ? (
    <TableCell
      className={clsx(classes.size, props.column.cellClassName)}
      component='th'
      scope='row'>
      <Link
        component={RouterLink}
        to={`/plans/${props.row.planId}`}
        underline='hover'>
        {props.row[props.column.field]}
      </Link>
    </TableCell>
  ) : (
    <TableCell
      className={clsx(classes.size, props.column.cellClassName)}
      component='th'
      scope='row'>
      {props.row[props.column.field]}
    </TableCell>
  );
};

export const ActionCenterDetailsBulkWelcomeEmails: FC<{
  handleSubmit: UseMutationResult;
}> = props => {
  const { openDialog, closeDialog } = useDialog();

  const classes = useStyles();

  const columns = useMemo<GridColDef[]>(() => {
    return [
      {
        cellClassName: classes.planIdCellWidth,
        field: 'planId',
        headerName: 'Plan ID',
        width: 130
      },
      {
        cellClassName: classes.planNameCellWidth,
        field: 'planName',
        headerName: 'Name',
        width: 130
      },
      {
        cellClassName: classes.planNameCellWidth,
        field: 'error',
        headerName: 'Error',
        width: 130
      }
    ];
  }, [classes]);

  const handleSubmitAsync = useCallback(
    async (csvRows: { planId: string; date: Date }[]): Promise<void> => {
      try {
        const rawResults = await props.handleSubmit.mutateAsync(
          csvRows.map(r => ({
            date: dayjs(r.date).format('YYYY-MM-DD'),
            planId: r.planId
          }))
        );

        const results = await yup
          .object()
          .shape({
            errors: yup
              .array()
              .of(
                yup.object().shape({
                  error: yup.string().required('message is required'),
                  planId: yup.number().required('planId is required'),
                  planName: yup.string().required('planName is required')
                })
              )
              .required(),
            successes: yup
              .array()
              .of(
                yup.object().shape({
                  planId: yup.number().required('planId is required'),
                  planName: yup.string().required('planName is required')
                })
              )
              .required()
          })
          .validate(rawResults);

        if (results.errors.length === 0) {
          return;
        }

        openDialog({
          customContent: (
            <>
              <DialogTitle>
                Emails could not be scheduled for some plans
              </DialogTitle>
              <DialogContent>
                <Stack direction='row' justifyContent='flex-end' mb={1}>
                  <Stack direction='row' spacing={2}>
                    <CheckCircleOutlined color='success' />
                    {results.successes.length} Succeeded
                    <WarningAmberIcon color='error' />
                    {results.errors.length} Failed
                  </Stack>
                </Stack>
                <CollapsibleTable
                  cellComponent={ErrorPlansCell}
                  columns={columns}
                  disablePagination
                  primaryKey='planId'
                  tableData={orderBy(results.errors, 'planId')}
                />
                <DialogActions>
                  <Button onClick={closeDialog}>Close</Button>
                </DialogActions>
              </DialogContent>
            </>
          ),
          dialogProps: {
            maxWidth: 'md'
          }
        });
      } catch (e) {
        openDialog({
          customContent: (
            <>
              <DialogTitle>Error</DialogTitle>
              <DialogContent>
                <Typography>{e.message}</Typography>
                <DialogActions>
                  <Button onClick={closeDialog}>Close</Button>
                </DialogActions>
              </DialogContent>
            </>
          )
        });
      }
    },
    [closeDialog, columns, openDialog, props.handleSubmit.mutateAsync]
  );
  return (
    <FileUploadTableModal
      buttonProps={{
        fullWidth: true,
        variant: 'contained'
      }}
      columnDefs={[
        {
          alternates: [
            'Plan ID',
            'Plan Id',
            'plan id',
            'planId',
            'PlanId',
            'PlanID',
            'sponsor_plan_id'
          ],
          editable: true,
          field: 'planId',
          headerName: 'Plan ID',
          minWidth: 250,
          required: true,
          resizable: true,
          type: 'text'
        },
        {
          alternates: ['Employees Invite Date', 'effective_plan_date'],
          editable: true,
          field: 'date',
          headerName: 'Date',
          minWidth: 250,
          required: true,
          resizable: true,
          type: 'date'
        }
      ]}
      emptyRows={2}
      maxWidth='md'
      onSubmit={handleSubmitAsync}
      recordTypeName='Plan'
      submitTitle='Upload File'
      validationSchema={BulkWelcomeEmailsPlansSchema}
    />
  );
};
