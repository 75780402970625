import { DisbursementStatus } from '@vestwell-sub-accounting/models/common/DistributionRequestTypes';

export const DisbursementStatusColorMap: Record<
  string,
  'info' | 'neutral' | 'success' | 'warning'
> = {
  [DisbursementStatus.REJECTED]: 'warning',
  [DisbursementStatus.CLEARED]: 'success',
  [DisbursementStatus.CONFIRMED]: 'success',
  [DisbursementStatus.NO_DISBURSEMENT]: 'warning',
  [DisbursementStatus.OUTSTANDING]: 'warning',
  [DisbursementStatus.PENDING]: 'neutral',
  [DisbursementStatus.REISSUED]: 'info',
  [DisbursementStatus.STOPPED]: 'info'
};
