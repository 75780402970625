import TextStack, {
  TextLabel,
  TextLabelProps,
  TextStackItem,
  TextStackItemProps,
  TextStackProps,
  TextValue,
  TextValueProps
} from './TextStack.component';

export type {
  TextStackProps,
  TextLabelProps,
  TextStackItemProps,
  TextValueProps
};
export { TextLabel, TextStackItem, TextValue };
export default TextStack;
