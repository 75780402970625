import Card, { CardContent } from '@/components/card';
import Link from '@/components/link/Link.component';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { AlertDto } from '@/models/ops/alerts/AlertDTO.model';
import SubAccountService from '@/services/ops/accounts/SubAccount.service';
import BalanceService from '@/services/ops/balances/Balance.service';
import ParentAccountOrderService from '@/services/ops/parent-account-orders/ParentAccountOrder.service';
import SecurityService from '@/services/ops/security/Security.service';
import formatters from '@/utils/Formatters';
import { Alert, Divider, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useQuery } from '@tanstack/react-query';
import { AlertSubType } from '@vestwell-sub-accounting/models/common/AlertSubType';
import { AlertType } from '@vestwell-sub-accounting/models/common/AlertType';
import { ParentAccountType } from '@vestwell-sub-accounting/models/common/ParentAccountType';

import { AxiosError } from 'axios';

type OrderCustodianUnitSellOffsetAlertDetailFieldsProps = {
  alert: AlertDto;
};

export const OrderCustodianUnitSellOffsetAlertDetailFields = ({
  alert
}: OrderCustodianUnitSellOffsetAlertDetailFieldsProps): JSX.Element => {
  const { showSnackbar } = useSnackbar();
  const displayType = formatters.getValueKey(AlertType, alert.alertType);
  const displaySubType = formatters.getValueKey(
    AlertSubType,
    alert.alertSubType
  );

  const getSecurityQuery = useQuery(
    ['SecurityService.get', alert.details?.breakageTransaction?.cusip],
    () => SecurityService.get(alert.details.breakageTransaction?.cusip),
    {
      enabled: Boolean(alert.details?.breakageTransaction?.cusip),
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Getting security failed: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const getSubAccountQuery = useQuery(
    ['SubAccountService.getById', alert.details.breakageTransaction?.accountId],
    () =>
      SubAccountService.getById(alert.details.breakageTransaction.accountId),
    {
      enabled: Boolean(alert.details.breakageTransaction?.accountId),
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Getting parent account failed: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const getBalanceQuery = useQuery(
    ['BalanceService.get', alert.details.breakageTransaction?.accountId],
    () => BalanceService.get(alert.details.breakageTransaction.accountId),
    {
      enabled: Boolean(alert.details.breakageTransaction?.accountId),
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Getting balance failed: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const getParentAccountOrderQuery = useQuery(
    [
      'ParentAccountOrderService.search',
      alert.details.breakageTransaction?.orderId
    ],
    () =>
      ParentAccountOrderService.search({
        ourParentOrderId: alert.details.breakageTransaction.orderId
      }),
    {
      enabled: Boolean(alert.details.breakageTransaction?.orderId),
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Getting Parent Account Order failed: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const position = getBalanceQuery.data?.positions.find(
    position =>
      position.security.cusip === alert.details.breakageTransaction?.cusip
  );

  return (
    <Stack direction='column' id='alert-detail-fields' spacing={2}>
      <Alert severity='info'>
        <Typography color='black' variant='caption'>
          To facilitate rounding and order matching, an offset transaction was
          created when deaggregating this order.
        </Typography>
      </Alert>
      <TextStack direction='column' spacing={1}>
        <TextStackItem>
          <TextLabel>Type / Sub Type</TextLabel>
          <TextValue data-testid='order-custodian-unit-sell-offset-type-value'>
            {formatters.displayCase(displayType)} /{' '}
            {formatters.displayCase(displaySubType)}
          </TextValue>
        </TextStackItem>
        {getSubAccountQuery.data?.parentAccount.accountType ===
          ParentAccountType.PlanLevel && (
          <TextStackItem>
            <TextLabel>Plan</TextLabel>
            <TextValue
              data-testid='order-custodian-unit-sell-offset-plan-value'
              links={[
                getSubAccountQuery.data?.parentAccount.planId
                  ? {
                      label: `ID: ${getSubAccountQuery.data.planId}`,
                      target: '_blank',
                      to: `/plans/${getSubAccountQuery.data.planId}/plan`
                    }
                  : undefined,
                getSubAccountQuery.data?.parentAccountId
                  ? {
                      label: 'View Parent Acct.',
                      target: '_blank',
                      to: `/ops/accounts/${getSubAccountQuery.data?.parentAccountId}/sub-accounts`
                    }
                  : undefined
              ].filter(Boolean)}>
              {getSubAccountQuery.data?.parentAccount.plan?.name}
            </TextValue>
          </TextStackItem>
        )}
        {getSubAccountQuery.data?.parentAccount && (
          <TextStackItem>
            <TextLabel>Custodian Acct.</TextLabel>
            <TextValue data-testid='order-custodian-unit-sell-offset-parent-account-value'>
              {`${formatters.displayCase(getSubAccountQuery.data.parentAccount.custodianId)}: ${getSubAccountQuery.data.parentAccount.custodianAccountNumber}`}
            </TextValue>
          </TextStackItem>
        )}
        <TextStackItem>
          <TextLabel>Order ID</TextLabel>
          <TextValue data-testid='order-custodian-unit-sell-offset-order-id-value'>
            {getParentAccountOrderQuery.data?.results.length > 0 ? (
              <Link
                target='_blank'
                to={
                  '/ops/parent-account-orders/' +
                  getParentAccountOrderQuery.data.results[0].id
                }>
                {alert.details.breakageTransaction?.orderId}
              </Link>
            ) : (
              alert.details.breakageTransaction?.orderId
            )}
          </TextValue>
        </TextStackItem>
      </TextStack>
      <Card>
        <CardContent>
          <Stack direction='column' spacing={2}>
            <Typography color='black' variant='subtitle2'>
              Breakage Account
            </Typography>
            <TextStack direction='column' spacing={1}>
              <TextStackItem>
                <TextLabel>Sub Account</TextLabel>
                <TextValue
                  data-testid='order-custodian-unit-sell-offset-sub-account-value'
                  links={
                    alert.details.breakageTransaction?.accountId
                      ? [
                          {
                            label: 'View',
                            target: '_blank',
                            to: `/ops/accounts/${getSubAccountQuery.data?.parentAccountId}/sub-accounts/${alert.details.breakageTransaction.accountId}`
                          }
                        ]
                      : undefined
                  }>
                  {alert.details.breakageTransaction?.accountId}
                </TextValue>
              </TextStackItem>
              {alert.details.breakageTransaction?.cusip && (
                <TextStackItem>
                  <TextLabel>Security</TextLabel>
                  <TextValue
                    data-testid='order-custodian-unit-sell-offset-security-value'
                    links={[
                      {
                        label: formatters.displayCase(
                          getSecurityQuery.data?.description ?? 'Details'
                        ),
                        target: '_blank',
                        to: `/ops/securities/${alert.details.breakageTransaction.cusip}`
                      }
                    ]}>
                    {formatters.formatSecurityName(
                      getSecurityQuery.data?.symbol,
                      alert.details.breakageTransaction?.cusip
                    )}
                  </TextValue>
                </TextStackItem>
              )}
            </TextStack>
            <Divider flexItem textAlign='left'>
              Transaction Details
            </Divider>
            <TextStack direction='column' rowColumnWidth='dynamic' spacing={1}>
              <TextStackItem>
                <TextLabel>Trade Date</TextLabel>
                <TextValue data-testid='order-custodian-unit-sell-offset-trade-date-value'>
                  {alert.details.breakageTransaction?.tradeDate
                    ? formatters.formatFromIsoDateCustom(
                        alert.details.breakageTransaction?.tradeDate,
                        'MM/DD/YYYY'
                      )
                    : null}
                </TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Transaction Type</TextLabel>
                <TextValue data-testid='order-custodian-unit-sell-offset-transaction-type-value'>
                  {`${formatters.formatTransactionBaseType(alert.details.breakageTransaction?.transactionBaseType)} / ${formatters.displayCase(alert.details.breakageTransaction?.transactionTypeCode) ?? ''}`}
                </TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Units</TextLabel>
                <TextValue data-testid='order-custodian-unit-sell-offset-units-value'>
                  {formatters.formatDecimal(
                    alert.details.breakageTransaction?.units,
                    3
                  )}
                </TextValue>
              </TextStackItem>
              <TextStackItem maxWidth='50%'>
                <TextLabel>Transaction ID</TextLabel>
                <TextValue
                  copyValue={
                    alert.details.breakageTransaction?.sourceTransactionId
                  }
                  copyable
                  data-testid='order-custodian-unit-sell-offset-transaction-id-value'>
                  <Typography
                    color='black'
                    component='p'
                    maxWidth={200}
                    variant='body2'>
                    {alert.details.breakageTransaction?.sourceTransactionId}
                  </Typography>
                </TextValue>
              </TextStackItem>
            </TextStack>
            <Divider flexItem textAlign='left'>
              Position & Balances
            </Divider>
            <Grid container gap={2}>
              <Grid>
                <TextStack direction='column' spacing={1}>
                  <TextStackItem>
                    <TextLabel>Confirmed Units</TextLabel>
                    <TextValue data-testid='order-custodian-unit-sell-offset-confirmed-units-value'>
                      {formatters.formatDecimal(position?.confirmedUnits, 3)}
                    </TextValue>
                  </TextStackItem>
                  <TextStackItem>
                    <TextLabel>Pending Units</TextLabel>
                    <TextValue data-testid='order-custodian-unit-sell-offset-pending-units-value'>
                      {formatters.formatDecimal(position?.pendingUnits, 3)}
                    </TextValue>
                  </TextStackItem>
                </TextStack>
              </Grid>
              <Grid>
                <TextStack direction='column' spacing={1}>
                  <TextStackItem>
                    <TextLabel>Confirmed Bal.</TextLabel>
                    <TextValue data-testid='order-custodian-unit-sell-offset-confirmed-bal-value'>
                      {formatters.formatDollars(
                        getBalanceQuery.data?.cash.confirmed,
                        2
                      )}
                    </TextValue>
                  </TextStackItem>
                  <TextStackItem>
                    <TextLabel>Pending Bal.</TextLabel>
                    <TextValue data-testid='order-custodian-unit-sell-offset-pending-bal-value'>
                      {formatters.formatDollars(
                        getBalanceQuery.data?.cash.pending,
                        2
                      )}
                    </TextValue>
                  </TextStackItem>
                </TextStack>
              </Grid>
            </Grid>
          </Stack>
        </CardContent>
      </Card>
      <TextStack direction='column' spacing={1}>
        <TextStackItem>
          <TextLabel>Created</TextLabel>
          <TextValue data-testid='order-custodian-unit-sell-offset-created-value'>
            {alert.createdAt
              ? formatters.formatFromIsoDateCustom(
                  alert.createdAt,
                  'MM/DD/YYYY HH:mm:ss'
                )
              : null}
          </TextValue>
        </TextStackItem>
        <TextStackItem>
          <TextLabel>Last Updated</TextLabel>
          <TextValue data-testid='order-custodian-unit-sell-offset-updated-value'>
            {alert.updatedAt
              ? formatters.formatFromIsoDateCustom(
                  alert.updatedAt,
                  'MM/DD/YYYY HH:mm:ss'
                )
              : null}
          </TextValue>
        </TextStackItem>
      </TextStack>
    </Stack>
  );
};
