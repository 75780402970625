import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { FraudRankerReport } from '@/models/FraudrankerResult.model';
import { UpdateLoanDto } from '@/models/LoanDTO.model';
import ParticipantService from '@/services/Participant.service';
import EditIcon from '@mui/icons-material/Edit';
import {
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
  Typography
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { FC, useMemo } from 'react';
import { useToggle } from 'react-use';

type mutationProps = {
  id: number;
  latestFraudRank: string;
  participantId?: number;
};

const ColoredCircle = styled('span')<{
  color: string;
}>(props => {
  return {
    backgroundColor: props.color,
    borderRadius: 100,
    height: 15,
    width: 15
  };
});

const colorToString: Record<string, 'green' | 'yellow' | 'red'> = {
  '#43A047': 'green',
  '#E53935': 'red',
  '#FB8C00': 'yellow'
};

const fraudColor = (score: number | string) => {
  if (typeof score === 'number' ? score < 60 : score === 'green') {
    return '#43A047';
  }
  if (typeof score === 'number' ? score < 100 : score === 'yellow') {
    return '#FB8C00';
  }
  return '#E53935';
};

export const FraudrankerScoreCell: FC<{
  data: FraudRankerReport;
}> = props => {
  const [edit, toggleEdit] = useToggle(false);
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const withdrawalMutation = useMutation(
    ['ParticipantService.updateFraudRank'],
    (params: mutationProps) =>
      ParticipantService.updateFraudRank({
        latestFraudRank: params.latestFraudRank,
        withdrawalId: params.id
      }),
    {
      onError: () => {
        showSnackbar({
          message: 'Error updating withdrawal callout to participant',
          severity: 'error'
        });
      },
      onSuccess: () => {
        toggleEdit();
        queryClient.refetchQueries(['FraudrankerService.getReport']);
        showSnackbar({
          message: 'Success!',
          severity: 'success'
        });
      }
    }
  );

  const loanMutation = useMutation(
    ['ParticipantService.updateLoan'],
    (params: mutationProps) =>
      ParticipantService.updateLoan(params.participantId, params.id, {
        attributes: { latestFraudRank: params.latestFraudRank },
        id: +params.id
      } as UpdateLoanDto),
    {
      onError: () => {
        showSnackbar({
          message: 'Error updating loan callout to participant',
          severity: 'error'
        });
      },
      onSuccess: () => {
        toggleEdit();
        queryClient.refetchQueries(['FraudrankerService.getReport']);
        showSnackbar({
          message: 'Success',
          severity: 'success'
        });
      }
    }
  );

  const newFraudRankOptions = useMemo(() => {
    const fraudScore =
      colorToString[fraudColor(props.data.latestFraudrank ?? props.data.score)];
    return ['green', 'yellow', 'red'].filter(rank => rank !== fraudScore);
  }, [props.data.score]);

  const handleSetNewRank = (event: SelectChangeEvent) => {
    if (props.data.reason === 'Loan') {
      loanMutation.mutate({
        id: props.data.id,
        latestFraudRank: event.target.value,
        participantId: props.data.participant.id
      });
    } else {
      withdrawalMutation.mutate({
        id: props.data.id,
        latestFraudRank: event.target.value
      });
    }
  };

  return (
    <Stack alignItems='center' direction='row' spacing={1}>
      {edit && (
        <Select onChange={handleSetNewRank} size='small'>
          {newFraudRankOptions.map(rank => (
            <MenuItem key={rank} value={rank}>
              {rank.charAt(0).toUpperCase() + rank.slice(1)}
            </MenuItem>
          ))}
        </Select>
      )}
      {!edit && (
        <>
          <Typography>
            {props.data.latestFraudrank
              ? EMPTY_FIELD_PLACEHOLDER
              : props.data.score}
          </Typography>
          <ColoredCircle
            color={fraudColor(
              props.data.latestFraudrank
                ? props.data.latestFraudrank
                : props.data.score
            )}
          />
          <IconButton aria-label='edit rank' onClick={toggleEdit} size='small'>
            <EditIcon fontSize='inherit' />
          </IconButton>
        </>
      )}
    </Stack>
  );
};
