import { createSvgIcon, SvgIconProps } from '@mui/material';

import React, { FC } from 'react';

export const GroupIcon: FC<SvgIconProps> = createSvgIcon(
  <path
    d='M8 14C10.2 14 12 12.2 12 10C12 7.8 10.2 6 8 6C5.8 6 4 7.8 4 10C4 12.2 5.8 14 8 14ZM10.26 16.2C9.52 16.08 8.78 16 8 16C6.02 16 4.14 16.42 2.44 17.16C0.96 17.8 0 19.24 0 20.86V24H9V20.78C9 19.12 9.46 17.56 10.26 16.2ZM40 14C42.2 14 44 12.2 44 10C44 7.8 42.2 6 40 6C37.8 6 36 7.8 36 10C36 12.2 37.8 14 40 14ZM48 20.86C48 19.24 47.04 17.8 45.56 17.16C43.86 16.42 41.98 16 40 16C39.22 16 38.48 16.08 37.74 16.2C38.54 17.56 39 19.12 39 20.78V24H48V20.86ZM32.48 15.3C30.14 14.26 27.26 13.5 24 13.5C20.74 13.5 17.86 14.28 15.52 15.3C13.36 16.26 12 18.42 12 20.78V24H36V20.78C36 18.42 34.64 16.26 32.48 15.3ZM16.14 20C16.32 19.54 16.4 19.22 17.96 18.62C19.9 17.86 21.94 17.5 24 17.5C26.06 17.5 28.1 17.86 30.04 18.62C31.58 19.22 31.66 19.54 31.86 20H16.14ZM24 4C25.1 4 26 4.9 26 6C26 7.1 25.1 8 24 8C22.9 8 22 7.1 22 6C22 4.9 22.9 4 24 4ZM24 0C20.68 0 18 2.68 18 6C18 9.32 20.68 12 24 12C27.32 12 30 9.32 30 6C30 2.68 27.32 0 24 0Z'
    fill='#E0E0E0'
  />,
  'GroupIcon'
);

GroupIcon.defaultProps = {
  style: {
    height: 48,
    width: 48
  },
  viewBox: '0 0 48 24'
};

GroupIcon.displayName = 'GroupIcon';
