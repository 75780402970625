import CollapsibleTable from '@/components/collapsible-table';
import { alpha, Card, CardHeader, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useMemo } from 'react';

import DeconversionRequestsCollapsible from './DeconversionRequestsCollapsible.component';
import DeconversionRequestsTableCell from './DeconversionRequestsTableCell.component';

interface DeconversionRequestsTabProps {
  data: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  headerCell: {
    color: alpha(theme.palette.common.black, 0.6),
    fontSize: theme.spacing(1.75),
    fontWeight: 500,
    paddingBottom: theme.spacing(1.25),
    paddingTop: theme.spacing(1.25)
  }
}));

const DeconversionRequests: React.FunctionComponent<
  DeconversionRequestsTabProps
> = (props: DeconversionRequestsTabProps) => {
  const { data } = props;

  const classes = useStyles();

  const deconversionRequestColumns = useMemo(
    () => [
      {
        cellClassName: classes.headerCell,
        field: 'request',
        headerName: 'Request',
        width: 130
      },
      {
        cellClassName: classes.headerCell,
        field: 'status',
        headerName: 'Execution Status',
        width: 130
      },
      {
        cellClassName: classes.headerCell,
        field: 'workflowStatus',
        headerName: 'Workflow Status',
        width: 130
      },
      {
        cellClassName: classes.headerCell,
        field: 'updatedAt',
        headerName: 'Updated At',
        width: 130
      },
      {
        cellClassName: classes.headerCell,
        field: 'createdAt',
        headerName: 'Create At',
        width: 130
      },
      {
        cellClassName: classes.headerCell,
        field: 'actions',
        headerName: ''
      }
    ],
    [classes]
  );

  return (
    <Card sx={{ mt: theme => theme.spacing(5) }} variant='outlined'>
      <CardHeader title='Plan Deconversion Request' />
      <CollapsibleTable
        backgroundPaperElevation={0}
        cellComponent={DeconversionRequestsTableCell}
        collapsibleComponent={
          data?.[0]?.transactions.length > 0
            ? DeconversionRequestsCollapsible
            : null
        }
        columns={deconversionRequestColumns}
        headerPagination
        rootPaperElevation={0}
        tableData={data}
        tablePaginationSx={{
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          borderTop: '1px solid rgba(0, 0, 0, 0.12)'
        }}
      />
    </Card>
  );
};

export default DeconversionRequests;
