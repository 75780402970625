import { AlertStatus } from '@vestwell-sub-accounting/models/common/AlertStatus';

export const AlertStatusColorMap: Record<
  string,
  'info' | 'neutral' | 'success' | 'warning'
> = {
  [AlertStatus.Open]: 'warning',
  [AlertStatus.OutreachNeeded]: 'warning',
  [AlertStatus.InProcess]: 'success',
  [AlertStatus.Blocked]: 'neutral',
  [AlertStatus.Waiting]: 'neutral',
  [AlertStatus.Closed]: 'info'
};
