import CircularLoading from '@/components/circular-loading';
import { FundChangePreviewRow } from '@/routes/ops/investments/FundChangesTab/FundChangePreviewRow.component';
import { FundChangePreview } from '@/routes/ops/investments/FundChangesTab/types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

type FundChangesPreviewModalProps = {
  isPreviewModalOpen: boolean;
  isSubmitting: boolean;
  onPreviewModalClose: () => void;
  fundChangePreview?: FundChangePreview[];
  onSubmit: () => void;
};

export const FundChangesPreviewModal = (
  props: FundChangesPreviewModalProps
): JSX.Element => {
  const {
    isPreviewModalOpen,
    isSubmitting,
    onPreviewModalClose,
    onSubmit,
    fundChangePreview
  } = props;
  const theme = useTheme();

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      onClose={isSubmitting ? () => undefined : onPreviewModalClose}
      open={isPreviewModalOpen}>
      <DialogTitle>Confirm security fund change details</DialogTitle>
      <DialogContent sx={{ p: 0, textAlign: 'center' }}>
        {fundChangePreview ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    Fund Change Effective Date
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>Old Fund</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>New Fund</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fundChangePreview?.map((fundChange, index) => {
                  return (
                    <FundChangePreviewRow
                      fundChange={fundChange.fundChange}
                      key={index}
                      plansAffected={fundChange.plansAffected}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <CircularLoading size={theme.spacing(4.5)} />
        )}
      </DialogContent>
      <DialogActions sx={{ px: theme.spacing(3), py: theme.spacing(1.75) }}>
        <Button
          disabled={isSubmitting}
          onClick={isSubmitting ? () => undefined : onPreviewModalClose}>
          Keep Editing
        </Button>
        <Button disabled={isSubmitting} onClick={onSubmit} variant='contained'>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FundChangesPreviewModal.displayName = 'FundChangesPreviewModal';
