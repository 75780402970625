import CollapsibleTable from '@/components/collapsible-table';
import { DuplicateParticipantsDto } from '@/models/MergeParticipantsDto.model';
import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import { isEqual } from 'lodash';
import React, { FC, useMemo } from 'react';

import { EmployeeMatchesTableCell } from './EmployeeMatchesTableCell.component';

export enum MatchedAccountFields {
  ID = 'id',
  NAME = 'name',
  MATCHING_VALUE = 'matchingValue',
  ACTION = 'action'
}

type EmployeeMatchesTableProps = {
  originalParticipant: Record<string, any>;
  duplicates: DuplicateParticipantsDto[];
};

const columns: GridColDef[] = [
  {
    field: MatchedAccountFields.ID,
    headerName: 'ID',
    sortable: false
  },
  {
    field: MatchedAccountFields.NAME,
    headerName: 'Name',
    sortable: false
  },
  {
    field: MatchedAccountFields.MATCHING_VALUE,
    headerName: 'Matching Value',
    sortable: false
  },
  {
    align: 'center',
    field: MatchedAccountFields.ACTION,
    headerName: 'Action',
    sortable: false
  }
];

export const EmployeeMatchesTable: FC<EmployeeMatchesTableProps> = props => {
  const tableData = useMemo(
    () =>
      props.duplicates.map(duplicate => {
        const match = [
          ...(isEqual(
            duplicate.ssn.slice(-4),
            props.originalParticipant.ssn.slice(-4)
          )
            ? ['ssn']
            : []),
          ...(isEqual(
            `${duplicate.lastName}, ${duplicate.firstName}`,
            props.originalParticipant.name
          )
            ? ['name']
            : [])
        ];

        return {
          ...duplicate,
          matchBy: match,
          originalParticipant: props.originalParticipant,
          planId: props.originalParticipant.plan.data.id,
          planName: props.originalParticipant.plan.data.attributes.name,
          planType: props.originalParticipant.plan.data.attributes.type
        };
      }),
    [props.duplicates, props.originalParticipant]
  );

  return (
    <Box data-testid='employee-matches-table'>
      <CollapsibleTable
        backgroundPaperElevation={0}
        cellComponent={EmployeeMatchesTableCell}
        columns={columns}
        data-testid='employee-merge-accounts-matches-table'
        disablePagination
        primaryKey='createdAt'
        rootPaperElevation={0}
        tableData={tableData}
      />
    </Box>
  );
};

EmployeeMatchesTable.displayName = 'EmployeeMatchesTable';
