import { Stack, StackProps } from '@mui/material';

import { Children, cloneElement, isValidElement } from 'react';

type TreeItemProps = {
  children: React.ReactNode;
  index?: number;
};

export const TreeItem = (props: TreeItemProps) => {
  const Arm = (
    <svg
      fill='none'
      height='20'
      viewBox='0 0 20 20'
      width='20'
      xmlns='http://www.w3.org/2000/svg'>
      <path d='M4 0V9C4 9.55228 4.44772 10 5 10H16' stroke='#E0E0E0' />
    </svg>
  );

  return (
    <Stack
      alignItems='center'
      direction='row'
      ml={props.index === 0 ? 0 : 4 * (props.index - 1)}>
      {props.index !== 0 && Arm}
      {props.children}
    </Stack>
  );
};

const Tree = (props: StackProps) => {
  return (
    <Stack {...props}>
      {props.children &&
        Children.map(props.children, (child, index) =>
          isValidElement<TreeItemProps>(child)
            ? cloneElement(child, { index })
            : null
        )}
    </Stack>
  );
};

export default Tree;
