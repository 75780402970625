import formatters from '@/utils/Formatters';

export type StatusBadgeProps = {
  label: string;
  color: LabelColor;
  values: string[];
};

export const statusLabels: StatusBadgeProps[] = [
  { color: 'success', label: 'Delivered', values: ['Delivery'] },
  { color: 'success', label: 'Open', values: ['Open', 'Click'] },
  { color: 'warning', label: 'Bounce', values: ['Bounce'] },
  { color: 'warning', label: 'Reported as Spam', values: ['Complaint'] },
  {
    color: 'lightWarning',
    label: 'Not Delivered',
    values: ['Rendering Failure', 'Send']
  }
];

type LabelColor =
  | 'info'
  | 'neutral'
  | 'success'
  | 'warning'
  | 'lightWarning'
  | undefined;

export const getLabelAndColor = (
  status: string
): { label: string; color: LabelColor } => {
  switch (status?.toLowerCase()) {
    case 'delivery':
      return { color: 'success', label: 'Delivered' };
    case 'click':
    case 'open':
      return { color: 'success', label: 'Open' };
    case 'bounce':
      return { color: 'warning', label: 'Bounce' };
    case 'complaint':
      return { color: 'warning', label: 'Reported as Spam' };
    case 'rendering failure':
    default:
      return { color: 'lightWarning', label: 'Not Delivered' };
  }
};

export const getInitiatorLabel = (idType: string, id: string) => {
  switch (idType) {
    case 'participant':
      return 'Saver';
    case 'sponsor':
      return 'Employer';
    case 'LDAP':
      return `Admin - ${id}`;
    default:
      return formatters.capitalizeFirstChar(idType || '--');
  }
};

export const successLetterEventTypes = [
  'letter.delivered',
  'letter.viewed',
  'letter.certified.delivered',
  'postcard.delivered',
  'postcard.viewed'
];

export const neutralLetterEventTypes = [
  'letter.created',
  'letter.rendered_pdf',
  'letter.rendered_thumbnails',
  'letter.mailed',
  'letter.in_transit',
  'letter.in_local_area',
  'letter.processed_for_delivery',
  'letter.certified.mailed',
  'letter.certified.in_transit',
  'letter.certified.in_local_area',
  'letter.certified.processed_for_delivery',
  'letter.certified.pickup_available',
  'letter.certified.issue',
  'letter.return_envelope.created',
  'letter.return_envelope.in_transit',
  'letter.return_envelope.in_local_area',
  'letter.return_envelope.processed_for_delivery',
  'letter.return_envelope.re-routed',
  'postcard.created',
  'postcard.rendered_pdf',
  'postcard.rendered_thumbnails',
  'postcard.mailed',
  'postcard.in_transit',
  'postcard.in_local_area',
  'postcard.processed_for_delivery',
  'postcard.re-routed'
];

export const errorLetterEventTypes = [
  'letter.deleted',
  'letter.failed',
  'letter.re-routed',
  'letter.returned_to_sender',
  'letter.certified.re-routed',
  'letter.certified.returned_to_sender',
  'letter.return_envelope.returned_to_sender',
  'postcard.rejected',
  'postcard.deleted',
  'postcard.failed',
  'postcard.returned_to_sender'
];

export const physicalMailLetterEventTypesLabels: StatusBadgeProps[] = [
  {
    color: 'success',
    label: 'Success',
    values: successLetterEventTypes
  },
  {
    color: 'neutral',
    label: 'Pending',
    values: neutralLetterEventTypes
  },
  {
    color: 'warning',
    label: 'Error',
    values: errorLetterEventTypes
  }
];

export const getStatusLabelAndColor = (
  letterEventType: string
): { label: string; color: LabelColor } => {
  if (neutralLetterEventTypes.includes(letterEventType)) {
    return {
      color: 'neutral',
      label: splitAndFormatMailerLetterEventType(letterEventType).status
    };
  }
  if (successLetterEventTypes.includes(letterEventType)) {
    return {
      color: 'success',
      label: splitAndFormatMailerLetterEventType(letterEventType).status
    };
  }
  if (errorLetterEventTypes.includes(letterEventType)) {
    return {
      color: 'warning',
      label: splitAndFormatMailerLetterEventType(letterEventType).status
    };
  }
  return {
    color: 'neutral',
    label: 'Unknown Letter Event Type'
  };
};

export const formatStatus = (status: string): string => {
  return status
    .replace(/[._]/g, ' ')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const splitAndFormatMailerLetterEventType = (
  mailerLetterEventType: string
): { mailType: string; status: string } => {
  const lastDotIndex = mailerLetterEventType.lastIndexOf('.');
  if (lastDotIndex === -1) {
    return { mailType: formatStatus(mailerLetterEventType), status: '' };
  }
  const mailType = mailerLetterEventType.substring(0, lastDotIndex + 1);
  const status = mailerLetterEventType.substring(lastDotIndex + 1);
  return {
    mailType: formatStatus(mailType).trim(),
    status: formatStatus(status)
  };
};

export const mailerLetterEventTypeToTooltip = (status: string): string => {
  switch (status) {
    case 'letter.created':
      return 'Mail was successfully created (Lob returns a 200 status code).';
    case 'letter.rendered_pdf':
      return 'Mail’s PDF proof was successfully rendered.';
    case 'letter.rendered_thumbnails':
      return 'Mail’s thumbnails were successfully rendered.';
    case 'letter.deleted':
      return 'Mail was successfully canceled.';
    case 'letter.mailed':
      return 'Mail has been handed off to and accepted by USPS and is en route.';
    case 'letter.in_transit':
      return 'Mail is being processed at the entry/origin facility.';
    case 'letter.in_local_area':
      return 'Mail is being processed at the destination facility.';
    case 'letter.processed_for_delivery':
      return "The mail has been greenlit for delivery at the recipient's nearest postal facility. The mail should reach the mailbox within 1 business day of this tracking event.";
    case 'letter.delivered':
      return "The mail piece has been delivered to the recipient’s address. The final scan is generated when the mail carrier's GPS unit leaves the delivery area.";
    case 'letter.failed':
      return 'Mail receives a "Failed" rendering error or tracking event. Please reach out to LOB for assistance if need be.';
    case 'letter.re-routed':
      return 'Mail was re-routed due to recipient change of address, address errors, or USPS relabeling of barcode/ID tag area.';
    case 'letter.returned_to_sender':
      return 'Mail is being returned to sender due to barcode, ID tag area, or address errors.';
    case 'letter.viewed':
      return 'Mail’s QR code or URL was scanned or viewed by the recipient.';
    case 'letter.certified.mailed':
      return 'Mail has been handed off to and accepted by USPS and is en route.';
    case 'letter.certified.in_transit':
      return 'Mail is being processed at the entry/origin facility.';
    case 'letter.certified.in_local_area':
      return 'Mail is being processed at the destination facility.';
    case 'letter.certified.processed_for_delivery':
      return "The mail has been greenlit for delivery at the recipient's nearest postal facility. The mail should reach the mailbox within 1 business day of this tracking event.";
    case 'letter.certified.re-routed':
      return 'Mail was re-routed due to recipient change of address, address errors, or USPS relabeling of barcode/ID tag area.';
    case 'letter.certified.returned_to_sender':
      return 'Mail is being returned to sender due to barcode, ID tag area, or address errors.';
    case 'letter.certified.delivered':
      return "The mail piece has been delivered to the recipient’s address. The final scan is generated when the mail carrier's GPS unit leaves the delivery area.";
    case 'letter.certified.pickup_available':
      return 'Mail is available for pickup at carrier location.';
    case 'letter.certified.issue':
      return 'Mail receives an "Issue" tracking event.';
    case 'letter.return_envelope.created':
      return 'A return envelope is created (occurs simultaneously with letter creation).';
    case 'letter.return_envelope.in_transit':
      return 'Mail is being processed at the entry/origin facility.';
    case 'letter.return_envelope.in_local_area':
      return 'Mail is being processed at the destination facility.';
    case 'letter.return_envelope.processed_for_delivery':
      return "The mail has been greenlit for delivery at the recipient's nearest postal facility. The mail should reach the mailbox within 1 business day of this tracking event.";
    case 'letter.return_envelope.re-routed':
      return 'Mail was re-routed due to recipient change of address, address errors, or USPS relabeling of barcode/ID tag area.';
    case 'letter.return_envelope.returned_to_sender':
      return 'Mail is being returned to sender due to barcode, ID tag area, or address errors.';
    case 'postcard.created':
      return 'Mail was successfully created';
    case 'postcard.rejected':
      return 'A postcard was not successfully created (Usually happens when one or more postcards fail the creation step during a batch request)';
    case 'postcard.rendered_pdf':
      return 'Mail’s PDF proof was successfully rendered.';
    case 'postcard.rendered_thumbnails':
      return 'Mail’s thumbnails were successfully rendered.';
    case 'postcard.deleted':
      return 'Mail was successfully canceled.';
    case 'postcard.mailed':
      return 'Mail has been handed off to and accepted by USPS and is en route.';
    case 'postcard.in_transit':
      return 'Mail is being processed at the entry/origin facility.';
    case 'postcard.in_local_area':
      return 'Mail is being processed at the destination facility.';
    case 'postcard.processed_for_delivery':
      return "The mail has been greenlit for delivery at the recipient's nearest postal facility. The mail should reach the mailbox within 1 business day of this tracking event.";
    case 'postcard.delivered':
      return "The mail piece has been delivered to the recipient’s address. The final scan is generated when the mail carrier's GPS unit leaves the delivery area.";
    case 'postcard.failed':
      return 'Mail receives a "Failed" rendering error or tracking event. Please reach out to LOB for assistance if need be.';
    case 'postcard.re-routed':
      return 'Mail was re-routed due to recipient change of address, address errors, or USPS relabeling of barcode/ID tag area.';
    case 'postcard.returned_to_sender':
      return 'Mail is being returned to sender due to barcode, ID tag area, or address errors.';
    case 'postcard.viewed':
      return 'Mail’s QR code or URL was scanned or viewed by the recipient.';
    default:
      return '';
  }
};
