import { Typography } from '@mui/material';

interface DetailValueProps {
  children?: string;
  color?: string;
}

const DetailValue = (props: DetailValueProps): JSX.Element => {
  const { children, color = 'rgba(0, 0, 0, 0.87)' } = props;

  return (
    <Typography color={color} title={children} variant='caption'>
      {children || ''}
    </Typography>
  );
};

export default DetailValue;
