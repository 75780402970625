import { GroupsWithDates } from '@/routes/plans/plan-detail/PlanDocumentsTab/PlanDocumentsGroup.component';

import * as yup from 'yup';

import formatters from '../Formatters';

export const UPLOAD_DOC_ERROR = {
  NO_DATE: 'Required',
  NO_DOC: 'No file chosen'
};
export function getUploadPlanDocValidationSchema(
  documentKey: boolean,
  groups: GroupsWithDates[]
): yup.AnyObjectSchema {
  return yup.object().shape({
    document: yup
      .object()
      .nullable()
      .test('is doc loaded', UPLOAD_DOC_ERROR.NO_DOC, docData => !docData),
    documentKey: documentKey
      ? yup.string().required('Document Key is required')
      : yup.string().nullable(),
    documentName: yup.string().required(UPLOAD_DOC_ERROR.NO_DOC),
    effectiveDate: getUniqueEffectivePerDocumentGroups(groups)
  });
}

export function getUniqueEffectivePerDocumentGroups(groups: GroupsWithDates[]) {
  return yup
    .string()
    .required(UPLOAD_DOC_ERROR.NO_DATE)
    .test(
      'effective-date-in-use',
      'This effective date is already in use',
      value => {
        return !groups
          ?.filter(group => group.groupingId > 0 && group.effectiveDate)
          ?.some(group => {
            return (
              formatters.formatFromIsoDateCustom(
                group.effectiveDate?.toString(),
                'MM/DD/YYYY'
              ) === formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY')
            );
          });
      }
    );
}
