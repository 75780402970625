import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { WorkflowContext } from '@/routes/ops/conductor/common/WorkflowContext';
import { DistributionService } from '@/services/ops/distributions/Distribution.service';
import formatters from '@/utils/Formatters';
import { useQuery } from '@tanstack/react-query';

import { AxiosError } from 'axios';
import { useContext } from 'react';

export const DistributionInputFields = () => {
  const workflowContext = useContext(WorkflowContext);
  const { showSnackbar } = useSnackbar();

  const distributionQuery = useQuery(
    ['DistributionService.getByTracerId', workflowContext.tracerId],
    () => DistributionService.getByTracerId(workflowContext.tracerId),
    {
      enabled: Boolean(workflowContext.tracerId),
      onError: (err: AxiosError) => {
        showSnackbar({
          message: `Failed to load distribution details: ${err.response?.data ? err.response.data : err.message}`,
          severity: 'error'
        });
      }
    }
  );

  return (
    <TextStack direction='column' divider>
      <TextStackItem>
        <TextLabel>Core Request</TextLabel>
        {workflowContext.investorId &&
        workflowContext.coreRequest?.context?.loanId ? (
          <TextValue
            links={[
              {
                label: `ID: ${workflowContext.coreRequest.context.loanId}`,
                target: '_blank',
                to: `/participant/${workflowContext.investorId}/loans/${workflowContext.coreRequest.context.loanId}`
              }
            ]}>
            {formatters.formatFromIsoDateCustom(
              workflowContext.coreRequest.createdAt,
              'M/DD/YYYY'
            )}{' '}
            Loan Disbursement
          </TextValue>
        ) : workflowContext.investorId &&
          workflowContext.coreRequest?.context?.withdrawalId ? (
          <TextValue
            links={[
              {
                label: `ID: ${workflowContext.coreRequest.context.withdrawalId}`,
                target: '_blank',
                to: `/participant/${workflowContext.investorId}/withdrawals/${workflowContext.coreRequest.context.withdrawalId}/details/tradeRequests`
              }
            ]}>
            {formatters.formatFromIsoDateCustom(
              workflowContext.coreRequest.createdAt,
              'M/DD/YYYY'
            )}{' '}
            Withdrawal
          </TextValue>
        ) : (
          <TextValue />
        )}
      </TextStackItem>

      <TextStackItem>
        <TextLabel>Plan</TextLabel>
        <TextValue
          links={
            workflowContext.planId && [
              {
                label: `ID: ${workflowContext.planId}`,
                target: '_blank',
                to: `/plans/${workflowContext.planId}`
              }
            ]
          }>
          {workflowContext.plan?.name}
        </TextValue>
      </TextStackItem>

      <TextStackItem>
        <TextLabel>Participant</TextLabel>
        <TextValue
          links={
            workflowContext.investorId && [
              {
                label: `ID: ${workflowContext.investorId}`,
                target: '_blank',
                to: `/participants/${workflowContext.investorId}`
              }
            ]
          }>
          {distributionQuery.data?.participantName}
        </TextValue>
      </TextStackItem>

      <TextStackItem>
        <TextLabel>Created</TextLabel>
        <TextValue>
          {formatters.formatFromIsoDate(workflowContext.createdAt)}
        </TextValue>
      </TextStackItem>
    </TextStack>
  );
};
