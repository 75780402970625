import AccessControl from '@/components/access-control/AccessControl.component';
import SimpleDropdown from '@/components/simple-dropdown';
import SimpleMaskedInput from '@/components/simple-masked-input';
import { TextLabel, TextStackItem, TextValue } from '@/components/text-stack';
import STATE_CODES from '@/consts/states.constants';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import {
  LoanAddressUpdate,
  LoanAddressUpdateRequest
} from '@/models/LoanDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import ParticipantService from '@/services/Participant.service';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { FC, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useToggle } from 'react-use';
import { Address } from 'scala-sdk';
import * as yup from 'yup';

type CheckAddressProps = {
  deliveryAddress?: Address;
  updateAddress?: LoanAddressUpdate;
  loanStatus: string;
};

type LoanDetailRouteProps = {
  participantId: string;
  loanId: string;
};

const validationSchema = yup.object({
  address1: yup.string().required('Address Line 1 is required'),
  address2: yup.string().optional(),
  city: yup.string().required('City is required'),
  state: yup.string().oneOf(STATE_CODES).required('State is required'),
  zip: yup
    .string()
    .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Must be a valid zip code')
    .required('Zip is required')
});

export const CheckAddress: FC<CheckAddressProps> = (
  props: CheckAddressProps
) => {
  const routeParams = useParams<LoanDetailRouteProps>();
  const [open, toggleOpen] = useToggle(false);
  const dialog = useDialog();
  const queryClient = useQueryClient();
  const userInfo = userService.getUser();
  const [successMessage, setSuccessMessage] = useState('');
  const { showSnackbar } = useSnackbar();

  const isUploadingUser =
    userInfo?.nickname === props.updateAddress?.submittedBy?.id;

  const loanAddressMutation = useMutation(
    ['ParticipantService.putLoanAddress', routeParams.loanId],
    (data: LoanAddressUpdateRequest) => {
      return ParticipantService.putLoanAddress(data);
    },
    {
      onError: () => {
        showSnackbar({
          message: `Couldn't submit address changes at the moment`,
          severity: 'error'
        });
      },
      onSuccess: () => {
        queryClient.refetchQueries([
          'ParticipantService.getLoan',
          routeParams.participantId,
          routeParams.loanId,
          true
        ]);
        showSnackbar({
          message: successMessage,
          severity: 'success'
        });
      }
    }
  );

  const openModal = useCallback(
    () =>
      dialog.openDialog({
        actionButtons: {
          cancelButton: {
            children: 'Cancel'
          },
          submitButton: {
            children: 'Submit for approval'
          }
        },
        onSubmit: rawInputValues => {
          setSuccessMessage('Submitted address changes for approval');
          loanAddressMutation.mutate({
            address1: rawInputValues.address1,
            address2: rawInputValues.address2,
            city: rawInputValues.city,
            loanId: +routeParams.loanId,
            state: rawInputValues.state,
            status: 'Pending',
            zip: rawInputValues.zip
          });
        },
        steps: [
          {
            fields: {
              address1: {
                initialValue:
                  props.updateAddress?.address1 ??
                  props.deliveryAddress?.address1,
                label: 'Address Line 1'
              },
              address2: {
                initialValue:
                  props.updateAddress?.address2 ??
                  props.deliveryAddress?.address2,
                label: 'Address Line 2'
              },
              city: {
                initialValue:
                  props.updateAddress?.city ?? props.deliveryAddress?.city,
                label: 'City'
              },
              state: {
                component: (
                  <SimpleDropdown
                    fieldId='state'
                    fieldName='State'
                    fieldValues={STATE_CODES}
                  />
                ),
                initialValue:
                  props.updateAddress?.state ?? props.deliveryAddress?.state,
                label: 'State'
              },
              zip: {
                component: (
                  <SimpleMaskedInput
                    fieldId='zip'
                    fieldName='Zipcode'
                    inputTransform={(raw: string) => {
                      const numerals = formatters.extractNumerals(raw, 9);
                      if (numerals.length <= 5) {
                        return numerals;
                      } else if (numerals.length <= 9) {
                        return `${numerals.slice(0, 5)}-${numerals.slice(5)}`;
                      }

                      return null;
                    }}
                    placeholder='90210'
                  />
                ),
                initialValue:
                  props.updateAddress?.zip ?? props.deliveryAddress?.zip,
                label: 'Zipcode'
              }
            },
            title: 'Update Payee Address'
          }
        ],
        validationSchema
      }),
    [props.updateAddress, props.deliveryAddress]
  );

  const cancelChanges = () => {
    setSuccessMessage('Payee address change has been canceled');
    loanAddressMutation.mutate({
      loanId: +routeParams.loanId,
      status: 'Canceled'
    });
    toggleOpen();
  };

  const rejectChanges = () => {
    setSuccessMessage('Payee address change has been rejected');
    loanAddressMutation.mutate({
      loanId: +routeParams.loanId,
      status: 'Rejected'
    });
  };

  const acceptChanges = () => {
    setSuccessMessage('Payee address has been successfully updated');
    loanAddressMutation.mutate({
      loanId: +routeParams.loanId,
      status: 'Approved'
    });
  };

  return (
    <>
      <TextStackItem>
        <TextLabel>Address</TextLabel>
        <TextValue data-testid='loan-address'>
          {props.deliveryAddress?.address1}
          <br />
          {props.deliveryAddress?.address2 && (
            <>
              {props.deliveryAddress?.address2}
              <br />
            </>
          )}
          {`${props.deliveryAddress?.city} ${props.deliveryAddress?.state} ${props.deliveryAddress?.zip}`}
          <br />
          <AccessControl
            requiresOneOf={[FeatureLevelPermissions.WRITE_MONEY_OUT_ADDRESS]}>
            {props.updateAddress?.status === 'Approved' && (
              <Typography color='GrayText' variant='caption'>{`Edited by ${
                props.updateAddress.submittedBy.id
              } & approved by ${
                props.updateAddress.approvedBy.id
              } ${formatters.formatFromIsoDate(
                props.updateAddress.updatedAt
              )}`}</Typography>
            )}
            {(props.updateAddress === undefined ||
              props.updateAddress?.status === 'Approved') && (
              <Button
                color='primary'
                disabled={
                  !['Fraud Check Pending', 'Fraud Check Complete'].includes(
                    props.loanStatus
                  )
                }
                onClick={openModal}
                variant='text'>
                EDIT
              </Button>
            )}
            {props.updateAddress?.status === 'Pending' && (
              <Paper
                square={false}
                sx={{
                  borderColor: theme => theme.palette.primary.main,
                  padding: 2
                }}
                variant='outlined'>
                <Typography color='CaptionText' variant='subtitle2'>
                  Pending Changes
                </Typography>
                {props.updateAddress.address1}
                <br />
                {props.updateAddress.address2 && (
                  <>
                    {props.updateAddress.address2}
                    <br />
                  </>
                )}
                {`${props.updateAddress.city} ${props.updateAddress.state} ${props.updateAddress.zip}`}
                <br />
                <Typography variant='caption'>{`Submitted by ${
                  props.updateAddress.submittedBy.id
                } ${formatters.formatFromIsoDate(
                  props.updateAddress.updatedAt
                )}`}</Typography>
                <Stack direction='row'>
                  {isUploadingUser ? (
                    <>
                      <Button
                        color='primary'
                        onClick={openModal}
                        variant='text'>
                        EDIT
                      </Button>
                      <Button
                        color='primary'
                        onClick={toggleOpen}
                        variant='text'>
                        CANCEL CHANGES
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        color='primary'
                        onClick={acceptChanges}
                        variant='text'>
                        APPROVE
                      </Button>
                      <Button
                        color='primary'
                        onClick={rejectChanges}
                        variant='text'>
                        REJECT
                      </Button>
                    </>
                  )}
                </Stack>
              </Paper>
            )}
          </AccessControl>
        </TextValue>
      </TextStackItem>
      <Dialog onClose={toggleOpen} open={open}>
        <DialogTitle>Cancel the submitted changes?</DialogTitle>
        <DialogActions>
          <Button onClick={toggleOpen}>GO BACK</Button>
          <LoadingButton
            loading={loanAddressMutation.isLoading}
            onClick={cancelChanges}>
            CANCEL CHANGES
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
