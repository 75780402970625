import { Parser } from 'json2csv';
import type { Options } from 'json2csv';
import { mapValues } from 'lodash';

interface ConvertOptions extends Options<any> {
  trimSpaces?: boolean;
}

const handleTrimSpaces = (row: Record<string, unknown>) =>
  mapValues(row, value => (typeof value === 'string' ? value.trim() : value));

const convertJsonToCsv = (
  data: Record<string, unknown>[] = [],
  options: ConvertOptions = {}
): string => {
  const {
    trimSpaces = true,
    escapedQuote = '',
    quote = '',
    transforms = []
  } = options;

  const json2csvParser = new Parser({
    ...options,
    escapedQuote,
    quote,
    transforms: [...(trimSpaces ? [handleTrimSpaces] : []), ...transforms]
  });

  return json2csvParser.parse(data);
};

export default convertJsonToCsv;
