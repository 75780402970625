import { useUserToken } from '@/contexts/UserTokenContext';
import ApiService from '@/services/Api.service';
import { TokenData, userService } from '@/services/User.service';
import { Button, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import React from 'react';
import {
  Navigate,
  NavigateFunction,
  Link as RouterLink,
  useLocation
} from 'react-router-dom';

import LinearLoading from '../linear-loading/LinearLoading.component';

interface ErrorObject {
  errorText: string;
  errorCode: string;
}

export const redirectToErrorPage = (
  error: Record<string, any>
): JSX.Element => {
  return (
    <Navigate
      // We should add types to the errors from react-query
      // For now assert it to Error here.
      state={{ error: { code: error.code, message: error.message } }}
      to={{
        pathname: '/entity-not-found'
      }}
    />
  );
};

export const navigateToErrorPage = (
  navigate: NavigateFunction,
  error: Record<string, any>
) => {
  navigate('/entity-not-found', {
    state: { error: { code: error.code, message: error.message } }
  });
};

export const generateErrorMessage = (error: Error): ErrorObject => {
  const [errorCode] = error?.message?.match(/(\d{3})/) || ['500'];

  switch (errorCode) {
    case '502':
    case '501':
      return {
        errorCode,
        errorText: 'We encountered an error.'
      };
    case '500':
      return {
        errorCode,
        errorText: 'Something unexpected happened.'
      };
    case '404':
      return {
        errorCode,
        errorText: 'We couldn’t find this information.'
      };
    case '403':
    case '401':
      return {
        errorCode,
        errorText: 'You don’t have access.'
      };
    default:
      return {
        errorCode,
        errorText: 'Something unexpected happened.'
      };
  }
};

const useStyles = makeStyles((theme: Theme) => ({
  backBtn: {
    fontWeight: 'bold',
    marginTop: theme.spacing(7),
    width: theme.spacing(20)
  },
  errorCode: {
    marginTop: theme.spacing(4)
  },
  errorContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    width: '100%'
  },
  errorText: {
    marginTop: theme.spacing(2),
    textAlign: 'center'
  },
  img: {
    marginBottom: theme.spacing(7)
  }
}));

const ErrorDetailPage: React.FunctionComponent = (): JSX.Element => {
  const classes = useStyles();
  const location = useLocation();

  const queryClient = useQueryClient();

  const { userHasValidToken } = useUserToken();

  // todo moshe: type this properly
  const { state, search } = location;
  const queryParams = new URLSearchParams(search);

  const code = queryParams.get('code');
  const description = queryParams.get('message');

  const { errorCode, errorText } = generateErrorMessage(
    code ? new Error(`${code}`) : location.state.error
  );

  const meQuery = useQuery<TokenData>(
    ['me'],
    () => ApiService.getJson<TokenData>('me'),
    { enabled: userHasValidToken }
  );

  // moshe: We only send users to this page if they have an error.
  // If no error is present, this implies user should not be here, redirect them back home
  if (!state?.error && !code) {
    return <Navigate to='/' />;
  }

  // we don't have an email to show, but our query is loading, show loading screen
  if (meQuery.isFetching && !userService.getTokenData()?.helpEmail) {
    return <LinearLoading />;
  }

  // try to show a custom email, default to vestwell's own help email
  const helpEmail: string =
    userService.getTokenData()?.helpEmail ||
    meQuery.data?.helpEmail ||
    'help@vestwell.com';

  return (
    <div className={classes.errorContainer}>
      <img alt='Error' className={classes.img} src='/images/error-img.png' />
      <Typography data-testid='error-page-oops-message' variant='h2'>
        Oops!
      </Typography>
      <Typography
        className={classes.errorText}
        data-testid='error-page-error-text-message'
        variant='h2'>
        {errorText}
      </Typography>
      {description && (
        <Typography
          className={classes.errorText}
          data-testid='error-page-email-help-description'>
          {description}
        </Typography>
      )}
      <Typography
        className={classes.errorText}
        data-testid='error-page-email-help-message'>
        Please email {helpEmail} or try again.
      </Typography>
      <Typography
        className={classes.errorCode}
        data-testid='error-page-error-code-message'>
        Error Code: {errorCode}
      </Typography>
      <Button
        className={classes.backBtn}
        component={RouterLink}
        data-testid='error-page-back-home-button'
        onMouseDown={() => {
          queryClient.invalidateQueries();
        }}
        to='/'
        variant='contained'>
        Back Home
      </Button>
    </div>
  );
};

export default ErrorDetailPage;
