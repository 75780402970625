import VisibilityToggle from '@/components/visibility-toggle';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { ParticipantBeneficiary } from '@/models/ParticipantDTO.model';
import formatters from '@/utils/Formatters';
import { Stack, Typography } from '@mui/material';
import { formatSsn } from '@vestwell-frontend/helpers';

import React, { useMemo, useState } from 'react';

export interface BeneficaryCardProps {
  beneficiary: ParticipantBeneficiary;
}

export const BeneficaryCard: React.FunctionComponent<
  BeneficaryCardProps
> = props => {
  const [isMaskActive, setIsMaskActive] = useState(true);

  const ssn = useMemo(() => {
    if (!props.beneficiary.attributes.ssn) {
      return EMPTY_FIELD_PLACEHOLDER;
    }

    const formattedSSN = formatSsn(props.beneficiary.attributes.ssn);

    return isMaskActive ? formatters.maskSSN(formattedSSN) : formattedSSN;
  }, [isMaskActive, props.beneficiary.attributes]);

  return (
    <Stack spacing={1}>
      {props.beneficiary.attributes.beneficiaryType === 'person' ? (
        <Stack>
          <Typography variant='body1'>
            {props.beneficiary.attributes.firstName}{' '}
            {props.beneficiary.attributes.lastName}
          </Typography>
          <Typography variant='caption'>
            {props.beneficiary.attributes.isPrimary ? 'Primary - ' : ''}
            {props.beneficiary.attributes.relationship}
          </Typography>
        </Stack>
      ) : (
        <Typography variant='caption'>
          {props.beneficiary.attributes.isPrimary ? 'Primary - ' : ''}
          {props.beneficiary.attributes.beneficiaryType === 'trust'
            ? 'Trust'
            : 'Organization'}
        </Typography>
      )}

      {props.beneficiary.attributes.beneficiaryType === 'person' && (
        <Stack alignItems='center' direction='row'>
          <Typography color='textSecondary' variant='body1'>
            SSN: {ssn}
          </Typography>
          <VisibilityToggle
            component='div'
            isMasked={isMaskActive}
            toggleMask={() => setIsMaskActive(!isMaskActive)}
          />
        </Stack>
      )}
      {props.beneficiary.attributes.ein && (
        <Typography color='textSecondary' variant='body1'>
          EIN: {props.beneficiary.attributes.ein}
        </Typography>
      )}
      {props.beneficiary.attributes.trustId && (
        <Typography color='textSecondary' variant='body1'>
          Trust ID: {props.beneficiary.attributes.trustId}
        </Typography>
      )}
      <Typography color='textSecondary' variant='body1'>
        {props.beneficiary.attributes.address1}
      </Typography>
      {['organization', 'trust'].includes(
        props.beneficiary.attributes.beneficiaryType
      ) &&
        props.beneficiary.attributes.address2 && (
          <Typography color='textSecondary' variant='body1'>
            {props.beneficiary.attributes.address2}
          </Typography>
        )}
      <Typography color='textSecondary' variant='body1'>
        {[
          props.beneficiary.attributes.city,
          props.beneficiary.attributes.state,
          props.beneficiary.attributes.zip
        ]
          .filter(str => str?.trim() !== '')
          .join(', ')}
      </Typography>
    </Stack>
  );
};
