import { GroupIcon } from '@/components/icon/GroupIcon';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { EsaPlanGroup } from '@/models/EsaPlanGroup.model';
import { EsaService } from '@/services/Esa.service';
import formatters from '@/utils/Formatters';
import EditIcon from '@mui/icons-material/Edit';
import {
  Unstable_Grid2 as Grid,
  IconButton,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import React, { useMemo, useState } from 'react';

import { EditGroupModal } from './EditGroupModal.component';

type EsaPlanGroupsTableProps = {
  planId: number;
};

export const EsaPlanGroupsTable: React.FC<EsaPlanGroupsTableProps> = props => {
  const [groupInEdit, setGroupInEdit] = useState<EsaPlanGroup | undefined>(
    undefined
  );
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  const EsaPlanGroupsQuery = useQuery(
    ['EsaService.getEsaPlanGroups', props.planId],
    () => EsaService.getEsaPlanGroups(props.planId),
    {
      staleTime: Infinity
    }
  );

  const updateGroup = useMutation(
    ['EsaService.updateEsaPlanGroup'],
    (updatedGroup: EsaPlanGroup) =>
      EsaService.updateEsaPlanGroup(
        props.planId,
        updatedGroup.groupId,
        updatedGroup
      ),
    {
      onError: () => {
        showSnackbar({ message: 'Error updating group', severity: 'error' });
      },
      onSuccess: async () => {
        await queryClient.refetchQueries([
          'EsaService.getEsaPlanGroups',
          props.planId
        ]);
        showSnackbar({ message: 'Group updated', severity: 'success' });
        setGroupInEdit(undefined);
      }
    }
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        cellClassName: 'primary-font-size',
        field: 'name',
        flex: 1,
        headerName: 'Name',
        sortable: false,
        valueGetter: params => params.row.name ?? '--'
      },
      {
        field: 'employerMatch',
        flex: 1,
        headerName: 'Employer Match',
        renderCell: params => (
          <Stack>
            <Typography color='text.primary'>
              {formatters.formatDollars(params.row.match.dollarAmount, 0)}
            </Typography>
            <Typography variant='body2'>
              Match cap{' '}
              {formatters.formatDollars(params.row.match.lifetimeAmount, 0)}
            </Typography>
          </Stack>
        ),
        sortable: false
      },
      {
        field: 'initialDepositBonus',
        flex: 1,
        headerName: 'Initial Deposit Bonus',
        renderCell: params => (
          <Stack>
            <Typography color='text.primary'>
              {formatters.formatDollars(params.row.initialBonus, 0)}
            </Typography>
            <Typography variant='body2'>
              Required{' '}
              {formatters.formatDollars(params.row.initialBonusThreshold, 0)}
            </Typography>
          </Stack>
        ),
        sortable: false
      },
      {
        field: 'milestoneBonus',
        flex: 1,
        headerName: 'Milestone Bonus',
        renderCell: params => (
          <Stack>
            <Typography color='text.primary'>
              {formatters.formatDollars(params.row.milestoneBonus.amount, 0)}
            </Typography>
            <Typography variant='body2'>
              Required{' '}
              {formatters.formatDollars(params.row.milestoneBonus.threshold, 0)}
            </Typography>
          </Stack>
        ),
        sortable: false
      },
      {
        cellClassName: 'primary-font-size',
        field: 'participantCount',
        flex: 1,
        headerName: 'Employees',
        sortable: false,
        valueGetter: params => params.row.participantCount ?? '--'
      },
      {
        cellClassName: 'primary-font-size',
        field: 'action',
        headerName: '',
        renderCell: params => (
          <IconButton
            aria-label='edit'
            data-testid={`button-edit-group-${params.row.groupId}`}
            onClick={() => {
              setGroupInEdit(params.row);
            }}
            sx={{
              color: theme => theme.palette.grey[600]
            }}>
            <EditIcon fontSize='inherit' />
          </IconButton>
        ),
        sortable: false
      }
    ],
    []
  );

  return (
    <Paper
      data-testid='employee-groups-card'
      elevation={0}
      sx={{ width: '100%' }}
      variant='outlined'>
      <Stack flexGrow={1}>
        <Grid container px={2} py={2.5}>
          <Typography variant='h5'>Employee Groups</Typography>
        </Grid>
        <DataGridPro
          autoHeight
          columns={columns}
          data-testid='employee-groups-table'
          disableColumnMenu
          disableColumnResize
          disableRowSelectionOnClick
          getRowId={(row: EsaPlanGroup) => row.groupId}
          hideFooter
          hideFooterRowCount
          loading={EsaPlanGroupsQuery.isLoading}
          rows={EsaPlanGroupsQuery.data ?? []}
          slots={{
            noRowsOverlay: () => (
              <Grid
                alignItems='center'
                container
                data-testid='no-employee-groups-data'
                direction='column'
                height='100%'
                justifyContent='center'>
                <GroupIcon />
                <Typography variant='body1'>No Employee Groups</Typography>
              </Grid>
            )
          }}
          sx={{
            '& .primary-font-size': {
              fontSize: theme => theme.typography.body1.fontSize
            },
            '.MuiDataGrid-columnSeparator': {
              display: 'none'
            },
            border: '0px !important',
            color: theme => theme.palette.text.primary
          }}
        />
      </Stack>
      <EditGroupModal
        closeModal={() => setGroupInEdit(undefined)}
        initialValues={groupInEdit}
        isOpen={!!groupInEdit}
        isSubmitting={updateGroup.isLoading}
        onSubmit={updateGroup.mutate}
      />
    </Paper>
  );
};

EsaPlanGroupsTable.displayName = 'EsaPlanGroupsTable';
