import Card, { CardContent } from '@/components/card';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { useSnackbar } from '@/contexts/SnackBarContext';
import ParentAccountService from '@/services/ops/accounts/ParentAccount.service';
import BalanceService from '@/services/ops/balances/Balance.service';
import CurrentDatesAndWindowsService from '@/services/ops/current-dates-and-windows/CurrentDatesAndWindows.service';
import formatters from '@/utils/Formatters';
import { Divider, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { PositionDateType } from '@vestwell-sub-accounting/models/accountsAndLedgers/PositionDateType';

import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { FC, useContext } from 'react';

import { AlertContext } from '../../AlertContext';
import { CardHeader } from '../common/CardHeader.component';

export const CustodianDestinationRejectParentAccountBalances: FC = () => {
  const alert = useContext(AlertContext);
  const { showSnackbar } = useSnackbar();

  const getCurrentDatesAndWindowsQuery = useQuery(
    ['CurrentDatesAndWindowsService.get'],
    () => CurrentDatesAndWindowsService.get(),
    {
      cacheTime: 0,
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Failed to fetch current trade date and processing windows: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const getParentAccountQuery = useQuery(
    [
      'ParentAccountService.get',
      alert.details.trade?.custodianAccountNumber,
      alert.details.trade?.custodianId
    ],
    () =>
      ParentAccountService.get({
        custodianAccountNumber: alert.details.trade.custodianAccountNumber,
        custodianId: alert.details.trade.custodianId
      }),
    {
      enabled:
        !!alert.details.trade?.custodianAccountNumber &&
        !!alert.details.trade?.custodianId,
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Failed getting Parent account: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const getParentAccountPositionQuery = useQuery(
    [
      'ParentAccountService.getParentToCustodianPositions',
      alert.details?.trade?.cusip,
      dayjs(getCurrentDatesAndWindowsQuery.data?.tradingDate),
      getParentAccountQuery.data?.parentAccountId
    ],
    async () => {
      const response = await ParentAccountService.getParentToCustodianPositions(
        {
          cusip: alert.details.trade.cusip,
          endDate: dayjs(
            getCurrentDatesAndWindowsQuery.data.tradingDate
          ).format('YYYY-MM-DD'),
          parentAccountId: getParentAccountQuery.data.parentAccountId,
          startDate: dayjs(getCurrentDatesAndWindowsQuery.data.tradingDate)
            .subtract(7, 'day')
            .format('YYYY-MM-DD')
        }
      );
      if (response.results.length === 0) {
        return null;
      }
      return response.results[response.results.length - 1];
    },
    {
      enabled:
        !!alert.details?.trade?.cusip &&
        !!dayjs(getCurrentDatesAndWindowsQuery.data?.tradingDate).toString() &&
        !!getParentAccountQuery.data?.parentAccountId &&
        !!dayjs(getCurrentDatesAndWindowsQuery.data?.tradingDate).toString(),
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Failed getting Parent account position: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const getParentBalancesQuery = useQuery(
    ['BalanceService.get'],
    () => BalanceService.get(getParentAccountQuery.data.parentAccountId),
    {
      enabled: !!getParentAccountQuery.data?.parentAccountId,
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Balance request failed: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const getParentAccountTradeBalanceQuery = useQuery(
    ['BalanceService.get', getParentAccountQuery.data?.parentAccountId],
    () =>
      BalanceService.get(getParentAccountQuery.data.parentAccountId, {
        dateType: PositionDateType.Trade
      }),
    {
      enabled: !!getParentAccountQuery.data?.parentAccountId,
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Balance request failed: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const getParentAccountSettlementBalanceQuery = useQuery(
    ['BalanceService.get', getParentAccountQuery.data?.parentAccountId],
    () =>
      BalanceService.get(getParentAccountQuery.data.parentAccountId, {
        dateType: PositionDateType.Settlement
      }),
    {
      enabled: !!getParentAccountQuery.data?.parentAccountId,
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Balance request failed: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  return (
    <Card size='small'>
      <CardHeader title='Parent Account Balances' />
      <Divider />
      <CardContent>
        <Stack spacing={5}>
          <Stack direction='row' justifyContent='space-between' spacing={2}>
            <Stack flex={1} spacing={1}>
              <Stack>
                <Typography variant='subtitle1'>Parent Account</Typography>
                <Typography variant='subtitle2'>
                  {getParentAccountQuery.data?.accountName}
                </Typography>
              </Stack>
              <TextStack direction='column'>
                <TextStackItem>
                  <TextLabel>Pending Balance</TextLabel>
                  <TextValue>
                    {getParentBalancesQuery.data?.cash.pending
                      ? formatters.formatDollars(
                          getParentBalancesQuery.data.cash.pending
                        )
                      : null}
                  </TextValue>
                </TextStackItem>
                <TextStackItem>
                  <TextLabel>Trade Balance</TextLabel>
                  <TextValue>
                    {getParentAccountTradeBalanceQuery.data?.cash?.confirmed
                      ? formatters.formatDollars(
                          getParentAccountTradeBalanceQuery.data.cash.confirmed
                        )
                      : null}
                  </TextValue>
                </TextStackItem>
                <TextStackItem>
                  <TextLabel>Settlement Balance</TextLabel>
                  <TextValue>
                    {getParentAccountSettlementBalanceQuery.data?.cash.confirmed
                      ? formatters.formatDollars(
                          getParentAccountSettlementBalanceQuery.data.cash
                            .confirmed
                        )
                      : null}
                  </TextValue>
                </TextStackItem>
              </TextStack>
              <Typography variant='caption'>
                As of date:{' '}
                {dayjs(getCurrentDatesAndWindowsQuery.data?.tradingDate).format(
                  'MM/DD/YYYY'
                )}
              </Typography>
            </Stack>
            <Divider flexItem orientation='vertical' />
            <Stack flex={1} spacing={1}>
              <Stack>
                <Typography variant='subtitle1'>
                  Parent Account Position
                </Typography>
                <Typography variant='subtitle2'>
                  {alert.details?.trade.cusip}
                </Typography>
              </Stack>
              <TextStack direction='column'>
                <TextStackItem>
                  <TextLabel>Pending Unit</TextLabel>
                  <TextValue>
                    {getParentAccountPositionQuery.data?.custodianUnits
                      ? formatters.formatDollars(
                          getParentAccountPositionQuery.data.custodianUnits,
                          3
                        )
                      : null}
                  </TextValue>
                </TextStackItem>
                <TextStackItem>
                  <TextLabel>Trade Unit</TextLabel>
                  <TextValue>
                    {getParentAccountPositionQuery.data?.tradeDateUnits
                      ? formatters.formatDollars(
                          getParentAccountPositionQuery.data.tradeDateUnits,
                          3
                        )
                      : null}
                  </TextValue>
                </TextStackItem>
                <TextStackItem>
                  <TextLabel>Settlement Unit</TextLabel>
                  <TextValue>
                    {getParentAccountPositionQuery.data?.settlementDateUnits
                      ? formatters.formatDollars(
                          getParentAccountPositionQuery.data
                            .settlementDateUnits,
                          3
                        )
                      : null}
                  </TextValue>
                </TextStackItem>
              </TextStack>
              <Typography variant='caption'>
                As of date:{' '}
                {dayjs(getCurrentDatesAndWindowsQuery.data?.tradingDate).format(
                  'MM/DD/YYYY'
                )}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction='row' justifyContent='space-between' spacing={2}>
            <Stack flex={1} spacing={1}>
              <Stack>
                <Typography variant='subtitle1'>Custodian</Typography>
                <Typography variant='subtitle2'>
                  {alert.details?.trade.custodianAccountNumber}
                </Typography>
              </Stack>
              <TextStack direction='column'>
                <TextStackItem>
                  <TextLabel>Cash Balance</TextLabel>
                  <TextValue>
                    {getParentAccountQuery.data?.cashBalance
                      ? formatters.formatDollars(
                          getParentAccountQuery.data?.cashBalance
                        )
                      : ''}
                  </TextValue>
                </TextStackItem>
              </TextStack>
              <Typography variant='caption'>
                Effective Date:{' '}
                {dayjs(getCurrentDatesAndWindowsQuery.data?.tradingDate).format(
                  'MM/DD/YYYY'
                )}
              </Typography>
            </Stack>
            <Divider flexItem orientation='vertical' />
            <Stack flex={1} spacing={1}>
              <Stack>
                <Typography variant='subtitle1'>Custodian Position</Typography>
                <Typography variant='subtitle2'>
                  {alert.details?.trade.cusip}
                </Typography>
              </Stack>
              <TextStack direction='column'>
                <TextStackItem>
                  <TextLabel>Unit</TextLabel>
                  <TextValue>
                    {getParentAccountPositionQuery.data?.custodianUnits
                      ? formatters.formatDollars(
                          getParentAccountPositionQuery.data.custodianUnits,
                          3
                        )
                      : null}
                  </TextValue>
                </TextStackItem>
              </TextStack>
              <Typography variant='caption'>
                As of date:{' '}
                {dayjs(
                  getParentAccountPositionQuery.data?.effectiveDate
                ).format('MM/DD/YYYY')}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

CustodianDestinationRejectParentAccountBalances.displayName =
  'CustodianDestinationRejectParentAccountBalances';
