import { Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';

export default styled(Dialog)(({ theme }) => ({
  '& .MuiDialogActions-root': {
    borderTop: '1px solid var(--mui-palette-grey-300)'
  },

  '& .MuiDialogContent-root': {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0
  },

  '& .MuiDialogTitle-root': {
    paddingLeft: theme.spacing(2)
  }
}));
