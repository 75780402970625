import Badge from '@/components/badge/Badge.component';
import { CardContent, CardPlaceholder } from '@/components/card/Card.component';
import DataTable, {
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { PlanAdminStatusColorMap } from '@/models/ops/common/PlanAdminStatusColorMap.model';
import { DepositPlanDto } from '@/models/ops/deposit-plans/DepositPlanDTO.model';
import { DepositPlanSearchRequest } from '@/models/ops/deposit-plans/DepositPlanSearchRequest.model';
import DepositPlanService from '@/services/ops/deposit-plans/DepositPlan.service';
import {
  CheckCircle as CheckCircleIcon,
  Search as SearchIcon,
  SearchOutlined as SearchOutlinedIcon
} from '@mui/icons-material';
import {
  Button,
  Card,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack
} from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';

import { AxiosError } from 'axios';
import { Field, Form, Formik } from 'formik';
import { forwardRef, ReactNode, useContext, useMemo, useState } from 'react';

import { AlertContext } from '../../AlertContext';
import { useUpdateAlertMutation } from '../../useUpdateAlertMutation.hook';
import { CardHeader } from './CardHeader.component';

type IdentifyPlanProps = {
  isConversion?: boolean;
  title?: ReactNode;
};
export const IdentifyPlan = forwardRef<HTMLDivElement, IdentifyPlanProps>(
  (props: IdentifyPlanProps, ref) => {
    const alert = useContext(AlertContext);

    const { showSnackbar } = useSnackbar();
    const [planName, setPlanName] = useState('');
    const updateAlertMutation = useUpdateAlertMutation();

    const {
      data: searchData,
      fetchNextPage,
      hasNextPage,
      isFetching
    } = useInfiniteQuery(
      ['DepositPlanService.search', planName],
      ({ pageParam }) =>
        DepositPlanService.search({
          isConversion: props.isConversion,
          page: pageParam,
          planName
        }),
      {
        enabled: planName !== '',
        getNextPageParam: previousPage =>
          previousPage.pagination.nextPage ?? undefined,
        keepPreviousData: true,
        onError: (err: AxiosError) => {
          const message = err.response?.data ? err.response.data : err.message;

          showSnackbar({
            message: `Failed to search plans: ${message}`,
            severity: 'error'
          });
        }
      }
    );

    const handleSubmit = (values: DepositPlanSearchRequest) => {
      if (values.planName) setPlanName(values.planName);
    };

    const columnDefs = [
      {
        autoHeight: true,
        cellRenderer: ({ data }: { data: DepositPlanDto }) => (
          <DataTableStackCell
            primary={data.planName}
            secondary={data.planId.toString()}
          />
        ),
        field: 'planName',
        headerName: 'Plan'
      },
      {
        cellRenderer: ({ data }: { data: DepositPlanDto }) =>
          data.adminStatus ? (
            <Badge
              color={PlanAdminStatusColorMap[data.adminStatus]}
              maxWidth='100%'
              sx={{ mt: 1.5 }}>
              {data.adminStatus}
            </Badge>
          ) : null,
        field: 'status',
        headerName: 'Status'
        // unable to apply top padding via cellStyle due to a bewildering type conflict
      },
      {
        cellRenderer: ({ data }: { data: DepositPlanDto }) => (
          <IconButton
            onClick={() => {
              if (!alert) return;
              updateAlertMutation.mutate({
                alertId: alert.id,
                updateRequest: {
                  details: {
                    cashTransferWorkflowTracerId: null,
                    depositPlanId: data.planId // reset for a new transfer if one was already attempted
                  }
                }
              });
            }}>
            <CheckCircleIcon />
          </IconButton>
        ),
        cellStyle: {
          paddingLeft: 8,
          paddingRight: 8
        },
        maxWidth: 58
      }
    ];

    const searchResults = useMemo(() => {
      if (searchData === undefined) return;

      const results = [];

      for (const page of searchData.pages) {
        results.push(...page.results);
      }

      return results;
    }, [searchData]);

    return (
      <Formik
        initialValues={{
          planName: ''
        }}
        onSubmit={handleSubmit}>
        <Card ref={ref}>
          <CardHeader title={props.title} />
          <Divider />
          <CardContent sx={{ p: 1.5 }}>
            <Form>
              <Stack direction='row'>
                <FormControl fullWidth size='small'>
                  <InputLabel htmlFor='planName'>Plan Name</InputLabel>
                  <Field
                    as={OutlinedInput}
                    autoComplete='off'
                    data-testid='identify-plan-search-input'
                    id='planName'
                    label='Plan Name'
                    name='planName'
                  />
                </FormControl>
                <IconButton
                  color='primary'
                  data-testid='identify-plan-search-button'
                  type='submit'>
                  <SearchOutlinedIcon />
                </IconButton>
              </Stack>
            </Form>
          </CardContent>
          <CardContent disablePadding loading={isFetching}>
            {searchData === undefined ||
            (Array.isArray(searchData.pages[0].results) &&
              searchData.pages[0].results.length === 0) ? (
              <CardPlaceholder
                icon={<SearchIcon fontSize='inherit' />}
                subtitle={
                  searchData === undefined
                    ? 'Plan will appear here'
                    : 'No results found'
                }
              />
            ) : (
              <DataTable
                columnDefs={columnDefs}
                columnSizing='fit'
                data-testid='identify-plan-search-results'
                paginationSource='server'
                primaryKey='planId'
                rowData={searchResults}
              />
            )}
          </CardContent>
          {hasNextPage && (
            <CardContent sx={{ p: 1 }}>
              <Button onClick={() => fetchNextPage()} size='small'>
                Show More Results
              </Button>
            </CardContent>
          )}
        </Card>
      </Formik>
    );
  }
);

IdentifyPlan.defaultProps = {
  title: 'Identify Plan'
};

IdentifyPlan.displayName = 'IdentifyPlan';

export default IdentifyPlan;
