import CollapsibleTable, {
  CellComponentProps,
  Order
} from '@/components/collapsible-table';
import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import NoInvestmentOptionLinked from '@/routes/ops/investments//NoInvestmentOptionLinked.component';
import {
  RiskSeriesSearchData,
  RiskSeriesService
} from '@/services/ops/investments/RiskSeries.service';
import { Box, Link, TableCell, Theme } from '@mui/material';
import Radio from '@mui/material/Radio';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import React, { useEffect, useState } from 'react';
import { useToggle } from 'react-use';

interface ProgramInvestmentRiskSeriesProps {
  programId: number;
  searchTerm: string;
  riskSeriesId: number | undefined;
  setRiskSeriesIdCallback: (name: number | undefined) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    createdDateWidth: {
      width: '15%'
    },
    radio: {
      paddingLeft: '0px'
    },
    riskSeriesIdWidth: {
      width: '15%'
    },
    riskSeriesNameWidth: {
      width: '25%'
    },
    selectWidth: {
      width: '5%'
    },
    size: {
      fontSize: theme.spacing(2)
    }
  })
);

const ProgramInvestmentRiskSeries = (
  props: ProgramInvestmentRiskSeriesProps
): JSX.Element => {
  const { programId, searchTerm, riskSeriesId, setRiskSeriesIdCallback } =
    props;
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [pageNumber, setPageNumber] = useState(1);
  const [isSnackDisplay, toggleSnackDisplay] = useToggle(false);
  const { showSnackbar } = useSnackbar();

  useEffect(() => setPageNumber(1), [searchTerm]);

  const [selectedValue, setSelectedValue] = useState(riskSeriesId);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLInputElement;
    const value =
      Number(target.value) === selectedValue ? undefined : Number(target.value);
    setSelectedValue(value);
    setRiskSeriesIdCallback(value);
    if (value) {
      toggleSnackDisplay();
    }
  };

  const controlProps = (item: number) => ({
    checked: selectedValue === item,
    onClick: handleClick,
    value: item
  });

  const isSelectedRow = (row: RiskSeriesSearchData) =>
    row.riskSeriesId === selectedValue;

  const riskSeriesTableColumns: GridColDef[] = [
    {
      cellClassName: classes.selectWidth,
      field: 'select',
      headerName: 'Select',
      sortable: false
    },
    {
      cellClassName: classes.riskSeriesIdWidth,
      field: 'riskSeriesId',
      headerName: 'ID'
    },
    {
      cellClassName: classes.riskSeriesNameWidth,
      field: 'name',
      headerName: 'Model Series Name'
    },
    {
      cellClassName: classes.createdDateWidth,
      field: 'createdAt',
      headerName: 'Created Date'
    },
    { field: 'programCount', headerName: 'Programs' }
  ];

  const selectedRiskSeriesResult = useQuery<{
    data: RiskSeriesSearchData[];
    meta: { count: number };
  }>(
    [
      'RiskSeriesService.getRiskSeriesPage',
      programId,
      1,
      1,
      orderBy,
      order,
      riskSeriesId
    ],
    async () => {
      return RiskSeriesService.getRiskSeriesPage(
        1,
        1,
        orderBy,
        order,
        undefined,
        riskSeriesId
      );
    },
    {
      enabled: Boolean(riskSeriesId)
    }
  );

  const riskSeriesSearchResult = useQuery<{
    data: RiskSeriesSearchData[];
    meta: { count: number };
  }>(
    [
      'RiskSeriesService.getRiskSeriesPage',
      programId,
      pageNumber,
      rowsPerPage,
      searchTerm,
      orderBy,
      order
    ],
    async () => {
      return RiskSeriesService.getRiskSeriesPage(
        pageNumber,
        rowsPerPage,
        orderBy,
        order,
        searchTerm
      );
    },
    {
      enabled: Boolean(searchTerm)
    }
  );

  const riskSeriesData =
    riskSeriesSearchResult.data?.data.filter(
      item => item.riskSeriesId !== riskSeriesId
    ) || [];
  const selectedRiskSeriesData = selectedRiskSeriesResult.data?.data[0];

  if (selectedRiskSeriesData) {
    riskSeriesData.unshift(selectedRiskSeriesData);

    if (isSnackDisplay) {
      showSnackbar({
        message: `You have selected ${selectedRiskSeriesData.name}`,
        severity: 'info'
      });
      toggleSnackDisplay();
    }
  }

  const { error } = riskSeriesSearchResult;
  if (error) {
    return redirectToErrorPage(error as Error);
  }

  const RiskSeriesTableCell: React.FunctionComponent<CellComponentProps> = (
    cellProps: CellComponentProps
  ) => {
    const { row, column } = cellProps;
    let field;

    if (column.field === 'select') {
      field = (
        <Radio {...controlProps(row.riskSeriesId)} className={classes.radio} />
      );
    } else field = row[column.field];

    return (
      <TableCell className={classes.size} component='th' scope='row'>
        <Box>
          {column.field === 'name' ? (
            <Link
              href={`/ops/investments/risk-series/${row.riskSeriesId}`}
              rel='noopener noreferrer'
              target='_blank'>
              {field}
            </Link>
          ) : (
            field
          )}
        </Box>
      </TableCell>
    );
  };

  return (
    <>
      <CollapsibleTable
        cellComponent={RiskSeriesTableCell}
        columns={riskSeriesTableColumns}
        isSelectedRow={isSelectedRow}
        noDataPlaceholderComponent={
          <NoInvestmentOptionLinked typeName='risk series' />
        }
        pager={{
          metaCount: riskSeriesSearchResult.data?.meta.count,
          onPageNumberChanged: (zeroIndexedPageNumber: number) => {
            return setPageNumber(zeroIndexedPageNumber + 1);
          },
          onRowsPerPageChanged: (newRowsPerPage: number) => {
            return setRowsPerPage(newRowsPerPage);
          },
          pageNumber: pageNumber - 1,
          rowsPerPage
        }}
        sorter={{
          onSortOrderChanged: (newOrderBy: string, newOrder: Order) => {
            setOrderBy(newOrderBy);
            setOrder(newOrder);
          },
          order,
          orderBy
        }}
        tableData={riskSeriesData}
      />
    </>
  );
};

export default ProgramInvestmentRiskSeries;
