import CollapsibleTable, {
  CellComponentProps,
  Order
} from '@/components/collapsible-table';
import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import { Program } from '@/models/ops/investments/Program.model';
import ProgramPlansModal from '@/routes/ops/investments/programs/ProgramPlansModal.component';
import { ProgramService } from '@/services/ops/investments/Program.service';
import { Box, Link, Modal, TableCell, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export interface ProgramData {
  programId: number;
  name: string;
  createdAt: string;
  planCount: number;
}

interface InvestmentProgramsProps {
  isUserAuthenticated: boolean;
  searchTerm: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    createdDateWidth: {
      width: '15%'
    },
    programIdWidth: {
      width: '15%'
    },
    programNameWidth: {
      width: '25%'
    },
    size: {
      fontSize: theme.spacing(2)
    }
  })
);

const toProgramData = (program: Program): ProgramData => {
  return {
    createdAt: program.createdAt,
    name: program.name,
    planCount: program?.planCount || 0,
    programId: program.programId
  };
};

const convertPrograms = (programSearchResult: Program[]): ProgramData[] => {
  const mappedPrograms = programSearchResult.map(toProgramData);
  return mappedPrograms;
};

const InvestmentPrograms = (props: InvestmentProgramsProps): JSX.Element => {
  const { isUserAuthenticated, searchTerm } = props;
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [pageNumber, setPageNumber] = useState(1);

  const [selectedProgram, setSelectedProgram] = useState<Program>(
    {} as Program
  );
  const [openProgramPlansModal, setOpenProgramPlansModal] = useState(false);

  useEffect(() => setPageNumber(1), [searchTerm]);

  const handleOpenProgramPlansModal = (program: Program) => {
    setSelectedProgram(program);
    setOpenProgramPlansModal(true);
  };

  const handleCloseModal = () => {
    setSelectedProgram({} as Program);
    setOpenProgramPlansModal(false);
  };

  const programTableColumns: GridColDef[] = [
    {
      cellClassName: classes.programIdWidth,
      field: 'programId',
      headerName: 'Program ID'
    },
    {
      cellClassName: classes.programNameWidth,
      field: 'name',
      headerName: 'Program Name'
    },
    {
      cellClassName: classes.createdDateWidth,
      field: 'createdAt',
      headerName: 'Created Date'
    },
    { field: 'planCount', headerName: 'Plans' }
  ];

  const programSearchResult = useQuery<{
    data: Program[];
    meta: { count: number };
  }>(
    [
      'ProgramService.getProgramsPageWithPlanCount',
      pageNumber,
      rowsPerPage,
      searchTerm,
      orderBy,
      order
    ],
    async () => {
      const dto = await ProgramService.getProgramsPageWithPlanCount(
        pageNumber,
        rowsPerPage,
        searchTerm,
        orderBy,
        order
      );
      return dto;
    },
    {
      enabled: isUserAuthenticated
    }
  );

  const programs = convertPrograms(programSearchResult.data?.data || []);

  const { error } = programSearchResult;
  if (error) {
    return redirectToErrorPage(error as Error);
  }

  const ProgramTableCell: React.FunctionComponent<CellComponentProps> = (
    cellProps: CellComponentProps
  ) => {
    const { row, column } = cellProps;
    let field;

    if (column.field === 'name') {
      field = (
        <Link
          component={RouterLink}
          to={`/ops/investments/program/${row.programId}`}
          underline='hover'>
          {row[column.field]}
        </Link>
      );
    } else if (column.field === 'planCount' && row[column.field] !== 0) {
      field = (
        <Link
          className={classes.size}
          data-testid='modal-plan-link'
          onClick={() => handleOpenProgramPlansModal(row)}
          underline='hover'>
          {row[column.field]}
        </Link>
      );
    } else field = row[column.field];

    return (
      <TableCell className={classes.size} component='th' scope='row'>
        <Box>{field}</Box>
      </TableCell>
    );
  };

  return (
    <>
      <Modal onClose={handleCloseModal} open={openProgramPlansModal}>
        <Box>
          <ProgramPlansModal
            handleCloseModal={handleCloseModal}
            modalTitle={'Plans with '
              .concat(selectedProgram.name)
              .concat(' Program')}
            programId={selectedProgram.programId}
          />
        </Box>
      </Modal>
      <CollapsibleTable
        cellComponent={ProgramTableCell}
        columns={programTableColumns}
        pager={{
          metaCount: programSearchResult.data?.meta.count,
          onPageNumberChanged: (zeroIndexedPageNumber: number) => {
            return setPageNumber(zeroIndexedPageNumber + 1);
          },
          onRowsPerPageChanged: (newRowsPerPage: number) => {
            return setRowsPerPage(newRowsPerPage);
          },
          pageNumber: pageNumber - 1,
          rowsPerPage
        }}
        sorter={{
          onSortOrderChanged: (newOrderBy: string, newOrder: Order) => {
            setOrderBy(newOrderBy);
            setOrder(newOrder);
          },
          order,
          orderBy
        }}
        tableData={programs}
      />
    </>
  );
};

export default InvestmentPrograms;
