import * as yup from 'yup';

const makeExemptionValidationSchema = (requiredAlternateEmployerId: boolean) =>
  yup.object({
    alternateEmployerId: requiredAlternateEmployerId
      ? yup.string().when('exemptionStatus', {
          is: 'Exempt',
          then: yup
            .string()
            .required('SDAT is required')
            .matches(/^[A-Z]\d{8}$/i, `Please enter a valid SDAT`)
        })
      : yup.string().optional(),
    exemptionReason: yup.string().when('exemptionStatus', {
      is: 'Exempt',
      then: yup.string().required('Exemption Reason is required')
    }),
    exemptionSignature: yup.string().when('exemptionStatus', {
      is: 'Exempt',
      then: yup.string().required('Exemption Signature is required')
    }),
    exemptionStatus: yup.string().required('Exemption Status is required')
  });

export default makeExemptionValidationSchema;
