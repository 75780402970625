class MaxYearsResidencePurchase {
  convertToApiState = (
    value: string | number | undefined
  ): number | undefined => {
    return Number(value) || undefined;
  };

  disabled: boolean;

  output: number | undefined;

  setDisabled = (value: boolean): void => {
    this.disabled = value;
  };

  constructor(props: Record<string, any>) {
    this.disabled =
      !props.allowResidencePurchaseExtension ||
      !props.allowLoans ||
      props.allowLoans === 'false';
    this.output = props.maxYearsResidencePurchase;
  }
}

export default MaxYearsResidencePurchase;
