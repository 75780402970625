import { EditScheduleChangeDialog } from '@/components/scheduled-changes/EditScheduleChangeDialog.component';
import { useGetPlanScheduledChanges } from '@/components/scheduled-changes/useGetPlanScheduledChanges';
import { makeScheduleChangeSchema } from '@/components/scheduled-changes/utils';
import { ScheduledChangePayloadItem } from '@/models/ScheduledChangesDTO.model';
import {
  Business,
  validateControlGroup
} from '@/routes/plans/plan-detail/PlanCompanyInfoCard/ControlGroupTab.component';
import formatters from '@/utils/Formatters';
import { Box, Button, Theme } from '@mui/material';

import dayjs from 'dayjs';
import {
  Field,
  Form,
  Formik,
  FormikContextType,
  FormikProps,
  FormikValues
} from 'formik';
import { TextField } from 'formik-mui';
import { isEqual, omit } from 'lodash';
import { FC, useCallback, useRef, useState } from 'react';
import { useToggle } from 'react-use';

interface ControlGroupItemProps {
  allBusinesses: Business[];
  business?: Business;
  isLoading: boolean;
  isNew: boolean;
  onClose: () => void;
  onSave: (
    data: Business,
    payload: ScheduledChangePayloadItem[],
    removeId?: string
  ) => Promise<void>;
  sponsorPlanId: number;
  sponsorId: number;
  isStateIRA: boolean;
}

const ControlGroupItem: FC<ControlGroupItemProps> = props => {
  const [isEdit, toggleEdit] = useToggle(false);
  const [isRemove, toggleRemove] = useToggle(false);
  const $form = useRef<FormikContextType<any>>(null);
  const [scheduleChangesOpen, setScheduleChangesOpen] = useState(false);

  const scheduledChanges = useGetPlanScheduledChanges({
    planId: +props.sponsorPlanId
  });

  const handleClose = useCallback(() => {
    $form?.current?.setValues($form?.current?.initialValues);
    $form?.current?.setTouched({ business: false, ein: false });

    toggleEdit(false);

    props.onClose?.();
  }, [props, toggleEdit]);

  const handleSave = useCallback(async () => {
    await props.onSave?.(
      $form?.current?.values,
      makeScheduledChangesPayload(
        $form?.current?.initialValues,
        $form?.current?.values,
        {
          isRemove
        }
      ),
      isRemove ? $form?.current?.values.id : undefined
    );
    $form?.current?.resetForm();
    toggleEdit(false);
    setScheduleChangesOpen(false);
  }, [props, isRemove]);

  const initialValues = {
    allBusinesses: props.allBusinesses || [],
    business: props.business?.business || '',
    ein: props.business?.ein || '',
    id: props.business?.id || '',
    scheduledChangesEffectiveDate: dayjs().format('MM/DD/YYYY')
  };
  const makeScheduledChangesPayload = (
    currentValues: Record<string, any>,
    scheduledValues: Record<string, any>,
    config?: { isRemove: boolean }
  ) => {
    const name = `${currentValues.business} ${currentValues.ein}`;
    const id = formatters.textToDataTestId(name);
    return [
      {
        fieldName: `${
          config?.isRemove ? 'Remove' : name.trim() ? id : 'New'
        }-business`,
        uiCurrentValue: currentValues.business,
        uiLabel: `${
          config?.isRemove ? `Remove ${name}` : name.trim() ? name : 'New'
        } - Company Name`,
        uiScheduledValue: config?.isRemove ? '' : scheduledValues.business,
        value: config?.isRemove ? '' : scheduledValues.business
      },
      {
        fieldName: `${
          config?.isRemove ? 'Remove' : name.trim() ? id : 'New'
        }-ein`,
        uiCurrentValue: currentValues.ein,
        uiLabel: `${
          config?.isRemove ? `Remove ${name}` : name.trim() ? name : 'New'
        } - EIN`,
        uiScheduledValue: config?.isRemove ? '' : scheduledValues.ein,
        value: config?.isRemove ? '' : scheduledValues.ein
      }
    ].filter(
      payloadItem => payloadItem.uiCurrentValue !== payloadItem.uiScheduledValue
    );
  };

  return (
    <Box mb={2} width='100%'>
      <Formik
        initialValues={initialValues}
        innerRef={$form}
        onSubmit={handleSave}
        validateOnChange
        validationSchema={validateControlGroup().concat(
          makeScheduleChangeSchema(
            makeScheduledChangesPayload,
            {
              isRemove
            },
            { data: scheduledChanges.data },
            initialValues,
            'company'
          )
        )}>
        {(formProps: FormikProps<FormikValues>) => (
          <>
            <EditScheduleChangeDialog
              changeType='company'
              hideDatePicker={props.isStateIRA}
              initialValues={formProps.initialValues}
              makeScheduledChangesPayload={makeScheduledChangesPayload}
              makeScheduledChangesPayloadConfig={{
                isRemove
              }}
              onClose={() => setScheduleChangesOpen(false)}
              onSubmit={() => null}
              open={scheduleChangesOpen}
              sponsorPlanId={props.sponsorPlanId}
              title=''
              validationSchema={validateControlGroup()}
              warning={
                props.isStateIRA
                  ? ''
                  : 'Scheduled changes could override the current Control Group Business, please review pending scheduled changes'
              }
            />

            <Form>
              <Box alignItems='baseline' display='flex' width='100%'>
                <Field
                  component={TextField}
                  disabled={!props.isNew && !isEdit}
                  fullWidth
                  label='Company Name'
                  name='business'
                  sx={{
                    '& .MuiFormLabel-root.MuiInputLabel-root': {
                      color: (theme: Theme) => theme.palette.text.secondary
                    },
                    '& .MuiInputBase-root.MuiOutlinedInput-root .Mui-disabled':
                      {
                        '-webkit-text-fill-color': (theme: Theme) =>
                          theme.palette.text.primary,
                        color: (theme: Theme) => theme.palette.text.primary
                      },
                    width: '40%'
                  }}
                />

                <Field
                  component={TextField}
                  disabled={!props.isNew && !isEdit}
                  fullWidth
                  label='EIN'
                  name='ein'
                  sx={{
                    '& .MuiFormLabel-root.MuiInputLabel-root': {
                      color: (theme: Theme) => theme.palette.text.secondary
                    },
                    '& .MuiInputBase-root.MuiOutlinedInput-root .Mui-disabled':
                      {
                        '-webkit-text-fill-color': (theme: Theme) =>
                          theme.palette.text.primary,
                        color: (theme: Theme) => theme.palette.text.primary
                      },
                    marginLeft: '1rem',
                    width: '20%'
                  }}
                />
                {!props.isNew && !isEdit && (
                  <Button
                    onClick={() => toggleEdit(true)}
                    sx={{ marginLeft: '1rem' }}>
                    Edit
                  </Button>
                )}
                {(props.isNew || isEdit) && (
                  <>
                    <Button
                      disabled={
                        !!Object.keys(
                          omit(formProps.errors, [
                            'scheduledChangesEffectiveDate'
                          ]) || {}
                        ).length ||
                        props.isLoading ||
                        isEqual(formProps.initialValues, formProps.values)
                      }
                      onClick={() => setScheduleChangesOpen(true)}
                      sx={{ marginLeft: '1rem' }}
                      type='button'
                      variant='contained'>
                      Continue
                    </Button>
                    <Button onClick={handleClose} sx={{ marginLeft: '1rem' }}>
                      Cancel
                    </Button>
                  </>
                )}
                {!props.isNew && isEdit && (
                  <Button
                    disabled={!formProps.isValid || props.isLoading}
                    onClick={() => {
                      toggleRemove(true);
                      setScheduleChangesOpen(true);
                    }}
                    sx={{ marginLeft: '1rem' }}>
                    Remove
                  </Button>
                )}
              </Box>
            </Form>
          </>
        )}
      </Formik>
    </Box>
  );
};

export default ControlGroupItem;
