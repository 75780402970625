import SimpleDropdown from '@/components/simple-dropdown';
import SimpleRadio from '@/components/simple-radio';
import { PlanDesignService } from '@/services/PlanDesign.service';
import VestwellTheme from '@/theme/Vestwell.theme';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';

import { FormikContextType, useField, useFormikContext } from 'formik';
import React, { ReactElement, useEffect, useMemo } from 'react';

const firstTierCapValues = [
  '1%',
  '2%',
  '3%',
  '4%',
  '5%',
  '6%',
  '7%',
  '8%',
  '9%',
  '10%',
  '15%',
  '20%',
  '50%',
  '90%',
  '100%'
];

const useStyles = makeStyles(() => ({
  noPadding: {
    padding: 0
  },
  text: {
    paddingTop: '1rem',
    whiteSpace: 'nowrap'
  }
}));

const ContributionMatchFormula = (): ReactElement | null => {
  const classes = useStyles();
  const { values }: FormikContextType<any> = useFormikContext();

  const [, , cappedHelpers] = useField('capped');
  const [, , tierHelpers] = useField('tier');
  const [, , firstTierPercentHelpers] = useField('firstTierPercent');
  const [, , secondTierPercentHelpers] = useField('secondTierPercent');
  const [, , firstTierCapHelpers] = useField('firstTierCap');
  const [, , secondTierCapHelpers] = useField('secondTierCap');

  const discretionaryMatchDollarCaps = useQuery(
    ['PlanDesignService.getDiscretionaryMatchDollarCaps'],
    () => PlanDesignService.getDiscretionaryMatchDollarCaps(),
    {
      staleTime: Infinity
    }
  )?.data;

  const secondTierCapValues = useMemo(() => {
    return firstTierCapValues.filter(
      cap => parseInt(cap, 10) > parseInt(values.firstTierCap, 10)
    );
  }, [values.firstTierCap]);

  const isTierDisabled = useMemo(() => {
    return (
      values.firstTierCap === firstTierCapValues[firstTierCapValues.length - 1]
    );
  }, [values.firstTierCap]);

  useEffect(() => {
    if (
      values.nonSafeHarborMatchType === 'Formula' &&
      !values.firstTierPercent &&
      !values.firstTierCap
    ) {
      firstTierPercentHelpers.setTouched(true, false);
      firstTierPercentHelpers.setError('Required');

      firstTierCapHelpers.setTouched(true, false);
      firstTierCapHelpers.setError('Required');
    }
  }, [values.nonSafeHarborMatchType]);

  if (!values.nonSafeHarborMatchType) {
    return null;
  }

  return (
    <Box margin='1rem 0 2rem 0'>
      {values.nonSafeHarborMatchType === 'Discretionary Match' && (
        <Box color={VestwellTheme.palette.text.primary}>
          <SimpleRadio
            defaultValue={values.formulaDefined}
            fieldId='formulaDefined'
            fieldName=''
            fieldValues={['No Formula Defined', 'Defined Formula']}
          />
        </Box>
      )}

      {values.formulaDefined === 'Defined Formula' && (
        <>
          <Box marginTop='1rem' padding='0 1rem'>
            <Grid alignItems='start' container direction='row'>
              <Grid item sm={3}>
                <SimpleDropdown
                  fieldId='firstTierPercent'
                  fieldName='Match'
                  fieldValues={[
                    '5%',
                    '10%',
                    '15%',
                    '20%',
                    '25%',
                    '50%',
                    '60%',
                    '75%',
                    '100%',
                    '200%'
                  ]}
                />
              </Grid>

              <Grid item sm={1}>
                <Typography
                  align='center'
                  classes={{ root: classes.text }}
                  color={VestwellTheme.palette.text.primary}
                  variant='body1'>
                  Up to
                </Typography>
              </Grid>

              <Grid item sm={3}>
                <SimpleDropdown
                  fieldId='firstTierCap'
                  fieldName='Contribution'
                  fieldValues={firstTierCapValues}
                  onChange={event => {
                    if (
                      event.target.value ===
                      firstTierCapValues[firstTierCapValues.length - 1]
                    ) {
                      secondTierPercentHelpers.setValue('');
                      secondTierCapHelpers.setValue('');
                      tierHelpers.setValue(false);
                    } else if (
                      parseInt(event.target.value, 10) >=
                      parseInt(values.secondTierCap, 10)
                    ) {
                      secondTierCapHelpers.setValue('');
                    }

                    firstTierCapHelpers.setValue(event.target.value);
                  }}
                />
              </Grid>

              <Grid item sm={2}>
                <Box
                  color={VestwellTheme.palette.text.primary}
                  padding='0 2rem'>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.tier}
                        classes={{ root: classes.noPadding }}
                      />
                    }
                    disabled={isTierDisabled}
                    label='Tier'
                    name='tier'
                    onChange={(e, checked) => tierHelpers.setValue(checked)}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.capped}
                        classes={{ root: classes.noPadding }}
                      />
                    }
                    label='Capped'
                    name='capped'
                    onChange={(e, checked) => cappedHelpers.setValue(checked)}
                  />
                </Box>
              </Grid>

              {values.capped && (
                <Grid item sm={3}>
                  <SimpleDropdown
                    fieldId='dollarCap'
                    fieldName='Cap Amount'
                    fieldOptions={
                      discretionaryMatchDollarCaps.map(cap => ({
                        option: `$${cap.dollarCap}`,
                        value: cap.dollarCap
                      })) || []
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Box>
          {values.tier && (
            <Box marginTop='1rem' padding='0 1rem'>
              <Grid alignItems='start' container direction='row'>
                <Grid item sm={3}>
                  <SimpleDropdown
                    fieldId='secondTierPercent'
                    fieldName='Match'
                    fieldValues={[
                      '10%',
                      '15%',
                      '20%',
                      '25%',
                      '50%',
                      '75%',
                      '100%',
                      '200%'
                    ]}
                  />
                </Grid>

                <Grid item sm={1}>
                  <Typography
                    align='center'
                    classes={{ root: classes.text }}
                    color={VestwellTheme.palette.text.primary}
                    variant='body1'>
                    {`${values.firstTierCap || '?'} <>`}
                  </Typography>
                </Grid>

                <Grid item sm={3}>
                  <SimpleDropdown
                    fieldId='secondTierCap'
                    fieldName='Contribution'
                    fieldValues={secondTierCapValues}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default ContributionMatchFormula;
