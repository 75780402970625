import { useMemo } from 'react';

export const usePlanType = (config: { recordkeeper: string; type: string }) => {
  const isVoyaPlan = useMemo(
    () => config.recordkeeper === 'Voya',
    [config.recordkeeper]
  );
  const isStateIRA = useMemo(
    () =>
      config.type === 'State IRA' ||
      config.recordkeeper === 'Surpas (State IRA)',
    [config.recordkeeper, config.type]
  );
  const isEsa = useMemo(
    () => config.type === 'ESA' || config.recordkeeper === 'Vestwell ESA',
    [config.type]
  );
  const urlPlansType = useMemo(
    () =>
      isVoyaPlan
        ? 'voya-plans'
        : isStateIRA
          ? 'vss-plans'
          : isEsa
            ? 'esa-plans'
            : 'plans',
    [isVoyaPlan, isEsa, isStateIRA]
  );

  const urlPlansTypeName = useMemo(
    () =>
      isVoyaPlan
        ? 'Voya Plans'
        : isStateIRA
          ? 'State Savings'
          : isEsa
            ? 'ESA Plan'
            : 'Workplace',
    [isVoyaPlan, isEsa, isStateIRA]
  );

  return useMemo(
    () => ({ isEsa, isStateIRA, isVoyaPlan, urlPlansType, urlPlansTypeName }),
    [isVoyaPlan, isStateIRA, isEsa, urlPlansType, urlPlansTypeName]
  );
};
