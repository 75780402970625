import { Checkbox, FormControlLabel, styled, Typography } from '@mui/material';

import { FC } from 'react';

import type { RiskSeriesModel } from '../../RiskSeries.component';
import type { TargetSeriesModel } from '../../TargetSeries.component';

type RebalanceRiskModel = {
  model: RiskSeriesModel;
  modelType: 'risk';
};

type RebalanceTargetModel = {
  model: TargetSeriesModel;
  modelType: 'target';
};

type RebalanceModelCheckboxProps = (
  | RebalanceRiskModel
  | RebalanceTargetModel
) & {
  checked: boolean;
  disabled?: boolean;
  onChange: (modelId: number, checked: boolean) => void;
};

const StyledFormControlLabel = styled(FormControlLabel)(props => ({
  margin: `${props.theme.spacing(1)} 0 0`
}));

export const RebalanceModelCheckbox: FC<
  RebalanceModelCheckboxProps
> = props => {
  return (
    <>
      <StyledFormControlLabel
        control={
          <Checkbox
            checked={props.checked}
            onChange={e => {
              props.onChange(
                props.model[`${props.modelType}ModelId`],
                e.target.checked
              );
            }}
          />
        }
        disabled={props.disabled}
        label={
          <>
            <Typography variant='body1'>{props.model.modelName}</Typography>
            <Typography variant='caption'>
              ID: {props.model[`${props.modelType}ModelId`]} |{' '}
              {props.modelType === 'target'
                ? `Target: ${props.model.targetRetireYearLow}-${props.model.targetRetireYearHigh}`
                : `Risk Level: ${props.model.riskLevel}`}
            </Typography>
          </>
        }
      />
    </>
  );
};

RebalanceModelCheckbox.displayName = 'RebalanceModelCheckbox';
