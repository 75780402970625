import { Order } from '@/components/collapsible-table';
import ApiService from '@/services/Api.service';

export interface TargetDateSeriesSearchData {
  targetSeriesId: number;
  name: string;
  description?: string;
  createdAt?: string;
  programCount?: number;
}
type TargetDateSeriesListDto = {
  data: TargetDateSeriesSearchData[];
  meta: { count: number };
};

class TargetDateSeriesService {
  static async getTargetDateSeriesPage(
    pageNumber: number,
    pageSize: number,
    orderBy: string,
    order: Order,
    name?: string,
    id?: number
  ): Promise<TargetDateSeriesListDto> {
    const sort = (order === 'asc' ? '' : '-').concat(orderBy);
    return ApiService.getJson('/investments/target-series', {
      id,
      name,
      pageNumber,
      pageSize,
      sort
    }) as unknown as TargetDateSeriesListDto;
  }
}

export type { TargetDateSeriesListDto };
export { TargetDateSeriesService };
