import AccessControl from '@/components/access-control/AccessControl.component';
import Badge from '@/components/badge';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { ParticipantInfo } from '@/models';
import {
  RolloverDto,
  UpdateRolloverStatusDto
} from '@/models/RolloversDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import ParticipantService from '@/services/Participant.service';
import formatters from '@/utils/Formatters';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button, Card, CardContent, Theme, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import React, { useState } from 'react';

import { colorByStatus } from './helpers/colorMapping';
import InvestModal from './InvestModal.component';

interface RolloverDetailCardProps {
  rollover?: RolloverDto;
  recordkeeper: string;
  participant: Pick<ParticipantInfo, 'firstName' | 'lastName'>;
}

const useStyles = makeStyles((theme: Theme) => ({
  border: {
    borderBottom: '1px solid lightgray',
    width: '100%'
  },
  contentBody: {
    paddingBottom: '0',
    paddingTop: '0',
    width: '100%'
  },
  dropDownMenu: {
    marginLeft: '0.7rem'
  },
  label: {
    color: 'rgba(0, 0 , 0, 0.6 )',
    fontSize: '0.875rem',
    paddingBottom: '10px'
  },
  markAsCompletedBtn: {
    border: '1px solid',
    borderColor: blue[500],
    color: blue[500],
    float: 'right',
    fontWeight: 'bold',
    height: '34px',
    padding: '15px',
    position: 'relative',
    width: '200px'
  },
  recordkeeperSpan: {
    backgroundColor: '#C4C4C4',
    borderRadius: '37px',
    display: 'inline-block',
    fontSize: '20px',
    fontWeight: 'bold',
    padding: '0 10px'
  },
  rowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 0'
  },
  subtitle: {
    padding: '15px 0'
  },
  tableContent: {
    display: 'flex',
    padding: '0'
  },
  titleText: {
    fontWeight: 'bold'
  },
  value: {
    color: theme.palette.text.primary,
    fontSize: '0.875rem',
    marginLeft: '1rem',
    paddingBottom: '10px'
  },
  wrapper: {
    width: '45rem'
  }
}));

const ITEM_HEIGHT = 48;

const SUBA = 'Vestwell Sub-Accounting Platform';
const LT = 'LT';
const COMPLETE_STATUS = 'Mark as Completed';
const CANCEL_STATUS = 'Cancel';
const INVEST = 'Invest';

const options = (rk: string, status: string) => {
  if (rk === SUBA && status === 'Awaiting Funds')
    return [COMPLETE_STATUS, CANCEL_STATUS];

  if (rk === SUBA && ['Investment Pending', 'Complete'].includes(status))
    return [INVEST, CANCEL_STATUS];

  if (
    [SUBA, LT].includes(rk) &&
    ['Awaiting Funds', 'Investment Pending'].includes(status)
  )
    return [CANCEL_STATUS];

  return [];
};

const RolloverDetailCard: React.FunctionComponent<RolloverDetailCardProps> = (
  props: RolloverDetailCardProps
) => {
  const classes = useStyles();
  const { openDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { participant, rollover, recordkeeper } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openInvestDialog, setOpenInvestDialog] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const dropDownOptions =
    rollover && options(recordkeeper, rollover.attributes.status);

  const updateRollover = useMutation(
    (dto: UpdateRolloverStatusDto) => {
      return ParticipantService.updateRolloverStatus(
        rollover?.attributes.participantId,
        rollover?.id,
        dto
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['ParticipantService.getRollover']);

        showSnackbar({
          message: 'Successfully updated rollover status!',
          severity: 'success'
        });
      }
    }
  );

  const onUpdateStatus = async (status: string) => {
    openDialog({
      actionButtons: {
        cancelButton: {
          children: 'Go back'
        },
        submitButton: {
          children: 'Confirm'
        }
      },
      onSubmit: async () => {
        try {
          await updateRollover.mutateAsync({
            data: {
              attributes: {
                status
              },
              id: rollover?.id as number,
              type: rollover?.type as string
            }
          });
        } catch (e) {
          showSnackbar({
            message: 'Failed',
            severity: 'error'
          });
        }
      },
      steps: [
        {
          contentText: `Are you sure you want to change the status to ${status}?`,
          title: 'Update Rollover Status'
        }
      ]
    });
  };

  const handleItemClick = (opt: string) => {
    switch (opt) {
      case CANCEL_STATUS:
        onUpdateStatus('Canceled');
        break;
      case COMPLETE_STATUS:
        onUpdateStatus('Complete');
        break;
      case INVEST:
        setOpenInvestDialog(true);
        break;
      default:
        break;
    }
    setAnchorEl(null);
  };

  const isVisibleCompleteButton =
    (recordkeeper === SUBA &&
      rollover?.attributes.status === 'Investment Pending') ||
    (recordkeeper === LT && rollover?.attributes.status === 'Awaiting Funds');

  return (
    <div className={classes.wrapper} data-testid='plan-info-card'>
      <Card elevation={0} variant='outlined'>
        <CardContent className={classes.contentBody}>
          <div className={classes.rowContainer}>
            <Typography variant='h5'>Details</Typography>
            <AccessControl
              requiresOneOf={[FeatureLevelPermissions.WRITE_ROLLOVERS_ACTION]}>
              <div className={classes.tableContent}>
                {recordkeeper === SUBA &&
                  rollover?.attributes.status === 'Awaiting Funds' && (
                    <Button
                      onClick={() => setOpenInvestDialog(true)}
                      sx={{
                        height: '34px',
                        width: '100px'
                      }}
                      variant='contained'>
                      INVEST
                    </Button>
                  )}
                {isVisibleCompleteButton && (
                  <Button
                    className={classes.markAsCompletedBtn}
                    onClick={() => handleItemClick(COMPLETE_STATUS)}>
                    MARK AS COMPLETED
                  </Button>
                )}
                {dropDownOptions && dropDownOptions.length > 0 && (
                  <div>
                    <IconButton
                      aria-controls={open ? 'long-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      aria-haspopup='true'
                      aria-label='more'
                      className={classes.dropDownMenu}
                      id='long-button'
                      onClick={handleClick}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      MenuListProps={{
                        'aria-labelledby': 'long-button'
                      }}
                      PaperProps={{
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5,
                          width: '20ch'
                        }
                      }}
                      anchorEl={anchorEl}
                      id='long-menu'
                      onClose={handleClose}
                      open={open}>
                      {dropDownOptions.map(option => (
                        <MenuItem
                          key={option}
                          onClick={() => handleItemClick(option)}>
                          {option}
                        </MenuItem>
                      ))}
                    </Menu>
                  </div>
                )}
              </div>
            </AccessControl>
          </div>
        </CardContent>
        <div className={classes.border} />
        <CardContent className={classes.contentBody}>
          <TextStack direction='column' sx={{ py: 2 }}>
            <TextStackItem>
              <TextLabel>Status</TextLabel>
              <TextValue data-testid='rollover-status'>
                <Badge color={colorByStatus[rollover?.attributes?.status]}>
                  {rollover?.attributes?.status}
                </Badge>
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Previous Provider</TextLabel>
              <TextValue data-testid='previous-provider'>
                {rollover?.attributes?.accountProvider}
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Account Type</TextLabel>
              <TextValue data-testid='account-type'>
                {rollover?.attributes?.accountType}
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Type</TextLabel>
              <TextValue data-testid='fund-type'>
                {(rollover?.attributes?.rothAmount && 'Roth') ||
                  (rollover?.attributes?.preTaxAmount && 'Pre-tax') ||
                  EMPTY_FIELD_PLACEHOLDER}
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Estimated Amount</TextLabel>
              <TextValue data-testid='estimated-amount'>
                {formatters.formatDollars(
                  rollover?.attributes?.rothAmount ||
                    rollover?.attributes?.preTaxAmount
                )}
              </TextValue>
            </TextStackItem>

            {rollover?.attributes?.rothCostBasis && (
              <TextStackItem>
                <TextLabel>Roth Cost Basis</TextLabel>
                <TextValue data-testid='roth-cost-basis'>
                  {formatters.formatDollars(
                    rollover?.attributes?.rothCostBasis
                  )}
                </TextValue>
              </TextStackItem>
            )}
            {rollover?.attributes?.rothStartDate && (
              <TextStackItem>
                <TextLabel>Roth Start Date</TextLabel>
                <TextValue data-testid='roth-start-date'>
                  {rollover?.attributes?.rothStartDate ||
                    EMPTY_FIELD_PLACEHOLDER}
                </TextValue>
              </TextStackItem>
            )}
          </TextStack>
        </CardContent>
      </Card>
      <InvestModal
        isOpen={openInvestDialog}
        participant={participant}
        rollover={rollover}
        setIsOpen={setOpenInvestDialog}
      />
    </div>
  );
};

export default RolloverDetailCard;
export type { RolloverDetailCardProps as PlanCompanyInfoCardProps };
