import Badge from '@/components/badge';
import Card, { CardHeader, CardPlaceholder } from '@/components/card';
import LinearLoading from '@/components/linear-loading';
import { AutoRebalanceHistory } from '@/models/AutoRebalanceHistory.model';
import ParticipantService from '@/services/Participant.service';
import formatters from '@/utils/Formatters';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';

import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

interface AutoRebalanceSettingHistoryCardProps {
  participantId: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      ...theme.typography.caption,
      color: theme.palette.grey[900],
      fontSize: theme.spacing(2),
      height: theme.spacing(7.8),
      padding: '0',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },

    headerCells: {
      '& svg': {
        color: theme.palette.grey[400] + ' !important'
      },
      color: theme.palette.text.secondary + ' !important',
      fontSize: theme.spacing(1.75) + ' !important',
      fontWeight: 500,
      paddingTop: '0px !important'
    },
    inititatedDateCellWidth: {
      width: '15%'
    },

    noData: {
      fontSize: theme.spacing(2.4),
      padding: theme.spacing(2),
      textAlign: 'center'
    },
    transactionTypeCellWidth: {
      width: '32%'
    }
  })
);

const AutoRebalanceSettingHistoryCard: React.FunctionComponent<
  AutoRebalanceSettingHistoryCardProps
> = ({ participantId }) => {
  const classes = useStyles();
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [tableData, setTableData] = useState<AutoRebalanceHistory[]>([]);

  const { isLoading, isSuccess, isError, data } = useQuery<
    AutoRebalanceHistory[]
  >(
    ['ParticipantService.getAutoRebalanceHistory', participantId],
    async () => {
      const newData =
        await ParticipantService.getAutoRebalanceHistory(participantId);

      return newData.sort(
        (a, b) =>
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      );
    },
    {
      enabled: !!participantId,
      staleTime: Infinity
    }
  );
  const headCells = [
    {
      cellWidth: classes.inititatedDateCellWidth,
      id: 'date',
      label: 'Date',
      sortable: true
    },
    {
      cellWidth: classes.transactionTypeCellWidth,
      id: 'status',
      label: 'Status'
    }
  ];

  useEffect(() => {
    if (data) {
      setTableData(order === 'asc' ? [...data].reverse() : [...data]);
    }
  }, [order, data]);

  const noResult = (
    <CardPlaceholder
      data-testid='auto-rebalance-setting-no-result'
      subtitle='No Auto Rebalance Setting History'
    />
  );

  return (
    <Card data-testid='auto-rebalance-setting-history-card'>
      <CardHeader
        disableDivider={isLoading || !!data?.length}
        title='Auto Rebalance Setting History'
      />
      {isLoading && <LinearLoading />}
      {!isLoading && !data?.length && noResult}
      {isSuccess && !isError && !!data.length && (
        <TableContainer>
          <Table data-testid='auto-rebalance-setting-history-table'>
            <TableHead>
              <TableRow>
                {headCells.map(headCell => (
                  <TableCell
                    className={clsx(
                      classes.headerCells,
                      headCell.cellWidth ? headCell.cellWidth : ''
                    )}
                    key={headCell.id}>
                    <TableSortLabel
                      active={headCell.sortable}
                      className={classes.headerCells}
                      direction={order}
                      disabled={!headCell.sortable}
                      hideSortIcon={!headCell.sortable}
                      onClick={() => {
                        setOrder(order === 'asc' ? 'desc' : 'asc');
                      }}>
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((settingHistory, index) => {
                return (
                  <TableRow
                    data-testid={`auto-rebalance-setting-history-row-${index}`}
                    key={settingHistory.updatedAt}>
                    <TableCell className={classes.body}>
                      {formatters.formatFromIsoDateCustom(
                        settingHistory.updatedAt,
                        'MM/DD/YYYY HH:mm:ss'
                      )}
                    </TableCell>
                    <TableCell className={classes.body}>
                      <Badge
                        color={
                          settingHistory.autoRebalance ? 'success' : 'info'
                        }
                        size='medium'>
                        {settingHistory.autoRebalance ? 'On' : 'Off'}
                      </Badge>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Card>
  );
};
export default AutoRebalanceSettingHistoryCard;
