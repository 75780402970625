import { useSnackbar } from '@/contexts/SnackBarContext';
import { UpdatePlanPayload } from '@/models';
import { PlanService } from '@/services/Plan.service';
import {
  useMutation,
  UseMutationResult,
  useQueryClient
} from '@tanstack/react-query';

export type UpdatePlanStatusDto = {
  data: {
    type: 'plan';
    id: number;
    attributes: {
      status:
        | 'Active'
        | 'Abandoned'
        | 'Deferred'
        | 'Onboarding'
        | 'Deconversion Requested'
        | 'Deconverted'
        | 'Termination Requested'
        | 'Terminated'
        | 'Pending'
        | 'Ready - Awaiting Effective Date';
    };
  };
};

const updatePlanStatus = (updatePlanDto: UpdatePlanStatusDto) => {
  return PlanService.updatePlan(updatePlanDto.data.id, updatePlanDto);
};

export const usePlanStatusUpdate = (): UseMutationResult<
  UpdatePlanPayload,
  Error,
  UpdatePlanStatusDto,
  () => void
> => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation<UpdatePlanPayload, Error, UpdatePlanStatusDto, () => void>(
    updatePlanStatusDto => updatePlanStatus(updatePlanStatusDto),
    {
      onError: () => {
        showSnackbar({ message: 'Failed!', severity: 'error' });
      },
      onSuccess: async (_, variables) => {
        await queryClient.refetchQueries([
          'PlanService.getPlanById',
          variables.data.id?.toString()
        ]);
        showSnackbar({ message: 'Success!', severity: 'success' });
      }
    }
  );
};
