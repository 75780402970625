import { OpsToolingType } from '@/services/payroll-integrations.service';
import { Stack, styled } from '@mui/material';

import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';
import { useToggle } from 'react-use';

import { OpsToolingWindow } from './OpsToolingWindow.component';

const StyledParentStack = styled(Stack)({
  flexDirection: 'column',
  height: '100vh'
});

type StyledSiblingStackProps = {
  opsToolingHeight: number;
  open: boolean;
};

const StyledSiblingStack = styled(Stack, {
  shouldForwardProp: prop => prop !== 'opsToolingHeight' && prop !== 'open'
})<StyledSiblingStackProps>(({ opsToolingHeight, open }) => ({
  height: `calc(100vh - ${opsToolingHeight}px)`,
  ...(open ? { overflow: 'auto' } : {})
}));

type OpsToolingProviderProps = { children: ReactNode };

type OpsToolingContextType = {
  hideOpsToolingDrawer: () => void;
  showOpsToolingDrawer: (
    sponsorPlanId: number,
    toolingType: OpsToolingType
  ) => void;
};

const OpsToolingContext = createContext<OpsToolingContextType | undefined>(
  undefined
);

export function OpsToolingProvider({
  children
}: OpsToolingProviderProps): JSX.Element {
  const [opsToolingHeight, setOpsToolingHeight] = useState(0);
  const [isDrawerOpen, toggleDrawerOpen] = useToggle(false);
  const [sponsorPlanId, setSponsorPlanId] = useState(null);
  const [toolingType, setToolingType] = useState(null);

  const hideOpsToolingDrawer = useCallback(() => {
    toggleDrawerOpen(false);
  }, [toggleDrawerOpen]);

  const showOpsToolingDrawer = useCallback(
    (sponsorPlanId: number, toolingType: OpsToolingType) => {
      setSponsorPlanId(sponsorPlanId);
      setToolingType(toolingType);
      toggleDrawerOpen(true);
    },
    [setSponsorPlanId, setToolingType, toggleDrawerOpen]
  );

  const value = useMemo(
    () => ({ hideOpsToolingDrawer, showOpsToolingDrawer }),
    [hideOpsToolingDrawer, showOpsToolingDrawer]
  );

  return (
    <OpsToolingContext.Provider value={value}>
      <StyledParentStack>
        <StyledSiblingStack
          open={isDrawerOpen}
          opsToolingHeight={opsToolingHeight}>
          {children}
        </StyledSiblingStack>
        <OpsToolingWindow
          isDrawerOpen={isDrawerOpen}
          setOpsToolingHeight={setOpsToolingHeight}
          sponsorPlanId={sponsorPlanId}
          toggleDrawerOpen={toggleDrawerOpen}
          toolingType={toolingType}
        />
      </StyledParentStack>
    </OpsToolingContext.Provider>
  );
}

export function useOpsTooling(): OpsToolingContextType {
  const context = useContext(OpsToolingContext);
  if (context === undefined) {
    throw new Error('useOpsTooling must be used within a OpsToolingProvider');
  }
  return context;
}
