import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class TerminationDate {
  convertToApiState = Helper.transformDateToTimestamp;

  disabled: boolean;

  setDisabled = (value: boolean): void => {
    this.disabled = value;
  };

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.disabled = props.adminStatus !== 'Terminated';
    this.output = Helper.transformDate(props.terminationDate);
  }
}

export default TerminationDate;
