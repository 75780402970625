import { FormControl } from '@mui/material';

import { Field, FormikProps } from 'formik';
import React from 'react';

import NumericMoneyOrPercentage from '../numeric-money-or-percentage/NumericMoneyOrPercentage.component';

interface NumericMoneyOrPercentageFormProps {
  fieldName: string;
  fieldId: string;
  oneHundredPercentValue: number;
  defaultIsPercentage?: boolean;
}

const NumericMoneyOrPercentageForm: React.FunctionComponent<
  NumericMoneyOrPercentageFormProps
> = ({
  fieldName,
  fieldId,
  oneHundredPercentValue,
  defaultIsPercentage = false
}: NumericMoneyOrPercentageFormProps) => {
  return (
    <FormControl fullWidth>
      <Field name={fieldId}>
        {(fieldProps: { form: FormikProps<any> }) => {
          const labelId = `${fieldId}-label`;

          const formValue = fieldProps.form.values[fieldId];
          return (
            <>
              <NumericMoneyOrPercentage
                defaultIsPercentage={defaultIsPercentage}
                error={!!fieldProps.form.errors[fieldId]}
                helperText={fieldProps.form.errors[fieldId] as string}
                id={labelId}
                label={fieldName}
                onChange={(value, type) =>
                  fieldProps.form.setFieldValue(fieldId, {
                    type,
                    value
                  })
                }
                oneHundredPercentValue={oneHundredPercentValue}
                value={formValue.value}
              />
            </>
          );
        }}
      </Field>
    </FormControl>
  );
};

export default NumericMoneyOrPercentageForm;
