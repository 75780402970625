import CollapsibleTable, {
  CellComponentProps,
  Order
} from '@/components/collapsible-table';
import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import { ModelSeries } from '@/models/ops/investments/ModelSeries.model';
import ModelProgramsModal from '@/routes/ops/investments/models/ModelProgramsModal.component';
import { ModelSeriesService } from '@/services/ops/investments/ModelSeries.service';
import formatters from '@/utils/Formatters';
import { Box, Link, Modal, TableCell, Theme } from '@mui/material';
import { blue } from '@mui/material/colors';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export interface ModelSeriesData {
  investmentOptionId: number;
  name: string;
  type: string;
  createdAt: string;
  programCount: number;
}

interface InvestmentModelsProps {
  isUserAuthenticated: boolean;
  searchTerm: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      backgroundColor: '#FAFAFA',
      boxShadow: '24px',
      left: '50%',
      maxHeight: '95%',
      minWidth: theme.spacing(60),
      overflowY: 'auto',
      padding: theme.spacing(3),
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: '65%'
    },
    modalCloseBtn: {
      '&:hover': {
        background: 'transparent'
      },
      border: 'none',
      color: blue[500],
      float: 'right',
      fontWeight: 'bold',
      marginRight: theme.spacing(3),
      marginTop: theme.spacing(2)
    },
    modelContainer: {
      marginTop: theme.spacing(4)
    },
    size: {
      fontSize: theme.spacing(2)
    }
  })
);

const toModelSeriesData = (modelSeries: ModelSeries): ModelSeriesData => {
  return {
    createdAt: formatters.formatFromIsoDateCustom(
      modelSeries.createdAt,
      'M/DD/YYYY'
    ),
    investmentOptionId: modelSeries.investmentOptionId,
    name: modelSeries.name,
    programCount: modelSeries.programCount,
    type: modelSeries.type
  };
};

const convertModelSeries = (
  modelSeriesSearchResult: ModelSeries[]
): ModelSeriesData[] => {
  const mappedModelSeries = modelSeriesSearchResult.map(toModelSeriesData);
  return mappedModelSeries;
};

const InvestmentModels = (props: InvestmentModelsProps): JSX.Element => {
  const { isUserAuthenticated, searchTerm } = props;
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(100);

  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState('investmentOptionId');
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => setPageNumber(1), [searchTerm]);

  const [openModelProgramsModal, setOpenModelProgramsModal] = useState(false);
  const [selectedModelSeries, setSelectedModelSeries] =
    useState<ModelSeriesData>({} as ModelSeriesData);

  const handleOpenModelSeriesProgramsModal = (modelSeries: ModelSeriesData) => {
    setSelectedModelSeries(modelSeries);
    setOpenModelProgramsModal(true);
  };
  const handleCloseModal = () => {
    setSelectedModelSeries({} as ModelSeriesData);
    setOpenModelProgramsModal(false);
  };

  const modelSeriesTableColumns: GridColDef[] = [
    { field: 'investmentOptionId', headerName: 'Investment Option ID' },
    { field: 'name', headerName: 'Model Series Name' },
    { field: 'type', headerName: 'Type' },
    { field: 'createdAt', headerName: 'Created Date' },
    { field: 'programCount', headerName: 'Programs' }
  ];

  const modelSeriesSearchResult = useQuery<{
    data: ModelSeries[];
    meta: { count: number };
  }>(
    [
      'ModelSeriesService.getModelSeriesPage',
      pageNumber,
      rowsPerPage,
      searchTerm,
      orderBy,
      order
    ],
    async () => {
      const dto = await ModelSeriesService.getModelSeriesPage(
        pageNumber,
        rowsPerPage,
        searchTerm,
        orderBy,
        order
      );
      return dto;
    },
    {
      enabled: isUserAuthenticated
    }
  );

  const modelSeries = convertModelSeries(
    modelSeriesSearchResult.data?.data || []
  );

  const { error } = modelSeriesSearchResult;
  if (error) {
    return redirectToErrorPage(error as Error);
  }

  const ModelSeriesTableCell: React.FunctionComponent<CellComponentProps> = (
    cellProps: CellComponentProps
  ) => {
    const { row, column } = cellProps;
    let field;
    if (column.field === 'name') {
      let linkTo;
      switch (row.type) {
        case 'target':
          linkTo = `/ops/investments/target-series/${row.investmentOptionId}`;
          break;
        case 'risk':
          linkTo = `/ops/investments/risk-series/${row.investmentOptionId}`;
          break;
        default:
          linkTo = `/ops/investments?unknownType=${row.fieldName}`;
      }

      field = (
        <Link
          component={RouterLink}
          data-testid='model-series-name-link'
          to={linkTo}
          underline='hover'>
          {row[column.field]}
        </Link>
      );
    } else if (column.field === 'programCount' && row[column.field] !== 0) {
      field = (
        <Link
          className={classes.size}
          data-testid='modal-program-link'
          onClick={() => handleOpenModelSeriesProgramsModal(row)}
          underline='hover'>
          {row[column.field]}
        </Link>
      );
    } else field = row[column.field];

    return (
      <TableCell className={classes.size} component='th' scope='row'>
        <Box>{field}</Box>
      </TableCell>
    );
  };

  return (
    <>
      <Modal onClose={handleCloseModal} open={openModelProgramsModal}>
        <Box>
          <ModelProgramsModal
            handleCloseModal={handleCloseModal}
            investmentOptionId={selectedModelSeries.investmentOptionId}
            modalTitle={'Programs with '.concat(selectedModelSeries.name)}
            modelType={selectedModelSeries.type}
          />
        </Box>
      </Modal>
      <CollapsibleTable
        cellComponent={ModelSeriesTableCell}
        columns={modelSeriesTableColumns}
        pager={{
          metaCount: modelSeriesSearchResult.data?.meta.count,
          onPageNumberChanged: (zeroIndexedPageNumber: number) => {
            return setPageNumber(zeroIndexedPageNumber + 1);
          },
          onRowsPerPageChanged: (newRowsPerPage: number) => {
            return setRowsPerPage(newRowsPerPage);
          },
          pageNumber: pageNumber - 1,
          rowsPerPage
        }}
        sorter={{
          onSortOrderChanged: (newOrderBy: string, newOrder: Order) => {
            setOrderBy(newOrderBy);
            setOrder(newOrder);
          },
          order,
          orderBy
        }}
        tableData={modelSeries}
      />
    </>
  );
};

export default InvestmentModels;
