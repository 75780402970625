import { TransactionCodeDto } from '@/models/ops/transaction-codes/TransactionCodeDto';
import { TransactionCodeSearchRequest } from '@/models/ops/transaction-codes/TransactionCodeSearchRequest';
import ApiService from '@/services/Api.service';

export class TransactionCodeService {
  static async search(
    params?: TransactionCodeSearchRequest
  ): Promise<TransactionCodeDto[]> {
    return ApiService.getJson<TransactionCodeDto[]>(
      `/transaction-codes`,
      params
    );
  }
}

export default TransactionCodeService;
