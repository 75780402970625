import ApiService from '@/services/Api.service';

type PlaidIdVerResult = {
  status: string;
  dashboardLink: string;
};

type IdentityVerificationStatus = {
  cipStatus?: string;
  idVerMode?: string;
  idVerResponse?: PlaidIdVerResult;
};

class IdentityVerificationService {
  static async getOrUpdateVerification(
    participantId: number
  ): Promise<IdentityVerificationStatus> {
    return ApiService.getJson('/identity-verification/status', {
      participantId
    }) as unknown as IdentityVerificationStatus;
  }
}

export type { IdentityVerificationStatus };
export { IdentityVerificationService };
