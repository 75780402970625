import branding from '@/environment/branding.config';
import { AppConfigSchema, Environment } from '@/models';

import getAuth0Config from './environment/auth0.config';
import siteUIVersionData from './version_config_provided_by_build_script.json';

const ONE_SECOND = 1000;
const ONE_MINUTE = ONE_SECOND * 60;

export const environments: Environment[] = [
  {
    environment: 'chromatic',
    validator: () => /chromatic.com$/.test(window.location.hostname)
  },
  {
    environment: 'local',
    timeoutOverride: ONE_MINUTE * 300,
    validator: () => /localhost/.test(window.location.hostname)
  },
  {
    environment: 'qa',
    validator: () => /(ci|qa)[0-9]+/.test(window.location.hostname)
  },
  {
    environment: 'production',
    validator: () =>
      /^([a-z]+.service)|(tpa)|(service)|([a-z-]+.prod.[a-z0-9]+).vestwell.com$/.test(
        window.location.hostname
      )
  },
  {
    // default to qa if unable to determine environment
    environment: 'qa',
    validator: () => /(.*)/.test(window.location.hostname)
  }
];

const getAppConfig = (): AppConfigSchema => {
  const environment = environments.find(env => env.validator());

  const defaultTimeout = ONE_MINUTE * 40;
  const defaultPromptTimeout = ONE_MINUTE * 5;

  if (!environment) {
    throw new Error('Environment did not match existing options');
  }

  return {
    apiHost:
      process.env.NODE_ENV === 'development' ? 'http://localhost:8088' : '/api',
    auth0Config: getAuth0Config(environment.environment),
    branding,
    environment: environment.environment,
    landingPage: '/plans',
    loginPage: '/',

    promptBeforeIdle:
      environment.timeoutOverride && environment.promptTimeoutOverride
        ? environment.promptTimeoutOverride
        : defaultPromptTimeout,

    timeout:
      environment.timeoutOverride + environment.promptTimeoutOverride ||
      defaultTimeout + defaultPromptTimeout,
    uiVersionDetails: siteUIVersionData.version
  };
};

export default getAppConfig();
