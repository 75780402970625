import { GridColDef } from '@mui/x-data-grid-pro';

export type PlanEmployeesColumns = {
  readonly overview: GridColDef[];
  readonly eligibility: GridColDef[];
  readonly all: GridColDef[];
};

const defaultColumns: GridColDef[] = [
  { field: 'ssn', headerName: 'SSN', width: 130 },
  { field: 'name', headerName: 'Name', width: 130 }
];

export const columns: PlanEmployeesColumns = {
  all: [
    ...defaultColumns,
    { field: 'elStatus', headerName: 'Eligibility Status', width: 130 },
    { field: 'workEmail', headerName: 'Work Email', width: 130 },
    { field: 'preTaxDeferral', headerName: 'Pre-Tax Deferral', width: 130 },
    { field: 'rothDeferral', headerName: 'Roth Deferral', width: 130 },
    { field: 'entryDate', headerName: 'Entry Date', width: 130 },
    { field: 'ageRequirement', headerName: 'Age Requirement', width: 130 },
    {
      field: 'serviceRequirement',
      headerName: 'Service Requirement',
      width: 130
    }
  ],
  eligibility: [
    ...defaultColumns,
    { field: 'elStatus', headerName: 'Eligibility Status', width: 130 },
    { field: 'entryDate', headerName: 'Entry Date', width: 130 },
    { field: 'ageRequirement', headerName: 'Age Requirement', width: 130 },
    {
      field: 'serviceRequirement',
      headerName: 'Service Requirement',
      width: 130
    }
  ],
  overview: [
    ...defaultColumns,
    { field: 'elStatus', headerName: 'Eligibility Status', width: 130 },
    { field: 'workEmail', headerName: 'Work Email', width: 130 },
    { field: 'preTaxDeferral', headerName: 'Pre-Tax Deferral', width: 130 },
    { field: 'rothDeferral', headerName: 'Roth Deferral', width: 130 }
  ]
};

export const stateIRAOverrideColumns: PlanEmployeesColumns = {
  all: columns.all,
  eligibility: [
    ...defaultColumns,
    { field: 'elStatus', headerName: 'Eligibility Status', width: 80 },
    {
      field: 'stateIraPerEmployerStatus',
      headerName: 'Status with Employer',
      width: 80
    },
    { field: 'waitingPeriod', headerName: 'Waiting Period', width: 100 },
    {
      field: 'stateIraCipStatus',
      headerName: 'CIP Status',
      width: 130
    },
    {
      field: 'stateIraAccountStatus',
      headerName: 'Account Status',
      width: 130
    }
  ],
  overview: [
    ...defaultColumns,
    { field: 'elStatus', headerName: 'Employee Status', width: 130 },
    { field: 'workEmail', headerName: 'Work Email', width: 130 },
    { field: 'rothDeferral', headerName: 'Deferral Rate', width: 130 }
  ]
};

export const esaPlanOverrideColumns: PlanEmployeesColumns = {
  ...columns,
  eligibility: [
    ...defaultColumns,
    { field: 'elStatus', headerName: 'Eligibility Status', width: 130 },
    { field: 'entryDate', headerName: 'Entry Date', width: 130 }
  ],
  overview: [
    ...defaultColumns,
    { field: 'workEmail', headerName: 'Work Email', width: 130 },
    { field: 'deferralRate', headerName: 'Deferral Rate', width: 130 },
    { field: 'esaGroupName', headerName: 'Employee Group', width: 130 }
  ]
};

const columnsMap = {
  basic: columns,
  esa: esaPlanOverrideColumns,
  stateIRA: stateIRAOverrideColumns
};

export const getHeaderColumns = (
  choice: 'overview' | 'eligibility' | 'registration' | 'all',
  canRemoveOrMerge: string,
  isStateIRA: boolean,
  isEsa: boolean
): GridColDef[] => {
  const columnType = isStateIRA ? 'stateIRA' : isEsa ? 'esa' : 'basic';
  const result = [
    ...columnsMap[columnType][choice === 'registration' ? 'all' : choice]
  ];

  if (canRemoveOrMerge && choice === 'overview' && columnType !== 'esa') {
    result.push({ field: 'action', headerName: 'Action', width: 130 });
  }

  return result;
};
