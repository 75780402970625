import LinearLoading from '@/components/linear-loading';
import InvestmentService from '@/services/Investment.service';
import {
  FundChangesPreview,
  ProgramService
} from '@/services/ops/investments/Program.service';
import { SecurityMasterService } from '@/services/SecurityMaster.service';
import { useQuery } from '@tanstack/react-query';

import dayjs from 'dayjs';
import { uniq } from 'lodash';
import { useParams } from 'react-router-dom';

import { FundChangesTab } from './FundChangesTab.component';
import { InvestmentOptionLabel, ProgramFund } from './types';

export type ProgramFundChangesTabProps = {
  availableOptions: InvestmentOptionLabel[];
};

export const ProgramFundChangesTab = (
  props: ProgramFundChangesTabProps
): JSX.Element => {
  const { id: programId } = useParams();

  const programFundsQuery = useQuery<ProgramFund[]>(
    ['programFundsQuery', +programId],
    () => ProgramService.getProgramFunds(+programId),
    { cacheTime: 0 }
  );

  const programFundChangesQuery = useQuery(
    ['programFundChangesQuery', +programId],
    async () => {
      const orders = await ProgramService.getFundChanges(+programId);

      const fundChangeApprove = await Promise.all(
        orders.data.map(order =>
          InvestmentService.getFundChangeApproval(order.fundChangeId)
        )
      );

      const securities = await SecurityMasterService.getSecuritiesByCusips(
        uniq([
          ...orders.data.map((order: { oldFund: string }) => order.oldFund),
          ...orders.data.map((order: { newFund: string }) => order.newFund)
        ])
      );

      const previews = await ProgramService.createFundChangePreview(
        +programId,
        orders.data.map(order => ({
          investmentOptions: order.investmentOptions,
          newFund: securities.find(security => security.cusip === order.newFund)
            ?.cusip,
          oldFund: securities.find(security => security.cusip === order.oldFund)
            ?.cusip,
          tradeDate: dayjs(order.tradeDate).format('YYYY-MM-DD')
        })) as FundChangesPreview[]
      );

      return orders.data.map((order, i) => ({
        ...order,
        approveInfo: fundChangeApprove[i],
        newFund: securities.find(security => security.cusip === order.newFund),
        oldFund: securities.find(security => security.cusip === order.oldFund),
        plansAffected: previews.find(
          preview =>
            preview.fundChange.oldFund === order.oldFund &&
            preview.fundChange.newFund === order.newFund
        )?.plansAffected
      }));
    },
    { cacheTime: 0 }
  );

  if (programFundsQuery.isFetching || programFundChangesQuery.isFetching) {
    return <LinearLoading />;
  }

  return (
    <FundChangesTab
      availableOptions={props.availableOptions}
      fundChanges={programFundChangesQuery.data}
      funds={programFundsQuery.data}
      onSubmitFundChanges={() => programFundChangesQuery.refetch()}
      programId={+programId}
    />
  );
};

ProgramFundChangesTab.displayName = 'ProgramFundChangesTab';
