import Badge from '@/components/badge';
import { CardContent } from '@/components/card';
import { QueueErrorCard } from '@/components/queue-error-card/QueueErrorCard.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import useAccountValue from '@/hooks/useAccountValue.hook';
import { BalanceGetRequest } from '@/models/ops/balances/BalanceGetRequest.model';
import { DeconversionStatus } from '@/models/PlanDeconversionDTO.model';
import BalanceService from '@/services/ops/balances/Balance.service';
import { PlanService } from '@/services/Plan.service';
import formatters from '@/utils/Formatters';
import { Box, Theme, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';
import { PositionDateType } from '@vestwell-sub-accounting/models/accountsAndLedgers/PositionDateType';

import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';

import Checklist from './Checklist.component';
import DeconversionRequests from './DeconversionRequests.component';

const useStyles = makeStyles((theme: Theme) => ({
  card: { padding: theme.spacing(3) },
  cardContext: { padding: 0, paddingTop: theme.spacing(2.25) },
  cardHeader: { padding: 0 },
  downloadContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  downloadIcon: {
    '&.Mui-disabled': {
      pointerEvents: 'auto' // enable tooltip for disabled download button
    },
    color: theme.palette.info.main,
    cursor: 'pointer'
  },
  fileCard: {
    height: '100%'
  },
  fileDateField: {
    color: theme.palette.text.secondary,
    fontSize: theme.spacing(1.8),
    marginTop: theme.spacing(-1.4)
  },
  label: {
    color: 'rgba(0, 0, 0, 0.6)'
  },
  noFilesPlaceholder: {
    marginTop: theme.spacing(1)
  },
  textHeader: {
    paddingTop: theme.spacing(1.4)
  }
}));

interface PlanDeconversionProps {
  sponsorPlanId: number;
  sponsorId: number;
}

const PlanDeconversion: React.FunctionComponent<PlanDeconversionProps> = (
  props: PlanDeconversionProps
) => {
  const classes = useStyles();

  const { sponsorPlanId } = props;
  const accountId = 'vw+plan+' + sponsorPlanId;
  const { showSnackbar } = useSnackbar();
  const [query] = useState<BalanceGetRequest>({
    date: dayjs().format('YYYY-MM-DD'),
    dateType: PositionDateType.Trade
  });
  const { data, isFetching } = useQuery(
    ['BalanceService.get', accountId, query],
    () => BalanceService.get(accountId, query),
    {
      keepPreviousData: true,
      onError: (err: any) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Balance request failed: ${message}`,
          severity: 'error'
        });
      },
      staleTime: Infinity
    }
  );

  const { data: planDeconversion } = useQuery(
    [`PlanService.getPlanDeconversion(${sponsorPlanId})`],
    () => {
      return PlanService.getPlanDeconversion(sponsorPlanId);
    },
    {
      enabled: Boolean(sponsorPlanId),
      staleTime: Infinity
    }
  );

  const { data: deconversionRequests } = useQuery(
    [`PlanService.getDeconversionRequest(${sponsorPlanId})`],
    () => {
      return PlanService.getDeconversionRequest(sponsorPlanId);
    },
    {
      enabled: Boolean(sponsorPlanId)
    }
  );

  const { confirmedValue } = useAccountValue(data);

  const statusColor = useMemo(() => {
    if (
      planDeconversion?.data?.status === DeconversionStatus.NotScheduled ||
      !planDeconversion?.data?.status
    )
      return 'neutral';
    else if (
      planDeconversion?.data?.status === DeconversionStatus.PendingApproval
    )
      return 'lightWarning';
    else return 'success';
  }, [planDeconversion?.data?.status]);

  return (
    <>
      <CardContent disablePadding overlayLoading={isFetching}>
        <Stack
          direction='row'
          divider={<Divider flexItem orientation='vertical' />}
          spacing={2}>
          <Box>
            <Typography className={classes.label} variant='subtitle1'>
              Plan Balance
            </Typography>
            <Typography className={classes.label} variant='inherit'>
              {formatters.formatDollars(confirmedValue)}
            </Typography>
            <Typography className={classes.label} variant='caption'>
              As of {query.date}
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.label} variant='subtitle1'>
              Deconversion Status
            </Typography>
            <Badge color={statusColor} size='medium'>
              {planDeconversion?.data?.status || 'N/A'}
            </Badge>
          </Box>
        </Stack>
        <Checklist
          deconversion={planDeconversion?.data}
          hasSuccessRequest={
            planDeconversion?.data?.status ===
              DeconversionStatus.PendingLiquidation &&
            deconversionRequests?.data.length === 1 &&
            !!deconversionRequests?.data?.find(i => i.status === 'SUCCEEDED')
          }
          sponsorId={props.sponsorId}
          sponsorPlanId={sponsorPlanId}
        />
        <QueueErrorCard
          sx={{ mt: 5 }}
          type='Deconversion'
          value={sponsorPlanId.toString()}
        />
        <DeconversionRequests data={deconversionRequests?.data || []} />
      </CardContent>
    </>
  );
};

export default PlanDeconversion;
