import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';

import type { FC } from 'react';

type UnrecognizedHeadersProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const UnrecognizedHeaders: FC<UnrecognizedHeadersProps> = props => {
  return (
    <Dialog
      data-testid='UnrecognizedHeadersDialog'
      fullWidth
      maxWidth='xs'
      onClose={props.onClose}
      open={props.isOpen}>
      <DialogTitle data-testid='UnrecognizedHeadersDialog-title'>
        Headers in the uploaded file are not recognized
      </DialogTitle>
      <DialogContent data-testid='UnrecognizedHeadersDialog-content'>
        We cannot recognize any headers on the uploaded file. Please make sure
        that the first row has the required headers and reupload.
      </DialogContent>
      <DialogActions>
        <Button
          data-testid='UnrecognizedHeadersDialog-closeButton'
          onClick={props.onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
