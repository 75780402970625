import AccessControl from '@/components/access-control/AccessControl.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import {
  GlobalSearchAdvisorDto,
  GlobalSearchParticipantDto,
  GlobalSearchPlanDto
} from '@/models/GlobalSearchDTO.model';
import { MutateUpdateUserRelationshipsDto } from '@/models/MutateUpdateUserRelationshipsDto.model';
import { Entity } from '@/models/UserAssociationsDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { UserAssociationsDto, UsersItemDto } from '@/models/UsersDTO.model';
import { UserManagementStateSaversAssociateSearch } from '@/routes/user-management/user-management-associate/UserManagementStateSaversAssociateSearch.component';
import AdvisorService from '@/services/Advisor.service';
import ParticipantService from '@/services/Participant.service';
import SponsorService from '@/services/Sponsor.service';
import UserManagementService from '@/services/UserManagement.service';
import {
  Grid,
  Paper,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import React, { useCallback, useMemo, useState } from 'react';

import UserManagementAssociateSearch from './UserManagementAssociateSearch.component';

interface UserManagementAssociateProps {
  selectedUser: UsersItemDto | null;
  canWriteUserManagementParticipant: boolean;
  canWriteUserManagement: boolean;
}

enum AssociationType {
  WORKPLACE_USERS = 'WORKPLACE USERS',
  STATE_SAVERS = 'STATE SAVERS'
}

const useStyles = makeStyles((theme: Theme) => ({
  contentBody: {
    padding: theme.spacing(3)
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%'
  },
  root: {
    padding: theme.spacing(2)
  },
  toggleButton: {
    '&.Mui-disabled, &:hover': {
      backgroundColor: 'rgba(33, 150, 243, 0.04)',
      color: theme.palette.primary.main
    },
    padding: theme.spacing(0.9),
    width: theme.spacing(25)
  }
}));

const TWO_HRS_IN_MILLISECONDS = 1000 * 60 * 60 * 2;

const typesMap = {
  advisor: 'advisorId',
  participant: 'participantId',
  sponsor: 'sponsorPlanId',
  state_saver: 'surpasId'
};

const UserManagementAssociate: React.FunctionComponent<
  UserManagementAssociateProps
> = ({
  selectedUser,
  canWriteUserManagementParticipant,
  canWriteUserManagement
}): JSX.Element => {
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const classes = useStyles();

  const [associationType, setAssociationType] = useState(
    AssociationType.WORKPLACE_USERS
  );

  const associations = useQuery<UserAssociationsDto[]>(
    ['UserManagementService.getUserAssociations', selectedUser.userId],
    () => UserManagementService.getUserAssociations(selectedUser?.userId),
    {
      cacheTime: TWO_HRS_IN_MILLISECONDS,
      enabled: Boolean(selectedUser),
      staleTime: TWO_HRS_IN_MILLISECONDS
    }
  );

  const userSponsorRelationshipsMutation = useMutation(
    (variables: MutateUpdateUserRelationshipsDto) => {
      return SponsorService.createUserSponsorRelationship(
        selectedUser?.userId,
        { entityId: variables.entityId }
      );
    },
    {
      onError: () => {
        showSnackbar({
          message: `Failed!`,
          severity: 'error'
        });
      },
      onSuccess: async (_, variables) => {
        showSnackbar({
          message: `Success! This user has been associated with the business ${variables.item.companyName}`,
          severity: 'success'
        });

        await queryClient.invalidateQueries([
          'UserManagementService.getUserAssociations'
        ]);
      }
    }
  );

  const userAdvisorRelationshipsMutation = useMutation(
    (variables: MutateUpdateUserRelationshipsDto) => {
      return AdvisorService.createUserAdvisorRelationship(
        selectedUser?.userId,
        { entityId: variables.entityId }
      );
    },
    {
      onError: () => {
        showSnackbar({
          message: `Failed!`,
          severity: 'error'
        });
      },
      onSuccess: async (_, variables) => {
        showSnackbar({
          message: `Success! This user has been associated with the advisor ${variables.item.firstName} ${variables.item.lastName}`,
          severity: 'success'
        });

        await queryClient.invalidateQueries([
          'UserManagementService.getUserAssociations'
        ]);
      }
    }
  );

  const userParticipantRelationshipsMutation = useMutation(
    async (variables: MutateUpdateUserRelationshipsDto) => {
      const participant = await ParticipantService.getParticipantById(
        variables.entityId
      );

      if (!participant.registeredOn) {
        throw new Error('A user cannot be associated to an unregistered saver');
      }

      return ParticipantService.createUserParticipantRelationship(
        selectedUser?.userId,
        { entityId: variables.entityId }
      );
    },
    {
      onError: (error: Error) => {
        showSnackbar({
          message: error.message || `Failed!`,
          severity: 'error'
        });
      },
      onSuccess: async (_, variables) => {
        showSnackbar({
          message: `Success! This user has been associated with the saver ${
            variables.item.firstName
          } ${variables.item.middleName || ''} ${variables.item.lastName}`,
          severity: 'success'
        });

        await queryClient.invalidateQueries([
          'UserManagementService.getUserAssociations'
        ]);
      }
    }
  );

  const userVssStateSaverRelationshipsMutation = useMutation(
    async (variables: MutateUpdateUserRelationshipsDto) => {
      return UserManagementService.createVssStateSaverRelationship(
        selectedUser?.userId,
        { entityId: variables.entityId }
      );
    },
    {
      onError: (error: Error) => {
        showSnackbar({
          message: error.message || `Failed!`,
          severity: 'error'
        });
      },
      onSuccess: async (_, variables) => {
        showSnackbar({
          message: `Success! This user has been associated with the VSS state saver ${variables.item.name}`,
          severity: 'success'
        });

        await queryClient.invalidateQueries([
          'UserManagementService.getUserAssociations'
        ]);
      }
    }
  );

  const handleAssociationTypeChange = useCallback(
    (_: React.MouseEvent<HTMLElement>, newChoice: AssociationType) => {
      setAssociationType(newChoice);
    },
    []
  );

  const onAdd = (
    item: GlobalSearchPlanDto &
      GlobalSearchParticipantDto &
      GlobalSearchAdvisorDto,
    id: number,
    type: Entity
  ) => {
    if (associations) {
      const associationExists = associations.data?.some(
        item => item.type === type && item[`${type}Id`] === id
      );

      if (associationExists) {
        return;
      }

      const mutateInfo: MutateUpdateUserRelationshipsDto = {
        entityId: id,
        item
      };

      switch (type) {
        case 'sponsor':
          userSponsorRelationshipsMutation.mutate(mutateInfo);
          break;
        case 'advisor':
          userAdvisorRelationshipsMutation.mutate(mutateInfo);
          break;
        case 'participant':
          userParticipantRelationshipsMutation.mutate(mutateInfo);
          break;
        case 'state_saver':
          userVssStateSaverRelationshipsMutation.mutate(mutateInfo);
          break;
        default:
          break;
      }
    }
  };

  const associationsHash = useMemo(() => {
    const initialHash = {
      advisor: [],
      participant: [],
      sponsor: [],
      state_saver: []
    };

    if (!associations.data) return initialHash;

    return associations.data.reduce((hash, item) => {
      const { type } = item;
      if (typesMap[type]) hash[type].push(item[typesMap[type]]);
      return hash;
    }, initialHash);
  }, [associations.data]);

  const { advisor, participant, sponsor, state_saver } = associationsHash;

  return (
    <Paper className={classes.paper}>
      <AccessControl
        requires={[
          FeatureLevelPermissions.WRITE_USER_MANAGEMENT_PERSONA_ASSOCIATION
        ]}>
        <Grid
          alignItems='flex-start'
          className={classes.root}
          container
          flexDirection='column'
          justifyContent='center'
          spacing={3}
          wrap='nowrap'>
          <Grid item>
            <Typography variant='h5'>Associate with a persona</Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                {associationType === AssociationType.WORKPLACE_USERS && (
                  <UserManagementAssociateSearch
                    canWriteUserManagement={canWriteUserManagement}
                    canWriteUserManagementParticipant={
                      canWriteUserManagementParticipant
                    }
                    hideValues={{ advisor, participant, sponsor }}
                    onAdd={onAdd}
                  />
                )}
                {associationType === AssociationType.STATE_SAVERS && (
                  <UserManagementStateSaversAssociateSearch
                    onAdd={onAdd}
                    vssStateSavers={state_saver}
                  />
                )}
              </Grid>
              <Grid item>
                <ToggleButtonGroup
                  data-testid='associate-toggle-buttons'
                  exclusive
                  onChange={handleAssociationTypeChange}
                  size='small'
                  value={associationType}>
                  {Object.values(AssociationType).map(type => (
                    <ToggleButton
                      className={classes.toggleButton}
                      data-testid={`${type}-toggle`}
                      disabled={associationType === type}
                      key={type}
                      value={type}>
                      <Typography>{type}</Typography>
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccessControl>
    </Paper>
  );
};

export default UserManagementAssociate;
