import Card from '@/components/card';
import { ExecuteParentAccountOrderDialog } from '@/routes/ops/accounts/common/ExecuteParentAccountOrderDialog.component';
import ResubmitParentAccountOrderDialog from '@/routes/ops/accounts/common/ResubmitParentAccountOrderDialog.component';
import DeferParentAccountOrderDialog from '@/routes/ops/order-management/parent-account-orders-detail/DeferParentAccountOrderDialog.component';
import ParentAccountOrderService from '@/services/ops/parent-account-orders/ParentAccountOrder.service';
import WorkflowService from '@/services/ops/workflows/Workflow.service';
import {
  Button,
  CardContent,
  CircularProgress,
  Stack,
  Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { AlertStatus } from '@vestwell-sub-accounting/models/common/AlertStatus';
import { OrderStatus } from '@vestwell-sub-accounting/models/orderManagement/OrderStatus';

import { FC, useContext } from 'react';
import { useToggle } from 'react-use';

import { AlertContext } from '../../AlertContext';
import useUpdateAlertMutation from '../../useUpdateAlertMutation.hook';

export const CustodianDestinationRejectActions: FC = () => {
  const alert = useContext(AlertContext);

  const [openResubmitDialog, toggleOpenResubmitDialog] = useToggle(false);
  const [openDeferDialog, toggleOpenDeferDialog] = useToggle(false);
  const [openManuallyExecuteDialog, toggleOpenManuallyExecuteDialog] =
    useToggle(false);

  const updateAlertMutation = useUpdateAlertMutation();

  const getParentAccountOrderQuery = useQuery(
    ['ParentAccountOrderService.getById', alert.details?.trade?.parentOrderId],
    () =>
      ParentAccountOrderService.getById(
        Number(alert.details.trade.parentOrderId)
      ),
    {
      enabled:
        !!alert.details?.trade?.parentOrderId &&
        !isNaN(Number(alert.details?.trade?.parentOrderId))
    }
  );

  const getWorkflowQuery = useQuery(
    ['WorkflowService.getById', alert?.details?.request?.tracerId],
    async () => {
      return WorkflowService.getById(
        `tracer:${alert?.details?.request?.tracerId}`
      );
    }
  );

  if (getWorkflowQuery.isInitialLoading) return <CircularProgress size={24} />;

  const closeAlert = () => {
    updateAlertMutation.mutate({
      alertId: alert.id,
      updateRequest: {
        alertStatus: AlertStatus.Closed
      }
    });
  };

  return (
    <>
      <Card>
        <CardContent>
          <Stack data-testid='actions' spacing={1}>
            <Stack
              alignItems='center'
              direction='row'
              justifyContent='space-between'
              spacing={1}>
              <Typography variant='h6'>Available Actions</Typography>
              <Stack
                alignItems='center'
                direction='row'
                justifyContent='space-around'
                spacing={1}>
                <Button
                  color='primary'
                  data-testid='actions-resubmit-button'
                  disabled={
                    !getParentAccountOrderQuery.data ||
                    getParentAccountOrderQuery.data?.orderStatus !==
                      OrderStatus.Rejected
                  }
                  onClick={toggleOpenResubmitDialog}>
                  Resubmit
                </Button>
                <Button
                  color='primary'
                  data-testid='actions-defer-button'
                  disabled={
                    !getParentAccountOrderQuery.data ||
                    getParentAccountOrderQuery.data?.orderStatus !==
                      OrderStatus.Rejected
                  }
                  onClick={toggleOpenDeferDialog}>
                  Defer
                </Button>
                <Button
                  color='primary'
                  data-testid='actions-manually-complete-button'
                  disabled={
                    !getParentAccountOrderQuery.data ||
                    [
                      OrderStatus.Rejected,
                      OrderStatus.Accepted,
                      OrderStatus.Submitted
                    ].includes(getParentAccountOrderQuery.data?.orderStatus)
                  }
                  onClick={toggleOpenManuallyExecuteDialog}>
                  Manually Complete
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      {getParentAccountOrderQuery.data && (
        <>
          <DeferParentAccountOrderDialog
            onClose={() => {
              toggleOpenDeferDialog(false);
            }}
            onDeferredParentAccountOrders={closeAlert}
            open={openDeferDialog}
            parentAccountOrder={getParentAccountOrderQuery.data}
          />
          <ResubmitParentAccountOrderDialog
            onClose={toggleOpenResubmitDialog}
            onResubmit={closeAlert}
            open={openResubmitDialog}
            parentAccountOrder={getParentAccountOrderQuery.data}
          />
          <ExecuteParentAccountOrderDialog
            onClose={toggleOpenManuallyExecuteDialog}
            onExecute={closeAlert}
            open={openManuallyExecuteDialog}
            parentAccountOrder={getParentAccountOrderQuery.data}
          />
        </>
      )}
    </>
  );
};

CustodianDestinationRejectActions.displayName =
  'CustodianDestinationRejectActions';
