import LinearLoading from '@/components/linear-loading';
import { FundLineupData } from '@/routes/ops/investments/FundLineup.component';
import InvestmentService from '@/services/Investment.service';
import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import React from 'react';

import FundLineupTable from './FundLineupTable.component';

interface FundLineupTabProps {
  fundLineupId: number;
}

const FundLineupTab: React.FC<FundLineupTabProps> = ({ fundLineupId }) => {
  const { data, isLoading } = useQuery<FundLineupData>(
    ['FundLineupEdit.fundLineupQuery', 'fund-lineup', fundLineupId],
    () => InvestmentService.getFundLineup(fundLineupId),
    {
      staleTime: Infinity
    }
  );

  if (isLoading) {
    return (
      <Box textAlign='center'>
        <LinearLoading />
      </Box>
    );
  }

  return <FundLineupTable data={data} />;
};

export default FundLineupTab;
