import Badge from '@/components/badge';
import CopyToClipboard from '@/components/copy-to-clipboard';
import NavigationBreadcrumbs from '@/components/navigation-breadcrumbs';
import { colorByPlanStatus } from '@/consts/plan.constants';
import { Divider, Grid, Stack, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import React, { FC, ReactNode, Suspense } from 'react';

import CircularLoading from '../circular-loading';

const DetailsPageVariantState = {
  advisor: 'Advisor',
  contribution: 'Contribution',
  loan: 'Loan',
  participant: 'Employee',
  rollover: 'Rollover',
  sponsorPlan: 'Plan',
  withdrawal: 'Withdrawal'
};

const DetailsPageVariantNonState = {
  advisor: 'Advisor',
  contribution: 'Contribution',
  loan: 'Loan',
  participant: 'Participant',
  rollover: 'Rollover',
  sponsorPlan: 'Plan',
  withdrawal: 'Withdrawal'
};

interface DetailsPageProps {
  paths: Path[];
  title: string;
  variant:
    | keyof typeof DetailsPageVariantState
    | keyof typeof DetailsPageVariantNonState;
  copyableNumber?: number | string;
  isStateIRA?: boolean;
  children?: React.ReactNode;
  programName?: string;
  planStage?: string;
  status?: string;
  recordkeeper?: string;
  flowSubtype?: string;
  headerItems?: ReactNode[];
}

interface Path {
  name: string;
  to: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  copySpacing: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  detailSpacing: {
    width: '80vw'
  },
  mutedText: {
    color: theme.palette.text.secondary
  },
  planStageBadge: {
    marginLeft: theme.spacing(1)
  },
  statusBadge: {
    marginLeft: theme.spacing(1)
  }
}));

const DetailsPage: FC<DetailsPageProps> = props => {
  const classes = useStyles();

  return (
    <>
      <NavigationBreadcrumbs paths={props.paths} />
      <Grid className={classes.detailSpacing} container spacing={2}>
        <Grid item xs={12}>
          <Grid alignItems='center' container>
            <Grid item>
              <Typography data-testid='details-page-title' variant='h4'>
                {props.title}
              </Typography>
            </Grid>

            {props.status && (
              <Grid
                className={classes.statusBadge}
                data-testid='details-page-status-badge'
                item>
                <Badge color={colorByPlanStatus[props.status]} size='small'>
                  {props.status}
                </Badge>
              </Grid>
            )}

            {props.planStage && props.planStage !== 'Onboarding Complete' && (
              <Grid
                className={classes.planStageBadge}
                data-testid='details-page-plan-stage-badge'
                item>
                <Badge color='neutral' size='small'>
                  {props.planStage}
                </Badge>
              </Grid>
            )}

            {(!!props.copyableNumber || !!props.headerItems) && (
              <Grid container mb={1} mt={1}>
                {props.copyableNumber && (
                  <Grid item>
                    <Stack alignItems='center' direction='row' spacing={1}>
                      <Typography
                        className={classes.mutedText}
                        data-testid='details-page-entity-id'
                        variant='subtitle1'>
                        {props.isStateIRA
                          ? DetailsPageVariantState[props.variant]
                          : DetailsPageVariantNonState[props.variant]}{' '}
                        ID: {props.copyableNumber}
                      </Typography>{' '}
                      <CopyToClipboard
                        copyName={`${
                          props.isStateIRA
                            ? DetailsPageVariantState[props.variant]
                            : DetailsPageVariantNonState[props.variant]
                        } ID`}
                        copyValue={String(props.copyableNumber)}
                        size='small'
                      />
                      {props.isStateIRA && props.variant === 'participant' && (
                        <Typography
                          className={classes.mutedText}
                          data-testid='details-page-entity-program-name'
                          variant='subtitle1'>
                          Program: {props.programName}
                        </Typography>
                      )}
                    </Stack>
                  </Grid>
                )}
                <Suspense
                  fallback={
                    <Grid
                      alignItems='center'
                      display='flex'
                      item
                      justifyContent='center'
                      pl={3}>
                      <CircularLoading size='20px' sx={{ display: 'block' }} />
                    </Grid>
                  }>
                  {props.headerItems &&
                    props.headerItems.map(component => (
                      <Grid item key={component.toString()}>
                        <Stack alignItems='center' direction='row' spacing={1}>
                          <Divider flexItem orientation='vertical' />

                          {component}
                        </Stack>
                      </Grid>
                    ))}
                </Suspense>
              </Grid>
            )}
          </Grid>
        </Grid>
        {props.children}
      </Grid>
    </>
  );
};

export default DetailsPage;
