import formatters from '@/utils/Formatters';

import dayjs from 'dayjs';
import Decimal from 'decimal.js';
import { isEmpty, snakeCase, sortBy } from 'lodash';
import { useMemo } from 'react';

const getNormalizedData = (
  columns: string[],
  data: Record<string, string[]>,
  loans
) => {
  if (isEmpty(data) || data[Object.keys(data)[0]].length === 0) return [];

  const participants: Record<string, any>[] = Array(
    data[Object.keys(data)[0]].length
  )
    .fill(null)
    .map((value, normalizedDataIndex) =>
      columns.reduce((acc, columnKey, columnIndex) => {
        const normalizedColumn = data[columns[columnIndex]];

        return {
          ...acc,
          normalizedDataIndex: normalizedDataIndex + 1,
          [![
            'esaEeContribution',
            'esaMatch',
            'esaInitialBonus',
            'esaMilestoneBonusAmount'
          ].includes(columnKey)
            ? snakeCase(columnKey)
            : columnKey]: Array.isArray(normalizedColumn)
            ? normalizedColumn[normalizedDataIndex]
            : ''
        };
      }, {})
    );

  const participantsWithLoans = loans?.length
    ? participants.map(participant => {
        const loan = loans
          ?.find(loan => participant.id === loan.participantId)
          ?.loans?.reduce((acc, current) => {
            return {
              ...acc,
              [current.loanNumber]: [
                0,
                current.paymentAmount,
                current.fullAmount
              ]
            };
          }, {});

        const loanOptions = loan
          ? Object.entries(participant)?.reduce(
              (acc, [key, value]) => ({
                ...acc,
                ...(key.includes('loan_num_')
                  ? { [`${key.replace('num', 'amt')}_options`]: loan[value] }
                  : {})
              }),
              {}
            )
          : {};

        return {
          ...participant,
          ...loanOptions,
          ln:
            participant.ln ||
            Object.entries(participant)
              ?.reduce(
                (acc, [key, value]) =>
                  key.includes('loan_amt_') && value
                    ? acc.plus(value || 0)
                    : acc,
                new Decimal(0)
              )
              ?.toNumber()
        };
      })
    : participants;

  return (
    participantsWithLoans?.map(participant => ({
      ...participant,
      ...(participant.date_of_hire
        ? { date_of_hire: dayjs(participant.date_of_hire).format('MM/DD/YYYY') }
        : {}),
      ...(participant.date_of_rehire
        ? {
            date_of_rehire: dayjs(participant.date_of_rehire).format(
              'MM/DD/YYYY'
            )
          }
        : {}),
      ...(participant.date_of_termination
        ? {
            date_of_termination: dayjs(participant.date_of_termination).format(
              'MM/DD/YYYY'
            )
          }
        : {}),
      ...(participant.date_of_birth
        ? {
            date_of_birth: dayjs(participant.date_of_birth).format('MM/DD/YYYY')
          }
        : {})
    })) ?? []
  );
};

const getParticipantsData = (
  participants,
  columns,
  recordkeeperName,
  loans
) => {
  const defaultValues = columns.reduce(
    (acc, current) =>
      'field' in current ? { ...acc, [current.field]: '' } : acc,
    {}
  );

  if (recordkeeperName === 'Voya') {
    return participants
      .map(participant => ({
        ...participant,
        ...{
          loans: loans.find(loan => participant.id === loan.participantId)
            ?.loans
        }
      }))
      .map(participant => ({
        ...defaultValues,
        city: participant.contactInfo?.address?.city,
        country: participant.contactInfo?.address?.country,
        date_of_birth: participant.birthDate,
        date_of_hire: participant.hireDate,
        date_of_termination: participant.terminationDate,
        external_payroll_id: participant.externalId,
        first_name: participant.firstName,
        id: participant.id,
        last_name: participant.lastName,
        marital_status: participant.maritalStatus,
        ssn: participant.ssn,
        state: participant.contactInfo?.address?.state,
        street_address_1: participant.contactInfo?.address?.address1,
        street_address_2: participant.contactInfo?.address?.address2,
        zip_code: participant.contactInfo?.address?.zip,
        ...sortBy(participant.loans, value => +value.loanNumber).reduce(
          (acc, current, index) => ({
            ...acc,
            [`loan_num_${index + 1}`]: current.loanNumber,
            [`loan_amt_${index + 1}`]: '',
            [`loan_amt_${index + 1}_options`]: [
              0,
              current.paymentAmount,
              current.fullAmount
            ]
          }),
          {}
        )
      }));
  }

  if (recordkeeperName === 'Vestwell ESA') {
    return participants.map(participant => ({
      ...defaultValues,
      at_election: formatters.formatDeferral(
        participant.deferralElections?.afterTaxType,
        participant.deferralElections?.afterTaxAmt
      ),
      city: participant.contactInfo?.address?.city,
      country: participant.contactInfo?.address?.country,
      date_of_birth: participant.birthDate,
      date_of_hire: participant.hireDate,
      date_of_rehire: participant.rehireDate,
      date_of_termination: participant.terminationDate,
      email: participant.contactInfo?.workEmail,
      employee_group_name: participant.esaGroupName,
      external_payroll_id: participant.externalId,
      first_name: participant.firstName,
      id: participant.id,
      last_name: participant.lastName,
      ssn: participant.ssn,
      state: participant.contactInfo?.address?.state,
      street_address_1: participant.contactInfo?.address?.address1,
      street_address_2: participant.contactInfo?.address?.address2,
      zip_code: participant.contactInfo?.address?.zip
    }));
  }

  if (['Vestwell Sub-Accounting Platform'].includes(recordkeeperName)) {
    return participants.map(participant => ({
      ...defaultValues,
      at_election: formatters.formatDeferral(
        participant.deferralElections?.afterTaxType,
        participant.deferralElections?.afterTaxAmt
      ),
      city: participant.contactInfo?.address?.city,
      country: participant.contactInfo?.address?.country,
      date_of_birth: participant.birthDate,
      date_of_hire: participant.hireDate,
      date_of_rehire: participant.rehireDate,
      date_of_termination: participant.terminationDate,
      email: participant.contactInfo?.workEmail,
      external_payroll_id: participant.externalId,
      first_name: participant.firstName,
      id: participant.id,
      last_name: participant.lastName,
      rc_election: formatters.formatDeferral(
        participant.deferralElections?.rothType,
        participant.deferralElections?.rothAmt
      ),
      sd_election: formatters.formatDeferral(
        participant.deferralElections?.preTaxType,
        participant.deferralElections?.preTaxAmt
      ),
      ssn: participant.ssn,
      state: participant.contactInfo?.address?.state,
      street_address_1: participant.contactInfo?.address?.address1,
      street_address_2: participant.contactInfo?.address?.address2,
      zip_code: participant.contactInfo?.address?.zip
    }));
  }

  return participants.map(participant => ({
    ...defaultValues,
    at_election: formatters.formatDeferral(
      participant.deferralElections?.afterTaxType,
      participant.deferralElections?.afterTaxAmt
    ),
    date_of_birth: participant.birthDate,
    first_name: participant.firstName,
    id: participant.id,
    last_name: participant.lastName,
    rc_election: formatters.formatDeferral(
      participant.deferralElections?.rothType,
      participant.deferralElections?.rothAmt
    ),
    sd_election: formatters.formatDeferral(
      participant.deferralElections?.preTaxType,
      participant.deferralElections?.preTaxAmt
    ),
    ssn: participant.ssn
  }));
};

export const useGridRows = (
  participants,
  recordkeeperName,
  columns,
  loans,
  populate
) => {
  return useMemo(() => {
    if (!participants || !columns || !recordkeeperName) return [];

    if (populate === 'normalized') {
      return getNormalizedData(Object.keys(participants), participants, loans);
    }

    return getParticipantsData(participants, columns, recordkeeperName, loans);
  }, [participants, recordkeeperName, populate, loans]);
};
