import { useSnackbar } from '@/contexts/SnackBarContext';
import { PlanV2Dto } from '@/models';
import ContributionService from '@/services/Contribution.service';
import InMemoryFileDownloadService from '@/services/InMemoryFileDownloadService.service';
import { PlanService } from '@/services/Plan.service';
import { DriveFileRenameOutline as DriveFileRenameOutlineIcon } from '@mui/icons-material';
import { Button, Link, Skeleton, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useQuery } from '@tanstack/react-query';

import { useCallback, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';

import { CancelContribution } from './CancelContribution.component';
import { Header, SpreadSheet, Upload } from './components';

export const ContributionSubmissionRoute = () => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const snackbar = useSnackbar();

  const plan = useQuery<PlanV2Dto>(
    [PlanService.getPlanById.name, +params.planId],
    () => PlanService.getPlanById(+params.planId),
    { enabled: !!params.planId }
  );

  const contribution = useQuery(
    [
      ContributionService.getContributionDetails.name,
      plan.data?.data?.id,
      plan.data?.data?.relationships?.sponsor?.data?.id,
      params.ucid
    ],
    () =>
      ContributionService.getContributionDetails({
        planId: plan.data?.data?.id,
        sort: 'lastName',
        sponsorId: plan.data?.data?.relationships?.sponsor?.data?.id,
        ucid: params.ucid
      }),
    { enabled: !!params.ucid && !!plan.data?.data }
  );

  const contributionTemplate = useQuery(
    [
      ContributionService.getDownloadContributionTemplate.name,
      params.ucid,
      plan.data?.data?.id,
      plan.data?.data?.relationships?.recordKeeper?.data?.id === 2,
      plan.data?.data?.relationships?.sponsor?.data?.id
    ],
    () =>
      ContributionService.getDownloadContributionTemplate(
        { ucid: params.ucid },
        {
          sponsorId: plan.data?.data?.relationships?.sponsor?.data?.id,
          sponsorPlanId: plan.data?.data?.id,
          test: plan.data?.data?.relationships?.recordKeeper?.data?.id === 2
        }
      ),
    { enabled: false }
  );

  const isManual = useMemo(
    () => JSON.parse(searchParams?.get('isManual')),
    [searchParams]
  );

  const isEdit = useMemo(
    () => JSON.parse(searchParams?.get('isEdit')),
    [searchParams]
  );

  const onDownload = useCallback(() => contributionTemplate.refetch(), []);

  const onManualSubmission = useCallback(
    () => setSearchParams({ isManual: 'true' }),
    []
  );

  useUpdateEffect(() => {
    InMemoryFileDownloadService.triggerFileDownload(
      contributionTemplate.data as string,
      'Contributions_Template.xlsx'
    );
  }, [contributionTemplate.isSuccess, contributionTemplate.data]);

  useUpdateEffect(() => {
    snackbar.showSnackbar({
      message: 'Something went wrong!',
      severity: 'error'
    });
  }, [contributionTemplate.isError]);

  return (
    <Grid container>
      <Grid mb={2} mt={3} xs={12}>
        <Header
          date={contribution.data?.payrollDate}
          flowSubtype={contribution.data?.key?.flowSubtype}
          isLoading={contribution.isLoading || contribution.isFetching}
          payGroupCode={contribution.data?.division}
          payGroupName={contribution.data?.payGroupName}
          planId={plan.data?.data?.id}
          planName={plan.data?.data?.attributes?.name}
        />
      </Grid>
      <Grid mb={5} xs={12}>
        <CancelContribution
          flowSubtype={contribution.data?.key?.flowSubtype}
          sponsorId={plan.data?.data?.relationships?.sponsor?.data?.id}
          sponsorPlanId={plan.data?.data?.id}
          ucid={contribution.data?.ucid}
        />
      </Grid>
      {isManual || isEdit ? (
        <SpreadSheet
          planId={plan.data?.data?.id}
          populate={isEdit ? 'normalized' : 'participants'}
          ucid={params.ucid}
        />
      ) : (
        <>
          <Grid alignItems='center' display='flex' mb={3}>
            <Upload planId={plan.data?.data?.id} ucid={params.ucid} />
            <Typography mx={3}>or</Typography>
            <Button
              data-testid='enterDataManually'
              onClick={onManualSubmission}
              startIcon={<DriveFileRenameOutlineIcon />}>
              Enter data manually
            </Button>
          </Grid>
          <Grid xs={12}>
            {contributionTemplate.isFetching ? (
              <Skeleton width={250} />
            ) : (
              <Link
                component='button'
                data-testid='downloadTemplate'
                onClick={onDownload}>
                Click here to download a template.
              </Link>
            )}
            <Typography color='gray' data-testid='uploadFileWarning'>
              New employees can only be added via a file upload.
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};
