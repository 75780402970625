import { useSnackbar } from '@/contexts/SnackBarContext';
import { TickerOption } from '@/routes/ops/investments/common/TickerAutocomplete.component';
import InvestmentTable from '@/routes/ops/investments/investment-table/InvestmentTable.component';
import { TickerSelect } from '@/routes/ops/investments/investment-table/TickerSelect.component';
import InvestmentService from '@/services/Investment.service';
import { Typography } from '@mui/material';

import { ColDef } from 'ag-grid-community';
import { useFormik } from 'formik';
import { times } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import ModelEditDetailView from './models/ModelEditDetailView.component';

export interface FundLineupFund {
  cusip: string;
  symbol: string;
  assetClass: string;
  fundName: string;
  fundId: string;
  secId: string;
  securityProviderName: string;
  broadAssetClass: string;
  expenseRatio: number;
}

export interface FundLineupData {
  fundLineupId: number;
  name: string;
  description?: string;
  fundLineupFunds: FundLineupFund[];
}

export interface FundLineupProps {
  fundLineupInvestmentOption: FundLineupData;
  readonly: boolean;
  programCount: number;
  fundLineupId: number;
  refetchFundLineup: () => void;
}

const FundLineupSchema = Yup.object().shape({
  fundLineupFunds: Yup.array()
    .min(1, 'Must have at least one fund')
    .required('Required'),
  name: Yup.string().required('Required')
});

export const FundLineup = (props: FundLineupProps): JSX.Element => {
  const {
    fundLineupInvestmentOption,
    programCount,
    fundLineupId,
    refetchFundLineup
  } = props;

  const snackbar = useSnackbar();

  const initialValues = useMemo(
    () => fundLineupInvestmentOption,
    [fundLineupInvestmentOption]
  );

  const form = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values, formikHelpers) => {
      try {
        await InvestmentService.putFundLineup(fundLineupId, values);
        refetchFundLineup();
        snackbar.showSnackbar({
          message: 'Save Successful',
          severity: 'success'
        });
      } catch ({ message }) {
        snackbar.showSnackbar({
          message,
          severity: 'error'
        });
        formikHelpers.resetForm();
      }
    },
    validationSchema: FundLineupSchema
  });

  const tickerRows = useMemo(() => {
    const DEFAULT_NUMBER_OF_TICKER_ROWS = 30;
    const NEEDED_BLANK_ROWS =
      DEFAULT_NUMBER_OF_TICKER_ROWS - form?.values.fundLineupFunds.length;

    const providedTickers =
      form?.values.fundLineupFunds.map((item: TickerOption, index: number) => {
        const name = `fundLineupFunds[${index}]`;
        return {
          ...item,
          id: index,
          index,
          name,
          symbol: item.symbol
        };
      }) || [];

    return [
      ...providedTickers,
      ...times(NEEDED_BLANK_ROWS > 0 ? NEEDED_BLANK_ROWS : 1, index => {
        return {
          cusip: '',
          id: form?.values.fundLineupFunds.length + index,
          symbol: ''
        };
      })
    ];
  }, [form?.values.fundLineupFunds]);

  const onCommit = useCallback(
    (params: { value: any; id: number }) => {
      const arry = form?.values.fundLineupFunds || [];

      // delete row if empty value
      // TODO: this does not appear to be working
      if (!params.value || !params.value.cusip || !params.value.symbol) {
        const found = arry.find((_, index) => index === params.id);

        if (found) {
          const adjusted = arry.filter((item: { symbol: string }) => {
            return item.symbol !== params.value.symbol;
          });
          form?.setFieldValue(`fundLineupFunds`, adjusted);
        }

        // logic if value is not empty
      } else {
        const existing = (form?.values.fundLineupFunds || []).find(
          item =>
            item.cusip === params.value.cusip &&
            item.symbol === params.value.symbol
        );

        // if value is not duplicate
        if (!existing) {
          if (form?.values.fundLineupFunds[params.id]) {
            form?.setFieldValue(`fundLineupFunds[${params.id}]`, params.value);
          } else {
            form?.setFieldValue(
              `fundLineupFunds[${arry.length}]`,
              params.value
            );
          }
        } else {
          const isEmptyRow = !arry[parseInt(`${params.id}`, 10)];

          if (isEmptyRow) {
            snackbar.showSnackbar({
              message: 'Duplicate ticker found',
              severity: 'warning'
            });
          }
        }
      }
    },
    [form, snackbar]
  );

  const columnDefs: ColDef[] = useMemo(() => {
    return [
      {
        cellEditor: TickerSelect,
        cellEditorParams: {
          lookupTickerCallback: InvestmentService.searchSymbol,
          onCommit
        },
        cellEditorPopup: true,
        cellRenderer: (params: any) => {
          const editable = params.colDef.editable(params);
          if (params.value) return params.value;

          return (
            <Typography color='rgba(0, 0, 0, 0.6)'>
              {editable ? 'Enter Ticker' : ''}
            </Typography>
          );
        },
        comparator: (valueA, valueB) =>
          !valueA || !valueB ? 0 : valueA.localeCompare(valueB),
        editable: params => {
          return (
            !props.readonly &&
            (params.node.rowIndex === null ||
              !!params.context.values.fundLineupFunds[params.node.rowIndex] ||
              !!params.context.values.fundLineupFunds[
                params.node.rowIndex - 1
              ] ||
              params.node.rowIndex === 0)
          );
        },
        field: 'symbol',
        headerName: 'Ticker',
        lockPinned: true,
        maxWidth: 146,
        pinned: 'left',
        resizable: false,
        sortable: true,
        suppressMenu: true,
        suppressMovable: true,
        valueFormatter: params => params.data?.symbol || 'Enter Ticker'
      },
      {
        comparator: (valueA, valueB) =>
          !valueA || !valueB ? 0 : valueA.localeCompare(valueB),
        field: 'fundName',
        headerName: 'Security Name',
        lockPinned: true,
        resizable: true,
        sortable: true,
        suppressMenu: true,
        suppressMovable: true,
        valueFormatter: params => params.data?.fundName || ' '
      },
      {
        comparator: (valueA, valueB) =>
          !valueA || !valueB ? 0 : valueA.localeCompare(valueB),
        field: 'broadAssetClass',
        headerName: 'Broad Assset Class',
        lockPinned: true,
        resizable: true,
        sortable: true,
        suppressMenu: true,
        suppressMovable: true,
        valueFormatter: params => params.data?.broadAssetClass || ' '
      },
      {
        comparator: (valueA, valueB) =>
          !valueA || !valueB ? 0 : valueA - valueB,
        field: 'expenseRatio',
        headerName: 'Expense Ratio',
        lockPinned: true,
        resizable: true,
        sortable: true,
        suppressMenu: true,
        suppressMovable: true,
        valueFormatter: params => params.data?.expenseRatio || ' '
      }
    ];
  }, [onCommit, props.readonly]);

  return (
    <ModelEditDetailView
      bulkField='fundLineupFunds'
      content={
        <InvestmentTable
          columnDefs={columnDefs}
          context={form}
          data-testid='investmentTable'
          rowData={tickerRows}
        />
      }
      dirty={form?.dirty}
      errors={form?.errors}
      investmentOptionId={fundLineupInvestmentOption.fundLineupId}
      isSubmitting={form?.isSubmitting}
      isValid={form?.isValid}
      lookupTickerCallback={InvestmentService.searchSymbol}
      modelSeriesType='Fund Lineup'
      onSaveModel={form?.submitForm}
      programCount={programCount}
      readonly={props.readonly}
      setFieldValue={form?.setFieldValue}
      values={form?.values}
    />
  );
};

FundLineup.defaultProps = {
  readonly: false
};
