import STATE_CODES from '@/consts/states.constants';

import * as yup from 'yup';

const blackListAddresses = ['POBox', 'PostOfficeBox'];

const checkForValidAddress = (value?: string) =>
  !(
    value &&
    blackListAddresses
      .map(address => address.toLowerCase())
      .includes(value.replaceAll('.', '').replaceAll(' ', '').toLowerCase())
  );

const mailingAddressValidationSchema = {
  mailingAddress1: yup
    .string()
    .trim()
    .when('mailingAddressEqualsPhysical', {
      is: false,
      then: yup
        .string()
        .required('Mailing Address 1 is required')
        .test(
          'invalid address check',
          'Please provide a valid address',
          checkForValidAddress
        )
    }),

  mailingAddress2: yup
    .string()
    .trim()
    .when('mailingAddressEqualsPhysical', {
      is: false,
      then: yup
        .string()
        .test(
          'invalid address check',
          'Please provide a valid address',
          checkForValidAddress
        )
    }),
  mailingCity: yup
    .string()
    .trim()
    .when('mailingAddressEqualsPhysical', {
      is: false,
      then: yup.string().required('City is required')
    }),
  mailingState: yup.string().when('mailingAddressEqualsPhysical', {
    is: false,
    then: yup
      .string()
      .oneOf(STATE_CODES, 'Please enter a valid state abbreviation')
      .required('State is required')
  }),
  mailingZip: yup
    .string()
    .trim()
    .when('mailingAddressEqualsPhysical', {
      is: false,
      then: yup
        .string()
        .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Must be a valid zip code')
        .required('Zip is required')
    })
};

const createParticipantContactValidationSchema = (
  isStateIRA: boolean
): yup.AnyObjectSchema => {
  const mailingFields = !isStateIRA ? {} : mailingAddressValidationSchema;
  return yup.object().shape(
    {
      address1: yup
        .string()
        .trim()
        .required('Address 1 is required')
        .test(
          'invalid address check',
          'Please provide a valid address',
          checkForValidAddress
        ),
      address2: yup
        .string()
        .trim()
        .test(
          'invalid address check',
          'Please provide a valid address',
          checkForValidAddress
        ),
      city: yup.string().trim().required('City is required'),
      mailingAddressEqualsPhysical: yup.boolean(),
      personalEmail: yup.string().trim().email('Please provide a valid email'),
      phoneNumber: yup
        .string()
        .matches(
          /^(?:\d{3})-\d{3}-\d{4}$/,
          'Invalid format, please use: XXX-XXX-XXXX'
        )
        .when('workEmail', {
          is: workEmail => isStateIRA && !workEmail,
          then: schema => schema.required('Email or Mobile phone is required')
        }),
      state: yup
        .string()
        .oneOf(STATE_CODES, 'Please enter a valid state abbreviation')
        .required('State is required'),
      workEmail: yup
        .string()
        .trim()
        .email('Please provide a valid email')
        .when('phoneNumber', {
          is: phoneNumber => isStateIRA && !phoneNumber,
          then: schema => schema.required('Email or Mobile phone is required')
        }),
      zip: yup
        .string()
        .trim()
        .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Must be a valid zip code')
        .required('Zip is required'),
      ...mailingFields
    },
    [['workEmail', 'phoneNumber']]
  );
};

export default createParticipantContactValidationSchema;
