import formatters from '@/utils/Formatters';
import { NativeSelect, TextField } from '@mui/material';
import { formatSsn } from '@vestwell-frontend/helpers';

import React, { forwardRef, useCallback, useState } from 'react';
import {
  NumericFormat,
  NumericFormatProps,
  PatternFormat
} from 'react-number-format';
import { useUpdateEffect } from 'react-use';

import { AgGridEditor } from './AgGridEditor.component';

const NumericFormatCustom = React.forwardRef<
  NumericFormatProps,
  { onChange: (data: any) => void }
>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      allowNegative={false}
      decimalScale={2}
      defaultValue={0}
      getInputRef={ref}
      onValueChange={onChange}
      thousandSeparator
      valueIsNumericString
    />
  );
});

const CurrencyFormatCustom = React.forwardRef<
  NumericFormatProps,
  { onChange: (data: any) => void }
>(function CurrencyFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      allowNegative={false}
      decimalScale={2}
      defaultValue={0}
      getInputRef={ref}
      onValueChange={onChange}
      prefix='$'
      thousandSeparator
      valueIsNumericString
    />
  );
});

const DateFormatCustom = React.forwardRef<
  NumericFormatProps,
  { onChange: (data: any) => void }
>(function DateFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <PatternFormat
      {...other}
      format='##/##/####'
      getInputRef={ref}
      onValueChange={value => onChange(value.formattedValue)}
      // @ts-ignore
      value={other.value?.replace(/\D/g, '')}
    />
  );
});

export const columnTypes = {
  currency: AgGridEditor(
    {
      cellClass: ['currency'],
      valueFormatter: params =>
        params.value === ''
          ? params.value
          : formatters.formatDollars(params.value)
    },
    // eslint-disable-next-line react/display-name
    forwardRef<any, any>((params, ref) => {
      const [value, setValue] = useState(params.value);

      const onValueChange = useCallback(
        data => setValue(data.floatValue ?? 0),
        []
      );

      useUpdateEffect(() => {
        params.onChange(value);
      }, [value]);

      return (
        <TextField
          InputProps={{
            inputComponent: CurrencyFormatCustom as any
          }}
          autoFocus
          fullWidth
          onChange={onValueChange}
          ref={ref}
          value={value}
        />
      );
    })
  ),
  date: AgGridEditor(
    {
      cellClass: ['date']
    },
    // eslint-disable-next-line react/display-name
    forwardRef<any, any>((params, ref) => {
      const [value, setValue] = useState(params.value);

      const onValueChange = useCallback(data => setValue(data), []);

      useUpdateEffect(() => {
        params.onChange(value);
      }, [value]);

      return (
        <TextField
          InputProps={{
            inputComponent: DateFormatCustom as any
          }}
          autoFocus
          fullWidth
          onChange={onValueChange}
          ref={ref}
          value={value}
        />
      );
    })
  ),
  numeric: AgGridEditor(
    {
      cellClass: ['numeric']
    },
    // eslint-disable-next-line react/display-name
    forwardRef<any, any>((params, ref) => {
      const [value, setValue] = useState(params.value);

      const onValueChange = useCallback(
        data => setValue(data.floatValue ?? 0),
        []
      );

      useUpdateEffect(() => {
        params.onChange(value);
      }, [value]);

      return (
        <TextField
          InputProps={{
            inputComponent: NumericFormatCustom as any
          }}
          autoFocus
          fullWidth
          onChange={onValueChange}
          ref={ref}
          value={value}
        />
      );
    })
  ),
  select: AgGridEditor(
    {
      cellClass: ['select'],
      valueFormatter: params =>
        params.value === ''
          ? params.value
          : formatters.formatDollars(params.value)
    },
    // eslint-disable-next-line react/display-name
    forwardRef<any, any>((params, ref) => {
      const [value, setValue] = useState(params.value);

      const onValueChange = useCallback(
        event => setValue(event.target?.value),
        []
      );

      useUpdateEffect(() => {
        params.onChange(value, true);
      }, [value]);

      return (
        <NativeSelect
          fullWidth
          onChange={onValueChange}
          ref={ref}
          value={params.value}>
          {params.values?.map(value => (
            <option key={value} value={value}>
              {formatters.formatDollars(value ?? 0)}
            </option>
          ))}
        </NativeSelect>
      );
    })
  ),
  ssn: AgGridEditor(
    {
      cellClass: ['ssn'],
      valueFormatter: params => {
        try {
          return formatters.maskSSN(formatSsn(params.value));
        } catch {
          return params.value;
        }
      }
    },
    // eslint-disable-next-line react/display-name
    forwardRef<any, any>((params, ref) => {
      const [value, setValue] = useState(params.value);

      const onValueChange = useCallback(
        data => setValue(data.floatValue ?? 0),
        []
      );

      useUpdateEffect(() => {
        params.onChange(value);
      }, [value]);

      return (
        <TextField
          autoFocus
          fullWidth
          onChange={onValueChange}
          ref={ref}
          value={value}
        />
      );
    })
  ),
  text: AgGridEditor(
    {
      cellClass: ['text']
    },
    // eslint-disable-next-line react/display-name
    forwardRef<any, any>((params, ref) => {
      const [value, setValue] = useState(params.value);

      const onValueChange = useCallback(
        event => setValue(event.target?.value ?? ''),
        []
      );

      useUpdateEffect(() => {
        params.onChange(value);
      }, [value]);

      return (
        <TextField
          autoFocus
          fullWidth
          onChange={onValueChange}
          ref={ref}
          value={value}
        />
      );
    })
  )
};
