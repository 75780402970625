import Card, {
  CardContent,
  CardHeader,
  CardPlaceholder
} from '@/components/card';
import { EsaService } from '@/services/Esa.service';
import ParticipantService from '@/services/Participant.service';
import formatters from '@/utils/Formatters';
import { Box } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import React, { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';

const ParticipantGoals: FC = () => {
  const params = useParams();
  const esaParticipantGoals = useQuery(
    ['EsaService.getEsaParticipantGoals', +params.participantId],
    () => EsaService.getEsaParticipantGoals(+params.participantId)
  );
  const completedTransactionsQuery = useQuery(
    ['ParticipantService.getGroupedTransactions', +params.participantId],
    () =>
      ParticipantService.getGroupedTransactions({
        participantId: +params.participantId
      }),
    {
      select: data =>
        data.reduce(
          (accumulator, group) =>
            accumulator + formatters.unformatCurrency(group.amount),
          0
        )
    }
  );

  const columns = useMemo(
    () => [
      {
        field: 'priority',
        flex: 1,
        headerName: 'Priority'
      },
      {
        field: 'name',
        flex: 1,
        headerName: 'Name'
      },
      {
        field: 'goalAmount',
        flex: 1,
        headerName: 'Amount',
        valueFormatter: data => formatters.formatDollars(data.value)
      },
      {
        field: 'Contributed',
        flex: 1,
        headerName: 'Contributed',
        valueFormatter: () =>
          formatters.formatDollars(completedTransactionsQuery.data)
      },
      {
        field: 'Progress',
        flex: 1,
        headerName: 'Progress',
        valueFormatter: data =>
          formatters.toFixedIfNecessary(
            data.api.getRow(data.id).goalAmount /
              completedTransactionsQuery.data || 0,
            2
          ) + '%'
      },
      {
        field: 'createdAt',
        flex: 1,
        headerName: 'Last Updated At',
        valueFormatter: data => formatters.formatFromIsoDate(data.value)
      }
    ],
    [completedTransactionsQuery.data]
  );

  return (
    <Card data-testid='participant-goals-card'>
      <CardHeader
        data-testid='goals-header'
        disableDivider
        loading={
          esaParticipantGoals.isFetching ||
          completedTransactionsQuery.isFetching
        }
        title='Goals'
      />
      <CardContent>
        {!esaParticipantGoals.data?.length ||
        completedTransactionsQuery.isLoading ? (
          <CardPlaceholder
            data-testid='no-data-placeholder'
            subtitle='There are no goals'
          />
        ) : (
          <Box data-testid='goals-table' display='flex' flexGrow='1'>
            <DataGridPro
              autoHeight
              columnBuffer={6}
              columns={columns}
              disableColumnResize
              disableRowSelectionOnClick
              getDetailPanelHeight={() => 'auto'}
              getRowId={row => row.id}
              pageSizeOptions={[10]}
              pagination
              rows={esaParticipantGoals.data || []}
              sx={{
                '.MuiDataGrid-columnSeparator': {
                  display: 'none'
                },
                border: '0px !important'
              }}
            />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default ParticipantGoals;
