import Badge from '@/components/badge';
import CircularLoading from '@/components/circular-loading';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { Address } from '@/models/ParticipantInfo.model';
import ParticipantService from '@/services/Participant.service';
import formatters from '@/utils/Formatters';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { ParticipantAddVerificationServiceResultResponse } from 'scala-sdk';

type ParticipantAddressVerificationDialogProps = {
  address: Address;
  addressVerificationDetails: ParticipantAddVerificationServiceResultResponse;
  isVerified: boolean;
  open: boolean;
  onClose: () => void;
  participantId: number;
};

const StyledTooltip = styled(
  ({
    className,
    ...props
  }: TooltipProps & { isDeliverabilityTooltip?: boolean }) => (
    <Tooltip {...props} classes={{ popper: className }}>
      {props.children}
    </Tooltip>
  ),
  {
    shouldForwardProp: prop => prop !== 'isDeliverabilityTooltip'
  }
)(({ theme, isDeliverabilityTooltip }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffffff',
    border: '1px solid #dadde9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: isDeliverabilityTooltip ? 450 : 380,
    padding: theme.spacing(2)
  }
}));

export const DeliverabilityTooltip = (props: {
  isDeliverabilityTooltip?: boolean;
}) => (
  <StyledTooltip
    isDeliverabilityTooltip={props.isDeliverabilityTooltip}
    placement='right'
    title={
      <Stack spacing={1}>
        <Typography variant='body1'>Deliverability</Typography>
        <Stack
          divider={<Divider flexItem orientation='horizontal' />}
          spacing={2}>
          <Stack spacing={1}>
            <Badge color='success' maxWidth='fit-content' size='small'>
              Deliverable
            </Badge>
            <Typography variant='body2'>
              The address is deliverable by the USPS
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Badge color='warning' maxWidth='fit-content' size='small'>
              Deliverable Unnecessary Unit
            </Badge>
            <Typography variant='body2'>
              The address is deliverable, but the secondary unit information is
              unnecessary
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Badge color='warning' maxWidth='fit-content' size='small'>
              Deliverable Incorrect Unit
            </Badge>
            <Typography variant='body2'>
              The address is deliverable to the building's default address but
              the secondary unit provided may not exist. There is a chance the
              mail will not reach the intended recipient
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Badge color='warning' maxWidth='fit-content' size='small'>
              Deliverable Missing Unit
            </Badge>
            <Typography variant='body2'>
              The address is deliverable to the building's default address but
              is missing secondary unit information. There is a chance the mail
              will not reach the intended recipient
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Badge color='error' maxWidth='fit-content' size='small'>
              Undeliverable
            </Badge>
            <Typography variant='body2'>
              The address is not deliverable according to the USPS
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    }>
    <IconButton aria-label='Deliverability' color='default' size='small'>
      <InfoOutlinedIcon fontSize='small' />
    </IconButton>
  </StyledTooltip>
);

export const ConfidenceScoreTooltip = (props: {
  isDeliverabilityTooltip?: boolean;
}) => {
  return (
    <StyledTooltip
      isDeliverabilityTooltip={props.isDeliverabilityTooltip}
      placement='right'
      title={
        <Stack spacing={1}>
          <Typography variant='body1'>
            Confidence Score is the likelihood that an address is deliverable
            based on Lob’s mail delivery data to over half of US households.
          </Typography>
          <Stack
            divider={<Divider flexItem orientation='horizontal' />}
            spacing={2}>
            <Stack direction='row' spacing={3}>
              <Badge color='success' maxWidth='fit-content' size='small'>
                High
              </Badge>
              <Typography variant='body2'>
                Has a Lob confidence score greater than 70
              </Typography>
            </Stack>
            <Stack direction='row' spacing={1}>
              <Badge color='warning' maxWidth='fit-content' size='small'>
                Medium
              </Badge>
              <Typography variant='body2'>
                Has a Lob confidence score between 40 and 70
              </Typography>
            </Stack>
            <Stack direction='row' spacing={3.5}>
              <Badge color='error' maxWidth='fit-content' size='small'>
                Low
              </Badge>
              <Typography variant='body2'>
                Has a Lob confidence score less than 40
              </Typography>
            </Stack>
            <Stack direction='row' spacing={3.5}>
              <Badge color='neutral' maxWidth='fit-content' size='small'>
                N/A
              </Badge>
              <Typography variant='body2'>
                No reference data exists for this address
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      }>
      <IconButton aria-label='Confidence Level' color='default' size='small'>
        <InfoOutlinedIcon fontSize='small' />
      </IconButton>
    </StyledTooltip>
  );
};

export const ParticipantAddressVerificationDialog: FC<
  ParticipantAddressVerificationDialogProps
> = props => {
  const queryClient = useQueryClient();

  const verifyAddress = useMutation(
    ['ParticipantService.postVerifyAddress'],
    () => ParticipantService.postVerifyAddress([props.participantId]),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [
            'ParticipantService.getAddressVerification',
            props.participantId
          ]
        });
      }
    }
  );

  const onClose = useCallback(() => {
    props.onClose();
  }, []);

  const verifyData = useMemo(() => {
    const source = props.isVerified
      ? props.addressVerificationDetails
      : verifyAddress.data?.[0]?.verificationResult;

    const {
      isAddressValid = null,
      deliverability = null,
      confidenceScore = null,
      createdAt = null
    } = source || {};

    return {
      confidenceScore,
      createdAt,
      deliverability,
      isAddressValid
    };
  }, [
    verifyAddress.data?.verificationResult,
    props.addressVerificationDetails,
    props.isVerified
  ]);

  useEffect(() => {
    if (!props.isVerified) {
      verifyAddress.mutate();
    }
  }, [props.isVerified]);

  return (
    <Dialog
      data-testid='participantAddressVerificationDialog'
      fullWidth
      maxWidth='sm'
      onClose={onClose}
      open={props.open}>
      <DialogTitle>Address verification</DialogTitle>
      <DialogContent>
        <Stack
          divider={<Divider flexItem orientation='horizontal' />}
          spacing={2}>
          <Stack>
            {formatters.formatAddress(props.address).map((line, index) => (
              <Typography key={`${line}-${index}`} variant='body1'>
                {line}
              </Typography>
            ))}
          </Stack>
          {verifyAddress.isLoading ? (
            <CircularLoading />
          ) : verifyAddress.isError ? (
            <TextStack direction='column'>
              <TextStackItem>
                <TextLabel>Error</TextLabel>
                <TextValue>
                  Failed to verify address due to an issue with the LOB API
                </TextValue>
              </TextStackItem>
            </TextStack>
          ) : (
            <Stack spacing={2}>
              <Stack direction='row' spacing={7}>
                <Typography variant='body2'>Address Validity</Typography>
                <Badge
                  color={verifyData.isAddressValid ? 'success' : 'error'}
                  maxWidth='fit-content'
                  size='small'>
                  {verifyData.isAddressValid ? 'Valid' : 'Invalid'}
                </Badge>
              </Stack>
              <Stack alignItems='center' direction='row' spacing={5.5}>
                <Typography variant='body2'>
                  Deliverability{' '}
                  <DeliverabilityTooltip isDeliverabilityTooltip={true} />
                </Typography>
                <Badge
                  color={
                    verifyData.deliverability === 'deliverable'
                      ? 'success'
                      : verifyData.deliverability ===
                          'deliverable_unnecessary_unit'
                        ? 'warning'
                        : verifyData.deliverability ===
                            'deliverable_incorrect_unit'
                          ? 'warning'
                          : verifyData.deliverability ===
                              'deliverable_missing_unit'
                            ? 'warning'
                            : 'error'
                  }
                  maxWidth='fit-content'
                  size='small'>
                  {verifyData.deliverability === 'deliverable'
                    ? 'Deliverable'
                    : verifyData.deliverability ===
                        'deliverable_unnecessary_unit'
                      ? 'Deliverable Unnecessary Unit'
                      : verifyData.deliverability ===
                          'deliverable_incorrect_unit'
                        ? 'Deliverable Incorrect Unit'
                        : verifyData.deliverability ===
                            'deliverable_missing_unit'
                          ? 'Deliverable Missing Unit'
                          : 'Undeliverable'}
                </Badge>
              </Stack>
              <Stack alignItems='center' direction='row' spacing={2}>
                <Typography variant='body2'>
                  Confidence Level{' '}
                  <ConfidenceScoreTooltip isDeliverabilityTooltip={false} />
                </Typography>
                <Badge
                  color={
                    verifyData.confidenceScore > 70
                      ? 'success'
                      : verifyData.confidenceScore > 40 &&
                          verifyData.confidenceScore < 70
                        ? 'warning'
                        : verifyData.confidenceScore < 40
                          ? 'error'
                          : 'neutral'
                  }
                  maxWidth='fit-content'
                  size='small'>
                  {verifyData.confidenceScore > 70
                    ? 'High'
                    : verifyData.confidenceScore > 40 &&
                        verifyData.confidenceScore < 70
                      ? 'Medium'
                      : verifyData.confidenceScore < 40
                        ? 'Low'
                        : 'N/A'}
                </Badge>
              </Stack>
              <Stack direction='row' spacing={6.5}>
                <Typography variant='body2'>Verification Date</Typography>
                <Typography variant='body1'>
                  {formatters.formatFromIsoDateCustom(
                    verifyData.createdAt,
                    'MM/DD/YYYY, hh:mma'
                  )}
                </Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Done</Button>
      </DialogActions>
    </Dialog>
  );
};
