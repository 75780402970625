import AccessControl from '@/components/access-control/AccessControl.component';
import CardInfoField from '@/components/card-info-field';
import SimpleDropdown from '@/components/simple-dropdown';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { useDialog } from '@/contexts/DialogContext';
import { useUserToken } from '@/contexts/UserTokenContext';
import usePlan from '@/hooks/usePlan';
import {
  UpdatePlanStatusDto,
  usePlanStatusUpdate
} from '@/hooks/usePlanStatusUpdate';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import React from 'react';

interface PlanEmployerStatusCardProps {
  sponsorPlanId: number | string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    editButton: {
      color: blue[500],
      marginLeft: 'auto'
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    root: {
      paddingBottom: '15px',
      width: '100%'
    }
  })
);

const nonStatePlansItems = [
  { disabled: true, value: 'Abandoned' },
  { disabled: false, value: 'Active' },
  { disabled: false, value: 'Deconversion Requested' },
  { disabled: false, value: 'Deconverted' },
  { disabled: true, value: 'Deferred' },
  { disabled: false, value: 'OB Cancelled' },
  { disabled: false, value: 'Onboarding' },
  { disabled: false, value: 'Pending' },
  { disabled: false, value: 'Ready - Awaiting Effective Date' },
  { disabled: false, value: 'Terminated' },
  { disabled: false, value: 'Termination Requested' }
];

const statePlansItems = [
  { disabled: false, value: 'Active' },
  { disabled: false, value: 'Onboarding' },
  { disabled: false, value: 'Terminated' }
];

type Status =
  | 'Abandoned'
  | 'Active'
  | 'Deconversion Requested'
  | 'Deconverted'
  | 'Deferred'
  | 'Onboarding'
  | 'Pending'
  | 'Ready - Awaiting Effective Date'
  | 'Terminated'
  | 'Termination Requested';

const PlanEmployerStatusCard: React.FunctionComponent<
  PlanEmployerStatusCardProps
> = (props: PlanEmployerStatusCardProps) => {
  const { sponsorPlanId } = props;
  const { openDialog } = useDialog();
  const mutation = usePlanStatusUpdate();
  const { userHasValidToken } = useUserToken();
  const classes = useStyles();
  const { token } = useUserToken();

  // Will: Why do we not destructure this? Because typescript
  // Read: https://tkdodo.eu/blog/react-query-and-type-script typenarrowing :D
  const planQuery = usePlan<{
    status: Status;
    type: string;
    recordKeeperName: string;
  }>(sponsorPlanId, userHasValidToken, planDto => ({
    recordKeeperName: planDto.data.attributes.recordkeeper,
    status: planDto.data.attributes.status,
    type: planDto.data.attributes.type
  }));

  if (planQuery.isFetching) {
    return <p>Loading...</p>;
  }

  if (!planQuery.isSuccess) {
    return <p>Error</p>;
  }

  const isStateIRA =
    planQuery.isSuccess &&
    planQuery.data.type === 'State IRA' &&
    planQuery.data.recordKeeperName === 'Surpas (State IRA)';

  const isBNYUser = token?.tpaId === 42;

  const menuItems = isStateIRA
    ? isBNYUser
      ? statePlansItems?.filter(status => status.value !== 'Active')
      : statePlansItems
    : nonStatePlansItems;

  return (
    <Card
      className={classes.root}
      data-testid='plan-employer-status-card'
      elevation={0}
      variant='outlined'>
      <CardContent className='employer-status-card'>
        <Box
          alignItems='baseline'
          display='flex'
          flexDirection='row'
          width='100%'>
          <Box width='100%'>
            <Typography variant='h5'>
              {isStateIRA ? 'Employer Status' : 'Plan Status'}
            </Typography>
            <Box display='flex' flexDirection='row' width='100%'>
              <Box width='100%'>
                <CardInfoField
                  fieldName='Status'
                  fieldValue={planQuery.data.status || EMPTY_FIELD_PLACEHOLDER}
                />
              </Box>
            </Box>
          </Box>

          <AccessControl
            requiresOneOf={[
              FeatureLevelPermissions.WRITE_COMPANY_PLAN_STATUS,
              'user:write'
            ]}>
            <Button
              className={classes.editButton}
              data-testid='plan-employer-status-card-edit-button'
              onClick={() =>
                openDialog({
                  onSubmit: async values => {
                    const status = values.status as Status;
                    const dto: UpdatePlanStatusDto = {
                      data: {
                        attributes: {
                          status
                        },
                        id: Number(sponsorPlanId),
                        type: 'plan'
                      }
                    };
                    await mutation.mutateAsync(dto);
                  },
                  steps: [
                    {
                      fields: {
                        status: {
                          component: (
                            <SimpleDropdown
                              fieldId='status'
                              fieldName='Status'
                              fieldValues={menuItems}
                            />
                          ),
                          initialValue: planQuery.data.status,
                          label: 'status'
                        }
                      },
                      title: 'Edit Employer Status'
                    }
                  ]
                })
              }>
              Edit
            </Button>
          </AccessControl>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PlanEmployerStatusCard;
