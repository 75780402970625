import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';

export interface ParticipantHoursOfServiceDto {
  data: ParticipantHoursOfService[];
  links: {
    first: string;
    last: string;
    next?: string;
    self: string;
  };
  meta: {
    count: number;
  };
}

export interface ParticipantHoursOfService {
  employeeHoursTracker: number;
  hours: number | string;
  startDate: string;
  endDate: string;
  isOverwrite: boolean;
}

export interface ParticipantHoursToUpdateDto {
  data: ParticipantHoursToUpdate[];
}

interface ParticipantHoursToUpdate {
  employeeHoursTrackerId: number;
  hours: number;
  startDate: string;
  endDate: string;
}

export interface HoursOfServiceRecord {
  employeeHoursTrackerId: number;
  participantId: number;
  hours: number;
  ytdHours: number;
  overwrite: boolean;
  origin?: string;
  isMissedPreviousSubmission: boolean;
  updatedAt: string;
  startDate: string;
  endDate: string;
  payrollSetupId?: number;
  ucid?: string;
  fileTag?: string;
  sourceColumn: string;
  createdAt: string;
}

export interface UpdatedHoursOfServiceDto {
  data: HoursOfServiceRecord[];
}

export interface ParticipantHoursCreatedDto {
  data: HoursOfServiceRecord[];
}

export interface CreateParticipantHours {
  hours: number;
  startDate: string;
  endDate: string;
}

export const DefaultHoursOfServiceDto: ParticipantHoursOfServiceDto = {
  data: [
    {
      employeeHoursTracker: 1,
      endDate: EMPTY_FIELD_PLACEHOLDER,
      hours: EMPTY_FIELD_PLACEHOLDER,
      isOverwrite: false,
      startDate: EMPTY_FIELD_PLACEHOLDER
    }
  ],
  links: {
    first: EMPTY_FIELD_PLACEHOLDER,
    last: EMPTY_FIELD_PLACEHOLDER,
    self: EMPTY_FIELD_PLACEHOLDER
  },
  meta: {
    count: 0
  }
};

export interface ParticipantHoursTotalDto {
  sponsorPlanId: number;
  startDate: string;
  endDate: string;
  hoursPerParticipant: HoursPerParticipant[];
}

interface HoursPerParticipant {
  participantId: number;
  total: number;
  combinedHrs: CombinedHrs;
}

interface CombinedHrs {
  total: number;
  containsMissingData: boolean;
  hoursPerRanges: HoursPerRanges[];
}

interface HoursPerRanges {
  hoursWorked: number;
  cumulativeHoursWorked: number;
  startDate: string;
  endDate: string;
}
