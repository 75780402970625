import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { UsersItemDto } from '@/models/UsersDTO.model';
import UserManagementService from '@/services/UserManagement.service';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import React, { FC } from 'react';

interface DisableTwoFactorAuthModalProps {
  onDisable: () => void;
  user: UsersItemDto;
}

export const DisableTwoFactorAuthModal: FC<
  DisableTwoFactorAuthModalProps
> = props => {
  const { showSnackbar } = useSnackbar();
  const { closeDialog } = useDialog();

  const onDisableMfa = useMutation(
    ['UserManagementService.deleteMfa'],
    () => {
      return UserManagementService.deleteMfa(props.user.userId);
    },
    {
      onError: () => {
        showSnackbar({
          message: `Failed! Could not disable 2-Factor Authentication for ${props.user.email}.`,
          severity: 'error'
        });
      },
      onSuccess: () => {
        closeDialog();
        showSnackbar({
          message: `Successfully disabled 2-Factor Authentication for ${props.user.email}.`,
          severity: 'success'
        });
        props.onDisable();
      }
    }
  );

  return (
    <>
      <DialogTitle>Disable 2-Factor Authentication</DialogTitle>
      <DialogContent data-testid='disable-two-factor-auth-modal-content'>
        <Typography variant='body1'>
          Are you sure you want to disable 2-Factor Authentication for{' '}
          {props.user.email}? This action cannot be undone, only proceed if the{' '}
          <strong>user’s identity has been verified over a phone call.</strong>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button data-testid='cancel-btn' onClick={closeDialog} variant='text'>
          CANCEL
        </Button>
        <Button
          data-testid='disable-btn'
          disabled={onDisableMfa.isLoading}
          onClick={() => onDisableMfa.mutateAsync()}
          variant='text'>
          DISABLE
        </Button>
      </DialogActions>
    </>
  );
};
