import { CardPlaceholder } from '@/components/card';
import CircularLoading from '@/components/circular-loading';
import CollapsibleTable from '@/components/collapsible-table';
import { Position } from '@/models/ParticipantPositionsDTO.model';
import { alpha, Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GridColDef } from '@mui/x-data-grid-pro';

import clsx from 'clsx';
import Decimal from 'decimal.js';
import React, { useMemo } from 'react';

import Formatters from '../../../../utils/Formatters';
import PositionsTableCell from './PositionsTableCell.component';

const useStyles = makeStyles((theme: Theme) => ({
  assetClassCell: {
    width: 182
  },
  headerCell: {
    color: alpha(theme.palette.common.black, 0.6),
    fontSize: theme.spacing(1.75),
    fontWeight: 500,
    paddingBottom: theme.spacing(1.25),
    paddingTop: theme.spacing(1.25)
  },
  positionCell: {
    textAlign: 'right',
    width: 160
  },
  ratioCell: {
    textAlign: 'right',
    width: 124
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2)
  },
  weightCell: {
    textAlign: 'right',
    width: 114
  }
}));

interface PositionsTableProps {
  isError: boolean;
  isLoading: boolean;
  positionsData?: Position[];
  asOfDate?: string;
}

export enum PositionsFields {
  SECURITY = 'security',
  POSITION = 'position',
  WEIGHT = 'weight',
  EXPENSE_RATIO_PCT = 'expenseRatioPct',
  BROAD_ASSET_CLASS = 'broadAssetClass',
  ALLOCATION = 'allocation'
}

export interface PositionsRow {
  maxTickerLength: number;
  id: string;
  [PositionsFields.SECURITY]: {
    id: string;
    cusip: string;
    fundName: string;
  };
  [PositionsFields.POSITION]: {
    shares: string;
    balance: string;
  };
  [PositionsFields.WEIGHT]: string;
  [PositionsFields.EXPENSE_RATIO_PCT]: string;
  [PositionsFields.BROAD_ASSET_CLASS]: string;
}

const mapPositionsRow = (position: Position, maxTickerLength: number) => ({
  broadAssetClass: position.attributes.broadAssetClass,
  expenseRatioPct: `${new Decimal(position.attributes.expenseRatioPct).toFixed(
    2
  )}%`,
  id: position.id,
  maxTickerLength,
  position: {
    balance: Formatters.formatDollars(position.attributes.balance),
    shares: `${new Decimal(position.attributes.shares).toFixed(3)} Shares`
  },
  security: {
    cusip: position.attributes.cusip,
    fundName: position.attributes.fundName,
    id: position.id
  },
  weight: `${new Decimal(position.attributes.weight).toFixed(2)}%`
});

const PositionsTable: React.FunctionComponent<PositionsTableProps> = (
  props: PositionsTableProps
) => {
  const { isLoading, isError, positionsData = [], asOfDate } = props;
  const classes = useStyles();
  const positions = useMemo(() => {
    const maxTickerLength = Math.max(...positionsData.map(p => p.id.length));
    return positionsData
      .sort((p1, p2) => p2.attributes.balance - p1.attributes.balance)
      .map(p => mapPositionsRow(p, maxTickerLength));
  }, [positionsData]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        cellClassName: classes.headerCell,
        field: PositionsFields.SECURITY,
        headerName: 'Security'
      },
      {
        cellClassName: clsx(classes.headerCell, classes.positionCell),
        field: PositionsFields.POSITION,
        headerName: 'Position'
      },
      {
        cellClassName: clsx(classes.headerCell, classes.weightCell),
        field: PositionsFields.WEIGHT,
        headerName: 'Portion'
      },
      {
        cellClassName: clsx(classes.headerCell, classes.ratioCell),
        field: PositionsFields.EXPENSE_RATIO_PCT,
        headerName: 'Expense Ratio'
      },
      {
        cellClassName: clsx(classes.headerCell, classes.assetClassCell),
        field: PositionsFields.BROAD_ASSET_CLASS,
        headerName: 'Asset Class'
      }
    ],
    [classes]
  );

  if (isLoading) {
    return (
      <Box className={classes.root} textAlign='center'>
        <CircularLoading />
      </Box>
    );
  }

  if (isError) {
    return <Box className={classes.root}>Error retrieving positions</Box>;
  }

  if (!positionsData?.length) {
    return (
      <Box className={classes.root}>
        <CardPlaceholder
          data-testid='no-data-holdings'
          subtitle={
            asOfDate ? 'No holdings for the date selected' : 'No holdings'
          }
        />
      </Box>
    );
  }

  return (
    <Box flexGrow={1}>
      <CollapsibleTable
        backgroundPaperElevation={0}
        cellComponent={PositionsTableCell}
        columns={columns}
        data-testid='holdings-positions-table'
        disablePagination
        pager={{
          rowsPerPage: 100
        }}
        primaryKey='participantPositions'
        rootPaperElevation={0}
        tableData={positions}
        useSquareBottomContainer
      />
    </Box>
  );
};

export default PositionsTable;
