import { AgGridReactProps } from 'ag-grid-react';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import { useUpdateEffect } from 'react-use';
import * as yup from 'yup';

import { AgGrid } from './AgGrid.component';
import { AgGridEditorContext } from './AgGridEditorContext';
import { AgGridApi } from './AgGridTypes';

type CustomProps = AgGridReactProps & {
  onCellChanged(key: string, value: any, prevValue: any): void;
  primaryKey: string;
  validations: yup.SchemaOf<any>;
  initialRows?: any[];
  onError?(errors: Record<string, any>): void;
  'data-testid'?: string;
};

export const AgGridValidation = forwardRef<AgGridApi, CustomProps>(
  (props, ref) => {
    const $agGrid = useRef<any>(null);
    const [errors, setErrors] = useState<Record<string, any>>({});

    useImperativeHandle(ref, () => $agGrid.current);

    const gridContext = useMemo(
      () => ({
        errors,
        initialRows: props.initialRows,
        primaryKey: props.primaryKey
      }),
      [errors, props.primaryKey, props.initialRows]
    );

    const editorContext = useMemo(
      () => ({
        errors,
        initialRows: props.initialRows,
        primaryKey: props.primaryKey,
        setValue: props.onCellChanged
      }),
      [props.initialRows, props.primaryKey, errors, props.onCellChanged]
    );

    const getRowId = useCallback(
      params => params.data[props.primaryKey],
      [props.primaryKey]
    );

    useEffect(() => {
      props.validations
        ?.validate(props.rowData, { abortEarly: false })
        .then(() => setErrors({}))
        .catch((err: { inner: { path: string; errors: string[] }[] }) => {
          setErrors(
            err.inner.reduce((acc, current) => {
              return {
                ...acc,
                [current.path]: current.errors
              };
            }, {})
          );
        });
    }, [props.validations, props.rowData]);

    useUpdateEffect(() => {
      if (!props.onError) return;
      props.onError(errors);
    }, [errors]);

    useUpdateEffect(() => {
      $agGrid.current?.api?.refreshCells({ force: true });
    }, [gridContext.errors]);

    return (
      <AgGridEditorContext.Provider value={editorContext}>
        <AgGrid
          {...props}
          context={gridContext}
          getRowId={props.primaryKey ? getRowId : props.getRowId}
          ref={$agGrid}
        />
      </AgGridEditorContext.Provider>
    );
  }
);

AgGridValidation.defaultProps = {
  columnDefs: [],
  rowData: [],
  stopEditingWhenCellsLoseFocus: false
};

AgGridValidation.displayName = 'AgGridValidation';
