import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import ErrorMessage from '@/components/error-message';
import LinearLoading from '@/components/linear-loading';
import { useUserToken } from '@/contexts/UserTokenContext';
import { ParticipantInfo } from '@/models';
import { PlanV2Dto } from '@/models/PlanV2DTO.model';
import { RolloverResponseDto } from '@/models/RolloversDTO.model';
import ParticipantService from '@/services/Participant.service';
import { PlanService } from '@/services/Plan.service';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import React, { useMemo } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';

import ParticipantInfoCard from './ParticipantInfoCard.component';
import RolloverDeliveryInstructionsCard from './RolloverDeliveryInsructionsCard.component';
import RolloverDetailCard from './RolloverDetailCard.component';
import RolloverDetailsDataGridsCard from './RolloverDetailsDataGrids/RolloverDetailsDataGridsCard.component';

type RolloverDetailRouteProps = {
  participantId: string;
  rolloverId: string;
  custodianAccountNumber?: string;
};

const RolloverDetailRoute: React.FunctionComponent<
  RolloverDetailRouteProps
> = props => {
  const { participantId, rolloverId } = useParams<RolloverDetailRouteProps>();
  const { userHasValidToken } = useUserToken();
  const participantQuery = useQuery<ParticipantInfo>(
    // moshe: hard code the first part of the cache key for testing!
    // otherwise mocking services changes method name, which
    // causes tons of cache collisions and makes tests break :(
    ['ParticipantService.getParticipantById', participantId?.toString()],
    async () => {
      return ParticipantService.getParticipantById(participantId);
    },
    {
      enabled: Boolean(participantId && userHasValidToken),
      staleTime: Infinity
    }
  );

  const rolloverQuery = useQuery<RolloverResponseDto>(
    ['ParticipantService.getRollover', participantId, rolloverId],
    () => {
      return ParticipantService.getRollover(+participantId, +rolloverId);
    }
  );

  const participant = participantQuery.data || ({} as ParticipantInfo);

  const planQuery = useQuery<PlanV2Dto>(
    [
      'PlanService.getPlanById',
      participantQuery.data?.sponsorPlanId?.toString()
    ],
    () => {
      if (!participantQuery.data?.sponsorPlanId)
        throw new Error(`keep the compiler happy`);

      return PlanService.getPlanById(participantQuery.data.sponsorPlanId);
    },
    {
      enabled: Boolean(
        participantId &&
          userHasValidToken &&
          participantQuery.data?.sponsorPlanId
      ),
      staleTime: Infinity
    }
  );

  const { data: planDesignData } = useQuery(
    ['PlanService.getPlanDesignById', participantQuery.data?.sponsorPlanId],
    () => {
      if (!participantQuery.data?.sponsorPlanId)
        throw new Error(`keep the compiler happy`);

      return PlanService.getPlanDesignById(participantQuery.data.sponsorPlanId);
    },
    {
      enabled: Boolean(participantQuery.data?.sponsorPlanId),
      staleTime: Infinity
    }
  );

  const isVestwellSubaccounting = useMemo(
    () =>
      planQuery.data?.data.attributes.recordkeeper ===
      'Vestwell Sub-Accounting Platform',
    [planQuery.data?.data.attributes.recordkeeper]
  );

  const error = participantQuery.error || planQuery.error;

  if (error) {
    return redirectToErrorPage(error as Error);
  }

  const requestError =
    participantQuery.error || (planQuery.error as any | undefined);

  const isLoading = participantQuery.isFetching || planQuery.isFetching;
  const isSuccess = participantQuery.isSuccess && planQuery.isSuccess;

  return (
    <>
      {isLoading && <LinearLoading />}
      <Button
        component={RouterLink}
        startIcon={<ArrowBackOutlinedIcon />}
        to={`/participants/${participantId}/rollovers`}
        variant='text'>
        Back to Rollovers
      </Button>
      <Typography variant='h6'>Rollover Request ID: {rolloverId}</Typography>
      {isSuccess && (
        <Stack mt={2}>
          <Stack direction='row'>
            <Stack spacing={3}>
              <RolloverDetailCard
                participant={participant}
                recordkeeper={planQuery?.data?.data.attributes.recordkeeper}
                rollover={rolloverQuery?.data?.data}
              />
              {isVestwellSubaccounting && (
                <RolloverDeliveryInstructionsCard
                  custodianAccountNumber={props.custodianAccountNumber}
                  name={`${participant?.firstName} ${participant?.lastName}`}
                />
              )}
            </Stack>
            <ParticipantInfoCard
              custodianNumber={
                planDesignData?.data.recordkeeperAndCustodian
                  ?.custodianAccountNumber
              }
              participant={participant}
              sponsorPlan={planQuery?.data?.data}
            />
          </Stack>
          {isVestwellSubaccounting && (
            <Box sx={{ width: '100%' }}>
              <RolloverDetailsDataGridsCard />
            </Box>
          )}
        </Stack>
      )}
      {requestError && (
        <ErrorMessage
          error={requestError.message || 'An Unknown Error Occurred'}
        />
      )}
    </>
  );
};

export default RolloverDetailRoute;
