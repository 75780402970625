import ContributionService from '@/services/Contribution.service';
import formatters from '@/utils/Formatters';
import {
  Card,
  Divider,
  LinearProgress,
  Theme,
  Tooltip,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles } from '@mui/styles';
import { DataGridPro } from '@mui/x-data-grid-pro';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { snakeCase } from 'lodash';
import { useMemo } from 'react';
import type { FC } from 'react';
import * as uuid from 'uuid';

import { HEADER_NAMES } from '../consts';
import { NoRowsOverlay } from './NoRowsOverlay.component';

type AdjustmentsProps = {
  sponsorId: number;
  sponsorPlanId: number;
  ucid: string;
  search?: string;
  submissionInitiator?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  cellBorder: {
    borderRight: `1px solid ${theme.palette.grey[300]}`
  }
}));

export const Adjustments: FC<AdjustmentsProps> = props => {
  const classes = useStyles();

  const corrections = useQuery(
    [
      ContributionService.getContributionCorrections.name,
      props.ucid,
      props.sponsorId,
      props.sponsorPlanId
    ],
    () =>
      ContributionService.getContributionCorrections(
        { ucid: props.ucid },
        {
          sponsorId: props.sponsorId,
          sponsorPlanId: props.sponsorPlanId
        }
      ),
    {
      refetchOnMount: 'always'
    }
  );

  const columns = useMemo<GridColDef[]>(() => {
    const errorCodes = {
      ERR_CONTRIBUTIONS_FOR_INELIGIBLE_LTPT:
        'Employer contributions not expected for long-term part-time employees.',
      ERR_CONTRIBUTIONS_RC_BAD_FORMAT: 'Invalid contribution amount',
      ERR_DATE_MISMATCH_BIRTH_HIRE: 'Date of Hire must be after Date of Birth',
      ERR_ELIGIBILITY_CONTRIBUTIONS_BY_INELIG:
        'Eligibility requirements not met',
      ERR_ESA_FOREIGN_ADDRESS_NOT_ALLOWED: 'Foreign address is not allowed',
      ERR_ESA_UNKNOWN_EMPLOYEE_GRP: 'Unknown employee group',
      ERR_IDENTITY_BAD_SSN_FORMAT:
        'Improperly formatted social security number',
      ERR_IDENTITY_DUPLICATE_SSN: 'Duplicated social security number',
      ERR_IDENTITY_INVALID_SSN: 'Invalid social security number',
      ERR_IDENTITY_MISSING_NAMES: 'Missing a name',
      ERR_IDENTITY_MISSING_SSN: 'Missing a valid social security number',
      ERR_MISMATCHED_LOAN_PAYMENT: 'Mismatched loan repayment',
      ERR_STATE_IRA_OVER_IRS_LIMIT: 'Employee Contribution Limit',
      UNRECOGNIZED_EMPLOYEE: 'Unrecognized employee detected',
      WARN_CONTRIBUTIONS_INVALID_LOAN_REPAYMENT:
        'Participant has no active loans',
      WARN_CONTRIBUTIONS_IRS_LIMITS:
        'Employee and Employer Contribution Limits',
      WARN_CONTRIBUTIONS_UNESTABLISHED_NON_ROTH:
        'Unestablished non-Roth Account',
      WARN_CONTRIBUTIONS_UNESTABLISHED_ROTH: 'Unestablished Roth Account',
      WARN_EXCESSIVE_NEW_EMPLOYEES_AMOUNT: 'Excessive new employees amount'
    };

    return [
      {
        field: 'ssn',
        headerName: 'SSN',
        resizable: true,
        sortable: true,
        valueFormatter: params => {
          try {
            return formatters.maskSSN(params.value);
          } catch {
            return params.value;
          }
        },
        width: 150
      },
      {
        cellClassName: classes.cellBorder,
        field: 'name',
        headerName: 'Full Name',
        resizable: true,
        sortable: true,
        valueGetter: params =>
          params.row?.firstName && params.row?.lastName
            ? `${params.row?.firstName || ''} ${params.row?.lastName || ''}`
            : 'Unknown',
        width: 150
      },
      {
        field: 'reason',
        flex: 1,
        headerName: 'Reason',
        minWidth: 300,
        renderCell: params => (
          <div className='overflow-hidden'>
            <Tooltip placement='top' title={params.value}>
              {params.value}
            </Tooltip>
          </div>
        ),
        resizable: true,
        sortable: false,
        valueGetter: params =>
          params.row?.reason || errorCodes[params.row?.errorCode]
      },
      {
        align: 'right',
        field: 'reportedAmount',
        headerAlign: 'right',
        headerName: 'Reported',
        resizable: true,
        sortable: true,
        valueFormatter: params => formatters.formatDollars(params.value),
        width: 180
      },
      {
        align: 'right',
        field: 'processedAmount',
        headerAlign: 'right',
        headerName: 'Processed',
        resizable: true,
        sortable: true,
        valueFormatter: params => formatters.formatDollars(params.value),
        width: 180
      }
    ];
  }, []);

  const rows = useMemo(
    () =>
      corrections.data?.reduce((acc, correction) => {
        if (
          ![
            'ERR_MISMATCHED_LOAN_PAYMENT',
            'WARN_CONTRIBUTIONS_INVALID_LOAN_REPAYMENT'
          ].includes(correction.initialValue.errorCode) &&
          !['auto_update', 'delete'].includes(correction.correctionType)
        )
          return acc;

        const hasTotalSummary =
          ([
            'ERR_MISMATCHED_LOAN_PAYMENT',
            'WARN_CONTRIBUTIONS_INVALID_LOAN_REPAYMENT'
          ].includes(correction.initialValue.errorCode) &&
            correction.initialValue?.contributionAmount) ||
          (correction.initialValue?.contributionAmount &&
            (correction?.initialValue?.unallowedAmount ||
              correction?.initialValue?.employeeUnallowedAmount));

        const total = hasTotalSummary
          ? {
              corrected:
                correction.correctionValue?.errorCode ===
                'ERR_MISMATCHED_LOAN_PAYMENT'
                  ? +correction.autoCorrectionValue?.ln ||
                    +correction.correctionValue?.ln
                  : (+correction.autoCorrectionValue?.contributionAmount || 0) -
                    (+correction?.autoCorrectionValue?.unallowedAmount ||
                      +correction?.autoCorrectionValue
                        ?.employeeUnallowedAmount ||
                      0),
              initial: +correction.initialValue?.contributionAmount || 0
            }
          : [
              'rc',
              'rc_catchup',
              'sd',
              'sd_catchup',
              'sh',
              'ps',
              'em',
              'ln',
              'qm',
              'qc',
              'at',
              'loan_amt_1',
              'loan_amt_2',
              'loan_amt_3',
              'loan_amt_4',
              'loan_amt_5'
            ].reduce(
              (acc, header) => {
                acc.initial += +correction.initialValue[header] || 0;
                acc.corrected +=
                  correction.correctionType === 'auto_update'
                    ? +correction.autoCorrectionValue[header] || 0
                    : +correction.correctionValue[header] || 0;

                return acc;
              },
              { corrected: 0, initial: 0 }
            );

        if (
          !['System', 'Admin'].includes(props.submissionInitiator) &&
          correction.correctionType === 'delete' &&
          total.initial === 0
        )
          return acc;

        const row = {
          firstName:
            correction.correctionValue?.firstName ||
            correction.initialValue?.firstName,
          lastName:
            correction.correctionValue?.lastName ||
            correction.initialValue?.lastName,
          processedAmount: total.corrected,
          reportedAmount: total.initial,
          ssn: correction.correctionValue?.ssn || correction.initialValue?.ssn
        };

        if (correction.initialValue?.errorCode === 'DATA_UPDATE') {
          return [
            ...acc,
            ...Object.keys(correction.correctionValue)
              .filter(key => !['action', 'errorCode', 'rowNum'].includes(key))
              .map(key => ({
                id: uuid.v4(),
                ...row,
                reason: `${HEADER_NAMES[snakeCase(key)]} from ${
                  correction.initialValue?.[key] || '--'
                } to ${correction.correctionValue?.[key] || '--'}`
              }))
          ];
        }

        return [
          ...acc,
          {
            id: uuid.v4(),
            ...row,
            errorCode: correction.correctionValue?.errorCode,
            reason: correction.correctionValue?.reason
          }
        ];
      }, []) ?? [],
    [corrections.data, props.submissionInitiator]
  );

  return (
    <Card data-testid='adjustments' variant='outlined'>
      <Grid p={2}>
        <Typography data-testid='adjustments-header' variant='h6'>
          Contribution Adjustments ({rows?.length ?? 0})
        </Typography>
      </Grid>
      <Divider />
      <DataGridPro
        autoHeight
        columnBuffer={5}
        columns={columns}
        data-testid='adjustments-table'
        disableColumnMenu
        disableRowSelectionOnClick
        filterModel={{
          items: [{ field: 'name', operator: 'contains', value: props.search }]
        }}
        initialState={{
          pagination: {
            paginationModel: {
              page: 0,
              pageSize: 10
            }
          }
        }}
        loading={corrections.isLoading || corrections.isFetching}
        pageSizeOptions={[10, 25, 50, 100]}
        pagination
        paginationMode='client'
        rows={rows}
        slots={{
          loadingOverlay: LinearProgress,
          noRowsOverlay: NoRowsOverlay
        }}
        sx={{ '--DataGrid-overlayHeight': '200px' }}
      />
    </Card>
  );
};
