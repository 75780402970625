import DataTable, {
  DataTableProps
} from '@/components/data-table/DataTable.component';

import { ColumnState } from 'ag-grid-community';
import React, { useCallback, useState } from 'react';

export type InvestmentTableProps = Pick<
  DataTableProps,
  | 'gridRef'
  | 'context'
  | 'defaultColDef'
  | 'alignedGrids'
  | 'headerHeight'
  | 'columnDefs'
  | 'rowData'
  | 'onGridReady'
  | 'onCellEditRequest'
  | 'onRowDataUpdated'
>;

const InvestmentTable: React.FC<InvestmentTableProps> = props => {
  const {
    alignedGrids,
    columnDefs,
    context,
    defaultColDef,
    gridRef,
    headerHeight,
    onGridReady,
    onCellEditRequest,
    onRowDataUpdated,
    rowData
  } = props;

  const [sortState, setSortState] = useState<
    Pick<ColumnState, 'colId' | 'sort'>[]
  >([{ colId: 'symbol' }]);

  const onSortChanged = useCallback(params => {
    setSortState(params);
  }, []);

  return (
    <DataTable
      alignedGrids={alignedGrids}
      columnDefs={columnDefs}
      columnSizing='flex'
      context={context}
      defaultColDef={defaultColDef}
      enterNavigatesVerticallyAfterEdit
      gridRef={gridRef}
      headerHeight={headerHeight}
      onCellEditRequest={onCellEditRequest}
      onGridReady={onGridReady}
      onRowDataUpdated={onRowDataUpdated}
      onSortChanged={onSortChanged}
      pageSize={100}
      readOnlyEdit
      rowData={rowData}
      rowHeight={56}
      singleClickEdit
      sort={sortState}
      stopEditingWhenCellsLoseFocus
      suppressColumnVirtualisation
      suppressRowVirtualisation
    />
  );
};

export default InvestmentTable;
