import { FileUploadTableEditorContext } from '@/components/file-upload-table/FileUploadTableEditorContext';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { IconButton } from '@mui/material';

import { ICellRendererParams } from 'ag-grid-community';
import { useContext } from 'react';

export default (props: ICellRendererParams): JSX.Element => {
  const ctx = useContext(FileUploadTableEditorContext);

  return (
    <span>
      <IconButton
        aria-label='remove row'
        onClick={() => {
          if (ctx?.removeRow) {
            ctx?.removeRow({
              ...props,
              colDef: props.colDef ?? {},
              data: props.data
            });
          }
        }}
        sx={{ color: theme => theme.palette.grey[400] }}>
        <RemoveCircleOutlineIcon />
      </IconButton>
    </span>
  );
};
