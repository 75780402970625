import SimpleNumberField from '@/components/simple-number-field/SimpleNumberField';
import useShowMore from '@/hooks/useShowMore';
import { FundingSource } from '@/models/PlanDesign.model';
import {
  PlanParticipantInfoMapped,
  PlanParticipantsInfo
} from '@/models/PlanParticipantsDTO.model';
import { TestExecutionResultsDto } from '@/models/YearEndTestingDTO.model';
import { PlanService } from '@/services/Plan.service';
import formatters from '@/utils/Formatters';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { useFormikContext } from 'formik';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

type HceEmployeeLineProps = {
  index: number;
  removeLine: (employeeIndex: number) => void;
  fundingSources?: FundingSource[];
  label: string;
};

type HceEmployeeLineParams = {
  sponsorPlanId: string;
};

const emptyEmployeeOption = {
  id: undefined,
  name: undefined,
  ssn: undefined
};

const NUMBER_OF_SEARCH_RESULTS = 5;

const getOptionLabel = option =>
  option?.name ? `${option.name} (${option.ssn})` : '';

const formatValue = (value: string) =>
  Number.isNaN(+value) || value === ''
    ? value
    : formatters.formatDollars(value);

export const HceEmployeeLine: FC<HceEmployeeLineProps> = props => {
  const form = useFormikContext<TestExecutionResultsDto>();
  const [abortController, setAbortController] = useState(new AbortController());

  const { sponsorPlanId } = useParams<HceEmployeeLineParams>();

  const [employeeSearchKey] = useDebounce(
    form.values[props.label].additionalData.hce[
      props.index
    ].employeeInputValue.replace(/\([^)]*\)/i, ''),
    500
  );

  const employeeValue = useMemo(
    () =>
      form.values[props.label].additionalData.hce?.[props.index]?.id
        ? form.values[props.label].additionalData.hce[props.index]
        : null,
    [form.values[props.label].additionalData.hce?.[props.index]?.id]
  ); // to ensure referential stability between renders

  const employeesQuery = useShowMore<
    PlanParticipantsInfo,
    PlanParticipantInfoMapped
  >(
    [
      'PlanService.getParticipantsByPlanId',
      +sponsorPlanId,
      NUMBER_OF_SEARCH_RESULTS,
      ['lastName', 'name'],
      employeeSearchKey
    ],
    page =>
      PlanService.getParticipantsByPlanId({
        abortSignal: abortController.signal,
        pageNumber: page,
        pageSize: NUMBER_OF_SEARCH_RESULTS,
        planId: +sponsorPlanId,
        searchTerm: employeeSearchKey,
        sort: ['lastName', 'name']
      }),
    Boolean(sponsorPlanId),
    data => data.participants,
    data => Boolean(data.participants.length < data.count)
  );

  const handleEmployeeChange = useCallback(
    (event, newValue) => {
      abortController.abort();
      setAbortController(new AbortController());

      const value = newValue?.id
        ? { id: newValue.id, name: newValue.name, ssn: newValue.ssn }
        : newValue === null
          ? { ...emptyEmployeeOption, employeeInputValue: '' }
          : employeesQuery.data?.length
            ? {
                ...employeesQuery.data[0],
                employeeInputValue: getOptionLabel(employeesQuery.data[0])
              }
            : emptyEmployeeOption;

      form.setFieldValue(
        `${props.label}.additionalData.hce.${props.index}`,
        {
          ...form.values[props.label].additionalData.hce[props.index],
          ...value
        },
        true
      );
    },
    [employeesQuery.data]
  );

  const onAutocompleteBlur = useCallback(() => {
    if (employeeValue) {
      form.setFieldValue(
        `${props.label}.additionalData.hce.${props.index}.employeeInputValue`,
        getOptionLabel(employeeValue)
      );
    }
  }, [employeeValue]);

  const onAutocompleteInputChange = useCallback(
    (event, value) => {
      if (
        !form.values[props.label].additionalData.hce[props.index]
          ?.employeeInputValue
      ) {
        abortController.abort();
        setAbortController(new AbortController());
        form.setFieldValue(
          `${props.label}.additionalData.hce.${props.index}.employeeInputValue`,
          value
        );
      }
    },
    [abortController]
  );

  const fundingSources = useMemo(() => {
    if (['resultACP', 'resultADP'].includes(props.label)) {
      return props?.fundingSources?.filter(
        el => !['Safe Harbor', 'Profit Sharing'].includes(el.fundingSourceName)
      );
    }

    return props.fundingSources;
  }, [props.fundingSources, props.label]);

  const onFundingSourceChange = useCallback(event => {
    form.setFieldValue(
      `${props.label}.additionalData.hce.${props.index}.fundingSource`,
      event.target.value,
      true
    );
  }, []);

  const onValueChange = useCallback(value => {
    form.setFieldValue(
      `${props.label}.additionalData.hce.${props.index}.value`,
      value,
      true
    );
  }, []);

  const onEarningsChange = useCallback(value => {
    form.setFieldValue(
      `${props.label}.additionalData.hce.${props.index}.earnings`,
      value,
      true
    );
  }, []);

  const onCode1099Change = useCallback(e => {
    form.setFieldValue(
      `${props.label}.additionalData.hce.${props.index}.1099_codes`,
      e.target.value,
      true
    );
  }, []);

  const onLostGainChange = useCallback(value => {
    form.setFieldValue(
      `${props.label}.additionalData.hce.${props.index}.lostGain`,
      value,
      true
    );
  }, []);

  const onHceDelete = useCallback(() => props.removeLine(props.index), []);

  return (
    <Stack direction='row' width='100%'>
      <Grid container spacing={2} width='100%'>
        <Grid xs={4}>
          <FormControl
            error={Boolean(
              form.errors[props.label]?.additionalData?.hce?.[props.index]?.id
            )}
            fullWidth>
            <Autocomplete
              PaperComponent={props => (
                <Paper>
                  {props.children}
                  {!employeesQuery.data?.length &&
                    !employeesQuery.isLoading && (
                      <MenuItem>No participants</MenuItem>
                    )}
                </Paper>
              )}
              clearOnBlur={false}
              data-testid={`${props.label}-hce-name-${props.index}`}
              disablePortal
              filterOptions={option => option}
              forcePopupIcon
              freeSolo // to avoid console error after deleting hce array item
              getOptionLabel={getOptionLabel}
              inputValue={
                form.values[props.label].additionalData.hce[props.index]
                  .employeeInputValue
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              loading={employeesQuery.isLoading}
              onBlur={onAutocompleteBlur}
              onChange={handleEmployeeChange}
              onInputChange={onAutocompleteInputChange}
              options={employeesQuery.data || []}
              renderInput={renderParams => (
                <TextField
                  {...renderParams}
                  InputProps={{
                    ...renderParams.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {employeesQuery.isLoading ? (
                          <CircularProgress color='inherit' size={20} />
                        ) : null}
                        {renderParams.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                  error={Boolean(
                    form.errors[props.label]?.additionalData?.hce?.[props.index]
                      ?.id
                  )}
                  label='Name / SSN'
                />
              )}
              renderOption={(optionProps, option) => (
                <React.Fragment key={optionProps['data-option-index']}>
                  <Box component='li' {...optionProps}>
                    <Box>
                      <Typography>{option.name}</Typography>
                      <Typography color='textSecondary'>
                        SSN: ••• •• {option.ssn.slice(1)}
                      </Typography>
                    </Box>
                  </Box>
                  {optionProps['data-option-index'] ===
                    employeesQuery.data?.length - 1 &&
                    employeesQuery.isPaginated && (
                      <MenuItem onClick={employeesQuery.showMore}>
                        <Button
                          disabled={employeesQuery.isPaginationLoading}
                          key='showBtn'>
                          Show More
                        </Button>
                      </MenuItem>
                    )}
                </React.Fragment>
              )}
              size='small'
              value={employeeValue}
            />
            <FormHelperText>
              {form.errors[props.label]?.additionalData?.hce?.[props.index]?.id}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={2}>
          <FormControl
            error={Boolean(
              form.errors[props.label]?.additionalData?.hce?.[props.index]
                ?.fundingSource
            )}
            fullWidth
            size='small'>
            <InputLabel>Source</InputLabel>
            <Select
              data-testid={`${props.label}-hce-source-${props.index}`}
              label='Source'
              onChange={onFundingSourceChange}
              value={
                form.values[props.label].additionalData.hce[props.index]
                  .fundingSource || ''
              }>
              {!!fundingSources?.length &&
                fundingSources.map(option => {
                  return (
                    <MenuItem
                      key={option.fundingSourceId}
                      value={option.fundingSourceName}>
                      {option.fundingSourceName}
                    </MenuItem>
                  );
                })}
            </Select>
            <FormHelperText>
              {
                form.errors[props.label]?.additionalData?.hce?.[props.index]
                  ?.fundingSource
              }
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={2}>
          <FormControl
            error={Boolean(
              form.errors[props.label]?.additionalData?.hce?.[props.index]
                ?.value
            )}
            fullWidth>
            <SimpleNumberField
              allowNegative={false}
              error={Boolean(
                form.errors[props.label]?.additionalData?.hce?.[props.index]
                  ?.value
              )}
              format={formatValue}
              label={
                ['result415', 'result402g'].includes(props.label)
                  ? 'Base Refund'
                  : 'Value'
              }
              name='value'
              onChange={onValueChange}
              precision={2}
              size='small'
              testId={`${props.label}-hce-value-${props.index}`}
              value={
                form.values[props.label].additionalData.hce[props.index].value
              }
            />
            <FormHelperText>
              {
                form.errors[props.label]?.additionalData?.hce?.[props.index]
                  ?.value
              }
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={2}>
          <FormControl
            error={Boolean(
              form.errors[props.label]?.additionalData?.hce?.[props.index]
                ?.lostGain
            )}
            fullWidth>
            <SimpleNumberField
              allowNegative={true}
              error={Boolean(
                form.errors[props.label]?.additionalData?.hce?.[props.index]
                  ?.lostGain
              )}
              format={formatValue}
              label='Lost Gain'
              name='lostGain'
              onChange={onLostGainChange}
              precision={2}
              size='small'
              testId={`${props.label}-hce-lostGain-${props.index}`}
              value={
                form.values[props.label].additionalData.hce[props.index]
                  .lostGain
              }
            />
            <FormHelperText>
              {
                form.errors[props.label]?.additionalData?.hce?.[props.index]
                  ?.lostGain
              }
            </FormHelperText>
          </FormControl>
        </Grid>
        {['result415', 'result402g'].includes(props.label) && (
          <Grid xs={2}>
            <FormControl
              error={Boolean(
                form.errors[props.label]?.additionalData?.hce?.[props.index]
                  ?.earnings
              )}
              fullWidth>
              <SimpleNumberField
                allowNegative={false}
                error={Boolean(
                  form.errors[props.label]?.additionalData?.hce?.[props.index]
                    ?.earnings
                )}
                format={formatValue}
                label='Earnings'
                name='earnings'
                onChange={onEarningsChange}
                precision={2}
                size='small'
                testId={`${props.label}-hce-earnings-${props.index}`}
                value={
                  form.values[props.label].additionalData.hce[props.index]
                    .earnings
                }
              />
              <FormHelperText>
                {
                  form.errors[props.label]?.additionalData?.hce?.[props.index]
                    ?.earnings
                }
              </FormHelperText>
            </FormControl>
          </Grid>
        )}

        <Grid xs={4}>
          <FormControl
            error={Boolean(
              form.errors[props.label]?.additionalData?.hce?.[props.index]?.[
                '1099_codes'
              ]
            )}
            fullWidth>
            <TextField
              data-testId={`${props.label}-hce-1099_codes-${props.index}`}
              error={Boolean(
                form.errors[props.label]?.additionalData?.hce?.[props.index]?.[
                  '1099_codes'
                ]
              )}
              label='1099 Codes (comma-separated)'
              multiline
              onChange={onCode1099Change}
              placeholder='code1,code2,code3'
              size='small'
              value={
                form.values[props.label].additionalData.hce[props.index]?.[
                  '1099_codes'
                ]
              }
            />

            <FormHelperText>
              {
                form.errors[props.label]?.additionalData?.hce?.[props.index]?.[
                  '1099_codes'
                ]
              }
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <div>
        <IconButton
          data-testid={`${props.label}-delete-hce-${props.index}`}
          onClick={onHceDelete}>
          <RemoveCircleOutlineOutlinedIcon color='primary' />
        </IconButton>
      </div>
    </Stack>
  );
};

HceEmployeeLine.displayName = 'HceEmployeeLine';
