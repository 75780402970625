import { KeyboardArrowDown } from '@mui/icons-material';
import { Button, Menu, MenuItem } from '@mui/material';

import { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type EditProps = {
  ucid: string;
  planId: number;
};

export const Edit: FC<EditProps> = props => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const onOpen = useCallback(event => setAnchorEl(event.currentTarget), []);

  const onClose = useCallback(() => setAnchorEl(null), []);

  const onEdit = useCallback(
    () =>
      navigate(
        `/plans/${props.planId}/contributions/${props.ucid}/submission?isEdit=true`
      ),
    [props.planId, props.ucid]
  );

  const onUpload = useCallback(
    () =>
      navigate(`/plans/${props.planId}/contributions/${props.ucid}/submission`),
    [props.planId, props.ucid]
  );

  return (
    <>
      <Button
        data-testid='editButton'
        disableElevation
        endIcon={<KeyboardArrowDown />}
        onClick={onOpen}
        variant='outlined'>
        Edit
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom'
        }}
        data-testid='editMenu'
        onClose={onClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top'
        }}>
        <MenuItem data-testid='editMenu-uploadNewFileItem' onClick={onUpload}>
          Upload New File
        </MenuItem>
        <MenuItem data-testid='editMenu-editManuallyItem' onClick={onEdit}>
          Edit Manually
        </MenuItem>
      </Menu>
    </>
  );
};
