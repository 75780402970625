import { useSnackbar } from '@/contexts/SnackBarContext';
import TickerAutocomplete, {
  TickerOption
} from '@/routes/ops/investments/common/TickerAutocomplete.component';

import React, {
  forwardRef,
  SyntheticEvent,
  useCallback,
  useImperativeHandle,
  useState
} from 'react';

export interface TickerLookupSearchResult {
  symbol: string;
  cusip: string;
  assetClass: string;
  fundName: string;
  fundId: string;
  secId: string;
  securityProviderName: string;
  broadAssetClass: string;
  expenseRatio: number;
}

const NUMBER_OF_SEARCH_RESULTS = 5;

export type TickerSelectProps = {
  lookupTickerCallback: (
    ticker: string,
    numberOfResults: number
  ) => Promise<TickerLookupSearchResult[]>;
  onCommit: (params: {
    value?: any;
    id: number;
    goeAccountAssetClassId?: number;
    remove?: boolean;
  }) => void;
  rowIndex: number;
  value?: string;
  data?: {
    goeAccountAssetClassId: number;
  };
};

export const TickerSelect = forwardRef(
  (props: TickerSelectProps, ref): JSX.Element => {
    const {
      lookupTickerCallback,
      rowIndex,
      onCommit,
      value: initialValue
    } = props;
    const [tickerOptions, setTickerOptions] = useState<TickerOption[]>([]);
    const [fieldValue, setFieldValue] = useState<string | undefined>(
      initialValue || ''
    );
    const snackbar = useSnackbar();

    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the grid, on completion of editing
        getValue() {
          return fieldValue;
        },

        // Gets called once when editing is finished (eg if Enter is pressed).
        // If you return true, then the result of the edit will be ignored.
        isCancelAfterEnd() {
          if (!fieldValue) {
            onCommit({
              goeAccountAssetClassId: props.data?.goeAccountAssetClassId,
              id: rowIndex,
              remove: true,
              value: {
                symbol: initialValue
              }
            });
          }

          if (
            fieldValue &&
            fieldValue?.length >= 2 &&
            fieldValue.toUpperCase() !== (initialValue || '').toUpperCase()
          ) {
            const matchingOption = tickerOptions.find(
              o => o.symbol === fieldValue.toUpperCase()
            );

            if (matchingOption) {
              onCommit({
                goeAccountAssetClassId: props.data?.goeAccountAssetClassId,
                id: rowIndex,
                value: matchingOption
              });
            } else if (!initialValue) {
              snackbar.showSnackbar({
                message: `No matching ticker found for ${fieldValue.toUpperCase()}`,
                severity: 'error'
              });
            }
          }

          return true;
        }
      };
    });

    const handleChange = useCallback(
      (event: SyntheticEvent<Element, Event>, newValue: any) => {
        if (event !== null && newValue !== null) {
          onCommit({
            goeAccountAssetClassId: props.data?.goeAccountAssetClassId,
            id: rowIndex,
            value: props.data?.goeAccountAssetClassId
              ? {
                  ...newValue,
                  goeAccountAssetClassId: props.data?.goeAccountAssetClassId
                }
              : newValue
          });
        }
      },
      [onCommit, rowIndex, props.data?.goeAccountAssetClassId]
    );

    const loadOptions = useCallback(
      async (event: React.SyntheticEvent) => {
        const target = event.target as HTMLInputElement;

        if (target.value && target.value.length >= 2) {
          const results = await lookupTickerCallback(
            target.value,
            NUMBER_OF_SEARCH_RESULTS
          );

          const options = results
            .filter(t => t.cusip.length > 0) // TODO: remove when this is implemented in scala secmaster
            .slice(0, 5)
            .map<TickerOption>(r => {
              return r;
            });
          setTickerOptions(options);
        }
      },
      [lookupTickerCallback]
    );

    return (
      <TickerAutocomplete
        autoFocus
        inputValue={fieldValue}
        onBlur={loadOptions}
        onChange={handleChange}
        onInputChange={(event, value) => {
          loadOptions(event);
          setFieldValue(value);
        }}
        options={tickerOptions}
        ref={ref}
        sx={{
          backgroundColor: theme => theme.palette.common.white,
          width: 300
        }}
      />
    );
  }
);

TickerSelect.displayName = 'TickerSelect';

export default TickerSelect;
