import { SponsorDto } from '@/models';
import SponsorService from '@/services/Sponsor.service';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

export default function useSponsor<T = SponsorDto>(
  sponsorId: string,
  select?: (data: SponsorDto) => T
): UseQueryResult<T, unknown> {
  return useQuery(
    ['SponsorService.getSponsorById', sponsorId?.toString()],
    () => SponsorService.getSponsorById(sponsorId),
    {
      enabled: sponsorId !== undefined && sponsorId !== '0',
      select,
      staleTime: Infinity
    }
  );
}
