import {
  ExtendedColDef,
  ExtendedGroupColDef
} from '@/components/file-upload-table/FileUploadTable.component';
import { readFiles } from '@/components/simple-upload/SimpleUpload.component';
import { PlanService } from '@/services/Plan.service';
import { useMutation } from '@tanstack/react-query';

import { camelCase } from 'lodash';
import { useCallback, useMemo } from 'react';
import { read, utils } from 'xlsx';

import { convertFileToCsv } from './helpers/jsonToCsvConverter';

interface ColumnConf extends ExtendedColDef {
  field?: string;
  required: boolean;
  excelHeader: any;
  found: boolean;
}

export const useConversionFile = (
  onUpload: (data: Record<string, unknown>[]) => void,
  sponsorPlanId: number,
  conversionType: string,
  documentId?: number,
  columnDefs?: (ExtendedGroupColDef | ExtendedColDef)[]
): any => {
  const {
    mutateAsync: downloadDocument,
    isLoading,
    isSuccess
  } = useMutation(
    ['PlanService.getDocumentForPlanId', sponsorPlanId, documentId],
    () => {
      return PlanService.getDocumentForPlanId(sponsorPlanId, documentId).then(
        async response => {
          const csv = convertFileToCsv(
            response.base64Data,
            response.originalFileName,
            conversionType
          );

          const blob = new Blob([
            Buffer.from(csv.fileData, 'base64').toString('utf8')
          ]);
          const file = new File([blob], csv.fileName);
          return readFiles([file]);
        }
      );
    }
  );

  function isGroupColumn(
    column: ExtendedGroupColDef | ExtendedColDef
  ): column is ExtendedGroupColDef {
    return (column as ExtendedGroupColDef).children !== undefined;
  }

  const columnDefReducer = useCallback(
    (
      acc: Record<string, ExtendedColDef>,
      column: ExtendedGroupColDef | ExtendedColDef
    ): Record<string, ExtendedColDef> => {
      return {
        ...acc,
        ...(isGroupColumn(column)
          ? column.children.reduce(
              columnDefReducer,
              {} as Record<string, ExtendedColDef>
            )
          : { [column.field ?? '']: column })
      };
    },
    []
  );

  const columnsHash = useMemo(
    () =>
      columnDefs?.reduce(
        columnDefReducer,
        {} as Record<string, ExtendedColDef>
      ) ?? {},
    [columnDefs, columnDefReducer]
  );

  const handleUpload = useCallback(
    (data: any[], values) => {
      const rows = data.map(row => ({
        ...Object.keys(values).reduce(
          (acc, key) => ({
            ...acc,
            [key]:
              columnsHash[key].valueParser?.(row[values[key]]) ??
              row[values[key]]
          }),
          {}
        )
      }));

      onUpload(rows);
    },
    [columnsHash, onUpload]
  );

  const canContinue = useCallback((values, columns?: ColumnConf[]) => {
    const requiredFields = columns
      ? columns
          .filter((col: ColumnConf) => col.required)
          .map(({ field }) => field)
      : [];
    return requiredFields.every(field => field && values[field]);
  }, []);

  const onClicked = useCallback(async () => {
    try {
      const files = await downloadDocument();

      const { Sheets, SheetNames } = read(files[0].data, {
        cellDates: true,
        raw: true,
        type: 'binary'
      });

      const [headers, ...rawData]: any[] = utils.sheet_to_json(
        Sheets[SheetNames[0]],
        {
          dateNF: 'string',
          header: 1,

          raw: true,
          // Setting this to true will break zip codes
          // with leading zeros
          rawNumbers: false
        }
      );

      const columnDefCount = columnDefs?.reduce(
        (acc, col) => (isGroupColumn(col) ? acc + col.children.length : ++acc),
        0
      );

      if (headers && headers.length !== columnDefCount) {
        console.error(new Error('Invalid number of columns in file'));
      }

      const data = rawData.map(row => {
        return (Array.isArray(row) ? row : []).reduce((obj, value, index) => {
          if (value) {
            obj[headers[index]] = value;
          }
          return obj;
        }, {});
      });

      const headersHash = (Array.isArray(headers) ? headers : []).reduce(
        (hash, header) => {
          hash[camelCase(header).toLowerCase().trim()] = header;
          return hash;
        },
        {}
      );

      function parseToColumnConf(col: ExtendedColDef): ColumnConf {
        let excelHeader = headersHash[camelCase(col.field).toLowerCase()];

        if (!excelHeader && Array.isArray(col.alternates)) {
          for (let i = 0; i < col.alternates.length; i++) {
            const alternateHeader =
              headersHash[camelCase(col.alternates[i]).toLowerCase()];
            if (alternateHeader) {
              excelHeader = alternateHeader;
              break;
            }
          }
        }

        return {
          ...col,
          excelHeader,
          field: col.field,
          found: !!excelHeader,
          required: col.required ?? true
        } as ColumnConf;
      }

      const columns = columnDefs?.reduce<ColumnConf[]>((acc, column) => {
        const nombreTemp = isGroupColumn(column)
          ? column.children.map(val => parseToColumnConf(val))
          : [parseToColumnConf(column)];

        return [...acc, ...nombreTemp];
      }, []);

      const values = columns?.reduce(
        (acc, col) => ({
          ...acc,
          [col.field ?? '']: col.found ? col.excelHeader : ''
        }),
        {} as Record<string, any>
      );

      if (canContinue(values, columns)) {
        handleUpload(data, values);
        return;
      }
    } catch (e) {
      console.error('an error occurred while parsing a file [ csv, xlsx ]', e);
    }
  }, [downloadDocument, columnDefs, canContinue, handleUpload]);

  return { isLoading, isSuccess, onClicked };
};
