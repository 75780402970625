import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';
import HardshipWithdrawals from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/distribution-features/HardshipWithdrawals';

class StarterVestwellWorkplaceHardshipWithdrawals extends HardshipWithdrawals {
  convertToApiState = Helper.convertValueToApiState;

  output: string | undefined;

  constructor(allowHardshipWithdrawals: string | undefined) {
    super(['Yes - All Accounts', 'Yes - Selected Accounts', 'No']);
    this.output = allowHardshipWithdrawals;
  }
}

export default StarterVestwellWorkplaceHardshipWithdrawals;
