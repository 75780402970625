import Badge from '@/components/badge';
import { CellComponentProps } from '@/components/collapsible-table';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import formatters from '@/utils/Formatters';
import { Box, Link, TableCell, Typography } from '@mui/material';

import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export const ForfeitureHistoryTableCell: FC<CellComponentProps> = props => {
  let field = <Box fontSize='1rem'>{props.row[props.column.field]}</Box>;

  if (props.column.field === 'createdAt') {
    field = (
      <Box fontSize='1rem'>
        {formatters.formatFromIsoDateCustom(props.row.createdAt, 'MM/DD/YYYY')}
      </Box>
    );
  }

  if (props.column.field === 'category') {
    field = (
      <Box fontSize='1rem'>
        {props.row.forfeitedBalance?.participantId ? (
          <Link
            component={RouterLink}
            to={`/participants/${props.row.forfeitedBalance?.participantId}/personal`}>
            {`Forfeited Amount from ${props.row.forfeitedBalance?.participantLastName}, 
              ${props.row.forfeitedBalance?.participantFirstName}`}
          </Link>
        ) : props.row.fundingContribution ? (
          props.row.fundingContribution.ucid ? (
            <Link
              component={RouterLink}
              to={`/plans/${props.row.sponsorPlanId}/contributions/${props.row.fundingContribution.ucid}/overview`}>
              {`Funding ${
                props.row.fundingContribution.expectedPayDate
                  ? `${formatters.formatFromIsoDateCustom(
                      props.row.fundingContribution.expectedPayDate,
                      'MM/DD/YYYY'
                    )} `
                  : ''
              }Contribution`}
            </Link>
          ) : (
            'Funding Contribution'
          )
        ) : props.row.payingFees ? (
          <>
            Funding{' '}
            {props.row.payingFees?.purpose === 'SponsorFees'
              ? 'Sponsor Fees'
              : props.row.payingFees?.purpose === 'PlanFees'
                ? 'Plan Fees'
                : props.row.payingFees?.purpose === 'ManagedAccountFees'
                  ? 'Managed Account Fees'
                  : 'Sponsor Fee'}
            {(props.row.payingFees?.periodStartDate ||
              props.row.payingFees?.periodEndDate) && (
              <Typography variant='body2'>
                {props.row.payingFees?.periodStartDate
                  ? `For the period ${formatters.formatFromIsoDateCustom(
                      props.row.payingFees.periodStartDate,
                      'MM/DD/YYYY'
                    )} - ${formatters.formatFromIsoDateCustom(
                      props.row.payingFees.periodEndDate,
                      'MM/DD/YYYY'
                    )}`
                  : `For period ending ${formatters.formatFromIsoDateCustom(
                      props.row.payingFees.periodEndDate,
                      'MM/DD/YYYY'
                    )}`}
              </Typography>
            )}
          </>
        ) : props.row.actionType === 'PlanLiquidation' ? (
          'Plan Liquidation'
        ) : props.row.transactionTypeCode === 'MigrationDeposit' ? (
          'Migration Deposit'
        ) : props.row.transactionTypeCode === 'PlanDeconversionWithdrawal' ? (
          'Plan Deconversion'
        ) : props.row.transactionTypeCode === 'CashConversationDeposit' ? (
          'Plan Conversion Deposit'
        ) : props.row.amount <= 0 ? (
          'Money Out'
        ) : (
          'Money In'
        )}
      </Box>
    );
  }

  if (props.column.field === 'amount') {
    field = (
      <Box fontSize='1rem'>{formatters.formatDollars(props.row.amount)}</Box>
    );
  }

  if (props.column.field === 'status') {
    field = (
      <Badge color={props.row.status === 'CONFIRMED' ? 'success' : 'neutral'}>
        {props.row.status}
      </Badge>
    );
  }

  if (props.column.field === 'tracerId') {
    field = (
      <Box fontSize='1rem'>{props.row.tracerId || EMPTY_FIELD_PLACEHOLDER}</Box>
    );
  }

  return (
    <TableCell component='th' scope='row'>
      <Box>{field}</Box>
    </TableCell>
  );
};
