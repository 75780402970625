import { useField, useFormikContext } from 'formik';
import { FC, useEffect } from 'react';

const EligibilityFeaturesObserver: FC<{
  data: Record<string, any>;
}> = ({ data }: { data: Record<string, any> }) => {
  const { values }: { values: Record<string, any> } = useFormikContext();

  const [, , lengthOfEmploymentRequiredHelpers] = useField(
    'lengthOfEmploymentRequired'
  );
  const [, , hoursRequiredHelpers] = useField('hoursRequired');
  const [, , specialParticipationDateHelpers] = useField(
    'specialParticipationDate'
  );
  const [, , specialEntryDateHelpers] = useField('specialEntryDate');

  useEffect(() => {
    if (
      ['No Requirement', 'Specified Hours of Service'].includes(
        values.serviceCalculationType
      ) &&
      values.lengthOfEmploymentRequired !== 'No Requirement'
    ) {
      lengthOfEmploymentRequiredHelpers.setValue('No Requirement');
    } else if (
      ['One (1) Year - Hours of Service', 'State IRA Eligibility'].includes(
        values.serviceCalculationType
      ) &&
      values.lengthOfEmploymentRequired !== '12 months'
    ) {
      lengthOfEmploymentRequiredHelpers.setValue('12 months');
    }

    data.lengthOfEmploymentRequired.setDisabled(
      !values.serviceCalculationType ||
        [
          'No Requirement',
          'Specified Hours of Service',
          'One (1) Year - Hours of Service',
          'State IRA Eligibility'
        ].includes(values.serviceCalculationType)
    );

    data.hoursRequired.setDisabled(
      !values.serviceCalculationType ||
        [
          'No Requirement',
          'Specified Months - Elapsed Time',
          'State IRA Eligibility'
        ].includes(values.serviceCalculationType)
    );

    if (values.hoursRequired + '' === '') {
      hoursRequiredHelpers.setValue('');
    } else if (
      [
        'No Requirement',
        'Specified Months - Elapsed Time',
        'State IRA Eligibility'
      ].includes(values.serviceCalculationType) &&
      values.hoursRequired !== 0
    ) {
      hoursRequiredHelpers.setValue(0);
    }

    if (values.specialParticipationDate === 'Invalid Date') {
      specialParticipationDateHelpers.setValue('');
    }

    if (values.specialEntryDate === 'Invalid Date') {
      specialEntryDateHelpers.setValue('');
    }
  }, [
    data,
    values,
    lengthOfEmploymentRequiredHelpers,
    hoursRequiredHelpers,
    specialParticipationDateHelpers,
    specialEntryDateHelpers
  ]);

  return null;
};

export default EligibilityFeaturesObserver;
