import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class EarlyRetirementAge {
  convertToApiState = Helper.convertValueFromNumericStringToFloat;

  disabled: boolean;

  setDisabled = (value: boolean): void => {
    this.disabled = value;
  };

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.disabled = !['Age Only', 'Later of Age and Service'].includes(
      props.earlyRetirementAgeType
    );
    this.output = props.earlyRetirementAge?.toString();
  }
}

export default EarlyRetirementAge;
