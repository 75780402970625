import { CardHeader } from '@/components/card';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useSearchPendingTransactionsQuery } from '@/hooks/ops/useSearchPendingTransactionsQuery.hook';
import { ConfirmedTransactionSearchRequest } from '@/models/ops/transactions/ConfirmedTransactionSearchRequest.model';
import {
  PendingTransactionSearchByPlanIdRequest,
  PendingTransactionSearchRequest
} from '@/models/ops/transactions/PendingTransactionSearchRequest.model';
import { TransactionStatus } from '@/models/ops/transactions/TransactionStatus.model';
import { PaginatedApiResponse } from '@/models/PaginatedApiResponse.model';
import formatters from '@/utils/Formatters';
import { useUrlStateParams } from '@/utils/Url';
import DownloadIcon from '@mui/icons-material/Download';
import { Card } from '@mui/material';
import { UseQueryResult } from '@tanstack/react-query';
import { AccountLevel } from '@vestwell-sub-accounting/models/accountsAndLedgers/AccountLevel';
import { PositionDateType } from '@vestwell-sub-accounting/models/accountsAndLedgers/PositionDateType';
import { ConfirmedTransactionStatus } from '@vestwell-sub-accounting/models/common/ConfirmedTransactionStatus';
import { PendingTransactionStatus } from '@vestwell-sub-accounting/models/common/PendingTransactionStatus';

import dayjs from 'dayjs';
import { FC, useCallback, useMemo, useRef } from 'react';
import { useToggle } from 'react-use';

import { ConfirmedTransactions } from './ConfirmedTransactions.component';
import { TransactionsProps } from './models/TransactionsProps.model';
import { PendingTransactions } from './PendingTransactions.component';

export const Transactions: FC<TransactionsProps> = ({ planId, ...props }) => {
  const exportTransactionsRef = useRef<() => Promise<void>>();
  const queryRef = useRef<UseQueryResult<PaginatedApiResponse<[]>>>();
  const { showSnackbar } = useSnackbar();
  const [isQueryFetching, setIsQueryFetching] = useToggle(false);
  const [isExporting, toggleIsExporting] = useToggle(false);

  const [accountLevel, setAccountLevel] = useUrlStateParams<AccountLevel>(
    props.accountLevel || AccountLevel.ParentAccount,
    'accountLevel',
    value => value,
    value => value as AccountLevel
  );

  const [page, setPage] = useUrlStateParams(
    1,
    'page',
    value => (!props.preventSearchInUrl ? String(value) : ''),
    value => (!isNaN(Number(value)) ? Number(value) : 1)
  );

  const [pageSize, setPageSize] = useUrlStateParams(
    25,
    'pageSize',
    value => (!props.preventSearchInUrl ? String(value) : ''),
    value => (!isNaN(Number(value)) ? Number(value) : 25)
  );

  const [transactionStatus, setTransactionStatus] =
    useUrlStateParams<TransactionStatus>(
      TransactionStatus.Confirmed,
      'transactionStatus',
      value => value,
      value => value as TransactionStatus
    );

  const initialConfirmedSearchParams: Partial<ConfirmedTransactionSearchRequest> =
    {
      dateType: PositionDateType.Trade,
      endingDate: dayjs().format('YYYY-MM-DD'),
      ...props.customDefaultFilters, // overwrites the above defaults
      statuses: (props.customDefaultFilters
        ?.statuses as ConfirmedTransactionStatus[]) || [
        ConfirmedTransactionStatus.Confirmed
      ]
    };

  const initialPendingSearchParams: Partial<PendingTransactionSearchRequest> = {
    dateType: PositionDateType.Trade,
    endingDate: dayjs().format('YYYY-MM-DD'),
    ...props.customDefaultFilters, // overwrites the above defaults
    statuses: (props.customDefaultFilters
      ?.statuses as PendingTransactionStatus[]) || [
      PendingTransactionStatus.Pending
    ]
  };

  const initialSearchParams =
    transactionStatus === TransactionStatus.Confirmed
      ? initialConfirmedSearchParams
      : initialPendingSearchParams;

  const [searchParams, setSearchParams] = useUrlStateParams(
    initialSearchParams,
    'transactions',
    value => {
      if (props.preventSearchInUrl) {
        return '';
      }
      // remove any values that are the same as the default
      const cleanedQuery = formatters.objectDiff<
        | Partial<ConfirmedTransactionSearchRequest>
        | Partial<PendingTransactionSearchRequest>
      >(value, initialSearchParams);
      return JSON.stringify(cleanedQuery) !== '{}' // only add query if it's not empty
        ? encodeURIComponent(JSON.stringify(cleanedQuery))
        : '';
    },
    value => {
      try {
        const urlQuery = JSON.parse(decodeURIComponent(value));
        return {
          ...initialSearchParams,
          ...urlQuery
        };
      } catch {
        return initialSearchParams;
      }
    }
  );

  const pendingTransactionsQuery = useSearchPendingTransactionsQuery(
    planId
      ? ({
          accountLevel,
          dateType: PositionDateType.Trade,
          planId,
          statuses: [PendingTransactionStatus.Pending]
        } as PendingTransactionSearchByPlanIdRequest)
      : {
          accountId: props.accountId,
          accountLevel,
          dateType: PositionDateType.Trade,
          statuses: [PendingTransactionStatus.Pending]
        }
  );

  const handleExportTransactions = useCallback(async () => {
    toggleIsExporting();

    try {
      await exportTransactionsRef.current();
    } catch (err) {
      showSnackbar({
        message: `Failed to export transactions: ${err.message}`,
        severity: 'error'
      });
    }

    toggleIsExporting();
  }, [exportTransactionsRef]);

  const cardHeaderToggles = useMemo(
    () =>
      [
        props.hideAccountLevelToggle
          ? undefined
          : {
              'data-testid': 'account-level',
              onChangeAction: (
                event: React.MouseEvent<HTMLElement>,
                value: AccountLevel
              ) => {
                setAccountLevel(value);
                setPage(1);
              },
              options: [
                {
                  label: 'Parent',
                  value: AccountLevel.ParentAccount
                },
                { label: 'Sub', value: AccountLevel.SubAccount }
              ],
              value: accountLevel
            },
        {
          'data-testid': 'status',
          onChangeAction: (
            event: React.MouseEvent<HTMLElement>,
            value: TransactionStatus
          ) => {
            setTransactionStatus(value);
            setPage(1);

            if (value === TransactionStatus.Confirmed) {
              setSearchParams({
                ...searchParams,
                statuses: initialConfirmedSearchParams.statuses
              });
            } else {
              setSearchParams({
                ...searchParams,
                statuses: initialPendingSearchParams.statuses
              });
            }
          },
          options: [
            {
              label: 'Confirmed',
              value: TransactionStatus.Confirmed
            },
            {
              BadgeProps: {
                badgeContent: pendingTransactionsQuery.data?.pagination.total
              },
              disabled:
                pendingTransactionsQuery.data === undefined ||
                pendingTransactionsQuery.data.pagination === undefined ||
                pendingTransactionsQuery.data?.pagination.total === 0,
              label: 'Pending',
              value: TransactionStatus.Pending
            }
          ],
          value: transactionStatus
        }
      ].filter(Boolean),
    [props.hideAccountLevelToggle, pendingTransactionsQuery.data]
  );

  return (
    <Card data-testid={props['data-testid']}>
      {!props.hideHeader && (
        <CardHeader
          actionButtonsProps={[
            {
              ['data-testid']: 'export-csv-button',
              disabled:
                exportTransactionsRef.current === undefined ||
                queryRef.current?.data?.results === undefined ||
                queryRef.current.data.results.length === 0,
              label: 'Export CSV',
              loading: isExporting,
              onClick: handleExportTransactions,
              startIcon: <DownloadIcon />
            }
          ]}
          data-testid='transactions-header'
          loading={queryRef.current?.data?.results && isQueryFetching}
          title='Transactions'
          toggles={cardHeaderToggles}
        />
      )}
      {transactionStatus === TransactionStatus.Confirmed && (
        <ConfirmedTransactions
          {...props}
          exportTransactionsRef={exportTransactionsRef}
          page={page}
          pageSize={pageSize}
          queryRef={queryRef}
          searchParams={searchParams as ConfirmedTransactionSearchRequest}
          setIsQueryFetching={setIsQueryFetching}
          setPage={setPage}
          setPageSize={setPageSize}
          setSearchParams={setSearchParams}
          {...(planId
            ? {
                accountLevel: AccountLevel.SubAccount,
                planId
              }
            : {
                accountLevel
              })}
        />
      )}
      {transactionStatus === TransactionStatus.Pending && (
        <PendingTransactions
          {...props}
          exportTransactionsRef={exportTransactionsRef}
          page={page}
          pageSize={pageSize}
          queryRef={queryRef}
          searchParams={searchParams as PendingTransactionSearchRequest}
          setIsQueryFetching={setIsQueryFetching}
          setPage={setPage}
          setPageSize={setPageSize}
          setSearchParams={setSearchParams}
          {...(planId
            ? {
                accountLevel: AccountLevel.SubAccount,
                planId
              }
            : {
                accountLevel
              })}
        />
      )}
    </Card>
  );
};
