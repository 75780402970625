import AccessControl from '@/components/access-control/AccessControl.component';
import { FromFields } from '@/components/form-fields/FormFields.component';
import { useGetPlanScheduledChanges } from '@/components/scheduled-changes/useGetPlanScheduledChanges';
import { COMPANY_ENTITY_TYPES } from '@/consts/sponsor.constants';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUserToken } from '@/contexts/UserTokenContext';
import usePayrollFundingInfo from '@/hooks/usePayrollFundingInfo';
import useSponsor from '@/hooks/useSponsor';
import useSponsorExemption from '@/hooks/useSponsorExemption';
import { PlanV2Dto, UpdateSponsorDto } from '@/models';
import PlanPayrollSetupInfo from '@/models/PlanPayrollSetupInfo.model';
import { ScheduledChange } from '@/models/ScheduledChangesDTO.model';
import {
  SponsorAddress,
  SponsorPrimaryContact
} from '@/models/SponsorDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { PlanService } from '@/services/Plan.service';
import SponsorService from '@/services/Sponsor.service';
import formatters from '@/utils/Formatters';
import makeValidationSchema from '@/utils/validations/CompanyInfoValidationSchema.schema';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import MarkunreadMailboxOutlinedIcon from '@mui/icons-material/MarkunreadMailboxOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';

import CardIconInfoField from '../../../../components/card-icon-info-field/CardIconInfoField.component';
import { EditScheduleChangeDialog } from '../../../../components/scheduled-changes/EditScheduleChangeDialog.component';
import {
  editCompanyInfoFormToScheduleChangesPayload,
  formValuesToAttributesSponsorDto,
  getFormDialogStep,
  getInitialValues
} from './utils';

type CompanyInfoTabProps = {
  sponsorId: string | number;
  sponsorPlanId: string | number;
  isStateIRA: boolean;
  isVoyaPlan: boolean;
};

export type CompanyInfoTabData = {
  exemptionId: number;
  firmId: number;
  alternateEmployerId?: string;
  name: string;
  doingBusinessAs: string;
  address: SponsorAddress;
  physicalAddress?: SponsorAddress;
  mailingAddress?: SponsorAddress;
  phone?: string;
  email: string;
  ein?: string;
  primaryContact: SponsorPrimaryContact;
  companyEntityType: string;
  fiscalYearEnd: string;
  stateOfIncorporation: string;
  isWiredAtWork: boolean | null;
  isPartOfControlGroup: boolean | null;
};

const useStyles = makeStyles(() => ({
  editButton: {
    marginLeft: 'auto',
    padding: '0px'
  },
  secondaryInfoField: {
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '1.8rem',
    margin: '0 0 10px 0'
  }
}));

const CompanyInfoTab: React.FunctionComponent<CompanyInfoTabProps> = props => {
  const classes = useStyles();
  const { userHasValidToken } = useUserToken();
  const isVwPlan = !props.isStateIRA && !props.isVoyaPlan;
  const { showSnackbar } = useSnackbar();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const queryClient = useQueryClient();

  const scheduledChanges = useGetPlanScheduledChanges({
    planId: +props.sponsorPlanId
  });
  const scheduledChangesHistory = useGetPlanScheduledChanges({
    applied: true,
    enabled: false,
    planId: +props.sponsorPlanId
  });

  const payrollSetupsQuery = useQuery<PlanPayrollSetupInfo[]>(
    ['PlanService.getPlanPayrollSetups', props.sponsorPlanId],
    () => {
      return PlanService.getPlanPayrollSetups(props.sponsorPlanId);
    },
    {
      enabled: Boolean(userHasValidToken && props.sponsorPlanId),
      staleTime: Infinity
    }
  );

  const sponsorPlanQuery = useQuery<PlanV2Dto>(
    ['PlanService.getPlanById', props.sponsorPlanId?.toString()],
    () => {
      return PlanService.getPlanById(props.sponsorPlanId);
    },
    {
      enabled: userHasValidToken && Boolean(props.sponsorPlanId)
    }
  );

  const sponsorQuery = useSponsor<CompanyInfoTabData>(
    props.sponsorId.toString(),
    sponsorDto => ({
      address: sponsorDto.data.attributes.physicalAddress,
      alternateEmployerId: sponsorDto.data.attributes.alternateEmployerId,
      companyEntityType: sponsorDto.data.attributes.companyEntityType,
      doingBusinessAs: sponsorDto.data.attributes.doingBusinessAs,
      ein: sponsorDto.data.attributes.ein,
      email: sponsorDto.data.attributes.primaryContact.email,
      exemptionId: sponsorDto.data.relationships.exemption?.data.id,
      firmId: sponsorDto.data.relationships.firm?.data.id,
      fiscalYearEnd: sponsorDto.data.attributes.fiscalYearEnd,
      isPartOfControlGroup: sponsorDto.data.attributes.isPartOfControlGroup,
      isWiredAtWork: sponsorDto.data.attributes.isWiredAtWork,
      mailingAddress: sponsorDto.data.attributes.mailingAddress,
      name: sponsorDto.data.attributes.name,
      phone: sponsorDto.data.attributes.companyPhoneNumber,
      physicalAddress: sponsorDto.data.attributes.physicalAddress,
      primaryContact: sponsorDto.data.attributes.primaryContact,
      stateOfIncorporation: sponsorDto.data.attributes.stateOfIncorporation
    })
  );

  const payrollFundingInfoQuery = usePayrollFundingInfo<{
    surpasAccountNumber: string | undefined;
  }>(
    props.isStateIRA && payrollSetupsQuery.isSuccess
      ? props.sponsorPlanId.toString()
      : 0,
    payrollFundingInfoDto => ({
      surpasAccountNumber: payrollFundingInfoDto.employerReferenceNumber
    })
  );

  const sponsorExemptionQuery = useSponsorExemption(
    sponsorQuery.data?.exemptionId,
    sponsorExemptionDTO => ({
      canceledAt: sponsorExemptionDTO.data.attributes.canceledAt,
      createdAt: sponsorExemptionDTO.data.attributes.createdAt,
      exemptionCode:
        sponsorExemptionDTO.data.attributes.exemptionConfirmationCode,
      reason: sponsorExemptionDTO.data.attributes.reason,
      signatureName: sponsorExemptionDTO.data.attributes.signatureName
    })
  );

  const formDialogStep = useMemo(
    () =>
      sponsorQuery.data
        ? getFormDialogStep(
            sponsorQuery.data,
            props.isVoyaPlan,
            props.isStateIRA
          )
        : { fields: {}, title: 'Edit Company Info' },
    [sponsorQuery.data]
  );
  const initialValues = useMemo(
    () => getInitialValues(formDialogStep.fields),
    [formDialogStep]
  );

  const createScheduleChange = useMutation(
    ['SponsorService.createScheduleChanges'],
    (params: Omit<ScheduledChange, 'createdAt' | 'createdBy' | 'id'>) => {
      return SponsorService.createScheduleChange(props.sponsorId, params);
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Error!',
          severity: 'error'
        });
      },
      onSuccess: () => {
        scheduledChanges.invalidate('company');

        showSnackbar({ message: 'Success!', severity: 'success' });
        setEditDialogOpen(false);
      }
    }
  );

  const updateSponsor = useMutation(
    ['SponsorService.updateSponsor'],
    (params: UpdateSponsorDto) => {
      return SponsorService.updateSponsor(props.sponsorId, params);
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Error!',
          severity: 'error'
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [
            'SponsorService.getSponsorById',
            props.sponsorId.toString()
          ]
        });
        scheduledChangesHistory.invalidate('company');

        showSnackbar({ message: 'Success!', severity: 'success' });
        setEditDialogOpen(false);
      }
    }
  );

  const onCreateScheduleChange = async (data: Record<string, any>) => {
    if (dayjs(data.scheduledChangesEffectiveDate).isSameOrBefore(dayjs())) {
      await updateSponsor.mutateAsync({
        data: {
          attributes: formValuesToAttributesSponsorDto(
            data,
            props.isStateIRA,
            props.isVoyaPlan
          ),
          id: Number(props.sponsorId),
          type: 'sponsor'
        }
      });
    }
    await createScheduleChange.mutateAsync({
      changeType: 'company',
      effectiveDate: dayjs(data.scheduledChangesEffectiveDate).isSameOrBefore(
        dayjs()
      )
        ? dayjs().format('YYYY-MM-DDTHH:mm:ssZ')
        : dayjs(data.scheduledChangesEffectiveDate).format(
            'YYYY-MM-DDTHH:mm:ssZ'
          ),
      entityId: +props.sponsorId,
      entityType: 'sponsor',
      payload: editCompanyInfoFormToScheduleChangesPayload(
        initialValues,
        data,
        {
          fields: formDialogStep.fields,
          isStateIRA: props.isStateIRA,
          isVoyaPlan: props.isVoyaPlan
        }
      )
    });
  };

  // bug disabled  payrollFundingInfoQuery always loading true?
  if (
    sponsorQuery.isLoading ||
    (payrollFundingInfoQuery.isLoading &&
      props.isStateIRA &&
      payrollSetupsQuery.isSuccess)
  ) {
    return (
      <Typography data-testid='plan-company-info-card-company-tab-loading'>
        Loading...
      </Typography>
    );
  }

  if (sponsorQuery.isError || !sponsorQuery.isSuccess) {
    return (
      <Typography data-testid='plan-company-info-card-company-tab-error'>
        Error
      </Typography>
    );
  }

  const secondaryInfo: Array<{ field: string; value: string | undefined }> = [
    {
      field: 'EIN',
      value: formatters.formatEinOrSdat(sponsorQuery.data.ein)
    }
  ];

  if (
    props.isStateIRA &&
    sponsorPlanQuery.data?.data?.relationships?.firm?.data?.id === 1001
  ) {
    secondaryInfo.push({
      field: 'SDAT',
      value: sponsorQuery.data.alternateEmployerId
    });
  }

  if (props.isStateIRA) {
    secondaryInfo.push(
      {
        field: 'Surpas Account #',
        value: payrollFundingInfoQuery.data?.surpasAccountNumber
      },
      {
        field: 'Program Name',
        value: sponsorPlanQuery.data?.data?.relationships?.firm?.data?.name
      }
    );
  }

  if (isVwPlan || props.isVoyaPlan) {
    secondaryInfo.push(
      {
        field: 'Entity Type',
        value: formatters.displayValueOrEmptyFieldPlaceholder(
          COMPANY_ENTITY_TYPES.filter(
            entityType =>
              entityType.value === sponsorQuery.data?.companyEntityType
          )[0]?.option
        )
      },
      {
        field: 'Fiscal Year End',
        value: formatters.displayValueOrEmptyFieldPlaceholder(
          sponsorQuery.data?.fiscalYearEnd
        )
      }
    );
  }

  if (isVwPlan) {
    secondaryInfo.push(
      {
        field: 'State of Incorporation',
        value: sponsorQuery.data?.stateOfIncorporation
      },
      {
        field: 'Control Group',
        value: formatters.displayValueOrEmptyFieldPlaceholder(
          sponsorQuery.data?.isPartOfControlGroup
        )
      },
      {
        field: 'Wired at Work',
        value: formatters.displayValueOrEmptyFieldPlaceholder(
          sponsorQuery.data?.isWiredAtWork
        )
      }
    );
  }

  return (
    <Box width='100%'>
      <Box
        alignItems='baseline'
        data-testid='plan-company-info-card-company-tab-content'
        display='flex'
        flexDirection='row'
        width='100%'>
        <Box
          display='flex'
          flexDirection='column'
          sx={{ '> *': { margin: '0 0 10px 0' } }}>
          <CardIconInfoField
            icon={<BusinessOutlinedIcon />}
            subtitle={
              'DBA: ' +
              formatters.displayValueOrEmptyFieldPlaceholder(
                sponsorQuery.data?.doingBusinessAs
              )
            }
            title={formatters.displayValueOrEmptyFieldPlaceholder(
              sponsorQuery.data?.name
            )}
            tooltip='Company Legal Name'
          />
          <CardIconInfoField
            icon={<PhoneOutlinedIcon />}
            subtitle={formatters.displayValueOrEmptyFieldPlaceholder(
              sponsorQuery.data?.phone
            )}
            tooltip='Company Phone Number'
          />
          {props.isVoyaPlan && (
            <CardIconInfoField
              icon={<EmailOutlinedIcon />}
              subtitle={formatters.displayValueOrEmptyFieldPlaceholder(
                sponsorQuery.data?.email
              )}
              tooltip='Company Email'
            />
          )}
          <CardIconInfoField
            icon={<PinDropOutlinedIcon />}
            subtitle={formatters.formatAddress(
              sponsorQuery.data?.physicalAddress
            )}
            tooltip='Physical Address'
          />
          {props.isStateIRA && (
            <CardIconInfoField
              icon={<MarkunreadMailboxOutlinedIcon />}
              subtitle={formatters.formatAddress(
                sponsorQuery.data?.mailingAddress
              )}
              tooltip='Mailing Address'
            />
          )}
        </Box>

        <Box display='flex' flexDirection='row' margin='0 auto'>
          <div style={{ width: '175px' }}>
            {secondaryInfo.map((info, index) => (
              <Typography
                className={classes.secondaryInfoField}
                data-testid={`secondary-info-${formatters.textToDataTestId(
                  info.field
                )}-field`}
                key={index}
                sx={{ color: grey[500] }}>
                {info.field}
              </Typography>
            ))}
          </div>
          <div>
            {secondaryInfo.map((info, index) => (
              <Typography
                className={classes.secondaryInfoField}
                data-testid={`secondary-info-${formatters.textToDataTestId(
                  info.field
                )}-value`}
                key={index}>
                {formatters.displayValueOrEmptyFieldPlaceholder(info.value)}
              </Typography>
            ))}
          </div>
        </Box>

        <AccessControl
          requiresOneOf={[FeatureLevelPermissions.WRITE_COMPANY_COMPANY_INFO]}>
          <LoadingButton
            className={classes.editButton}
            data-testid='edit-company-button'
            loading={createScheduleChange.isLoading || sponsorQuery.isFetching}
            onClick={() => setEditDialogOpen(true)}
            variant='text'>
            Edit
          </LoadingButton>
        </AccessControl>
        <EditScheduleChangeDialog
          changeType='company'
          editForm={<FromFields fields={formDialogStep.fields} />}
          hideDatePicker={props.isStateIRA}
          initialValues={initialValues}
          makeScheduledChangesPayload={
            editCompanyInfoFormToScheduleChangesPayload
          }
          makeScheduledChangesPayloadConfig={{
            fields: formDialogStep.fields,
            isStateIRA: props.isStateIRA,
            isVoyaPlan: props.isVoyaPlan
          }}
          onClose={() => {
            setEditDialogOpen(false);
          }}
          onSubmit={onCreateScheduleChange}
          open={editDialogOpen}
          sponsorPlanId={+props.sponsorPlanId}
          title='Edit Company Info'
          validationSchema={makeValidationSchema({
            ein: sponsorQuery.data?.ein || '',
            isStateIRA: props.isStateIRA,
            isVoyaPlan: props.isVoyaPlan,
            phoneNumber: sponsorQuery.data?.phone || '',
            requiredAlternateEmployerId:
              sponsorPlanQuery.data?.data?.relationships?.firm?.data?.id ===
                1001 &&
              Boolean(
                sponsorQuery.data?.exemptionId &&
                  !sponsorExemptionQuery.data?.canceledAt
              )
          })}
        />
      </Box>
    </Box>
  );
};

export default CompanyInfoTab;
