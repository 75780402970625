import { GlobalSearchDto } from '@/models/GlobalSearchDTO.model';
import ApiService from '@/services/Api.service';

class GlobalSearchService {
  static async getGlobalSearchResult(
    searchTerm: string,
    pageNumber: number,
    pageSize: number,
    type?: number,
    abortSignal?: AbortSignal
  ): Promise<GlobalSearchDto> {
    const dto: GlobalSearchDto = type
      ? await ApiService.getJson(
          `/entity-search`,
          {
            pageNumber,
            pageSize,
            query: searchTerm,
            type
          },
          abortSignal
        )
      : await ApiService.getJson(
          `/entity-search`,
          {
            pageNumber,
            pageSize,
            query: searchTerm
          },
          abortSignal
        );

    if (!dto) {
      throw new Error(`invalid JSON received from backend`);
    }

    return dto;
  }
}

export default GlobalSearchService;
