import { CardPlaceholder } from '@/components/card';
import DataTable from '@/components/data-table/DataTable.component';
import { RiskSeriesGridData } from '@/routes/ops/investments/RiskSeries.component';
import { TargetSeriesGridData } from '@/routes/ops/investments/TargetSeries.component';
import InvestmentService from '@/services/Investment.service';
import {
  RiskSeriesSearchData,
  RiskSeriesService
} from '@/services/ops/investments/RiskSeries.service';
import {
  TargetDateSeriesSearchData,
  TargetDateSeriesService
} from '@/services/ops/investments/TargetDateSeries.service';
import { Search } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  InputAdornment,
  Stack,
  TextField,
  Theme,
  Typography
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';

import { ColDef, SelectionChangedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { useRef, useState } from 'react';

export type ModelSeriesData = {
  id: number;
  name: string;
  tickers?: string[];
  description?: string;
};

interface ModelSeriesProps {
  type: 'target' | 'risk';
  investmentId: number;
  importModelSeriesCallback: (
    models: Record<string, any>[],
    allocations: Record<string, any>[]
  ) => void;
  handleCloseModal: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      backgroundColor: '#FAFAFA',
      boxShadow: '24px',
      left: '50%',
      maxHeight: '95%',
      minWidth: theme.spacing(60),
      padding: theme.spacing(3),
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: '65%'
    },
    modalBtn: {
      marginTop: theme.spacing(2)
    },
    searchInput: {
      width: '50ch'
    },
    searchSection: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3)
    },
    size: {
      fontSize: theme.spacing(2)
    }
  })
);

const ModelSeriesImportModal = (props: ModelSeriesProps): JSX.Element => {
  const { type, investmentId, handleCloseModal, importModelSeriesCallback } =
    props;
  const isTarget = type === 'target';
  const isRisk = type === 'risk';
  const classes = useStyles();
  const gridRef = useRef<AgGridReact>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [isImportDisabled, setIsImportDisabled] = useState(true);

  const targeSeriesSearchResult = useQuery<{
    data: TargetDateSeriesSearchData[];
    meta: { count: number };
  }>(
    [
      'TargetDateSeriesService.getTargetDateSeriesPage',
      pageNumber,
      rowsPerPage,
      searchTerm,
      orderBy,
      order
    ],
    async () => {
      return TargetDateSeriesService.getTargetDateSeriesPage(
        pageNumber,
        rowsPerPage,
        orderBy,
        order,
        searchTerm
      );
    },
    {
      cacheTime: 30000,
      enabled: isTarget,
      staleTime: 30000
    }
  );

  const riskSeriesSearchResult = useQuery<{
    data: RiskSeriesSearchData[];
    meta: { count: number };
  }>(
    [
      'RiskSeriesService.getRiskSeriesPage',
      pageNumber,
      rowsPerPage,
      searchTerm,
      orderBy,
      order
    ],
    async () => {
      return RiskSeriesService.getRiskSeriesPage(
        pageNumber,
        rowsPerPage,
        orderBy,
        order,
        searchTerm
      );
    },
    {
      cacheTime: 30000,
      enabled: isRisk,
      staleTime: 30000
    }
  );

  const targeSeriesSearchData =
    targeSeriesSearchResult.data?.data.filter(
      item => item.targetSeriesId !== investmentId
    ) || [];
  const riskSeriesSearchData =
    riskSeriesSearchResult.data?.data.filter(
      item => item.riskSeriesId !== investmentId
    ) || [];

  const paginationTotal =
    targeSeriesSearchResult.data?.meta.count ||
    riskSeriesSearchResult.data?.meta.count;

  let ids: number[] = [];
  if (isTarget) ids = targeSeriesSearchData.map(item => item.targetSeriesId);
  else if (isRisk) ids = riskSeriesSearchData.map(item => item.riskSeriesId);

  const targeSeriesGridModels = useQuery<Map<number, TargetSeriesGridData>>(
    ['InvestmentService.getTargetModelSeriesGridByIds', ids],
    async () => {
      return InvestmentService.getTargetModelSeriesGridByIds(ids);
    },
    {
      cacheTime: 30000,
      enabled: isTarget && targeSeriesSearchResult.isSuccess,
      staleTime: 30000
    }
  );

  const riskSeriesGridModels = useQuery<Map<number, RiskSeriesGridData>>(
    ['InvestmentService.getRiskSeriesGridByIds', ids],
    async () => {
      return InvestmentService.getRiskSeriesGridByIds(ids);
    },
    {
      cacheTime: 30000,
      enabled: isRisk && riskSeriesSearchResult.isSuccess,
      staleTime: 30000
    }
  );

  let modelSeries: ModelSeriesData[] = [];
  if (isTarget)
    modelSeries = targeSeriesSearchData.map(item => {
      return {
        description: item?.description,
        id: item.targetSeriesId,
        name: item.name,
        tickers: targeSeriesGridModels.data
          ?.get(item.targetSeriesId)
          ?.allocations.map(allocation => allocation.symbol as string)
      };
    });
  else if (isRisk)
    modelSeries = riskSeriesSearchData.map(item => {
      return {
        description: item?.description,
        id: item.riskSeriesId,
        name: item.name,
        tickers: riskSeriesGridModels.data
          ?.get(item.riskSeriesId)
          ?.allocations.map(allocation => allocation.symbol as string)
      };
    });

  const searchModelSeries = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;
    setSearchTerm(search);
    setPageNumber(1);
    gridRef.current?.api.deselectAll();
    setIsImportDisabled(true);
  };

  const columnDefs: ColDef[] = [
    {
      field: 'name',
      headerName: 'Model Series Name',
      minWidth: 60,
      sortable: true,
      sortingOrder: ['asc', 'desc'],
      suppressMenu: true
    },
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: ModelSeriesData }) => {
        return (
          <Typography
            sx={{ margin: theme => theme.spacing(1) }}
            variant='body2'>
            {cellData.data.tickers?.join(', ')}
          </Typography>
        );
      },
      field: 'tickers',
      headerName: 'Tickers',
      suppressMenu: true,
      wrapText: true
    },
    {
      field: 'description',
      headerName: 'Model Series Description',
      minWidth: 60,
      sortable: true,
      sortingOrder: ['asc', 'desc'],
      suppressMenu: true
    }
  ];

  const handlePageChanged = (newPage: number) => {
    setPageNumber(newPage);
    gridRef.current?.api.deselectAll();
    setIsImportDisabled(true);
  };

  const handlePageSizeChanged = (newPageSize: number) => {
    setRowsPerPage(newPageSize);
    gridRef.current?.api.deselectAll();
    setIsImportDisabled(true);
  };

  const handleSortChanged = (
    newSort: { colId: string; sort?: 'asc' | 'desc' }[]
  ) => {
    setOrderBy(newSort[0].colId || 'name');
    setOrder(newSort[0].sort || 'asc');
    gridRef.current?.api.deselectAll();
    setIsImportDisabled(true);
  };

  const onSelectionChanged = (event: SelectionChangedEvent) => {
    setIsImportDisabled(event.api.getSelectedNodes().length === 0);
  };

  const handleImport = () => {
    const selectedId = gridRef.current?.api.getSelectedRows()[0].id as number;

    let models: Record<string, any>[] = [];
    if (isTarget)
      models =
        targeSeriesGridModels.data?.get(selectedId)?.models.map(item => {
          return {
            description: item?.description,
            modelName: item.modelName,
            targetRetireYearHigh: item.targetRetireYearHigh,
            targetRetireYearLow: item.targetRetireYearLow
          };
        }) || [];
    else if (isRisk)
      models =
        riskSeriesGridModels.data?.get(selectedId)?.models.map(item => {
          return {
            description: item?.description,
            modelName: item.modelName,
            riskLevel: item.riskLevel
          };
        }) || [];

    let allocations: Record<string, any>[] = [];
    if (isTarget)
      allocations =
        targeSeriesGridModels.data?.get(selectedId)?.allocations || [];
    else if (isRisk)
      allocations =
        riskSeriesGridModels.data?.get(selectedId)?.allocations || [];
    handleCloseModal();
    importModelSeriesCallback(models, allocations);
  };

  return (
    <Box className={classes.modal}>
      <Typography component='div' variant='h5'>
        Import Model Series
      </Typography>
      <Box className={classes.searchSection}>
        <TextField
          InputProps={{
            onChange: searchModelSeries,
            placeholder: 'Search model series',
            startAdornment: (
              <InputAdornment position='start'>
                <Search />
              </InputAdornment>
            )
          }}
          className={classes.searchInput}
          data-testid='search-model-series-field'
          variant='outlined'
        />
      </Box>
      <Card elevation={0} variant='outlined'>
        <DataTable
          columnDefs={columnDefs}
          columnSizing='fit'
          data-testid='data-import-model-series-table'
          domLayout='normal'
          emptyPlaceholderComponent={
            <Stack
              alignItems='center'
              data-testid='no-data-custodian-funds-table'
              justifyContent='center'
              sx={{ height: '371px' }}>
              <CardPlaceholder
                icon={<Search fontSize='inherit' />}
                subtitle='No related Model Series found.'
              />
            </Stack>
          }
          gridHeight='330px'
          gridRef={gridRef}
          onPageChanged={handlePageChanged}
          onPageSizeChanged={handlePageSizeChanged}
          onSelectionChanged={onSelectionChanged}
          onSortChanged={handleSortChanged}
          page={pageNumber}
          pageSize={rowsPerPage}
          pageSizeOptions={[5, 10, 25]}
          pagination
          paginationPosition='bottom'
          paginationSource='server'
          paginationSx={{ height: '10%', py: 0.375 }}
          paginationTotal={paginationTotal}
          primaryKey='id'
          rowData={modelSeries}
          rowHeight={56}
          rowSelection='single'
          sort={[
            {
              colId: orderBy,
              sort: order
            }
          ]}
          suppressCellFocus
          suppressHorizontalScroll={true}
        />
      </Card>
      <Stack direction='row' justifyContent='flex-end' spacing={3}>
        <Box>
          <Button
            className={classes.modalBtn}
            data-testid='modal-close-btn'
            onClick={handleCloseModal}>
            Cancel
          </Button>
        </Box>
        <Box>
          <Button
            className={classes.modalBtn}
            data-testid='modal-import-btn'
            disabled={isImportDisabled}
            onClick={handleImport}
            variant='contained'>
            Import Model Series
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default ModelSeriesImportModal;
