import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { PageConversionDto } from '@/models/ConversionDTO.model';
import formatters from '@/utils/Formatters';
import { Divider, Stack } from '@mui/material';

import React from 'react';

interface ConversionDetailsCollapsibleProps {
  row: PageConversionDto;
}

const ConversionDetailsCollapsible = (
  props: ConversionDetailsCollapsibleProps
): JSX.Element => {
  const { row } = props;

  return (
    <Stack
      direction='row'
      divider={<Divider flexItem orientation='vertical' />}
      spacing={3}>
      <TextStack direction='column' spacing={1}>
        <TextStackItem>
          <TextLabel>Total $ Allocated</TextLabel>
          <TextValue>
            {formatters.formatDollars(row?.totalAllocatedAmount)}
          </TextValue>
        </TextStackItem>
        <TextStackItem>
          <TextLabel># Part. Allocated</TextLabel>
          <TextValue>
            {row?.allocatedParticipants || EMPTY_FIELD_PLACEHOLDER}
          </TextValue>
        </TextStackItem>
      </TextStack>
      <TextStack direction='column' spacing={1}>
        <TextStackItem>
          <TextLabel>File Owners</TextLabel>
          <TextValue>
            {row?.cashAllocationFileOwners?.join(', ') ||
              EMPTY_FIELD_PLACEHOLDER}
          </TextValue>
        </TextStackItem>
      </TextStack>
    </Stack>
  );
};

export default ConversionDetailsCollapsible;
