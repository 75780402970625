import { DocumentDto } from '@/models';
import { Model } from '@/models/ops/investments/Model.model';
import {
  BulkUpdateModelRequest,
  BulkUpdateUpdateResponse
} from '@/routes/ops/model-update/ModelUpdate.component';
import ApiService from '@/services/Api.service';

type ModelFactsheet = {
  modelId: number;
  factsheet: string;
  createdAt: string;
  updatedAt: string;
};

export type ModelSnapshotResponse = {
  fromTs: string;
  investmentOptionId: number;
  modelId: number;
  modelName: string;
  modelType: string;
  realizedModelId: number;
  state: {
    cusip: string;
    fundName: string;
    modelId: number;
    modelPropId: number;
    symbol: string;
    target: number;
  }[];
  thruTs?: string;
};

class ModelService {
  static async downloadModelFactsheet(
    modelId: string | number
  ): Promise<DocumentDto> {
    return ApiService.getJson(
      `investments/models/${modelId}/factsheet/download`
    );
  }

  static async uploadModelFactsheet(
    modelId: number,
    documentData: FormData
  ): Promise<ModelFactsheet> {
    return ApiService.postFormData(
      `investments/models/${modelId}/factsheet/upload`,
      documentData
    );
  }

  static async deleteModelFactsheet(modelId: number): Promise<any> {
    return ApiService.deleteJson(`investments/models/${modelId}/factsheet`);
  }

  static async bulkUpdateModel(
    data: BulkUpdateModelRequest
  ): Promise<BulkUpdateUpdateResponse> {
    return ApiService.postJson('investments/models/bulk-update', data);
  }

  static async getModelById(modelId: number): Promise<Model> {
    return ApiService.getJson(`investments/models/${modelId}`);
  }

  static async bulkRebalanceModel(
    modelIds: number[]
  ): Promise<{ participantIds: number[] }> {
    return ApiService.postJson('/investments/models/bulk-rebalance', {
      modelIds
    });
  }

  static async bulkRebalanceModelPreview(
    modelIds: number[]
  ): Promise<{ participantIds: number[] }> {
    return ApiService.postJson('/investments/models/bulk-rebalance/preview', {
      modelIds
    });
  }

  static async getModelHistoryByModelId(
    modelId: number,
    asOf?: string
  ): Promise<ModelSnapshotResponse[]> {
    return ApiService.getJson(`investments/models/${modelId}/history`, {
      asOf
    });
  }
}

export type { ModelFactsheet };
export { ModelService };
