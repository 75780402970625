import { DisbursementRequestQueueStatus } from '@vestwell-sub-accounting/models/common/HoldingTankStatus';

export const DisbursementRequestQueueStatusColorMap: Record<
  DisbursementRequestQueueStatus,
  'info' | 'neutral' | 'success' | 'warning'
> = {
  [DisbursementRequestQueueStatus.queued]: 'neutral',
  [DisbursementRequestQueueStatus.disbursing]: 'neutral',
  [DisbursementRequestQueueStatus.disbursementRejected]: 'warning',
  [DisbursementRequestQueueStatus.deferred]: 'info',
  [DisbursementRequestQueueStatus.failed]: 'warning',
  [DisbursementRequestQueueStatus.finished]: 'success'
};
