import Constants from '@/consts/planDesign.constants';
import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class EntryDateFrequency {
  entryDateFrequencies = Constants.entryDateFrequencies;

  convertToApiState = Helper.convertValueToApiState;

  options = this.entryDateFrequencies.map(f => f.type);

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.output = props.entryDateFrequency;
  }
}

export default EntryDateFrequency;
