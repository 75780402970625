import { FirmDto } from '@/models';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import React from 'react';

interface FirmDetailsInfoProps {
  attributes: FirmDto['data']['attributes'];
}

const useStyles = makeStyles((theme: Theme) => ({
  fieldLabel: {
    color: theme.palette.text.secondary
  }
}));

const FirmDetailsInfo: React.FC<FirmDetailsInfoProps> = ({
  attributes = {}
}) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid
        border='1px solid rgba(0, 0, 0, 0.12)'
        borderRadius='0.25rem 0.25rem'
        item
        lg={6}
        mb='2rem'
        xs={12}>
        <Grid container direction='column'>
          <Grid borderBottom='1px solid rgba(0, 0, 0, 0.12)' item p='1rem'>
            <Typography variant='h5'>Firm Info</Typography>
          </Grid>

          <Grid container px='1.5rem' py='2rem'>
            <Grid item md={6} xs={12}>
              <Grid container direction='column'>
                <Grid container mb='0.5rem'>
                  <Grid item xs={6}>
                    <Typography className={classes.fieldLabel} variant='body1'>
                      Home Office
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body1'>
                      {attributes.homeOfficeName}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={classes.fieldLabel} variant='body1'>
                      Sub Domain
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body1'>
                      {attributes.subDomain}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container mb='0.5rem'>
                  <Grid item xs={6}>
                    <Typography className={classes.fieldLabel} variant='body1'>
                      Contact
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body1'>
                      {attributes.contactName}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={6} xs={12}>
              <Grid container direction='column'>
                <Grid container mb='0.5rem'>
                  <Grid item xs={6}>
                    <Typography className={classes.fieldLabel} variant='body1'>
                      Phone
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body1'>
                      {attributes.phoneNumber}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FirmDetailsInfo;
