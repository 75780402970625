import AccessControl from '@/components/access-control/AccessControl.component';
import Card, { CardContent, CardHeader } from '@/components/card';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { PlanService } from '@/services/Plan.service';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Theme,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation } from '@tanstack/react-query';

import React, { useState } from 'react';

interface AmendmentNotificationsCardProps {
  sponsorPlanId: number;
  planName: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  infoIcon: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: 'big',
    marginLeft: '20px'
  }
}));

const AmendmentNotificationsCard: React.FunctionComponent<
  AmendmentNotificationsCardProps
> = ({ sponsorPlanId, planName }) => {
  const classes = useStyles();

  const { showSnackbar } = useSnackbar();

  const [safeHarborOrAutoEnrollLoading, setSafeHarborOrAutoEnrollLoading] =
    useState(false);
  const [otherTypeLoading, setOtherTypeLoading] = useState(false);

  const { mutateAsync: sendAmendmentNotifications } = useMutation(
    ['PlanService.sendAmendmentNotifications', sponsorPlanId],
    ({ isSafeHarborOrAutoEnroll }: { isSafeHarborOrAutoEnroll: boolean }) => {
      return PlanService.sendAmendmentNotifications(
        sponsorPlanId,
        isSafeHarborOrAutoEnroll,
        planName
      );
    },
    {
      onError: () => {
        setSafeHarborOrAutoEnrollLoading(false);
        setOtherTypeLoading(false);
        showSnackbar({
          message: 'Error!',
          severity: 'error'
        });
      },
      onSuccess: () => {
        setSafeHarborOrAutoEnrollLoading(false);
        setOtherTypeLoading(false);
        showSnackbar({
          message: 'Success!',
          severity: 'success'
        });
      }
    }
  );

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Card data-testid='amendment-notifications-card'>
      <Dialog
        data-testid='harbor-auto-enroll-dialog-info'
        onClose={handleClose}
        open={open}>
        <Box>
          <DialogContent>
            <Typography id='modal-modal-description'>
              Safe Harbor Plans
              <ul style={{ margin: '0 0 25px 0' }}>
                <li>SH Formula, (ACA formula if QACA)</li>
                <li>any elective deferral changes</li>
                <li>
                  any compensation changes (shouldn’t happen given our platform
                  limits)
                </li>
                <li>vesting changes</li>
                <li>any distribution changes (in-service or otherwise)</li>
                <li>any employer contribution changes (including timing)</li>
              </ul>
              Auto enroll (including QACA) changes:
              <ul style={{ margin: '0 0 25px 0' }}>
                <li>Auto enroll amount</li>
                <li>Auto increase amount</li>
                <li>
                  The employee's right to elect not to have elective
                  contributions made on the employee's behalf (or to elect to
                  have such contributions made in a different amount or
                  percentage of compensation)
                </li>
                <li>
                  How contributions under the automatic contribution arrangement
                  will be invested (including, in the case of an arrangement
                  under which the employee may elect among 2 or more investment
                  options, how contributions will be invested in the absence of
                  an investment election by the employee)
                </li>
              </ul>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Box>
      </Dialog>
      <CardHeader disableDivider title='Amendment Participant Notifications' />
      <CardContent>
        <Box
          alignItems='center'
          display='flex'
          flexDirection='row'
          margin='0 0 20px 0'>
          <Typography variant='body1'>
            Safe Harbor/QACA Safe Harbor <br />
            Provisions/Automatic Enrollment
          </Typography>
          <InfoOutlinedIcon
            className={classes.infoIcon}
            data-testid='holdings-as-of-info-icon'
            onClick={handleOpen}
          />
          <AccessControl
            requiresOneOf={[FeatureLevelPermissions.WRITE_AMENDMENTS_ACTIONS]}>
            <LoadingButton
              data-testid='send-amendment-notifications-button-safe-harbor-or-auto-enroll'
              loading={safeHarborOrAutoEnrollLoading}
              onClick={() => {
                setSafeHarborOrAutoEnrollLoading(true);
                sendAmendmentNotifications({ isSafeHarborOrAutoEnroll: true });
              }}
              sx={{ marginLeft: 'auto' }}
              variant='outlined'>
              Send Notice
            </LoadingButton>
          </AccessControl>
        </Box>
        <Divider sx={{ borderColor: theme => theme.palette.grey[300] }} />
        <Box
          alignItems='center'
          display='flex'
          flexDirection='row'
          margin='20px 0'>
          <Typography variant='body1'>Other type of amendments</Typography>
          <AccessControl
            requires={[FeatureLevelPermissions.WRITE_AMENDMENTS_ACTIONS]}>
            <LoadingButton
              data-testid='send-amendment-notifications-button-other'
              loading={otherTypeLoading}
              onClick={() => {
                setOtherTypeLoading(true);
                sendAmendmentNotifications({ isSafeHarborOrAutoEnroll: false });
              }}
              sx={{ marginLeft: 'auto' }}
              variant='outlined'>
              Send Notice
            </LoadingButton>
          </AccessControl>
        </Box>
      </CardContent>
    </Card>
  );
};
export default AmendmentNotificationsCard;
