import Card, { CardHeader } from '@/components/card';
import {
  CommunicationFilters,
  initialCommunicationFilters,
  PlanCommunications,
  PlanParticipantsMailerLetters
} from '@/models/PlanCommunicationsDTO.model';
import { PlanCommunicationsPhysicalMailFilters } from '@/routes/plans/plan-detail/PlanActivitiesTab/PlanCommunicationsTab/PlanCommunicationsPhysicalMailFilters.component';
import { PlanCommunicationsPhysicalMailTable } from '@/routes/plans/plan-detail/PlanActivitiesTab/PlanCommunicationsTab/PlanCommunicationsPhysicalMailTable.component';
import { splitAndFormatMailerLetterEventType } from '@/routes/plans/plan-detail/PlanActivitiesTab/PlanCommunicationsTab/utils';
import { cleanEmailSubject, cleanTemplateName } from '@/services/mappers';
import { PlanService } from '@/services/Plan.service';
import CheckIcon from '@mui/icons-material/Check';
import {
  Box,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import dayjs from 'dayjs';
import React, { useCallback, useState } from 'react';

import PlanCommunicationFilters from './PlanCommunicationFilters.component';
import PlanCommunicationsTable from './PlanCommunicationsTable.component';

interface PlanCommunicationsTabProps {
  planId: number;
  sponsorId: number;
  parentTabPath: string;
}

export enum EntityTypeValues {
  EMPLOYEES = 'Employees',
  EMPLOYERS = 'Employers'
}

export enum MailTypeValues {
  EMAIL = 'Email',
  PHYSICAL_MAIL = 'Physical Mail'
}

const CommonContentWrapper = (props: {
  children: React.ReactNode;
}): React.ReactElement => {
  return (
    <Box
      sx={{
        alignContent: 'flex-start',
        display: 'flex',
        justifyContent: 'flex-start',
        overflow: 'hidden'
      }}>
      {props.children}
    </Box>
  );
};

const PlanCommunicationsTab = (
  props: PlanCommunicationsTabProps
): JSX.Element => {
  const { planId, sponsorId } = props;
  const [entityType, setEntityType] = useState(EntityTypeValues.EMPLOYEES);
  const [mailType, setMailType] = useState(MailTypeValues.EMAIL);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [filters, setFilters] = useState<CommunicationFilters>(
    initialCommunicationFilters
  );

  const participantsEmailsQuery = useQuery(
    [
      'PlanService.getPlanParticipantsCommunications',
      planId,
      offset,
      limit,
      filters
    ],
    () =>
      PlanService.getPlanParticipantsCommunications(planId, {
        limit,
        offset,
        ...filters
      }),
    {
      enabled: Boolean(planId) && entityType === EntityTypeValues.EMPLOYEES,
      refetchOnMount: false,
      select: (data: PlanCommunications) => {
        return {
          ...data,
          messages: data?.messages?.map(m => {
            return {
              ...m,
              sendDate: dayjs(m.sendDate).format('MM/DD/YYYY HH:mm:ss'),
              subject: cleanEmailSubject(m.subject),
              templateName: cleanTemplateName(m.templateName)
            };
          })
        };
      }
    }
  );

  const sponsorEmailsQuery = useQuery(
    [
      'PlanService.getPlanSponsorCommunications',
      planId,
      offset,
      limit,
      filters
    ],
    () =>
      PlanService.getPlanSponsorCommunications(planId, {
        limit,
        offset,
        ...filters
      }),
    {
      enabled: Boolean(planId) && entityType === EntityTypeValues.EMPLOYERS,
      refetchOnMount: false,
      select: (data: PlanCommunications) => {
        return {
          ...data,
          messages: data?.messages?.map(m => {
            return {
              ...m,
              sendDate: dayjs(m.sendDate).format('MM/DD/YYYY HH:mm:ss'),
              subject: cleanEmailSubject(m.subject),
              templateName: cleanTemplateName(m.templateName)
            };
          })
        };
      }
    }
  );

  const participantsMailerLettersQuery = useQuery(
    [
      'PlanService.getPlanParticipantsMailerLetters',
      planId,
      offset,
      limit,
      filters
    ],
    () =>
      PlanService.getPlanParticipantsMailerLetters(planId, {
        limit,
        offset,
        ...filters
      }),
    {
      enabled: Boolean(planId) && entityType === EntityTypeValues.EMPLOYEES,
      refetchOnMount: false,
      select: (data: PlanParticipantsMailerLetters) => {
        return {
          ...data,
          messages: data?.messages?.map(m => {
            return {
              ...m,
              createdAt: dayjs(m.createdAt).format('MM/DD/YYYY HH:mm:ss'),
              eventCreatedAt: dayjs(m.eventCreatedAt).format(
                'MM/DD/YYYY HH:mm:ss'
              ),
              mailType: splitAndFormatMailerLetterEventType(m.letterEventType)
                .mailType,
              status: m.letterEventType
            };
          })
        };
      }
    }
  );

  const updateFilters = (newFilters: CommunicationFilters) => {
    setFilters(newFilters);
    setOffset(0);
  };

  const onChange = useCallback(
    (newValue: EntityTypeValues | MailTypeValues) => {
      if (
        newValue === EntityTypeValues.EMPLOYEES ||
        newValue === EntityTypeValues.EMPLOYERS
      ) {
        setEntityType(newValue);
      } else {
        setMailType(newValue);
      }
      setFilters(initialCommunicationFilters);
      setLimit(10);
      setOffset(0);
    },
    []
  );

  return (
    <Card>
      <CardHeader justifyContent='flex-start' title='Communications'>
        <ToggleButtonGroup
          color='primary'
          exclusive
          onChange={(event, value) => onChange(value)}
          size='small'
          value={entityType}>
          {Object.values(EntityTypeValues).map(value => (
            <ToggleButton
              data-testId={`entityType${value}`}
              key={value}
              sx={{
                // to account for the 1px border
                px: 1.5,
                py: 0.625
              }}
              value={value}>
              {entityType === value && (
                <Stack
                  sx={{
                    fontSize: 20,
                    lineHeight: '24px'
                  }}>
                  <CheckIcon fontSize='inherit' />
                </Stack>
              )}
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  lineHeight: '24px'
                }}>
                {value}
              </Typography>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        {entityType === EntityTypeValues.EMPLOYEES && (
          <ToggleButtonGroup
            color='primary'
            exclusive
            onChange={(event, value) => onChange(value)}
            size='small'
            value={mailType}>
            {Object.values(MailTypeValues).map(value => (
              <ToggleButton
                data-testId={`mailType${value}`}
                key={value}
                sx={{
                  // to account for the 1px border
                  px: 1.5,
                  py: 0.625
                }}
                value={value}>
                {mailType === value && (
                  <Stack
                    sx={{
                      fontSize: 20,
                      lineHeight: '24px'
                    }}>
                    <CheckIcon fontSize='inherit' />
                  </Stack>
                )}
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 500,
                    lineHeight: '24px'
                  }}>
                  {value}
                </Typography>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        )}
      </CardHeader>
      {entityType === EntityTypeValues.EMPLOYEES &&
        mailType === MailTypeValues.EMAIL && (
          <CommonContentWrapper>
            <PlanCommunicationFilters
              entity={EntityTypeValues.EMPLOYEES}
              filters={filters}
              planId={planId}
              updateFilters={updateFilters}
            />
            <PlanCommunicationsTable
              emailsData={participantsEmailsQuery.data?.messages}
              filters={filters}
              isError={participantsEmailsQuery.isError}
              isLoading={participantsEmailsQuery.isFetching}
              pageNumber={offset}
              rowsPerPage={limit}
              setFilters={setFilters}
              setPageNumber={setOffset}
              setRowsPerPage={setLimit}
              totalEmails={participantsEmailsQuery.data?.meta.total}
            />
          </CommonContentWrapper>
        )}
      {entityType === EntityTypeValues.EMPLOYERS && (
        <CommonContentWrapper>
          <PlanCommunicationFilters
            entity={EntityTypeValues.EMPLOYERS}
            filters={filters}
            planId={planId}
            sponsorId={sponsorId}
            updateFilters={updateFilters}
          />
          <PlanCommunicationsTable
            emailsData={sponsorEmailsQuery.data?.messages}
            filters={filters}
            isError={sponsorEmailsQuery.isError}
            isLoading={sponsorEmailsQuery.isFetching}
            isSponsor
            pageNumber={offset}
            rowsPerPage={limit}
            setFilters={setFilters}
            setPageNumber={setOffset}
            setRowsPerPage={setLimit}
            totalEmails={sponsorEmailsQuery.data?.meta.total}
          />
        </CommonContentWrapper>
      )}
      {entityType === EntityTypeValues.EMPLOYEES &&
        mailType === MailTypeValues.PHYSICAL_MAIL && (
          <CommonContentWrapper>
            <PlanCommunicationsPhysicalMailFilters
              filters={filters}
              updateFilters={updateFilters}
            />
            <PlanCommunicationsPhysicalMailTable
              filters={filters}
              isError={participantsMailerLettersQuery.isError}
              isLoading={participantsMailerLettersQuery.isFetching}
              pageNumber={offset}
              physicalMailsData={participantsMailerLettersQuery.data?.messages}
              rowsPerPage={limit}
              setFilters={setFilters}
              setPageNumber={setOffset}
              setRowsPerPage={setLimit}
              totalPhysicalMails={
                participantsMailerLettersQuery.data?.meta.total
              }
            />
          </CommonContentWrapper>
        )}
    </Card>
  );
};

export default PlanCommunicationsTab;
