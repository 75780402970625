import { Theme } from '@mui/material';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';

import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  fieldContainer: {
    marginBottom: theme.spacing(5)
  },
  textfield: {
    width: '100%'
  }
}));

interface ModelInformationEditFieldsProps {
  isSubmitting: boolean;
  name: string;
  description?: string;
  onNameChange: (name: string) => void;
  onDescriptionChange: (description: string) => void;
}

const ModelInformationEditFields = (
  props: ModelInformationEditFieldsProps
): JSX.Element => {
  const { name, description, isSubmitting, onNameChange, onDescriptionChange } =
    props;

  const classes = useStyles();

  return (
    <>
      <div className={classes.fieldContainer}>
        <TextField
          InputProps={{
            readOnly: isSubmitting
          }}
          className={classes.textfield}
          data-testid='investmentOptionName'
          label='Name'
          onChange={event => onNameChange(event.target.value)}
          value={name || ''}
          variant='filled'
        />
      </div>
      <div className={classes.fieldContainer}>
        <TextField
          InputProps={{
            readOnly: isSubmitting
          }}
          className={classes.textfield}
          data-testid='investmentOptionDescription'
          label='Description'
          onChange={event => onDescriptionChange(event.target.value)}
          value={description || ''}
          variant='filled'
        />
      </div>
      <div className={classes.fieldContainer}>
        <TextField
          InputProps={{
            readOnly: true
          }}
          className={classes.textfield}
          defaultValue='Vestwell'
          label='Owner'
          variant='filled'
        />
      </div>
    </>
  );
};

export default ModelInformationEditFields;
