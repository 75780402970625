import { TradeRequestWorkflowVariations } from '@/models/ops/workflows/TradeRequestWorkflowVariations.model';
import { WorkflowDto } from '@/models/ops/workflows/WorkflowDTO.model';
import { WorkflowStatusEnumsObject } from '@/models/ops/workflows/WorkflowStatusEnumObject.model';
import PendingRequestService from '@/services/ops/pending-requests/PendingRequest.service';
import WorkflowService from '@/services/ops/workflows/Workflow.service';
import { Button, Stack, Typography } from '@mui/material';
import {
  InvestContributionHoldingTankStatus,
  TradeRequestHoldingTankStatus
} from '@vestwell-sub-accounting/models/common/HoldingTankStatus';
import { SubAccountType } from '@vestwell-sub-accounting/models/common/SubAccountType';
import { WorkflowName } from '@vestwell-sub-accounting/models/conductor/WorkflowName.model';
import { PendingRequestStatuses } from '@vestwell-sub-accounting/models/integrationsGateway/PendingRequestStatuses';

import React, { useState } from 'react';

import {
  ActionConfirmationDialog,
  ActionConfirmationDialogProps
} from './WorkflowDetailActionConfirmation.component';

interface ActionButtonProps extends React.HTMLAttributes<HTMLBaseElement> {
  'data-testid'?: string;
  children?: React.ReactNode;
  onClick: () => void;
}
const ActionButton = (props: ActionButtonProps): JSX.Element => {
  const { children, onClick, 'data-testid': testId } = props;

  return (
    <Button
      color='primary'
      data-testid={testId}
      onClick={onClick}
      sx={{ borderRadius: '999px', fontSize: 13, lineHeight: 1.4, py: 0.75 }}
      variant='outlined'>
      {children}
    </Button>
  );
};

interface WorkflowActionsBaseProps {
  workflow: WorkflowDto;
  setConfirmationDialogConfig: React.Dispatch<
    React.SetStateAction<ActionConfirmationDialogProps>
  >;
}

export const WorkflowInvestContributionActions = ({
  workflow,
  setConfirmationDialogConfig
}: WorkflowActionsBaseProps): JSX.Element => {
  return (
    <>
      {workflow.queue && (
        <>
          {workflow.queue.status ===
            InvestContributionHoldingTankStatus.failed && (
            <ActionButton
              data-testid='reprocess-workflow-button'
              onClick={() => {
                setConfirmationDialogConfig(prevConfig => ({
                  ...prevConfig,
                  message: 'Are you sure you want to reprocess this workflow?',
                  onConfirm: async () => {
                    await WorkflowService.reprocess(workflow.id);
                  },
                  open: true,
                  successMessage: 'Workflow reprocessed'
                }));
              }}>
              Reprocess
            </ActionButton>
          )}
          {workflow.relatedWorkflows?.find(
            relatedWorkflow =>
              relatedWorkflow.workflowName ===
              WorkflowName.queueReversalByTracerId
          ) === undefined &&
            workflow.queue?.status ===
              InvestContributionHoldingTankStatus.finished && (
              <ActionButton
                data-testid='reverse-workflow-button'
                onClick={() => {
                  setConfirmationDialogConfig(prevConfig => ({
                    ...prevConfig,
                    inputs: [
                      {
                        initialValue: SubAccountType.error,
                        label: 'Operational Sub Account Type',
                        name: 'operationalSubAccountType',
                        options: [
                          { label: 'Error', value: SubAccountType.error },
                          { label: 'Breakage', value: SubAccountType.breakage },
                          { label: 'Dividend', value: SubAccountType.dividend }
                        ],
                        type: 'select'
                      }
                    ],
                    message: 'Are you sure you want to reverse this workflow?',
                    onConfirm: async (values: Record<string, unknown>) => {
                      await WorkflowService.reverse(
                        workflow.tracerId,
                        String(values.operationalSubAccountType) ||
                          SubAccountType.error
                      );
                    },
                    open: true,
                    successMessage: 'Workflow reversed'
                  }));
                }}>
                Reverse
              </ActionButton>
            )}
          {workflow.queue?.status === TradeRequestHoldingTankStatus.queued && (
            <ActionButton
              data-testid='defer-workflow-button'
              onClick={() => {
                setConfirmationDialogConfig(prevConfig => ({
                  ...prevConfig,
                  message: 'Are you sure you want to defer this workflow?',
                  onConfirm: async () => {
                    await WorkflowService.defer(workflow.tracerId);
                  },
                  open: true,
                  successMessage: 'Workflow deferred'
                }));
              }}>
              Defer
            </ActionButton>
          )}
          {workflow.queue?.status === TradeRequestHoldingTankStatus.queued && (
            <ActionButton
              data-testid='force-processing-workflow-button'
              onClick={() => {
                setConfirmationDialogConfig(prevConfig => ({
                  ...prevConfig,
                  message:
                    'Are you sure you want to force processing of this workflow?',
                  onConfirm: async () => {
                    await WorkflowService.forceProcessing(workflow.tracerId);
                  },
                  open: true,
                  successMessage: 'Workflow forced'
                }));
              }}>
              Force Processing
            </ActionButton>
          )}
        </>
      )}
    </>
  );
};

export const WorkflowTradeRequestRebalanceActions = ({
  workflow,
  setConfirmationDialogConfig
}: WorkflowActionsBaseProps): JSX.Element => {
  return (
    <>
      {workflow.queue && (
        <>
          {workflow.queue?.status === TradeRequestHoldingTankStatus.failed && (
            <ActionButton
              data-testid='reprocess-workflow-button'
              onClick={() => {
                setConfirmationDialogConfig(prevConfig => ({
                  ...prevConfig,
                  message: 'Are you sure you want to reprocess this workflow?',
                  onConfirm: async () => {
                    await WorkflowService.reprocess(workflow.id);
                  },
                  open: true,
                  successMessage: 'Workflow reprocessed'
                }));
              }}>
              Reprocess
            </ActionButton>
          )}
          {workflow.relatedWorkflows?.find(
            relatedWorkflow =>
              relatedWorkflow.workflowName ===
              WorkflowName.queueReversalByTracerId
          ) === undefined &&
            workflow.queue?.status ===
              TradeRequestHoldingTankStatus.finished && (
              <ActionButton
                data-testid='reverse-workflow-button'
                onClick={() => {
                  setConfirmationDialogConfig(prevConfig => ({
                    ...prevConfig,
                    inputs: [
                      {
                        initialValue: SubAccountType.error,
                        label: 'Operational Sub Account Type',
                        name: 'operationalSubAccountType',
                        options: [
                          { label: 'Error', value: SubAccountType.error },
                          { label: 'Breakage', value: SubAccountType.breakage },
                          { label: 'Dividend', value: SubAccountType.dividend }
                        ],
                        type: 'select'
                      }
                    ],
                    message: 'Are you sure you want to reverse this workflow?',
                    onConfirm: async (values: Record<string, unknown>) => {
                      await WorkflowService.reverse(
                        workflow.tracerId,
                        String(values.operationalSubAccountType) ||
                          SubAccountType.error
                      );
                    },
                    open: true,
                    successMessage: 'Workflow reversed'
                  }));
                }}>
                Reverse
              </ActionButton>
            )}
          {workflow.queue?.status === TradeRequestHoldingTankStatus.queued && (
            <ActionButton
              data-testid='defer-workflow-button'
              onClick={() => {
                setConfirmationDialogConfig(prevConfig => ({
                  ...prevConfig,
                  message: 'Are you sure you want to defer this workflow?',
                  onConfirm: async () => {
                    await WorkflowService.defer(workflow.tracerId);
                  },
                  open: true,
                  successMessage: 'Workflow deferred'
                }));
              }}>
              Defer
            </ActionButton>
          )}
          {workflow.queue?.status === TradeRequestHoldingTankStatus.queued && (
            <ActionButton
              data-testid='force-processing-workflow-button'
              onClick={() => {
                setConfirmationDialogConfig(prevConfig => ({
                  ...prevConfig,
                  message:
                    'Are you sure you want to force processing of this workflow?',
                  onConfirm: async () => {
                    await WorkflowService.forceProcessing(workflow.tracerId);
                  },
                  open: true,
                  successMessage: 'Workflow forced'
                }));
              }}>
              Force Processing
            </ActionButton>
          )}
        </>
      )}
    </>
  );
};

export const WorkflowNewPlanActions = ({
  workflow,
  setConfirmationDialogConfig
}: WorkflowActionsBaseProps): JSX.Element => {
  return (
    <>
      {workflow.workflowStatus ===
        WorkflowStatusEnumsObject.PendingCustodianAccount &&
        workflow.pendingRequest?.status !== PendingRequestStatuses.pending && (
          <ActionButton
            data-testid='resend-to-custodian-workflow-button'
            onClick={() => {
              setConfirmationDialogConfig(prevConfig => ({
                ...prevConfig,
                message: 'Are you sure you want to resend this workflow?',
                onConfirm: async () => {
                  if (workflow.pendingRequest?.id) {
                    // shouldn't ever be the case that pendingRequest id would be undefined at this point but typescript cares
                    await PendingRequestService.resendToCustodian(
                      workflow.pendingRequest.id
                    );
                  }
                },
                open: true,
                successMessage: 'Workflow resent'
              }));
            }}>
            Resend to Custodian
          </ActionButton>
        )}
    </>
  );
};

export const WorkflowDepositRequestActions = ({
  workflow,
  setConfirmationDialogConfig
}: WorkflowActionsBaseProps): JSX.Element => {
  return (
    <>
      {workflow.workflowStatus ===
        WorkflowStatusEnumsObject.PendingACHProcessing &&
        workflow.pendingRequest?.status !== PendingRequestStatuses.pending && (
          <ActionButton
            data-testid='resend-to-custodian-workflow-button'
            onClick={() => {
              setConfirmationDialogConfig(prevConfig => ({
                ...prevConfig,
                message: 'Are you sure you want to resend this workflow?',
                onConfirm: async () => {
                  if (workflow.pendingRequest?.id) {
                    // shouldn't ever be the case that pendingRequest id would be undefined at this point but typescript cares
                    await PendingRequestService.resendToCustodian(
                      workflow.pendingRequest.id
                    );
                  }
                },
                open: true,
                successMessage: 'Workflow resent'
              }));
            }}>
            Resend to Custodian
          </ActionButton>
        )}
    </>
  );
};

interface WorkflowDetailActionsProps {
  workflow: WorkflowDto;
  workflowType: WorkflowName | TradeRequestWorkflowVariations;
}
export const WorkflowDetailActions = ({
  workflow,
  workflowType
}: WorkflowDetailActionsProps): JSX.Element => {
  const [confirmationDialogConfig, setConfirmationDialogConfig] =
    useState<ActionConfirmationDialogProps>({
      inputs: [],
      message: '',
      onCancel: () => {
        setConfirmationDialogConfig(prevConfig => ({
          ...prevConfig,
          open: false
        }));
      },
      onConfirm: async () => {},
      open: false,
      successMessage: ''
    });

  return (
    <>
      <ActionConfirmationDialog
        {...confirmationDialogConfig}
        onConfirm={async values => {
          if (typeof confirmationDialogConfig.onConfirm === 'function') {
            await confirmationDialogConfig.onConfirm(values);
          }
          setConfirmationDialogConfig(prevConfig => ({
            ...prevConfig,
            open: false
          }));
        }}
      />
      <Stack
        alignItems='center'
        data-testid='workflow-actions'
        direction='row'
        spacing={1}
        sx={{ mt: 3 }}>
        <Typography>Available Actions</Typography>
        {workflowType === WorkflowName.investContribution && (
          <WorkflowInvestContributionActions
            setConfirmationDialogConfig={setConfirmationDialogConfig}
            workflow={workflow}
          />
        )}
        {workflowType ===
          TradeRequestWorkflowVariations.tradeRequestRebalance && (
          <WorkflowTradeRequestRebalanceActions
            setConfirmationDialogConfig={setConfirmationDialogConfig}
            workflow={workflow}
          />
        )}
        {workflowType === WorkflowName.newPlan && (
          <WorkflowNewPlanActions
            setConfirmationDialogConfig={setConfirmationDialogConfig}
            workflow={workflow}
          />
        )}
        {workflowType === WorkflowName.depositRequest && (
          <WorkflowDepositRequestActions
            setConfirmationDialogConfig={setConfirmationDialogConfig}
            workflow={workflow}
          />
        )}
      </Stack>
    </>
  );
};
