import CardInfoField from '@/components/card-info-field';
import SimpleDropdown from '@/components/simple-dropdown';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { SubaErrors } from '@/hooks/useSubaErrors.hook';
import { DistributionAccount } from '@/models/WithdrawalsDTO.model';
import formatters from '@/utils/Formatters';
import { RemoveCircleOutlineOutlined } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Typography
} from '@mui/material';

import { FormikContextType, useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';

import { EXCLUDED_FUNDING_SOURCES } from './constants';

type SubAccountInfoCardProps = {
  participantAccounts?: DistributionAccount[];
  index: number;
  remove: <T>(index: number) => T | undefined;
  errors: SubaErrors;
};

const SubAccountInfoCard: React.FunctionComponent<SubAccountInfoCardProps> = (
  props: SubAccountInfoCardProps
) => {
  const { participantAccounts, index, remove, errors } = props;
  const formik: FormikContextType<any> = useFormikContext();

  const accountInfo = useMemo(() => {
    return participantAccounts?.find(
      i => i.accountId === formik.values.accounts[index].accountId
    );
  }, [formik.values.accounts, index, participantAccounts]);

  const fundingSourcesOpt = useMemo(() => {
    const accounts = participantAccounts?.filter(
      i => !EXCLUDED_FUNDING_SOURCES.includes(i.source.code)
    );

    return accounts?.map(i => {
      return {
        option: `${i.source.code} - ${i.source.name}`,
        value: i.accountId
      };
    }) as Array<{ value: any; option: string }>;
  }, [participantAccounts]);

  return (
    <Card elevation={0} sx={{ mb: 3 }} variant='outlined'>
      <CardContent>
        <Typography
          id='tableTitle'
          sx={{ fontWeight: 500, mb: 3 }}
          variant='subtitle1'>
          Sub Account #{index + 1}
        </Typography>
        {!isEmpty(errors.indexedErrors?.['accounts']) && (
          <Alert severity='error'>
            {errors.indexedErrors?.['accounts']?.[index]?.['error']}
          </Alert>
        )}
        <Box sx={{ display: 'flex', maxWidth: 800 }}>
          <Box sx={{ marginRight: 5, minWidth: 250 }}>
            <SimpleDropdown
              fieldId={`accounts[${index}].accountId`}
              fieldName='Funding Source*'
              fieldOptions={fundingSourcesOpt}
              onChange={event => {
                formik.setFieldValue(
                  `accounts[${index}].accountId`,
                  event.target.value
                );
              }}
              size='small'
            />
          </Box>

          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.accounts[index].applyVesting}
                data-testid='apply-vesting-checkbox'
                name='applyVestingCheckbox'
                onChange={(e, checked) => {
                  formik.setFieldValue(
                    `accounts[${index}].applyVesting`,
                    checked
                  );
                }}
                value={formik.values.accounts[index].applyVesting}
              />
            }
            label='Apply Vesting'
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.accounts[index].forfeitureFlag}
                data-testid='apply-forfeiture-checkbox'
                name='forfeitureFlagCheckbox'
                onChange={(e, checked) => {
                  formik.setFieldValue(
                    `accounts[${index}].forfeitureFlag`,
                    checked
                  );
                }}
                value={formik.values.accounts[index].forfeitureFlag}
              />
            }
            label='Apply Forfeiture'
          />
        </Box>
        {formik.values.accounts[index].accountId && (
          <Box sx={{ display: 'flex' }}>
            <CardInfoField
              fieldName='Tax Type'
              fieldValue={accountInfo?.taxType || EMPTY_FIELD_PLACEHOLDER}
            />
            <CardInfoField
              fieldName='Outstanding Loan Balance'
              fieldValue={
                accountInfo?.loanBalance !== undefined
                  ? formatters.formatDollars(accountInfo?.loanBalance)
                  : EMPTY_FIELD_PLACEHOLDER
              }
            />
            <CardInfoField
              fieldName='Previous Partial Withdrawal'
              fieldValue={
                accountInfo?.previousWithdrawal !== undefined
                  ? formatters.formatDollars(accountInfo?.previousWithdrawal)
                  : EMPTY_FIELD_PLACEHOLDER
              }
            />
            <CardInfoField
              fieldName='Vesting %'
              fieldValue={
                accountInfo?.vestedPercent !== undefined
                  ? `${accountInfo?.vestedPercent}%`
                  : EMPTY_FIELD_PLACEHOLDER
              }
            />
          </Box>
        )}
        {formik.values.accounts.length > 1 && (
          <Button
            onClick={() => remove(index)}
            startIcon={<RemoveCircleOutlineOutlined />}
            sx={{ float: 'right' }}>
            REMOVE
          </Button>
        )}
      </CardContent>
    </Card>
  );
};
export default SubAccountInfoCard;
