import CollapsibleTable, {
  CellComponentProps
} from '@/components/collapsible-table';
import formatters from '@/utils/Formatters';
import { alpha, Box, TableCell, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import React, { useMemo } from 'react';

import { SecurityCell } from '../PlanInvestmentsTab/FundLineupTab/FundLineupCell.component';
import RequestStatus from './ConversionMainComponents/RequestStatus.component';

interface ConversionRequestsCollapsibleProps {
  row: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  headerCell: {
    color: alpha(theme.palette.common.black, 0.6),
    fontSize: theme.spacing(1.75),
    fontWeight: 500,
    paddingBottom: theme.spacing(1.25),
    paddingTop: theme.spacing(1.25)
  }
}));

const ConversionTransactionsTableCell: React.FC<CellComponentProps> = (
  props: CellComponentProps
) => {
  const { row, column } = props;

  let field = <Box>{row[column.field] || '--'}</Box>;

  switch (column.field) {
    case 'tradeDate':
      field = (
        <Box>
          {formatters.formatFromIsoDateCustom(row[column.field], 'M/DD/YYYY')}
        </Box>
      );
      break;
    case 'price':
    case 'amount':
      field = <Box>{formatters.formatDollars(row[column.field])}</Box>;
      break;
    case 'security':
      field = <SecurityCell row={row} />;
      break;
    case 'status':
      field = <RequestStatus status={row[column.field]} />;
  }

  return (
    <TableCell component='th' scope='row'>
      <Box sx={{ '& .MuiBox-root': { fontSize: theme => theme.spacing(2) } }}>
        {field}
      </Box>
    </TableCell>
  );
};

const ConversionRequestsCollapsible = (
  props: ConversionRequestsCollapsibleProps
): JSX.Element => {
  const classes = useStyles();
  const { row } = props;

  const conversionRequestColumns = useMemo(
    () => [
      {
        cellClassName: classes.headerCell,
        field: 'status',
        headerName: 'Status',
        width: 130
      },
      {
        cellClassName: classes.headerCell,
        field: 'type',
        headerName: 'Base Type / Type',
        width: 130
      },
      {
        cellClassName: classes.headerCell,
        field: 'security',
        headerName: 'Security',
        width: 260
      },
      {
        cellClassName: classes.headerCell,
        field: 'unit',
        headerName: 'Unit',
        width: 130
      },
      {
        cellClassName: classes.headerCell,
        field: 'price',
        headerName: 'Price',
        width: 130
      },
      {
        cellClassName: classes.headerCell,
        field: 'amount',
        headerName: 'Amount',
        width: 130
      },
      {
        cellClassName: classes.headerCell,
        field: 'tradeDate',
        headerName: 'Trade Date',
        width: 130
      }
    ],
    [classes]
  );

  return (
    <Box sx={{ ml: 5 }}>
      <Typography sx={{ mb: 2 }} variant='h5'>
        Transactions
      </Typography>
      <CollapsibleTable
        backgroundPaperElevation={0}
        cellComponent={ConversionTransactionsTableCell}
        columns={conversionRequestColumns}
        headerPagination
        rootPaperElevation={0}
        tableData={row.transactions}
        tablePaginationSx={{
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          borderTop: '1px solid rgba(0, 0, 0, 0.12)'
        }}
      />
    </Box>
  );
};

export default ConversionRequestsCollapsible;
