import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  createSvgIcon,
  Link as MuiLink,
  LinkBaseProps as MuiLinkBaseProps,
  SvgIconProps,
  Typography
} from '@mui/material';

import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// TODO: SUB-3540 — replace existing icon with below in src/components/icon once older adhoc code is replaced with Link
const OpenInNewIcon: FC<SvgIconProps> = createSvgIcon(
  <path
    d='M18 20H4V6H10V4H4C2.76667 4 2 4.77778 2 6V20C2 21.2222 2.76667 22 4 22H18C19.2222 22 20 21.2222 20 20V14H18V20ZM14 2V4H18.4333L8 14.4333L9.56667 16L20 5.56667V10H22V2H14Z'
    fill='currentColor'
  />,
  'OpenInNewIcon'
);

OpenInNewIcon.defaultProps = {
  viewBox: '0 0 24 24'
};

OpenInNewIcon.displayName = 'OpenInNewIcon';

type LinkProps = (
  | {
      children?: React.ReactNode;
      'data-testid'?: string;
      to: string;
      target?: '_blank';
    }
  | {
      children?: React.ReactNode;
      'data-testid'?: string;
      onClick: () => void;
    }
) &
  MuiLinkBaseProps;

export const Link: React.FunctionComponent<LinkProps> = (props: LinkProps) => {
  const children = (
    <Typography
      component='span'
      fontSize='inherit'
      minWidth={0}
      overflow='hidden'
      textOverflow='ellipsis'
      whiteSpace='nowrap'>
      {props.children}
    </Typography>
  );

  return 'to' in props ? (
    <MuiLink
      alignItems='center'
      color='primary'
      component={props.to.startsWith('http') ? 'a' : RouterLink}
      data-testid={props['data-testid']}
      display='inline-flex'
      gap={0.75}
      maxWidth='100%'
      underline='none'
      {...props}>
      {children}
      {props.target.toLowerCase() === '_blank' ? (
        <OpenInNewIcon fontSize='inherit' />
      ) : (
        <KeyboardArrowRightIcon fontSize='inherit' />
      )}
    </MuiLink>
  ) : (
    <MuiLink
      alignItems='center'
      color='primary'
      component='button'
      data-testid={props['data-testid']}
      display='inline-flex'
      gap={0.75}
      maxWidth='100%'
      underline='none'
      {...props}>
      {children}
      <OpenInNewIcon fontSize='inherit' />
    </MuiLink>
  );
};

export default Link;
