import { useField, useFormikContext } from 'formik';
import { FC, useEffect } from 'react';

const EffectiveDateObserver: FC = () => {
  const { values }: { values: Record<string, any> } = useFormikContext();
  const [, , liquidationDateHelpers] = useField('liquidationDate');
  const [, , originalEffectivePlanDateHelpers] = useField(
    'originalEffectivePlanDate'
  );
  const [, , safeHarborEffectiveDateHelpers] = useField(
    'safeHarborEffectiveDate'
  );
  const [, , vestwellStartDateHelpers] = useField('vestwellStartDate');
  const [, , salaryRothDeferralEffectiveDateHelpers] = useField(
    'salaryRothDeferralEffectiveDate'
  );
  const [, , profitSharingStartDateHelpers] = useField(
    'profitSharingStartDate'
  );

  useEffect(() => {
    if (values.liquidationDate === 'Invalid Date') {
      liquidationDateHelpers.setValue('');
    }

    if (values.originalEffectivePlanDate === 'Invalid Date') {
      originalEffectivePlanDateHelpers.setValue('');
    }

    if (values.profitSharingStartDate === 'Invalid Date') {
      profitSharingStartDateHelpers.setValue('');
    }

    if (values.safeHarborEffectiveDate === 'Invalid Date') {
      safeHarborEffectiveDateHelpers.setValue('');
    }

    if (values.salaryRothDeferralEffectiveDate === 'Invalid Date') {
      salaryRothDeferralEffectiveDateHelpers.setValue('');
    }

    if (values.vestwellStartDate === 'Invalid Date') {
      vestwellStartDateHelpers.setValue('');
    }
  }, [
    values,
    liquidationDateHelpers,
    originalEffectivePlanDateHelpers,
    safeHarborEffectiveDateHelpers,
    vestwellStartDateHelpers,
    salaryRothDeferralEffectiveDateHelpers,
    profitSharingStartDateHelpers
  ]);

  return null;
};

export default EffectiveDateObserver;
