import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class ExternalHardDollarBillingAccountNumber {
  convertToApiState = Helper.convertValueToApiState;

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.output = props.externalHardDollarBillingAccountNumber;
  }
}

export default ExternalHardDollarBillingAccountNumber;
