import STATE_CODES from '@/consts/states.constants';

import * as yup from 'yup';

interface SchemaParams {
  isStateIRA: boolean;
  isVoyaPlan: boolean;
  phoneNumber: string;
  ein: string;
  requiredAlternateEmployerId: boolean;
}

const einRegex = (ein: string) =>
  new RegExp(`((^${ein}$)|(^\\d{9}$)|(^\\d{2}-\\d{7}$))`);

const einOrSDATRegex = (ein: string) =>
  new RegExp(
    `((^${ein}$)|(^\\d{9}$)|(^\\d{2}-\\d{7}$))|((^${ein}$)|(^\\d{8}$)|(^\\d{2}-\\d{6}$))`
  );

const blackListedEINs = [
  '000000000',
  '111111111',
  '222222222',
  '333333333',
  '444444444',
  '555555555',
  '666666666',
  '777777777',
  '888888888',
  '999999999',
  '123456789',
  '987654321'
];

const blackListedSDATs = [
  '00000000',
  '11111111',
  '22222222',
  '33333333',
  '44444444',
  '55555555',
  '66666666',
  '77777777',
  '88888888',
  '99999999',
  '12345678',
  '87654321'
];

export const EINOrSDATFieldValidation = (
  type: 'EIN' | 'SDAT',
  initialValue: string
) =>
  yup
    .string()
    .matches(
      type == 'EIN' ? einRegex(initialValue) : einOrSDATRegex(initialValue),
      `Please enter a valid ${type}`
    )
    .test(
      `checks for valid  ${type} for state plans`,
      `Please enter a valid  ${type}`,
      newValue => {
        if (newValue) {
          const blackListedEINValue = blackListedSDATs.includes(
            newValue.replaceAll('-', '')
          );
          const blackListedSDATValue = blackListedEINs.includes(
            newValue.replaceAll('-', '')
          );
          if (
            (blackListedEINValue && newValue.replaceAll('-', '')) ||
            (blackListedSDATValue && newValue.replaceAll('-', ''))
          ) {
            return false;
          }
        }
        return true;
      }
    )
    .required(`${type} is a required field`);

const makeValidationSchema = (params: SchemaParams): yup.AnyObjectSchema => {
  const baseCompanyContactValidationSchema = yup.object({
    alternateEmployerId: params.requiredAlternateEmployerId
      ? yup
          .string()
          .required('SDAT is required')
          .matches(/^[A-Z]\d{8}$/i, `Please enter a valid SDAT`)
      : yup.string().optional(),
    companyEntityType: yup.string().optional(),
    companyPhoneNumber: yup
      .string()
      .matches(
        /^(?:\d{3})-\d{3}-\d{4}$/,
        'Invalid format, please use: XXX-XXX-XXXX'
      ),
    doingBusinessAs: yup.string(),
    ein: EINOrSDATFieldValidation('EIN', params.ein),
    fiscalYearEnd: yup.string().optional(),
    isPartOfControlGroup: yup.string().optional(),
    name: yup.string().required('Company Legal Name is required'),
    physicalAddress1: yup.string().required('Address 1 is required'),
    physicalAddress2: yup.string(),
    physicalCity: yup.string().required('City is required'),
    physicalState: yup
      .string()
      .oneOf(STATE_CODES, 'Please enter a valid state abbreviation')
      .required('State is required'),
    physicalZip: yup
      .string()
      .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Must be a valid zip code')
      .required('Zip is required'),
    stateOfIncorporation: yup.string().optional(),
    wiredAtWork: yup.string().optional()
  });

  const voyaPlanCompanyContactValidationSchema =
    baseCompanyContactValidationSchema.shape({
      email: yup.string().email('Email must be a valid email')
    });

  const stateIraCompanyContactValidationSchema =
    baseCompanyContactValidationSchema.shape({
      ein: EINOrSDATFieldValidation('EIN', params.ein),
      mailingAddress1: yup.string().required('Mailing Address 1 is required'),
      mailingAddress2: yup.string(),
      mailingCity: yup.string().required('City is required'),
      mailingState: yup
        .string()
        .oneOf(STATE_CODES, 'Please enter a valid state abbreviation')
        .required('State is required'),
      mailingZip: yup
        .string()
        .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Must be a valid zip code')
        .required('Zip is required')
    });

  return params.isStateIRA
    ? stateIraCompanyContactValidationSchema
    : params.isVoyaPlan
      ? voyaPlanCompanyContactValidationSchema
      : baseCompanyContactValidationSchema;
};

export default makeValidationSchema;
