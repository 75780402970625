import { PlanDesignDto } from '@/models';
import { ClientSuccessManagerDto } from '@/models/ClientSuccessManager.model';
import { PlanDesign, SafeHarborType } from '@/models/PlanDesign.model';
import DistributionFeaturesObserver from '@/routes/plans/plan-detail/PlanTab/formik-observers/DistributionFeaturesObserver';
import EffectiveDateObserver from '@/routes/plans/plan-detail/PlanTab/formik-observers/EffectiveDateObserver';
import EligibilityFeaturesObserver from '@/routes/plans/plan-detail/PlanTab/formik-observers/EligibilityFeaturesObserver';
import EmployeeContributionObserver from '@/routes/plans/plan-detail/PlanTab/formik-observers/EmployeeContributionObserver';
import EmployerContributionObserver from '@/routes/plans/plan-detail/PlanTab/formik-observers/EmployerContributionObserver';
import ForceOutPreferencesObserver from '@/routes/plans/plan-detail/PlanTab/formik-observers/ForceOutPreferencesObserver';
import LoansObserver from '@/routes/plans/plan-detail/PlanTab/formik-observers/LoansObserver';
import RecordkeeperAndCustodianObserver from '@/routes/plans/plan-detail/PlanTab/formik-observers/RecordkeeperAndCustodianObserver';
import VestingPreferencesObserver from '@/routes/plans/plan-detail/PlanTab/formik-observers/VestingPreferencesObserver';
import {
  designTabFieldsWithValidationRules,
  getNavigationSchema,
  getUiSchema
} from '@/routes/plans/plan-detail/PlanTab/schemas';
import { PlanDesignService } from '@/services/PlanDesign.service';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Theme,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';

import { Form, useFormikContext } from 'formik';
import { intersection } from 'lodash';
import React, { useEffect, useMemo } from 'react';

import { useGetField } from './getField.hook';
import { UploadPlanDesignFile } from './UploadPlanDesignFile.component';
import { useWarnings } from './useWarnings.hook';
import { planDataToFormValues, uploadingDataToPlanData } from './utils';

const useStyles = makeStyles((theme: Theme) => ({
  accordionError: {
    boxShadow:
      '0px 2px 10px -1px rgb(0 0 0 / 20%), 0px 0px 1px 0px rgb(0 0 0 / 14%), 0px 0px 3px 0px rgb(0 0 0 / 12%)',
    color: theme.palette.error.main
  },
  accordionRoot: {
    boxShadow:
      '0px 2px 10px -1px rgb(0 0 0 / 20%), 0px 0px 1px 0px rgb(0 0 0 / 14%), 0px 0px 3px 0px rgb(0 0 0 / 12%)'
  },
  actionButtons: {
    backgroundColor: theme.palette.background.default,
    bottom: 0,
    position: 'sticky',
    zIndex: 1
  },
  active: {
    color: theme.palette.info.main
  },
  errorMessage: {
    color: theme.palette.error.main,
    padding: '1.5rem'
  },
  field: {
    marginTop: '1rem !important'
  },
  hidden: {
    display: 'none'
  },
  label: {
    color: 'rgba(0, 0, 0, 0.6)'
  },
  navigationItem: {
    color: 'rgba(0, 0, 0, 0.6)',
    cursor: 'pointer',
    marginBottom: '1.125rem !important'
  },
  value: {
    paddingLeft: '0.5rem'
  }
}));

const checkError = (values: string[], errors: Record<string, unknown>) => {
  if (!Object.keys(errors).length) {
    return false;
  }

  return !!intersection(Object.keys(errors), values)?.length;
};

type EditPlanDesignFormProps = {
  activeNavigationItem: string;
  csms?: ClientSuccessManagerDto;
  data: Record<string, any>;
  file: File | null;
  isEsa?: boolean;
  isPooledPlan?: boolean;
  isTpaUser?: boolean;
  isUploading: boolean;
  planDesignData: PlanDesignDto;
  onSelectFile: (files: any) => void;
  sponsorPlanId: number;
  uploadingData: PlanDesign;
  uploadingError: string;
  unsupportedValues: Record<string, Record<string, any>>;
  unsupportedValuesLength: number;
};

export const EditPlanDesignForm: React.FC<EditPlanDesignFormProps> = props => {
  const classes = useStyles();
  const formikCtx = useFormikContext<Record<string, unknown>>();

  const { getField } = useGetField(
    props.data,
    formikCtx.initialValues,
    props.isPooledPlan
  );

  const warnings = useWarnings(formikCtx.values);

  const uiSchema = useMemo(() => getUiSchema(props.isEsa), [props.isEsa]);
  const navigationSchema = useMemo(
    () => getNavigationSchema(props.isEsa),
    [props.isEsa]
  );

  const safeHarborTypes = useQuery<SafeHarborType[]>(
    ['PlanDesignService.getSafeHarborTypes'],
    () => PlanDesignService.getSafeHarborTypes(),
    {
      staleTime: Infinity
    }
  );

  useEffect(() => {
    if (Object.keys(props.uploadingData).length) {
      formikCtx.setValues(
        planDataToFormValues(
          uploadingDataToPlanData(
            props.uploadingData,
            props.planDesignData,
            props.csms,
            props.isTpaUser,
            props.isPooledPlan,
            safeHarborTypes.data
          )
        )
      );
    }
  }, [props.uploadingData]);

  return (
    <>
      {['Onboarding', 'Ready - Awaiting Effective Date'].includes(
        props.data.adminStatus.output
      ) &&
        !props.isPooledPlan && (
          <UploadPlanDesignFile
            file={props.file}
            isUploading={props.isUploading}
            onSelect={props.onSelectFile}
            unsupportedValues={props.unsupportedValues}
            unsupportedValuesLength={props.unsupportedValuesLength}
            uploadingData={props.uploadingData}
            uploadingError={props.uploadingError}
          />
        )}
      <Form data-testid='edit-plan-design-form'>
        <DistributionFeaturesObserver data={props.data} />
        <EffectiveDateObserver />
        <EligibilityFeaturesObserver data={props.data} />
        <EmployeeContributionObserver data={props.data} />
        <EmployerContributionObserver data={props.data} />
        <ForceOutPreferencesObserver data={props.data} />
        <LoansObserver data={props.data} />
        <RecordkeeperAndCustodianObserver data={props.data} />
        <VestingPreferencesObserver data={props.data} />
        <Box>
          {navigationSchema.design.map(section => (
            <Accordion
              classes={{
                root: checkError(
                  designTabFieldsWithValidationRules[section.key],
                  formikCtx.errors
                )
                  ? classes.accordionError
                  : classes.accordionRoot
              }}
              data-testid={`${section.key}-tab`}
              defaultExpanded={section.key === props.activeNavigationItem}
              key={`${section.key}-${
                'sections' in uiSchema.design[section.key]
                  ? 'sections'
                  : 'fields' in uiSchema.design[section.key]
                    ? 'fields'
                    : 'unlabeled'
              }`}>
              <AccordionSummary>
                <Typography align='center' variant='h6'>
                  {section.label}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {uiSchema.design[section.key].fields &&
                  uiSchema.design[section.key].fields.map(field =>
                    getField(
                      field,
                      formikCtx.errors,
                      warnings,
                      props.unsupportedValues[field.key]
                    )
                  )}
                {uiSchema.design[section.key].sections &&
                  uiSchema.design[section.key].sections.map(section =>
                    section.fields.map(field =>
                      getField(
                        field,
                        formikCtx.errors,
                        warnings,
                        props.unsupportedValues[field.key]
                      )
                    )
                  )}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Form>
    </>
  );
};
