export const RolloverStatusColorMap: Partial<
  Record<string, 'error' | 'info' | 'neutral' | 'success' | 'warning'>
> = {
  // list from https://github.com/vestwell/hello401-backend/blob/5268b444d0971c0094947c5e04014b5c88bd9e48/src/main/scala/com/hello401/core/rollover/model/RolloverRequestStatus.scala#L7-L11
  'Awaiting Funds': 'warning',
  Canceled: 'error',
  Complete: 'success',
  Expired: 'error',
  'Investment Pending': 'warning'
};
