import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { CashDeliveryDetails } from '@/models';
import formatters from '@/utils/Formatters';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Stack,
  Typography
} from '@mui/material';

import React from 'react';

import AccountNumber from './AccountNumber.component';
import TaxDetailsStack from './TaxDetailsStack.component';

type CashDeliveryInfoAccordionProps = {
  fundingSources: string[];
  delivery: CashDeliveryDetails;
  taxType: 'After-Tax' | 'Pre-Tax' | 'Roth';
};

const CashDeliveryInfoAccordion: React.FunctionComponent<
  CashDeliveryInfoAccordionProps
> = (props: CashDeliveryInfoAccordionProps) => {
  const { fundingSources, delivery } = props;

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ flexShrink: 0, width: '33%' }} variant='h6'>
            {`Cash (${props.taxType})`}
          </Typography>
          <Typography>
            {`${
              delivery.fullAmount
                ? 'FULL'
                : formatters.formatDollars(delivery.amount)
            }, ${delivery.deliveryDestination.type}`}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack
            direction='row'
            divider={<Divider flexItem orientation='vertical' />}
            spacing={2}>
            <TextStack direction='column' sx={{ m: 0, p: 0, width: '41%' }}>
              <Typography sx={{ fontSize: 15, fontWeight: 500 }}>
                Delivery Options
              </Typography>
              <TextStackItem>
                <TextLabel>Delivery Method</TextLabel>
                <TextValue data-testid='cash-delivery-type'>
                  {delivery.deliveryDestination.type}
                </TextValue>
              </TextStackItem>
              <TextStackItem>
                <TextLabel>Amount</TextLabel>
                <TextValue data-testid='cash-amount'>
                  {delivery.fullAmount
                    ? 'FULL'
                    : formatters.formatDollars(delivery.amount)}
                </TextValue>
              </TextStackItem>
              {(delivery.deliveryDestination.type === 'CHECK' ||
                delivery.deliveryDestination.type === 'OVERNIGHT_CHECK') && (
                <>
                  <TextStackItem>
                    <TextLabel>Address</TextLabel>
                    <TextValue data-testid='cash-address'>
                      {`${delivery.deliveryDestination.address1}`}
                      <br />
                      {!!delivery.deliveryDestination?.address2 && (
                        <>
                          {`${delivery.deliveryDestination?.address2 ?? ''}`}
                          <br />
                        </>
                      )}
                      {`${delivery.deliveryDestination.city} ${delivery.deliveryDestination.state} ${delivery.deliveryDestination.zip}`}
                    </TextValue>
                  </TextStackItem>
                </>
              )}
              {(delivery.deliveryDestination.type === 'ACH' ||
                delivery.deliveryDestination.type === 'WIRE') && (
                <>
                  <TextStackItem>
                    <TextLabel>Bank Name</TextLabel>
                    <TextValue data-testid='cash-bank-name'>
                      {delivery.deliveryDestination.institutionName ?? null}
                    </TextValue>
                  </TextStackItem>
                  <TextStackItem>
                    <TextLabel>Routing Number</TextLabel>
                    <TextValue data-testid='cash-routing-number'>
                      {delivery.deliveryDestination.routingNumber ?? null}
                    </TextValue>
                  </TextStackItem>
                  {!!delivery.deliveryDestination.accountNumber && (
                    <AccountNumber
                      accountNumber={delivery.deliveryDestination.accountNumber}
                      cardTitle='Account Number'
                      isShowNumber={true}
                    />
                  )}
                </>
              )}
              {delivery.deliveryDestination.type === 'WIRE' && (
                <>
                  <TextStackItem>
                    <TextLabel>Credit To</TextLabel>
                    <TextValue>
                      {delivery.deliveryDestination.creditTo ?? null}
                    </TextValue>
                  </TextStackItem>
                  <TextStackItem>
                    <TextLabel>Further Credit To</TextLabel>
                    <TextValue>
                      {delivery.deliveryDestination.furtherCreditTo ?? null}
                    </TextValue>
                  </TextStackItem>
                </>
              )}
            </TextStack>
            {props.taxType !== 'After-Tax' && (
              <TaxDetailsStack
                isRoth={props.taxType === 'Roth'}
                taxDetails={delivery.taxDetails}
              />
            )}
            <Box>
              <Typography mb={2} sx={{ fontSize: 15, fontWeight: 500 }}>
                Funding Source(s)
              </Typography>
              {fundingSources.map((fundingSource, index) => (
                <Typography
                  key={`fundingSource-${index}`}
                  sx={{ color: theme => theme.palette.text.primary }}
                  variant='body2'>
                  {fundingSource}
                </Typography>
              ))}
            </Box>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
export default CashDeliveryInfoAccordion;
