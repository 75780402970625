import CollapsibleTable, {
  CellComponentProps,
  Order
} from '@/components/collapsible-table';
import DocumentDownloadButton from '@/components/document-download-button';
import SimpleAutocomplete from '@/components/simple-autocomplete';
import SimpleDropdown from '@/components/simple-dropdown';
import { useUserToken } from '@/contexts/UserTokenContext';
import { DocumentMetadataListDto } from '@/models';
import { GlobalSearchPlanDto } from '@/models/GlobalSearchDTO.model';
import GlobalSearchService from '@/services/GlobalSearch.service';
import TpaService from '@/services/Tpa.service';
import {
  Box,
  Divider,
  Grid,
  ListItem,
  Paper,
  TableCell,
  Theme,
  Toolbar,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import clsx from 'clsx';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import { isString, noop, orderBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  cellSharedStyles: {
    fontSize: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
  noResultsTableBackground: {
    borderTop: '0px',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    paddingBottom: '100px',
    paddingTop: '100px'
  },
  noResultsTableMessage: {
    color: theme.palette.grey[700]
  },
  originalFileNameCell: {
    width: theme.spacing(45)
  },
  pageTitle: {
    marginBottom: theme.spacing(3.5),
    marginTop: theme.spacing(3.5)
  },
  planNameCell: {
    width: theme.spacing(55)
  }
}));

const useTableStyles = makeStyles((theme: Theme) => ({
  tableInputContainer: {
    width: theme.spacing(20)
  },
  tableInputStyles: {
    '& > *': {
      paddingRight: theme.spacing(1.5),
      paddingTop: theme.spacing(2)
    },
    paddingLeft: theme.spacing(1.5)
  },
  tableToolbar: {
    marginBottom: '0px'
  },
  tableToolbarDivider: {
    height: '1px',
    marginBottom: theme.spacing(0),
    marginTop: 0
  },
  tableToolbarGridContainer: {
    marginBottom: theme.spacing(2)
  },
  tableToolbarTitle: {
    marginBottom: theme.spacing(2.5),
    marginTop: theme.spacing(2.5),
    paddingLeft: theme.spacing(2)
  }
}));

const SPONSORPLAN_ENTITY_TYPE = 3;

const ReportTableCell: React.FunctionComponent<CellComponentProps> = (
  props: CellComponentProps
) => {
  const classes = useStyles();
  const { row, column } = props;

  const formatCell = useCallback(
    (r: typeof row, c: typeof column): string | undefined => {
      if (c.field === 'fileName') {
        return r.fileName.split('.')[0];
      }

      return r[c.field];
    },
    []
  );

  return (
    <TableCell
      className={clsx(classes.cellSharedStyles, column.cellClassName)}
      component='th'
      scope='row'
      {...(column.field === 'download' && { align: 'right' })}>
      <Box>
        {formatCell(row, column) || (
          <DocumentDownloadButton
            docV2={{
              documentKey: row.documentKey,
              entityId: `${row.planId}`,
              id: row.uploadHistoryId,
              originalFileName: row.fileName
            }}
            iconWithFileExtension
          />
        )}
      </Box>
    </TableCell>
  );
};

const ReportsRoute = (): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const tableClasses = useTableStyles();
  const { userHasValidToken } = useUserToken();
  const [filterPlanId, setFilterPlanId] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterYear, setFilterYear] = useState<string>('');
  const [filterQuarter, setFilterQuarter] = useState<string>('');
  const [sortKey, setSortKey] = useState<string>('planName');
  const [sortOrder, setSortOrder] = useState<Order>('asc');

  const defaultColumns: GridColDef<DocumentMetadataListDto[number]>[] = [
    {
      cellClassName: classes.planNameCell,
      field: 'planName',
      headerName: 'Plan Name',
      sortable: true
    },
    {
      cellClassName: classes.originalFileNameCell,
      field: 'fileName',
      headerName: 'Report',
      sortable: true
    },
    {
      field: 'download',
      sortable: false
    }
  ];

  const documentListQuery = useQuery(
    ['TpaService.getAllDocumentsForAllEntitiesLinkedToTPAId'],
    () =>
      TpaService.getTpaReportingDocuments({
        documentKeyPrefix: `Plan Executive Summary`,
        entityType: 'plan',
        pageNumber: pageNumber + 1, // collapsible table is zero indexed
        pageSize: rowsPerPage,
        ...(filterYear || filterQuarter
          ? { fileNameContains: `${filterYear} ${filterQuarter}` }
          : {}),
        ...(filterPlanId ? { entityId: filterPlanId } : {}),
        ...{ sort: `${sortOrder === 'desc' ? '-' : ''}${sortKey}` }
      }),
    {
      enabled: userHasValidToken,
      select: data => {
        return {
          documents: data.data ?? [],
          meta: data.meta
        };
      }
    }
  );

  const historyOptions = useMemo(() => {
    const currentYear = dayjs().year();
    const years = [];

    for (let i = 0; i < 10; i++) {
      years.push({
        option: `${currentYear - i}`,
        value: `${currentYear - i}`
      });
    }

    return [{ option: 'Any', value: '' }, ...years];
  }, []);

  useEffect(() => {
    if (userHasValidToken) {
      documentListQuery.refetch();
    }
  }, [
    rowsPerPage,
    pageNumber,
    filterQuarter,
    filterYear,
    filterPlanId,
    sortKey,
    sortOrder
  ]);

  return (
    <div>
      <Typography className={classes.pageTitle} variant='h4'>
        Reports
      </Typography>
      <CollapsibleTable
        backgroundPaperElevation={0}
        cellComponent={ReportTableCell}
        columns={defaultColumns}
        disablePagination={
          (documentListQuery.data?.meta?.count || 0) < rowsPerPage
        }
        headerComponent={
          <Toolbar className={tableClasses.tableToolbar} disableGutters>
            <Formik
              initialValues={{
                quarter: filterQuarter,
                year: filterYear
              }}
              onSubmit={noop}>
              {formikProps => {
                return (
                  <Grid
                    className={tableClasses.tableToolbarGridContainer}
                    container>
                    <Grid item lg={12} md={12} sm={12}>
                      <Typography
                        className={tableClasses.tableToolbarTitle}
                        component='div'
                        id='collapsible-table-toolbar-title'
                        variant='h5'>
                        Plan Executive Summary
                      </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12}>
                      <Divider
                        className={tableClasses.tableToolbarDivider}
                        flexItem
                      />
                    </Grid>
                    <Grid item lg={12}>
                      <div className={tableClasses.tableInputStyles}>
                        <SimpleAutocomplete
                          fieldId='plan'
                          fieldName='Plan'
                          getFieldValues={async (userInput: string) => {
                            const results = (
                              await GlobalSearchService.getGlobalSearchResult(
                                userInput,
                                1,
                                500,
                                SPONSORPLAN_ENTITY_TYPE
                              )
                            ).data.plans.data;

                            return /[0-9]+/.test(userInput)
                              ? results.filter(result =>
                                  result.sponsorPlanId
                                    .toString()
                                    .includes(userInput)
                                )
                              : orderBy(results, p => p.planName);
                          }}
                          getOptionLabel={(
                            plan: GlobalSearchPlanDto | string
                          ) => (isString(plan) ? plan : plan.planName)}
                          isOptionEqualToValue={(a, b) =>
                            a.sponsorPlanId === b.sponsorPlanId
                          }
                          keepLabelOnTop
                          onChange={(_, choice) => {
                            setFilterPlanId(
                              choice == null
                                ? null
                                : parseInt(choice.sponsorPlanId, 10)
                            );
                          }}
                          placeholder='All Plans'
                          renderOption={(optionProps, option) => {
                            return (
                              <ListItem
                                {...optionProps}
                                key={option.sponsorPlanId}>
                                <Grid container>
                                  <Grid item lg={8} md={8}>
                                    <Typography>
                                      {isString(option)
                                        ? option
                                        : option.planName}
                                    </Typography>
                                  </Grid>
                                  <Grid item md={4}>
                                    <Typography
                                      align='right'
                                      color={theme => theme.palette.grey[700]}
                                      data-testid='plan-id-heading'
                                      variant='caption'>
                                      PLAN ID: {option.sponsorPlanId}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </ListItem>
                            );
                          }}
                          width={theme.spacing(55)}
                        />
                        <Box
                          className={tableClasses.tableInputContainer}
                          component='div'
                          display='inline-block'>
                          <SimpleDropdown
                            fieldId='year'
                            fieldName='Year'
                            fieldOptions={historyOptions}
                            onChange={event => {
                              setFilterYear(event.target.value);
                              formikProps.setFieldValue(
                                'year',
                                event.target.value
                              );
                            }}
                          />
                        </Box>
                        <Box
                          className={tableClasses.tableInputContainer}
                          component='div'
                          display='inline-block'>
                          <SimpleDropdown
                            fieldId='quarter'
                            fieldName='Quarter'
                            fieldOptions={[
                              { option: 'Any', value: '' },
                              { option: 'Q1', value: 'Q1' },
                              { option: 'Q2', value: 'Q2' },
                              { option: 'Q3', value: 'Q3' },
                              { option: 'Q4', value: 'Q4' }
                            ]}
                            onChange={event => {
                              setFilterQuarter(event.target.value);
                              formikProps.setFieldValue(
                                'quarter',
                                event.target.value
                              );
                            }}
                          />
                        </Box>
                      </div>
                    </Grid>
                  </Grid>
                );
              }}
            </Formik>
          </Toolbar>
        }
        isLoading={documentListQuery.isRefetching}
        pager={{
          metaCount: documentListQuery.data?.meta.count,
          onPageNumberChanged: (newIndexedPageNumber: number) => {
            return setPageNumber(newIndexedPageNumber);
          },
          onRowsPerPageChanged: (newRowsPerPage: number) => {
            return setRowsPerPage(newRowsPerPage);
          },
          pageNumber,
          rowsPerPage
        }}
        sorter={{
          onSortOrderChanged: (newOrderBy: string, newOrder: Order) => {
            setSortKey(newOrderBy);
            setSortOrder(newOrder);
          },
          order: sortOrder,
          orderBy: sortKey
        }}
        tableData={documentListQuery.data?.documents || []}
        useSquareBottomContainer={!documentListQuery.data?.documents.length}
      />
      {documentListQuery.isFetching && (
        <Paper className={classes.noResultsTableBackground}>
          <Typography
            align='center'
            className={classes.noResultsTableMessage}
            variant='subtitle1'>
            Loading reports...
          </Typography>
        </Paper>
      )}
      {documentListQuery.isSuccess &&
        !documentListQuery.data?.documents?.length && (
          <Paper className={classes.noResultsTableBackground}>
            <Typography
              align='center'
              className={classes.noResultsTableMessage}
              variant='subtitle1'>
              There are no reports available for the options selected.
            </Typography>
          </Paper>
        )}
      {documentListQuery.isError &&
        !documentListQuery.data?.documents?.length && (
          <Paper className={classes.noResultsTableBackground}>
            <Typography
              align='center'
              className={classes.noResultsTableMessage}
              variant='subtitle1'>
              We encountered an error retrieving reports.
            </Typography>
          </Paper>
        )}
    </div>
  );
};

export default ReportsRoute;
