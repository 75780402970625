import useHasPermissions from '@/components/access-control/useHasPermissions.hook';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import {
  ActionCenterService,
  BulkWelcomeEmailTargetRecords
} from '@/services/ActionCenter.service';
import { useMutation } from '@tanstack/react-query';

import { useMemo } from 'react';

import { ActionCenterDetailsBulkWelcomeEmails } from './ActionCenterDetailsBulkWelcomeEmails.component';
import { BulkActionsConfig } from './useBulkActionCenterConfig.hook';

export const useBulkWelcomeEmailsConfig = queryClient => {
  const permissions = useHasPermissions({
    requires: [FeatureLevelPermissions.WRITE_BULK_EMAIL_ACTIONS]
  });

  const mutation = useMutation(
    ['ActionCenterService.bulkWelcomeEmailsPlans'],
    (records: BulkWelcomeEmailTargetRecords) =>
      ActionCenterService.bulkWelcomeEmailsPlans(records),
    {
      onSettled: () => {
        queryClient.invalidateQueries([
          'ActionCenterService.getBulkWelcomeEmailActions'
        ]);
      }
    }
  );

  const bulkUploadFilesConfig = useMemo<BulkActionsConfig>(
    () => ({
      step2: <ActionCenterDetailsBulkWelcomeEmails handleSubmit={mutation} />,
      step3: null,

      visible: permissions.isAllowed
    }),
    [permissions]
  );

  return bulkUploadFilesConfig;
};
