import { Autocomplete, TextField } from '@mui/material';

import React, { forwardRef, SyntheticEvent } from 'react';

export interface TickerOption {
  readonly cusip: string;
  readonly symbol: string;
}

export interface TickerAutocompleteProps {
  onChange: (event: SyntheticEvent, newValue: any) => void;
  inputValue?: string;
  label?: string;
  size?: 'small' | 'medium';
  sx?: Record<string, unknown>;
  autoFocus?: boolean;
  onInputChange: (event: SyntheticEvent, value: string) => void;
  options?: TickerOption[];
  onBlur: (event: SyntheticEvent) => void;
  error?: boolean;
  helperText?: string;
}

export const TickerAutocomplete = forwardRef(
  (props: TickerAutocompleteProps, ref): JSX.Element => {
    const {
      autoFocus,
      error = false,
      label,
      size,
      onBlur,
      onChange,
      onInputChange,
      sx,
      inputValue,
      options,
      helperText
    } = props;

    return (
      <Autocomplete
        clearOnBlur={false}
        disablePortal
        getOptionLabel={(option: TickerOption) => {
          return option.symbol || '';
        }}
        inputValue={inputValue}
        isOptionEqualToValue={(option: TickerOption, value: TickerOption) => {
          return option.cusip === value.cusip;
        }}
        onBlur={onBlur}
        onChange={onChange}
        onInputChange={onInputChange}
        options={options || []}
        ref={ref}
        renderInput={renderParams => {
          return (
            <TextField
              {...renderParams}
              autoFocus={autoFocus || false}
              error={error}
              helperText={helperText}
              label={label}
              size={size || 'medium'}
            />
          );
        }}
        sx={sx}
      />
    );
  }
);

export default TickerAutocomplete;
