import planConsts from '@/consts/planDesign.constants';
import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class NormalRetirementAgeType {
  convertToApiState = Helper.convertValueToApiState;

  options = planConsts.normalRetirementAgeTypeOptions;

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.output = props.normalRetirementAgeType;
  }
}

export default NormalRetirementAgeType;
