import Card, { CardContent, CardHeader } from '@/components/card';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { Program } from '@/models/ops/investments/Program.model';
import ProgramInvestmentRiskSeries from '@/routes/ops/investments/ProgramInvestmentRiskSeries.component';
import { ProgramService } from '@/services/ops/investments/Program.service';
import { Search } from '@mui/icons-material';
import { InputAdornment, TextField, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useQueryClient } from '@tanstack/react-query';

import { useFormik } from 'formik';
import { FC, useCallback, useState } from 'react';
import { unstable_usePrompt } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchInput: {
      width: theme.spacing(65)
    }
  })
);

type ProgramRiskSeriesProps = {
  programData: Program;
  refetchProgram: () => void;
};

export const ProgramRiskSeries: FC<ProgramRiskSeriesProps> = props => {
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState('');

  const form = useFormik({
    initialValues: props.programData,
    onSubmit: async (values, actions) => {
      try {
        await ProgramService.putProgram(values.programId, values);
        snackbar.showSnackbar({
          message: 'Save Successful',
          severity: 'success'
        });
        actions.resetForm({ values });
        queryClient.invalidateQueries([
          'ProgramSecurities',
          form.values.programId
        ]);
        props.refetchProgram();
      } catch (error) {
        snackbar.showSnackbar({
          message: `Failed to save program: ${error?.details}`,
          severity: 'error'
        });
      }
    }
  });

  const onRiskSeriesIdSet = useCallback((id?: number) => {
    form.setFieldValue('riskSeriesId', id);
    setSearchTerm('');
  }, []);

  unstable_usePrompt({
    message: 'Choose cancel to avoid losing the unsaved changes.',
    when: form.dirty
  });

  return (
    <Card>
      <CardHeader
        actionButtonsProps={[
          {
            disabled: !form.dirty,
            label: 'Update',
            onClick: form.submitForm,
            variant: 'contained'
          }
        ]}
        title='Risk Based Model Series'
      />
      <CardContent>
        <TextField
          InputProps={{
            onChange: event => setSearchTerm(event.target.value),
            placeholder: 'Search for Risk based model series to link',
            startAdornment: (
              <InputAdornment position='start'>
                <Search />
              </InputAdornment>
            )
          }}
          className={classes.searchInput}
          data-testid='search-investment-field'
          value={searchTerm}
          variant='outlined'
        />
      </CardContent>
      <ProgramInvestmentRiskSeries
        programId={form.values.programId}
        riskSeriesId={form.values.riskSeriesId}
        searchTerm={searchTerm}
        setRiskSeriesIdCallback={onRiskSeriesIdSet}
      />
    </Card>
  );
};

ProgramRiskSeries.displayName = 'ProgramRiskSeries';
