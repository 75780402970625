import FileUploadTable from '@/components/file-upload-table/FileUploadTable.component';
import LinearLoading from '@/components/linear-loading';
import { CONVERSION_TYPES, DOCUMENT_TYPES } from '@/consts/uploads';
import { Conversion } from '@/models/ConversionDTO.model';
import ActionTableForm from '@/routes/plans/plan-detail/PlanActionTableV2/ConversionMainComponents/ActionTableForm.component';
import { PlanService } from '@/services/Plan.service';
import deferralRatesValidationSchema from '@/utils/validations/DeferralRates.schema';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { formatSsn } from '@vestwell-frontend/helpers';

import { omit } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';

import ConversionCardButton from './ConversionMainComponents/ConversionCardButton.component';
import ConversionDialog from './ConversionMainComponents/ConversionDialog';
import ConversionDialogHeader from './ConversionMainComponents/ConversionDialogHeader.component';
import { useConversionAgGrid } from './useConversionAgGrid';
import { useConversionFile } from './useEditFile';
import useUploadOnboardingAgGridFile from './useUploadOnboardingAgGridFile.hook';

interface DeferralRatesButtonProps {
  sponsorPlanId: number;
  conversion?: Conversion;
}

const EMPTY_ROWS = 10;

const DeferralRates: React.FunctionComponent<DeferralRatesButtonProps> = ({
  sponsorPlanId,
  conversion
}: DeferralRatesButtonProps) => {
  const [openPlanActionDialog, setOpenPlanActionDialog] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const conversionAgGrid = useConversionAgGrid(sponsorPlanId);

  const planQuery = useQuery(
    ['PlanService.getPlanById', sponsorPlanId?.toString()],
    () => PlanService.getPlanById(sponsorPlanId),
    {
      enabled: !!sponsorPlanId,
      staleTime: Infinity
    }
  );

  const { mutateAsync: uploadDeferralRates, isLoading } =
    useUploadOnboardingAgGridFile(
      sponsorPlanId as number,
      DOCUMENT_TYPES.DEFERRAL_RATES
    );

  const columnDefs = useMemo(
    () => [
      {
        alternates: [
          'social',
          'SS #',
          'ss#',
          'social security number',
          'social_security_number',
          'social security',
          'ssn#',
          'Taxpayer ID (SSN or Fed ID)',
          'socialsecuritynumber',
          'employee ssn',
          'national_identifier',
          'employee social',
          'national identifier',
          'ssn number',
          'ss number',
          'ssn/fein',
          'Social Security Number'
        ],
        cellEditorParams: {
          showExcludeRowButton: true
        },
        editable: true,
        field: 'ssn',
        headerName: 'SSN/ITIN',
        headerTooltip: 'Enter 9 digit SSN with or without dashes.',
        maxWidth: 270,
        minWidth: 250,
        required: true,
        suppressMenu: true,
        type: 'ssn',
        valueParser: (value: string) => {
          if (/^(?!(000))\d{3}(?!00)\d{2}(?!0000)\d{4}$/.test(value)) {
            return formatSsn(value);
          }
          return value;
        }
      },
      {
        children: [
          {
            alternates: ['New_Pretax_Value'],
            editable: true,
            field: 'toPreTaxValue',
            headerName: 'Value',
            headerTooltip:
              'Maximum contribution limits apply and will depend on whether the value is specified as a dollar amount or percentage. This value must be a valid integer',
            maxWidth: 200,
            minWidth: 180,
            required: true,
            suppressMenu: true,
            type: 'number'
          },
          {
            alternates: ['New_Pretax_Type'],
            editable: true,
            field: 'toPreTaxType',
            headerName: 'Type',
            headerTooltip:
              "Indicates whether the contribution is a fixed dollar amount or a percentage of the employee’s salary. Value must be either '$' or '%'.",
            maxWidth: 90,
            minWidth: 60,
            options: planQuery.data?.data.attributes.isDeferInDollars
              ? ['%', '$']
              : ['%'],
            required: true,
            suppressMenu: true,
            type: 'select'
          }
        ],
        headerName: 'New Pre-Tax'
      },
      {
        children: [
          {
            alternates: ['New_Roth_Value'],
            editable: true,
            field: 'toRothValue',
            headerName: 'Value',
            headerTooltip:
              'Maximum contribution limits apply and will depend on whether the value is specified as a dollar amount or percentage. This value must be a valid integer',
            maxWidth: 200,
            minWidth: 180,
            required: true,
            suppressMenu: true,
            type: 'number'
          },
          {
            alternates: ['New_Roth_Type'],
            editable: true,
            field: 'toRothType',
            headerName: 'Type',
            headerTooltip:
              "Indicates whether the contribution is a fixed dollar amount or a percentage of the employee’s salary. Value must be either '$' or '%'.",
            maxWidth: 90,
            minWidth: 60,
            options: planQuery.data?.data.attributes.isDeferInDollars
              ? ['%', '$']
              : ['%'],
            required: true,
            suppressMenu: true,
            type: 'select'
          }
        ],
        headerName: 'New Roth'
      },
      {
        alternates: ['is_affirmative_election'],
        cellEditorParams: {
          showExcludeRowButton: true
        },
        defaultValue: true,
        editable: true,
        field: 'isAffirmativeElection',
        headerName: 'Is Affirmative Election',
        required: false,
        suppressMenu: true,
        type: 'checkbox',
        valueParser: value => {
          if ([null, undefined, ''].includes(value)) {
            return true;
          }

          if (typeof value == 'boolean') {
            return value;
          }

          if (typeof value == 'string' && value) {
            return value.toLowerCase() === 'true'
              ? true
              : value.toLowerCase() === 'false'
                ? false
                : value;
          }

          return value;
        }
      }
    ],
    [planQuery.data]
  );

  const { onClicked: editFileClicked, isLoading: isLoadingEditFile } =
    useConversionFile(
      conversionAgGrid.handleLoad,
      sponsorPlanId,
      CONVERSION_TYPES.DEFERRAL_RATES,
      conversion?.documentId,
      columnDefs
    );

  const currentYear = new Date().getFullYear();
  const participantsForDeferralConversionQuery = useQuery(
    [
      'PlanService.getParticipantsForDeferralConversion',
      sponsorPlanId,
      {
        type: planQuery.data?.data.attributes.type ?? '',
        year: currentYear
      }
    ],
    () =>
      PlanService.getParticipantsForDeferralConversion(sponsorPlanId, {
        type: planQuery.data?.data.attributes.type ?? '',
        year: currentYear
      }),
    {
      enabled: !!sponsorPlanId,
      staleTime: Infinity
    }
  );

  const validationSchema =
    participantsForDeferralConversionQuery.data && planQuery.data
      ? deferralRatesValidationSchema(
          sponsorPlanId,
          participantsForDeferralConversionQuery.data,
          planQuery.data.data.attributes.isDeferInDollars
        )
      : null;

  const isSubmitDisabled = useMemo(() => {
    return conversionAgGrid.errorsCount > 0 || conversionAgGrid.rowCount === 0;
  }, [conversionAgGrid.errorsCount, conversionAgGrid.rowCount]);

  const onSubmit = useCallback(async () => {
    setOpenConfirmationDialog(false);
    setOpenPlanActionDialog(false);

    const data =
      conversionAgGrid.gridRows
        ?.filter(row => !row?.pristine)
        ?.map(row => ({
          ...omit(row, ['uuid']),
          deferralType:
            row.isAffirmativeElection === false
              ? 'Auto_Enroll'
              : 'Deferral_Change',
          isAffirmativeElection: row.isAffirmativeElection ?? true,
          sponsorPlanId
        })) ?? [];

    await uploadDeferralRates({ results: data });
  }, [conversionAgGrid.gridRows, sponsorPlanId]);

  return (
    <>
      {conversion ? (
        <ConversionCardButton
          conversion={conversion}
          isLoading={isLoadingEditFile}
          onClick={() => {
            setOpenPlanActionDialog(true);
            editFileClicked();
          }}
          sponsorPlanId={sponsorPlanId}
        />
      ) : (
        <LoadingButton
          data-testid='uploadDeferralRatesConversion'
          disabled={!sponsorPlanId || isLoading}
          loading={isLoading}
          onClick={() => {
            setOpenPlanActionDialog(true);
          }}
          startIcon={<FileUploadOutlinedIcon />}>
          Upload
        </LoadingButton>
      )}
      <ConversionDialog
        data-testid='conversion'
        fullWidth
        maxWidth='lg'
        onClose={() => {
          setOpenPlanActionDialog(false);
        }}
        open={openPlanActionDialog}>
        <DialogTitle>
          <ConversionDialogHeader
            columnDefs={columnDefs}
            onUpload={conversionAgGrid.handleLoad}
            title='Deferral Rates'
          />
        </DialogTitle>
        <Stack direction='row' justifyContent='flex-end' mb={1}>
          <Stack direction='row' mr={2}>
            <WarningAmberIcon
              color='error'
              sx={{ marginRight: theme => theme.spacing(1) }}
            />
            {conversionAgGrid.errorsCount} Errors
          </Stack>
        </Stack>
        <DialogContent>
          <ActionTableForm
            defaultEmptyRowValues={{
              isAffirmativeElection: true
            }}
            emptyRowsCount={!conversion ? EMPTY_ROWS : undefined}
            initialValues={conversionAgGrid.gridRows}
            onChange={conversionAgGrid.setGridRows}
            onErrors={conversionAgGrid.handleErrors}
            onSubmit={conversionAgGrid.handleSubmit}
            ref={conversionAgGrid.form}
            validateOnChange
            validateOnMount
            validationSchema={validationSchema}>
            <FileUploadTable
              addNewRowsCount={EMPTY_ROWS}
              columnDefs={columnDefs}
              defaultEmptyRowValues={{
                isAffirmativeElection: true
              }}
              errors={conversionAgGrid.gridErrors}
              onCellChanged={conversionAgGrid.handleCellChange}
              onRowsChanged={conversionAgGrid.handleRowsChange}
              rowData={conversionAgGrid.gridRows}
            />
            {isLoadingEditFile && (
              <Box sx={{ mt: 2 }}>
                <LinearLoading />
              </Box>
            )}
          </ActionTableForm>
        </DialogContent>
        <DialogActions>
          <Stack width='100%'>
            <Typography variant='body2'>Employees</Typography>
            <Typography>{conversionAgGrid.rowCount}</Typography>
          </Stack>
          <Button
            data-testid='cancel'
            onClick={() => {
              setOpenPlanActionDialog(false);
              conversionAgGrid.form.current?.setValues([]);
            }}
            variant='text'>
            Cancel
          </Button>
          {!conversion && (
            <LoadingButton
              data-testid='submit'
              disabled={isSubmitDisabled}
              loading={isLoading}
              onClick={() => {
                setOpenConfirmationDialog(true);
              }}
              variant='contained'>
              Submit
            </LoadingButton>
          )}
        </DialogActions>
      </ConversionDialog>
      <Dialog
        data-testid='confirmation'
        onClose={() => setOpenConfirmationDialog(false)}
        open={openConfirmationDialog}>
        <DialogTitle>Submit & overwrite existing deferral rates</DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{ color: theme => theme.palette.text.primary }}>
            Continuing will overwrite existing deferral rates for participants.
            This process cannot be reversed. Are you sure you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmationDialog(false)}>
            BACK TO EDIT
          </Button>
          <Button data-testid='confirmationSubmit' onClick={onSubmit}>
            SUBMIT
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeferralRates;
