import Constants from '@/consts/planDesign.constants';
import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class HardshipCriteria {
  convertToApiState = Helper.convertValueToApiState;

  disabled: boolean;

  options = Constants.hardshipCriteriaOptions;

  output: string | undefined;

  setDisabled = (value: boolean): void => {
    this.disabled = value;
  };

  constructor(props: Record<string, any>) {
    this.disabled = !['Yes - All Accounts', 'Yes - Selected Accounts'].includes(
      props.allowHardshipWithdrawals
    );

    this.output = props.hardshipCriteria;
  }
}

export default HardshipCriteria;
