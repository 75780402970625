import { CellComponentProps } from '@/components/collapsible-table';
import { AllocationCell } from '@/routes/ops/investments/investment-table/AllocationCell.component';
import { Box, TableCell, Theme, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

import React, { useMemo } from 'react';

import { RiskSeriesFields, RiskSeriesRow } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  rootCell: {
    fontSize: theme.spacing(2),
    paddingBottom: theme.spacing(0.75),
    paddingTop: theme.spacing(0.75)
  },
  securityContent: {
    color: blue[500],
    display: 'flex'
  }
}));

interface CellProps {
  row: RiskSeriesRow;
}

const SecurityCell: React.FC<CellProps> = (props: CellProps) => {
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.securityContent}>
        <Box style={{ width: props.row.maxTickerLength * 12 + 'px' }}>
          {props.row[RiskSeriesFields.SECURITY].id}
        </Box>
        <Box>| {props.row[RiskSeriesFields.SECURITY].cusip}</Box>
      </Box>

      <Typography variant='body2'>
        {props.row[RiskSeriesFields.SECURITY].fundName}
      </Typography>
    </Box>
  );
};

const RiskSeriesTableCell: React.FC<CellComponentProps> = (
  props: CellComponentProps
) => {
  const { row, column } = props;
  const classes = useStyles();

  const CellComponent = useMemo(() => {
    switch (column.field) {
      case RiskSeriesFields.SECURITY:
        return <SecurityCell row={row} />;
      case RiskSeriesFields.TOTAL_ALLOCATION:
        return (
          <AllocationCell
            allocations={[{ [row.id]: row.totalAllocation }]}
            backgroundColor={blue[500]}
            colId={row.id}
          />
        );
      default:
        return <>{row[column.field]}</>;
    }
  }, [column, row]);

  return (
    <TableCell className={classes.rootCell} component='td' scope='row'>
      {CellComponent}
    </TableCell>
  );
};

export default RiskSeriesTableCell;
