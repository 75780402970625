import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { IconButton, SxProps } from '@mui/material';

import React, { ElementType } from 'react';

interface VisibilityToggleProps extends React.HTMLAttributes<HTMLBaseElement> {
  isMasked: boolean;
  toggleMask: () => void;
  component?: ElementType<any>;
  sx?: SxProps;
}

const VisibilityToggle: React.FunctionComponent<VisibilityToggleProps> = (
  props: VisibilityToggleProps
) => {
  const { isMasked, toggleMask, component, sx = {} } = props;

  return (
    <IconButton
      component={component ? component : 'button'}
      onClick={toggleMask}
      size='small'
      sx={{ ml: theme => theme.spacing(3), ...sx }}>
      {isMasked ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
    </IconButton>
  );
};

export default VisibilityToggle;
export type { VisibilityToggleProps };
