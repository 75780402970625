import {
  FeatureLevelPermissions,
  Permissions
} from '@/models/UserPermissions.model';

interface IDPUser {
  email: string;
  email_verified: boolean;
  name: string;
  nickname: string;
  picture: string;
  sub: string;
  updated_at: string;
}

// moshe: move this to models
interface TokenData {
  tpaId: number | undefined;
  email: string;
  helpEmail: string;
  aud: string[];
  azp: string;
  exp: number;
  iat: number;
  iss: string;
  permissions: Permissions[];
  scope: string;
  sub: string;
  userId: string;
}
interface UserData {
  user: IDPUser | undefined;
  token: TokenData | undefined;
  isAuthenticated: boolean;
}

type UserUpdateCallback = (userData: UserData) => any;

// TODO: consider providing this via the UserTokenContext
class UserService {
  user?: IDPUser;

  isAuthenticated: boolean;

  tokenData?: TokenData;

  accessToken?: string;

  subscriptions: Map<string, UserUpdateCallback>;

  constructor() {
    this.isAuthenticated = false;
    this.subscriptions = new Map<string, UserUpdateCallback>();
  }

  hasPermission(permission: Permissions): boolean {
    const tokenData = this.getTokenData();

    if (!tokenData) return false;

    return tokenData.permissions.includes(permission);
  }

  isTpaUser(): boolean {
    const hasTpaReadPermission = this.hasPermission(
      FeatureLevelPermissions.READ_TPA
    );
    const hasTpaId = !!this.getTokenData()?.tpaId;
    return hasTpaId && hasTpaReadPermission;
  }

  setAccessToken(token: string) {
    this.accessToken = token;
  }

  setUserFromIDP(user: IDPUser) {
    this.user = user;
    this.broadcastUpdate();
  }

  setIsAuthenticated(isAuthenticated: boolean) {
    this.isAuthenticated = isAuthenticated;
    this.broadcastUpdate();
  }

  setTokenData(tokenData: TokenData) {
    this.tokenData = tokenData;
    this.broadcastUpdate();
  }

  broadcastUpdate() {
    const payload: UserData = {
      isAuthenticated: this.isAuthenticated,
      token: this.tokenData,
      user: this.user
    };
    this.subscriptions.forEach(cb => cb(payload));
  }

  getAccessToken(): string | undefined {
    return this.accessToken;
  }

  getUser(): IDPUser | undefined {
    return this.user;
  }

  getTokenData(): TokenData | undefined {
    return this.tokenData;
  }

  getIsAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  // TODO(mbildner) consider replacing this pubsub with a promise.
  subscribe(id: string, callback: UserUpdateCallback) {
    if (!callback || typeof callback !== 'function') {
      throw new Error(`A callback function must be provided`);
    }
    this.subscriptions.set(id, callback);
    this.broadcastUpdate();
  }

  unsubscribe(id: string) {
    if (!this.subscriptions.has(id)) {
      throw new Error(`No subscription exists for id ${id}`);
    }
    this.subscriptions.delete(id);
    this.broadcastUpdate();
  }
}

const userService = new UserService();

export { userService };
export type { IDPUser, UserUpdateCallback, UserData, TokenData };
