import { PlanAdminStatus } from './PlanAdminStatus.model';

export const PlanAdminStatusColorMap: Partial<
  Record<PlanAdminStatus, 'info' | 'neutral' | 'success' | 'warning'>
> = {
  [PlanAdminStatus.Abandoned]: 'info',
  [PlanAdminStatus.Active]: 'success',
  [PlanAdminStatus.DeconversionRequested]: 'warning',
  [PlanAdminStatus.Deconverted]: 'info',
  [PlanAdminStatus.Deferred]: 'info',
  [PlanAdminStatus.ObCancelled]: 'info',
  [PlanAdminStatus.Onboarding]: 'warning',
  [PlanAdminStatus.Pending]: 'warning',
  [PlanAdminStatus.ReadyAwaitingEffectiveDate]: 'neutral',
  [PlanAdminStatus.Terminated]: 'info',
  [PlanAdminStatus.TerminationRequested]: 'warning'
};
