import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { difference, intersection } from 'lodash';
import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useToggle, useUpdateEffect } from 'react-use';

type Columns = {
  ssn?: string;
  sd?: string;
  rc?: string;
  at?: string;
  sh?: string;
  em?: string;
  ps?: string;
  ln?: string;
  qc?: string;
  qm?: string;
};

type HeadersValidations = {
  matched: Columns;
  recognized: Columns;
  unrecognized: string[];
  isValid: boolean;
};

type HeaderMappingProps = {
  headers?: string[];
  onHeadersMatched: (rows: Record<string, any>[]) => void;
  rawData?: (string | number)[][];
  onCancel: () => void;
  synonyms?: Record<string, string[]>;
};

const useStyles = makeStyles(theme => ({
  actions: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  checkbox: {
    alignItems: 'center',
    display: 'flex'
  },
  container: {
    alignItems: 'center',
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    display: 'flex',
    padding: '10px 0 10px 0'
  },
  message: {
    marginBottom: 10
  },
  select: {
    flex: 1,
    height: 50
  },
  title: {
    flex: 1
  }
}));

const REQUIRED_HEADERS = ['ssn'];
const OPTIONAL_HEADERS = ['sd', 'rc', 'at', 'sh', 'em', 'ps', 'ln', 'qc', 'qm'];

const NAMES: Record<string, string> = {
  at: 'After Tax',
  em: 'D. Match',
  ln: 'Loan',
  ps: 'P. Sharing',
  qc: 'QNEC',
  qm: 'QMAC',
  rc: 'Roth',
  sd: 'Pre Tax',
  sh: 'S. Harbor',
  ssn: 'SSN'
};

const SelectSection: FC<{
  headersValidation: HeadersValidations;
  onChange: (event: SelectChangeEvent, child: ReactNode) => void;
}> = props => {
  const classes = useStyles();

  const headers = useMemo(
    () => Object.entries(props.headersValidation.matched) ?? [],
    [props.headersValidation.matched]
  );

  const items = useMemo(() => {
    const selectedUnrecognizedHeaders = headers.map(([, value]) => value);

    return (
      props.headersValidation?.unrecognized?.map(unrecognizedHeader => ({
        disabled: selectedUnrecognizedHeaders.includes(unrecognizedHeader),
        value: unrecognizedHeader
      })) ?? []
    );
  }, [headers, props.headersValidation.unrecognized]);

  return (
    <>
      {headers.map(([key, value]) => (
        <div className={classes.container} data-testid={`${key}-row`} key={key}>
          <div className={classes.title} data-testid={`${key}-row-title`}>
            {NAMES[key]}
          </div>
          <Select
            className={classes.select}
            data-testid={`${key}-row-dropdown`}
            name={key}
            onChange={props.onChange}
            value={value}>
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            {items.map(option => (
              <MenuItem
                disabled={option.disabled}
                key={option.value}
                value={option.value}>
                {option.value}
              </MenuItem>
            ))}
          </Select>
        </div>
      ))}
    </>
  );
};

const HeaderMapping: FC<HeaderMappingProps> = props => {
  const [checked, toggleChecked] = useToggle(false);
  const [isInitiallyValid, setInitiallyValid] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [headersValidation, setHeadersValidation] =
    useState<HeadersValidations>({
      isValid: false,
      matched: {},
      recognized: {},
      unrecognized: []
    });

  const classes = useStyles();

  const onChange = useCallback((event: SelectChangeEvent) => {
    const { value, name } = event.target;

    setHeadersValidation(prev => ({
      ...prev,
      matched: {
        ...prev.matched,
        [name]: value
      }
    }));
  }, []);

  const onCancel = useCallback(() => {
    setOpen(false);
    props.onCancel();
  }, [props]);

  const onContinue = useCallback(() => {
    const headers = {
      ...headersValidation.recognized,
      ...headersValidation.matched
    };

    const columnIndexes = Object.entries(headers)?.reduce<[number, string][]>(
      (acc, [key, value]) => {
        const columnIndex =
          props.headers?.findIndex(column => column === value) ?? -1;

        return [...acc, [columnIndex, key]];
      },
      []
    );

    const data = props.rawData?.map(row => {
      return columnIndexes?.reduce((acc, [index, header]) => {
        return {
          ...acc,
          [header]: row[index]
        };
      }, {});
    });

    setOpen(false);
    props.onHeadersMatched(data ?? []);
  }, [headersValidation, props]);

  useEffect(() => {
    const normalizedFileHeaders =
      props.headers?.map(header => header.toLowerCase()?.trim()) ?? [];
    const normalizedSynonyms = Object.entries(props.synonyms ?? {})?.reduce<
      Record<string, string[]>
    >(
      (acc, [key, synonyms]) => ({
        ...acc,
        [key]: synonyms?.map(synonym => synonym.toLowerCase()?.trim())
      }),
      {}
    );

    const recognizedHeaders = Object.entries(normalizedSynonyms).reduce(
      (acc, [key, synonyms]) => {
        const headers = intersection(synonyms, normalizedFileHeaders);

        return headers?.length
          ? {
              ...acc,
              [key]: headers?.[0]
            }
          : acc;
      },
      {}
    );

    const unrecognizedHeaders = difference(
      normalizedFileHeaders,
      Object.values(recognizedHeaders)
    );

    const isRequiredHeadersValid = !!intersection(
      Object.keys(recognizedHeaders),
      REQUIRED_HEADERS
    ).length;
    const isOptionalHeadersValid =
      intersection(Object.keys(recognizedHeaders), OPTIONAL_HEADERS).length > 0;

    const headersToMatch = difference(
      [
        ...REQUIRED_HEADERS,
        ...(!isOptionalHeadersValid ? OPTIONAL_HEADERS : [])
      ],
      Object.keys(recognizedHeaders)
    )?.reduce((acc, value) => ({ ...acc, [value]: '' }), {});

    setHeadersValidation({
      isValid: isRequiredHeadersValid && isOptionalHeadersValid,
      matched: headersToMatch,
      recognized: recognizedHeaders,
      unrecognized: unrecognizedHeaders
    });

    setInitiallyValid(isRequiredHeadersValid && isOptionalHeadersValid);
    setOpen(!isRequiredHeadersValid || !isOptionalHeadersValid);
  }, [props.headers, props.synonyms]);

  useUpdateEffect(() => {
    if (!isInitiallyValid) return;
    onContinue();
  }, [isInitiallyValid]);

  useUpdateEffect(() => {
    const keys = [
      ...Object.keys(headersValidation.recognized),
      ...Object.entries(headersValidation.matched).reduce<string[]>(
        (acc, [key, value]) => (value ? [...acc, key] : acc),
        []
      )
    ];

    const isValid =
      !!intersection(keys, REQUIRED_HEADERS).length &&
      intersection(keys, OPTIONAL_HEADERS).length > 0;

    setHeadersValidation(prev => ({
      ...prev,
      isValid
    }));
  }, [headersValidation.matched]);

  return (
    <Dialog fullWidth maxWidth='sm' open={isOpen}>
      <DialogTitle>Matching Headers From Your File</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.message}>
          Select a column from your file to match required fields:
        </DialogContentText>
        <div>
          <SelectSection
            headersValidation={headersValidation}
            onChange={onChange}
          />
          {checked &&
            Object.entries(headersValidation.recognized)?.map(
              ([key, value]) => (
                <div
                  className={classes.container}
                  data-testid={`${key}-row`}
                  key={key}>
                  <div
                    className={classes.title}
                    data-testid={`${key}-row-title`}>
                    {NAMES[key]}
                  </div>
                  <Select
                    className={classes.select}
                    data-testid={`${key}-row-dropdown`}
                    disabled
                    value={value}>
                    <MenuItem value={value}>{value}</MenuItem>
                  </Select>
                </div>
              )
            )}
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <div className={classes.checkbox}>
          <Checkbox data-testid='checkbox' onChange={toggleChecked} />
          <Typography>Display automatically matched fields</Typography>
        </div>
        <div>
          <Button data-testid='cancel' onClick={onCancel}>
            Cancel
          </Button>
          <Button
            data-testid='submit'
            disabled={!headersValidation.isValid}
            onClick={onContinue}>
            Continue
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default HeaderMapping;
