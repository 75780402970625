import AccessControl from '@/components/access-control/AccessControl.component';
import CollapsibleTable from '@/components/collapsible-table';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import ContributionService from '@/services/Contribution.service';
import { PlanService } from '@/services/Plan.service';
import { Box, Button, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { sortBy } from 'lodash';
import React, { FC, useCallback, useMemo } from 'react';
import { useToggle } from 'react-use';

import { Retry } from './Retry.component';
import TradeRequestTableCell from './TradeRequestTableCell.component';

type TradeRequestProps = {
  planId: number;
  ucid: string;
  sponsorId: number;
};

const columns: GridColDef[] = [
  {
    field: 'participantName',
    headerName: 'Participant'
  },
  {
    field: 'transactionType',
    headerName: 'Type',
    width: 110
  },
  {
    field: 'amount',
    headerName: 'Amount',
    width: 70
  },
  {
    field: 'status',
    headerName: 'Execution Status',
    width: 150
  },
  {
    field: 'workflowStatus',
    headerName: 'Workflow Status',
    width: 150
  },
  {
    field: 'fundingSource',
    headerName: 'Funding Source'
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 110
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    width: 110
  },
  {
    field: 'tracerId',
    headerName: 'Tracer ID',
    width: 110
  }
];

export const Corrections: FC<TradeRequestProps> = props => {
  const [isRetry, toggleRetry] = useToggle(false);

  const contributionDetails = useQuery(
    [
      ContributionService.getContributionDetails.name,
      props.planId,
      props.sponsorId,
      props.ucid
    ],
    () =>
      ContributionService.getContributionDetails({
        includeCorrections: true,
        planId: props.planId,
        sponsorId: props.sponsorId,
        ucid: props.ucid
      }),
    {
      enabled: !!props.planId && !!props.ucid && !!props.sponsorId
    }
  );

  const corrections = useQuery(
    [PlanService.getAdvancedCorrections.name, props.planId, props.ucid],
    () => PlanService.getAdvancedCorrections(props.planId, props.ucid),
    {
      enabled: !!props.planId && !!props.ucid && !!props.sponsorId
    }
  );

  const rows = useMemo(
    () =>
      sortBy(corrections.data ?? [], participant =>
        participant.participantName?.toLowerCase()
      ),
    [corrections.data]
  );

  const onSuccess = useCallback(async () => {
    await contributionDetails.refetch();
    await corrections.refetch();
  }, [contributionDetails, corrections]);

  return (
    <Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 2
        }}>
        <Box
          data-testid='correction-table-header'
          sx={{ alignItems: 'center', display: 'flex' }}>
          <Typography ml={2} mr={2} variant='h6'>
            Correction
          </Typography>
          <Typography color={grey[600]}>
            Submitted {contributionDetails.data?.payrollStartedDate}
          </Typography>
        </Box>
        <Box sx={{ marginRight: 2 }}>
          <AccessControl
            requires={[FeatureLevelPermissions.WRITE_CONTRIBUTIONS_ACTION]}>
            <Button
              data-testid='correction-retry'
              onClick={toggleRetry}
              variant='outlined'>
              Retry Correction Requests
            </Button>
          </AccessControl>
        </Box>
      </Box>
      <Retry
        isOpen={isRetry}
        onClose={toggleRetry}
        onSuccess={onSuccess}
        planId={props.planId}
        type='advancedCorrectionRequests'
        ucid={props.ucid}
      />
      <CollapsibleTable
        cellComponent={TradeRequestTableCell}
        columns={columns}
        data-testid='correction-table'
        tableData={rows}
      />
    </Box>
  );
};
