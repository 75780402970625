import { Card } from './Card.component';

export {
  Card,
  CardContent,
  CardHeader,
  CardPlaceholder
} from './Card.component';

export type {
  CardContentProps,
  CardHeaderProps,
  CardPlaceholderProps,
  CardProps
} from './Card.component';

export default Card;
