import { CardPlaceholder } from '@/components/card';
import { ActionCenterService } from '@/services/ActionCenter.service';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import React from 'react';

export interface Column {
  id: string;
  label: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  sx?: SxProps;
  rowComponent?: (value: any) => JSX.Element;
}

interface ActionCenterPreviewDialogProps {
  title: string;
  handleClose: () => void;
  open: boolean;
  columns: Column[];
  tableData: Record<string, any>[];
  onConfirm?: () => any;
  noDataMessage: string;
}

const ActionCenterPreviewDialog: React.FunctionComponent<
  ActionCenterPreviewDialogProps
> = ({
  title,
  handleClose,
  open,
  columns,
  tableData,
  onConfirm,
  noDataMessage
}) => {
  const { data: documentKeys } = useQuery(
    ['ActionCenterService.getDocumentKeys', 'plan'],
    () => ActionCenterService.getDocumentKeys('plan')
  );

  const options: string[] = [];

  documentKeys?.forEach(documentKey => {
    if (documentKey.entity === 'plan') {
      options.push(documentKey.documentKey);
    }
  });

  return (
    <Dialog
      data-testid='action-center-preview-dialog'
      maxWidth='md'
      onClose={handleClose}
      open={open}>
      <DialogTitle sx={{ pl: '16px' }}>{title}</DialogTitle>
      <DialogContent sx={{ minWidth: '700px', p: '0px' }}>
        <TableContainer sx={{ maxHeight: 500 }}>
          <Table
            aria-label='sticky table'
            data-testid='action-center-preview-dialog-table'
            stickyHeader>
            <TableHead data-testid='action-center-preview-dialog-table-head'>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    align={column.align}
                    key={column.id}
                    style={{ padding: '5px 16px' }}
                    sx={column.sx}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody data-testid='action-center-preview-dialog-table-body'>
              {tableData.map((row, index) => {
                return (
                  <TableRow
                    data-testid={`action-center-preview-dialog-table-row-${index}`}
                    key={JSON.stringify(row)}>
                    {columns.map(column => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          align={column.align}
                          data-field={column.id}
                          key={column.id}
                          sx={{ p: '10px 16px' }}>
                          {column.rowComponent
                            ? column.rowComponent(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {!tableData.length && (
          <CardPlaceholder
            data-testid='no-data-action-center-preview-dialog'
            title={noDataMessage}
          />
        )}
      </DialogContent>
      <DialogActions sx={{ p: '15px 20px' }}>
        <Button onClick={handleClose}>Keep Editing</Button>
        {onConfirm && !!tableData.length && (
          <Button
            data-testid='action-center-preview-dialog-confirm-button'
            onClick={onConfirm}
            variant='contained'>
            Confirm
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default ActionCenterPreviewDialog;
