import { PendingTransactionStatus } from '@vestwell-sub-accounting/models/common/PendingTransactionStatus';

export const PendingTransactionStatusColorMap: Partial<
  Record<PendingTransactionStatus, 'info' | 'neutral' | 'success'>
> = {
  cancelled: 'success',
  confirmed: 'neutral',
  deleted: 'info',
  pending: 'neutral'
};
