import { VestwellStaffUser } from '@/models/VestwellStaffUser.model';
import ApiService from '@/services/Api.service';

export type FormattedVestwellStaff = VestwellStaffUser & { label: string };

class AuthZService {
  static async getVestwellStaff(): Promise<VestwellStaffUser[]> {
    return ApiService.getJson(`/authz-api/vestwell-staff`);
  }

  static formatVestwellStaffList(
    staff: VestwellStaffUser[]
  ): (VestwellStaffUser & { label: string })[] {
    const displayStaff: FormattedVestwellStaff[] = [];

    // create labels for the staff by finding duplicate names and adding email to the end
    const nameCounts = staff.reduce(
      (acc, staffUser) => {
        if (staffUser.name) {
          acc[staffUser.name] = (acc[staffUser.name] || 0) + 1;
        }
        return acc;
      },
      {} as Record<string, number>
    );

    staff
      .filter(staffUser => staffUser.userId) // remove missing userIds
      .forEach(staffUser => {
        const displayStaffUser: VestwellStaffUser & { label: string } = {
          ...staffUser,
          label: ''
        };
        if (displayStaffUser.name && nameCounts[displayStaffUser.name] > 1) {
          displayStaffUser.label = `${displayStaffUser.name} (${displayStaffUser.email})`;
        } else if (displayStaffUser.name) {
          displayStaffUser.label = displayStaffUser.name;
        } else {
          displayStaffUser.label = displayStaffUser.email;
        }
        displayStaff.push(displayStaffUser);
      });

    // sort by label
    displayStaff.sort((a, b) => a.label?.localeCompare(b.label || '') || 0);

    return displayStaff;
  }
}
export default AuthZService;
