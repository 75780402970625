import { createSvgIcon, SvgIconProps } from '@mui/material';

import React, { FC } from 'react';

const CorrectedIcon: FC<SvgIconProps> = createSvgIcon(
  <path
    d='M10 0.256348C8.68678 0.256348 7.38642 0.515005 6.17317 1.01755C4.95991 1.5201 3.85752 2.25669 2.92893 3.18528C1.05357 5.06064 0 7.60418 0 10.2563C0 12.9085 1.05357 15.4521 2.92893 17.3274C3.85752 18.256 4.95991 18.9926 6.17317 19.4951C7.38642 19.9977 8.68678 20.2563 10 20.2563C12.6522 20.2563 15.1957 19.2028 17.0711 17.3274C18.9464 15.4521 20 12.9085 20 10.2563H18C18 12.3781 17.1571 14.4129 15.6569 15.9132C14.1566 17.4135 12.1217 18.2563 10 18.2563C7.87827 18.2563 5.84344 17.4135 4.34315 15.9132C2.84285 14.4129 2 12.3781 2 10.2563C2 8.13462 2.84285 6.09978 4.34315 4.59949C5.84344 3.0992 7.87827 2.25635 10 2.25635V0.256348ZM16.78 1.25635C16.61 1.25635 16.43 1.32635 16.3 1.45635L15.08 2.66635L17.58 5.16635L18.8 3.95635C19.06 3.69635 19.06 3.25635 18.8 3.00635L17.25 1.45635C17.12 1.32635 16.95 1.25635 16.78 1.25635ZM14.37 3.37635L7 10.7563V13.2563H9.5L16.87 5.87635L14.37 3.37635Z'
    fill='#2196F3'
  />,
  'AccountIcon'
);

CorrectedIcon.defaultProps = {
  viewBox: '0 0 20 21'
};

CorrectedIcon.displayName = 'CorrectedIcon';

export default CorrectedIcon;
