import { CardPlaceholder } from '@/components/card';
import {
  AuditLogsFilters,
  TableTriggeredFilters
} from '@/models/AuditLogs.model';
import { PlanService } from '@/services/Plan.service';
import { Search, WarningAmber } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import {
  DataGridPro,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridColDef,
  GridSortDirection
} from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import dayjs from 'dayjs';
import React, { useState } from 'react';

import { CustomDetailPanelToggle } from './CustomDetailPanelToggle';
import { DetailPanel } from './DetailPanelComponent';

type AuditLogTableProps = {
  planId: number;
  filters: AuditLogsFilters;
  triggerFiltersFromTable: (filters: TableTriggeredFilters) => void;
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
};

const columns: GridColDef[] = [
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    renderCell: params => (
      <CustomDetailPanelToggle id={params.id} value={params.value} />
    )
  },
  {
    field: 'createdOn',
    headerName: 'Date',
    valueFormatter: params => dayjs(params.value).format('MM/DD/YYYY HH:mm A'),
    width: 200
  },
  {
    field: 'initiatorType',
    headerName: 'Initiator Type',
    sortable: false,
    width: 150
  },
  {
    field: 'eventTypeName',
    headerName: 'Action',
    sortable: false,
    width: 270
  }
];

const getDetailPanelHeight = () => 230;

export const PlanAuditLogTable: React.FC<AuditLogTableProps> = props => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState<GridSortDirection>('desc');
  const [orderBy, setOrderBy] = useState(undefined);

  const auditLogsQuery = useQuery(
    [
      'PlanService.getAuditLogs',
      props.planId,
      rowsPerPage,
      props.pageNumber,
      orderBy,
      order,
      props.filters
    ],
    () =>
      PlanService.getAuditLogs({
        ...props.filters,
        order,
        orderBy,
        planId: props.planId,
        skip: props.pageNumber,
        take: rowsPerPage
      }),
    {
      enabled: !!props.planId,
      staleTime: Infinity
    }
  );

  return (
    <Box overflow='auto' width='100%'>
      <DataGridPro
        autoHeight
        columns={columns}
        data-testid='plan-auditlogs-table'
        disableColumnMenu
        disableColumnResize
        disableRowSelectionOnClick
        getDetailPanelContent={params => (
          <DetailPanel
            {...params}
            lastRowId={
              auditLogsQuery?.data?.logs?.length
                ? auditLogsQuery.data.logs[auditLogsQuery.data.logs.length - 1]
                    .id
                : undefined
            }
            triggerFiltersFromTable={props.triggerFiltersFromTable}
          />
        )}
        getDetailPanelHeight={getDetailPanelHeight}
        getRowId={row => row.id}
        loading={auditLogsQuery.isLoading}
        onPaginationModelChange={model => {
          props.setPageNumber(model.page);
          setRowsPerPage(model.pageSize);
        }}
        onSortModelChange={model => {
          setOrder(model[0].sort);
          setOrderBy(model[0].field);
          props.setPageNumber(0);
        }}
        pageSizeOptions={[10, 25, 50]}
        pagination
        paginationMode='server'
        paginationModel={{
          page: props.pageNumber,
          pageSize: rowsPerPage
        }}
        rowCount={auditLogsQuery?.data?.total || 0}
        rows={auditLogsQuery?.data?.logs || []}
        slots={{
          noRowsOverlay: () => (
            <Stack
              alignItems='center'
              data-testid='no-audit-logs-data-table'
              height='100%'
              justifyContent='center'>
              <CardPlaceholder
                icon={
                  auditLogsQuery.isError ? (
                    <WarningAmber fontSize='inherit' />
                  ) : (
                    <Search fontSize='inherit' />
                  )
                }
                subtitle={
                  auditLogsQuery.isError
                    ? 'Error retrieving audit logs'
                    : 'No audit logs available'
                }
              />
            </Stack>
          )
        }}
        sortModel={[{ field: 'createdOn', sort: order }]}
        sortingOrder={['desc', 'asc']}
        sx={{
          '.MuiDataGrid-columnHeaders': {
            maxHeight: '40px !important',
            minHeight: '40px !important'
          },
          '.MuiDataGrid-columnSeparator': {
            display: 'none'
          },
          '.MuiDataGrid-row': {
            color: 'black',
            fontSize: theme => theme.spacing(2)
          },
          border: '0px !important'
        }}
      />
    </Box>
  );
};
