import CollapsibleTable from '@/components/collapsible-table';
import SimpleTabs, { SimpleTabsProps, TabData } from '@/components/simple-tabs';
import {
  RolloverTradeRequestDto,
  RolloverTradeRequestTable,
  RolloverTransactionDto
} from '@/models/RolloversDTO.model';
import { SubaExecutionStatus } from '@/models/SubaExecution.model';
import ParticipantService from '@/services/Participant.service';
import { Card, CardContent } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import RolloverTradeRequestsTableCell from './RolloverTradeRequestsTableCell.component';
import RolloverTransactionsTableCell from './RolloverTransactionsTableCell.component';

const transactionColumns: GridColDef[] = [
  { field: 'id', headerName: 'Tx ID' },
  { field: 'tradeDate', headerName: 'Trade Date' },
  { field: 'effectiveDate', headerName: 'Effective Date' },
  { field: 'fundingSource', headerName: 'Funding Source' },
  { field: 'transactionSubtype', headerName: 'Tx Subtype' },
  { field: 'ticker', headerName: 'Ticker' },
  { field: 'dollars', headerName: 'Amount' },
  { field: 'status', headerName: 'Status' },
  { field: 'tracerId', headerName: 'Tracer ID' }
];

const tradeRequestColumns: GridColDef[] = [
  { field: 'fundingSource', headerName: 'Funding Source' },
  { field: 'amount', headerName: 'Amount' },
  { field: 'transactionType', headerName: 'Transaction Type' },
  { field: 'status', headerName: 'Execution Status' },
  { field: 'workflowStatus', headerName: 'Workflow Status' },
  { field: 'createdAt', headerName: 'Created At' },
  { field: 'updatedAt', headerName: 'Updated At' },
  { field: 'tracerId', headerName: 'Tracer ID' },
  { field: 'action', headerName: '' }
];

type RolloverDetailsDataGridsCardProps = {
  participantId: string;
  rolloverId: string;
};

const RolloverDetailsDataGridsCard: React.FunctionComponent = () => {
  const { participantId, rolloverId } =
    useParams<RolloverDetailsDataGridsCardProps>();

  const transactions = useQuery<RolloverTransactionDto[]>(
    ['ParticipantService.getRolloverTransactions', participantId, rolloverId],
    () => {
      return ParticipantService.getRolloverTransactions(
        +participantId,
        +rolloverId
      );
    },
    {
      staleTime: Infinity
    }
  );

  const tradeRequests = useQuery<RolloverTradeRequestDto[]>(
    ['ParticipantService.getRolloverTradeRequests', participantId, rolloverId],
    () => {
      return ParticipantService.getRolloverTradeRequests(
        +participantId,
        +rolloverId
      );
    },
    {
      staleTime: Infinity
    }
  );

  const tradeRequestTableData = useMemo(() => {
    return (
      tradeRequests.data?.map(tradeRequest => {
        return {
          amount: +tradeRequest.request.amount,
          createdAt: tradeRequest.createdAt,
          fundingSource: tradeRequest.fundingSource,
          id: tradeRequest.id,
          status: tradeRequest.status as SubaExecutionStatus,
          subaResult: tradeRequest.subaResult,
          tracerId: tradeRequest.tracerId,
          transactionType: tradeRequest.request.depositCode,
          updatedAt: tradeRequest.updatedAt,
          workflowStatus: tradeRequest.workflowStatus
        } as RolloverTradeRequestTable;
      }) ?? []
    );
  }, [tradeRequests.data]);

  const makeTabsElement = (): SimpleTabsProps => {
    const RolloverTransactionsTab: TabData = {
      component: (
        <CollapsibleTable
          cellComponent={RolloverTransactionsTableCell}
          columns={transactionColumns}
          tableData={transactions.data || []}
        />
      ),
      label: 'Transactions',
      path: `/participant/${participantId}/rollovers/${rolloverId}/transactions`
    };
    const RolloverTradeRequestsTab: TabData = {
      component: (
        <CollapsibleTable
          cellComponent={RolloverTradeRequestsTableCell}
          columns={tradeRequestColumns}
          tableData={tradeRequestTableData}
        />
      ),
      label: 'Trade Requests',
      path: `/participant/${participantId}/rollovers/${rolloverId}/tradeRequests`
    };

    const registeredTabs = [RolloverTradeRequestsTab, RolloverTransactionsTab];

    const displayTabs: SimpleTabsProps = {
      tabs: registeredTabs,
      tabsAriaLabel: 'rollover-details-data-grid-tabs'
    };

    return displayTabs;
  };

  const tabs = makeTabsElement();

  return (
    <Card elevation={0} sx={{ marginTop: 3 }} variant='outlined'>
      <CardContent sx={{ padding: 0 }}>
        <SimpleTabs {...tabs} />
      </CardContent>
    </Card>
  );
};

export default RolloverDetailsDataGridsCard;
