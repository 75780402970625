import AccessControl from '@/components/access-control/AccessControl.component';
import Card, { CardContent } from '@/components/card';
import DatePicker from '@/components/date-picker/DatePicker';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import UserManagementService from '@/services/UserManagement.service';
import DOMInteraction from '@/utils/DOMInteraction';
import { FileDownloadOutlined } from '@mui/icons-material';
import {
  Button,
  Divider,
  FormControl,
  Tooltip,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useMutation } from '@tanstack/react-query';

import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import React, { FC, useCallback, useState } from 'react';

export const UserManagementReports: FC = () => {
  const { showSnackbar } = useSnackbar();
  const [startDate, setStartDate] = useState(
    dayjs().subtract(2, 'week').format('MM/DD/YYYY')
  );
  const [endDate, setEndDate] = useState(dayjs().format('MM/DD/YYYY'));

  const downloadLoginReport = useMutation(
    [`UserManagementService.getUsersReports`, 'login_activity'],
    async () => {
      return UserManagementService.getUsersReports(
        'login_activity',
        'Login Report',
        startDate,
        endDate
      );
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Error downloading document',
          severity: 'error'
        });
      },
      onSuccess: data => {
        DOMInteraction.triggerDownload(data, `Login Report.csv`);
      }
    }
  );

  const downloadAuditHistory = useMutation(
    [`UserManagementService.getUsersReports`, 'audit'],
    async () => {
      return UserManagementService.getUsersReports(
        'audit',
        'Audit History',
        startDate,
        endDate
      );
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Error downloading document',
          severity: 'error'
        });
      },
      onSuccess: data => {
        DOMInteraction.triggerDownload(data, `Audit History.csv`);
      }
    }
  );

  const onStartDateChange = useCallback(async event => {
    setStartDate(event.target.value);
  }, []);

  const onEndDateChange = useCallback(async event => {
    setEndDate(event.target.value);
  }, []);

  const onDownloadLoginReport = useCallback(async () => {
    await downloadLoginReport.mutateAsync();
  }, [downloadLoginReport]);

  const onDownloadAuditHistory = useCallback(async () => {
    await downloadAuditHistory.mutateAsync();
  }, [downloadAuditHistory]);

  return (
    <AccessControl
      requires={[FeatureLevelPermissions.READ_USER_MANAGEMENT_REPORTS]}>
      <Card data-testid='balance-card'>
        <CardContent>
          <Typography mb={2} variant='h6'>
            Generate all-user report
          </Typography>
          <Formik
            initialValues={{ endDate: new Date(), startDate: new Date() }}
            onSubmit={() => {}}>
            <Form>
              <FormControl fullWidth>
                <Grid
                  container
                  data-testid='userManagementReportsContainer'
                  direction='row'
                  spacing={2}>
                  <Grid>
                    <DatePicker
                      data-testid='startDate'
                      label='Start Date'
                      name='startDate'
                      onChange={onStartDateChange}
                      size='small'
                      value={startDate}
                      variant='outlined'
                    />
                  </Grid>
                  <Grid>
                    <DatePicker
                      data-testid='endDate'
                      label='End Date'
                      name='endDate'
                      onChange={onEndDateChange}
                      size='small'
                      value={endDate}
                      variant='outlined'
                    />
                  </Grid>
                  <Divider
                    flexItem
                    orientation='vertical'
                    sx={{ margin: '0 16px' }}
                  />
                  <Grid>
                    <Tooltip title='Click to download Login Report'>
                      <Button
                        color='primary'
                        data-testid='loginReportDownloadButton'
                        disabled={downloadLoginReport.isLoading}
                        onClick={onDownloadLoginReport}
                        size='large'
                        startIcon={<FileDownloadOutlined />}
                        variant='outlined'>
                        Login Report
                      </Button>
                    </Tooltip>
                  </Grid>
                  <Grid>
                    <Tooltip title='Click to download Audit History'>
                      <Button
                        color='primary'
                        data-testid='auditHistoryDownloadButton'
                        disabled={downloadAuditHistory.isLoading}
                        onClick={onDownloadAuditHistory}
                        size='large'
                        startIcon={<FileDownloadOutlined />}
                        variant='outlined'>
                        Audit History
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
              </FormControl>
            </Form>
          </Formik>
        </CardContent>
      </Card>
    </AccessControl>
  );
};
