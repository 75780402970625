import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class MaxAmount {
  convertToApiState = Helper.convertValueFromNumericStringToNumber;

  disabled: boolean;

  output: string | undefined;

  setDisabled = (value: boolean): void => {
    this.disabled = value;
  };

  constructor(props: Record<string, any>) {
    this.disabled = !props.isAllowed;

    this.output = props.maxAmount;
  }
}

export default MaxAmount;
