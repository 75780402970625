import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class AdoptionAgreementDocumentProvider {
  convertToApiState = Helper.convertValueToApiState;

  options = ['FT Williams', 'VW Legacy', 'Custom'];

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.output = props.adoptionAgreementDocumentProvider;
  }
}

export default AdoptionAgreementDocumentProvider;
