import AccessControl from '@/components/access-control/AccessControl.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { Model } from '@/models/ops/investments/Model.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { ModelService } from '@/services/ops/investments/Model.service';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { useMutation, useQuery } from '@tanstack/react-query';

import { useFormik } from 'formik';
import React, { useCallback, useState } from 'react';
import { useDebounce, useToggle } from 'react-use';
import * as yup from 'yup';

const MODEL_TYPES = [
  { label: 'Managed Account', value: 'goal' },
  { label: 'Target Series', value: 'target' },
  { label: 'Risk Series', value: 'risk' }
];
const REASONS_FOR_UPDATE = [
  {
    label: 'Participant missing default election',
    value: 'qdia_account_initialization'
  },
  { label: 'Quarterly rebalance', value: 'quarterly_rebalance' },
  { label: 'CME Update', value: 'cme_portfolio_update' },
  { label: 'Account Nonzero', value: 'account_nonzero' },
  { label: 'Conversion', value: 'conversion' },
  { label: 'Rollover', value: 'rollover' },
  { label: 'Withdrawal', value: 'withdrawal' },
  { label: 'Other', value: 'unknown' }
];

const StyledAccordion = styled(MuiAccordion)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  marginBottom: theme.spacing(2)
}));

const StyledAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  padding: theme.spacing(2)
}));

const modelUpdateSchema = (isManagedAccount: boolean) =>
  yup.object().shape({
    participantIds: yup
      .array()
      .of(yup.number().required())
      .min(1, 'Must have at least one participant')
      .required('Required'),
    ...(isManagedAccount
      ? { modelType: yup.string().oneOf(MODEL_TYPES.map(e => e.value)) }
      : {})
  });

const modelIdSchema = yup.object().shape({
  modelId: yup.number().required()
});

const validateModelId = (
  modelType: string,
  modelId: number,
  setErrors,
  fetchedData?: Model
) => {
  if (modelId < (modelType === 'target' ? 1 : 2)) {
    setErrors({
      modelId: `Minimum value is ${modelType === 'target' ? 1 : 2}`
    });
  } else if (fetchedData && fetchedData?.modelType !== modelType) {
    setErrors({ modelId: "This Model ID doesn't exist" });
  } else {
    setErrors({});
  }
};

export type BulkUpdateModelRequest = {
  participantIds: number[];
  modelType: string;
  modelId?: number;
  reason?: string;
  rebalanceAfterUpdate?: boolean;
};

export type BulkUpdateUpdateResponse = {
  failed: { id: number; reasonForFailure: string }[];
  successful: { id: number; modelId: number; modelName: string }[];
};

export const ModelUpdate: React.FC = () => {
  const [participantIds, setParticipantIds] = useState('');
  const [reasonForUpdate, setReasonForUpdate] = useState(
    REASONS_FOR_UPDATE[0].value
  );
  const [isRebalanceAfterUpdate, toggleRebalance] = useToggle(false);
  const [isModelIdChanged, toggleModelIdChanged] = useToggle(false);
  const [responseView, setResponseView] = useState('');
  const { showSnackbar } = useSnackbar();

  const form = useFormik({
    enableReinitialize: false,
    initialValues: {
      modelType: MODEL_TYPES[0].value,
      participantIds: []
    },
    onSubmit: () => undefined,
    validateOnChange: true,
    validationSchema: () => modelUpdateSchema(form.values.modelType === 'goal')
  });

  const modelIdForm = useFormik({
    enableReinitialize: false,
    initialValues: {
      modelId: 0
    },
    onSubmit: () => undefined,
    validateOnChange: true,
    validationSchema: modelIdSchema
  });

  const isManagedAccount = form.values.modelType === 'goal';

  const modelSearchResult = useQuery(
    [ModelService.getModelById.name, modelIdForm.values.modelId],
    () => ModelService.getModelById(modelIdForm.values.modelId),
    {
      enabled: false,
      onError: () => {
        if (!modelIdForm.errors?.modelId) {
          modelIdForm.setFieldError('modelId', "This Model ID doesn't exist");
        }
      },
      onSettled: () => {
        toggleModelIdChanged(false);
      },
      onSuccess: res => {
        if (
          res.modelType !== form.values.modelType &&
          !modelIdForm.errors?.modelId
        ) {
          modelIdForm.setFieldError('modelId', "This Model ID doesn't exist");
        }
      }
    }
  );

  useDebounce(
    async () => {
      if (!modelSearchResult.isFetching) {
        await modelSearchResult.refetch();
      }
    },
    1000,
    [modelIdForm.values.modelId]
  );

  const bulkUpdate = useMutation(
    () =>
      ModelService.bulkUpdateModel({
        modelId: isManagedAccount ? undefined : modelIdForm.values.modelId,
        modelType: form.values.modelType,
        participantIds: form.values.participantIds,
        reason: isManagedAccount ? reasonForUpdate : undefined,
        rebalanceAfterUpdate: isManagedAccount
          ? undefined
          : isRebalanceAfterUpdate
      }),
    {
      onError: () => {
        showSnackbar({
          message:
            form.values.modelType === 'goal'
              ? 'Error in updating model'
              : 'Failed',
          severity: 'error'
        });
      },
      onSuccess: res => {
        if (form.values.modelType === 'goal') {
          showSnackbar({
            message: res.failed.length
              ? 'Error! Rebalance request could not be submitted'
              : 'Request to update to Managed Account has been submitted successfully.',
            severity: res.failed.length ? 'error' : 'success'
          });
        }

        setResponseView(form.values.modelType);
      }
    }
  );

  const onParticipantsIdChange = useCallback(event => {
    const splitList = event.target.value
      .split(/[\r?\n,]/)
      .filter(e => e)
      .map(e => +e);
    form.setFieldValue('participantIds', [...new Set(splitList)]);
    setParticipantIds(event.target.value);
  }, []);

  const onModelTypeChange = useCallback(
    event => {
      form.setFieldValue('modelType', event.target.value);
      validateModelId(
        event.target.value,
        modelIdForm.values.modelId,
        modelIdForm.setErrors,
        modelSearchResult.data
      );
    },
    [modelIdForm.values.modelId]
  );

  const onModelIdChange = useCallback(
    async event => {
      if (event.target.value.match(/[^0-9]/)) return;
      if (isNaN(+event.target.value)) return;
      if (+event.target.value > Number.MAX_SAFE_INTEGER) return;
      toggleModelIdChanged(true);
      await modelIdForm.setFieldValue('modelId', +event.target.value);
      await validateModelId(
        form.values.modelType,
        +event.target.value,
        modelIdForm.setErrors
      );
    },
    [form.values.modelType]
  );

  const onUpdateClick = useCallback(() => {
    bulkUpdate.mutateAsync();
  }, []);

  const onResetClick = useCallback(() => {
    form.resetForm();
    modelIdForm.resetForm();
    setParticipantIds('');
    bulkUpdate.reset();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid container direction='column' item mr={3} spacing={2} xs={3}>
        <Grid item>
          <Typography variant='body1'>Participant IDs</Typography>
          <Typography variant='body2'>Comma or line-break delimited</Typography>
        </Grid>
        <Grid item>
          <TextField
            disabled={bulkUpdate.isLoading}
            error={!!form.errors.participantIds}
            fullWidth
            helperText={
              Array.isArray(form.errors?.participantIds)
                ? 'Invalid participant id'
                : form.errors?.participantIds
            }
            minRows={8}
            multiline
            onChange={onParticipantsIdChange}
            placeholder='Separate participant IDs by comma or enter each ID in a new line'
            value={participantIds}
          />
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <InputLabel id='model-type'>Model Type</InputLabel>
            <Select
              data-testid='model-type-select'
              disabled={bulkUpdate.isLoading}
              label='Model Type'
              labelId='model-type'
              onChange={onModelTypeChange}
              size='small'
              value={form.values.modelType}>
              {MODEL_TYPES.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {isManagedAccount && (
          <Grid item>
            <FormControl fullWidth>
              <InputLabel id='reason-for-update'>Reason for update</InputLabel>
              <Select
                data-testid='model-type-select'
                disabled={bulkUpdate.isLoading}
                label='Reason for update'
                labelId='reason-for-update'
                onChange={event => setReasonForUpdate(event.target.value)}
                size='small'
                value={reasonForUpdate}>
                {REASONS_FOR_UPDATE.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {!isManagedAccount && (
          <Grid item>
            <TextField
              disabled={bulkUpdate.isLoading}
              error={!!modelIdForm.errors.modelId}
              fullWidth
              helperText={modelIdForm.errors.modelId}
              label='Model ID'
              onChange={onModelIdChange}
              size='small'
              value={modelIdForm.values.modelId}
            />
          </Grid>
        )}
        {!isManagedAccount && (
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isRebalanceAfterUpdate}
                  disabled={bulkUpdate.isLoading}
                  onChange={toggleRebalance}
                />
              }
              label='Rebalance after update'
            />
          </Grid>
        )}
        {isManagedAccount && (
          <Grid item>
            <Typography variant='caption'>
              This action will trigger the GOE engine and rebalance for the
              selected participants.
            </Typography>
          </Grid>
        )}
        <Grid item>
          <Stack direction='row' spacing={2}>
            <AccessControl
              requires={[FeatureLevelPermissions.WRITE_INVESTMENTS_MODIFY]}>
              <LoadingButton
                disabled={
                  !(
                    form.isValid &&
                    form.dirty &&
                    (isManagedAccount ||
                      (modelIdForm.isValid && modelIdForm.dirty))
                  ) || isModelIdChanged
                }
                loading={bulkUpdate.isLoading}
                onClick={onUpdateClick}
                variant='contained'>
                Update Model
              </LoadingButton>
            </AccessControl>
            <Button
              disabled={bulkUpdate.isLoading}
              onClick={onResetClick}
              variant='text'>
              RESET
            </Button>
          </Stack>
        </Grid>
      </Grid>
      {bulkUpdate.data &&
        (responseView !== 'goal' || !!bulkUpdate.data.failed.length) && (
          <>
            {' '}
            <Divider flexItem orientation='vertical'></Divider>
            <Grid item xs={7}>
              <Typography mb={4} variant='body1'>
                Results
              </Typography>

              {responseView !== 'goal' && (
                <StyledAccordion>
                  <MuiAccordionSummary
                    aria-controls='panel1a-content'
                    disabled={!bulkUpdate.data.successful.length}
                    expandIcon={<ExpandMoreIcon />}
                    id='panel1a-header'>
                    <Grid container>
                      <Grid item lg={2}>
                        <Stack direction='row' spacing={1}>
                          <CheckCircleOutlinedIcon color='success' />
                          <Typography color='success.main'>Success</Typography>
                        </Stack>
                      </Grid>
                      <Grid item lg={8}>
                        <Typography align='center' color='common.black'>
                          {bulkUpdate.data.successful.length} participant(s)
                        </Typography>
                      </Grid>
                    </Grid>
                  </MuiAccordionSummary>
                  <StyledAccordionDetails>
                    <Typography
                      color={theme => theme.palette.grey[700]}
                      data-testid='new-model-heading'
                      variant='caption'>
                      New Model
                    </Typography>
                    <Typography variant='body1'>
                      {bulkUpdate.data.successful?.[0]?.modelId} |{' '}
                      {bulkUpdate.data.successful?.[0]?.modelName}
                    </Typography>
                    <Typography
                      color={theme => theme.palette.grey[700]}
                      data-testid='participant-ids-heading'
                      variant='caption'>
                      Participant IDs
                    </Typography>
                    <Typography variant='body1'>
                      {bulkUpdate.data.successful.map(e => e.id).join(', ')}
                    </Typography>
                  </StyledAccordionDetails>
                </StyledAccordion>
              )}
              <StyledAccordion>
                <MuiAccordionSummary
                  aria-controls='panel2a-content'
                  disabled={!bulkUpdate.data.failed.length}
                  expandIcon={<ExpandMoreIcon />}
                  id='panel2a-header'>
                  <Grid container>
                    <Grid item lg={2}>
                      <Stack direction='row' spacing={1}>
                        <WarningAmberOutlinedIcon color='error' />
                        <Typography color='error.main'>Failed</Typography>
                      </Stack>
                    </Grid>
                    <Grid item lg={8}>
                      <Typography align='center' color='common.black'>
                        {bulkUpdate.data.failed.length} participant(s)
                      </Typography>
                    </Grid>
                  </Grid>
                </MuiAccordionSummary>
                <StyledAccordionDetails>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Participant ID</TableCell>
                          <TableCell>Reason</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {bulkUpdate.data.failed.map(row => (
                          <TableRow key={row.id}>
                            <TableCell>{row.id}</TableCell>
                            <TableCell>{row.reasonForFailure}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </StyledAccordionDetails>
              </StyledAccordion>
            </Grid>
          </>
        )}
    </Grid>
  );
};
