import { useUserToken } from '@/contexts/UserTokenContext';
import { Permissions } from '@/models/UserPermissions.model';
import TpaService from '@/services/Tpa.service';
import { useQuery } from '@tanstack/react-query';

type PermissionStatus = 'loading' | 'allowed' | 'forbidden';

type PermissionStatusObj = {
  isLoading: boolean;
  isAllowed: boolean;
  isForbidden: boolean;
};

const makeStatusObj = (status: PermissionStatus): PermissionStatusObj => {
  return {
    isAllowed: status === 'allowed',
    isForbidden: status === 'forbidden',
    isLoading: status === 'loading'
  };
};

const useHasPermissions = (params: {
  requires?: Permissions[];
  requiresOneOf?: Permissions[];
  hideFromTPA?: boolean;
}): PermissionStatusObj => {
  const { token, userHasValidToken } = useUserToken();
  const { requires, requiresOneOf, hideFromTPA } = params;

  const tpaRequest = useQuery<boolean>(
    ['TpaService.isCurrentUserTPA'],
    () => TpaService.isCurrentUserTPA(token),
    {
      enabled:
        userHasValidToken && (!!requires || !!requiresOneOf || !!hideFromTPA),
      staleTime: Infinity
    }
  );

  if (
    requires === undefined &&
    requiresOneOf === undefined &&
    hideFromTPA === undefined
  ) {
    return makeStatusObj('allowed');
  }

  if (!userHasValidToken) {
    return makeStatusObj('loading');
  }

  if (tpaRequest.isFetching && hideFromTPA) {
    return makeStatusObj('loading');
  }

  if (tpaRequest.data && hideFromTPA) {
    // hide since user is a tpa
    return makeStatusObj('forbidden');
  }

  const myPermissions = token?.permissions || [];

  const hasAllRequiredPermissions = !!requires?.every(p =>
    myPermissions.includes(p)
  );

  const hasOneOfRequiredPermissions = !!requiresOneOf?.some(p =>
    myPermissions.includes(p)
  );

  if (
    (requires && !hasAllRequiredPermissions) ||
    (requiresOneOf && !hasOneOfRequiredPermissions)
  ) {
    return makeStatusObj('forbidden');
  }

  return makeStatusObj('allowed');
};

export default useHasPermissions;
