import { useSnackbar } from '@/contexts/SnackBarContext';
import { QueueErrorDTO } from '@/models';
import QueueErrorService from '@/services/QueueError.service';
import { Button, Stack, styled, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useMutation } from '@tanstack/react-query';

import { useState } from 'react';
import { useToggle } from 'react-use';

import JSONViewer from '../json-viewer';
import TextStack, { TextLabel, TextStackItem, TextValue } from '../text-stack';

const StyledContainer = styled('div')(() => ({
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word'
}));

export const QueueErrorCollapsible = ({
  context,
  data: rowData
}: {
  context: any;
  data: QueueErrorDTO;
}): JSX.Element => {
  const [note, setNote] = useState(rowData.note ?? '');
  const [isNoteEditing, toggleIsNoteEditing] = useToggle(false);
  const { showSnackbar } = useSnackbar();

  const saveNoteMutation = useMutation(
    ['QueueErrorService.saveNote', rowData.errorId],
    () => QueueErrorService.saveNote(rowData.errorId, note),
    {
      onError: () => {
        showSnackbar({
          message: 'Error updating queue error note',
          severity: 'error'
        });
      },
      onSuccess: () => {
        context.refreshData();
        context.onNoteUpdate(rowData);
        showSnackbar({
          message: 'Success!',
          severity: 'success'
        });
      }
    }
  );

  return (
    <Grid
      columnSpacing={2}
      container
      height='100%'
      p={2}
      sx={{ overflow: 'scroll' }}>
      <Grid xs={6}>
        <TextStack direction='column'>
          <TextStackItem>
            <TextLabel>Note</TextLabel>
            <TextValue>
              {!isNoteEditing ? (
                <>
                  <StyledContainer>
                    {rowData.note ?? '--'}
                    <Button onClick={toggleIsNoteEditing}>EDIT</Button>
                  </StyledContainer>
                </>
              ) : (
                <Stack spacing={1}>
                  <TextField
                    label='Note'
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setNote(event.target.value);
                    }}
                    size='small'
                    value={note}
                  />
                  <Stack direction='row' spacing={1}>
                    <Button
                      onClick={() => {
                        saveNoteMutation.mutate();
                        toggleIsNoteEditing();
                      }}
                      variant='contained'>
                      SAVE
                    </Button>
                    <Button onClick={toggleIsNoteEditing}>CANCEL</Button>
                  </Stack>
                </Stack>
              )}
            </TextValue>
          </TextStackItem>
          <TextStackItem>
            <TextLabel>Initial Message Body</TextLabel>
            <TextValue>
              <StyledContainer>
                {rowData.initialMessage?.body ?? null}
              </StyledContainer>
            </TextValue>
          </TextStackItem>
          <TextStackItem>
            <TextLabel>Failure Message</TextLabel>
            <TextValue>
              <StyledContainer>
                {rowData.failureMessage ?? null}
              </StyledContainer>
            </TextValue>
          </TextStackItem>
        </TextStack>
      </Grid>
      <Grid sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }} xs={6}>
        <Typography
          color={theme => theme.palette.grey[700]}
          data-testid='json-heading'
          variant='caption'>
          JSON
        </Typography>
        <JSONViewer json={rowData.context}></JSONViewer>
      </Grid>
    </Grid>
  );
};
