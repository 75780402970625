import AccessControl from '@/components/access-control/AccessControl.component';
import { GroupIcon } from '@/components/icon/GroupIcon';
import Link from '@/components/link/Link.component';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import ParticipantService from '@/services/Participant.service';
import {
  CardHeader,
  Chip,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { FC, useMemo } from 'react';

type ParticipantUserCardProps = {
  participantId: number;
};

const ParticipantUserCard: FC<ParticipantUserCardProps> = props => {
  const participantUserListQuery = useQuery(
    ['ParticipantService.getParticipantUsers', props.participantId],
    () =>
      ParticipantService.getParticipantUserList({
        participantId: props.participantId
      }),
    {
      enabled: Boolean(props.participantId),
      suspense: true
    }
  );

  const tableColumns = useMemo(() => {
    return [
      { id: 'id', label: 'User ID' },
      { id: 'email', label: 'Email' }
    ];
  }, []);
  return (
    <Paper data-testid='participant-user-list' elevation={0} variant='outlined'>
      <CardHeader title='Login Information' />
      {participantUserListQuery.data?.length > 0 && (
        <TableContainer
          aria-label='users table'
          aria-labelledby='tableTitle'
          data-testid='participant-user-table'>
          <Table>
            <TableHead>
              <TableRow>
                {tableColumns.map(c => {
                  return <TableCell key={c.id}>{c.label}</TableCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {participantUserListQuery.data.map(u => {
                return (
                  <TableRow key={u.id}>
                    <TableCell>
                      <AccessControl
                        prohibitedAccessComponent={
                          <Typography variant='body1'>{u.id}</Typography>
                        }
                        requires={[
                          FeatureLevelPermissions.WRITE_USER_MANAGEMENT
                        ]}>
                        <Link
                          target='_blank'
                          to={`/user-management/${u.id}/user#login`}>
                          <Typography variant='body1'>{u.id}</Typography>
                        </Link>
                      </AccessControl>
                    </TableCell>
                    <TableCell>
                      <Stack direction='row' spacing={1}>
                        <Typography variant='body1'>{u.email}</Typography>
                        {u.isTemp && (
                          <Chip
                            color='info'
                            label='Temporary'
                            size='small'
                            variant='outlined'
                          />
                        )}
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!participantUserListQuery.data?.length && (
        <Grid
          alignItems='center'
          container
          data-testid='no-user-data'
          direction='column'
          justifyContent='space-around'
          pb={5}
          pt={3}>
          <GroupIcon />
          <Typography className='no-user' variant='body1'>
            No Data Available
          </Typography>
        </Grid>
      )}
    </Paper>
  );
};

export default ParticipantUserCard;
