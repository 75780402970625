import { SponsorTeammateData } from '@/models/SponsorTeammates.model';

import * as yup from 'yup';

const createSponsorTeamValidationSchema = (
  teammates: SponsorTeammateData[]
): yup.AnyObjectSchema => {
  return yup.object({
    email: yup
      .string()
      .required('Email is required')
      .email('Please provide a valid email')
      .test(
        'Check if email is already registered',
        'This email is already registered with an existing user',
        value => {
          return !teammates.filter(
            user =>
              user.attributes.isRegistered && user.attributes.email === value
          ).length;
        }
      )
  });
};

export default createSponsorTeamValidationSchema;
