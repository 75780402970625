import { CommentAddRequest } from '@/models/ops/comments/CommentAddRequest.model';
import { CommentDto } from '@/models/ops/comments/CommentDTO.model';
import { CommentSearchRequest } from '@/models/ops/comments/CommentSearchRequest.model';
import { CommentUpdateRequest } from '@/models/ops/comments/CommentUpdateRequest.model';
import { PaginatedApiResponse } from '@/models/PaginatedApiResponse.model';
import ApiService from '@/services/Api.service';

class CommentService {
  static async search(
    params: CommentSearchRequest
  ): Promise<PaginatedApiResponse<CommentDto[]>> {
    const result = await ApiService.getJson<PaginatedApiResponse<CommentDto[]>>(
      `comments/`,
      params
    );
    return result;
  }

  static async add(params: CommentAddRequest): Promise<CommentDto> {
    const result = await ApiService.postJson<CommentAddRequest, CommentDto>(
      `comments/`,
      params
    );
    return result;
  }

  static async update(
    id: number,
    params: CommentUpdateRequest
  ): Promise<CommentDto> {
    const result = await ApiService.patchJson<CommentUpdateRequest, CommentDto>(
      `comments/${id}`,
      params
    );
    return result;
  }

  static async delete(id: number): Promise<void> {
    await ApiService.deleteJson(`comments/${id}`);
  }
}

export default CommentService;
