import { createTheme, lighten } from '@mui/material';
import { blue, green, grey, orange, red } from '@mui/material/colors';

declare module '@mui/material' {
  interface BadgePropsColorOverrides {
    'grey.25': true;
    'primary.light': true;
  }

  interface Color {
    // used for shades lighter than what Material Design specifies
    25: string;
  }

  interface ZIndex {
    vestwellAppBar: number;
  }
}

declare module '@mui/material/styles/createPalette' {
  // extensions mirror MUI for Figma library: https://www.figma.com/file/Q3vRFum46KtwdszvAL19Dy/mui-for-figma-v4.12.0?node-id=38%3A4557

  interface PaletteColor {
    lightBg: string;
  }

  interface SimplePaletteColorOptions {
    lightBg?: React.CSSProperties['color'];
  }
}

const VestwellTheme = createTheme({
  breakpoints: {
    values: {
      lg: 1200,
      md: 900,
      sm: 600,
      xl: 1920,
      xs: 0
    }
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        filledError: {
          backgroundColor: red[100],
          color: red[900],
          fontWeight: 700
        },
        filledSuccess: {
          backgroundColor: green[100],
          fontColor: green[900],
          fontWeight: 700
        },
        icon: {
          color: 'inherit !important'
        }
      }
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          variants: [
            {
              props: props => props.badgeContent?.toString() === '0',
              style: {
                backgroundColor: grey[400]
              }
            }
          ]
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableFocusRipple: true
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingBottom: '8px'
          }
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          '&+.MuiDialogContent-root': {
            paddingTop: '8px !important'
          }
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          '&::after': {
            width:
              ownerState.orientation === 'horizontal' &&
              ownerState.textAlign === 'left'
                ? '100%'
                : undefined
          },
          '&::before': {
            width:
              ownerState.orientation === 'horizontal' &&
              ownerState.textAlign === 'left'
                ? 0
                : undefined
          }
        }),
        wrapper: ({ ownerState }) => ({
          color: grey[700],
          fontSize: 14,
          fontWeight: 500,
          paddingLeft:
            ownerState.orientation === 'horizontal' &&
            ownerState.textAlign === 'left'
              ? 0
              : undefined
        })
      }
    }
  },
  palette: {
    error: {
      lightBg: red[100],
      main: red[500]
    },
    grey: {
      25: '#fdfdfd'
    },
    primary: {
      light: '#f1f7fe',
      main: blue[500]
    },
    secondary: {
      light: '#e3f2fd',
      main: blue[500]
    },
    success: {
      dark: '#3b873e',
      lightBg: lighten(green[500], 0.9),
      main: green[500]
    },
    warning: {
      dark: '#ED6C02',
      lightBg: lighten(orange[500], 0.9),
      main: orange[500]
    }
  },
  typography: {
    body2: {
      color: grey[700]
    }
  },
  zIndex: {
    // used for the app bar instead of doing drawer + 1 so that other areas of the app can reliably place themselves above the app bar
    vestwellAppBar: 1201
  }
});

export default VestwellTheme;
