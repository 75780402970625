import { QueueErrorCard } from '@/components/queue-error-card/QueueErrorCard.component';
import ContributionService from '@/services/Contribution.service';
import { Box, Card, Divider, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import React, { FC } from 'react';

import { AchDetails } from './AchDetails';
import { Corrections } from './Corrections.components';
import { TradeRequest } from './TradeRequest.components';

type DetailsTabProps = {
  ucid: string;
  planId: number;
  sponsorId: number;
};

export const DetailsTab: FC<DetailsTabProps> = props => {
  const contributionDetails = useQuery(
    [ContributionService.getContributionDetails.name, props.planId, props.ucid],
    async () => {
      return ContributionService.getContributionDetails({
        includeCorrections: true,
        planId: props.planId,
        sponsorId: props.sponsorId,
        ucid: props.ucid
      });
    },
    {
      enabled: !!props.planId && !!props.ucid && !!props.sponsorId
    }
  );

  return (
    <div>
      <QueueErrorCard sx={{ mb: 2 }} type='Contribution' value={props.ucid} />

      <Card sx={{ marginBottom: 5 }}>
        <Typography mb={3} ml={2} mt={3} variant='h5'>
          ACH Details
        </Typography>

        <Divider />

        <AchDetails
          planId={props.planId}
          sponsorId={props.sponsorId}
          ucid={contributionDetails.data?.ucid as string}
        />

        {contributionDetails.data?.corrections?.ucids?.map(contributionUcid => (
          <AchDetails
            key={contributionUcid}
            planId={props.planId}
            sponsorId={props.sponsorId}
            ucid={contributionUcid}
          />
        ))}

        {contributionDetails.data?.lostGainsUcids?.map(ucid => (
          <AchDetails
            key={ucid}
            planId={props.planId}
            sponsorId={props.sponsorId}
            ucid={ucid}
          />
        ))}
      </Card>

      <Card>
        <Typography mb={3} ml={2} mt={3} variant='h5'>
          {contributionDetails.data?.recordkeeper === 'Vestwell ESA'
            ? 'Allocate Deposit Requests'
            : 'Invest Contribution Requests'}
        </Typography>

        <Divider />

        <Box sx={{ marginTop: 5 }}>
          <TradeRequest
            planId={props.planId}
            sponsorId={props.sponsorId}
            title='Original Contribution'
            ucid={contributionDetails.data?.ucid as string}
          />
        </Box>

        {contributionDetails.data?.lostGainsUcids?.map(ucid => (
          <Box key={ucid} sx={{ marginTop: 5 }}>
            <TradeRequest
              planId={props.planId}
              sponsorId={props.sponsorId}
              title='Lost Gains'
              ucid={ucid}
            />
          </Box>
        ))}

        {contributionDetails.data?.corrections?.ucids?.map(contributionUcid => (
          <Box key={contributionUcid} sx={{ marginTop: 5 }}>
            <Corrections
              planId={props.planId}
              sponsorId={props.sponsorId}
              ucid={contributionUcid}
            />
          </Box>
        ))}
      </Card>
    </div>
  );
};
