/* eslint-disable sort-keys-plus/sort-keys */
import AccessControl from '@/components/access-control/AccessControl.component';
import CardIconInfoField from '@/components/card-icon-info-field/CardIconInfoField.component';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import SponsorService from '@/services/Sponsor.service';
import {
  AddOutlined,
  CreditCardOutlined,
  InfoOutlined,
  LockOutlined
} from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import { red } from '@mui/material/colors';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { Sponsors } from '@vestwell-api/scala';

import React, { useCallback, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { accountNumber } from 'us-bank-account-validator';
import * as yup from 'yup';

import { DeleteBankAccountDialog } from './DeleteBankAccountDialog.component';

interface CompanyBankAccountsProps {
  sponsorId: number;
  sponsorPlanId: number;
}

const validationSchema = yup.object({
  accountNumber: yup
    .string()
    .test({
      message: 'Invalid Account Number',
      name: 'is-valid-account-number',
      test: val => accountNumber(val).isPotentiallyValid
    })
    .required(),
  bankName: yup.string().required('Bank Name is required'),
  confirmAccountNumber: yup
    .string()
    .when('accountNumber', (actNum, schema) =>
      schema.test({
        message: 'The Account Number must match.',
        name: 'matches-account-number',
        test: (val: string) => actNum === val
      })
    )
    .required(),
  routingNumber: yup
    .string()
    .test({
      message: 'Invalid Routing Number',
      name: 'is-valid-routing-number',
      test: val => {
        const isFormatValid =
          val?.length === 9 && !isNaN(+val) && val !== '000000000';

        if (!isFormatValid) {
          return false;
        }

        const firstTwoDigits = +val.slice(0, 2);
        const isRangeValid =
          firstTwoDigits <= 12 ||
          (firstTwoDigits >= 21 && firstTwoDigits <= 32) ||
          (firstTwoDigits >= 61 && firstTwoDigits <= 72) ||
          firstTwoDigits === 80;
        const isChecksumValid =
          (3 * (+val[0] + +val[3] + +val[6]) +
            7 * (+val[1] + +val[4] + +val[7]) +
            (+val[2] + +val[5] + +val[8])) %
            10 ===
          0;

        return isRangeValid && isChecksumValid;
      }
    })
    .required()
});

const RoutingNumber = React.forwardRef((props, ref) => (
  <NumericFormat {...props} allowLeadingZeros getInputRef={ref} />
));

const AccountNumber = React.forwardRef((props, ref) => (
  <NumericFormat {...props} allowLeadingZeros getInputRef={ref} />
));

export const CompanyBankAccountsTab: React.FC<CompanyBankAccountsProps> = (
  props: CompanyBankAccountsProps
) => {
  const bankAccounts = useQuery(
    ['SponsorService.getSponsorBankAccounts', props.sponsorId],
    () => SponsorService.getSponsorBankAccounts(+props.sponsorId),
    {
      enabled: Boolean(props.sponsorId)
    }
  );

  const { openDialog } = useDialog();
  const { showSnackbar } = useSnackbar();

  const [deleteDialogBankAccount, setDeleteDialogBankAccount] =
    useState(undefined);

  const editBankAccount = useMutation(
    ['SponsorService.putSponsorBankAccount', props.sponsorId],
    (data: {
      bankAccountId: number;
      body: Sponsors.PutBankAccounts.RequestBody;
    }) =>
      SponsorService.putSponsorBankAccount(
        +props.sponsorId,
        data.bankAccountId,
        data.body
      ),
    {
      onError: (err: any) => {
        showSnackbar({
          message: err.message,
          severity: 'error'
        });
      },
      onSuccess: async bankAccount => {
        await bankAccounts.refetch();
        showSnackbar({
          message: `Bank Account ${bankAccount.bankName} edited successfully`,
          severity: 'success'
        });
      }
    }
  );

  const addBankAccount = useMutation(
    ['SponsorService.postSponsorBankAccount', props.sponsorId],
    (data: Sponsors.PostBankAccounts.RequestBody) =>
      SponsorService.postSponsorBankAccount(+props.sponsorId, data),
    {
      onError: (err: any) => {
        showSnackbar({
          message: err.message,
          severity: 'error'
        });
      },
      onSuccess: async bankAccount => {
        await bankAccounts.refetch();
        showSnackbar({
          message: `Bank Account ${bankAccount.bankName} added successfully.`,
          severity: 'success'
        });
      }
    }
  );

  const onEdit = useCallback(
    (bankAccount: Sponsors.GetBankAccounts.ResponseBody[0]) => {
      openDialog({
        actionButtons: {
          cancelButton: {
            children: 'Cancel'
          },
          submitButton: {
            children: 'Update'
          }
        },
        onSubmit: async values => {
          await editBankAccount.mutateAsync({
            bankAccountId: bankAccount.bankAccountId,
            body: values as Sponsors.PutBankAccounts.RequestBody
          });
        },
        steps: [
          {
            contentText: bankAccount.payrollSetups?.length &&
              bankAccount.payrollSetups.length > 2 && (
                <Grid
                  alignItems='center'
                  borderRadius={2}
                  container
                  display='flex'
                  p={2}
                  sm={12}
                  sx={{
                    backgroundColor: red[50]
                  }}>
                  <InfoOutlined
                    sx={{
                      color: red[800]
                    }}
                  />
                  <Grid flexGrow={1} ml={2}>
                    <Typography>
                      This bank account is being used by{' '}
                      {bankAccount.payrollSetups?.length} pay groups:
                    </Typography>
                    {bankAccount.payrollSetups?.map(ps => (
                      <li key={ps.id}>
                        {ps.payGroupName} (Setup ID: {ps.id})
                      </li>
                    ))}
                  </Grid>
                </Grid>
              ),
            fields: {
              bankName: {
                initialValue: bankAccount.bankName,
                label: 'Bank Name'
              },
              routingNumber: {
                fieldProps: {
                  InputProps: {
                    inputComponent: RoutingNumber
                  }
                },
                initialValue: bankAccount.routingNumber,
                label: 'Routing Number'
              },
              accountNumber: {
                fieldProps: {
                  InputProps: {
                    inputComponent: AccountNumber
                  }
                },
                initialValue: bankAccount.accountNumber,
                label: 'Account Number'
              },
              confirmAccountNumber: {
                fieldProps: {
                  InputProps: {
                    inputComponent: AccountNumber
                  }
                },
                label: 'Confirm Account Number'
              }
            },
            title: 'Edit Bank Account'
          }
        ],
        validationSchema
      });
    },
    [editBankAccount, openDialog]
  );

  const onAdd = useCallback(() => {
    openDialog({
      actionButtons: {
        cancelButton: {
          children: 'Cancel'
        },
        submitButton: {
          children: 'Add'
        }
      },
      onSubmit: async ({ confirmAccountNumber, ...values }) => {
        await addBankAccount.mutateAsync(
          values as Sponsors.PostBankAccounts.RequestBody
        );
      },
      steps: [
        {
          fields: {
            bankName: {
              label: 'Bank Name'
            },
            routingNumber: {
              fieldProps: {
                InputProps: {
                  inputComponent: RoutingNumber
                }
              },
              label: 'Routing Number'
            },
            accountNumber: {
              fieldProps: {
                InputProps: {
                  inputComponent: AccountNumber
                }
              },
              label: 'Account Number'
            },
            confirmAccountNumber: {
              fieldProps: {
                InputProps: {
                  inputComponent: AccountNumber
                }
              },
              label: 'Confirm Account Number'
            }
          },
          title: 'Add Bank Account'
        }
      ],
      validationSchema
    });
  }, [addBankAccount, openDialog]);

  return (
    <>
      <DeleteBankAccountDialog
        account={deleteDialogBankAccount}
        onClose={() => setDeleteDialogBankAccount(undefined)}
        open={!!deleteDialogBankAccount}
        sponsorId={props.sponsorId}
        sponsorPlanId={props.sponsorPlanId}
      />
      <Stack
        data-testid='plan-company-bank-accounts-tab-content'
        divider={<Divider flexItem />}
        flexGrow={1}>
        <Stack direction='row' justifyContent='space-between'>
          <Typography fontSize='1.2rem' fontWeight={500} pl={2}>
            Bank Accounts
          </Typography>
          <AccessControl
            requires={[FeatureLevelPermissions.WRITE_COMPANY_PAYROLL]}>
            <Button onClick={onAdd} variant='text'>
              <AddOutlined
                fontSize='small'
                sx={{
                  marginRight: 1
                }}
              />
              Add Bank Account
            </Button>
          </AccessControl>
        </Stack>
        <Grid container>
          {bankAccounts.data?.map(bankAccount => (
            <Grid item key={bankAccount.bankAccountId} my={2} xs={4}>
              <CardIconInfoField
                icon={
                  <CreditCardOutlined
                    sx={{
                      marginTop: '2px'
                    }}
                  />
                }
                subtitle={[
                  `Routing Number: ${bankAccount.routingNumber}`,
                  `Account Number: ${bankAccount.accountNumber}`
                ]}
                title={
                  <>
                    <span
                      style={{
                        paddingRight: '12px'
                      }}>
                      {bankAccount.bankName}
                    </span>
                    {bankAccount.isPlaid && (
                      <Box
                        component='span'
                        sx={{
                          backgroundColor: theme => theme.palette.grey[200],
                          borderRadius: '12px',
                          bottom: 3,
                          color: theme => theme.palette.grey[600],
                          display: 'inline-block',
                          fontSize: 14,
                          fontWeight: 500,
                          lineHeight: 1.5,
                          position: 'relative',
                          px: '12px'
                        }}>
                        via Plaid
                      </Box>
                    )}
                  </>
                }
                tooltip='Company Legal Name'
              />
              {bankAccount.accountNumber.slice(-4) != bankAccount.plaidMask &&
                bankAccount.isPlaid && (
                  <Grid item ml={bankAccount.isPlaid ? 5.5 : 3.5}>
                    <Alert severity='info'>
                      This bank has issued a tokenized routing and account
                      number to plaid. The actual account number ends in *
                      <strong>{bankAccount.plaidMask}</strong>
                    </Alert>
                  </Grid>
                )}
              <Grid item ml={bankAccount.isPlaid ? 4.5 : 3.5}>
                <AccessControl
                  requires={[FeatureLevelPermissions.WRITE_COMPANY_PAYROLL]}>
                  <Button
                    disabled={bankAccount.isPlaid}
                    endIcon={
                      bankAccount.isPlaid ? (
                        <LockOutlined fontSize='small' />
                      ) : undefined
                    }
                    onClick={() => onEdit(bankAccount)}
                    variant='text'>
                    EDIT
                  </Button>
                </AccessControl>
                <AccessControl
                  requires={[
                    FeatureLevelPermissions.WRITE_COMPANY_REMOVE_BANK_ACCOUNT
                  ]}>
                  <Button
                    onClick={() => setDeleteDialogBankAccount(bankAccount)}
                    variant='text'>
                    Delete
                  </Button>
                </AccessControl>
              </Grid>
            </Grid>
          ))}
        </Grid>
        {!bankAccounts.isFetching && !bankAccounts.data?.length && (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'center',
              width: '100%'
            }}>
            <CreditCardOutlined
              fontSize='large'
              sx={{
                color: theme => theme.palette.grey[300]
              }}
            />
            <Typography
              sx={{
                fontSize: '1.2rem',
                fontWeight: '500'
              }}>
              No Bank Accounts
            </Typography>
            <Typography
              sx={{
                color: theme => theme.palette.grey[600]
              }}>
              Bank accounts in pay groups will appear here.
            </Typography>
          </Box>
        )}
      </Stack>
    </>
  );
};
