export const isSubaExecutionComplete = (status?: string): boolean => {
  return status === 'SUCCEEDED';
};

export const isSubaExecutionFailed = (status?: string): boolean => {
  if (!status) return false;
  return [
    'FAILED',
    'SUBA_FAILED',
    'SUBA_ABORTED',
    'SUBA_REVERSED',
    'SUBA_TIMED-OUT',
    'CANCELED'
  ].includes(status);
};

export const isSubaExecutionRunning = (status?: string): boolean => {
  if (!status) return false;
  return [
    'CREATED',
    'REPLAYED',
    'STARTED',
    'SUBA_STARTED',
    'SUBA_RUNNING',
    'SUBA_SUCCEEDED'
  ].includes(status);
};

export const isWorkflowComplete = (workflowStatus?: string): boolean => {
  return workflowStatus === 'COMPLETE';
};

export const isWorkflowFailed = (workflowStatus?: string): boolean => {
  if (!workflowStatus) return false;
  return [
    'PROCESSING_ABORTED',
    'PROCESSING_ERROR',
    'VALIDATION_ERROR'
  ].includes(workflowStatus);
};

export const isWorkflowRunning = (workflowStatus?: string): boolean => {
  if (!workflowStatus) return true; // assume running if we don't have a status yet
  return ['PENDING_INVESTMENT', 'PENDING_PROCESSING'].includes(workflowStatus);
};
