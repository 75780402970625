import { CONVERSION_TYPES } from '@/consts/uploads';
import { PlanV2Dto } from '@/models';
import { PlanService } from '@/services/Plan.service';
import { Unstable_Grid2 as Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { ReactElement } from 'react';

import ConversionCard from '../PlanActionTableV2/ConversionMainComponents/ConversionCard.component';
import { YearsOfService } from './YearsOfService.component';

interface PlanUploadsProps {
  sponsorPlanId: number;
}

const PlanUploads = ({ sponsorPlanId }: PlanUploadsProps): ReactElement => {
  const conversions = useQuery(
    [`PlanService.getPlanConversions ${sponsorPlanId}`],
    () => PlanService.getPlanConversions(sponsorPlanId),
    {
      enabled: Boolean(sponsorPlanId)
    }
  );

  const planQuery = useQuery<PlanV2Dto>(
    ['PlanService.getPlanById', sponsorPlanId?.toString()],
    () => {
      if (!sponsorPlanId) throw new Error(`keep the compiler happy`);

      return PlanService.getPlanById(sponsorPlanId);
    },
    {
      enabled: !!sponsorPlanId,
      staleTime: Infinity
    }
  );

  return (
    <Grid container>
      {['Vestwell Sub-Accounting Platform', 'LT'].includes(
        planQuery.data?.data.attributes.recordkeeper ?? ''
      ) && (
        <Grid xs={6}>
          <ConversionCard
            component={<YearsOfService sponsorPlanId={sponsorPlanId} />}
            conversionData={
              conversions?.data?.filter(
                i =>
                  i.conversionType ===
                  CONVERSION_TYPES.HISTORICAL_YEARS_OF_SERVICE
              ) ?? []
            }
            sponsorPlanId={sponsorPlanId}
            title='Historical Years of Service'
          />
        </Grid>
      )}
    </Grid>
  );
};

export default PlanUploads;
