import CopyToClipboard from '@/components/copy-to-clipboard';
import { useSnackbar } from '@/contexts/SnackBarContext';
import DetailLabel from '@/routes/ops/investments/common/DetailLabel.component';
import DetailValue from '@/routes/ops/investments/common/DetailValue.component';
import SortingIcon from '@/routes/ops/investments/common/SortingIcon.component';
import { HasModelFunds } from '@/routes/ops/investments/data-grid/RiskAndTargetUtil';
import { AllocationCell } from '@/routes/ops/investments/investment-table/AllocationCell.component';
import AllocationTable from '@/routes/ops/investments/investment-table/AllocationTable.component';
import InvestmentTable from '@/routes/ops/investments/investment-table/InvestmentTable.component';
import { TickerLookupSearchResult } from '@/routes/ops/investments/investment-table/TickerSelect.component';
import { Box, Theme, Typography } from '@mui/material';
import { AlertColor } from '@mui/material/Alert';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';

import { CellClickedEvent, ICellRendererParams } from 'ag-grid-community';
import Decimal from 'decimal.js';
import { useFormik } from 'formik';
import { every, times, uniqBy } from 'lodash';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useToggle } from 'react-use';
import * as Yup from 'yup';

import ModelEditDetailView from './models/ModelEditDetailView.component';

export interface TargetSeriesGridData {
  allocations: Record<string, any>[];
  models: TargetSeriesModel[];
}

export type TargetSeriesModel = {
  targetModelId?: number;
  description?: string;
  modelName: string;
  targetRetireYearLow?: number;
  targetRetireYearHigh?: number;
};

export interface TargetSeriesProps {
  targetSeriesGrid?: TargetSeriesGridData;
  targetSeries: TargetSeriesData;
  lookupTickerCallback?: (
    ticker: string,
    numberOfResults: number
  ) => Promise<TickerLookupSearchResult[]>;
  saveCallback?: (
    updatedTargetSeries: TargetSeriesData,
    updatedModelGridProps: TargetSeriesGridData
  ) => Promise<[TargetSeriesData, TargetSeriesGridData]>;
  readonly: boolean;
  programCount: number;
}

export interface TargetSeriesData {
  targetSeriesId: number;
  name: string;
  description?: string;
}

export interface TargetSeriesModelProps extends HasModelFunds {
  targetModelId?: number;
  modelName: string;
  targetRetireYearHigh?: number;
  targetRetireYearLow?: number;
  description?: string;
}

const MODEL_ID_ROW = 'targetModelId';
const MODEL_NAME_ROW = 'modelName';
const TARGET_YEARS_ROW = 'targetYears';
const DESCRIPTION_ROW = 'description';

const NUMBER_OF_MODELS = 10;

const useStyles = makeStyles((theme: Theme) => ({
  copyContainer: {
    alignItems: 'center',
    border: `1px solid ${grey[300]}`,
    borderRadius: '100px',
    display: 'flex',
    padding: `2px 8px`
  },
  copyIcon: {
    height: theme.spacing(2)
  },
  darkSubtitle: {
    color: 'rgb(0, 0, 0, 0.87)',
    fontSize: 13,
    letterSpacing: 0.25
  }
}));

const DetailValueCell = (props: any) => {
  const classes = useStyles();
  const { context, rowIndex, value, valueFormatted, column, eGridCell } = props;
  const editable = props.colDef.editable(props);
  const isExistingColumnError =
    typeof context.errors.models !== 'string' &&
    context.errors.models?.[Number(column.colId)];
  if (rowIndex === 4) {
    return (
      <AllocationCell
        allocations={props.context.values.allocations}
        colId={props.colDef.colId}
      />
    );
  }

  if (rowIndex === 2) {
    const years = value || 'YYYY - YYYY';
    eGridCell.setAttribute(
      'data-test-id',
      `model-target-years-${column.colId}`
    );
    return (
      <DetailValue
        color={isExistingColumnError && !value ? 'red' : 'rgba(0, 0, 0, 0.6)'}>
        {years}
      </DetailValue>
    );
  }

  if (rowIndex === 3) {
    eGridCell.setAttribute('data-test-id', `model-description-${column.colId}`);

    if (!value && editable) {
      return <DetailValue>{valueFormatted}</DetailValue>;
    }
  }

  if (rowIndex === 1 && !value && editable) {
    eGridCell.setAttribute('data-test-id', `model-name-${column.colId}`);

    if (!value && editable) {
      return (
        <DetailValue
          color={isExistingColumnError ? 'red' : 'rgba(0, 0, 0, 0.6)'}>
          {isExistingColumnError ? 'Name Required' : 'TD 2020'}
        </DetailValue>
      );
    }
  }

  if (rowIndex === 0 && value) {
    return (
      <div
        className={classes.copyContainer}
        data-testid={`target-model-id-container-${column.colId}`}>
        <Typography
          className={classes.darkSubtitle}
          data-testid={`target-model-id-${column.colId}`}
          display='inline'>
          {value}
        </Typography>
        <CopyToClipboard
          copyName='Model ID'
          copyValue={value}
          iconCssClass={classes.copyIcon}
          size='small'
        />
      </div>
    );
  }

  return <DetailValue>{value || ''}</DetailValue>;
};

const getLabel = (field: string) => {
  switch (field) {
    case MODEL_ID_ROW:
      return 'Model ID';
    case MODEL_NAME_ROW:
      return 'Model Name';
    case TARGET_YEARS_ROW:
      return 'Target Years';
    case DESCRIPTION_ROW:
      return 'Description';
  }
};

const TargetSeriesSchema = Yup.object().shape({
  allocations: Yup.array()
    .min(1, 'Must have at least one allocation')
    .test(
      'unique-symbol',
      'Allocations must have unique tickers.',
      function (value) {
        return (value || []).length === uniqBy(value || [], 'symbol').length;
      }
    )
    .required('Required'),
  description: Yup.string(),
  models: Yup.array()
    .of(
      Yup.object().shape({
        modelName: Yup.string().required('Required'),
        targetRetireYearHigh: Yup.number()
          .test(
            'higher-than-low',
            'Retirement year high must be higher than retirment year low.',
            (value, context) => {
              return (value || 0) > context.parent.targetRetireYearLow;
            }
          )
          .test(
            'duplicate-or-overlapping',
            'Duplicate and overlapping target years.',
            (value, context: any) => {
              const { models } = context.options.from[1].value;
              const modelName = context.parent.modelName;
              const targetModelId = context.parent.targetModelId || 0;
              const targetRetireYearHigh = value || 0;
              const targetRetireYearLow =
                context.parent.targetRetireYearLow || 0;
              return !models.some(
                (model: {
                  modelName: string | undefined;
                  targetModelId: number | undefined;
                  targetRetireYearHigh: number;
                  targetRetireYearLow: number;
                }) =>
                  model.targetModelId !== targetModelId &&
                  model.modelName !== modelName &&
                  ((targetRetireYearLow >= model.targetRetireYearLow &&
                    targetRetireYearLow <= model.targetRetireYearHigh) ||
                    (targetRetireYearHigh >= model.targetRetireYearLow &&
                      targetRetireYearHigh <= model.targetRetireYearHigh))
              );
            }
          )
          .required('Required'),
        targetRetireYearLow: Yup.number().required('Required')
      })
    )
    .test('unique-name', 'Models Must Have Unique Names', function (value) {
      return (value || []).length === uniqBy(value || [], 'modelName').length;
    })
    .test(
      'valid-allocations',
      'Invalid Fund Allocations',
      function (value, context) {
        const models =
          value?.map((_, index) => {
            return context.parent.allocations.reduce(
              (accumulator: Decimal, allocation: Array<any>) =>
                Decimal.add(accumulator, allocation[index] || '0'),
              new Decimal(0)
            );
          }) || [];
        return every(models, m => m.equals(new Decimal(100)));
      }
    )
    .min(1, 'Must have at least one model')
    .required('Required'),
  name: Yup.string().required('Required')
});

const TargetSeries = (targetSeriesProps: TargetSeriesProps): JSX.Element => {
  const {
    targetSeriesGrid: initialTargetSeriesGrid,
    targetSeries: initialTargetSeries,
    saveCallback,
    lookupTickerCallback,
    programCount
  } = targetSeriesProps;

  const snackbar = useSnackbar();

  const modelGrid = useRef(null);
  const tickerGrid = useRef<any>(null);
  const [is5Year, toggle5Year] = useToggle(true);
  const [sort, setSort] = useState<null | 'asc' | 'desc'>(null);

  const [targetSeries, setTargetSeries] =
    useState<TargetSeriesData>(initialTargetSeries);
  const [targetSeriesGridData, setTargetSeriesGridData] =
    useState<TargetSeriesGridData>(
      initialTargetSeriesGrid || { allocations: [], models: [] }
    );

  const [fundNameCellWidth, setFundNameCellWidth] = useState(undefined);

  const sortTickers = useCallback(
    params => {
      if (params.rowIndex === 4) {
        const newSort = sort ? (sort === 'asc' ? 'desc' : null) : 'asc';
        tickerGrid.current.columnApi.applyColumnState({
          defaultState: { sort: null },
          state: [{ colId: 'symbol', sort: newSort }]
        });
        setSort(newSort);
      }
    },
    [sort]
  );

  const displayMessage = useCallback(
    (severity: AlertColor, message: string) => {
      snackbar.showSnackbar({
        message,
        severity
      });
    },
    [snackbar]
  );

  const form = useFormik<TargetSeriesData & TargetSeriesGridData>({
    enableReinitialize: true,
    initialValues: {
      ...targetSeries,
      ...targetSeriesGridData
    },
    onSubmit: async values => {
      const updatedTargetSeriesDetails = {
        description: values.description,
        name: values.name,
        targetSeriesId: values.targetSeriesId
      };

      return saveCallback?.(updatedTargetSeriesDetails, {
        allocations: values.allocations,
        models: values.models
      }).then(([updatedTargetSeries, updatedTargetSeriesGrid]) => {
        setTargetSeries(updatedTargetSeries);
        setTargetSeriesGridData(updatedTargetSeriesGrid);
        displayMessage('success', 'Target Series Saved');
      });
    },
    validationSchema: TargetSeriesSchema
  });

  const defaultColDef = {
    editable: false,
    filter: false,
    resizable: false,
    sortable: false,
    suppressMenu: true,
    suppressMovable: true
  };

  const getDetailColumn = useCallback(
    (columnIndex: number) => {
      return {
        cellEditorPopup: true,
        cellRenderer: DetailValueCell,
        colId: columnIndex.toString(),
        editable: (params: any) => {
          if (targetSeriesProps.readonly) return false;
          if ([0, 4].includes(params.node.rowIndex)) return false;
          if (columnIndex > form.values.models.length) return false;

          if (params.node.rowIndex === 3 || params.node.rowIndex === 2) {
            return !!form.values.models[columnIndex];
          }

          return true;
        },
        field: `value${columnIndex}`,
        flex: 0.75,
        headerName: '',
        minWidth: 165,
        sortable: false,
        suppressKeyboardEvent: (params: any) => {
          if (
            params.event.type === 'keydown' &&
            params.event.key === 'Enter' &&
            ((params.node.rowIndex === 2 && params.editing) ||
              params.node.rowIndex === 3)
          ) {
            tickerGrid.current?.api.setFocusedCell(0, params.colDef.colId);
            params.api.stopEditing();
            return true;
          }
          return false;
        },
        valueFormatter: (params: any) => {
          if (params.value === undefined) {
            const editable = params.colDef.editable(params);
            if (params.data.field === DESCRIPTION_ROW && editable) {
              return 'Add Description';
            }

            return '';
          }

          return params.value;
        },
        valueGetter: (params: any) => {
          const model = form.values.models[columnIndex];

          // targetYears formatter
          if (params.data.field === TARGET_YEARS_ROW) {
            if (!model?.targetRetireYearLow || !model?.targetRetireYearHigh)
              return '';

            return `${model.targetRetireYearLow} - ${model.targetRetireYearHigh}`;
          }

          if (params.data.field === MODEL_ID_ROW) {
            return model?.targetModelId;
          }

          // modelName formatter
          if (params.data.field === MODEL_NAME_ROW) {
            return model?.modelName;
          }

          // description formatter
          if (params.data.field === DESCRIPTION_ROW) {
            return model?.description;
          }

          return '';
        }
      };
    },
    [form.values, targetSeriesProps.readonly]
  );

  const TICKER_WIDTH = 140;

  const modelColumnLength = useMemo(() => {
    return form.values?.models.length + 1 > NUMBER_OF_MODELS
      ? form.values?.models.length + 1
      : NUMBER_OF_MODELS;
  }, [form.values?.models.length]);

  const topColumnDefs = useMemo(
    () => [
      {
        cellRenderer: (params: ICellRendererParams) => {
          return (
            <Typography
              color='rgba(0, 0, 0, 0.87)'
              sx={{ display: 'flex' }}
              variant='body2'>
              {params.value || ''}
              {params.node.rowIndex === 4 && <SortingIcon sort={sort} />}
            </Typography>
          );
        },
        field: 'sublabel',
        flex: 1,
        headerName: '',
        maxWidth: TICKER_WIDTH,
        minWidth: TICKER_WIDTH,
        onCellClicked: (params: CellClickedEvent) => sortTickers(params),
        pinned: true,
        valueGetter: (params: any) => params.data.sublabel || ' ',
        width: TICKER_WIDTH
      },
      {
        cellRenderer: DetailLabel,
        field: 'label',
        headerName: '',
        pinned: true,
        width: fundNameCellWidth
      },
      ...times(modelColumnLength, index => getDetailColumn(index))
    ],
    [modelColumnLength, getDetailColumn, sortTickers, sort, fundNameCellWidth]
  );

  const getDetailValueRowData = useCallback(
    (
      field:
        | typeof MODEL_ID_ROW
        | typeof MODEL_NAME_ROW
        | typeof TARGET_YEARS_ROW
        | typeof DESCRIPTION_ROW
    ) => {
      let row = { field, label: getLabel(field) };
      times(modelColumnLength, (index: number) => {
        const model: {
          targetModelId?: number;
          modelName?: string;
          targetYears?: string;
          description?: string;
        } = form.values?.models ? form.values?.models[index] : {};
        if (model && model[field]) {
          row = {
            ...row,
            [`value${index}`]: model[field]
          };
        }
      });
      return row;
    },
    [form.values.models, modelColumnLength]
  );

  const onEditDetailCellEditRequest = useCallback(
    (params: any) => {
      // new modelName column
      if (
        !params.context.values.models ||
        (params.data.field === MODEL_NAME_ROW &&
          !params.context.values.models[params.colDef.colId])
      ) {
        const newModelProps = Array.from(params.context.values.models || []);
        const lastYearHigh = params.context.values.models.reduce(
          (
            high: number,
            ts: { targetRetireYearHigh: number; targetRetireYearLow: number }
          ) =>
            (ts.targetRetireYearHigh || 0) > high
              ? ts.targetRetireYearHigh || 0
              : high,
          0
        );
        const modelName = params.value;
        const pattern = /^.*([0-9]{4}).*/;
        const patternResult = pattern.exec(modelName);
        const fallbackYear = lastYearHigh > 0 ? lastYearHigh + 3 : 2020;
        const year = patternResult
          ? parseInt(patternResult[1], 10)
          : fallbackYear;

        newModelProps.push({
          description: undefined,
          modelName,
          targetRetireYearHigh: year + (is5Year ? 2 : 4),
          targetRetireYearLow: year - (is5Year ? 2 : 5)
        });

        params.context.setFieldValue('models', newModelProps);
        return;
      }

      if (params.data.field === TARGET_YEARS_ROW) {
        const newModelProps = Array.from(params.context.values.models);
        const [low, high] = params.value.split('-');
        newModelProps[params.colDef.colId] = {
          ...((newModelProps[params.colDef.colId] as object) || {}),
          targetRetireYearHigh: parseInt(high, 10),
          targetRetireYearLow: parseInt(low, 10)
        };
        params.context.setFieldValue('models', newModelProps);
        return;
      }

      params.context.setFieldValue(
        `models[${params.colDef.colId}].${params.data.field}`,
        params.value
      );
    },
    [is5Year]
  );

  return (
    <ModelEditDetailView
      content={
        <>
          <Box>
            <Box>
              <InvestmentTable
                alignedGrids={
                  tickerGrid?.current ? [tickerGrid.current] : undefined
                }
                columnDefs={topColumnDefs}
                context={form}
                defaultColDef={defaultColDef}
                gridRef={modelGrid}
                headerHeight={0}
                onCellEditRequest={onEditDetailCellEditRequest}
                rowData={[
                  getDetailValueRowData(MODEL_ID_ROW),
                  getDetailValueRowData(MODEL_NAME_ROW),
                  getDetailValueRowData(TARGET_YEARS_ROW),
                  getDetailValueRowData(DESCRIPTION_ROW),
                  { label: 'Allocation', sublabel: 'Ticker' }
                ]}
              />
            </Box>
            <AllocationTable
              alignedGrids={
                modelGrid?.current ? [modelGrid.current] : undefined
              }
              allocations={form.values.allocations}
              defaultColDef={defaultColDef}
              gridRef={tickerGrid}
              lookupTickerCallback={lookupTickerCallback}
              models={form.values.models}
              readonly={targetSeriesProps.readonly}
              setFieldValue={form.setFieldValue}
              setFundNameCellWidth={setFundNameCellWidth}
            />
          </Box>
        </>
      }
      dirty={form?.dirty}
      errors={form?.errors}
      investmentOptionId={initialTargetSeries.targetSeriesId}
      is5Year={is5Year}
      isSubmitting={form?.isSubmitting}
      isValid={form?.isValid}
      lookupTickerCallback={lookupTickerCallback}
      modelSeriesType='Target'
      onSaveModel={form.handleSubmit}
      programCount={programCount}
      readonly={targetSeriesProps.readonly}
      setFieldValue={form?.setFieldValue}
      toggle5Year={toggle5Year}
      values={form?.values}
    />
  );
};

TargetSeries.defaultProps = {
  readonly: false
};

export default TargetSeries;
