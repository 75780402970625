import AccessControl from '@/components/access-control/AccessControl.component';
import CopyToClipboard from '@/components/copy-to-clipboard';
import { FirmModal } from '@/components/entity-modals/EntityModals.component';
import NavigationBreadcrumbs from '@/components/navigation-breadcrumbs';
import { FirmDto } from '@/models';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import React from 'react';

interface FirmDetailsHeaderProps {
  firmId: FirmDto['data']['id'];
  companyName: FirmDto['data']['attributes']['companyName'];
}

const useStyles = makeStyles((theme: Theme) => ({
  copyToClipboardText: {
    color: theme.palette.text.secondary
  }
}));

const FirmDetailsHeader: React.FC<FirmDetailsHeaderProps> = ({
  firmId,
  companyName
}) => {
  const classes = useStyles();

  return (
    <Grid container justifyContent='space-between' mb='2.5rem'>
      <Grid item>
        <Grid container direction='column'>
          <NavigationBreadcrumbs paths={[{ name: 'Firms', to: '/firms' }]} />

          <Typography variant='h4'>{companyName}</Typography>

          <Grid alignItems='center' container>
            <Typography
              className={classes.copyToClipboardText}
              variant='subtitle1'>
              Firm ID: {firmId}
            </Typography>
            <Grid item ml='0.5rem'>
              <CopyToClipboard
                copyName='Firm ID'
                copyValue={String(firmId)}
                size='small'
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <AccessControl
          hideFromTPA
          requires={[FeatureLevelPermissions.WRITE_FIRM]}>
          <FirmModal action='edit' firmId={firmId} variant='outlined' />
        </AccessControl>
      </Grid>
    </Grid>
  );
};

export default FirmDetailsHeader;
