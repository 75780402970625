import { useSnackbar } from '@/contexts/SnackBarContext';
import { PlanService } from '@/services/Plan.service';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { FormikContextType } from 'formik';
import { useCallback, useMemo, useState } from 'react';

type FormDocumentStatus =
  | 'uploading-to-server'
  | 'in-memory'
  | 'downloading-from-server'
  | 'error'
  | 'deleting-from-server'
  | 'idle';

export const useOnboardingQuestionnaireDocumentsHook = (args: {
  formikRef: React.MutableRefObject<FormikContextType<any>>;
  required?: boolean;
  name: string;
  query: {
    documentKey: string;
    entity: string;
    entityId: string;
  };
}) => {
  const [isWaitingForUpload, setIsWaitingForUpload] = useState(false);

  const queryClient = useQueryClient();

  const onDocUploadedInMemory = useCallback(() => {
    setIsWaitingForUpload(true);

    if (args.required) {
      args.formikRef.current?.setFieldError(args.name, null);
      setTimeout(() =>
        args.formikRef.current?.setFieldTouched(args.name, true)
      );
    }
  }, [args.name, args.required]);

  const snackbar = useSnackbar();

  const deleteDoc = useMutation(
    ['PlanService.deleteOnboardingDocumentById'],
    (docId: number) => {
      return PlanService.deleteOnboardingDocumentById({
        documentId: docId,
        planId: +args.query.entityId
      });
    }
  );

  const serverDocuments = useQuery(
    ['PlanService.getAllPlanDocuments', args.query.entityId],
    async () => PlanService.getAllPlanDocuments(args.query.entityId)
  );
  const serverDocument = useMemo(() => {
    const doc = serverDocuments.data?.find(
      doc => doc.document_key === args.query.documentKey
    )?.latest;
    return doc
      ? {
          ...doc,
          path: doc?.original_file_name
        }
      : null;
  }, [serverDocuments.data, args.query.documentKey]);

  const serverDocumentId = useMemo(() => {
    return serverDocument?.id;
  }, [serverDocument]);

  const initialValue = useMemo(() => {
    return serverDocument ? [serverDocument] : [];
  }, [serverDocument]);

  const onDeleteFromServer = useCallback(async () => {
    setIsWaitingForUpload(false);

    if (isWaitingForUpload) return;

    if (serverDocumentId) {
      try {
        await deleteDoc.mutateAsync(serverDocumentId);

        queryClient.invalidateQueries([
          ['PlanService.getAllPlanDocuments', args.query.entityId]
        ]);
      } catch (e) {
        snackbar.showSnackbar({
          message: 'Error removing document',
          severity: 'error'
        });
      }
    }
  }, [
    isWaitingForUpload,
    serverDocuments.data,
    deleteDoc,
    queryClient,
    snackbar,
    serverDocumentId
  ]);

  const uploadDoc = useMutation(
    ['PlanService.uploadPlanDocument', args.query],
    async f => {
      const formData = new FormData();
      formData.append(
        'file',
        new Blob([f[0].data], {
          type: 'text/plain'
        }),
        f[0].path
      );
      formData.append('documentKey', args.query.documentKey);

      await PlanService.uploadPlanDocument(+args.query.entityId, formData);
    }
  );

  const onSave = useCallback(
    async f => {
      const formData = new FormData();
      formData.append(
        'file',
        new Blob([f.data], {
          type: 'text/plain'
        }),
        f.path
      );
      formData.append(
        'documentKey',
        'Onboarding/Prior Plan Adoption Agreement'
      );

      return await PlanService.uploadPlanDocument(
        +args.query.entityId,
        formData
      );
    },
    [args.query]
  );

  const status: FormDocumentStatus = useMemo(() => {
    if (uploadDoc.isLoading) {
      return 'uploading-to-server';
    } else if (isWaitingForUpload) {
      return 'in-memory';
    } else if (serverDocuments.isLoading) {
      return 'downloading-from-server';
    } else if (serverDocuments.isError) {
      return 'error';
    } else if (deleteDoc.isLoading) {
      return 'deleting-from-server';
    } else {
      return 'idle';
    }
  }, [
    isWaitingForUpload,
    uploadDoc.isLoading,
    serverDocuments.isLoading,
    serverDocuments.isError
  ]);

  return {
    initialValue,
    name: args.name,
    onDeleteFromServer,
    onDocUploadedInMemory,
    onSave,
    query: args.query,
    status
  };
};
