import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class ExternalPlanId {
  convertToApiState = Helper.convertValueToApiState;

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.output = props.externalPlanId;
  }
}

export default ExternalPlanId;
