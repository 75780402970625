import Badge from '@/components/badge';
import DatePickerForm from '@/components/date-picker/DatePickerForm';
import {
  CommunicationFilters,
  initialCommunicationFilters
} from '@/models/PlanCommunicationsDTO.model';
import { ChevronLeft, Search } from '@mui/icons-material';
import CachedIcon from '@mui/icons-material/Cached';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  alpha,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';

import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import { isEqual } from 'lodash';
import React, { FC } from 'react';
import { useToggle } from 'react-use';

import { physicalMailLetterEventTypesLabels, StatusBadgeProps } from './utils';

type PlanCommunicationsPhysicalMailFiltersProps = {
  updateFilters: (filters: CommunicationFilters) => void;
  filters: CommunicationFilters;
};

type PhysicalMailFiltersForm = {
  filter: string;
  category: string[];
  status: StatusBadgeProps[];
  startDate: string | null;
  endDate: string | null;
};

export const PlanCommunicationsPhysicalMailFilters: FC<
  PlanCommunicationsPhysicalMailFiltersProps
> = props => {
  const [isSidePanelCollapsed, toggleIsSidePanelCollapsed] = useToggle(false);
  const theme = useTheme();

  const handleSubmit = (values: PhysicalMailFiltersForm) => {
    const status = values.status.reduce((acc, curr) => {
      acc.push(...curr.values);
      return acc;
    }, [] as string[]);
    props.updateFilters({
      ...values,
      endDate: !values.endDate
        ? null
        : dayjs(values.endDate).add(1, 'day').format('MM/DD/YYYY'),
      filter: values.filter.trim(),
      order: props.filters.order,
      startDate: !values.startDate
        ? null
        : dayjs(values.startDate).format('MM/DD/YYYY'),
      status
    });
  };

  return (
    <Box borderRight={`1px solid ${grey[300]}`} p={2}>
      <Box
        alignItems='center'
        display='flex'
        justifyContent='space-between'
        pb={2}>
        {isSidePanelCollapsed ? (
          <IconButton
            aria-label='Show Filters'
            onClick={toggleIsSidePanelCollapsed}>
            <FilterListIcon fontSize='inherit' />
          </IconButton>
        ) : (
          <>
            <Box alignItems='center' display='flex' gap={1.5}>
              <FilterListIcon
                fontSize='inherit'
                sx={{ fill: alpha('#000', 0.54) }}
              />
              <Typography variant='h6'>Filters</Typography>
            </Box>
            <IconButton
              aria-label='Hide Filters'
              onClick={toggleIsSidePanelCollapsed}>
              <ChevronLeft fontSize='inherit' />
            </IconButton>
          </>
        )}
      </Box>
      <Collapse
        easing={theme.transitions.easing.sharp}
        in={!isSidePanelCollapsed}
        orientation='horizontal'>
        <Formik
          initialValues={initialCommunicationFilters}
          onSubmit={handleSubmit}>
          {({ setFieldValue, setFieldError, values, isValid, resetForm }) => {
            const areFiltersEmpty = isEqual(
              values,
              initialCommunicationFilters
            );

            return (
              <Form data-testid='physicalMailFilterForm'>
                <Box
                  alignItems='flex-start'
                  display='flex'
                  flexDirection='column'
                  gap={2}
                  width={272}>
                  <FormControl fullWidth>
                    <TextField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <Search />
                          </InputAdornment>
                        )
                      }}
                      data-testid='physicalMailSearchBarFilter'
                      onChange={e => {
                        setFieldValue('filter', e.target.value);
                      }}
                      placeholder='Filter by Participant ID'
                      size='small'
                      value={values.filter}
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <Autocomplete
                      data-testid='physicalMailStatusFilter'
                      disableCloseOnSelect
                      getOptionLabel={option => option.label}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                      }
                      multiple
                      onChange={(_event, newValues) => {
                        setFieldValue('status', newValues);
                      }}
                      options={physicalMailLetterEventTypesLabels}
                      renderInput={params => {
                        return (
                          <TextField
                            {...params}
                            data-testid='physicalMailStatusFilterInput'
                            label='Status'
                            sx={{
                              p: 0
                            }}
                          />
                        );
                      }}
                      renderOption={(rootProps, option, { selected }) => {
                        return (
                          <MenuItem {...rootProps} data-value={option.label}>
                            <Checkbox
                              checked={selected}
                              sx={{ pl: 0, py: 0 }}
                            />
                            {option.label}
                          </MenuItem>
                        );
                      }}
                      renderTags={tagValue => {
                        return tagValue.map(option => {
                          return (
                            <Box
                              data-testid={`physicalMailStatusFilterBadge${option.label}`}
                              key={option.label}
                              mb={0.5}
                              mr={0.5}>
                              <Badge
                                color={option.color}
                                deleteable
                                handleClick={() => {
                                  const newValues = values.status.filter(
                                    (badge: StatusBadgeProps) =>
                                      badge.label !== option.label
                                  );
                                  setFieldValue('status', newValues);
                                }}>
                                {option.label}
                              </Badge>
                            </Box>
                          );
                        });
                      }}
                      size='small'
                      value={values.status}
                    />
                  </FormControl>

                  <Divider style={{ width: '100%' }} textAlign='left'>
                    Create Date
                  </Divider>

                  <FormControl fullWidth>
                    <DatePickerForm
                      data-testid='physicalMailCreateDateFromFilter'
                      disableFuture
                      format='MM/DD/YYYY'
                      fullWidth
                      handleError={error => {
                        if (error === 'invalidDate') {
                          setFieldError('startDate', '');
                        }
                      }}
                      inputProps={{
                        autoComplete: 'off'
                      }}
                      label='From'
                      name='startDate'
                      size='small'
                      value={values.startDate}
                      variant='outlined'
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <DatePickerForm
                      data-testid='physicalMailCreateDateToFilter'
                      disableFuture
                      format='MM/DD/YYYY'
                      fullWidth
                      handleError={error => {
                        if (error === 'invalidDate') {
                          setFieldError('endDate', '');
                        }
                      }}
                      inputProps={{
                        autoComplete: 'off'
                      }}
                      label='To'
                      minDate={dayjs(values.startDate)}
                      name='endDate'
                      size='small'
                      value={values.endDate}
                      variant='outlined'
                    />
                  </FormControl>

                  <Box
                    alignItems='center'
                    display='flex'
                    gap={2}
                    justifyContent='space-between'
                    width='100%'>
                    <Button
                      data-testid='submit'
                      disabled={!isValid}
                      type='submit'
                      variant='outlined'>
                      Apply
                    </Button>
                    {!areFiltersEmpty && (
                      <Button
                        data-testid='reset'
                        onClick={() => {
                          resetForm();
                          props.updateFilters(initialCommunicationFilters);
                        }}
                        startIcon={<CachedIcon />}
                        variant='text'>
                        Reset
                      </Button>
                    )}
                  </Box>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Collapse>
    </Box>
  );
};
