import Constants from '@/consts/planDesign.constants';
import { EligibilityRule } from '@/models/PlanDesign.model';

class HoursRequired {
  convertToApiState = (value: string): number | undefined => {
    return value ? parseInt(value, 10) : undefined;
  };

  disabled: boolean;

  setDisabled = (value: boolean): void => {
    this.disabled = value;
  };

  output: number | string | undefined;

  serviceCalculationTypeValue: string | undefined;

  constructor(props: Record<string, any>) {
    this.output = this.transform(props.eligibilityRules);
    this.serviceCalculationTypeValue = this.getServiceCalculationTypeValue(
      props.eligibilityRules
    );
    this.disabled =
      !this.serviceCalculationTypeValue ||
      [
        'No Requirement',
        'Specified Months - Elapsed Time',
        'State IRA Eligibility'
      ].includes(this.serviceCalculationTypeValue || '');
  }

  private transform = (rules: EligibilityRule[]) => {
    const serviceRequirement = rules?.find(
      rule => rule.eligibilityRequirementTypeName !== 'Age'
    );
    return serviceRequirement?.ruleParameter?.hours?.toString();
  };

  getServiceCalculationTypeValue = (
    rules: EligibilityRule[] | undefined
  ): string | undefined => {
    if (!rules) {
      return '';
    }

    const eligibilityRule = rules.find(rule => {
      return Constants.eligibilityRequirementTypes
        .map(type => type.eligibilityRequirementTypeName)
        .includes(rule.eligibilityRequirementTypeName || '');
    });

    return eligibilityRule?.ruleParameter?.months === 0 &&
      eligibilityRule.eligibilityRequirementTypeId === 2
      ? 'No Requirement'
      : eligibilityRule?.eligibilityRequirementTypeName;
  };
}

export default HoursRequired;
