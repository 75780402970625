import Card, { CardHeader } from '@/components/card/Card.component';
import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { SearchAccountsContent } from '@/routes/ops/accounts/common/AllParentAccounts.component';
import { userService } from '@/services/User.service';
import { Typography } from '@mui/material';
import { AccountLevel } from '@vestwell-sub-accounting/models/accountsAndLedgers/AccountLevel';

import React, { useState } from 'react';

import { SubAccounts } from '../common/SubAccounts.component';

export const SearchAccountsRoute = (): JSX.Element => {
  const [accountLevel, setAccountLevel] = useState<AccountLevel>(
    AccountLevel.ParentAccount
  );

  const hasRequiredPermissions = userService.hasPermission(
    FeatureLevelPermissions.READ_SUBA_ACCOUNTS
  );

  if (!hasRequiredPermissions) {
    return redirectToErrorPage(new Error('403'));
  }

  return (
    <>
      <Typography component='h2' mb={5} variant='h4'>
        Accounts
      </Typography>
      <Card>
        <CardHeader
          title='Search Accounts'
          toggle={{
            onChangeAction: (
              event: React.MouseEvent<HTMLElement>,
              value: any
            ) => {
              setAccountLevel(value);
            },
            options: [
              {
                label: 'Parent',
                value: AccountLevel.ParentAccount
              },
              { label: 'Sub', value: AccountLevel.SubAccount }
            ],
            value: accountLevel
          }}
        />
        {accountLevel === AccountLevel.ParentAccount && (
          <SearchAccountsContent />
        )}
        {accountLevel === AccountLevel.SubAccount && <SubAccounts />}
      </Card>
    </>
  );
};

export default SearchAccountsRoute;
