import Card, { CardContent } from '@/components/card';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useParentAccountByCustodianQuery } from '@/hooks/ops/useParentAccountByCustodianQuery.hook';
import BalanceService from '@/services/ops/balances/Balance.service';
import CurrentDatesAndWindowsService from '@/services/ops/current-dates-and-windows/CurrentDatesAndWindows.service';
import formatters from '@/utils/Formatters';
import { Divider, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { PositionDateType } from '@vestwell-sub-accounting/models/accountsAndLedgers/PositionDateType';

import { AxiosError } from 'axios';
import { FC, useContext } from 'react';

import { AlertContext } from '../../AlertContext';
import { CardHeader } from '../common/CardHeader.component';

export const CashTransferRejectedExpandedFromAccountBalances: FC = () => {
  const alert = useContext(AlertContext);
  const { showSnackbar } = useSnackbar();

  const request = alert.details?.request ?? {};

  const fromSubAccount = request.fromSubAccount ?? {};
  const fromParentAccount = fromSubAccount?.parentAccount ?? {};

  const getCurrentDatesAndWindowsQuery = useQuery(
    ['CurrentDatesAndWindowsService.get'],
    () => CurrentDatesAndWindowsService.get(),
    {
      cacheTime: 0,
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Failed to fetch current trade date and processing windows: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const parentAccountQuery = useParentAccountByCustodianQuery({
    custodianAccountNumber: fromParentAccount.custodianAccountNumber,
    custodianId: fromParentAccount.custodianId
  });

  const getParentAccountTradeBalanceQuery = useQuery(
    ['BalanceService.get', parentAccountQuery.data?.parentAccountId],
    () =>
      BalanceService.get(parentAccountQuery.data.parentAccountId, {
        dateType: PositionDateType.Trade
      }),
    {
      enabled: Boolean(parentAccountQuery.data?.parentAccountId),
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Balance request failed: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const getParentAccountSettlementBalanceQuery = useQuery(
    ['BalanceService.get', parentAccountQuery.data?.parentAccountId],
    () =>
      BalanceService.get(parentAccountQuery.data.parentAccountId, {
        dateType: PositionDateType.Settlement
      }),
    {
      enabled: Boolean(parentAccountQuery.data?.parentAccountId),
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Balance request failed: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const getSubAccountTradeBalanceQuery = useQuery(
    ['BalanceService.get', fromSubAccount.id],
    () =>
      BalanceService.get(fromSubAccount.id, {
        dateType: PositionDateType.Trade
      }),
    {
      enabled: Boolean(fromSubAccount.id),
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Balance request failed: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const getSubaccountSettlementBalanceQuery = useQuery(
    ['BalanceService.get', fromSubAccount.id],
    () =>
      BalanceService.get(fromSubAccount.id, {
        dateType: PositionDateType.Settlement
      }),
    {
      enabled: Boolean(fromSubAccount.id),
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Balance request failed: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  return (
    <Card size='small'>
      <CardHeader title='From Account Balances' />
      <Divider />
      <CardContent>
        <Stack spacing={5}>
          <Stack direction='row' justifyContent='space-between' spacing={2}>
            <Stack flex={1} spacing={1}>
              <div>
                <Typography variant='subtitle1'>Parent Account</Typography>
                <Typography variant='subtitle2'>
                  {fromParentAccount?.accountName}
                </Typography>
              </div>
              <TextStack direction='column'>
                <TextStackItem>
                  <TextLabel>Current Balance</TextLabel>
                  <TextValue>
                    {getParentAccountTradeBalanceQuery.data?.cash?.confirmed
                      ? formatters.formatDollars(
                          getParentAccountTradeBalanceQuery.data?.cash.confirmed
                        )
                      : null}
                  </TextValue>
                </TextStackItem>
                <TextStackItem>
                  <TextLabel>Settlement Balance</TextLabel>
                  <TextValue>
                    {getParentAccountSettlementBalanceQuery.data?.cash
                      ?.confirmed
                      ? formatters.formatDollars(
                          getParentAccountSettlementBalanceQuery.data?.cash
                            ?.confirmed
                        )
                      : null}
                  </TextValue>
                </TextStackItem>
              </TextStack>
              <Typography variant='caption'>
                {`As of date: ${
                  getCurrentDatesAndWindowsQuery.data?.tradingDate
                    ? formatters.formatFromIsoDateCustom(
                        getCurrentDatesAndWindowsQuery.data.tradingDate,
                        'MM/DD/YYYY'
                      )
                    : '-'
                }`}
              </Typography>
            </Stack>
            <Divider flexItem orientation='vertical' />
            <Stack flex={1} spacing={1}>
              <div>
                <Typography variant='subtitle1'>Sub Account</Typography>
                <Typography variant='subtitle2'>
                  {formatters.displayCase(fromSubAccount?.accountType)}
                </Typography>
              </div>
              <TextStack direction='column'>
                <TextStackItem>
                  <TextLabel>Current Balance</TextLabel>
                  <TextValue>
                    {getSubAccountTradeBalanceQuery.data?.cash?.confirmed
                      ? formatters.formatDollars(
                          getSubAccountTradeBalanceQuery.data.cash.confirmed
                        )
                      : null}
                  </TextValue>
                </TextStackItem>
                <TextStackItem>
                  <TextLabel>Settlement Balance</TextLabel>
                  <TextValue>
                    {getSubaccountSettlementBalanceQuery.data?.cash?.confirmed
                      ? formatters.formatDollars(
                          getSubaccountSettlementBalanceQuery.data.cash
                            .confirmed
                        )
                      : null}
                  </TextValue>
                </TextStackItem>
              </TextStack>
              <Typography variant='caption'>
                {`As of date: ${
                  getCurrentDatesAndWindowsQuery.data?.tradingDate
                    ? formatters.formatFromIsoDateCustom(
                        getCurrentDatesAndWindowsQuery.data.tradingDate,
                        'MM/DD/YYYY'
                      )
                    : '-'
                }`}
              </Typography>
            </Stack>
          </Stack>
          <Stack spacing={1}>
            <Typography variant='subtitle1'>Custodian</Typography>
            <TextStack direction='column'>
              <TextStackItem>
                <TextLabel>Cash Balance</TextLabel>
                <TextValue>
                  {parentAccountQuery.data?.cashBalance
                    ? formatters.formatDollars(
                        parentAccountQuery.data?.cashBalance
                      )
                    : ''}
                </TextValue>
              </TextStackItem>
            </TextStack>
            <Typography variant='caption'>
              {`Effective date: ${
                getCurrentDatesAndWindowsQuery.data?.tradingDate
                  ? formatters.formatFromIsoDateCustom(
                      getCurrentDatesAndWindowsQuery.data.tradingDate,
                      'MM/DD/YYYY'
                    )
                  : '-'
              }`}
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

CashTransferRejectedExpandedFromAccountBalances.displayName =
  'CashTransferRejectedExpandedFromAccountBalances';
