import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

import { useFormikContext } from 'formik';
import { FC, useEffect } from 'react';

const RecordkeeperAndCustodianObserver: FC<{
  data: Record<string, any>;
}> = ({ data }: { data: Record<string, any> }) => {
  const { values }: { values: Record<string, any> } = useFormikContext();

  useEffect(() => {
    data.priorProvider.setDisabled(
      values.conversionType !== Helper.getConversionTypeName('true')
    );
  }, [values, data]);

  return null;
};

export default RecordkeeperAndCustodianObserver;
