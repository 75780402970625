import { createSvgIcon, SvgIconProps } from '@mui/material';

import React, { FC } from 'react';

// Custom ViewParent icon created by Derek in Figma
const ViewParentIcon: FC<SvgIconProps> = createSvgIcon(
  <path
    clipRule='evenodd'
    d='M21 6H10V4H21V6ZM21 10L18 7L15 10L17 10V12C17 12.5523 16.5523 13 16 13H14V15H16C17.6569 15 19 13.6569 19 12V10L21 10ZM3 11H13V9H3V11ZM13 15H3V13H13V15ZM3 19H13V17H3V19Z'
    fill='black'
    fillOpacity='0.38'
    fillRule='evenodd'
  />,
  'ViewParentIcon'
);

ViewParentIcon.defaultProps = {
  viewBox: '0 0 24 24'
};

ViewParentIcon.displayName = 'ViewParentIcon';

export default ViewParentIcon;
