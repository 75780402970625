// copied from https://github.com/vestwell/vestwell-admin-ui/blob/1d096e35f35f7b0530902cfecb2beb789b42b2ea/src/routes/plans/plan-detail/PlanTab/constants.ts#L51-L63
export enum PlanAdminStatus {
  Abandoned = 'Abandoned',
  Active = 'Active',
  DeconversionRequested = 'DeconversionRequested',
  Deconverted = 'Deconverted',
  Deferred = 'Deferred',
  ObCancelled = 'OB Cancelled',
  Onboarding = 'Onboarding',
  Pending = 'Pending',
  ReadyAwaitingEffectiveDate = 'Ready — Awaiting Effective Date',
  Terminated = 'Terminated',
  TerminationRequested = 'Termination Requested'
}
