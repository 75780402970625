import dayjs from 'dayjs';

export const findAvailableDates = (
  date: string,
  existingDates: string[] = [],
  config: {
    availableDates?: number;
    maxSteps?: number;
    step?: number;
  } = {
    availableDates: 2,
    maxSteps: 60,
    step: 1
  }
) => {
  let i = config.step;
  const dates = [];

  while (Math.abs(i) <= 60 && dates.length < 2) {
    const futureDate = dayjs(date).add(i, 'day').format('MM/DD/YYYY');

    if (!existingDates.includes(futureDate)) {
      dates.push(futureDate);
    }

    i = i + config.step;
  }

  return dates;
};
