import AccessControl from '@/components/access-control/AccessControl.component';
import useHasPermissions from '@/components/access-control/useHasPermissions.hook';
import CircularLoading from '@/components/circular-loading';
import CollapsibleTable, { Order } from '@/components/collapsible-table';
import { CreatePooledPlanModal } from '@/components/entity-modals/EntityModals.component';
import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import LinearLoading from '@/components/linear-loading';
import { PooledPlanDto } from '@/models/PooledPlanDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import PooledPlansTableCell from '@/routes/plans/plans/PooledPlansIndex/PooledPlansTableCell.component';
import { PlanService } from '@/services/Plan.service';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Grid,
  Stack,
  TableCell,
  TableFooter,
  TableRow,
  Theme,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { isEqual } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

import { initialPlansFilters, PlanFilters } from '../consts';
import { PlansFilters } from '../PlansFilters/PlansFilters.component';

const useStyles = makeStyles((theme: Theme) => ({
  margin: {
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(3)
  },
  planIdCellWidth: {
    width: theme.spacing(20)
  },
  planNameCellWidth: {
    width: theme.spacing(90)
  },
  textField: {
    width: '50ch'
  }
}));

const PooledPlansIndex = (): JSX.Element => {
  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [planFilters, setPlanFilters] =
    useState<PlanFilters>(initialPlansFilters);
  const areFiltersApplied = !isEqual(planFilters, initialPlansFilters);

  const [sorter, setSorter] = useState<{ direction: Order; by: string }>({
    by: 'status',
    direction: 'asc'
  });

  const perms = useHasPermissions({
    requiresOneOf: [FeatureLevelPermissions.WRITE_CREATE_PLAN]
  });

  const columns = useMemo<GridColDef[]>(() => {
    return [
      {
        cellClassName: classes.planIdCellWidth,
        field: 'pooledPlanId',
        headerName: 'ID',
        sortable: true,
        width: 130
      },
      {
        cellClassName: classes.planNameCellWidth,
        field: 'pooledPlanName',
        headerName: 'Name',
        sortable: true,
        width: 130
      },
      {
        field: 'pooledPlanStatus',
        headerName: 'Plan Design Status',
        sortable: true,
        width: 130
      },
      {
        field: 'pooledPlanType',
        headerName: 'Type',
        sortable: true,
        width: 130
      },
      ...(perms.isAllowed
        ? [
            {
              field: 'action',
              headerName: 'Action',
              sortable: true,
              width: 130
            }
          ]
        : [])
    ];
  }, [classes, perms]);

  const planListQuery = useQuery<PooledPlanDto>(
    [
      'PlanService.getPooledPlansPage',
      pageNumber,
      rowsPerPage,
      planFilters.search,
      planFilters.search,
      planFilters.filterType,
      planFilters.types,
      planFilters.statuses,
      sorter.by,
      sorter.direction
    ],
    async () =>
      PlanService.getPooledPlansPage(
        pageNumber,
        rowsPerPage,
        planFilters.search,
        planFilters.filterType,
        planFilters.statuses.map(status => status.toLocaleLowerCase()),
        planFilters.types.map(type => type.toLocaleLowerCase()),

        sorter.by,
        sorter.direction
      ),
    {
      cacheTime: 10_000_000,
      keepPreviousData: true,
      retry: false,
      staleTime: 10_000_000
    }
  );

  const updateFilters = useCallback((newFilters: PlanFilters) => {
    setPlanFilters(newFilters);
    setPageNumber(1);
  }, []);

  if (planListQuery.error) {
    return redirectToErrorPage(planListQuery.error as Error);
  }

  if (planListQuery.isFetching) {
    return <LinearLoading />;
  }

  return (
    <>
      <Grid container mb={3} spacing={2}>
        <Grid item sm={4} xs={6}></Grid>
        <Grid item sm={6} xs={false} />
        <Grid item sm={2} xs={5}>
          <Box display='flex' justifyContent='flex-end'>
            <AccessControl
              hideFromTPA
              requiresOneOf={[FeatureLevelPermissions.WRITE_CREATE_PLAN]}>
              <CreatePooledPlanModal onRefetch={planListQuery.refetch} />
            </AccessControl>
          </Box>
        </Grid>
      </Grid>
      <Stack
        alignContent='flex-start'
        border={`1px solid ${grey[300]}`}
        borderRadius={1}
        flexDirection='row'
        justifyContent='flex-start'
        overflow='hidden'>
        <PlansFilters
          areFiltersApplied={areFiltersApplied}
          isPooledPlanDisplay
          updateFilters={updateFilters}
        />
        <Box overflow='auto' width='100%'>
          <CollapsibleTable
            backgroundPaperElevation={0}
            cellComponent={PooledPlansTableCell}
            columns={columns}
            disablePagination={!planListQuery.data?.meta.count}
            footerComponent={
              <>
                {(!planListQuery.data?.meta.count ||
                  planListQuery.isFetching) && (
                  <TableFooter>
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        sx={{
                          border: 'none',
                          paddingBottom: 20,
                          paddingTop: 20
                        }}>
                        <Stack
                          alignItems='center'
                          gap={1}
                          justifyContent='center'>
                          {planListQuery.isFetching ? (
                            <CircularLoading />
                          ) : (
                            <>
                              <SearchIcon
                                sx={{
                                  color: 'common.black',
                                  height: 40,
                                  width: 40
                                }}
                              />
                              <Typography variant='body2'>
                                No plans found
                              </Typography>
                            </>
                          )}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                )}
              </>
            }
            pager={{
              metaCount: planListQuery.data?.meta?.count,
              onPageNumberChanged: (zeroIndexedPageNumber: number) =>
                setPageNumber(zeroIndexedPageNumber),
              onRowsPerPageChanged: (newRowsPerPage: number) =>
                setRowsPerPage(newRowsPerPage),
              pageNumber: pageNumber - 1,
              rowsPerPage
            }}
            primaryKey='sponsorPlanId'
            rootPaperElevation={0}
            sorter={{
              onSortOrderChanged: (newOrderBy: string, newOrder: Order) => {
                setSorter({ by: newOrderBy, direction: newOrder });
              },
              order: sorter.direction,
              orderBy: sorter.by
            }}
            tableData={planListQuery.data?.data || []}
            useSquareBottomContainer
          />
        </Box>
      </Stack>
    </>
  );
};

export default PooledPlansIndex;
