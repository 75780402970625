import CollapsibleTable from '@/components/collapsible-table';
import SimpleTabs, { SimpleTabsProps, TabData } from '@/components/simple-tabs';
import {
  LoanTradeRequestDto,
  LoanTradeRequestTable,
  LoanTransactionResponseDto
} from '@/models/LoanDTO.model';
import ParticipantService from '@/services/Participant.service';
import { Card, CardContent } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import LoanTradeRequestsTableCell from './LoanTradeRequestsTableCell.component';
import LoanTransactionsTableCell from './LoanTransactionsTableCell.component';

const transactionColumns: GridColDef[] = [
  { field: 'id', headerName: 'Tx ID' },
  { field: 'tradeDate', headerName: 'Trade Date' },
  { field: 'effectiveDate', headerName: 'Effective Date' },
  { field: 'fundingSource', headerName: 'Funding Source' },
  { field: 'transactionSubtype', headerName: 'Tx Subtype' },
  { field: 'ticker', headerName: 'Ticker' },
  { field: 'dollars', headerName: 'Amount' },
  { field: 'status', headerName: 'Status' },
  { field: 'tracerId', headerName: 'Tracer ID' }
];

const tradeRequestColumns: GridColDef[] = [
  { field: 'type', headerName: 'Type' },
  { field: 'status', headerName: 'Execution Status' },
  { field: 'workflowStatus', headerName: 'Workflow Status' },
  { field: 'createdAt', headerName: 'Created At' },
  { field: 'updatedAt', headerName: 'Updated At' },
  { field: 'tracerId', headerName: 'Tracer ID' },
  { field: 'action', headerName: '' }
];

type LoanDetailsDataGridsCardProps = {
  participantId: string;
  loanId: string;
};

const LoanDetailsDataGridsCard: React.FunctionComponent<{
  tradeRequests: LoanTradeRequestDto;
}> = props => {
  const { participantId, loanId } = useParams<LoanDetailsDataGridsCardProps>();

  const transactions = useQuery<LoanTransactionResponseDto>(
    ['ParticipantService.getLoanTransactions', +participantId, +loanId],
    () => {
      return ParticipantService.getLoanTransactions(+participantId, +loanId);
    },
    {
      staleTime: Infinity
    }
  );

  const tradeRequestTableData = useMemo(() => {
    if (props.tradeRequests) {
      return [
        {
          createdAt: props.tradeRequests.createdAt,
          id: props.tradeRequests.id,
          status: props.tradeRequests.status,
          subaResult: props.tradeRequests.subaResult,
          tracerId: props.tradeRequests.tracerId,
          type: props.tradeRequests.actionType,
          updatedAt: props.tradeRequests.updatedAt,
          workflowStatus: props.tradeRequests.workflowStatus
        }
      ] as LoanTradeRequestTable[];
    }
    return [];
  }, [props.tradeRequests]);

  const makeTabsElement = (): SimpleTabsProps => {
    const LoanTransactionsTab: TabData = {
      component: (
        <CollapsibleTable
          cellComponent={LoanTransactionsTableCell}
          columns={transactionColumns}
          tableData={transactions.data?.data || []}
        />
      ),
      label: 'Transactions',
      path: `/participant/${participantId}/loans/${loanId}/transactions`
    };
    const LoanTradeRequestsTab: TabData = {
      component: (
        <CollapsibleTable
          cellComponent={LoanTradeRequestsTableCell}
          columns={tradeRequestColumns}
          tableData={tradeRequestTableData}
        />
      ),
      label: 'Trade Requests',
      path: `/participant/${participantId}/loans/${loanId}/tradeRequests`
    };

    const registeredTabs = [LoanTradeRequestsTab, LoanTransactionsTab];

    const displayTabs: SimpleTabsProps = {
      disableStateInUrl: true,
      tabs: registeredTabs,
      tabsAriaLabel: 'loan-details-data-grid-tabs'
    };

    return displayTabs;
  };

  const tabs = makeTabsElement();

  return (
    <Card elevation={0} sx={{ marginTop: 2 }} variant='outlined'>
      <CardContent sx={{ padding: 0 }}>
        <SimpleTabs {...tabs} />
      </CardContent>
    </Card>
  );
};

export default LoanDetailsDataGridsCard;
