import { StreamParser } from '@json2csv/plainjs';

export const json2csvParser = (data: Array<unknown>): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (data.length === 0) {
      resolve('');
    }

    const opts = { fields: Object.keys(data[0]) };
    const parser = new StreamParser(opts);

    let csv = '';
    parser.onData = chunk => (csv += chunk.toString());
    parser.onEnd = () => resolve(csv);
    parser.onError = err => reject(err);

    parser.write(JSON.stringify(data));
    parser.end();
  });
};
