import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { Theme } from '@mui/material/styles';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      ...theme.typography.caption,
      color: theme.palette.grey[400],
      fontSize: theme.spacing(2)
    }
  })
);

const PlanUsersTableCell: React.FunctionComponent<TableCellProps> = (
  props: TableCellProps
): JSX.Element => {
  const classes = useStyles();
  const { children } = props;

  return (
    <TableCell
      className={classes.body}
      style={{ color: children === EMPTY_FIELD_PLACEHOLDER ? '' : 'black' }}>
      {children}
    </TableCell>
  );
};

export default PlanUsersTableCell;
