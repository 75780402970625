import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class SpecialParticipationDate {
  convertToApiState = Helper.transformDateToApiState;

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.output = Helper.transformDate(props.specialParticipationDate);
  }
}

export default SpecialParticipationDate;
