import CollapsibleTable from '@/components/collapsible-table';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import {
  AssetClass,
  GoalSeriesFund,
  ManagedAccountSeries
} from '@/routes/ops/investments/ManagedAccount.component';
import formatters from '@/utils/Formatters';
import {
  alpha,
  Box,
  Card,
  CardContent,
  CardHeader,
  Theme
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GridColDef } from '@mui/x-data-grid-pro';

import Decimal from 'decimal.js';
import React, { useMemo, useState } from 'react';

import ManagedAccountTableCell from './ManagedAccountCell.component';
import { ManagedAccountFields } from './types';

const mapDataRow = (option: GoalSeriesFund, maxTickerLength: number) => ({
  actualAssetClass: option.broadAssetClass,
  expenseRatio: new Decimal(option.expenseRatio || 0).toFixed(2),
  id: option.fundId,
  maxTickerLength,
  recommendedAssetClass: option.assetClass,
  security: {
    cusip: option.cusip,
    fundName: option.fundName,
    id: option.symbol
  }
});

const useStyles = makeStyles((theme: Theme) => ({
  card: { padding: theme.spacing(3) },
  cardContext: { padding: 0, paddingTop: theme.spacing(2.25) },
  cardHeaderRoot: { padding: 0 },
  cardHeaderTitle: { fontWeight: 500 },
  headerCell: {
    color: alpha(theme.palette.common.black, 0.6),
    fontSize: theme.spacing(1.75),
    fontWeight: 500,
    paddingBottom: theme.spacing(1.25),
    paddingTop: theme.spacing(1.25)
  }
}));

interface ManagedAccountTableProps {
  managedAccountData: ManagedAccountSeries;
  managedAccountFund: GoalSeriesFund[];
  assetClasses: AssetClass[];
}

const ManagedAccountTable: React.FC<ManagedAccountTableProps> = props => {
  const { assetClasses, managedAccountFund, managedAccountData } = props;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const classes = useStyles();

  const tickerRows = useMemo(() => {
    return assetClasses.map(ac => {
      return {
        ...(managedAccountFund.find(
          fund => fund.goeAccountAssetClassId === ac.goeAccountAssetClassId
        ) || ac),
        assetClass: ac.assetClass
      };
    });
  }, [assetClasses, managedAccountFund]) as GoalSeriesFund[];

  const columns: GridColDef[] = useMemo(
    () => [
      {
        cellClassName: classes.headerCell,
        field: ManagedAccountFields.RECOMMENDED_ASSET_CLASS,
        headerName: 'Recommended Asset Class'
      },
      {
        cellClassName: classes.headerCell,
        field: ManagedAccountFields.SECURITY,
        headerName: 'Security'
      },
      {
        cellClassName: classes.headerCell,
        field: ManagedAccountFields.ACTUAL_ASSET_CLASS,
        headerName: 'Actual Asset Class'
      },
      {
        cellClassName: classes.headerCell,
        field: ManagedAccountFields.EXPENSE_RATIO,
        headerName: 'Expense Ratio'
      }
    ],
    [classes]
  );

  const tableData = useMemo(() => {
    if (!tickerRows) return [];

    const maxTickerLength = Math.max(
      ...tickerRows.map(p => p.symbol?.length || 0)
    );
    return tickerRows.map(p => mapDataRow(p, maxTickerLength));
  }, [tickerRows]);

  const programDesc = [
    { label: 'Owner', value: 'Vestwell' },
    {
      label: 'Description',
      value: formatters.chopStringLongerThan(
        managedAccountData?.description,
        300
      )
    },
    { label: 'GOE Account', value: managedAccountData?.goeAccountId }
  ];

  return (
    <Box>
      <Card className={classes.card} elevation={0} variant='outlined'>
        <CardHeader
          classes={{
            root: classes.cardHeaderRoot,
            title: classes.cardHeaderTitle
          }}
          title={managedAccountData?.name}
        />
        <CardContent className={classes.cardContext}>
          <TextStack direction='row' rowColumnWidth='dynamic'>
            {programDesc
              .filter(item => item.value)
              .map(item => (
                <TextStackItem key={item.label} maxWidth='100%'>
                  <TextLabel>{item.label}</TextLabel>
                  <TextValue>{item.value}</TextValue>
                </TextStackItem>
              ))}
          </TextStack>
        </CardContent>
      </Card>
      <CollapsibleTable
        backgroundPaperElevation={0}
        cellComponent={ManagedAccountTableCell}
        columns={columns}
        pager={{
          metaCount: tableData.length,
          onPageNumberChanged: newPage => setPageNumber(newPage + 1),
          onRowsPerPageChanged: newRowsPerPage =>
            setRowsPerPage(newRowsPerPage),
          pageNumber: pageNumber - 1,
          rowsPerPage: rowsPerPage
        }}
        primaryKey='managedAccountId'
        rootPaperElevation={0}
        tableData={tableData.slice(
          rowsPerPage * pageNumber - rowsPerPage,
          rowsPerPage * pageNumber
        )}
        useSquareBottomContainer
      />
    </Box>
  );
};

export default ManagedAccountTable;
