import {
  BalanceDto,
  PositionBalanceDto
} from '@/models/ops/balances/BalanceDTO.model';

import { useMemo } from 'react';

type AccountResult = {
  confirmedValue: number;
  displayBalance: DisplayBalance[];
};

export type DisplayBalance = PositionBalanceDto & {
  confirmedValue: number | null; // null if missing price
  pendingValue: number | null; // null if missing price
  orgHierarchy: string[];
  accountId?: string;
};

const useAccountValue = (data: BalanceDto | undefined): AccountResult => {
  return useMemo(() => {
    const formattedParentData: DisplayBalance[] =
      data?.positions?.map(balance => ({
        ...balance,
        confirmedValue: balance.price
          ? Number(balance.confirmedUnits) * Number(balance.price)
          : null,
        orgHierarchy: [balance.security.cusip],
        pendingValue: balance.price
          ? Number(balance.pendingUnits) * Number(balance.price)
          : null
      })) ?? [];
    formattedParentData.sort((a, b) => {
      const aVal = a.security.symbol || '';
      const bVal = b.security.symbol || '';
      return aVal > bVal ? 1 : -1;
    }); // default sort by symbol

    // account value is the sum of all confirmed values from holdings plus the confirmed cash balance
    const confirmedValue = formattedParentData.reduce(
      (sum, balance) => sum + (balance.confirmedValue ?? 0),
      0
    );

    // flatten the subaccount positions into the main positions notating the organizational hierarchy of the parent account / cusip they belong to
    const flattendedData = formattedParentData.reduce((acc, balance) => {
      const subAccountPositions =
        balance.subAccountPositions?.map(subAccountBalance => ({
          ...subAccountBalance,
          confirmedValue: balance.price
            ? Number(subAccountBalance.confirmedUnits) * Number(balance.price)
            : null,
          orgHierarchy: [balance.security.cusip, subAccountBalance.accountId],
          pendingValue: balance.price
            ? Number(subAccountBalance.pendingUnits) * Number(balance.price)
            : null,
          price: balance.price,
          security: balance.security
        })) ?? [];
      return [...acc, balance, ...subAccountPositions];
    }, [] as DisplayBalance[]);

    return {
      confirmedValue: confirmedValue + Number(data?.cash?.confirmed ?? 0),
      displayBalance: flattendedData
    };
  }, [data]);
};

export default useAccountValue;
