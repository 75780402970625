import { DeferralRatesParticipants } from '@/models/ParticipantDTO.model';
import { PlanService } from '@/services/Plan.service';
import { formatSsn } from '@vestwell-frontend/helpers';

import { isEmpty } from 'lodash';
import * as yup from 'yup';

const formattedSSN = /^(?!(000))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/;
const plainSSN = /^(?!(000))\d{3}(?!00)\d{2}(?!0000)\d{4}$/;
const blacklistedSSNs = ['123-45-6789', '987-65-4321'];

const deferralRatesValidationSchema = (
  planId: number,
  participants: DeferralRatesParticipants[],
  isDeferInDollars: boolean
): yup.ArraySchema<yup.AnySchema> => {
  function checkValidValues(currentFieldType: string, currentRowData: any) {
    if (
      currentRowData.toPreTaxType === '%' &&
      currentRowData.toRothType === '%'
    ) {
      return currentRowData.toPreTaxValue + currentRowData.toRothValue > 100
        ? 'Combined savings rate cannot be greater than 100%.'
        : false;
    } else if (currentRowData[currentFieldType] === '%') return false;
    //check max value from irsLimits
    else {
      if (currentRowData.ssn === undefined) {
        return false;
      }

      const ssnNoDashes = currentRowData.ssn.replace(/-/g, '');

      const foundParticipant = participants.find(
        participant => participant.ssn === ssnNoDashes
      );

      if (foundParticipant === undefined) {
        return false;
      }

      const preTaxValue =
        currentRowData.toPreTaxType === '$' ? currentRowData.toPreTaxValue : 0;
      const rothValue =
        currentRowData.toRothType === '$' ? currentRowData.toRothValue : 0;

      return preTaxValue + rothValue > foundParticipant.irsLimits
        ? `Combined contribution can’t exceed ${foundParticipant.irsLimits}.`
        : false;
    }
  }

  const taxType = ['%', ...(isDeferInDollars ? ['$'] : [])];
  const pretaxTypeError = isDeferInDollars
    ? 'New_Pretax_Type must be either $ or %.'
    : 'This plan does not allow $ deferrals. Please enter % value.';
  const rothTypeError = isDeferInDollars
    ? 'New_Pretax_Type must be either $ or %.'
    : 'This plan does not allow $ deferrals. Please enter % value.';

  return yup
    .array()
    .of(
      yup
        .object()
        .when({
          is: ({ ...row }) => !isEmpty(row),
          otherwise: schema => schema,
          then: schema =>
            schema.shape({
              isAffirmativeElection: yup
                .boolean()
                .optional()
                .typeError('The value must be boolean'),
              ssn: yup
                .string()
                .required('SSN is required.')
                .test(
                  'Check for valid SSN format',
                  'Please enter a valid SSN',
                  (value?: string) => {
                    if (value) {
                      const ssnNoDashes = value.replace(/-/g, '');
                      const ssnDigits = new Set(ssnNoDashes.split(''));
                      return (
                        (formattedSSN.test(value) || plainSSN.test(value)) &&
                        ssnDigits.size > 1 &&
                        !blacklistedSSNs.includes(ssnNoDashes)
                      );
                    }
                    return true;
                  }
                )
                .test(
                  'Check if SSN exist within a plan',
                  'This SSN does not exist in plan.',
                  async value => {
                    let checkResult;
                    if (
                      planId &&
                      value &&
                      (formattedSSN.test(value) || plainSSN.test(value)) &&
                      !blacklistedSSNs.includes(value.replaceAll('-', '')) &&
                      new Set(value.replaceAll('-', '').split('')).size > 1
                    ) {
                      const ssnToValidate = formattedSSN.test(value)
                        ? value
                        : formatSsn(value);
                      checkResult = await PlanService.checkSsnWithinPlan(
                        planId,
                        ssnToValidate
                      );
                    } else {
                      await new Promise(resolve => {
                        setTimeout(resolve, 50);
                      });
                    }
                    return checkResult?.data ?? false;
                  }
                ),
              toPreTaxType: yup
                .string()
                .oneOf(taxType, pretaxTypeError)
                .required(pretaxTypeError),
              toPreTaxValue: yup
                .number()
                .typeError('New_Pretax_Value must be numeric.')
                .min(0, 'New_Pretax_Value cannot be less than 0.')
                .integer('New_Pretax_Value must be whole number.')
                .when('toPreTaxType', {
                  is: (toPreTaxType: string) => toPreTaxType === '%',
                  then: yup
                    .number()
                    .typeError('New_Pretax_Value must be numeric.')
                    .max(
                      100,
                      'Pre-Tax savings rate cannot be greater than 100%.'
                    )
                })
                .required('New_Pretax_Value is required.'),
              toRothType: yup
                .string()
                .oneOf(taxType, rothTypeError)
                .required(rothTypeError),
              toRothValue: yup
                .number()
                .typeError('New_Roth_Value must be numeric.')
                .min(0, 'New_Roth_Value cannot be less than 0.')
                .integer('New_Roth_Value must be whole number.')
                .when('toRothType', {
                  is: (toRothType: string) => toRothType === '%',
                  then: yup
                    .number()
                    .typeError('New_Roth_Value must be numeric.')
                    .max(100, 'Roth savings rate cannot be greater than 100%.')
                })
                .required('New_Roth_Value is required.')
            })
        })
        .test({
          test: function (row) {
            const errorsList = [
              ...(
                this.parent
                  .filter((o: any) => o !== row)
                  .map((o: any) => {
                    if (o?.ssn && o?.ssn === row?.ssn) {
                      return this.createError({
                        message: 'Duplicate SSN.',
                        path: `${this.path}.ssn`
                      });
                    }

                    return true;
                  }) as (true | yup.ValidationError)[]
              ).filter(error => error instanceof yup.ValidationError),
              ...[
                ['toPreTaxValue', 'toPreTaxType'],
                ['toRothValue', 'toRothType']
              ]
                .map(field => {
                  const hasError = checkValidValues(field[1], row);
                  if (hasError) {
                    return this.createError({
                      message: hasError,
                      path: `${this.path}.${field[0]}`
                    });
                  }

                  return true;
                })
                .filter(error => error instanceof yup.ValidationError)
            ];

            if (errorsList.length > 0) {
              return new yup.ValidationError(
                errorsList as yup.ValidationError[],
                row,
                this.path
              );
            }

            return true;
          }
        })
    )
    .min(1);
};

export default deferralRatesValidationSchema;
