import { useSnackbar } from '@/contexts/SnackBarContext';
import {
  UserAssociationsDto,
  UserForgotPasswordLinkDto
} from '@/models/UsersDTO.model';
import UserManagementService from '@/services/UserManagement.service';
import {
  Alert,
  Button,
  DialogActions,
  DialogTitle,
  Unstable_Grid2 as Grid,
  Typography
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';

import dayjs from 'dayjs';
import React, { useCallback, useMemo } from 'react';
import { useCopyToClipboard } from 'react-use';

export interface PlanUsersTableResetPasswordModalProps {
  onClose: () => void;
  userId?: number;
}

export const PlanUsersTableResetPasswordModal = (
  props: PlanUsersTableResetPasswordModalProps
): JSX.Element => {
  const snackbar = useSnackbar();
  const [, copyToClipboard] = useCopyToClipboard();

  const userAssociations = useQuery<UserAssociationsDto[]>(
    ['UserManagementService.getUserAssociations', props.userId],
    () => UserManagementService.getUserAssociations(props.userId),
    { enabled: !!props.userId }
  );

  const link = useQuery<UserForgotPasswordLinkDto>(
    ['UserManagementService.getUserForgotPasswordLink', props.userId],
    () => UserManagementService.getUserForgotPasswordLink(props.userId),
    { enabled: !!props.userId }
  );

  const postUserForgotPassword = useMutation(
    () => UserManagementService.postUserForgotPassword(props.userId),
    {
      onError: () => {
        snackbar.showSnackbar({
          message: `Failed! Couldn’t generate a link`,
          severity: 'error'
        });
      },
      onSuccess: () => {
        snackbar.showSnackbar({
          message: 'Link Generated',
          severity: 'success'
        });

        link.refetch();
      }
    }
  );

  const onGenerateResetLink = useCallback(async () => {
    await postUserForgotPassword.mutateAsync();
  }, []);

  const onCopyLink = useCallback(async () => {
    copyToClipboard(link.data?.url ?? '');
    snackbar.showSnackbar({
      message: `Reset Link copied. Link will expire in ${dayjs(
        link.data?.expires
      ).diff(dayjs(), 'hour')} hours (at ${dayjs(link.data?.expires).format(
        'MM/DD/YYYY hh:mm:ss A'
      )})`,
      severity: 'success'
    });
  }, [copyToClipboard, link.data?.expires, link.data?.url, snackbar]);

  const isDisabled = useMemo(
    () =>
      userAssociations.data?.some(
        association => association?.recordKeeperId === 2
      ),
    [userAssociations.data]
  );

  return (
    <>
      <DialogTitle>Reset Password</DialogTitle>
      <Grid container disableEqualOverflow={true} px={3} spacing={2}>
        <Grid lg={12}>
          <Typography variant='body2'>
            Only copy and provide a user a link if they are having trouble
            resetting their password via the reset password email.{' '}
            <strong>
              User identity must be verified over a call before providing them
              with a copied reset link.
            </strong>
          </Typography>
        </Grid>
        <Grid lg={12}>
          <Alert severity='warning'>
            Password Reset Email will be sent to the user each time a link is
            generated
          </Alert>
        </Grid>
        <Grid lg={12}>
          <Button
            data-testid='generateResetLinkBtn'
            disabled={isDisabled || postUserForgotPassword.isLoading}
            onClick={onGenerateResetLink}>
            Generate Reset Link
          </Button>
          <Button
            data-testid='copyLinkBtn'
            disabled={
              !link.data?.url ||
              postUserForgotPassword.isLoading ||
              link.isFetching
            }
            onClick={onCopyLink}>
            Copy link
          </Button>
        </Grid>
      </Grid>
      <DialogActions>
        <Button onClick={props.onClose} variant='text'>
          Close
        </Button>
      </DialogActions>
    </>
  );
};
