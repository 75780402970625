import DatePicker from '@/components/date-picker';
import { PayGroupForm } from '@/routes/plans/plan-detail/PayGroups/PayGroupsModal.component';
import { PlanService } from '@/services/Plan.service';
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useQuery } from '@tanstack/react-query';
import { Payroll } from '@vestwell-api/scala';

import dayjs, { Dayjs } from 'dayjs';
import { FormikProps } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useUpdateEffect } from 'react-use';

export const PayGroupsModalSchedule: React.FunctionComponent<{
  formik: FormikProps<PayGroupForm>;
  payGroup?: Payroll.GetSetups.ResponseBody[0] & {
    division?: string;
  };
  sponsorPlanId: number;
}> = props => {
  const isDayDisabled = useCallback(
    (day: Dayjs): boolean => {
      if (day.isAfter(dayjs().add(6, 'M'))) {
        return true;
      }

      // day of week
      if (
        ['B', 'W'].includes(props.formik.values.frequency ?? '') &&
        day.day().toString() !== props.formik.values.dayOfWeek
      ) {
        return true;
      }

      //once a month, last day
      if (props.formik.values.frequency === 'ME') {
        return !day.isSame(day.endOf('M'), 'day');
      }

      //twice a month, 15th and last day
      if (props.formik.values.frequency === 'S') {
        return !(day.date() === 15 || day.isSame(day.endOf('M'), 'd'));
      }

      return false;
    },
    [props.formik.values.dayOfWeek, props.formik.values.frequency]
  );

  const nextPayDates = useQuery<Payroll.GetNextDates.ResponseBody>(
    ['PlanService.getNextPayrollDates'],
    () => {
      if (
        !props.formik.values.frequency ||
        !props.formik.values.firstPayrollDate ||
        (['B', 'W'].includes(props.formik.values.frequency) &&
          !props.formik.values.dayOfWeek) ||
        (['SC'].includes(props.formik.values.frequency) &&
          !props.formik.values.nextPayrollDate)
      ) {
        return Promise.resolve([]);
      }

      const params: Payroll.GetNextDates.RequestQuery = {
        firstPayrollDate: dayjs(props.formik.values.firstPayrollDate).format(
          'MM/DD/YYYY'
        ),
        frequency: props.formik.values
          .frequency as Payroll.GetNextDates.RequestQuery['frequency'],
        payAfterWeekend: props.formik.values.payAfterWeekend
      };

      if (props.formik.values.dayOfWeek) {
        params.dayOfWeek = dayjs()
          .day(+props.formik.values.dayOfWeek)
          .format('ddd') as Payroll.GetNextDates.RequestQuery['dayOfWeek'];
      }

      if (props.formik.values.nextPayrollDate) {
        params.nextPayrollDate = dayjs(
          props.formik.values.nextPayrollDate
        ).format('MM/DD/YYYY');
      }
      return PlanService.getNextPayrollDates(params);
    },
    {
      enabled:
        !!props.formik.values.frequency &&
        !!props.formik.values.firstPayrollDate &&
        (!['B', 'W'].includes(props.formik.values.frequency) ||
          !!props.formik.values.dayOfWeek) &&
        (!['SC'].includes(props.formik.values.frequency) ||
          !!props.formik.values.nextPayrollDate),
      onSuccess: data => {
        if (data?.[1] && props.formik.values?.frequency !== 'SC') {
          props.formik.setFieldValue('nextPayrollDate', data[1].expected);
        }
      }
    }
  );

  useEffect(() => {
    if (
      props.formik.values.frequency === 'A' &&
      props.formik.values.firstPayrollDate
    ) {
      props.formik.setFieldValue(
        'nextPayrollDate',
        props.formik.values.firstPayrollDate
      );
    }

    nextPayDates.refetch();
  }, [
    props.formik.values.firstPayrollDate,
    props.formik.values.nextPayrollDate,
    props.formik.values.frequency,
    props.formik.values.dayOfWeek
  ]);

  useUpdateEffect(() => {
    if (props.formik.values.frequency !== 'A') return;

    const date = dayjs().format('MM/DD/YYYY');

    props.formik.setValues(values => ({
      ...values,
      firstPayrollDate: date,
      nextPayrollDate: date
    }));
  }, [props.formik.values.frequency]);

  return (
    <Grid container display='flex' flexDirection='column' spacing={4}>
      {props.payGroup?.division && (
        <Grid item>
          <TextField
            disabled
            label='Division'
            name='division'
            value={props.payGroup?.division}
          />
        </Grid>
      )}
      <Grid item sm={6}>
        <TextField
          data-testid='payGroupName'
          error={
            props.formik.touched.payGroupName &&
            Boolean(props.formik.errors.payGroupName)
          }
          fullWidth
          helperText={
            props.formik.touched.payGroupName &&
            props.formik.errors.payGroupName
          }
          label='Pay Group Name'
          name='payGroupName'
          onBlur={props.formik.handleBlur}
          onChange={props.formik.handleChange}
          size='small'
          value={props.formik.values.payGroupName}
        />
      </Grid>
      <Grid item sm={6}>
        <FormControl fullWidth>
          <InputLabel id='pay-after-weekend-label' size='small'>
            Calendar Logic
          </InputLabel>
          <Select
            displayEmpty
            error={
              props.formik.touched.payAfterWeekend &&
              Boolean(props.formik.errors.payAfterWeekend)
            }
            fullWidth
            id='payroll-freq'
            label='Calendar Logic'
            labelId='pay-after-weekend-label'
            name='payAfterWeekend'
            onBlur={props.formik.handleBlur}
            onChange={props.formik.handleChange}
            size='small'
            value={props.formik.values.payAfterWeekend}>
            <MenuItem value={false as any}>Pay before weekend/holiday</MenuItem>
            <MenuItem value={true as any}>Pay after weekend/holiday</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item sm={6}>
        <FormControl fullWidth>
          <InputLabel id='payroll-freq-label' size='small'>
            Pay Schedule
          </InputLabel>
          <Select
            displayEmpty
            error={
              props.formik.touched.frequency &&
              Boolean(props.formik.errors.frequency)
            }
            id='payroll-freq'
            label='Pay Schedule'
            labelId='payroll-freq-label'
            name='frequency'
            onBlur={props.formik.handleBlur}
            onChange={e => {
              props.formik.handleChange(e);
              if (!['S', 'SC'].includes(props.formik.values.frequency ?? '')) {
                props.formik.setFieldValue('dayOfWeek', '');
              }
            }}
            size='small'
            value={props.formik.values.frequency}>
            <MenuItem value='A'>No Regular Schedule</MenuItem>
            <MenuItem value='W'>Weekly</MenuItem>
            <MenuItem value='B'>Bi-Weekly</MenuItem>
            <MenuItem value='M'>Monthly</MenuItem>
            <MenuItem value='ME'>Month End</MenuItem>
            <MenuItem value='S'>Semi Monthly</MenuItem>
            <MenuItem value='SC'>Semi Monthly (Custom)</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {!!props.formik.values.frequency &&
        ['B', 'W'].includes(props.formik.values.frequency) && (
          <Grid item sm={6}>
            <FormControl fullWidth>
              <InputLabel id='day-of-week-label' size='small'>
                Day of the Week
              </InputLabel>
              <Select
                displayEmpty
                id='day-of-week'
                label='Day of the Week'
                name='dayOfWeek'
                onBlur={props.formik.handleBlur}
                onChange={props.formik.handleChange}
                size='small'
                value={props.formik.values.dayOfWeek}>
                <MenuItem value='1'>Monday</MenuItem>
                <MenuItem value='2'>Tuesday</MenuItem>
                <MenuItem value='3'>Wednesday</MenuItem>
                <MenuItem value='4'>Thursday</MenuItem>
                <MenuItem value='5'>Friday</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}

      {!!props.formik.values.frequency &&
        (!['B', 'W', 'A'].includes(props.formik.values.frequency) ||
          !!props.formik.values.dayOfWeek) && (
          <Grid item>
            <FormControl fullWidth>
              <DatePicker
                InputProps={{
                  autoComplete: 'off',
                  sx: { width: 200 }
                }}
                data-testid='firstPayDate'
                label='First Pay Date'
                name='firstPayrollDate'
                onBlur={props.formik.handleBlur}
                onChange={props.formik.handleChange}
                shouldDisableDate={isDayDisabled}
                size='small'
                value={props.formik.values.firstPayrollDate}
                variant='outlined'
              />
              <FormHelperText>
                {props.formik.touched.firstPayrollDate &&
                  props.formik.errors.firstPayrollDate}
              </FormHelperText>
            </FormControl>
          </Grid>
        )}

      {['SC'].includes(props.formik.values.frequency ?? '') && (
        <Grid item>
          <FormControl fullWidth>
            <DatePicker
              InputProps={{
                autoComplete: 'off',
                sx: { width: 200 }
              }}
              data-testid='next-payroll-date'
              label='Second Pay Date'
              name='nextPayrollDate'
              onBlur={props.formik.handleBlur}
              onChange={props.formik.handleChange}
              shouldDisableDate={date =>
                isDayDisabled(date) ||
                date.isBefore(dayjs(props.formik.values.firstPayrollDate))
              }
              size='small'
              value={props.formik.values.nextPayrollDate}
              variant='outlined'
            />
            <FormHelperText>
              {props.formik.touched.nextPayrollDate &&
                props.formik.errors.nextPayrollDate}
            </FormHelperText>
          </FormControl>
        </Grid>
      )}

      {!!nextPayDates.data?.length && props.formik.values.frequency !== 'A' && (
        <Grid
          display='flex'
          flexDirection='column'
          item
          ml={4}
          mt={4}
          p={4}
          sx={{
            backgroundColor: grey[200],
            borderRadius: 1
          }}>
          <Typography variant='body2'>
            Next 5 pay dates based on entered schedule.
          </Typography>
          <Grid container display='flex' item pt={2} spacing={2}>
            {nextPayDates.data.slice(0, 5).map(d => (
              <Grid item key={d.expected}>
                <Typography>{dayjs(d.expected).format('MMM DD')}</Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
