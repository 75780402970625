import { CardContent, CardHeader, CardPlaceholder } from '@/components/card';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { DeferralChangeDtoWithUUID } from '@/routes/participants/participant-detail/ParticipantDetail.route';
import formatters from '@/utils/Formatters';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Unstable_Grid2';

import React, { useState } from 'react';

type ParticipantDeferralHistoryChangeCardProps = {
  deferralChanges: DeferralChangeDtoWithUUID[];
  isStateIRA: boolean;
  isEsa?: boolean;
};

const generateHeaderTitles = (
  isIntegrated: boolean,
  isStateIra: boolean,
  isEsa?: boolean
) => {
  if (isEsa) {
    return ['Date', 'New Contribution', 'Change Type'];
  }
  if (isIntegrated && isStateIra) {
    return ['Date', 'Status', 'New Contribution', 'Deferral Type'];
  }
  if (!isIntegrated && !isStateIra) {
    return ['Date', 'New Contribution', 'Deferral Type', 'Change Type'];
  }
  if (isIntegrated && !isStateIra) {
    return [
      'Date',
      'Status',
      'New Contribution',
      'Deferral Type',
      'Change Type'
    ];
  }
  return ['Date', 'New Contribution', 'Deferral Type'];
};

const StyledCard = styled(Card)(() => ({
  height: '100%'
}));

const StyledTableCell = styled(TableCell)(() => ({
  cursor: 'pointer'
}));

const StyledArrowUpward = styled(ArrowUpward)(({ theme }) => ({
  height: theme.spacing(2),
  verticalAlign: 'middle'
}));

const StyledArrowDownward = styled(ArrowDownward)(({ theme }) => ({
  height: theme.spacing(2),
  verticalAlign: 'middle'
}));

const ParticipantDeferralHistoryChangeCard: React.FC<
  ParticipantDeferralHistoryChangeCardProps
> = props => {
  const [sortCriteria, setSortCriteria] = useState('desc');

  const onDateClickHandler = (title: string) => {
    if (title !== 'Date') return;
    if (sortCriteria === 'asc') {
      setSortCriteria('desc');
    } else {
      setSortCriteria('asc');
    }
  };

  return (
    <StyledCard
      data-testid='participant-deferral-history-change-card'
      elevation={0}
      variant='outlined'>
      <CardHeader disableDivider title='Deferral Change History' />
      <CardContent>
        {props.deferralChanges.length ? (
          <TableContainer data-testid='deferral-changes-table'>
            <Table aria-label='a dense table' size='small'>
              <TableHead>
                <TableRow>
                  {generateHeaderTitles(
                    Boolean(
                      props.deferralChanges.filter(
                        defChange => defChange.payrollIntegrationType === 3
                      ).length
                    ),
                    props.isStateIRA,
                    props.isEsa
                  ).map(title => (
                    <StyledTableCell
                      key={title}
                      onClick={() => onDateClickHandler(title)}>
                      <Grid container spacing={0.5}>
                        <Grid>{title}</Grid>
                        <Grid>
                          {title === 'Date' &&
                            (sortCriteria === 'asc' ? (
                              <StyledArrowUpward fontSize='inherit' />
                            ) : (
                              <StyledArrowDownward fontSize='inherit' />
                            ))}
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {formatters
                  .sortDeferralChangesByDate(
                    props.deferralChanges,
                    sortCriteria
                  )
                  .filter(row =>
                    (props.isEsa
                      ? ['After-tax']
                      : ['Pre-tax', 'Roth']
                    ).includes(row.deferralType)
                  )
                  .map((row: DeferralChangeDtoWithUUID) => (
                    <TableRow key={row.uuid}>
                      <TableCell>
                        {row.createdAt
                          ? formatters.formatFromIsoDateCustom(
                              row.createdAt,
                              'YYYY-MM-DD HH:mm'
                            )
                          : EMPTY_FIELD_PLACEHOLDER}
                      </TableCell>
                      {row.payrollIntegrationType &&
                        row.payrollIntegrationType === 3 &&
                        !props.isEsa && (
                          <TableCell>
                            {formatters.formatDeferralStatus(row.status) ||
                              EMPTY_FIELD_PLACEHOLDER}
                          </TableCell>
                        )}
                      <TableCell>
                        {formatters.formatDeferral(row.toType, +row.toValue)}
                      </TableCell>
                      {!props.isEsa && (
                        <TableCell>
                          {row.deferralType || EMPTY_FIELD_PLACEHOLDER}
                        </TableCell>
                      )}
                      {!props.isStateIRA && (
                        <TableCell>
                          {row.causeOfChange || EMPTY_FIELD_PLACEHOLDER}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <CardPlaceholder
            data-testid='participant-deferral-history-no-data'
            subtitle='No deferral changes'
          />
        )}
      </CardContent>
    </StyledCard>
  );
};

export default ParticipantDeferralHistoryChangeCard;
