import { createSvgIcon, SvgIconProps } from '@mui/material';

import React, { FC } from 'react';

export const ReceiveIcon: FC<SvgIconProps> = createSvgIcon(
  <>
    <path
      d='M8 3.49994H14C15.1046 3.49994 16 4.39537 16 5.49994V9.92371C16 12.3735 14.7193 14.6451 12.6232 15.9131L11.0352 16.8737C10.3988 17.2587 9.60124 17.2587 8.9648 16.8737L7.3768 15.9131C5.2807 14.6451 4 12.3735 4 9.9237V7.49994'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeWidth='1.5'
    />
    <path
      d='M11.5 7.00006L11.5 11.0001M11.5 11.0001L7.5 11.0001M11.5 11.0001L4.5 4.00006'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
    />
  </>,
  'ReceiveIcon'
);

ReceiveIcon.defaultProps = {
  style: {
    height: 20,
    width: 20
  },
  viewBox: '0 0 20 20'
};

ReceiveIcon.displayName = 'ReceiveIcon';
