import { CellComponentProps } from '@/components/collapsible-table';
import { AdvisorSponsorPlanDto } from '@/models';
import { Box, Link, TableCell, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { memo, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  badgeContainerActive: {
    background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), rgb(76, 175, 80)'
  },
  badgeContainerInactive: {
    backgroundColor: '#EEEEEE'
  },
  badgeTextActive: {
    color: '#3B873E'
  },
  badgeTextInactive: {
    color: '#757575'
  },
  tableCell: {
    fontSize: theme.spacing(2)
  }
}));

const SponsorPlanIdCell: React.FC<{ row: AdvisorSponsorPlanDto }> = ({
  row
}) => {
  return (
    <Link component={RouterLink} to={`/plans/${row.sponsorPlanId}`}>
      {row.sponsorPlanId}
    </Link>
  );
};

const PlanStatusCell: React.FC<{ row: AdvisorSponsorPlanDto }> = ({ row }) => {
  const classes = useStyles();

  return (
    <Box
      borderRadius='1.75rem'
      className={clsx({
        [classes.badgeContainerActive]: row.planStatus === 'Active',
        [classes.badgeContainerInactive]: row.planStatus !== 'Active'
      })}
      display='inline-block'
      px='0.75rem'
      py='0.375rem'>
      <Typography
        className={clsx({
          [classes.badgeTextActive]: row.planStatus === 'Active',
          [classes.badgeTextInactive]: row.planStatus !== 'Active'
        })}
        variant='body2'>
        {row.planStatus}
      </Typography>
    </Box>
  );
};

const AdvisorDetailsPlansTableCell: React.FC<CellComponentProps> = (
  props: CellComponentProps
) => {
  const classes = useStyles();
  const { row, column } = props;

  const CellComponent = useMemo(() => {
    switch (column.field) {
      case 'sponsorPlanId':
        return <SponsorPlanIdCell row={row} />;
      case 'planStatus':
        return <PlanStatusCell row={row} />;
      case 'createdAt':
        return <>{dayjs(row[column.field]).format('MM/DD/YYYY')}</>;
      default:
        return <>{row[column.field]}</>;
    }
  }, [row, column.field]);

  return (
    <TableCell
      className={clsx(classes.tableCell, column.cellClassName)}
      component='th'
      scope='row'>
      {CellComponent}
    </TableCell>
  );
};

export default memo(AdvisorDetailsPlansTableCell);
