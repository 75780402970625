import AccessControl from '@/components/access-control/AccessControl.component';
import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import LinearLoading from '@/components/linear-loading';
import { useUserToken } from '@/contexts/UserTokenContext';
import { AdvisorDto, FirmDto } from '@/models';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import AdvisorDetailsHeader from '@/routes/advisors/advisor-details/AdvisorDetailsHeader.component';
import AdvisorDetailsInfo from '@/routes/advisors/advisor-details/AdvisorDetailsInfo.component';
import AdvisorDetailsPlans from '@/routes/advisors/advisor-details/AdvisorDetailsPlans.component';
import AdvisorService from '@/services/Advisor.service';
import FirmService from '@/services/Firm.service';
import { Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import React from 'react';
import { useParams } from 'react-router-dom';

type AdvisorDetailsProps = {
  advisorId: string;
};

const AdvisorDetails: React.FC<AdvisorDetailsProps> = () => {
  const { advisorId } = useParams<AdvisorDetailsProps>();
  const { userHasValidToken } = useUserToken();

  const advisor = useQuery<AdvisorDto>(
    ['AdvisorService.getById', advisorId],
    () => {
      return AdvisorService.getById(advisorId);
    },
    {
      enabled: userHasValidToken && Boolean(advisorId),
      staleTime: Infinity
    }
  );

  const firm = useQuery<FirmDto>(
    [
      'FirmService.getFirmById',
      advisor.data?.data?.relationships?.firm?.data?.id
    ],
    async () => {
      return FirmService.getFirmById(
        advisor.data?.data?.relationships?.firm?.data?.id
      );
    },
    {
      enabled:
        userHasValidToken &&
        Boolean(advisor.data?.data?.relationships?.firm?.data?.id),
      staleTime: Infinity
    }
  );

  if (advisor.error || firm.error) {
    return redirectToErrorPage((advisor.error || firm.error) as Error);
  }

  return (
    <AccessControl requires={[FeatureLevelPermissions.READ_ADVISOR]}>
      {(advisor.isFetching || firm.isFetching) && <LinearLoading />}
      {advisor.isSuccess && firm.isSuccess && (
        <Grid container data-testid='advisor-details' direction='column'>
          <AdvisorDetailsHeader
            advisor={advisor?.data?.data}
            firm={firm?.data?.data}
          />
          <AdvisorDetailsInfo
            advisor={advisor?.data?.data}
            firm={firm?.data?.data}
          />
          <AdvisorDetailsPlans advisorId={advisor.data?.data?.id} />
        </Grid>
      )}
    </AccessControl>
  );
};

export default AdvisorDetails;
