import AccessControl from '@/components/access-control/AccessControl.component';
import CollapsibleTable, {
  CellComponentProps
} from '@/components/collapsible-table';
import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import LinearLoading from '@/components/linear-loading';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUserToken } from '@/contexts/UserTokenContext';
import { TpaListDto, TpaListDtoItem } from '@/models/TpaDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import TpaService from '@/services/Tpa.service';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';
import { Search } from '@mui/icons-material';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import {
  Box,
  Card,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  TableCell,
  TextField,
  Theme,
  Tooltip,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { sortBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Navigate, Link as RouterLink } from 'react-router-dom';

import AddTpaButton from './AddTpaButton.component';
import EditTPAButton from './EditTPAButton.component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      ...theme.typography.caption,
      color: theme.palette.grey[700],
      fontSize: theme.spacing(2),
      height: theme.spacing(6),
      padding: '0',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    headerTableTitle: {
      padding: theme.spacing(2)
    },
    noData: {
      fontSize: theme.spacing(2.4),
      marginTop: theme.spacing(4),
      padding: theme.spacing(2),
      textAlign: 'center'
    },
    paper: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(4),
      width: '100%'
    },
    size: {
      fontSize: theme.spacing(2)
    },
    title: {
      marginBottom: theme.spacing(1),
      textAlign: 'left'
    },
    tpaIdWidth: {
      width: '15%'
    }
  })
);

const redirectUserToTpaDetails = (tpaId: number): JSX.Element => {
  return (
    <Navigate
      to={{
        pathname: `/tpa/${tpaId}`
      }}
    />
  );
};

const TpaMainPageTableCell: React.FunctionComponent<CellComponentProps> = (
  props: CellComponentProps
) => {
  const classes = useStyles();
  const { row, column } = props;
  const blackListedTpas = [1, 42];
  const isUserGroup = Boolean(row.userGroupNames.length);
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  const backFillTpaOrganizationMutation = useMutation(
    (tpaId: string) => {
      return TpaService.backFillTpaOrganization(tpaId);
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Failed to backfill organization!',
          severity: 'error'
        });
      },
      onSuccess: async () => {
        showSnackbar({
          message: 'Auth0 organization backfilled',
          severity: 'success'
        });
        await queryClient.refetchQueries(['TpaService.getAll']);
      }
    }
  );

  let field = <Box className={classes.size}>{row[column.field]}</Box>;

  if (column.field === 'name') {
    field = (
      <Box className={classes.size}>
        {isUserGroup ? (
          <Link
            component={RouterLink}
            data-testid='tpa-main-page-link'
            to={`/tpa/${row.tpaId}`}
            underline='hover'>
            {formatters.chopStringLongerThan(row[column.field], 80)}
          </Link>
        ) : (
          formatters.chopStringLongerThan(row[column.field], 80)
        )}
        <EditTPAButton
          initialValue={row[column.field]}
          size='small'
          tpaId={row.tpaId}
        />
      </Box>
    );
  }

  if (
    column.field === 'action' &&
    !isUserGroup &&
    !blackListedTpas.includes(row.tpaId)
  ) {
    field = (
      <Tooltip title='Click to backfill organization'>
        <IconButton
          disabled={false}
          onClick={() => backFillTpaOrganizationMutation.mutate(row.tpaId)}>
          <CorporateFareIcon />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <TableCell className={classes.body} component='th' scope='row'>
      <Box>{field}</Box>
    </TableCell>
  );
};

const TpaMainPage: React.FunctionComponent = (): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const { userHasValidToken, token } = useUserToken();

  const hasRequiredAccess = userService.hasPermission(
    FeatureLevelPermissions.READ_TPA
  );
  const userDoesNotHaveAccess = userHasValidToken && !hasRequiredAccess;

  const headerColumns = [
    {
      cellClassName: classes.tpaIdWidth,
      field: 'tpaId',
      headerName: 'TPA ID'
    },
    { field: 'name', headerName: 'TPA Name' }
  ];

  // david: limited access to be grant specific users to be able to be able
  // to back fill auth0 authorization for existing TPA entities
  const hasTpaBackfillAccess = userService.hasPermission(
    FeatureLevelPermissions.WRITE_TPA_BACKFILL
  );
  if (hasTpaBackfillAccess) {
    headerColumns.push({
      field: 'action',
      headerName: 'Action'
    });
  }

  const TPAsQuery = useQuery<TpaListDto>(
    ['TpaService.getAll'],
    () => {
      return TpaService.getAll();
    },
    {
      enabled: userHasValidToken,
      staleTime: Infinity
    }
  );

  // david: exclude TPA 42 (BNY) from selection
  // since BNY should not be treated as a TPA
  const filterAndSortTpas = (tpas: TpaListDtoItem[], searchBy: string) => {
    const filtered = tpas.filter(
      tpa =>
        tpa.tpaId !== 42 &&
        (searchBy
          ? (tpa.tpaId + tpa.name)
              .toLocaleLowerCase()
              .includes(searchBy.toLocaleLowerCase())
          : true)
    );
    return sortBy<TpaListDtoItem>(filtered, ['tpaId']);
  };

  const tpaData = useMemo(() => {
    if (!TPAsQuery.data) {
      return [];
    }
    const tpasRefined = filterAndSortTpas(TPAsQuery.data.data, searchTerm);
    return sortBy(tpasRefined, ['name']);
  }, [TPAsQuery.data, searchTerm, rowsPerPage, pageNumber]);

  const addTpa = async (tpaName: string) => {
    try {
      await TpaService.createTpaOrganization({ name: tpaName });
      TPAsQuery.refetch();
      showSnackbar({
        message: 'TPA was successfully created!',
        severity: 'success'
      });
    } catch (e) {
      showSnackbar({ message: 'Failed!', severity: 'error' });
    }
  };

  useEffect(() => {
    if (searchTerm) {
      setPageNumber(1);
    }
  }, [searchTerm]);

  if (userDoesNotHaveAccess) {
    return redirectToErrorPage(new Error('403'));
  }

  if (userHasValidToken && token?.tpaId) {
    return redirectUserToTpaDetails(token.tpaId);
  }

  let noData = <LinearLoading />;

  if (TPAsQuery.isSuccess && !tpaData.length) {
    noData = (
      <Card className={classes.noData}>
        <Typography className={classes.title} variant='h5'>
          TPAs
        </Typography>
        No TPAs
      </Card>
    );
  }

  if (TPAsQuery.isError) {
    return <Typography>Error retrieving TPAs</Typography>;
  }

  return (
    <>
      <Grid container>
        <Grid xs={3}>
          <TextField
            InputProps={{
              'aria-placeholder': 'Search TPAs',
              onChange: e => setSearchTerm(e.target.value?.trim()),
              placeholder: 'Search TPAs',
              startAdornment: (
                <InputAdornment position='start'>
                  <Search />
                </InputAdornment>
              )
            }}
            data-testid='search-tpas'
            style={{ width: '100%' }}
            variant='outlined'
          />
        </Grid>
        <Grid style={{ flexGrow: '1' }} />
        <Grid alignItems='center' display='flex'>
          <AccessControl requires={[FeatureLevelPermissions.WRITE_TPA_ADD_TPA]}>
            <AddTpaButton addTpa={addTpa} tpaData={tpaData} />
          </AccessControl>
        </Grid>
      </Grid>

      {!tpaData.length ? (
        noData
      ) : (
        <Paper className={classes.paper} elevation={0} variant='outlined'>
          <Typography
            className={classes.headerTableTitle}
            component='div'
            data-testid='tpa-main-page-header'
            variant='h5'>
            TPAs
          </Typography>
          <div data-testid='tpa-main-page-table'>
            <CollapsibleTable
              cellComponent={TpaMainPageTableCell}
              columns={headerColumns}
              pager={{
                metaCount: tpaData.length,
                onPageNumberChanged: (zeroIndexedPageNumber: number) => {
                  return setPageNumber(zeroIndexedPageNumber + 1);
                },
                onRowsPerPageChanged: (newRowsPerPage: number) => {
                  return setRowsPerPage(newRowsPerPage);
                },
                pageNumber: pageNumber - 1,
                rowsPerPage
              }}
              tableData={tpaData.slice(
                rowsPerPage * pageNumber - rowsPerPage,
                rowsPerPage * pageNumber
              )}
              useDivAsBackground
            />
          </div>
        </Paper>
      )}
    </>
  );
};

export default TpaMainPage;
