import { Stack } from '@mui/material';

import { FC, useContext, useState } from 'react';

import { AlertContext } from '../AlertContext';
import { AchReversalContext } from './ach-reversal-expanded-components/AchReversalContext';
import { AchReversalExpandedRecentContributions } from './ach-reversal-expanded-components/AchReversalExpandedRecentContributions.component';
import { AchReversalExpandedRecentReversals } from './ach-reversal-expanded-components/AchReversalExpandedRecentReversals.component';
import { AchReversalExpandedReversalTransactionDetails } from './ach-reversal-expanded-components/AchReversalExpandedReversalTransactionDetails.component';
import { AchReversalExpandedSubmitDeposit } from './ach-reversal-expanded-components/AchReversalExpandedSubmitDeposit.component';
import { AchReversalExpandedSubmittedDeposit } from './ach-reversal-expanded-components/AchReversalExpandedSubmittedDeposit.component';

export const AchReversalExpandedAlertDetails: FC = () => {
  const [context, setContext] = useState({
    selectedContribution: undefined
  });
  const alert = useContext(AlertContext);
  return (
    <AchReversalContext.Provider value={{ context, setContext }}>
      <Stack spacing={2}>
        <AchReversalExpandedReversalTransactionDetails />
        <AchReversalExpandedRecentReversals />
        {alert.details.depositRequestTracerId &&
        !context?.selectedContribution ? (
          <AchReversalExpandedSubmittedDeposit />
        ) : !context?.selectedContribution ? (
          <AchReversalExpandedRecentContributions />
        ) : (
          <AchReversalExpandedSubmitDeposit />
        )}
      </Stack>
    </AchReversalContext.Provider>
  );
};

AchReversalExpandedAlertDetails.displayName = 'AchReversalExpandedAlertDetails';
