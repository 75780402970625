import { useGetPlanScheduledChanges } from '@/components/scheduled-changes/useGetPlanScheduledChanges';
import { ScheduledChangePayloadItem } from '@/models/ScheduledChangesDTO.model';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack
} from '@mui/material';

import { Formik } from 'formik';
import React, { useState } from 'react';
import { AnyObjectSchema } from 'yup';

import { ScheduleChangesForm } from './ScheduleChangesForm.component';
import ScheduledChangesDialogActions from './ScheduledChangesDialogActions.component';
import { makeScheduleChangeSchema } from './utils';

type EditScheduleChangeDialogProps<T> = {
  sponsorPlanId: number;
  onSubmit: (values: Record<string, any>) => void | Promise<void>;
  open: boolean;
  onClose: () => void;
  disableContinue?: boolean;
  makeScheduledChangesPayload: (
    currentValues: Record<string, any>,
    scheduledValues: Record<string, any>,
    config?: T
  ) => ScheduledChangePayloadItem[];
  makeScheduledChangesPayloadConfig?: T;
  initialValues: Record<string, string>;
  validationSchema: AnyObjectSchema;
  changeType: string;
  editForm?: React.ReactNode;
  title?: string;
  warning?: string;
  hideDatePicker?: boolean;
};

export const EditScheduleChangeDialog = <T,>(
  props: EditScheduleChangeDialogProps<T>
): JSX.Element => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [scheduleChangesStep, setScheduleChangesStep] = useState(false);

  const scheduledChanges = useGetPlanScheduledChanges({
    planId: +props.sponsorPlanId
  });

  const warning = (
    <Alert data-testid='preview-warning' icon={false} severity='warning'>
      <Stack
        alignItems='center'
        direction='row'
        justifyContent='center'
        spacing={1}>
        <WarningAmberIcon
          fontSize='small'
          sx={{ color: theme => theme.palette.warning.dark }}
        />
        <div>{props.warning}</div>
      </Stack>
    </Alert>
  );

  return (
    <Dialog
      data-testid='schedule-changes-dialog'
      fullWidth
      maxWidth='md'
      onClose={() => {
        if (!isSubmitting) {
          props.onClose();
          setScheduleChangesStep(false);
        }
      }}
      open={props.open}
      transitionDuration={{ exit: 0 }}>
      <DialogTitle data-testid='schedule-changes-dialog-title'>
        {!scheduleChangesStep ? props.title : 'Summary'}
      </DialogTitle>

      {!!scheduledChanges.data && props.editForm && (
        <Formik
          initialValues={props.initialValues}
          onSubmit={async values => {
            await props.onSubmit(values);
            setScheduleChangesStep(false);
          }}
          validateOnChange
          validationSchema={props.validationSchema.concat(
            makeScheduleChangeSchema(
              props.makeScheduledChangesPayload,
              props.makeScheduledChangesPayloadConfig,
              { data: scheduledChanges.data },
              props.initialValues,
              props.changeType
            )
          )}>
          {() => (
            <>
              <DialogContent>
                <Stack spacing={2}>
                  {props.warning && warning}
                  {!scheduleChangesStep ? (
                    <>{props.editForm}</>
                  ) : (
                    <ScheduleChangesForm
                      changeType={props.changeType}
                      makeScheduledChangesPayload={
                        props.makeScheduledChangesPayload
                      }
                      makeScheduledChangesPayloadConfig={
                        props.makeScheduledChangesPayloadConfig
                      }
                      sponsorPlanId={props.sponsorPlanId}
                    />
                  )}
                </Stack>
              </DialogContent>
              <ScheduledChangesDialogActions
                disableContinue={props.disableContinue}
                hideDatePicker={props.hideDatePicker}
                makeScheduledChangesPayload={props.makeScheduledChangesPayload}
                makeScheduledChangesPayloadConfig={
                  props.makeScheduledChangesPayloadConfig
                }
                onClose={props.onClose}
                scheduleChangesStep={scheduleChangesStep}
                setIsSubmitting={setIsSubmitting}
                setScheduleChangesStep={setScheduleChangesStep}
                singleStep={false}
              />
            </>
          )}
        </Formik>
      )}

      {!!scheduledChanges.data && !props.editForm && (
        <DialogContent>
          <Stack spacing={2}>
            {props.warning && warning}
            <ScheduleChangesForm
              changeType={props.changeType}
              makeScheduledChangesPayload={props.makeScheduledChangesPayload}
              makeScheduledChangesPayloadConfig={
                props.makeScheduledChangesPayloadConfig
              }
              sponsorPlanId={props.sponsorPlanId}
            />{' '}
          </Stack>
        </DialogContent>
      )}

      {!props.editForm && (
        <ScheduledChangesDialogActions
          disableContinue={props.disableContinue}
          hideDatePicker={props.hideDatePicker}
          makeScheduledChangesPayload={props.makeScheduledChangesPayload}
          makeScheduledChangesPayloadConfig={
            props.makeScheduledChangesPayloadConfig
          }
          onClose={props.onClose}
          scheduleChangesStep={props.editForm ? scheduleChangesStep : true}
          setIsSubmitting={setIsSubmitting}
          setScheduleChangesStep={setScheduleChangesStep}
          singleStep
        />
      )}
    </Dialog>
  );
};
