import { PlanDocumentCategory } from '@/models/PlanDocumentCategoryDTO.model';

const documentCategories: PlanDocumentCategory[] = [
  {
    categoryName: 'Plan Documents',
    id: 1,
    parentDocKey: 'Plan Documents/Plan Adoption Agreement',
    useEffectiveDate: true,
    useEffectiveYear: false
  },
  {
    categoryName: 'Service Agreements',
    id: 2,
    parentDocKey: 'Plan Documents/Plan Services Agreement',
    useEffectiveDate: true,
    useEffectiveYear: false
  },
  {
    categoryName: 'Sponsor Disclosures',
    id: 3,
    useEffectiveDate: true,
    useEffectiveYear: false
  },
  {
    categoryName: 'Annual Participant Disclosures',
    id: 4,
    useEffectiveDate: false,
    useEffectiveYear: true
  },
  {
    categoryName: 'Ad Hoc Participant Disclosures',
    id: 8,
    useEffectiveDate: true,
    useEffectiveYear: false
  },
  {
    categoryName: 'Annual Testing',
    id: 5,
    useEffectiveDate: false,
    useEffectiveYear: true
  },
  {
    categoryName: 'Government Reporting',
    id: 6,
    useEffectiveDate: false,
    useEffectiveYear: true
  },
  {
    categoryName: 'Correspondence & Special Projects',
    id: 7,
    useEffectiveDate: false,
    useEffectiveYear: true
  }
];

export default documentCategories;
