import formatters from '@/utils/Formatters';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';

import {
  Field,
  ErrorMessage as FormikErrorMessage,
  FormikProps,
  useField
} from 'formik';
import { get, isString } from 'lodash';
import React, { useEffect } from 'react';

export const NullSignifierOption = '(None)';

interface DropdownOptionObject {
  value: string;
  disabled?: boolean;
}

interface SimpleDropdownProps {
  'data-testid'?: string;
  displayEmpty?: boolean;
  fieldName: string;
  fieldId: string;
  fieldValues?: string[] | DropdownOptionObject[];
  fieldOptions?: Array<{ value: any; option: React.ReactNode }>;
  disabled?: boolean;
  required?: boolean;
  fullWidth?: boolean;
  onChange?: (event: SelectChangeEvent<any>, child: React.ReactNode) => void;
  clearOnExit?: boolean;
  size?: 'medium' | 'small' | undefined;
  errorMessage?: string;
}

const SimpleDropdown: React.FunctionComponent<SimpleDropdownProps> = (
  props: SimpleDropdownProps
) => {
  const {
    displayEmpty = false,
    fieldName,
    fieldId,
    fieldValues = [],
    fieldOptions = [],
    disabled,
    required,
    fullWidth = true,
    onChange,
    clearOnExit = false,
    size = 'medium',
    errorMessage
  } = props;

  const [, , helpers] = useField(fieldId);

  useEffect(
    () => () => {
      if (clearOnExit) {
        helpers.setValue('');
        helpers.setTouched(false);
      }
    },

    [clearOnExit]
  );

  return (
    <FormControl fullWidth={fullWidth} required={required}>
      <Field name={fieldId}>
        {(fieldProps: { form: FormikProps<any> }) => {
          const isError = Boolean(
            get(fieldProps.form.touched, fieldId) &&
              get(fieldProps.form.errors, fieldId)
          );

          const labelId = `${props['data-testid'] || fieldId}-label`;

          const options = [
            ...fieldOptions.map(o => o.value),
            ...(isString(fieldValues?.[0])
              ? fieldValues
              : fieldValues.map(o => (o as DropdownOptionObject).value))
          ];

          const unsafeValue = get(fieldProps.form.values, fieldId);
          const value = options.includes(unsafeValue) ? unsafeValue : '';

          return (
            <>
              <InputLabel
                {...(displayEmpty ? { shrink: true } : {})}
                data-testid={labelId}
                error={isError}
                id={labelId}
                size={size === 'medium' ? 'normal' : size}>
                {fieldName}
              </InputLabel>
              <Select
                {...(displayEmpty ? { notched: true } : {})}
                data-testid={props['data-testid'] || fieldId}
                disabled={disabled}
                displayEmpty={displayEmpty}
                error={isError}
                id={fieldId}
                label={fieldName}
                labelId={labelId}
                onBlur={() => fieldProps.form.setFieldTouched(fieldId, true)}
                onChange={
                  onChange ||
                  (event => {
                    fieldProps.form.setFieldValue(fieldId, event.target.value);
                  })
                }
                size={size}
                value={value}>
                {!fieldOptions.length &&
                  fieldValues.map(option => (
                    <MenuItem
                      data-testid={`${props['data-testid'] || fieldId}-option-${formatters.textToDataTestId(
                        (option as DropdownOptionObject)?.value ?? option
                      )}`}
                      disabled={
                        (option as DropdownOptionObject)?.disabled ?? false
                      }
                      key={(option as DropdownOptionObject)?.value ?? option}
                      value={(option as DropdownOptionObject)?.value ?? option}>
                      {(option as DropdownOptionObject)?.value ?? option}
                    </MenuItem>
                  ))}

                {fieldOptions.length &&
                  fieldOptions.map(option => (
                    <MenuItem
                      data-testid={`${props['data-testid'] || fieldId}-option-${formatters.textToDataTestId(
                        isString(option.option) ? option.option : option.value
                      )}`}
                      key={option.value}
                      value={option.value}>
                      {option.option}
                    </MenuItem>
                  ))}
              </Select>
              <FormikErrorMessage name={fieldId}>
                {msg => (
                  <FormHelperText
                    data-testid={`${props['data-testid'] || fieldId}-error-message`}
                    error>
                    {errorMessage || msg}
                  </FormHelperText>
                )}
              </FormikErrorMessage>
            </>
          );
        }}
      </Field>
    </FormControl>
  );
};

export default SimpleDropdown;
export type { SimpleDropdownProps, DropdownOptionObject };
