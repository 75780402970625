import AccessControl from '@/components/access-control/AccessControl.component';
import useHasPermissions from '@/components/access-control/useHasPermissions.hook';
import TooltipButton from '@/components/tool-tip-button';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import {
  PlanTestResultDto,
  WorkflowProcessEventResponse,
  YearEndContributionResultsDto
} from '@/models/YearEndTestingDTO.model';
import { PlanService } from '@/services/Plan.service';
import {
  Check,
  KeyboardArrowDown,
  KeyboardArrowRight
} from '@mui/icons-material';
import {
  Alert,
  Collapse,
  FormControl,
  Unstable_Grid2 as Grid,
  IconButton,
  Link,
  MenuItem,
  Paper,
  Select,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import { Form, Formik } from 'formik';
import React, { FC, useCallback, useMemo } from 'react';
import { useToggle } from 'react-use';

interface YearEndContributionRowProps {
  planId: number;
  onCompleteStep: (action: string) => void;
  event: WorkflowProcessEventResponse | undefined;
  completedEvents: (string | undefined)[];
  year?: number;
  data: PlanTestResultDto['yearEndContribution'];
}

interface YearEndContributionRowType {
  formField:
    | 'resultSafeHarbor'
    | 'resultDiscretionaryMatch'
    | 'resultProfitSharing'
    | 'resultYearEndContribution';
  name: string;
  options: {
    label: string;
    value: string;
  }[];
}

const options = [
  {
    label: 'Yes',
    value: 'pass'
  },
  {
    label: 'No',
    value: 'fail'
  },
  {
    label: 'N/A',
    value: 'N/A'
  }
];

const rows: YearEndContributionRowType[] = [
  {
    formField: 'resultSafeHarbor',
    name: 'Safe Harbor Calculation',
    options
  },
  {
    formField: 'resultDiscretionaryMatch',
    name: 'Discretionary Match Calculation',
    options
  },
  {
    formField: 'resultProfitSharing',
    name: 'Profit Sharing Calculation',
    options
  },
  {
    formField: 'resultYearEndContribution',
    name: 'Year End Contributions',
    options
  }
];

const initialValues: YearEndContributionResultsDto = {
  resultDiscretionaryMatch: {
    result: 'N/A',
    testId: 7
  },
  resultProfitSharing: {
    result: 'N/A',
    testId: 8
  },
  resultSafeHarbor: {
    result: 'N/A',
    testId: 6
  },
  resultYearEndContribution: {
    result: 'N/A',
    testId: 9
  }
};

export const YearEndContributionRow: FC<
  YearEndContributionRowProps
> = props => {
  const { showSnackbar } = useSnackbar();
  const [open, toggleOpen] = useToggle(false);

  const initValues = useMemo(() => {
    return {
      ...initialValues,
      ...props.data
    };
  }, [props.data]);

  const permissions = useHasPermissions({
    requires: [FeatureLevelPermissions.WRITE_ANNUAL_TESTING_ACTIONS]
  });

  const postYETTestResultsMutation = useMutation(
    (data: YearEndContributionResultsDto) =>
      PlanService.postYETTestResults(props.planId, data, props.year),
    {
      onError: () => {
        showSnackbar({
          message: 'Something went wrong!',
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: 'Year End Contribution have been saved!',
          severity: 'success'
        });
        props.onCompleteStep('completeYearEndContribution');
      }
    }
  );

  const onSubmit = useCallback(
    (values: YearEndContributionResultsDto) => {
      postYETTestResultsMutation.mutate(values);
    },
    [postYETTestResultsMutation]
  );

  return (
    <>
      <TableRow data-testid='year-end-contribution-row'>
        <TableCell align='center' padding='none'>
          <IconButton
            aria-label='expand row'
            data-testid='year-end-contribution-expand-row-button'
            onClick={toggleOpen}
            size='small'>
            {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </IconButton>
        </TableCell>
        <TableCell align='center' padding='none'>
          {!!props.event && (
            <Check
              color='primary'
              data-testid='year-end-contribution-checkmark'
            />
          )}
        </TableCell>
        <TableCell>Year End Contribution</TableCell>
        <TableCell data-testid='year-end-contribution-initiatedBy'>
          {props.event?.initiatedBy?.id}
        </TableCell>
        <TableCell>{props.event?.createdAt}</TableCell>
        <TableCell />
      </TableRow>
      <TableRow data-testid='year-end-contribution-expandable-row'>
        <TableCell colSpan={12} padding='none'>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Formik initialValues={initValues} onSubmit={onSubmit}>
              {({ handleSubmit, setFieldValue, values }) => (
                <Form data-testid='year-end-contribution-form'>
                  <Grid
                    container
                    direction='column'
                    disableEqualOverflow={true}
                    padding={2}
                    spacing={2}>
                    <Grid direction='column' lg={12}>
                      <Paper
                        data-testid='year-end-contribution-row-content'
                        sx={{
                          paddingBottom: theme => theme.spacing(2)
                        }}>
                        {rows.map(row => (
                          <Grid
                            alignItems='center'
                            container
                            key={row.name}
                            spacing={0}>
                            <Grid lg={2}>
                              <Typography>{row.name}</Typography>
                            </Grid>
                            <Grid lg={10}>
                              <FormControl>
                                <Select
                                  data-testid={`${row.formField}-dropdown`}
                                  disabled={permissions.isForbidden}
                                  displayEmpty
                                  onChange={event =>
                                    setFieldValue(
                                      `${row.formField}.result`,
                                      event.target.value
                                    )
                                  }
                                  size='small'
                                  value={values[`${row.formField}`].result}>
                                  {row.options.map(item => (
                                    <MenuItem
                                      key={item.label}
                                      value={item.value}>
                                      {item.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        ))}
                      </Paper>
                    </Grid>
                    <Grid>
                      <Alert severity='info'>
                        Please ensure there is an associated doc for each test
                        otherwise the results will not be saved. Annual testing
                        docs can be reviewed{' '}
                        <Link
                          data-testid='yet-contribution-redirection-link'
                          href={`/plans/${props.planId}/documents#annual-testing`}>
                          here
                        </Link>
                        .
                      </Alert>
                    </Grid>
                    <Grid>
                      <Alert severity='warning'>
                        Saved values will not be visible after leaving this
                        page.
                      </Alert>
                    </Grid>
                    <Grid lg={4}>
                      <AccessControl
                        requires={[
                          FeatureLevelPermissions.WRITE_ANNUAL_TESTING_ACTIONS
                        ]}>
                        <TooltipButton
                          disabled={
                            postYETTestResultsMutation.isLoading ||
                            !props.completedEvents?.includes(
                              'dataReviewCompleted'
                            )
                          }
                          handleOnClick={handleSubmit}
                          testId='save-year-end-contribution-btn'
                          tooltipMessage={
                            props.completedEvents?.includes(
                              'dataReviewCompleted'
                            )
                              ? ''
                              : 'Please complete the previous step'
                          }
                          variant='contained'>
                          SAVE
                        </TooltipButton>
                      </AccessControl>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
