import TransactionService from '@/services/ops/transactions/Transaction.service';
import { Alert, AlertTitle, Grow, LinearProgress, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { AlertStatus } from '@vestwell-sub-accounting/models/common/AlertStatus';

import { useContext } from 'react';

import { AlertContext } from '../AlertContext';
import ChooseTransactionType from './common/ChooseTransactionType.component';
import { PendingRollovers } from './common/PendingRollovers.component';
import { Rollover } from './common/Rollover.component';
import UploadCheckImage from './common/UploadCheckImage.component';

export const NewRolloverDepositExpandedAlertDetails = (): JSX.Element => {
  const alert = useContext(AlertContext);

  // a new transaction is created whenever updated transactions are reversed
  const originalParentAccountTransactionId =
    alert?.details?.event?.transactionIds[0];
  const originalSubAccountTransactionId =
    alert?.details?.event?.transactionIds[1];

  const updatedParentAccountTransactionId =
    alert?.details?.updatedParentAccountTransactionId;
  const updatedSubAccountTransactionId =
    alert?.details?.updatedSubAccountTransactionId;

  const checkImageDocumentId =
    // TODO: SUB-3571 — check images should be associated with the latest transaction but we do not update the document following a workflow execution yet
    // once we do, add a useGetPlanDepositTransactions call and use the returned ID here, prioritized over the others
    updatedParentAccountTransactionId || originalParentAccountTransactionId;

  const { data: checkImageDocument } = useQuery(
    ['TransactionService.getCheckImageDocument', checkImageDocumentId],
    () => TransactionService.getCheckImageDocument(checkImageDocumentId),
    {
      enabled: Boolean(checkImageDocumentId)
    }
  );

  if (!alert) return <LinearProgress />;

  if (
    !originalParentAccountTransactionId &&
    !originalSubAccountTransactionId &&
    !updatedParentAccountTransactionId &&
    !updatedSubAccountTransactionId
  ) {
    return (
      <Alert data-testid='missing-transaction-ids-alert' severity='error'>
        <AlertTitle>No further details or actions are available</AlertTitle>
        This alert is missing a required transaction ID
      </Alert>
    );
  }

  // Users must upload a check image before they can choose a transaction type
  return (
    <Stack spacing={2}>
      <UploadCheckImage alert={alert} />
      <Grow
        in={
          Boolean(checkImageDocument) &&
          alert.alertStatus !== AlertStatus.Closed
        }
        unmountOnExit>
        <ChooseTransactionType />
      </Grow>
      <Grow in={Boolean(checkImageDocument)} unmountOnExit>
        {alert?.details?.rolloverId ? <Rollover /> : <PendingRollovers />}
      </Grow>
    </Stack>
  );
};

export default NewRolloverDepositExpandedAlertDetails;
