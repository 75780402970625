import AccessControl from '@/components/access-control/AccessControl.component';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { useDialog } from '@/contexts/DialogContext';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import {
  UserMfaMethodDto,
  UserMfaMethodDtoWithOrder,
  UsersItemDto
} from '@/models/UsersDTO.model';
import formatters from '@/utils/Formatters';
import {
  Button,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';

import React, { FC, useMemo } from 'react';

import { DisableTwoFactorAuthModal } from './DisableTwoFactorAuthModal.component';

export interface UserManagementTwoFactorAuthProps {
  badge?: {
    color: string;
    value: string;
  };
  onDisable: () => void;
  mfaMethods: UserMfaMethodDto[] | undefined;
  selectedUser: UsersItemDto;
}

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(2)
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': { border: 0 }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1, 2)
}));

export const UserManagementTwoFactorAuth: FC<
  UserManagementTwoFactorAuthProps
> = props => {
  const { openDialog } = useDialog();

  const phoneMethodsWithOrder = useMemo(() => {
    return (props.mfaMethods || [])
      ?.filter(
        (mfaEntry): mfaEntry is UserMfaMethodDtoWithOrder =>
          mfaEntry.entryType === 'phone'
      )
      .sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      )
      .map((mfaEntry, index) => ({
        ...mfaEntry,
        order: index + 1
      }));
  }, [props.mfaMethods]);

  const otherMethods = useMemo((): UserMfaMethodDtoWithOrder[] => {
    return (props.mfaMethods || [])?.filter(
      mfaEntry => mfaEntry.entryType !== 'phone'
    );
  }, [props.mfaMethods]);

  const combinedMethods = useMemo(() => {
    return [...(otherMethods || []), ...(phoneMethodsWithOrder || [])].sort(
      (a, b) => {
        const entryTypeCompare = a.entryType
          .toLowerCase()
          .localeCompare(b.entryType.toLowerCase());
        return entryTypeCompare === 0
          ? entryTypeCompare
          : (a.order || 0) - (b.order || 0);
      }
    );
  }, [otherMethods, phoneMethodsWithOrder]);

  return (
    <>
      {!!combinedMethods?.length && (
        <TableContainer data-testid='user-management-two-factor-auth'>
          <Table aria-label='table'>
            <TableHead>
              <TableRow>
                <StyledTableCell>Method</StyledTableCell>
                <StyledTableCell align='left'>Detail</StyledTableCell>
                <StyledTableCell align='left'>Default</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!!combinedMethods?.length &&
                combinedMethods?.map(mfaEntry => (
                  <StyledTableRow key={mfaEntry.mfaEntryId}>
                    <StyledTableCell component='th' scope='row'>
                      {mfaEntry?.entryType === 'app'
                        ? 'Authentication App'
                        : mfaEntry.entryType === 'phone'
                          ? `Phone Number #${mfaEntry?.order}`
                          : 'Email'}
                    </StyledTableCell>
                    <StyledTableCell align='left'>
                      {combinedMethods?.length
                        ? mfaEntry?.entryType === 'app'
                          ? 'Enabled'
                          : mfaEntry.entryType === 'phone'
                            ? `${formatters.formatPhone(mfaEntry?.value)}`
                            : `${mfaEntry?.value}`
                        : EMPTY_FIELD_PLACEHOLDER}
                    </StyledTableCell>
                    <StyledTableCell align='left'>
                      {mfaEntry.isPrimary ? 'Yes' : EMPTY_FIELD_PLACEHOLDER}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <AccessControl requires={[FeatureLevelPermissions.WRITE_USER_MFA]}>
        {!!combinedMethods?.length && (
          <StyledContainer>
            <Button
              color='error'
              data-testid='disable-two-factor-auth-btn'
              onClick={() =>
                openDialog({
                  customContent: (
                    <DisableTwoFactorAuthModal
                      onDisable={props.onDisable}
                      user={props.selectedUser}
                    />
                  )
                })
              }
              variant='outlined'>
              REMOVE 2FA
            </Button>
          </StyledContainer>
        )}
      </AccessControl>
    </>
  );
};
