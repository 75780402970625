import BasePlan from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/BasePlan';
import DefaultHardshipWithdrawals from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/templates/default-template/DefaultHardshipWithdrawals';
import DefaultServiceCalculationType from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/templates/default-template/DefaultServiceCalculationType';

class DefaultTemplate extends BasePlan {
  allowHardshipWithdrawals: DefaultHardshipWithdrawals;

  serviceCalculationType: DefaultServiceCalculationType;

  constructor(props: Record<string, any>) {
    super(props);

    this.allowHardshipWithdrawals = new DefaultHardshipWithdrawals(
      props.distributionFeatures?.allowHardshipWithdrawals
    );

    this.serviceCalculationType = new DefaultServiceCalculationType(
      props.eligibilityFeatures?.eligibilityRules
    );
  }
}

export default DefaultTemplate;
