import { useDialog } from '@/contexts/DialogContext';
import { TpaListDtoItem } from '@/models/TpaDTO.model';
import { Button } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import React from 'react';
import * as yup from 'yup';

interface AddTpaButtonProps {
  tpaData: TpaListDtoItem[];
  addTpa: (tpaName: string) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    tpaBtnContainer: {
      display: 'flex',
      justifyContent: 'flex-end'
    }
  })
);

const AddTpaButton = (props: AddTpaButtonProps): JSX.Element => {
  const classes = useStyles();
  const { openDialog } = useDialog();

  const { tpaData, addTpa } = props;

  const validationSchema = yup.object({
    tpaName: yup
      .string()
      .required('TPA Name is required')
      .min(3, 'TPA Name length must be at least 3 characters')
      .test('unique', 'TPA Name must be unique', inputValue => {
        if (inputValue) {
          return !tpaData.some(
            tpa => tpa.name.toLowerCase() === inputValue.toLowerCase()
          );
        }
        return false;
      })
  });

  return (
    <div className={classes.tpaBtnContainer}>
      <Button
        data-testid='tpa-main-page-btn'
        onClick={() =>
          openDialog({
            actionButtons: {
              cancelButton: {
                children: 'CANCEl'
              },
              submitButton: {
                children: 'ADD'
              }
            },
            onSubmit: values => {
              addTpa(values.tpaName);
            },
            steps: [
              {
                fields: {
                  tpaName: {
                    initialValue: '',
                    label: 'TPA Name'
                  }
                },
                title: 'Add New TPA'
              }
            ],
            validationSchema
          })
        }
        variant='contained'>
        ADD TPA
      </Button>
    </div>
  );
};

export default AddTpaButton;
