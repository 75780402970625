export enum Recordkeeper {
  LT = 1,
  Voya = 2,
  Vestwell = 3,
  Surpas = 4,
  SubA = 5,
  ESA = 6
}

export enum RecordkeeperDisplay {
  LT = 'LT',
  Voya = 'Voya',
  Vestwell = 'Vestwell (RK) - Folio',
  Surpas = 'Surpas (State IRA)',
  SubA = 'SIERRA',
  ESA = 'ESA'
}
