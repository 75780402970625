import mime from 'mime';

class InMemoryFileDownloadService {
  static triggerFileDownload(contents: string, filename: string): void {
    const element = document.createElement('a');
    element.setAttribute('download', filename);
    const contentType = mime.getType(filename);
    const data = `data:${contentType};base64,${encodeURIComponent(contents)}`;
    element.setAttribute('href', data);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
}

export default InMemoryFileDownloadService;
