import { WorkflowExecutionStatusEnum } from '@vestwell-sub-accounting/models/conductor/WorkflowExecutionStatus.model';

export const executionStatusColorMap: Partial<
  Record<
    WorkflowExecutionStatusEnum,
    'info' | 'neutral' | 'success' | 'warning'
  >
> = {
  [WorkflowExecutionStatusEnum.Aborted]: 'warning',
  [WorkflowExecutionStatusEnum.Failed]: 'warning',
  [WorkflowExecutionStatusEnum.Running]: 'neutral',
  [WorkflowExecutionStatusEnum.Started]: 'success',
  [WorkflowExecutionStatusEnum.Succeeded]: 'success'
};
