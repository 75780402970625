import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Collapse } from '@mui/material';

import React, { useState } from 'react';

interface CollapsibleLabelProps {
  label: string;
  content: () => JSX.Element;
}
const CollapsibleLabel: React.FunctionComponent<
  CollapsibleLabelProps
> = props => {
  const { label, content } = props;
  const [open, setOpen] = useState(true);

  return (
    <div>
      <div
        onClick={() => setOpen(prevOpen => !prevOpen)}
        onKeyDown={() => {}}
        role='button'
        style={{
          color: 'rgba(0, 0, 0, 0.6)',
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'left',
          textAlign: 'center'
        }}
        tabIndex={0}>
        <div>{label}</div>
        <div>{open ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}</div>
      </div>
      <Collapse in={!open} timeout='auto' unmountOnExit>
        <div
          style={{
            color: 'rgba(0, 0, 0, 0.6)',
            fontSize: '0.8em',
            margin: '10px 0 0 0'
          }}>
          {' '}
        </div>
        {content()}
      </Collapse>
    </div>
  );
};

export default CollapsibleLabel;
