import CollapsibleTable from '@/components/collapsible-table';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import {
  FundLineupData,
  FundLineupFund
} from '@/routes/ops/investments/FundLineup.component';
import formatters from '@/utils/Formatters';
import {
  alpha,
  Box,
  Card,
  CardContent,
  CardHeader,
  Theme
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GridColDef } from '@mui/x-data-grid-pro';

import Decimal from 'decimal.js';
import React, { useMemo, useState } from 'react';

import FundLineupTableCell from './FundLineupCell.component';
import { FundLineupFields } from './types';

const mapDataRow = (option: FundLineupFund, maxTickerLength: number) => ({
  broadAssetClass: option.broadAssetClass,
  expenseRatio: new Decimal(option.expenseRatio).toFixed(5),
  id: option.fundId,
  maxTickerLength,
  security: {
    cusip: option.cusip,
    fundName: option.fundName,
    id: option.symbol
  }
});

const useStyles = makeStyles((theme: Theme) => ({
  card: { padding: theme.spacing(3) },
  cardContext: { padding: 0, paddingTop: theme.spacing(2.25) },
  cardHeaderRoot: { padding: 0 },
  cardHeaderTitle: { fontWeight: 500 },
  headerCell: {
    color: alpha(theme.palette.common.black, 0.6),
    fontSize: theme.spacing(1.75),
    fontWeight: 500,
    paddingBottom: theme.spacing(1.25),
    paddingTop: theme.spacing(1.25)
  }
}));

interface FundLineupTableProps {
  data?: FundLineupData;
}

const FundLineupTable: React.FC<FundLineupTableProps> = props => {
  const { data } = props;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const classes = useStyles();

  const fundLineupFundsData = data?.fundLineupFunds;

  const columns: GridColDef[] = useMemo(
    () => [
      {
        cellClassName: classes.headerCell,
        field: FundLineupFields.SECURITY,
        headerName: 'Security'
      },
      {
        cellClassName: classes.headerCell,
        field: FundLineupFields.BROAD_ASSET_CLASS,
        headerName: 'Broad Asset Class'
      },
      {
        cellClassName: classes.headerCell,
        field: FundLineupFields.EXPENSE_RATIO,
        headerName: 'Expense Ratio'
      }
    ],
    [classes]
  );

  const tableData = useMemo(() => {
    if (!fundLineupFundsData) return [];

    const maxTickerLength = Math.max(
      ...fundLineupFundsData.map(p => p.symbol.length)
    );
    return fundLineupFundsData.map(p => mapDataRow(p, maxTickerLength));
  }, [fundLineupFundsData]);

  const programDesc = [
    { label: 'Owner', value: 'Vestwell' },
    {
      label: 'Description',
      value: formatters.chopStringLongerThan(data?.description, 300)
    }
  ];

  return (
    <Box>
      <Card className={classes.card} elevation={0} variant='outlined'>
        <CardHeader
          classes={{
            root: classes.cardHeaderRoot,
            title: classes.cardHeaderTitle
          }}
          title={data?.name}
        />
        <CardContent className={classes.cardContext}>
          <TextStack direction='row' rowColumnWidth='dynamic'>
            {programDesc
              .filter(item => item.value)
              .map(item => (
                <TextStackItem key={item.label} maxWidth='100%'>
                  <TextLabel>{item.label}</TextLabel>
                  <TextValue>{item.value}</TextValue>
                </TextStackItem>
              ))}
          </TextStack>
        </CardContent>
      </Card>
      <CollapsibleTable
        backgroundPaperElevation={0}
        cellComponent={FundLineupTableCell}
        columns={columns}
        pager={{
          metaCount: tableData.length,
          onPageNumberChanged: newPage => setPageNumber(newPage + 1),
          onRowsPerPageChanged: newRowsPerPage =>
            setRowsPerPage(newRowsPerPage),
          pageNumber: pageNumber - 1,
          rowsPerPage: rowsPerPage
        }}
        primaryKey='fundLineupId'
        rootPaperElevation={0}
        tableData={tableData.slice(
          rowsPerPage * pageNumber - rowsPerPage,
          rowsPerPage * pageNumber
        )}
        useSquareBottomContainer
      />
    </Box>
  );
};

export default FundLineupTable;
