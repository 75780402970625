import SimpleDropdown from '@/components/simple-dropdown';
import STATE_CODES from '@/consts/states.constants';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  TextField
} from '@mui/material';

import { FormikContextType, useFormikContext } from 'formik';
import { get } from 'lodash';
import { useMemo } from 'react';

type TaxDetailsCardProps = {
  keyPrefix: string;
  disableRothInfo: boolean;
};

const TaxDetailsCard: React.FunctionComponent<TaxDetailsCardProps> = (
  props: TaxDetailsCardProps
) => {
  const { keyPrefix, disableRothInfo } = props;
  const formik: FormikContextType<any> = useFormikContext();

  const fieldIds = useMemo(() => {
    return {
      _1099Code: `${keyPrefix}_1099Code`,
      err: `${keyPrefix}err`,
      federalWithholdingPercent: `${keyPrefix}federalWithholdingPercent`,
      rothInitialYear: `${keyPrefix}rothInitialYear`,
      rothQualifiedWithdrawal: `${keyPrefix}rothQualifiedWithdrawal`,
      stateWithholdingCode: `${keyPrefix}stateWithholdingCode`,
      stateWithholdingPercent: `${keyPrefix}stateWithholdingPercent`
    };
  }, [keyPrefix]);

  return (
    <>
      <Grid
        columnSpacing={{ md: 3, sm: 2, xs: 1 }}
        container
        rowSpacing={2}
        sx={{ py: 2 }}>
        <Grid item xs={4}>
          <TextField
            InputProps={{
              endAdornment: <InputAdornment position='start'>%</InputAdornment>
            }}
            error={
              get(formik.touched, fieldIds.federalWithholdingPercent) &&
              Boolean(get(formik.errors, fieldIds.federalWithholdingPercent))
            }
            fullWidth
            helperText={
              (get(formik.touched, fieldIds.federalWithholdingPercent) &&
                get(
                  formik.errors,
                  fieldIds.federalWithholdingPercent
                )?.toString()) ||
              ''
            }
            id={fieldIds.federalWithholdingPercent}
            label='Federal Withholding'
            name={fieldIds.federalWithholdingPercent}
            onChange={formik.handleChange}
            required
            size='small'
            value={get(formik.values, fieldIds.federalWithholdingPercent)}
          />
        </Grid>
        <Grid item xs={4}>
          <SimpleDropdown
            fieldId={fieldIds.stateWithholdingCode}
            fieldName='State Withholding Code'
            fieldValues={STATE_CODES}
            size='small'
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            InputProps={{
              endAdornment: <InputAdornment position='start'>%</InputAdornment>
            }}
            error={
              get(formik.touched, fieldIds.stateWithholdingPercent) &&
              Boolean(get(formik.errors, fieldIds.stateWithholdingPercent))
            }
            fullWidth
            helperText={
              (get(formik.touched, fieldIds.stateWithholdingPercent) &&
                get(
                  formik.errors,
                  fieldIds.stateWithholdingPercent
                )?.toString()) ||
              ''
            }
            id={fieldIds.stateWithholdingPercent}
            label='State Withholding'
            name={fieldIds.stateWithholdingPercent}
            onChange={formik.handleChange}
            required
            size='small'
            value={get(formik.values, fieldIds.stateWithholdingPercent)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            error={
              get(formik.touched, fieldIds._1099Code) &&
              Boolean(get(formik.errors, fieldIds._1099Code))
            }
            fullWidth
            helperText={
              (get(formik.touched, fieldIds._1099Code) &&
                get(formik.errors, fieldIds._1099Code)?.toString()) ||
              ''
            }
            id={fieldIds._1099Code}
            label='1099 Code'
            name={fieldIds._1099Code}
            onChange={formik.handleChange}
            required
            size='small'
            value={get(formik.values, fieldIds._1099Code)}
          />
        </Grid>{' '}
        <Grid item xs={8}></Grid>
        {!disableRothInfo && (
          <>
            <Grid item xs={4}>
              <TextField
                error={
                  get(formik.touched, fieldIds.rothInitialYear) &&
                  Boolean(get(formik.errors, fieldIds.rothInitialYear))
                }
                fullWidth
                helperText={
                  (get(formik.touched, fieldIds.rothInitialYear) &&
                    get(formik.errors, fieldIds.rothInitialYear)?.toString()) ||
                  ''
                }
                id={fieldIds.rothInitialYear}
                label='Initial Roth Year'
                name={fieldIds.rothInitialYear}
                onChange={formik.handleChange}
                required
                size='small'
                value={get(formik.values, fieldIds.rothInitialYear)}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={get(
                        formik.values,
                        fieldIds.rothQualifiedWithdrawal
                      )}
                    />
                  }
                  label='Qualified Roth'
                  name={fieldIds.rothQualifiedWithdrawal}
                  onChange={(event, checked) => {
                    formik.setFieldValue(
                      fieldIds.rothQualifiedWithdrawal,
                      checked
                    );
                  }}
                />

                <FormHelperText
                  error={Boolean(
                    get(formik.errors, fieldIds.rothQualifiedWithdrawal)
                  )}
                  sx={{ mb: 3 }}
                  variant='outlined'>
                  {get(formik.touched, fieldIds.rothQualifiedWithdrawal)
                    ? get(
                        formik.errors,
                        fieldIds.rothQualifiedWithdrawal
                      )?.toString() ?? ''
                    : ''}
                </FormHelperText>
              </FormControl>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default TaxDetailsCard;
