import CircularLoading from '@/components/circular-loading';
import CollapsibleTable, { Order } from '@/components/collapsible-table';
import { SponsorPlanInfoDto } from '@/models/PlanV2DTO.model';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  TableCell,
  TableFooter,
  TableRow,
  Typography
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { UseQueryResult } from '@tanstack/react-query';

import React from 'react';

import { PlanFilters } from '../consts';
import { PlansFilters } from '../PlansFilters/PlansFilters.component';
import { SponsorPlanV1 } from './PlansIndex.component';
import PlanTableCell from './PlanTableCell.component';

type PlansIndexTableProps = {
  areFiltersApplied: boolean;
  hideProgram: boolean;
  isModal: boolean;
  isTPA: boolean;
  isVestwellUser: boolean;
  planTypes: string[];
  updateFilters: (newFilters: PlanFilters) => void;
  planListQuery: UseQueryResult<SponsorPlanInfoDto, unknown>;
  plansStatsFetching?: boolean;
  columns: GridColDef[];
  setPageNumber: (pageNumber: number) => void;
  setRowsPerPage: (rowsPerPage: number) => void;
  pageNumber: number;
  rowsPerPage: number;
  plans: (SponsorPlanV1 & {
    disableLink: boolean;
    stats: any;
  })[];
  receivePlan: (plan: SponsorPlanV1) => void;
  setSorter: (sorter: { direction: Order; by: string }) => void;
  sorter: { direction: Order; by: string };
};
const PlansIndexTable: React.FC<PlansIndexTableProps> = props => {
  return (
    <Box
      sx={{
        alignContent: 'flex-start',
        backgroundColor: 'white',

        display: 'flex',
        justifyContent: 'flex-start',
        overflow: 'hidden'
      }}>
      <PlansFilters
        areFiltersApplied={props.areFiltersApplied}
        hideProgram={props.hideProgram}
        isModal={props.isModal}
        isTPA={props.isTPA}
        isVestwellUser={props.isVestwellUser}
        planTypes={props.planTypes}
        updateFilters={props.updateFilters}
      />
      <Box overflow='auto' width='100%'>
        <CollapsibleTable
          backgroundPaperElevation={0}
          cellComponent={PlanTableCell}
          columns={props.columns}
          disablePagination={!props.planListQuery.data?.meta.count}
          footerComponent={
            <>
              {(!props.planListQuery.data?.meta.count ||
                props.planListQuery.isFetching) && (
                <TableFooter>
                  <TableRow>
                    <TableCell
                      colSpan={props.columns.length}
                      sx={{
                        border: 'none',
                        paddingBottom: 20,
                        paddingTop: 20
                      }}>
                      <Box
                        alignItems='center'
                        display='flex'
                        flexDirection='column'
                        gap={1}
                        justifyContent='center'>
                        {props.planListQuery.isFetching ||
                        props.plansStatsFetching ? (
                          <CircularLoading />
                        ) : (
                          <>
                            <SearchIcon
                              sx={{
                                color: 'common.black',
                                height: 40,
                                width: 40
                              }}
                            />
                            <Typography variant='body2'>
                              No plans found
                            </Typography>
                          </>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              )}
            </>
          }
          isModal={props.isModal}
          pager={{
            metaCount: props.planListQuery.data?.meta.count,
            onPageNumberChanged: (newPageNumber: number) => {
              return props.setPageNumber(newPageNumber);
            },
            onRowsPerPageChanged: (newRowsPerPage: number) => {
              return props.setRowsPerPage(newRowsPerPage);
            },
            pageNumber: props.pageNumber,
            rowsPerPage: props.rowsPerPage
          }}
          receivePlan={props.receivePlan}
          rootPaperElevation={0}
          sorter={{
            onSortOrderChanged: (newOrderBy: string, newOrder: Order) => {
              props.setSorter({ by: newOrderBy, direction: newOrder });
            },
            order: props.sorter.direction,
            orderBy: props.sorter.by
          }}
          tableData={
            props.isModal
              ? props.plans.map(plan => {
                  return { ...plan, selected: false };
                })
              : props.plans
          }
          useSquareBottomContainer
        />
      </Box>
    </Box>
  );
};

export default PlansIndexTable;
