import { DataGridFilters } from '@/components/data-grid-filters/DataGridFilters.component';
import { useUrlStateParams } from '@/utils/Url';
import { Search } from '@mui/icons-material';
import CachedIcon from '@mui/icons-material/Cached';
import {
  Autocomplete,
  Button,
  Checkbox,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material';

import { Field, Form, Formik } from 'formik';
import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { NumericFormat } from 'react-number-format';

import {
  initialPlansFilters,
  PlanFilters,
  PlanFilterType,
  planMainTypesOptions,
  pooledMainTypesOptions,
  pooledStatusesOptions,
  statusesOptions
} from '../consts';

interface PlansFiltersProps {
  updateFilters: (filters: PlanFilters) => void;
  planTypes?: string[];
  hideProgram?: boolean;
  isEsaDisplay?: boolean;
  isTPA?: boolean;
  areFiltersApplied?: boolean;
  isVestwellUser?: boolean;
  isModal?: boolean;
  isPooledPlanDisplay?: boolean;
}

interface FiltersFormValues {
  search: string;
  statuses: string[];
  types: string[];
  filterType: PlanFilterType;
}

const getToggleOptions = (
  isTPA?: boolean,
  isVestwellUser?: boolean,
  isModal?: boolean,
  isEsaDisplay?: boolean,
  hideProgram?: boolean,
  isPooledPlanDisplay?: boolean
) => {
  const toggles = [
    { label: 'Plan ID', value: PlanFilterType.PLAN_ID },
    { label: 'Plan Name', value: PlanFilterType.PLAN_NAME }
  ];
  if (!isModal && !isPooledPlanDisplay) {
    if (isVestwellUser || isTPA || isEsaDisplay) {
      toggles.push({ label: 'Firm', value: PlanFilterType.FIRM });
    }
    if (!isTPA && isVestwellUser && !hideProgram && !isPooledPlanDisplay) {
      toggles.push({ label: 'Program Name', value: PlanFilterType.PROGRAM });
    }
  }
  return toggles;
};

const SearchByPlanIdInput = React.forwardRef(props => {
  return (
    <NumericFormat
      allowNegative={false}
      data-testid='numeric-search-by-id'
      decimalScale={0}
      {...props}
    />
  );
});

export const PlansFilters: React.FC<PlansFiltersProps> = props => {
  const [searchFilter, setSearchFilter] = useUrlStateParams<string>(
    initialPlansFilters.search,
    'search',
    value => value,
    value => value.trim()
  );

  const [filterTypeFilter, setFilterTypeFilter] =
    useUrlStateParams<PlanFilterType>(
      initialPlansFilters.filterType,
      'filterType',
      value => value,
      (value: PlanFilterType) =>
        Object.values(PlanFilterType).includes(value)
          ? value
          : initialPlansFilters.filterType
    );

  const [statusesFilter, setStatusesFilter] = useUrlStateParams<string[]>(
    initialPlansFilters.statuses,
    'statuses',
    value => value.join(),
    value => value.split(',')
  );

  const [typesFilter, setTypesFilter] = useUrlStateParams<string[]>(
    initialPlansFilters.types,
    'types',
    value => value.join(),
    value => value.split(',')
  );

  const filterTypes = useMemo(
    () =>
      getToggleOptions(
        props.isTPA,
        props.isVestwellUser,
        props.isModal,
        props.isEsaDisplay,
        props.hideProgram,
        props.isPooledPlanDisplay
      ),
    [
      props.isTPA,
      props.isVestwellUser,
      props.isModal,
      props.isEsaDisplay,
      props.hideProgram
    ]
  );

  const handleSubmit = useCallback((values: FiltersFormValues) => {
    setSearchFilter(values.search);
    setFilterTypeFilter(values.filterType);
    setStatusesFilter(values.statuses);
    setTypesFilter(values.types);
  }, []);

  const paramsPlansFilters = useMemo(() => {
    return {
      filterType: filterTypeFilter,
      search: searchFilter,
      statuses: statusesFilter,
      types: typesFilter
    };
  }, [filterTypeFilter, searchFilter, statusesFilter, typesFilter]);

  const prevParamsPlansFilters = useRef(initialPlansFilters);

  useEffect(() => {
    if (!isEqual(prevParamsPlansFilters.current, paramsPlansFilters)) {
      prevParamsPlansFilters.current = paramsPlansFilters;
      props.updateFilters(paramsPlansFilters);
    }
  }, [paramsPlansFilters]);

  return (
    <DataGridFilters>
      <Formik
        initialValues={{ ...initialPlansFilters, ...paramsPlansFilters }}
        onSubmit={handleSubmit}>
        {formProps => {
          const areFiltersEmpty = isEqual(
            formProps.values,
            initialPlansFilters
          );

          return (
            <Form data-testid='filter-form'>
              <Stack
                alignItems='stretch'
                flexDirection='column'
                gap={2}
                width={272}>
                <FormControl fullWidth>
                  <Field
                    InputProps={{
                      'aria-placeholder': 'Search Plans',
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Search />
                        </InputAdornment>
                      ),
                      ...(formProps.values.filterType === PlanFilterType.PLAN_ID
                        ? { inputComponent: SearchByPlanIdInput }
                        : {}),
                      placeholder: `Search by ${filterTypes
                        .find(t => t.value === formProps.values.filterType)
                        ?.label.toLowerCase()}`,
                      sx: { '& .MuiOutlinedInput-input': { pb: 1, pt: 1 } }
                    }}
                    as={TextField}
                    data-testid='search-plans'
                    id='search-plans'
                    name='search'
                    variant='outlined'
                  />
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id='search-by-label'>Search By</InputLabel>
                  <Select
                    data-testid='search-by-select'
                    label='Search By'
                    labelId='search-by-label'
                    onChange={event => {
                      formProps.setFieldValue('filterType', event.target.value);
                    }}
                    size='small'
                    value={formProps.values.filterType}>
                    {filterTypes.map(type => (
                      <MenuItem
                        data-testid={type.label
                          .replace(/\s+/g, '_')
                          .toLowerCase()}
                        key={type.value}
                        value={type.value}>
                        {type.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {(!props.isModal || props.isEsaDisplay) && (
                  <>
                    <Divider
                      component='div'
                      role='presentation'
                      textAlign='left'>
                      Meta
                    </Divider>
                    <FormControl fullWidth>
                      <Autocomplete
                        data-testid='plan-status-filter'
                        disableCloseOnSelect
                        multiple
                        onChange={(_event, newValues) => {
                          formProps.setFieldValue('statuses', newValues);
                        }}
                        options={
                          props.isPooledPlanDisplay
                            ? pooledStatusesOptions
                            : statusesOptions
                        }
                        renderInput={params => (
                          <TextField
                            {...params}
                            data-testid='plan-status-filter-input'
                            label='Status'
                            sx={{ p: 0 }}
                          />
                        )}
                        renderOption={(rootProps, option, { selected }) => (
                          <MenuItem
                            {...rootProps}
                            data-value={option}
                            style={{ whiteSpace: 'normal' }}>
                            <Checkbox checked={selected} sx={{ py: 0 }} />
                            {option}
                          </MenuItem>
                        )}
                        size='small'
                        value={formProps.values.statuses}
                      />
                    </FormControl>
                  </>
                )}

                {!props.isModal &&
                  !props.isTPA &&
                  props.isVestwellUser &&
                  !props.planTypes?.length &&
                  !props.isPooledPlanDisplay && (
                    <>
                      <FormControl fullWidth>
                        <Autocomplete
                          data-testid='plan-type-filter'
                          disableCloseOnSelect
                          multiple
                          onChange={(_event, newValues) => {
                            formProps.setFieldValue('types', newValues);
                          }}
                          options={
                            props.isPooledPlanDisplay
                              ? pooledMainTypesOptions
                              : planMainTypesOptions
                          }
                          renderInput={params => (
                            <TextField
                              {...params}
                              data-testid='plan-type-filter-input'
                              label='Type'
                              sx={{
                                p: 0
                              }}
                            />
                          )}
                          renderOption={(rootProps, option, { selected }) => (
                            <MenuItem {...rootProps} data-value={option}>
                              <Checkbox
                                checked={selected}
                                sx={{ pl: 0, py: 0 }}
                              />
                              {option}
                            </MenuItem>
                          )}
                          size='small'
                          value={formProps.values.types}
                        />
                      </FormControl>
                    </>
                  )}

                {props.isPooledPlanDisplay && (
                  <FormControl fullWidth>
                    <Autocomplete
                      data-testid='plan-type-filter'
                      disableCloseOnSelect
                      multiple
                      onChange={(_event, newValues) => {
                        formProps.setFieldValue('types', newValues);
                      }}
                      options={pooledMainTypesOptions}
                      renderInput={params => (
                        <TextField
                          {...params}
                          data-testid='plan-type-filter-input'
                          label='Type'
                          sx={{
                            p: 0
                          }}
                        />
                      )}
                      renderOption={(rootProps, option, { selected }) => (
                        <MenuItem {...rootProps} data-value={option}>
                          <Checkbox checked={selected} sx={{ pl: 0, py: 0 }} />
                          {option}
                        </MenuItem>
                      )}
                      size='small'
                      value={formProps.values.types}
                    />
                  </FormControl>
                )}
                <Stack
                  flexDirection='row'
                  gap={2}
                  justifyContent='space-between'
                  width='100%'>
                  <Button
                    data-testid='submit'
                    disabled={!formProps.isValid}
                    type='submit'
                    variant='outlined'>
                    Apply
                  </Button>
                  {!areFiltersEmpty && (
                    <Button
                      data-testid='reset'
                      onClick={() => {
                        formProps.resetForm();
                        props.updateFilters(initialPlansFilters);
                        handleSubmit(initialPlansFilters);
                      }}
                      startIcon={<CachedIcon />}
                      variant='text'>
                      Reset
                    </Button>
                  )}
                </Stack>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </DataGridFilters>
  );
};

PlansFilters.displayName = 'PlansFilters';

PlansFilters.defaultProps = {
  areFiltersApplied: false,
  isEsaDisplay: false,
  isModal: false,
  isTPA: false,
  isVestwellUser: false
};
