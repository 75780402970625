import CollapsibleTable from '@/components/collapsible-table';
import {
  AdvisorDto,
  AdvisorSponsorPlanDto,
  AdvisorSponsorPlansDto
} from '@/models';
import AdvisorDetailsPlansTableCellComponent from '@/routes/advisors/advisor-details/AdvisorDetailsPlansTableCell.component';
import AdvisorService from '@/services/Advisor.service';
import { Search } from '@mui/icons-material';
import { Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import React, { useState } from 'react';
import { useDebounce } from 'use-debounce';

interface AdvisorDetailsPlansProps {
  advisorId: AdvisorDto['data']['id'];
}

const AdvisorDetailsPlans: React.FC<AdvisorDetailsPlansProps> = ({
  advisorId
}) => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [filter, setFilter] = useState('');

  const [debouncedFilter] = useDebounce(filter, 300);

  const advisors = useQuery<AdvisorSponsorPlansDto>(
    [
      'AdvisorService.getAdvisorSponsorPlans',
      advisorId,
      size,
      page,
      debouncedFilter
    ],
    async () => {
      return AdvisorService.getAdvisorSponsorPlans({
        advisorId,
        filter: debouncedFilter,
        page,
        size,
        sortBy: 'createdAt',
        sortDirection: 'DESC'
      });
    },
    {
      keepPreviousData: true,
      staleTime: Infinity
    }
  );

  const columns: GridColDef<AdvisorSponsorPlanDto>[] = [
    {
      field: 'sponsorPlanId',
      headerName: 'Plan ID'
    },
    {
      field: 'sponsorId',
      headerName: 'Sponsor ID'
    },
    {
      field: 'programName',
      headerName: 'Program Name'
    },
    {
      field: 'planName',
      headerName: 'Plan Name'
    },
    {
      field: 'planStatus',
      headerName: 'Plan Status'
    },
    {
      field: 'createdAt',
      headerName: 'Creation Date'
    }
  ];

  return (
    <Grid container>
      <Grid
        border='1px solid rgba(0, 0, 0, 0.12)'
        borderRadius='0.25rem 0.25rem'
        item
        mb='1.5rem'
        xs={12}>
        <Grid container direction='column'>
          <Grid item>
            <Grid
              borderBottom='1px solid rgba(0, 0, 0, 0.12)'
              container
              p='1rem'>
              <Typography variant='h5'>All Plans</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item lg={4} md={6} p='0.5rem 1rem 1rem 1rem' xs={12}>
                <TextField
                  InputProps={{
                    'aria-placeholder': 'Search by id, name, status',
                    onChange: event => {
                      setFilter(event.target.value || '');
                      setPage(1);
                    },
                    placeholder: 'Search by id, name, status',
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Search />
                      </InputAdornment>
                    )
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <CollapsibleTable
              backgroundPaperElevation={0}
              cellComponent={AdvisorDetailsPlansTableCellComponent}
              columns={columns}
              pager={{
                metaCount: advisors.data?.totalCount || 0,
                onPageNumberChanged: (pageNumber: number) => {
                  return setPage(pageNumber + 1);
                },
                onRowsPerPageChanged: (rowsPerPage: number) => {
                  return setSize(rowsPerPage);
                },
                pageNumber: page - 1,
                rowsPerPage: size
              }}
              primaryKey='sponsorPlanId'
              tableData={advisors.data?.data || []}
              useSquareBottomContainer
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdvisorDetailsPlans;
