import Constants from '@/consts/planDesign.constants';
import { EligibilityRule } from '@/models/PlanDesign.model';

class MinimumAge {
  convertToApiState = (value: string): number | undefined => {
    if (!value) {
      return undefined;
    }

    return value === 'None' ? 0 : parseInt(value, 10);
  };

  options = Constants.minimumAgeOptions.map(option =>
    option === 0 ? 'None' : String(option)
  );

  output: number | string | undefined;

  constructor(props: Record<string, any>) {
    this.output = this.transform(props);
  }

  private transform = (props: Record<string, any>): string | number => {
    const ageRequirement = props.eligibilityRules?.find(
      (er: EligibilityRule) => er.eligibilityRequirementTypeName === 'Age'
    );

    if (ageRequirement && ageRequirement.ruleParameter) {
      return ageRequirement.ruleParameter.age === 0
        ? 'None'
        : ageRequirement.ruleParameter.age?.toString();
    }

    return '';
  };
}

export default MinimumAge;
