import SimpleNumberField from '@/components/simple-number-field/SimpleNumberField';
import {
  ParticipantHoursToUpdateDto,
  UpdatedHoursOfServiceDto
} from '@/models/';
import formatters from '@/utils/Formatters';
import { getUpdateHoursValidationSchema } from '@/utils/validations/HoursOfServiceValidation.schema';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {
  alpha,
  Box,
  Button,
  Collapse,
  FormHelperText,
  TableCell,
  TableRow,
  Theme
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { GridColDef } from '@mui/x-data-grid-pro';
import { UseMutateFunction } from '@tanstack/react-query';

import clsx from 'clsx';
import dayjs from 'dayjs';
import Decimal from 'decimal.js';
import { Form, Formik } from 'formik';
import React from 'react';

import { useSharedStyles } from './styles';

const useStyles = makeStyles((theme: Theme) => ({
  column: {
    fontSize: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
  editIcon: {
    cursor: 'pointer',
    fill: theme.palette.grey[500]
  },
  hoursTooltip: {
    position: 'absolute',
    top: '100%'
  },
  openedRow: {
    backgroundColor: alpha(theme.palette.primary.main, 0.04)
  }
}));

type HoursUpdateFormValues = {
  hours: string;
};

interface DisplayRowCellProps {
  column: GridColDef;
  row: any;
  handleEditOpen: () => void;
  isEditable: boolean;
  id: number;
}

const DisplayRowCell: React.FunctionComponent<DisplayRowCellProps> = (
  props: DisplayRowCellProps
) => {
  const { column, row, handleEditOpen, isEditable, id } = props;
  const classes = useStyles();

  let value = row[column.field];
  if (column.field === 'action' && isEditable) {
    value = (
      <EditOutlinedIcon
        aria-label='edit-hours'
        className={classes.editIcon}
        data-testid={`${id}-open-hours-edit`}
        onClick={handleEditOpen}
      />
    );
  }
  if (column.field === 'hours') {
    value = !isNaN(row[column.field])
      ? formatters.formatDecimal(row[column.field], 2)
      : row[column.field];
  }
  return (
    <TableCell
      className={classes.column}
      component='th'
      key={column.field}
      scope='row'>
      {value}
    </TableCell>
  );
};

interface HoursTableCellProps {
  row: any;
  columns: GridColDef[];
  selected: boolean;
  isEditable: boolean;
  isSubmitting: boolean;
  updateHoursInSubmit: (id: number) => void;
  onToggle: (id?: number) => void;
  onSubmit: UseMutateFunction<
    UpdatedHoursOfServiceDto,
    unknown,
    ParticipantHoursToUpdateDto,
    unknown
  >;
}

const HoursTableCell: React.FunctionComponent<HoursTableCellProps> = (
  props: HoursTableCellProps
) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const {
    row: { employeeHoursTracker: id, ...row },
    columns,
    selected,
    isEditable,
    isSubmitting,
    updateHoursInSubmit,
    onToggle,
    onSubmit
  } = props;

  const handleEditOpen = () => {
    if (!selected) {
      return onToggle(id);
    }
    onToggle();
  };

  const validationSchema = getUpdateHoursValidationSchema(
    row.startDate,
    row.endDate
  );
  const formInitialValues = {
    hours: row.hours
  };

  const checkDataEquality = (newValues: HoursUpdateFormValues) =>
    newValues.hours === ''
      ? false
      : new Decimal(formInitialValues.hours).toFixed(2) ===
        new Decimal(newValues.hours).toFixed(2);

  const handleFormSubmit = (values: HoursUpdateFormValues) => {
    if (checkDataEquality(values)) return;
    updateHoursInSubmit(id);
    const data: ParticipantHoursToUpdateDto = {
      data: [
        {
          employeeHoursTrackerId: id,
          endDate: dayjs(row.endDate).format('YYYY-MM-DD'),
          hours: +new Decimal(values.hours).toFixed(2),
          startDate: dayjs(row.startDate).format('YYYY-MM-DD')
        }
      ]
    };
    onSubmit(data);
  };

  return (
    <>
      <TableRow className={clsx(selected && classes.openedRow)}>
        {columns.map(column => (
          <DisplayRowCell
            column={column}
            handleEditOpen={handleEditOpen}
            id={id}
            isEditable={isEditable}
            key={column.field}
            row={row}
          />
        ))}
      </TableRow>
      {isEditable && (
        <TableRow>
          <TableCell
            className={clsx(
              sharedClasses.collapseColumn,
              selected && sharedClasses.openCollapseColumn
            )}
            colSpan={columns.length + 1}>
            <Collapse in={selected} timeout='auto' unmountOnExit>
              <Formik
                initialValues={formInitialValues}
                onSubmit={(values: HoursUpdateFormValues) =>
                  handleFormSubmit(values)
                }
                validationSchema={validationSchema}>
                {({ handleSubmit, errors, values, setFieldValue }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <Box marginBottom={3} marginTop={3} position='relative'>
                        <SimpleNumberField
                          allowNegative={false}
                          className={sharedClasses.inputField}
                          error={Boolean(errors.hours)}
                          inputProps={{
                            autoComplete: 'off',
                            autoFocus: true
                          }}
                          isDisabled={isSubmitting}
                          label='Hours'
                          name='hours'
                          onChange={value =>
                            setFieldValue('hours', value, true)
                          }
                          precision={2}
                          testId={`${id}-edit-hours-input`}
                          value={values.hours}
                        />
                        <Box
                          alignContent='center'
                          alignItems='center'
                          display='inline-flex'
                          gap={1}
                          height={40}>
                          <Button
                            className={sharedClasses.button}
                            data-testid={`${id}-cancel-hours-edit-btn`}
                            disabled={isSubmitting}
                            onClick={handleEditOpen}>
                            CANCEL
                          </Button>
                          <Button
                            className={sharedClasses.button}
                            data-testid={`${id}-update-hours-btn`}
                            disabled={isSubmitting || checkDataEquality(values)}
                            onClick={() => handleSubmit()}>
                            UPDATE
                          </Button>
                        </Box>
                        <FormHelperText
                          className={clsx(
                            sharedClasses.inputTooltip,
                            classes.hoursTooltip
                          )}
                          error={Boolean(errors.hours)}>
                          {errors.hours?.toString() ?? ' '}
                        </FormHelperText>
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default HoursTableCell;
