import Card, { CardHeader } from '@/components/card';
import DatePicker from '@/components/date-picker';
import { ParticipantInfo } from '@/models';
import { ParticipantPositionsDto } from '@/models/ParticipantPositionsDTO.model';
import ParticipantService from '@/services/Participant.service';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Tooltip } from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { Dayjs } from 'dayjs';
import React from 'react';
import { isTradingDay } from 'sub-accounting-calendar-utility';

import HoldingsSummarySection from './HoldingsSummarySection.component';
import PositionsTable from './PositionsTable.component';

type HoldingsCardProps = {
  asOfDate?: string;
  handleSetAsOfDate: (event: {
    target: {
      name: string;
      value: string;
    };
  }) => void;
  participantId: number;
};

const useStyles = makeStyles(() => ({
  infoIcon: {
    color: grey[600],
    fontSize: 'big',
    marginLeft: '-10px',
    marginRight: '25px',
    marginTop: '25px'
  }
}));

export const HoldingsCard: React.FunctionComponent<
  HoldingsCardProps
> = props => {
  const queryClient = useQueryClient();
  const classes = useStyles();
  const positionsQuery = useQuery<ParticipantPositionsDto>(
    ['ParticipantService.getPositions', props.participantId, props.asOfDate],
    async () =>
      ParticipantService.getPositions(props.participantId, props.asOfDate),
    {
      enabled: !!props.participantId,
      staleTime: Infinity
    }
  );
  const previousParticipant = queryClient.getQueryData<ParticipantInfo>([
    'ParticipantService.getParticipantById',
    props.participantId?.toString()
  ]);

  return (
    <Card data-testid='investments-holdings-card'>
      <CardHeader
        title='Holdings'
        titleDecoration={
          <>
            <Tooltip
              data-testid='holdings-as-of-tooltip'
              title='Note that this date toggle will update the holdings information for a participant. Model and expense ratios will not update per the date selection, and reflect the latest information available.'>
              <InfoOutlinedIcon
                className={classes.infoIcon}
                data-testid='holdings-as-of-info-icon'
              />
            </Tooltip>
            <Box height='50px' margin='20px 0px 10px 0px'>
              <DatePicker
                InputProps={{
                  autoComplete: 'off',
                  sx: { width: 170 }
                }}
                data-testid='holdings-as-of-datepicker'
                label='As of'
                name='as-of'
                onChange={props.handleSetAsOfDate}
                shouldDisableDate={(date: Dayjs) =>
                  !isTradingDay(date.format('YYYY-MM-DD'))
                }
                size='small' // FormControl doesn't pass to our DatePicker
                value={props.asOfDate}
                variant='outlined'
              />
            </Box>
          </>
        }
        titleStyle={{ marginTop: '20px' }}
      />
      <Box alignItems='stretch' display='flex' flexDirection='row'>
        <HoldingsSummarySection
          investmentElections={previousParticipant?.investmentElection}
          isLoading={positionsQuery.isFetching}
          stats={positionsQuery.data?.stats}
          usingAsOfDate={!!props.asOfDate}
          vestingTotal={previousParticipant?.vestingAmounts
            ?.flatMap(r => r.vestedAmount)
            .reduce((r, c) => r + c, 0)}
        />
        <PositionsTable
          asOfDate={props.asOfDate}
          isError={positionsQuery.isError}
          isLoading={positionsQuery.isFetching}
          positionsData={positionsQuery.data?.data}
        />
      </Box>
    </Card>
  );
};

HoldingsCard.displayName = 'HoldingsCard';
