import { CardPlaceholder } from '@/components/card';
import CollapsibleTable from '@/components/collapsible-table';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { ForfeitureHistoryTableCell } from '@/routes/plans/plan-detail/PlanForfeituresTab/ForfeitureHistoryTableCell.component';
import { PlanService } from '@/services/Plan.service';
import DOMInteraction from '@/utils/DOMInteraction';
import { ArrowDropDownOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Paper, styled, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useMutation, useQuery } from '@tanstack/react-query';

import React, { FC, useCallback, useMemo, useState } from 'react';

interface ForfeitureHistoryProps {
  sponsorPlanId: number;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(4),
  width: '100%'
}));

const Header = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  width: '100%'
}));

const columns = [
  { field: 'createdAt', headerName: 'Date', width: 130 },
  { field: 'category', headerName: 'Description', width: 130 },
  { field: 'amount', headerName: 'Amount', width: 130 },
  { field: 'status', headerName: 'Status', width: 130 },
  { field: 'tracerId', headerName: 'Tracer ID', width: 260 }
];

export const ForfeitureHistory: FC<ForfeitureHistoryProps> = props => {
  const { showSnackbar } = useSnackbar();

  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [pageNumber, setPageNumber] = useState(1);
  const [bookType, setBookType] = useState('');

  const transactions = useQuery(
    ['PlanService.getSubaTransactions', props.sponsorPlanId],
    () =>
      PlanService.getSubaTransactions(
        props.sponsorPlanId,
        'forfeiture',
        rowsPerPage,
        pageNumber
      ),
    {
      enabled: Boolean(props.sponsorPlanId),
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      select: data => {
        return data.map(item => ({
          ...item,
          sponsorPlanId: props.sponsorPlanId
        }));
      }
    }
  );

  const downloadReport = useMutation(
    ['PlanService.getSubaTransactionsReport', props.sponsorPlanId, bookType],
    (bookType: string) =>
      PlanService.getSubaTransactionsReport(
        props.sponsorPlanId,
        'forfeiture',
        bookType
      ),
    {
      onError: () => {
        showSnackbar({
          message: 'Error downloading document',
          severity: 'error'
        });
      },
      onSuccess: data => {
        DOMInteraction.triggerDownload(data, `Forfeiture History.${bookType}`);
      }
    }
  );

  const onClose = useCallback(() => setAnchor(null), []);

  const onClick = useCallback(event => setAnchor(event.currentTarget), []);

  const onDownload = useCallback(async () => {
    setBookType('csv');
    onClose();
    await downloadReport.mutateAsync('csv');
  }, []);

  const pager = useMemo(
    () => ({
      metaCount: transactions.data?.length,
      onPageNumberChanged: (zeroIndexedPageNumber: number) => {
        return setPageNumber(zeroIndexedPageNumber + 1);
      },
      onRowsPerPageChanged: (newRowsPerPage: number) => {
        return setRowsPerPage(newRowsPerPage);
      },
      pageNumber: pageNumber - 1,
      rowsPerPage
    }),
    [pageNumber, rowsPerPage, transactions.data?.length]
  );

  return (
    <StyledPaper
      data-testid='forfeitureHistory'
      elevation={0}
      variant='outlined'>
      <Header>
        <Typography variant='h5'>Forfeiture History</Typography>
        <LoadingButton
          aria-controls={anchor ? 'downloadActions' : undefined}
          aria-expanded={!!anchor}
          aria-haspopup='true'
          data-testid='downloadButton'
          endIcon={<ArrowDropDownOutlined />}
          loading={downloadReport.isLoading}
          onClick={onClick}>
          Download
        </LoadingButton>
        <Menu
          anchorEl={anchor}
          id='downloadActions'
          onClose={onClose}
          open={!!anchor}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}>
          <MenuItem data-testid='downloadCsv' onClick={onDownload}>
            Download CSV
          </MenuItem>
        </Menu>
      </Header>
      <CollapsibleTable
        cellComponent={ForfeitureHistoryTableCell}
        columns={columns}
        data-testid='forfeitureHistoryTable'
        isLoading={transactions.isLoading}
        noDataPlaceholderComponent={
          <CardPlaceholder
            data-testid='noDatadataForfeitureHistoryTable'
            subtitle={`${
              transactions.isError ? 'Error' : 'No data for this plan'
            }`}
          />
        }
        pager={pager}
        rootPaperElevation={0}
        tableData={
          transactions.data?.slice(
            rowsPerPage * pageNumber - rowsPerPage,
            rowsPerPage * pageNumber
          ) || []
        }
        useDivAsBackground
      />
    </StyledPaper>
  );
};
