import { Field, useField } from 'formik';
import { TextField } from 'formik-mui';
import React, { useEffect } from 'react';

const SimpleStaticValue = (props: {
  value: string;
  fieldId: string;
  fieldName: string;
}): JSX.Element => {
  const { value, fieldId, fieldName } = props;

  const [, , helpers] = useField(fieldId);

  useEffect(() => {
    helpers.setValue(value, true);
    return () => {
      helpers.setTouched(false);
      helpers.setValue('', true);
    };
  }, []);

  return (
    <Field
      component={TextField}
      disabled
      fullWidth
      label={fieldName}
      name={fieldId}
      value={value}
    />
  );
};

export default SimpleStaticValue;
