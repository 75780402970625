export type DepositType =
  | 'A'
  | 'R'
  | 'C'
  | 'W'
  | 'D'
  | 'F'
  | 'M'
  | 'V'
  | 'S'
  | 'B'
  | 'O'
  | 'P'
  | 'X';

export const depositTypeNames: Record<DepositType, string> = {
  A: 'ACH',
  B: 'Misc',
  C: 'Check',
  D: 'Dividend',
  F: 'IRA Rollover Deposit',
  M: 'Fund Commissions',
  O: 'Misc',
  P: 'Misc',
  R: 'ACH',
  S: 'Misc',
  V: 'ACH Reversal',
  W: 'Wire',
  X: 'Unclassified Cash Transfer'
};
