import { PayGroupForm } from '@/routes/plans/plan-detail/PayGroups/PayGroupsModal.component';
import SponsorService from '@/services/Sponsor.service';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import type { Sponsors } from '@vestwell-api/scala';

import { FormikProps } from 'formik';
import React from 'react';

export const PayGroupsModalContact: React.FunctionComponent<{
  formik: FormikProps<PayGroupForm>;
  sponsorId: number;
}> = props => {
  const contacts = useQuery<Sponsors.GetPayrollContacts.ResponseBody>(
    ['SponsorService.getSponsorPayrollContacts'],
    () => {
      return SponsorService.getSponsorPayrollContacts(props.sponsorId);
    }
  );

  return (
    <Grid item>
      <FormControl>
        <FormLabel
          id='demo-radio-buttons-group-label'
          sx={{
            '&.Mui-focused': {
              color: theme => theme.palette.text.secondary
            },
            marginBottom: 2
          }}>
          Select payroll contact
        </FormLabel>
        <RadioGroup
          name='payrollContactId'
          onBlur={props.formik.handleBlur}
          onChange={props.formik.handleChange}
          value={props.formik.values.payrollContactId}>
          <FormControlLabel
            control={<Radio />}
            label={
              <Grid item>
                <Typography variant='body1'>None</Typography>
              </Grid>
            }
            sx={{
              py: 2
            }}
            value=''
          />
          {contacts.data?.map(c => (
            <FormControlLabel
              control={<Radio />}
              key={c.payrollContactId}
              label={
                <Grid item>
                  <Typography variant='body1'>{`${c.firstName} ${c.lastName}`}</Typography>
                  <Typography variant='body2'>{c.email}</Typography>
                </Grid>
              }
              sx={{
                py: 2
              }}
              value={c.payrollContactId}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};
