import { WorkflowStatusEnumsObject } from '@/models/ops/workflows/WorkflowStatusEnumObject.model';

type ValueOf<T> = T[keyof T];

export const workflowStatusColorMap: Partial<
  Record<
    ValueOf<typeof WorkflowStatusEnumsObject>,
    'info' | 'neutral' | 'success' | 'warning'
  >
> = {
  /**
   * When WorkflowStatus changes the following should be reviewed and updated
   * to align. If this must be done retroactively, extract a list of enum
   * object values from sub-accounting-core in /src/models/conductor/WorkflowStatus.model.ts
   * and compare.
   */

  [WorkflowStatusEnumsObject.ACHRejected]: 'warning',
  [WorkflowStatusEnumsObject.CashTransferFailed]: 'warning',
  [WorkflowStatusEnumsObject.DisbursementError]: 'warning',
  [WorkflowStatusEnumsObject.DisbursementRejected]: 'warning',
  [WorkflowStatusEnumsObject.Failed]: 'warning',
  [WorkflowStatusEnumsObject.FeeTransferError]: 'warning',
  [WorkflowStatusEnumsObject.InvestmentUpdateError]: 'warning',
  [WorkflowStatusEnumsObject.ProcessingError]: 'warning',
  [WorkflowStatusEnumsObject.Rejected]: 'warning',
  [WorkflowStatusEnumsObject.ValidationError]: 'warning',

  [WorkflowStatusEnumsObject.ACHConfirmed]: 'success',
  [WorkflowStatusEnumsObject.Cleared]: 'success',
  [WorkflowStatusEnumsObject.Complete]: 'success',
  [WorkflowStatusEnumsObject.Confirmed]: 'success',
  [WorkflowStatusEnumsObject.Disbursed]: 'success',
  [WorkflowStatusEnumsObject.FundingConfirmed]: 'success',

  [WorkflowStatusEnumsObject.ConfirmCustodianAccount]: 'neutral',
  [WorkflowStatusEnumsObject.CreateInternalAccounts]: 'neutral',
  [WorkflowStatusEnumsObject.PendingACHProcessing]: 'neutral',
  [WorkflowStatusEnumsObject.PendingCustodianAccount]: 'neutral',
  [WorkflowStatusEnumsObject.PendingCustodianInvestmentUpdate]: 'neutral',
  [WorkflowStatusEnumsObject.PendingInvestment]: 'neutral',
  [WorkflowStatusEnumsObject.PendingLiquidation]: 'neutral',
  [WorkflowStatusEnumsObject.PendingProcessing]: 'neutral',
  [WorkflowStatusEnumsObject.PostingDeposits]: 'neutral',
  [WorkflowStatusEnumsObject.ProcessingCustodianInvestmentUpdate]: 'neutral',
  [WorkflowStatusEnumsObject.ValidatingRequest]: 'neutral',
  [WorkflowStatusEnumsObject.Queued]: 'neutral',

  [WorkflowStatusEnumsObject.CompleteNoTrades]: 'info',
  [WorkflowStatusEnumsObject.DisbursementsInitiated]: 'neutral',
  [WorkflowStatusEnumsObject.Disbursing]: 'neutral',
  [WorkflowStatusEnumsObject.FeeTransferInitiated]: 'neutral',
  [WorkflowStatusEnumsObject.Liquidating]: 'neutral',
  [WorkflowStatusEnumsObject.ProcessingAborted]: 'info',
  [WorkflowStatusEnumsObject.DisbursementCleared]: 'neutral'
};
