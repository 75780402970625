import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isDate, isNaN, isNumber, isString } from 'lodash';

dayjs.extend(utc);
dayjs.extend(timezone);

export const dateStringHasTimezone = (dateString: string): boolean => {
  return !!dateString.match(
    /^.*(T| ).*(?:Z|[+-]\d{2}(?::?\d{2})?|UTC|[a-zA-Z]+(?:\/[a-zA-Z_]+)*(?:\s+[a-zA-Z]+)?)$/
  );
};

export const isLessThanDaysFromNow = (
  rawDate: Parameters<typeof dayjs>[0],
  days: number
): boolean => {
  if (isNaN(rawDate)) return false;

  if (
    !(
      dayjs.isDayjs(rawDate) ||
      isDate(rawDate) ||
      isString(rawDate) ||
      isNumber(rawDate)
    )
  ) {
    return false;
  }

  return dayjs(rawDate).isBefore(dayjs().add(days, 'day'));
};

export const parseDateToZonedTime = <T = Date | string>(
  dateString: string
): T => {
  let date: Date;
  try {
    const dateUsesDashes = /^\d{2,4}-\d{1,2}-\d{1,2}$/.test(dateString);
    if (dateUsesDashes && !dateStringHasTimezone(dateString)) {
      /**
       * There is unexpected behavior (to the average user) in javascript where dates defined in
       * ISO dash format are parsed as UTC when the string has no timezone or offset this results
       * in the wrong date if the user is in a timezone whose offset puts the adjusted date the
       * day before or after the date in the string
       */
      date = dayjs(dateString).tz(dayjs.tz.guess()).toDate();
    } else {
      // if the value is an epoch (inherently UTC) or has a timezone or offset,
      // just parse it like normal which will use the timezone/offset from the string
      date = new Date(
        // check if it is an epoch that needs to be a number
        !isNaN(Number(dateString)) ? Number(dateString) : dateString
      );
    }
    if (isNaN(date.valueOf())) {
      return dateString as unknown as T;
    }
    return date as unknown as T;
  } catch (e) {
    // if the value is not a valid date, return the original value
    return dateString as unknown as T;
  }
};
