import ErrorMessage from '@/components/error-message';
import LinearLoading from '@/components/linear-loading';
import { SponsorUserInvitesInfo } from '@/models';
import SponsorService from '@/services/Sponsor.service';
import { Box, Theme, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';

import clsx from 'clsx';
import React, { useMemo } from 'react';
import Masonry from 'react-masonry-css';

import { PayGroups } from '../PayGroups/PayGroups.component';
import { PlanCompanyInfoCard } from '../PlanCompanyInfoCard/PlanCompanyInfoCard.component';
import PlanExemptionStatusCard from '../PlanExemptionStatusCard';
import PlanUsersTable from '../PlanUsersTable';
import PlanEmployerStatusCard from './PlanEmployerStatusCard.component';

interface PlanCompanyTabProps {
  sponsorPlanId: number;
  isStateIRA: boolean;
  isVoyaPlan: boolean;
  sponsorId: string | number;
  firmId?: number;
  isEsa?: boolean;
  tpaId?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '70vh',
    overflow: 'auto'
  },
  masonryGrid: {
    display: 'flex',
    gap: theme.spacing(2),
    width: 'auto'
  },
  masonryGridColumn: {
    '& > *': {
      marginBottom: theme.spacing(2)
    }
  }
}));

export const PlanCompanyTab: React.FC<PlanCompanyTabProps> = props => {
  const theme = useTheme<Theme>();
  const classes = useStyles();

  const masonryStyles = useMemo(
    () => ({
      breakpointCols: {
        default: 2,
        [theme.breakpoints.values.lg]: 1
      },
      className: clsx('Plan-detail__MasonryGrid', classes.masonryGrid),
      columnClassName: classes.masonryGridColumn
    }),
    [
      classes.masonryGridColumn,
      classes.masonryGrid,
      theme.breakpoints.values.lg
    ]
  );

  const sponsorUserInvitesQuery = useQuery<SponsorUserInvitesInfo>(
    ['SponsorService.getSponsorUserInvites', props.sponsorId.toString()],
    () => SponsorService.getSponsorUserInvites(props.sponsorId),
    {
      enabled: Boolean(props.sponsorId)
    }
  );

  const requestError = sponsorUserInvitesQuery.error as any | undefined;

  if (requestError) {
    return (
      <ErrorMessage
        error={requestError.message || 'An Unknown Error Occurred'}
      />
    );
  }

  if (!sponsorUserInvitesQuery.isSuccess || sponsorUserInvitesQuery.isError) {
    return <LinearLoading />;
  }

  return (
    <>
      <PlanCompanyInfoCard
        isEsa={props.isEsa}
        isStateIRA={props.isStateIRA}
        isVoyaPlan={props.isVoyaPlan}
        sponsorId={props.sponsorId}
        sponsorPlanId={props.sponsorPlanId}
        tpaId={props.tpaId}
      />
      <Box
        display='flex'
        flexDirection='row'
        gap={thisTheme => thisTheme.spacing(2)}
        marginBottom={thisTheme => thisTheme.spacing(2)}>
        {props.isStateIRA && (
          <>
            <PlanEmployerStatusCard sponsorPlanId={props.sponsorPlanId} />
            <PlanExemptionStatusCard
              firmId={props.firmId}
              sponsorId={props.sponsorId}
            />
          </>
        )}
      </Box>
      <Masonry {...masonryStyles}>
        <PayGroups
          isStateIRA={props.isStateIRA}
          isVoyaPlan={props.isVoyaPlan}
          sponsorId={+props.sponsorId}
          sponsorPlanId={props.sponsorPlanId}
        />
        <PlanUsersTable
          isStateIRA={props.isStateIRA}
          isVoyaPlan={props.isVoyaPlan}
          sponsorId={+props.sponsorId}
        />
      </Masonry>
    </>
  );
};

PlanCompanyTab.displayName = 'PlanCompanyTab';

PlanCompanyTab.defaultProps = {
  isEsa: false
};
