import { HEADERS_KEYS } from '../consts';

export const getInvalidHeaders = (
  contributionValidation
): {
  name: string;
  fileNames: string[];
  isRequired: boolean;
  isPresent: boolean;
  isDuplicated: boolean;
}[] => {
  const contributionsHeaders = [
    'rc',
    'sd',
    'sh',
    'ps',
    'em',
    'ln',
    'qm',
    'qc',
    'at',
    'loan_amt_1',
    'loan_amt_2',
    'loan_amt_3',
    'loan_amt_4',
    'loan_amt_5',
    'esa_ee_contribution'
  ];

  const hasContributionHeader = contributionValidation?.validationResults?.some(
    header => contributionsHeaders.includes(header.name) && header.isPresent
  );

  const invalidHeaders = contributionValidation?.validationResults?.filter(
    header => {
      if (header.isRequired && (!header.isPresent || header.isDuplicated))
        return header;

      if (
        !hasContributionHeader &&
        !header.isDuplicated &&
        contributionsHeaders.includes(header.name)
      ) {
        return header;
      }

      if (header.isDuplicated) return header;

      return null;
    }
  );

  if (invalidHeaders?.find(header => header.name === HEADERS_KEYS.SSN)) {
    const presentedExternalIdHeader =
      contributionValidation.validationResults.find(
        header =>
          header.name === HEADERS_KEYS.EXTERNAL_PAYROLL_ID && header.isPresent
      );
    return presentedExternalIdHeader
      ? invalidHeaders.filter(header => header.name !== HEADERS_KEYS.SSN)
      : invalidHeaders;
  }

  return invalidHeaders;
};
