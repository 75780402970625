import Badge from '@/components/badge';
import { CellComponentProps } from '@/components/collapsible-table';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import formatters from '@/utils/Formatters';
import { Box, TableCell, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';

import React from 'react';

const CashTransfersTableCell: React.FunctionComponent<CellComponentProps> = (
  props: CellComponentProps
) => {
  const { row, column } = props;
  let field = row[column.field];

  if (column.field === 'workflowStatus' || column.field === 'executionStatus') {
    field = row[column.field] && (
      <Badge color='neutral'>{row[column.field]}</Badge>
    );
  }

  if (column.field === 'amount') {
    field = (
      <Box minWidth={150} textAlign='right'>
        {formatters.formatDollars(row[column.field])}
      </Box>
    );
  }

  if (column.field === 'tracerId') {
    field = <Typography color={blue[500]}>{row.tracerId}</Typography>;
  }

  return (
    <TableCell component='th' scope='row'>
      <Box>{!field ? EMPTY_FIELD_PLACEHOLDER : field}</Box>
    </TableCell>
  );
};

export default CashTransfersTableCell;
