import { SponsorExemptionDto } from '@/models';
import SponsorService from '@/services/Sponsor.service';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

export default function useSponsorExemption<T = SponsorExemptionDto>(
  sponsorExemptionId: number | undefined,
  select?: (data: SponsorExemptionDto) => T
): UseQueryResult<T, unknown> {
  return useQuery(
    ['SponsorService.getSponsorExemption', sponsorExemptionId],
    () => SponsorService.getSponsorExemption(sponsorExemptionId),
    {
      enabled: Boolean(sponsorExemptionId),
      select
    }
  );
}
