import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class OriginalEffectivePlanDate {
  convertToApiState = Helper.transformDateToApiState;

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.output = Helper.transformDate(props.originalEffectivePlanDate);
  }
}

export default OriginalEffectivePlanDate;
