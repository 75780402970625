import {
  Card,
  CardContent,
  Divider,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';

import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useRef, useState } from 'react';

const SecurityDetailCell = (props: {
  gridRef: React.RefObject<AgGridReact<any>>;
  setRequestNoteCallback: (id: number, note?: string) => void;
  cellData: { data: any };
  numberOfPlans: number;
}): JSX.Element => {
  const { gridRef, setRequestNoteCallback, cellData, numberOfPlans } = props;
  const [inputValue, setInputValue] = useState(cellData.data.requestNote);
  const isNew = Array.from({ length: numberOfPlans }).some(
    (_, i) => cellData.data[`status${i}`] === 'New'
  );

  const resultsDivRef = useRef<any>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        resultsDivRef.current &&
        !resultsDivRef.current.contains(event.target)
      ) {
        if (isNew) {
          setRequestNoteCallback(cellData.data.id, inputValue);
        }

        gridRef.current?.api?.getRowNode(cellData.data.id)?.setExpanded(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [
    gridRef,
    resultsDivRef,
    cellData.data.id,
    setRequestNoteCallback,
    inputValue,
    isNew
  ]);

  return (
    <div ref={resultsDivRef}>
      {isNew ? (
        <Card elevation={0} sx={{ padding: theme => theme.spacing(2) }}>
          <CardContent
            sx={{
              '&:last-child': {
                paddingBottom: '0px'
              },
              marginBottom: 2,
              padding: 0
            }}>
            <TextField
              autoFocus
              label='Request Note'
              multiline
              onChange={e => setInputValue(e.target.value)}
              onFocus={e =>
                e.currentTarget.setSelectionRange(
                  e.currentTarget.value.length,
                  e.currentTarget.value.length
                )
              }
              rows={4}
              sx={{ width: '40%' }}
              value={inputValue}
              variant='outlined'
            />
          </CardContent>
        </Card>
      ) : (
        <Stack
          direction='row'
          divider={<Divider flexItem orientation='vertical' />}
          justifyContent='flex-start'
          spacing={2}
          sx={{ padding: theme => theme.spacing(2), width: '90%' }}>
          <Card elevation={0} sx={{ width: '100%' }}>
            <CardContent sx={{ padding: 0 }}>
              <Typography sx={{ color: grey[700] }} variant='body2'>
                Request Note
              </Typography>
              <Typography
                sx={{
                  marginTop: theme => theme.spacing(1),
                  whiteSpace: 'normal'
                }}
                variant='body1'>
                {cellData.data?.requestNote}
              </Typography>
              {!cellData.data?.requestNote && (
                <Typography
                  align='center'
                  sx={{ color: grey[700] }}
                  variant='body2'>
                  No request note
                </Typography>
              )}
            </CardContent>
          </Card>
        </Stack>
      )}
    </div>
  );
};

const SecurityDetailCellRenderer =
  (
    gridRef: React.RefObject<AgGridReact<any>>,
    setRequestNoteCallback: (id: number, note?: string) => void,
    numberOfPlans: number
  ) =>
  (cellData: { data: any }): JSX.Element => {
    return (
      <SecurityDetailCell
        cellData={cellData}
        gridRef={gridRef}
        numberOfPlans={numberOfPlans}
        setRequestNoteCallback={setRequestNoteCallback}
      />
    );
  };

export default SecurityDetailCellRenderer;
