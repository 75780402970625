import AccessControl from '@/components/access-control/AccessControl.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import InvestmentService from '@/services/Investment.service';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import {
  Button,
  Unstable_Grid2 as Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { ICellRendererParams } from 'ag-grid-community';
import { FC, useCallback, useState } from 'react';
import { useToggle } from 'react-use';

import { ApproveRejectModal } from './ApproveRejectModal.component';

const StyledDetail = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  padding: theme.spacing(2),
  paddingLeft: theme.spacing(7)
}));

type FundChangeHistoryDetailProps = {
  cellParams: ICellRendererParams;
  onSubmitFundChanges: () => void;
};

export const FundChangeHistoryDetail: FC<
  FundChangeHistoryDetailProps
> = props => {
  const snackbar = useSnackbar();
  const [isModalOpen, toggleModal] = useToggle(false);
  const [isSubmitting, toggleSubmitting] = useToggle(false);
  const [modalView, setModalView] = useState<'approve' | 'reject'>('reject');
  const isAnotherApprover =
    userService.getUser()?.nickname !==
    props.cellParams.data.approveInfo?.secondApproval?.id;

  const onSubmit = async () => {
    toggleSubmitting();
    try {
      await InvestmentService.putFundChangeApproval(
        props.cellParams.data.fundChangeId,
        modalView === 'approve'
      );
      snackbar.showSnackbar({
        message: `${
          modalView === 'reject' ? 'Rejected' : 'Approved & submitted'
        } fund change request (${props.cellParams.data.oldFund.symbol} → ${
          props.cellParams.data.newFund.symbol
        })`,
        severity: 'success'
      });
      props.onSubmitFundChanges();
    } catch (error) {
      snackbar.showSnackbar({
        message: `Couldn't ${
          modalView === 'reject' ? 'reject' : 'approve'
        } fund change request (${props.cellParams.data.oldFund.symbol} → ${
          props.cellParams.data.newFund.symbol
        }). The request might had already been submitted, or removed`,
        severity: 'error'
      });
    }
    toggleSubmitting();
    toggleModal();
  };

  const onRejectClick = useCallback(() => {
    toggleModal();
    setModalView('reject');
  }, []);

  const onApproveClick = useCallback(() => {
    toggleModal();
    setModalView('approve');
  }, []);

  return (
    <>
      <StyledDetail>
        <Typography mb={1} variant='body1'>
          Submitted by {props.cellParams.data.approveInfo?.secondApproval?.id}{' '}
          at{' '}
          {formatters.formatFromIsoDateCustom(
            props.cellParams.data.approveInfo?.createdAt,
            'MM/DD/YYYY HH:mm'
          )}
        </Typography>
        {props.cellParams.data.approveInfo?.managerApproval && (
          <Typography mb={1} variant='body1'>
            {props.cellParams.data.approveInfo.approved
              ? 'Approved'
              : 'Rejected'}{' '}
            by {props.cellParams.data.approveInfo?.managerApproval?.id} at{' '}
            {formatters.formatFromIsoDateCustom(
              props.cellParams.data.approveInfo?.managerApprovedAt,
              'MM/DD/YYYY HH:mm'
            )}
          </Typography>
        )}
        <Grid container>
          <Grid lg={4}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Plan</TableCell>
                  <TableCell>Plan Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.cellParams.data.plansAffected?.map(plan => {
                  return (
                    <TableRow key={plan.sponsorPlanId}>
                      <TableCell>
                        <Link
                          href={`/plans/${plan.sponsorPlanId}`}
                          target='_blank'>
                          {plan.sponsorPlanId}
                        </Link>
                      </TableCell>
                      <TableCell>{plan.sponsorPlanName}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        {!props.cellParams.data.approveInfo?.managerApproval &&
          isAnotherApprover && (
            <AccessControl
              requires={[
                FeatureLevelPermissions.WRITE_INVESTMENTS_FUND_CHANGE_APPROVE
              ]}>
              <Button
                color='error'
                data-testid='reject-button'
                onClick={onRejectClick}
                startIcon={<RemoveCircleOutlineOutlinedIcon />}
                variant='text'>
                Reject
              </Button>
              <Button
                data-testid='approve-button'
                onClick={onApproveClick}
                startIcon={<CheckCircleOutlinedIcon />}
                variant='text'>
                Approve & Submit
              </Button>
            </AccessControl>
          )}
      </StyledDetail>
      <ApproveRejectModal
        data={props.cellParams.data}
        handleSubmit={onSubmit}
        isModalOpen={isModalOpen}
        isSubmitting={isSubmitting}
        toggleModal={toggleModal}
        view={modalView}
      />
    </>
  );
};

FundChangeHistoryDetail.displayName = 'FundChangeHistoryDetail';
