import formatters from '@/utils/Formatters';
import { TextField } from '@mui/material';

import { FC, forwardRef, useCallback } from 'react';
import { NumericFormat } from 'react-number-format';

const Currency = forwardRef((props, ref) => (
  <NumericFormat
    {...props}
    allowLeadingZeros
    allowNegative={false}
    decimalScale={2}
    defaultValue={0}
    getInputRef={ref}
    prefix='$'
    thousandSeparator
    valueIsNumericString
  />
));

type CurrencyFieldProps = {
  value: number;
  onChange: (e: any) => void;
  error: boolean;
  helperText: string;
  disabled: boolean;
};

export const CurrencyField: FC<CurrencyFieldProps> = props => {
  const onChange = useCallback(
    data => props.onChange(formatters.unformatCurrency(data.target.value)),
    [props.onChange]
  );

  return (
    <TextField
      InputProps={{
        inputComponent: Currency
      }}
      disabled={props.disabled}
      error={props.error}
      helperText={props.helperText}
      onChange={onChange}
      value={props.value}
    />
  );
};
