import { Fade } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import React from 'react';

interface LinearLoadingProps {
  fadeIn?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > * + *': {
        marginTop: theme.spacing(2)
      },
      width: '100%'
    }
  })
);

const LinearLoading: React.FunctionComponent<LinearLoadingProps> = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Fade in={props.fadeIn !== undefined ? props.fadeIn : true}>
        <LinearProgress />
      </Fade>
    </div>
  );
};

export default LinearLoading;
