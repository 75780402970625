export const TargetSeriesFields = {
  EXPENSE_RATIO: 'expenseRatio' as const,
  MODEL_NAME: 'modelName' as const,
  SECURITY: 'security' as const,
  TARGET_YEARS: 'targetYears' as const,
  TOTAL_ALLOCATION: 'totalAllocation' as const
};

export interface TargetSeriesRow {
  maxTickerLength: number;
  id: string;
  [TargetSeriesFields.TARGET_YEARS]: string;
  [TargetSeriesFields.MODEL_NAME]: string;
  [TargetSeriesFields.SECURITY]: {
    id: string;
    cusip: string;
    fundName: string;
  };
  [TargetSeriesFields.TOTAL_ALLOCATION]: { [index: string]: number };
  [TargetSeriesFields.EXPENSE_RATIO]: string;
}
