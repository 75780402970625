export enum TransactionSortKey {
  accountId = 'accountId',
  accountLevel = 'accountLevel',
  amount = 'amount',
  balanceType = 'balanceType',
  cashInterestActivityId = 'cashInterestActivityId',
  confirmDate = 'confirmDate',
  contributionYear = 'contributionYear',
  controlDate = 'controlDate',
  correctionsTracerId = 'correctionsTracerId',
  createdAt = 'createdAt',
  cusip = 'cusip',
  dividendActivityId = 'dividendActivityId',
  dividendEligibleDate = 'dividendEligibleDate',
  dividendId = 'dividendId',
  fee = 'fee',
  id = 'id',
  impliedPrice = 'impliedPrice',
  orderId = 'orderId',
  reversedTransactionId = 'reversedTransactionId',
  securityOrderType = 'securityOrderType',
  securityUnitPrice = 'securityUnitPrice',
  settlementDate = 'settlementDate',
  sourceTransactionId = 'sourceTransactionId',
  tracerId = 'tracerId',
  tradeDate = 'tradeDate',
  transactionBaseType = 'transactionBaseType',
  transactionTypeCode = 'transactionTypeCode',
  transferSubAccountId = 'transferSubAccountId',
  uniqueTransactionId = 'uniqueTransactionId',
  units = 'units',
  updatedAt = 'updatedAt'
}
