import Badge from '@/components/badge';
import { CellComponentProps } from '@/components/collapsible-table';
import formatters from '@/utils/Formatters';
import { Box, Link, TableCell, Typography } from '@mui/material';

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const LoansTableCell: React.FunctionComponent<CellComponentProps> = (
  props: CellComponentProps
) => {
  const { row, column } = props;

  let field = <Box>{row[column.field] || '--'}</Box>;
  if (column.field === 'id') {
    field = (
      <Box sx={{ fontSize: theme => theme.spacing(2) }}>
        <Link
          component={RouterLink}
          to={`/participant/${row.participantId}/loans/${row.id}`}>
          {row[column.field]}
        </Link>
      </Box>
    );
  }
  if (
    column.field === 'createdAt' ||
    column.field === 'firstPaymentDueDate' ||
    column.field === 'startDate'
  ) {
    field = (
      <Box>
        {formatters.formatFromIsoDateCustom(row[column.field], 'M/DD/YY')}
      </Box>
    );
  }
  if (
    column.field === 'originalAmount' ||
    column.field === 'outstandingBalance'
  ) {
    field = <Box>{formatters.formatDollars(row[column.field])}</Box>;
  }
  if (column.field === 'status')
    field = (
      <Badge
        color={row?.status === 'Completed' ? 'success' : 'neutral'}
        size='small'>
        {row?.status}
      </Badge>
    );
  if (column.field === 'participant')
    field = (
      <>
        <Typography variant='body1'>
          {row?.participantFirstName} {row?.participantLastName}
        </Typography>
        <Typography variant='body2'>ID: {row?.participantId}</Typography>
      </>
    );

  return (
    <TableCell component='th' scope='row'>
      <Box>{field}</Box>
    </TableCell>
  );
};

export default LoansTableCell;
