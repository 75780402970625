import { alpha, Link, Theme } from '@mui/material';

import { CellClassParams, CellStyleFunc, ColGroupDef } from 'ag-grid-community';
import { createElement } from 'react';

export const getSpreadSheetColumns = (theme: Theme): ColGroupDef[] => {
  const cellStyle = (params: CellClassParams) => {
    const styles = ['sd', 'sh', 'ln'].includes(params.colDef?.field ?? '')
      ? { borderLeft: `1px solid ${theme.palette.grey[300]}` }
      : {};

    if (
      params.context?.errors?.[`[${params.data?.uuid}].${params.colDef.field}`]
    ) {
      return {
        ...styles,
        backgroundColor: alpha(theme.palette.error.light, 0.1),
        color: theme.palette.error.main
      };
    }

    if (
      params.context?.initialRows?.[params.data?.uuid]?.[
        params.colDef?.field ?? ''
      ] !== params.node?.data?.[params.colDef?.field ?? '']
    ) {
      return {
        ...styles,
        backgroundColor: alpha(theme.palette.error.light, 0.1)
      };
    }

    return styles;
  };

  return [
    {
      children: [
        {
          cellRenderer: (data: {
            value: string;
            data: { participantId: number };
          }) =>
            createElement(
              Link,
              {
                href: `/participants/${data.data?.participantId}/personal`,
                target: '_blank'
              },
              data.value
            ),
          checkboxSelection: true,
          field: 'employee',
          flex: 2,
          headerName: 'Employee',
          resizable: true,
          sortable: true,
          valueGetter: (params: {
            data: { lastName: string; firstName: string };
          }) => `${params.data?.lastName}, ${params.data?.firstName}`
        }
      ]
    },
    {
      children: [
        {
          cellClass: 'ag-right-aligned-cell',
          cellStyle: cellStyle as CellStyleFunc,
          editable: true,
          field: 'sd',
          flex: 1,
          headerClass: 'ag-right-aligned-header',
          headerName: 'Pre-Tax',
          resizable: true,
          type: 'currency'
        },
        {
          cellClass: 'ag-right-aligned-cell',
          cellStyle: cellStyle as CellStyleFunc,
          editable: true,
          field: 'rc',
          flex: 1,
          headerClass: 'ag-right-aligned-header',
          headerName: 'Roth',
          resizable: true,
          type: 'currency'
        },
        {
          cellClass: 'ag-right-aligned-cell',
          cellStyle: cellStyle as CellStyleFunc,
          editable: true,
          field: 'at',
          flex: 1,
          headerClass: 'ag-right-aligned-header',
          headerName: 'After-Tax',
          resizable: true,
          type: 'currency'
        }
      ],
      headerClass: 'ag-right-aligned-header',
      headerName: 'Employee Contributions'
    },
    {
      children: [
        {
          cellClass: 'ag-right-aligned-cell',
          cellStyle: cellStyle as CellStyleFunc,
          editable: true,
          field: 'sh',
          flex: 1,
          headerClass: 'ag-right-aligned-header',
          headerName: 'S. Harbor',
          resizable: true,
          type: 'currency'
        },
        {
          cellClass: 'ag-right-aligned-cell',
          cellStyle: cellStyle as CellStyleFunc,
          editable: true,
          field: 'em',
          flex: 1,
          headerClass: 'ag-right-aligned-header',
          headerName: 'D. Match',
          resizable: true,
          type: 'currency'
        },
        {
          cellClass: 'ag-right-aligned-cell',
          cellStyle: cellStyle as CellStyleFunc,
          editable: true,
          field: 'ps',
          flex: 1,
          headerClass: 'ag-right-aligned-header',
          headerName: 'P. Sharing',
          resizable: true,
          type: 'currency'
        },
        {
          cellClass: 'ag-right-aligned-cell',
          cellStyle: cellStyle as CellStyleFunc,
          editable: true,
          field: 'qc',
          flex: 1,
          headerClass: 'ag-right-aligned-header',
          headerName: 'QNEC',
          type: 'currency'
        },
        {
          cellClass: 'ag-right-aligned-cell',
          cellStyle: cellStyle as CellStyleFunc,
          editable: true,
          field: 'qm',
          flex: 1,
          headerClass: 'ag-right-aligned-header',
          headerName: 'QMAC',
          type: 'currency'
        }
      ],
      headerName: 'Employer Contributions'
    },
    {
      children: [
        {
          cellClass: 'ag-right-aligned-cell',
          cellStyle: cellStyle as CellStyleFunc,
          editable: true,
          field: 'ln',
          flex: 1,
          headerClass: 'ag-right-aligned-header',
          headerName: 'Loan',
          type: 'currency'
        }
      ]
    }
  ];
};
