import AccessControl from '@/components/access-control/AccessControl.component';
import { CellComponentProps } from '@/components/collapsible-table';
import DocumentDownloadButton from '@/components/document-download-button';
import { DocumentMetadataDtoV2 } from '@/models/DocumentMetadataDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { CheckCircle, DeleteOutlineOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Modal,
  Paper,
  TableCell,
  Theme,
  Tooltip,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

import React, { useMemo } from 'react';
import { useToggle } from 'react-use';

import DeleteDocDialog from '../delete_doc/DeleteDocDialog.component';
import { HistoryFields } from './DocUploadHistoryDialog.component';

const useStyles = makeStyles((theme: Theme) => ({
  buttonIcon: {
    '& .MuiButton-startIcon': {
      margin: 0
    },
    '&:hover': {
      color: theme.palette.primary.main
    },
    color: grey[500],
    justifyContent: 'flex-start',
    minWidth: 'auto'
  },
  checkIcon: {
    fill: theme.palette.success.main
  },
  modal: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  paper: {
    height: theme.spacing(23),
    width: theme.spacing(70)
  },
  rootCell: {
    fontSize: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  }
}));

interface DocUploadHistRow extends DocumentMetadataDtoV2 {
  isLatest: boolean;
  isHighlighted: boolean;
  groupId: number;
  categoryId: number;
}

interface CellProps {
  row: DocUploadHistRow;
}

const FileNameCell: React.FC<CellProps> = (props: CellProps) => {
  const classes = useStyles();

  return (
    <Box alignItems='center' display='flex' gap={1.2}>
      <Typography
        sx={
          props.row.isHighlighted
            ? { fontStyle: 'italic', fontWeight: 500 }
            : {}
        }
        variant='body1'>
        {props.row.originalFileName}
      </Typography>
      {props.row.isLatest && (
        <CheckCircle
          className={classes.checkIcon}
          data-testid={`document-latest-mark-document-id-${props.row.id}`}
        />
      )}
    </Box>
  );
};

const PositionCell: React.FC<CellProps> = (props: CellProps) => {
  const classes = useStyles();
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useToggle(false);

  return (
    <Box alignItems='center' display='flex' gap={0.5}>
      <DocumentDownloadButton docV2={props.row} iconOnly />
      <AccessControl
        requires={[FeatureLevelPermissions.WRITE_DOCUMENTS_DELETE]}>
        <Tooltip title={`Click to delete ${props.row.originalFileName}`}>
          <Button
            className={classes.buttonIcon}
            data-testid={`document-delete-button-document-id-${props.row.id}`}
            onClick={() => {
              setDeleteDialogOpen(true);
            }}
            size='large'
            startIcon={<DeleteOutlineOutlined />}
          />
        </Tooltip>
        <Modal
          className={classes.modal}
          id='delete-doc-dialog-modal'
          onClose={() => {
            setDeleteDialogOpen(false);
          }}
          open={isDeleteDialogOpen}>
          <Paper className={classes.paper}>
            <DeleteDocDialog
              categoryId={props.row.categoryId}
              docId={props.row.id}
              documentKey={props.row.documentKey}
              groupId={props.row.groupId}
              onClose={setDeleteDialogOpen}
              planId={+props.row.entityId}
            />
          </Paper>
        </Modal>
      </AccessControl>
    </Box>
  );
};

const DocUploadHistoryCell: React.FC<CellComponentProps> = (
  props: CellComponentProps
) => {
  const { row, column } = props;
  const classes = useStyles();

  const CellComponent = useMemo(() => {
    switch (column.field) {
      case HistoryFields.ORIGINAL_FILE_NAME:
        return <FileNameCell row={row} />;
      case HistoryFields.ACTIONS:
        return <PositionCell row={row} />;
      default:
        return <Typography variant='body1'>{row[column.field]}</Typography>;
    }
  }, [column, row]);

  return (
    <TableCell className={classes.rootCell} component='th' scope='row'>
      {CellComponent}
    </TableCell>
  );
};

export default DocUploadHistoryCell;
