import Badge from '@/components/badge';
import { DataGridFilters } from '@/components/data-grid-filters/DataGridFilters.component';
import DatePickerForm from '@/components/date-picker/DatePickerForm';
import {
  allEventTypeNames,
  allInitiatorTypes,
  AuditLogsFilters,
  initialAuditLogsFilters,
  TableTriggeredFilters
} from '@/models/AuditLogs.model';
import { Search } from '@mui/icons-material';
import CachedIcon from '@mui/icons-material/Cached';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography
} from '@mui/material';

import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import { isEqual } from 'lodash';
import React, { useCallback } from 'react';

type PlanAuditLogsProps = {
  updateFilters: (filters: AuditLogsFilters) => void;
  filters: AuditLogsFilters;
  tableTriggeredFilters: TableTriggeredFilters;
  setTableTriggeredFilters: (
    tableTriggeredFilters: Partial<TableTriggeredFilters>
  ) => void;
  planId: number;
};

export const PlanAuditLogsFilters: React.FC<PlanAuditLogsProps> = props => {
  const handleSubmit = useCallback(
    (values: AuditLogsFilters) => {
      props.updateFilters({
        ...values,
        ...props.tableTriggeredFilters,
        endDate: values.endDate
          ? dayjs(values.endDate).add(1, 'day').toISOString()
          : null,
        startDate: values.startDate
          ? dayjs(values.startDate).toISOString()
          : null
      });
    },
    [props.updateFilters, props.tableTriggeredFilters]
  );

  return (
    <DataGridFilters>
      <Formik initialValues={initialAuditLogsFilters} onSubmit={handleSubmit}>
        {formikProps => {
          const areFiltersDefault = isEqual(
            { ...formikProps.values, ...props.tableTriggeredFilters },
            initialAuditLogsFilters
          );

          return (
            <Form>
              <Stack gap={2} width={350}>
                <FormControl fullWidth>
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Search />
                        </InputAdornment>
                      )
                    }}
                    onChange={e =>
                      props.setTableTriggeredFilters({
                        search: e.target.value
                      })
                    }
                    placeholder='Find in Context'
                    size='small'
                    value={props.tableTriggeredFilters.search}
                  />
                </FormControl>

                <Autocomplete
                  fullWidth
                  multiple
                  onChange={(_event, value) =>
                    props.setTableTriggeredFilters({
                      eventTypeNames: value
                    })
                  }
                  options={allEventTypeNames}
                  renderInput={params => (
                    <TextField {...params} label='Action' />
                  )}
                  renderOption={(rootProps, option, { selected }) => (
                    <MenuItem
                      {...rootProps}
                      data-value={option}
                      style={{ whiteSpace: 'normal' }}>
                      <Checkbox checked={selected} sx={{ py: 0 }} />
                      {option}
                    </MenuItem>
                  )}
                  renderTags={tagValue =>
                    tagValue.length === allEventTypeNames.length ? (
                      <Typography component='div' ml={1}>
                        All
                      </Typography>
                    ) : (
                      tagValue.map(option => (
                        <Box key={option} mb={0.5} mr={0.5}>
                          <Badge
                            deleteable
                            handleClick={() =>
                              props.setTableTriggeredFilters({
                                eventTypeNames:
                                  formikProps.values.eventTypeNames.filter(
                                    v => v !== option
                                  )
                              })
                            }>
                            {option}
                          </Badge>
                        </Box>
                      ))
                    )
                  }
                  size='small'
                  value={props.tableTriggeredFilters.eventTypeNames}
                />

                <Autocomplete
                  data-testid='auditlogs-initiatorTypes-filter'
                  fullWidth
                  multiple
                  onChange={(_event, value) =>
                    formikProps.setFieldValue('initiatorTypes', value)
                  }
                  options={allInitiatorTypes}
                  renderInput={params => (
                    <TextField {...params} label='Initiator type' />
                  )}
                  renderOption={(rootProps, option, { selected }) => (
                    <MenuItem
                      {...rootProps}
                      data-value={option}
                      style={{ whiteSpace: 'normal' }}>
                      <Checkbox checked={selected} sx={{ py: 0 }} />
                      {option}
                    </MenuItem>
                  )}
                  renderTags={tagValue =>
                    tagValue.length === allInitiatorTypes.length ? (
                      <Typography component='div' ml={1}>
                        All
                      </Typography>
                    ) : (
                      tagValue.map(option => (
                        <Box key={option} mb={0.5} mr={0.5}>
                          <Badge
                            deleteable
                            handleClick={() =>
                              formikProps.setFieldValue(
                                'initiatorTypes',
                                formikProps.values.initiatorTypes.filter(
                                  v => v !== option
                                )
                              )
                            }>
                            {option}
                          </Badge>
                        </Box>
                      ))
                    )
                  }
                  size='small'
                  value={formikProps.values.initiatorTypes}
                />

                <DatePickerForm
                  format='MM/DD/YYYY'
                  fullWidth
                  label='From'
                  maxDate={
                    formikProps.values.endDate
                      ? dayjs(formikProps.values.endDate)
                      : dayjs()
                  }
                  minDate={dayjs().subtract(3, 'months')}
                  name='startDate'
                  onChange={event =>
                    formikProps.setFieldValue(
                      'startDate',
                      dayjs(event.target.value).format(
                        'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
                      )
                    )
                  }
                  size='small'
                  value={formikProps.values.startDate}
                  variant='outlined'
                />

                <DatePickerForm
                  format='MM/DD/YYYY'
                  fullWidth
                  label='To'
                  maxDate={
                    formikProps.values.startDate &&
                    !dayjs(formikProps.values.startDate)
                      .add(3, 'months')
                      .isAfter(dayjs())
                      ? dayjs(formikProps.values.startDate).add(3, 'months')
                      : dayjs()
                  }
                  minDate={
                    formikProps.values.startDate
                      ? dayjs(formikProps.values.startDate)
                      : dayjs().subtract(3, 'months')
                  }
                  name='endDate'
                  onChange={event =>
                    formikProps.setFieldValue(
                      'endDate',
                      dayjs(event.target.value).format(
                        'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
                      )
                    )
                  }
                  size='small'
                  value={formikProps.values.endDate}
                  variant='outlined'
                />

                <Stack flexDirection='row' justifyContent='space-between'>
                  <Button
                    disabled={!formikProps.isValid}
                    type='submit'
                    variant='outlined'>
                    Apply
                  </Button>
                  {!areFiltersDefault && (
                    <Button
                      data-testid='reset-btn'
                      onClick={() => {
                        formikProps.resetForm();
                        props.setTableTriggeredFilters({
                          eventTypeNames:
                            initialAuditLogsFilters.eventTypeNames,
                          search: initialAuditLogsFilters.search
                        });
                        props.updateFilters(initialAuditLogsFilters);
                      }}
                      startIcon={<CachedIcon />}
                      variant='text'>
                      Reset
                    </Button>
                  )}
                </Stack>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </DataGridFilters>
  );
};
