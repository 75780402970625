import MenuButton from '@/components/menu-button/MenuButton.component';
import NavigationBreadcrumbs from '@/components/navigation-breadcrumbs';
import ListInvestments from '@/routes/ops/investments/ListInvestments.component';
import ModelModal from '@/routes/ops/investments/ModelModal.component';
import ProgramModal from '@/routes/ops/investments/programs/ProgramModal.component';
import MenuItem from '@mui/material/MenuItem';

import React from 'react';
import { useToggle } from 'react-use';

interface CreateInvestmentMenuProps {
  onModelCreate: () => void;
  onProgramCreate: () => void;
}

const CreateInvestmentMenu = (
  props: CreateInvestmentMenuProps
): JSX.Element => {
  const { onModelCreate, onProgramCreate } = props;
  return (
    <MenuButton>
      <MenuItem
        data-testid='programMenuItem'
        disableRipple
        onClick={onProgramCreate}>
        Program
      </MenuItem>
      <MenuItem
        data-testid='investmentTypesMenuItem'
        disableRipple
        onClick={onModelCreate}>
        Investment Types
      </MenuItem>
    </MenuButton>
  );
};

const InvestmentDetailRoute = (): JSX.Element => {
  const [isModelModalOpen, toggleModelModal] = useToggle(false);
  const [isProgramModalOpen, toggleProgramModal] = useToggle(false);

  const paths = [
    {
      name: 'Ops',
      to: '/ops/investments'
    },
    {
      name: 'Investments',
      to: '/ops/investments'
    }
  ];

  return (
    <>
      <ModelModal onClose={toggleModelModal} open={isModelModalOpen} />
      <ProgramModal onClose={toggleProgramModal} open={isProgramModalOpen} />
      <NavigationBreadcrumbs paths={paths} />
      <h1>Investments</h1>
      <CreateInvestmentMenu
        onModelCreate={toggleModelModal}
        onProgramCreate={toggleProgramModal}
      />
      <ListInvestments />
    </>
  );
};

export default InvestmentDetailRoute;
