import {
  GlobalSearchParticipantDto,
  GlobalSearchPlanDto
} from '@/models/GlobalSearchDTO.model';

export const findMatches = (search: string, result: string): boolean => {
  if (!result) {
    return false;
  }
  const splittedResult = result
    .toLowerCase()
    .replace(/[^\w\s]/gi, ' ')
    .split(' ');
  const splittedSearch = search
    .toLowerCase()
    .trim()
    .replace(/[^\w\s]/gi, ' ')
    .split(' ');

  let i = 0;

  while (i !== splittedSearch.length) {
    const searchValue = splittedSearch[i];
    if (
      splittedResult.some((resultValue: string) =>
        resultValue.includes(searchValue)
      )
    ) {
      const searchMatchIndex = splittedResult.findIndex((resultValue: string) =>
        resultValue.includes(searchValue)
      );
      splittedResult.splice(searchMatchIndex, 1);
      i += 1;
    } else {
      return false;
    }
  }
  return true;
};

const matchPhoneNumber = (search: string, result: string): boolean => {
  const flattenedSearch = search.replace(/\D/g, '');
  const flattenedResult = result.replace(/\D/g, '');
  // check substring match
  if (flattenedResult.includes(flattenedSearch)) {
    return true;
  }

  const splitSearch = search.replace(/\D/g, ' ').split(' ');
  const foundMatch = splitSearch.find(digits =>
    flattenedResult.includes(digits)
  );

  return !!foundMatch;
};

export const createPlanDynamicField = (
  search: string,
  planItem: GlobalSearchPlanDto
): { key: string; value: string } => {
  // check if the search result in static fields -
  // no need to check for dyncamic field
  if (
    planItem &&
    !planItem.sponsorPlanId?.toString().includes(search) &&
    !findMatches(search, planItem.planName) &&
    !planItem.companyName?.includes(search)
  ) {
    const isStateRecord = !!planItem.externalId;
    const trimmedQuery = search && search.trim();

    if (
      planItem.employerIdentificationNumber &&
      search.replace(/\D/g, '') !== '' &&
      planItem.employerIdentificationNumber
        .replace(/\D/g, '')
        .includes(search.replace(/\D/g, ''))
    ) {
      return {
        key: 'Employer Identification Number',
        value: planItem.employerIdentificationNumber
      };
    }
    if (
      planItem.externalId &&
      search.replace(/\D\W/g, '') !== '' &&
      planItem.externalId
        .replace(/\D\W/g, '')
        .includes(search.replace(/\D\W/g, ''))
    ) {
      return {
        key: 'Surpas Number',
        value: planItem.externalId
      };
    }
    if (planItem.primaryContactEmail?.includes(trimmedQuery)) {
      return {
        key: 'Primary Contact Email',
        value: planItem.primaryContactEmail
      };
    }
    if (planItem.userEmails?.find(email => email.includes(trimmedQuery))) {
      return {
        key: 'User Email',
        value:
          planItem.userEmails.find(email => email.includes(trimmedQuery)) || ''
      };
    }
    if (
      planItem.userInviteEmails?.find(email => email.includes(trimmedQuery))
    ) {
      return {
        key: 'User Invite Email',
        value:
          planItem.userInviteEmails.find(email =>
            email.includes(trimmedQuery)
          ) || ''
      };
    }
    if (
      planItem.doingBusinessAs &&
      findMatches(search, planItem.doingBusinessAs)
    ) {
      return { key: 'Doing Business As', value: planItem.doingBusinessAs };
    }
    if (
      planItem.sponsorId &&
      search.replace(/\D/g, '') !== '' &&
      planItem.sponsorId.toString().includes(search.replace(/\D/g, ''))
    ) {
      return {
        key: isStateRecord ? 'Employer Id' : 'Sponsor Id',
        value: planItem.sponsorId.toString()
      };
    }
    if (planItem.inviteCodes.length) {
      const inviteCodeMatch = planItem.inviteCodes.find(c =>
        c.includes(trimmedQuery)
      );
      if (inviteCodeMatch) {
        return {
          key: 'Invite/Access Code',
          value: inviteCodeMatch
        };
      }
    }
    if (
      planItem.companyPhoneNumber &&
      search.replace(/\D/g, '') !== '' &&
      matchPhoneNumber(search, planItem.companyPhoneNumber)
    ) {
      return {
        key: 'Company Phone Number',
        value: planItem.companyPhoneNumber
      };
    }
  }
  return { key: '', value: '' };
};

export const createParticipantDynamicField = (
  search: string,
  participantItem: GlobalSearchParticipantDto
): { key: string; value: string } => {
  const trimmedQuery = search && search.trim();
  // check if the search result in static fields -
  // no need to check for dyncamic field
  if (
    participantItem &&
    !participantItem.firstName?.includes(search) &&
    !participantItem.lastName?.includes(search) &&
    participantItem.planName &&
    !findMatches(search, participantItem.planName) &&
    !participantItem.participantId.toString().includes(search)
  ) {
    if (
      participantItem.ssn &&
      search.replace(/\D\W/g, '') !== '' &&
      participantItem.ssn.includes(search.substring(search.length - 4))
    ) {
      return { key: 'SSN', value: participantItem.ssn };
    }
    if (
      participantItem.externalId &&
      search.replace(/\D\W/g, '') !== '' &&
      participantItem.externalId
        .replace(/\D\W/g, '')
        .includes(search.replace(/\D\W/g, ''))
    ) {
      return {
        key: 'Surpas Number',
        value: participantItem.externalId
      };
    }

    if (participantItem.workEmail?.includes(trimmedQuery)) {
      return { key: 'Work Email', value: participantItem.workEmail };
    }
    if (participantItem.personalEmail?.includes(trimmedQuery)) {
      return { key: 'Personal Email', value: participantItem.personalEmail };
    }
    if (
      participantItem.userEmails?.find(email => email.includes(trimmedQuery))
    ) {
      return {
        key: 'User Email',
        value:
          participantItem.userEmails.find(email =>
            email.includes(trimmedQuery)
          ) || ''
      };
    }
    if (
      participantItem.phone &&
      search.replace(/\D/g, '') !== '' &&
      matchPhoneNumber(search, participantItem.phone)
    ) {
      return { key: 'Phone', value: participantItem.phone };
    }
  }
  return { key: '', value: '' };
};
