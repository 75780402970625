import Badge from '@/components/badge';
import CircularLoading from '@/components/circular-loading';
import { useSnackbar } from '@/contexts/SnackBarContext';
import {
  ProgramConverter,
  ProgramPlan
} from '@/models/ops/investments/Program.model';
import { ProgramPlansListDto } from '@/services/ops/investments/Program.service';
import { PlanService } from '@/services/Plan.service';
import { Close, Search } from '@mui/icons-material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  InputAdornment,
  ListItem,
  Paper,
  Stack,
  TextField,
  Theme,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';

import clsx from 'clsx';
import { useFormik } from 'formik';
import { identity, isEqual, isString } from 'lodash';
import React, { useState } from 'react';

const itemHeight = (theme: Theme) => theme.spacing(5);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    autocompleteWidth: {
      width: theme.spacing(65)
    },
    clearUser: {
      color: theme.palette.text.secondary,
      cursor: 'pointer',
      marginLeft: 'auto',
      marginRight: theme.spacing(1)
    },
    header: {
      fontSize: theme.spacing(3)
    },
    idField: {
      color: theme.palette.text.secondary,
      marginLeft: theme.spacing(2)
    },
    inputIcon: {
      paddingRight: theme.spacing(1)
    },
    nameField: {
      marginLeft: theme.spacing(1)
    },
    paper: {
      marginBottom: theme.spacing(2),
      width: '100%'
    },
    planInfoInput: {
      backgroundColor: '#FFFFFF',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: 4,
      height: itemHeight(theme),
      paddingTop: theme.spacing(0.75)
    },
    saveButton: { height: itemHeight(theme) },
    section: {
      marginBottom: theme.spacing(3),
      marginLeft: theme.spacing(3),
      marginTop: theme.spacing(3)
    },
    statusField: {
      marginLeft: theme.spacing(1),
      whiteSpace: 'nowrap'
    }
  })
);

export interface ProgramDetailsAddPlanProps {
  programId: number;
  setPlanId: (planId: number) => void;
}

const ProgramDetailsAddPlan = (
  props: ProgramDetailsAddPlanProps
): JSX.Element => {
  const { programId, setPlanId } = props;
  const classes = useStyles();
  const theme = useTheme();
  const snackbar = useSnackbar();

  const [query, setQuery] = useState('');

  const programPlansQuery = useQuery<ProgramPlansListDto>(
    ['PlanService.getNullProgramPlans', programId, query],
    async () => {
      return PlanService.getNullProgramPlans(query);
    },
    {
      enabled: Boolean(query.trim().length)
    }
  );

  const programPlansData = programPlansQuery.data?.data || [];
  const options = programPlansData.map(plan =>
    ProgramConverter.toProgramPlan(plan)
  );

  let noOptionsText = 'Type to search';
  const hasResults = options.length > 0;
  const hasQuery = query.trim().length > 0;

  if (hasQuery && !hasResults) {
    if (programPlansQuery.isSuccess) {
      noOptionsText = `No results found for "${query}"`;
    } else if (programPlansQuery.isError) {
      noOptionsText = `Encountered an error searching for "${query}"`;
    } else if (!programPlansQuery.isFetching) {
      noOptionsText = 'Type to search';
    } else if (programPlansQuery.isFetching) {
      noOptionsText = 'Searching...';
    }
  }

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {} as ProgramPlan,
    onSubmit: async values => {
      try {
        await PlanService.updatePlanProgram(values.id, programId);
        snackbar.showSnackbar({
          message: 'Program added to Plan',
          severity: 'success'
        });
        setPlanId(values.id);
        form.resetForm({});
      } catch (error) {
        snackbar.showSnackbar({
          message: `Failed to added Program to Plan: ${error}`,
          severity: 'error'
        });
      }
    }
  });

  return (
    <>
      <Paper className={classes.paper} elevation={0} variant='outlined'>
        <div className={classes.section}>
          <Typography className={classes.header} variant='h5'>
            Add Plan to Program
          </Typography>
        </div>
        <Divider />
        <div className={classes.section}>
          <Stack direction='row' spacing={3}>
            {Boolean(form.values.id) && (
              <Paper
                className={clsx(
                  classes.planInfoInput,
                  classes.autocompleteWidth
                )}
                variant='outlined'>
                <Stack direction='row'>
                  <Typography className={classes.idField}>
                    {form.values.id}
                  </Typography>
                  <Typography className={classes.nameField} noWrap>
                    {form.values.name}
                  </Typography>
                  <Box className={classes.statusField}>
                    <Badge color='success' size='small'>
                      {form.values.status}
                    </Badge>
                  </Box>
                  <Box className={classes.clearUser}>
                    {form.isSubmitting && <CircularLoading />}
                    {!form.isSubmitting && (
                      <Close
                        onClick={() => {
                          setQuery('');
                          form.setValues({} as ProgramPlan);
                        }}
                      />
                    )}
                  </Box>
                </Stack>
              </Paper>
            )}
            {!form.values.id && (
              <Autocomplete
                className={classes.autocompleteWidth}
                clearIcon={<Close />}
                disablePortal={false}
                filterOptions={identity}
                freeSolo
                getOptionLabel={option => (isString(option) ? '' : option.name)}
                isOptionEqualToValue={isEqual}
                noOptionsText={noOptionsText}
                onChange={(event, value) => {
                  if (value && !isString(value)) form.setValues(value);
                }}
                openOnFocus
                options={options}
                renderInput={params => {
                  return (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        placeholder: 'Search by Plan ID or Plan Name',
                        startAdornment: programPlansQuery.isFetching ? (
                          <InputAdornment position='start'>
                            <CircularLoading />
                          </InputAdornment>
                        ) : (
                          <InputAdornment position='start'>
                            <Search />
                          </InputAdornment>
                        ),
                        style: {
                          height: itemHeight(theme),
                          paddingBottom: '0px',
                          paddingLeft: theme.spacing(1.75),
                          paddingRight: '0px',
                          paddingTop: '0px'
                        }
                      }}
                      inputProps={{
                        ...params.inputProps,
                        style: {
                          paddingLeft: '0px'
                        }
                      }}
                      onChange={event => setQuery(event.target.value)}
                    />
                  );
                }}
                renderOption={(optionProps, option) => {
                  return (
                    <ListItem {...optionProps} key={option.id}>
                      <Stack direction='row'>
                        <Typography className={classes.idField}>
                          {option.id}
                        </Typography>
                        <Typography className={classes.nameField} noWrap>
                          {option.name}
                        </Typography>
                        <Box className={classes.statusField}>
                          <Badge color='success' size='small'>
                            {option.status}
                          </Badge>
                        </Box>
                      </Stack>
                    </ListItem>
                  );
                }}
              />
            )}
            <Button
              className={classes.saveButton}
              disabled={!form.dirty}
              endIcon={<PersonAddIcon />}
              onClick={() => form.handleSubmit()}
              variant='outlined'>
              Create association
            </Button>
          </Stack>
        </div>
      </Paper>
    </>
  );
};

export default ProgramDetailsAddPlan;
