import Badge from '@/components/badge';
import { CellComponentProps } from '@/components/collapsible-table';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { PlanService } from '@/services/Plan.service';
import formatters from '@/utils/Formatters';
import { Box, Button, Link, Stack, TableCell, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import dayjs from 'dayjs';
import React, { useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useToggle } from 'react-use';

import { RothDepositReportModal } from './RothDepositReportModal.component';

interface CellProps {
  planName: string;
  planId: number;
}

export const PlanNameCell: React.FC<CellProps> = (props: CellProps) => {
  return (
    <Box minWidth={200}>
      <Link component={RouterLink} to={`/plans/${props.planId}/conversion`}>
        <Typography color={blue[500]} fontWeight={400}>
          {props.planName}
        </Typography>
      </Link>

      <Typography variant='body2'>ID: {props.planId}</Typography>
    </Box>
  );
};

function getDayDifference(dateString: string): string {
  const inputDate = dayjs(dateString);
  const currentDate = dayjs();

  const dayDifference = inputDate.diff(currentDate, 'day');
  return dayDifference > 0 ? `in ${dayDifference} days` : '';
}

const ConversionsTableCell: React.FunctionComponent<CellComponentProps> = (
  props: CellComponentProps
) => {
  const { row, column } = props;
  const [open, setOpen] = useToggle(false);
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  let field = row[column.field];

  const statusMutation = useMutation(
    ['PlanService.postConversionStatus'],
    async (status: boolean) => {
      return PlanService.postConversionStatus(row.planId, status);
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Error updating conversion',
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: 'Conversion status updated',
          severity: 'success'
        });

        queryClient.refetchQueries(['PlanService.getPageConversions']);
      }
    }
  );

  const completeConversion = useCallback(() => {
    statusMutation.mutate(true);
  }, []);

  const reopenConversion = useCallback(() => {
    statusMutation.mutate(false);
  }, []);

  if (column.field.includes('Status')) {
    field = row[column.field] && (
      <Badge color={row[column.field] === 'Complete' ? 'success' : 'neutral'}>
        {row[column.field]}
      </Badge>
    );
  }

  if (column.field === 'blackoutDate') {
    field = row[column.field] && (
      <Stack direction='column'>
        {row[column.field]}
        <Typography color='gray' variant='caption'>
          {getDayDifference(row[column.field])}
        </Typography>
      </Stack>
    );
  }

  if (column.field === 'planName') {
    field = <PlanNameCell planId={row.planId} planName={row.planName} />;
  }

  if (column.field === 'rothBasis') {
    field = <Link onClick={setOpen}>{row[column.field]}</Link>;
  }

  if (column.field === 'planAvailableBalance') {
    field = (
      <Box maxWidth={140} textAlign='right'>
        {formatters.formatDollars(row[column.field])}
      </Box>
    );
  }

  if (column.field === 'action') {
    const isCashAllocationComplete = row.cashAllocationStatus === 'Complete';
    const isLoanStatusCompleteOrUndefined =
      row.loanStatus === 'Complete' || row.loanStatus === undefined;

    if (
      isCashAllocationComplete &&
      !row.conversionComplete &&
      isLoanStatusCompleteOrUndefined
    ) {
      field = <Button onClick={completeConversion}>Complete</Button>;
    } else if (row.conversionComplete) {
      field = <Button onClick={reopenConversion}>Re-Open</Button>;
    } else {
      field = '';
    }
  }

  return (
    <TableCell component='th' scope='row'>
      <Box>
        {!field ? <Typography color='lightgray'>n/a</Typography> : field}
      </Box>
      <RothDepositReportModal
        onClose={setOpen}
        open={open}
        planId={row.planId}
      />
    </TableCell>
  );
};

export default ConversionsTableCell;
