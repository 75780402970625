import { CellComponentProps } from '@/components/collapsible-table';
import JSONViewer from '@/components/json-viewer';
import formatters from '@/utils/Formatters';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  TableCell,
  Typography
} from '@mui/material';

import React from 'react';
import { useToggle } from 'react-use';

interface JsonPreviewModalProps extends DialogProps {
  title: string;
  json: any;
}

const JsonPreviewModal: React.FunctionComponent<JsonPreviewModalProps> = (
  props: JsonPreviewModalProps
) => {
  return (
    <Dialog onClose={props.onClose} open={props.open}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <JSONViewer json={props.json} />
      </DialogContent>
    </Dialog>
  );
};

const WithdrawalTradeRequestsTableCell: React.FunctionComponent<
  CellComponentProps
> = (props: CellComponentProps) => {
  const { row, column } = props;
  const [openErrorModal, toggleOpenErrorModal] = useToggle(false);
  const [openRequestModal, toggleOpenRequestModal] = useToggle(false);
  const [openSubaModal, toggleOpenSubaModal] = useToggle(false);

  const hasError = [
    'FAILED',
    'SUBA_FAILED',
    'SUBA_TIMED-OUT',
    'SUBA_ABORTED'
  ].includes(row[column.field]);

  let field = <Box>{row[column.field] || '--'}</Box>;
  if (column.field === 'createdAt' || column.field === 'updatedAt') {
    field = (
      <Box>
        {formatters.formatFromIsoDateCustom(row[column.field], 'M/DD/YY')}
      </Box>
    );
  } else if (column.field === 'amount') {
    field = <Box>{formatters.formatDollars(row[column.field])}</Box>;
  } else if (column.field === 'status') {
    field = (
      <Box sx={{ display: 'flex' }}>
        <Box>{row[column.field] || '--'}</Box>
        {hasError && (
          <IconButton onClick={toggleOpenErrorModal} size='small'>
            <OpenInNewOutlinedIcon fontSize='inherit' />
          </IconButton>
        )}
      </Box>
    );
  } else if (column.field === 'action') {
    field = (
      <Box>
        <Button onClick={toggleOpenRequestModal}>View JSON Request</Button>
        <Button onClick={toggleOpenSubaModal}>View Suba Result</Button>
      </Box>
    );
  }

  return (
    <>
      <TableCell component='th' scope='row'>
        <Box sx={{ '& .MuiBox-root': { fontSize: theme => theme.spacing(2) } }}>
          {field}
        </Box>
      </TableCell>
      <Dialog onClose={toggleOpenErrorModal} open={openErrorModal}>
        <DialogTitle>Error message</DialogTitle>
        <DialogContent>
          <Typography variant='body1'>{row.errorMessage || '--'}</Typography>
        </DialogContent>
      </Dialog>
      <JsonPreviewModal
        json={row.request}
        onClose={toggleOpenRequestModal}
        open={openRequestModal}
        title='JSON Request'
      />
      <JsonPreviewModal
        json={row.subaResult}
        onClose={toggleOpenSubaModal}
        open={openSubaModal}
        title='Suba Result'
      />
    </>
  );
};

export default WithdrawalTradeRequestsTableCell;
