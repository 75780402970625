import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { AlertDto } from '@/models/ops/alerts/AlertDTO.model';
import formatters from '@/utils/Formatters';
import { Box } from '@mui/material';
import { AlertSubType } from '@vestwell-sub-accounting/models/common/AlertSubType';
import { AlertType } from '@vestwell-sub-accounting/models/common/AlertType';

import lodash from 'lodash';

import AlertDynamicDetails from '../AlertDynamicDetails.component';

type DefaultAlertDetailFieldsProps = {
  alert: AlertDto;
};

export const DefaultAlertDetailFields = ({
  alert
}: DefaultAlertDetailFieldsProps): JSX.Element => {
  // fetch friendly display name for alert type
  const displayType = formatters.getValueKey(AlertType, alert?.alertType);
  // fetch friendly display name for alert sub type
  const displaySubType = formatters.getValueKey(
    AlertSubType,
    alert?.alertSubType
  );

  return (
    <TextStack
      direction='column'
      id='alert-detail-fields'
      spacing={1}
      sx={{ mb: 1.5, mt: 3.5, p: 0 }}>
      <TextStackItem>
        <TextLabel>Type / Sub Type</TextLabel>
        <TextValue>
          {formatters.displayCase(displayType)} /{' '}
          {formatters.displayCase(displaySubType)}
        </TextValue>
      </TextStackItem>

      <TextStackItem>
        <TextLabel>Tracer ID</TextLabel>
        <TextValue copyable>{alert.tracerId}</TextValue>
      </TextStackItem>

      {alert.details &&
        lodash.isPlainObject(alert.details) &&
        Object.values(alert.details).length > 0 && (
          <Box
            component='li'
            sx={{
              listStyle: 'none'
            }}>
            <AlertDynamicDetails detailsObj={alert.details} />
          </Box>
        )}

      <TextStackItem>
        <TextLabel>Created</TextLabel>
        <TextValue>
          {alert.createdAt
            ? formatters.formatFromIsoDate(alert.createdAt)
            : null}
        </TextValue>
      </TextStackItem>
    </TextStack>
  );
};
