import DataTable, {
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { OffsettingOperationalAccountTypes } from '@/models/ops/transactions/ReverseTransactionRequest.model';
import { TransactionDto } from '@/models/ops/transactions/TransactionDTO.model';
import TransactionService from '@/services/ops/transactions/Transaction.service';
import formatters from '@/utils/Formatters';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AccountLevel } from '@vestwell-sub-accounting/models/accountsAndLedgers/AccountLevel';
import { TransactionBaseType } from '@vestwell-sub-accounting/models/common/TransactionBaseType';

import { ColDef } from 'ag-grid-community';
import { useEffect, useState } from 'react';

import { TransactionAccountSummary } from './TransactionAccountSummary.component';

type ReverseTransactionDialogProps = {
  open: boolean;
  onClose: () => void;
  transactions: TransactionDto[];
  onReversedTransaction?: () => void;
  accountId: string;
  accountLevel: AccountLevel;
  offsettingOperationalAccountType?: OffsettingOperationalAccountTypes;
};

const pluralize = (possibleArray: unknown) => {
  return Array.isArray(possibleArray) && possibleArray.length > 1 ? 's' : '';
};

export const ReverseTransactionDialog = ({
  open,
  onClose,
  transactions,
  onReversedTransaction,
  accountId,
  accountLevel,
  offsettingOperationalAccountType
}: ReverseTransactionDialogProps): JSX.Element => {
  const { showSnackbar } = useSnackbar();

  const queryClient = useQueryClient();
  const reverseTransactionMutation = useMutation(
    ['TransactionService.reverse'],
    (sourceTransactionId: string | string[]) => {
      return TransactionService.reverse(sourceTransactionId, {
        offsettingOperationalAccountType
      });
    },
    {
      onError: (error, variables) => {
        showSnackbar({
          message: `Error reversing Transaction${pluralize(variables)} ${
            Array.isArray(variables) && variables.length > 1
              ? variables.join(', ')
              : variables
          }`,
          severity: 'error'
        });
      },
      onSuccess: (data, variables) => {
        showSnackbar({
          message: `Transaction${pluralize(variables)}  ${
            Array.isArray(variables) && variables.length > 1
              ? variables.join(', ')
              : variables
          } reversed`,
          severity: 'success'
        });
        // Invalidate all transaction related queries to ensure any related components reflect these changes
        queryClient.invalidateQueries();
        onClose();
        if (typeof onReversedTransaction === 'function') {
          onReversedTransaction();
        }
      }
    }
  );

  const [columnDefs, setColumnDefs] = useState<ColDef[]>([
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: TransactionDto }) => {
        const displayBaseType = formatters.getValueKey(
          TransactionBaseType,
          cellData.data.transactionBaseType
        );
        return (
          <DataTableStackCell
            primary={formatters.displayCase(displayBaseType)}
            secondary={formatters.displayCase(
              cellData.data.transactionTypeCode
            )}
          />
        );
      },
      field: 'transactionBaseType',
      headerName: 'Base Type / Type',
      minWidth: 255
    },
    {
      field: 'units',
      headerName: 'Units',
      minWidth: 125,
      type: 'numericColumn',
      valueFormatter: ({ value }: { value: number }) =>
        value ? formatters.formatDecimal(value, 3) : ''
    },
    {
      field: 'securityUnitPrice',
      headerName: 'Price',
      minWidth: 125,
      type: 'numericColumn',
      valueFormatter: ({ value }: { value: string }) =>
        value ? formatters.formatDollars(value) : ''
    },
    {
      field: 'amount',
      headerName: 'Amount',
      minWidth: 133,
      type: 'numericColumn',
      valueFormatter: ({ value }: { value: string }) =>
        value ? formatters.formatDollars(value) : ''
    },
    {
      field: 'tradeDate',
      headerName: 'Trade Date',
      minWidth: 160,
      valueFormatter: ({ value }: { value: string }) =>
        formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY')
    }
  ]);

  useEffect(() => {
    // check if any transactions are accountLevel sub accounts and if so add a column for sub account id
    const hasSubAccountTransaction = transactions.some(
      transaction => transaction.accountLevel === AccountLevel.SubAccount
    );
    setColumnDefs(cd => {
      const newColumnDefs = [...cd];
      const accountIdColumnIndex = newColumnDefs.findIndex(
        col => col.field === 'accountId'
      );
      if (hasSubAccountTransaction && accountIdColumnIndex === -1) {
        // sub account transactions present but no account id column so add one
        newColumnDefs.unshift({
          field: 'accountId',
          headerName: 'Sub Account ID',
          maxWidth: 180,
          minWidth: 180,
          tooltipField: 'accountId'
        });
      } else if (!hasSubAccountTransaction && accountIdColumnIndex !== -1) {
        // no sub account transactions present and account id column is present so remove it
        newColumnDefs.splice(accountIdColumnIndex, 1);
      }
      return newColumnDefs;
    });
  }, [transactions]);

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      onClose={() => {
        onClose();
      }}
      open={open}>
      <DialogTitle
        sx={{
          pb: 1 // there is an unknown rule somewhere setting 8px important padding to the top of DialogContent so we have to compensate here
        }}>
        {offsettingOperationalAccountType
          ? `Reverse & Offset Transaction${pluralize(transactions)}`
          : `Reverse Transaction${pluralize(transactions)} (No Offset)`}
      </DialogTitle>
      {Array.isArray(transactions) && transactions.length > 0 && (
        <DialogContent
          sx={{
            p: 0
          }}>
          <TransactionAccountSummary
            accountId={accountId}
            accountLevel={accountLevel}
          />
          <Box sx={{ minHeight: '320px', px: 3, py: 1 }}>
            <Typography sx={{ mb: 3.75 }}>
              The following transaction{pluralize(transactions)} will be
              reversed
              {offsettingOperationalAccountType
                ? ' and offset'
                : ' (no offset transactions will be created)'}
              :
            </Typography>
            <DataTable
              columnDefs={columnDefs}
              data-testid='reverse-transactions-table'
              rowData={transactions as any}
            />
          </Box>
        </DialogContent>
      )}
      <Divider />
      <DialogActions
        sx={{
          px: 3,
          py: 2.25
        }}>
        <Button
          onClick={() => {
            onClose();
          }}>
          Nevermind
        </Button>

        <LoadingButton
          disabled={!Array.isArray(transactions) || !transactions.length}
          loading={reverseTransactionMutation.isLoading}
          onClick={() => {
            const sourceTransactionIds = transactions.map(
              transaction => transaction.sourceTransactionId
            );
            reverseTransactionMutation.mutate(sourceTransactionIds);
          }}
          variant='contained'>
          {offsettingOperationalAccountType
            ? `Reverse & Offset Transaction${pluralize(transactions)}`
            : `Reverse Transaction${pluralize(transactions)}`}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
