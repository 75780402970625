import Constants from '@/consts/planDesign.constants';
import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class AutoEnrollAmount {
  convertToApiState = (value: string): number | undefined => {
    if (!value) {
      return undefined;
    }

    return value === 'None'
      ? 0
      : Helper.convertValueFromNumericStringToNumber(value);
  };

  options = Constants.autoEnrollAmountOptions.map(o => o.value);

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.output = this.transform(props);
  }

  private transform = (props: Record<string, any>): string | undefined => {
    return props.autoEnrollAmount === 0
      ? 'None'
      : Helper.addPercentSign(props.autoEnrollAmount);
  };
}

export default AutoEnrollAmount;
