import DatePicker from '@/components/date-picker/DatePicker';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { ContributionDetailsDto, ContributionDto, PlanV2Dto } from '@/models';
import ContributionService from '@/services/Contribution.service';
import { PlanService } from '@/services/Plan.service';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Unstable_Grid2 as Grid
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';

import dayjs from 'dayjs';
import { useFormik } from 'formik';
import React, { FC } from 'react';
import { useUpdateEffect } from 'react-use';
import * as yup from 'yup';

type EditMetaDataProps = {
  onClose: () => void;
  isOpen: boolean;
  ucid: string;
  sponsorId: number;
  planId: number;
};

const validations = yup.object().shape({
  date: yup.string().test({
    test: function (value, ctx) {
      if (!value) {
        return this.createError({
          message: 'Enter a date',
          path: ctx.path
        });
      }

      if (!dayjs(value).isValid()) {
        return this.createError({
          message: 'Enter a valid date as MM/DD/YYYY',
          path: ctx.path
        });
      }

      if (
        ctx.parent?.recordKeeperId !== 5 &&
        dayjs(ctx.parent?.originalDate).year() !== dayjs(value).year()
      ) {
        return this.createError({
          message: `Updating contribution year is not supported for ${ctx.parent?.recordKeeperName}`,
          path: ctx.path
        });
      }

      const existingContribution = ctx.parent?.existingDates?.get(
        dayjs(value).format('MM/DD/YYYY')
      );

      if (
        existingContribution &&
        existingContribution.ucid &&
        existingContribution?.isOffCycle === ctx.parent?.isOffCycle &&
        existingContribution?.division === ctx.parent?.division
      ) {
        return this.createError({
          message: `A ${value} ${
            existingContribution.isOffCycle ? 'off-cycle' : 'regular'
          } contribution for paygroup "${
            existingContribution.division ?? 'null'
          }" already exists. Please use a different pay date`,
          path: ctx.path
        });
      }

      return true;
    }
  })
});

export const EditMetadata: FC<EditMetaDataProps> = props => {
  const { showSnackbar } = useSnackbar();

  const plan = useQuery<PlanV2Dto>(
    [PlanService.getPlanById.name, props.planId],
    () => {
      return PlanService.getPlanById(props.planId);
    },
    { enabled: props.isOpen }
  );

  const contributions = useQuery<ContributionDto[]>(
    [ContributionService.getContributions.name, props.planId, props.sponsorId],
    () =>
      ContributionService.getContributions({
        nonCompleted: false,
        planId: props.planId,
        sponsorId: props.sponsorId
      }),
    { enabled: props.isOpen && !!props.planId && !!props.sponsorId }
  );

  const contribution = useQuery<ContributionDetailsDto>(
    [
      ContributionService.getContributionDetails.name,
      props.sponsorId,
      props.planId,
      props.ucid
    ],
    async () => {
      return ContributionService.getContributionDetails({
        planId: props.planId,
        sponsorId: props.sponsorId,
        ucid: props.ucid
      });
    },
    {
      enabled:
        props.isOpen && !!props.ucid && !!props.planId && !!props.sponsorId
    }
  );

  const putContribution = useMutation(
    (data: {
      ucid: string;
      planId: number;
      sponsorId: number;
      expectedPayrollDate: string;
    }) => ContributionService.putContribution(data),
    {
      onError: () =>
        showSnackbar({
          message: 'Something went wrong!',
          severity: 'error'
        }),
      onSuccess: () =>
        showSnackbar({
          message: 'Contributions paydate has been updated.',
          severity: 'success'
        })
    }
  );

  const formik = useFormik({
    initialValues: {
      date: null,
      division: null,
      existingDates: null,
      isOffCycle: null,
      originalDate: null,
      recordKeeperId: null,
      recordKeeperName: null
    },
    onSubmit: async values => {
      await putContribution.mutateAsync({
        expectedPayrollDate: dayjs(values.date).format('MM/DD/YYYY'),
        planId: props.planId,
        sponsorId: props.sponsorId,
        ucid: props.ucid
      });

      contribution.refetch();

      props.onClose();
    },
    validateOnMount: true,
    validationSchema: validations
  });

  useUpdateEffect(() => {
    const existingDates = new Map(
      contributions.data?.map(contribution => [
        contribution.contributionMeta?.payrollDate,
        {
          division: contribution.key?.division,
          isOffCycle: contribution.key?.offcycle,
          ucid: contribution.key?.ucid
        }
      ])
    );

    formik.setValues({
      date: contribution.data?.payrollDate,
      division: contribution.data?.division,
      existingDates: existingDates,
      isOffCycle: contribution.data?.isOffCycle,
      originalDate: contribution.data?.payrollDate,
      recordKeeperId: plan.data?.data?.relationships?.recordKeeper?.data?.id,
      recordKeeperName: plan.data?.data.attributes?.recordkeeper
    });
  }, [contributions.data, plan.data, contribution.data, props.isOpen]);

  return (
    <Dialog fullWidth maxWidth='xs' onClose={props.onClose} open={props.isOpen}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Edit Metadata</DialogTitle>
        <DialogContent>
          <Grid mb={3} xs={12}>
            <FormControl fullWidth required>
              <DatePicker
                InputProps={{
                  autoComplete: 'off'
                }}
                data-testid='dateInput'
                disabled={
                  plan.isFetching ||
                  contribution.isFetching ||
                  contributions.isFetching
                }
                errorMessage={formik.errors?.date}
                label='Pay Date'
                name='date'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                size='small'
                value={formik.values.date}
                variant='outlined'
              />
            </FormControl>
          </Grid>
          <Grid container spacing={1}>
            <Grid>
              <Alert severity='info'>
                Updates to the contribution may require lost earnings to be
                calculated. Please review.
              </Alert>
            </Grid>
            <Grid>
              <Alert severity='info'>
                Transactions’ date will be updated the next business day.
              </Alert>
            </Grid>
            <Grid>
              <Alert severity='info'>
                Updating this contribution date may recalculate hours with the
                new pay date and may impact eligibility.
              </Alert>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button data-testid='cancel' onClick={props.onClose}>
            Cancel
          </Button>
          <LoadingButton
            data-testid='submit'
            disabled={!formik.isValid}
            loading={
              plan.isFetching ||
              contribution.isFetching ||
              contributions.isFetching ||
              formik.isSubmitting
            }
            type='submit'
            variant='contained'>
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
