import SimpleUpload from '@/components/simple-upload';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Button, FormControl, Typography } from '@mui/material';

import { FC, useCallback } from 'react';

interface ActionCenterDetailsBulkEmployerProps {
  setDetails: (details: Record<string, any>) => void;
  details: Record<string, any>;
}

export const ActionCenterDetailsBulkEmployer: FC<
  ActionCenterDetailsBulkEmployerProps
> = props => {
  const onSelect = useCallback(async files => {
    if (Array.isArray(files)) {
      props.setDetails({
        ...props.details,
        csvFile: files[0],
        csvName: files[0].name
      });
    }
  }, []);

  return (
    <div data-testid='action-center-details-filter-bulk-employer'>
      <FormControl fullWidth size='small'>
        <Typography mb={2} variant='body2'>
          Upload an employer list below. See the template for the required data.
        </Typography>
        <SimpleUpload
          accept={{ 'text/csv': ['.csv'] }}
          data-testid='upload-csv-file'
          onSelect={onSelect}
          selectRawFiles
          style={{
            marginBottom: '15px'
          }}>
          <Button
            data-testid='details-file-upload-button'
            startIcon={<FileUploadOutlinedIcon />}
            variant='outlined'>
            Select .CSV file
          </Button>
          <Typography
            sx={{
              color: theme => theme.palette.text.secondary,
              display: 'block',
              marginTop: '10px'
            }}
            variant='caption'>
            {props.details.csvName || 'No file chosen'}
          </Typography>
        </SimpleUpload>
      </FormControl>
    </div>
  );
};
