import { SubAccountOrderDto } from '@/models/ops/sub-account-orders/SubAccountOrderDTO.model';
import { SubAccountOrderSearchRequest } from '@/models/ops/sub-account-orders/SubAccountOrderSearchRequest.model';
import { PaginatedApiResponse } from '@/models/PaginatedApiResponse.model';
import ApiService from '@/services/Api.service';

class SubAccountOrderService {
  static async search(
    params: SubAccountOrderSearchRequest
  ): Promise<PaginatedApiResponse<SubAccountOrderDto[]>> {
    const result = await ApiService.getJson<
      PaginatedApiResponse<SubAccountOrderDto[]>
    >(`sub-account-orders/`, params);
    return result;
  }

  static async defer(id: number): Promise<void> {
    return ApiService.patchJson<void, void>(
      `/sub-account-orders/defer/${id}`,
      undefined
    );
  }
}

export default SubAccountOrderService;
