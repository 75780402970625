import CancelIcon from '@mui/icons-material/Cancel';
import { Grid, IconButton, lighten, SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { yellow } from '@mui/material/colors';

import React from 'react';

interface BadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  color?:
    | 'info'
    | 'neutral'
    | 'success'
    | 'warning'
    | 'lightWarning'
    | 'error'
    | 'primary';
  'data-testid'?: string;
  size?: 'small' | 'medium';
  deleteable?: boolean;
  handleClick?: () => void;
  maxWidth?: string;
  sx?: SxProps;
}

export const Badge: React.FunctionComponent<BadgeProps> = (
  props: BadgeProps
) => {
  const {
    children,
    color = 'neutral',
    size = 'small',
    deleteable,
    handleClick,
    maxWidth,
    sx
  } = props;

  const themeColor = (theme: Theme) =>
    ({
      error: theme.palette.error.dark,
      info: theme.palette.grey[700],
      lightWarning: lighten(yellow[900], 0.2),
      neutral: theme.palette.grey[600],
      primary: theme.palette.primary.dark,
      success: theme.palette.success.dark,
      warning: theme.palette.warning.dark
    })[color];

  return (
    <Box
      data-testid={props['data-testid']}
      sx={{
        backgroundColor: theme =>
          ({
            error: theme.palette.error.lightBg,
            info: theme.palette.grey[300],
            lightWarning: lighten(yellow[600], 0.8),
            neutral: theme.palette.grey[200],
            primary: theme.palette.primary.light,
            success: theme.palette.success.lightBg,
            warning: theme.palette.warning.lightBg
          })[color],
        borderRadius: () =>
          ({
            medium: '16px',
            small: '12px'
          })[size],
        color: themeColor,
        display: 'inline-flex',
        fontSize: {
          medium: 16,
          small: 14
        }[size],
        fontWeight: 500,
        height: {
          medium: '32px',
          small: '24px'
        }[size],
        lineHeight: {
          medium: '32px',
          small: '24px'
        }[size],
        maxWidth,
        overflow: 'hidden',
        paddingX: {
          medium: 2,
          small: '12px'
        }[size],
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        ...(sx || {})
      }}>
      {deleteable && (
        <Grid
          container
          display='flex'
          justifyContent='space-between'
          spacing={1}>
          <Grid item>{children}</Grid>
          <Grid item>
            <IconButton
              onClick={handleClick}
              sx={{ color: themeColor, padding: '0px' }}>
              <CancelIcon fontSize='small' sx={{ padding: '2px' }} />
            </IconButton>
          </Grid>
        </Grid>
      )}
      {!deleteable && children}
    </Box>
  );
};

export default Badge;
export type { BadgeProps };
