/* eslint-disable sort-keys-plus/sort-keys */
import AccessControl from '@/components/access-control/AccessControl.component';
import CardInfoField from '@/components/card-info-field';
import DatePicker from '@/components/date-picker';
import SimpleDropdown from '@/components/simple-dropdown';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { ParticipantInfo } from '@/models';
import { ParticipantCensusDataDto } from '@/models/ParticipantCensusDataDTO.model';
import { ParticipantEligibilityDto } from '@/models/ParticipantEligibilityDTO.model';
import { UpdateParticipantInfoDto } from '@/models/UpdateParticipantInfoDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { EsaService } from '@/services/Esa.service';
import ParticipantService from '@/services/Participant.service';
import formatters from '@/utils/Formatters';
import validationFunctions from '@/utils/validations/helpers/InputValidations';
import createParticipantEmploymentDatesValidationSchema from '@/utils/validations/ParticipantEmploymentDates.schema';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormHelperText,
  Theme,
  Typography
} from '@mui/material';
import { orange } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';

import dayjs from 'dayjs';
import { Field } from 'formik';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';

interface EmploymentDates {
  hireDate: string | null;
  rehireDate: string | null;
  terminationDate: string | null;
}

interface ParticipantEmploymentCardProps {
  isEsa?: boolean;
  isVestingOverride: boolean;
  isTimeElapsedPlan: boolean;
  externalId?: number;
  employmentData: ParticipantCensusDataDto;
  isStateIRA: boolean;
  participant: ParticipantInfo;
  refreshParticipantEligibilityMutation: UseMutationResult<
    ParticipantEligibilityDto,
    unknown,
    boolean,
    unknown
  >;
}

const useStyles = makeStyles((theme: Theme) => ({
  cardContainer: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1
    }
  },
  contentBody: {
    paddingBottom: 0
  },
  editBtn: {
    margin: theme.spacing(0, 0, 1, 1)
  },
  root: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexDirection: 'column'
    }
  }
}));

const ParticipantEmploymentCard: React.FunctionComponent<
  ParticipantEmploymentCardProps
> = (props: ParticipantEmploymentCardProps) => {
  const {
    employmentData,
    isStateIRA,
    participant,
    refreshParticipantEligibilityMutation,
    isVestingOverride,
    isTimeElapsedPlan
  } = props;

  const classes = useStyles();
  const { openDialog } = useDialog();
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  const esaPlanGroups = useQuery(
    ['EsaService.getEsaPlanGroups', participant.sponsorPlanId],
    () => EsaService.getEsaPlanGroups(participant.sponsorPlanId),
    { enabled: props.isEsa }
  );

  const esaGroup = useMemo(
    () =>
      esaPlanGroups.data?.filter(
        group => group.groupId === participant.esaGroupId
      )[0],
    [esaPlanGroups.data, participant]
  );

  const updateInfo = useMutation(
    (data: { employmentStatus: string }) => {
      return ParticipantService.updateParticipantEmployment(
        participant.participantId as number,
        participant.sponsorPlanId as number,
        {
          data: {
            attributes: {
              employmentStatus: data.employmentStatus,
              // DO NOT send emails when employment status is updated
              sendEmailUpdate: false
            },
            id: participant.participantId as number,
            personalEmail: participant.personalEmail,
            type: 'participant',
            userEmail: participant.userEmail,
            workEmail: participant.workEmail
          }
        }
      );
    },
    {
      onError: () => {
        showSnackbar({
          message: `Failed!`,
          severity: 'error'
        });
      },
      onSuccess: async () => {
        await queryClient.refetchQueries([
          'ParticipantService.getParticipantById',
          participant.participantId?.toString()
        ]);

        showSnackbar({
          message: `Success! Employment information saved.`,
          severity: 'success'
        });
      }
    }
  );
  const employmentDates: EmploymentDates = {
    hireDate: employmentData.data.dateOfHire
      ? dayjs(employmentData.data.dateOfHire).format('MM/DD/YYYY')
      : '',
    rehireDate: employmentData.data.dateOfRehire
      ? dayjs(employmentData.data.dateOfRehire).format('MM/DD/YYYY')
      : '',
    terminationDate: employmentData.data.dateOfTermination
      ? dayjs(employmentData.data.dateOfTermination).format('MM/DD/YYYY')
      : ''
  };

  const updateEmploymentDates = useMutation(
    (data: UpdateParticipantInfoDto) => {
      return ParticipantService.updateEmploymentDates(
        participant.participantId as number,
        participant.sponsorPlanId as number,
        data
      );
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Error!',
          severity: 'error'
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries([
          'ParticipantService.getParticipantCensusTableData',
          String(participant.participantId),
          participant.sponsorPlanId
        ]);
      }
    }
  );

  return (
    <div className={classes.root} data-testid='participant-employment-card'>
      <Card className={classes.cardContainer} elevation={0} variant='outlined'>
        <CardContent className={classes.contentBody}>
          <Typography variant='h5'>Employment</Typography>
          {(isStateIRA || props.isEsa) && (
            <CardInfoField
              fieldName='Employment Status'
              fieldSubscript=''
              fieldValue={
                !participant.employmentStatus
                  ? EMPTY_FIELD_PLACEHOLDER
                  : participant.employmentStatus.toString()
              }
            />
          )}
          {!props.isEsa && (
            <CardInfoField
              fieldName='External Payroll ID'
              fieldValue={
                !participant.externalId
                  ? EMPTY_FIELD_PLACEHOLDER
                  : participant.externalId.toString()
              }
            />
          )}
          {(!isStateIRA || props.isEsa) && (
            <>
              <CardInfoField
                fieldName='Hire Date'
                fieldValue={employmentDates.hireDate || EMPTY_FIELD_PLACEHOLDER}
              />
              <CardInfoField
                fieldName='Termination Date'
                fieldValue={
                  employmentDates.terminationDate || EMPTY_FIELD_PLACEHOLDER
                }
              />

              <CardInfoField
                fieldName='Rehire Date'
                fieldValue={
                  employmentDates.rehireDate || EMPTY_FIELD_PLACEHOLDER
                }
              />
            </>
          )}
          {props.isEsa && (
            <>
              <CardInfoField
                fieldName='Employee Group'
                fieldValue={esaGroup?.name || EMPTY_FIELD_PLACEHOLDER}
              />
              <Typography fontWeight='bold' mb={1} mt={2} variant='subtitle1'>
                Incentives
              </Typography>
              <TextStack direction='column'>
                <TextStackItem fieldName='Initial Deposit Bonus'>
                  <TextLabel>Initial Deposit Bonus</TextLabel>
                  <TextValue
                    detail={`Required ${formatters.formatDollars(esaGroup?.initialBonusThreshold)}`}>
                    {esaGroup?.initialBonus
                      ? formatters.formatDollars(esaGroup?.initialBonus)
                      : EMPTY_FIELD_PLACEHOLDER}
                  </TextValue>
                </TextStackItem>
                <TextStackItem fieldName='Employer Match'>
                  <TextLabel>Employer Match</TextLabel>
                  <TextValue
                    detail={`Match cap ${formatters.formatDollars(esaGroup?.match.lifetimeAmount)}`}>
                    {esaGroup?.match.percent
                      ? `${esaGroup?.match.percent}% ${
                          esaGroup?.match.dollarAmount &&
                          `, up to ${formatters.formatDollars(
                            esaGroup?.match.dollarAmount
                          )}`
                        }`
                      : EMPTY_FIELD_PLACEHOLDER}
                  </TextValue>
                </TextStackItem>
                <TextStackItem fieldName='Milestone Bonus'>
                  <TextLabel>Milestone Bonus</TextLabel>
                  <TextValue
                    detail={`Required ${formatters.formatDollars(esaGroup?.milestoneBonus.threshold)}`}>
                    {esaGroup?.milestoneBonus.amount
                      ? formatters.formatDollars(
                          esaGroup?.milestoneBonus.amount
                        )
                      : EMPTY_FIELD_PLACEHOLDER}
                  </TextValue>
                </TextStackItem>
              </TextStack>
            </>
          )}
        </CardContent>

        {isStateIRA && (
          <AccessControl
            requiresOneOf={[
              FeatureLevelPermissions.WRITE_EMPLOYMENT_EMPLOYMENT
            ]}>
            <CardActions>
              <Button
                onClick={() => {
                  openDialog({
                    actionButtons: {
                      cancelButton: {
                        children: 'Cancel'
                      },
                      submitButton: {
                        children: 'Save'
                      }
                    },
                    onSubmit: async values => {
                      await updateInfo.mutateAsync({
                        employmentStatus: values.employmentStatus
                      });
                    },
                    steps: [
                      {
                        fields: {
                          employmentStatus: {
                            component: (
                              <SimpleDropdown
                                fieldId='employmentStatus'
                                fieldName='Employment Status'
                                fieldValues={['Employed', 'Terminated']}
                              />
                            ),
                            initialValue: participant.employmentStatus,
                            label: 'Employment Status'
                          }
                        },
                        title: 'Edit Employment Status'
                      }
                    ],
                    subcomponentProps: {
                      dialogContentProps: {
                        dividers: true
                      }
                    }
                  });
                }}
                type='button'
                variant='text'>
                EDIT
              </Button>
            </CardActions>
          </AccessControl>
        )}

        <AccessControl
          requires={[FeatureLevelPermissions.WRITE_EMPLOYMENT_EMPLOYMENT]}>
          {!isStateIRA && (
            <CardActions>
              <Button
                className={classes.editBtn}
                data-testid='edit-employment-dates-btn'
                onClick={() => {
                  const validationSchema =
                    createParticipantEmploymentDatesValidationSchema(
                      employmentDates.rehireDate,
                      participant.birthDate
                    );
                  return openDialog({
                    actionButtons: {
                      cancelButton: {
                        children: 'CANCEl'
                      },
                      submitButton: {
                        children: 'SAVE'
                      }
                    },
                    onSubmit: async values => {
                      try {
                        await updateInfo.mutateAsync({
                          employmentStatus: values.employmentStatus
                        });

                        const updatedDates = {} as EmploymentDates;
                        const keys = Object.keys(employmentDates);

                        keys.forEach(key => {
                          const dateKey = key as keyof EmploymentDates;
                          if (employmentDates[dateKey] !== values[dateKey]) {
                            if (values[dateKey] === '') {
                              updatedDates[dateKey] = null;
                            } else {
                              updatedDates[dateKey] = dayjs(
                                values[dateKey].trim()
                              ).format('YYYY-MM-DD');
                            }
                          }
                        });

                        if (isEmpty(updatedDates)) {
                          return;
                        }

                        const body = {
                          data: {
                            attributes: {
                              ...updatedDates,
                              // DO NOT send emails when employment dates are updated
                              sendEmailUpdate: false
                            },
                            id: participant.participantId as number,
                            personalEmail: participant.personalEmail,
                            type: 'participant',
                            userEmail: participant.userEmail,
                            workEmail: participant.workEmail
                          }
                        };
                        await updateEmploymentDates.mutateAsync(body);
                        if (participant.isOverride) {
                          showSnackbar({
                            message:
                              'Please note that there is an eligibility override on the account. If you’d like to recalculate eligibility, please take the override off and press “Rerun” under eligibility.',
                            severity: 'warning'
                          });
                        } else {
                          await refreshParticipantEligibilityMutation.mutateAsync(
                            false
                          );
                          showSnackbar({
                            message:
                              'Information saved! Eligibility should be refreshed.',
                            severity: 'success'
                          });
                        }
                      } catch (err) {
                        showSnackbar({
                          message: 'Error!',
                          severity: 'error'
                        });
                      }
                    },
                    steps: [
                      {
                        fields: {
                          employmentStatus: {
                            component: (
                              <SimpleDropdown
                                disabled={props.isEsa}
                                fieldId='employmentStatus'
                                fieldName='Employment Status'
                                fieldValues={['Employed', 'Terminated']}
                              />
                            ),
                            initialValue: participant.employmentStatus,
                            label: 'Employment Status',
                            showWhen: () => props.isEsa
                          },
                          hireDate: {
                            component(
                              formValues: Record<string, any>,
                              formErrors: Record<string, any>
                            ) {
                              return (
                                <FormControl error={!!formErrors.hireDate}>
                                  <Field
                                    as={DatePicker}
                                    label='Hire Date'
                                    name='hireDate'
                                    variant='outlined'
                                  />
                                  <FormHelperText>
                                    {formErrors.hireDate?.toString() ?? ''}
                                  </FormHelperText>
                                </FormControl>
                              );
                            },
                            initialValue: employmentDates.hireDate,
                            label: 'Hire Date',
                            maxLength: 10,
                            validate: validationFunctions.validateDateSsnField
                          },
                          terminationDate: {
                            initialValue: employmentDates.terminationDate,
                            label: 'Termination Date',
                            maxLength: 10,
                            validate: validationFunctions.validateDateSsnField
                          },
                          rehireDate: {
                            initialValue: employmentDates.rehireDate,
                            label: 'Rehire Date',
                            maxLength: 10,
                            validate: validationFunctions.validateDateSsnField
                          },
                          vestingOverrideAlert: {
                            component: (
                              <Box
                                alignItems='center'
                                data-testid='vesting-override-warning'
                                display='flex'
                                justifyContent='center'
                                padding='20px'
                                style={{
                                  background:
                                    'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #ED6C02',
                                  borderRadius: '4px'
                                }}>
                                <Box marginRight='20px'>
                                  <WarningAmberIcon htmlColor={orange[500]} />
                                </Box>
                                <Box fontSize='1em'>
                                  Please note that there are historical/total
                                  years of service entered. Please review and
                                  ensure they are still correct.
                                </Box>
                              </Box>
                            ),
                            initialValue: isVestingOverride,
                            isFieldAlwaysHidden:
                              !isTimeElapsedPlan || !isVestingOverride,
                            label: 'isVestingOverride'
                          }
                        },
                        title: 'Edit Employment Info'
                      }
                    ],
                    validationSchema
                  });
                }}>
                EDIT
              </Button>{' '}
            </CardActions>
          )}
        </AccessControl>
      </Card>
    </div>
  );
};

export default ParticipantEmploymentCard;
