import { Parser } from 'json2csv';

import { getCsvOptionsToDownload } from './conversionCsvHeaders';

export interface ConversionFile {
  fileName: string;
  fileData: string;
}

export const convertFileToCsv = (
  base64: string,
  originalFileName: string,
  conversionType: string
): ConversionFile => {
  const csvHeaders = getCsvOptionsToDownload(conversionType);
  const parserOptions = { fields: csvHeaders };
  const json2csvParser = new Parser(parserOptions);

  let fileData = base64;
  let fileName = originalFileName;

  const handleFileData = (base64Content: string) => {
    const str = Buffer.from(base64Content, 'base64').toString('utf8');
    try {
      window.atob(str);
      return Buffer.from(str, 'base64').toString('utf8');
    } catch (e) {
      return str;
    }
  };

  const extractCsvString = (fileContent: string) => {
    const csvString = handleFileData(fileContent);
    const rows = csvString.split('\n');

    const headerRow = rows.shift();
    const headerColumns = headerRow?.split(',') || [];

    const columnIndices = csvHeaders.map(column =>
      headerColumns.indexOf(`"${column}"`)
    );

    const extractedRows = rows.map(row => {
      const columns = row.split(',');
      const extractedColumns = columnIndices.map(
        columnIndex => columns[columnIndex]
      );
      return extractedColumns.join(',');
    });

    return [csvHeaders, ...extractedRows].join('\n');
  };

  if (fileName.includes('.json')) {
    const jsonData = JSON.parse(handleFileData(base64));
    const csvFile = json2csvParser.parse(jsonData);
    fileData = Buffer.from(csvFile).toString('base64');
    fileName = fileName.replace('json', 'csv');
  } else if (fileName.includes('.csv')) {
    fileData = Buffer.from(extractCsvString(base64)).toString('base64');
  }

  return { fileData, fileName };
};
