import LinearLoading from '@/components/linear-loading';
import NavigationBreadcrumbs from '@/components/navigation-breadcrumbs';
import {
  AssetClass,
  GoalSeriesFund,
  ManagedAccount,
  ManagedAccountSeries
} from '@/routes/ops/investments/ManagedAccount.component';
import ApiService from '@/services/Api.service';
import InvestmentService from '@/services/Investment.service';
import { useQuery } from '@tanstack/react-query';

import { useParams } from 'react-router-dom';

type ManagedAccountEditRouteProps = {
  id: string;
};

const ManagedAccountEditRoute = (): JSX.Element => {
  const { id } = useParams<ManagedAccountEditRouteProps>();

  const paths = [
    {
      name: 'Ops',
      to: '/ops/investments'
    },
    {
      name: 'Investments',
      to: '/ops/investments'
    }
  ];
  const managedAccountSeriesId = parseInt(id, 10);

  const onChange = async (
    managedAccountData: ManagedAccountSeries,
    managedAccountFunds: GoalSeriesFund[]
  ): Promise<[ManagedAccountSeries, GoalSeriesFund[]]> => {
    return Promise.all([
      InvestmentService.putGoalSeries(
        managedAccountSeriesId,
        managedAccountData
      ),
      InvestmentService.putGoalSeriesFunds(
        managedAccountSeriesId,
        managedAccountFunds
      )
    ]);
  };

  const getAccountSeries = async (): Promise<
    [ManagedAccountSeries, AssetClass[]]
  > => {
    const managedAccountSeries = await InvestmentService.getGoalSeries(
      managedAccountSeriesId
    );
    const assetClasses = await InvestmentService.getGoalSeriesAssetClasses(
      managedAccountSeries.goeAccountId
    );
    return [managedAccountSeries, assetClasses];
  };

  const seriesQuery = useQuery<[ManagedAccountSeries, AssetClass[]]>(
    ['ManagedAccountEdit.seriesQuery', 'managed-account-series', id],
    getAccountSeries,
    { cacheTime: 0 }
  );
  const managedAccountFundsQuery = useQuery<GoalSeriesFund[]>(
    [
      'ManagedAccountEdit.managedAccountFundsQuery',
      'managed-account-funds',
      id
    ],
    () => {
      return InvestmentService.getGoalSeriesFunds(managedAccountSeriesId);
    },
    { cacheTime: 0 }
  );

  const programCount = useQuery<any>(
    ['ManagedAccountEdit.seriesQuery', 'program-count', id],
    () => {
      return ApiService.getJson(
        `/investments/program?goalSeriesId=${id}&pageSize=0&pageNumber=1`
      );
    },
    { cacheTime: 0 }
  );

  const loading =
    seriesQuery.isFetching ||
    managedAccountFundsQuery.isFetching ||
    programCount.isFetching;
  const canDisplay =
    !loading &&
    seriesQuery.data !== undefined &&
    managedAccountFundsQuery.data !== undefined;
  const possibleError = !loading && !canDisplay;

  return (
    <>
      <NavigationBreadcrumbs paths={paths} />
      {loading && <LinearLoading />}
      {canDisplay && (
        <ManagedAccount
          assetClasses={seriesQuery.data[1]}
          lookupTickerCallback={InvestmentService.searchSymbol}
          managedAccountData={seriesQuery.data[0]}
          managedAccountFund={managedAccountFundsQuery.data}
          programCount={programCount.data?.meta?.count}
          saveManagedCallback={onChange}
        />
      )}
      {possibleError && <div>Possible Error</div>}
    </>
  );
};

export default ManagedAccountEditRoute;
