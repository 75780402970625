/* eslint-disable sort-keys-plus/sort-keys */
import SimpleDropdown from '@/components/simple-dropdown';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import {
  COMPANY_ENTITY_TYPES,
  NULLABLE_DROP_DOWN_OPTIONS,
  YEAR_END_DATES
} from '@/consts/sponsor.constants';
import STATE_CODES from '@/consts/states.constants';
import { FieldOptions } from '@/contexts/DialogContext';
import { UpdateSponsorDto } from '@/models';
import { ScheduledChangePayloadItem } from '@/models/ScheduledChangesDTO.model';
import formatters from '@/utils/Formatters';

import { filter, isBoolean, isEqual, keys } from 'lodash';

import { CompanyInfoTabData } from './CompanyInfoTab.component';

const formatUiValue = (value: any, field: string) => {
  let result;

  try {
    result = JSON.parse(value);
  } catch {
    result = value;
  }
  if (isBoolean(result)) {
    return result ? 'Yes' : 'No';
  }
  if (field === 'companyEntityType') {
    result = COMPANY_ENTITY_TYPES.find(type => type.value === value)?.option;
  }

  return result;
};

export const formValuesToAttributesSponsorDto = (
  values: Record<string, any>,
  isVoyaPlan: boolean,
  isStateIRA: boolean
) => {
  const optionalDto = {
    companyEntityType:
      values.companyEntityType === '--' ? null : values.companyEntityType,
    fiscalYearEnd: values.fiscalYearEnd === '--' ? null : values.fiscalYearEnd,
    isPartOfControlGroup:
      values.isPartOfControlGroup === 'null'
        ? null
        : values.isPartOfControlGroup === 'true'
          ? true
          : false,
    stateOfIncorporation:
      values.stateOfIncorporation === '--' ? null : values.stateOfIncorporation,
    wiredAtWork:
      values.wiredAtWork === 'null'
        ? null
        : values.wiredAtWork === 'true'
          ? true
          : false
  };

  const dto: UpdateSponsorDto['data']['attributes'] = {
    alternateEmployerId: values.alternateEmployerId,
    companyPhoneNumber: values.companyPhoneNumber,
    doingBusinessAs: values.doingBusinessAs,
    ein: values.ein?.includes('-')
      ? values.ein
      : formatters.formatEinOrSdat(values.ein),
    mailingAddress: {
      address1: values.mailingAddress1,
      address2: values.mailingAddress2,
      city: values.mailingCity,
      country: 'United States',
      state: values.mailingState,
      zip: values.mailingZip
    },
    name: values.name,
    physicalAddress: {
      address1: values.physicalAddress1,
      address2: values.physicalAddress2,
      city: values.physicalCity,
      country: 'United States',
      state: values.physicalState,
      zip: values.physicalZip
    },
    primaryContact: {
      email: values.email
    },
    ...(isVoyaPlan || isStateIRA ? {} : optionalDto)
  };

  return dto;
};

const emptyPlaceHolderToValue = value =>
  value === EMPTY_FIELD_PLACEHOLDER ? null : value;

const formatEin = value =>
  value?.includes('-') ? value : formatters.formatEinOrSdat(value);

const fieldsFormatters: Record<string, (value: any) => any> = {
  companyEntityType: emptyPlaceHolderToValue,
  ein: formatEin,
  fiscalYearEnd: emptyPlaceHolderToValue,
  isPartOfControlGroup: JSON.parse,
  stateOfIncorporation: emptyPlaceHolderToValue,
  wiredAtWork: JSON.parse
};

export const editCompanyInfoFormToScheduleChangesPayload = (
  currentValues: Record<string, any>,
  scheduledValues: Record<string, any>,
  config?: {
    fields: FieldOptions<string>;
    isVoyaPlan: boolean;
    isStateIRA: boolean;
  }
): ScheduledChangePayloadItem[] => {
  const differences: Record<string, string> = filter(
    keys(scheduledValues),
    key => scheduledValues[key] !== currentValues[key]
  ).reduce((a, v) => ({ ...a, [v]: v }), {});
  const newPayload: ScheduledChangePayloadItem[] = [];

  {
    Object.entries(config?.fields ?? {}).forEach(([name, fieldOptions]) => {
      if (differences[name]) {
        newPayload.push({
          fieldName: name,
          uiCurrentValue: formatUiValue(currentValues[name], name),
          uiLabel: fieldOptions.label,
          uiScheduledValue: formatUiValue(scheduledValues[name], name),
          value: fieldsFormatters[name]
            ? fieldsFormatters[name](scheduledValues[name])
            : scheduledValues[name]
        });
      }
    });

    const currentAttributes = formValuesToAttributesSponsorDto(
      currentValues,
      config?.isVoyaPlan,
      config?.isStateIRA
    );
    const scheduledAttributes = formValuesToAttributesSponsorDto(
      scheduledValues,
      config?.isVoyaPlan,
      config?.isStateIRA
    );

    const attributesDifferences: Record<string, string> = filter(
      keys(scheduledAttributes),
      key =>
        !isEqual(scheduledAttributes[key], currentAttributes[key]) &&
        !differences[key]
    ).reduce((a, v) => ({ ...a, [v]: scheduledAttributes[v] }), {});

    Object.entries(attributesDifferences).forEach(([key, value]) => {
      newPayload.push({
        fieldName: key,
        uiCurrentValue: '',
        uiLabel: '',
        uiScheduledValue: '',
        value: value
      });
    });

    return [...newPayload].filter(
      payloadItem =>
        (payloadItem.uiCurrentValue !== payloadItem.uiScheduledValue ||
          payloadItem.uiLabel === '') &&
        payloadItem.fieldName !== 'scheduledChangesEffectiveDate'
    );
  }
};

export const getInitialValues = (stepFields: FieldOptions<string>) => {
  const initialValues: Record<string, any> = {};
  Object.entries(stepFields ?? {}).forEach(([name, fieldOptions]) => {
    initialValues[name] = fieldOptions.initialValue;
  });
  return initialValues;
};

export const getFormDialogStep = (
  companyInfoTabData: CompanyInfoTabData,
  isVoyaPlan: boolean,
  isStateIRA: boolean
) => {
  const formattedEINInitialValue = companyInfoTabData.ein?.includes('-')
    ? companyInfoTabData.ein
    : formatters.formatEinOrSdat(companyInfoTabData.ein);

  const mailingAddressFields = isStateIRA
    ? {
        mailingAddress1: {
          initialValue: companyInfoTabData.mailingAddress?.address1 || '',
          label: 'Mailing Address 1'
        },
        mailingAddress2: {
          initialValue: companyInfoTabData.mailingAddress?.address2 || '',
          label: 'Mailing Address 2'
        },
        mailingCity: {
          initialValue: companyInfoTabData.mailingAddress?.city || '',
          label: 'Mailing City'
        },
        mailingState: {
          component: (
            <SimpleDropdown
              fieldId='mailingState'
              fieldName='Mailing State'
              fieldValues={STATE_CODES}
            />
          ),
          initialValue: companyInfoTabData.mailingAddress?.state || '',
          label: 'Mailing State'
        },
        mailingZip: {
          initialValue: companyInfoTabData.mailingAddress?.zip || '',
          label: 'Mailing Zip'
        }
      }
    : null;

  return {
    fields: {
      doingBusinessAs: {
        initialValue: companyInfoTabData.doingBusinessAs,
        label: 'Doing Business As'
      },
      name: {
        initialValue: companyInfoTabData.name,
        label: 'Company Legal Name'
      },
      physicalAddress1: {
        initialValue: companyInfoTabData.physicalAddress?.address1,
        label: 'Physical Address 1'
      },
      physicalAddress2: {
        initialValue: companyInfoTabData.physicalAddress?.address2,
        label: 'Physical Address 2'
      },
      physicalCity: {
        initialValue: companyInfoTabData.physicalAddress?.city,
        label: 'Physical City'
      },
      physicalState: {
        component: (
          <SimpleDropdown
            fieldId='physicalState'
            fieldName='Physical State'
            fieldValues={STATE_CODES}
          />
        ),
        initialValue: companyInfoTabData.physicalAddress?.state,
        label: 'Physical State'
      },
      physicalZip: {
        initialValue: companyInfoTabData.physicalAddress?.zip,
        label: 'Physical Zip'
      },
      ...mailingAddressFields,
      alternateEmployerId: {
        initialValue: companyInfoTabData.alternateEmployerId,
        isFieldAlwaysHidden: !(
          isStateIRA && companyInfoTabData.firmId === 1001
        ),
        isFieldDisabled: companyInfoTabData.firmId !== 1001,
        label: 'SDAT'
      },

      companyPhoneNumber: {
        initialValue: companyInfoTabData.phone,
        label: 'Company Phone Number'
      },
      ein: {
        initialValue: formattedEINInitialValue,
        label: 'EIN'
      },
      companyEntityType: {
        component: (
          <SimpleDropdown
            fieldId='companyEntityType'
            fieldName='Entity Type'
            fieldOptions={COMPANY_ENTITY_TYPES}
          />
        ),
        initialValue: formatters.displayValueOrEmptyFieldPlaceholder(
          companyInfoTabData.companyEntityType
        ),
        isFieldAlwaysHidden: isVoyaPlan || isStateIRA,
        label: 'Entity Type'
      },
      email: {
        initialValue: companyInfoTabData.email,
        isFieldAlwaysHidden: !isVoyaPlan,
        label: 'Company Email'
      },
      fiscalYearEnd: {
        component: (
          <SimpleDropdown
            fieldId='fiscalYearEnd'
            fieldName='Fiscal Year End'
            fieldValues={[
              ...YEAR_END_DATES,
              !YEAR_END_DATES.some(
                entry => entry === companyInfoTabData?.fiscalYearEnd
              )
                ? companyInfoTabData?.fiscalYearEnd
                : ''
            ]}
          />
        ),
        initialValue: formatters.displayValueOrEmptyFieldPlaceholder(
          companyInfoTabData.fiscalYearEnd
        ),
        isFieldAlwaysHidden: isVoyaPlan || isStateIRA,
        label: 'Fiscal Year End'
      },
      isPartOfControlGroup: {
        component: (
          <SimpleDropdown
            fieldId='isPartOfControlGroup'
            fieldName='Is Part of Control Group'
            fieldOptions={NULLABLE_DROP_DOWN_OPTIONS}
          />
        ),
        initialValue: isBoolean(companyInfoTabData.isPartOfControlGroup)
          ? String(companyInfoTabData.isPartOfControlGroup)
          : 'null',
        isFieldAlwaysHidden: isVoyaPlan || isStateIRA,
        label: 'Is Part of Control Group'
      },

      stateOfIncorporation: {
        component: (
          <SimpleDropdown
            fieldId='stateOfIncorporation'
            fieldName='State of Incorporation'
            fieldValues={['--', ...STATE_CODES]}
          />
        ),
        initialValue: formatters.displayValueOrEmptyFieldPlaceholder(
          companyInfoTabData.stateOfIncorporation
        ),
        isFieldAlwaysHidden: isVoyaPlan || isStateIRA,
        label: 'State of Incorporation'
      },
      wiredAtWork: {
        component: (
          <SimpleDropdown
            fieldId='wiredAtWork'
            fieldName='Wired at Work'
            fieldOptions={NULLABLE_DROP_DOWN_OPTIONS}
          />
        ),
        initialValue: isBoolean(companyInfoTabData.isWiredAtWork)
          ? String(companyInfoTabData.isWiredAtWork)
          : 'null',
        isFieldAlwaysHidden: isVoyaPlan || isStateIRA,
        label: 'Wired at Work'
      }
    },
    title: 'Edit Company Info'
  };
};
