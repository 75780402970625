import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { useSnackbar } from '@/contexts/SnackBarContext';
import {
  DeconversionDestinationDto,
  DisbursementInstructionsDto
} from '@/models/PlanDeconversionDTO.model';
import { PlanService } from '@/services/Plan.service';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Autocomplete,
  Box,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Form, Formik } from 'formik';
import React, { FunctionComponent, useCallback } from 'react';
import * as yup from 'yup';

interface DisbursementInstructionsProps {
  data?: DisbursementInstructionsDto;
  deconversionDestinations?: DeconversionDestinationDto[];
  disableAction?: boolean;
  sponsorPlanId: number;
}

const validationSchema = yup.object({
  clientEmail: yup
    .string()
    .required('email is required')
    .email('Invalid email'),
  destinationSelect: yup.object({
    bankInfo: yup.object({
      accountName: yup.string().required('Bank Account Name is required'),
      accountNumber: yup.string().required('Bank Account Number is required'),
      name: yup.string().required('Bank Name is required'),
      referenceMemo: yup.string().max(60, '60 characters max'),
      routingNumber: yup
        .string()
        .required('Bank Routing Number is required')
        .test(
          'receivingBankRoutingNumber',
          'Bank Routing Number must be 9 digits',
          value => !!value && /^\d{9}$/.test(value)
        )
    }),
    payeeInfo: yup.object({
      address: yup
        .string()
        .required('Address is required')
        .max(60, 'Address 60 characters max'),
      city: yup.string().required('City is required'),
      country: yup.string().required('Country is required'),
      name: yup
        .string()
        .required('Recipient name is required')
        .max(60, 'Recipient 60 characters max'),
      state: yup
        .string()
        .required('State is required')
        .max(2, 'State 2 characters max'),
      zip: yup
        .string()
        .required('Zip is required')
        .test(
          'payeeZip',
          'Zip must be 5 or 9 characters',
          value => !!value && (value.length === 5 || value.length === 9)
        )
    })
  }),
  disbursementMethod: yup.string().required('Delivery Method is required'),
  rkEmail: yup.string().required('email is required').email('Invalid email')
});

const DisbursementInstructions: FunctionComponent<
  DisbursementInstructionsProps
> = (props: DisbursementInstructionsProps) => {
  const { data, sponsorPlanId, disableAction, deconversionDestinations } =
    props;
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  const { mutateAsync: saveDisbursementInstructions, isLoading: loading } =
    useMutation(
      [`PlanService.updateDisbursementInstructions ${sponsorPlanId}`],
      (dto: DisbursementInstructionsDto) =>
        PlanService.updateDisbursementInstructions(
          sponsorPlanId as number,
          dto
        ),
      {
        onError: () => {
          showSnackbar({ message: 'Failed!', severity: 'error' });
        },
        onSuccess: async () => {
          queryClient.fetchQuery([
            `PlanService.getPlanDeconversion(${sponsorPlanId})`
          ]);
          showSnackbar({
            message: 'Successfully updated!',
            severity: 'success'
          });
        }
      }
    );

  const selectedDestination = useCallback(
    (destinationId: number) =>
      deconversionDestinations?.find(dest => dest?.id === destinationId),
    [deconversionDestinations]
  );

  return (
    <>
      <Typography sx={{ mb: 3 }} variant='h6'>
        Disbursement Instructions
      </Typography>
      <Formik
        initialValues={{
          clientEmail: data?.contacts?.clientEmail,
          creditToAccountNumber: data?.bankInfo?.creditToAccountNumber,
          creditToName: data?.bankInfo?.creditToName,
          destinationSelect: {
            bankInfo: data?.bankInfo,
            id: data?.destinationId,
            payeeInfo: data?.payeeInfo
          },
          disbursementMethod: data?.deliveryMethod ?? 'ACH',
          referenceMemo: data?.payeeInfo?.referenceMemo,
          rkEmail: data?.contacts?.rkEmail
        }}
        onSubmit={values => {
          const dto: DisbursementInstructionsDto = {
            bankInfo: {
              accountName: values.destinationSelect?.bankInfo?.accountName,
              accountNumber: values.destinationSelect?.bankInfo?.accountNumber,
              creditToAccountNumber: values.creditToAccountNumber,
              creditToName: values.creditToName,
              name: values.destinationSelect?.bankInfo?.name,
              routingNumber: values.destinationSelect?.bankInfo?.routingNumber
            },
            contacts: {
              clientEmail: values.clientEmail,
              rkEmail: values.rkEmail
            },
            deliveryMethod: values.disbursementMethod,
            destinationId: values.destinationSelect.id,
            payeeInfo: {
              address: values.destinationSelect?.payeeInfo?.address,
              city: values.destinationSelect?.payeeInfo?.city,
              country: values.destinationSelect?.payeeInfo?.country,
              name: values.destinationSelect?.payeeInfo?.name,
              referenceMemo: values.referenceMemo,
              state: values.destinationSelect?.payeeInfo?.state,
              zip: values.destinationSelect?.payeeInfo?.zip
            }
          };

          saveDisbursementInstructions(dto);
        }}
        validationSchema={validationSchema}>
        {formik => (
          <Form data-testid='disbursement-instructions-form'>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                mb: 2,
                width: '100%'
              }}>
              <FormControl disabled={disableAction} sx={{ mt: 3 }}>
                <FormLabel id='disbursement-method-radio-group'>
                  Method
                </FormLabel>
                <RadioGroup
                  aria-labelledby='disbursement-method-radio-group-label'
                  name='disbursementMethod'
                  row
                  value={formik.values.disbursementMethod}>
                  <FormControlLabel
                    control={<Radio />}
                    label='ACH'
                    onChange={formik.handleChange}
                    value='ACH'
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label='Wire'
                    onChange={formik.handleChange}
                    value='WIRE'
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label='Delay'
                    onChange={formik.handleChange}
                    value='NONE'
                  />
                </RadioGroup>
                <FormHelperText
                  error={
                    formik.touched.disbursementMethod &&
                    Boolean(formik.errors.disbursementMethod)
                  }>
                  {formik.touched.disbursementMethod &&
                    formik.errors.disbursementMethod}
                </FormHelperText>
              </FormControl>
              <Autocomplete
                disablePortal
                disabled={disableAction}
                fullWidth
                getOptionLabel={(opt: DeconversionDestinationDto) => opt?.name}
                id='destinationsSelect'
                onChange={(event, v) =>
                  formik.setFieldValue('destinationSelect', v || {})
                }
                options={deconversionDestinations || []}
                renderInput={params => (
                  <TextField {...params} label='Destination' />
                )}
                value={selectedDestination(
                  formik.values.destinationSelect?.id ?? data?.destinationId
                )}
              />
              {(formik.values.destinationSelect?.id ||
                formik.values.destinationSelect?.payeeInfo ||
                formik.values.destinationSelect?.bankInfo) && (
                <>
                  <Divider textAlign='left'>Payee Info</Divider>
                  <Card variant='outlined'>
                    <CardContent>
                      <Typography variant='body1'>
                        <b>
                          {formik.values.destinationSelect?.payeeInfo?.name}
                        </b>{' '}
                        <br />
                        {
                          formik.values.destinationSelect?.payeeInfo?.address
                        }{' '}
                        <br />
                        {[
                          formik.values.destinationSelect?.payeeInfo?.city,
                          formik.values.destinationSelect?.payeeInfo?.state,
                          formik.values.destinationSelect?.payeeInfo?.zip
                        ].join(', ')}
                        <br />
                        {
                          formik.values.destinationSelect?.payeeInfo?.country
                        }{' '}
                      </Typography>
                    </CardContent>
                    {!!formik.errors.destinationSelect?.payeeInfo?.name && (
                      <Alert severity='error'>
                        {formik.errors.destinationSelect?.payeeInfo?.name}
                      </Alert>
                    )}
                    {!!formik.errors.destinationSelect?.payeeInfo?.address && (
                      <Alert severity='error'>
                        {formik.errors.destinationSelect?.payeeInfo?.address}
                      </Alert>
                    )}
                    {!!formik.errors.destinationSelect?.payeeInfo?.city && (
                      <Alert severity='error'>
                        {formik.errors.destinationSelect?.payeeInfo?.city}
                      </Alert>
                    )}
                    {!!formik.errors.destinationSelect?.payeeInfo?.state && (
                      <Alert severity='error'>
                        {formik.errors.destinationSelect?.payeeInfo?.state}
                      </Alert>
                    )}
                    {!!formik.errors.destinationSelect?.payeeInfo?.zip && (
                      <Alert severity='error'>
                        {formik.errors.destinationSelect?.payeeInfo?.zip}
                      </Alert>
                    )}
                    {!!formik.errors.destinationSelect?.payeeInfo?.country && (
                      <Alert severity='error'>
                        {formik.errors.destinationSelect?.payeeInfo?.country}
                      </Alert>
                    )}
                  </Card>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    disabled={disableAction}
                    error={
                      formik.touched.referenceMemo &&
                      Boolean(formik.errors.referenceMemo)
                    }
                    helperText={
                      (formik.touched.referenceMemo &&
                        formik.errors.referenceMemo?.toString()) ||
                      'Optional'
                    }
                    id='referenceMemo'
                    inputProps={{ maxLength: 60 }}
                    label='Reference Memo'
                    name='referenceMemo'
                    onChange={formik.handleChange}
                    size='small'
                    value={formik.values.referenceMemo}
                  />

                  <Divider textAlign='left'>Bank Info</Divider>
                  <Card variant='outlined'>
                    <CardContent>
                      <Typography variant='body1'>
                        <b>{formik.values.destinationSelect?.bankInfo?.name}</b>{' '}
                        <br />
                        {formik.values.destinationSelect?.bankInfo?.accountName}
                      </Typography>

                      <Typography variant='body1'>
                        Routing Number:{' '}
                        {formik.values.destinationSelect?.bankInfo
                          ?.routingNumber ?? EMPTY_FIELD_PLACEHOLDER}{' '}
                      </Typography>

                      {selectedDestination(formik.values.destinationSelect?.id)
                        ?.bankInfo.accountNumber === '' ? (
                        <TextField
                          error={
                            formik.touched.destinationSelect?.bankInfo
                              ?.accountNumber &&
                            Boolean(
                              formik.errors.destinationSelect?.bankInfo
                                ?.accountNumber
                            )
                          }
                          helperText={
                            (formik.touched.destinationSelect?.bankInfo
                              ?.accountNumber &&
                              formik.errors.destinationSelect?.bankInfo?.accountNumber?.toString()) ||
                            ''
                          }
                          id='destinationSelect.bankInfo.accountNumber'
                          label='Account Number'
                          name='destinationSelect.bankInfo.accountNumber'
                          onChange={formik.handleChange}
                          required
                          size='small'
                          sx={{ my: 2 }}
                          value={
                            formik.values.destinationSelect?.bankInfo
                              ?.accountNumber
                          }
                        />
                      ) : (
                        <Typography variant='body1'>
                          Account Number:{' '}
                          {formik.values.destinationSelect?.bankInfo
                            ?.accountNumber ?? EMPTY_FIELD_PLACEHOLDER}
                        </Typography>
                      )}
                    </CardContent>
                    {!!formik.errors.destinationSelect?.bankInfo?.name && (
                      <Alert severity='error'>
                        {formik.errors.destinationSelect?.bankInfo?.name}
                      </Alert>
                    )}
                    {!!formik.errors.destinationSelect?.bankInfo
                      ?.accountName && (
                      <Alert severity='error'>
                        {formik.errors.destinationSelect?.bankInfo?.accountName}
                      </Alert>
                    )}
                    {!!formik.errors.destinationSelect?.bankInfo
                      ?.routingNumber && (
                      <Alert severity='error'>
                        {
                          formik.errors.destinationSelect?.bankInfo
                            ?.routingNumber
                        }
                      </Alert>
                    )}
                    {!!formik.errors.destinationSelect?.bankInfo
                      ?.accountNumber && (
                      <Alert severity='error'>
                        {
                          formik.errors.destinationSelect?.bankInfo
                            ?.accountNumber
                        }
                      </Alert>
                    )}
                  </Card>
                </>
              )}
              {['WIRE', 'NONE'].includes(formik.values.disbursementMethod) && (
                <>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    disabled={disableAction}
                    error={
                      formik.touched.creditToName &&
                      Boolean(formik.errors.creditToName)
                    }
                    helperText={
                      (formik.touched.creditToName &&
                        formik.errors.creditToName?.toString()) ||
                      'Optional'
                    }
                    id='creditToName'
                    label='Further Credit To (Name)'
                    name='creditToName'
                    onChange={formik.handleChange}
                    size='small'
                    value={formik.values.creditToName}
                  />
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    disabled={disableAction}
                    error={
                      formik.touched.creditToAccountNumber &&
                      Boolean(formik.errors.creditToAccountNumber)
                    }
                    helperText={
                      (formik.touched.creditToAccountNumber &&
                        formik.errors.creditToAccountNumber?.toString()) ||
                      'Optional'
                    }
                    id='creditToAccountNumber'
                    label='Further Credit To (Account Number)'
                    name='creditToAccountNumber'
                    onChange={formik.handleChange}
                    size='small'
                    value={formik.values.creditToAccountNumber}
                  />
                </>
              )}
              <Divider textAlign='left'>Contacts Info</Divider>
              <>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  disabled={disableAction}
                  error={
                    formik.touched.rkEmail && Boolean(formik.errors?.rkEmail)
                  }
                  helperText={
                    formik.touched.rkEmail && formik.errors?.rkEmail?.toString()
                  }
                  id='rkEmail'
                  label='RK Email'
                  name='rkEmail'
                  onChange={formik.handleChange}
                  size='small'
                  value={formik.values.rkEmail}
                />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  disabled={disableAction}
                  error={
                    formik.touched.clientEmail &&
                    Boolean(formik.errors?.clientEmail)
                  }
                  helperText={
                    formik.touched.clientEmail &&
                    formik.errors?.clientEmail?.toString()
                  }
                  id='clientEmail'
                  label='Client Email'
                  name='clientEmail'
                  onChange={formik.handleChange}
                  size='small'
                  value={formik.values.clientEmail}
                />
              </>
            </Box>
            <LoadingButton
              data-testid='disbursement-instructions-button'
              disabled={disableAction}
              loading={loading}
              sx={{ height: 30, width: 70 }}
              type='submit'
              variant='contained'>
              Save
            </LoadingButton>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default DisbursementInstructions;
