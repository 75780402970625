import { alpha, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useSharedStyles = makeStyles((theme: Theme) => ({
  button: {
    padding: theme.spacing(0.5)
  },
  collapseColumn: {
    backgroundColor: alpha(theme.palette.primary.main, 0.04),
    borderBottomWidth: 0,
    paddingBottom: 0,
    paddingTop: 0
  },
  datePicker: {
    '& .MuiInputLabel-outlined': {
      fontSize: theme.spacing(2),
      lineHeight: theme.spacing(2),
      transform: 'translate(12px, 12px) scale(1)'
    },
    '& .MuiInputLabel-shrink': {
      fontSize: '1rem',
      lineHeight: '1.4375em',
      transform: 'translate(14px, -9px) scale(0.75)'
    },
    backgroundColor: theme.palette.common.white
  },
  inputField: {
    '& .MuiInputBase-input': {
      height: theme.spacing(3),
      padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`
    },
    '& .MuiInputLabel-outlined': {
      fontSize: theme.spacing(2),
      lineHeight: theme.spacing(2),
      transform: 'translate(12px, 12px) scale(1)'
    },
    '& .MuiInputLabel-shrink': {
      fontSize: '1rem',
      lineHeight: '1.4375em',
      transform: 'translate(14px, -9px) scale(0.75)'
    },
    backgroundColor: theme.palette.common.white,
    marginRight: theme.spacing(3),
    maxWidth: 87
  },
  inputTooltip: {
    fontSize: theme.spacing(1.5),
    lineHeight: theme.spacing(1.5),
    margin: 0,
    marginTop: theme.spacing(0.75),
    width: 'max-content'
  },
  openCollapseColumn: {
    borderBottomWidth: 1
  },
  pickerInput: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  }
}));
