import AccessControl from '@/components/access-control/AccessControl.component';
import { CardPlaceholder } from '@/components/card';
import CollapsibleTable from '@/components/collapsible-table';
import { PlanFeaturesDto } from '@/models/PlanFeaturesDto.model';
import { FormattedRolloverDto } from '@/models/RolloversDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import ParticipantService from '@/services/Participant.service';
import { PlanService } from '@/services/Plan.service';
import { LoadingButton } from '@mui/lab';
import { Card, Paper, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';

import React from 'react';
import { useToggle } from 'react-use';

import RolloverNewRequestDialog from './RolloverNewRequestDialog.component';
import RolloverTableCell from './RolloverTableCell.component';

interface RolloverRequestsProps {
  participantId: string;
  rk?: string;
  sponsorPlanId?: number;
}

const RolloverRequests = (props: RolloverRequestsProps): JSX.Element => {
  const headCells = [
    { field: 'id', headerName: 'ID' },
    { field: 'status', headerName: 'Status' },
    { field: 'accountType', headerName: 'Account Type' },
    { field: 'pretaxAmount', headerName: 'Pre-tax Amount' },
    { field: 'rothAmount', headerName: 'Roth Amount' },
    { field: 'createdAt', headerName: 'Created At Date' },
    { field: 'updatedAt', headerName: 'Last Updated Date' }
  ];

  const theme = useTheme();
  const [isNewRolloverOpen, toggleNewRolloverOpen] = useToggle(false);

  const RolloversQuery = useQuery<FormattedRolloverDto[]>(
    ['ParticipantService.getRollovers', props.participantId],
    () => {
      return ParticipantService.getRollovers(+props.participantId);
    },
    {
      enabled: Boolean(props.participantId)
    }
  );

  const planFeaturesQuery = useQuery<PlanFeaturesDto>(
    ['PlanService.getPlanFeatures', props.sponsorPlanId],
    () => {
      if (!props.sponsorPlanId) throw new Error(`keep the compiler happy`);

      return PlanService.getPlanFeatures(props.sponsorPlanId);
    },
    {
      enabled: Boolean(props.sponsorPlanId)
    }
  );

  if (RolloversQuery.isFetching) {
    return <p>Loading....</p>;
  }

  if (!RolloversQuery.isSuccess) {
    return <p>Error</p>;
  }

  const header = (
    <Stack direction='row' justifyContent='space-between'>
      <Typography id='tableTitle' p={2} variant='h5'>
        Rollovers
      </Typography>
      <AccessControl
        requiresOneOf={[FeatureLevelPermissions.WRITE_ROLLOVERS_ACTION]}>
        {props.rk === 'Vestwell Sub-Accounting Platform' && (
          <LoadingButton
            data-testid='create-rollover-request'
            onClick={toggleNewRolloverOpen}>
            NEW ROLLOVER
          </LoadingButton>
        )}
      </AccessControl>
      <RolloverNewRequestDialog
        isOpen={isNewRolloverOpen}
        onClose={toggleNewRolloverOpen}
        participantId={+props.participantId}
        planFeatures={planFeaturesQuery.data}
      />
    </Stack>
  );

  if (!RolloversQuery.data?.length) {
    return (
      <Card
        sx={{
          fontSize: theme.spacing(2.4),
          padding: theme.spacing(2),
          textAlign: 'center'
        }}>
        {header}
        <CardPlaceholder
          data-testid='no-data-rollovers-participant'
          subtitle='No data for this participant'
        />
      </Card>
    );
  }

  return (
    <Paper elevation={0} sx={{ mb: 2, width: '100%' }} variant='outlined'>
      {header}
      <div className='rollovers-table'>
        <CollapsibleTable
          cellComponent={RolloverTableCell}
          columns={headCells}
          tableData={RolloversQuery.data || []}
        />
      </div>
    </Paper>
  );
};

export default RolloverRequests;
