import useHasPermissions from '@/components/access-control/useHasPermissions.hook';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useParticipantSuspiciousActivity } from '@/hooks/useParticipantSuspiciousActivity.hook';
import { useSubaErrors } from '@/hooks/useSubaErrors.hook';
import { WithdrawalResponseDto, WithdrawalStatus } from '@/models';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import {
  normalizeWithdrawalStatus,
  UpdateWithdrawalStatus,
  WithdrawalStatusEnum
} from '@/models/Withdrawals.model';
import ParticipantService from '@/services/Participant.service';
import { Error } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import * as React from 'react';
import { FC, useCallback, useState } from 'react';
import { useToggle } from 'react-use';

import { WithdrawalSkipLocationValidationModal } from './WithdrawalSkipLocationValidationModal';

type WithdrawalActionsProps = {
  distributionMethod: string;
  onSubmit: () => void;
  participantId: string;
  planBlackoutActive: boolean;
  withdrawalId: string;
  withdrawalStatus: WithdrawalStatusEnum;
  tradeRequestStatus?: string;
  missingParticipantAddressinfo: boolean;
};

const missingWithdrawalPermissionTitle = `You are not authorized to perform this action. Missing permission is ${FeatureLevelPermissions.WRITE_WITHDRAWALS_ACTION}`;

export const WithdrawalActions: FC<WithdrawalActionsProps> = (
  props: WithdrawalActionsProps
) => {
  const fraudPerms = useHasPermissions({
    requires: [FeatureLevelPermissions.WRITE_FRAUD]
  });
  const withdrawalPerms = useHasPermissions({
    requires: [FeatureLevelPermissions.WRITE_WITHDRAWALS_ACTION]
  });

  const snackBar = useSnackbar();
  const [isConfirmModalOpen, toogleConfirmModalOpen] = useToggle(false);
  const [showForceSubmitModal, toggleShowForceSubmitModal] = useToggle(false);
  const [sendEmail, toggleSendEmail] = useToggle(true);
  const [hasAddressError, setHasAddressError] = useState(false);
  const [newStatus, setNewStatus]: [
    WithdrawalStatus | undefined,
    React.Dispatch<React.SetStateAction<WithdrawalStatus | undefined>>
  ] = useState();
  const subaErrorsHook = useSubaErrors();
  const suspiciousQuery = useParticipantSuspiciousActivity(props.participantId);
  const suspiciousActivity = suspiciousQuery.data?.hasSuspiciousActivity;

  const statusHandler = (status: WithdrawalStatus) => {
    setNewStatus(status);
    toogleConfirmModalOpen();
  };
  const onCompleteFraudCheck = () => {
    statusHandler(WithdrawalStatusEnum.FraudCheckComplete);
  };
  const onApprove = () => {
    statusHandler(WithdrawalStatusEnum.Processing);
  };
  const onReject = () => statusHandler(WithdrawalStatusEnum.Rejected);
  const onCancel = () => statusHandler(WithdrawalStatusEnum.Canceled);
  const onRevertToPending = () => statusHandler(WithdrawalStatusEnum.Pending);

  const normalizedWithdrawalStatus = normalizeWithdrawalStatus(
    props.withdrawalStatus
  );

  const enableCancelReverseDisburse =
    normalizedWithdrawalStatus === WithdrawalStatusEnum.Processing &&
    (props.tradeRequestStatus === undefined ||
      [
        'FAILED',
        'SUBA_FAILED',
        'SUBA_ABORTED',
        'SUBA_TIMED-OUT',
        'CANCELED'
      ].includes(props.tradeRequestStatus));

  const withdrawalStatusMutation = useMutation(
    [
      'ParticipantService.updateWithdrawalStatus',
      props.participantId,
      props.withdrawalId
    ],
    (updateWithdrawalStatus: UpdateWithdrawalStatus) => {
      return updateWithdrawalStatus.status ===
        WithdrawalStatusEnum.FraudCheckComplete
        ? ParticipantService.completeWithdrawalFraudCheck(+props.withdrawalId)
        : ParticipantService.updateWithdrawalStatus(
            +props.participantId,
            +props.withdrawalId,
            updateWithdrawalStatus.status,
            updateWithdrawalStatus.forceSkipLocationValidation,
            sendEmail
          );
    },
    {
      onError: () => {
        snackBar.showSnackbar({
          message: `Failed to update the withdrawal status!`,
          severity: 'error'
        });
      },
      onSuccess: (data, variables) => {
        if (!data.data.attributes.validationErrors?.length) {
          snackBar.showSnackbar({
            message: `Successfully updated status to ${variables.status}`,
            severity: 'success'
          });
          props.onSubmit();
        }
      }
    }
  );

  const onConfirm = useCallback(() => {
    withdrawalStatusMutation.mutate(
      {
        forceSkipLocationValidation: false,
        status: newStatus
      },
      {
        onSettled: () => toogleConfirmModalOpen(),
        onSuccess: data => {
          if (newStatus === 'processing') {
            checkSubmission(data);
          }
          toggleSendEmail(true);
        }
      }
    );
  }, [newStatus]);

  const checkSubmission = (response: WithdrawalResponseDto) => {
    if (
      !response.data.attributes.submitted &&
      response.data.attributes.validationErrors?.length > 0
    ) {
      if (
        response.data.attributes.validationErrors.every(validationError =>
          validationError.source.includes('disbursementAddress')
        )
      ) {
        subaErrorsHook.checkSubaErrors(
          response.data.attributes.validationErrors
        );
        toggleShowForceSubmitModal();
        setHasAddressError(true);
      } else setHasAddressError(false);
    }
  };

  const onForceSubmit = () => {
    withdrawalStatusMutation.mutate({
      forceSkipLocationValidation: true,
      status: 'processing'
    });
    toggleShowForceSubmitModal();
  };

  return (
    <>
      <Stack alignItems='center' direction='row' spacing={2}>
        {normalizedWithdrawalStatus === WithdrawalStatusEnum.Pending &&
          props.distributionMethod !== 'CUSTOM' && (
            <>
              <Tooltip
                arrow
                disableHoverListener={fraudPerms.isAllowed}
                title={`You are not authorized to perform this action. Missing permission is ${FeatureLevelPermissions.WRITE_FRAUD}`}>
                <span>
                  <Button
                    color='primary'
                    data-testid='withdrawal-completefraudcheck-btn'
                    disabled={!fraudPerms.isAllowed}
                    onClick={onCompleteFraudCheck}
                    variant='outlined'>
                    Complete Fraud Check
                  </Button>
                </span>
              </Tooltip>
              <Tooltip
                arrow
                disableHoverListener={withdrawalPerms.isAllowed}
                title={missingWithdrawalPermissionTitle}>
                <span>
                  <Button
                    color='primary'
                    data-testid='withdrawal-reject-btn'
                    disabled={!withdrawalPerms.isAllowed}
                    onClick={onReject}
                    variant='outlined'>
                    Reject
                  </Button>
                </span>
              </Tooltip>
            </>
          )}
        {normalizedWithdrawalStatus ===
          WithdrawalStatusEnum.FraudCheckComplete &&
          props.distributionMethod !== 'CUSTOM' && (
            <>
              <Tooltip
                disableHoverListener={
                  withdrawalPerms.isAllowed &&
                  !props.planBlackoutActive &&
                  !props.missingParticipantAddressinfo
                }
                title={
                  !withdrawalPerms.isAllowed
                    ? missingWithdrawalPermissionTitle
                    : props.planBlackoutActive
                      ? 'Plan is in blackout period.'
                      : props.missingParticipantAddressinfo
                        ? 'Participant is missing address info.'
                        : ''
                }>
                <span>
                  <Button
                    color='primary'
                    data-testid='withdrawal-approve-btn'
                    disabled={
                      !withdrawalPerms.isAllowed ||
                      props.planBlackoutActive ||
                      suspiciousActivity ||
                      props.missingParticipantAddressinfo
                    }
                    onClick={onApprove}
                    variant='outlined'>
                    Approve
                  </Button>
                </span>
              </Tooltip>
              <Tooltip
                arrow
                disableHoverListener={withdrawalPerms.isAllowed}
                title={missingWithdrawalPermissionTitle}>
                <span>
                  <Button
                    color='primary'
                    data-testid='withdrawal-reject-btn'
                    disabled={!withdrawalPerms.isAllowed}
                    onClick={onReject}
                    variant='outlined'>
                    Reject
                  </Button>
                </span>
              </Tooltip>
            </>
          )}
        {([
          WithdrawalStatusEnum.Pending,
          WithdrawalStatusEnum.FraudCheckComplete
        ].includes(normalizedWithdrawalStatus) ||
          enableCancelReverseDisburse) && (
          <Tooltip
            arrow
            disableHoverListener={withdrawalPerms.isAllowed}
            title={missingWithdrawalPermissionTitle}>
            <span>
              <Button
                color='error'
                data-testid='withdrawal-cancel-btn'
                disabled={!withdrawalPerms.isAllowed}
                onClick={onCancel}
                variant='outlined'>
                Cancel
              </Button>
            </span>
          </Tooltip>
        )}
        {enableCancelReverseDisburse && (
          <Tooltip
            arrow
            disableHoverListener={withdrawalPerms.isAllowed}
            title={missingWithdrawalPermissionTitle}>
            <span>
              <Button
                color='primary'
                data-testid='withdrawal-revert-btn'
                disabled={!withdrawalPerms.isAllowed}
                onClick={onRevertToPending}
                variant='outlined'>
                Revert to Pending
              </Button>
            </span>
          </Tooltip>
        )}
        {withdrawalStatusMutation.data?.data?.attributes?.validationErrors
          ?.length > 0 &&
          !hasAddressError && (
            <Tooltip
              disableFocusListener
              sx={{ maxWidth: 500 }}
              title={
                <React.Fragment>
                  <Typography
                    component='pre'
                    sx={{
                      fontSize: 12,
                      whiteSpace: 'pre-wrap',
                      wordWrap: 'break-word'
                    }}>
                    {JSON.stringify(
                      withdrawalStatusMutation.data?.data?.attributes
                        ?.validationErrors,
                      null,
                      2
                    )}
                  </Typography>
                </React.Fragment>
              }>
              <Error color='error' sx={{ ml: 1 }} />
            </Tooltip>
          )}
      </Stack>
      <Dialog onClose={toogleConfirmModalOpen} open={isConfirmModalOpen}>
        <DialogTitle>Update Withdrawal</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Typography variant='body1'>
              Are you sure you want to change the status to {newStatus}?
            </Typography>
            {newStatus !== WithdrawalStatusEnum.FraudCheckComplete && (
              <FormControlLabel
                control={
                  <Checkbox checked={sendEmail} onChange={toggleSendEmail} />
                }
                label='Send email to participant'
              />
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            disabled={!newStatus}
            loading={withdrawalStatusMutation.isLoading}
            onClick={onConfirm}
            variant='outlined'>
            CONFIRM
          </LoadingButton>
          <Button
            data-testid='tpa-plans-modal-cancel-btn'
            onClick={toogleConfirmModalOpen}>
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
      <WithdrawalSkipLocationValidationModal
        backendErrors={subaErrorsHook.subaErrors}
        isLoading={withdrawalStatusMutation.isLoading}
        showModal={showForceSubmitModal}
        showModalStateHandler={toggleShowForceSubmitModal}
        submitHandler={onForceSubmit}
      />
    </>
  );
};
