import { ConversionStatus } from '@/models/ConversionDTO.model';

export const getStatusBtnColor = (
  status: string
): { bg: string; color: string } => {
  switch (status) {
    case ConversionStatus.Draft:
      return {
        bg: '#EEEEEE',
        color: '#757575'
      };
    case ConversionStatus.PendingApproval:
    case ConversionStatus.PendingInvestment:
    case ConversionStatus.CompletedWithErrors:
      return {
        bg: '#fff5e6',
        color: '#C77700'
      };
    case 'SUCCEEDED':
    case 'CONFIRMED':
    case ConversionStatus.Complete:
    case ConversionStatus.Processed:
      return {
        bg: '#edf7ee',
        color: '#3e8e41'
      };
    case ConversionStatus.Failed:
      return {
        bg: '#fbeaea',
        color: '#D32F2F'
      };
    default:
      return {
        bg: '#E3F2FD',
        color: '#0288d1'
      };
  }
};
