import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class AutoEscalateMaximum {
  convertToApiState = Helper.convertValueFromNumericStringToNumber;

  disabled: boolean;

  setDisabled = (value: boolean): void => {
    this.disabled = value;
  };

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.disabled = !props.autoEscalateAmount;
    this.output = Helper.addPercentSign(props.autoEscalateMaximum);
  }
}

export default AutoEscalateMaximum;
