import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { PooledPlan } from '@/models/PooledPlanDTO.model';
import {
  ScheduledChange,
  ScheduledChangePayloadItem
} from '@/models/ScheduledChangesDTO.model';
import { getPlanTemplate } from '@/routes/plans/plan-detail/PlanTab/utils';
import { PlanService } from '@/services/Plan.service';
import formatters from '@/utils/Formatters';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  LinearProgress,
  Stack,
  Tab,
  Tabs,
  Theme,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import TablePagination from '@mui/material/TablePagination';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQuery } from '@tanstack/react-query';

import dayjs from 'dayjs';
import { startCase } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

import AccessControl from '../access-control/AccessControl.component';
import useHasPermissions from '../access-control/useHasPermissions.hook';
import Drawer from '../drawer/Drawer.component';
import { useGetPlanScheduledChanges } from './useGetPlanScheduledChanges';
import { useScheduledChangePayloadConfig } from './useScheduledChangePayloadConfig';
import {
  getUpdatedPayloadUnschedulePayloadItemAndErrors,
  permissionsByChangeType
} from './utils';

type ScheduledChangesDrawerProps = {
  open: boolean;
  onClose: () => void;
  planId: number;
  pooledPlanData?: PooledPlan;
  isEsa?: boolean;
  isPooledPlan?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    marginLeft: 'auto',
    minWidth: '0px',
    padding: '0px !important'
  },
  newValueContainer: {
    display: 'flex',
    paddingBottom: 5,
    paddingTop: 5
  },

  newValueContainerEditable: {
    '&:hover': {
      '& $deleteButton': {
        visibility: 'visible !important'
      },
      backgroundColor: theme.palette.grey[50]
    },
    display: 'flex',
    paddingBottom: 5,
    paddingTop: 5
  }
}));

const HISTORY_PAGE_SIZE = 5;

export const ScheduledChangesDrawer = (
  props: ScheduledChangesDrawerProps
): JSX.Element => {
  const classes = useStyles();

  const [page, setPage] = useState(1);
  const [tab, setTab] = useState(0);
  const [openUnscheduleDialog, setOpenUnscheduleDialog] = useState(false);
  const [selectedUnschedulePayloadItem, setSelectedUnschedulePayloadItem] =
    useState<ScheduledChangePayloadItem | null>();
  const [selectedScheduleChange, setSelectedScheduleChange] =
    useState<ScheduledChange | null>();

  const { showSnackbar } = useSnackbar();

  const scheduledChangePayloadConfig = useScheduledChangePayloadConfig(
    props.planId
  );

  const scheduledChanges = useGetPlanScheduledChanges({
    planId: +props.planId
  });
  const scheduledChangesHistory = useGetPlanScheduledChanges({
    applied: true,
    enabled: tab === 1,
    planId: +props.planId
  });

  const planDesign = useQuery(
    ['PlanService.getPlanDesignById', +props.planId],
    () => {
      return PlanService.getPlanDesignById(props.planId);
    },
    {
      enabled: Boolean(props.planId) && !props.isPooledPlan
    }
  );

  const planTemplate = getPlanTemplate({
    isEsa: props.isEsa,
    isPooledPlan: props.isPooledPlan,
    planDesignData: planDesign.data,
    pooledPlanData: props.pooledPlanData
  });

  const permissions = useHasPermissions({
    hideFromTPA: dayjs().isAfter(planTemplate?.vestwellStartDate?.output)
  });

  const updateScheduleChange = useMutation(
    ['PlanService.updateScheduleChange'],
    (params: { scheduleChangeId: number; scheduledChange: ScheduledChange }) =>
      PlanService.updateScheduleChange(
        props.planId,
        params.scheduleChangeId,
        params.scheduledChange
      ),
    {
      onError: () => {
        showSnackbar({
          message: 'Error!',
          severity: 'error'
        });
      },
      onSuccess: (_data, variables) => {
        scheduledChanges.invalidate(variables.scheduledChange.changeType);
        showSnackbar({
          message: 'Scheduled Change updated!',
          severity: 'success'
        });
      }
    }
  );

  const deleteScheduleChange = useMutation(
    ['PlanService.deleteScheduleChange'],
    (params: {
      scheduleChangeId: number;
      changeType: ScheduledChange['changeType'];
    }) =>
      PlanService.deleteScheduleChange(props.planId, params.scheduleChangeId),
    {
      onError: () => {
        showSnackbar({
          message: 'Error!',
          severity: 'error'
        });
      },
      onSuccess: (_data, variables) => {
        scheduledChanges.invalidate(variables.changeType);
        showSnackbar({
          message: 'Scheduled Change deleted!',
          severity: 'success'
        });
      }
    }
  );

  const sortedScheduledChanges = useMemo(
    () =>
      (
        (tab === 0 ? scheduledChanges.data : scheduledChangesHistory.data) || []
      ).sort((scheduledChangeA, scheduledChangeB) => {
        const effectiveDateA = new Date(
          scheduledChangeA.effectiveDate
        ).getTime();
        const effectiveDateB = new Date(
          scheduledChangeB.effectiveDate
        ).getTime();

        return tab === 0
          ? effectiveDateA - effectiveDateB
          : effectiveDateB - effectiveDateA;
      }),
    [scheduledChangesHistory.data, tab, scheduledChanges.data]
  );

  const scheduledChangesHistoryPaged = useMemo(
    () =>
      sortedScheduledChanges.slice(
        (page - 1) * HISTORY_PAGE_SIZE,
        page * HISTORY_PAGE_SIZE
      ) || [],
    [sortedScheduledChanges, page]
  );

  const handleOpenUnscheduleDialog = useCallback(
    (
      scheduledChange: ScheduledChange,
      unschedulePayload?: ScheduledChangePayloadItem
    ) => {
      setSelectedUnschedulePayloadItem(unschedulePayload);
      setSelectedScheduleChange(scheduledChange);
      setOpenUnscheduleDialog(true);
    },
    []
  );

  const handleCloseUnscheduleDialog = useCallback(() => {
    setOpenUnscheduleDialog(false);
  }, []);

  const handleSubmitUnscheduleDialog = useCallback(async () => {
    const payload = selectedScheduleChange.payload.filter(
      payloadItem => payloadItem.uiLabel
    );

    if (
      selectedUnschedulePayloadItem &&
      selectedScheduleChange &&
      payload.length > 1
    ) {
      const result = await getUpdatedPayloadUnschedulePayloadItemAndErrors(
        selectedScheduleChange,
        selectedUnschedulePayloadItem,
        scheduledChangePayloadConfig
      );

      if (result.errors[selectedUnschedulePayloadItem?.fieldName]) {
        showSnackbar({
          message:
            'This field cannot be deleted, please delete the whole schedule change',
          severity: 'error'
        });
      } else if (
        !result.updatedScheduledChange.payload.filter(
          payloadItem => payloadItem.uiLabel
        ).length
      ) {
        await deleteScheduleChange.mutateAsync({
          changeType: selectedScheduleChange.changeType,
          scheduleChangeId: selectedScheduleChange.id
        });
      } else {
        await updateScheduleChange.mutateAsync({
          scheduleChangeId: selectedScheduleChange.id,
          scheduledChange: result.updatedScheduledChange
        });
      }
    } else if (selectedScheduleChange) {
      await deleteScheduleChange.mutateAsync({
        changeType: selectedScheduleChange.changeType,
        scheduleChangeId: selectedScheduleChange.id
      });
    }

    setOpenUnscheduleDialog(false);
  }, [selectedScheduleChange, selectedUnschedulePayloadItem]);

  const handlePageChange = useCallback(
    (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage + 1);
    },
    []
  );

  const handleTabChange = useCallback(
    (_event: React.SyntheticEvent, newValue: number) => {
      setTab(newValue);
      setPage(1);
    },
    []
  );

  const formatText = payload => {
    if (typeof payload.createdBy === 'string') {
      return ` ${payload.createdBy} at ${formatters.formatFromIsoDateCustom(
        payload.createdAt,
        'MM/DD/YYYY HH:mm:ss'
      )}`;
    }

    return ` ${payload.createdBy.id} at ${formatters.formatFromIsoDateCustom(
      payload.createdAt,
      'MM/DD/YYYY HH:mm:ss'
    )}`;
  };

  return (
    <Drawer
      data-testid='scheduled-changes-drawer'
      hideFabIcon
      onClose={props.onClose}
      open={props.open}>
      <Dialog
        data-testid='scheduled-changes-unschedule-dialog'
        fullWidth
        maxWidth='sm'
        onClose={handleCloseUnscheduleDialog}
        open={openUnscheduleDialog}>
        <DialogTitle>
          Unschedule "
          {selectedUnschedulePayloadItem?.uiLabel ||
            startCase(selectedScheduleChange?.changeType.replaceAll('_', ' '))}
          "?
        </DialogTitle>
        <DialogContent>
          {!selectedUnschedulePayloadItem && selectedScheduleChange && (
            <Typography
              color={theme => theme.palette.text.primary}
              data-testid='scheduled-changes-unschedule-dialog-effective-date'
              mb={2}
              variant='body2'>
              Effective Date:{' '}
              {formatters.formatDateWithFromNow(
                selectedScheduleChange.effectiveDate
              )}
            </Typography>
          )}
          <Alert icon={false} severity='warning'>
            <Stack
              alignItems='center'
              data-testid='scheduled-changes-unschedule-dialog-warning'
              direction='row'
              justifyContent='center'
              spacing={1}>
              <WarningAmberIcon
                fontSize='small'
                sx={{ color: theme => theme.palette.warning.dark }}
              />
              <div>
                {selectedUnschedulePayloadItem
                  ? `Deleting a single field out of a set could cause errors that prevent
            the changes from being applied on the effective date. Please review
            carefully.`
                  : 'This change cannot be undone.'}
              </div>
            </Stack>
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button
            data-testid='unschedule-dialog-cancel-button'
            disabled={
              updateScheduleChange.isLoading || deleteScheduleChange.isLoading
            }
            onClick={handleCloseUnscheduleDialog}>
            Cancel
          </Button>
          <LoadingButton
            autoFocus
            data-testid='unschedule-dialog-unschedule-button'
            loading={
              updateScheduleChange.isLoading || deleteScheduleChange.isLoading
            }
            onClick={handleSubmitUnscheduleDialog}
            variant='contained'>
            Unschedule
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Box display='flex' flexDirection='column' height='100%'>
        <Typography p={2} variant='h6'>
          Scheduled Changes
        </Typography>
        <Tabs
          data-testid='scheduled-changes-drawer-tabs'
          onChange={handleTabChange}
          value={tab}>
          <Tab
            data-testid='scheduled-changes-drawer-tabs-scheduled'
            label='Scheduled'
          />
          <Tab
            data-testid='scheduled-changes-drawer-tabs-history'
            label='History'
          />
        </Tabs>
        {!sortedScheduledChanges.length ? (
          <Box
            alignItems='center'
            bgcolor={grey[100]}
            data-testid='scheduled-changes-drawer-no-data'
            display='flex'
            flexGrow={1}
            justifyContent='center'>
            <Typography variant='body1'>No scheduled changes</Typography>
          </Box>
        ) : (
          <Box
            bgcolor={grey[100]}
            data-testid='scheduled-changes-drawer-data'
            flexGrow={1}
            overflow='auto'
            p={2}>
            {scheduledChangesHistory.isFetching && <LinearProgress />}
            {(tab === 0
              ? sortedScheduledChanges
              : scheduledChangesHistoryPaged
            ).map((scheduledChange, index) => (
              <Card
                data-testid={`scheduled-changes-drawer-card-${scheduledChange.id}`}
                key={scheduledChange.id}
                sx={{ mt: index === 0 ? 0 : 2 }}>
                <CardContent sx={{ mb: 1, pt: 2 }}>
                  <Stack spacing={1}>
                    <Box display='flex'>
                      <Typography
                        data-testid='card-change-type'
                        fontWeight={600}
                        variant='subtitle1'>
                        {startCase(
                          scheduledChange.changeType.replaceAll('_', ' ')
                        )}
                      </Typography>

                      {!scheduledChange.applied && (
                        <AccessControl
                          requiresOneOf={[
                            permissionsByChangeType[scheduledChange.changeType]
                          ]}>
                          <Button
                            aria-label='delete'
                            className={classes.deleteButton}
                            data-testid='card-delete-button'
                            disabled={permissions.isForbidden}
                            onClick={() =>
                              handleOpenUnscheduleDialog(scheduledChange)
                            }
                            size='small'>
                            <DeleteOutlineIcon
                              color={
                                permissions.isForbidden ? 'disabled' : 'primary'
                              }
                              fontSize='small'
                            />
                          </Button>
                        </AccessControl>
                      )}
                    </Box>

                    <Typography
                      color={theme => theme.palette.text.primary}
                      data-testid='card-effective-date'
                      variant='body2'>
                      Effective Date:{' '}
                      {formatters.formatDateWithFromNow(
                        scheduledChange.effectiveDate
                      )}
                    </Typography>
                    <Typography
                      color={theme => theme.palette.text.primary}
                      data-testid='card-scheduled-by'
                      variant='body2'>
                      Scheduled by
                      {formatText(scheduledChange)}
                    </Typography>
                    {typeof scheduledChange.createdBy !== 'string' && (
                      <Typography
                        color={theme => theme.palette.text.primary}
                        data-testid='card-auth-role'
                        variant='body2'>
                        {scheduledChange.createdBy.authRole === 'tpa'
                          ? `Role: ${scheduledChange.createdBy.authRole.toUpperCase()}`
                          : `Role: ${formatters.capitalizeFirstChar(
                              scheduledChange.createdBy.authRole
                            )}`}
                      </Typography>
                    )}
                    <Divider />
                    <Typography
                      color={theme => theme.palette.text.primary}
                      fontWeight={500}
                      variant='body2'>
                      New Values
                    </Typography>

                    {scheduledChange.payload
                      .filter(payloadItem => payloadItem.uiLabel)
                      .map(payloadItem => (
                        <Grid
                          className={
                            permissions.isForbidden
                              ? classes.newValueContainer
                              : !scheduledChange.applied
                                ? classes.newValueContainerEditable
                                : null
                          }
                          container
                          data-testid={`card-new-value-${payloadItem.fieldName}`}
                          key={payloadItem.fieldName}>
                          <Grid xs={6}>
                            <Typography
                              data-testid='new-value-ui-label'
                              fontWeight={400}
                              variant='body2'>
                              {payloadItem.uiLabel}
                            </Typography>
                          </Grid>
                          <Grid xs={5}>
                            <Typography
                              color={theme =>
                                payloadItem.uiScheduledValue &&
                                payloadItem.uiScheduledValue !==
                                  EMPTY_FIELD_PLACEHOLDER
                                  ? theme.palette.text.primary
                                  : theme.palette.text.disabled
                              }
                              data-testid='new-value-value'
                              fontWeight={400}
                              variant='body2'>
                              {payloadItem.uiScheduledValue &&
                              payloadItem.uiScheduledValue !==
                                EMPTY_FIELD_PLACEHOLDER
                                ? payloadItem.uiScheduledValue
                                : '(unset)'}
                            </Typography>
                          </Grid>

                          <AccessControl
                            requiresOneOf={[
                              permissionsByChangeType[
                                scheduledChange.changeType
                              ]
                            ]}>
                            <Button
                              aria-label='delete'
                              className={classes.deleteButton}
                              data-testid={`card-delete-${payloadItem.fieldName}-button`}
                              onClick={() =>
                                handleOpenUnscheduleDialog(
                                  scheduledChange,
                                  payloadItem
                                )
                              }
                              size='small'
                              sx={{
                                visibility: scheduledChange.applied
                                  ? 'hidden !important'
                                  : 'hidden'
                              }}>
                              <DeleteOutlineIcon
                                color='primary'
                                fontSize='small'
                              />
                            </Button>
                          </AccessControl>
                        </Grid>
                      ))}
                    {scheduledChange.updatedAt && !scheduledChange.applied && (
                      <Alert
                        data-testid='card-modified-fields-warning'
                        icon={false}
                        severity='warning'>
                        <Stack
                          alignItems='center'
                          direction='row'
                          justifyContent='center'
                          spacing={1}>
                          <WarningAmberIcon
                            fontSize='small'
                            sx={{
                              color: theme => theme.palette.warning.dark
                            }}
                          />
                          <div>
                            Some fields has been modified, please review to
                            ensure it would still make sense.
                          </div>
                        </Stack>
                      </Alert>
                    )}
                  </Stack>
                </CardContent>
              </Card>
            ))}
          </Box>
        )}
        {tab === 1 && (
          <Box>
            <TablePagination
              component='div'
              count={scheduledChangesHistory.data.length || 0}
              data-testid='scheduled-changes-drawer-pagination'
              labelDisplayedRows={paginationInfo => (
                <Typography
                  color={theme => theme.palette.text.primary}
                  flexGrow='1'
                  variant='body2'>
                  Showing {paginationInfo.from} - {paginationInfo.to} of{' '}
                  {paginationInfo.count} scheduled changes
                </Typography>
              )}
              onPageChange={handlePageChange}
              page={page - 1}
              rowsPerPage={HISTORY_PAGE_SIZE}
              rowsPerPageOptions={[HISTORY_PAGE_SIZE]}
            />
          </Box>
        )}
      </Box>
    </Drawer>
  );
};
