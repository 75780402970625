import DatePicker from '@/components/date-picker';
import SimpleDropdown from '@/components/simple-dropdown';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { PlanFeaturesDto } from '@/models/PlanFeaturesDto.model';
import { RolloverCreateDto } from '@/models/RolloversDTO.model';
import ParticipantService from '@/services/Participant.service';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Field, Form, Formik } from 'formik';
import { FC, useRef } from 'react';
import * as yup from 'yup';

import { NumericDollarFormat } from '../NumericDollarFormat';

interface RolloverNewRequestDialogProps {
  isOpen: boolean;
  onClose: () => void;
  participantId: number;
  planFeatures: PlanFeaturesDto;
}

const validateForm = yup.object({
  accountProvider: yup.string().required('Required'),
  accountType: yup
    .string()
    .oneOf([
      '401(k)',
      'IRA',
      '403(b)',
      '457(b)',
      'SIMPLE IRA',
      'SEP IRA',
      'TSP',
      'Qualified Retirement Plan'
    ])
    .required('Required'),
  amount: yup.number().moreThan(0).required('Required'),
  fundType: yup
    .string()
    .when('accountType', {
      is: value =>
        [
          'IRA',
          'SIMPLE IRA',
          'SEP IRA',
          'Qualified Retirement Plan',
          'TSP'
        ].includes(value),
      then: yup.string().oneOf(['Pre-Tax']).required('Required')
    })
    .when('accountType', {
      is: value => ['401(k)', '403(b)', '457(b)'].includes(value),
      then: yup.string().oneOf(['Pre-Tax', 'Roth']).required('Required')
    })
    .required('Required'),
  rothCostBasis: yup.number().when('fundType', {
    is: value => value === 'Roth',
    then: yup.number().moreThan(0).required('Required')
  }),
  rothStartDate: yup.string().when('fundType', {
    is: value => value === 'Roth',
    then: yup
      .string()
      .matches(/^\d{4}-\d{2}-\d{2}$/, 'Must be in MM/DD/YYYY format.')
      .required('Required')
  })
});

const RolloverNewRequestDialog: FC<RolloverNewRequestDialogProps> = props => {
  const formRef = useRef<any>();
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();

  const createRolloverMutation = useMutation(
    ['ParticipantService.postParticipantRollover'],
    (data: RolloverCreateDto) => {
      return ParticipantService.postParticipantRollover(data);
    },
    {
      onError: () => {
        snackbar.showSnackbar({
          message: 'Failed to create a new rollover request!',
          severity: 'error'
        });
      },
      onSuccess: async () => {
        snackbar.showSnackbar({
          message: 'Successfully submitted a new rollover request!',
          severity: 'success'
        });

        queryClient.invalidateQueries();
        props.onClose();
      }
    }
  );

  return (
    <Dialog fullWidth maxWidth='md' open={props.isOpen}>
      <Formik
        initialValues={{
          accountProvider: undefined,
          accountType: undefined,
          amount: undefined,
          fundType: undefined,
          participantId: props.participantId,
          rothCostBasis: undefined,
          rothStartDate: undefined
        }}
        innerRef={formRef}
        onSubmit={async data =>
          await createRolloverMutation.mutateAsync({
            accountProvider: data.accountProvider,
            accountType: data.accountType,
            fundType: data.fundType,
            participantId: props.participantId,
            preTaxAmount: data.fundType === 'Pre-Tax' ? data.amount : undefined,
            rothAmount: data.fundType === 'Roth' ? data.amount : undefined,
            rothCostBasis:
              data.fundType === 'Roth' ? data.rothCostBasis : undefined,
            rothStartDate:
              data.fundType === 'Roth' ? data.rothStartDate : undefined
          } as RolloverCreateDto)
        }
        validationSchema={validateForm}>
        {formik => (
          <>
            <DialogContent
              sx={{
                p: 2,
                pb: 0.5
              }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Form data-testid='filter-form'>
                  <Typography mb={2} variant='h6'>
                    New Rollover Request
                  </Typography>
                  <Divider sx={{ mb: 2 }} textAlign='left'>
                    Previous Account
                  </Divider>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <SimpleDropdown
                        fieldId='accountType'
                        fieldName='Account Type'
                        fieldOptions={[
                          { option: '401(k)', value: '401(k)' },
                          { option: 'Traditional IRA', value: 'IRA' },
                          { option: '403(b)', value: '403(b)' },
                          { option: '457(b)', value: '457(b)' },
                          { option: 'SIMPLE IRA', value: 'SIMPLE IRA' },
                          { option: 'SEP-IRA', value: 'SEP IRA' },
                          { option: 'Thrift Savings Plan', value: 'TSP' },
                          {
                            option: 'Qualified Retirement Plan (non 401k)',
                            value: 'Qualified Retirement Plan'
                          }
                        ]}
                        fullWidth
                        onChange={event => {
                          formik.setFieldValue(
                            'accountType',
                            event.target.value
                          );
                          formik.setFieldValue('fundType', '');
                        }}
                        size='small'
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        error={
                          formik.touched.accountProvider &&
                          Boolean(formik.errors.accountProvider)
                        }
                        fullWidth
                        helperText={
                          (formik.touched.accountProvider &&
                            formik.errors.accountProvider?.toString()) ||
                          ''
                        }
                        id='accountProvider'
                        label='Previous Provider Name'
                        name='accountProvider'
                        onChange={formik.handleChange}
                        size='small'
                        value={formik.values.accountProvider}
                      />
                    </Grid>
                  </Grid>
                  <Divider sx={{ my: 2 }} textAlign='left'>
                    Amount
                  </Divider>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <SimpleDropdown
                        fieldId='fundType'
                        fieldName='Fund Type'
                        fieldOptions={[
                          { option: 'Pre-Tax', value: 'Pre-Tax' },
                          (['401(k)', '403(b)', '457(b)'].includes(
                            formik.values.accountType
                          ) ||
                            (formik.values.accountType === 'TSP' &&
                              props.planFeatures.data?.attributes
                                ?.isRothIraContributionAllowed)) &&
                            props.planFeatures.data?.attributes
                              ?.isSalaryRothDeferral && {
                              option: 'Roth',
                              value: 'Roth'
                            }
                        ].filter(Boolean)}
                        fullWidth
                        onChange={event => {
                          formik.setFieldValue('fundType', event.target.value);
                        }}
                        size='small'
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        InputProps={{
                          inputComponent: NumericDollarFormat as any,
                          startAdornment: (
                            <InputAdornment position='start'>$</InputAdornment>
                          )
                        }}
                        error={
                          formik.touched.amount && Boolean(formik.errors.amount)
                        }
                        fullWidth
                        helperText={
                          (formik.touched.amount &&
                            formik.errors.amount?.toString()) ||
                          ''
                        }
                        id='amount'
                        label='Amount'
                        name='amount'
                        onChange={formik.handleChange}
                        required
                        size='small'
                        value={formik.values.amount}
                      />
                    </Grid>
                    {formik.values?.fundType === 'Roth' && (
                      <>
                        <Grid item xs={6}>
                          <TextField
                            InputProps={{
                              inputComponent: NumericDollarFormat as any,
                              startAdornment: (
                                <InputAdornment position='start'>
                                  $
                                </InputAdornment>
                              )
                            }}
                            error={
                              formik.touched.rothCostBasis &&
                              Boolean(formik.errors.rothCostBasis)
                            }
                            fullWidth
                            helperText='The total Roth contributions made to participant’s previous account, not including any gains or losses.'
                            id='rothCostBasis'
                            label='Roth Cost Basis'
                            name='rothCostBasis'
                            onChange={formik.handleChange}
                            size='small'
                            value={formik.values.rothCostBasis}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <Field
                              as={DatePicker}
                              data-testid='roth-start-date-input'
                              label='Roth Start Date'
                              name='rothStartDate'
                              size='small'
                              variant='outlined'
                            />
                            {formik.errors.rothStartDate && (
                              <FormHelperText error>
                                {formik.errors.rothStartDate?.toString()}
                              </FormHelperText>
                            )}
                            <FormHelperText>
                              The date Roth contributions were first made to
                              participant’s previous Roth account.
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Form>
              </LocalizationProvider>
            </DialogContent>
            <DialogActions sx={{ padding: '1.5rem' }}>
              <Button
                color='primary'
                onClick={() => props.onClose()}
                variant='text'>
                CANCEL
              </Button>
              <LoadingButton
                loading={createRolloverMutation.isLoading}
                onClick={() => formik.submitForm()}
                variant='contained'>
                SUBMIT
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default RolloverNewRequestDialog;
