import formatters from '@/utils/Formatters';
import { Box, Theme, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import React from 'react';

interface CardIconInfoFieldProps {
  title?: string;
  subtitle?: string | string[];
  icon: React.ReactElement;
  tooltip: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.grey[500],
    margin: '0 10px'
  }
}));

const CardIconInfoField: React.FunctionComponent<CardIconInfoFieldProps> = ({
  title,
  subtitle,
  icon,
  tooltip
}) => {
  const dataTestIdName = formatters.textToDataTestId(tooltip);
  const classes = useStyles();

  const subtitlesArray = Array.isArray(subtitle) ? subtitle : [subtitle];
  return (
    <Box
      data-testid={`card-icon-info-field-${dataTestIdName}-container`}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
      }}>
      <Tooltip
        data-testid={`card-icon-info-field-${dataTestIdName}-tooltip`}
        title={tooltip}>
        <Box
          className={classes.icon}
          data-testid={`card-icon-info-field-${dataTestIdName}-icon`}>
          {icon}
        </Box>
      </Tooltip>

      <div>
        {title && (
          <Typography
            data-testid={`card-icon-info-field-${dataTestIdName}-title`}
            sx={{ fontSize: '1.2rem', fontWeight: '500' }}>
            {title}
          </Typography>
        )}

        {subtitle &&
          subtitlesArray.map((line, index) => (
            <Typography
              data-testid={`card-icon-info-field-${dataTestIdName}-subtitle-line-${index}`}
              key={line}
              sx={{ fontSize: '1rem', fontWeight: '400' }}>
              {line}
            </Typography>
          ))}
      </div>
    </Box>
  );
};
export default CardIconInfoField;
