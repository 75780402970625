import formatters from '@/utils/Formatters';
import { Box, TextField } from '@mui/material';

import React, { forwardRef, useCallback, useState } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { useUpdateEffect } from 'react-use';

import { AgGridEditor } from './AgGridEditor.component';

const NumericFormatCustom = React.forwardRef<
  NumericFormatProps,
  { onChange: (data: any) => void }
>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  const validate = useCallback(
    ({ floatValue }) => (floatValue ?? 0) < 1000000,
    []
  );

  return (
    <NumericFormat
      {...other}
      allowNegative={false}
      decimalScale={2}
      defaultValue={0}
      getInputRef={ref}
      isAllowed={validate}
      onValueChange={onChange}
      prefix='$'
      thousandSeparator
      valueIsNumericString
    />
  );
});

export const columnTypes = {
  currency: AgGridEditor(
    {
      cellClass: ['currency', 'text-right'],
      cellRenderer: (params: any) => {
        const symbol =
          params.context?.initialRows?.[params.data?.uuid]?.[
            params.column?.colId
          ] === params.value
            ? ''
            : '*';

        return (
          <>
            {params.valueFormatted || params.value}{' '}
            {!!symbol && (
              <Box
                sx={{
                  color: theme => theme.palette.error.main
                }}>
                {symbol}
              </Box>
            )}
          </>
        );
      },
      valueFormatter: params => formatters.formatDollars(params.value)
    },
    forwardRef<any, any>((params, ref) => {
      const [value, setValue] = useState(params.value);

      const onValueChange = useCallback(
        data => setValue(data.floatValue ?? 0),
        []
      );

      useUpdateEffect(() => {
        params.onChange(value);
      }, [value]);

      return (
        <TextField
          InputProps={{
            inputComponent: NumericFormatCustom as any
          }}
          autoFocus
          fullWidth
          onChange={onValueChange}
          ref={ref}
          value={value}
        />
      );
    })
  )
};
