import { EligibilityRule } from '@/models/PlanDesign.model';
import ServiceCalculationType from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/eligibility-features/ServiceCalculationType';

class StarterVestwellWorkplaceServiceCalculationType extends ServiceCalculationType {
  eligibilityRules: EligibilityRule[] | undefined;

  serviceCalculationTypesOptions: Record<string, number> = {
    'Elapsed time - 1 Month': 1,
    'Elapsed time - 12 Months': 12,
    'Elapsed time - 3 Months': 3,
    'Elapsed time - 6 Months': 6,
    'No Requirement': 0
  };

  convertToApiState = (value: string): EligibilityRule[] | undefined => {
    if (!value) {
      return this.eligibilityRules;
    }

    const monthRequirement = (this.eligibilityRules || []).find(rule =>
      Object.keys(rule.ruleParameter || {}).includes('months')
    );

    if (monthRequirement && monthRequirement.ruleParameter) {
      monthRequirement.ruleParameter.months =
        this.serviceCalculationTypesOptions[value];
    }

    return this.eligibilityRules;
  };

  output: string | undefined;

  constructor(eligibilityRules: EligibilityRule[] | undefined) {
    super(['No Requirement', 'Specified Months - Elapsed Time']);
    this.output = this.transform(eligibilityRules);
    this.eligibilityRules = eligibilityRules;
  }

  private transform = (
    rules: EligibilityRule[] | undefined
  ): string | undefined => {
    if (!rules) {
      return '';
    }

    const monthRequirement = rules.find(rule =>
      Object.keys(rule.ruleParameter || {}).includes('months')
    );

    if (
      monthRequirement &&
      monthRequirement.ruleParameter &&
      monthRequirement.ruleParameter.months
    ) {
      if (monthRequirement.ruleParameter.months === 0) {
        return 'No Requirement';
      }

      return `Elapsed time - ${monthRequirement.ruleParameter.months} ${
        monthRequirement?.ruleParameter.months > 1 ? 'Months' : 'Month'
      }`;
    }

    return undefined;
  };
}

export default StarterVestwellWorkplaceServiceCalculationType;
