import { SuspiciousActivityDto } from '@/models/ParticipantSuspiciousActivity.model';
import ParticipantService from '@/services/Participant.service';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

type Options = Omit<
  UseQueryOptions<SuspiciousActivityDto, Error>,
  'queryKey' | 'queryFn'
>;

export function useParticipantSuspiciousActivity(
  participantId: string,
  options?: Options
) {
  return useQuery<SuspiciousActivityDto, Error>(
    ['ParticipantService.getSuspiciousActivity', participantId],
    () => ParticipantService.getSuspiciousActivity(+participantId),
    {
      enabled: Boolean(participantId),
      ...options
    }
  );
}
