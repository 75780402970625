import { MenuItem, Select, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import React, { FC } from 'react';

import { HEADER_NAMES } from '../consts';

type ValidationRowProps = {
  validation: {
    name: string;
    fileNames: string[];
    isRequired: boolean;
    isPresent: boolean;
    isDuplicated: boolean;
  };
  unrecognizedHeaders?: string[];
  handleChange?: (event: any) => void;
  selectedHeaders?: Record<string, string>;
};

export const HeaderValidationRow: FC<ValidationRowProps> = props => {
  return (
    <Grid
      data-testid='headerValidation-row'
      display='flex'
      justifyContent='space-between'
      my={2}
      xs={12}>
      <Grid
        alignItems='center'
        data-testid='headerValidation-row-nameCell'
        display='flex'
        justifyContent='center'
        xs={5}>
        <Typography>{HEADER_NAMES[props.validation.name]}</Typography>
      </Grid>
      <Grid display='flex' justifyContent='space-between' xs={2}>
        <svg fill='none' viewBox='0 0 83 12' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M0 6L10 11.7735V0.226497L0 6ZM83 6L73 0.226497V11.7735L83 6ZM9 7H74V5H9V7Z'
            fill='#BDBDBD'
          />
        </svg>
      </Grid>
      <Grid
        data-testid='headerValidation-row-dropdownCell'
        display='flex'
        justifyContent='center'
        xs={5}>
        {props.validation.fileNames.length === 0 ||
        props.validation.isDuplicated ? (
          <Select
            data-testid='headerValidation-row-dropdown'
            name={props.validation.name}
            onChange={props.handleChange}
            placeholder='Select'
            sx={{ minWidth: 120 }}
            value={props.selectedHeaders[props.validation.name] || ''}>
            {props?.unrecognizedHeaders?.map(header => (
              <MenuItem
                data-testid='dropdown-option'
                key={header}
                value={header}>
                {header}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Typography>{props.validation.fileNames[0]}</Typography>
        )}
      </Grid>
    </Grid>
  );
};
