import TooltipButton from '@/components/tool-tip-button/TooltipButton.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { ModelService } from '@/services/ops/investments/Model.service';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Accordion,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  styled,
  Switch,
  Typography
} from '@mui/material';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { useMutation, useQuery } from '@tanstack/react-query';

import { isEmpty, omit } from 'lodash';
import { FC, useCallback, useState } from 'react';
import { useToggle } from 'react-use';

import { RiskSeriesModel } from '../../RiskSeries.component';
import { TargetSeriesModel } from '../../TargetSeries.component';
import { RebalanceModelCheckbox } from './RebalanceModelCheckbox.component';

const StyledAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

type RebalanceRiskModels = {
  modelType: 'risk';
  models: RiskSeriesModel[];
};

type RebalanceTargetModels = {
  modelType: 'target';
  models: TargetSeriesModel[];
};

type RebalanceDialogProps = (RebalanceRiskModels | RebalanceTargetModels) & {
  dirty: boolean;
  isRebalanceModalOpen: boolean;
  toggleRebalanceModal: (value?: boolean) => void;
};

export const RebalanceDialog: FC<RebalanceDialogProps> = props => {
  const [checkedModels, setCheckedModels] = useState<Record<string, boolean>>(
    {}
  );
  const [modelIdKey] = useState(`${props.modelType}ModelId`);
  const [allModels, toggleAllModels] = useToggle(true);
  const snackbar = useSnackbar();

  const bulkRebalanceParticipants = useQuery<{ participantIds: number[] }>(
    [
      'ModelService.bulkRebalanceModelPreview',
      allModels
        ? props.models.map(e => e[modelIdKey]).join(',')
        : Object.keys(checkedModels).join(',')
    ],
    () =>
      ModelService.bulkRebalanceModelPreview(
        allModels
          ? props.models.map(e => e[modelIdKey])
          : Object.keys(checkedModels).map(e => +e)
      )
  );

  const submitBulkRebalanceMutation = useMutation(
    ['ModelService.bulkRebalanceModel'],
    () =>
      ModelService.bulkRebalanceModel(
        allModels
          ? props.models.map(e => e[modelIdKey])
          : Object.keys(checkedModels).map(e => +e)
      ),
    {
      onError: () => {
        snackbar.showSnackbar({
          message: 'Error in rebalancing',
          severity: 'error'
        });
      },
      onSettled: () => {
        setCheckedModels({});
        toggleAllModels(true);
        props.toggleRebalanceModal();
      },
      onSuccess: () => {
        snackbar.showSnackbar({
          message: 'The rebalance request has been submitted successfully.',
          severity: 'success'
        });
      }
    }
  );

  const handleModelChecked = useCallback(
    (modelId, checked) => {
      setCheckedModels(
        checked
          ? {
              ...checkedModels,
              [modelId]: true
            }
          : omit({ ...checkedModels }, [modelId])
      );
    },
    [checkedModels]
  );

  const handleBulkRebalance = useCallback(() => {
    if (props.dirty) {
      snackbar.showSnackbar({
        message:
          'Error! You cannot submit a rebalance request until the investment option changes have been saved.',
        severity: 'error'
      });
      props.toggleRebalanceModal();
    } else {
      submitBulkRebalanceMutation.mutateAsync();
    }
  }, [props.dirty, props.modelType]);

  const handleCancelClick = useCallback(() => {
    if (submitBulkRebalanceMutation.isLoading) {
      return;
    }

    props.toggleRebalanceModal();
    setCheckedModels({});
    toggleAllModels(true);
  }, [submitBulkRebalanceMutation.isLoading]);

  return (
    <Dialog
      fullWidth
      onClose={handleCancelClick}
      open={props.isRebalanceModalOpen}>
      <DialogTitle>Rebalance</DialogTitle>
      <DialogContent>
        <Stack gap={1}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch checked={allModels} onChange={toggleAllModels} />
              }
              disabled={submitBulkRebalanceMutation.isLoading}
              label='All models'
            />
          </FormGroup>
          {allModels ? (
            <Accordion
              disabled={
                !bulkRebalanceParticipants.data?.participantIds.length ||
                submitBulkRebalanceMutation.isLoading
              }>
              <MuiAccordionSummary
                aria-controls='panel1a-content'
                expandIcon={
                  bulkRebalanceParticipants.data?.participantIds?.length >
                    0 && <ExpandMoreIcon />
                }
                id='panel1a-header'>
                <Stack direction='row' gap={1}>
                  <InfoOutlinedIcon />
                  <Typography>
                    {bulkRebalanceParticipants.data?.participantIds?.length ||
                      0}{' '}
                    participants will be affected
                  </Typography>
                </Stack>
              </MuiAccordionSummary>
              {bulkRebalanceParticipants.data?.participantIds?.length > 0 && (
                <StyledAccordionDetails>
                  <Typography>
                    {bulkRebalanceParticipants.data?.participantIds.join(', ')}
                  </Typography>
                </StyledAccordionDetails>
              )}
            </Accordion>
          ) : (
            <>
              <Divider />
              <Grid container>
                <Grid item xs={6}>
                  {props.models
                    .slice(0, props.models.length / 2 + 1)
                    .map(model => (
                      <RebalanceModelCheckbox
                        checked={!!checkedModels[model[modelIdKey]]}
                        disabled={submitBulkRebalanceMutation.isLoading}
                        key={model[modelIdKey]}
                        model={model}
                        modelType={props.modelType}
                        onChange={handleModelChecked}
                      />
                    ))}
                </Grid>
                <Grid item xs={6}>
                  {props.models
                    .slice(props.models.length / 2 + 1)
                    .map(model => (
                      <RebalanceModelCheckbox
                        checked={!!checkedModels[model[modelIdKey]]}
                        disabled={submitBulkRebalanceMutation.isLoading}
                        key={model[modelIdKey]}
                        model={model}
                        modelType={props.modelType}
                        onChange={handleModelChecked}
                      />
                    ))}
                </Grid>
              </Grid>
              <Accordion
                disabled={
                  !bulkRebalanceParticipants.data?.participantIds.length ||
                  submitBulkRebalanceMutation.isLoading
                }>
                <MuiAccordionSummary
                  aria-controls='panel1a-content'
                  expandIcon={
                    bulkRebalanceParticipants.data?.participantIds.length >
                      0 && <ExpandMoreIcon />
                  }
                  id='panel1a-header'>
                  <Stack direction='row' gap={1}>
                    <InfoOutlinedIcon />
                    <Typography>
                      {bulkRebalanceParticipants.data?.participantIds.length
                        ? `${bulkRebalanceParticipants.data?.participantIds.length} participants will be affected`
                        : 'No affected participants'}
                    </Typography>
                  </Stack>
                </MuiAccordionSummary>
                {bulkRebalanceParticipants.data?.participantIds.length > 0 && (
                  <StyledAccordionDetails>
                    <Typography>
                      {bulkRebalanceParticipants.data?.participantIds &&
                        bulkRebalanceParticipants.data?.participantIds.join(
                          ', '
                        )}
                    </Typography>
                  </StyledAccordionDetails>
                )}
              </Accordion>
            </>
          )}
        </Stack>
        <DialogActions>
          <Button
            disabled={submitBulkRebalanceMutation.isLoading}
            onClick={handleCancelClick}>
            Cancel
          </Button>
          <TooltipButton
            disabled={
              bulkRebalanceParticipants.isLoading ||
              submitBulkRebalanceMutation.isLoading ||
              !bulkRebalanceParticipants.data?.participantIds.length
            }
            handleOnClick={handleBulkRebalance}
            testId='confirm-rebalance-btn'
            tooltipMessage={
              isEmpty(checkedModels) && !allModels
                ? 'Please select at least one model'
                : 'No affected participants'
            }
            tooltipPlacement='top'
            variant='contained'>
            Confirm & Rebalance
          </TooltipButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

RebalanceDialog.displayName = 'RebalanceDialog';
