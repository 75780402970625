import DatePicker from '@/components/date-picker/DatePicker';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useTransactionTypeCodesByBaseType } from '@/hooks/ops/useTransactionTypeCodesByBaseType.hook';
import { TransactionDto } from '@/models/ops/transactions/TransactionDTO.model';
import { UpdateTransactionRequest } from '@/models/ops/transactions/UpdateTransactionRequest.model';
import { TransactionAccountSummary } from '@/routes/ops/common/transactions/TransactionAccountSummary.component';
import { ApiServiceError } from '@/services/Api.service';
import TransactionsService from '@/services/ops/transactions/Transaction.service';
import formatters from '@/utils/Formatters';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Unstable_Grid2 as Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  Typography
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AccountLevel } from '@vestwell-sub-accounting/models/accountsAndLedgers/AccountLevel';
import { TransactionBaseType } from '@vestwell-sub-accounting/models/common/TransactionBaseType';
import { TransactionTypeCode } from '@vestwell-sub-accounting/models/common/TransactionTypeCode';

import { Dayjs } from 'dayjs';
import { Field, Form, Formik, useFormikContext } from 'formik';
import React from 'react';
import {
  isTradeDateWithinBounds,
  isTradingDay
} from 'sub-accounting-calendar-utility';
import * as yup from 'yup';

export type EditTransactionFormValues = {
  amount?: number | string; // type="number" input converts value; SubA requires string
  tracerId?: string;
  tradeDate?: string;
  transactionTypeCode: TransactionTypeCode | '';
};

type EditTransactionDialogProps = DialogProps & {
  accountId: string;
  accountLevel: AccountLevel;
  open: boolean;
  transaction?: TransactionDto;
  onClose: () => void;
  onEditTransaction?: (data: Partial<UpdateTransactionRequest>) => void;
};

yup.setLocale({
  mixed: {
    required: 'Required'
  }
});

const fieldsByTransactionBaseType = {
  // retain types without fields to permit typesafe access by key and possible future expansion of editable fields
  [TransactionBaseType.Buy]: ['amount'],
  [TransactionBaseType.Deposit]: ['amount', 'tradeDate'],
  [TransactionBaseType.Fee]: ['amount', 'tradeDate'],
  [TransactionBaseType.FeeReversal]: ['amount', 'tradeDate'],
  [TransactionBaseType.IncomeIn]: undefined,
  [TransactionBaseType.IncomeOut]: undefined,
  [TransactionBaseType.Sell]: ['amount'],
  [TransactionBaseType.TransferIn]: undefined,
  [TransactionBaseType.TransferOut]: undefined,
  [TransactionBaseType.Withdrawal]: ['amount', 'tradeDate']
};

const validationSchema = yup.object({
  transactionTypeCode: yup.string().required()
  // contribution year and tracer ID omitted because they are optional
});

type EditTransactionFormProps = {
  error?: ApiServiceError;
  showAmount?: boolean;
  showTradeDate?: boolean;
  transaction: TransactionDto;
};

const EditTransactionForm: React.FC<EditTransactionFormProps> = props => {
  const { errors, touched } = useFormikContext<EditTransactionFormValues>();

  const transactionTypeCodesByBaseTypeQuery =
    useTransactionTypeCodesByBaseType();

  return (
    <Form data-testid='edit-transaction-form'>
      <Grid container spacing={2}>
        <Grid xs={6}>
          {transactionTypeCodesByBaseTypeQuery.isInitialLoading ? (
            <Skeleton height={40} variant='rectangular' width={420} />
          ) : (
            <FormControl
              error={
                touched.transactionTypeCode &&
                Boolean(errors.transactionTypeCode)
              }
              fullWidth
              size='small'>
              <InputLabel id='transaction-type-button-label'>
                Transaction Type
              </InputLabel>
              <Field
                MenuProps={{
                  'data-testid': 'transactionTypeCode-menu'
                }}
                as={Select}
                error={
                  touched.transactionTypeCode &&
                  Boolean(errors.transactionTypeCode)
                }
                inputProps={{
                  'data-testid': 'transactionTypeCode-input'
                }}
                label='Transaction Type'
                labelId='transaction-type-button-label'
                name='transactionTypeCode'>
                {transactionTypeCodesByBaseTypeQuery.data &&
                  props.transaction?.transactionBaseType &&
                  (
                    transactionTypeCodesByBaseTypeQuery.data[
                      props.transaction?.transactionBaseType
                    ] || []
                  ).map((value: TransactionTypeCode) => (
                    <MenuItem key={value} value={value}>
                      {formatters.displayCase(value)}
                    </MenuItem>
                  ))}
              </Field>
              <FormHelperText>
                {touched.transactionTypeCode && errors.transactionTypeCode}
              </FormHelperText>
            </FormControl>
          )}
        </Grid>
        <Grid xs={6} />
        {props.showTradeDate && (
          <>
            <Grid xs={3}>
              <FormControl
                error={touched.tradeDate && Boolean(errors.tradeDate)}
                fullWidth>
                <Field
                  as={DatePicker}
                  autoComplete='off'
                  data-testid='trade-date-picker'
                  disableFuture
                  label='Trade Date'
                  name='tradeDate'
                  shouldDisableDate={(date: Dayjs) =>
                    !isTradingDay(date.format('YYYY-MM-DD')) ||
                    !isTradeDateWithinBounds(date.format('YYYY-MM-DD'))
                  }
                  size='small' // FormControl doesn't pass to our DatePicker
                  variant='outlined'
                />
                <FormHelperText>
                  {touched.tradeDate && errors.tradeDate}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid xs={9} />
          </>
        )}
        {props.showAmount && (
          <>
            <Grid xs={3}>
              <FormControl
                error={touched.amount && Boolean(errors.amount)}
                fullWidth
                size='small'>
                <InputLabel htmlFor='amount-input'>Amount</InputLabel>
                <Field
                  as={OutlinedInput}
                  autoComplete='off'
                  id='amount-input'
                  inputProps={{
                    'data-testid': 'amount-input'
                  }}
                  label='Amount'
                  name='amount'
                  startAdornment={
                    <InputAdornment position='start'>$</InputAdornment>
                  }
                  type='number'
                />
                <FormHelperText>
                  {touched.amount && errors.amount}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid xs={9} />
          </>
        )}
        <Grid xs={12}>
          <Divider textAlign='left'>Meta</Divider>
          <Typography
            color={theme => theme.palette.grey[700]}
            variant='caption'>
            Not required
          </Typography>
        </Grid>
        <Grid xs={6}>
          <FormControl fullWidth size='small'>
            <InputLabel htmlFor='tracer-id-input'>Tracer ID</InputLabel>
            <Field
              as={OutlinedInput}
              autoComplete='off'
              error={touched.tracerId && Boolean(errors.tracerId)}
              id='tracer-id-input'
              inputProps={{
                'data-testid': 'tracerId-input'
              }}
              label='Tracer ID'
              name='tracerId'
            />
            <FormHelperText>
              {touched.tracerId && errors.tracerId}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={6} />
        {props.error && (
          <Grid xs={12}>
            <Alert severity='error'>{props.error.message}</Alert>
          </Grid>
        )}
      </Grid>
      {/* hidden submit button for keyboard submission without breaking Dialog overflow fixed title/actions */}
      <Box component='input' sx={visuallyHidden} type='submit' />
    </Form>
  );
};

export const EditTransactionDialog: React.FunctionComponent<
  EditTransactionDialogProps
> = props => {
  const {
    accountId,
    accountLevel,
    open,
    transaction,
    onClose,
    onEditTransaction
  } = props;
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const updateTransactionMutation = useMutation<
    TransactionDto,
    ApiServiceError,
    Partial<UpdateTransactionRequest>
  >(
    ['TransactionsService.update'],
    data =>
      TransactionsService.update({
        ...data,
        sourceTransactionId: transaction.sourceTransactionId
      }),
    {
      onError: () => {
        showSnackbar({
          message: 'Error editing transaction',
          severity: 'error'
        });
      },
      onSuccess: (_data, variables) => {
        showSnackbar({
          message: 'Transaction edited',
          severity: 'success'
        });
        queryClient.invalidateQueries({
          queryKey: ['TransactionService.search']
        });
        queryClient.invalidateQueries({
          queryKey: ['ParentAccountService.getById', accountId]
        });
        onClose();
        if (typeof onEditTransaction === 'function') {
          onEditTransaction(variables);
        }
      }
    }
  );

  const baseTypeFields =
    fieldsByTransactionBaseType[props.transaction?.transactionBaseType] || [];

  const showAmount = baseTypeFields.includes('amount');
  const showTradeDate = baseTypeFields?.includes('tradeDate');

  const handleSubmit = (data: EditTransactionFormValues) => {
    if (
      transaction === undefined ||
      // block if required enum values are empty (Select requires '' value missing in enum)
      data.transactionTypeCode === ''
    ) {
      return;
    }

    const submission = {
      amount:
        showAmount && typeof data.amount === 'number'
          ? data.amount.toFixed(2)
          : undefined,
      tracerId: data.tracerId,
      tradeDate: showTradeDate ? data.tradeDate : undefined,
      transactionTypeCode: data.transactionTypeCode
    };

    Object.entries(submission).forEach(([key, value]) => {
      if (value === '') delete submission[key as keyof typeof submission];
    });

    updateTransactionMutation.mutate(submission);
  };

  return (
    <Formik
      initialValues={{
        amount: transaction?.amount?.toFixed(2) || '',
        tracerId: transaction?.tracerId || '',
        tradeDate: transaction?.tradeDate || '',
        transactionTypeCode: transaction?.transactionTypeCode || ''
      }}
      onSubmit={(values: EditTransactionFormValues) => handleSubmit(values)}
      validationSchema={validationSchema}>
      {({ handleSubmit: handleFormSubmit, isValid }) => (
        <Dialog fullWidth maxWidth='md' onClose={() => onClose()} open={open}>
          <DialogTitle>Edit Transaction</DialogTitle>
          <TransactionAccountSummary
            accountId={accountId}
            accountLevel={accountLevel}
          />
          <DialogContent>
            <EditTransactionForm
              error={updateTransactionMutation.error}
              showAmount={showAmount}
              showTradeDate={showTradeDate}
              transaction={transaction}
            />
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button
              data-testid='nevermind-button'
              disabled={updateTransactionMutation.isLoading}
              onClick={() => onClose()}>
              Nevermind
            </Button>
            <LoadingButton
              data-testid='save-button'
              disabled={!isValid}
              loading={updateTransactionMutation.isLoading}
              onClick={() => handleFormSubmit()}
              type='submit'
              variant='contained'>
              Save
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};
