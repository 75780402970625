import CollapsibleTable from '@/components/collapsible-table';
import SimpleTabs, { SimpleTabsProps, TabData } from '@/components/simple-tabs';
import {
  WithdrawalTradeRequestDto,
  WithdrawalTransactionDto
} from '@/models/WithdrawalsDTO.model';
import ParticipantService from '@/services/Participant.service';
import { Card, CardContent } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import React, { FC } from 'react';

import WithdrawalTradeRequestsTableCell from './WithdrawalTradeRequestsTableCell.component';
import WithdrawalTransactionsTableCell from './WithdrawalTransactionsTableCell.component';

const transactionColumns: GridColDef[] = [
  { field: 'id', headerName: 'Tx ID' },
  { field: 'tradeDate', headerName: 'Trade Date' },
  { field: 'effectiveDate', headerName: 'Effective Date' },
  { field: 'fundingSource', headerName: 'Funding Source' },
  { field: 'transactionSubtype', headerName: 'Tx Subtype' },
  { field: 'ticker', headerName: 'Ticker' },
  { field: 'dollars', headerName: 'Amount' },
  { field: 'status', headerName: 'Status' },
  { field: 'tracerId', headerName: 'Tracer ID' }
];

const tradeRequestColumns: GridColDef[] = [
  { field: 'actionType', headerName: 'Type' },
  { field: 'status', headerName: 'Execution Status' },
  { field: 'workflowStatus', headerName: 'Workflow Status' },
  { field: 'createdAt', headerName: 'Created At' },
  { field: 'updatedAt', headerName: 'Updated At' },
  { field: 'tracerId', headerName: 'Tracer ID' },
  { field: 'action', headerName: '' }
];

type WithdrawalDetailsDataGridsCardProps = {
  participantId: string;
  withdrawalId: string;
  tradeRequests: WithdrawalTradeRequestDto[];
};

const WithdrawalDetailsDataGridsCard: FC<
  WithdrawalDetailsDataGridsCardProps
> = props => {
  const transactions = useQuery<WithdrawalTransactionDto[]>(
    [
      'ParticipantService.getWithdrawalTransactions',
      props.participantId,
      props.withdrawalId
    ],
    () => {
      return ParticipantService.getWithdrawalTransactions(
        +props.participantId,
        +props.withdrawalId
      );
    },
    {
      staleTime: Infinity
    }
  );

  const makeTabsElement = (): SimpleTabsProps => {
    const WithdrawalTransactionsTab: TabData = {
      component: (
        <CollapsibleTable
          cellComponent={WithdrawalTransactionsTableCell}
          columns={transactionColumns}
          tableData={transactions.data || []}
        />
      ),
      label: 'Transactions',
      path: `/participants/${props.participantId}/withdrawals/${props.withdrawalId}/details/transactions`
    };
    const WithdrawalTradeRequestsTab: TabData = {
      component: (
        <CollapsibleTable
          cellComponent={WithdrawalTradeRequestsTableCell}
          columns={tradeRequestColumns}
          tableData={props.tradeRequests || []}
        />
      ),
      label: 'Trade Requests',
      path: `/participants/${props.participantId}/withdrawals/${props.withdrawalId}/details/tradeRequests`
    };

    const registeredTabs = [
      WithdrawalTradeRequestsTab,
      WithdrawalTransactionsTab
    ];

    const displayTabs: SimpleTabsProps = {
      tabs: registeredTabs,
      tabsAriaLabel: 'withdrawals-details-data-grid-tabs'
    };

    return displayTabs;
  };

  const tabs = makeTabsElement();

  return (
    <Card elevation={0} sx={{ marginTop: 3 }} variant='outlined'>
      <CardContent sx={{ padding: 0 }}>
        <SimpleTabs {...tabs} />
      </CardContent>
    </Card>
  );
};

export default WithdrawalDetailsDataGridsCard;
