import CollapsibleTable from '@/components/collapsible-table';
import {
  LoanExpectedPayment,
  LoanPaymentscheduleTable
} from '@/models/LoanDTO.model';
import DOMInteraction from '@/utils/DOMInteraction';
import formatters from '@/utils/Formatters';
import { json2csvParser } from '@/utils/Json2csvParser';
import { Button, Card, CardContent, CardHeader, useTheme } from '@mui/material';

import React, { useMemo } from 'react';

import LoanAmortizationScheduleTableCell from './LoanAmortizationScheduleTableCell.component';

interface LoansAmortizationScheduleProps {
  loanExpectedPaymentData: LoanExpectedPayment[];
}

const formatDate = (value: string | undefined) => {
  return value ? formatters.formatFromIsoDateCustom(value, 'M/DD/YY') : '--';
};

const exportCsv = async (tableData: LoanPaymentscheduleTable[]) => {
  const csv = await json2csvParser(
    tableData.map(row => {
      const record = Object.assign({}, row);
      record.updatedAt = formatDate(row.updatedAt);
      record.latestPaymentDate = formatDate(row.latestPaymentDate);
      return record;
    })
  );
  const filename = 'loan-amortization-schedule.csv';
  DOMInteraction.triggerDownload(csv, filename);
};

const LoansAmortizationSchedule: React.FunctionComponent<
  LoansAmortizationScheduleProps
> = (props: LoansAmortizationScheduleProps) => {
  const { loanExpectedPaymentData } = props;
  const theme = useTheme();

  const headCells = [
    { field: 'id', headerName: '' },
    { field: 'dueDate', headerName: 'Date' },
    {
      field: 'total',
      headerName: 'Total Due'
    },
    {
      field: 'principal',
      headerName: 'Principal Due'
    },
    {
      field: 'interest',
      headerName: 'Interest Due'
    },
    {
      field: 'paymentStatus',
      headerName: 'Status'
    },
    {
      field: 'totalPaid',
      headerName: 'Total Paid'
    },
    { field: 'latestPaymentDate', headerName: 'Last Paid Date' },
    { field: 'updatedAt', headerName: 'Updated At' }
  ];

  const tableData: LoanPaymentscheduleTable[] = useMemo(
    () =>
      loanExpectedPaymentData.map(row => {
        return {
          dueDate: row.attributes?.dueDate,
          id: row.attributes?.paymentSequenceNumber,
          interest: row.attributes?.interest,
          latestPaymentDate: row.attributes?.latestPaymentDate,
          paymentStatus: row.attributes?.paymentStatus,
          principal: row.attributes?.principal,
          total: row.attributes?.total,
          totalPaid:
            (row.attributes?.principalPaid ?? 0) +
            (row.attributes?.interestPaid ?? 0),
          updatedAt: row.attributes?.updatedAt
        } as LoanPaymentscheduleTable;
      }),
    [loanExpectedPaymentData]
  );

  return (
    <>
      <Card data-testid='loan-amortization-card'>
        <CardHeader
          action={<Button variant='text'>Export CSV</Button>}
          onClick={() => exportCsv(tableData)}
          sx={{ borderBottom: `1px solid ${theme.palette.grey[300]}` }}
          title='Amortization Schedule'
        />
        <CardContent data-testid='loan-amortization-card-content'>
          <CollapsibleTable
            cellComponent={LoanAmortizationScheduleTableCell}
            columns={headCells}
            tableData={tableData || []}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default LoansAmortizationSchedule;
