import LinearLoading from '@/components/linear-loading';
import { useNotesQuery } from '@/hooks/useNotes.hook';
import { ParticipantAccountsDto, PlanV2Dto } from '@/models';
import ParticipantInfo from '@/models/ParticipantInfo.model';
import { Permissions } from '@/models/UserPermissions.model';
import ParticipantService from '@/services/Participant.service';
import Grid from '@mui/material/Unstable_Grid2';
import { useQuery } from '@tanstack/react-query';

import { Suspense } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { NotesInfoCard } from '../NotesInfoCard.component';
import { DeferralChangeDtoWithUUID } from '../ParticipantDetail.route';
import ParticipantDeferralHistoryChangeCard from './ParticipantDeferralHistoryChangeCard.component';
import ParticipantElectionDeferralRate from './ParticipantElectionDeferralRate.component';
import ParticipantRebalanceHistory from './ParticipantRebalanceHistory.component';

type ParticipantElectionsTabProps = {
  accountInfo: ParticipantAccountsDto;
  isStateIRA: boolean;
  isVestwellSubAccounting: boolean;
  isVoyaPlan: boolean;
  participant: ParticipantInfo;
  participantId: number;
  planData: PlanV2Dto;
  userHasValidToken: boolean;
  isEsa?: boolean;
  electionNotesQuery: ReturnType<typeof useNotesQuery>;
  openEditNotesModal: () => void;
  permission: Permissions;
};

const ParticipantElectionsTab: React.FC<
  ParticipantElectionsTabProps
> = props => {
  const deferralChangesQuery = useQuery<DeferralChangeDtoWithUUID[]>(
    ['ParticipantService.getDeferralChanges', props.participantId],
    async () => {
      const changes = await ParticipantService.getDeferralChanges(
        props.participantId
      );
      return changes.map(change => ({ ...change, uuid: uuidv4() }));
    },
    {
      enabled: Boolean(props.participantId && props.userHasValidToken)
    }
  );

  return (
    <Grid container data-testid='elections-tab' md={12} spacing={3}>
      <Grid md={3} xs={2}>
        <ParticipantElectionDeferralRate
          isEsa={props.isEsa}
          isStateIRA={props.isStateIRA}
          isVoyaPlan={props.isVoyaPlan}
          participant={props.participant}
        />
      </Grid>
      <Grid md={9}>
        {deferralChangesQuery.data && (
          <ParticipantDeferralHistoryChangeCard
            deferralChanges={deferralChangesQuery.data}
            isEsa={props.isEsa}
            isStateIRA={props.isStateIRA}
          />
        )}
      </Grid>
      <Grid md={12} xs={8}>
        <NotesInfoCard
          emptyMessage='No election-related notes for this participant'
          notes={props.electionNotesQuery.notesQuery.data?.data?.notes}
          onClickEdit={props.openEditNotesModal}
          permission={props.permission}
          title='vesting'
        />
      </Grid>
      <Grid md={12} xs={8}></Grid>
      {props.isVestwellSubAccounting && !props.isEsa && (
        <Grid md={12} xs={8}>
          <Suspense fallback={<LinearLoading />}>
            <ParticipantRebalanceHistory
              accounts={props.accountInfo}
              isVestwellSubaccounting={props.isVestwellSubAccounting}
              participantId={props.participantId}
              plan={props.planData}
            />
          </Suspense>
        </Grid>
      )}
    </Grid>
  );
};

export default ParticipantElectionsTab;
