import useHasPermissions from '@/components/access-control/useHasPermissions.hook';
import { Permissions } from '@/models/UserPermissions.model';

import React from 'react';
import { Navigate } from 'react-router-dom';

// credit: https://stackoverflow.com/questions/56006111/is-it-possible-to-define-a-non-empty-array-type-in-typescript
type NonEmptyArray<T> = [T, ...T[]];

type HideFromTPA = {
  hideFromTPA: boolean;
};

type RequiresAll = {
  requires: NonEmptyArray<Permissions>;
};

type OneOf = {
  requiresOneOf: NonEmptyArray<Permissions>;
};

type Component = {
  children: React.ReactNode;
  prohibitedAccessComponent?: React.ReactNode;
};

type AccessControlParams = Component & { redirectOnBlocked?: string } & (
    | HideFromTPA
    | (Partial<HideFromTPA> & (RequiresAll | OneOf))
  );

const AccessControl: React.FunctionComponent<AccessControlParams> = (
  props: AccessControlParams
) => {
  const { children, hideFromTPA = false } = props;

  const { requiresOneOf } =
    'requiresOneOf' in props ? props : { requiresOneOf: undefined };
  const { requires } = 'requires' in props ? props : { requires: undefined };
  const { redirectOnBlocked } =
    'redirectOnBlocked' in props ? props : { redirectOnBlocked: null };

  const perms = useHasPermissions({
    hideFromTPA,
    requires,
    requiresOneOf
  });

  if (perms.isAllowed) {
    return <>{children}</>;
  }

  if (perms.isLoading) {
    return null;
  }

  if (props.prohibitedAccessComponent) {
    return <>{props.prohibitedAccessComponent}</>;
  }

  return redirectOnBlocked ? <Navigate to={redirectOnBlocked} /> : null;
};

export default AccessControl;
