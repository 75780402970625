import Constants from '@/consts/planDesign.constants';
import { EligibilityRule } from '@/models/PlanDesign.model';

class LengthOfEmploymentRequired {
  disabled: boolean;

  setDisabled = (value: boolean): void => {
    this.disabled = value;
  };

  output: string | undefined;

  lengths = Constants.lengthOfEmploymentRequiredOptions;

  convertToApiState = (label: string): number | undefined => {
    return this.lengths.find(l => l.label === label)?.value;
  };

  options = this.lengths.map(l => l.label);

  serviceCalculationTypeValue: string | undefined;

  constructor(props: Record<string, any>) {
    this.output = this.transform(props.eligibilityRules);
    this.serviceCalculationTypeValue = this.getServiceCalculationTypeValue(
      props.eligibilityRules
    );
    this.disabled =
      !this.serviceCalculationTypeValue ||
      [
        'No Requirement',
        'Specified Hours of Service',
        'One (1) Year - Hours of Service',
        'State IRA Eligibility'
      ].includes(this.serviceCalculationTypeValue || '');
  }

  private transform = (rules: EligibilityRule[]) => {
    const monthRequirement = rules?.find(rule =>
      Object.keys(rule.ruleParameter || {}).includes('months')
    );
    return this.lengths.find(
      l => l.value === monthRequirement?.ruleParameter?.months
    )?.label;
  };

  getServiceCalculationTypeValue = (
    rules: EligibilityRule[] | undefined
  ): string | undefined => {
    if (!rules) {
      return '';
    }

    const eligibilityRule = rules.find(rule => {
      return Constants.eligibilityRequirementTypes
        .map(type => type.eligibilityRequirementTypeName)
        .includes(rule.eligibilityRequirementTypeName || '');
    });

    return eligibilityRule?.ruleParameter?.months === 0 &&
      eligibilityRule.eligibilityRequirementTypeId === 2
      ? 'No Requirement'
      : eligibilityRule?.eligibilityRequirementTypeName;
  };
}

export default LengthOfEmploymentRequired;
