import { useSnackbar } from '@/contexts/SnackBarContext';
import DOMInteraction from '@/utils/DOMInteraction';
import formatters from '@/utils/Formatters';
import { json2csvParser } from '@/utils/Json2csvParser';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';

import React, { useState } from 'react';

interface DownloadCSVButtonProps {
  buttonProps?: LoadingButtonProps;
  getInfo: () => Promise<any[]>;
  formatInfoWith?: (info: any) => any;
  fileName: string;
  text: string;
}
const DownloadCSVButton: React.FunctionComponent<DownloadCSVButtonProps> = ({
  buttonProps,
  formatInfoWith = data => data,
  getInfo,
  fileName,
  text
}) => {
  const [loading, setLoading] = useState(false);
  const { showSnackbar } = useSnackbar();

  async function downloadCSV() {
    setLoading(true);
    try {
      const allInfo = await getInfo();
      const formattedParticipantInfo = allInfo.map(formatInfoWith);
      const csv = await json2csvParser(formattedParticipantInfo);
      DOMInteraction.triggerDownload(csv, `${fileName}.csv`);
    } catch (e) {
      showSnackbar({
        message: 'Failed to download, please try again',
        severity: 'error'
      });
    }
    setLoading(false);
  }

  return (
    <LoadingButton
      data-testid={`download-csv-button-${formatters.textToDataTestId(text)}`}
      loading={loading}
      onClick={downloadCSV}
      variant='contained'
      {...buttonProps}>
      {text}
    </LoadingButton>
  );
};

export default DownloadCSVButton;
