import ApiService from '@/services/Api.service';

interface FundingSource {
  fundingSourceId?: number;
  name: string;
  code: string;
}

class FundingSourceService {
  static async getAllFundingSources(): Promise<FundingSource[]> {
    return ApiService.getJson(`/funding-sources`) as any;
  }
}

export type { FundingSource };
export { FundingSourceService };
