import AccessControl from '@/components/access-control/AccessControl.component';
import Badge from '@/components/badge';
import { CellComponentProps } from '@/components/collapsible-table';
import { CreatePlanModal } from '@/components/entity-modals/EntityModals.component';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { Link, TableCell, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import clsx from 'clsx';
import { capitalize } from 'lodash';
import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  size: {
    fontSize: theme.spacing(2)
  }
}));

const PooledPlansTableCell: React.FunctionComponent<CellComponentProps> = (
  props: CellComponentProps
) => {
  const classes = useStyles();
  const { row, column } = props;

  const content = useMemo(() => {
    if (column.field === 'pooledPlanName' && !row.disableLink) {
      return (
        <Link
          component={RouterLink}
          to={`/pooled-plans/${row.pooledPlanId}`}
          underline='hover'>
          {row[column.field]}
        </Link>
      );
    }

    if (column.field === 'pooledPlanStatus') {
      const color =
        row[column.field]?.toLowerCase() === 'confirmed'
          ? 'success'
          : 'neutral';

      return (
        <Badge color={color} size='small'>
          {capitalize(row[column.field])}
        </Badge>
      );
    }

    if (column.field === 'pooledPlanType') {
      return <Typography>{row[column.field]?.toUpperCase()}</Typography>;
    }

    if (column.field === 'action') {
      return row.pooledPlanStatus === 'confirmed' ? (
        <AccessControl requires={[FeatureLevelPermissions.WRITE_CREATE_PLAN]}>
          <CreatePlanModal
            pooledPlanId={row.pooledPlanId}
            title='Add Adopter'
            variant='text'
          />
        </AccessControl>
      ) : null;
    }

    return row[column.field] || '--';
  }, [column, row]);

  return (
    <TableCell
      className={clsx(classes.size, column.cellClassName)}
      component='th'
      scope='row'>
      {content}
    </TableCell>
  );
};

export default PooledPlansTableCell;
