import LinearLoading from '@/components/linear-loading';
import useShowMore from '@/hooks/useShowMore';
import {
  GlobalSearchDto,
  GlobalSearchProgramDto
} from '@/models/GlobalSearchDTO.model';
import GlobalSearchService from '@/services/GlobalSearch.service';
import { EventNote } from '@mui/icons-material';
import {
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Theme,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  GLOBAL_SEARCH_PROGRAMS_TYPE,
  GLOBAL_SEARCH_RESULT_PAGE_SIZE
} from './constants';

interface GlobalSearchProgramsResultProps {
  setSearchTerm: (search: string) => void;
  searchTerm: string;
  isFocused: boolean;
  abortSignal?: AbortSignal;
}

const useStyles = makeStyles((theme: Theme) => ({
  globalSearchProgramContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  globalSearchProgramIdField: {
    color: theme.palette.text.secondary,
    fontSize: theme.spacing(1.2),
    paddingTop: theme.spacing(1.5),
    textAlign: 'right',
    width: '25%'
  },
  globalSearchProgramImg: {
    color: '#ffcc00'
  },
  globalSearchProgramItem: {
    '&:hover': {
      backgroundColor: grey[200]
    },
    cursor: 'pointer'
  },
  globalSearchProgramsHeader: {
    fontSize: theme.spacing(1.5),
    letterSpacing: '0.4px',
    marginLeft: theme.spacing(1.5),
    marginTop: theme.spacing(2)
  },
  globalSearchShowMoreBtn: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5)
  },
  noResults: {
    paddingLeft: theme.spacing(2)
  }
}));

const GlobalSearchProgramsResult = (
  props: GlobalSearchProgramsResultProps | any
): JSX.Element => {
  const classes = useStyles();

  const { setSearchTerm, searchTerm, abortSignal } = props;

  const navigate = useNavigate();

  const redirectToProgramsPage = (id: number) => {
    setSearchTerm('');
    navigate(`/ops/investments/program/${id}`);
  };

  const globalSearchProgramQuery = useShowMore<
    GlobalSearchDto,
    GlobalSearchProgramDto
  >(
    ['globalSearchProgramResultQuery', searchTerm],
    page =>
      GlobalSearchService.getGlobalSearchResult(
        searchTerm,
        page,
        GLOBAL_SEARCH_RESULT_PAGE_SIZE,
        GLOBAL_SEARCH_PROGRAMS_TYPE,
        abortSignal
      ),
    Boolean(searchTerm),
    data => data.data.programs.data,
    data => Boolean(data?.data.programs.links.next),
    'programId'
  );

  let loader;

  if (globalSearchProgramQuery.isLoading || searchTerm === '') {
    loader = <LinearLoading />;
  } else {
    loader = null;
  }

  if (globalSearchProgramQuery.isError) {
    return <p>Error</p>;
  }

  return (
    <>
      <Typography
        className={classes.globalSearchProgramsHeader}
        id='global-search-programs-header'>
        PROGRAMS
      </Typography>
      {loader || (
        <div>
          {!globalSearchProgramQuery.isLoading &&
          !!globalSearchProgramQuery.data?.length ? (
            <List data-testid='gs-program' id='global-search-program'>
              {globalSearchProgramQuery.data.map(
                (program: GlobalSearchProgramDto) => {
                  return (
                    <ListItem
                      className={classes.globalSearchProgramItem}
                      id='global-search-program-item'
                      key={program.programId}
                      onClick={() => redirectToProgramsPage(program.programId)}>
                      <ListItemAvatar
                        className={classes.globalSearchProgramImg}>
                        <EventNote id='global-search-program-image' />
                      </ListItemAvatar>
                      <div
                        className={classes.globalSearchProgramContainer}
                        id='global-search-program-container'>
                        <div id='global-search-program-wrapper'>
                          <ListItemText
                            id='global-search-program-name'
                            primary={`${program.name}`}
                          />
                        </div>
                        <Typography
                          className={classes.globalSearchProgramIdField}
                          id='global-search-programs-id'>
                          PROGRAM ID: {program.programId}
                        </Typography>
                      </div>
                    </ListItem>
                  );
                }
              )}
            </List>
          ) : (
            <p className={classes.noResults}>No results</p>
          )}
          {!globalSearchProgramQuery.isLoading &&
            !!globalSearchProgramQuery.data?.length &&
            globalSearchProgramQuery.isPaginated && (
              <Button
                className={classes.globalSearchShowMoreBtn}
                disabled={globalSearchProgramQuery.isPaginationLoading}
                id='global-search-programs-show-more-btn'
                onClick={globalSearchProgramQuery.showMore}>
                SHOW MORE
              </Button>
            )}
        </div>
      )}
    </>
  );
};

export default GlobalSearchProgramsResult;
