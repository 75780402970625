import { PayrollFundingInfoDto } from '@/models';
import { PlanService } from '@/services/Plan.service';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

export default function usePayrollFundingInfo<T>(
  planId: string | number,
  select?: (data: PayrollFundingInfoDto) => T
): UseQueryResult<T, unknown> {
  // needs a type param
  return useQuery(
    ['PlanService.getPayrollFundingInfo', planId.toString()],
    () => PlanService.getPayrollFundingInfo(planId),
    {
      enabled: planId !== undefined && planId.toString() !== '0',
      select,
      staleTime: Infinity
    }
  );
}
