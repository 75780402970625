import { useField, useFormikContext } from 'formik';
import { FC, useEffect } from 'react';

const LoansObserver: FC<{
  data: Record<string, any>;
}> = props => {
  const ctx = useFormikContext<Record<string, unknown>>();

  const [, , allowResidencePurchaseExtensionHelpers] = useField(
    'allowResidencePurchaseExtension'
  );
  const [, , maxOutstandingLoansHelpers] = useField('maxOutstandingLoans');
  const [, , maxYearsResidencePurchaseHelpers] = useField(
    'maxYearsResidencePurchase'
  );
  const [, , loanEmailHelpers] = useField('loanEmail');

  useEffect(() => {
    props.data.maxOutstandingLoans.setDisabled(ctx.values.allowLoans === 'No');

    props.data.allowResidencePurchaseExtension.setDisabled(
      !ctx.values.allowLoans || ctx.values.allowLoans === 'No'
    );

    props.data.maxYearsResidencePurchase.setDisabled(
      !ctx.values.allowResidencePurchaseExtension ||
        ctx.values.allowResidencePurchaseExtension === 'No' ||
        !ctx.values.allowLoans ||
        ctx.values.allowLoans === 'No'
    );

    props.data.loanEmail.setDisabled(
      !ctx.values.allowLoans || ctx.values.allowLoans === 'No'
    );

    if (!ctx.values.allowLoans || ctx.values.allowLoans === 'No') {
      if (ctx.values.allowResidencePurchaseExtension) {
        allowResidencePurchaseExtensionHelpers.setValue('');
      }

      if (ctx.values.maxOutstandingLoans) {
        maxOutstandingLoansHelpers.setValue('');
      }

      if (ctx.values.maxYearsResidencePurchase) {
        maxYearsResidencePurchaseHelpers.setValue('');
      }

      if (ctx.values.loanEmail) {
        loanEmailHelpers.setValue('');
      }
    }
  }, [
    allowResidencePurchaseExtensionHelpers,
    ctx.values,
    maxOutstandingLoansHelpers,
    maxYearsResidencePurchaseHelpers,
    props.data
  ]);

  return null;
};

export default LoansObserver;
