import OpenInNewIcon from '@/components/icon/OpenInNewIcon';
import { RelatedWorkflow } from '@/models/ops/workflows/WorkflowDTO.model';
import formatters from '@/utils/Formatters';
import { Box, Dialog, DialogContent, Link, Typography } from '@mui/material';
import { WorkflowName } from '@vestwell-sub-accounting/models/conductor/WorkflowName.model';

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

type RelatedWorkflowsDialogProps = {
  open: boolean;
  onClose: () => void;
  relatedWorkflows?: RelatedWorkflow[];
};

const RelatedWorkflowsDialog = ({
  open,
  onClose,
  relatedWorkflows = []
}: RelatedWorkflowsDialogProps): JSX.Element => {
  return (
    <Dialog
      fullWidth
      maxWidth='xs'
      onClose={() => {
        onClose();
      }}
      open={open}>
      <DialogContent
        sx={{
          p: 2,
          pb: 0.5
        }}>
        {relatedWorkflows.map(relatedWorkflow => (
          <Box key={relatedWorkflow.id} sx={{ mb: 1.5 }}>
            <Link
              color='primary'
              component={RouterLink}
              fontSize={16}
              lineHeight={1.17}
              target='_blank'
              to={`/ops/workflows/${relatedWorkflow.id}`}>
              ID: {relatedWorkflow.id}
              <Box
                component='span'
                sx={{
                  lineHeight: 1,
                  pl: 0.5
                }}>
                <OpenInNewIcon sx={{ fontSize: 12 }} />
              </Box>
            </Link>
            <Typography
              sx={{
                color: theme => theme.palette.grey[600],
                fontSize: 15,
                lineHeight: 1.17
              }}>
              {formatters.displayCase(
                formatters.getValueKey(
                  WorkflowName,
                  relatedWorkflow.workflowName
                )
              )}
            </Typography>
            <Typography
              sx={{
                color: theme => theme.palette.grey[600],
                fontSize: 15,
                lineHeight: 1.17,
                mt: 0.5
              }}>
              {formatters.formatFromIsoDate(relatedWorkflow.createdAt)}
            </Typography>
          </Box>
        ))}
      </DialogContent>
    </Dialog>
  );
};
export default RelatedWorkflowsDialog;
