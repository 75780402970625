import { useField, useFormikContext } from 'formik';
import { FC, useEffect } from 'react';

const ForceOutPreferencesObserver: FC<{
  data: Record<string, any>;
}> = props => {
  const ctx = useFormikContext<Record<string, unknown>>();
  const [, , excludeRolloverHelpers] = useField('excludeRollover');
  const [, , isAutomatedHelpers] = useField('isAutomated');
  const [, , maxAmountHelpers] = useField('maxAmount');
  const [, , rolloverMinHelpers] = useField('rolloverMin');

  useEffect(() => {
    props.data.excludeRollover.setDisabled(
      !ctx.values.isAllowed || ctx.values.isAllowed === 'No'
    );
    props.data.isAutomated.setDisabled(
      !ctx.values.isAllowed || ctx.values.isAllowed === 'No'
    );
    props.data.maxAmount.setDisabled(
      !ctx.values.isAllowed || ctx.values.isAllowed === 'No'
    );
    props.data.rolloverMin.setDisabled(
      !ctx.values.isAllowed || ctx.values.isAllowed === 'No'
    );

    if (!ctx.values.isAllowed || ctx.values.isAllowed === 'No') {
      if (ctx.values.excludeRollover) {
        excludeRolloverHelpers.setValue('');
      }

      if (ctx.values.isAutomated) {
        isAutomatedHelpers.setValue('');
      }

      if (ctx.values.maxAmount || ctx.values.maxAmount === 0) {
        maxAmountHelpers.setValue('');
      }

      if (ctx.values.rolloverMin || ctx.values.rolloverMin === 0) {
        rolloverMinHelpers.setValue('');
      }
    }
  }, [
    ctx.values,
    excludeRolloverHelpers,
    isAutomatedHelpers,
    maxAmountHelpers,
    props.data,
    rolloverMinHelpers
  ]);

  useEffect(() => {
    if (ctx.errors.maxAmount && !ctx.touched.maxAmount) {
      maxAmountHelpers.setTouched(true, false);
    }

    if (ctx.errors.rolloverMin && !ctx.touched.rolloverMin) {
      rolloverMinHelpers.setTouched(true, false);
    }
  }, [ctx.errors, ctx.touched, maxAmountHelpers, rolloverMinHelpers]);

  return null;
};

export default ForceOutPreferencesObserver;
