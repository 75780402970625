import DataTable from '@/components/data-table/DataTable.component';
import DOMInteraction from '@/utils/DOMInteraction';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { alpha } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { ColDef } from 'ag-grid-community';
import React, { useMemo } from 'react';

import ConfirmTableDetailCellRenderer from './ConfirmTableDetailCellRenderer.component';

const useStyles = makeStyles(theme =>
  createStyles({
    columnHeader: {
      color: alpha(theme.palette.common.black, 0.87)
    },
    requestNoteCell: {
      fontSize: 14,
      lineHeight: '20px'
    }
  })
);

interface ConfirmModalTableProps {
  newValues: {
    fund: { symbol: string; cusip: string };
    plans: { plan: number; planName: string }[];
  }[];
}

const ConfirmModalTable: React.FC<ConfirmModalTableProps> = props => {
  const classes = useStyles();
  const detailCellRenderer = useMemo(() => {
    return ConfirmTableDetailCellRenderer(classes.columnHeader);
  }, [classes.columnHeader]);

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        cellRenderer: () => <></>,
        field: 'fund',
        headerName: '',
        maxWidth: 48,
        minWidth: 48,
        suppressMenu: true
      },
      {
        field: 'fund',
        headerClass: () => classes.columnHeader,
        headerName: 'Fund',
        maxWidth: 190,
        minWidth: 130,
        resizable: false,
        suppressMenu: true,
        valueFormatter: params =>
          `${params.value.symbol} | ${params.value.cusip}`
      },
      {
        cellRenderer: (params: any) => {
          const plansNumber = params.value.length;

          if (plansNumber === 1) {
            return `${plansNumber} plan`;
          }

          return `${plansNumber} plans`;
        },
        field: 'plans',
        headerClass: () => classes.columnHeader,
        headerName: 'Plans',
        maxWidth: 130,
        minWidth: 130,
        suppressMenu: true
      },
      {
        autoHeight: true,
        cellClass: classes.requestNoteCell,
        field: 'requestNote',
        headerClass: () => classes.columnHeader,
        headerName: 'Request Note',
        minWidth: 450,
        suppressMenu: true,
        wrapText: true
      }
    ],
    [classes]
  );

  return (
    <DataTable
      animateRows={true}
      columnDefs={columnDefs}
      columnSizing='fit'
      data-testid='data-security-confirm-modal-table'
      detailCellRenderer={detailCellRenderer}
      detailColumn={0}
      detailRowAutoHeight
      icons={{
        groupContracted: DOMInteraction.JSXToHTML(
          <KeyboardArrowRightOutlinedIcon
            className='mui-group-icon'
            fontSize='small'
          />
        ),
        groupExpanded: DOMInteraction.JSXToHTML(
          <KeyboardArrowDownOutlinedIcon
            className='mui-group-icon'
            fontSize='small'
          />
        )
      }}
      pageSize={100}
      paginationPosition='bottom'
      paginationSx={{ py: 0.375 }}
      primaryKey='id'
      rowData={props.newValues}
      rowHeight={48}
      suppressCellFocus
    />
  );
};

export default ConfirmModalTable;
