import AccessControl from '@/components/access-control/AccessControl.component';
import CardInfoField from '@/components/card-info-field';
import LinearLoading from '@/components/linear-loading';
import SimpleDropdown from '@/components/simple-dropdown';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import useCancelSponsorExemption from '@/hooks/useCancelSponsorExemption';
import useCreateSponsorExemption from '@/hooks/useCreateSponsorExemption';
import useSponsor from '@/hooks/useSponsor';
import useSponsorExemption from '@/hooks/useSponsorExemption';
import useUpdateSponsor from '@/hooks/useUpdateSponsor';
import { CreateSponsorExemptionDto, UpdateSponsorDto } from '@/models';
import type { ExemptionReason } from '@/models/SponsorExemptionDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import formatters from '@/utils/Formatters';
import makeExemptionValidationSchema from '@/utils/validations/PlanStatusValidationSchema.schema';
import {
  Button,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  Typography
} from '@mui/material';
import { blue } from '@mui/material/colors';
import Grid from '@mui/material/Unstable_Grid2';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useQueryClient } from '@tanstack/react-query';

import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { isEqual } from 'lodash';
import React from 'react';

interface PlanExemptionStatusCardProps {
  sponsorId: string | number;
  firmId?: number;
}

enum FormattedExemptionReason {
  '401a' = '401(k) - or other 401(a) plan',
  '403a' = '403(a) - qualified annuity plan',
  '403b' = '403(b) - tax-sheltered annuity plan',
  '408k' = '408(k) - SEP plan',
  '408p' = '408(p) - SIMPLE IRA plan',
  '457b' = '457(b) - governmental deferred compensation plan',
  'form5500' = '5500 - federal tax return for a qualified retirement plan',
  'minimum_employee_threshold' = 'Does not meet statutory threshold for minimum employees',
  'no_software' = 'Payroll processed with no software',
  'no_w2_employees' = 'Sole proprietor with no W2 employees'
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cancelButton: {
      color: blue[500]
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    editButton: {
      color: blue[500],
      marginLeft: 'auto'
    },
    root: { width: '100%' },
    submitButton: {
      '&:hover': {
        backgroundColor: blue[700]
      },
      backgroundColor: blue[500],
      color: theme.palette.getContrastText(blue[500])
    }
  })
);

const exemptionReasons: ExemptionReason[] = [
  '401a',
  '403a',
  '403b',
  '408k',
  '408p',
  '457b',
  'form5500'
];

const getMenuItems = (firmId?: number) => {
  const isMarylandSavesFirm = firmId === 1001;
  const isOregonSavesFirm = firmId === 712;
  const minimumEmployee: ExemptionReason = 'minimum_employee_threshold';
  const noSoftwareReason: ExemptionReason = 'no_software';
  const noW2Employees: ExemptionReason = 'no_w2_employees';
  const menuItems: ExemptionReason[] = [...exemptionReasons];

  if (isMarylandSavesFirm) {
    menuItems.push(noSoftwareReason);
  }

  if (isMarylandSavesFirm || isOregonSavesFirm) {
    menuItems.push(noW2Employees);
  } else {
    menuItems.push(minimumEmployee);
  }

  return menuItems.map(item => ({
    option: FormattedExemptionReason[item],
    value: item
  }));
};

const generateCreateSponsorExemptionDto = (
  exemptionReason: string | undefined,
  exemptionSignature: string | undefined,
  sponsorId: number
): CreateSponsorExemptionDto => {
  if (!exemptionReason || !exemptionSignature) {
    throw new Error('Missing required fields');
  }
  return {
    data: {
      attributes: {
        reason: exemptionReason as ExemptionReason,
        signatureName: exemptionSignature
      },
      relationships: {
        sponsor: {
          data: {
            id: sponsorId,
            type: 'sponsor'
          }
        }
      },
      type: 'sponsor-exemption'
    }
  };
};

const PlanExemptionStatusCard = (
  props: PlanExemptionStatusCardProps
): JSX.Element => {
  const { sponsorId, firmId } = props;
  const { openDialog, closeDialog } = useDialog();
  const sponsorExemptionMutation = useCreateSponsorExemption();
  const cancelSponsorExemptionMutation = useCancelSponsorExemption();
  const { showSnackbar } = useSnackbar();

  const classes = useStyles();

  const queryClient = useQueryClient();

  const sponsorQuery = useSponsor<{
    exemptionId: number | undefined;
    attributes: UpdateSponsorDto['data']['attributes'];
  }>(sponsorId.toString(), sponsorDto => ({
    attributes: {
      address: sponsorDto.data.attributes.address,
      alternateEmployerId: sponsorDto.data.attributes.alternateEmployerId,
      companyPhoneNumber: sponsorDto.data.attributes.companyPhoneNumber,
      doingBusinessAs: sponsorDto.data.attributes.doingBusinessAs,
      name: sponsorDto.data.attributes.name,
      physicalAddress: sponsorDto.data.attributes.physicalAddress,
      primaryContact: sponsorDto.data.attributes.primaryContact
    },
    exemptionId: sponsorDto.data.relationships.exemption?.data.id
  }));

  const sponsorMutation = useUpdateSponsor(false);

  const sponsorExemptionQuery = useSponsorExemption<{
    reason: ExemptionReason;
    signatureName: string;
    exemptionCode: string;
    canceledAt: string | undefined;
    createdAt: string;
  }>(sponsorQuery.data?.exemptionId, sponsorExemptionDTO => ({
    canceledAt: sponsorExemptionDTO.data.attributes.canceledAt,
    createdAt: sponsorExemptionDTO.data.attributes.createdAt,
    exemptionCode:
      sponsorExemptionDTO.data.attributes.exemptionConfirmationCode,
    reason: sponsorExemptionDTO.data.attributes.reason,
    signatureName: sponsorExemptionDTO.data.attributes.signatureName
  }));

  const isExempt = Boolean(
    sponsorQuery.data?.exemptionId && !sponsorExemptionQuery.data?.canceledAt
  );

  const initialValues = sponsorExemptionQuery.isSuccess
    ? {
        alternateEmployerId: sponsorQuery.data?.attributes.alternateEmployerId,
        exemptionReason: isExempt ? sponsorExemptionQuery.data.reason : '',
        exemptionSignature: isExempt
          ? sponsorExemptionQuery.data.signatureName
          : '',
        exemptionStatus: isExempt ? 'Exempt' : 'Non-Exempt'
      }
    : {
        alternateEmployerId: sponsorQuery.data?.attributes.alternateEmployerId,
        exemptionReason: '',
        exemptionSignature: '',
        exemptionStatus: isExempt ? 'Exempt' : 'Non-Exempt'
      };

  return (
    <Card
      className={classes.root}
      data-testid='plan-exemption-status-card'
      elevation={0}
      variant='outlined'>
      <CardContent>
        <LinearLoading
          fadeIn={sponsorExemptionQuery.isFetching || sponsorQuery.isFetching}
        />
        <Grid
          container
          flexDirection='row'
          flexWrap='nowrap'
          justifyContent='space-between'>
          <Grid>
            <Typography variant='h5'>Exemption Status</Typography>
            <Grid columnGap={3} container flexDirection='row'>
              <Grid container flexDirection='column'>
                <CardInfoField
                  fieldName='Exemption Status'
                  fieldValue={isExempt ? 'Exempt' : 'Non-Exempt'}
                  key='exemption-status'
                />
                {isExempt && sponsorExemptionQuery.isSuccess && (
                  <CardInfoField
                    fieldName='Exemption Reason'
                    fieldValue={
                      FormattedExemptionReason[
                        sponsorExemptionQuery.data.reason
                      ] || ''
                    }
                    key='exemption-reason'
                  />
                )}
              </Grid>
              {isExempt && sponsorExemptionQuery.isSuccess && (
                <>
                  <Grid container flexDirection='column'>
                    <Grid>
                      <CardInfoField
                        fieldName='Signature'
                        fieldValue={sponsorExemptionQuery.data.signatureName}
                        key='exemption-signature'
                      />
                    </Grid>
                    <Grid>
                      <CardInfoField
                        fieldName='Exemption Code'
                        fieldValue={sponsorExemptionQuery.data.exemptionCode}
                        key='exemption-code'
                      />
                    </Grid>
                  </Grid>
                  <Grid>
                    <CardInfoField
                      fieldName='Exemption Date'
                      fieldValue={formatters.formatFromIsoDateCustom(
                        sponsorExemptionQuery.data.createdAt,
                        'MM/DD/YYYY'
                      )}
                      key='exemption-date'
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Grid>
            <AccessControl
              requiresOneOf={[FeatureLevelPermissions.WRITE_COMPANY_EXEMPTION]}>
              <Button
                className={classes.editButton}
                data-testid='edit-exemption-status-button'
                onClick={() =>
                  openDialog({
                    customContent: (
                      <Formik
                        initialValues={initialValues}
                        onSubmit={async values => {
                          if (
                            values.exemptionStatus !==
                              initialValues.exemptionStatus ||
                            values.exemptionReason !==
                              initialValues.exemptionReason ||
                            values.exemptionSignature !==
                              initialValues.exemptionSignature
                          ) {
                            if (sponsorQuery.data?.exemptionId) {
                              await cancelSponsorExemptionMutation.mutateAsync({
                                invalidateSponsor:
                                  values.exemptionStatus !== 'Exempt',
                                sponsorExemptionId:
                                  sponsorQuery.data?.exemptionId
                              });
                            }

                            if (values.exemptionStatus === 'Exempt') {
                              await sponsorExemptionMutation.mutateAsync(
                                generateCreateSponsorExemptionDto(
                                  values.exemptionReason,
                                  values.exemptionSignature,
                                  Number(sponsorId)
                                )
                              );
                            }
                          } else {
                            showSnackbar({
                              message: 'Success!',
                              severity: 'success'
                            });
                          }
                          if (
                            firmId === 1001 &&
                            (values.exemptionStatus !==
                              initialValues.exemptionStatus ||
                              values.alternateEmployerId !==
                                initialValues.alternateEmployerId)
                          ) {
                            await sponsorMutation.mutateAsync({
                              data: {
                                attributes: {
                                  ...sponsorQuery.data?.attributes,
                                  alternateEmployerId:
                                    values.alternateEmployerId
                                },
                                id: +sponsorId,
                                type: 'sponsor'
                              }
                            });
                          }

                          queryClient.invalidateQueries({
                            predicate: ({ queryHash }) => {
                              return queryHash.includes(sponsorId.toString());
                            }
                          });

                          closeDialog();
                        }}
                        validateOnBlur={false}
                        validateOnChange={false}
                        validationSchema={makeExemptionValidationSchema(
                          firmId === 1001
                        )}>
                        {formProps => {
                          const unchangedValues =
                            isEqual(
                              formProps.initialValues,
                              formProps.values
                            ) &&
                            Object.keys(formProps.initialValues).length > 0 &&
                            Object.keys(formProps.values).length > 0;
                          const saveDisabled =
                            formProps.isSubmitting || unchangedValues;

                          return (
                            <Form>
                              <DialogTitle id='edit-exemption-status'>
                                Edit Exemption Status
                              </DialogTitle>
                              <DialogContent className={classes.dialogContent}>
                                <SimpleDropdown
                                  fieldId='exemptionStatus'
                                  fieldName='Exemption Status'
                                  fieldValues={['Exempt', 'Non-Exempt']}
                                />
                                {formProps.values.exemptionStatus ===
                                  'Exempt' && (
                                  <SimpleDropdown
                                    fieldId='exemptionReason'
                                    fieldName='Exemption Reason'
                                    fieldOptions={getMenuItems(firmId)}
                                  />
                                )}

                                {formProps.values.exemptionStatus ===
                                  'Exempt' &&
                                  firmId === 1001 && (
                                    <Field
                                      component={TextField}
                                      fullWidth
                                      key='alternateEmployerId'
                                      label='SDAT'
                                      name='alternateEmployerId'
                                    />
                                  )}
                                {formProps.values.exemptionStatus ===
                                  'Exempt' && (
                                  <Field
                                    component={TextField}
                                    fullWidth
                                    key='exemptionSignature'
                                    label='Exemption Signature'
                                    name='exemptionSignature'
                                  />
                                )}
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  className={classes.cancelButton}
                                  disabled={formProps.isSubmitting}
                                  onClick={closeDialog}>
                                  Cancel
                                </Button>
                                <Button
                                  className={classes.submitButton}
                                  disabled={saveDisabled}
                                  type='submit'
                                  variant='contained'>
                                  Save
                                </Button>
                              </DialogActions>
                            </Form>
                          );
                        }}
                      </Formik>
                    )
                  })
                }>
                Edit
              </Button>
            </AccessControl>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PlanExemptionStatusCard;
