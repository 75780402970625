export default interface FraudrankerResult {
  data: FraudrankerResultData;
}

export interface FraudrankerResultData {
  id: number;
  reason?: string;
  fraudScore: number;
  findings: string[];
  fraudRank: string;
  participantId: number;
  firstName?: string;
  lastName?: string;
  timestamp?: string;
  amount?: number;
  ipAddresses?: string;
  jiraTicketLink?: string;
  plaidAccountOwners?: PlaidIdentity;
  plaidIdentityMatch?: PlaidIdentityMatch;
  snapshotAccountOwners?: PlaidIdentity;
  context: Record<string, any>;
}

export type UpdatedFraudranker = {
  fraudRank: string;
  updatedAt: string;
  updatedBy: {
    authRole: string;
    id: string;
    idType: string;
  };
};

interface PlaidIdentity {
  addresses: PlaidAddress[];
  emails: PlaidEmail[];
  names: string[];
  phoneNumbers: PlaidPhoneNumber[];
}

interface PlaidAddress {
  street?: string;
  city?: string;
  region?: string;
  postalCode?: string;
  country?: string;
}

interface PlaidEmail {
  data?: string;
  isPrimary?: boolean;
  type?: string;
}

interface PlaidPhoneNumber {
  data?: string;
  isPrimary?: boolean;
  type?: string;
}

interface PlaidIdentityMatch {
  legalNameMatch: PlaidLegalNameMatch;
  addressMatch: PlaidAddresseeMatch;
  phoneNumbersMatchScore: number;
  emailMatchScore: number;
}

interface PlaidLegalNameMatch {
  score: number;
  isNicknameMatch: boolean;
  isFirstNameOrLastNameMatch: boolean;
  isBusinessNameDetected: boolean;
}

interface PlaidAddresseeMatch {
  score: number;
  isPostalCodeMatch: boolean;
}

type FraudrankerStats = {
  averageFraudScore: number;
  distributionsPerDay: number;
  loansPerDay: number;
  withdrawalsPerDay: number;
};

export type FraudrankerOverviewResponse = {
  findings: {
    loan?: Record<string, number>;
    overall: Record<string, number>;
    withdrawal?: Record<string, number>;
  };
  stats: FraudrankerStats;
};

export const FindingTextMap: Record<string, any> = {
  ACCT_NAME: 'Bank Account Name Difference',
  ACCT_STREET: 'Bank Account Street Difference',
  ACCT_ZIP_LOCAL: 'Bank Account Zip Local Difference',
  ACCT_ZIP_REGION: 'Bank Account Zip Region Difference',
  AMOUNT: 'Amount',
  AMT_PCT: 'Amount Percentage',
  CASH: 'Cash',
  CHILD: 'Child',
  EMAIL_DIFF: 'Plaid Email Difference',
  HARDSHIP: 'Hardship',
  INST_NAME: 'Institution Name',
  IP_FRAUD_HIGH: 'IP Fraud Score High',
  IP_FRAUD_LOW: 'IP Fraud Score Low',
  IP_FRAUD_QUERY_FAILURE: 'IP Fraud Query Failure',
  IP_GEOLOCATION_FAILURE: 'IP Geolocation Failure',
  IP_NOT_US: 'IP outside of US',
  LOAN: 'Loan',
  METHOD_UNKNOWN: 'Method Unknown',
  MFA_NOT_SETUP: 'MFA Not Setup',
  MFA_TWICE_DISABLED: 'MFA Disabled Twice In Last 30 days',
  MFA_UPDATE_RECENT: 'MFA Updated Recently',
  MULTI_WITH: 'Multiple Withdrawals',
  NO_PLAID_EMAIL: 'No Plaid Email',
  NO_PLAID_PHONE: 'No Plaid Phone',
  NO_VW_EMAIL: 'Participant Email Not Set',
  NO_VW_PHONE: 'Participant Phone Not Set',
  PARTICIPANT_CHANGE: 'Participant Change',
  PARTICIPANT_NEVER_UPDATED: 'Participant Never Updated',
  PASSWORD_CHANGE: 'Password Change',
  PHONE_DIFF: 'Plaid Phone Difference',
  REASON_UNKNOWN: 'Reason Unknown',
  ROLLOVER: 'Rollover'
};

export type FraudRankerReportResponse = {
  data?: FraudRankerReport[];
  totalCount: number;
};

export type FraudRankerReport = {
  amount: number;
  calloutParticipant: boolean;
  id: number;
  jiraTicket?: string;
  latestFraudrank?: string;
  participant: {
    id: number;
    name: string;
  };
  reason: string;
  requestDate: string;
  score: number;
};

export type FraudrankerReportQueryParams = {
  pageNumber?: number;
  pageSize?: number;
  sort?: string;
  id?: number;
  fraudType?: 'Withdrawal' | 'Loan' | 'LoanAndWithdrawal';
  status?: '' | 'New' | 'Review' | 'Done';
  hasTicket?: boolean;
  calloutToParticipant?: boolean;
  distributionAmountMax?: number;
  distributionAmountMin?: number;
  fraudScore?: '' | 'Green' | 'Yellow' | 'Red';
  scoreModified?: boolean;
  from?: string;
  to?: string;
};
