import { useSnackbar } from '@/contexts/SnackBarContext';
import SponsorService from '@/services/Sponsor.service';
import {
  EmailOutlined,
  PersonOutline,
  PhoneOutlined
} from '@mui/icons-material';
import {
  alpha,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Stack,
  styled,
  Typography
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { FC } from 'react';
import { PayrollContactResponse } from 'scala-sdk';

import CardIconInfoField from './CardIconInfoField.component';

const StyledColorContainer = styled('div')(props => ({
  backgroundColor: alpha(props.theme.palette.primary.main, 0.08),
  padding: `${props.theme.spacing(1)} ${props.theme.spacing(3)}`
}));

type DeletePayrollContactDialogProps = {
  payrollContact?: Partial<PayrollContactResponse>;
  open: boolean;
  onClose: () => void;
  sponsorId: number;
};

export const DeletePayrollContactDialog: FC<
  DeletePayrollContactDialogProps
> = props => {
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const deletePayrollContactQuery = useMutation(
    [
      `SponsorService.deleteSponsorPayrollContact`,
      props.sponsorId,
      props.payrollContact?.id
    ],
    async () =>
      await SponsorService.deleteSponsorPayrollContact(
        props.sponsorId,
        props.payrollContact.id
      ),
    {
      onError: () => {
        showSnackbar({
          message: 'Error deleting payroll contact',
          severity: 'error'
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries([
          'SponsorService.getSponsorPayrollContacts',
          props.sponsorId
        ]);
        showSnackbar({
          message: 'Payroll contact was deleted',
          severity: 'success'
        });
        props.onClose();
      }
    }
  );

  const handleDelete = async () => {
    await deletePayrollContactQuery.mutateAsync();
    props.onClose();
  };
  return (
    <Dialog fullWidth maxWidth='sm' onClose={props.onClose} open={props.open}>
      <DialogTitle>Delete Payroll Contact</DialogTitle>
      <StyledColorContainer>
        <Grid pb={2}>
          <CardIconInfoField
            icon={<PersonOutline />}
            subtitle={props.payrollContact?.role}
            title={[
              props.payrollContact?.firstName,
              props.payrollContact?.lastName
            ].join(' ')}
            tooltip='Payroll Contact Name'
          />
        </Grid>
        <CardIconInfoField
          icon={<PhoneOutlined />}
          subtitle={props.payrollContact?.phoneNumber}
          tooltip='Payroll Contact Number'
        />
        <CardIconInfoField
          icon={<EmailOutlined />}
          subtitle={props.payrollContact?.email}
          tooltip='Payroll Contact Email'
        />
      </StyledColorContainer>
      <Stack marginX={3} marginY={1}>
        <Typography variant='body2'>
          Are you sure you want to delete this payroll contact?
        </Typography>
      </Stack>
      <DialogActions>
        <Button
          data-testid='cancel-payroll-contact-delete-btn'
          disabled={deletePayrollContactQuery.isLoading}
          onClick={props.onClose}>
          Cancel
        </Button>
        <Button
          color='error'
          data-testid='delete-payroll-contact-btn'
          disabled={deletePayrollContactQuery.isLoading}
          onClick={handleDelete}
          variant='contained'>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
