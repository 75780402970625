import planConsts from '@/consts/planDesign.constants';
import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class Rollover {
  convertToApiState = Helper.convertValueToApiState;

  output: string | undefined;

  options = planConsts.rolloverOptions;

  constructor(props: Record<string, any>) {
    this.output = props.allowRollover;
  }
}

export default Rollover;
