import formatters from '@/utils/Formatters';
import { Box, Tab, Tabs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import React, { FunctionComponent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface VerticalTabsData {
  component: JSX.Element | null;
  label: string;
}

interface VerticalTabsProps {
  className?: any;
  tabs: VerticalTabsData[];
  defaultTabIdx?: number;
  'data-testid'?: string;
  hideTabs?: boolean;
}

const useStyles = makeStyles(() => ({
  tab: {
    '&.Mui-selected': { backgroundColor: 'rgba(33, 150, 243, 0.04)' },
    alignItems: 'start',
    padding: '0 0 0 16px',
    textAlign: 'left'
  }
}));

const VerticalTabs: FunctionComponent<VerticalTabsProps> = (
  props: VerticalTabsProps
) => {
  const {
    tabs = [],
    defaultTabIdx = 0,
    'data-testid': dataTestId,
    hideTabs
  } = props;

  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(defaultTabIdx);

  const handleChange = (_event: any, newValue: number) => {
    setValue(newValue);

    if (newValue !== defaultTabIdx) {
      const hash = props.tabs[newValue].label
        .toLowerCase()
        .split(' ')
        .join('-');
      navigate(`${location.pathname}#${hash}`);
    } else {
      navigate(location.pathname);
    }
  };

  useEffect(() => {
    if (location.hash) {
      const label = location.hash.replaceAll('#', '').replaceAll('-', ' ');
      const index = tabs.findIndex(
        tab => tab.label.toLowerCase() === label.toLowerCase()
      );
      setValue(index);
    }
  }, [location.hash, tabs]);

  useEffect(() => {
    if (value === -1) {
      setValue(0);
      navigate(location.pathname, { replace: true });
      return;
    }
  }, [value, navigate, location.pathname]);

  return (
    <Box data-testid={dataTestId} display='flex'>
      {!hideTabs && (
        <Box display='inline-flex'>
          <Tabs
            data-testid={`${dataTestId}-tabs`}
            onChange={handleChange}
            orientation='vertical'
            sx={{
              borderColor: 'divider',
              borderRight: 1,
              width: 250
            }}
            value={value > 0 ? value : 0}
            variant='standard'>
            {tabs.map(({ label }) => {
              return (
                <Tab
                  className={classes.tab}
                  data-testid={`${dataTestId}-${formatters.textToDataTestId(
                    label
                  )}-tab-button`}
                  key={label}
                  label={label}
                />
              );
            })}
          </Tabs>
        </Box>
      )}
      <Box
        display='inline-flex'
        sx={{
          overflow: 'auto',
          width: '100%'
        }}>
        {tabs[value]?.component}
      </Box>
    </Box>
  );
};

export default VerticalTabs;
export type { VerticalTabsProps, VerticalTabsData };
