import { SecurityDto } from '@/models/SecurityMaster.model';
import ApiService from '@/services/Api.service';

class SecurityMasterService {
  static async getSecuritiesByCusips(cusips: string[]): Promise<SecurityDto[]> {
    const cusipParam = cusips.join(',');
    return ApiService.getJson(`/security-master/securitiesByCusips`, {
      cusips: cusipParam
    });
  }
}

export { SecurityMasterService };
