import Card, { CardContent } from '@/components/card';
import LinearLoading from '@/components/linear-loading';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { AllPlanStatsDto } from '@/models/PlanStatsDTO.model';
import { PlanService } from '@/services/Plan.service';
import formatters from '@/utils/Formatters';
import { useQuery } from '@tanstack/react-query';

type PooledPlanStatusProps = {
  pooledPlanId: number;
};

export const PooledPlanStats: React.FC<PooledPlanStatusProps> = props => {
  const pooledPlanStats = useQuery(
    ['PlanService.getOverviewStats', props.pooledPlanId],
    () => PlanService.getPooledPlanStats([props.pooledPlanId]),
    {
      enabled: !!props.pooledPlanId,
      select: data => data[0] as AllPlanStatsDto,
      staleTime: Infinity
    }
  );

  return (
    <Card data-testid='pooled-plans-stats-card' sx={{ mb: 5, mt: 5 }}>
      <LinearLoading fadeIn={pooledPlanStats.isLoading} />
      <CardContent>
        <TextStack divider rowColumnWidth='dynamic' spacing={3} sx={{ p: 1 }}>
          <TextStackItem>
            <TextLabel>Total Plan Market Value</TextLabel>
            <TextValue data-testid='total-plan-market-value'>
              {pooledPlanStats.data?.totalPlanMarketValue
                ? formatters.formatDollars(
                    pooledPlanStats.data.totalPlanMarketValue
                  )
                : null}
            </TextValue>
          </TextStackItem>
          <TextStackItem>
            <TextLabel>Number of Onboarding Plans</TextLabel>
            <TextValue data-testid='number-of-onboarding-plans'>
              {pooledPlanStats.data?.plansOnboarding || null}
            </TextValue>
          </TextStackItem>
          <TextStackItem>
            <TextLabel>Number of Active Plans</TextLabel>
            <TextValue data-testid='number-of-active-plans'>
              {pooledPlanStats.data?.plansActive || null}
            </TextValue>
          </TextStackItem>
        </TextStack>
      </CardContent>
    </Card>
  );
};
