import { ReplayResponseDto } from '@/hooks/useQueueError.hook';
import {
  BulkErrorReplayResponse,
  QueueErrorDTO,
  QueueErrorPaginatedResponse,
  SubaQueueErrorDTO
} from '@/models/QueueErrorsDTO.model';
import ApiService from '@/services/Api.service';

import { isEmpty } from 'lodash';

class QueueErrorService {
  static async getSubaQueueErrors(
    type: string,
    value: string,
    includeArchived: boolean
  ): Promise<SubaQueueErrorDTO> {
    return ApiService.getJson('/queue-errors/suba/', {
      includeArchived: !includeArchived ? includeArchived : undefined,
      type,
      value
    });
  }

  static async getAllQueueErrors(
    includeArchived: boolean
  ): Promise<QueueErrorDTO[]> {
    return ApiService.getJson('/queue-errors', {
      includeArchived: !includeArchived ? includeArchived : undefined
    });
  }

  static async getFilteredQueueErrors(
    category: string,
    includeArchived: boolean,
    pageNumber: number,
    pageSize: number,
    sortBy?: string,
    sortOrder?: string,
    search?: string
  ): Promise<QueueErrorPaginatedResponse> {
    return ApiService.getJson('/queue-errors/filtered', {
      archived: !includeArchived ? includeArchived : undefined,
      category,
      pageNumber,
      pageSize,
      search: !isEmpty(search) ? search : undefined,
      sort: `${sortOrder === 'desc' ? '-' : ''}${sortBy}`
    });
  }

  static async archiveQueueError(id: number): Promise<unknown> {
    return ApiService.putJson(`/queue-errors/archive/${id}`, {});
  }

  static async unarchiveQueueError(id: number): Promise<unknown> {
    return ApiService.putJson(`/queue-errors/unarchive/${id}`, {});
  }

  static async replayQueueError(id: number): Promise<ReplayResponseDto> {
    return ApiService.putJson(`/queue-errors/replay/${id}`, {});
  }

  static async saveNote(id: number, note: string): Promise<unknown> {
    return ApiService.putJson('/queue-errors/note/', { id, note });
  }

  static async bulkArchiveQueueErrors(
    ids: number[],
    note: string
  ): Promise<unknown> {
    return ApiService.putJson('/queue-errors/bulk/archive', { ids, note });
  }

  static async bulkReplayQueueErrors(
    ids: number[]
  ): Promise<BulkErrorReplayResponse> {
    return ApiService.putJson('/queue-errors/bulk/replay', { ids });
  }

  static async bulkNoteQueueErrors(
    ids: number[],
    note: string
  ): Promise<BulkErrorReplayResponse> {
    return ApiService.putJson('/queue-errors/bulk/note', { ids, note });
  }
}

export default QueueErrorService;
