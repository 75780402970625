import CircularLoading from '@/components/circular-loading';
import CollapsibleTable from '@/components/collapsible-table';
import { useDialog } from '@/contexts/DialogContext';
import { DocumentMetadataListDtoV2 } from '@/models/DocumentMetadataDTO.model';
import { PlanService } from '@/services/Plan.service';
import { CheckCircle } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { createStyles, makeStyles } from '@mui/styles';
import { useQuery } from '@tanstack/react-query';

import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';

import DocUploadHistoryCell from './DocUploadHistoryCell.component';

interface DocUploadHistoryDialogProps {
  planId: number | string;
  documentKey: string;
  documentName: string;
  groupId: number;
  categoryId: number;
  uploadHistoryId?: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionsCol: {
      width: '11%'
    },
    checkIcon: {
      fill: theme.palette.success.main
    },
    dialogContext: {
      maxWidth: 880,
      width: '100%'
    },
    effectiveCol: {
      width: '14%'
    },
    historyIcon: {
      '&:hover': {
        fill: theme.palette.primary.main
      },
      cursor: 'pointer',
      display: 'block',
      fill: grey[500]
    },
    uploadedCol: {
      width: '30%'
    }
  })
);

export enum HistoryFields {
  ORIGINAL_FILE_NAME = 'original_file_name',
  CREATED_AT = 'createdAt',
  ACTIONS = 'actions'
}

const DocUploadHistoryDialog = (
  props: DocUploadHistoryDialogProps
): JSX.Element => {
  const {
    documentKey,
    documentName,
    planId,
    uploadHistoryId,
    groupId,
    categoryId
  } = props;

  const classes = useStyles();
  const { closeDialog } = useDialog();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  const historyQuery = useQuery(
    ['PlanService.getPlanDocUploadHist', +planId, documentKey],
    async () => PlanService.getPlanDocUploadHist(+planId, documentKey),
    {
      enabled: Boolean(planId) && Boolean(documentKey),
      select: (data: DocumentMetadataListDtoV2) => {
        const latestDate = Math.max(
          ...data.map(h => new Date(h.createdAt).valueOf())
        );

        return data
          .map(h => ({
            ...h,
            categoryId: categoryId,
            createdAt: dayjs(h.createdAt).format('MM/DD/YYYY hh:mm:ss A'),
            groupId: groupId,
            isHighlighted: h.id === uploadHistoryId,
            isLatest: new Date(h.createdAt).valueOf() === latestDate
          }))
          .sort(
            (h1, h2) =>
              new Date(h2.createdAt).getTime() -
                new Date(h1.createdAt).getTime() || h2.id - h1.id
          );
      },
      staleTime: Infinity
    }
  );

  const columns = useMemo(
    () => [
      {
        field: HistoryFields.ORIGINAL_FILE_NAME,
        headerName: 'File Name'
      },
      {
        cellClassName: classes.uploadedCol,
        field: HistoryFields.CREATED_AT,
        headerName: 'Uploaded At'
      },
      {
        cellClassName: classes.actionsCol,
        field: HistoryFields.ACTIONS,
        headerName: ''
      }
    ],
    [classes]
  );

  return (
    <>
      <DialogTitle>{`${documentName} Details`}</DialogTitle>
      <DialogContent className={classes.dialogContext}>
        {historyQuery.isFetching ? (
          <CircularLoading />
        ) : !historyQuery.data?.length ? (
          <Typography>No history found</Typography>
        ) : (
          <>
            <CollapsibleTable
              backgroundPaperElevation={0}
              cellComponent={DocUploadHistoryCell}
              columns={columns}
              data-testid='doc-upload-hist-table'
              pager={{
                metaCount: historyQuery.data?.length,
                onPageNumberChanged: (newPage: number) => {
                  return setPageNumber(newPage + 1);
                },
                onRowsPerPageChanged: (newRowsPerPage: number) => {
                  return setRowsPerPage(newRowsPerPage);
                },
                pageNumber: pageNumber - 1,
                rowsPerPage: rowsPerPage
              }}
              rootPaperElevation={0}
              tableData={
                historyQuery.data
                  ? historyQuery.data?.slice(
                      rowsPerPage * pageNumber - rowsPerPage,
                      rowsPerPage * pageNumber
                    )
                  : []
              }
              useSquareBottomContainer
            />
            <Box alignItems='center' display='flex' gap={1.2}>
              <CheckCircle
                className={classes.checkIcon}
                data-testid='document-latest-mark-document-id-legend'
              />
              <Typography fontStyle='italic' variant='body1'>
                Denotes document currently showing in employer/employee portal
              </Typography>
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Close</Button>
      </DialogActions>
    </>
  );
};

export default DocUploadHistoryDialog;
