import Badge from '@/components/badge';
import CircularLoading from '@/components/circular-loading';
import CollapsibleTable, {
  CellComponentProps
} from '@/components/collapsible-table';
import { useUserToken } from '@/contexts/UserTokenContext';
import { OrganizationUser } from '@/models/OrganizationUser.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import TpaService from '@/services/Tpa.service';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';
import GroupIcon from '@mui/icons-material/Group';
import { Box, Paper, Stack, TableCell, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';

import ActionTpaBtn from './ActionTpaBtn.component';
import AddTpaUserToolbar from './AddTpaUserToolbar.component';

interface ManageTPAUsersTableProps {
  userGroups: string[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionCell: {
      width: '40%'
    },
    actionHeader: {
      paddingLeft: theme.spacing(3)
    },
    body: {
      fontSize: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold
    },
    cell: {
      ...theme.typography.caption,
      color: theme.palette.grey[700],
      fontSize: theme.spacing(2),
      width: '25%'
    },
    noData: {
      fontSize: theme.spacing(2.4),
      marginTop: theme.spacing(4),
      padding: theme.spacing(2),
      textAlign: 'center'
    },
    noTeamMembersIcon: {
      color: theme.palette.grey[400],
      fontSize: '48px'
    },
    paper: {
      marginBottom: theme.spacing(2),
      width: '100%'
    },
    root: {
      marginTop: theme.spacing(5),
      width: '100%'
    },
    statusCell: {
      width: '10%'
    },
    table: {
      minWidth: 750
    },
    title: {
      marginBottom: theme.spacing(1),
      textAlign: 'left'
    }
  })
);

const FIVE_MINUTES_IN_MILLISECONDS = 300000;

const ManageTPAUsersTable = (props: ManageTPAUsersTableProps): JSX.Element => {
  const classes = useStyles();
  const { userHasValidToken } = useUserToken();
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { userGroups } = props;
  const [organizationId] = userGroups;
  const isUserGroupOrganization = organizationId.includes('org_');
  const [searchTerm, setSearchTerm] = useState('');

  const canManageTpaUsers = userService.hasPermission(
    FeatureLevelPermissions.WRITE_TPA_DETAILS_MANAGE_USERS
  );

  const defaultColumns: GridColDef[] = [
    { field: 'email', headerName: 'Email' },
    { field: 'role', headerName: 'TPA Role' },
    { field: 'status', headerName: 'Status' },
    ...(canManageTpaUsers
      ? [
          {
            cellClassName: classes.actionHeader,
            field: 'action',
            headerName: 'Action'
          }
        ]
      : [])
  ];

  const invitesQuery = useQuery<OrganizationUser[]>(
    ['TpaService.getTpaInvites', organizationId?.toString()],
    () => TpaService.getTpaInvites(organizationId),
    {
      enabled: Boolean(isUserGroupOrganization && userHasValidToken),
      retry: false,
      staleTime: FIVE_MINUTES_IN_MILLISECONDS
    }
  );

  const membersQuery = useQuery<OrganizationUser[]>(
    ['TpaService.getTpaMembers', organizationId?.toString()],
    () => TpaService.getTpaMembers(organizationId),
    {
      enabled: Boolean(isUserGroupOrganization && userHasValidToken),
      retry: false,
      staleTime: FIVE_MINUTES_IN_MILLISECONDS
    }
  );

  const isLoading = membersQuery.isFetching || invitesQuery.isFetching;

  const TpaUserCell: React.FunctionComponent<CellComponentProps> = (
    cellProps: CellComponentProps
  ) => {
    const { row, column } = cellProps;
    let cellClass = column.cellClassName
      ? clsx(classes.cell, column.cellClassName)
      : clsx(classes.cell);

    if (column.field === 'action') {
      cellClass = clsx(classes.cell, classes.actionCell);
    }

    const CellComponent = useMemo(() => {
      switch (column.field) {
        case 'action':
          return (
            <ActionTpaBtn
              isRegistered={row.status === 'Registered'}
              user={row}
            />
          );
        case 'role':
          return <>{formatters.capitalizeFirstChar(row[column.field])}</>;
        case 'status':
          return (
            <>
              <Badge
                color={
                  row[column.field] === 'Registered' ? 'success' : 'neutral'
                }
                size='medium'>
                {row[column.field]}
              </Badge>
            </>
          );
        default:
          return <>{row[column.field]}</>;
      }
    }, [row, column.field]);

    return (
      <TableCell className={cellClass} component='th' scope='row'>
        <Box>{CellComponent}</Box>
      </TableCell>
    );
  };

  const tpaUsers = useMemo(() => {
    const registeredUsers = membersQuery.data || [];
    const invitedUsers = invitesQuery.data || [];

    return [...invitedUsers, ...registeredUsers].filter(user =>
      searchTerm
        ? (user.email + user.role + user.status)
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase())
        : true
    );
  }, [invitesQuery.data, membersQuery.data, searchTerm]);

  useEffect(() => {
    if (searchTerm) {
      setPageNumber(1);
    }
  }, [searchTerm]);

  return (
    <div
      className={classes.root}
      data-testid='tpa-detail-page-manage-users-table'>
      <Paper className={classes.paper} elevation={0} variant='outlined'>
        <AddTpaUserToolbar
          orgId={organizationId}
          setSearchTerm={setSearchTerm}
          tpaUsers={tpaUsers}
        />
        {isLoading && (
          <Box data-testid='tpa-users-loading'>
            <Stack
              alignItems='center'
              justifyContent='center'
              sx={{
                minHeight: '233px'
              }}>
              <CircularLoading size='36px' />
            </Stack>
          </Box>
        )}
        {!isLoading && (
          <CollapsibleTable
            cellComponent={TpaUserCell}
            columns={defaultColumns}
            data-testid='tpa-manage-users-table'
            noDataPlaceholderComponent={
              <Box
                alignItems='center'
                data-testid='no-team-members-container'
                display='flex'
                flexDirection='column'
                height='200px'
                justifyContent='center'
                width='100%'>
                <GroupIcon className={classes.noTeamMembersIcon} />
                <Typography variant='body1'>No users</Typography>
              </Box>
            }
            pager={{
              metaCount: tpaUsers.length,
              onPageNumberChanged: (zeroIndexedPageNumber: number) => {
                return setPageNumber(zeroIndexedPageNumber + 1);
              },
              onRowsPerPageChanged: (newRowsPerPage: number) => {
                return setRowsPerPage(newRowsPerPage);
              },
              pageNumber: pageNumber - 1,
              rowsPerPage
            }}
            primaryKey='email'
            tableData={(tpaUsers || []).slice(
              rowsPerPage * pageNumber - rowsPerPage,
              rowsPerPage * pageNumber
            )}
            useDivAsBackground
          />
        )}
      </Paper>
    </div>
  );
};

export default ManageTPAUsersTable;
