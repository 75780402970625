import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import {
  gridDetailPanelExpandedRowsContentCacheSelector,
  GridRenderCellParams,
  useGridApiContext,
  useGridSelector
} from '@mui/x-data-grid-pro';

import * as React from 'react';

export const CustomDetailPanelToggle: React.FC<
  Pick<GridRenderCellParams, 'id' | 'value'>
> = props => {
  const apiRef = useGridApiContext();

  const contentCache = useGridSelector(
    apiRef,
    gridDetailPanelExpandedRowsContentCacheSelector
  );

  const hasDetail = React.isValidElement(contentCache[props.id]);

  return (
    <IconButton
      aria-label={props.value ? 'Close' : 'Open'}
      disabled={!hasDetail}
      size='small'
      tabIndex={-1}>
      {props.value ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
    </IconButton>
  );
};
