import Badge from '@/components/badge';
import { CellComponentProps } from '@/components/collapsible-table';
import {
  Box,
  Button,
  Unstable_Grid2 as Grid,
  Link,
  TableCell,
  Typography
} from '@mui/material';

import React, { FC, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { RemoveEmployeeRow } from '../RemoveEmployeeDialog.component';
import { MatchedAccountFields } from './EmployeeMatchesTable.component';
import { MergeParticipantDialog } from './MergeParticipantDialog.component';

export type EmpoyeeMatchesRow = {
  createdAt: string;
  dob: string;
  id: number;
  firstName: string;
  lastName: string;
  ssn: string;
  matchBy: string[];
  planName: string;
  planType: string;
  planId: number;
  originalParticipant: RemoveEmployeeRow;
};

type CellProps = {
  row: EmpoyeeMatchesRow;
};

const IdCell: FC<CellProps> = props => {
  return (
    <Box key={props.row.id}>
      <Link
        component={RouterLink}
        to={`/participants/${props.row.id}`}
        variant='body2'>
        {props.row.id}
      </Link>
    </Box>
  );
};

const NameCell: FC<CellProps> = props => {
  return (
    <Box key={props.row.ssn}>
      <Typography variant='body2'>{`${props.row.lastName}, ${props.row.firstName}`}</Typography>
      <Typography
        color={theme => theme.palette.grey[700]}
        data-testid='ssn-heading'
        variant='caption'>{`SSN: -${props.row.ssn.slice(-4)}`}</Typography>
    </Box>
  );
};

const MatchingValueCell: FC<CellProps> = props => (
  <Grid container gap={1} key={props.row.createdAt}>
    <Grid>
      {props.row.matchBy.includes('name') && (
        <Badge color='success' data-testid={`name-match-badge-${props.row.id}`}>
          Name
        </Badge>
      )}
    </Grid>
    <Grid>
      {props.row.matchBy.includes('ssn') && (
        <Badge color='success' data-testid={`ssn-match-badge-${props.row.id}`}>
          SSN
        </Badge>
      )}
    </Grid>
  </Grid>
);

export const EmployeeMatchesTableCell: FC<CellComponentProps> = props => {
  const [isOpen, setOpen] = useState(false);

  const CellComponent = useMemo(() => {
    switch (props.column.field) {
      case MatchedAccountFields.ID:
        return <IdCell row={props.row} />;
      case MatchedAccountFields.NAME:
        return <NameCell row={props.row} />;
      case MatchedAccountFields.MATCHING_VALUE:
        return <MatchingValueCell row={props.row} />;
      case MatchedAccountFields.ACTION:
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              data-testid={`select-employee-${props.row.id}`}
              onClick={() => setOpen(true)}>
              Select
            </Button>
          </div>
        );
    }
  }, [props.column, props.row]);

  return (
    <TableCell component='th' scope='row' sx={{ pb: '6px', pt: '6px' }}>
      {CellComponent}
      {isOpen && (
        <MergeParticipantDialog
          data={props.row}
          open={isOpen}
          setOpen={setOpen}
        />
      )}
    </TableCell>
  );
};

EmployeeMatchesTableCell.displayName = 'EmployeeMatchesTableCell';
