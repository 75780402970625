import useHasPermissions from '@/components/access-control/useHasPermissions.hook';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { ParticipantInfo } from '@/models';
import {
  FindingTextMap,
  FraudrankerResultData,
  UpdatedFraudranker
} from '@/models/FraudrankerResult.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import formatters from '@/utils/Formatters';
import { Circle } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  Unstable_Grid2 as Grid,
  IconButton,
  Link,
  List,
  ListItem,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { UseMutateFunction } from '@tanstack/react-query';

import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useToggle } from 'react-use';

type FraudNotesMutationParams = {
  calloutToParticipant?: boolean;
  fraudNotes?: string;
  latestFraudRank?: string;
};

type FraudInfoCardProps = {
  creationDate: string;
  participantInfo: ParticipantInfo;
  mutate: UseMutateFunction<any, unknown, FraudNotesMutationParams, unknown>;
  fraudrankerResult?: FraudrankerResultData;
  jiraTicket?: string;
  fraudNotes?: string;
  callOutToParticipant?: boolean;
  updatedFraudrankResult?: UpdatedFraudranker;
};

const gridTextColor = {
  color: 'black'
};

const ColoredCircle = styled('span')<{
  color: string;
}>(props => {
  return {
    backgroundColor: props.color,
    borderRadius: 100,
    height: 12,
    width: 12
  };
});

const SectionTitle = styled(Typography)({
  fontSize: 15,
  fontWeight: 500
});

const ListItemText = styled(Typography, { shouldForwardProp: () => true })({
  color: 'black',
  wordBreak: 'break-word'
});

const missingPermissionTitle = `You are not authorized to perform this action. Missing permission is ${FeatureLevelPermissions.WRITE_FRAUD}`;

const FraudInfoCard: React.FunctionComponent<FraudInfoCardProps> = (
  props: FraudInfoCardProps
) => {
  const fraudPerms = useHasPermissions({
    requires: [FeatureLevelPermissions.WRITE_FRAUD]
  });
  const { participantInfo, mutate, fraudrankerResult, jiraTicket, fraudNotes } =
    props;
  const [isFraudNotesEditing, setIsFraudNotesEditing] = useState(false);
  const [fraudNote, setFraudNote] = useState(fraudNotes);
  const [calloutToParticipant, setCalloutToParticipant] = useState(false);
  const [editFraudRank, setEditFraudRank] = useToggle(false);
  const [fraudRank, setFraudRank] = useState('');

  const findings = useMemo(
    () => [
      ...new Set(fraudrankerResult?.findings.map(i => FindingTextMap[i] || i))
    ],
    [fraudrankerResult?.findings]
  );

  const jiraTicketLink = fraudrankerResult?.jiraTicketLink ?? jiraTicket;

  const newFraudRankOptions = useMemo(() => {
    return ['green', 'yellow', 'red'].filter(
      rank => rank !== fraudrankerResult?.fraudRank.toLowerCase()
    );
  }, [fraudrankerResult?.fraudRank]);

  useEffect(() => {
    setCalloutToParticipant(props.callOutToParticipant);
  }, [props.callOutToParticipant]);

  const handleSetNewRank = (event: SelectChangeEvent) => {
    setFraudRank(event.target.value as string);
  };

  const handleSetCalloutToParticipant = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setCalloutToParticipant(event.target.checked);
    props.mutate({ calloutToParticipant: event.target.checked });
  };

  const submitFraudRank = () => {
    props.mutate({ latestFraudRank: fraudRank });
    setEditFraudRank();
  };

  return (
    <Card sx={{ marginBottom: 0 }} variant='outlined'>
      <CardHeader
        subheader={
          jiraTicketLink ? (
            <>
              Jira Ticket:&nbsp;
              <Link href={jiraTicketLink} target='_blank'>
                {jiraTicketLink.substring(jiraTicketLink.lastIndexOf('/') + 1)}
              </Link>
            </>
          ) : (
            EMPTY_FIELD_PLACEHOLDER
          )
        }
        sx={{
          borderBottom: theme => `1px solid ${theme.palette.grey[300]}`,
          height: '89px'
        }}
        title='Fraud Info'
      />
      <CardContent sx={{ paddingTop: 1 }}>
        <Stack spacing={2}>
          {isFraudNotesEditing && (
            <Stack mb={2} spacing={1}>
              <TextField
                label='Note'
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFraudNote(event.target.value);
                }}
                size='small'
                value={fraudNote}
              />
              <Stack direction='row' spacing={1}>
                <Button
                  disableElevation
                  onClick={() =>
                    mutate(
                      { fraudNotes: fraudNote },
                      {
                        onError: () => {
                          setIsFraudNotesEditing(false);
                        },
                        onSuccess: () => {
                          setIsFraudNotesEditing(false);
                        }
                      }
                    )
                  }
                  size='small'
                  variant='contained'>
                  SAVE
                </Button>
                <Button
                  onClick={() => setIsFraudNotesEditing(false)}
                  size='small'>
                  CANCEL
                </Button>
              </Stack>
            </Stack>
          )}
          {!isFraudNotesEditing && (
            <Stack alignItems='center' direction='row' spacing={2}>
              <NoteAltOutlinedIcon color='action' fontSize='small' />
              <Typography>{fraudNotes}</Typography>
              <Tooltip
                arrow
                disableHoverListener={fraudPerms.isAllowed}
                title={missingPermissionTitle}>
                <span>
                  <Button
                    disabled={!fraudPerms.isAllowed}
                    onClick={() => setIsFraudNotesEditing(true)}>
                    {fraudNotes ? 'EDIT' : 'ADD NOTE'}
                  </Button>
                </span>
              </Tooltip>
            </Stack>
          )}
          <Stack alignItems='center' direction='row' spacing={2}>
            <RecordVoiceOverIcon color='action' fontSize='small' />
            <Tooltip
              arrow
              disableHoverListener={fraudPerms.isAllowed}
              title={missingPermissionTitle}>
              <span>
                <FormControlLabel
                  control={
                    <Switch
                      checked={calloutToParticipant}
                      disabled={!fraudPerms.isAllowed}
                      name='calloutToParticipant'
                      onChange={handleSetCalloutToParticipant}
                      size='small'
                    />
                  }
                  label='Require Callout'
                />
              </span>
            </Tooltip>
          </Stack>
          <Divider />
          <Grid columnSpacing={{ xs: 2 }} container>
            <Grid xs={4}>
              <Typography variant='body2'>Score</Typography>
              <Stack direction='row'>
                <Typography
                  sx={{
                    alignItems: 'center',
                    color: theme => theme.palette.grey[600],
                    display: 'flex'
                  }}
                  variant='h6'>
                  <Circle
                    sx={{
                      color:
                        props.updatedFraudrankResult?.fraudRank ??
                        fraudrankerResult?.fraudRank.toLowerCase(),
                      mr: 1
                    }}
                  />{' '}
                  {!props.updatedFraudrankResult?.fraudRank &&
                    fraudrankerResult?.fraudScore}
                </Typography>
                <Tooltip
                  arrow
                  disableHoverListener={fraudPerms.isAllowed}
                  title={missingPermissionTitle}>
                  <span>
                    <IconButton
                      aria-label='edit rank'
                      disabled={!fraudPerms.isAllowed}
                      onClick={setEditFraudRank}>
                      <EditIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Stack>
              {props.updatedFraudrankResult && (
                <Stack>
                  <Stack alignItems='center' direction='row' spacing={1}>
                    <Typography variant='caption'>Original score: </Typography>
                    <ColoredCircle
                      color={fraudrankerResult?.fraudRank.toLowerCase()}
                    />
                    <Typography variant='caption'>
                      {fraudrankerResult?.fraudScore}
                    </Typography>
                  </Stack>
                  <Typography variant='caption'>Updated:</Typography>
                  <Typography variant='caption'>
                    {formatters.formatFromIsoDateCustom(
                      props.updatedFraudrankResult?.updatedAt,
                      'MM/DD/YYYY h:mmA'
                    )}
                  </Typography>
                </Stack>
              )}
              {editFraudRank && (
                <>
                  <Select
                    onChange={handleSetNewRank}
                    size='small'
                    value={fraudRank}>
                    {newFraudRankOptions.map(rank => (
                      <MenuItem key={rank} value={rank}>
                        {rank.charAt(0).toUpperCase() + rank.slice(1)}
                      </MenuItem>
                    ))}
                  </Select>
                  <Stack direction='row' mt={2}>
                    <Button
                      onClick={submitFraudRank}
                      size='small'
                      variant='contained'>
                      Save
                    </Button>
                    <Button onClick={setEditFraudRank} size='small'>
                      Cancel
                    </Button>
                  </Stack>
                </>
              )}
            </Grid>
            <Grid xs={1}>
              <Divider orientation='vertical' />
            </Grid>
            <Grid xs={7}>
              <Typography variant='body2'>Findings</Typography>
              <List
                data-testid='fraud-findings'
                sx={{
                  color: theme => theme.palette.grey[600],
                  listStyleType: 'disc',
                  pl: 3,
                  pt: 0
                }}>
                {findings.map(i => (
                  <ListItem key={i} sx={{ display: 'list-item', padding: 0 }}>
                    <Typography variant='body2'>{i}</Typography>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
          <Divider />
          {fraudrankerResult?.snapshotAccountOwners && (
            <>
              <SectionTitle>
                Plaid Snapshot (on{' '}
                {formatters.formatFromIsoDateCustom(
                  props.creationDate,
                  'MM/DD/YYYY HH:mm'
                )}
                )
              </SectionTitle>
              <TextStack direction='column' textLabelColumnWidth='167px'>
                <TextStackItem>
                  <TextLabel>Name</TextLabel>
                  <TextValue data-testid='fraud-snapshot-names'>
                    {fraudrankerResult?.snapshotAccountOwners?.names &&
                    fraudrankerResult?.snapshotAccountOwners?.names.length >
                      1 ? (
                      <List
                        sx={{
                          color: theme => theme.palette.grey[600],
                          listStyleType: 'disc',
                          pl: 3,
                          pt: 0
                        }}>
                        {fraudrankerResult?.snapshotAccountOwners?.names.map(
                          name => (
                            <ListItem
                              key={name}
                              sx={{ display: 'list-item', padding: 0 }}>
                              <ListItemText variant='body1'>
                                {name}
                              </ListItemText>
                            </ListItem>
                          )
                        )}
                      </List>
                    ) : (
                      fraudrankerResult?.snapshotAccountOwners?.names?.[0] ??
                      null
                    )}
                  </TextValue>
                </TextStackItem>
                <TextStackItem>
                  <TextLabel>Email</TextLabel>
                  <TextValue data-testid='fraud-snapshot-email'>
                    {fraudrankerResult?.snapshotAccountOwners?.emails &&
                    fraudrankerResult?.snapshotAccountOwners?.emails.length >
                      1 ? (
                      <List
                        sx={{
                          color: theme => theme.palette.grey[600],
                          listStyleType: 'disc',
                          pl: 3,
                          pt: 0
                        }}>
                        {fraudrankerResult?.snapshotAccountOwners?.emails.map(
                          email => (
                            <ListItem
                              key={email.data}
                              sx={{ display: 'list-item', padding: 0 }}>
                              <ListItemText variant='body1'>
                                {email.data}
                              </ListItemText>
                            </ListItem>
                          )
                        )}
                      </List>
                    ) : (
                      fraudrankerResult?.snapshotAccountOwners?.emails?.[0]
                        ?.data ?? null
                    )}
                  </TextValue>
                </TextStackItem>
                <TextStackItem>
                  <TextLabel>Address</TextLabel>
                  <TextValue data-testid='fraud-snapshot-address'>
                    {fraudrankerResult?.snapshotAccountOwners?.addresses &&
                    fraudrankerResult?.snapshotAccountOwners?.addresses.length >
                      1 ? (
                      <List
                        sx={{
                          color: theme => theme.palette.grey[600],
                          listStyleType: 'disc',
                          pl: 3,
                          pt: 0
                        }}>
                        {fraudrankerResult?.snapshotAccountOwners?.addresses.map(
                          address => (
                            <ListItem
                              key={address.street}
                              sx={{ display: 'list-item', padding: 0 }}>
                              <ListItemText variant='body1'>
                                {`${address.street} 
                              ${address.city}, ${address.region}, ${address.postalCode}`}
                              </ListItemText>
                            </ListItem>
                          )
                        )}
                      </List>
                    ) : fraudrankerResult?.snapshotAccountOwners
                        ?.addresses?.[0] ? (
                      `${fraudrankerResult?.snapshotAccountOwners?.addresses[0]?.street} 
                      ${fraudrankerResult?.snapshotAccountOwners?.addresses[0]?.city},
                      ${fraudrankerResult?.snapshotAccountOwners?.addresses[0]?.region},
                      ${fraudrankerResult?.snapshotAccountOwners?.addresses[0]?.postalCode}`
                    ) : null}
                  </TextValue>
                </TextStackItem>
              </TextStack>
            </>
          )}
          <SectionTitle>General Info</SectionTitle>
          <TextStack direction='column' textLabelColumnWidth='167px'>
            {!!fraudrankerResult?.ipAddresses && (
              <TextStackItem fieldName='fraud-ip'>
                <TextLabel>IP Address</TextLabel>
                <TextValue
                  links={[
                    {
                      label: `${fraudrankerResult?.ipAddresses}`,
                      to: `https://scamalytics.com/ip/${fraudrankerResult?.ipAddresses}`
                    }
                  ]}>
                  {' '}
                </TextValue>
              </TextStackItem>
            )}
            <TextStackItem>
              <TextLabel>Account Last Updated At</TextLabel>
              <TextValue data-testid='fraud-account-last-updated-at'>
                {participantInfo.updatedAt
                  ? formatters.formatFromIsoDateCustom(
                      participantInfo.updatedAt,
                      'MM/DD/YYYY [at] h:mm A'
                    )
                  : null}
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Bank Account Owner</TextLabel>
              <TextValue data-testid='fraud-bank-account-owner'>
                {fraudrankerResult?.plaidAccountOwners?.names &&
                fraudrankerResult?.plaidAccountOwners?.names.length > 1 ? (
                  <List
                    sx={{
                      color: theme => theme.palette.grey[600],
                      listStyleType: 'disc',
                      pl: 3,
                      pt: 0
                    }}>
                    {fraudrankerResult?.plaidAccountOwners?.names.map(name => (
                      <ListItem
                        key={name}
                        sx={{ display: 'list-item', padding: 0 }}>
                        <ListItemText variant='body1'>{name}</ListItemText>
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  fraudrankerResult?.plaidAccountOwners?.names?.[0] ?? null
                )}
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Bank Account Address</TextLabel>
              <TextValue data-testid='fraud-bank-account-address'>
                {fraudrankerResult?.plaidAccountOwners?.addresses &&
                fraudrankerResult?.plaidAccountOwners?.addresses.length > 1 ? (
                  <List
                    sx={{
                      color: theme => theme.palette.grey[600],
                      listStyleType: 'disc',
                      pl: 3,
                      pt: 0
                    }}>
                    {fraudrankerResult?.plaidAccountOwners?.addresses.map(
                      address => (
                        <ListItem
                          key={address.street}
                          sx={{ display: 'list-item', padding: 0 }}>
                          <ListItemText variant='body1'>
                            {`${address.street} 
                              ${address.city}, ${address.region}, ${address.postalCode}`}
                          </ListItemText>
                        </ListItem>
                      )
                    )}
                  </List>
                ) : fraudrankerResult?.plaidAccountOwners?.addresses?.[0] ? (
                  `${fraudrankerResult?.plaidAccountOwners?.addresses[0]?.street} 
                      ${fraudrankerResult?.plaidAccountOwners?.addresses[0]?.city},
                      ${fraudrankerResult?.plaidAccountOwners?.addresses[0]?.region},
                      ${fraudrankerResult?.plaidAccountOwners?.addresses[0]?.postalCode}`
                ) : null}
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Phone</TextLabel>
              <TextValue data-testid='fraud-phone'>
                {fraudrankerResult?.plaidAccountOwners?.phoneNumbers &&
                fraudrankerResult?.plaidAccountOwners?.phoneNumbers.length >
                  1 ? (
                  <List
                    sx={{
                      color: theme => theme.palette.grey[600],
                      listStyleType: 'disc',
                      pl: 3,
                      pt: 0
                    }}>
                    {fraudrankerResult?.plaidAccountOwners?.phoneNumbers.map(
                      phone => (
                        <ListItem
                          key={phone.data}
                          sx={{ display: 'list-item', padding: 0 }}>
                          <Typography sx={gridTextColor} variant='body1'>
                            {phone.data}
                          </Typography>
                        </ListItem>
                      )
                    )}
                  </List>
                ) : (
                  fraudrankerResult?.plaidAccountOwners?.phoneNumbers?.[0]
                    ?.data ?? null
                )}
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Email</TextLabel>
              <TextValue data-testid='fraud-email'>
                {fraudrankerResult?.plaidAccountOwners?.emails &&
                fraudrankerResult?.plaidAccountOwners?.emails.length > 1 ? (
                  <List
                    sx={{
                      color: theme => theme.palette.grey[600],
                      listStyleType: 'disc',
                      pl: 3,
                      pt: 0
                    }}>
                    {fraudrankerResult?.plaidAccountOwners?.emails.map(
                      email => (
                        <ListItem
                          key={email.data}
                          sx={{ display: 'list-item', padding: 0 }}>
                          <ListItemText variant='body1'>
                            {email.data}
                          </ListItemText>
                        </ListItem>
                      )
                    )}
                  </List>
                ) : (
                  fraudrankerResult?.plaidAccountOwners?.emails?.[0]?.data ??
                  null
                )}
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>MFA Last Disabled At</TextLabel>
              <TextValue data-testid='mfa-last-disabled-at'>
                {participantInfo.mfaLastDisabled
                  ? formatters.formatFromIsoDateCustom(
                      participantInfo.mfaLastDisabled,
                      'MM/DD/YYYY [at] h:mm A'
                    )
                  : null}
              </TextValue>
            </TextStackItem>
          </TextStack>
          <SectionTitle>Identity Match</SectionTitle>
          <TextStack direction='column' textLabelColumnWidth='167px'>
            <TextStackItem>
              <TextLabel>Legal Name Match Score</TextLabel>
              <TextValue data-testid='legal-name-score'>
                {fraudrankerResult?.plaidIdentityMatch?.legalNameMatch.score}
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Nick Name Match</TextLabel>
              <TextValue data-testid='nick-name-match'>
                {fraudrankerResult?.plaidIdentityMatch?.legalNameMatch
                  .isNicknameMatch != null ? (
                  fraudrankerResult?.plaidIdentityMatch?.legalNameMatch
                    .isNicknameMatch ? (
                    <CheckCircleIcon color='success' />
                  ) : (
                    <CancelIcon color='warning' />
                  )
                ) : null}
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>First Or Last Name Match</TextLabel>
              <TextValue data-testid='first-or-last--name-match'>
                {fraudrankerResult?.plaidIdentityMatch?.legalNameMatch
                  .isFirstNameOrLastNameMatch != null ? (
                  fraudrankerResult?.plaidIdentityMatch?.legalNameMatch
                    .isFirstNameOrLastNameMatch ? (
                    <CheckCircleIcon color='success' />
                  ) : (
                    <CancelIcon color='warning' />
                  )
                ) : null}
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Business Name Detected</TextLabel>
              <TextValue data-testid='busines-name-detected'>
                {fraudrankerResult?.plaidIdentityMatch?.legalNameMatch
                  .isBusinessNameDetected != null ? (
                  fraudrankerResult?.plaidIdentityMatch?.legalNameMatch
                    .isBusinessNameDetected ? (
                    <CheckCircleIcon color='success' />
                  ) : (
                    <CancelIcon color='warning' />
                  )
                ) : null}
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Address Match Score</TextLabel>
              <TextValue data-testid='address-score'>
                {fraudrankerResult?.plaidIdentityMatch?.addressMatch.score}
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Postal Code Match</TextLabel>
              <TextValue data-testid='postal-code-match'>
                {fraudrankerResult?.plaidIdentityMatch?.addressMatch
                  .isPostalCodeMatch != null ? (
                  fraudrankerResult?.plaidIdentityMatch?.addressMatch
                    .isPostalCodeMatch ? (
                    <CheckCircleIcon color='success' />
                  ) : (
                    <CancelIcon color='warning' />
                  )
                ) : null}
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Phone Number Match Score</TextLabel>
              <TextValue data-testid='phone-number-match-score'>
                {fraudrankerResult?.plaidIdentityMatch?.phoneNumbersMatchScore}
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Email Match Score</TextLabel>
              <TextValue data-testid='email-match-score'>
                {fraudrankerResult?.plaidIdentityMatch?.emailMatchScore}
              </TextValue>
            </TextStackItem>
          </TextStack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default FraudInfoCard;
