import AccessControl from '@/components/access-control/AccessControl.component';
import { CardPlaceholder } from '@/components/card';
import CollapsibleTable from '@/components/collapsible-table';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { ParticipantAccountsDto, PlanV2Dto } from '@/models';
import { RebalanceHistoryDto } from '@/models/RebalanceDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import ParticipantService from '@/services/Participant.service';
import { Box, Button, Paper, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useMutation, useQuery } from '@tanstack/react-query';

import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import ParticipantRebalanceHistoryCell, {
  ParticipantRebalanceCollapsible
} from './ParticipantRebalanceHistoryTableCell.component';

type ParticipantRebalanceHistoryProps = {
  participantId: number;
  accounts: ParticipantAccountsDto;
  plan: PlanV2Dto;
  isVestwellSubaccounting: boolean;
};

export const ParticipantRebalanceHistory: React.FC<
  ParticipantRebalanceHistoryProps
> = props => {
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const snackbar = useSnackbar();
  const dialog = useDialog();

  const headCells = [
    { field: 'createdAt', headerName: 'Created At' },
    { field: 'status', headerName: 'Status' },
    { field: 'updatedAt', headerName: 'Updated At' }
  ];

  const rebalanceHistoryQuery = useQuery<RebalanceHistoryDto[]>(
    ['ParticipantService.getRebalanceHistory', props.participantId],
    () => {
      return ParticipantService.getRebalanceHistory(props.participantId);
    },
    {
      enabled: Boolean(props.participantId),
      suspense: true
    }
  );

  const rebalanceParticipantMutation = useMutation(
    () => ParticipantService.rebalanceHoldings(props.participantId),
    {
      onError: () => {
        snackbar.showSnackbar({
          message: 'Failed',
          severity: 'error'
        });
      },
      onSuccess: () => {
        snackbar.showSnackbar({
          message:
            'Success! The participant rebalance request was successfully submitted.',
          severity: 'success'
        });
      }
    }
  );

  const formattedDto =
    rebalanceHistoryQuery.data?.map(dto => {
      return {
        ...dto,
        id: uuidv4()
      };
    }) || [];

  if (!rebalanceHistoryQuery.isSuccess) {
    return <p>Error</p>;
  }

  const disableRebalance =
    !props.accounts?.stats.balance.total ||
    props.plan?.data.attributes.isBlackout;

  return (
    <Paper
      data-testid='participant-rebalance-history'
      elevation={0}
      variant='outlined'>
      <Grid container p={2}>
        <Grid>
          <Typography component='div' id='rebalance-table-id' variant='h5'>
            Rebalance History
          </Typography>
        </Grid>
        <Grid style={{ flexGrow: '1' }} />
        <Grid alignItems='center' display='flex'>
          {props.isVestwellSubaccounting && (
            <AccessControl
              requires={[FeatureLevelPermissions.WRITE_REBALANCE_ACTION]}>
              <Tooltip
                title={
                  disableRebalance ? (
                    <>
                      Rebalancing is not allowed at this time for the following
                      reasons:
                      <br /> <br />
                      {!props.accounts?.stats.balance.total &&
                        ' - The participant has no balance'}
                      <br />
                      {props.plan.data.attributes.isBlackout &&
                        ' - The plan is in blackout'}
                    </>
                  ) : null
                }>
                <Box>
                  <AccessControl
                    requires={[
                      FeatureLevelPermissions.WRITE_REBALANCE_ACTION,
                      FeatureLevelPermissions.WORK_IN_PROGRESS
                    ]}>
                    <Button
                      color='primary'
                      data-testid='rebalance-button'
                      disabled={disableRebalance}
                      onClick={() => {
                        dialog.openDialog({
                          customContent: (
                            <Box>
                              <Grid container padding={3} rowGap={3}>
                                <Grid>
                                  <Typography variant='h5'>
                                    Rebalance Participant Holdings
                                  </Typography>
                                </Grid>

                                <Grid>
                                  <Typography variant='body1'>
                                    Are you sure you want to rebalance this
                                    participant&apos;s holdings?
                                  </Typography>
                                </Grid>

                                <Grid container>
                                  <Grid>
                                    <Button
                                      data-testid='confirm-rebalance-button'
                                      onClick={() => {
                                        rebalanceParticipantMutation.mutate();
                                        dialog.closeDialog();
                                      }}
                                      variant='contained'>
                                      CONFIRM
                                    </Button>
                                  </Grid>

                                  <Grid>
                                    <Button
                                      data-testid='cancel-button'
                                      onClick={() => dialog.closeDialog()}>
                                      Go Back
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Box>
                          )
                        });
                      }}
                      variant='contained'>
                      Rebalance Now
                    </Button>
                  </AccessControl>
                </Box>
              </Tooltip>
            </AccessControl>
          )}
        </Grid>
      </Grid>

      {rebalanceHistoryQuery.data?.length ? (
        <CollapsibleTable
          cellComponent={ParticipantRebalanceHistoryCell}
          collapsibleComponent={ParticipantRebalanceCollapsible}
          columns={headCells}
          data-testid='rollovers-table'
          pager={{
            metaCount: rebalanceHistoryQuery.data.length,
            onPageNumberChanged: (zeroIndexedPageNumber: number) => {
              return setPageNumber(zeroIndexedPageNumber + 1);
            },
            onRowsPerPageChanged: (newRowsPerPage: number) => {
              return setRowsPerPage(newRowsPerPage);
            },
            pageNumber: pageNumber - 1,
            rowsPerPage
          }}
          tableData={formattedDto.slice(
            rowsPerPage * pageNumber - rowsPerPage,
            rowsPerPage * pageNumber
          )}
          useDivAsBackground
        />
      ) : (
        <CardPlaceholder
          data-testid='no-data-rebalance-history-participant'
          subtitle='No data for this participant'
        />
      )}
    </Paper>
  );
};

export default ParticipantRebalanceHistory;
