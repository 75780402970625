import { PlanV2 } from '@/models';

const VestwellPlanRecordkeepers = [
  {
    id: 1,
    name: 'LT',
    nameOverride: null
  },
  {
    id: 5,
    name: 'Sub-Accounting',
    nameOverride: 'SIERRA'
  }
] as const;

const StatePlanRecordkeepers = [
  {
    id: 4,
    name: 'Surpas (State IRA)',
    nameOverride: null
  }
] as const;

const VoyaPlanRecordkeepers = [
  {
    id: 2,
    name: 'Voya',
    nameOverride: null
  }
] as const;

const ESARecordKeepers = [
  {
    id: 6,
    name: 'Vestwell ESA',
    nameOverride: null
  }
] as const;

export const AllRecordkeepers = [
  ...VestwellPlanRecordkeepers,
  ...StatePlanRecordkeepers,
  ...VoyaPlanRecordkeepers,
  ...ESARecordKeepers
] as const;

export type RecordKeeper = (typeof AllRecordkeepers)[number];

const getName = (r: RecordKeeper): string => {
  return r.nameOverride || r.name;
};

const getById = (id: number): RecordKeeper | undefined => {
  return AllRecordkeepers.find(r => r.id === id);
};

class RecordKeeperService {
  static getVestwellRKOptions(): string[] {
    // moshe: only VW Recordkeepers, NOT all
    return VestwellPlanRecordkeepers.map(r => getName(r));
  }

  static getVestwellRKIds(): number[] {
    return VestwellPlanRecordkeepers.map(r => r.id);
  }

  static getRKNameForPlan(plan?: PlanV2): string {
    const rkId = plan?.relationships.recordKeeper.data.id;

    if (!rkId) return '';

    const rk = getById(rkId);

    if (!rk) return '';

    return getName(rk);
  }

  static getRKForName(searchTerm: string): RecordKeeper | undefined {
    return AllRecordkeepers.find(candidateRecordkeeper => {
      const { nameOverride, name } = candidateRecordkeeper;

      return [nameOverride, name].some(rkNameField => {
        return (
          rkNameField &&
          searchTerm.toLowerCase().includes(rkNameField.toLowerCase())
        );
      });
    });
  }

  static formatRKName(rkName?: string): string | undefined {
    if (!rkName) return undefined;

    const rk = RecordKeeperService.getRKForName(rkName);

    if (!rk) return undefined;

    return getName(rk);
  }
}

export default RecordKeeperService;
