import { capitalize } from 'lodash';

class PlanDesignTypeTemplate {
  options = ['Starter', 'Plus or Custom', 'Unbundled'];

  output: string | undefined;

  constructor(options: Record<string, any>) {
    this.output = this.transform(options.planDesignTypeTemplate);
  }

  private transform = (value: string | undefined): string | undefined => {
    return value ? capitalize(value) : value;
  };
}

export default PlanDesignTypeTemplate;
