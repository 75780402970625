import { useSnackbar } from '@/contexts/SnackBarContext';
import { CancelSponsorExemptionPayload } from '@/models';
import SponsorService from '@/services/Sponsor.service';
import {
  useMutation,
  UseMutationResult,
  useQueryClient
} from '@tanstack/react-query';

const cancelSponsorExemption = (sponsorExemptionId: number) => {
  return SponsorService.cancelSponsorExemption(sponsorExemptionId);
};

type CancelSponsorConfig = {
  sponsorExemptionId: number;
  invalidateSponsor: boolean;
};
const useCancelSponsorExemption = (): UseMutationResult<
  CancelSponsorExemptionPayload,
  Error,
  CancelSponsorConfig,
  () => void
> => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation<
    CancelSponsorExemptionPayload,
    Error,
    CancelSponsorConfig,
    () => void
  >(config => cancelSponsorExemption(config.sponsorExemptionId), {
    onError: () => {
      showSnackbar({ message: 'Failed!', severity: 'error' });
    },
    onSuccess: (cancelSponsorExemptionPayload, config) => {
      if (config.invalidateSponsor) {
        queryClient.invalidateQueries([
          'SponsorService.getSponsorById',
          cancelSponsorExemptionPayload.data.relationships.sponsor.data.id.toString()
        ]);
        queryClient.invalidateQueries([
          'SponsorService.getSponsorExemption',
          config.sponsorExemptionId
        ]);
      }

      showSnackbar({ message: 'Success!', severity: 'success' });
    }
  });
};

export default useCancelSponsorExemption;
