export type InvestmentOptionLabel =
  | 'Fund Lineup'
  | '401(k) Manager'
  | 'Target Date Series'
  | 'Risk Series'
  | 'Managed Account'
  | 'Custom Model'
  | '401(k) Manager';

export const FUND: InvestmentOptionLabel = 'Fund Lineup';
export const MS_MANAGED: InvestmentOptionLabel = '401(k) Manager';
export const TARGET: InvestmentOptionLabel = 'Target Date Series';
export const RISK: InvestmentOptionLabel = 'Risk Series';
export const MANAGED: InvestmentOptionLabel = 'Managed Account';
export const CUSTOM: InvestmentOptionLabel = 'Custom Model';
export const MS_401K_MANAGER: InvestmentOptionLabel = '401(k) Manager';

export enum RuleType {
  default_type = 'default_type',
  available_type = 'available_type'
}

export enum RuleValue {
  riskalyze = 'riskalyze',
  target = 'target',
  risk = 'risk',
  rtq = 'rtq',
  manual = 'manual',
  model = 'model',
  template = 'template',
  dynamic_goal = 'dynamic_goal',
  morningstar_menu_type = 'morningstar_menu_type',
  standard = 'Standard',
  menu = 'menu',
  ms_managed = 'ms_ma'
}

export const getInvestmentType = (
  ruleValue: RuleValue | string
): string | undefined => {
  switch (ruleValue) {
    case RuleValue.ms_managed:
      return MS_MANAGED;
    case RuleValue.target:
      return TARGET;
    case RuleValue.menu:
      return FUND;
    case RuleValue.risk:
      return RISK;
    case RuleValue.dynamic_goal:
      return MANAGED;
    case RuleValue.model:
      return CUSTOM;
    default:
      return undefined;
  }
};

export interface ProgramRule {
  programRulesId?: number;
  ruleType: RuleType;
  ruleValue: RuleValue;
}

export interface ManagedAccount {
  minimumAge: number;
  fallbackRule: RuleValue;
}

export type Program = {
  programId: number;
  name: string;
  targetDateSeriesId?: number;
  riskSeriesId?: number;
  goalSeriesId?: number;
  fundLineupId?: number;
  defaultModelId?: number;
  has401kManager: boolean;
  ms401kManagerLineUp: number;
  programRule: ProgramRule[];
  managedAccount?: ManagedAccount;
  createdAt: string;
  planCount?: number;
};

export interface ProgramRequest {
  programId: number;
  name: string;
  targetDateSeriesId?: number;
  riskSeriesId?: number;
  goalSeriesId?: number;
  fundLineupId?: number;
  defaultModelId?: number;
  has401kManager: boolean;
  programRule: ProgramRule[];
  managedAccount?: ManagedAccount;
}

export interface ProgramModelFactsheet {
  modelId: number;
  modelName: string;
  modelType: string;
  factsheet?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface ProgramPlan {
  id: number;
  name: string;
  type: string;
  companyName: string;
  status: string;
}

export interface ProgramPlanAttributes {
  name: string;
  type: string;
  sponsorCompany: string;
  status: string;
  custodianAccountNumber?: string;
  recordkeeperId?: number;
}

export interface ProgramPlanDto {
  id: number;
  type: string;
  attributes: ProgramPlanAttributes;
}

export class ProgramConverter {
  static toProgramPlan(programPlanDto: ProgramPlanDto): ProgramPlan {
    return {
      companyName: programPlanDto.attributes.sponsorCompany,
      id: programPlanDto.id,
      name: programPlanDto.attributes.name,
      status: programPlanDto.attributes.status,
      type: programPlanDto.attributes.type
    };
  }
}

export interface ProgramFund {
  symbol: string;
  cusip: string;
  fundName?: string;
}
