import { useSnackbar } from '@/contexts/SnackBarContext';
import {
  DocumentDto,
  DocumentMetadataDto,
  DocumentMetadataDtoV2
} from '@/models';
import { ActionCenterService } from '@/services/ActionCenter.service';
import InMemoryFileDownloadService from '@/services/InMemoryFileDownloadService.service';
import { ProgramService } from '@/services/ops/investments/Program.service';
import { PlanService } from '@/services/Plan.service';
import SponsorService from '@/services/Sponsor.service';
import { FileDownloadOutlined } from '@mui/icons-material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Button, CircularProgress, Theme, Tooltip } from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation } from '@tanstack/react-query';

import { last } from 'lodash';
import React, { useCallback, useMemo } from 'react';

type DocumentDownloadButtonProps = {
  doc?: Partial<DocumentMetadataDto>;
  docV2?: Partial<DocumentMetadataDtoV2>;
  iconOnly?: boolean;
  iconWithFileName?: boolean;
  iconWithFileExtension?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  buttonIcon: {
    '& .MuiButton-startIcon': {
      margin: 0
    },
    '&:hover': {
      color: theme.palette.primary.main
    },
    color: grey[500],
    justifyContent: 'flex-start',
    minWidth: 'auto'
  },
  defaultButton: {
    textTransform: 'none'
  },
  downloadBtnIcon: {
    '&:hover': {
      color: theme.palette.primary.main
    },
    color: grey[500]
  },
  downloadIcon: {
    '&.Mui-disabled': {
      pointerEvents: 'auto' // enable tooltip for disabled download button
    },
    color: theme.palette.info.main,
    cursor: 'pointer'
  }
}));

const DocumentDownloadButton: React.FunctionComponent<
  DocumentDownloadButtonProps
> = (props: DocumentDownloadButtonProps) => {
  const { doc, docV2 } = props;
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();

  const meta = useMemo(() => {
    return {
      entity: docV2?.entity ?? doc?.entity,
      entity_id: docV2?.entityId ?? doc?.entity_id ?? '',
      id: docV2?.id ?? doc?.id ?? '',
      originalFileName: docV2?.originalFileName ?? doc?.original_file_name ?? ''
    };
  }, [doc, docV2]);

  const documentContentsQuery = useMutation<DocumentDto>(
    [`download`, meta.entity_id, meta.id],
    async () => {
      return meta.entity === 'program'
        ? ProgramService.getDocumentForProgramId(meta.entity_id, meta.id)
        : meta.entity === 'sponsor'
          ? SponsorService.getDocumentForSponsorId(meta.entity_id, meta.id)
          : meta.entity === 'internal' && meta.entity_id === 'vestwell'
            ? ActionCenterService.getDocument(meta.id)
            : PlanService.getDocumentForPlanId(meta.entity_id, meta.id);
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Error downloading document',
          severity: 'error'
        });
      },
      onSuccess: (dto: DocumentDto) => {
        InMemoryFileDownloadService.triggerFileDownload(
          dto.base64Data,
          dto.originalFileName
        );
      }
    }
  );

  const getExtension = useCallback(
    (fileName: string): string => last(fileName.split('.')) || 'UNKNOWN',
    []
  );

  const doDownload = useCallback(() => {
    documentContentsQuery.mutate();
  }, [documentContentsQuery]);

  return (
    <Tooltip title={`Click to download ${meta.originalFileName}`}>
      <Button
        className={
          props.iconOnly
            ? classes.buttonIcon
            : props.iconWithFileExtension || props.iconWithFileName
              ? ''
              : classes.defaultButton
        }
        data-testid={`document-download-button-document-id-${meta.id}`}
        disabled={documentContentsQuery.isLoading}
        endIcon={
          !props.iconOnly && documentContentsQuery.isLoading ? (
            <CircularProgress size={10} sx={{ visibility: 'block' }} />
          ) : null
        }
        key={meta.id}
        onClick={doDownload}
        size='large'
        startIcon={
          (props.iconWithFileExtension || props.iconWithFileName) && (
            <CloudDownloadIcon />
          )
        }
        variant={
          props.iconWithFileExtension || props.iconWithFileName
            ? 'text'
            : undefined
        }>
        {props.iconOnly ? (
          documentContentsQuery.isLoading ? (
            <CircularProgress size={10} sx={{ visibility: 'block' }} />
          ) : (
            <FileDownloadOutlined />
          )
        ) : props.iconWithFileExtension ? (
          getExtension(meta.originalFileName)
        ) : (
          meta.originalFileName
        )}
      </Button>
    </Tooltip>
  );
};

export default DocumentDownloadButton;
