import {
  FraudrankerOverviewResponse,
  FraudrankerReportQueryParams,
  FraudRankerReportResponse
} from '@/models/FraudrankerResult.model';
import ApiService from '@/services/Api.service';

export class FraudrankerService {
  static async getOverview(findingsPeriod: string, statsPeriod: string) {
    return ApiService.getJson<FraudrankerOverviewResponse>(
      '/fraudranker/overview',
      { findingsPeriod, statsPeriod }
    );
  }

  static async getReport(query: FraudrankerReportQueryParams) {
    return ApiService.getJson<FraudRankerReportResponse>(
      '/fraudranker/report',
      query
    );
  }
}
