import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { ParentAccountOrderDto } from '@/models/ops/parent-account-orders/ParentAccountOrderDTO.model';
import formatters from '@/utils/Formatters';
import { Divider, Stack } from '@mui/material';

import React from 'react';

export const parentAccountOrderDetailCellRendererFields: Partial<
  Record<
    keyof ParentAccountOrderDto,
    { label: string; valueFormatter: (value: any) => any }
  >
> = {
  confirmDate: {
    label: 'Confirm Date',
    valueFormatter: (value: string | null): string | null =>
      value ? formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY') : null
  },
  createdAt: {
    label: 'Created At',
    valueFormatter: (value: string | null): string | null =>
      value ? formatters.formatFromIsoDate(value) : null
  },
  custodianAccountNumber: {
    label: 'Custodian Account #',
    valueFormatter: (value: string | null): string | null => value
  },
  custodianBatchDate: {
    label: 'Custodian Batch Date',
    valueFormatter: (value: string | null): string | null =>
      value ? formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY') : null
  },
  custodianBatchId: {
    label: 'Custodian Batch ID',
    valueFormatter: (value: string | null): string | null => value
  },
  custodyConfirmId: {
    label: 'Custodian Confirm ID',
    valueFormatter: (value: string | null): string | null => value
  },
  omsBatchId: {
    label: 'OMS Batch ID',
    valueFormatter: (value: string | null): string | null => value
  },
  ourParentOrderId: {
    label: 'Order ID', // product requested we show parent order id as order id instead of the order's actual id field
    valueFormatter: (value: string | null): string | null => value
  },
  settlementDate: {
    label: 'Settlement Date',
    valueFormatter: (value: string | null): string | null =>
      value ? formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY') : null
  },
  sourceTransactionId: {
    label: 'Source Transaction ID',
    valueFormatter: (value: string | null): string | null => value
  },
  tradeAmount: {
    label: 'Executed Amount',
    valueFormatter: (value: string | null): string | null =>
      value ? formatters.formatDollars(value) : null
  },
  tradeDate: {
    label: 'Trade Date',
    valueFormatter: (value: string | null): string | null =>
      value ? formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY') : null
  },
  tradeFee: {
    label: 'Trade Fee',
    valueFormatter: (value: string | null): string | null =>
      value ? formatters.formatDollars(value) : null
  },
  tradeUnitPrice: {
    label: 'Executed Price',
    valueFormatter: (value: string | null): string | null =>
      value ? formatters.formatDollars(value) : null
  },
  tradeUnits: {
    label: 'Executed Units',
    valueFormatter: (value: string | null): string | null =>
      value ? formatters.formatDecimal(value, 3) : null
  },
  updatedAt: {
    label: 'Updated At',
    valueFormatter: (value: string | null): string | null =>
      value ? formatters.formatFromIsoDate(value) : null
  }
};

const sections: Array<Array<keyof ParentAccountOrderDto>> = [
  [
    'tradeDate',
    'settlementDate',
    'confirmDate',
    'tradeAmount',
    'tradeUnits',
    'tradeUnitPrice',
    'tradeFee'
  ],
  [
    'custodianAccountNumber',
    'custodyConfirmId',
    'custodianBatchDate',
    'custodianBatchId',
    'omsBatchId'
  ],
  ['ourParentOrderId', 'sourceTransactionId', 'createdAt', 'updatedAt']
];

const ParentAccountOrderDetailCellRenderer = ({
  data: rowData,
  disablePadding = false
}: {
  data: ParentAccountOrderDto;
  disablePadding?: boolean;
}): JSX.Element => (
  <Stack
    direction='row'
    divider={<Divider flexItem orientation='vertical' />}
    spacing={2}
    sx={{ p: disablePadding ? 0 : 2 }}>
    {sections.map(section => (
      <TextStack direction='column' key={`section_${section[0]}`}>
        <>
          {section.map(fieldId => (
            <TextStackItem key={fieldId}>
              <TextLabel>
                {parentAccountOrderDetailCellRendererFields[fieldId]?.label}
              </TextLabel>
              <TextValue>
                {parentAccountOrderDetailCellRendererFields[
                  fieldId
                ]?.valueFormatter(rowData[fieldId])}
              </TextValue>
            </TextStackItem>
          ))}
        </>
      </TextStack>
    ))}
  </Stack>
);
export default ParentAccountOrderDetailCellRenderer;
