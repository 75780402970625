import AccessControl from '@/components/access-control/AccessControl.component';
import LinearLoading from '@/components/linear-loading';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { SecurityDto } from '@/models/SecurityMaster.model';
import {
  AddRemoveAction,
  SubaPlanFundStatusAttribute
} from '@/models/SubaccountingPlanFund.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { SecurityMasterService } from '@/services/SecurityMaster.service';
import SubAccountingService from '@/services/SubAccounting.service';
import {
  Alert,
  Button,
  Card,
  CardContent,
  Theme,
  Typography
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';

import React, { useState } from 'react';
import { useToggle } from 'react-use';

import CustodianFundsTable from './CustodianFundsTabTable.component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alertParagraph: {
      margin: 0
    },
    programHeader: {
      fontSize: theme.spacing(3),
      marginBottom: theme.spacing(3)
    },
    submitAllButton: {
      float: 'right'
    },
    submitAllCard: {
      borderLeft: 0,
      borderRight: 0,
      borderTop: 0,
      padding: theme.spacing(2)
    },
    submitAllCardContent: {
      padding: 0
    }
  })
);

export type CustodianFund = {
  symbol: string;
  cusip: string;
  fundName: string;
  secId: string;
  status: string;
  isProgram: boolean;
  custodianAccountNumber?: string;
  requestNote?: string;
  responseNote?: string;
};

export interface CustodianFundsTabContentProps {
  planId: number;
  cusips: string[];
  programCusips: string[];
  plunFundStatusByCusip: Map<string, SubaPlanFundStatusAttribute>;
}

export const NEW_SATAUS = 'New';
export const PENDING_ACTIVATION_SATAUS = 'Pending Activation';
export const ACTIVE_SATAUS = 'Active';
export const PENDING_DEACTIVATION_SATAUS = 'Pending Deactivation';
export const INACTIVE_SATAUS = 'Inactive';

const CustodianFundsTabContent = (
  props: CustodianFundsTabContentProps
): JSX.Element => {
  const { planId, cusips, programCusips, plunFundStatusByCusip } = props;
  const classes = useStyles();
  const snackbar = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [failedFunds, setFailedFunds] = useState<string[]>([]);
  const [isAlertDisplayed, toggleAlertDisplay] = useToggle(false);

  const securitiesQuery = useQuery<SecurityDto[]>(
    ['SecurityMasterService.getSecuritiesByCusips', cusips],
    () => SecurityMasterService.getSecuritiesByCusips(cusips),
    { enabled: Boolean(cusips), staleTime: Infinity }
  );

  if (securitiesQuery.isFetching) {
    return <LinearLoading />;
  }

  if (securitiesQuery.isError) {
    return <Typography>Error retrieving securities</Typography>;
  }
  if (securitiesQuery.data === undefined) {
    return <Typography>No securities data for this plan</Typography>;
  }

  const getStatus = (sataus: string | undefined) => {
    switch (sataus) {
      case 'inactive':
        return INACTIVE_SATAUS;
      case 'pending_deactivation':
        return PENDING_DEACTIVATION_SATAUS;
      case 'active':
        return ACTIVE_SATAUS;
      case 'pending_add':
        return PENDING_ACTIVATION_SATAUS;
      case 'not_sent':
        return 'Not Sent';
      default:
        return NEW_SATAUS;
    }
  };

  const custodianFunds: CustodianFund[] = (securitiesQuery.data || [])
    .sort((a, b) => {
      return a.symbol.toLowerCase().localeCompare(b.symbol.toLowerCase());
    })
    .map(item => {
      return {
        cusip: item.cusip,
        custodianAccountNumber: plunFundStatusByCusip.get(item.cusip)
          ?.custodianAccountNumber,
        fundName: item.fundName,
        isProgram: programCusips.includes(item.cusip),
        requestNote: plunFundStatusByCusip.get(item.cusip)?.planFund?.notes,
        secId: item.secId,
        status: getStatus(plunFundStatusByCusip.get(item.cusip)?.status),
        symbol: item.symbol
      };
    });

  const newFunds = custodianFunds.filter(item => item.status === NEW_SATAUS);

  const submitPlanFund = async (
    cusip: string,
    addRemove: AddRemoveAction,
    notes?: string
  ) => {
    try {
      setIsLoading(true);
      switch (addRemove) {
        case AddRemoveAction.add:
          await SubAccountingService.addPlanFund(planId, cusip, notes);
          break;
        case AddRemoveAction.remove:
          await SubAccountingService.removePlanFund(planId, cusip);
          break;
      }
      const planFundsAndStatuses =
        await SubAccountingService.getPlanFundsAndStatuses(planId);
      planFundsAndStatuses.data.forEach(item =>
        plunFundStatusByCusip.set(
          item.attributes.planFund.cusip,
          item.attributes
        )
      );
      setIsLoading(false);
      snackbar.showSnackbar({
        message: 'Fund have been submitted',
        severity: 'success'
      });
    } catch (error) {
      setIsLoading(false);
      snackbar.showSnackbar({
        message: `Failed to submit fund: ${error}`,
        severity: 'error'
      });
    }
  };

  const submitAllNewFunds = async () => {
    try {
      setIsLoading(true);
      const failedRequests: string[] = [];
      Promise.allSettled(
        newFunds.map(item => {
          return SubAccountingService.addPlanFund(
            planId,
            item.cusip,
            item.requestNote
          );
        })
      )
        .then(results => {
          results.forEach((result, num) => {
            if (result.status == 'rejected') {
              failedRequests.push(newFunds[num].cusip);
            }
          });
          return SubAccountingService.getPlanFundsAndStatuses(planId);
        })
        .then(planFundsAndStatuses => {
          planFundsAndStatuses.data.forEach(item =>
            plunFundStatusByCusip.set(
              item.attributes.planFund.cusip,
              item.attributes
            )
          );
          setIsLoading(false);
          if (failedRequests.length === 0) {
            snackbar.showSnackbar({
              message: `${newFunds.length} funds have been submitted`,
              severity: 'success'
            });
          } else {
            setFailedFunds(failedRequests);
            toggleAlertDisplay(true);
            snackbar.showSnackbar({
              message: `${
                newFunds.length - failedRequests.length
              } funds have been submitted. ${
                failedRequests.length
              } funds failed.`,
              severity: 'warning'
            });
          }
        });
    } catch (error) {
      setIsLoading(false);
      snackbar.showSnackbar({
        message: `Failed to submit funds: ${error}`,
        severity: 'error'
      });
    }
  };

  return (
    <>
      {isLoading && <LinearLoading />}
      {isAlertDisplayed && failedFunds.length !== 0 && (
        <Alert onClose={toggleAlertDisplay} severity='warning' variant='filled'>
          <p className={classes.alertParagraph}>
            <strong>{custodianFunds.length - failedFunds.length}</strong> funds
            have been submitted.
          </p>
          <p className={classes.alertParagraph}>
            <strong>{failedFunds.length}</strong> funds failed:
            {` ${failedFunds.join(', ')}`}
          </p>
        </Alert>
      )}
      {Boolean(newFunds.length) && (
        <AccessControl
          requires={[FeatureLevelPermissions.WRITE_INVESTMENTS_MODIFY]}>
          <Card
            className={classes.submitAllCard}
            elevation={0}
            variant='outlined'>
            <CardContent className={classes.submitAllCardContent}>
              <Button
                className={classes.submitAllButton}
                onClick={() => submitAllNewFunds()}
                variant='text'>
                {`Submit all new funds (${newFunds.length})`}
              </Button>
            </CardContent>
          </Card>
        </AccessControl>
      )}
      <CustodianFundsTable
        custodianFunds={custodianFunds}
        submitPlanFundCallback={submitPlanFund}
      />
    </>
  );
};

export default CustodianFundsTabContent;
