import { FormControlLabel, Radio } from '@mui/material';

type RadioLabelProps = {
  value: any;
  label: string;
};
export const RadioLabel: React.FC<RadioLabelProps> = props => (
  <FormControlLabel
    control={<Radio />}
    label={props.label}
    value={props.value}
  />
);
