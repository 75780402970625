import LinearLoading from '@/components/linear-loading';
import { Box, Button, List, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  AssociateSearchItemsHeader: {
    fontSize: theme.spacing(1.5),
    letterSpacing: '0.4px',
    marginLeft: theme.spacing(1.5),
    marginTop: theme.spacing(2)
  },

  AssociateSearchShowMoreBtn: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5)
  }
}));

interface AssociateSearchListProps {
  children?: React.ReactNode;
  isLoading: boolean;
  isNoResults: boolean;
  listTitle: string;
  isPaginated?: boolean;
  isPaginationLoading?: boolean;
  isError: boolean;
  onShowMore?: () => void;
}
const AssociateSearchList: React.FunctionComponent<
  AssociateSearchListProps
> = ({
  isLoading,
  isNoResults,
  children,
  listTitle,
  isPaginated,
  isPaginationLoading,
  onShowMore,
  isError
}): JSX.Element => {
  const formatListTitle = listTitle.toLowerCase().replaceAll(' ', '-');
  const classes = useStyles();

  return (
    <div
      data-testid={`user-management-associate-search-${formatListTitle}-list`}>
      <Typography
        className={classes.AssociateSearchItemsHeader}
        data-testid={`user-management-associate-search-${formatListTitle}-list-title`}>
        {listTitle}
      </Typography>
      {isLoading && <LinearLoading />}

      {(isNoResults || isError) && (
        <Box
          alignItems='center'
          display='flex'
          height='50px'
          justifyContent='center'>
          <Typography
            data-testid={`user-management-associate-search-${formatListTitle}-list-no-results-or-error`}>
            {isNoResults ? 'No results' : 'Error fetching the data'}
          </Typography>
        </Box>
      )}

      {!isLoading && !isNoResults && !isError && (
        <List
          data-testid={`user-management-associate-search-${formatListTitle}-list-items`}>
          {children}
        </List>
      )}

      {isPaginated && !isError && (
        <Button
          className={classes.AssociateSearchShowMoreBtn}
          data-testid={`user-management-associate-search-${formatListTitle}-list-show-more-btn`}
          disabled={isPaginationLoading}
          onClick={() => {
            onShowMore();
          }}>
          SHOW MORE
        </Button>
      )}
    </div>
  );
};

export default AssociateSearchList;
