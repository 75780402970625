import Constants from '@/consts/planDesign.constants';
import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class PlanStatus {
  convertToApiState = Helper.convertValueToApiState;

  options = Constants.planStatuses;

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.output = props.adminStatus;

    if (props.isEsa) {
      this.options = Constants.esaPlanStatuses;
    }
  }
}

export default PlanStatus;
