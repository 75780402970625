import * as yup from 'yup';

const validateName = yup
  .string()
  .matches(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*\.?$/, {
    message: 'Invalid Name'
  });

const validatePhoneNumber = yup.string().matches(/^(?:\d{3})-\d{3}-\d{4}$/, {
  message: 'Invalid format, please use: XXX-XXX-XXXX'
});

export const companyPayrollContactsSchema = yup.object({
  email: yup.string().email('Invalid Email').required('Required'),
  firstName: validateName.required('Required'),
  lastName: validateName.required('Required'),
  phoneNumber: validatePhoneNumber.required('Required'),
  role: yup.string()
});
