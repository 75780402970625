import DatePicker from '@/components/date-picker';
import SimpleDropdown from '@/components/simple-dropdown';
import SimpleRadio from '@/components/simple-radio';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { ParticipantInfo } from '@/models';
import { RmdAmountPayload, RmdCalculatedAmount } from '@/models/RmdDTO.model';
import ParticipantService from '@/services/Participant.service';
import formatters from '@/utils/Formatters';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';

import { Form, Formik } from 'formik';
import { useRef, useState } from 'react';
import * as yup from 'yup';

type RMDCalculatorDialogProps = {
  open: boolean;
  onClose: () => void;
  participant?: ParticipantInfo;
  participantId: number;
};

const RMDCalculatorDialog: React.FunctionComponent<RMDCalculatorDialogProps> = (
  props: RMDCalculatorDialogProps
) => {
  const { open, onClose, participant, participantId } = props;
  const formRef = useRef<any>();
  const { showSnackbar } = useSnackbar();
  const initialVals = {
    birthDate: '',
    maritalStatus: '',
    recipient: '',
    soleBeneficiary: false,
    spouseAge: ''
  };
  const [estimatedRMD, setEstimatedRMD] = useState<null | number>(null);

  const validationSchema = yup.object({
    birthDate: yup.date().when('recipient', {
      is: (value: string) => value === 'Beneficiary',
      then: schema => schema.required('')
    }),
    maritalStatus: yup.string().when('recipient', {
      is: (value: string) => value === 'Account Owner',
      then: schema => schema.required('')
    }),
    recipient: yup.string().required(''),
    soleBeneficiary: yup.boolean().when('maritalStatus', {
      is: (value: string) => value === 'Married',
      then: schema => schema.required('')
    }),
    spouseAge: yup.number().when('soleBeneficiary', {
      is: (value: boolean) => value,
      then: schema => schema.positive().required('')
    })
  });

  const currentYear = new Date().getFullYear();
  const rmdBalanceQuery = useQuery(
    ['ParticipantService.getRMDBalance', participantId, currentYear],
    () => ParticipantService.getRMDBalance(participantId, currentYear),
    {
      staleTime: Infinity
    }
  );

  const rmdAmountMutation = useMutation(
    (data: RmdAmountPayload) =>
      ParticipantService.getRMDCalculatedAmount(
        participantId,
        data.year,
        data.birthDate,
        data.accountOwner,
        data.married,
        data.spouseSoleBeneficiary,
        data.spouseAge
      ),
    {
      onError: () => {
        showSnackbar({
          message: 'Failed',
          severity: 'error'
        });
      },
      onSuccess: (data: RmdCalculatedAmount) => {
        setEstimatedRMD(data.requiredAmount);
      }
    }
  );

  return (
    <Dialog onClose={() => onClose()} open={open}>
      <DialogTitle>
        <Stack direction='row' justifyContent='space-between'>
          RMD Calculator
          <IconButton aria-label='close' onClick={() => onClose()}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialVals}
          innerRef={formRef}
          onSubmit={() => {}}
          validateOnChange
          validateOnMount
          validationSchema={validationSchema}>
          {formik => (
            <Form>
              <Grid columnSpacing={6} container rowSpacing={2}>
                <Grid item xs={6}>
                  <Typography variant='subtitle1'>Recipient</Typography>
                </Grid>
                <Grid item xs={6}>
                  <SimpleDropdown
                    fieldId='recipient'
                    fieldName='Recipient'
                    fieldValues={['Account Owner', 'Beneficiary']}
                    onChange={event => {
                      formik.setFieldValue('recipient', event.target.value);

                      if (event.target.value === 'Account Owner') {
                        formik.setFieldValue(
                          'birthDate',
                          formatters.formatFromIsoDateCustom(
                            participant?.birthDate ?? '',
                            'YYYY-MM-DD'
                          )
                        );
                      } else {
                        formik.setFieldValue('birthDate', null);
                      }
                    }}
                    size='small'
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='subtitle1'>
                    {`${rmdBalanceQuery.data?.year} vested balance`}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='subtitle1'>
                    {formatters.formatDollars(rmdBalanceQuery.data?.balance)}
                  </Typography>
                </Grid>
                {formik.values.recipient === 'Account Owner' && (
                  <>
                    <Grid item xs={6}>
                      <Typography variant='subtitle1'>Date of birth</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='subtitle1'>
                        {participant?.birthDate}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='subtitle1'>
                        Marital Status *
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <SimpleRadio
                        fieldId='maritalStatus'
                        fieldName=''
                        fieldValues={['Married', 'Unmarried']}
                        row
                      />
                    </Grid>
                    {formik.values.maritalStatus === 'Married' && (
                      <>
                        <Grid item xs={6}>
                          <Typography variant='subtitle1'>
                            Spouse beneficiary status
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formik.values.soleBeneficiary}
                                name='soleBeneficiary'
                                onChange={formik.handleChange}
                                value={formik.values.soleBeneficiary}
                              />
                            }
                            label='Spouse is the sole beneficiary'
                            sx={{ width: 400 }}
                          />
                        </Grid>
                        {formik.values.soleBeneficiary && (
                          <>
                            <Grid item xs={6}>
                              <Typography variant='subtitle1'>
                                Spouse's age
                              </Typography>
                              <Typography
                                sx={{
                                  color: theme => theme.palette.grey[700]
                                }}
                                variant='caption'>
                                The age of the spouse at the end of this year
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                error={Boolean(formik.errors.spouseAge)}
                                helperText={formik.errors.spouseAge ?? ''}
                                id='spouseAge'
                                name='spouseAge'
                                onChange={formik.handleChange}
                                size='small'
                                sx={{ mt: 2 }}
                                value={formik.values.spouseAge}
                                variant='outlined'
                              />
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
                {formik.values.recipient === 'Beneficiary' && (
                  <>
                    <Grid item xs={6}>
                      <Typography variant='subtitle1'>
                        Date of birth *
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <DatePicker
                        componentsProps={{}}
                        disableFuture
                        error={Boolean(formik.errors.birthDate)}
                        errorMessage={formik.errors.birthDate ?? ''}
                        format='MM/DD/YYYY'
                        label='Birth Date'
                        name='birthDate'
                        onChange={(event: {
                          target: { name: string; value: string };
                        }) => {
                          formik.setFieldValue('birthDate', event.target.value);
                        }}
                        size='small'
                        variant='outlined'
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <Divider sx={{ my: 3 }} />
              <Box>
                <Typography mb={1}>Estimate RMD</Typography>
                <Stack direction='row' spacing={2}>
                  {estimatedRMD !== null && (
                    <Typography variant='h6'>
                      {formatters.formatDollars(estimatedRMD)}
                    </Typography>
                  )}
                  <LoadingButton
                    disabled={!formik.isValid}
                    loading={rmdAmountMutation.isLoading}
                    onClick={() =>
                      rmdAmountMutation.mutate({
                        accountOwner:
                          formik.values.recipient === 'Account Owner',
                        birthDate: formik.values.birthDate,
                        married: formik.values.maritalStatus === 'Married',
                        spouseAge: formik.values.spouseAge
                          ? +formik.values.spouseAge
                          : 0,
                        spouseSoleBeneficiary:
                          formik.values.soleBeneficiary ?? false,
                        year: currentYear
                      } as RmdAmountPayload)
                    }
                    variant='contained'>
                    CALCULATE
                  </LoadingButton>
                </Stack>
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default RMDCalculatorDialog;
