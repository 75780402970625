import formatters from '@/utils/Formatters';
import { Link, TableCell, Theme, Typography } from '@mui/material';
import { green, red } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import { GridColDef } from '@mui/x-data-grid-pro';

import clsx from 'clsx';
import React, { FC, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

type PlanContributionReversalsTableCellProps = {
  row: any;
  column: GridColDef;
};

const useStyles = makeStyles((theme: Theme) => ({
  border: {
    borderLeft: `1px solid ${theme.palette.grey[300]}`
  },
  negativeAmount: {
    color: red[700]
  },
  negativeSubtotal: {
    color: red[300]
  },
  negativeTotal: {
    color: red[800]
  },
  positiveAmount: {
    color: green[700]
  },
  positiveSubtotal: {
    color: green[300]
  },
  positiveTotal: {
    color: green[800]
  },
  showTransactionsButton: {
    marginLeft: theme.spacing(1)
  },
  size: {
    fontSize: theme.spacing(2)
  },
  textGray: {
    color: theme.palette.grey[500]
  },
  textRight: {
    textAlign: 'right'
  },
  transactionsDrawer: theme.mixins.toolbar
}));

const PlanContributionReversalsTableCell: FC<
  PlanContributionReversalsTableCellProps
> = props => {
  const { row, column } = props;

  const classes = useStyles();

  const value = row?.[column.field];

  const formattedValue = useMemo(() => {
    if (!value) return '--';

    return typeof value === 'number' ? formatters.formatDollars(value) : value;
  }, [value]);

  return (
    <TableCell
      className={clsx(
        classes.size,
        ['sd', 'sh', 'ln'].includes(column.field) ? classes.border : ''
      )}
      component='th'
      scope='row'>
      {column.field === 'name' ? (
        <Link
          component={RouterLink}
          target='_blank'
          to={`/participants/${row.id}`}
          underline='hover'>
          {value}
        </Link>
      ) : (
        <Typography
          className={clsx(
            classes.textRight,
            ['employeeContributionTotal', 'employerContributionTotal'].includes(
              column.field
            ) &&
              (value > 0 ? classes.positiveSubtotal : classes.negativeSubtotal),
            ['sd', 'rc', 'at', 'sh', 'em', 'ps', 'ln'].includes(column.field) &&
              (value > 0 ? classes.positiveAmount : classes.negativeAmount),
            column.field === 'contributionTotal' &&
              (value > 0 ? classes.positiveTotal : classes.negativeTotal),
            !row[column.field] && classes.textGray
          )}>
          {formattedValue}
        </Typography>
      )}
    </TableCell>
  );
};

export default PlanContributionReversalsTableCell;
