import EARLIEST_POSSIBLE_DATE from '@/consts/dates.constants';

import dayjs from 'dayjs';
import * as yup from 'yup';

export const HOURS_ERROR_MSGS = {
  DATE_FORMAT: 'Please enter a valid date as mm/dd/yyyy',
  EARLY_START_DATE: 'Start date cannot be before 01/01/1901',
  END_DATE_BEFORE: 'End date cannot be before start date',
  NEGATIVE_HOURS: 'Hours must be 0 or greater',
  REQUIRED_END_DATE: 'End date is required',
  REQUIRED_HOURS: 'Hours is required',
  REQUIRED_START_DATE: 'Start date is required',
  START_DATE_AFTER: 'Start date cannot be after end date',
  TOO_BIG_HOURS: 'Hours entered is not feasible for the time range'
};

const getMaxHours = (startDate: string, endDate: string) =>
  dayjs(endDate).diff(dayjs(startDate), 'hour', true) + 24;

export const getUpdateHoursValidationSchema = (
  startDate: string,
  endDate: string
): yup.AnyObjectSchema => {
  return yup.object({
    hours: yup
      .number()
      .required(HOURS_ERROR_MSGS.REQUIRED_HOURS)
      .moreThan(-0.0001, HOURS_ERROR_MSGS.NEGATIVE_HOURS)
      .test('are hours feasible', HOURS_ERROR_MSGS.TOO_BIG_HOURS, hours => {
        if (!hours || isNaN(hours)) return true;
        return hours <= getMaxHours(startDate, endDate);
      })
  });
};

export const getCreateHoursValidationSchema = (): yup.AnyObjectSchema =>
  yup.object().shape({
    endDate: yup
      .string()
      .required(HOURS_ERROR_MSGS.REQUIRED_END_DATE)
      .test(
        'Check is end date is after start date',
        HOURS_ERROR_MSGS.END_DATE_BEFORE,
        (endDate, testContext) =>
          !testContext.parent.startDate
            ? true
            : !dayjs(endDate).isBefore(dayjs(testContext.parent.startDate))
      ),
    hours: yup
      .number()
      .required(HOURS_ERROR_MSGS.REQUIRED_HOURS)
      .moreThan(-0.0001, HOURS_ERROR_MSGS.NEGATIVE_HOURS)
      .test(
        'are hours feasible',
        HOURS_ERROR_MSGS.TOO_BIG_HOURS,
        (hours, testContext) => {
          if (!hours || isNaN(hours)) return true;
          return (
            hours <=
            getMaxHours(
              testContext.parent.startDate,
              testContext.parent.endDate
            )
          );
        }
      ),
    startDate: yup
      .string()
      .required(HOURS_ERROR_MSGS.REQUIRED_START_DATE)
      .test(
        'Check if start date is in available range',
        HOURS_ERROR_MSGS.EARLY_START_DATE,
        value => !dayjs(value).isBefore(dayjs(EARLIEST_POSSIBLE_DATE))
      )
      .test(
        'Check is start date is before end date',
        HOURS_ERROR_MSGS.START_DATE_AFTER,
        (startDate, testContext) =>
          !testContext.parent.endDate
            ? true
            : !dayjs(startDate).isAfter(dayjs(testContext.parent.endDate))
      )
  });
