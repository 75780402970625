import { SafeHarborType } from '@/models';

class SafeHarborMatchType {
  convertToApiState = (value: string | undefined): string | undefined => {
    return this.safeHarborTypes.find(sht => sht.name === value)?.code;
  };

  options: string[];

  output: string | undefined;

  safeHarborTypes: SafeHarborType[] = [];

  setSafeHarborTypes = (types: SafeHarborType[]): void => {
    this.safeHarborTypes = types;
  };

  setOptions = (): void => {
    this.options = this.safeHarborTypes?.map(type => type.name);
  };

  setOutput = (value: string): void => {
    this.output = this.safeHarborTypes.find(type => type.code === value)?.name;
  };

  set = (
    safeHarborMatchType: string,
    safeHarborTypes: SafeHarborType[]
  ): void => {
    this.setSafeHarborTypes(safeHarborTypes);
    this.setOptions();
    this.setOutput(safeHarborMatchType);
  };

  constructor() {}
}

export default SafeHarborMatchType;
