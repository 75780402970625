import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon
} from '@mui/icons-material';
import {
  Box,
  Fab,
  Drawer as MuiDrawer,
  DrawerProps as MuiDrawerProps,
  Theme,
  useTheme
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    position: 'fixed',
    top: '24px',
    zIndex: theme.zIndex.vestwellAppBar + 2
  },
  drawerPaper: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '100%',
    minHeight: '100vh'
  },
  fabIcon: {
    background: '#fff',
    fontSize: 20,
    height: 28,
    lineHeight: 1,
    minHeight: 28,
    p: 0.5,
    verticalAlign: 'middle',
    width: 28
  }
}));

interface DrawerProps extends MuiDrawerProps {
  width?: number | string;
  onClose?: () => void;
  anchor?: 'left' | 'right';
  hideFabIcon?: boolean;
}

const DRAWER_WIDTH = 480;

const Drawer: React.FunctionComponent<DrawerProps> = (props: DrawerProps) => {
  const {
    children,
    open,
    onClose = () => {},
    width,
    hideFabIcon,
    ...restProps
  } = props;

  const muiTheme = useTheme();

  const classes = useStyles();

  const expandedDrawerTransition = muiTheme.transitions.create('width');

  return (
    <MuiDrawer
      PaperProps={{
        className: classes.drawerPaper,
        sx: {
          width: width || DRAWER_WIDTH
        }
      }}
      SlideProps={{
        onEntered: node =>
          (node.style.transition = node.style.transition
            ? `${node.style.transition},${expandedDrawerTransition}`
            : expandedDrawerTransition)
      }}
      anchor='right'
      onClose={onClose}
      open={open}
      sx={{
        zIndex: theme => theme.zIndex.vestwellAppBar + 1
      }}
      {...restProps}>
      <Box sx={{ width: '100%' }}>
        <Box
          className={classes.container}
          sx={{
            justifyContent: restProps.anchor == 'left' ? 'right' : 'left',
            ml: `${restProps.anchor == 'left' ? '' : '-'}14px`,
            width: width || DRAWER_WIDTH
          }}>
          {!hideFabIcon && (
            <Fab
              aria-label='close'
              className={classes.fabIcon}
              color='inherit'
              onClick={onClose}
              size='small'>
              {restProps.anchor == 'left' ? (
                <ChevronLeftIcon fontSize='inherit' />
              ) : (
                <ChevronRightIcon fontSize='inherit' />
              )}
            </Fab>
          )}
        </Box>
        {children}
      </Box>
    </MuiDrawer>
  );
};

export default Drawer;
export type { DrawerProps };
