import { GetLogsResponseDto } from '@/models/LogDTO.model';
import ApiService from '@/services/Api.service';

export class LogService {
  static async getLogs(query: {
    action?: string;
    entityId?: string;
    entityType?: string;
  }): Promise<GetLogsResponseDto[]> {
    return ApiService.getJson<GetLogsResponseDto[]>(`/logs`, query);
  }
}
