import { FileUploadTableEditorContext } from '@/components/file-upload-table/FileUploadTableEditorContext';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Button } from '@mui/material';

import { ICellRendererParams } from 'ag-grid-community';
import { useContext } from 'react';

export default (props: ICellRendererParams): JSX.Element => {
  const ctx = useContext(FileUploadTableEditorContext);

  return (
    <span>
      <Button
        aria-label='add rows'
        onClick={() => {
          if (ctx?.addRow) {
            ctx.addRow({
              ...props,
              colDef: props.colDef ?? {},
              data: props.data
            });
          }
        }}
        startIcon={
          <AddCircleOutlineOutlinedIcon
            sx={{
              fontSize: theme => theme.spacing(3) + '!important'
            }}
          />
        }
        sx={{
          '& .MuiButton-startIcon': {
            marginLeft: 0
          }
        }}>
        {ctx?.newRowCount ? `Add ${ctx?.newRowCount} more rows` : 'Add new row'}
      </Button>
    </span>
  );
};
