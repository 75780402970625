import AccessControl from '@/components/access-control/AccessControl.component';
import CardIconInfoField from '@/components/card-icon-info-field/CardIconInfoField.component';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import useSponsor from '@/hooks/useSponsor';
import useUpdateSponsor from '@/hooks/useUpdateSponsor';
import { PlanV2Dto, UpdateSponsorDto } from '@/models';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { PlanService } from '@/services/Plan.service';
import SponsorService from '@/services/Sponsor.service';
import formatters from '@/utils/Formatters';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import React from 'react';
import * as yup from 'yup';

interface MainContactTabProps {
  sponsorId: string | number;
  sponsorPlanId: string | number;
  isVWPlan: boolean;
}

const useStyles = makeStyles(() => ({
  editButton: {
    marginLeft: 'auto',
    padding: '0px'
  },
  resendWelcomeEmailButton: {
    justifyContent: 'left',
    padding: '0px 12px',
    width: 'fit-content'
  }
}));

const MainContactTab: React.FunctionComponent<MainContactTabProps> = (
  props: MainContactTabProps
) => {
  const classes = useStyles();
  const { openDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const sponsorMutation = useUpdateSponsor();
  const { sponsorId, sponsorPlanId, isVWPlan } = props;
  const queryClient = useQueryClient();

  const sponsorQuery = useSponsor(sponsorId.toString(), sponsorDto => ({
    activationDate: sponsorDto.data.attributes.activationDate,
    primaryContact: sponsorDto.data.attributes.primaryContact
  }));

  const sponsorPlanQuery = useQuery<PlanV2Dto>(
    ['PlanService.getPlanById', sponsorPlanId?.toString()],
    () => {
      return PlanService.getPlanById(sponsorPlanId);
    },
    {
      enabled: Boolean(sponsorPlanId),
      staleTime: Infinity
    }
  );

  const sponsorUserInvitesQuery = useQuery(
    ['SponsorService.getSponsorUserInvites', String(sponsorId)],
    () => SponsorService.getSponsorUserInvites(sponsorId),
    {
      staleTime: Infinity
    }
  );

  const userInvite = sponsorUserInvitesQuery.data?.sponsorUserInvites.filter(
    invite =>
      invite.attributes.email === sponsorQuery.data?.primaryContact.email
  )[0];
  const sponsorUserInviteCode = userInvite?.attributes.inviteCode;

  const sendSponsorWelcomeInviteMutation = useMutation(
    ['SponsorService.sendSponsorWelcomeInvite'],
    () =>
      SponsorService.sendSponsorWelcomeInvite(
        sponsorId,
        sponsorPlanQuery.data.data.id,
        sponsorQuery.data?.primaryContact.email || '',
        sponsorUserInviteCode || ''
      ),
    {
      onError: () => {
        showSnackbar({
          message: `Failed to resend welcome email `,
          severity: 'error'
        });
      },
      onSuccess: () => {
        queryClient.fetchQuery([
          'SponsorService.getSponsorTeammates',
          sponsorId
        ]);
        showSnackbar({
          message: `Success! Welcome email sent to ${
            sponsorQuery.data?.primaryContact.email || ''
          }`,
          severity: 'success'
        });
      }
    }
  );

  const createSponsorPrimaryContactInviteMutation = useMutation(
    ['SponsorService.createSponsorPrimaryContactInvite'],
    () =>
      SponsorService.createSponsorPrimaryContactInvite(sponsorId, {
        deliveryMethod: 'email',
        email: sponsorQuery.data?.primaryContact.email || ''
      }),
    {
      onError: () => {
        showSnackbar({
          message: `Failed to create and send welcome email `,
          severity: 'error'
        });
      },
      onSuccess: () => {
        queryClient.fetchQuery([
          'SponsorService.getSponsorTeammates',
          sponsorId
        ]);
        sponsorUserInvitesQuery.refetch();
        showSnackbar({
          message: `Success! Welcome email created and email sent to ${
            sponsorQuery.data?.primaryContact.email || ''
          }`,
          severity: 'success'
        });
      }
    }
  );

  const deleteSponsorUserInviteMutation = useMutation(
    ['SponsorService.deleteSponsorUserInvite'],
    () => SponsorService.deleteSponsorUserInvite(userInvite?.id || ''),
    {
      onError: () => {
        showSnackbar({
          message: `Failed to create and send welcome email `,
          severity: 'error'
        });
      }
    }
  );

  const resendInvites = async () => {
    if (!sponsorUserInviteCode) {
      await createSponsorPrimaryContactInviteMutation.mutateAsync();
    } else if (new Date() > new Date(userInvite?.attributes?.expiresAt)) {
      await deleteSponsorUserInviteMutation.mutateAsync();
      await createSponsorPrimaryContactInviteMutation.mutateAsync();
    } else {
      await sendSponsorWelcomeInviteMutation.mutateAsync();
    }
  };

  if (sponsorQuery.isFetching) {
    return (
      <Typography data-testid='plan-company-info-card-main-contact-tab-loading'>
        Loading...
      </Typography>
    );
  }

  if (sponsorQuery.isError || !sponsorQuery.isSuccess) {
    return (
      <Typography data-testid='plan-company-info-card-main-contact-tab-error'>
        Error
      </Typography>
    );
  }

  return (
    <Box
      alignItems='baseline'
      data-testid='plan-company-info-card-main-contact-tab-content'
      display='flex'
      flexDirection='row'
      width='100%'>
      <Box
        display='flex'
        flexDirection='column'
        sx={{ '> *': { margin: '0 0 10px 0' } }}>
        <CardIconInfoField
          icon={<PersonOutlineOutlinedIcon />}
          title={formatters.displayValueOrEmptyFieldPlaceholder(
            (!sponsorQuery.data.primaryContact.firstName ||
            sponsorQuery.data.primaryContact.firstName === 'ㅤ'
              ? ''
              : sponsorQuery.data.primaryContact.firstName) +
              ' ' +
              (!sponsorQuery.data.primaryContact.lastName ||
              sponsorQuery.data.primaryContact.lastName === 'ㅤ'
                ? ''
                : sponsorQuery.data.primaryContact.lastName)
          )}
          tooltip='Main Contact Name and Role'
        />
        <CardIconInfoField
          icon={<PhoneOutlinedIcon />}
          subtitle={formatters.displayValueOrEmptyFieldPlaceholder(
            sponsorQuery.data.primaryContact.phone
          )}
          tooltip='Main Contact Phone Number'
        />
        <CardIconInfoField
          icon={<EmailOutlinedIcon />}
          subtitle={formatters.displayValueOrEmptyFieldPlaceholder(
            sponsorQuery.data.primaryContact.email
          )}
          tooltip='Main Contact Email'
        />

        {isVWPlan && (
          <AccessControl
            requiresOneOf={[
              FeatureLevelPermissions.WRITE_COMPANY_COMPANY_INFO
            ]}>
            <Button
              className={classes.resendWelcomeEmailButton}
              data-testid='resend-welcome-email-button'
              disabled={
                createSponsorPrimaryContactInviteMutation.isLoading ||
                deleteSponsorUserInviteMutation.isLoading ||
                sendSponsorWelcomeInviteMutation.isLoading ||
                Boolean(sponsorQuery.data?.activationDate) ||
                formatters.displayValueOrEmptyFieldPlaceholder(
                  sponsorQuery.data.primaryContact.email
                ) === '--'
              }
              endIcon={
                (createSponsorPrimaryContactInviteMutation.isLoading ||
                  deleteSponsorUserInviteMutation.isLoading ||
                  sendSponsorWelcomeInviteMutation.isLoading) && (
                  <CircularProgress size={10} />
                )
              }
              onClick={() => resendInvites()}
              variant='text'>
              Resend welcome email
            </Button>
          </AccessControl>
        )}
      </Box>

      <AccessControl
        requiresOneOf={[FeatureLevelPermissions.WRITE_COMPANY_COMPANY_INFO]}>
        <Button
          className={classes.editButton}
          data-testid='edit-main-contact-button'
          onClick={() => {
            const initialEmail = sponsorQuery.data?.primaryContact?.email;

            const validationSchema = yup.object().shape(
              {
                email: yup
                  .string()
                  .trim()
                  .email('Email must be a valid email')
                  .max(512)
                  .test(
                    'Check if user tries to delete a main contact email',
                    'You cannot remove the main contact email',
                    newEmail => {
                      if (!newEmail && initialEmail) {
                        return false;
                      }
                      return true;
                    }
                  ),
                firstName: yup
                  .string()
                  .trim()
                  .max(100, 'First Name must be at most 100 characters')
                  .when('lastName', {
                    is: (lastName: string) => lastName && lastName.length > 0,
                    otherwise: yup.string().optional(),
                    then: yup
                      .string()
                      .required('Please enter both first and last names')
                  })

                  .matches(
                    /^[A-Za-z\s.,'-]+$/g,
                    'First Name cannot contain special characters'
                  ),
                lastName: yup
                  .string()
                  .trim()
                  .max(100, 'Last Name must be at most 100 characters')
                  .when('firstName', {
                    is: (firstName: string) =>
                      firstName && firstName.length > 0,
                    otherwise: yup.string().optional(),
                    then: yup
                      .string()
                      .required('Please enter both first and last names')
                  })
                  .matches(
                    /^[A-Za-z\s.,'-]+$/g,
                    'Last Name cannot contain special characters'
                  ),
                phone: yup
                  .string()
                  .matches(
                    /^(?:\d{3})-\d{3}-\d{4}$/,
                    'Invalid format, please use: XXX-XXX-XXXX'
                  )
              },
              [['firstName', 'lastName']]
            );

            return openDialog({
              onSubmit: async values => {
                const dto = {
                  data: {
                    attributes: {
                      primaryContact: {
                        email: values.email,
                        firstName: values.firstName.trim(),
                        lastName: values.lastName.trim(),
                        phone: values.phone
                      }
                    },
                    id: Number(sponsorId),
                    type: 'sponsor'
                  }
                };

                await sponsorMutation.mutateAsync(dto as UpdateSponsorDto);
              },
              steps: [
                {
                  fields: {
                    firstName: {
                      initialValue:
                        !sponsorQuery.data.primaryContact.firstName ||
                        sponsorQuery.data.primaryContact.firstName === 'ㅤ'
                          ? ''
                          : sponsorQuery.data.primaryContact.firstName,
                      label: 'First Name'
                    },
                    lastName: {
                      initialValue:
                        !sponsorQuery.data.primaryContact.lastName ||
                        sponsorQuery.data.primaryContact.lastName === 'ㅤ'
                          ? ''
                          : sponsorQuery.data.primaryContact.lastName,
                      label: 'Last Name'
                    },
                    phone: {
                      initialValue: sponsorQuery.data.primaryContact.phone,
                      label: 'Phone'
                    },
                    // eslint-disable-next-line sort-keys-plus/sort-keys
                    email: {
                      initialValue: sponsorQuery.data.primaryContact.email,
                      label: 'Email'
                    }
                  },
                  title: 'Edit Main Contact Info'
                }
              ],
              validationSchema
            });
          }}
          variant='text'>
          Edit
        </Button>
      </AccessControl>
    </Box>
  );
};

export default MainContactTab;
