import Constants from '@/consts/planDesign.constants';
import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class SafeHarborMatch {
  isHidden: boolean | undefined;

  convertToApiState = (
    editPlanDetails: Record<string, any>
  ): boolean | undefined => {
    return editPlanDetails.isSafeHarbor != null &&
      editPlanDetails.isSafeHarbor !== ''
      ? Helper.convertFromAllowDontAllowToBoolean(editPlanDetails.isSafeHarbor)
      : editPlanDetails.safeHarborMatchType
        ? editPlanDetails.safeHarborMatchType !== 'N/A - No Safe Harbor'
        : undefined;
  };

  options = Constants.allowDontAllowOptions;

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.isHidden = props.recordkeeperId !== Constants.voyaRecordkeeperId;
    this.output = this.isHidden
      ? undefined
      : Helper.convertFromBooleanToAllowDontAllow(props.isSafeHarbor);
  }
}

export default SafeHarborMatch;
