import { Box, TextField } from '@mui/material';

import { FormikContextType, useFormikContext } from 'formik';
import { get } from 'lodash';
import { useMemo } from 'react';

type BankAccountCardProps = {
  keyPrefix: string;
  disableCreditInfo: boolean;
  disableAccountType?: boolean;
};

const fieldStyle = { width: 350 };

const BankAccountCard: React.FunctionComponent<BankAccountCardProps> = (
  props: BankAccountCardProps
) => {
  const { keyPrefix, disableCreditInfo, disableAccountType = false } = props;
  const formik: FormikContextType<any> = useFormikContext();

  const fieldIds = useMemo(() => {
    return {
      accountName: `${keyPrefix}receivingBankAccountName`,
      accountNumber: `${keyPrefix}receivingBankAccountNumber`,
      bankName: `${keyPrefix}receivingBankName`,
      creditToAccountNumber: `${keyPrefix}furtherCreditToAccountNumber`,
      creditToName: `${keyPrefix}furtherCreditToName`,
      routingNumber: `${keyPrefix}receivingBankRoutingNumber`
    };
  }, [keyPrefix]);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
        <TextField
          error={
            get(formik.touched, fieldIds.bankName) &&
            Boolean(get(formik.errors, fieldIds.bankName))
          }
          helperText={
            (get(formik.touched, fieldIds.bankName) &&
              get(formik.errors, fieldIds.bankName)?.toString()) ||
            ''
          }
          id={fieldIds.bankName}
          label='Bank Name'
          name={fieldIds.bankName}
          onChange={formik.handleChange}
          required
          size='small'
          sx={fieldStyle}
          value={get(formik.values, fieldIds.bankName)}
        />
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            error={
              get(formik.touched, fieldIds.accountName) &&
              Boolean(get(formik.errors, fieldIds.accountName))
            }
            helperText={
              (get(formik.touched, fieldIds.accountName) &&
                get(formik.errors, fieldIds.accountName)?.toString()) ||
              ''
            }
            id={fieldIds.accountName}
            label='Account Owner'
            name={fieldIds.accountName}
            onChange={formik.handleChange}
            required
            size='small'
            sx={fieldStyle}
            value={get(formik.values, fieldIds.accountName)}
          />
          {!disableAccountType && (
            <TextField
              InputProps={{
                readOnly: true
              }}
              id='bankAccountType'
              label='Account Type'
              size='small'
              sx={fieldStyle}
              value='Checking'
              variant='outlined'
            />
          )}
        </Box>

        <TextField
          error={
            get(formik.touched, fieldIds.accountNumber) &&
            Boolean(get(formik.errors, fieldIds.accountNumber))
          }
          helperText={
            (get(formik.touched, fieldIds.accountNumber) &&
              get(formik.errors, fieldIds.accountNumber)?.toString()) ||
            ''
          }
          id={fieldIds.accountNumber}
          label='Account Number'
          name={fieldIds.accountNumber}
          onChange={formik.handleChange}
          required
          size='small'
          sx={fieldStyle}
          value={get(formik.values, fieldIds.accountNumber)}
        />
        <TextField
          error={
            get(formik.touched, fieldIds.routingNumber) &&
            Boolean(get(formik.errors, fieldIds.routingNumber))
          }
          helperText={
            (get(formik.touched, fieldIds.routingNumber) &&
              get(formik.errors, fieldIds.routingNumber)?.toString()) ||
            ''
          }
          id={fieldIds.routingNumber}
          label='Routing Number'
          name={fieldIds.routingNumber}
          onChange={formik.handleChange}
          required
          size='small'
          sx={fieldStyle}
          value={get(formik.values, fieldIds.routingNumber)}
        />
        {!disableCreditInfo && (
          <>
            <TextField
              error={
                get(formik.touched, fieldIds.creditToName) &&
                Boolean(get(formik.errors, fieldIds.creditToName))
              }
              helperText={
                (get(formik.touched, fieldIds.creditToName) &&
                  get(formik.errors, fieldIds.creditToName)?.toString()) ||
                ''
              }
              id={fieldIds.creditToName}
              label='Further Credit To (Name)'
              name={fieldIds.creditToName}
              onChange={formik.handleChange}
              size='small'
              sx={fieldStyle}
              value={get(formik.values, fieldIds.creditToName)}
            />
            <TextField
              error={
                get(formik.touched, fieldIds.creditToAccountNumber) &&
                Boolean(get(formik.errors, fieldIds.creditToAccountNumber))
              }
              helperText={
                (get(formik.touched, fieldIds.creditToAccountNumber) &&
                  get(
                    formik.errors,
                    fieldIds.creditToAccountNumber
                  )?.toString()) ||
                ''
              }
              id={fieldIds.creditToAccountNumber}
              label='Further Credit To (Account Number)'
              name={fieldIds.creditToAccountNumber}
              onChange={formik.handleChange}
              size='small'
              sx={fieldStyle}
              value={get(formik.values, fieldIds.creditToAccountNumber)}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default BankAccountCard;
