import NavigationBreadcrumbs from '@/components/navigation-breadcrumbs';
import { PlanV2Dto } from '@/models';
import { PlanService } from '@/services/Plan.service';
import { Box, Grid, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import React, { FC } from 'react';

import { usePlanType } from '../../plan-detail/usePlanType.hook';

type HeaderProps = {
  children?: React.ReactNode;
  planId: string | number;
  planName?: string;
  ucid: string;
  label: string;
  payrollDate?: string;
};

const Header: FC<HeaderProps> = props => {
  const { children, planId, planName, ucid, label, payrollDate } = props;

  const planQuery = useQuery<PlanV2Dto>({
    enabled: !!planId,
    queryFn: () => PlanService.getPlanById(planId),
    queryKey: [PlanService.getPlanById.name, planId],
    staleTime: Infinity
  });

  const planType = usePlanType({
    recordkeeper: planQuery.data?.data.attributes.recordkeeper,
    type: planQuery.data?.data.attributes.type
  });

  const paths = [
    {
      name: planType.urlPlansTypeName,
      to: `/${planType.urlPlansType}`
    },
    {
      name: planName ?? '--',
      to: `/${planType.urlPlansType}/${planId}`
    },
    {
      name: `${payrollDate || '...'} Contribution`,
      to: `/${planType.urlPlansType}/${planId}/contributions/${ucid}/overview`
    }
  ];

  return (
    <>
      <NavigationBreadcrumbs
        data-testid='correction-header-breadcrumbs'
        paths={paths}
      />
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography data-testid='correction-header-title' variant='h4'>
            {label}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Box display='flex' justifyContent='right'>
            {children}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Header;
