import { Order } from '@/components/collapsible-table';
import { GoalSeriesSearchData } from '@/routes/ops/investments/goal-series/InvestmentGoalSeries.component';
import ApiService from '@/services/Api.service';

type GoalSeriesListDto = {
  data: GoalSeriesSearchData[];
  meta: { count: number };
};

class GoalSeriesService {
  static async getGoalSeriesPage(
    pageNumber: number,
    pageSize: number,
    orderBy: string,
    order: Order,
    name?: string,
    id?: number
  ): Promise<GoalSeriesListDto> {
    const sort = (order === 'asc' ? '' : '-').concat(orderBy);
    return ApiService.getJson('/investments/goal-series', {
      id,
      name,
      pageNumber,
      pageSize,
      sort
    }) as unknown as GoalSeriesListDto;
  }
}

export type { GoalSeriesListDto };
export { GoalSeriesService };
