import AccessControl from '@/components/access-control/AccessControl.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import {
  UserAssociationsDto,
  UserForgotPasswordLinkDto,
  UsersItemDto
} from '@/models/UsersDTO.model';
import UserManagementService from '@/services/UserManagement.service';
import { WarningAmberOutlined } from '@mui/icons-material';
import { Alert, Box, Button, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';

import dayjs from 'dayjs';
import React, { FC, useCallback, useMemo } from 'react';

export interface UserManagementResetPasswordProps {
  selectedUser: UsersItemDto;
}

export const UserManagementResetPassword: FC<
  UserManagementResetPasswordProps
> = props => {
  const { showSnackbar } = useSnackbar();

  const userAssociations = useQuery<UserAssociationsDto[]>(
    ['UserManagementService.getUserAssociations', props.selectedUser.userId],
    () => UserManagementService.getUserAssociations(+props.selectedUser.userId)
  );

  const link = useQuery<UserForgotPasswordLinkDto>(
    [
      'UserManagementService.getUserForgotPasswordLink',
      props.selectedUser.userId
    ],
    () =>
      UserManagementService.getUserForgotPasswordLink(
        +props.selectedUser.userId
      )
  );

  const postUserForgotPassword = useMutation(
    () =>
      UserManagementService.postUserForgotPassword(+props.selectedUser.userId),
    {
      onError: () => {
        showSnackbar({
          message: `Failed! Couldn’t generate a link`,
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: 'Link Generated',
          severity: 'success'
        });

        link.refetch();
      }
    }
  );

  const onGenerateResetLink = useCallback(async () => {
    await postUserForgotPassword.mutateAsync();
  }, [postUserForgotPassword]);

  const onCopyLink = useCallback(async () => {
    await navigator.clipboard.writeText(link.data?.url ?? '');
    showSnackbar({
      message: `Reset Link copied. Link will expire in ${dayjs(
        link.data?.expires
      ).diff(dayjs(), 'hour')} hours (at ${dayjs(link.data?.expires).format(
        'MM/DD/YYYY hh:mm:ss A'
      )})`,
      severity: 'success'
    });
  }, [link.data?.expires, link.data?.url, showSnackbar]);

  const isDisabled = useMemo(
    () =>
      userAssociations.data?.some(
        association => association?.recordKeeperId === 2
      ),
    [userAssociations.data]
  );

  return (
    <Box data-testid='user-management-reset-password'>
      <AccessControl
        prohibitedAccessComponent={
          <Alert icon={<WarningAmberOutlined />} severity='warning'>
            You do not have permissions to create a reset password link, please
            contact a service manager.
          </Alert>
        }
        requires={[
          FeatureLevelPermissions.WRITE_USER_MANAGEMENT_PASSWORD_RESET
        ]}>
        <Typography maxWidth={480} mb={3} variant='body2'>
          Only copy and provide a user a link if they are having trouble
          resetting their password via the reset password email.{' '}
          <strong>
            User identity must be verified over a call before providing them
            with a copied reset link.
          </strong>
        </Typography>
        <Alert severity='warning' sx={{ mb: 3 }}>
          Password Reset Email will be sent to the user each time a link is
          generated
        </Alert>
        <Button
          disabled={isDisabled || postUserForgotPassword.isLoading}
          onClick={onGenerateResetLink}>
          Generate Reset Link
        </Button>
        <Button
          disabled={
            !link.data?.url ||
            postUserForgotPassword.isLoading ||
            link.isFetching
          }
          onClick={onCopyLink}
          sx={{ ml: 2 }}
          variant='outlined'>
          Copy link
        </Button>
      </AccessControl>
    </Box>
  );
};
