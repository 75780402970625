import { Transaction } from './TransactionDTO.model';

export enum DeconversionStatus {
  NotScheduled = 'Not Scheduled',
  PendingApproval = 'Pending Approval',
  Scheduled = 'Scheduled',
  PendingLiquidation = 'Pending Liquidation and Disbursement',
  Complete = 'Complete'
}

export interface BankInfoDto {
  name: string;
  accountName?: string;
  routingNumber: string;
  accountNumber: string;
  creditToName?: string;
  creditToAccountNumber?: string;
}

export interface PayeeInfoDto {
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  referenceMemo?: string;
}

export type DeconversionContactsDto = {
  rkEmail: string;
  clientEmail: string;
};

export interface DisbursementInstructionsDto {
  deliveryMethod: string;
  payeeInfo?: PayeeInfoDto;
  bankInfo?: BankInfoDto;
  destinationId?: number;
  contacts?: DeconversionContactsDto;
}

export interface ShortPositionDto {
  unit: number;
  subAccount: string;
  source: string;
  shortSince: string;
}

export interface ShortPositionsDto {
  data: ShortPositionDto[];
}

export interface DebitCashDataDto {
  subAccount: string;
  participant?: string;
  amount: number;
}

export interface DebitCashDto {
  data: DebitCashDataDto[];
}

export interface ReconciliationExceptionDto {
  id: number;
  status: string;
  breakType: string;
  security?: string;
  exceptionDate: string;
}

export interface ReconciliationExceptionsDto {
  reconciliations: ReconciliationExceptionDto[];
}

export interface PlanDeconversionDto {
  sponsorPlanId: number;
  status: DeconversionStatus;
  liquidationDate?: string;
  disbursementInstructions?: DisbursementInstructionsDto;
  shortPositions?: ShortPositionsDto;
  debitCash?: DebitCashDto;
  reconciliationExceptions?: ReconciliationExceptionsDto;
  submittedBy?: any;
  approvedBy?: any;
  createdAt: string;
  updatedAt: string;
}

export interface DeconversionRequestDto {
  data: {
    actionType: string;
    status: string;
    workflowStatus?: string;
    tracerId: string;
    createdAt: string;
    updatedAt: string;
    transactions: Transaction[];
    subaResult?: any;
    request?: any;
  }[];
}

export interface PlanDeconversionResponse {
  data: PlanDeconversionDto;
}

export interface UpdateDeconversionDto {
  sponsorPlanId: number;
  status: DeconversionStatus;
  isSubmitted?: boolean;
  isApproved?: boolean;
}

export type DeconversionDestinationDto = {
  id?: number;
  name: string;
  bankInfo: BankInfoDto;
  payeeInfo: PayeeInfoDto;
  createdAt?: string;
};

export type DeconversionDestinationResponse = {
  data: DeconversionDestinationDto[];
};
