import AppConfig from '@/App.config';
import { DocumentDto } from '@/models';
import { Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import React from 'react';

interface TermsOfUseContentProps {
  document: DocumentDto;
}

const useStyles = makeStyles((theme: Theme) => ({
  vestwellLogo: {
    height: theme.spacing(7)
  }
}));

const TermsOfUseContent = (props: TermsOfUseContentProps): JSX.Element => {
  const classes = useStyles();
  const { document } = props;
  const htmlData = Buffer.from(document.base64Data, 'base64').toString();

  return (
    <>
      <Grid container justifyContent='center'>
        <Grid item>
          <img
            alt={AppConfig.branding.logoAlt}
            className={classes.vestwellLogo}
            data-testid='agreement-vestwell-icon'
            src={AppConfig.branding.logoPath}
          />
        </Grid>
        <div
          dangerouslySetInnerHTML={{ __html: htmlData }}
          data-testid='terms-of-use-html-content'
        />
      </Grid>
    </>
  );
};

export default TermsOfUseContent;
