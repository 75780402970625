import AccessControl from '@/components/access-control/AccessControl.component';
import SimpleDropdown from '@/components/simple-dropdown';
import SimpleMaskedInput from '@/components/simple-masked-input';
import { TextLabel, TextStackItem, TextValue } from '@/components/text-stack';
import STATE_CODES from '@/consts/states.constants';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { WithdrawalDto } from '@/models';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { WITHDRAWAL_PENDING_STATUSES } from '@/models/Withdrawals.model';
import { WithdrawalAddressUpdateRequest } from '@/models/WithdrawalsDTO.model';
import ParticipantService from '@/services/Participant.service';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { FC, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useToggle } from 'react-use';
import * as yup from 'yup';

type WithdrawalAddressProps = {
  address: {
    address1: string;
    address2?: string;
    city: string;
    memo?: string;
    payee?: string;
    state: string;
    zip: string;
  };
  type: 'Cash' | 'RolloverPretax' | 'RolloverRoth';
};

type WithdrawalDetailRouteProps = {
  participantId: string;
  withdrawalId: string;
};

const validationSchema = yup.object({
  address1: yup.string().required('Address Line 1 is required'),
  address2: yup.string().optional(),
  city: yup.string().required('City is required'),
  state: yup.string().oneOf(STATE_CODES).required('State is required'),
  zip: yup
    .string()
    .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Must be a valid zip code')
    .required('Zip is required')
});

export const WithdrawalAddress: FC<WithdrawalAddressProps> = (
  props: WithdrawalAddressProps
) => {
  const routeParams = useParams<WithdrawalDetailRouteProps>();
  const [openCancelDialog, toggleOpenCancelDialog] = useToggle(false);
  const [successMessage, setSuccessMessage] = useState('');
  const { showSnackbar } = useSnackbar();
  const dialog = useDialog();
  const queryClient = useQueryClient();
  const userInfo = userService.getUser();

  const withdrawalQuery = useQuery<WithdrawalDto>(
    [
      'ParticipantService.getWithdrawal',
      routeParams.participantId,
      routeParams.withdrawalId
    ],
    () =>
      ParticipantService.getWithdrawal(
        +routeParams.participantId,
        +routeParams.withdrawalId
      ),
    {
      enabled: !!(routeParams.participantId && routeParams.withdrawalId),
      staleTime: Infinity
    }
  );

  const isUploadingUser =
    userInfo?.nickname ===
    withdrawalQuery.data?.attributes.addressUpdate?.submittedBy?.id;

  const updatedAddress = useMemo(() => {
    return {
      ...(props.type === 'Cash' && {
        address1:
          withdrawalQuery.data?.attributes.addressUpdate?.cash
            ?.withdrawalAddress1,
        address2:
          withdrawalQuery.data?.attributes.addressUpdate?.cash
            ?.withdrawalAddress2,
        city: withdrawalQuery.data?.attributes.addressUpdate?.cash
          ?.withdrawalCity,
        state:
          withdrawalQuery.data?.attributes.addressUpdate?.cash?.withdrawalState,
        status: withdrawalQuery.data?.attributes.addressUpdate?.cash?.status,
        zip: withdrawalQuery.data?.attributes.addressUpdate?.cash?.withdrawalZip
      }),
      ...(props.type === 'RolloverPretax' && {
        address1:
          withdrawalQuery.data?.attributes.addressUpdate?.rolloverPretax
            ?.withdrawalAddress1,
        address2:
          withdrawalQuery.data?.attributes.addressUpdate?.rolloverPretax
            ?.withdrawalAddress2,
        city: withdrawalQuery.data?.attributes.addressUpdate?.rolloverPretax
          ?.withdrawalCity,
        memo: withdrawalQuery.data?.attributes.addressUpdate?.rolloverPretax
          ?.withdrawalMemo,
        payee:
          withdrawalQuery.data?.attributes.addressUpdate?.rolloverPretax
            ?.withdrawalPayee,
        state:
          withdrawalQuery.data?.attributes.addressUpdate?.rolloverPretax
            ?.withdrawalState,
        status:
          withdrawalQuery.data?.attributes.addressUpdate?.rolloverPretax
            ?.status,
        zip: withdrawalQuery.data?.attributes.addressUpdate?.rolloverPretax
          ?.withdrawalZip
      }),
      ...(props.type === 'RolloverRoth' && {
        address1:
          withdrawalQuery.data?.attributes.addressUpdate?.rolloverRoth
            ?.withdrawalAddress1,
        address2:
          withdrawalQuery.data?.attributes.addressUpdate?.rolloverRoth
            ?.withdrawalAddress2,
        city: withdrawalQuery.data?.attributes.addressUpdate?.rolloverRoth
          ?.withdrawalCity,
        memo: withdrawalQuery.data?.attributes.addressUpdate?.rolloverRoth
          ?.withdrawalMemo,
        payee:
          withdrawalQuery.data?.attributes.addressUpdate?.rolloverRoth
            ?.withdrawalPayee,
        state:
          withdrawalQuery.data?.attributes.addressUpdate?.rolloverRoth
            ?.withdrawalState,
        status:
          withdrawalQuery.data?.attributes.addressUpdate?.rolloverRoth?.status,
        zip: withdrawalQuery.data?.attributes.addressUpdate?.rolloverRoth
          ?.withdrawalZip
      })
    };
  }, [withdrawalQuery.data?.attributes.addressUpdate]);

  const withdrawalAddressMutation = useMutation(
    [
      'ParticipantService.putWithdrawalAddress',
      routeParams.participantId,
      routeParams.withdrawalId
    ],
    (data: WithdrawalAddressUpdateRequest) =>
      ParticipantService.putWithdrawalAddress(data),
    {
      onError: () => {
        showSnackbar({
          message: `Couldn't submit address changes at the moment`,
          severity: 'error'
        });
      },
      onSuccess: (_, variables) => {
        queryClient.refetchQueries([
          'ParticipantService.getWithdrawal',
          routeParams.participantId,
          routeParams.withdrawalId
        ]);
        showSnackbar({
          message: successMessage,
          severity: 'success'
        });
        if (variables.status === 'Approved') {
          queryClient.refetchQueries([
            'ParticipantService.getWithdrawalDestinations',
            routeParams.participantId,
            routeParams.withdrawalId
          ]);
        }
      }
    }
  );

  const openModal = useCallback(
    () =>
      dialog.openDialog({
        actionButtons: {
          cancelButton: {
            children: 'Cancel'
          },
          submitButton: {
            children: 'Submit for approval'
          }
        },
        onSubmit: rawInputValues => {
          setSuccessMessage('Submitted address changes for approval');
          withdrawalAddressMutation.mutate({
            address1: rawInputValues.address1,
            address2: rawInputValues.address2,
            city: rawInputValues.city,
            deliveryType: props.type,
            memo: rawInputValues.memo,
            payee: rawInputValues.payee,
            state: rawInputValues.state,
            status: 'Pending',
            withdrawalId: +routeParams.withdrawalId,
            zip: rawInputValues.zip
          });
        },
        steps: [
          {
            fields: {
              address1: {
                initialValue:
                  updatedAddress?.address1 ?? props.address.address1,
                label: 'Address Line 1'
              },
              address2: {
                initialValue:
                  updatedAddress?.address2 ?? props.address.address2,
                label: 'Address Line 2'
              },
              city: {
                initialValue: updatedAddress?.city ?? props.address.city,
                label: 'City'
              },
              state: {
                component: (
                  <SimpleDropdown
                    fieldId='state'
                    fieldName='State'
                    fieldValues={STATE_CODES}
                  />
                ),
                initialValue: updatedAddress?.state ?? props.address.state,
                label: 'State'
              },
              zip: {
                component: (
                  <SimpleMaskedInput
                    fieldId='zip'
                    fieldName='Zipcode'
                    inputTransform={(raw: string) => {
                      const numerals = formatters.extractNumerals(raw, 9);
                      if (numerals.length <= 5) {
                        return numerals;
                      } else if (numerals.length <= 9) {
                        return `${numerals.slice(0, 5)}-${numerals.slice(5)}`;
                      }

                      return null;
                    }}
                    placeholder='90210'
                  />
                ),
                initialValue: updatedAddress?.zip ?? props.address.zip,
                label: 'Zipcode'
              },
              ...(props.type !== 'Cash' && {
                memo: {
                  initialValue: updatedAddress?.memo ?? props.address.memo,
                  label: 'Memo'
                },
                payee: {
                  initialValue: updatedAddress?.payee ?? props.address.payee,
                  label: 'Payee'
                }
              })
            },
            title: 'Update Payee Address'
          }
        ],
        validationSchema
      }),
    [withdrawalQuery.data]
  );

  const cancelChanges = () => {
    setSuccessMessage('Payee address change has been canceled');
    withdrawalAddressMutation.mutate({
      deliveryType: props.type,
      status: 'Canceled',
      withdrawalId: +routeParams.withdrawalId
    });
    toggleOpenCancelDialog();
  };

  const rejectChanges = () => {
    setSuccessMessage('Payee address change has been rejected');
    withdrawalAddressMutation.mutate({
      deliveryType: props.type,
      status: 'Rejected',
      withdrawalId: +routeParams.withdrawalId
    });
  };

  const acceptChanges = () => {
    setSuccessMessage('Payee address has been successfully updated');
    withdrawalAddressMutation.mutate({
      deliveryType: props.type,
      status: 'Approved',
      withdrawalId: +routeParams.withdrawalId
    });
  };

  return (
    <>
      {props.type !== 'Cash' && (
        <>
          <TextStackItem>
            <TextLabel>Payee</TextLabel>
            <TextValue data-testid={`${props.type}-payee`}>
              {props.address.payee ?? null}
            </TextValue>
          </TextStackItem>
          <TextStackItem>
            <TextLabel>Memo</TextLabel>
            <TextValue data-testid={`${props.type}-memo`}>
              {props.address.memo ?? null}
            </TextValue>
          </TextStackItem>
        </>
      )}
      <TextStackItem>
        <TextLabel>Address</TextLabel>
        <TextValue data-testid={`${props.type}-address`}>
          {props.address.address1}
          <br />
          {props.address.address2 && (
            <>
              {props.address.address2}
              <br />
            </>
          )}
          {`${props.address.city} ${props.address.state} ${props.address.zip}`}
          <br />
          <AccessControl
            requiresOneOf={[FeatureLevelPermissions.WRITE_MONEY_OUT_ADDRESS]}>
            {updatedAddress?.status === 'Approved' && (
              <Typography
                color='GrayText'
                data-testid='edited-by-heading'
                variant='caption'>{`Edited by ${
                withdrawalQuery.data?.attributes.addressUpdate.submittedBy.id
              } & approved by ${
                withdrawalQuery.data?.attributes.addressUpdate.approvedBy.id
              } ${formatters.formatFromIsoDate(
                withdrawalQuery.data?.attributes.addressUpdate.updatedAt
              )}`}</Typography>
            )}
            {WITHDRAWAL_PENDING_STATUSES.includes(
              withdrawalQuery.data?.attributes.status
            ) &&
              (!withdrawalQuery.data?.attributes.addressUpdate ||
                !updatedAddress?.status ||
                updatedAddress?.status === 'Approved') && (
                <Button color='primary' onClick={openModal} variant='text'>
                  EDIT
                </Button>
              )}
            {WITHDRAWAL_PENDING_STATUSES.includes(
              withdrawalQuery.data?.attributes.status
            ) &&
              updatedAddress?.status === 'Pending' && (
                <Paper
                  square={false}
                  sx={{
                    borderColor: theme => theme.palette.primary.main,
                    padding: 2
                  }}
                  variant='outlined'>
                  <Typography
                    color='CaptionText'
                    data-testid='pending-changes-heading'
                    variant='caption'>
                    Pending Changes
                  </Typography>
                  {`Memo: ${updatedAddress.memo}`}
                  <br />
                  {`Payee: ${updatedAddress.payee}`}
                  <br />
                  {updatedAddress.address1}
                  <br />
                  {updatedAddress.address2 && (
                    <>
                      {updatedAddress.address2}
                      <br />
                    </>
                  )}
                  {`${updatedAddress.city} ${updatedAddress.state} ${updatedAddress.zip}`}
                  <br />
                  <Typography variant='caption'>{`Submitted by ${
                    withdrawalQuery.data?.attributes.addressUpdate.submittedBy
                      .id
                  } ${formatters.formatFromIsoDate(
                    withdrawalQuery.data?.attributes.addressUpdate.updatedAt
                  )}`}</Typography>
                  <Stack direction='row'>
                    {isUploadingUser ? (
                      <>
                        <Button
                          color='primary'
                          onClick={openModal}
                          variant='text'>
                          EDIT
                        </Button>
                        <Button
                          color='primary'
                          onClick={toggleOpenCancelDialog}
                          variant='text'>
                          CANCEL CHANGES
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          color='primary'
                          onClick={acceptChanges}
                          variant='text'>
                          APPROVE
                        </Button>
                        <Button
                          color='primary'
                          onClick={rejectChanges}
                          variant='text'>
                          REJECT
                        </Button>
                      </>
                    )}
                  </Stack>
                </Paper>
              )}
          </AccessControl>
        </TextValue>
      </TextStackItem>
      <Dialog onClose={toggleOpenCancelDialog} open={openCancelDialog}>
        <DialogTitle>Cancel the submitted changes?</DialogTitle>
        <DialogActions>
          <Button onClick={toggleOpenCancelDialog}>GO BACK</Button>
          <LoadingButton
            loading={withdrawalAddressMutation.isLoading}
            onClick={cancelChanges}>
            CANCEL CHANGES
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
