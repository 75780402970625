import Card, { CardContent } from '@/components/card';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { useSnackbar } from '@/contexts/SnackBarContext';
import BalanceTotalsService from '@/services/ops/balance-totals/BalanceTotals.service';
import BalanceService from '@/services/ops/balances/Balance.service';
import formatters from '@/utils/Formatters';
import { Divider } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { SubAccountType } from '@vestwell-sub-accounting/models/common/SubAccountType';

import { AxiosError } from 'axios';
import { FC, useContext, useMemo } from 'react';

import { AlertContext } from '../../AlertContext';
import { CardHeader } from '../common/CardHeader.component';

export const AchReversalExpandedReversalTransactionDetails: FC = () => {
  const alert = useContext(AlertContext);
  const { showSnackbar } = useSnackbar();

  const balanceTotalQuery = useQuery(
    ['BalanceTotalsService.get', alert?.parentAccountId],
    () => {
      return BalanceTotalsService.get({
        parentAccountId: alert?.parentAccountId
      });
    },
    {
      enabled: !!alert?.parentAccountId,
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Failed to fetch operational account balance totals: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const parentAccountBalanceQuery = useQuery(
    ['BalanceService.get', alert?.parentAccountId],
    () => {
      return BalanceService.get(alert?.parentAccountId, {});
    },
    {
      enabled: !!alert?.parentAccountId,
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Failed to fetch parent account balances: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const balances = useMemo(() => {
    if (balanceTotalQuery.data && parentAccountBalanceQuery.data) {
      return {
        moneyInBalance: balanceTotalQuery.data.filter(
          account => account.accountType === SubAccountType.moneyIn
        )[0].confirmedCashBalance,
        parentAccountBalance: parentAccountBalanceQuery.data.cash.confirmed
      };
    }
  }, [balanceTotalQuery.data, parentAccountBalanceQuery.data]);

  return (
    <Card data-testid='ach-reversal-expanded-reversal-transaction-detail-card'>
      <CardHeader title='Reversal Transaction'></CardHeader>
      <Divider />
      <CardContent
        loading={
          parentAccountBalanceQuery.isLoading || balanceTotalQuery.isLoading
        }>
        <TextStack direction='column' id='alert-detail-fields' spacing={1}>
          <TextStackItem>
            <TextLabel>Reversal Amount</TextLabel>
            <TextValue>
              {formatters.formatDollars(alert.details.event.body[0].amount)}
            </TextValue>
          </TextStackItem>
          <TextStackItem>
            <TextLabel>Parent Account Balance</TextLabel>
            <TextValue>
              {formatters.formatDollars(balances?.parentAccountBalance)}
            </TextValue>
          </TextStackItem>
          <TextStackItem>
            <TextLabel>Money In Balance</TextLabel>
            <TextValue>
              {formatters.formatDollars(balances?.moneyInBalance)}
            </TextValue>
          </TextStackItem>
        </TextStack>
      </CardContent>
    </Card>
  );
};
