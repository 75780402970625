import { DistributionRequestHoldingTankStatus } from '@vestwell-sub-accounting/models/common/HoldingTankStatus';

export const DistributionRequestHoldingTankStatusColorMap: Record<
  DistributionRequestHoldingTankStatus,
  'info' | 'neutral' | 'success' | 'warning'
> = {
  [DistributionRequestHoldingTankStatus.deferred]: 'info',
  [DistributionRequestHoldingTankStatus.disbursing]: 'neutral',
  [DistributionRequestHoldingTankStatus.disbursingFailed]: 'warning',
  [DistributionRequestHoldingTankStatus.disbursingQueued]: 'neutral',
  [DistributionRequestHoldingTankStatus.failed]: 'warning',
  [DistributionRequestHoldingTankStatus.finished]: 'success',
  [DistributionRequestHoldingTankStatus.partiallyReversed]: 'warning',
  [DistributionRequestHoldingTankStatus.processed]: 'neutral',
  [DistributionRequestHoldingTankStatus.queued]: 'neutral',
  [DistributionRequestHoldingTankStatus.reversed]: 'info'
};
