import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, List, ListItem, ListItemText, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import React, { ReactElement } from 'react';

interface Item {
  text: string;
  nestedItems: NestedItem[];
}
interface NestedItem {
  nestedItemText: string;
}
interface PlanContributionProcessedListProps {
  item: Item;
}

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    color: theme.palette.text.secondary,
    letterSpacing: theme.spacing(1 / 32),
    margin: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(0)
  },
  mainListItem: {
    color: theme.palette.text.secondary,
    letterSpacing: theme.spacing(1 / 32),
    margin: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    paddingTop: theme.spacing(0)
  },
  wrapper: {
    flex: 'none',
    width: '15vw'
  }
}));

const PlanContributionProcessedList = (
  props: PlanContributionProcessedListProps
): ReactElement<PlanContributionProcessedListProps> => {
  const { item } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      {item.nestedItems?.length ? (
        <>
          <ListItem
            button
            className={classes.mainListItem}
            onClick={handleClick}>
            <ListItemText className={classes.wrapper} primary={item.text} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              {item.nestedItems.map((nestedItem: NestedItem) => (
                <ListItem
                  className={classes.listItem}
                  key={nestedItem.nestedItemText}>
                  <ListItemText primary={nestedItem.nestedItemText} />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </>
      ) : (
        <ListItem className={classes.mainListItem}>
          <ListItemText primary={item.text} />
        </ListItem>
      )}
    </>
  );
};

export default PlanContributionProcessedList;
