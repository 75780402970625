import { useSnackbar } from '@/contexts/SnackBarContext';
import ContributionService from '@/services/Contribution.service';
import PayrollIntegrationsService from '@/services/payroll-integrations.service';
import { PlanService } from '@/services/Plan.service';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Unstable_Grid2 as Grid,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';

import { useFormik } from 'formik';
import { FC, useMemo } from 'react';
import { useUpdateEffect } from 'react-use';
import * as yup from 'yup';

type RemovePayGroupProps = {
  onClose: () => void;
  onSuccess: () => void;
  isOpen: boolean;
  planId: number;
  sponsorId: number;
  payrollSetupId: number;
};

const validations = yup.object().shape({
  otherReason: yup.string().when('reason', {
    is: value => value === 'Other',
    then: yup
      .string()
      .matches(/^\S+(\s+\S+)*$/, {
        excludeEmptyString: true,
        message: 'The field cannot begin or end with blank space'
      })
      .required('Required')
  }),
  reason: yup.string().required('Required')
});

export const PayGroupDeactivation: FC<RemovePayGroupProps> = props => {
  const { showSnackbar } = useSnackbar();

  const payrollSetup = useQuery(
    ['PlanService.getPayrollSetups', props.planId],
    () => {
      return PlanService.getPayrollSetups(props.planId);
    },
    {
      enabled: props.isOpen,
      select: payrollSetups =>
        payrollSetups?.find(
          payrollSetup => payrollSetup.id === props.payrollSetupId
        )
    }
  );

  const contributions = useQuery<any>(
    [
      'ContributionService.getContributions',
      props.planId,
      payrollSetup.data?.id,
      payrollSetup.data?.division
    ],
    () =>
      ContributionService.getContributions({
        division: payrollSetup.data?.division,
        planId: props.planId,
        sponsorId: props.sponsorId
      }),
    { enabled: !!payrollSetup.data }
  );

  const update = useMutation((data: any) =>
    PlanService.updatePayrollSetup(props.planId, payrollSetup.data?.id, data)
  );

  const deletePayrollIntegration = useMutation(
    (params: { payrollSetupId: number }) =>
      PayrollIntegrationsService.deletePayrollIntegration(params)
  );

  const formik = useFormik({
    initialValues: {
      otherReason: '',
      reason: ''
    },
    onSubmit: async values => {
      await update.mutateAsync({
        ...payrollSetup.data,
        ...(payrollSetup.data?.bankAccount
          ? payrollSetup.data?.bankAccount
          : {}),
        deactivationReason:
          values.reason === 'Other' ? values.otherReason : values.reason,
        isActive: false
      });

      if (payrollSetup.data?.payrollIntegrated) {
        await deletePayrollIntegration.mutateAsync({
          payrollSetupId: payrollSetup.data?.id
        });
      }

      showSnackbar({
        message: `Successfully deactivated "${payrollSetup.data?.payGroupName}"`,
        severity: 'success'
      });

      payrollSetup.refetch();

      props.onSuccess();
    },
    validateOnMount: true,
    validationSchema: validations
  });

  const isMissingContribution = useMemo(
    () =>
      contributions.data?.some(
        contribution =>
          contribution.contributionMeta?.payrollDueStatus === 'Missing'
      ),
    [contributions.data]
  );

  useUpdateEffect(() => {
    if (update.isError || deletePayrollIntegration.isError) {
      showSnackbar({
        autoHideDuration: 20000,
        message: `Encountered issue while deactivating "${payrollSetup.data?.payGroupName}" and calling downstream services. Please contact LEO for further investigation.`,
        severity: 'error'
      });

      payrollSetup.refetch();
    }
  }, [update.isError, deletePayrollIntegration.isError]);

  return (
    <Dialog fullWidth maxWidth='xs' onClose={props.onClose} open={props.isOpen}>
      <form onChange={formik.handleChange} onSubmit={formik.handleSubmit}>
        <DialogTitle data-testid='payGroupDeactivation-title'>
          Deactivate Pay Group "{payrollSetup.data?.payGroupName}"?
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid>
              <DialogContentText data-testid='payGroupDeactivation-note'>
                The pay group will be hidden from employer portal.
              </DialogContentText>
            </Grid>
            <Grid>
              <FormControl data-testid='payGroupDeactivation-form'>
                <FormLabel>Reason</FormLabel>
                <RadioGroup name='reason'>
                  <FormControlLabel
                    control={<Radio />}
                    data-testid='noLongerUsed'
                    label='No longer used'
                    value='No longer used'
                  />
                  <FormControlLabel
                    control={<Radio />}
                    data-testid='duplicatePayGroup'
                    label='Duplicate pay group'
                    value='Duplicate pay group'
                  />
                  <FormControlLabel
                    control={<Radio />}
                    data-testid='erroneouslyAdded'
                    label='Erroneously added'
                    value='Erroneously added'
                  />
                  <FormControlLabel
                    control={<Radio />}
                    data-testid='other'
                    label='Other'
                    value='Other'
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid xs={12}>
              {formik.values?.reason === 'Other' && (
                <TextField
                  error={!!formik.errors?.otherReason}
                  fullWidth
                  helperText={formik.errors?.otherReason}
                  label='Other reason'
                  name='otherReason'
                  placeholder='Other reason'
                />
              )}
            </Grid>
            {isMissingContribution && (
              <Grid>
                <Alert severity='warning'>
                  This pay group may be missing past contributions. Please
                  ensure that there are no outstanding contributions left to
                  process. Deactivating this pay group will skip all the open
                  "missing" and upcoming pay dates.
                </Alert>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            data-testid='payGroupDeactivation-cancel'
            onClick={props.onClose}>
            Cancel
          </Button>
          <Button
            color='error'
            data-testid='payGroupDeactivation-submit'
            disabled={!formik.isValid || formik.isSubmitting}
            type='submit'
            variant='contained'>
            Deactivate Pay Group
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
