import { CellComponentProps } from '@/components/collapsible-table';
import formatters from '@/utils/Formatters';
import { Box, TableCell } from '@mui/material';

import React from 'react';

const WithdrawalTransactionsTableCell: React.FunctionComponent<
  CellComponentProps
> = (props: CellComponentProps) => {
  const { row, column } = props;
  let field = <Box>{row[column.field] || '--'}</Box>;
  if (column.field === 'tradeDate' || column.field === 'effectiveDate') {
    field = (
      <Box>
        {formatters.formatFromIsoDateCustom(row[column.field], 'M/DD/YY')}
      </Box>
    );
  } else if (column.field === 'amount') {
    field = <Box>{formatters.formatDollars(row[column.field])}</Box>;
  }

  return (
    <TableCell component='th' scope='row'>
      <Box sx={{ '& .MuiBox-root': { fontSize: theme => theme.spacing(2) } }}>
        {field}
      </Box>
    </TableCell>
  );
};

export default WithdrawalTransactionsTableCell;
