import { DividendActivityQueueDto } from '@/models/ops/dividends/dividend-activity-queue-dto.model';
import { DividendActivityQueueRequest } from '@/models/ops/dividends/dividend-activity-queue-request.model';
import { DividendActivityTransactionDto } from '@/models/ops/dividends/dividend-activity-transaction-dto.model';
import { DividendActivityTransactionRequest } from '@/models/ops/dividends/dividend-activity-transaction-request.model';
import { DividendParentAccountTransactionDto } from '@/models/ops/dividends/DividendParentAccountTransactionDto.model';
import { DividendSearchParentAccountTransactionsRequest } from '@/models/ops/dividends/DividendSearchParentAccountTransactionsRequest.model';
import { PaginatedApiResponse } from '@/models/PaginatedApiResponse.model';
import ApiService from '@/services/Api.service';

class DividendService {
  static async searchDividendActivityTransactions(
    params: DividendActivityTransactionRequest
  ): Promise<PaginatedApiResponse<DividendActivityTransactionDto[]>> {
    return ApiService.getJson<
      PaginatedApiResponse<DividendActivityTransactionDto[]>
    >(`dividends/search-dividend-activity-transactions`, params);
  }

  static async searchParentAccountTransactions(
    params: DividendSearchParentAccountTransactionsRequest
  ): Promise<PaginatedApiResponse<DividendParentAccountTransactionDto[]>> {
    try {
      const result = await ApiService.getJson<
        PaginatedApiResponse<DividendParentAccountTransactionDto[]>
      >(`dividends/search-parent-account-transactions`, params);
      return result;
    } catch (err: any) {
      console.error(err);
      /**
       * err.response.data should contain the friendly error message
       * sent from the original data source
       */
      throw err.response?.data ? err.response?.data : err.message;
    }
  }

  static async searchDividendActivityQueue(
    params: DividendActivityQueueRequest
  ) {
    try {
      const result = await ApiService.getJson<
        PaginatedApiResponse<DividendActivityQueueDto[]>
      >(`dividends/search-dividend-activity-queue`, params);
      return result;
    } catch (err: any) {
      console.error(err);
      /**
       * err.response.data should contain the friendly error message
       * sent from the original data source
       */
      throw err.response?.data ? err.response?.data : err.message;
    }
  }
}

export default DividendService;
