import SimpleTabs from '@/components/simple-tabs';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { Card, CardContent } from '@mui/material';

import { FC, useMemo } from 'react';

type RolloverInstructionsProps = {
  name: string;
  custodianAccountNumber?: string;
};

const RolloverDeliveryInstructionsCard: FC<
  RolloverInstructionsProps
> = props => {
  const tabs = useMemo(() => {
    const tabItems = [
      {
        component: (
          <TextStack direction='column' sx={{ px: 3 }}>
            <TextStackItem>
              <TextLabel>Mailing Address:</TextLabel>
              <TextValue>
                Matrix Trust (DEN)
                <br />
                Attn: TPA 000429
                <br />
                P.O. Box 3595
                <br />
                New York, NY 10008-3595
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Payable To:</TextLabel>
              <TextValue>
                Matrix Trust Company
                <br />
                FBO {props?.name}
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Memo:</TextLabel>
              <TextValue>
                TPA 000429
                <br />
                Account Number {props?.custodianAccountNumber}
              </TextValue>
            </TextStackItem>
          </TextStack>
        ),
        label: 'CHECK'
      },
      {
        component: (
          <TextStack direction='column' sx={{ px: 3 }}>
            <TextStackItem>
              <TextLabel>Bank Name</TextLabel>
              <TextValue>JPMorgan Chase</TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Routing/ABA #</TextLabel>
              <TextValue>021 000 021</TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Account Number</TextLabel>
              <TextValue>886 273 556</TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Receiving Company Name</TextLabel>
              <TextValue>
                000429 {props?.custodianAccountNumber}
                <br />
                FBO {props?.name}
              </TextValue>
            </TextStackItem>
          </TextStack>
        ),
        label: 'ACH'
      },
      {
        component: (
          <TextStack direction='column' sx={{ px: 3 }}>
            <TextStackItem>
              <TextLabel>Bank Name</TextLabel>
              <TextValue>JPMorgan Chase</TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Bank Location</TextLabel>
              <TextValue>
                270 Park Ave.,
                <br />
                New York, NY, 10017
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Routing/ABA #</TextLabel>
              <TextValue>021 000 021</TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Beneficiary Account #</TextLabel>
              <TextValue>886 273 556</TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Beneficiary Name</TextLabel>
              <TextValue>Matrix Trust Company</TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Originator to Beneficiary Information</TextLabel>
              <TextValue>
                TPA 000429 <br />
                Account Number {props?.custodianAccountNumber}
              </TextValue>
            </TextStackItem>
          </TextStack>
        ),
        label: 'WIRE'
      }
    ];

    return {
      tabs: tabItems,
      tabsAriaLabel: 'rollover-details-data-grid-tabs'
    };
  }, [props]);

  return (
    <Card elevation={0} variant='outlined'>
      <CardContent sx={{ padding: 0 }}>
        <SimpleTabs {...tabs} />
      </CardContent>
    </Card>
  );
};

export default RolloverDeliveryInstructionsCard;
