import { ContributionCorrectionDto } from '@/models';
import { PlanService } from '@/services/Plan.service';
import formatters from '@/utils/Formatters';
import { Card, CardContent, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';

import React, { useMemo } from 'react';

import PlanCorrectionsDetailsTable from './PlanCorrectionsDetailsTable/PlanCorrectionsDetailsTable.component';

type PlanCorrectionsContributionProps = {
  sponsorId: number | undefined;
  sponsorPlanId: number | string;
  ucid: string;
  isStateIRA?: boolean;
};

type CorrectionDTOWithTotals = ContributionCorrectionDto & {
  totals: {
    initial: number;
    corrected: number;
  };
};

const useStyles = makeStyles((theme: Theme) => ({
  adjustment: {
    fontWeight: 'bold',
    marginTop: theme.spacing(2)
  },
  contentBody: {
    paddingBottom: theme.spacing(2)
  },
  headerTitle: {
    fontWeight: 'bold'
  },
  root: {
    paddingBottom: theme.spacing(2),
    width: '100%'
  }
}));

const normalizeCorrectionData =
  (correction: CorrectionDTOWithTotals, index: number) =>
  (isStateIRA?: boolean) => {
    const blackListedKeys = ['errorCode', 'rowNum', 'action'];
    const { initialValue, correctionValue } = correction;
    let metaDataFields = {};

    if (initialValue?.columnNames) {
      const strippedList =
        initialValue?.columnNames?.slice(1, -1).split(',') || [];

      const fieldNames = strippedList.map(field =>
        formatters.snakeToCamelCase(field)
      );

      const hasMultipleFields = fieldNames?.length > 1 || false;

      metaDataFields = { fieldNames, hasMultipleFields };
    } else {
      const fieldNames = Object.keys(initialValue).filter(
        key =>
          Object.keys(correctionValue).includes(key) &&
          !blackListedKeys.includes(key)
      );

      const hasMultipleFields = fieldNames.length > 1;

      metaDataFields = { fieldNames, hasMultipleFields };
    }

    return {
      ...correction,
      id: `${correction.source}-${index}`,
      isStateIRA,
      ...metaDataFields
    };
  };

const getCorrectionTotals = (correction: ContributionCorrectionDto) => {
  const hasTotalSummary =
    ([
      'ERR_MISMATCHED_LOAN_PAYMENT',
      'WARN_CONTRIBUTIONS_INVALID_LOAN_REPAYMENT'
    ].includes(correction.initialValue.errorCode) &&
      correction.initialValue?.contributionAmount) ||
    (correction.initialValue?.contributionAmount &&
      (correction?.initialValue?.unallowedAmount ||
        correction?.initialValue?.employeeUnallowedAmount));

  return hasTotalSummary
    ? {
        corrected:
          correction.correctionValue?.errorCode ===
          'ERR_MISMATCHED_LOAN_PAYMENT'
            ? +correction.autoCorrectionValue?.ln ||
              +correction.correctionValue?.ln
            : (+correction.autoCorrectionValue?.contributionAmount || 0) -
              (+correction?.autoCorrectionValue?.unallowedAmount ||
                +correction?.autoCorrectionValue?.employeeUnallowedAmount ||
                0),
        initial: +correction.initialValue?.contributionAmount || 0
      }
    : [
        'rc',
        'rc_catchup',
        'sd',
        'sd_catchup',
        'sh',
        'ps',
        'em',
        'ln',
        'qm',
        'qc',
        'at',
        'loan_amt_1',
        'loan_amt_2',
        'loan_amt_3',
        'loan_amt_4',
        'loan_amt_5'
      ].reduce(
        (acc, header) => {
          acc.initial += +correction.initialValue[header] || 0;
          acc.corrected +=
            correction.correctionType === 'auto_update'
              ? +correction.autoCorrectionValue[header] || 0
              : +correction.correctionValue[header] || 0;

          return acc;
        },
        { corrected: 0, initial: 0 }
      );
};

const filterEmployerCorrections = (
  corrections: ContributionCorrectionDto[],
  submissionInitiator?: string
) =>
  corrections.reduce((acc, correction) => {
    if (
      (![
        'ERR_MISMATCHED_LOAN_PAYMENT',
        'WARN_CONTRIBUTIONS_INVALID_LOAN_REPAYMENT'
      ].includes(correction.initialValue.errorCode) &&
        !['auto_update', 'delete'].includes(correction.correctionType)) ||
      correction.source === 'Admin'
    )
      return acc;

    const totals = getCorrectionTotals(correction);

    if (
      submissionInitiator !== 'System' &&
      correction.correctionType === 'delete' &&
      totals.initial === 0
    ) {
      return acc;
    }

    return [...acc, { ...correction, totals }];
  }, []);

const PlanContributionCorrections: React.FunctionComponent<
  PlanCorrectionsContributionProps
> = (props: PlanCorrectionsContributionProps) => {
  const classes = useStyles();

  const { sponsorPlanId, ucid, sponsorId } = props;

  const contributionsQuery = useQuery<ContributionCorrectionDto[]>(
    [
      PlanService.getContributionsCorrection.name,
      sponsorPlanId,
      ucid,
      sponsorId
    ],
    async () => {
      const contributionCorrectionsResponse: any =
        await PlanService.getContributionsCorrection(+sponsorPlanId, ucid);

      return contributionCorrectionsResponse;
    },
    {
      enabled: !!sponsorId
    }
  );

  const contributionDetailsQuery = useQuery(
    ['PlanService.getContributionDetails', +sponsorPlanId, ucid],
    async () => {
      return PlanService.getContributionsDetails(+sponsorPlanId, ucid);
    },
    {
      enabled: Boolean(sponsorPlanId)
    }
  );

  const adminCorrections = useMemo(
    () =>
      (contributionsQuery.data || [])
        ?.filter(correction => correction.source === 'Admin')
        .map(correction => ({
          ...correction,
          totals: getCorrectionTotals(correction)
        }))
        .map((correction, index) =>
          normalizeCorrectionData(correction, index)(props.isStateIRA)
        ),
    [contributionsQuery.data]
  );

  const employerCorrections = useMemo(
    () =>
      filterEmployerCorrections(
        contributionsQuery.data || [],
        contributionDetailsQuery.data?.pngStatuses[0]?.initiator
      ).map((correction, index) =>
        normalizeCorrectionData(correction, index)(props.isStateIRA)
      ),
    [contributionsQuery.data, contributionDetailsQuery.data]
  );

  const corrections = useMemo(
    () => [...adminCorrections, ...employerCorrections],
    [adminCorrections, employerCorrections]
  );

  if (contributionsQuery.isFetching) {
    return (
      <Typography className='Plan-Contribution-Corrections_Loading'>
        Loading...
      </Typography>
    );
  }

  if (contributionsQuery.isError) {
    return (
      <Typography className='Plan-Contribution-Corrections_Error'>
        Error retrieving contribution corrections
      </Typography>
    );
  }

  return (
    <Card
      className={classes.root}
      data-testid='contribution-adjustments-tile'
      elevation={0}
      variant='outlined'>
      <CardContent className={classes.contentBody}>
        <Typography data-testid='contribution-adjustments-title' variant='h5'>
          Contribution Adjustment
        </Typography>
      </CardContent>
      <PlanCorrectionsDetailsTable
        data={corrections}
        testId='contribution-adjustments-table'
      />
    </Card>
  );
};

export default PlanContributionCorrections;
