import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { LoanDetailsResponseDto } from '@/models';
import formatters from '@/utils/Formatters';
import { Box, Card, CardContent, CardHeader, Grid } from '@mui/material';

import React from 'react';

import { CheckAddress } from './CheckAddress.component';

type LoanDeastinationProps = {
  loanDetails: LoanDetailsResponseDto;
};

const LoanDestinationCard: React.FunctionComponent<LoanDeastinationProps> = (
  props: LoanDeastinationProps
) => {
  const { loanDetails } = props;
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Card variant='outlined'>
          <CardHeader
            sx={{
              borderBottom: theme => `1px solid ${theme.palette.grey[300]}`
            }}
            title='Destination'
          />
          <CardContent sx={{ margin: 2 }}>
            <Grid container rowSpacing={2}>
              <Grid xs={12}>
                <TextStack direction='column'>
                  <TextStackItem>
                    <TextLabel>Delivery Method</TextLabel>
                    <TextValue data-testid='delivery-method'>
                      {loanDetails.data.attributes.deliveryMethod ||
                        EMPTY_FIELD_PLACEHOLDER}
                    </TextValue>
                  </TextStackItem>
                  <TextStackItem>
                    <TextLabel>Financial Institution</TextLabel>
                    <TextValue data-testid='financial-institution'>
                      {loanDetails.data.attributes.bankAccountInfo
                        ?.financialInstitution || EMPTY_FIELD_PLACEHOLDER}
                    </TextValue>
                  </TextStackItem>
                  <TextStackItem>
                    <TextLabel>Routing Number</TextLabel>
                    <TextValue data-testid='routing-number'>
                      {loanDetails.data.attributes.bankAccountInfo
                        ?.routingNumber || EMPTY_FIELD_PLACEHOLDER}
                    </TextValue>
                  </TextStackItem>
                  <TextStackItem>
                    <TextLabel>Account Number</TextLabel>
                    <TextValue data-testid='account-number'>
                      {loanDetails.data.attributes.bankAccountInfo
                        ?.accountNumber
                        ? formatters.maskBankNumber(
                            loanDetails.data.attributes.bankAccountInfo
                              ?.accountNumber
                          )
                        : EMPTY_FIELD_PLACEHOLDER}
                    </TextValue>
                  </TextStackItem>
                  <TextStackItem>
                    <TextLabel>Plaid Mask</TextLabel>
                    <TextValue data-testid='account-number'>
                      {loanDetails.data.attributes.bankAccountInfo?.plaidMask ||
                        EMPTY_FIELD_PLACEHOLDER}
                    </TextValue>
                  </TextStackItem>
                  {loanDetails.data.attributes.deliveryAddress && (
                    <CheckAddress
                      deliveryAddress={
                        loanDetails.data.attributes.deliveryAddress
                      }
                      loanStatus={loanDetails.data.attributes.loanStatus}
                      updateAddress={loanDetails.data.attributes.addressUpdate}
                    />
                  )}
                </TextStack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default LoanDestinationCard;
