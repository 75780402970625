import { startCase } from 'lodash';

class PlanDesignProvider {
  options = [];

  output: string | undefined;

  constructor(options: Record<string, any>) {
    this.output = this.transform(options.planDesignProvider);
  }

  private transform = (value: string | undefined): string | undefined => {
    return value ? startCase(value) : value;
  };
}

export default PlanDesignProvider;
