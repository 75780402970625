import Badge from '@/components/badge';
import { CellComponentProps } from '@/components/collapsible-table';
import { useSnackbar } from '@/contexts/SnackBarContext';
import formatters from '@/utils/Formatters';
import { Button, styled, TableCell } from '@mui/material';

// import { Link } from '@mui/material';
import React, { FC, useCallback } from 'react';

// import { Link as RouterLink } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: theme.spacing(2),
  padding: theme.spacing(1)
}));

export const WhitelabelManagementTableCell: FC<CellComponentProps> = props => {
  const { showSnackbar } = useSnackbar();

  const onClick = useCallback(() => {
    showSnackbar({
      message: 'This feature is not yet implemented.',
      severity: 'success'
    });
  }, []);

  const fieldComponents = {
    logo: (
      <img
        alt='Logo'
        height='32px'
        onError={e => {
          const target = e.target as HTMLImageElement;
          target.onerror = null;
          target.src = props.row.logo.replace(/([^/]*)\.webp$/, 'connect.webp');
        }}
        src={props.row.logo}
        width='auto'
      />
    ),
    status: (
      <Badge
        color={
          props.row.status === 'Active'
            ? 'success'
            : props.row.status === 'Inactive'
              ? 'lightWarning'
              : 'neutral'
        }>
        {props.row.status}
      </Badge>
    ),
    updatedAt: formatters.formatFromIsoDateCustom(
      props.row.updatedAt,
      'MM/DD/YYYY HH:mm:ss'
    ),
    whitelabelId: (
      // Replace Button with the Link below once feature is ready for release
      // <Link
      //   color='secondary'
      //   component={RouterLink}
      //   to={`/ops/whitelabel-management/${props.row.whitelabelId}`}
      //   underline='hover'>
      //   {props.row[props.column.field]}
      // </Link>
      <Button onClick={onClick} variant='text'>
        {props.row[props.column.field]}
      </Button>
    )
  };

  const field =
    fieldComponents[props.column.field] || props.row[props.column.field];

  return (
    <StyledTableCell component='th' scope='row'>
      <div>{field}</div>
    </StyledTableCell>
  );
};
