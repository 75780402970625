import CopyToClipboard from '@/components/copy-to-clipboard';
import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import LinearLoading from '@/components/linear-loading';
import NavigationBreadcrumbs from '@/components/navigation-breadcrumbs';
import SimpleTabs, { SimpleTabsProps, TabData } from '@/components/simple-tabs';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import FundingSource from '@/models/ops/FundingSourceEnum.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import Conductor from '@/routes/ops/accounts/common/Conductor.component';
import { ConfirmFlatteningTradesDialog } from '@/routes/ops/accounts/common/ConfirmFlatteningTradesDialog.component';
import { Holdings } from '@/routes/ops/accounts/common/holdings/Holdings.component';
import SubAccountOrders from '@/routes/ops/accounts/common/SubAccountOrders.component';
import { CreateTransactionDialog } from '@/routes/ops/common/transactions/CreateTransactionDialog.component';
import { Transactions } from '@/routes/ops/common/transactions/Transactions.component';
import SubAccountsService from '@/services/ops/accounts/SubAccount.service';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import { unstable_useId as useId } from '@mui/utils';
import { useQuery } from '@tanstack/react-query';
import { AccountLevel } from '@vestwell-sub-accounting/models/accountsAndLedgers/AccountLevel';
import { isOperationalSubAccount } from '@vestwell-sub-accounting/models/common/SubAccountType';

import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { TransferCashDrawer } from '../common/TransferCashDrawer.component';

enum CreateAction {
  CashTransfer = 'CashTransfer',
  FlatteningTrades = 'FlatteningTrades',
  Transaction = 'Transaction'
}

const CreateMenu = ({
  setAction,
  isOperationalSubAccount
}: {
  setAction: (action: CreateAction) => void;
  isOperationalSubAccount: boolean;
}) => {
  const buttonId = useId();
  const menuId = useId();
  const [buttonEl, setButtonEl] = useState<HTMLButtonElement | null>(null);
  const [isOpen, setOpen] = useState(false);
  const hasFlattenPermissions = userService.hasPermission(
    FeatureLevelPermissions.WRITE_TRADE_REQUEST_EDIT
  );

  return (
    <>
      <Button
        aria-controls={isOpen ? menuId : undefined}
        aria-expanded={isOpen ? 'true' : undefined}
        aria-haspopup='true'
        aria-label='Create'
        endIcon={<KeyboardArrowDownIcon />}
        id={buttonId}
        onClick={() => setOpen(true)}
        ref={el => setButtonEl(el)}
        sx={{
          position: 'absolute',
          right: 0,
          top: 0
        }}
        variant='outlined'>
        Create
      </Button>
      <Menu
        MenuListProps={{
          'aria-labelledby': buttonId
        }}
        anchorEl={buttonEl}
        id={menuId}
        onClose={() => setOpen(false)}
        open={isOpen}>
        <MenuItem disabled sx={{ fontSize: 12, textTransform: 'uppercase' }}>
          For Sub Account
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAction(CreateAction.Transaction);
            setOpen(false);
          }}>
          Transaction
        </MenuItem>
        {hasFlattenPermissions && isOperationalSubAccount && (
          <MenuItem
            onClick={() => {
              setAction(CreateAction.FlatteningTrades);
              setOpen(false);
            }}>
            Flattening Trades
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            setAction(CreateAction.CashTransfer);
            setOpen(false);
          }}>
          Cash Transfer
        </MenuItem>
      </Menu>
    </>
  );
};

type SubAccountsDetailRouteProps = {
  subAccountId: string;
  parentAccountId: string;
};

export const SubAccountsDetailRoute: React.FunctionComponent = () => {
  const { parentAccountId, subAccountId } =
    useParams<SubAccountsDetailRouteProps>();
  const [action, setAction] = useState<CreateAction | null>(null);

  const hasAccountPermissions = userService.hasPermission(
    FeatureLevelPermissions.READ_SUBA_ACCOUNTS
  );
  const hasCashTransferPermissions = userService.hasPermission(
    FeatureLevelPermissions.WRITE_SUBA_TRANSFER_CASH
  );

  const { data: subAccountQueryData, isLoading } = useQuery(
    ['SubAccountService.getById', subAccountId],
    () => SubAccountsService.getById(subAccountId),
    {
      enabled: hasAccountPermissions,
      staleTime: Infinity
    }
  );

  const tabElements: TabData[] = useMemo(
    // memo to prevent tabs being unloaded and reloaded when this component state changes
    () => [
      {
        component: <Holdings subAccountId={subAccountId} />,
        label: 'holdings',
        path: `/ops/accounts/${parentAccountId}/sub-accounts/${subAccountId}/holdings`
      },
      {
        component: <SubAccountOrders customDefaultFilters={{ subAccountId }} />,
        label: 'trading',
        path: `/ops/accounts/${parentAccountId}/sub-accounts/${subAccountId}/trading`
      },
      {
        component: (
          <Transactions
            accountId={subAccountId}
            accountLevel={AccountLevel.SubAccount}
            hideColumns={['accountId']}
          />
        ),
        label: 'transactions',
        path: `/ops/accounts/${parentAccountId}/sub-accounts/${subAccountId}/transactions`
      },
      {
        component: (
          <Conductor
            accountId={subAccountId}
            accountLevel={AccountLevel.SubAccount}
            subAccountType={subAccountQueryData?.accountType}
          />
        ),
        label: 'conductor',
        path: `/ops/accounts/${parentAccountId}/sub-accounts/${subAccountId}/conductor`
      }
    ],
    [parentAccountId, subAccountId, subAccountQueryData]
  );

  if (!hasAccountPermissions) {
    return redirectToErrorPage(new Error('403'));
  }

  const tabs: SimpleTabsProps = {
    tabs: tabElements,
    tabsAriaLabel: 'accounts-tabs'
  };

  const parentName =
    subAccountQueryData?.parentAccount?.accountType === 'SuperOmnibus' ||
    subAccountQueryData?.parentAccount?.accountType === 'House'
      ? subAccountQueryData?.parentAccount?.accountName
      : subAccountQueryData?.parentAccount?.plan?.name;

  const name =
    subAccountQueryData?.accountType === 'participantStandard' ||
    subAccountQueryData?.accountType === 'participantOutsideInvestment' ||
    subAccountQueryData?.accountType === 'participantCash'
      ? `${formatters.displayCase(subAccountQueryData?.accountType)} - ${
          subAccountQueryData?.fundingSource
            ? FundingSource[
                subAccountQueryData?.fundingSource as keyof typeof FundingSource
              ]
            : ''
        }`
      : formatters.displayCase(subAccountQueryData?.accountType);

  const paths = [
    { name: 'Search Accounts', to: '/ops/accounts' },
    {
      name: parentName || '',
      to: `/ops/accounts/${parentAccountId}`
    }
  ];

  return (
    <>
      {isLoading && <LinearLoading />}
      {action === CreateAction.Transaction && subAccountQueryData && (
        <CreateTransactionDialog
          accountId={subAccountQueryData?.subAccountId}
          accountLevel={AccountLevel.SubAccount}
          custodianId={subAccountQueryData?.parentAccount?.custodianId}
          onClose={() => setAction(null)}
          open
          parentAccountId={subAccountQueryData?.parentAccount?.parentAccountId}
          parentAccountType={subAccountQueryData?.parentAccount?.accountType}
        />
      )}
      {action === CreateAction.FlatteningTrades && subAccountQueryData && (
        <ConfirmFlatteningTradesDialog
          onClose={() => setAction(null)}
          open
          subAccountId={subAccountId}
          subAccountName={name}
        />
      )}
      {hasCashTransferPermissions &&
        action === CreateAction.CashTransfer &&
        subAccountQueryData && (
          <TransferCashDrawer
            onClose={() => setAction(null)}
            onSubmit={() => setAction(null)}
            open
            parentAccountId={
              subAccountQueryData?.parentAccount?.parentAccountId
            }
            planId={subAccountQueryData?.planId}
            subAccount={subAccountQueryData}
          />
        )}
      {!isLoading && (
        <Box position='relative'>
          <Box
            data-testid='sub-account-detail-header'
            display='flex'
            justifyContent='space-between'>
            <NavigationBreadcrumbs
              data-testid='sub-account-detail-breadcrumbs'
              paths={paths}
            />
          </Box>
          <Typography role='heading' variant='h4'>
            {name}
          </Typography>
          <Box alignItems='center' display='flex' justifyContent='left'>
            <Typography data-testid='detail_id' variant='subtitle1'>
              ID: {subAccountQueryData?.subAccountId}
            </Typography>

            <CopyToClipboard
              copyName={`Sub Account ID `}
              copyValue={String(subAccountQueryData?.subAccountId)}
            />
          </Box>
          <TextStack
            divider
            id='sub-account-detail-header-fields'
            rowColumnWidth='dynamic'
            sx={{ m: '30px 0', p: 0 }}>
            <>
              <TextStackItem>
                <TextLabel>VW Core Account ID</TextLabel>
                <TextValue>{subAccountQueryData?.coreAccountId}</TextValue>
              </TextStackItem>

              <TextStackItem>
                <TextLabel>Plan</TextLabel>
                <TextValue
                  links={
                    subAccountQueryData?.parentAccount?.accountType !==
                      'SuperOmnibus' &&
                    subAccountQueryData?.parentAccount?.accountType !==
                      'House' && [
                      {
                        target: '_blank',
                        to: `/plans/${subAccountQueryData?.planId}/plan`
                      }
                    ]
                  }>
                  {subAccountQueryData?.parentAccount?.plan?.name}
                </TextValue>
              </TextStackItem>

              {subAccountQueryData?.investorId && (
                <TextStackItem>
                  <TextLabel>Participant</TextLabel>
                  <TextValue
                    copyLabel={`ID: ${subAccountQueryData?.investorId}`}
                    copyValue={String(subAccountQueryData?.investorId)}
                    copyable
                    links={[
                      {
                        target: '_blank',
                        to: `/participants/${subAccountQueryData?.investorId}/personal`
                      }
                    ]}>
                    {`${subAccountQueryData?.participant?.firstName} ${subAccountQueryData?.participant?.lastName}`}
                  </TextValue>
                </TextStackItem>
              )}
              <TextStackItem>
                <TextLabel>Funding Source</TextLabel>
                <TextValue>
                  {subAccountQueryData?.fundingSource
                    ? FundingSource[
                        subAccountQueryData?.fundingSource as keyof typeof FundingSource
                      ]
                    : null}
                </TextValue>
              </TextStackItem>

              <TextStackItem>
                <TextLabel>Created</TextLabel>
                <TextValue>
                  {subAccountQueryData?.createdAt
                    ? formatters.formatFromIsoDate(
                        subAccountQueryData?.createdAt
                      )
                    : null}
                </TextValue>
              </TextStackItem>
            </>
          </TextStack>
          <CreateMenu
            isOperationalSubAccount={isOperationalSubAccount(
              subAccountQueryData?.accountType
            )}
            setAction={setAction}
          />
          <SimpleTabs data-testid='sub-accounts-detail-tabs' {...tabs} />
        </Box>
      )}
    </>
  );
};

export default SubAccountsDetailRoute;
