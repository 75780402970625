import { Order } from '@/components/collapsible-table';

export interface MessageData {
  to: string[];
  templateData: Record<string, any>;
}

export interface Definition {
  name: string;
  config: DefinitionConfig;
}

export interface DefinitionConfig {
  subject: string;
}

export interface PlanCommunications {
  messages: Message[];
  meta: {
    offset: number;
    perPage: number;
    total: number;
  };
}

export interface Message {
  attempts: number;
  email: string;
  entityType?: string;
  entityId?: number;
  eventType: string;
  firstName?: string;
  id: number;
  initiator: {
    idType: string;
    id: string;
    env: string;
  };
  lastName?: string;
  messageId: string;
  sendDate: string;
  templateName: string;
  subject: string;
}

export interface GetCommunicationsParams {
  limit: number;
  offset: number;
  filter?: string;
  order?: string;
  category?: string[];
  status?: string[];
  startDate?: string | null;
  endDate?: string | null;
}

export interface CommunicationFilters {
  order?: Order;
  filter?: string;
  category?: string[];
  status?: string[];
  startDate?: string;
  endDate?: string;
}

export const initialCommunicationFilters = {
  category: [],
  endDate: null,
  filter: '',
  order: 'desc' as Order,
  startDate: null,
  status: []
};

export type MailerLettersDto = {
  participantId: number;
  firstName: string;
  lastName: string;
  letterId: string;
  description: string;
  letterEventType: string;
  createdAt: string;
  eventCreatedAt: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
};

export type MailerLetters = MailerLettersDto & {
  mailType: string;
  status: string;
};

export type GetPlanParticipantsMailerLettersParams = {
  limit: number;
  offset: number;
  filter?: string | number;
  order?: string;
  letterEventType?: string;
  startDate?: string;
  endDate?: string;
};

export type PlanParticipantsMailerLetters = {
  messages: MailerLettersDto[];
  meta: {
    offset: number;
    perPage: number;
    total: number;
  };
};
