import CopyToClipboard from '@/components/copy-to-clipboard';
import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import OpenInNewIcon from '@/components/icon/OpenInNewIcon';
import LinearLoading from '@/components/linear-loading';
import NavigationBreadcrumbs from '@/components/navigation-breadcrumbs';
import SimpleTabs, { TabData } from '@/components/simple-tabs';
import { useParentAccountById } from '@/hooks/ops/useParentAccountById.hook';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import Conductor from '@/routes/ops/accounts/common/Conductor.component';
import { CreateAchRequestDialog } from '@/routes/ops/accounts/common/CreateAchRequestDialog.component';
import { Holdings } from '@/routes/ops/accounts/common/holdings/Holdings.component';
import { SearchReconExceptions } from '@/routes/ops/common/SearchReconExceptions.component';
import { CreateTransactionDialog } from '@/routes/ops/common/transactions/CreateTransactionDialog.component';
import { Transactions } from '@/routes/ops/common/transactions/Transactions.component';
import { userService } from '@/services/User.service';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Button,
  Link,
  Menu,
  MenuItem,
  Stack,
  Typography
} from '@mui/material';
import { unstable_useId as useId } from '@mui/utils';
import { AccountLevel } from '@vestwell-sub-accounting/models/accountsAndLedgers/AccountLevel';
import { ParentAccountType } from '@vestwell-sub-accounting/models/common/ParentAccountType';

import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { SearchAlerts } from '../../common/SearchAlerts.component';
import ParentAccountOrders from '../common/ParentAccountOrders.component';
import { TransferCashDrawer } from '../common/TransferCashDrawer.component';
import { AccountDetailsHeader } from './components/AccountDetailsHeader.component';
import { PlansTab } from './components/PlansTab.component';
import { SubAccountsTab } from './components/SubAccountsTab.component';
import { useAccountsDetailRouteParams } from './useAccountsDetailRouteParams.hook';

enum CreateAction {
  AchRequest = 'AchRequest',
  CashTransfer = 'CashTransfer',
  Transaction = 'Transaction'
}

const CreateMenu = ({
  setAction,
  hasCashTransferPermissions,
  hasInitiateAchPermissions
}: {
  setAction: (action: CreateAction) => void;
  hasCashTransferPermissions: boolean;
  hasInitiateAchPermissions: boolean;
}) => {
  const buttonId = useId();
  const menuId = useId();
  const [buttonEl, setButtonEl] = useState<HTMLButtonElement | null>(null);
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <Button
        aria-controls={isOpen ? menuId : undefined}
        aria-expanded={isOpen ? 'true' : undefined}
        aria-haspopup='true'
        aria-label='Create'
        endIcon={<KeyboardArrowDownIcon />}
        id={buttonId}
        onClick={() => setOpen(true)}
        ref={el => setButtonEl(el)}
        sx={{
          position: 'absolute',
          right: 0,
          top: 0
        }}
        variant='outlined'>
        Create
      </Button>
      <Menu
        MenuListProps={{
          'aria-labelledby': buttonId
        }}
        anchorEl={buttonEl}
        id={menuId}
        onClose={() => setOpen(false)}
        open={isOpen}>
        <MenuItem disabled sx={{ fontSize: 12, textTransform: 'uppercase' }}>
          For Parent Account
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAction(CreateAction.Transaction);
            setOpen(false);
          }}>
          Transaction
        </MenuItem>
        {hasInitiateAchPermissions && (
          <MenuItem
            onClick={() => {
              setAction(CreateAction.AchRequest);
              setOpen(false);
            }}>
            ACH Request
          </MenuItem>
        )}
        {hasCashTransferPermissions && (
          <MenuItem
            onClick={() => {
              setAction(CreateAction.CashTransfer);
              setOpen(false);
            }}>
            Cash Transfer
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

type AccountsDetailRouteProps = {
  accountId: string;
};

export const AccountsDetailRoute: FC = () => {
  const { accountId } = useParams<AccountsDetailRouteProps>();
  const routeParams = useAccountsDetailRouteParams();
  const [action, setAction] = useState<CreateAction | null>(null);
  const getParentAccountByIdQuery = useParentAccountById({
    accountId
  });

  const hasAccountPermissions = userService.hasPermission(
    FeatureLevelPermissions.READ_SUBA_ACCOUNTS
  );
  const hasInitiateAchPermissions = userService.hasPermission(
    FeatureLevelPermissions.WRITE_SUBA_INITIATE_ACH
  );
  const hasCashTransferPermissions = userService.hasPermission(
    FeatureLevelPermissions.WRITE_SUBA_TRANSFER_CASH
  );

  const tabElements: TabData[] = useMemo(
    // memo to prevent tabs being unloaded and reloaded when this component state changes
    () =>
      [
        getParentAccountByIdQuery.data?.accountType ===
          ParentAccountType.SuperOmnibus && {
          component: <PlansTab />,
          label: 'plans',
          path: `/ops/accounts/${accountId}/plans`
        },
        {
          component: <SubAccountsTab parentAccountId={routeParams.accountId} />,
          label: 'sub accounts',
          path: `/ops/accounts/${accountId}/sub-accounts`
        },
        {
          component: <Holdings parentAccountId={accountId} />,
          label: 'holdings',
          path: `/ops/accounts/${accountId}/holdings`
        },
        {
          component: <ParentAccountOrders parentAccountId={accountId} />,
          label: 'trading',
          path: `/ops/accounts/${accountId}/trading`
        },
        {
          component: <Transactions accountId={accountId} />,
          label: 'transactions',
          path: `/ops/accounts/${accountId}/transactions`
        },
        {
          component: <Conductor accountId={accountId} />,
          label: 'conductor',
          path: `/ops/accounts/${accountId}/conductor`
        },
        {
          component: <SearchAlerts parentAccountId={accountId} />,
          label: 'alerts',
          path: `/ops/accounts/${accountId}/alerts`
        },
        {
          component: <SearchReconExceptions parentAccountId={accountId} />,
          label: 'recon',
          path: `/ops/accounts/${accountId}/recon`
        }
      ].filter(Boolean),
    [accountId, getParentAccountByIdQuery.data, routeParams.accountId]
  );

  if (!hasAccountPermissions) {
    return redirectToErrorPage(new Error('403'));
  }

  const name =
    getParentAccountByIdQuery.data?.accountType === 'SuperOmnibus' ||
    getParentAccountByIdQuery.data?.accountType === 'House'
      ? getParentAccountByIdQuery.data?.accountName
      : getParentAccountByIdQuery.data?.plan?.name;

  const paths = [{ name: 'Search Accounts', to: '/ops/accounts' }];

  if (getParentAccountByIdQuery.isInitialLoading) return <LinearLoading />;

  return (
    <>
      {action === CreateAction.Transaction &&
        getParentAccountByIdQuery.data && (
          <CreateTransactionDialog
            accountId={accountId}
            accountLevel={AccountLevel.ParentAccount}
            custodianId={getParentAccountByIdQuery.data.custodianId}
            onClose={() => setAction(null)}
            open
            parentAccountType={getParentAccountByIdQuery.data.accountType}
          />
        )}

      {hasInitiateAchPermissions &&
        action === CreateAction.AchRequest &&
        getParentAccountByIdQuery.data && (
          <CreateAchRequestDialog
            accountId={accountId}
            onClose={() => setAction(null)}
            open
            parentAccountId={getParentAccountByIdQuery.data.parentAccountId}
            planId={getParentAccountByIdQuery.data.planId}
          />
        )}

      {hasCashTransferPermissions &&
        action === CreateAction.CashTransfer &&
        getParentAccountByIdQuery.data && (
          <TransferCashDrawer
            onClose={() => setAction(null)}
            onSubmit={() => setAction(null)}
            open
            parentAccountId={getParentAccountByIdQuery.data.parentAccountId}
            planId={getParentAccountByIdQuery.data.planId}
          />
        )}

      {!getParentAccountByIdQuery.isInitialLoading &&
        getParentAccountByIdQuery.data && (
          <Stack gap={4} position='relative'>
            <Stack>
              <Box
                data-testid='account-detail-header'
                display='flex'
                justifyContent='space-between'>
                <NavigationBreadcrumbs
                  data-testid='account-detail-breadcrumbs'
                  paths={paths}
                />
              </Box>
              <Typography role='heading' variant='h4'>
                {name}
              </Typography>
              <Stack direction='row' spacing={2}>
                <Stack alignItems='flex-end' direction='row'>
                  <Typography data-testid='detail_id' variant='subtitle1'>
                    ID: {getParentAccountByIdQuery.data?.parentAccountId}
                  </Typography>
                  <CopyToClipboard
                    copyName={`Account ID `}
                    copyValue={String(
                      getParentAccountByIdQuery.data?.parentAccountId
                    )}
                  />
                </Stack>
                <Stack alignItems='flex-end' direction='row'>
                  <Typography data-testid='detail_id' variant='subtitle1'>
                    View plan details:
                  </Typography>
                  <Link
                    href={`/plans/${getParentAccountByIdQuery.data?.planId}/plan`}
                    target='_blank'>
                    <OpenInNewIcon fontSize='inherit' />
                  </Link>
                </Stack>
              </Stack>
            </Stack>
            <AccountDetailsHeader accountId={accountId} />

            <CreateMenu
              hasCashTransferPermissions={hasCashTransferPermissions}
              hasInitiateAchPermissions={hasInitiateAchPermissions}
              setAction={setAction}
            />

            <SimpleTabs
              data-testid='accounts-detail-tabs'
              tabs={tabElements}
              tabsAriaLabel='accounts-tabs'
            />
          </Stack>
        )}
    </>
  );
};
