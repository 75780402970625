import CollapsibleTable from '@/components/collapsible-table';
import { ForfeiturePendingTransactionsTableCell } from '@/routes/plans/plan-detail/PlanForfeituresTab/ForfeiturePendingTransactionsTableCell.component';
import { PlanService } from '@/services/Plan.service';
import { Paper, styled, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { FC } from 'react';

interface ForfeiturePendingTransactionsProps {
  sponsorPlanId: number;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(4),
  width: '100%'
}));

const Header = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  width: '100%'
}));

const columns = [
  { field: 'createdAt', headerName: 'Date', width: 130 },
  { field: 'type', headerName: 'Descritpion', width: 130 },
  { field: 'amount', headerName: 'Amount', width: 130 },
  { field: 'ucid', headerName: 'UCID', width: 390 }
];

export const ForfeiturePendingTransactions: FC<
  ForfeiturePendingTransactionsProps
> = props => {
  const trackerQuery = useQuery(
    ['PlanService.getForfeitureTrackerData', props.sponsorPlanId],
    () => PlanService.getForfeitureTrackerData(props.sponsorPlanId),
    {
      enabled: Boolean(props.sponsorPlanId),
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      select: data => {
        return data.data
          .filter(item => item.data?.attributes?.status === 'NEW')
          .map(item => ({
            ...item.data?.attributes,
            sponsorPlanId: props.sponsorPlanId
          }));
      }
    }
  );

  return (
    !!trackerQuery.data?.length && (
      <StyledPaper
        data-testid='forfeiturePendingTransactions'
        elevation={0}
        variant='outlined'>
        <Header>
          <Typography variant='h5'>Pending Transactions</Typography>
        </Header>
        <CollapsibleTable
          cellComponent={ForfeiturePendingTransactionsTableCell}
          columns={columns}
          data-testid='forfeiturePendingTransactionsTable'
          disablePagination
          isLoading={trackerQuery.isLoading}
          rootPaperElevation={0}
          tableData={trackerQuery.data || []}
          useDivAsBackground
        />
      </StyledPaper>
    )
  );
};
