import { AgGridEditorContext } from '@/components/ag-grid/AgGridEditorContext';
import { EditorComponentProps } from '@/components/ag-grid/AgGridTypes';
import { red } from '@mui/material/colors';

import { ColDef } from 'ag-grid-community';
import { ElementType, useCallback, useContext, useRef } from 'react';

export function AgGridEditor(config: ColDef, Component: ElementType): ColDef {
  const EditorComponent = (props: EditorComponentProps) => {
    const ctx = useContext(AgGridEditorContext);
    const inputRef = useRef();

    const onChange = useCallback(
      (value: string, stopCellEditing: boolean) => {
        ctx?.setValue(props.data?.uuid, props.colDef?.field, value);

        if (stopCellEditing) {
          props.api?.stopEditing();
        }
      },
      [props.colDef, props.data?.uuid, ctx]
    );

    return <Component {...props} onChange={onChange} ref={inputRef} />;
  };

  EditorComponent.displayName = 'AgGridEditor';

  return {
    ...config,
    cellEditor: EditorComponent,
    cellStyle: params => {
      const errorKey = `[${params.data?.uuid}].${params.colDef?.field}`;
      const error = params.context?.errors?.[errorKey]?.[0];

      return {
        'background-color': error ? red[50] : '',
        ...config.cellStyle
      };
    }
  };
}
