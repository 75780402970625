import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { AdvisorDto, FirmDto } from '@/models';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import React from 'react';

interface AdvisorDetailsInfoProps {
  advisor: AdvisorDto['data'];
  firm: FirmDto['data'];
}

const useStyles = makeStyles((theme: Theme) => ({
  fieldLabel: {
    color: theme.palette.text.secondary
  }
}));

const AdvisorDetailsInfo: React.FC<AdvisorDetailsInfoProps> = ({
  advisor = {},
  firm = {}
}) => {
  const classes = useStyles();

  return (
    <Grid container data-testid='advisor-details-info'>
      <Grid
        border='1px solid rgba(0, 0, 0, 0.12)'
        borderRadius='0.25rem 0.25rem'
        item
        lg={8}
        mb='2rem'
        xs={12}>
        <Grid container direction='column'>
          <Grid borderBottom='1px solid rgba(0, 0, 0, 0.12)' item p='1rem'>
            <Typography variant='h5'>Advisor Info</Typography>
          </Grid>

          <Grid container px='1.5rem' py='2rem'>
            <Grid item md={6} xs={12}>
              <Grid container direction='column'>
                <Grid container mb='0.5rem'>
                  <Grid item xs={4}>
                    <Typography
                      className={classes.fieldLabel}
                      data-testid='advisor-details-info-first-name-label'
                      variant='body1'>
                      First Name
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      data-testid='advisor-details-info-first-name-value'
                      variant='body1'>
                      {advisor?.attributes?.firstName ||
                        EMPTY_FIELD_PLACEHOLDER}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container mb='0.5rem'>
                  <Grid item xs={4}>
                    <Typography
                      className={classes.fieldLabel}
                      data-testid='advisor-details-info-last-name-label'
                      variant='body1'>
                      Last Name
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      data-testid='advisor-details-info-last-name-value'
                      variant='body1'>
                      {advisor?.attributes?.lastName || EMPTY_FIELD_PLACEHOLDER}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={4}>
                    <Typography
                      className={classes.fieldLabel}
                      data-testid='advisor-details-info-crd-label'
                      variant='body1'>
                      CRD #
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      data-testid='advisor-details-info-crd-value'
                      variant='body1'>
                      {advisor.attributes?.crdNumber || EMPTY_FIELD_PLACEHOLDER}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={6} xs={12}>
              <Grid container direction='column'>
                <Grid container mb='0.5rem'>
                  <Grid item xs={4}>
                    <Typography
                      className={classes.fieldLabel}
                      data-testid='advisor-details-info-email-label'
                      variant='body1'>
                      Email
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      data-testid='advisor-details-info-email-value'
                      variant='body1'>
                      {advisor.attributes?.email || EMPTY_FIELD_PLACEHOLDER}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container mb='0.5rem'>
                  <Grid item xs={4}>
                    <Typography
                      className={classes.fieldLabel}
                      data-testid='advisor-details-info-phone-label'
                      variant='body1'>
                      Phone
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      data-testid='advisor-details-info-phone-value'
                      variant='body1'>
                      {advisor.attributes?.phone || EMPTY_FIELD_PLACEHOLDER}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={4}>
                    <Typography
                      className={classes.fieldLabel}
                      data-testid='advisor-details-info-firm-label'
                      variant='body1'>
                      Firm
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography
                      data-testid='advisor-details-info-firm-value'
                      variant='body1'>
                      {firm.attributes?.companyName || EMPTY_FIELD_PLACEHOLDER}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdvisorDetailsInfo;
