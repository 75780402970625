import { useQueryClient } from '@tanstack/react-query';

import { uniqBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

interface UseShowMoreReturnType<DataReturnType> {
  data?: DataReturnType[];
  lastDataChunk?: DataReturnType[];
  isPaginated: boolean;
  isLoading: boolean;
  isPaginationLoading: boolean;
  showMore: () => Promise<void>;
  page: number;
  isError: boolean;
}

const useShowMore = <QueryReturnType, DataReturnType>(
  queryKey: any[],
  queryFunction: (page: number) => Promise<QueryReturnType>,
  enabled: boolean,
  getDataArrayFormat: (data: QueryReturnType) => any[],
  getIsPaginatedFormat: (data: QueryReturnType) => boolean,
  uniqueId?: string
): UseShowMoreReturnType<DataReturnType> => {
  const [page, setPage] = useState(1);
  const [data, setData] = useState<DataReturnType[]>();
  const [lastDataChunk, setLastDataChunk] = useState<DataReturnType[]>();
  const [isPaginated, setIsPaginated] = useState(false);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const queryClient = useQueryClient();

  const fetchData = useCallback(
    async (
      pageNumber: number,
      pastData: DataReturnType[] | undefined,
      forceSearch?: boolean
    ) => {
      if (
        (!isPaginationLoading &&
          !isLoading &&
          (page === 1 || isPaginated) &&
          !isError) ||
        forceSearch
      ) {
        let queryData: QueryReturnType;
        try {
          queryData = await queryClient.fetchQuery<QueryReturnType>(
            [...queryKey, pageNumber],
            () => queryFunction(pageNumber),
            {
              staleTime: Infinity
            }
          );
          setIsPaginated(getIsPaginatedFormat(queryData));
          const newChunk = [...getDataArrayFormat(queryData)];
          let newData = pastData ? [...pastData, ...newChunk] : [...newChunk];
          if (uniqueId) {
            newData = uniqBy(newData, uniqueId);
          }
          setData([...newData]);
          setLastDataChunk([...newChunk]);
          setIsLoading(false);
          setIsPaginationLoading(false);
        } catch (e: any) {
          // Fix for WM3-2614 - TODO: Improve typing on `e`
          setIsError(e.message === 'canceled' ? false : true);
          setIsLoading(e.message === 'canceled' ? true : false);
          setData(undefined);
          setLastDataChunk(undefined);
          setIsPaginationLoading(false);
        }
      }
    },
    [
      getDataArrayFormat,
      getIsPaginatedFormat,
      isError,
      isLoading,
      isPaginated,
      isPaginationLoading,
      page,
      queryClient,
      queryFunction,
      queryKey,
      uniqueId
    ]
  );

  useEffect(() => {
    setIsError(false);
    if (enabled) {
      setIsLoading(true);
      setPage(1);
      fetchData(1, undefined, true);
    } else {
      setData(undefined);
      setLastDataChunk(undefined);
      setIsPaginated(false);
    }
    /*
    I just want this hook to run when only and just only when the queryKey changes,
    so it refresh the data and reset the page number
    */
  }, [JSON.stringify(queryKey)]);

  const showMore = useCallback(async () => {
    setIsPaginationLoading(true);
    await fetchData(page + 1, data);
    setPage(page + 1);
  }, [data, fetchData, page]);

  return {
    data,
    isError,
    isLoading,
    isPaginated,
    isPaginationLoading,
    lastDataChunk,
    page,
    showMore
  };
};

export default useShowMore;
