import { CellComponentProps } from '@/components/collapsible-table';
import JSONViewer from '@/components/json-viewer';
import formatters from '@/utils/Formatters';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TableCell
} from '@mui/material';

import React from 'react';
import { useToggle } from 'react-use';

const LoanTradeRequestsTableCell: React.FunctionComponent<
  CellComponentProps
> = (props: CellComponentProps) => {
  const { row, column } = props;
  const [open, toggleOpen] = useToggle(false);

  let field = <Box>{row[column.field] || '--'}</Box>;
  if (column.field === 'createdAt' || column.field === 'updatedAt') {
    field = (
      <Box>
        {formatters.formatFromIsoDateCustom(row[column.field], 'M/DD/YY')}
      </Box>
    );
  } else if (column.field === 'action') {
    field = (
      <Box>
        <Button onClick={toggleOpen}>View Suba Result</Button>
      </Box>
    );
  }

  return (
    <>
      <TableCell component='th' scope='row'>
        <Box sx={{ '& .MuiBox-root': { fontSize: theme => theme.spacing(2) } }}>
          {field}
        </Box>
      </TableCell>
      <Dialog onClose={toggleOpen} open={open}>
        <DialogTitle>Suba Result</DialogTitle>
        <DialogContent>
          <JSONViewer json={row.subaResult} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LoanTradeRequestsTableCell;
