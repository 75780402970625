export const GLOBAL_SEARCH_RESULT_PAGE_SIZE = 5;

export const GLOBAL_SEARCH_PARTICIPANTS_PLANS_TYPE = 2;
export const GLOBAL_SEARCH_RESULT_PLANS_TYPE = 3;
export const GLOBAL_SEARCH_RESULT_POOLED_PLANS_TYPE = 22;
export const GLOBAL_SEARCH_ADVISORS_TYPE = 4;
export const GLOBAL_SEARCH_SPONSORS_TYPE = 10;
export const GLOBAL_SEARCH_PROGRAMS_TYPE = 16;
export const GLOBAL_SEARCH_TARGET_SERIES_TYPE = 17;
export const GLOBAL_SEARCH_RISK_SERIES_TYPE = 18;
export const GLOBAL_SEARCH_FUND_LINEUPS_TYPE = 19;
export const GLOBAL_SEARCH_GOAL_SERIES_TYPE = 20;
