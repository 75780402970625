import { Order } from '@/components/collapsible-table';
import { ModelSeries } from '@/models/ops/investments/ModelSeries.model';
import ApiService from '@/services/Api.service';

type ModelSeriesListDto = { data: ModelSeries[]; meta: { count: number } };

class ModelSeriesService {
  static async getModelSeriesPage(
    pageNumber: number,
    pageSize: number,
    query: string,
    orderBy: string,
    order: Order
  ): Promise<ModelSeriesListDto> {
    const sort = (order === 'asc' ? '' : '-').concat(orderBy);
    return ApiService.getJson('/investments/model-series', {
      pageNumber,
      pageSize,
      query,
      sort
    }) as unknown as ModelSeriesListDto;
  }
}

export type { ModelSeriesListDto };
export { ModelSeriesService };
