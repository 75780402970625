import Badge from '@/components/badge';
import Card, { CardContent } from '@/components/card';
import Link from '@/components/link/Link.component';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { useSnackbar } from '@/contexts/SnackBarContext';
import ParentAccountService from '@/services/ops/accounts/ParentAccount.service';
import formatters from '@/utils/Formatters';
import { Divider } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { SetupStatuses } from '@vestwell-sub-accounting/models/conductor/SetupStatuses';

import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { FC, useContext } from 'react';

import { AlertContext } from '../../AlertContext';
import { CardHeader } from '../common/CardHeader.component';

export const CustodianDestinationRejectHistory: FC = () => {
  const alert = useContext(AlertContext);

  const { showSnackbar } = useSnackbar();
  const getParentAccountQuery = useQuery(
    [
      'ParentAccountService.get',
      alert.details.trade?.custodianAccountNumber,
      alert.details.trade?.custodianId
    ],
    () =>
      ParentAccountService.get({
        custodianAccountNumber: alert.details.trade.custodianAccountNumber,
        custodianId: alert.details.trade.custodianId
      }),
    {
      enabled:
        !!alert.details.trade?.custodianAccountNumber &&
        !!alert.details.trade?.custodianId,
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Failed getting Parent account: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  const getCustodianSetupHistoryQuery = useQuery(
    [
      'ParentAccountService.searchInvestmentUpdateQueue',
      getParentAccountQuery.data?.parentAccountId
    ],
    async () => {
      const result = await ParentAccountService.searchInvestmentUpdateQueue({
        parentAccountId: getParentAccountQuery.data.parentAccountId
      });

      return result.results[0];
    },
    {
      enabled: !!getParentAccountQuery.data?.parentAccountId,
      onError: (err: AxiosError) => {
        const message = err.response?.data ? err.response.data : err.message;
        showSnackbar({
          message: `Failed getting security: ${message}`,
          severity: 'error'
        });
      }
    }
  );

  return (
    <Card data-testid='custodian-fund-setup-status-history-card'>
      <CardHeader title='Custodian Fund Setup Status History' />
      <Divider />
      <CardContent loading={getCustodianSetupHistoryQuery.isInitialLoading}>
        <TextStack direction='column' spacing={1}>
          <TextStackItem>
            <TextLabel>Fund Account Number</TextLabel>
            <TextValue data-testid='account-number-value'>
              {getCustodianSetupHistoryQuery.data?.fundAccountNumber}
            </TextValue>
          </TextStackItem>
          <TextStackItem>
            <TextLabel>Holding Status</TextLabel>
            <TextValue data-testid='holding-status-value'>
              <Badge
                color={
                  getCustodianSetupHistoryQuery.data?.holdingStatus === 'Active'
                    ? 'success'
                    : 'neutral'
                }
                maxWidth='fit-content'>
                {formatters.displayCase(
                  getCustodianSetupHistoryQuery.data?.holdingStatus
                )}
              </Badge>
            </TextValue>
          </TextStackItem>
          <TextStackItem>
            <TextLabel>Setup Status</TextLabel>
            <TextValue data-testid='setup-status-value'>
              <Badge
                color={
                  getCustodianSetupHistoryQuery.data?.setupStatus ===
                  SetupStatuses.confirmed
                    ? 'success'
                    : 'neutral'
                }
                maxWidth='fit-content'>
                {formatters.displayCase(
                  getCustodianSetupHistoryQuery.data?.setupStatus
                )}
              </Badge>
            </TextValue>
          </TextStackItem>
          <TextStackItem>
            <TextLabel>Request Notes</TextLabel>
            <TextValue data-testid='request-notes-value'>
              {getCustodianSetupHistoryQuery.data?.requestNotes}
            </TextValue>
          </TextStackItem>
          <TextStackItem>
            <TextLabel>Response Notes</TextLabel>
            <TextValue data-testid='response-notes-value'>
              {getCustodianSetupHistoryQuery.data?.responseNotes}
            </TextValue>
          </TextStackItem>
          <TextStackItem>
            <TextLabel>Tracer ID</TextLabel>
            <TextValue data-testid='tracer-id-value'>
              <Link
                target='_blank'
                to={`/ops/workflows/tracer:${getCustodianSetupHistoryQuery.data?.tracerId}`}>
                Tracer ID: {getCustodianSetupHistoryQuery.data?.tracerId}
              </Link>
            </TextValue>
          </TextStackItem>
          <TextStackItem>
            <TextLabel>Created</TextLabel>
            <TextValue data-testid='created-value'>
              {dayjs(getCustodianSetupHistoryQuery.data?.createdAt).format(
                'MM/DD/YYYY HH:mm:ss'
              )}
            </TextValue>
          </TextStackItem>
          <TextStackItem>
            <TextLabel>Last Updated</TextLabel>
            <TextValue data-testid='last-updated-value'>
              {dayjs(getCustodianSetupHistoryQuery.data?.updatedAt).format(
                'MM/DD/YYYY HH:mm:ss'
              )}
            </TextValue>
          </TextStackItem>
        </TextStack>
      </CardContent>
    </Card>
  );
};

CustodianDestinationRejectHistory.displayName =
  'CustodianDestinationRejectHistory';
