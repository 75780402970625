import DatePicker from '@/components/date-picker';
import PayrollIntegrationsService, {
  OpsToolingType
} from '@/services/payroll-integrations.service';
import ReactJson from '@microlink/react-json-view';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {
  Button,
  Drawer,
  Stack,
  Theme,
  Toolbar,
  Typography
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation } from '@tanstack/react-query';

import { FC, useCallback, useEffect, useRef, useState } from 'react';

type ErrorResponse = {
  response?: {
    data?:
      | string
      | {
          message: string;
          path: string;
          statusCode: number;
        };
  };
};

const useStyles = makeStyles((theme: Theme) => ({
  closedOutlineIcon: {
    color: theme.palette.grey[500]
  },
  datePicker: {
    marginRight: theme.spacing(2)
  },
  divJson: {
    whiteSpace: 'normal',
    wordBreak: 'break-all'
  },
  divLoading: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    padding: theme.spacing(2)
  },
  drawerPaperProps: {
    backgroundColor: theme.palette.grey[100],
    flexGrow: 0,
    flexShrink: 0,
    maxHeight: '50vh'
  },
  mainBar: {
    backgroundColor: theme.palette.grey[50],
    boxShadow: theme.shadows[1],
    marginBottom: 0.1,
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.drawer + 1
  },
  secondaryBar: {
    backgroundColor: theme.palette.grey[50],
    boxShadow: theme.shadows[1]
  },
  stack: {
    overflowY: 'auto'
  },
  typography: {
    flexGrow: 1,
    fontWeight: 'bold'
  }
}));

const toolingTypeToText = {
  [OpsToolingType.connectionStatus]: 'Connection Status',
  [OpsToolingType.companyInfo]: 'Company Data',
  [OpsToolingType.payGroupInfo]: 'Pay Schedules',
  [OpsToolingType.payrollInfo]: 'Payrolls',
  [OpsToolingType.employeeInfo]: 'Employees'
};

type OpsToolingWindowProps = {
  isDrawerOpen: boolean;
  toggleDrawerOpen: () => void;
  sponsorPlanId: number | null;
  toolingType: string | null;
  setOpsToolingHeight: (height: number) => void;
};

export const OpsToolingWindow: FC<OpsToolingWindowProps> = props => {
  const classes = useStyles();
  const drawerRef = useRef(null);
  const isPayrollTooling = props.toolingType === OpsToolingType.payrollInfo;
  const [selectedStartDate, setSelectedStartDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    new Date().toISOString().slice(0, 10)
  );

  const getOpsToolingResponse = useMutation<unknown, ErrorResponse>(
    [
      'PayrollIntegrationsService.getOpsToolingData',
      props.sponsorPlanId,
      props.toolingType,
      selectedStartDate,
      selectedEndDate,
      isPayrollTooling
    ],
    () => {
      return PayrollIntegrationsService.getOpsToolingData({
        ...(isPayrollTooling
          ? { endDate: selectedEndDate, startDate: selectedStartDate }
          : {}),
        sponsorPlanId: props.sponsorPlanId,
        toolingType: props.toolingType
      });
    }
  );

  const mutateOpsToolingResponse = useCallback(() => {
    if (props.isDrawerOpen) {
      if (isPayrollTooling) {
        getOpsToolingResponse.reset();
      } else {
        getOpsToolingResponse.mutate();
      }
    }
  }, [props.isDrawerOpen, isPayrollTooling, getOpsToolingResponse]);

  useEffect(mutateOpsToolingResponse, [props.isDrawerOpen, props.toolingType]);

  useEffect(() => {
    const updateHeight = () => {
      props.setOpsToolingHeight(
        drawerRef.current?.children?.[0]?.clientHeight || 0
      );
    };
    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, [isPayrollTooling, getOpsToolingResponse]);

  return (
    <Drawer
      ModalProps={{ disableEnforceFocus: true, disableScrollLock: true }}
      PaperProps={{ className: classes.drawerPaperProps }}
      anchor='bottom'
      onClose={props.toggleDrawerOpen}
      open={props.isDrawerOpen}
      ref={drawerRef}
      variant='persistent'>
      {props.isDrawerOpen && (
        <Stack className={classes.stack}>
          <Toolbar className={classes.mainBar}>
            <Typography
              className={classes.typography}
              color='black'
              data-testid='title'>
              Integrated Payroll System Data |{' '}
              {toolingTypeToText[props.toolingType] || ''}
            </Typography>
            <Button onClick={props.toggleDrawerOpen} variant='text'>
              <CloseOutlinedIcon className={classes.closedOutlineIcon} />
            </Button>
          </Toolbar>
          {isPayrollTooling && (
            <Toolbar className={classes.secondaryBar}>
              <DatePicker
                InputProps={{
                  autoComplete: 'off'
                }}
                className={classes.datePicker}
                data-testid='startDate'
                label='Start Date'
                name='startDate'
                onChange={(newValue: { target: { value: string | null } }) =>
                  setSelectedStartDate(newValue?.target?.value || null)
                }
                size='small'
                value={selectedStartDate}
                variant='outlined'
              />
              <DatePicker
                InputProps={{
                  autoComplete: 'off'
                }}
                className={classes.datePicker}
                data-testid='endDate'
                label='End Date'
                name='endDate'
                onChange={(newValue: { target: { value: string | null } }) =>
                  setSelectedEndDate(newValue?.target?.value || null)
                }
                size='small'
                value={selectedEndDate}
                variant='outlined'
              />
              <Button
                color='primary'
                data-testid='applyButton'
                onClick={() => getOpsToolingResponse.mutate()}
                variant='contained'>
                Apply
              </Button>
            </Toolbar>
          )}

          {getOpsToolingResponse.isLoading ? (
            <div className={classes.divLoading}>
              <CircularProgress />
            </div>
          ) : (
            (getOpsToolingResponse.data || getOpsToolingResponse.error) && (
              <div className={classes.divJson} data-testid='opsToolingResponse'>
                <ReactJson
                  displayDataTypes={false}
                  displayObjectSize={false}
                  enableClipboard={false}
                  indentWidth={7}
                  name={null}
                  src={
                    (getOpsToolingResponse.data ||
                      (typeof getOpsToolingResponse.error?.response?.data ===
                      'string'
                        ? {
                            message: getOpsToolingResponse.error.response.data
                          }
                        : getOpsToolingResponse.error?.response
                            ?.data)) as object
                  }
                  style={{ fontSize: '13px' }}
                  theme='bright:inverted'
                />
              </div>
            )
          )}
        </Stack>
      )}
    </Drawer>
  );
};
