export const colorByStatus: Record<
  string,
  | 'success'
  | 'neutral'
  | 'warning'
  | 'info'
  | 'lightWarning'
  | 'error'
  | undefined
> = {
  'Awaiting Funds': 'warning',
  Canceled: 'info',
  Complete: 'success',
  Expired: 'neutral'
};
