import Decimal from 'decimal.js';

/**
 * Sorts numeric values
 * null values sort as if larger than non-null values to match default Postgres ORDER BY behavior, see: https://www.postgresql.org/docs/current/queries-order.html
 */
export const numericComparator = (
  a: number | string | null,
  b: number | string | null
): number => {
  if (
    (typeof a !== 'number' && typeof a !== 'string' && a !== null) ||
    (typeof b !== 'number' && typeof b !== 'string' && b !== null)
  ) {
    return 0;
  }

  if (a === null && b === null) return 0;
  if (a === null && b !== null) return 1;
  if (b === null && a !== null) return -1;

  // redundant null check to satisfy TS
  if (a !== null && b !== null) {
    return new Decimal(a).comparedTo(new Decimal(b));
  }

  // redundant return to satisfy TS
  return 0;
};
