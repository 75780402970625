import {
  AgPromise,
  ICellEditorComp,
  ICellEditorParams
} from 'ag-grid-community';

interface NumericEditorParams extends ICellEditorParams {
  snackbar: any;
}

class NumericEditor implements ICellEditorComp {
  eInput!: HTMLInputElement;

  cancelBeforeStart!: boolean;

  snackbar!: any;

  // gets called once before the renderer is used
  init(params: NumericEditorParams): void | AgPromise<void> {
    const value = params.data[params.column.getColId()];
    // create the cell
    this.snackbar = params.snackbar;
    this.eInput = document.createElement('input');
    this.eInput.type = 'number';
    this.eInput.min = '0';
    this.eInput.max = '100';

    if (params.eventKey && this.isCharNumeric(params.eventKey)) {
      this.eInput.value = params.eventKey;
    } else {
      if (value !== undefined && value !== null) {
        this.eInput.value = value;
      }
    }

    this.eInput.addEventListener('keypress', event => {
      if (!this.isKeyPressedNumeric(event)) {
        this.eInput.focus();
        if (event.preventDefault) event.preventDefault();
      } else if (this.isKeyPressedNavigation(event)) {
        event.stopPropagation();
      }
    });

    // only start edit if key pressed is a number, not a letter
    const charPressIsNotANumber =
      params.eventKey && '1234567890.'.indexOf(params.eventKey) < 0;
    this.cancelBeforeStart = !!charPressIsNotANumber;
  }

  isKeyPressedNavigation(event: KeyboardEvent): boolean {
    return event.key === 'ArrowLeft' || event.key === 'ArrowRight';
  }

  // gets called once when grid ready to insert the element
  getGui(): HTMLElement {
    return this.eInput;
  }

  // focus and select can be done after the gui is attached
  afterGuiAttached(): void | AgPromise<void> {
    this.eInput.focus();
  }

  // returns the new value after editing
  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  isCancelAfterEnd(): boolean {
    const value = this.getValue();

    const isLessThan100 = !value || parseFloat(value) <= 100;
    const hasLessThanThreeDecimals = (value.split('.')[1]?.length || 0) < 3;

    if (!isLessThan100) {
      this.snackbar.showSnackbar({
        message: 'Allocation must be less than or equal to 100%',
        severity: 'error'
      });
    } else if (!hasLessThanThreeDecimals) {
      this.snackbar.showSnackbar({
        message: 'Allocation must have less than 3 decimals',
        severity: 'error'
      });
    }

    const isInvalid = !isLessThan100 || !hasLessThanThreeDecimals;
    return isInvalid;
  }

  // returns the new value after editing
  getValue(): string {
    return this.eInput.value;
  }

  // any cleanup we need to be done here
  destroy(): void {}

  isCharNumeric(charStr: string | null): string | boolean | null {
    return charStr && !!/\d/.test(charStr);
  }

  isKeyPressedNumeric(event: KeyboardEvent): string | boolean | null {
    const charStr = event.key;
    return (
      this.eInput.value.length < 5 &&
      // Allow one decimal point
      ((charStr === '.' &&
        (this.eInput.value.match(/,/g) || []).length === 0) ||
        //   Allow digits
        this.isCharNumeric(charStr))
    );
  }
}

export default NumericEditor;
