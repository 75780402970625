import Card, { CardContent, CardHeader } from '@/components/card';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import {
  CashDestination,
  RolloverDestination,
  WithdrawalDestinationDto
} from '@/models/WithdrawalsDTO.model';
import ParticipantService from '@/services/Participant.service';
import formatters from '@/utils/Formatters';
import {
  Divider,
  Unstable_Grid2 as Grid,
  Stack,
  Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { FC, useMemo } from 'react';

import AccountNumber from './AccountNumber.component';
import { WithdrawalAddress } from './WithdrawalAddress.component';

type WithdrawalDestinationsCardProps = {
  participantId: string;
  withdrawalId: string;
};

export const WithdrawalDestinationsCard: FC<WithdrawalDestinationsCardProps> = (
  props: WithdrawalDestinationsCardProps
) => {
  const withdrawalDestinationsQuery = useQuery<WithdrawalDestinationDto>(
    [
      'ParticipantService.getWithdrawalDestinations',
      props.participantId,
      props.withdrawalId
    ],
    () =>
      ParticipantService.getWithdrawalDestinations(
        props.participantId,
        +props.withdrawalId
      ),
    {
      enabled: !!props.participantId,
      staleTime: Infinity
    }
  );

  return (
    <>
      {withdrawalDestinationsQuery.data?.data && (
        <Card>
          <CardHeader title='Destinations' />
          <CardContent>
            <Stack
              direction='row'
              divider={<Divider flexItem orientation='vertical' />}
              spacing={2}>
              {(withdrawalDestinationsQuery.data?.data
                .preTaxRolloverDestination ||
                withdrawalDestinationsQuery.data?.data
                  .rothRolloverDestination) && (
                <Grid container xs={6}>
                  {withdrawalDestinationsQuery.data?.data
                    .preTaxRolloverDestination && (
                    <Grid>
                      <RolloverDelivery
                        data={
                          withdrawalDestinationsQuery.data.data
                            .preTaxRolloverDestination
                        }
                        type={
                          withdrawalDestinationsQuery.data.data
                            .isRothSameDestination
                            ? 'Both'
                            : 'Pre-Tax'
                        }
                      />
                    </Grid>
                  )}
                  {withdrawalDestinationsQuery.data?.data
                    .rothRolloverDestination && (
                    <RolloverDelivery
                      data={
                        withdrawalDestinationsQuery.data.data
                          .rothRolloverDestination
                      }
                      type='Roth'
                    />
                  )}
                </Grid>
              )}

              {withdrawalDestinationsQuery.data?.data.cashDestination && (
                <Grid>
                  <CashDelivery
                    data={withdrawalDestinationsQuery.data.data.cashDestination}
                  />
                </Grid>
              )}
            </Stack>
          </CardContent>
        </Card>
      )}
    </>
  );
};

type RolloverDeliveryProps = {
  data: RolloverDestination;
  type: 'Pre-Tax' | 'Roth' | 'Both';
};

const RolloverDelivery: FC<RolloverDeliveryProps> = (
  props: RolloverDeliveryProps
) => {
  const deliveryType = useMemo(() => {
    return props.data.deliveryType
      ? props.data.deliveryType
      : props.data.address1
        ? 'CHECK'
        : null;
  }, [props.data.deliveryType]);

  return (
    <TextStack direction='column'>
      <Typography sx={{ fontSize: 15, fontWeight: 500 }}>
        Rollover ({props.type === 'Both' ? 'Pre-Tax, Roth' : props.type})
      </Typography>
      <TextStackItem>
        <TextLabel>Delivery Method</TextLabel>
        <TextValue data-testid={`rollover-${props.type}-delivery-type`}>
          {deliveryType}
        </TextValue>
      </TextStackItem>
      <TextStackItem>
        <TextLabel>Requested Amount</TextLabel>
        <TextValue data-testid={`rollover-${props.type}-amount`}>
          {props.data.isFullAmount
            ? 'FULL'
            : formatters.formatDollars(props.data.amount)}
        </TextValue>
      </TextStackItem>
      <TextStackItem>
        <TextLabel>Destination</TextLabel>
        <TextValue data-testid={`rollover-${props.type}-destination`}>
          {props.data.destination}
        </TextValue>
      </TextStackItem>
      {['ACH', 'WIRE'].includes(deliveryType) && (
        <>
          <TextStackItem>
            <TextLabel>Bank Name</TextLabel>
            <TextValue data-testid={`rollover-${props.type}-bank-name`}>
              {props.data.bankName ?? null}
            </TextValue>
          </TextStackItem>
          <TextStackItem>
            <TextLabel>Routing Number</TextLabel>
            <TextValue data-testid={`rollover-${props.type}-routing-number`}>
              {props.data.routingNumber ?? null}
            </TextValue>
          </TextStackItem>
          {!!props.data.accountNumber && (
            <AccountNumber
              accountNumber={props.data.accountNumber}
              cardTitle='Account Number'
              isShowNumber={true}
            />
          )}
        </>
      )}
      {deliveryType === 'WIRE' && (
        <>
          <TextStackItem>
            <TextLabel>Credit To</TextLabel>
            <TextValue>{props.data.creditTo}</TextValue>
          </TextStackItem>
          <TextStackItem>
            <TextLabel>Further Credit To</TextLabel>
            <TextValue>{props.data.furtherCreditTo ?? null}</TextValue>
          </TextStackItem>
        </>
      )}
      {['CHECK', 'OVERNIGHT_CHECK'].includes(deliveryType) && (
        <WithdrawalAddress
          address={{
            address1: props.data.address1,
            address2: props.data.address2,
            city: props.data.city,
            memo: props.data.memo,
            payee: props.data.payee,
            state: props.data.state,
            zip: props.data.zip
          }}
          type={props.type === 'Roth' ? 'RolloverRoth' : 'RolloverPretax'}
        />
      )}
    </TextStack>
  );
};

type CashDeliveryProps = {
  data: CashDestination;
};

const CashDelivery: FC<CashDeliveryProps> = (props: CashDeliveryProps) => {
  return (
    <TextStack direction='column'>
      <Typography sx={{ fontSize: 15, fontWeight: 500 }}>
        Cash {props.data.afterTaxAmount && '(After-tax)'}
      </Typography>
      <TextStackItem>
        <TextLabel>Delivery Method</TextLabel>
        <TextValue data-testid='cash-delivery-type'>
          {props.data.deliveryType}
        </TextValue>
      </TextStackItem>
      {props.data.isFullAmount && (
        <TextStackItem>
          <TextLabel>Requested Amount</TextLabel>
          <TextValue data-testid='cash-amount-full'>FULL</TextValue>
        </TextStackItem>
      )}
      {!props.data.isFullAmount && props.data.partialPreTaxAmount && (
        <TextStackItem>
          <TextLabel>Pre-Tax Requested Amount</TextLabel>
          <TextValue data-testid='pretax-cash-amount'>
            {formatters.formatDollars(props.data.partialPreTaxAmount)}
          </TextValue>
        </TextStackItem>
      )}
      {!props.data.isFullAmount && props.data.partialRothAmount && (
        <TextStackItem>
          <TextLabel>Roth Requested Amount</TextLabel>
          <TextValue data-testid='roth-cash-amount'>
            {formatters.formatDollars(props.data.partialRothAmount)}
          </TextValue>
        </TextStackItem>
      )}
      {props.data.afterTaxAmount && (
        <TextStackItem>
          <TextLabel>After Tax Amount</TextLabel>
          <TextValue data-testid='aftertax-cash-amount'>
            {formatters.formatDollars(props.data.afterTaxAmount)}
          </TextValue>
        </TextStackItem>
      )}

      {['ACH', 'WIRE'].includes(props.data.deliveryType) && (
        <>
          <TextStackItem>
            <TextLabel>Bank Name</TextLabel>
            <TextValue data-testid='cash-bank-name'>
              {props.data.bankName ?? null}
            </TextValue>
          </TextStackItem>
          <TextStackItem>
            <TextLabel>Routing Number</TextLabel>
            <TextValue data-testid='cash-routing-number'>
              {props.data.routingNumber ?? null}
            </TextValue>
          </TextStackItem>
          {!!props.data.accountNumber && (
            <AccountNumber
              accountNumber={props.data.accountNumber}
              cardTitle='Account Number'
              isShowNumber={true}
            />
          )}
        </>
      )}
      {props.data.deliveryType === 'WIRE' && (
        <>
          <TextStackItem>
            <TextLabel>Credit To</TextLabel>
            <TextValue>{props.data.creditTo ?? null}</TextValue>
          </TextStackItem>
          <TextStackItem>
            <TextLabel>Further Credit To</TextLabel>
            <TextValue>{props.data.furtherCreditTo ?? null}</TextValue>
          </TextStackItem>
        </>
      )}
      {['CHECK', 'OVERNIGHT_CHECK'].includes(props.data.deliveryType) && (
        <WithdrawalAddress
          address={{
            address1: props.data.address1,
            address2: props.data.address2,
            city: props.data.city,
            state: props.data.state,
            zip: props.data.zip
          }}
          type='Cash'
        />
      )}
    </TextStack>
  );
};
