import { EsaPlanGroup } from '@/models/EsaPlanGroup.model';
import { NumericDollarFormat } from '@/routes/participants/participant-detail/NumericDollarFormat';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Unstable_Grid2 as Grid,
  InputAdornment,
  Stack,
  TextField
} from '@mui/material';

import { Field, Form, Formik, useFormikContext } from 'formik';
import { isEqual } from 'lodash';
import React, { useCallback } from 'react';

type ModalNumericFieldProps = {
  value: number | null;
  testId: string;
  label: string;
  name: string;
  helperText?: string;
};

const ModalNumericField: React.FC<ModalNumericFieldProps> = props => {
  const formik = useFormikContext();

  return (
    <Grid xs={6}>
      <FormControl fullWidth>
        <TextField
          InputProps={{
            inputComponent: NumericDollarFormat as any,
            startAdornment: <InputAdornment position='start'>$</InputAdornment>
          }}
          data-testid={props.testId}
          fullWidth={true}
          helperText={props.helperText}
          inputProps={{
            allowNegative: false,
            decimalScale: 0
          }}
          label={props.label}
          name={props.name}
          onChange={event =>
            formik.handleChange({
              target: { ...event.target, value: event.target.value || null }
            })
          }
          size='small'
          value={props.value}
        />
      </FormControl>
    </Grid>
  );
};

type EditGroupModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  initialValues: EsaPlanGroup;
  onSubmit: (updatedGroup: EsaPlanGroup) => void;
  isSubmitting?: boolean;
};

export const EditGroupModal: React.FC<EditGroupModalProps> = props => {
  const handleSubmit = useCallback(
    values => props.onSubmit(values),
    [props.onSubmit]
  );

  return (
    <Dialog
      data-testid='edit-employee-group-dialog'
      fullWidth
      maxWidth='sm'
      onClose={() => props.closeModal()}
      open={props.isOpen}>
      <DialogTitle>Edit Employee Group</DialogTitle>
      <Formik initialValues={props.initialValues} onSubmit={handleSubmit}>
        {formProps => {
          const areValuesSame = isEqual(formProps.values, props.initialValues);

          return (
            <Form>
              <DialogContent>
                <Stack gap={3}>
                  <FormControl fullWidth>
                    <Field
                      as={TextField}
                      data-testid='group-name'
                      label='Group Name'
                      name='name'
                      size='small'
                      variant='outlined'
                    />
                  </FormControl>

                  <Divider textAlign='left'>Employer Match</Divider>

                  <Grid container spacing={3}>
                    <ModalNumericField
                      helperText='Maximum amount per pay period'
                      label='Match Amount'
                      name='match.dollarAmount'
                      testId='matchAmount'
                      value={formProps.values.match.dollarAmount}
                    />
                    <ModalNumericField
                      helperText='Lifetime match amount'
                      label='Match Cap'
                      name='match.lifetimeAmount'
                      testId='matchCap'
                      value={formProps.values.match.lifetimeAmount}
                    />
                  </Grid>

                  <Divider textAlign='left'>Initial Deposit Bonus</Divider>

                  <Grid container spacing={3}>
                    <ModalNumericField
                      label='Match Amount'
                      name='initialBonus'
                      testId='matchAmount-deposit'
                      value={formProps.values.initialBonus}
                    />
                    <ModalNumericField
                      label='Minimum deposit required'
                      name='initialBonusThreshold'
                      testId='minimum-deposit-initial'
                      value={formProps.values.initialBonusThreshold}
                    />
                  </Grid>

                  <Divider textAlign='left'>Milestone Bonus</Divider>

                  <Grid container spacing={3}>
                    <ModalNumericField
                      label='Match Amount'
                      name='milestoneBonus.amount'
                      testId='matchAmount-milestone'
                      value={formProps.values.milestoneBonus.amount}
                    />
                    <ModalNumericField
                      label='Minimum deposit required'
                      name='milestoneBonus.threshold'
                      testId='minimum-deposit-milestone'
                      value={formProps.values.milestoneBonus.threshold}
                    />
                  </Grid>

                  <DialogActions>
                    <Button onClick={props.closeModal}>Cancel</Button>
                    <Button
                      color='primary'
                      disabled={areValuesSame || props.isSubmitting}
                      type='submit'
                      variant='contained'>
                      Update
                    </Button>
                  </DialogActions>
                </Stack>
              </DialogContent>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

EditGroupModal.displayName = 'EditGroupModal';
