import { CardContent } from '@/components/card';
import {
  DataTable,
  DataTableBadgeCell
} from '@/components/data-table/DataTable.component';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { DistributionRequestHoldingTankStatusColorMap } from '@/models/ops/distributions/DistributionRequestHoldingTankStatusColorMap.model';
import { WorkflowContext } from '@/routes/ops/conductor/common/WorkflowContext';
import { DistributionService } from '@/services/ops/distributions/Distribution.service';
import formatters from '@/utils/Formatters';
import { Box, Card } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { DistributionRequestQueueApiResource } from '@vestwell-sub-accounting/models/conductor/DistributionRequestQueueApiResource';

import { AxiosError } from 'axios';
import { useContext, useMemo } from 'react';

type RequestDetailCellRendererProps = {
  data: DistributionRequestQueueApiResource;
};

const RequestDetailCellRenderer = (props: RequestDetailCellRendererProps) => {
  return (
    <Box p={2}>
      <TextStack direction='column'>
        <TextStackItem>
          <TextLabel>Street Address</TextLabel>
          <TextValue>{props.data.participantAddress}</TextValue>
        </TextStackItem>
        <TextStackItem>
          <TextLabel>City</TextLabel>
          <TextValue>{props.data.participantCity}</TextValue>
        </TextStackItem>
        <TextStackItem>
          <TextLabel>State</TextLabel>
          <TextValue>{props.data.participantState}</TextValue>
        </TextStackItem>
        <TextStackItem>
          <TextLabel>ZIP</TextLabel>
          <TextValue>{props.data.participantZip}</TextValue>
        </TextStackItem>
        <TextStackItem>
          <TextLabel>Country</TextLabel>
          <TextValue>
            {props.data.participantForeignCountry === 'usa'
              ? 'USA'
              : props.data.participantForeignCountry}
          </TextValue>
        </TextStackItem>
      </TextStack>
    </Box>
  );
};

export const RequestTab = () => {
  const workflow = useContext(WorkflowContext);

  const detailCellRenderer = useMemo(() => {
    return RequestDetailCellRenderer;
  }, []);

  const distributionQuery = useQuery(
    ['DistributionService.getByTracerId', workflow.tracerId],
    () => DistributionService.getByTracerId(workflow.tracerId),
    {
      enabled: Boolean(workflow.tracerId),
      onError: (err: AxiosError) => {
        console.error(err.response?.data ? err.response.data : err.message);
      }
    }
  );

  return (
    <Card>
      <CardContent
        disablePadding
        overlayLoading={distributionQuery.isInitialLoading}>
        <DataTable
          columnDefs={[
            {
              cellRenderer: 'agGroupCellRenderer',
              width: 60
            },
            {
              cellRenderer: ({
                data
              }: {
                data: DistributionRequestQueueApiResource;
              }) => `${data.participantName}`,
              field: 'participant',
              headerName: 'Participant'
            },
            {
              cellRenderer: ({
                data
              }: {
                data: DistributionRequestQueueApiResource;
              }) => (
                <DataTableBadgeCell
                  color={
                    DistributionRequestHoldingTankStatusColorMap[data.status]
                  }>
                  {formatters.displayCase(data.status)}
                </DataTableBadgeCell>
              ),
              field: 'status'
            },
            {
              field: 'submissionTime',
              valueFormatter: ({ value }) => formatters.formatFromIsoDate(value)
            },
            {
              field: 'createdAt',
              valueFormatter: ({ value }) => formatters.formatFromIsoDate(value)
            },
            {
              field: 'updatedAt',
              valueFormatter: ({ value }) => formatters.formatFromIsoDate(value)
            }
          ]}
          columnSizing='fit'
          detailCellRenderer={detailCellRenderer}
          detailRowAutoHeight
          rowData={
            distributionQuery.data ? [distributionQuery.data] : undefined
          }
        />
      </CardContent>
    </Card>
  );
};
