import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class PermissibleTimeframe {
  convertToApiState = Helper.convertValueFromNumericStringToNumber;

  disabled: boolean;

  setDisabled = (value: boolean): void => {
    this.disabled = value;
  };

  output: number | undefined;

  constructor(props: Record<string, any>) {
    this.disabled =
      !props.autoEnrollAmount || !props.allowPermissibleWithdrawal;
    this.output = Helper.convertValueFromNumericStringToNumber(
      props.permissibleTimeframe
    );
  }
}

export default PermissibleTimeframe;
