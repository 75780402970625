import formatters from '@/utils/Formatters';
import { Box, Typography } from '@mui/material';

import React, { FC, useMemo } from 'react';

type TotalProps = {
  label: string;
  value?: number;
  valueColor?: string;
  valueTestId?: string;
  valuePrefix?: string;
};

const Total: FC<TotalProps> = props => {
  const total = useMemo(
    () => formatters.formatDollars(props.value),
    [props.value]
  );

  return (
    <Box>
      <Typography color='GrayText' variant='body2'>
        {props.label}
      </Typography>
      <Typography
        color={props.valueColor}
        data-testid={props.valueTestId}
        variant='h5'>
        {props.valuePrefix}
        {total}
      </Typography>
    </Box>
  );
};

export default Total;
