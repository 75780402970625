import Badge from '@/components/badge';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { PngStatusItem } from '@/models/ContributionDetailsDTO.model';
import ContributionService from '@/services/Contribution.service';
import formatters from '@/utils/Formatters';
import { WarningAmberOutlined } from '@mui/icons-material';
import {
  Alert,
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Theme,
  Typography
} from '@mui/material';
import { blue } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';

import clsx from 'clsx';
import { capitalize } from 'lodash';
import React, { useMemo } from 'react';

import PlanContributionDetailsTable from './plan-contribution-table/PlanContributionDetailsTable.component';

interface PlanStatusContributionProps {
  planId: number;
  ucid: string;
  sponsorId: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  contentBody: {
    paddingBottom: 0
  },
  statusText: {
    color: theme.palette.text.secondary,
    fontSize: theme.spacing(1.8)
  }
}));

const PlanStatusContribution: React.FunctionComponent<
  PlanStatusContributionProps
> = (props: PlanStatusContributionProps) => {
  const classes = useStyles();

  const statusFieldStyle = (theme: Theme) => ({
    alignItems: 'center',
    backgroundColor: blue[50],
    borderRadius: theme.spacing(12.4),
    color: theme.palette.info.main,
    display: 'flex',
    height: theme.spacing(4),
    justifyContent: 'center',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
    minWidth: theme.spacing(12.4),
    padding: theme.spacing(1.4),
    textTransform: 'capitalize'
  });

  const contributionDetails = useQuery(
    [
      ContributionService.getContributionDetails.name,
      props.planId,
      props.sponsorId,
      props.ucid
    ],
    () =>
      ContributionService.getContributionDetails({
        pageSize: 0,
        planId: props.planId,
        sponsorId: props.sponsorId,
        ucid: props.ucid
      }),
    {
      enabled: !!props.sponsorId && !!props.ucid && !!props.sponsorId,
      keepPreviousData: true,
      refetchOnMount: 'always'
    }
  );

  const contributionActions = useQuery(
    [
      ContributionService.getContributionActions.name,
      props.planId,
      props.sponsorId,
      props.ucid
    ],
    () =>
      ContributionService.getContributionActions(
        { ucid: props.ucid },
        { planId: props.planId, sponsorId: props.sponsorId }
      ),
    {
      enabled: !!props.sponsorId && !!props.ucid && !!props.sponsorId,
      keepPreviousData: true,
      refetchInterval: 3000,
      refetchOnMount: 'always'
    }
  );

  const employerStatus = useMemo(() => {
    const pizzaTrackerStatuses =
      contributionActions.data?.statusAndActionsData?.pizzaTrackerStatuses ??
      [];

    const statuses = pizzaTrackerStatuses.map(({ status }) => ({
      label: formatters.formatContributionStatus(status)
    }));

    const activeStatusIndex =
      pizzaTrackerStatuses.map(status => status?.completed)?.lastIndexOf(true) +
      1;

    return formatters.formatContributionStatusHeader(
      statuses[activeStatusIndex]?.label ?? EMPTY_FIELD_PLACEHOLDER
    );
  }, [contributionActions.data]);

  const pngStatuses = useMemo(
    () =>
      [
        {
          createdAt: contributionDetails.data?.activityCreatedAt ?? '',
          initiator: capitalize(contributionDetails.data?.startedBy ?? ''),
          message: '',
          status: 'Created'
        },
        {},
        ...(contributionActions.data?.pngStatuses ?? [])
      ] as PngStatusItem[],
    [contributionActions.data, contributionDetails.data]
  );

  if (!contributionActions.data) {
    return (
      <Typography className='Plan-Contribution-Details_Error'>
        Error retrieving contributions
      </Typography>
    );
  }

  const payrollStatusFromApi = pngStatuses.length
    ? pngStatuses[pngStatuses.length - 1].status
    : EMPTY_FIELD_PLACEHOLDER;

  const payrollStatus =
    formatters.formatContributionStatusHeader(payrollStatusFromApi);

  const isErrorStatus = [
    'ACH_REQUEST_FAILED',
    'ACH_VALIDATION_ERROR',
    'FUNDING_ERROR',
    'TRADE_REQUEST_FAILED',
    'TRADE_REQUEST_VALIDATION_ERROR',
    'TRADE_REQUEST_PROCESSING_ERROR',
    'TRADE_REQUEST_PROCESSING_ABORTED',
    'HELD'
  ].includes(payrollStatusFromApi);

  return (
    <>
      <Card elevation={0} variant='outlined'>
        <Box sx={{ height: 5 }}>
          {contributionActions.isLoading && <LinearProgress />}
        </Box>
        <CardContent className={classes.contentBody}>
          <Grid container sx={{ marginBottom: 2 }}>
            <Typography
              data-testid='plan-contribution-status-title'
              sx={{ marginRight: theme => theme.spacing(2) }}
              variant='h5'>
              Status
            </Typography>
            <Badge
              color={isErrorStatus ? 'warning' : 'success'}
              data-testid='plan-contribution-status-value'
              size='medium'>
              {' '}
              <Box sx={{ textTransform: 'capitalize' }}>{payrollStatus}</Box>
            </Badge>
          </Grid>
          {payrollStatusFromApi === 'HELD' && (
            <Alert
              icon={<WarningAmberOutlined color='error' />}
              severity='warning'
              sx={{ display: 'inline-flex', marginBottom: 2 }}>
              {pngStatuses?.at(-1)?.message}
            </Alert>
          )}
          <Box sx={{ alignItems: 'center', display: 'flex' }}>
            <Typography
              className={classes.statusText}
              data-testid='plan-contribution-employer-status-title'>
              Employer Status:
            </Typography>
            <Box
              className={clsx('Contribution-details-status')}
              data-testid='plan-contribution-employer-status-value'
              sx={statusFieldStyle}>
              {employerStatus}
            </Box>
          </Box>
        </CardContent>
        <PlanContributionDetailsTable statusesData={pngStatuses} />
      </Card>
    </>
  );
};

export default PlanStatusContribution;
