import Psa, {
  ParticipantContributionAmounts,
  UpdateYearsVestingInfo
} from '@/models/Psa.model';
import ApiService from '@/services/Api.service';

import isNumber from 'lodash/isNumber';

class PsaService {
  private static isValidParticipantContributionAmounts = (
    json: ParticipantContributionAmounts
  ): json is ParticipantContributionAmounts => {
    return [
      json.currentYearEmployeeContribution,
      json.currentYearTotalContribution,
      json.priorYearEmployeeContribution,
      json.priorYearTotalContribution
    ].every(isNumber);
  };

  static async getPsa(participantId: string | number): Promise<Psa> {
    const response = await ApiService.getJson(
      /**
       * mbildner: old admin portal uses this endpoint for this data too:
       * https://github.com/vestwell/node-backend/blob/86e91771b28308973cc85ceb587f9ac0ead9df1f/services/ParticipantService.js#L246-L248
       */
      `psa/${participantId}`
    );

    const dto = response as Psa;

    if (
      !PsaService.isValidParticipantContributionAmounts(
        dto.participantContributionAmounts
      )
    ) {
      throw new Error(
        `invalid JSON received from backend for psa where participantId=${participantId}`
      );
    }
    return dto;
  }

  static async updateVestingYears(
    participantId: number,
    dto: UpdateYearsVestingInfo
  ): Promise<unknown> {
    return ApiService.putJson(`psa/${participantId}/vesting`, dto);
  }
}

export { PsaService };
