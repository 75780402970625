import DetailsPage from '@/components/details-page';
import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import LinearLoading from '@/components/linear-loading';
import SimpleTabs, { SimpleTabsProps, TabData } from '@/components/simple-tabs';
import { useUserToken } from '@/contexts/UserTokenContext';
import { useHeap } from '@/hooks';
import { useFixPlanRoute } from '@/hooks/useFixPlanRoute.hook';
import usePlan from '@/hooks/usePlan';
import { ContributionDetailsDto } from '@/models';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import ContributionService from '@/services/Contribution.service';
import { userService } from '@/services/User.service';
import Grid from '@mui/material/Unstable_Grid2';
import { useQuery } from '@tanstack/react-query';

import { AxiosError } from 'axios';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { usePlanType } from '../plan-detail/usePlanType.hook';
import { Actions } from './Actions';
import { DetailsTab } from './DetailsTab';
import PlanContributionOverview from './PlanContributionOverview';

type PlanContributionDetailRouteParams = {
  sponsorPlanId: string;
  ucid: string;
};

// moshe: rename to ContributionDetailRoute
const PlanContributionDetailRoute: React.FunctionComponent = () => {
  const { userHasValidToken } = useUserToken();
  const location = useLocation();
  const navigate = useNavigate();

  const { sponsorPlanId, ucid } =
    useParams<PlanContributionDetailRouteParams>();

  const planQuery = usePlan<{
    sponsorId: number;
    planName: string;
    type: string;
    recordkeeper: string;
    planId: number;
  }>(sponsorPlanId, userHasValidToken, planDto => ({
    planId: planDto.data.id,
    planName: planDto.data.attributes.name ?? '--',
    recordkeeper: planDto.data.attributes.recordkeeper,
    sponsorId: planDto.data.relationships.sponsor.data.id,
    type: planDto.data.attributes.type
  }));

  const planType = usePlanType({
    recordkeeper: planQuery.data?.recordkeeper,
    type: planQuery.data?.type
  });

  useFixPlanRoute();

  const paths = [
    {
      name: planType.urlPlansTypeName,
      to: `/${planType.urlPlansType}`
    },
    {
      name: planQuery.data?.planName ?? '--',
      to: `/${planType.urlPlansType}/${sponsorPlanId}`
    }
  ];

  const contributionQuery = useQuery<
    ContributionDetailsDto,
    AxiosError,
    ContributionDetailsDto,
    string[]
  >(
    [ContributionService.getContributionDetails.name, sponsorPlanId, ucid],
    async () => {
      return ContributionService.getContributionDetails({
        includeCorrections: true,
        planId: sponsorPlanId,
        sponsorId: planQuery.data?.sponsorId,
        ucid
      });
    },
    {
      enabled:
        userHasValidToken && Boolean(sponsorPlanId && ucid && planQuery.data),
      refetchOnMount: 'always'
    }
  );

  const user = useMemo(() => userService.getUser(), []);

  const isOverviewPage = useMemo(
    () => location?.pathname?.includes('/overview'),
    [location]
  );

  useHeap(user);

  const cantAccessEsa506472 =
    !userService.hasPermission(FeatureLevelPermissions.READ_ESA_506472) &&
    +sponsorPlanId === 506472;

  const hasPlan1ReadPermission = userService.hasPermission(
    FeatureLevelPermissions.READ_PLAN_1
  );

  const params = useParams();

  useEffect(() => {
    if (params.planType !== planType.urlPlansType && planQuery.data) {
      const locationPathArray = location.pathname.split('/');
      locationPathArray[1] = planType.urlPlansType;
      return navigate(locationPathArray.join('/'), { replace: true });
    }
  }, [params, location, planType, planQuery.data]);

  if (
    (sponsorPlanId === '1' && !hasPlan1ReadPermission) ||
    cantAccessEsa506472
  ) {
    return redirectToErrorPage(new Error('403'));
  }

  if (planQuery.isFetching || contributionQuery.isFetching) {
    // do we use this class for test assertions?
    return <LinearLoading />;
  }

  if (!planQuery.isSuccess || !contributionQuery.isSuccess) {
    return redirectToErrorPage(
      new Error(
        (
          planQuery?.error?.response?.status ||
          contributionQuery?.error?.response?.status
        )?.toString()
      )
    );
  }

  // should isStateIRA be extracted to a react Provider Context?
  const isStateIRA =
    planQuery.data.type === 'State IRA' ||
    planQuery.data.recordkeeper === 'Surpas (State IRA)';

  const error = planQuery.error || contributionQuery.error;
  if (error) {
    return redirectToErrorPage(error as Error);
  }

  const makeTabsElement = (): SimpleTabsProps => {
    const PlanContributionTab: TabData = {
      component: (
        <PlanContributionOverview
          contributionData={contributionQuery.data}
          isStateIRA={isStateIRA}
          planQuery={planQuery}
          sponsorPlanId={sponsorPlanId}
          ucid={ucid}
        />
      ),
      label: 'Overview',
      path: `/plans/${sponsorPlanId}/contributions/${ucid}/overview`
    };

    const DetailTab: TabData = {
      component: (
        <DetailsTab
          planId={+sponsorPlanId}
          sponsorId={planQuery.data?.sponsorId}
          ucid={ucid}
        />
      ),
      label: 'Details',
      path: `/plans/${sponsorPlanId}/contributions/${ucid}/details`
    };

    const registeredTabs = [PlanContributionTab, DetailTab];

    const hideDetailsTab = (tab: TabData) =>
      (['Vestwell Sub-Accounting Platform', 'Vestwell ESA'].includes(
        contributionQuery.data?.recordkeeper
      ) &&
        tab.label === 'Details') ||
      tab.label !== 'Details';

    const filteredTabs = registeredTabs.filter(hideDetailsTab);

    const displayTabs: SimpleTabsProps = {
      tabs: filteredTabs,
      tabsAriaLabel: 'plan-detail-tabs'
    };

    return displayTabs;
  };

  const tabs = makeTabsElement();

  return (
    <Grid container>
      <Grid>
        <Grid>
          <DetailsPage
            copyableNumber={contributionQuery.data?.ucid}
            flowSubtype={contributionQuery.data?.key?.flowSubtype}
            isStateIRA={isStateIRA}
            paths={paths}
            recordkeeper={planQuery.data?.recordkeeper}
            title={planQuery.data.planName}
            variant='contribution'
          />
        </Grid>
        {isOverviewPage && <Actions planId={+sponsorPlanId} ucid={ucid} />}
      </Grid>
      <SimpleTabs {...tabs} />
    </Grid>
  );
};

export default PlanContributionDetailRoute;
