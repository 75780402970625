import AccessControl from '@/components/access-control/AccessControl.component';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { GetRecentUsersLoginsDto, UsersItemDto } from '@/models/UsersDTO.model';
import UserManagementService from '@/services/UserManagement.service';
import DOMInteraction from '@/utils/DOMInteraction';
import formatters from '@/utils/Formatters';
import { FileDownloadOutlined } from '@mui/icons-material';
import {
  Button,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import React, { FC, useCallback } from 'react';

export interface UserManagementRecentLoginsProps {
  recentLogins: GetRecentUsersLoginsDto[];
  selectedUser: UsersItemDto;
  text: string;
}

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(2)
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': { border: 0 }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1, 2)
}));

export const UserManagementRecentLogins: FC<
  UserManagementRecentLoginsProps
> = props => {
  const { showSnackbar } = useSnackbar();

  const downloadLoginReport = useMutation(
    [`getUsersReports`, props.selectedUser?.userId],
    async () => {
      return UserManagementService.getUsersReportsById(
        '2 month Sign In history',
        props.selectedUser?.userId
      );
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Error downloading document',
          severity: 'error'
        });
      },
      onSuccess: data => {
        DOMInteraction.triggerDownload(data, `2 month Sign In history.csv`);
      }
    }
  );

  const onDownloadLoginReport = useCallback(async () => {
    await downloadLoginReport.mutateAsync();
  }, [downloadLoginReport]);

  return (
    <>
      <AccessControl
        requires={[FeatureLevelPermissions.READ_USER_MANAGEMENT_REPORTS]}>
        <StyledContainer>
          <Tooltip title='Click to download 2 month Sign In history'>
            <Button
              color='primary'
              data-testid='twoMonthLoginReportDownloadButton'
              disabled={downloadLoginReport.isLoading}
              onClick={onDownloadLoginReport}
              size='small'
              startIcon={<FileDownloadOutlined />}
              variant='outlined'>
              2 month Sign In history
            </Button>
          </Tooltip>
        </StyledContainer>
      </AccessControl>
      {!!props.recentLogins?.length && (
        <TableContainer data-testid='userManagementRecentLogins'>
          <Table aria-label='table'>
            <TableHead>
              <TableRow>
                <StyledTableCell>Device</StyledTableCell>
                <StyledTableCell align='left'>IP Address</StyledTableCell>
                <StyledTableCell align='left'>Date</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.recentLogins?.map(login => (
                <StyledTableRow key={login.createdAt}>
                  <StyledTableCell
                    component='th'
                    data-testid='userManagementRecentLoginsDevice'
                    scope='row'>
                    {login.browser ?? EMPTY_FIELD_PLACEHOLDER}
                    <Typography variant='body2'>
                      {`${login.device} (${login.os})` ??
                        EMPTY_FIELD_PLACEHOLDER}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell
                    align='left'
                    data-testid='userManagementIpAddress'>
                    {login.ipAddress ?? EMPTY_FIELD_PLACEHOLDER}
                  </StyledTableCell>
                  <StyledTableCell
                    align='left'
                    data-testid='userManagementDate'>
                    {formatters.formatFromIsoDateCustom(
                      login.createdAt,
                      'M/DD/YY'
                    ) ?? EMPTY_FIELD_PLACEHOLDER}
                    <Typography variant='body2'>
                      {formatters.formatFromIsoDateCustom(
                        login.createdAt,
                        'HH:mma'
                      ) ?? EMPTY_FIELD_PLACEHOLDER}
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
