import AccessControl from '@/components/access-control/AccessControl.component';
import { CreatePlanModal } from '@/components/entity-modals/EntityModals.component';
import { NoPlansIcon } from '@/components/icon/NoPlansIcon';
import LinearLoading from '@/components/linear-loading';
import { SponsorPlanInfoDto } from '@/models/PlanV2DTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import PlansIndex from '@/routes/plans/plans/PlansIndex';
import { PooledPlanStats } from '@/routes/plans/pooled-plan-stats/PooledPlanStats.component';
import { PlanService } from '@/services/Plan.service';
import { Box, Button, Stack, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';

import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  editPlanDesignLink: {
    marginTop: theme.spacing(1)
  },
  subText: {
    color: theme.palette.text.secondary
  }
}));

interface AdoptersProps {
  pooledPlanId: number;
  pooledPlanStatus?: string;
}

const Adopters = (props: AdoptersProps): JSX.Element => {
  const classes = useStyles();

  const planListQuery = useQuery<SponsorPlanInfoDto>(
    ['PlanService.getPlansPage', props.pooledPlanId],
    async () => {
      const dto = await PlanService.getPlansPage({
        pageSize: 25,
        pooledPlanId: props.pooledPlanId
      });
      return dto;
    },
    { staleTime: Infinity }
  );

  if (planListQuery.isFetching) {
    return <LinearLoading />;
  }

  return (
    <>
      {!planListQuery.data?.data?.length ? (
        <Box
          border='1px solid'
          borderColor='#E5E5E5'
          borderRadius='0.25rem'
          mb='1.5rem'>
          <Stack alignItems='center' justifyContent='center' p={6}>
            <NoPlansIcon />
            {props.pooledPlanStatus === 'pending' && (
              <>
                <Typography mt={1}>No adopter plans</Typography>
                <Typography className={classes.subText} variant='body2'>
                  Confirm plan design before adding plans.
                </Typography>
                <AccessControl
                  requires={[FeatureLevelPermissions.WRITE_CREATE_PLAN]}>
                  {/* moshe: using a real `href` here since simpletabs don't support tab-level react-router-dom routing */}
                  <Button
                    className={classes.editPlanDesignLink}
                    href={`/pooled-plans/${props.pooledPlanId}/plan?edit_plan_design=1`}
                    variant='outlined'>
                    Review Plan Design
                  </Button>
                </AccessControl>
              </>
            )}
            {props.pooledPlanStatus === 'confirmed' && (
              <>
                <Typography mt={1}>No plans found</Typography>
                <AccessControl
                  requiresOneOf={[FeatureLevelPermissions.WRITE_CREATE_PLAN]}>
                  <CreatePlanModal
                    pooledPlanId={props.pooledPlanId}
                    title='Add Adopter'
                    variant='text'
                  />
                </AccessControl>
              </>
            )}
          </Stack>
        </Box>
      ) : (
        <>
          <PooledPlanStats pooledPlanId={props.pooledPlanId} />
          <PlansIndex
            createPlanButton={
              <CreatePlanModal pooledPlanId={props.pooledPlanId} />
            }
            pooledPlanId={props.pooledPlanId}
          />
        </>
      )}
    </>
  );
};

export default Adopters;
