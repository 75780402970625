import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class AutoEnrollEffectiveDate {
  convertToApiState = Helper.transformDateToApiState;

  disabled: boolean;

  setDisabled = (value: boolean): void => {
    this.disabled = value;
  };

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.disabled = !props.autoEnrollAmount;
    this.output = Helper.transformDate(props.autoEnrollEffectiveDate);
  }
}

export default AutoEnrollEffectiveDate;
