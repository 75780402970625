import { CONVERSION_TYPES } from '@/consts/uploads';

export const cashAllocationCsvHeaders = ['ssn', 'fundingSource', 'balance'];

export const loansCsvHeaders = [
  'ssn',
  'purpose',
  'loan_origination_date',
  'original_loan_amount',
  'balance',
  'interest_rate',
  'payment_amount',
  'pay_frequency',
  'status',
  'payroll_setup_id',
  'first_pay_date',
  'parent_loan_id',
  'is_conversion',
  'source1_type',
  'source1_percent',
  'source2_type',
  'source2_percent',
  'source3_type',
  'source3_percent',
  'source4_type',
  'source4_percent',
  'source5_type',
  'source5_percent',
  'source6_type',
  'source6_percent',
  'source7_type',
  'source7_percent',
  'source8_type',
  'source8_percent',
  'source9_type',
  'source9_percent',
  'source10_type',
  'source10_percent',
  'source11_type',
  'source11_percent',
  'source12_type',
  'source12_percent',
  'source13_type',
  'source13_percent',
  'source14_type',
  'source14_percent',
  'source15_type',
  'source15_percent'
];

const deferralRatesCsvHeaders = [
  'ssn',
  'toPreTaxValue',
  'toPreTaxType',
  'toRothValue',
  'toRothType',
  'isAffirmativeElection'
];

const rothCostCsvHeaders = ['ssn', 'rothEstablishedDate', 'rothCostBasis'];

const yearsOfServiceHeaders = ['ssn', 'yearsOfService'];

export const getCsvOptionsToDownload = (conversionType: string): string[] => {
  switch (conversionType) {
    case CONVERSION_TYPES.CASH_ALLOCATION:
      return cashAllocationCsvHeaders;
    case CONVERSION_TYPES.DEFERRAL_RATES:
      return deferralRatesCsvHeaders;
    case CONVERSION_TYPES.LOANS:
      return loansCsvHeaders;
    case CONVERSION_TYPES.ROTH_COST_BASIS:
      return rothCostCsvHeaders;
    case CONVERSION_TYPES.HISTORICAL_YEARS_OF_SERVICE:
      return yearsOfServiceHeaders;
    case CONVERSION_TYPES.INVESTMENT_ELECTION_MAPPINGS:
      return ['ssn', 'cusip', 'allocation', 'totalAllocation'];
    default:
      return [];
  }
};
