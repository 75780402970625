import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class PriorProvider {
  editModeValues: {
    priorProviderId?: number;
    priorProviderUnverified?: string;
  };

  convertToApiState = Helper.convertValueToApiState;

  disabled: boolean;

  output: string | undefined;

  setDisabled = (value: boolean): void => {
    this.disabled = value;
  };

  constructor(props: Record<string, any>) {
    this.disabled = props.conversionType !== 'true';
    this.editModeValues = {
      priorProviderId: props.priorProviderId,
      priorProviderUnverified: ''
    };
    this.output = props.priorProviderName;
  }
}

export default PriorProvider;
