import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@mui/material';

import { FormikHelpers, useFormik } from 'formik';
import { toUpper } from 'lodash';
import { ChangeEvent, FC, useCallback, useState } from 'react';
import * as yup from 'yup';

interface BulkModalProps {
  toggleBulkModal: (flag: boolean) => void;
  isBulkModalOpen: boolean;
  handleSubmit: (
    values: { tickers: string },
    formikHelpers: FormikHelpers<{ tickers: string }>
  ) => void;
}

const validationSchema = yup.object().shape({
  tickers: yup
    .array()
    .of(yup.string())
    .min(1, 'Required')
    .test(
      'Unique',
      'Duplicate tickers found',
      values => new Set(values).size === values.length
    )
});

const BulkModal: FC<BulkModalProps> = props => {
  const [tickers, setTickers] = useState('');
  const bulkForm = useFormik({
    initialValues: {
      tickers: ''
    },
    onSubmit: props.handleSubmit,
    validationSchema
  });

  const onBulkModalClose = useCallback(() => {
    props.toggleBulkModal(false);
    setTickers('');
    bulkForm.resetForm();
  }, [props, bulkForm]);

  const handleTickersChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const splitList = event.target.value
        .split('\n')
        .map(ticker => toUpper(ticker.trim()))
        .filter(ticker => ticker.length > 0);
      bulkForm.setFieldValue('tickers', splitList);
      setTickers(event.target.value);
    },
    []
  );

  return (
    <Dialog onClose={() => onBulkModalClose()} open={props.isBulkModalOpen}>
      <DialogTitle>Add Bulk Tickers</DialogTitle>

      <DialogContent>
        <DialogContentText mb={4}>
          Paste a group of tickers from a spreadsheet. Each ticker should be on
          a separate line.
        </DialogContentText>
        <form onSubmit={bulkForm.handleSubmit}>
          <TextField
            autoFocus
            disabled={bulkForm.isSubmitting}
            error={!!bulkForm.errors.tickers}
            fullWidth
            helperText={bulkForm.errors.tickers}
            label='Tickers'
            multiline
            name='tickers'
            onChange={handleTickersChange}
            rows={5}
            sx={{ mb: 4 }}
            value={tickers}
          />
          <DialogActions>
            <Button disabled={bulkForm.isSubmitting} onClick={onBulkModalClose}>
              Cancel
            </Button>
            <Button
              color='primary'
              disabled={
                !bulkForm.isValid ||
                bulkForm.isSubmitting ||
                !bulkForm.values.tickers
              }
              type='submit'
              variant='contained'>
              {bulkForm.isSubmitting ? 'Adding...' : 'Add'}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default BulkModal;
