import { Button, ButtonProps, SxProps, Tooltip } from '@mui/material';

import React from 'react';

interface TooltipButtonProps extends React.HTMLAttributes<HTMLBaseElement> {
  handleOnClick?: () => void;
  tooltipMessage?: string;
  tooltipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  disabled?: boolean;
  testId?: string;
  variant?: ButtonProps['variant'];
  size?: ButtonProps['size'];
  startIcon?: ButtonProps['startIcon'];
  sx?: SxProps;
}

const TooltipButton: React.FunctionComponent<TooltipButtonProps> = (
  props: TooltipButtonProps
) => {
  const {
    tooltipMessage,
    tooltipPlacement,
    disabled,
    handleOnClick,
    children,
    testId,
    startIcon,
    variant,
    size,
    sx
  } = props;

  if (disabled && tooltipMessage) {
    return (
      <Tooltip placement={tooltipPlacement} title={tooltipMessage}>
        <span>
          <Button
            data-testid={testId}
            disabled={disabled}
            size={size}
            startIcon={startIcon}
            sx={{ minWidth: 'auto', whiteSpace: 'nowrap', ...sx }}
            variant={variant}>
            {children}
          </Button>
        </span>
      </Tooltip>
    );
  }

  return (
    <Button
      data-testid={testId}
      disabled={disabled}
      onClick={handleOnClick}
      size={size}
      startIcon={startIcon}
      sx={{ minWidth: 'auto', whiteSpace: 'nowrap', ...sx }}
      variant={variant}>
      {children}
    </Button>
  );
};

export default TooltipButton;
export type { TooltipButtonProps };
