import { CardPlaceholder } from '@/components/card';
import CollapsibleTable from '@/components/collapsible-table';
import {
  FormattedLoan,
  LoanDto,
  LoanResponseDto
} from '@/models/LoanDTO.model';
import ParticipantService from '@/services/Participant.service';
import { Card, Paper, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import React from 'react';

import LoansTableCell from './LoanTableCell.component';

interface LoanRequestsProps {
  participantId: number;
  recordkeeper: string;
}

const LoanRequests = (props: LoanRequestsProps): JSX.Element => {
  const headCells = [
    { field: 'id', headerName: 'Loan ID' },
    {
      field: 'createdAt',
      headerName: 'Created At'
    },
    {
      field: 'originationDate',
      headerName: 'Origination Date'
    },
    {
      field: 'startDate',
      headerName: 'Start Date'
    },
    {
      field: 'originalAmount',
      headerName: 'Original Amount'
    },
    {
      field: 'outstandingBalance',
      headerName: 'Outstanding Balance'
    },
    {
      field: 'status',
      headerName: 'Status'
    }
  ];

  const { participantId, recordkeeper } = props;

  const LoansQuery = useQuery<LoanResponseDto>(
    ['ParticipantService.getLoans', participantId],
    () => {
      return ParticipantService.getLoans(participantId);
    },
    {
      enabled: Boolean(participantId),
      staleTime: Infinity
    }
  );

  if (LoansQuery.isFetching) {
    return <p>Loading....</p>;
  }

  if (!LoansQuery.isSuccess) {
    return <p>Error</p>;
  }

  if (!LoansQuery.data?.data.length) {
    return (
      <Card
        sx={{
          fontSize: theme => theme.spacing(2.4),
          padding: theme => theme.spacing(2),
          textAlign: 'center'
        }}>
        <Typography
          sx={{
            marginBottom: theme => theme.spacing(1),
            textAlign: 'left'
          }}
          variant='h5'>
          Loans
        </Typography>
        <CardPlaceholder
          data-testid='no-data-loans-participant'
          subtitle='No data for this participant'
        />
      </Card>
    );
  }

  const inactiveLoanStatus = [
    'Paid in Full',
    'Offset',
    'Declined by Participant',
    'Expired',
    'Canceled',
    'Rejected',
    'Canceled - Migration',
    'Deconverted',
    'Deconverted - LT',
    'Reamortized'
  ];

  const formatLoan = (loan: LoanDto): FormattedLoan => {
    return {
      createdAt: loan.attributes.createdAt,
      id: loan.id,
      originalAmount: loan.attributes.originalLoanAmount,
      originationDate: loan.attributes.originationDate,
      outstandingBalance:
        loan.attributes.outstandingPrincipalDue +
        loan.attributes.overdueInterest,
      participantId,
      startDate:
        recordkeeper === 'LT'
          ? loan.attributes.startDate
          : loan.attributes.firstPaymentDueDate,
      status: loan.attributes.loanStatus
    } as FormattedLoan;
  };

  const activeLoans = LoansQuery.data.data
    .filter(loan => {
      return !inactiveLoanStatus.includes(loan.attributes.loanStatus);
    })
    .map(formatLoan);

  const inactiveLoans = LoansQuery.data.data
    .filter(loan => {
      return inactiveLoanStatus.includes(loan.attributes.loanStatus);
    })
    .map(formatLoan);

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          marginBottom: theme => theme.spacing(2),
          width: '100%'
        }}
        variant='outlined'>
        <Typography
          component='div'
          id='tableTitle'
          sx={{ padding: theme => theme.spacing(2) }}
          variant='h5'>
          Active Loans
        </Typography>
        {activeLoans.length ? (
          <CollapsibleTable
            cellComponent={LoansTableCell}
            columns={headCells}
            tableData={activeLoans || []}
          />
        ) : (
          <Typography
            sx={{
              padding: theme => theme.spacing(2)
            }}
            variant='h5'>
            No data
          </Typography>
        )}
      </Paper>
      <Paper
        elevation={0}
        sx={{
          marginBottom: theme => theme.spacing(2),
          width: '100%'
        }}
        variant='outlined'>
        <Typography
          component='div'
          id='tableTitle'
          sx={{ padding: theme => theme.spacing(2) }}
          variant='h5'>
          Inactive Loans
        </Typography>
        {inactiveLoans.length ? (
          <CollapsibleTable
            cellComponent={LoansTableCell}
            columns={headCells}
            tableData={inactiveLoans || []}
          />
        ) : (
          <Typography
            sx={{
              padding: theme => theme.spacing(2)
            }}
            variant='h5'>
            No data
          </Typography>
        )}
      </Paper>
    </>
  );
};

export default LoanRequests;
