import { useField, useFormikContext } from 'formik';
import { FC, useEffect } from 'react';

const StatusObserver: FC<{
  data: Record<string, any>;
}> = ({ data }: { data: Record<string, any> }) => {
  const { values }: { values: Record<string, any> } = useFormikContext();
  const [, , deconvertedToMepHelpers] = useField('deconvertedToMep');
  const [, , lastPayrollDateHelpers] = useField('lastPayrollDate');
  const [, , offboardingBlackoutEndDateHelpers] = useField(
    'offboardingBlackoutEndDate'
  );
  const [, , offboardingBlackoutStartDateHelpers] = useField(
    'offboardingBlackoutStartDate'
  );
  const [, , terminationDateHelpers] = useField('terminationDate');

  useEffect(() => {
    if (
      values.offboardingBlackoutEndDate === 'Invalid Date' &&
      values.adminStatus !== 'Deconverted'
    ) {
      offboardingBlackoutEndDateHelpers.setValue('');
    } else if (
      values.offboardingBlackoutEndDate === '' &&
      values.adminStatus === 'Deconverted'
    ) {
      offboardingBlackoutEndDateHelpers.setValue('Invalid Date');
    } else if (
      values.offboardingBlackoutEndDate &&
      values.offboardingBlackoutEndDate !== 'Invalid Date' &&
      !['Deconverted', 'Deconversion Requested'].includes(values.adminStatus)
    ) {
      offboardingBlackoutEndDateHelpers.setValue(null);
    }

    if (
      values.offboardingBlackoutStartDate === 'Invalid Date' &&
      values.adminStatus !== 'Deconverted'
    ) {
      offboardingBlackoutStartDateHelpers.setValue('');
    } else if (
      values.offboardingBlackoutStartDate === '' &&
      values.adminStatus === 'Deconverted'
    ) {
      offboardingBlackoutStartDateHelpers.setValue('Invalid Date');
    } else if (
      values.offboardingBlackoutStartDate &&
      values.offboardingBlackoutStartDate !== 'Invalid Date' &&
      !['Deconverted', 'Deconversion Requested'].includes(values.adminStatus)
    ) {
      offboardingBlackoutStartDateHelpers.setValue(null);
    }

    if (
      values.lastPayrollDate === 'Invalid Date' &&
      !['Terminated', 'Termination Requested', 'Deconverted'].includes(
        values.adminStatus
      )
    ) {
      lastPayrollDateHelpers.setValue('');
    } else if (
      values.lastPayrollDate === '' &&
      ['Terminated', 'Termination Requested', 'Deconverted'].includes(
        values.adminStatus
      )
    ) {
      lastPayrollDateHelpers.setValue('Invalid Date');
    } else if (
      values.lastPayrollDate &&
      values.lastPayrollDate !== 'Invalid Date' &&
      ![
        'Terminated',
        'Termination Requested',
        'Deconverted',
        'Deconversion Requested'
      ].includes(values.adminStatus)
    ) {
      lastPayrollDateHelpers.setValue(null);
    }

    if (
      values.terminationDate === 'Invalid Date' &&
      values.adminStatus !== 'Terminated'
    ) {
      terminationDateHelpers.setValue('');
    } else if (
      values.adminStatus === 'Terminated' &&
      values.terminationDate === ''
    ) {
      terminationDateHelpers.setValue('Invalid Date');
    } else if (
      values.terminationDate &&
      values.terminationDate !== 'Invalid Date' &&
      values.adminStatus !== 'Terminated'
    ) {
      terminationDateHelpers.setValue(null);
    }

    if (
      values.deconvertedToMep === 'Yes' &&
      values.adminStatus !== 'Deconverted'
    ) {
      deconvertedToMepHelpers.setValue('');
    }

    data.offboardingBlackoutEndDate.setDisabled(
      !['Deconverted', 'Deconversion Requested'].includes(values.adminStatus)
    );

    data.offboardingBlackoutStartDate.setDisabled(
      !['Deconverted', 'Deconversion Requested'].includes(values.adminStatus)
    );

    data.deconvertedToMep.setDisabled(values.adminStatus !== 'Deconverted');

    data.lastPayrollDate.setDisabled(
      ![
        'Terminated',
        'Termination Requested',
        'Deconverted',
        'Deconversion Requested'
      ].includes(values.adminStatus)
    );

    data.terminationDate.setDisabled(values.adminStatus !== 'Terminated');
  }, [
    data,
    deconvertedToMepHelpers,
    lastPayrollDateHelpers,
    offboardingBlackoutEndDateHelpers,
    offboardingBlackoutStartDateHelpers,
    terminationDateHelpers,
    values
  ]);

  return null;
};

export default StatusObserver;
