const autoEnrollAmountOptions = [
  { key: 0, value: 'None' },
  { key: 1, value: '1%' },
  { key: 2, value: '2%' },
  { key: 3, value: '3%' },
  { key: 4, value: '4%' },
  { key: 5, value: '5%' },
  { key: 6, value: '6%' },
  { key: 7, value: '7%' },
  { key: 8, value: '8%' },
  { key: 9, value: '9%' },
  { key: 10, value: '10%' }
];

const autoEscalateAmountOptions = [
  { key: 0, value: 'None' },
  { key: 1, value: '1%' },
  { key: 2, value: '2%' },
  { key: 3, value: '3%' },
  { key: 4, value: '4%' },
  { key: 5, value: '5%' },
  { key: 6, value: '6%' }
];

const allowDontAllowOptions = ['Allow', "Don't allow"];

const discretionaryMatchFundingIntentOptions = ['Per pay-period', 'Annually'];

const documentMatchFrequencyOptions = [
  'Per pay-period',
  'Quarterly',
  'Annually',
  'N/A - No Match'
];

const earlyRetirementAgeTypeOptions = [
  'Early Retirement Not Allowed',
  'Age Only',
  'Later of Age and Service'
];

const eligibilityRequirementTypes: {
  eligibilityRequirementTypeId: number;
  eligibilityRequirementTypeName: string;
}[] = [
  {
    eligibilityRequirementTypeId: -1,
    eligibilityRequirementTypeName: 'No Requirement'
  },
  {
    eligibilityRequirementTypeId: 2,
    eligibilityRequirementTypeName: 'Specified Months - Elapsed Time'
  },
  {
    eligibilityRequirementTypeId: 3,
    eligibilityRequirementTypeName: 'Specified Hours of Service'
  },
  {
    eligibilityRequirementTypeId: 4,
    eligibilityRequirementTypeName: 'One (1) Year - Hours of Service'
  },
  {
    eligibilityRequirementTypeId: 5,
    eligibilityRequirementTypeName:
      'Specified months - Hours of Service & Revert to 1000'
  },
  {
    eligibilityRequirementTypeId: 6,
    eligibilityRequirementTypeName:
      'Specified months - Hours of Service & Rolling'
  },
  {
    eligibilityRequirementTypeId: 7,
    eligibilityRequirementTypeName: 'State IRA Eligibility'
  }
];

const entryDateFrequencies = [
  { id: 1, type: 'Monthly' },
  { id: 2, type: 'Quarterly' },
  { id: 3, type: 'Semi-Annually' },
  { id: 4, type: 'Annual' },
  { id: 5, type: 'Daily' }
];

const excludedIncludedOptions = ['Excluded', 'Included'];

const frequencyOptions = ['Per pay-period', 'Quarterly', 'Annually'];

const hardshipCriteriaOptions = ['Safe Harbor', 'Non-Safe Harbor'];

const hardshipWithdrawalsOptions = [
  'Yes - All Accounts',
  'Yes - Selected Accounts',
  'No'
];

const inServiceAtSpecifiedAgeOptions = [
  'Yes - All Accounts',
  'Yes - Selected Accounts',
  'No'
];

const lengthOfEmploymentRequiredOptions = [
  { label: 'No Requirement', value: 0 },
  { label: '1 month', value: 1 },
  { label: '2 months', value: 2 },
  { label: '3 months', value: 3 },
  { label: '4 months', value: 4 },
  { label: '5 months', value: 5 },
  { label: '6 months', value: 6 },
  { label: '7 months', value: 7 },
  { label: '8 months', value: 8 },
  { label: '9 months', value: 9 },
  { label: '10 months', value: 10 },
  { label: '11 months', value: 11 },
  { label: '12 months', value: 12 }
];

const minimumAgeOptions = [0, 14, 15, 16, 17, 18, 19, 20, 21];

const nonSafeHarborMatchTypeOptions = ['discretionary', 'formula'];

const normalRetirementAgeTypeOptions = ['Age Only', 'Later of Age and Service'];

const esaPlanStatuses = [
  { disabled: false, value: 'Active' },
  { disabled: false, value: 'OB Cancelled' },
  { disabled: false, value: 'Onboarding' },
  { disabled: false, value: 'Ready - Awaiting Effective Date' },
  { disabled: false, value: 'Terminated' },
  { disabled: false, value: 'Termination Requested' }
];

const planStatuses = [
  ...esaPlanStatuses,
  { disabled: true, value: 'Abandoned' },
  { disabled: false, value: 'Deconversion Requested' },
  { disabled: false, value: 'Deconverted' },
  { disabled: true, value: 'Deferred' },
  { disabled: false, value: 'Pending' }
].sort((s1, s2) => (s1.value < s2.value ? -1 : s1.value > s2.value ? 1 : 0));

const planYearEndOptions = [
  'January 31',
  'February 28',
  'March 31',
  'April 30',
  'May 31',
  'June 30',
  'July 31',
  'August 31',
  'September 30',
  'October 31',
  'November 30',
  'December 31'
];

const pooledPlanTemplateEmptyState = 'Let adopters set';

const profitSharingTypes = [
  { option: 'N/A - No Profit Share', value: null },
  { option: 'New Comparability', value: 'newComparability' },
  { option: 'Pro Rata', value: 'participantToAllParticipants' },
  { option: 'Integrated (age)', value: 'integrated' }
];

const recordkeepers = [
  { id: 1, name: 'LT' },
  { id: 2, name: 'Voya' },
  { id: 3, name: 'Vestwell (RK) - Folio' },
  { id: 4, name: 'Surpas (State IRA)' },
  { id: 5, name: 'Vestwell Sub-Accounting Platform' }
];

const recordkeepersDisplay = [
  { option: 'LT', value: 'LT' },
  { option: 'Voya', value: 'Voya' },
  { option: 'Vestwell (RK) - Folio', value: 'Vestwell (RK) - Folio' },
  { option: 'Surpas (State IRA)', value: 'Surpas (State IRA)' },
  { option: 'SIERRA', value: 'Vestwell Sub-Accounting Platform' }
];

const rolloverOptions = [
  'Yes - All Eligible Employees',
  'Yes - Only active Participants',
  'No'
];

const vestingMethod = ['Elapsed Time', 'Hours of Service'];

const vestingSchedules = [
  { id: -1, name: '--' },
  { id: 1, name: 'Immediate', value: 'Immediate' },
  { id: 2, name: '1-2 Year Graded', value: '1-2 Year Graded' },
  { id: 3, name: '4 Year Graded (0|25|50|75|100)', value: '1-4 Year Graded' },
  {
    id: 4,
    name: '5 Year Graded (0|20|40|60|80|100)',
    value: '1-5 Year Graded'
  },
  {
    id: 5,
    name: '6 Year Graded (0|0|20|40|60|80|100)',
    value: '2-6 Year Graded'
  },
  { id: 6, name: '2 Year Cliff (0|0|100)', value: '2 Year Cliff' },
  { id: 7, name: '3 Year Cliff (0|0|0|100)', value: '3 Year Cliff' },
  { id: 8, name: '5 Year Graded (0|0|25|50|75|100)', value: '2-5 Year Graded' },
  {
    id: 9,
    name: '4 Year Graded (0|0|33|66|100)',
    value: '1-4 Year Graded (0/33/66/100)'
  },
  { id: 10, name: '1 Year Cliff (0|100)', value: '1 Year Cliff' },
  { id: 11, name: '3 Year Graded (0|33|66|100)', value: '3 Year Graded' },
  {
    id: 12,
    name: '6 Year Graded (0|5|20|40|60|80|100)',
    value: '6 Year Graded (0/5/20/40/60/80/100)'
  },
  {
    id: 13,
    name: '4 Year Graded (0|0|50|75|100)',
    value: '0-0-50-75-100'
  },
  {
    id: 14,
    name: '3 Year Graded (0|25|50|100)',
    value: '0-25-50-100'
  },
  {
    id: 15,
    name: '5 Year Graded (0|0|20|40|60|100)',
    value: '0-0-20-40-60-100'
  },
  {
    id: 16,
    name: '3 Year Graded (0|0|50|100)',
    value: '0-0-50-100'
  },
  {
    id: 17,
    name: '3 Year Graded (0|30|65|100)',
    value: '0-30-65-100'
  },
  {
    id: 18,
    name: '3 Year Graded (0|33|67|100)',
    value: '0-33-67-100'
  },
  {
    id: 19,
    name: '3 Year Graded (10|30|60|100)',
    value: '10-30-60-100'
  },
  {
    id: 20,
    name: '3 Year Graded (0|0|20|100)',
    value: '0-0-20-100'
  },
  {
    id: 21,
    name: '1 Year Graded (50|100)',
    value: '50-100'
  },
  {
    id: 22,
    name: '2 Year Graded (33|67|100)',
    value: '33-67-100'
  },
  {
    id: 23,
    name: '3 Year Graded (0|34|67|100)',
    value: '0-34-67-100'
  },
  {
    id: 24,
    name: '6 Year Graded (0|20|20|40|60|80|100)',
    value: '0-20-20-40-60-80-100'
  },
  {
    id: 25,
    name: '4 Year Graded (0|0|25|50|100)',
    value: '0-0-25-50-100'
  },
  {
    id: 26,
    name: '3 Year Graded (0|20|50|100)',
    value: '0-20-50-100'
  },
  {
    id: 27,
    name: '3 Year Graded (0|33.3|66.6|100)',
    value: '0-33.3-66.6-100'
  },
  {
    id: 28,
    name: '5 Year Graded (0|10|20|40|60|100)',
    value: '0-10-20-40-60-100'
  },
  {
    id: 29,
    name: '4 Year Graded (0|20|40|60|100)',
    value: '0-20-40-60-100'
  },
  {
    id: 30,
    name: '3 Year Graded (20|40|100)',
    value: '20-40-100'
  },
  {
    id: 31,
    name: '4 Year Graded (0|20|60|100)',
    value: '0-20-60-100'
  },
  {
    id: 32,
    name: '6 Year Graded (10|20|40|60|80|100)',
    value: '10-20-40-60-80-100'
  },
  {
    id: 33,
    name: '3 Year Graded (40|70|100)',
    value: '40-70-100'
  },
  {
    id: 34,
    name: '3 Year Graded (30|60|100)',
    value: '30-60-100'
  }
];

const yesNoOptions = ['Yes', 'No'];

const voyaRecordkeeperId = 2;

export default {
  allowDontAllowOptions,
  autoEnrollAmountOptions,
  autoEscalateAmountOptions,
  discretionaryMatchFundingIntentOptions,
  documentMatchFrequencyOptions,
  earlyRetirementAgeTypeOptions,
  eligibilityRequirementTypes,
  entryDateFrequencies,
  esaPlanStatuses,
  excludedIncludedOptions,
  frequencyOptions,
  hardshipCriteriaOptions,
  hardshipWithdrawalsOptions,
  inServiceAtSpecifiedAgeOptions,
  lengthOfEmploymentRequiredOptions,
  minimumAgeOptions,
  nonSafeHarborMatchTypeOptions,
  normalRetirementAgeTypeOptions,
  planStatuses,
  planYearEndOptions,
  pooledPlanTemplateEmptyState,
  profitSharingTypes,
  recordkeepers,
  recordkeepersDisplay,
  rolloverOptions,
  vestingMethod,
  vestingSchedules,
  voyaRecordkeeperId,
  yesNoOptions
};
