import {
  BookPushedDepositStatus,
  CashTransferWorkflowStatus,
  CommonWorkflowStatus,
  CorrectionByAmountWorkflowStatus,
  DepositRequestWorkflowStatus,
  DisbursementWorkflowStatus,
  DistributionRequestWorkflowStatus,
  FeeRequestWorkflowStatus,
  FlattenRequestWorkflowStatus,
  IndividualSubAccountOrderWorkflowStatus,
  InvestContributionWorkflowStatus,
  InvestmentUpdateWorkflowStatus,
  NewPlanWorkflowStatus,
  PlanLiquidationWorkflowStatus,
  ReversalWorkflowStatus,
  TradeRequestWorkflowStatus
} from '@vestwell-sub-accounting/models/conductor/WorkflowStatus.model';

export const WorkflowStatusEnumsObject = {
  ...CommonWorkflowStatus,
  ...NewPlanWorkflowStatus,
  ...TradeRequestWorkflowStatus,
  ...FlattenRequestWorkflowStatus,
  ...InvestContributionWorkflowStatus,
  ...InvestmentUpdateWorkflowStatus,
  ...DepositRequestWorkflowStatus,
  ...PlanLiquidationWorkflowStatus,
  ...BookPushedDepositStatus,
  ...DistributionRequestWorkflowStatus,
  ...CorrectionByAmountWorkflowStatus,
  ...DisbursementWorkflowStatus,
  ...FeeRequestWorkflowStatus,
  ...ReversalWorkflowStatus,
  ...IndividualSubAccountOrderWorkflowStatus,
  ...CashTransferWorkflowStatus
};
