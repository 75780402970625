import { BalanceTotalsDto } from '@/models/ops/balance-totals/BalanceTotalsDTO.model';
import { BalanceTotalsGetRequest } from '@/models/ops/balance-totals/BalanceTotalsGetRequest.model';
import ApiService from '@/services/Api.service';

class BalanceTotalsService {
  static async get(
    params: BalanceTotalsGetRequest
  ): Promise<BalanceTotalsDto[]> {
    const result = await ApiService.getJson<BalanceTotalsDto[]>(
      `/balance-totals`,
      params
    );
    return result;
  }
}

export default BalanceTotalsService;
