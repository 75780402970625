import Card, { CardContent, CardHeader } from '@/components/card';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { FundChangeApprovalDto } from '@/models/FundChangeDTO.model';
import { SecurityDto } from '@/models/SecurityMaster.model';
import InvestmentService from '@/services/Investment.service';
import {
  FundChangePreview,
  FundChangesPreview,
  InvestmentOption,
  ProgramService,
  SponsorPlan
} from '@/services/ops/investments/Program.service';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { FC, useCallback, useState } from 'react';
import { unstable_usePrompt } from 'react-router-dom';
import { useToggle } from 'react-use';
import * as yup from 'yup';

import { FundChangeHistoryCard } from './FundChangeHistoryCard.component';
import { FundChangesPreviewModal } from './FundChangesPreviewModal.component';
import { ProgramFundChangesTable } from './ProgramFundChangesTable.component';
import { InvestmentOptionLabel, ProgramFund } from './types';

const fundChangesValidationSchema = fundChanges =>
  yup.object().shape({
    fundChangesPreview: yup
      .array()
      .of(
        yup.object().shape({
          investmentOptions: yup
            .array()
            .of(yup.string().required())
            .when('isRemoved', {
              is: false,
              then: schema => schema.required().min(1, 'Required')
            }),
          isRemoved: yup.boolean().default(false),
          newFund: yup.string().when('isRemoved', {
            is: false,
            then: schema => schema.required('Missing required field.')
          }),
          oldFund: yup.string().when('isRemoved', {
            is: false,
            then: schema =>
              schema
                .test('unique', 'Duplicate fund', function (value, field: any) {
                  const { path, createError, options } = field;
                  const { fundChangesPreview } = options.from[1].value;

                  if (
                    fundChangesPreview
                      .filter(t => !t.isRemoved)
                      .filter((t: { oldFund: string }) => t.oldFund === value)
                      .length > 1
                  ) {
                    return createError({
                      message: 'Cannot accept duplicate tickers.',
                      path
                    });
                  }
                  return true;
                })
                .required('Required')
          }),
          tradeDate: yup.date().when('isRemoved', {
            is: false,
            then: schema =>
              schema
                .min(dayjs().format('MM-DD-YYYY'), 'Please choose a valid date')
                .required('Missing required field.')
          })
        })
      )
      .min(1, 'Must have at least one fund change')
      .test('Unique', 'Duplicate fund changes found', function (preSwaps) {
        return !preSwaps.some(preSwap =>
          fundChanges.find(fundChange => {
            return (
              fundChange?.approveInfo?.approved === undefined &&
              fundChange.oldFund.cusip === preSwap.oldFund &&
              preSwap.investmentOptions.some(investmentOption =>
                fundChange.investmentOptions.includes(investmentOption)
              )
            );
          })
        );
      })
  });

type FundChangesTabProps = {
  availableOptions: InvestmentOptionLabel[];
  funds: ProgramFund[];
  onSubmitFundChanges: () => void;
  programId: number;
  fundChanges: {
    approveInfo?: FundChangeApprovalDto;
    investmentOptions: InvestmentOption[];
    newFund: SecurityDto;
    oldFund: SecurityDto;
    plansAffected: SponsorPlan[];
    fundChangeId: number;
    tradeDate: string;
  }[];
};

export const FundChangesTab: FC<FundChangesTabProps> = props => {
  const [isPreviewModalOpen, togglePreviewModal] = useToggle(false);
  const [fundChangePreview, setFundChangePreview] =
    useState<FundChangePreview[]>();
  const snackbar = useSnackbar();

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      fundChangesPreview: [
        {
          investmentOptions: [],
          isRemoved: false,
          newFund: '',
          oldFund: '',
          tradeDate: ''
        }
      ]
    },
    onSubmit: async (values, ctx) => {
      await Promise.all(
        values.fundChangesPreview
          .filter(fundChangePreview => !fundChangePreview.isRemoved)
          .map(fundChangePreview =>
            ProgramService.createFundChange(props.programId, fundChangePreview)
          )
      );

      togglePreviewModal(false);
      ctx.resetForm();
      setFundChangePreview(undefined);
      snackbar.showSnackbar({
        message: 'Save Successful',
        severity: 'success'
      });
      props.onSubmitFundChanges();
    },
    validationSchema: fundChangesValidationSchema(props.fundChanges)
  });

  const onAddFundChangePreview = useCallback(() => {
    form.setFieldValue('fundChangesPreview', [
      ...form.values.fundChangesPreview,
      {}
    ]);
  }, [form]);

  const onRemoveFundChangePreview = useCallback(
    async (index: number) => {
      const fundChangesPreview = [...form.values.fundChangesPreview];
      fundChangesPreview.splice(index, 1);

      await form.setFieldValue(`fundChangesPreview[${index}].isRemoved`, true);
    },
    [form]
  );

  const createPreview = useMutation(
    ['ProgramService.createFundChangePreview'],
    (fundChangesPreview: FundChangesPreview[]) => {
      return ProgramService.createFundChangePreview(
        props.programId,
        fundChangesPreview
      );
    },
    {
      onSuccess: data => {
        setFundChangePreview(data);
      }
    }
  );

  const onFundChangePreviewsSave = useCallback(() => {
    const { fundChangesPreview } = form.values;
    const validFundChanges = fundChangesPreview.filter(
      fundChangePreview => !fundChangePreview.isRemoved
    );

    if (!validFundChanges || validFundChanges.length === 0) {
      return;
    }

    setFundChangePreview(undefined);
    togglePreviewModal(true);

    createPreview.mutate(validFundChanges);
  }, [form.values, createPreview, togglePreviewModal]);

  unstable_usePrompt({
    message: 'Choose cancel to avoid losing the unsaved changes.',
    when: form.dirty
  });

  return (
    <>
      <FundChangesPreviewModal
        fundChangePreview={fundChangePreview}
        isPreviewModalOpen={isPreviewModalOpen}
        isSubmitting={form.isSubmitting}
        onPreviewModalClose={() => togglePreviewModal(false)}
        onSubmit={form.submitForm}
      />
      <Card sx={{ my: 4 }}>
        <CardHeader
          actionButtonsProps={[
            {
              disabled: !(form.dirty && form.isValid),
              label: 'Submit Fund Change(s)',
              onClick: onFundChangePreviewsSave,
              variant: 'contained'
            }
          ]}
          subtitle='Empty rows will be ignored.'
          title='Initiate fund change order(s)'
        />
        <CardContent disablePadding>
          <ProgramFundChangesTable
            availableOptions={props.availableOptions}
            errors={form.errors}
            fundChangesPreview={form.values.fundChangesPreview}
            funds={props.funds}
            lookupTickerCallback={InvestmentService.searchSymbol}
            onChange={form.handleChange}
            onRemoveFundChangePreview={onRemoveFundChangePreview}
            setFieldError={form.setFieldError}
          />
          <Box p={2}>
            <Button
              disabled={
                form.values.fundChangesPreview.filter(fundChangePreview => {
                  return !fundChangePreview.isRemoved;
                }).length >= 10 || !!form.errors?.fundChangesPreview
              }
              onClick={onAddFundChangePreview}
              startIcon={<AddIcon />}>
              Add More Fund Changes
            </Button>
          </Box>
        </CardContent>
      </Card>
      <FundChangeHistoryCard
        availableOptions={props.availableOptions}
        fundChanges={props.fundChanges}
        onSubmitFundChanges={props.onSubmitFundChanges}
      />
    </>
  );
};

FundChangesTab.displayName = 'FundChangesTab';
