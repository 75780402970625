import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class SpecifiedAge {
  convertToApiState = Helper.convertValueToApiState;

  disabled: boolean;

  setDisabled = (value: boolean): void => {
    this.disabled = value;
  };

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.disabled = !props.allowInServiceAtSpecifiedAge;
    this.output = props.inServiceSpecifiedAge;
  }
}

export default SpecifiedAge;
