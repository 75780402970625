import Card, { CardContent, CardPlaceholder } from '@/components/card';
import DataTable from '@/components/data-table/DataTable.component';
import { BulkErrorModal } from '@/components/queue-error-card/BulkErrorModal.componen';
import { useQueueError } from '@/hooks/useQueueError.hook';
import {
  ErrorQueueTypes,
  ErrorQueueTypesMap,
  QueueErrorDTO
} from '@/models/QueueErrorsDTO.model';
import QueueErrorService from '@/services/QueueError.service';
import formatters from '@/utils/Formatters';
import { useUrlStateParams } from '@/utils/Url';
import { Search } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  Button,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { ColDef, SelectionChangedEvent } from 'ag-grid-community';
import { FC, useMemo, useState } from 'react';
import { useToggle } from 'react-use';
import { useDebounce } from 'use-debounce';

export const QueueErrorsRoute: FC = () => {
  const [bulkMethod, setBulkMethod] = useState<
    'Archive' | 'Replay' | 'Notes' | ''
  >('');
  const [includeArchived, toggleIncludeArchived] = useToggle(false);
  const [isBulkModalOpen, toggleBulkModal] = useToggle(false);
  const [orderBy, setOrderBy] = useState<string | undefined>();
  const [orderByDirection, setOrderByDirection] = useState<
    'asc' | 'desc' | undefined
  >();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const queryClient = useQueryClient();
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedIDs, setSelectedIDs] = useState<string[]>([]);

  const queueError = useQueueError();
  const [type, setType] = useUrlStateParams<ErrorQueueTypes>(
    'all',
    'type',
    value => String(value),
    value => (value ? (value as ErrorQueueTypes) : undefined)
  );

  const [searchTerm, setSearchTerm] = useUrlStateParams(
    '',
    'searchTerm',
    value => String(value),
    value => value ?? ''
  );
  const [debouncedSearchTerm] = useDebounce<string>(searchTerm, 300);

  const queueErrorsQuery = useQuery(
    [
      QueueErrorService.getFilteredQueueErrors.name,
      type,
      includeArchived,
      page,
      pageSize,
      orderBy,
      orderByDirection,
      debouncedSearchTerm
    ],
    () =>
      QueueErrorService.getFilteredQueueErrors(
        type,
        includeArchived,
        page,
        pageSize,
        orderBy,
        orderByDirection,
        debouncedSearchTerm
      ),
    {
      staleTime: Infinity
    }
  );

  const bulkArchive = () => {
    toggleBulkModal();
    setBulkMethod('Archive');
  };

  const bulkReplay = () => {
    toggleBulkModal();
    setBulkMethod('Replay');
  };

  const bulkNote = () => {
    toggleBulkModal();
    setBulkMethod('Notes');
  };

  const handleTypeChange = (event: SelectChangeEvent) => {
    setPage(1);
    setSelectedCount(0);
    setType(event.target.value as ErrorQueueTypes);
    if (queueError.expandAll) {
      queueError.toggleExpandAll();
    }
  };

  const onChangeIncludeArchived = () => {
    toggleIncludeArchived();
    setPage(1);
  };

  const columns: ColDef[] = useMemo(
    () => [
      {
        cellRenderer: 'agGroupCellRenderer',
        checkboxSelection: true,
        field: 'createdAt',
        headerCheckboxSelection: true,
        headerName: '',
        maxWidth: 100,
        valueFormatter: () => {
          return '';
        }
      },
      {
        field: 'errorId',
        headerName: 'Error ID',
        lockVisible: true,
        sortable: true
      },
      {
        field: 'createdAt',
        headerName: 'Date',
        valueFormatter: ({ value }: { value: string }) => {
          return formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY HH:mm');
        }
      },
      {
        field: 'initialMessage.body',
        headerName: 'Initial Message Body',
        width: 500
      },
      {
        field: 'note',
        flex: 1,
        headerName: 'Note Text'
      },
      {
        cellRenderer: (cellData: { data: QueueErrorDTO }) => {
          return (
            <>
              {cellData.data.isArchived ? (
                <Box textAlign='right'>
                  <Button
                    onClick={() => {
                      queueError.unarchiveErrorMutation.mutate(
                        cellData.data.errorId,
                        { onSuccess: () => refreshData() }
                      );
                    }}>
                    UNARCHIVE
                  </Button>
                </Box>
              ) : (
                <Box textAlign='right'>
                  <Button
                    onClick={() =>
                      queueError.archiveErrorMutation.mutate(
                        cellData.data.errorId,
                        { onSuccess: () => refreshData() }
                      )
                    }>
                    ARCHIVE
                  </Button>
                  <Button
                    disabled={cellData.data.isReplayed}
                    onClick={() =>
                      queueError.replayErrorMutation.mutate(
                        cellData.data.errorId,
                        { onSuccess: () => refreshData() }
                      )
                    }>
                    REPLAY
                  </Button>
                </Box>
              )}
            </>
          );
        },
        field: '',
        headerName: 'Actions'
      }
    ],
    []
  );

  const refreshData = () => {
    queryClient.invalidateQueries(
      [QueueErrorService.getFilteredQueueErrors.name],
      { refetchType: 'none' }
    );
    queueErrorsQuery.refetch();
  };

  const context = {
    onNoteUpdate: queueError.onNoteUpdate,
    refreshData
  };

  const handlePageChanged = (newPage: number) => {
    setPage(newPage);
  };

  const handlePageSizeChanged = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const onSelectionChanged = (event: SelectionChangedEvent) => {
    const selectedErrors = event.api
      .getSelectedRows()
      .filter((row: QueueErrorDTO) => !row.isReplayed);
    setSelectedCount(selectedErrors.length);
    setSelectedIDs(
      selectedErrors.map<string>((row: QueueErrorDTO) => {
        return row.errorId.toString();
      })
    );
  };

  const handleSortChanged = (
    newSort: { colId: string; sort?: 'asc' | 'desc' }[]
  ) => {
    if (!newSort || newSort.length === 0) {
      setOrderBy(undefined);
      setOrderByDirection(undefined);
    } else {
      const sortColId = newSort[0].colId;
      setOrderBy(sortColId);
      setOrderByDirection(newSort[0].sort);
    }
  };

  const onSubmit = () => {
    refreshData();
    setSelectedCount(0);
    setSelectedIDs([]);
  };

  return (
    <>
      <Typography marginBottom={2} variant='h4'>
        Queue Error
      </Typography>
      <Card>
        <CardHeader
          action={
            <Stack
              direction='row'
              divider={<Divider flexItem orientation='vertical' />}
              spacing={1}>
              <FormControlLabel
                control={
                  <Switch
                    checked={includeArchived}
                    onChange={onChangeIncludeArchived}
                  />
                }
                label='Show archived errors'
              />
              <Stack direction='row' spacing={2}>
                <Button onClick={queueError.onExpandCollapseClick}>
                  {queueError.expandAll ? 'COLLAPSE' : 'EXPAND'} ALL
                </Button>
                <Button
                  onClick={bulkArchive}
                  variant={selectedCount > 0 ? 'contained' : 'text'}>
                  BULK ARCHIVE {selectedCount > 0 ? `(${selectedCount})` : ''}
                </Button>
                <Button
                  onClick={bulkReplay}
                  variant={selectedCount > 0 ? 'contained' : 'text'}>
                  BULK REPLAY {selectedCount > 0 ? `(${selectedCount})` : ''}
                </Button>
                <Button
                  onClick={bulkNote}
                  variant={selectedCount > 0 ? 'contained' : 'text'}>
                  BULK NOTE {selectedCount > 0 ? `(${selectedCount})` : ''}
                </Button>
              </Stack>
            </Stack>
          }
          title={
            <Stack direction='row' spacing={2}>
              <FormControl>
                <InputLabel id='queue-select-label'>Type</InputLabel>
                <Select
                  label='Type'
                  labelId='queue-select-label'
                  onChange={handleTypeChange}
                  size='small'
                  value={type}>
                  {Object.keys(ErrorQueueTypesMap).map(key => (
                    <MenuItem key={key} value={key}>
                      {ErrorQueueTypesMap[key]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment
                      onClick={() => setSearchTerm('')}
                      position='end'>
                      <ClearIcon />
                    </InputAdornment>
                  ),
                  placeholder: 'Search by ID',
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Search />
                    </InputAdornment>
                  )
                }}
                onChange={e => {
                  setSearchTerm(e.target.value);
                }}
                size='small'
                value={searchTerm}
                variant='outlined'
              />
            </Stack>
          }
        />
        <Divider />
        <CardContent loading={queueErrorsQuery.isFetching} sx={{ p: 0 }}>
          <DataTable
            columnDefs={columns}
            columnSizing='flex'
            context={context}
            detailCellRenderer={queueError.detailCell}
            emptyPlaceholderComponent={
              <Stack
                alignItems='center'
                data-testid='no-data-queue-errors-table'
                justifyContent='center'
                sx={{ height: '100%' }}>
                <CardPlaceholder subtitle='No errors found' />
              </Stack>
            }
            gridRef={queueError.gridRef}
            onPageChanged={handlePageChanged}
            onPageSizeChanged={handlePageSizeChanged}
            onRowDataUpdated={queueError.onRowDataUpdated}
            onSelectionChanged={onSelectionChanged}
            onSortChanged={handleSortChanged}
            page={page}
            pageSize={pageSize}
            pagination
            paginationSource='server'
            paginationTotal={queueErrorsQuery.data?.data.totalRows}
            rowData={queueErrorsQuery.data?.data.rows || []}
            rowSelection='multiple'
            sort={
              orderBy
                ? [
                    {
                      colId: orderBy,
                      sort: orderByDirection
                    }
                  ]
                : []
            }
            suppressRowClickSelection={true}
          />
        </CardContent>
      </Card>
      <BulkErrorModal
        errorIds={selectedIDs}
        isBulkModalOpen={isBulkModalOpen}
        onSubmit={onSubmit}
        toggleBulkModal={toggleBulkModal}
        type={bulkMethod}
      />
    </>
  );
};

QueueErrorsRoute.displayName = 'QueueErrorsRoutes';
