import CardInfoField from '@/components/card-info-field';
import { SubAccountingPlan } from '@/models';
import formatters from '@/utils/Formatters';
import { Card, CardContent, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import React from 'react';

export interface SubAccountingPlanSetupCardProps {
  plan: SubAccountingPlan;
}

const useStyles = makeStyles(() => ({
  contentBody: {
    paddingBottom: 0
  }
}));

const SubAccountingPlanSetupCard: React.FunctionComponent<
  SubAccountingPlanSetupCardProps
> = (props: SubAccountingPlanSetupCardProps) => {
  const classes = useStyles();
  const { plan } = props;

  return (
    <div data-testid='sub-accounting-plan-setup-card'>
      <Card elevation={0} variant='outlined'>
        <CardContent className={classes.contentBody}>
          <Typography variant='h5'>Plan Setup Request Status</Typography>
          <CardInfoField
            fieldName='Execution Status'
            fieldSubscript=''
            fieldValue={plan.status ?? '--'}
          />
          <CardInfoField
            fieldName='Workflow Status'
            fieldSubscript=''
            fieldValue={plan.workflowStatus ?? '--'}
          />
          <CardInfoField
            fieldName='Created At'
            fieldValue={
              plan.createdAt
                ? formatters.formatFromIsoDate(plan.createdAt)
                : '--'
            }
          />
          <CardInfoField
            fieldName='Updated At'
            fieldValue={
              plan.createdAt
                ? formatters.formatFromIsoDate(plan.updatedAt)
                : '--'
            }
          />
          {plan.errorMessage?.length && (
            <CardInfoField fieldName='Error' fieldValue={plan.errorMessage} />
          )}
          {plan.errors?.length && (
            <CardInfoField fieldName='Errors' fieldValue={plan.errors} />
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default SubAccountingPlanSetupCard;
