import formatters from '@/utils/Formatters';
import { Box, SxProps, Theme, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import React, { ReactNode } from 'react';

interface CardIconInfoFieldProps {
  title?: ReactNode;
  subtitle?: string | string[];
  icon: React.ReactElement;
  tooltip: string;
  sx?: SxProps;
}

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.grey[500],
    margin: '0 10px'
  }
}));

const CardIconInfoField: React.FunctionComponent<
  CardIconInfoFieldProps
> = props => {
  const dataTestIdName = formatters.textToDataTestId(props.tooltip);
  const classes = useStyles();

  const subtitlesArray = Array.isArray(props.subtitle)
    ? props.subtitle
    : [props.subtitle];
  return (
    <Box
      data-testid={`card-icon-info-field-${dataTestIdName}-container`}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        ...props.sx
      }}>
      <Tooltip
        data-testid={`card-icon-info-field-${dataTestIdName}-tooltip`}
        title={props.tooltip}>
        <Box
          className={classes.icon}
          data-testid={`card-icon-info-field-${dataTestIdName}-icon`}>
          {props.icon}
        </Box>
      </Tooltip>

      <div>
        {props.title && (
          <Box sx={{ display: 'inline-flex' }}>
            <Typography
              data-testid={`card-icon-info-field-${dataTestIdName}-title`}
              sx={{ fontSize: '1.2rem', fontWeight: '500' }}>
              {props.title}
            </Typography>
          </Box>
        )}

        {props.subtitle &&
          subtitlesArray.map((line, index) => (
            <Typography
              data-testid={`card-icon-info-field-${dataTestIdName}-subtitle-line-${index}`}
              key={`${line} ${index}`}
              sx={{ fontSize: '1rem', fontWeight: '400' }}>
              {line}
            </Typography>
          ))}
      </div>
    </Box>
  );
};
export default CardIconInfoField;
