import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class SafeHarborEffectiveDate {
  convertToApiState = Helper.transformDateToTimestamp;

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.output = Helper.transformDate(props.safeHarborEffectiveDate);
  }
}
export default SafeHarborEffectiveDate;
