import { HoldingsDto } from '@/models/ops/holdings/HoldingsDto.model';
import formatters from '@/utils/Formatters';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Stack, Typography } from '@mui/material';
import { PositionDateType } from '@vestwell-sub-accounting/models/accountsAndLedgers/PositionDateType';

import dayjs from 'dayjs';
import { FC } from 'react';

type HoldingsSummaryProps = {
  asOfDate?: string;
  confirmedValue?: number;
  dateType: PositionDateType;
  holdings?: HoldingsDto;
};

export const HoldingsSummary: FC<HoldingsSummaryProps> = props => {
  return (
    <Stack p={2} spacing={2}>
      <Typography variant='h6'>Account Value</Typography>
      <Box data-testid='account-value'>
        <Typography variant='body2'>Total</Typography>
        <Typography variant='h5'>
          {formatters.formatDollars(props.confirmedValue)}
        </Typography>
      </Box>
      <Typography variant='h6'>Balances</Typography>
      {[PositionDateType.Trade].includes(props.dateType) &&
        dayjs().format('YYYY-MM-DD') === props.asOfDate && ( // don't show pending balances if not Trade date type or today's date
          <Box data-testid='pending-balance-total'>
            <Typography variant='body2'>Pending Balance</Typography>
            <Typography variant='h5'>
              {formatters.formatDollars(props.holdings?.cash.pending)}
            </Typography>
          </Box>
        )}
      {[PositionDateType.Trade, PositionDateType.Settlement].includes(
        props.dateType
      ) && ( // only Trade and Settlement date types display confirmed balances
        <Box data-testid='confirmed-balance-total'>
          <Typography variant='body2'>
            {props.dateType
              ? {
                  [PositionDateType.Trade]: 'Confirmed',
                  [PositionDateType.Settlement]: 'Settled',
                  [PositionDateType.Control]: undefined,
                  [PositionDateType.Dividend]: undefined
                }[props.dateType]
              : undefined}{' '}
            Balance
          </Typography>
          <Typography variant='h5'>
            {formatters.formatDollars(props.holdings?.cash.confirmed)}
          </Typography>
        </Box>
      )}
      {[PositionDateType.Control, PositionDateType.Dividend].includes(
        props.dateType
      ) && (
        <Stack
          alignItems='center'
          data-testid='no-balance-totals'
          justifyContent='center'
          spacing={0.5}>
          <InfoOutlinedIcon color='disabled' fontSize='large' />
          <Typography color={theme => theme.palette.grey[700]} variant='body2'>
            Not applicable
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};
