import { CellComponentProps } from '@/components/collapsible-table';
import formatters from '@/utils/Formatters';
import { Box, Link, TableCell } from '@mui/material';

import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export const ForfeiturePendingTransactionsTableCell: FC<
  CellComponentProps
> = props => {
  let field = <Box fontSize='1rem'>{props.row[props.column.field]}</Box>;

  if (props.column.field === 'createdAt') {
    field = (
      <Box fontSize='1rem'>
        {formatters.formatFromIsoDateCustom(props.row.createdAt, 'MM/DD/YYYY')}
      </Box>
    );
  }

  if (props.column.field === 'amount') {
    field = (
      <Box fontSize='1rem'>{formatters.formatDollars(props.row.amount)}</Box>
    );
  }

  if (props.column.field === 'ucid') {
    field = (
      <Link
        component={RouterLink}
        to={`/plans/${props.row.sponsorPlanId}/contributions/${props.row.ucid}/overview`}>
        {props.row.ucid}
      </Link>
    );
  }

  return (
    <TableCell component='th' scope='row'>
      {field}
    </TableCell>
  );
};
