import { CONVERSION_TYPES } from '@/consts/uploads';
import { Conversion } from '@/models/ConversionDTO.model';
import InMemoryFileDownloadService from '@/services/InMemoryFileDownloadService.service';
import { PlanService } from '@/services/Plan.service';
import formatters from '@/utils/Formatters';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Stack,
  styled,
  Typography,
  useTheme
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import React, { ReactNode } from 'react';

import { YearsOfService } from '../../PlanUploads/YearsOfService.component';
import CashAllocation from '../CashAllocation.component';
import DeferralRates from '../DeferralRates.component';
import { convertFileToCsv } from '../helpers/jsonToCsvConverter';
import { InvestmentElectionMapping } from '../InvestmentElectionMapping.component';
import LoansConversion from '../LoansConversion.component';
import RothCostBasis from '../RothCostBasis.component';
import RequestStatus from './RequestStatus.component';

interface ConversionCardProps {
  title: string;
  sponsorPlanId: number;
  conversionData: Conversion[];
  component: ReactNode;
}

interface ConversionDetailsCardProps {
  sponsorPlanId: number;
  data: Conversion;
}

const StyledFieldValue = styled(Typography)(() => ({
  color: '#424242'
}));

const StyledFieldKey = styled(Typography)(() => ({
  color: '#737373'
}));

const ConversionDetailsCard: React.FunctionComponent<
  ConversionDetailsCardProps
> = ({ data, sponsorPlanId }: ConversionDetailsCardProps) => {
  const { mutate: downloadDocument, isLoading: isDownloading } = useMutation(
    ['PlanService.getDocumentForPlanId', sponsorPlanId, data?.documentId],
    () => {
      return PlanService.getDocumentForPlanId(sponsorPlanId, data?.documentId);
    },
    {
      onSuccess: async response => {
        const file = convertFileToCsv(
          response.base64Data,
          response.originalFileName,
          data.conversionType
        );

        InMemoryFileDownloadService.triggerFileDownload(
          file.fileData,
          file.fileName
        );
      }
    }
  );

  const actionBtns = (type: string) => {
    switch (type) {
      case CONVERSION_TYPES.CASH_ALLOCATION:
        return (
          <CashAllocation conversion={data} sponsorPlanId={sponsorPlanId} />
        );
      case CONVERSION_TYPES.LOANS:
        return (
          <LoansConversion conversion={data} sponsorPlanId={sponsorPlanId} />
        );
      case CONVERSION_TYPES.DEFERRAL_RATES:
        return (
          <DeferralRates conversion={data} sponsorPlanId={sponsorPlanId} />
        );
      case CONVERSION_TYPES.ROTH_COST_BASIS:
        return (
          <RothCostBasis conversion={data} sponsorPlanId={sponsorPlanId} />
        );
      case CONVERSION_TYPES.HISTORICAL_YEARS_OF_SERVICE:
        return (
          <YearsOfService conversion={data} sponsorPlanId={sponsorPlanId} />
        );
      case CONVERSION_TYPES.INVESTMENT_ELECTION_MAPPINGS:
        return (
          <InvestmentElectionMapping
            conversion={data}
            sponsorPlanId={sponsorPlanId}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Card sx={{ mb: 1, mt: 2, padding: '15px 12px' }}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between'
        }}>
        <Typography data-testid='conversion-header-filename-id' variant='body1'>
          {data.fileName.replace('.json', '.csv')}
        </Typography>
        <Stack
          alignItems='center'
          direction='row'
          divider={<Divider flexItem orientation='vertical' />}
          marginRight={1}
          spacing={2}>
          {actionBtns(data.conversionType)}
          <IconButton
            disabled={isDownloading}
            onClick={() => downloadDocument()}>
            <FileDownloadOutlinedIcon />
          </IconButton>
        </Stack>
      </Box>
      <CardContent sx={{ p: '15px 0' }}>
        <Stack
          direction='row'
          divider={<Divider flexItem orientation='vertical' />}
          spacing={2}>
          <Box sx={{ width: '60%' }}>
            <Grid
              columnSpacing={{ md: 3, sm: 2, xs: 1 }}
              container
              rowSpacing={1}
              sx={{ alignItems: 'center' }}>
              {data?.status && (
                <>
                  <Grid item xs={4}>
                    <StyledFieldKey variant='body2'>Status</StyledFieldKey>
                  </Grid>
                  <Grid data-testid='status' item xs={8}>
                    <Box
                      display='flex'
                      fontSize='1.25rem'
                      justifyContent='center'>
                      <RequestStatus status={data.status} />
                    </Box>
                  </Grid>
                </>
              )}
              {data?.context?.allocatedAmount !== undefined && (
                <>
                  <Grid item xs={6}>
                    <StyledFieldKey variant='body2'>
                      Allocated Amount
                    </StyledFieldKey>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledFieldValue variant='body2'>
                      {formatters.formatDollars(
                        data.context.allocatedAmount +
                          (data.context?.forfeiture ?? 0) +
                          (data.context?.suspense ?? 0)
                      )}
                    </StyledFieldValue>
                  </Grid>
                </>
              )}
              {!!data?.context?.tradeAmount && (
                <>
                  <Grid item xs={6}>
                    <StyledFieldKey variant='body2'>
                      Trade Amount
                    </StyledFieldKey>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledFieldValue variant='body2'>
                      {formatters.formatDollars(data.context.tradeAmount)}
                    </StyledFieldValue>
                  </Grid>
                </>
              )}
              {!!data?.investmentElectionMappings && (
                <>
                  <Grid item xs={6}>
                    <StyledFieldKey variant='body2'>Employees</StyledFieldKey>
                  </Grid>
                  <Grid data-testid='employees' item xs={6}>
                    <StyledFieldValue variant='body2'>
                      {data?.investmentElectionMappings?.participantData
                        ?.length ?? 0}
                    </StyledFieldValue>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledFieldKey variant='body2'>Funds</StyledFieldKey>
                  </Grid>
                  <Grid data-testid='employees' item xs={6}>
                    <StyledFieldValue variant='body2'>
                      {data?.investmentElectionMappings?.fundMappings?.length ??
                        0}
                    </StyledFieldValue>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
          <Box sx={{ width: '60%' }}>
            <Grid
              columnSpacing={{ md: 3, sm: 2, xs: 1 }}
              container
              rowSpacing={1}>
              <Grid item xs={5}>
                <StyledFieldKey variant='body2'>Upload Date</StyledFieldKey>
              </Grid>
              <Grid data-testid='uploadDate' item xs={7}>
                <StyledFieldValue variant='body2'>
                  {formatters.formatFromIsoDateCustom(
                    data?.createdAt,
                    'M/DD/YY'
                  )}
                </StyledFieldValue>
              </Grid>
              {data?.submittedAt && (
                <>
                  <Grid item xs={4}>
                    <StyledFieldKey variant='body2'>
                      Submitted At
                    </StyledFieldKey>
                  </Grid>
                  <Grid data-testid='submittedAt' item xs={8}>
                    <StyledFieldValue variant='body2'>
                      {formatters.formatFromIsoDateCustom(
                        data.submittedAt,
                        'M/DD/YY HH:mm:ss'
                      )}
                    </StyledFieldValue>
                  </Grid>
                </>
              )}
              {data?.submittedBy?.id && (
                <>
                  <Grid item xs={5}>
                    <StyledFieldKey variant='body2'>
                      Submitted By
                    </StyledFieldKey>
                  </Grid>
                  <Grid data-testid='submittedBy' item xs={7}>
                    <StyledFieldValue variant='body2'>
                      {data.submittedBy.id}
                    </StyledFieldValue>
                  </Grid>
                </>
              )}
              {data?.approvedBy?.id && (
                <>
                  <Grid item xs={5}>
                    <StyledFieldKey variant='body2'>Approved By</StyledFieldKey>
                  </Grid>
                  <Grid item xs={7}>
                    <StyledFieldValue variant='body2'>
                      {data.approvedBy.id}
                    </StyledFieldValue>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

const ConversionCard: React.FunctionComponent<ConversionCardProps> = ({
  title,
  component,
  conversionData,
  sponsorPlanId
}: ConversionCardProps) => {
  const theme = useTheme();

  return (
    <Card
      data-testid={`${title} Conversion`}
      sx={{ marginTop: 5 }}
      variant='outlined'>
      <CardHeader
        action={component}
        data-testid='conversionTitle'
        sx={{ borderBottom: `1px solid ${theme.palette.grey[300]}` }}
        title={title}
      />
      <CardContent
        data-testid='conversionData'
        sx={
          conversionData.length === 0
            ? {
                alignItems: 'center',
                backgroundColor: theme.palette.grey[50],
                display: 'flex',
                flexDirection: 'column',
                height: '500px',
                justifyContent: 'center',
                pt: 0.5
              }
            : {
                backgroundColor: theme.palette.grey[50],
                height: '500px',
                overflow: 'auto',
                pt: 0.5
              }
        }>
        {conversionData.length === 0 && (
          <Typography sx={{ textAlign: 'center' }} variant='subtitle1'>
            No Data
          </Typography>
        )}
        {conversionData.map((d, i) => (
          <ConversionDetailsCard
            data={d}
            key={`conversion-card-${d.id}+${i}`}
            sponsorPlanId={sponsorPlanId}
          />
        ))}
      </CardContent>
    </Card>
  );
};

export default ConversionCard;
