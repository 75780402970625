import AccessControl from '@/components/access-control/AccessControl.component';
import CopyToClipboard from '@/components/copy-to-clipboard';
import { VESTWELL_TPA } from '@/components/entity-modals/EntityModals.component';
import VerticalTabs from '@/components/vertical-tabs/VerticalTabs.component';
import { useOpsTooling } from '@/contexts/OpsToolingContext';
import usePayrollSetups from '@/hooks/usePayrollSetups';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { CompanyBankAccountsTab } from '@/routes/plans/plan-detail/PlanCompanyInfoCard/CompanyBankAccountsTab.component';
import { CompanyPayrollContactsTab } from '@/routes/plans/plan-detail/PlanCompanyInfoCard/CompanyPayrollContactsTab.component';
import ControlGroupTab from '@/routes/plans/plan-detail/PlanCompanyInfoCard/ControlGroupTab.component';
import { OpsToolingType } from '@/services/payroll-integrations.service';
import { MoreVert } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Theme,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import AuthorizedSignerTab from './AuthorizedSignerTab.component';
import CompanyInfoTab from './CompanyInfoTab.component';
import MainContactTab from './MainContactTab.component';
import { TpaContactsTab } from './TpaContactsTab.component';

interface PlanCompanyInfoCardProps {
  sponsorId: string | number;
  sponsorPlanId: string | number;
  isEsa: boolean;
  isStateIRA: boolean;
  isVoyaPlan: boolean;
  tpaId?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  anchorIcon: {
    marginLeft: theme.spacing(2)
  },
  contentBody: {
    paddingBottom: 0
  },
  copyIcon: {
    color: theme.palette.text.secondary,
    height: theme.spacing(2)
  },
  darkSubtitle: {
    color: 'rgb(0, 0, 0, 0.87)',
    fontSize: 13,
    letterSpacing: 0.25
  },
  leftStack: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: theme.spacing(3)
  },
  root: {
    marginBottom: theme.spacing(2)
  },
  sponsorIdContainer: {
    alignItems: 'center',
    border: `1px solid ${grey[300]}`,
    borderRadius: '100px',
    display: 'flex',
    padding: `2px 8px`
  },
  tab: {
    alignItems: 'start',
    padding: 0,
    paddingRight: '16px',
    width: '240px'
  },
  titleContainer: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey[300],
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2.5)
  }
}));

export const PlanCompanyInfoCard: React.FC<
  PlanCompanyInfoCardProps
> = props => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const onClose = useCallback(() => {
    setAnchor(null);
  }, []);
  const { showOpsToolingDrawer } = useOpsTooling();

  const payrollSetupsQuery = usePayrollSetups(+props.sponsorPlanId);
  const isApiPlan = payrollSetupsQuery.data?.isApiPlan || false;

  const handleOpsToolingMenuClick = useCallback(() => {
    showOpsToolingDrawer(+props.sponsorPlanId, OpsToolingType.companyInfo);
    onClose();
  }, [props.sponsorPlanId]);

  useEffect(() => {
    if (props.isVoyaPlan) {
      navigate(location.pathname);
    }
  }, [navigate, props.isVoyaPlan, location.pathname]);

  const tabs = useMemo(
    () =>
      [
        {
          component: (
            <CompanyInfoTab
              isStateIRA={props.isStateIRA}
              isVoyaPlan={props.isVoyaPlan}
              sponsorId={props.sponsorId}
              sponsorPlanId={props.sponsorPlanId}
            />
          ),
          label: 'Company'
        },
        {
          component: !props.isEsa ? (
            <ControlGroupTab
              isStateIRA={props.isStateIRA}
              sponsorId={props.sponsorId}
              sponsorPlanId={+props.sponsorPlanId}
            />
          ) : null,
          label: 'Control Group'
        },
        {
          component: (
            <MainContactTab
              isVWPlan={!props.isStateIRA && !props.isVoyaPlan}
              sponsorId={props.sponsorId}
              sponsorPlanId={props.sponsorPlanId}
            />
          ),
          label: 'Main Contact'
        },
        {
          component:
            !props.isStateIRA && !props.isVoyaPlan && !props.isEsa ? (
              <AuthorizedSignerTab
                sponsorId={props.sponsorId}
                sponsorPlanId={props.sponsorPlanId}
              />
            ) : null,
          label: 'Authorized Signer'
        },
        {
          component: !props.isVoyaPlan ? (
            <CompanyBankAccountsTab
              sponsorId={+props.sponsorId}
              sponsorPlanId={+props.sponsorPlanId}
            />
          ) : null,
          label: 'Bank Accounts'
        },
        {
          component:
            !props.isVoyaPlan && !props.isStateIRA ? (
              <CompanyPayrollContactsTab sponsorId={+props.sponsorId} />
            ) : null,
          label: 'Payroll Contacts'
        },
        {
          component: props.tpaId ? (
            props.tpaId === VESTWELL_TPA.ID ? null : (
              <TpaContactsTab
                sponsorPlanId={+props.sponsorPlanId}
                tpaId={props.tpaId}
              />
            )
          ) : null,
          label: 'TPA Contacts'
        }
      ].filter(tab => !!tab.component),
    [props]
  );

  return (
    <Card
      className={classes.root}
      data-testid='plan-company-info-tile'
      elevation={0}
      variant='outlined'>
      <Stack className={classes.titleContainer} direction='row' spacing={3}>
        <Stack className={classes.leftStack}>
          <Typography variant='h5'>Company Info</Typography>
          <div
            className={classes.sponsorIdContainer}
            data-testid='sponsor-id-container'>
            <Typography
              className={classes.darkSubtitle}
              data-testid='sponsor-id'
              display='inline'>
              {props.isStateIRA ? 'Employer ID' : 'Sponsor ID'}:{' '}
              {props.sponsorId}
            </Typography>
            <CopyToClipboard
              copyName={props.isStateIRA ? 'Employer ID' : 'Sponsor ID'}
              copyValue={props.sponsorId.toString()}
              iconCssClass={classes.copyIcon}
              size='small'
            />
          </div>
        </Stack>

        <AccessControl
          requires={[FeatureLevelPermissions.READ_COMPANY_PAYROLL]}>
          {isApiPlan && (
            <>
              <IconButton
                aria-controls={anchor ? 'ops-tooling-menu' : undefined}
                aria-expanded={!!anchor}
                aria-haspopup='true'
                className={classes.anchorIcon}
                data-testid='openMenuButton'
                onClick={e => setAnchor(e.currentTarget)}
                size='small'>
                <MoreVert />
              </IconButton>
              <Menu
                anchorEl={anchor}
                id='ops-tooling-menu'
                onClick={onClose}
                onClose={onClose}
                open={!!anchor}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}>
                <MenuItem onClick={handleOpsToolingMenuClick}>
                  View Payroll Provider Data - Company Data
                </MenuItem>
              </Menu>
            </>
          )}
        </AccessControl>
      </Stack>

      <CardContent
        className={classes.contentBody}
        data-testid='plan-company-info-card-content-body'>
        <Box>
          {!props.isVoyaPlan ? (
            <VerticalTabs
              className={classes.tab}
              data-testid='plan-company-info-card'
              tabs={tabs}
            />
          ) : (
            <CompanyInfoTab
              isStateIRA={props.isStateIRA}
              isVoyaPlan={props.isVoyaPlan}
              sponsorId={props.sponsorId}
              sponsorPlanId={props.sponsorPlanId}
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

PlanCompanyInfoCard.displayName = 'PlanCompanyInfoCard';
