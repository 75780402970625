import Card, { CardHeader } from '@/components/card';
import CollapsibleTable, {
  CellComponentProps
} from '@/components/collapsible-table';
import { Program } from '@/models/ops/investments/Program.model';
import NoInvestmentOptionLinked from '@/routes/ops/investments//NoInvestmentOptionLinked.component';
import { FundLineupSearchData } from '@/routes/ops/investments/fund-lineup/InvestmentFundLineup.component';
import { FundLineupService } from '@/services/ops/investments/FundLineup.service';
import { Box, Link, TableCell, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { FC } from 'react';

const useTableCellStyles = makeStyles((theme: Theme) =>
  createStyles({
    size: {
      fontSize: theme.spacing(2)
    }
  })
);

const FundLineupTableCell: FC<CellComponentProps> = (
  cellProps: CellComponentProps
) => {
  const classes = useTableCellStyles();
  const field = cellProps.row[cellProps.column.field];

  return (
    <TableCell className={classes.size} component='th' scope='row'>
      <Box>
        {cellProps.column.field === 'name' ? (
          <Link
            href={`/ops/investments/fund-lineup/${cellProps.row.fundLineupId}`}
            rel='noopener noreferrer'
            target='_blank'>
            {field}
          </Link>
        ) : (
          field
        )}
      </Box>
    </TableCell>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    createdDateWidth: {
      width: '20%'
    },
    fundLineupIdWidth: {
      width: '10%'
    },
    fundLineupNameWidth: {
      width: '70%'
    }
  })
);

type Program401kManagerProps = {
  programData: Program;
  refetchProgram: () => void;
};

export const Program401kManager: FC<Program401kManagerProps> = props => {
  const classes = useStyles();

  const ms401kManagerColumns: GridColDef[] = [
    {
      cellClassName: classes.fundLineupIdWidth,
      field: 'fundLineupId',
      headerName: 'ID'
    },
    {
      cellClassName: classes.fundLineupNameWidth,
      field: 'name',
      headerName: 'Name'
    },
    {
      cellClassName: classes.createdDateWidth,
      field: 'createdAt',
      headerName: 'Created Date'
    },
    { field: 'programCount', headerName: 'Programs' }
  ];

  const fundLineupSearchResult = useQuery<{
    data: FundLineupSearchData[];
    meta: { count: number };
  }>(
    [
      'FundLineupService.getFundLineupPage',
      props.programData.programId,
      props.programData.ms401kManagerLineUp
    ],
    () =>
      FundLineupService.getFundLineupPage(
        1,
        1,
        'name',
        'asc',
        undefined,
        props.programData.ms401kManagerLineUp
      )
  );

  return (
    <Card>
      <CardHeader title='401(k) Manager Investment Option' />
      <CollapsibleTable
        cellComponent={FundLineupTableCell}
        columns={ms401kManagerColumns}
        noDataPlaceholderComponent={
          <NoInvestmentOptionLinked typeName='401(k) Manager' />
        }
        tableData={fundLineupSearchResult?.data?.data ?? []}
      />
    </Card>
  );
};

Program401kManager.displayName = 'Program401kManager';
