import useHasPermissions from '@/components/access-control/useHasPermissions.hook';
import { SnackbarOptions } from '@/contexts/SnackBarContext';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { ActionCenterService } from '@/services/ActionCenter.service';
import { QueryClient, useMutation } from '@tanstack/react-query';

import { useCallback, useMemo } from 'react';

import { ActionCenterDetailsBulkEmployer } from './ActionCenterDetailsBulkEmployer.component';
import { BulkEmployerPreviewDialog } from './BulkEmployerPreviewDialog.component';
import {
  BulkActionsConfig,
  previewColumns
} from './useBulkActionCenterConfig.hook';

export const useBulkUploadEmployerListConfig = (
  details: Record<string, any>,
  setDetails: React.Dispatch<React.SetStateAction<Record<string, any>>>,
  setActiveStep: React.Dispatch<React.SetStateAction<number>>,
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>,
  showSnackbar: (options: SnackbarOptions) => void,
  queryClient: QueryClient
) => {
  const permissions = useHasPermissions({
    requires: [FeatureLevelPermissions.WRITE_STATE_IRA_EMPLOYER_BULK_UPLOAD]
  });

  const bulkUploadEmployerListMutation = useMutation(
    ['ActionCenterService.bulkUploadEmployerList'],
    (data: FormData) => ActionCenterService.bulkUploadEmployerList(data),
    {
      onError: () =>
        showSnackbar({
          message:
            'There was an error while validating the file, please try again',
          severity: 'error'
        }),
      onSuccess: () => {
        setDialogOpen(true);
        queryClient.invalidateQueries({
          queryKey: ['ActionCenterService.bulkEmployerList']
        });
        showSnackbar({
          message: 'File uploaded succesfully',
          severity: 'success'
        });
      }
    }
  );

  const previewBulkUploadFiles = useCallback(() => {
    const formData = new FormData();
    formData.append('csvFile', details.csvFile, details.csvName);
    bulkUploadEmployerListMutation.mutate(formData);
  }, [details]);

  const bulkUploadFilesConfig = useMemo<BulkActionsConfig>(
    () => ({
      isPreviewError:
        !!bulkUploadEmployerListMutation.data?.data?.summary?.invalidRows,
      isPreviewLoading: bulkUploadEmployerListMutation.isLoading,
      noPreviewDataMessage: 'No data from validation response',
      onConfirm: () => {
        setDialogOpen(false);
      },
      onPreview: previewBulkUploadFiles,
      previewColumns,
      previewData: bulkUploadEmployerListMutation.data?.data,
      previewDialog: props => (
        <BulkEmployerPreviewDialog
          data={props.data}
          handleClose={props.handleClose}
          onConfirm={props.onConfirm}
          open={props.open}
        />
      ),
      step2: (
        <ActionCenterDetailsBulkEmployer
          details={details}
          setDetails={newDetails => {
            setDetails(newDetails);
            setActiveStep(newDetails.csvFile ? 3 : 1);
          }}
        />
      ),
      step3: `Not applicable to this action`,
      visible: permissions.isAllowed
    }),
    [bulkUploadEmployerListMutation, permissions]
  );

  return bulkUploadFilesConfig;
};
