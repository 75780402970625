import DetailsPage from '@/components/details-page';
import LinearLoading from '@/components/linear-loading';
import SimpleTabs from '@/components/simple-tabs';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { useUserToken } from '@/contexts/UserTokenContext';
import { PooledPlan } from '@/models/PooledPlanDTO.model';
import { PlanTab } from '@/routes/plans/plan-detail/PlanTab/PlanTab.component';
import Adopters from '@/routes/plans/pooled-plan-detail/AdoptersTab/Adopters.component';
import { PlanService } from '@/services/Plan.service';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';

import React from 'react';
import { useParams } from 'react-router-dom';

type PooledPlanDetailRouteParams = {
  planId: string;
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 'max'
  }
}));

const PooledPlanDetailRoute = (): JSX.Element => {
  const classes = useStyles();
  const { planId } = useParams<PooledPlanDetailRouteParams>();
  const { userHasValidToken } = useUserToken();

  const pooledPlanQuery = useQuery<PooledPlan>(
    ['PlanService.getPooledPlanById', planId],
    () => PlanService.getPooledPlanById(planId),
    {
      enabled: userHasValidToken && Boolean(planId),
      staleTime: Infinity
    }
  );

  const tabs = {
    tabs: [
      {
        component: (
          <PlanTab
            isPooledPlan
            pooledPlanData={pooledPlanQuery.data as PooledPlan}
            sponsorPlanId={planId}
          />
        ),
        label: 'Plan',
        path: `/pooled-plans/${planId}/plan`
      },
      {
        component: (
          <Adopters
            pooledPlanId={+planId}
            pooledPlanStatus={pooledPlanQuery.data?.pooledPlanStatus}
          />
        ),
        label: 'Adopters',
        path: `/pooled-plans/${planId}/adopters`
      }
    ],
    tabsAriaLabel: 'pooled-plan-detail-tabs'
  };

  return (
    <>
      {pooledPlanQuery.isFetching && <LinearLoading />}
      <Grid className={classes.root} container>
        <Grid item xs={8}>
          <DetailsPage
            copyableNumber={Number(planId)}
            paths={[
              {
                name: 'Pooled Plans',
                to: '/pooled-plans'
              }
            ]}
            title={
              pooledPlanQuery.data?.pooledPlanName || EMPTY_FIELD_PLACEHOLDER
            }
            variant='sponsorPlan'
          />
        </Grid>
      </Grid>
      <Grid container item spacing={2} xs={12}>
        <Grid item xs={12}>
          <SimpleTabs {...tabs} />
        </Grid>
      </Grid>
    </>
  );
};

export default PooledPlanDetailRoute;
