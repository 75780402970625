import { SubaValidationError } from '@/models/WithdrawalsDTO.model';

import { groupBy, reduce, set } from 'lodash';
import { useEffect, useState } from 'react';

export type SubaErrors = {
  hasAccountErrors: boolean;
  accountErrors: string;
  hasDisbursementsErrors: boolean;
  disbursementsErrors: string;
  hasOtherErrors: boolean;

  otherErrors: string;

  indexedErrors: object;
};
export function useSubaErrors() {
  const [subaErrors, setSubaErrors] = useState<SubaErrors>({
    accountErrors: '',
    disbursementsErrors: '',
    hasAccountErrors: false,
    hasDisbursementsErrors: false,
    hasOtherErrors: false,
    indexedErrors: {},
    otherErrors: ''
  });

  const [backendErrors, setBackendErrors] = useState({});

  const createIndexedErrors = (errors: object) => {
    return reduce(
      errors,
      (result, value, key) => {
        const [category, index, property] = key.split(/[.[\]]+/);
        result[category] = result[category] || [];
        result[category][index] = result[category][index] || {};
        result[category][index][property] = value;
        return result;
      },
      {}
    );
  };

  useEffect(() => {
    const errors = {
      accountErrors: backendErrors['accountsResponse'] ?? '',
      disbursementsErrors: backendErrors['disbursementsResponse'] ?? '',
      hasAccountErrors: Object.keys(backendErrors).some(
        k => k === 'accountsResponse'
      ),
      hasDisbursementsErrors: Object.keys(backendErrors).some(
        k => k === 'disbursementsResponse'
      ),
      hasOtherErrors: Object.keys(backendErrors).some(
        k => k === 'saveResponse'
      ),
      indexedErrors: createIndexedErrors(
        Object.keys(backendErrors)
          .filter(k => k.includes('['))
          .reduce((result, key) => {
            result[key] = backendErrors[key];
            return result;
          }, {})
      ),
      otherErrors: backendErrors['saveResponse'] ?? ''
    };
    setSubaErrors(errors);
  }, [backendErrors]);
  const checkSubaErrors = (errors: SubaValidationError[]) => {
    const foundErrors = groupBy(
      errors.map(e => {
        return {
          fieldId: ['accounts', 'account'].includes(e.source)
            ? 'accountsResponse'
            : e.source === 'disbursements'
            ? 'disbursementsResponse'
            : e.source.includes('[')
            ? `${e.source}.error`
            : 'saveResponse',
          msg: `Error: ${e.error}, hint: ${e.hint}`
        };
      }),
      err => err.fieldId
    );

    Object.keys(foundErrors).forEach(key => {
      set(foundErrors, key, foundErrors[key].map(i => i.msg).join('\n'));
    });

    setBackendErrors(foundErrors);
  };

  return {
    checkSubaErrors,
    setSubaErrors,
    subaErrors
  };
}
