import Constants from '@/consts/planDesign.constants';

class DocumentMatchFrequency {
  options = Constants.documentMatchFrequencyOptions;

  mapValueToOption: Record<string, string> = {
    'N/A - No Match': 'N/A - No Match',
    annually: 'Annually',
    'per pay-period': 'Per pay-period',
    quarterly: 'Quarterly'
  };

  mapOptionToValue: Record<string, string> = {
    Annually: 'annually',
    'N/A - No Match': 'N/A - No Match',
    'Per pay-period': 'per pay-period',
    Quarterly: 'quarterly'
  };

  convertToApiState = (value: string | undefined): string | undefined => {
    if (!value) {
      return undefined;
    }

    return this.mapOptionToValue[value];
  };

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.output = this.mapValueToOption[props.documentMatchFrequency];
  }
}

export default DocumentMatchFrequency;
