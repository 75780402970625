import AccessControl from '@/components/access-control/AccessControl.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { OrganizationUser } from '@/models/OrganizationUser.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import TpaService from '@/services/Tpa.service';
import { Button, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import React from 'react';

interface ActionTpaBtnProps {
  isRegistered: boolean;
  user: OrganizationUser;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionBtn: {
      fontSize: theme.spacing(2),
      textTransform: 'none'
    }
  })
);

const ActionTpaBtn = (props: ActionTpaBtnProps): JSX.Element => {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const { isRegistered, user } = props;
  const queryClient = useQueryClient();

  const resendInviteMutation = useMutation(
    (data: { email: string; organizationId: string; role: string }) => {
      return TpaService.inviteTpaUser(
        data.email,
        data.organizationId,
        data.role
      );
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Something went wrong. Please try again',
          severity: 'error'
        });
      },
      onSuccess: async () => {
        await queryClient.refetchQueries([
          'TpaService.getTpaInvites',
          user.organizationId?.toString()
        ]);
        showSnackbar({
          message: ' Invite was successfully resent!',
          severity: 'success'
        });
      }
    }
  );

  const removeInviteMutation = useMutation(
    (data: { organizationId: string; inviteId: string }) => {
      return TpaService.revokeTpaUserInvite(data.organizationId, data.inviteId);
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Something went wrong. Please try again',
          severity: 'error'
        });
      },
      onSuccess: async () => {
        await queryClient.refetchQueries([
          'TpaService.getTpaInvites',
          user.organizationId?.toString()
        ]);

        showSnackbar({
          message: 'Invite was successfully removed!',
          severity: 'success'
        });
      }
    }
  );

  const removeRegisteredTpaUserMutation = useMutation(
    (data: { organizationId: string; userId: string }) => {
      return TpaService.removeTpaMember(data.organizationId, data.userId);
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Something went wrong. Please try again',
          severity: 'error'
        });
      },
      onSuccess: async () => {
        await queryClient.refetchQueries([
          'TpaService.getTpaMembers',
          user.organizationId?.toString()
        ]);

        showSnackbar({
          message: 'User was successfully removed!',
          severity: 'success'
        });
      }
    }
  );

  const removeTpaUser = async (orgUser: OrganizationUser) => {
    const { organizationId, userId = '', inviteId = '' } = orgUser;

    if (isRegistered) {
      await removeRegisteredTpaUserMutation.mutateAsync({
        organizationId,
        userId
      });
    } else {
      await removeInviteMutation.mutateAsync({ inviteId, organizationId });
    }
  };

  const resendUserInvite = async (orgUser: OrganizationUser) => {
    const { email, organizationId, role } = orgUser;
    await resendInviteMutation.mutateAsync({ email, organizationId, role });
  };

  return (
    <AccessControl
      requires={[FeatureLevelPermissions.WRITE_TPA_DETAILS_MANAGE_USERS]}>
      {!isRegistered && (
        <>
          <Button
            className={classes.actionBtn}
            data-testid='tpa-resend-invite-btn'
            onClick={() => resendUserInvite(user)}>
            Resend Invite
          </Button>
          <span>/</span>
        </>
      )}
      <Button
        className={classes.actionBtn}
        data-testid='tpa-remove-btn'
        onClick={() => removeTpaUser(user)}
        size='medium'>
        Remove
      </Button>
    </AccessControl>
  );
};

export default ActionTpaBtn;
