class LoanEmail {
  disabled: boolean;

  output: string | undefined;

  setDisabled = (value: boolean): void => {
    this.disabled = value;
  };

  constructor(props: Record<string, any>) {
    this.disabled =
      !props.isPooledPlan || !props.allowLoans || props.allowLoans === 'false';
    this.output = props.loanEmail;
  }
}

export default LoanEmail;
