import { CellComponentProps } from '@/components/collapsible-table';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUserToken } from '@/contexts/UserTokenContext';
import { MutateUpdateUserRelationshipsDto } from '@/models/MutateUpdateUserRelationshipsDto.model';
import { Entity } from '@/models/UserAssociationsDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import AdvisorService from '@/services/Advisor.service';
import ParticipantService from '@/services/Participant.service';
import SponsorService from '@/services/Sponsor.service';
import UserManagementService from '@/services/UserManagement.service';
import formatters from '@/utils/Formatters';
import EmailIcon from '@mui/icons-material/Email';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import LinkIcon from '@mui/icons-material/Link';
import {
  Box,
  Button,
  IconButton,
  Link,
  TableCell,
  Tooltip
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useCopyToClipboard } from 'react-use';

const UserManagementAssociationsCollapsibleTableCell: React.FunctionComponent<
  CellComponentProps
> = (cellProps: CellComponentProps) => {
  const queryClient = useQueryClient();
  const [, copyToClipboard] = useCopyToClipboard();
  const { showSnackbar } = useSnackbar();
  const { token } = useUserToken();

  const canWriteUserManagementAdvisor = Boolean(
    token?.permissions.includes(
      FeatureLevelPermissions.WRITE_USER_MANAGEMENT_ADVISOR
    )
  );
  const canWriteUserManagementParticipant = Boolean(
    token?.permissions.includes(
      FeatureLevelPermissions.WRITE_USER_MANAGEMENT_PARTICIPANT
    )
  );
  const canWriteUserManagementSponsor = Boolean(
    token?.permissions.includes(
      FeatureLevelPermissions.WRITE_USER_MANAGEMENT_SPONSOR
    )
  );
  const canWriteUserManagementVssStateSaver = Boolean(
    token?.permissions.includes(
      FeatureLevelPermissions.WRITE_USER_MANAGEMENT_VSS_STATE_SAVER
    )
  );

  const { row, column } = cellProps;
  const userSponsorRelationshipsMutation = useMutation(
    (variables: MutateUpdateUserRelationshipsDto) => {
      return SponsorService.deleteUserSponsorRelationship(row.selectedUserId, {
        entityId: variables.entityId as number
      });
    },
    {
      onError: () => {
        showSnackbar({
          message: `Failed!`,
          severity: 'error'
        });
      },
      onSuccess: async (_, variables) => {
        showSnackbar({
          message: `Success! This user has been removed from the business ${variables.item.name}`,
          severity: 'success'
        });

        await queryClient.invalidateQueries([
          'UserManagementService.getUserAssociations'
        ]);
      }
    }
  );

  const userAdvisorRelationshipsMutation = useMutation(
    (variables: MutateUpdateUserRelationshipsDto) => {
      return AdvisorService.deleteUserAdvisorRelationship(row.selectedUserId, {
        entityId: variables.entityId as number
      });
    },
    {
      onError: () => {
        showSnackbar({
          message: `Failed!`,
          severity: 'error'
        });
      },
      onSuccess: async (_, variables) => {
        showSnackbar({
          message: `Success! This user has been removed from the advisor ${variables.item.name}`,
          severity: 'success'
        });

        await queryClient.invalidateQueries([
          'UserManagementService.getUserAssociations'
        ]);
      }
    }
  );

  const userParticipantRelationshipsMutation = useMutation(
    (variables: MutateUpdateUserRelationshipsDto) => {
      return ParticipantService.deleteUserParticipantRelationship(
        row.selectedUserId,
        { entityId: variables.entityId as number }
      );
    },
    {
      onError: () => {
        showSnackbar({
          message: `Failed!`,
          severity: 'error'
        });
      },
      onSuccess: async (_, variables) => {
        showSnackbar({
          message: `Success! This user has been removed from the saver ${variables.item.name}`,
          severity: 'success'
        });

        await queryClient.invalidateQueries([
          'UserManagementService.getUserAssociations'
        ]);
      }
    }
  );

  const userVssStateSaverRelationshipsMutation = useMutation(
    (externalId: string) => {
      return UserManagementService.deleteUserVssStateSaverRelationship(
        externalId,
        row.selectedUserId
      );
    },
    {
      onError: () => {
        showSnackbar({
          message: `Failed!`,
          severity: 'error'
        });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          'UserManagementService.getUserAssociations'
        ]);

        showSnackbar({
          message: `Success! This user has been removed`,
          severity: 'success'
        });
      }
    }
  );

  const onDelete = async (item: any, id: number, type: Entity) => {
    const mutateInfo: MutateUpdateUserRelationshipsDto = {
      entityId: id,
      item
    };

    switch (type) {
      case 'sponsor':
        userSponsorRelationshipsMutation.mutate(mutateInfo);
        break;
      case 'advisor':
        userAdvisorRelationshipsMutation.mutate(mutateInfo);
        break;
      case 'participant':
        userParticipantRelationshipsMutation.mutate(mutateInfo);
        break;
      default:
        break;
    }
  };

  const onVssStateSaverDelete = async (externalId: string) => {
    await userVssStateSaverRelationshipsMutation.mutateAsync(externalId);
  };

  const rolesInSponsorOrAdvisorRole = ['1', '3', '4', '5', '6'];

  const getSponsorUserInvite = async (id: number, email: string) => {
    const queryData = await queryClient.fetchQuery(
      ['SponsorService.getSponsorUserInvites', id, email],
      () => SponsorService.getSponsorUserInvites(id, email, true),
      {
        staleTime: Infinity
      }
    );
    return queryData;
  };

  const createSponsorUserInvite = async (id: number, email: string) => {
    const queryData = await getSponsorUserInvite(id, email);
    const newInviteCode = await SponsorService.createSponsorUserInvite(
      id,
      email,
      rolesInSponsorOrAdvisorRole
    );
    const newQueryData = { ...queryData };
    newQueryData.sponsorUserInvites = [
      ...(queryData?.sponsorUserInvites || []),
      newInviteCode.data
    ];
    newQueryData.sponsorUserInviteCode =
      newInviteCode.data.attributes.inviteCode;
    newQueryData.sponsorUserInviteExpiresAt =
      newInviteCode.data.attributes.expiresAt;
    newQueryData.relationships = newInviteCode.data.relationships;
    queryClient.setQueryData(
      ['SponsorService.getSponsorUserInvites', id, email],
      newQueryData
    );
    return newInviteCode;
  };

  const copyOrSendSponsorUserInvite = async (
    id: number,
    email: string,
    action: 'copy' | 'send'
  ) => {
    const queryData = await getSponsorUserInvite(id, email);
    if (
      queryData?.sponsorUserInviteCode &&
      new Date() < new Date(queryData?.sponsorUserInviteExpiresAt || '')
    ) {
      let snackBarMessage = `Success! Invite email sent to ${email}`;
      if (action === 'copy') {
        copyToClipboard(
          queryData?.registrationUrl + queryData?.sponsorUserInviteCode
        );
        snackBarMessage = `Success! Invite link copied.`;
      } else {
        await SponsorService.sendSponsorUserInvite(
          id,
          email,
          queryData.sponsorUserInviteCode
        );
      }
      return snackBarMessage;
    } else {
      const newInviteCode = await createSponsorUserInvite(id, email);
      let snackBarMessage = `Success! New invite link created and sent to ${email}`;
      if (action === 'copy') {
        copyToClipboard(
          queryData?.registrationUrl + newInviteCode.data.attributes.inviteCode
        );
        snackBarMessage = `Success! New invite link created and copied to clipboard.`;
      } else {
        await SponsorService.sendSponsorUserInvite(
          id,
          email,
          newInviteCode.data.attributes.inviteCode
        );
      }
      return snackBarMessage;
    }
  };

  const getAdvisorUserInvite = async (id: number, email: string) => {
    const queryData = await queryClient.fetchQuery(
      ['AdvisorService.getAdvisorUserInvites', id, email],
      () => AdvisorService.getAdvisorUserInvites(id, email, true),
      {
        staleTime: Infinity
      }
    );
    return queryData;
  };

  const createAdvisorUserInvite = async (id: number, email: string) => {
    const queryData = await getAdvisorUserInvite(id, email);
    const newInviteCode = await AdvisorService.createAdvisorUserInvite(
      id,
      email,
      rolesInSponsorOrAdvisorRole
    );

    const newQueryData = { ...queryData };
    newQueryData.advisorUserInvites = [
      ...(queryData?.advisorUserInvites || []),
      newInviteCode.data
    ];

    newQueryData.advisorUserInviteCode =
      newInviteCode.data.attributes.inviteCode;
    newQueryData.advisorUserInviteExpiresAt =
      newInviteCode.data.attributes.expiresAt;
    newQueryData.relationships = newInviteCode.data.relationships;
    queryClient.setQueryData(
      ['AdvisorService.getAdvisorUserInvites', id, email],
      newQueryData
    );
    return newInviteCode;
  };

  const copyOrSendAdvisorUserInvite = async (
    id: number,
    email: string,
    action: 'copy' | 'send'
  ) => {
    const queryData = await getAdvisorUserInvite(id, email);
    if (
      queryData?.advisorUserInviteCode &&
      new Date() < new Date(queryData?.advisorUserInviteExpiresAt || '')
    ) {
      let snackBarMessage = `Success! Invite email sent to ${email}`;
      if (action === 'copy') {
        copyToClipboard(
          queryData?.registrationUrl + queryData?.advisorUserInviteCode
        );
        snackBarMessage = `Success! Invite link copied.`;
      } else {
        await AdvisorService.sendAdvisorUserInvite(
          id,
          email,
          queryData.advisorUserInviteCode
        );
      }
      return snackBarMessage;
    } else {
      const newInviteCode = await createAdvisorUserInvite(id, email);
      let snackBarMessage = `Success! New invite link created and sent to ${email}`;
      if (action === 'copy') {
        copyToClipboard(
          queryData?.registrationUrl + newInviteCode.data.attributes.inviteCode
        );
        snackBarMessage = `Success! New invite link created and copied to clipboard.`;
      } else {
        await AdvisorService.sendAdvisorUserInvite(
          id,
          email,
          newInviteCode.data.attributes.inviteCode
        );
      }
      return snackBarMessage;
    }
  };

  const onCopyOrSendUserInviteLink = async (
    id: number,
    email: string,
    type: 'sponsor' | 'advisor' | 'participant',
    action: 'copy' | 'send'
  ) => {
    let snackBarMessage = '';
    try {
      switch (type) {
        case 'sponsor':
          snackBarMessage = await copyOrSendSponsorUserInvite(
            id,
            email,
            action
          );
          break;
        case 'advisor':
          snackBarMessage = await copyOrSendAdvisorUserInvite(
            id,
            email,
            action
          );
          break;
        default:
          break;
      }
      showSnackbar({
        message: snackBarMessage,
        severity: 'success'
      });
    } catch (error) {
      showSnackbar({
        message: `Failed!`,
        severity: 'error'
      });
    }
  };

  let field = (
    <Box>
      {formatters.displayValueOrEmptyFieldPlaceholder(row[column.field])}
    </Box>
  );
  if (column.field === 'actions') {
    // todo: create a wrapper component and refactor tooltip and icon buttons to remove mui warnings
    // reference https://github.com/mui/material-ui/issues/8416#issuecomment-1294989582
    field = (
      <Box>
        {((row.type === 'sponsor' && canWriteUserManagementSponsor) ||
          (row.type === 'advisor' && canWriteUserManagementAdvisor)) && (
          <>
            <Tooltip title='Copy invite link'>
              <span>
                <IconButton
                  aria-label='copy-user-invite-link'
                  data-testid={`associations-copy-user-invite-link-button-${row.type}-${row.id}`}
                  disabled={row.selectedUserHasPassword}
                  onClick={() =>
                    onCopyOrSendUserInviteLink(
                      row.id,
                      row.selectedUserEmail,
                      row.type,
                      'copy'
                    )
                  }
                  size='small'>
                  <LinkIcon fontSize='inherit' />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title='Send invite email'>
              <span>
                <IconButton
                  aria-label='send-user-invite'
                  data-testid={`associations-send-user-invite-button-${row.type}-${row.id}`}
                  disabled={row.selectedUserHasPassword}
                  onClick={() =>
                    onCopyOrSendUserInviteLink(
                      row.id,
                      row.selectedUserEmail,
                      row.type,
                      'send'
                    )
                  }
                  size='small'>
                  <EmailIcon fontSize='inherit' />
                </IconButton>
              </span>
            </Tooltip>
          </>
        )}
        {((row.type === 'participant' && canWriteUserManagementParticipant) ||
          (row.type === 'sponsor' && canWriteUserManagementSponsor) ||
          (row.type === 'advisor' && canWriteUserManagementAdvisor)) && (
          <Tooltip title='Remove Association'>
            <IconButton
              aria-label='delete'
              data-testid={`associations-delete-button-${row.type}-${row.id}`}
              onClick={() => onDelete(row, row.id, row.type)}
              size='small'>
              <GroupRemoveIcon fontSize='inherit' />
            </IconButton>
          </Tooltip>
        )}
        {row.type === 'vss_state_saver' &&
          canWriteUserManagementVssStateSaver && (
            <Tooltip title='Remove Association'>
              <Button
                aria-label='delete'
                color='primary'
                data-testid={`associations-delete-button-${row.type}-${row.id}`}
                disabled={userVssStateSaverRelationshipsMutation.isLoading}
                endIcon={<GroupRemoveIcon fontSize='inherit' />}
                onClick={() => onVssStateSaverDelete(row.id)}
                size='small'>
                Remove
              </Button>
            </Tooltip>
          )}
      </Box>
    );
  }

  if (column.field === 'name' && row.type === 'vss_state_saver') {
    field = (
      <Link
        component={RouterLink}
        target='_blank'
        to={row.loginUrl}
        underline='hover'>
        {row.name}
      </Link>
    );
  }

  return (
    <TableCell component='th' scope='row'>
      <Box>{field}</Box>
    </TableCell>
  );
};

export default UserManagementAssociationsCollapsibleTableCell;
