import ContributionService from '@/services/Contribution.service';
import formatters from '@/utils/Formatters';
import { Card, Divider, LinearProgress, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { DataGridPro } from '@mui/x-data-grid-pro';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { uniqBy } from 'lodash';
import { useMemo } from 'react';
import type { FC } from 'react';
import * as uuid from 'uuid';

import { NoRowsOverlay } from './NoRowsOverlay.component';

type NewEmployeesProps = {
  sponsorId: number;
  sponsorPlanId: number;
  ucid: string;
  search?: string;
};

export const NewEmployees: FC<NewEmployeesProps> = props => {
  const corrections = useQuery(
    [
      ContributionService.getContributionCorrections.name,
      props.ucid,
      props.sponsorId,
      props.sponsorPlanId
    ],
    () =>
      ContributionService.getContributionCorrections(
        { ucid: props.ucid },
        {
          sponsorId: props.sponsorId,
          sponsorPlanId: props.sponsorPlanId
        }
      ),
    {
      refetchOnMount: 'always'
    }
  );

  const rows = useMemo(() => {
    const newParticipants =
      corrections.data
        ?.filter(
          correction =>
            correction.correctionType !== 'delete' &&
            correction.initialValue?.errorCode === 'UNRECOGNIZED_EMPLOYEE'
        )
        ?.map(correction => ({
          firstName: correction.initialValue?.firstName,
          id: uuid.v4(),
          lastName: correction.initialValue?.lastName,
          ssn: correction.initialValue?.ssn
        })) ?? [];

    return uniqBy(newParticipants, 'ssn');
  }, [corrections.data]);

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'ssn',
        headerName: 'SSN',
        valueFormatter: params => {
          try {
            return formatters.maskSSN(params.value);
          } catch {
            return params.value;
          }
        },
        width: 150
      },
      {
        field: 'name',
        headerName: 'Full Name',
        valueGetter: params =>
          `${params.row?.firstName} ${params.row?.lastName}`,
        width: 150
      }
    ],
    []
  );

  return (
    <Card data-testid='newEmployees' variant='outlined'>
      <Grid p={2}>
        <Typography data-testid='newEmployees-header' variant='h6'>
          New Employees ({rows.length})
        </Typography>
      </Grid>
      <Divider />
      <DataGridPro
        autoHeight
        columns={columns}
        data-testid='newEmployees-table'
        disableColumnMenu
        disableRowSelectionOnClick
        filterModel={{
          items: [{ field: 'name', operator: 'contains', value: props.search }]
        }}
        initialState={{
          pagination: {
            paginationModel: {
              page: 0,
              pageSize: 10
            }
          }
        }}
        loading={corrections.isLoading || corrections.isFetching}
        pageSizeOptions={[10, 25, 50, 100]}
        pagination
        paginationMode='client'
        rows={rows}
        slots={{
          loadingOverlay: LinearProgress,
          noRowsOverlay: NoRowsOverlay
        }}
        sx={{ '--DataGrid-overlayHeight': '200px' }}
      />
    </Card>
  );
};
