import { AlertDto } from '@/models/ops/alerts/AlertDTO.model';
import { AlertSubType } from '@vestwell-sub-accounting/models/common/AlertSubType';

import { FC } from 'react';

import { AchReversalExpandedAlertDetails } from './alert-expanded-details/AchReversalExpandedAlertDetails.component';
import { CashTransferRejectedExpandedAlertDetails } from './alert-expanded-details/CashTransferRejectedExpandedAlertDetails.component';
import { CustodianDestinationRejectExpandedAlertDetails } from './alert-expanded-details/CustodianDestinationRejectExpandedAlertDetails.component';
import { NewConversionDepositExpandedAlertDetails } from './alert-expanded-details/NewConversionDepositExpandedAlertDetails.component';
import { NewRolloverDepositExpandedAlertDetails } from './alert-expanded-details/NewRolloverDepositExpandedAlertDetails.component';
import { UnclassifiedDepositExpandedAlertDetails } from './alert-expanded-details/UnclassifiedDepositExpandedAlertDetails.component';

// This helper function has multiple responsibilities:
// * returning component corresponding to alert sub-type
// * signaling no expanded alert details exist for the alert sub-type on null return
export const getExpandedAlertDetailsComponent = (
  alert?: AlertDto | null
): null | (() => JSX.Element) | FC => {
  if (!alert) return null;

  switch (alert.alertSubType) {
    case AlertSubType.NewConversionDeposit:
      return NewConversionDepositExpandedAlertDetails;
    case AlertSubType.NewRolloverDeposit:
      return NewRolloverDepositExpandedAlertDetails;
    case AlertSubType.UnclassifiedDeposit:
      return UnclassifiedDepositExpandedAlertDetails;
    case AlertSubType.DestinationReject:
    case AlertSubType.CustodianReject:
      return CustodianDestinationRejectExpandedAlertDetails;
    case AlertSubType.CashTransferRejected:
      return alert?.details?.request
        ? CashTransferRejectedExpandedAlertDetails
        : null;
    case AlertSubType.ACHReversal:
      return AchReversalExpandedAlertDetails;
    default:
      return null;
  }
};

export default getExpandedAlertDetailsComponent;
