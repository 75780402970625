import { CardPlaceholder } from '@/components/card';
import CollapsibleTable from '@/components/collapsible-table';
import { PlanParticipantsInfo } from '@/models/PlanParticipantsDTO.model';
import { PlanService } from '@/services/Plan.service';
import { Search, WarningAmber } from '@mui/icons-material';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Theme
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';

import React, { ReactElement, useEffect, useState } from 'react';

import { PlanEmployeesScheduleEmails } from './PlanEmployeesScheduleEmails.component';
import PlanEmployeeTableCell from './PlanEmployeeTableCell.component';

interface PlanEmployeesRegistrationTabProps {
  sponsorPlanId: string;
  search: string;
}

interface HeaderColumn {
  field: string;
  headerName: string;
  width: number;
}

const REGISTRATION_STATUSES = {
  NONE: {
    label: 'None',
    value: ''
  },
  REGISTERED: {
    label: 'Registered',
    value: 'REGISTERED'
  },
  UNREGISTERED: {
    label: 'Unregistered',
    value: 'UNREGISTERED'
  }
};

const WELCOME_EMAIL_STATUSES = {
  BOUNCED: {
    label: 'Bounced',
    value: 'BOUNCED'
  },
  DELIVERED: {
    label: 'Delivered',
    value: 'DELIVERED'
  },
  NONE: {
    label: 'None',
    value: ''
  },
  'NOT DELIVERED': {
    label: 'Not Delivered',
    value: 'NOT DELIVERED'
  }
};

const columns: HeaderColumn[] = [
  { field: 'id', headerName: 'ID', width: 130 },
  { field: 'name', headerName: 'Name', width: 130 },
  { field: 'registeredAt', headerName: 'Registration Status', width: 130 },
  { field: 'eligibilityStatus', headerName: 'Eligibility Status', width: 130 },
  { field: 'email', headerName: 'Login Email', width: 130 },
  {
    field: 'welcomeEmailStatus',
    headerName: 'Welcome Email Status',
    width: 130
  },
  { field: 'workEmail', headerName: 'Work Email', width: 130 }
];

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3.5)
  }
}));

const PlanEmployeesRegistrationTab = ({
  sponsorPlanId,
  search
}: PlanEmployeesRegistrationTabProps): ReactElement<PlanEmployeesRegistrationTabProps> => {
  const classes = useStyles();

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const [registrationStatus, setRegistrationStatus] = useState(
    REGISTRATION_STATUSES.NONE.value
  );
  const [welcomeEmailStatus, setWelcomeEmailStatus] = useState(
    WELCOME_EMAIL_STATUSES.NONE.value
  );

  const participantsQuery = useQuery<PlanParticipantsInfo>(
    [
      'PlanService.getParticipantsWithLatestWelcomeEmails',
      sponsorPlanId,
      pageNumber,
      pageSize,
      search,
      registrationStatus,
      welcomeEmailStatus
    ],
    async () => {
      const { count, participants } =
        await PlanService.getParticipantsWithLatestWelcomeEmails({
          params: {
            pageNumber,
            pageSize,
            registrationStatus,
            search,
            welcomeEmailStatus
          },
          sponsorPlanId
        });

      return {
        count,
        participants: participants.map(participant => ({
          ...participant,
          scope: 'registration',
          sponsorPlanId
        }))
      };
    },
    {
      keepPreviousData: true
    }
  );

  const planDesignQuery = useQuery(
    ['PlanService.getPlanDesignById', sponsorPlanId],
    () => PlanService.getPlanDesignById(sponsorPlanId)
  );

  useEffect(() => {
    setPageNumber(0);
  }, [search]);

  const onRegistrationStatusChange = (e: SelectChangeEvent) => {
    setRegistrationStatus(e.target.value);
    setPageNumber(0);
  };

  const onWelcomeEmailStatusChange = (e: SelectChangeEvent) => {
    setWelcomeEmailStatus(e.target.value);
    setPageNumber(0);
  };

  return (
    <>
      <Box className={classes.header}>
        <Box display='flex'>
          <Box mr={1} width={240}>
            <FormControl fullWidth>
              <InputLabel id='registrationStatus'>
                Registration Status
              </InputLabel>
              <Select
                disabled={!participantsQuery.isSuccess}
                id='registrationStatus'
                label='Registration Status'
                labelId='registrationStatus'
                onChange={onRegistrationStatusChange}
                value={registrationStatus}>
                {Object.values(REGISTRATION_STATUSES).map(
                  ({ value, label }) => (
                    <MenuItem key={label} value={value}>
                      {label}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Box>

          <Box width={240}>
            <FormControl fullWidth>
              <InputLabel id='welcomeEmailStatus'>
                Welcome Email Status
              </InputLabel>
              <Select
                disabled={!participantsQuery.isSuccess}
                id='welcomeEmailStatus'
                label='Welcome Email Status'
                labelId='welcomeEmailStatus'
                onChange={onWelcomeEmailStatusChange}
                value={welcomeEmailStatus}>
                {Object.values(WELCOME_EMAIL_STATUSES).map(
                  ({ value, label }) => (
                    <MenuItem key={label} value={value}>
                      {label}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Box>
        </Box>

        {planDesignQuery.isSuccess &&
          planDesignQuery.data?.data?.overview?.adminStatus !== 'Active' &&
          (planDesignQuery.data?.data?.overview?.planType === 'ESA' ||
            ![null, undefined].includes(
              planDesignQuery.data?.data?.employeeContribution?.autoEnrollAmount
            )) && <PlanEmployeesScheduleEmails sponsorPlanId={sponsorPlanId} />}
      </Box>

      <CollapsibleTable
        cellComponent={PlanEmployeeTableCell}
        columns={columns}
        isLoading={participantsQuery.isFetching || participantsQuery.isFetching}
        noDataPlaceholderComponent={
          <Stack
            alignItems='center'
            data-testid='no-data-employees-table'
            justifyContent='center'
            sx={{ height: '100%' }}>
            <CardPlaceholder
              icon={
                participantsQuery.isError ? (
                  <WarningAmber fontSize='inherit' />
                ) : (
                  <Search fontSize='inherit' />
                )
              }
              subtitle={
                participantsQuery.isFetching
                  ? 'Loading employees...'
                  : participantsQuery.isError
                    ? 'Error retrieving employees'
                    : search ||
                        registrationStatus !==
                          REGISTRATION_STATUSES.NONE.value ||
                        welcomeEmailStatus !== WELCOME_EMAIL_STATUSES.NONE.value
                      ? 'No results found'
                      : 'You have no employees in your plan'
              }
            />
          </Stack>
        }
        pager={{
          metaCount: participantsQuery.data?.count,
          onPageNumberChanged: setPageNumber,
          onRowsPerPageChanged: setPageSize,
          pageNumber,
          rowsPerPage: pageSize
        }}
        tableData={participantsQuery.data?.participants ?? []}
      />
    </>
  );
};

export default PlanEmployeesRegistrationTab;
