import { useFormikContext } from 'formik';
import { FC, useEffect } from 'react';

const VestingPreferencesObserver: FC<{
  data: Record<string, any>;
}> = ({ data }: { data: Record<string, any> }) => {
  const { values }: { values: Record<string, any> } = useFormikContext();

  useEffect(() => {
    data.hoursOfServiceRequired.setDisabled(
      values.vestingMethod !== 'Hours of Service'
    );
  }, [values, data]);

  return null;
};

export default VestingPreferencesObserver;
