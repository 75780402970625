import Badge from '@/components/badge';
import { CellComponentProps } from '@/components/collapsible-table';
import JSONViewer from '@/components/json-viewer';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import formatters from '@/utils/Formatters';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  TableCell,
  Typography
} from '@mui/material';
import { blue } from '@mui/material/colors';

import { startCase } from 'lodash';
import React from 'react';
import { useToggle } from 'react-use';

interface JsonPreviewModalProps extends DialogProps {
  title: string;
  json: any;
}

const JsonPreviewModal: React.FunctionComponent<JsonPreviewModalProps> = (
  props: JsonPreviewModalProps
) => {
  return (
    <Dialog onClose={props.onClose} open={props.open}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <JSONViewer json={props.json} />
      </DialogContent>
    </Dialog>
  );
};

const DeconversionRequestsTableCell: React.FunctionComponent<
  CellComponentProps
> = (props: CellComponentProps) => {
  const [openRequestModal, toggleOpenRequestModal] = useToggle(false);
  const [openSubaModal, toggleOpenSubaModal] = useToggle(false);
  const { row, column } = props;

  let field = <Box>{row[column.field] || '--'}</Box>;

  switch (column.field) {
    case 'request':
      field = (
        <>
          <Typography color={blue[500]} variant='body1'>
            Liquidate Plan{' '}
            {row.actionType === 'PlanResidualFundsLiquidation'
              ? '- Residual'
              : ''}
          </Typography>
          <Typography variant='body2'>Tracer ID: {row.tracerId}</Typography>
        </>
      );
      break;
    case 'createdAt':
    case 'updatedAt':
      field = (
        <Box>
          {formatters.formatFromIsoDateCustom(
            row[column.field],
            'MM/DD/YYYY hh:mm'
          )}
        </Box>
      );
      break;
    case 'amount':
      field = <Box>{formatters.formatDollars(row[column.field])}</Box>;
      break;
    case 'status':
      field = row[column.field] && (
        <Badge size='small'>{startCase(row[column.field])}</Badge>
      );
      break;
    case 'workflowStatus':
      field = row[column.field] && (
        <Badge size='small'>{row[column.field]}</Badge>
      );
      break;
    case 'actions':
      field = (
        <Stack direction='row'>
          <Button onClick={toggleOpenRequestModal}>View JSON Request</Button>
          <Button onClick={toggleOpenSubaModal}>View SubA Result</Button>
        </Stack>
      );
      break;
  }

  return (
    <>
      <TableCell component='th' scope='row'>
        <Box sx={{ '& .MuiBox-root': { fontSize: theme => theme.spacing(2) } }}>
          {field ?? EMPTY_FIELD_PLACEHOLDER}
        </Box>
      </TableCell>
      <JsonPreviewModal
        json={row.request}
        onClose={toggleOpenRequestModal}
        open={openRequestModal}
        title='JSON Request'
      />
      <JsonPreviewModal
        json={row.subaResult}
        onClose={toggleOpenSubaModal}
        open={openSubaModal}
        title='Suba Result'
      />
    </>
  );
};

export default DeconversionRequestsTableCell;
