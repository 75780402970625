import LinearLoading from '@/components/linear-loading';
import NavigationBreadcrumbs from '@/components/navigation-breadcrumbs';
import {
  FundLineup,
  FundLineupData
} from '@/routes/ops/investments/FundLineup.component';
import ApiService from '@/services/Api.service';
import InvestmentService from '@/services/Investment.service';
import { useQuery } from '@tanstack/react-query';

import { useParams } from 'react-router-dom';

type FundLineupEditRouteProps = {
  id: string;
};

const FundLineupEditRoute = (): JSX.Element => {
  const { id } = useParams<FundLineupEditRouteProps>();

  const fundLineupId = parseInt(id, 10);
  const fundLineupQuery = useQuery<FundLineupData>(
    ['FundLineupEdit.fundLineupQuery', 'fund-lineup', id],
    () => {
      return InvestmentService.getFundLineup(fundLineupId);
    },
    { cacheTime: 0 }
  );

  const programCount = useQuery<any>(
    ['FundLineupEdit.fundLineupQuery', 'program-count', id],
    () => {
      return ApiService.getJson(
        `/investments/program?fundLineupId=${id}&pageNumber=1&pageSize=0`
      );
    },
    { cacheTime: 0 }
  );

  const paths = [
    {
      name: 'Ops',
      to: '/ops/investments'
    },
    {
      name: 'Investments',
      to: '/ops/investments'
    }
  ];

  return (
    <>
      <NavigationBreadcrumbs paths={paths} />
      {fundLineupQuery.isFetching ||
      programCount.isFetching ||
      !fundLineupQuery.data ? (
        <LinearLoading />
      ) : (
        <FundLineup
          fundLineupId={fundLineupId}
          fundLineupInvestmentOption={fundLineupQuery.data}
          programCount={programCount.data?.meta?.count}
          refetchFundLineup={fundLineupQuery.refetch}
        />
      )}
    </>
  );
};

export default FundLineupEditRoute;
