import { CellComponentProps } from '@/components/collapsible-table';
import { Box, TableCell, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';
import React, { useMemo } from 'react';

import { PositionsFields, PositionsRow } from './PositionsTable.component';

const useStyles = makeStyles((theme: Theme) => ({
  cellWithAlign: {
    textAlign: 'right'
  },
  positionName: {
    color: theme.palette.common.black,
    display: 'flex',
    flexDirection: 'row'
  },
  rootCell: {
    fontSize: theme.spacing(2),
    paddingBottom: theme.spacing(0.75),
    paddingTop: theme.spacing(0.75)
  }
}));

interface CellProps {
  row: PositionsRow;
}

const SecurityCell: React.FC<CellProps> = (props: CellProps) => {
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.positionName}>
        <Box style={{ width: props.row.maxTickerLength * 12 + 'px' }}>
          {props.row[PositionsFields.SECURITY].id}{' '}
        </Box>
        <Box>| {props.row[PositionsFields.SECURITY].cusip}</Box>
      </Box>

      <Typography variant='body2'>
        {props.row[PositionsFields.SECURITY].fundName}
      </Typography>
    </Box>
  );
};

const PositionCell: React.FC<CellProps> = (props: CellProps) => {
  return (
    <Box textAlign='right'>
      <Box>{props.row[PositionsFields.POSITION].balance}</Box>
      <Typography variant='body2'>
        {props.row[PositionsFields.POSITION].shares}
      </Typography>
    </Box>
  );
};

const PositionsTableCell: React.FC<CellComponentProps> = (
  props: CellComponentProps
) => {
  const { row, column } = props;
  const classes = useStyles();
  const isAligned =
    column.field === PositionsFields.EXPENSE_RATIO_PCT ||
    column.field === PositionsFields.WEIGHT;

  const CellComponent = useMemo(() => {
    switch (column.field) {
      case PositionsFields.SECURITY:
        return <SecurityCell row={row} />;
      case PositionsFields.POSITION:
        return <PositionCell row={row} />;
      default:
        return <>{row[column.field]}</>;
    }
  }, [column, row]);

  return (
    <TableCell
      className={clsx(classes.rootCell, isAligned && classes.cellWithAlign)}
      component='th'
      scope='row'>
      {CellComponent}
    </TableCell>
  );
};

export default PositionsTableCell;
