import Badge from '@/components/badge';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { SponsorTeammateData } from '@/models/SponsorTeammates.model';
import { UserMfaMethodDto } from '@/models/UsersDTO.model';
import UserManagementService from '@/services/UserManagement.service';
import formatters from '@/utils/Formatters';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Unstable_Grid2 as Grid,
  Typography
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';

import React, { useCallback, useMemo } from 'react';

interface PlanUsersTableDisableTwoFactorAuthModalProps {
  onDisable: () => void;
  teammate: SponsorTeammateData;
}

export const PlanUsersTableDisableTwoFactorAuthModal = (
  props: PlanUsersTableDisableTwoFactorAuthModalProps
): JSX.Element => {
  const snackbar = useSnackbar();
  const dialog = useDialog();

  const mfaMethods = useQuery<UserMfaMethodDto[]>(
    [
      'UserManagementService.getUserMfaMethods',
      props.teammate?.relationships?.user?.data?.id
    ],
    () =>
      UserManagementService.getUserMfaMethods(
        props.teammate?.relationships?.user?.data?.id
      )
  );

  const onDisableMfa = useMutation(
    ['UserManagementService.deleteMfa'],
    () => {
      return UserManagementService.deleteMfa(
        props.teammate?.relationships?.user?.data?.id
      );
    },
    {
      onError: () => {
        snackbar.showSnackbar({
          message: `Failed! Could not disable 2-Factor Authentication for ${props.teammate?.attributes?.email}.`,
          severity: 'error'
        });
      },
      onSuccess: () => {
        dialog.closeDialog();
        snackbar.showSnackbar({
          message: `Successfully disabled 2-Factor Authentication for ${props.teammate?.attributes?.email}.`,
          severity: 'success'
        });
        props.onDisable();
      }
    }
  );

  const onDisable = useCallback(() => onDisableMfa.mutateAsync(), []);

  const methods = useMemo(() => {
    return mfaMethods.data
      ?.sort((a, b) => {
        return b.entryType
          .toLowerCase()
          .localeCompare(a.entryType.toLowerCase());
      })
      .map(mfaEntry => {
        return {
          entryType: mfaEntry?.entryType,
          label:
            mfaEntry?.entryType === 'app'
              ? 'Authentication App'
              : mfaEntry?.entryType === 'phone'
                ? 'Text Message'
                : 'Email',
          mfaEntryId: mfaEntry?.mfaEntryId,
          value:
            mfaEntry?.entryType === 'app'
              ? 'Google Authenticator or similar'
              : mfaEntry?.entryType === 'phone'
                ? `${formatters.formatPhone(mfaEntry?.value)}`
                : `${mfaEntry?.value}`
        };
      });
  }, [mfaMethods.data]);

  return (
    <>
      <DialogTitle>Disable 2-Factor Authentication</DialogTitle>
      <DialogContent data-testid='disableTwoFactorAuthModalContent'>
        <Grid container spacing={4}>
          <Grid lg={12}>
            <Typography variant='body1'>
              Are you sure you want to disable 2-Factor Authentication for{' '}
              {props.teammate?.attributes?.email}? This action cannot be undone,
              only proceed if the{' '}
              <strong>
                user’s identity has been verified over a phone call.
              </strong>
            </Typography>
          </Grid>
          <Grid lg={12}>
            <TextStack direction='row' divider rowColumnWidth='dynamic'>
              <>
                <TextStackItem maxWidth='100%'>
                  <TextLabel>Status</TextLabel>
                  <TextValue data-testid='planUsersTableTwoFactorAuthStatus'>
                    <Badge color='success' size='medium'>
                      {mfaMethods.data?.length ? 'Enabled' : 'Disabled'}
                    </Badge>
                  </TextValue>
                </TextStackItem>
                {!!methods?.length &&
                  methods?.map(mfaEntry => (
                    <TextStackItem
                      fieldName={`${mfaEntry?.entryType}TwoFactorAuth`}
                      key={mfaEntry.mfaEntryId}
                      maxWidth='100%'>
                      <TextLabel>{mfaEntry?.label}</TextLabel>
                      <TextValue>{mfaEntry?.value}</TextValue>
                    </TextStackItem>
                  ))}
              </>
            </TextStack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid='cancelBtn'
          onClick={dialog.closeDialog}
          variant='text'>
          CANCEL
        </Button>
        <Button
          color='error'
          data-testid='disableBtn'
          disabled={onDisableMfa.isLoading}
          onClick={onDisable}
          variant='text'>
          DISABLE
        </Button>
      </DialogActions>
    </>
  );
};
