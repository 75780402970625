import {
  Form,
  Formik,
  FormikConfig,
  FormikContextType,
  FormikErrors,
  useFormikContext
} from 'formik';
import {
  Dispatch,
  forwardRef,
  memo,
  ReactNode,
  SetStateAction,
  useEffect,
  useState
} from 'react';
import { useDebounce, useUnmount } from 'react-use';
import { v4 as uuidv4 } from 'uuid';

export type ActionTableFormProps<T> = Omit<
  FormikConfig<T>,
  'initialValues' | 'innerRef' | 'onSubmit'
> & {
  children?: ReactNode;
  /** className to apply to the wrapping form element */
  className?: string;
  emptyRowsCount?: number;
  defaultEmptyRowValues?: Record<string, any>;
  initialValues?: any;
  /** onChange called when values in the form changed */
  onChange?:
    | Dispatch<SetStateAction<T | undefined>>
    | ((values: T) => void | Promise<void>);
  /** onChange called when values in the form changed */
  onErrors?:
    | Dispatch<SetStateAction<FormikErrors<T> | undefined>>
    | ((errors: FormikErrors<T>) => void | Promise<void>);
  /** onRawErrors called when raw yup errors are updated */
  onRawErrors?: (errors: FormikErrors<T> | null) => void;
  /** called when submitting the form */
  onSubmit: FormikConfig<T>['onSubmit'];
  /** onValidation called when form validation state changed */
  onValidation?:
    | Dispatch<SetStateAction<boolean | undefined>>
    | ((valid: boolean) => void | Promise<void>);
  /** prevent onChange from getting called after mount */
  preventOnChangeOnMount?: boolean;
  /** additional context to pass to yup validationSchema; accessed via this.options.context */
  validationContext?: Record<string, unknown>;
};

/**
 * this is a workaround for adding an onChange callback to Form
 * unfortunately, formik does not provide onChange callback (only <form onChange>)
 */
const FormChangeHandler: React.FunctionComponent<{
  onChange: Dispatch<any> | ((values: any) => void | Promise<void>);
  preventOnChangeOnMount?: boolean;
}> = memo(
  (props: {
    onChange: Dispatch<any> | ((values: any) => void | Promise<void>);
    preventOnChangeOnMount?: boolean;
  }) => {
    const ctx = useFormikContext<any>();
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
      const $timeout = setTimeout(() => {
        setMounted(true);
      }, 10);
      return () => {
        clearTimeout($timeout);
      };
    }, []);

    const [, cancel] = useDebounce(
      () => {
        const shouldCall =
          !props.preventOnChangeOnMount ||
          (props.preventOnChangeOnMount && mounted);

        if (props.onChange && shouldCall) {
          const values = ctx.values.filter(i => i?.uuid);
          props.onChange(values);
        }
      },
      10,
      [ctx.values]
    );

    useUnmount(() => {
      cancel();
    });

    return null;
  }
);

/**
 * this is a workaround for adding an onErrors callback to Form
 * unfortunately, formik does not provide onErrors callback
 */
const FormErrorHandler: React.FunctionComponent<{
  onErrors: (errors: FormikErrors<any>) => void | Promise<void>;
}> = memo(
  (props: {
    onErrors: (errors: FormikErrors<any>) => void | Promise<void>;
  }) => {
    const ctx = useFormikContext<any>();
    const { onErrors } = props;

    useEffect(() => {
      if (onErrors) {
        onErrors(ctx.errors);
      }
    }, [onErrors, ctx.errors]);

    return null;
  }
);

const ActionTableForm = forwardRef<
  FormikContextType<any>,
  ActionTableFormProps<any>
>((props, ref) => {
  useEffect(() => {
    if (props.emptyRowsCount) {
      const formRef = ref as React.RefObject<FormikContextType<any>>;
      formRef?.current?.setValues(
        Array(props.emptyRowsCount)
          .fill({})
          .map(() => {
            return {
              ...props.defaultEmptyRowValues,
              pristine: true,
              uuid: uuidv4()
            };
          })
      );
    }
  }, [ref, props.emptyRowsCount]);
  return (
    <Formik
      enableReinitialize={props.enableReinitialize}
      initialTouched={props.initialTouched}
      initialValues={props.initialValues}
      innerRef={ref as any}
      onSubmit={props.onSubmit}
      validateOnBlur={props.validateOnBlur}
      validateOnChange={props.validateOnChange}
      validateOnMount={props.validateOnMount}
      validationSchema={props.validationSchema}>
      <Form>
        {props.children}
        {props.onChange && (
          <FormChangeHandler
            onChange={props.onChange}
            preventOnChangeOnMount={props.preventOnChangeOnMount}
          />
        )}
        {props.onErrors && <FormErrorHandler onErrors={props.onErrors} />}
      </Form>
    </Formik>
  );
});

export default ActionTableForm;
