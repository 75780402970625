import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import PayrollIntegrationsService from '@/services/payroll-integrations.service';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import { styled } from '@mui/styles';
import { useMutation } from '@tanstack/react-query';

import { Form, Formik } from 'formik';
import React from 'react';

interface PayGroupsModalBlockReasonProps {
  sponsorPlanId: number | string;
  onUpdate: () => void;
}

const StyledDialogContent = styled(DialogContent)(() => ({
  display: 'flex',
  flexDirection: 'column'
}));

export const PayGroupsModalBlockReason = (
  props: PayGroupsModalBlockReasonProps
): JSX.Element => {
  const ctx = useDialog();
  const { showSnackbar } = useSnackbar();
  const blockIntegrationMutation = useMutation<
    unknown,
    Error,
    { sponsorPlanId: number; reason: string }
  >(
    ['PayrollIntegrationsService.blockPayrollIntegration'],
    data => {
      return PayrollIntegrationsService.blockPayrollIntegration(
        data.sponsorPlanId,
        data.reason
      );
    },
    {
      onError: () => {
        showSnackbar({
          message: `Failed to block plan from integration`,
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: `Plan is now blocked from integration`,
          severity: 'success'
        });
      }
    }
  );

  const handleSubmit = async (values: {
    selectedReason: string;
    customReason?: string;
  }) => {
    await blockIntegrationMutation.mutateAsync({
      reason: values.customReason || values.selectedReason,
      sponsorPlanId: +props.sponsorPlanId
    });
    ctx.closeDialog();
    props.onUpdate();
  };

  const reasons = [
    'Unresponsive employer',
    'Plan with multiple entities',
    'Unsupported payroll product',
    'Per pay period discretionary matches',
    'Other'
  ];
  const radioGroupName = 'blockSelect';

  return (
    <Formik
      initialValues={{
        customReason: '',
        selectedReason: reasons[0]
      }}
      onSubmit={handleSubmit}>
      {({ setValues, values, handleChange }) => {
        return (
          <Form>
            <StyledDialogContent data-testid='blockReasonModalContent'>
              <DialogTitle sx={{ paddingLeft: '0px' }}>
                Block Plan from Integrating?
              </DialogTitle>
              <Typography color='text.primary' marginBottom={2} variant='body1'>
                The "Connect" button will be hidden in the Employer Portal and
                the integration will be disconnected. The client can no longer
                connect to the integration unless the block is removed.
              </Typography>
              <FormLabel
                id='block-reason-radio-group-label'
                sx={{ fontWeight: 'bold' }}>
                Reason
              </FormLabel>
              <RadioGroup
                name={radioGroupName}
                onChange={event => {
                  setValues({
                    customReason: '',
                    selectedReason: event.currentTarget.value
                  });
                }}
                value={values.selectedReason}>
                {reasons.map(r => (
                  <FormControlLabel
                    control={<Radio />}
                    key={`${r}-option`}
                    label={r}
                    value={r}
                  />
                ))}
              </RadioGroup>
              {values.selectedReason == 'Other' && (
                <TextField
                  data-testid='custom-block-reason'
                  fullWidth
                  label='Reason'
                  multiline
                  name='customReason'
                  onChange={handleChange}
                  rows={2}
                  value={values.customReason}
                  variant='outlined'
                />
              )}
              <Divider sx={{ marginTop: 2 }} />
              <DialogActions>
                <Button
                  data-testid='cancelBtn'
                  onClick={ctx.closeDialog}
                  variant='text'>
                  CANCEL
                </Button>
                <Button
                  data-testid='submitBtn'
                  disabled={
                    !values.selectedReason ||
                    (values.selectedReason == 'Other' && !values.customReason)
                  }
                  type='submit'
                  variant='contained'>
                  CONFIRM & BLOCK
                </Button>
              </DialogActions>
            </StyledDialogContent>
          </Form>
        );
      }}
    </Formik>
  );
};
