import { PlanDocument } from '@/models/PlanDocumentCategoryGroupDTO.model';
import { PlanService } from '@/services/Plan.service';
import formatters from '@/utils/Formatters';
import { useQuery } from '@tanstack/react-query';

import { orderBy } from 'lodash';
import { useMemo } from 'react';

import { GroupsWithDates } from './PlanDocumentsGroup.component';

export const useGetDocumentGroups = (
  sponsorPlanId: number,
  categoryId: number,
  useEffectiveDate: boolean,
  parentDocKey: string
) => {
  const { data: allPossibleDocumentsForCategory } = useQuery<string[]>(
    ['PlanService.getDocumentKeysByCategory', categoryId],
    () => PlanService.getDocumentKeysByCategory(categoryId || ''),
    {
      enabled: !!categoryId,
      staleTime: Infinity
    }
  );

  const {
    data: documentCategoryGroups,
    isLoading,
    isError
  } = useQuery(
    ['PlanService.getDocumentCategoryGroupings', sponsorPlanId, categoryId],
    async () =>
      PlanService.getDocumentCategoryGroupings(sponsorPlanId, categoryId),
    {
      enabled: Boolean(sponsorPlanId),
      staleTime: Infinity
    }
  );

  const adHocDocsQuery = useQuery(
    ['PlanService.getDocumentCategoryLegacy', sponsorPlanId, categoryId],
    async () =>
      PlanService.getDocumentCategoryLegacy(sponsorPlanId, categoryId),
    {
      enabled: Boolean(sponsorPlanId),
      staleTime: Infinity
    }
  );

  const refinedDocs = useMemo(() => {
    if (!documentCategoryGroups || !allPossibleDocumentsForCategory) return [];

    let refinedAdHocDocs = [];
    let uploadedDraftDocuments = [];

    if (adHocDocsQuery.data?.length) {
      const allAdHocDocs = orderBy(
        adHocDocsQuery.data,
        ['createdAt'],
        ['desc']
      );
      const adHocGroupIndex = documentCategoryGroups.findIndex(
        group => group.name.toLowerCase() === 'ad hoc'
      );

      if (adHocGroupIndex !== -1) {
        return [
          ...documentCategoryGroups.slice(0, adHocGroupIndex),
          ...documentCategoryGroups.slice(adHocGroupIndex + 1),
          { documents: allAdHocDocs, groupingId: -1, name: 'Ad Hoc' }
        ];
      }

      refinedAdHocDocs = allAdHocDocs.filter(doc => !doc.isDraft);
      uploadedDraftDocuments = adHocDocsQuery.data.filter(doc => doc.isDraft);
    }

    const uniqueDraftDocuments: PlanDocument[] = Object.values(
      uploadedDraftDocuments.reduce((acc, doc) => {
        if (
          !acc[doc.documentKey] ||
          acc[doc.documentKey].uploadHistoryId < doc.uploadHistoryId
        ) {
          acc[doc.documentKey] = doc;
        }
        return acc;
      }, {})
    );

    const mergedDraftDocuments = allPossibleDocumentsForCategory.map(docKey => {
      const existingDoc = uniqueDraftDocuments.find(
        doc => doc.documentKey === docKey
      );
      return (
        existingDoc || {
          createdAt: null,
          documentKey: docKey,
          effectiveDate: null,
          isDraft: true
        }
      );
    });

    const docsToReturn = [
      {
        documents: mergedDraftDocuments,
        effectiveDate: null,
        groupingId: -2,
        name: 'Draft'
      },
      ...documentCategoryGroups
    ];

    if (refinedAdHocDocs.length > 0) {
      docsToReturn.push({
        documents: refinedAdHocDocs,
        groupingId: -1,
        name: 'Ad Hoc'
      });
    }

    return docsToReturn;
  }, [
    adHocDocsQuery.data,
    documentCategoryGroups,
    allPossibleDocumentsForCategory
  ]);

  const sortedGroups = useMemo(() => {
    if (!refinedDocs) return [];

    const groupsWithDates = refinedDocs.map(group => {
      const document = group.documents.find(d =>
        useEffectiveDate ? d.documentKey === parentDocKey : !!d.effectiveDate
      );
      const oldestDocumentCreatedAt = orderBy(group.documents, 'createdAt', [
        'asc'
      ])[0];
      const oldestDocumentEffectiveDate = orderBy(
        group.documents,
        'effectiveDate',
        ['asc']
      )[0];

      return {
        ...group,
        createdAt: document?.createdAt || oldestDocumentCreatedAt?.createdAt,
        effectiveDate:
          document?.effectiveDate || oldestDocumentEffectiveDate?.effectiveDate
      };
    });

    const groupsWithEffectiveDate: GroupsWithDates[] = [];
    const groupsWithCreatedAtDate: GroupsWithDates[] = [];
    const groupsWithNoDates: GroupsWithDates[] = [];
    const adHocGroup: GroupsWithDates[] = [];
    const draftGroup: GroupsWithDates[] = [];
    groupsWithDates.forEach(group => {
      if (group.name === 'Ad Hoc') {
        adHocGroup.push(group);
      } else if (group.name === 'Draft') {
        draftGroup.push(group);
      } else if (!group.effectiveDate && !group.createdAt) {
        groupsWithNoDates.push(group);
      } else if (!group.effectiveDate) {
        groupsWithCreatedAtDate.push(group);
      } else {
        groupsWithEffectiveDate.push(group);
      }
    });

    groupsWithEffectiveDate.sort(
      (groupA, groupB) =>
        new Date(groupB.effectiveDate || 0).getTime() -
        new Date(groupA.effectiveDate || 0).getTime()
    );

    groupsWithCreatedAtDate.sort(
      (groupA, groupB) =>
        new Date(groupB.createdAt || 0).getTime() -
        new Date(groupA.createdAt || 0).getTime()
    );

    return [
      ...draftGroup,
      ...groupsWithEffectiveDate,
      ...groupsWithCreatedAtDate,
      ...groupsWithNoDates,
      ...adHocGroup
    ].map(group => {
      let effectiveDateString = '';

      if (useEffectiveDate && group.effectiveDate) {
        effectiveDateString = ` | Effective ${formatters.formatFromIsoDateCustom(
          group.effectiveDate.toString(),
          'MM/DD/YYYY'
        )}`;
      } else if (!useEffectiveDate && group.effectiveDate) {
        const year = formatters.formatFromIsoDateCustom(
          group.effectiveDate.toString(),
          'YYYY'
        );
        effectiveDateString = ` | Plan Year 01/01/${year} - 12/31/${year}`;
      }

      return {
        ...group,
        nameWithEffectiveDate: group.name + effectiveDateString
      };
    });
  }, [refinedDocs, parentDocKey, useEffectiveDate]);

  return {
    adHocDocsQuery,
    documentCategoryGroups,
    isError,
    isLoading,
    sortedGroups
  };
};
