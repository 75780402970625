import {
  ProgramFundChangeRow,
  ProgramFundChangeRowProps
} from '@/routes/ops/investments/FundChangesTab/ProgramFundChangeRow.component';
import { FundChangesPreview } from '@/routes/ops/investments/FundChangesTab/types';
import {
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { FormikErrors } from 'formik';
import { Fragment } from 'react';

export interface ProgramFundChangesTableProps
  extends Pick<
    ProgramFundChangeRowProps,
    | 'lookupTickerCallback'
    | 'availableOptions'
    | 'onChange'
    | 'funds'
    | 'onRemoveFundChangePreview'
  > {
  fundChangesPreview: FundChangesPreview[];
  errors?: FormikErrors<{
    fundChangesPreview: FundChangesPreview[];
  }>;
  setFieldError: (field: string, value: string) => void;
}

export const ProgramFundChangesTable = (
  props: ProgramFundChangesTableProps
): JSX.Element => {
  const {
    availableOptions,
    errors,
    setFieldError,
    funds,
    lookupTickerCallback,
    onChange,
    onRemoveFundChangePreview,
    fundChangesPreview
  } = props;
  const theme = useTheme();
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {fundChangesPreview.filter(fundChangePreview => {
                return !fundChangePreview.isRemoved;
              }).length > 1 && <TableCell />}
              <TableCell sx={{ minWidth: theme.spacing(23.5), py: 1 }}>
                Fund Change Effective Date
              </TableCell>
              <TableCell sx={{ minWidth: theme.spacing(24.5), py: 1 }}>
                Old Fund
              </TableCell>
              <TableCell />
              <TableCell sx={{ minWidth: theme.spacing(24.5), py: 1 }}>
                New Fund
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {fundChangesPreview.map((row, index) => {
              if (row.isRemoved) return <Fragment key={index} />;
              const error = errors?.fundChangesPreview?.[index];
              return (
                <ProgramFundChangeRow
                  availableOptions={availableOptions}
                  error={error}
                  funds={funds}
                  investmentOptions={row.investmentOptions}
                  isRemovable={
                    fundChangesPreview.filter(fundChangePreview => {
                      return !fundChangePreview.isRemoved;
                    }).length > 1
                  }
                  key={index}
                  lookupTickerCallback={lookupTickerCallback}
                  newFund={row.newFund}
                  oldFund={row.oldFund}
                  onChange={onChange}
                  onRemoveFundChangePreview={onRemoveFundChangePreview}
                  rowIndex={index}
                  setFieldError={setFieldError}
                  tradeDate={row.tradeDate}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {typeof errors?.fundChangesPreview === 'string' && (
        <Alert severity='error'>{errors.fundChangesPreview}</Alert>
      )}
    </>
  );
};

ProgramFundChangesTable.displayName = 'ProgramFundChangesTable';
