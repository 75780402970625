export const FLOW_SUBTYPES = {
  correction: 'Correction',
  lost_gains: 'Lost Gains',
  'off-cycle_supplemental_pay': 'Off-Cycle',
  regular: 'Regular'
};

export const HEADERS_KEYS = {
  AT: 'at',
  CITY: 'city',
  COUNTRY: 'country',
  CURRENT_HOURS: 'current_hours',
  DATE_OF_BIRTH: 'date_of_birth',
  DATE_OF_HIRE: 'date_of_hire',
  DATE_OF_REHIRE: 'date_of_rehire',
  DATE_OF_TERMINATION: 'date_of_termination',
  DIVISION: 'division',
  EM: 'em',
  EMAIL: 'email',
  EXTERNAL_PAYROLL_ID: 'external_payroll_id',
  FIRST_NAME: 'first_name',
  GROSS_COMPENSATION: 'gross_compensation',
  LAST_NAME: 'last_name',
  LN: 'ln',
  LOAN_AMOUNT_1: 'Loan Payment 1',
  LOAN_AMOUNT_2: 'Loan Payment 2',
  LOAN_AMOUNT_3: 'Loan Payment 3',
  LOAN_AMOUNT_4: 'Loan Payment 4',
  LOAN_AMOUNT_5: 'Loan Payment 5',
  LOAN_NUMBER_1: 'Loan Number 1',
  LOAN_NUMBER_2: 'Loan Number 2',
  LOAN_NUMBER_3: 'Loan Number 3',
  LOAN_NUMBER_4: 'Loan Number 4',
  LOAN_NUMBER_5: 'Loan Number 5',
  MARITAL_STATUS: 'marital_status',
  MIDDLE_NAME: 'middle_name',
  PARTIAL_YEAR_COMPENSATION: 'partial_year_compensation',
  PHONE: 'phone',
  PRIOR_YEAR_GROSS_PAY: 'prior_year_gross_pay',
  PS: 'ps',
  QC: 'qc',
  RC: 'rc',
  RC_CATCHUP: 'rc_catchup',
  RC_ELECTION: 'rc_election',
  SALARY: 'salary',
  SD: 'sd',
  SD_CATCHUP: 'sd_catchup',
  SH: 'sh',
  SSN: 'ssn',
  STATE: 'state',
  STREET_ADDRESS_1: 'street_address_1',
  STREET_ADDRESS_2: 'street_address_2',
  SUPPLEMENTAL_GROSS_COMP: 'supplemental_gross_comp',
  YTD_ELIGIBLE_COMPENSATION: 'ytd_eligible_compensation',
  YTD_HOURS: 'ytd_hours',
  ZIP_CODE: 'zip_code'
};

export const HEADER_NAMES = {
  at: 'After Tax Contribution',
  city: 'City',
  country: 'Country',
  current_hours: 'Period Hours Worked',
  date_of_birth: 'Date of Birth',
  date_of_hire: 'Date of Hire',
  date_of_rehire: 'Date of Rehire',
  date_of_termination: 'Date of Termination',
  division: 'Pay Group',
  em: 'Employer Discretionary Match',
  email: 'Email',
  employee_group_name: 'Employee group',
  esa_ee_contribution: 'ESA Contribution',
  external_payroll_id: 'External Payroll ID',
  first_name: 'First Name',
  gross_compensation: 'Year To Date Gross Pay',
  last_name: 'Last Name',
  ln: 'Loan Repayment',
  loan_amt_1: 'Loan Payment 1',
  loan_amt_2: 'Loan Payment 2',
  loan_amt_3: 'Loan Payment 3',
  loan_amt_4: 'Loan Payment 4',
  loan_amt_5: 'Loan Payment 5',
  loan_num_1: 'Loan Number 1',
  loan_num_2: 'Loan Number 2',
  loan_num_3: 'Loan Number 3',
  loan_num_4: 'Loan Number 4',
  loan_num_5: 'Loan Number 5',
  marital_status: 'Marital Status',
  middle_name: 'Middle Name',
  partial_year_compensation: 'Partial Year Compensation',
  phone: 'Phone Number',
  prior_year_gross_pay: 'Prior Year Gross Pay',
  ps: 'Profit Sharing Contribution',
  qc: 'QNEC',
  qm: 'QMAC',
  rc: 'Roth Contribution',
  rc_catchup: 'Catch-up Roth Contribution',
  rc_election: 'Roth',
  salary: 'Period Gross Pay',
  sd: 'Pre-Tax Contribution',
  sd_catchup: 'Catch-up Pre-Tax Contribution',
  sh: 'Employer Safe Harbor Contribution',
  ssn: 'SSN',
  state: 'State',
  street_address_1: 'Address Line 1',
  street_address_2: 'Address Line 2',
  supplemental_gross_comp: 'Supplemental Gross Pay',
  ytd_eligible_compensation: 'Eligible Compensation',
  ytd_hours: 'Year to Date Hours Worked',
  zip_code: 'Postal Code'
};

export const DOCUMENT_CATEGORIES = {
  loan_payment: 12,
  'off-cycle_supplemental_pay': 9,
  regular: 7
};
