import Badge from '@/components/badge';
import Card, { CardContent } from '@/components/card';
import OpenInNewIcon from '@/components/icon/OpenInNewIcon';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { ContributionStatusColorMap } from '@/models/ops/alerts/ContributionStatusColorMap.model';
import { InitiateDepositRequest } from '@/models/ops/workflows/InitiateDepositRequest.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import AlertService from '@/services/ops/alerts/Alert.service';
import WorkflowService from '@/services/ops/workflows/Workflow.service';
import { PlanService } from '@/services/Plan.service';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Stack,
  Typography
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AlertStatus } from '@vestwell-sub-accounting/models/common/AlertStatus';
import { SourceOfFunds } from '@vestwell-sub-accounting/models/common/SourceOfFunds';
import { TransactionTypeCode } from '@vestwell-sub-accounting/models/common/TransactionTypeCode';

import { AxiosError } from 'axios';
import Decimal from 'decimal.js';
import { FC, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { AlertContext } from '../../AlertContext';
import { CardHeader } from '../common/CardHeader.component';
import { AchReversalContext } from './AchReversalContext';

export const AchReversalExpandedSubmitDeposit: FC = () => {
  const alert = useContext(AlertContext);
  const { context: ctx, setContext } = useContext(AchReversalContext);
  const queryClient = useQueryClient();
  const hasAchPermissions = userService.hasPermission(
    FeatureLevelPermissions.WRITE_SUBA_INITIATE_ACH
  );

  /**
   * Get the paygroups
   */
  const latestPayrollSetupQuery = useQuery(
    [
      'PlanService.getLatestPlanPayrollSetupByName',
      ctx.selectedContribution.sponsorPlanId,
      ctx.selectedContribution.payGroupName
    ],
    async () => {
      if (ctx.selectedContribution.sponsorPlanId) {
        const setup = await PlanService.getLatestPlanPayrollSetupByName(
          ctx.selectedContribution.sponsorPlanId,
          ctx.selectedContribution.payGroupName
        );
        return setup || null;
      } else {
        return null;
      }
    }
  );

  /**
   * Save mutations
   */
  const { showSnackbar } = useSnackbar();
  const [savedDepositRequest, setSavedDepositRequest] = useState(null);

  const onSubmit = async () => {
    await initiateDepositMutation.mutateAsync({
      accountNumber: latestPayrollSetupQuery.data?.bankAccount?.account,
      amount: new Decimal(ctx.selectedContribution.total).abs().toFixed(2),
      contributionYear: formatters.formatFromIsoDateCustom(
        ctx.selectedContribution.payrollDate,
        'YYYY'
      ),
      depositCode: TransactionTypeCode.ACHReversalRetry,
      planId: `${ctx.selectedContribution.sponsorPlanId}`,
      routingNumber: latestPayrollSetupQuery.data?.bankAccount?.routingNumber,
      sourceOfFunds: SourceOfFunds.ACH_PULL,
      tracerId: `UI-${uuidv4()}`,
      useForfeitureAmount: '0.00',
      useSpendingBudgetAmount: '0.00'
    });
  };

  const initiateDepositMutation = useMutation(
    ['WorkflowService.initiateDeposit'],
    (params: InitiateDepositRequest) => {
      return WorkflowService.initiateDeposit(params);
    },
    {
      onError: (e: AxiosError) => {
        showSnackbar({
          message: e?.message || `Couldn't send deposit request at this time`,
          severity: 'error'
        });
      },
      onSuccess: depositResponse => {
        setSavedDepositRequest(depositResponse);
        alertMutation.mutate({
          contributionUcid: ctx.selectedContribution.ucid,
          status: AlertStatus.InProcess,
          tracerId: depositResponse.tracerId
        });
      }
    }
  );

  const alertMutation = useMutation(
    ['AlertService.update'],
    (params: {
      status: AlertStatus;
      tracerId?: string;
      contributionUcid?: string;
    }) => {
      return AlertService.update(alert.id, {
        alertStatus: params.status,
        details: {
          depositRequestContributionUcid: params.contributionUcid,
          depositRequestTracerId: params.tracerId
        }
      });
    },
    {
      onError: (e: AxiosError) => {
        showSnackbar({
          message: e?.message || `Couldn't update alert at this time`,
          severity: 'error'
        });
      },
      onSuccess: response => {
        queryClient.invalidateQueries(['AlertService.getById']);
        showSnackbar({
          message: `Alert status updated to ${response.alertStatus}`,
          severity: 'success'
        });
      }
    }
  );

  return (
    <Card data-testid='ach-reversal-expanded-submit-deposit-card'>
      <CardHeader
        action={
          <Link
            href={`/plans/${ctx.selectedContribution.sponsorPlanId}/contributions/${ctx.selectedContribution.ucid}`}
            target='_blank'>
            <Stack
              alignContent='center'
              direction='row'
              justifyContent='center'
              spacing={1}>
              <Typography variant='body1'>View</Typography>
              <Box component='span'>
                <OpenInNewIcon sx={{ fontSize: 14 }} />
              </Box>
            </Stack>
          </Link>
        }
        title={
          <>
            {formatters.formatFromIsoDateCustom(
              ctx.selectedContribution.payrollDate,
              'MM/DD/YYYY'
            )}{' '}
            Contribution
            <Stack
              alignItems='center'
              direction='row'
              justifyContent='flex-between'
              spacing={0}>
              <Stack direction='row'>
                <Badge
                  color={
                    ContributionStatusColorMap[
                      ctx.selectedContribution.processingStatus
                    ]
                  }
                  size='small'>
                  {ctx.selectedContribution.processingStatus}
                </Badge>
              </Stack>
            </Stack>
          </>
        }></CardHeader>
      <Divider />
      <CardContent loading={latestPayrollSetupQuery.isLoading}>
        <Stack direction='column' spacing={6}>
          <Grid container spacing={2}>
            <Grid item xs>
              <TextStack direction='column' spacing={1}>
                <TextStackItem>
                  <TextLabel>Amount</TextLabel>
                  <TextValue>
                    {formatters.formatDollars(ctx.selectedContribution.total)}
                  </TextValue>
                </TextStackItem>
                <TextStackItem>
                  <TextLabel>Contribution Date</TextLabel>
                  <TextValue>
                    {formatters.formatFromIsoDateCustom(
                      ctx.selectedContribution.payrollDate,
                      'MM/DD/YYYY'
                    )}
                  </TextValue>
                </TextStackItem>
              </TextStack>
            </Grid>
            <Grid item>
              <Divider orientation='vertical' />
            </Grid>
            <Grid item xs>
              <TextStack direction='column' spacing={1}>
                <TextStackItem>
                  <TextLabel>Pay Group Name</TextLabel>
                  <TextValue>
                    {latestPayrollSetupQuery.data?.payGroupName}
                  </TextValue>
                </TextStackItem>
                <TextStackItem>
                  <TextLabel>Bank Name</TextLabel>
                  <TextValue>
                    {latestPayrollSetupQuery.data?.bankAccount?.bankName}
                  </TextValue>
                </TextStackItem>
                <TextStackItem>
                  <TextLabel>Account Number</TextLabel>
                  <TextValue>
                    {latestPayrollSetupQuery.data?.bankAccount?.account}
                  </TextValue>
                </TextStackItem>
                <TextStackItem>
                  <TextLabel>Routing Number</TextLabel>
                  <TextValue>
                    {latestPayrollSetupQuery.data?.bankAccount?.routingNumber}
                  </TextValue>
                </TextStackItem>
              </TextStack>
            </Grid>
          </Grid>

          {latestPayrollSetupQuery.data?.payGroupName &&
            !latestPayrollSetupQuery.isLoading &&
            !savedDepositRequest && (
              <Alert
                data-testid='ach-reversal-expanded-submit-deposit-confirm-alert'
                icon={false}
                severity='info'
                variant='outlined'>
                <Stack direction='column' spacing={2}>
                  <AlertTitle>
                    <Typography variant='body1'>
                      Resubmit ACH for this contribution?
                    </Typography>
                  </AlertTitle>
                  <Stack direction='row' spacing={2}>
                    <Button
                      data-testid='ach-reversal-expanded-submit-deposit-submit-button'
                      disabled={
                        initiateDepositMutation.isLoading || !hasAchPermissions
                      }
                      onClick={onSubmit}
                      variant='contained'>
                      {initiateDepositMutation.isLoading
                        ? 'Loading...'
                        : 'Resubmit'}
                    </Button>
                    <Button
                      data-testid='ach-reversal-expanded-submit-deposit-select-different-button'
                      disabled={initiateDepositMutation.isLoading}
                      onClick={() => setContext(null)}>
                      Select a different contribution
                    </Button>
                  </Stack>
                </Stack>
              </Alert>
            )}

          {!latestPayrollSetupQuery.data?.payGroupName &&
            !latestPayrollSetupQuery.isLoading &&
            !savedDepositRequest && (
              <Alert
                data-testid='ach-reversal-expanded-submit-deposit-no-paygroup-found-alert'
                severity='error'
                variant='outlined'>
                <Stack direction='column' spacing={2}>
                  <AlertTitle>
                    <Typography variant='body1'>
                      No paygroup found for this contribution
                    </Typography>
                  </AlertTitle>
                  <Button
                    color='error'
                    data-testid='ach-reversal-expanded-submit-deposit-select-different-button'
                    onClick={() => setContext(null)}>
                    Select a different contribution
                  </Button>
                </Stack>
              </Alert>
            )}

          {savedDepositRequest && (
            <Alert
              action={
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'center'
                  }}>
                  <Button
                    disabled={alertMutation.isLoading}
                    onClick={() =>
                      alertMutation.mutate({ status: AlertStatus.Closed })
                    }>
                    Close Ticket
                  </Button>
                </Box>
              }
              data-testid='ach-reversal-expanded-submit-deposit-success-alert'
              icon={false}
              severity='success'
              variant='outlined'>
              <Stack direction='column' spacing={2}>
                <AlertTitle>
                  <Typography variant='body1'>
                    ACH has been submitted
                  </Typography>
                </AlertTitle>
                <Stack
                  direction='row'
                  spacing={2}
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center'
                  }}>
                  <Link
                    data-testid='ach-reversal-expanded-submit-deposit-workflow-link'
                    href={`/ops/workflows/tracer:${savedDepositRequest.tracerId}`}
                    target='_blank'>
                    tracerId: {savedDepositRequest?.tracerId}{' '}
                    <Box
                      component='span'
                      sx={{
                        lineHeight: 1,
                        pl: 0.5
                      }}>
                      <OpenInNewIcon sx={{ fontSize: 14 }} />
                    </Box>
                  </Link>
                  <Badge color='success' size='small'>
                    {formatters.displayCase(
                      savedDepositRequest.status?.toLowerCase()
                    )}
                  </Badge>
                </Stack>
              </Stack>
            </Alert>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

AchReversalExpandedSubmitDeposit.displayName =
  'AchReversalExpandedSubmitDeposit';
