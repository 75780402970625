import { PlanPayrollSetupInfo } from '@/models';
import { LoanBriefInfo } from '@/models/LoanDTO.model';
import { PlanService } from '@/services/Plan.service';
import { formatSsn } from '@vestwell-frontend/helpers';

import Decimal from 'decimal.js';
import { isEmpty } from 'lodash';
import * as yup from 'yup';

const formattedSSN = /^(?!(000))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/;
const plainSSN = /^(?!(000))\d{3}(?!00)\d{2}(?!0000)\d{4}$/;
const blacklistedSSNs = ['123-45-6789', '987-65-4321'];

const sourcePercentValidation = yup
  .number()
  .max(100, 'Max value is 100')
  .notRequired();

export const loansValidationSchema = (
  planId: number,
  fundingSources?: string[],
  payrollSetup?: PlanPayrollSetupInfo[],
  loansPerPlan?: LoanBriefInfo
): any => {
  const currentPlanPayrollSetupIds = payrollSetup?.map(e => e.id) || [];
  const currentPlanPayrollFrequencies =
    payrollSetup?.map(e => e.frequency.toUpperCase()) || [];
  const loansPerPlanMap = new Map(
    loansPerPlan?.data?.map(obj => [obj.loanId, obj.ssn])
  );
  const sourceTypeValidation = yup
    .string()
    .oneOf(
      fundingSources || [],
      `Valid funding sources: ${fundingSources?.join(', ')}`
    )
    .notRequired();

  return yup
    .array()
    .of(
      yup.object().when({
        is: ({ ...row }) => !isEmpty(row),
        then: schema =>
          schema
            .shape({
              /* eslint-disable sort-keys-plus/sort-keys */
              ssn: yup
                .string()
                .required('SSN is required.')
                .test(
                  'Check for valid SSN format',
                  'Please enter a valid SSN',
                  (value?: string) => {
                    if (value) {
                      const ssnNoDashes = value.replace(/-/g, '');
                      const ssnDigits = new Set(ssnNoDashes.split(''));
                      return (
                        (formattedSSN.test(value) || plainSSN.test(value)) &&
                        ssnDigits.size > 1 &&
                        !blacklistedSSNs.includes(ssnNoDashes)
                      );
                    }
                    return true;
                  }
                )
                .test(
                  'Check if SSN exist within a plan',
                  'This SSN does not exists in plan.',
                  async value => {
                    let checkResult;
                    if (
                      planId &&
                      value &&
                      (formattedSSN.test(value) || plainSSN.test(value)) &&
                      !blacklistedSSNs.includes(value.replaceAll('-', '')) &&
                      new Set(value.replaceAll('-', '').split('')).size > 1
                    ) {
                      const ssnToValidate = formattedSSN.test(value)
                        ? value
                        : formatSsn(value);
                      checkResult = await PlanService.checkSsnWithinPlan(
                        planId,
                        ssnToValidate
                      );
                    } else {
                      await new Promise(resolve => {
                        setTimeout(resolve, 50);
                      });
                    }
                    return checkResult?.data ?? false;
                  }
                ),
              purpose: yup
                .string()
                .required('purpose is required.')
                .oneOf(
                  ['General', 'Purchase of a Principal Residence'],
                  'Must be “General” or “Purchase of a Principal Residence”'
                ),
              loan_origination_date: yup
                .date()
                .optional()
                .typeError(
                  'loan_origination_date must be in YYYY-MM-DD format.'
                )
                .max(
                  new Date(),
                  'loan_origination_date must be prior to today.'
                ),
              original_loan_amount: yup
                .number()
                .optional()
                .typeError('must be only digits'),
              balance: yup
                .number()
                .required('balance is required')
                .typeError('must be only digits')
                .moreThan(0, 'must be balance > 0'),
              interest_rate: yup
                .number()
                .required('interest_rate is required')
                .typeError('must be only digits'),
              payment_amount: yup
                .number()
                .required('payment_amount is required')
                .typeError('must be only digits'),
              pay_frequency: yup
                .string()
                .optional()
                .oneOf(
                  currentPlanPayrollFrequencies,
                  `Must be in: [${currentPlanPayrollFrequencies.join(', ')}]`
                )
                .test(
                  'Check if payroll freq is unique within the plan',
                  'More than one pay frequency found in Plan. Use payroll_setup_id instead.',
                  value => {
                    if (value)
                      return (
                        currentPlanPayrollFrequencies.filter(f => f === value)
                          .length < 2
                      );
                    else return true;
                  }
                ),
              status: yup
                .string()
                .required()
                .oneOf(
                  ['Live', 'Default', 'Deemed'],
                  'Must be: "Live", "Default" or "Deemed"'
                ),
              payroll_setup_id: yup
                .number()
                .test(
                  'both pay_frequency and payroll_setup_id are empty',
                  'One of pay_frequency and payroll_setup_id must be entered for a loan',
                  (psi, context) => {
                    const payFrequency = context.parent.pay_frequency;
                    if (!payFrequency && !psi) return false;
                    return true;
                  }
                )
                .test(
                  'both pay_frequency and payroll_setup_id are set',
                  'Only one of pay_frequency and payroll_setup_id can be entered for a loan',
                  (psi, context) => {
                    const payFrequency = context.parent.pay_frequency;
                    if (payFrequency && psi) return false;
                    return true;
                  }
                )
                .oneOf(
                  currentPlanPayrollSetupIds,
                  `Must be in: [${currentPlanPayrollSetupIds.join(', ')}]`
                ),
              parent_loan_id: yup
                .number()
                .optional()
                .test(
                  'parent_loan_id exists in loans data',
                  'The provided parent_loan_id does not exist for this participant.',
                  (parentLoanId, context) => {
                    if (parentLoanId) {
                      const ssn = context.parent.ssn;
                      return loansPerPlanMap.get(parentLoanId) === ssn;
                    }
                    return true;
                  }
                ),
              is_conversion: yup
                .string()
                .optional()
                .oneOf(
                  ['true', 'false', 'True', 'False', 'TRUE', 'FALSE'],
                  'Must be "true" or "false"'
                ),
              source1_type: sourceTypeValidation,
              source1_percent: sourcePercentValidation,
              source2_type: sourceTypeValidation,
              source2_percent: sourcePercentValidation,
              source3_type: sourceTypeValidation,
              source3_percent: sourcePercentValidation,
              source4_type: sourceTypeValidation,
              source4_percent: sourcePercentValidation,
              source5_type: sourceTypeValidation,
              source5_percent: sourcePercentValidation,
              source6_type: sourceTypeValidation,
              source6_percent: sourcePercentValidation,
              source7_type: sourceTypeValidation,
              source7_percent: sourcePercentValidation,
              source8_type: sourceTypeValidation,
              source8_percent: sourcePercentValidation,
              source9_type: sourceTypeValidation,
              source9_percent: sourcePercentValidation,
              source10_type: sourceTypeValidation,
              source10_percent: sourcePercentValidation,
              source11_type: sourceTypeValidation,
              source11_percent: sourcePercentValidation,
              source12_type: sourceTypeValidation,
              source12_percent: sourcePercentValidation,
              source13_type: sourceTypeValidation,
              source13_percent: sourcePercentValidation,
              source14_type: sourceTypeValidation,
              source14_percent: sourcePercentValidation,
              source15_type: sourceTypeValidation,
              source15_percent: sourcePercentValidation
            })
            .test(
              `test-totalPercent`,
              'Percent total should be equal 100',
              function (value) {
                const { path, createError } = this;
                const sourcePercentTotal = [
                  value.source1_percent,
                  value.source2_percent,
                  value.source3_percent,
                  value.source4_percent,
                  value.source5_percent,
                  value.source6_percent,
                  value.source7_percent,
                  value.source8_percent,
                  value.source9_percent,
                  value.source10_percent,
                  value.source11_percent,
                  value.source12_percent,
                  value.source13_percent,
                  value.source14_percent,
                  value.source15_percent
                ].reduce(
                  (acc, v) => acc.plus(new Decimal(v || 0)),
                  new Decimal(0)
                );
                return sourcePercentTotal.equals(new Decimal(100))
                  ? true
                  : createError({
                      path: `${path}.source1_percent`,
                      message: 'Percent total should be equal 100'
                    });
              }
            ),
        otherwise: schema => schema
      })
    )
    .min(1);
};
