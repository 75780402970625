import Constants from '@/consts/planDesign.constants';
import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class PartnerSystemName {
  convertToApiState = Helper.convertValueToApiState;

  options = Constants.recordkeepers.map(rk => rk.name);

  optionsDisplay = Constants.recordkeepersDisplay;

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.output = props.partnerSystemName;
  }
}

export default PartnerSystemName;
