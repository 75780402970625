import ContributionService from '@/services/Contribution.service';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormLabel,
  TextField
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useMutation } from '@tanstack/react-query';

import React, { FC, useCallback, useMemo, useState } from 'react';

type ForceCompleteProps = {
  isOpen: boolean;
  toggleOpen: () => void;
  ucid: string;
  onComplete: () => void;
};

export const ForceComplete: FC<ForceCompleteProps> = props => {
  const [reason, setReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');

  const postForceCompletion = useMutation(
    (data: { reason: string; additionalInfo: string }) =>
      ContributionService.postContributionCompletion({ ucid: props.ucid }, data)
  );

  const isDisabled = useMemo(
    () =>
      additionalInfo
        ? reason
          ? reason === 'Other'
            ? !otherReason
            : false
          : true
        : true,
    [reason, otherReason, additionalInfo]
  );

  const onReasonChange = useCallback(
    (event, value) => {
      setReason(value);

      if (value !== 'Other') setOtherReason('');
    },
    [setReason]
  );

  const onOtherReasonChange = useCallback(
    event => setOtherReason(event.target?.value),
    [setOtherReason]
  );

  const onInfoChange = useCallback(
    event => setAdditionalInfo(event.target?.value),
    [setAdditionalInfo]
  );

  const onConfirm = useCallback(async () => {
    props.toggleOpen();
    await postForceCompletion.mutateAsync({
      additionalInfo,
      reason: otherReason || reason
    });
    props.onComplete();
  }, [reason, otherReason, additionalInfo, props, postForceCompletion]);

  return (
    <Dialog
      fullWidth
      maxWidth='xs'
      onClose={props.toggleOpen}
      open={props.isOpen}>
      <DialogTitle>Force complete correction</DialogTitle>
      <DialogContent>
        <FormControl>
          <FormLabel>Select a reason:</FormLabel>
          <RadioGroup name='reason' onChange={onReasonChange} value={reason}>
            <FormControlLabel
              control={<Radio />}
              data-testid='forceComplete-reason'
              label='LT submitted contribution'
              value='LT submitted contribution'
            />
            <FormControlLabel
              control={<Radio />}
              data-testid='forceComplete-reason'
              label='System Error'
              value='System Error'
            />
            <FormControlLabel
              control={<Radio />}
              data-testid='forceComplete-reason'
              label='Other'
              value='Other'
            />
          </RadioGroup>
        </FormControl>
        {reason === 'Other' && (
          <TextField
            data-testid='forceComplete-reason-other'
            label='Reason'
            minRows={3}
            multiline
            onChange={onOtherReasonChange}
            sx={{ display: 'flex', ml: 3, mt: 1 }}
          />
        )}
        <Divider light sx={{ my: 3 }} />
        <TextField
          data-testid='forceComplete-additional-information'
          fullWidth
          helperText='JIRA ticket, etc.'
          label='Additional Information'
          minRows={3}
          multiline
          onChange={onInfoChange}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button data-testid='forceComplete-cancel' onClick={props.toggleOpen}>
          Cancel
        </Button>
        <Button
          data-testid='forceComplete-submit'
          disabled={isDisabled}
          onClick={onConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
