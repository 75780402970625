import SimpleDropdown from '@/components/simple-dropdown';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { PartnerSystemMappingDto } from '@/models/PartnerSystem.model';
import { PlanService } from '@/services/Plan.service';
import {
  Alert,
  AlertTitle,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  Typography
} from '@mui/material';
import { styled } from '@mui/styles';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Payroll } from '@vestwell-api/scala';

import { Form, Formik, useFormikContext } from 'formik';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useToggle } from 'react-use';
import * as yup from 'yup';

import PayrollProviderDto from '../../../../models/PayrollProviderDTO.model';

interface PayGroupsUpdateModalProps {
  sponsorPlanId: number | string;
  payGroupsAmount: number | undefined;
  onUpdate: () => void;
}

interface PayrollIntegrationFieldProps {
  sponsorPlanId: number | string;
  payrollProviders: { value: number; option: string }[] | undefined;
  isIntegrationChecked: boolean;
  setLength180: (length: number) => void;
  setLength360: (length: number) => void;
  setValues: (values: { payrollProviderId: number }) => void;
  toggleIsIntegrationChecked: () => void;
  validateForm: (values?: any) => Promise<any>;
}

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  height: theme.spacing(46),
  marginBottom: theme.spacing(2)
}));

const PayrollIntegrationField: FC<PayrollIntegrationFieldProps> = props => {
  const { isIntegrationChecked, validateForm } = props;
  const { values } = useFormikContext<{
    payrollProviderId: number;
    externalId: string;
    integrationMethod180: number;
    integrationMethod360: number;
  }>();

  const onChange = () => {
    props.toggleIsIntegrationChecked();
    props.setValues({ payrollProviderId: values.payrollProviderId });
  };

  useEffect(() => {
    validateForm();
  }, [isIntegrationChecked, validateForm]);

  const integrationMethods180 = useQuery<
    {
      value: number;
      option: string;
    }[]
  >(
    ['PlanService.getIntegrationMethods', values.payrollProviderId, 2],
    async () => {
      return PlanService.getIntegrationMethods(values.payrollProviderId, 2);
    },
    {
      onSuccess: (
        data: {
          value: number;
          option: string;
        }[]
      ) => {
        props.setLength180(data?.length);
      }
    }
  );

  const integrationMethods360 = useQuery<
    {
      value: number;
      option: string;
    }[]
  >(
    ['PlanService.getIntegrationMethods', values.payrollProviderId, 3],
    async () => {
      return PlanService.getIntegrationMethods(values.payrollProviderId, 3);
    },
    {
      onSuccess: (
        data: {
          value: number;
          option: string;
        }[]
      ) => {
        props.setLength360(data?.length);
      }
    }
  );

  const partnerSystemName = props.payrollProviders?.find(
    payrollProvider => payrollProvider.value === values.payrollProviderId
  )?.option;

  const partnerSystemMappings = useQuery<PartnerSystemMappingDto[]>(
    [
      'PlanService.getPartnerSystemMappings',
      3,
      props.sponsorPlanId,
      partnerSystemName
    ],
    async () => {
      return PlanService.getPartnerSystemMappings(
        3,
        +props.sponsorPlanId,
        partnerSystemName
      );
    }
  );

  const filteredPartnerSystemMappings = useMemo(() => {
    return partnerSystemMappings.data?.length
      ? partnerSystemMappings.data
          ?.filter(partnerSystemRow => partnerSystemRow.externalId)
          ?.map(partnerSystemRow => ({
            option: (
              <Grid container direction='column'>
                <Typography color='text.primary' variant='body2'>
                  {partnerSystemRow.externalId}
                </Typography>
                <Typography color='text.secondary' variant='body2'>
                  Created {partnerSystemRow.createdAt}
                </Typography>
              </Grid>
            ),
            value: partnerSystemRow.externalId as string
          }))
      : [];
  }, [partnerSystemMappings.data]);

  return (
    <>
      {integrationMethods180.data?.length ||
      integrationMethods360.data?.length ? (
        <FormControlLabel
          control={
            <Switch checked={props.isIntegrationChecked} onChange={onChange} />
          }
          label={`Integration: ${props.isIntegrationChecked ? 'On' : 'Off'}`}
          sx={{ width: '12rem' }}
        />
      ) : (
        <></>
      )}
      {(integrationMethods180.data?.length ||
        integrationMethods360.data?.length) &&
      props.isIntegrationChecked ? (
        <>
          <Grid columns={12} container direction='row' spacing={2}>
            {!!integrationMethods180.data?.length && (
              <Grid
                item
                md={integrationMethods360.data?.length ? 6 : 12}
                xs={12}>
                <SimpleDropdown
                  fieldId='integrationMethod180'
                  fieldName='Receiving Data Method (180)'
                  fieldOptions={integrationMethods180.data}
                  size='small'
                />
              </Grid>
            )}
            {!!integrationMethods360.data?.length && (
              <Grid item md={6} xs={12}>
                <SimpleDropdown
                  fieldId='integrationMethod360'
                  fieldName='Sending Data Method (360)'
                  fieldOptions={[
                    {
                      option: (
                        <Typography sx={{ fontStyle: 'italic' }}>
                          No 360 Method
                        </Typography>
                      ),
                      value: ''
                    },
                    ...integrationMethods360.data
                  ]}
                  size='small'
                />
              </Grid>
            )}
          </Grid>
          <Divider />
          {filteredPartnerSystemMappings?.length ? (
            <SimpleDropdown
              fieldId='externalId'
              fieldName='Select an External ID'
              fieldOptions={filteredPartnerSystemMappings}
              size='small'
            />
          ) : (
            <Alert severity='warning'>
              <AlertTitle>
                No external IDs exist for this Payroll System.
              </AlertTitle>
              Please add an external ID via ⠇→ Manage External IDs, before
              running a bulk update.
            </Alert>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export const PayGroupsUpdateModal = (
  props: PayGroupsUpdateModalProps
): JSX.Element => {
  const { showSnackbar } = useSnackbar();
  const ctx = useDialog();
  const [length180, setLength180] = useState(0);
  const [length360, setLength360] = useState(0);
  const [isIntegrationChecked, toggleIsIntegrationChecked] = useToggle(true);

  const validationSchema = yup.object({
    externalId: yup.string().when('payrollProviderId', {
      is: (value: number) =>
        value === 6 || (!length180 && !length360) || !isIntegrationChecked,
      otherwise: yup.string().required('External ID is required'),
      then: yup.string().optional()
    }),
    integrationMethod180: yup.number().when('payrollProviderId', {
      is: (value: number) =>
        value === 6 || (!length180 && !length360) || !isIntegrationChecked,
      otherwise: yup
        .number()
        .required('Receiving Data Method (180) is required'),
      then: yup.number().optional()
    }),
    integrationMethod360: yup.number().optional(),
    payrollProviderId: yup.number().required('Payroll System is required')
  });

  const payrollProviders = useQuery<PayrollProviderDto[]>(
    ['PlanService.getPayrollProviders'],
    async () => {
      return PlanService.getPayrollProviders();
    }
  );

  const payrollProvidersOptions = useMemo(() => {
    return payrollProviders.data
      ?.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      })
      .map(provider => {
        return { option: provider.name, value: provider.id };
      });
  }, [payrollProviders.data]);

  const updatePayrollSetups = useMutation(
    (data: Payroll.PutPayrollSetupBulk.RequestBody) => {
      return PlanService.updatePayrollSetups(props.sponsorPlanId, data);
    },
    {
      onError: () => {
        showSnackbar({
          message: `Failure! Your Pay Groups' Payroll Integration settings were not updated!`,
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: `Payroll Integration settings for all your Pay Groups were successfully updated!`,
          severity: 'success'
        });
      }
    }
  );

  const handleSubmit = async (values: {
    payrollProviderId: number | undefined;
    externalId?: string;
    integrationMethod180?: number;
    integrationMethod360?: number;
  }) => {
    await updatePayrollSetups.mutateAsync(
      values as Payroll.PutPayrollSetupBulk.RequestBody
    );
    ctx.closeDialog();
    props.onUpdate();
  };

  return (
    <Formik
      initialValues={{
        payrollProviderId: undefined
      }}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={true}
      validateOnMount={true}
      validationSchema={validationSchema}>
      {({ setValues, values, isValid, validateForm }) => {
        return (
          <Form>
            <DialogTitle>Bulk Update Integration Settings</DialogTitle>
            <StyledDialogContent data-testid='updateIntegrationSettingsModalContent'>
              <Typography color='text.primary' variant='body2'>
                Changes made here will only apply to active pay groups.
              </Typography>
              <Divider />
              <SimpleDropdown
                fieldId='payrollProviderId'
                fieldName='Payroll System'
                fieldOptions={payrollProvidersOptions}
                onChange={value =>
                  setValues({ payrollProviderId: value.target.value })
                }
                size='small'
              />
              {values.payrollProviderId === 6 ? (
                <Alert severity='info'>
                  Please contact{' '}
                  <a href='mailto:payroll-integrations-dev@vestwell.com'>
                    payroll-integrations-dev@vestwell.com
                  </a>{' '}
                  if you would like a new Payroll System added to the list.
                </Alert>
              ) : values.payrollProviderId ? (
                <PayrollIntegrationField
                  isIntegrationChecked={isIntegrationChecked}
                  payrollProviders={payrollProvidersOptions}
                  setLength180={setLength180}
                  setLength360={setLength360}
                  setValues={setValues}
                  sponsorPlanId={props.sponsorPlanId}
                  toggleIsIntegrationChecked={toggleIsIntegrationChecked}
                  validateForm={validateForm}
                />
              ) : (
                <></>
              )}
            </StyledDialogContent>
            <DialogActions>
              <Button
                data-testid='cancelBtn'
                onClick={ctx.closeDialog}
                variant='text'>
                CANCEL
              </Button>
              <Button
                data-testid='submitBtn'
                disabled={updatePayrollSetups.isLoading || !isValid}
                type='submit'
                variant='text'>
                {`UPDATE (${props.payGroupsAmount} ACTIVE ${
                  props.payGroupsAmount === 1 ? 'PAY GROUP)' : 'PAY GROUPS)'
                }`}
              </Button>
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
};
