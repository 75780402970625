import AccessControl from '@/components/access-control/AccessControl.component';
import useHasPermissions from '@/components/access-control/useHasPermissions.hook';
import Badge from '@/components/badge';
import SimpleTabs, { SimpleTabsProps, TabData } from '@/components/simple-tabs';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import {
  GetRecentUsersLoginsDto,
  UserMfaMethodDto,
  UsersItemDto
} from '@/models/UsersDTO.model';
import { UserManagementRecentLogins } from '@/routes/user-management/user-management-recent-logins/UserManagementRecentLogins.component';
import UserManagementService from '@/services/UserManagement.service';
import formatters from '@/utils/Formatters';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Paper,
  Theme,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useQuery } from '@tanstack/react-query';

import { kebabCase, upperFirst } from 'lodash';
import React, { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { UserManagementAccountAccess } from '../user-management-account-access/UserManagementAccountAccess.component';
import UserManagementAssociate from '../user-management-associate/UserManagementAssociate.component';
import { UserManagementResetPassword } from '../user-management-reset-password/UserManagementResetPassword.component';
import { UserManagementTwoFactorAuth } from '../user-management-two-factor-auth/UserManagementTwoFactorAuth.component';
import { UserManagementAssociations } from '../UserManagementAssociations.component';
import { UserManagementUpdateEmail } from './UserManagementUpdateEmail.component';

interface UserManagementSelectedUserProps {
  refetchUser: () => void;
  selectedUser: UsersItemDto;
  setSelectedUser: React.Dispatch<any>;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  canWriteUserManagementParticipant?: boolean;
  userId?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  accordions: {
    display: 'overflow',
    padding: theme.spacing(3, 30)
  },
  clearUser: {
    color: theme.palette.text.secondary,
    cursor: 'pointer'
  },
  selectedUserContainer: {
    height: theme.spacing(12),
    padding: theme.spacing(2),
    width: theme.spacing(60)
  },
  selectedUserField: {
    color: theme.palette.text.secondary,
    fontSize: theme.spacing(1.8)
  }
}));

export const UserManagementSelectedUser: FC<
  UserManagementSelectedUserProps
> = props => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const hasAccessToLoginTab = useHasPermissions({
    requiresOneOf: [
      FeatureLevelPermissions.WRITE_USER_MANAGEMENT,
      FeatureLevelPermissions.WRITE_USER_MANAGEMENT_LOGIN
    ]
  });
  const canWriteUserManagementParticipant = useHasPermissions({
    requires: [FeatureLevelPermissions.WRITE_USER_MANAGEMENT_PARTICIPANT]
  });
  const canWriteUserManagement = useHasPermissions({
    requires: [FeatureLevelPermissions.WRITE_USER_MANAGEMENT]
  });

  const { selectedUser, setSelectedUser, setSearchTerm } = props;

  const mfaMethods = useQuery<UserMfaMethodDto[]>(
    ['UserManagementService.getUserMfaMethods', props.selectedUser.userId],
    () => UserManagementService.getUserMfaMethods(+props.selectedUser.userId)
  );

  const recentLogins = useQuery<GetRecentUsersLoginsDto[]>(
    ['UserManagementService.getRecentUsersLogins', props.selectedUser?.userId],
    () => {
      return UserManagementService.getRecentUsersLogins(
        10,
        props.selectedUser?.userId
      );
    }
  );

  useEffect(() => {
    if (selectedUser && location.pathname === '/user-management') {
      setSelectedUser(null);
      setSearchTerm('');
    }
  });

  const getTabElements = (userSelected: UsersItemDto) => [
    {
      component: (
        <Paper data-testid='user-management-update-email'>
          <UserManagementAssociate
            canWriteUserManagement={canWriteUserManagement.isAllowed}
            canWriteUserManagementParticipant={
              canWriteUserManagementParticipant.isAllowed
            }
            selectedUser={userSelected}
          />
          <UserManagementAssociations selectedUser={userSelected} />
        </Paper>
      ),
      hash: 'associations',
      label: 'Associations',
      path: `/user-management/${selectedUser.userId}/user`
    },
    {
      component: (
        <Paper className={classes.accordions}>
          {[
            <UserManagementUpdateEmail
              key='Update Login Email'
              selectedUser={userSelected}
              setSelectedUser={setSelectedUser}
            />,
            <UserManagementResetPassword
              key='Reset Password'
              selectedUser={userSelected}
            />,
            <UserManagementAccountAccess
              badge={{
                color: selectedUser?.isAccessRestricted ? 'warning' : 'success',
                value: selectedUser?.isAccessRestricted
                  ? 'Restricted'
                  : 'Accessible'
              }}
              key='Account Access'
              refetchUser={props.refetchUser}
              selectedUser={selectedUser}
            />,
            <UserManagementTwoFactorAuth
              badge={{
                color: mfaMethods.data?.length ? 'success' : 'warning',
                value: mfaMethods.data?.length ? 'Enabled' : 'Disabled'
              }}
              key='2-Factor Authentication'
              mfaMethods={mfaMethods.data}
              onDisable={mfaMethods.refetch}
              selectedUser={userSelected}
            />,
            <UserManagementRecentLogins
              key='Recent Logins'
              recentLogins={recentLogins.data}
              selectedUser={userSelected}
              text={recentLogins.data?.[0]?.createdAt}
            />
          ].map(component => {
            return (
              <Accordion
                data-testid={`user-management-${kebabCase(
                  component.key
                )}-accordion`}
                key={component.key}>
                <AccordionSummary
                  aria-controls={`${kebabCase(component.key)}-content`}
                  data-testid={`user-management-${kebabCase(
                    component.key
                  )}-content`}
                  expandIcon={<ExpandMoreIcon />}
                  id={`${kebabCase(component.key)}-header`}>
                  <Grid alignItems='center' container>
                    <Grid item lg={3}>
                      {component.key}
                    </Grid>
                    {component.props.badge && (
                      <Grid item lg={4}>
                        <Badge
                          color={component.props.badge.color}
                          size='medium'>
                          {component.props.badge.value}
                        </Badge>
                      </Grid>
                    )}
                    {component.props.text && (
                      <Grid item lg={4}>
                        <Typography variant='body2'>
                          Last Login:{' '}
                          {formatters.formatFromIsoDateCustom(
                            component.props.text,
                            'M/DD/YY, HH:mma'
                          )}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>{component}</AccordionDetails>
              </Accordion>
            );
          })}
        </Paper>
      ),
      hash: 'login',
      label: 'Login',
      path: `/user-management/${selectedUser.userId}/user`
    }
  ];
  const tabElements = getTabElements(selectedUser) as TabData[];

  const filteredTabElements = hasAccessToLoginTab
    ? tabElements
    : tabElements.filter(tab => tab.label !== 'Login');

  const tabs: SimpleTabsProps = {
    tabs: filteredTabElements,
    tabsAriaLabel: 'accounts-tabs'
  };

  const mfaMethodsNames = mfaMethods.data?.length
    ? Array.from(
        new Set(
          [...mfaMethods.data]
            .sort((a, b) => {
              return b.entryType
                .toLowerCase()
                .localeCompare(a.entryType.toLowerCase());
            })
            .map(mfaEntry =>
              mfaEntry?.entryType === 'phone'
                ? 'Phone'
                : upperFirst(mfaEntry.entryType)
            )
        )
      ).join(' & ')
    : '';

  return (
    <>
      <Grid item>
        <Paper
          className={classes.selectedUserContainer}
          data-testid='user-management-selected-user-container'>
          <Grid alignItems='center' container justifyContent='space-between'>
            <Grid item>
              <Grid
                container
                data-testid='user-management-selected-user-info'
                direction='column'>
                <Grid item>
                  <Typography>{selectedUser.email}</Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.selectedUserField}>
                    ID: {selectedUser.userId}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.selectedUserField}>
                    Status: Password{' '}
                    {selectedUser?.password ? 'set' : 'not set'}
                    {!!mfaMethods.data?.length && `, 2FA via `}
                    {mfaMethodsNames}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <AccessControl
                requires={[FeatureLevelPermissions.READ_USER_MANAGEMENT]}>
                <ClearIcon
                  className={classes.clearUser}
                  data-testid='SelectedUserClearIcon'
                  onClick={() => {
                    setSelectedUser(null);
                    setSearchTerm('');
                    navigate('/user-management', { replace: true });
                  }}
                />
              </AccessControl>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid container>
        <SimpleTabs
          {...tabs}
          enableNestedHash
          isCustomAppBar
          style={{ borderBottom: '1px solid rgba(33, 150, 243, 0.12)' }}
        />
      </Grid>
    </>
  );
};
