import { Button, ListItem, Theme, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';

import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  associateSearchButton: {
    justifyContent: 'right',
    lineHeight: 1,
    marginTop: 'auto',
    p: 0,
    width: '30px'
  },
  associateSearchItemField: {
    color: theme.palette.text.secondary,
    fontSize: theme.spacing(1.5)
  },
  associateSearchItemIdField: {
    color: theme.palette.text.secondary,
    fontSize: theme.spacing(1.5),
    marginTop: theme.spacing(0.5),
    textAlign: 'right'
  },
  associateSearchItemImg: {
    color: theme.palette.success.dark,
    paddingBottom: theme.spacing(2.5)
  },
  associateSearchItemItem: {
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    },

    alignItems: 'start'
  }
}));

interface AssociateSearchItemProps {
  item: any;
  itemId?: string | number;
  itemIdText?: string;
  onItemAdd: (item: any) => void;
  icon: JSX.Element;
  primaryItemText: string;
  itemDescriptions: string[];
  hideAddButton?: boolean;
}
const AssociateSearchItem: React.FunctionComponent<
  AssociateSearchItemProps
> = ({
  item,
  itemId,
  itemIdText,
  onItemAdd,
  icon,
  primaryItemText,
  itemDescriptions,
  hideAddButton = false
}): JSX.Element => {
  const classes = useStyles();

  return (
    <ListItem
      className={classes.associateSearchItemItem}
      data-testid={`user-management-associate-search-list-item-${itemId}`}
      key={itemId}>
      <Grid container spacing={1} width='100%'>
        <Grid
          data-testid='user-management-associate-search-list-item-icon'
          display='flex'
          flexDirection='column'
          xs={1.5}>
          {icon}
        </Grid>
        <Grid
          data-testid='user-management-associate-search-list-item-primary-text'
          xs={6}>
          <Typography>{primaryItemText}</Typography>
        </Grid>
        <Grid xs={4}>
          {itemIdText && itemId && (
            <Typography
              className={classes.associateSearchItemIdField}
              data-testid='user-management-associate-search-list-item-id'>
              {`${itemIdText} ${itemId}`}
            </Typography>
          )}
        </Grid>
        <Grid xs={1.5}></Grid>
        <Grid
          data-testid='user-management-associate-search-list-item-description'
          xs={6}>
          {itemDescriptions.map((description, index) => (
            <Typography
              className={classes.associateSearchItemField}
              data-testid={`user-management-associate-search-list-item-description-${index}`}
              key={description}>
              {description}
            </Typography>
          ))}
        </Grid>
        <Grid
          alignItems='flex-end'
          display='flex'
          flexDirection='column'
          xs={4}>
          {!hideAddButton && (
            <Button
              className={classes.associateSearchButton}
              data-testid='user-management-associate-search-list-item-add-button'
              onClick={() => onItemAdd(item)}
              variant='text'>
              add
            </Button>
          )}
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default AssociateSearchItem;
