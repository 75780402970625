import { PlanService } from '@/services/Plan.service';
import { formatSsn } from '@vestwell-frontend/helpers';

import { isEmpty } from 'lodash';
import * as yup from 'yup';

const formattedSSN = /^(?!(000))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/;
const plainSSN = /^(?!(000))\d{3}(?!00)\d{2}(?!0000)\d{4}$/;
const blacklistedSSNs = ['123-45-6789', '987-65-4321'];

export const validationSchema = (planId: number): any =>
  yup
    .array()
    .of(
      yup.object().when({
        is: ({ ...row }) => !isEmpty(row),
        otherwise: schema => schema,
        then: schema =>
          schema.shape({
            rothCostBasis: yup
              .number()
              .typeError('rothCostBasis must be numeric.')
              .min(0, 'rothCostBasis can’t be less than $0.')
              .required('rothCostBasis is required.'),
            rothEstablishedDate: yup
              .date()
              .required('rothEstablishedDate is required')
              .typeError('rothEstablishedDate must be in YYYY-MM-DD format.')
              .max(new Date(), 'rothEstablishedDate must be prior to today.'),
            ssn: yup
              .string()
              .required('SSN is required.')
              .test(
                'Check for valid SSN format',
                'Please enter a valid SSN',
                (value?: string) => {
                  if (value) {
                    const ssnNoDashes = value.replace(/-/g, '');
                    const ssnDigits = new Set(ssnNoDashes.split(''));
                    return (
                      (formattedSSN.test(value) || plainSSN.test(value)) &&
                      ssnDigits.size > 1 &&
                      !blacklistedSSNs.includes(ssnNoDashes)
                    );
                  }
                  return true;
                }
              )
              .test(
                'Check if SSN exist within a plan',
                'This SSN does not exists in plan.',
                async value => {
                  let checkResult;
                  if (
                    planId &&
                    value &&
                    (formattedSSN.test(value) || plainSSN.test(value)) &&
                    !blacklistedSSNs.includes(value.replaceAll('-', '')) &&
                    new Set(value.replaceAll('-', '').split('')).size > 1
                  ) {
                    const ssnToValidate = formattedSSN.test(value)
                      ? value
                      : formatSsn(value);
                    checkResult = await PlanService.checkSsnWithinPlan(
                      planId,
                      ssnToValidate
                    );
                  } else {
                    await new Promise(resolve => {
                      setTimeout(resolve, 50);
                    });
                  }
                  return checkResult?.data ?? false;
                }
              )
          })
      })
    )
    .min(1);
