import { FlattenSubAccountApiEvent } from '@/models/ops/workflows/FlattenSubAccountApiEvent.model';
import { InitiateDepositRequest } from '@/models/ops/workflows/InitiateDepositRequest.model';
import { ManualDequeueMethod } from '@/models/ops/workflows/ManualDequeueMethod.model';
import { WorkflowDto } from '@/models/ops/workflows/WorkflowDTO.model';
import { WorkflowSearchRequest } from '@/models/ops/workflows/WorkflowSearchRequest.model';
import { PaginatedApiResponse } from '@/models/PaginatedApiResponse.model';
import ApiService from '@/services/Api.service';

class WorkflowService {
  static async getById(id: number | string) {
    return ApiService.getJson<WorkflowDto>(`workflows/${id}`);
  }

  static async search(
    params: WorkflowSearchRequest
  ): Promise<PaginatedApiResponse<WorkflowDto[]>> {
    // have to stringify this because it is a nested object and to complex for the request library to handle in the url
    let workflowNameTradeCalculatorMethodPairings;
    if (params.workflowNameTradeCalculatorMethodPairings) {
      workflowNameTradeCalculatorMethodPairings = JSON.stringify(
        params.workflowNameTradeCalculatorMethodPairings
      );
    }
    const result = await ApiService.getJson<
      PaginatedApiResponse<WorkflowDto[]>
    >(`workflows/`, { ...params, workflowNameTradeCalculatorMethodPairings });
    return result;
  }

  static async reprocess(workflowId: number): Promise<void> {
    const result = await ApiService.patchJson<void, void>(
      `workflows/reprocess/${workflowId}`,
      undefined
    );
    return result;
  }

  static async reverse(
    tracerId: string,
    operationalSubAccountType: string
  ): Promise<void> {
    const result = await ApiService.patchJson<
      { tracerId: string; operationalSubAccountType: string },
      void
    >(`workflows/reverse`, {
      operationalSubAccountType,
      tracerId
    });
    return result;
  }

  static async defer(tracerId: string): Promise<void> {
    const result = await ApiService.patchJson<
      { tracerId: string; manualDequeueMethod: ManualDequeueMethod },
      void
    >(`workflows/manual-holding-tank-dequeuer`, {
      manualDequeueMethod: ManualDequeueMethod.defer,
      tracerId
    });
    return result;
  }

  static async forceProcessing(tracerId: string): Promise<void> {
    const result = await ApiService.patchJson<
      { tracerId: string; manualDequeueMethod: ManualDequeueMethod },
      void
    >(`workflows/manual-holding-tank-dequeuer`, {
      manualDequeueMethod: ManualDequeueMethod.forceStart,
      tracerId
    });
    return result;
  }

  static async initiateDeposit(
    payload: InitiateDepositRequest
  ): Promise<{ tracerId: string; status: string; startDate: string }> {
    return await ApiService.postJson<
      InitiateDepositRequest,
      { tracerId: string; status: string; startDate: string }
    >(`workflows/initiate-deposit`, payload);
  }

  static async flattenOperationalSubAccount(
    subAccountId: string
  ): Promise<void> {
    return await ApiService.postJson<FlattenSubAccountApiEvent, void>(
      `workflows/flatten-operational-sub-account`,
      { subAccountId }
    );
  }
}

export default WorkflowService;
