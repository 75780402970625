import { Button } from '@mui/material';

import { useRef } from 'react';

type UploadButtonProps = {
  'data-testid': string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick: () => void;
};

export const UploadButton = (props: UploadButtonProps): JSX.Element => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <Button
        component='label'
        data-testid={props['data-testid']}
        onClick={() => {
          props.onClick();
          inputFileRef.current?.click();
        }}
        size='large'>
        Upload
      </Button>
      <input
        accept='.pdf'
        data-testid={props['data-testid']}
        hidden
        onChange={props.onChange}
        ref={inputFileRef}
        type='file'
      />
    </>
  );
};
