import { useDialog } from '@/contexts/DialogContext';
import { SponsorTeammateData } from '@/models/SponsorTeammates.model';
import createSponsorTeamValidationSchema from '@/utils/validations/EmailValidationSchema.schema';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import React from 'react';

interface AddAdminUsersButtonProps {
  sponsorTeammates: SponsorTeammateData[];
  resendInvite: (email: string, isNewInvite: boolean) => Promise<void>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addInviteBtn: {
      marginLeft: 'auto',
      marginRight: theme.spacing(2),
      padding: '0px'
    }
  })
);

const AddAdminUsersButton = (props: AddAdminUsersButtonProps): JSX.Element => {
  const classes = useStyles();
  const { openDialog } = useDialog();
  const { resendInvite, sponsorTeammates } = props;

  return (
    <Button
      className={classes.addInviteBtn}
      data-testid='add-admin-users-button'
      onClick={() => {
        const validationSchema =
          createSponsorTeamValidationSchema(sponsorTeammates);
        openDialog({
          actionButtons: {
            cancelButton: {
              children: 'Cancel'
            },
            submitButton: {
              children: 'Send Invite'
            }
          },
          onSubmit: async values => {
            await resendInvite(values.email, true);
          },
          steps: [
            {
              fields: {
                email: {
                  initialValue: '',
                  label: 'Email Address'
                }
              },
              headerText: 'The user will receive an invite link to register.',
              title: 'Invite User'
            }
          ],
          validationSchema
        });
      }}>
      Invite User
    </Button>
  );
};

export default AddAdminUsersButton;
