import JSONViewer from '@/components/json-viewer';
import {
  SurpasLookupFormValues,
  SurpasMethods,
  SurpasOtherMethodsConfig
} from '@/models/ops/surpas/Surpas.model';
import { SurpasService } from '@/services/ops/surpas/Surpas.service';
import { LoadingButton } from '@mui/lab';
import {
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Unstable_Grid2 as Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { Form, Formik, FormikProps } from 'formik';
import { FC, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  acctNo: yup.string().test({
    test: function (value) {
      if (
        this.parent.category === 'other' &&
        SurpasOtherMethodsConfig[this.parent.method].includes('acctNo')
      ) {
        if (!value) {
          return this.createError({
            message: 'acctNo is required',
            path: this.path
          });
        }
      }
      return true;
    }
  }),
  brnId: yup.string().test({
    test: function (value) {
      if (
        this.parent.category === 'other' &&
        SurpasOtherMethodsConfig[this.parent.method].includes('brnId')
      ) {
        if (!value) {
          return this.createError({
            message: 'brnId is required',
            path: this.path
          });
        }
      }
      return true;
    }
  }),
  custId: yup.string().test({
    test: function (value) {
      if (
        this.parent.category === 'other' &&
        SurpasOtherMethodsConfig[this.parent.method].includes('custId')
      ) {
        if (!value) {
          return this.createError({
            message: 'custId is required',
            path: this.path
          });
        }
      }
      return true;
    }
  }),
  custType: yup.string().test({
    test: function (value) {
      if (
        this.parent.category === 'other' &&
        SurpasOtherMethodsConfig[this.parent.method].includes('custType')
      ) {
        if (!value) {
          return this.createError({
            message: 'custType is required',
            path: this.path
          });
        }
      }
      return true;
    }
  }),
  dlrId: yup.string().test({
    test: function (value) {
      if (
        this.parent.category === 'other' &&
        SurpasOtherMethodsConfig[this.parent.method].includes('dlrId')
      ) {
        if (!value) {
          return this.createError({
            message: 'dlrId is required',
            path: this.path
          });
        }
      }
      return true;
    }
  }),
  employerAccount: yup.string().test({
    test: function (value) {
      if (
        this.parent.category === 'other' &&
        SurpasOtherMethodsConfig[this.parent.method].includes('employerAccount')
      ) {
        if (!value) {
          return this.createError({
            message: 'employerAccount is required',
            path: this.path
          });
        }
      }
      return true;
    }
  }),
  externalFirmId: yup.string().test({
    test: function (value) {
      if (
        this.parent.category === 'other' &&
        SurpasOtherMethodsConfig[this.parent.method].includes('externalFirmId')
      ) {
        if (!value) {
          return this.createError({
            message: 'externalFirmId is required',
            path: this.path
          });
        }
      }
      return true;
    }
  }),
  participantId: yup.string().when('category', {
    is: 'employee',
    then: yup.string().min(1).required()
  }),
  programCode: yup.string().when('category', {
    is: 'employee',
    then: yup.string().min(1).required()
  }),
  repId: yup.string().test({
    test: function (value) {
      if (
        this.parent.category === 'employer' ||
        (this.parent.category === 'other' &&
          SurpasOtherMethodsConfig[this.parent.method].includes('repId'))
      ) {
        if (!value) {
          return this.createError({
            message: 'repId is required',
            path: this.path
          });
        }
      }
      return true;
    }
  })
});

export const SurpasRoute: FC = () => {
  const [formValues, setFormValues] = useState<SurpasLookupFormValues>({
    acctNo: '',
    brnId: '',
    category: 'employer',
    custId: '',
    custType: '',
    dlrId: '',
    employerAccount: '',
    externalFirmId: '',
    getECommUser: true,
    method: SurpasMethods.GetAcct,
    participantId: '',
    programCode: '',
    repId: ''
  });

  const lookupEmployerDataQuery = useQuery(
    ['SurpasService.getEmployerData'],
    () => {
      return SurpasService.getEmployerData(formValues.repId);
    },
    { enabled: false }
  );

  const lookupEmployeeDataQuery = useQuery(
    ['SurpasService.getEmployeeData'],
    () => {
      return SurpasService.getEmployeeData(
        formValues.participantId,
        formValues.programCode
      );
    },
    { enabled: false }
  );

  const lookupOtherDataQuery = useQuery(
    ['SurpasService.getOtherData'],
    () => {
      return SurpasService.getOtherData({
        acctNo: formValues.acctNo !== '' ? formValues.acctNo : null,
        brnId: formValues.brnId !== '' ? formValues.brnId : null,
        custId: formValues.custId !== '' ? formValues.custId : null,
        custType: formValues.custType !== '' ? formValues.custType : null,
        dlrId: formValues.dlrId !== '' ? formValues.dlrId : null,
        employerAccount:
          formValues.employerAccount !== '' ? formValues.employerAccount : null,
        externalFirmId:
          formValues.externalFirmId !== '' ? formValues.externalFirmId : null,
        method: formValues.method,
        repId: formValues.repId !== '' ? formValues.repId : null
      });
    },
    { enabled: false }
  );

  useUpdateEffect(() => {
    if (formValues.category === 'employer') {
      lookupEmployerDataQuery.refetch();
    } else if (formValues.category === 'employee') {
      lookupEmployeeDataQuery.refetch();
    } else {
      lookupOtherDataQuery.refetch();
    }
  }, [formValues]);

  const onSubmit = (values: SurpasLookupFormValues) => {
    setFormValues(values);
  };

  const clearTextFields = (formik: FormikProps<SurpasLookupFormValues>) => {
    formik.setValues({
      ...formik.values,
      acctNo: '',
      brnId: '',
      custId: '',
      custType: '',
      dlrId: '',
      employerAccount: '',
      externalFirmId: '',
      repId: ''
    });
  };

  return (
    <Stack spacing={4}>
      <Typography variant='h4'>Surpas Data Lookup</Typography>
      <Card variant='outlined'>
        <CardContent>
          <Grid container>
            <Grid xs={6}>
              <Formik
                initialValues={formValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}>
                {formik => (
                  <Form>
                    <Stack spacing={2}>
                      <FormControl>
                        <RadioGroup
                          name='category'
                          row
                          value={formik.values.category}>
                          <FormControlLabel
                            control={<Radio />}
                            label='Employer'
                            onChange={e => {
                              clearTextFields(formik);
                              formik.handleChange(e);
                            }}
                            value='employer'
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label='Employee'
                            onChange={e => {
                              clearTextFields(formik);
                              formik.handleChange(e);
                            }}
                            value='employee'
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label='Other'
                            onChange={e => {
                              clearTextFields(formik);
                              formik.handleChange(e);
                            }}
                            value='other'
                          />
                        </RadioGroup>
                      </FormControl>
                      {['employer', 'employee'].includes(
                        formik.values.category
                      ) && (
                        <FormControlLabel
                          control={
                            <Radio
                              checked={formik.values.getECommUser}
                              name='getECommUser'
                              onChange={formik.handleChange}
                              value={formik.values.getECommUser}
                            />
                          }
                          label='GetECommUser'
                          value='getECommUser'
                        />
                      )}
                      {formik.values.category === 'other' && (
                        <RadioGroup name='method' value={formik.values.method}>
                          {Object.values(SurpasMethods).map(method => (
                            <FormControlLabel
                              control={<Radio />}
                              key={method}
                              label={method}
                              onChange={e => {
                                clearTextFields(formik);
                                formik.handleChange(e);
                              }}
                              value={method}
                            />
                          ))}
                        </RadioGroup>
                      )}
                      {SurpasOtherMethodsConfig[formik.values.method].includes(
                        'acctNo'
                      ) &&
                        formik.values.category === 'other' && (
                          <>
                            <Typography variant='body1'>acctNo</Typography>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              name='acctNo'
                              onChange={formik.handleChange}
                              size='small'
                              value={formik.values.acctNo}
                            />
                          </>
                        )}
                      {SurpasOtherMethodsConfig[formik.values.method].includes(
                        'custId'
                      ) &&
                        formik.values.category === 'other' && (
                          <>
                            <Typography variant='body1'>ID</Typography>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              name='custId'
                              onChange={formik.handleChange}
                              size='small'
                              value={formik.values.custId}
                            />
                          </>
                        )}
                      {SurpasOtherMethodsConfig[formik.values.method].includes(
                        'custType'
                      ) &&
                        formik.values.category === 'other' && (
                          <>
                            <Typography variant='body1'>type</Typography>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              name='custType'
                              onChange={formik.handleChange}
                              size='small'
                              value={formik.values.custType}
                            />
                          </>
                        )}
                      {SurpasOtherMethodsConfig[formik.values.method].includes(
                        'employerAccount'
                      ) &&
                        formik.values.category === 'other' && (
                          <>
                            <Typography variant='body1'>
                              employerAccount
                            </Typography>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              name='employerAccount'
                              onChange={formik.handleChange}
                              size='small'
                              value={formik.values.employerAccount}
                            />
                          </>
                        )}
                      {SurpasOtherMethodsConfig[formik.values.method].includes(
                        'externalFirmId'
                      ) &&
                        formik.values.category === 'other' && (
                          <>
                            <Typography variant='body1'>
                              externalFirmID
                            </Typography>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              name='externalFirmId'
                              onChange={formik.handleChange}
                              size='small'
                              value={formik.values.externalFirmId}
                            />
                          </>
                        )}
                      {formik.values.category === 'employee' && (
                        <>
                          <Typography variant='body1'>programCode</Typography>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            name='programCode'
                            onChange={formik.handleChange}
                            size='small'
                            value={formik.values.programCode}
                          />

                          <Typography variant='body1'>participantID</Typography>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            name='participantId'
                            onChange={formik.handleChange}
                            size='small'
                            value={formik.values.participantId}
                          />
                        </>
                      )}
                      {(formik.values.category === 'employer' ||
                        (SurpasOtherMethodsConfig[
                          formik.values.method
                        ].includes('repId') &&
                          formik.values.category === 'other')) && (
                        <>
                          <Typography variant='body1'>repID</Typography>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            name='repId'
                            onChange={formik.handleChange}
                            size='small'
                            value={formik.values.repId}
                          />
                        </>
                      )}
                      {SurpasOtherMethodsConfig[formik.values.method].includes(
                        'brnId'
                      ) &&
                        formik.values.category === 'other' && (
                          <>
                            <Typography variant='body1'>brnID</Typography>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              name='brnId'
                              onChange={formik.handleChange}
                              size='small'
                              value={formik.values.brnId}
                            />
                          </>
                        )}
                      {SurpasOtherMethodsConfig[formik.values.method].includes(
                        'dlrId'
                      ) &&
                        formik.values.category === 'other' && (
                          <>
                            <Typography variant='body1'>dlrID</Typography>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              name='dlrId'
                              onChange={formik.handleChange}
                              size='small'
                              value={formik.values.dlrId}
                            />
                          </>
                        )}
                      <LoadingButton
                        disabled={!formik.isValid}
                        loading={
                          lookupEmployerDataQuery.isFetching ||
                          lookupEmployeeDataQuery.isFetching ||
                          lookupOtherDataQuery.isFetching
                        }
                        type='submit'
                        variant='contained'>
                        Execute
                      </LoadingButton>
                    </Stack>
                  </Form>
                )}
              </Formik>
            </Grid>
            <Grid xs={6}>
              <JSONViewer
                json={
                  formValues.category === 'employee'
                    ? lookupEmployeeDataQuery.data
                    : formValues.category === 'employer'
                      ? lookupEmployerDataQuery.data
                      : lookupOtherDataQuery.data
                }
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Stack>
  );
};

SurpasRoute.displayName = 'SurpasRoute';
