import CircularLoading from '@/components/circular-loading';
import SimpleUpload from '@/components/simple-upload';
import { PlanDesign } from '@/models/PlanDesign.model';
import { CheckCircleOutlined, WarningAmberOutlined } from '@mui/icons-material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import {
  Alert,
  Box,
  Button,
  Grid,
  Stack,
  Theme,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { useEffect } from 'react';
import { useToggle } from 'react-use';

const useStyles = makeStyles((theme: Theme) => ({
  successItem: {
    color: theme.palette.text.primary
  },
  unsupportedValueItem: {
    color: theme.palette.text.primary,
    paddingLeft: '0.5rem'
  },
  uploadAccept: {
    color: theme.palette.text.secondary,
    marginLeft: '0.5rem'
  },
  uploadBlock: {
    border: '1px solid',
    borderColor: theme.palette.grey['300'],
    borderRadius: '4px'
  },
  warningHeader: {
    marginTop: '1rem',
    padding: '0.5rem 1rem'
  },
  warningIcon: {
    color: theme.palette.warning.dark
  },
  warningItem: {
    borderTop: '1px solid',
    borderTopColor: theme.palette.grey['300'],
    padding: '1rem'
  }
}));

interface UploadPlanDesignFileProps {
  onSelect: (files: any) => void;
  uploadingData: PlanDesign;
  file: File | null;
  isUploading: boolean;
  unsupportedValuesLength: number;
  uploadingError: string;
  unsupportedValues: Record<string, Record<string, any>>;
}

export const UploadPlanDesignFile = ({
  onSelect,
  uploadingData,
  file,
  isUploading,
  unsupportedValuesLength,
  uploadingError,
  unsupportedValues
}: UploadPlanDesignFileProps): React.ReactElement => {
  const classes = useStyles();
  const [isFormulaSelected, toggleFormulaSelected] = useToggle(false);

  useEffect(() => {
    if (
      uploadingData.employerContribution?.nonSafeHarborMatchType?.toLowerCase() ===
      'formula'
    ) {
      toggleFormulaSelected(true);
    }
  }, [uploadingData]);

  return (
    <Box mb={2}>
      <Typography>
        If you have a plan design file from ftwilliam.com, you can upload it
        here. Otherwise, complete setup below.
      </Typography>
      <Box className={classes.uploadBlock} mt={2} p={2}>
        <Box alignItems='center' display='flex' justifyContent='space-between'>
          <Box alignItems='center' display='flex'>
            <SimpleUpload
              accept={{
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                  ['.xlsx'],
                'text/csv': ['.csv']
              }}
              multiple={false}
              onSelect={onSelect}
              selectRawFiles>
              <Button
                component='label'
                startIcon={<FileUploadOutlinedIcon />}
                variant='text'>
                Upload
              </Button>
            </SimpleUpload>
            <Typography className={classes.uploadAccept} variant='body2'>
              {file?.name || 'Accept .xlsx, .csv'}
            </Typography>
          </Box>
          {isUploading && <CircularLoading />}
        </Box>

        <Stack mt={2} spacing={2}>
          {!!Object.keys(uploadingData).length && !unsupportedValuesLength && (
            <Alert
              icon={<CheckCircleOutlined color='success' />}
              severity='success'>
              <Typography className={classes.successItem} variant='body2'>
                Successfully applied plan design to the sections below. Please
                review & save.
              </Typography>
            </Alert>
          )}

          {!!unsupportedValuesLength && (
            <Box>
              <Alert
                icon={<WarningAmberOutlined className={classes.warningIcon} />}
                severity='warning'>
                {unsupportedValuesLength}{' '}
                {unsupportedValuesLength > 1 ? 'fields' : 'field'}{' '}
                {unsupportedValuesLength > 1 ? 'have' : 'has'} unsupported{' '}
                {unsupportedValuesLength > 1 ? 'values' : 'value'}. Please
                review below.
              </Alert>

              <Grid className={classes.warningHeader} container>
                <Grid item sm={4}>
                  <Typography fontWeight={500} variant='body2'>
                    Section
                  </Typography>
                </Grid>
                <Grid item sm={4}>
                  <Typography fontWeight={500} variant='body2'>
                    Field Name
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography fontWeight={500} variant='body2'>
                    Unsupported Value
                  </Typography>
                </Grid>
              </Grid>

              {Object.entries(unsupportedValues).map(
                ([fieldKey, fieldObject]) => (
                  <Grid
                    className={classes.warningItem}
                    container
                    key={fieldKey}>
                    <Grid item sm={4}>
                      <Typography>{fieldObject.sectionName}</Typography>
                    </Grid>
                    <Grid item sm={4}>
                      <Typography>{fieldObject.fieldName}</Typography>
                    </Grid>
                    <Grid item sm={4}>
                      <Typography>{fieldObject.value}</Typography>
                    </Grid>
                  </Grid>
                )
              )}
            </Box>
          )}

          {uploadingError && <Alert severity='error'>{uploadingError}</Alert>}

          {isFormulaSelected && (
            <Alert
              icon={<WarningAmberOutlined className={classes.warningIcon} />}
              severity='warning'>
              Additional information required about your Non-Safe Harbor Match.
            </Alert>
          )}
        </Stack>
      </Box>
    </Box>
  );
};
