import { Box, CardContent, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import clsx from 'clsx';
import React from 'react';

import CopyToClipboard from '../copy-to-clipboard';

interface CardFieldProps {
  fieldName: string;
  fieldValue?: string | string[];
  fieldValueCopyToClipboard?: boolean;
  fieldSubscript?: string;
  monospace?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  cardContent: {
    '& > *': {
      marginBottom: theme.spacing(1),
      paddingBottom: 0
    },
    paddingBottom: 0,
    paddingLeft: 0
  },
  fieldSubscript: {
    color: theme.palette.text.secondary
  }
}));

const CardInfoField: React.FunctionComponent<CardFieldProps> = (
  props: CardFieldProps
) => {
  const classes = useStyles();
  const {
    fieldName,
    fieldValue,
    fieldSubscript = '',
    fieldValueCopyToClipboard = false,
    monospace = false
  } = props;

  const fieldValues = Array.isArray(fieldValue) ? fieldValue : [fieldValue];

  return (
    <CardContent
      className={clsx(classes.cardContent, 'CardInfoField_cardContent')}>
      <Typography
        className='CardInfoField_fieldName'
        data-testid={`${fieldName
          .toLocaleLowerCase()
          .replaceAll(' ', '-')}-FieldName`}
        variant='body2'>
        {fieldName}
      </Typography>
      {fieldValues.map((line, index) => (
        <Box
          alignItems='center'
          display='flex'
          key={`CardInfoField_fieldKey-${line}-${index}`}
          // margin top -3px to compensate CopyToClipboard component height
          marginTop={fieldValueCopyToClipboard ? '-3px' : '0'}>
          <Typography
            className='CardInfoField_fieldValue'
            data-testid={`${fieldName
              .toLocaleLowerCase()
              .replaceAll(' ', '-')}-FieldValue`}
            fontFamily={monospace ? 'Roboto Mono' : 'Roboto'}
            fontSize={monospace ? '0.99rem' : '1rem'}
            style={{ wordBreak: 'break-word', width: '100%' }}
            variant='body1'
            // Roboto Mono font proportions are a little bit weird
            sx={{ marginTop: monospace ? '-2px' : null }}>
            {line}
          </Typography>
          {fieldValueCopyToClipboard && (
            <CopyToClipboard
              copyName={`${fieldName}${
                Array.isArray(fieldValue) ? ` ${index + 1}` : ''
              }`}
              copyValue={line}
            />
          )}
        </Box>
      ))}
      <Typography
        className={clsx(classes.fieldSubscript, 'CardInfoField_fieldSubscript')}
        variant='caption'>
        {fieldSubscript}
      </Typography>
    </CardContent>
  );
};

export default CardInfoField;
