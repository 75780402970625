import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import VisibilityToggle from '@/components/visibility-toggle';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import ParticipantInfo from '@/models/ParticipantInfo.model';
import { PlanV2 } from '@/models/PlanV2DTO.model';
import formatters from '@/utils/Formatters';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Link,
  Stack,
  SxProps,
  Theme,
  Typography
} from '@mui/material';
import { formatSsn } from '@vestwell-frontend/helpers';

import React, { useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export interface ParticipantInfoCardProps {
  participant: ParticipantInfo;
  participantId: string;
  sponsorPlan: PlanV2;
  balance: {
    total?: number;
    vestedTotal?: number;
  };
  sx?: SxProps<Theme>;
}

const ParticipantInfoCard: React.FunctionComponent<ParticipantInfoCardProps> = (
  props: ParticipantInfoCardProps
) => {
  const [isMaskActive, setIsMaskActive] = useState(true);
  const { participant, sponsorPlan, balance, sx, participantId } = props;

  const ssn = useMemo(() => {
    if (!participant.ssn) {
      return EMPTY_FIELD_PLACEHOLDER;
    }

    const formattedSSN = formatSsn(participant.ssn);

    return isMaskActive ? formatters.maskSSN(formattedSSN) : formattedSSN;
  }, [isMaskActive, participant]);

  return (
    <Card elevation={0} sx={sx} variant='outlined'>
      <CardHeader
        sx={{ borderBottom: theme => `1px solid ${theme.palette.grey[300]}` }}
        title='Information'
      />
      <CardContent>
        <Stack mb={2}>
          <Typography variant='h6'>
            {`${participant.firstName} ${participant.lastName}`}
          </Typography>
          <Link
            component={RouterLink}
            to={`/participants/${participantId}/personal`}>
            Participant ID: {participantId}
          </Link>
        </Stack>
        <TextStack direction='column' sx={{ m: 0, mb: 2, p: 0 }}>
          <TextStackItem>
            <TextLabel>SSN</TextLabel>
            <TextValue data-testid='social-security-number'>
              {ssn}
              <VisibilityToggle
                isMasked={isMaskActive}
                sx={{ ml: 1, py: 0 }}
                toggleMask={() => setIsMaskActive(!isMaskActive)}
              />
            </TextValue>
          </TextStackItem>
          <TextStackItem>
            <TextLabel>Date of Birth</TextLabel>
            <TextValue data-testid='date-of-birth'>
              {participant.birthDate ?? null}
            </TextValue>
          </TextStackItem>
          <TextStackItem>
            <TextLabel>Eligibility Status</TextLabel>
            <TextValue data-testid='eligibility-status'>
              {participant.eligibilityStatus ?? null}
            </TextValue>
          </TextStackItem>
          {!!participant.terminationDate && (
            <TextStackItem>
              <TextLabel>Termination Date</TextLabel>
              <TextValue>{participant.terminationDate}</TextValue>
            </TextStackItem>
          )}
          <TextStackItem>
            <TextLabel>Total Balance</TextLabel>
            <TextValue data-testid='total-balance'>
              {balance.total ? formatters.formatDollars(balance.total) : null}
            </TextValue>
          </TextStackItem>
          <TextStackItem>
            <TextLabel>Address</TextLabel>
            <TextValue>
              {`${participant.address?.address1}`}
              <br />
              {!!participant.address?.address2 && (
                <>
                  {`${participant.address?.address2 ?? ''}`}
                  <br />
                </>
              )}
              {`${participant.address?.city} ${participant.address?.state} ${participant.address?.zip}`}
            </TextValue>
          </TextStackItem>
          <TextStackItem>
            <TextLabel>Phone #</TextLabel>
            <TextValue>{participant.phoneNumber ?? null}</TextValue>
          </TextStackItem>
          <TextStackItem>
            <TextLabel>User Email</TextLabel>
            <TextValue>{participant.userEmail ?? null}</TextValue>
          </TextStackItem>
          <TextStackItem>
            <TextLabel>Personal Email</TextLabel>
            <TextValue>{participant.personalEmail ?? null}</TextValue>
          </TextStackItem>
        </TextStack>
        <Divider />
        <Stack my={2}>
          <Typography variant='h6'>{sponsorPlan.attributes.name}</Typography>
          <Link component={RouterLink} to={`/plans/${sponsorPlan.id}`}>
            Plan ID: {sponsorPlan.id?.toString()}
          </Link>
        </Stack>
        <TextStack direction='column'>
          <TextStackItem>
            <TextLabel>Sponsor ID</TextLabel>
            <TextValue>
              {sponsorPlan?.relationships?.sponsor?.data?.id?.toString() ??
                null}
            </TextValue>
          </TextStackItem>
          <TextStackItem>
            <TextLabel>Recordkeeper</TextLabel>
            <TextValue>
              {sponsorPlan?.attributes?.recordkeeper ?? null}
            </TextValue>
          </TextStackItem>
        </TextStack>
      </CardContent>
    </Card>
  );
};

export default ParticipantInfoCard;
