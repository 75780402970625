import ApiService from '@/services/Api.service';

import type { WhitelabelEntityResponse } from 'scala-sdk';

export class WhitelabelService {
  static async getEntities(): Promise<WhitelabelEntityResponse[]> {
    const entities = await ApiService.getJson<WhitelabelEntityResponse[]>(
      '/whitelabel/entities'
    );

    if (!entities) {
      throw new Error(
        'invalid JSON received from backend for whitelabels getEntities list'
      );
    }

    return entities;
  }
}
