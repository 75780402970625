import { WorkflowName } from '@vestwell-sub-accounting/models/conductor/WorkflowName.model';

import { useContext } from 'react';

import { WorkflowContext } from '../common/WorkflowContext';
import { DefaultInputFields } from './input-fields/DefaultInputFields.component';
import { DepositRequestInputFields } from './input-fields/DepositRequestInputFields.component';
import { DistributionInputFields } from './input-fields/DistributionRequestInputFields.component';
import { InvestContributionInputFields } from './input-fields/InvestContributionInputFields.component';
import { NewPlanInputFields } from './input-fields/NewPlanInputFields.component';
import { TradeRequestRebalanceInputFields } from './input-fields/TradeRequestRebalanceInputFields.component';

export const WorkflowInputDetails = (): JSX.Element | null => {
  const workflow = useContext(WorkflowContext);

  if (!workflow) return null;

  switch (workflow.workflowName) {
    case WorkflowName.depositRequest:
      return <DepositRequestInputFields />;
    case WorkflowName.distributionRequest:
      return <DistributionInputFields />;
    case WorkflowName.investContribution:
      return <InvestContributionInputFields />;
    case WorkflowName.newPlan:
      return <NewPlanInputFields />;
    case WorkflowName.tradeRequest:
      return <TradeRequestRebalanceInputFields />;
    default:
      return <DefaultInputFields />;
  }
};
