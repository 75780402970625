import { useQueueError } from '@/hooks/useQueueError.hook';
import { QueueErrorDTO } from '@/models';
import QueueErrorService from '@/services/QueueError.service';
import formatters from '@/utils/Formatters';
import {
  Box,
  Button,
  CardHeader,
  Divider,
  FormControlLabel,
  Stack,
  Switch,
  SxProps
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { ColDef } from 'ag-grid-community';
import { FC, useMemo } from 'react';
import { useToggle } from 'react-use';

import Card, { CardContent, CardPlaceholder } from '../card';
import DataTable from '../data-table/DataTable.component';

type QueueErrorCardProps = {
  type: 'Contribution' | 'Withdrawal' | 'Loan' | 'Deconversion' | '';
  value: string;
  title?: (string | JSX.Element) & React.ReactNode;
  sx?: SxProps;
};

export const QueueErrorCard: FC<QueueErrorCardProps> = (
  props: QueueErrorCardProps
) => {
  const [includeArchived, toggleIncludeArchived] = useToggle(false);
  const queryClient = useQueryClient();
  const queueError = useQueueError();

  const subaQueueErrorsQuery = useQuery(
    [
      QueueErrorService.getSubaQueueErrors.name,
      props.type,
      props.value,
      includeArchived
    ],
    async () =>
      QueueErrorService.getSubaQueueErrors(
        props.type,
        props.value,
        includeArchived
      ),
    {
      staleTime: Infinity
    }
  );

  const refreshData = () => {
    queryClient.invalidateQueries([QueueErrorService.getSubaQueueErrors.name], {
      refetchType: 'none'
    });
    subaQueueErrorsQuery.refetch();
  };

  const columns: ColDef[] = useMemo(
    () => [
      {
        cellRenderer: 'agGroupCellRenderer',
        field: 'createdAt',
        headerName: '',
        maxWidth: 100,
        valueFormatter: () => {
          return '';
        }
      },
      {
        field: 'errorId',
        headerName: 'Error ID',
        lockVisible: true,
        sortable: true
      },
      {
        field: 'createdAt',
        headerName: 'Date',
        valueFormatter: ({ value }: { value: string }) => {
          return formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY HH:mm');
        }
      },
      {
        field: 'initialMessage.body',
        headerName: 'Initial Message Body',
        width: 500
      },
      {
        field: 'note',
        flex: 1,
        headerName: 'Note Text'
      },
      {
        cellRenderer: (cellData: { data: QueueErrorDTO }) => {
          return (
            <>
              {cellData.data.isArchived ? (
                <Box textAlign='right'>
                  <Button
                    onClick={() => {
                      queueError.unarchiveErrorMutation.mutate(
                        cellData.data.errorId,
                        { onSuccess: () => refreshData() }
                      );
                    }}>
                    UNARCHIVE
                  </Button>
                </Box>
              ) : (
                <Box textAlign='right'>
                  <Button
                    onClick={() =>
                      queueError.archiveErrorMutation.mutate(
                        cellData.data.errorId,
                        { onSuccess: () => refreshData() }
                      )
                    }>
                    ARCHIVE
                  </Button>
                  <Button
                    onClick={() =>
                      queueError.replayErrorMutation.mutate(
                        cellData.data.errorId,
                        { onSuccess: () => refreshData() }
                      )
                    }>
                    REPLAY
                  </Button>
                </Box>
              )}
            </>
          );
        },
        field: '',
        headerName: 'Actions'
      }
    ],
    []
  );

  const context = {
    onNoteUpdate: queueError.onNoteUpdate,
    refreshData
  };

  return (
    <Card sx={props.sx}>
      <CardHeader
        action={
          <Stack
            direction='row'
            divider={<Divider flexItem orientation='vertical' />}
            spacing={1}>
            <FormControlLabel
              control={
                <Switch
                  checked={includeArchived}
                  onChange={toggleIncludeArchived}
                />
              }
              label='Show archived errors'
            />
            <Stack direction='row' spacing={2}>
              <Button onClick={queueError.onExpandCollapseClick}>
                {queueError.expandAll ? 'COLLAPSE' : 'EXPAND'} ALL
              </Button>
            </Stack>
          </Stack>
        }
        title={props.title ?? 'Queue Error'}
      />
      <Divider />
      <CardContent loading={subaQueueErrorsQuery.isFetching} sx={{ p: 0 }}>
        <DataTable
          columnDefs={columns}
          columnSizing='flex'
          context={context}
          detailCellRenderer={queueError.detailCell}
          emptyPlaceholderComponent={
            <Stack
              alignItems='center'
              data-testid='no-data-queue-errors-table'
              justifyContent='center'
              sx={{ height: '100%' }}>
              <CardPlaceholder subtitle='No errors found' />
            </Stack>
          }
          gridRef={queueError.gridRef}
          onRowDataUpdated={queueError.onRowDataUpdated}
          rowData={subaQueueErrorsQuery.data?.data || []}
          suppressRowClickSelection={true}
        />
      </CardContent>
    </Card>
  );
};

QueueErrorCard.displayName = 'QueueErrorCard';
