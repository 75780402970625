import { isEmpty, pick, pickBy } from 'lodash';
import { useEffect, useMemo } from 'react';

type Heap = {
  track: (event: string, properties?: Record<string, unknown>) => void;
  identify: (identity: string) => void;
  resetIdentity: () => void;
  addUserProperties: (properties: Record<string, unknown>) => void;
  addEventProperties: (properties: Record<string, unknown>) => void;
  removeEventProperty: (property: string) => void;
  clearEventProperties: () => void;
  appid: string;
  userId: string;
  identity: string | null;
  config: unknown;
};

type Profile = {
  name?: string;
  nickname?: string;
  email?: string;
};

declare const window: Window & { heap?: Heap };

export function useHeap(data?: Profile): void {
  const profile = useMemo(
    () =>
      pickBy(
        pick(data, ['name', 'nickname', 'email']),
        value => !isEmpty(value)
      ),
    [data]
  );

  useEffect(() => {
    if (
      !window.heap ||
      !window.heap?.identify ||
      !window.heap?.addUserProperties ||
      isEmpty(profile)
    )
      return;

    window.heap?.identify(profile.nickname);

    window.heap?.addUserProperties({ ...profile, type: 'service-portal' });
  }, [profile]);
}
