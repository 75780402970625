import {
  IdentityVerificationService,
  IdentityVerificationStatus
} from '@/services/ops/kyc/IdentityVerificationService';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PendingIcon from '@mui/icons-material/Pending';
import { CircularProgress, Link } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import React, { FC, useMemo } from 'react';

interface CipStatusProps {
  participantId: number;
}

const CIP_PENDING = 'Pending';
const CIP_VERIFIED = 'Verified';
const CIP_FAILED = 'Failed';
const CIP_UNMET = 'Unmet';

const CIP_TEXT_BY_STATUS: Record<string, string> = {
  'not verified': CIP_FAILED,
  pending: CIP_PENDING,
  verified: CIP_VERIFIED
};

const IDV_TEXT_BY_MODE: Record<string, string> = {
  document_upload: 'via Document Upload',
  lightning: 'via Lightning',
  manual: 'manually via Plaid dashboard',
  pending_review: 'review'
};

export const CipStatus: FC<CipStatusProps> = props => {
  const verificationStatus = useQuery<IdentityVerificationStatus>(
    [
      'IdentityVerificationService.getOrUpdateVerification',
      props.participantId
    ],
    async () => {
      return await IdentityVerificationService.getOrUpdateVerification(
        props.participantId
      );
    },
    {
      enabled: Boolean(props.participantId)
    }
  );

  const cipStatus = useMemo(() => {
    return CIP_TEXT_BY_STATUS[verificationStatus.data?.cipStatus] ?? CIP_UNMET;
  }, [verificationStatus]);

  const plaidMode = useMemo(() => {
    return IDV_TEXT_BY_MODE[verificationStatus.data?.idVerMode] ?? '';
  }, [verificationStatus]);

  const plaidDashboardHref =
    verificationStatus.data?.idVerResponse?.dashboardLink;

  const iconSx = { fontSize: '1em' };

  return (
    <>
      {verificationStatus.data && !verificationStatus.isFetching && (
        <>
          {cipStatus === CIP_PENDING && (
            <PendingIcon color='warning' sx={iconSx} />
          )}
          {cipStatus === CIP_VERIFIED && (
            <CheckCircleIcon color='success' sx={iconSx} />
          )}
          {cipStatus === CIP_FAILED && <ErrorIcon color='error' sx={iconSx} />}
          {plaidDashboardHref && (
            <>
              {cipStatus === CIP_FAILED && ` ${cipStatus}`}
              <Link href={plaidDashboardHref} target='_blank'>
                {cipStatus !== CIP_FAILED
                  ? ` ${cipStatus} ${plaidMode} `
                  : ' view in Plaid'}
                <OpenInNewIcon color='primary' sx={iconSx} />
              </Link>
            </>
          )}
          {!plaidDashboardHref && <>{cipStatus}</>}
        </>
      )}
      {(!verificationStatus.data || verificationStatus.isFetching) && (
        <CircularProgress size={15} />
      )}
    </>
  );
};
