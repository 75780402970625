import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { LoanDetailsResponseDto } from '@/models';
import formatters from '@/utils/Formatters';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Unstable_Grid2 as Grid,
  Stack,
  Typography
} from '@mui/material';

import { FC } from 'react';

type LoanInfoCardProps = {
  loanDetails: LoanDetailsResponseDto;
};

const LoanInfoCard: FC<LoanInfoCardProps> = (props: LoanInfoCardProps) => {
  const { loanDetails } = props;
  return (
    <Stack>
      <Card variant='outlined'>
        <CardHeader
          sx={{
            borderBottom: theme => `1px solid ${theme.palette.grey[300]}`
          }}
          title='Details'
        />
        <CardContent sx={{ margin: 2 }}>
          <Grid container rowSpacing={2}>
            <Grid xs={12}>
              <Stack
                direction='row'
                divider={<Divider flexItem orientation='vertical' />}
                spacing={4}>
                <TextStack direction='column'>
                  <Typography sx={{ fontSize: 15, fontWeight: 500 }}>
                    Request Details
                  </Typography>
                  <TextStackItem>
                    <TextLabel>Loan Purpose</TextLabel>
                    <TextValue data-testid='loan-purpose'>
                      {loanDetails.data.attributes.loanPurpose}
                    </TextValue>
                  </TextStackItem>
                  <TextStackItem>
                    <TextLabel>Origination Date</TextLabel>
                    <TextValue data-testid='origination-date'>
                      {formatters.formatFromIsoDateCustom(
                        loanDetails.data.attributes.originationDate,
                        'MM/DD/YYYY'
                      )}
                    </TextValue>
                  </TextStackItem>
                  <TextStackItem>
                    <TextLabel>Original Loan Amount</TextLabel>
                    <TextValue data-testid='original-loan-amount'>
                      {formatters.formatDollars(
                        loanDetails.data.attributes.loanAmount
                      )}
                    </TextValue>
                  </TextStackItem>
                </TextStack>
                <TextStack direction='column'>
                  <Typography sx={{ fontSize: 15, fontWeight: 500 }}>
                    Loan
                  </Typography>
                  <TextStackItem>
                    <TextLabel>Outstanding Balance</TextLabel>
                    <TextValue data-testid='outstanding-balance'>
                      {formatters.formatDollars(
                        loanDetails.data.attributes.outstandingPrincipalDue +
                          loanDetails.data.attributes.overdueInterest
                      )}
                    </TextValue>
                  </TextStackItem>
                  <TextStackItem>
                    <TextLabel>Repayment Years</TextLabel>
                    <TextValue data-testid='repayment-years'>
                      {loanDetails.data.attributes.repaymentYears?.toString() ||
                        EMPTY_FIELD_PLACEHOLDER}
                    </TextValue>
                  </TextStackItem>
                  <TextStackItem>
                    <TextLabel>Interest Rate</TextLabel>
                    <TextValue data-testid='interest-rate'>
                      {loanDetails.data.attributes.interestRate.toString()}
                    </TextValue>
                  </TextStackItem>
                  <TextStackItem>
                    <TextLabel>Payments Remaining</TextLabel>
                    <TextValue data-testid='payments-remaining'>
                      {loanDetails.data.attributes.remainingPaymentsCount.toString()}
                    </TextValue>
                  </TextStackItem>
                </TextStack>
              </Stack>
            </Grid>
            <Grid xs={12}>
              <TextStack direction='column'>
                <Typography sx={{ fontSize: 15, fontWeight: 500 }}>
                  Fees
                </Typography>
                <TextStackItem>
                  <TextLabel>Origination Fee</TextLabel>
                  <TextValue data-testid='origination-fee'>
                    {formatters.formatDollars(
                      loanDetails.data.attributes.originationFee
                    )}
                  </TextValue>
                </TextStackItem>
                <TextStackItem>
                  <TextLabel>Maintenance Fee</TextLabel>
                  <TextValue data-testid='maintenance-fee'>
                    {formatters.formatDollars(
                      loanDetails.data.attributes.maintenanceFee
                    )}
                  </TextValue>
                </TextStackItem>
              </TextStack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Stack>
  );
};

export default LoanInfoCard;
