import { TextLabel, TextStackItem, TextValue } from '@/components/text-stack';
import VisibilityToggle from '@/components/visibility-toggle';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import formatters from '@/utils/Formatters';

import { useMemo, useState } from 'react';

type AccountNumberProps = {
  accountNumber?: string;
  isShowNumber: boolean;
  cardTitle: string;
};

const AccountNumber: React.FunctionComponent<AccountNumberProps> = (
  props: AccountNumberProps
) => {
  const { accountNumber, isShowNumber, cardTitle } = props;
  const [isMaskActive, setIsMaskActive] = useState(true);

  const bankAccount = useMemo(() => {
    const accountNum =
      isShowNumber && accountNumber ? accountNumber : EMPTY_FIELD_PLACEHOLDER;

    return isMaskActive ? formatters.maskBankNumber(accountNum) : accountNum;
  }, [isMaskActive, accountNumber, isShowNumber]);

  return (
    <TextStackItem>
      <TextLabel>{cardTitle}</TextLabel>
      <TextValue>
        {bankAccount}
        <VisibilityToggle
          isMasked={isMaskActive}
          sx={{ ml: 1, pr: 0, py: 0 }}
          toggleMask={() => setIsMaskActive(!isMaskActive)}
        />
      </TextValue>
    </TextStackItem>
  );
};

export default AccountNumber;
