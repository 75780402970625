import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';

import React, { FC, useCallback, useMemo, useState } from 'react';
import { v4 } from 'uuid';

import { GridRow } from '../types';

type UnrecognizedParticipantsProps = {
  rows?: GridRow[];
  onCancel: () => void;
};

const columns: GridColDef[] = [
  {
    align: 'center',
    field: 'uuid',
    headerAlign: 'center',
    headerName: '#',
    valueFormatter: params => +params.value + 1,
    width: 50
  },
  {
    field: 'ssn',
    flex: 1,
    headerName: 'SSN'
  }
];

const UnrecognizedParticipants: FC<UnrecognizedParticipantsProps> = props => {
  const [isOpen, setIsOpen] = useState(!!props.rows?.length);

  const rows = useMemo(
    () => props.rows?.map(row => ({ ...row, id: v4() })) ?? [],
    [props.rows]
  );

  const onCancel = useCallback(() => {
    setIsOpen(false);
    props.onCancel();
  }, [props]);

  return (
    <Dialog fullWidth maxWidth='sm' open={isOpen}>
      <DialogTitle>File contains unknown SSNs</DialogTitle>
      <DialogContent>
        <DialogContentText>
          The following SSNs do not exist on this plan.
        </DialogContentText>
        <DataGridPro
          autoHeight
          columns={columns}
          pagination
          paginationMode='client'
          paginationModel={{
            page: 0,
            pageSize: 25
          }}
          rows={rows}
        />
      </DialogContent>
      <DialogActions>
        <Button data-testid='cancel' onClick={onCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnrecognizedParticipants;
