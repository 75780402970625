import { isLessThanDaysFromNow } from '@/utils/Dates';

import dayjs from 'dayjs';
import { useMemo } from 'react';

export const useWarnings = (formikValues: {
  blackoutStartDate?: Parameters<typeof dayjs>[0];
  [key: string]: unknown;
}) => {
  return useMemo(() => {
    const isWithin7 = isLessThanDaysFromNow(formikValues.blackoutStartDate, 7);
    const isWithin35 = isLessThanDaysFromNow(
      formikValues.blackoutStartDate,
      35
    );

    return {
      blackoutStartDate: isWithin7
        ? 'Blackout start is less than 7 days from now. Participant and sponsor notices will not be automated.'
        : isWithin35
          ? 'Blackout start is less than 35 days from now. Participant notices will not be automated.'
          : null
    };
  }, [formikValues.blackoutStartDate]);
};
