import CardInfoField from '@/components/card-info-field';
import SimpleDropdown from '@/components/simple-dropdown';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { SubaErrors } from '@/hooks/useSubaErrors.hook';
import { GetSubaWithholdingContext } from '@/models/SubaWithholdingDTO.model';
import { RemoveCircleOutlineOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';

import { FormikContextType, useFormikContext } from 'formik';
import { get, isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { ValidationError } from 'yup';

import { NumericDollarFormat } from '../../NumericDollarFormat';
import AddressGrid from './AddressGrid.component';
import BankAccountCard from './BankAccountCard.component';
import {
  NOT_ROLLOVER_ELIGIBLE_DISTRIBUTIONS,
  TAX_TYPES,
  WITHHOLDING_ACCEPT_CODES
} from './constants';
import {
  useWithholdingInfo,
  withholdingValidationSchema
} from './customWithdrawalRequest';
import TaxDetailsCard from './TaxDetailsCard.component';

type WithdrawalDisbursementCardProps = {
  index: number;
  remove: <T>(index: number) => T | undefined;
  errors: SubaErrors;
};

const WithdrawalDisbursementCard: React.FunctionComponent<
  WithdrawalDisbursementCardProps
> = (props: WithdrawalDisbursementCardProps) => {
  const { index, remove } = props;
  const formik: FormikContextType<any> = useFormikContext();
  const withholdingMutation = useWithholdingInfo(formik.values.participantId);

  const onClickWithholding = async () => {
    const data = {
      amount: formik.values.disbursements[index].amount,
      destination:
        formik.values.disbursements[index].taxType?.toLowerCase() === 'roth' &&
        formik.values.disbursements[index].isRollover &&
        formik.values.disbursements[index].rothIRADestination
          ? 'Roth IRA'
          : undefined,
      distributionType: formik.values.withdrawalCode,
      earnings:
        formik.values.withdrawalCode === '402G'
          ? formik.values.earnings
          : undefined,
      isRollover: NOT_ROLLOVER_ELIGIBLE_DISTRIBUTIONS.includes(
        formik.values.withdrawalCode
      )
        ? false
        : formik.values.disbursements[index].isRollover,
      taxType: formik.values.disbursements[index].taxType?.toLowerCase()
    } as GetSubaWithholdingContext;

    await withholdingValidationSchema
      .validate(data, { abortEarly: false })
      .then(async () => {
        const res = await withholdingMutation.mutateAsync(data);

        formik.setFieldValue(`disbursements[${index}]`, {
          ...formik.values.disbursements[index],
          _1099Code: res.data.code1099,
          federalWithholdingPercent: res.data.federalWithholdingPercent,
          stateWithholdingCode: res.data.stateWithholdingCode,
          stateWithholdingPercent: res.data.stateWithholdingPercent
        });
      })
      .catch((err: any) => {
        if (err instanceof ValidationError) {
          err.inner.forEach((e: ValidationError) => {
            formik.setFieldTouched(`disbursements[${index}].${e.path}`, true);
            formik.setFieldError(
              `disbursements[${index}].${e.path}`,
              e.message
            );
          });
        }
      });
  };

  const getDisbursementErrors = useMemo(() => {
    const disbursementErrors =
      props.errors.indexedErrors?.['disbursements']?.[index]?.['error'];
    const disbursementAddressErrors =
      props.errors.indexedErrors?.['disbursementAddress']?.[index]?.['error'];
    return [disbursementErrors, disbursementAddressErrors]
      .filter(Boolean)
      .join('\n');
  }, [props.errors.indexedErrors, index]);

  return (
    <Card elevation={0} sx={{ mb: 3 }} variant='outlined'>
      <CardContent>
        <Typography
          id='tableTitle'
          sx={{ fontWeight: 500, mb: 3 }}
          variant='subtitle1'>
          Disbursement #{index + 1}
        </Typography>
        <FormControl>
          {!isEmpty(getDisbursementErrors) && (
            <Alert severity='error' sx={{ whiteSpace: 'pre-line' }}>
              {getDisbursementErrors}
            </Alert>
          )}
        </FormControl>
        <Grid columnSpacing={{ md: 3, sm: 2, xs: 1 }} container rowSpacing={2}>
          <Grid item xs={4}>
            <SimpleDropdown
              fieldId={`disbursements[${index}].taxType`}
              fieldName='Tax Type*'
              fieldOptions={TAX_TYPES}
              onChange={event => {
                formik.setFieldValue(
                  `disbursements[${index}].taxType`,
                  event.target.value
                );
              }}
              size='small'
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              InputProps={{
                inputComponent: NumericDollarFormat as any,
                startAdornment: (
                  <InputAdornment position='start'>$</InputAdornment>
                )
              }}
              disabled={get(
                formik.values,
                `disbursements[${index}].fullAmount`
              )}
              error={
                get(formik.touched, `disbursements[${index}].amount`) &&
                Boolean(get(formik.errors, `disbursements[${index}].amount`))
              }
              fullWidth
              helperText={
                (get(formik.touched, `disbursements[${index}].amount`) &&
                  get(
                    formik.errors,
                    `disbursements[${index}].amount`
                  )?.toString()) ||
                ''
              }
              id={`disbursements[${index}].amount`}
              label='Amount'
              name={`disbursements[${index}].amount`}
              onChange={e => {
                formik.handleChange(e);
              }}
              required
              size='small'
              value={get(formik.values, `disbursements[${index}].amount`)}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={get(
                    formik.values,
                    `disbursements[${index}].fullAmount`
                  )}
                  data-testid='full-amount-checkbox'
                  name={`disbursements[${index}].fullAmount`}
                  onChange={(e, checked) => {
                    formik.setFieldValue(
                      `disbursements[${index}].fullAmount`,
                      checked
                    );
                  }}
                  value={get(
                    formik.values,
                    `disbursements[${index}].fullAmount`
                  )}
                />
              }
              label='Full Amount'
              sx={{ width: 400 }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              InputProps={{
                inputComponent: NumericDollarFormat as any,
                startAdornment: (
                  <InputAdornment position='start'>$</InputAdornment>
                )
              }}
              error={
                get(formik.touched, `disbursements[${index}].feeAmount`) &&
                Boolean(get(formik.errors, `disbursements[${index}].feeAmount`))
              }
              fullWidth
              helperText={
                (get(formik.touched, `disbursements[${index}].feeAmount`) &&
                  get(
                    formik.errors,
                    `disbursements[${index}].feeAmount`
                  )?.toString()) ||
                ''
              }
              id={`disbursements[${index}].feeAmount`}
              label='Fee Amount'
              name={`disbursements[${index}].feeAmount`}
              onChange={formik.handleChange}
              required
              size='small'
              value={get(formik.values, `disbursements[${index}].feeAmount`)}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={get(
                    formik.values,
                    `disbursements[${index}].isRollover`
                  )}
                  data-testid='is-rollover-checkbox'
                  name={`disbursements[${index}].isRollover`}
                  onChange={(e, checked) => {
                    formik.setFieldValue(
                      `disbursements[${index}].isRollover`,
                      checked
                    );
                  }}
                  value={get(
                    formik.values,
                    `disbursements[${index}].isRollover`
                  )}
                />
              }
              disabled={NOT_ROLLOVER_ELIGIBLE_DISTRIBUTIONS.includes(
                formik.values.withdrawalCode
              )}
              label='Is a Rollover?'
              sx={{ width: 400 }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    formik.values.disbursements?.[index]?.rothIRADestination
                  }
                  data-testid='roth-ira-checkbox'
                  name={`disbursements[${index}].rothIRADestination`}
                  onChange={(e, checked) => {
                    formik.setFieldValue(
                      `disbursements[${index}].rothIRADestination`,
                      checked
                    );
                  }}
                  value={
                    formik.values.disbursements?.[index]?.rothIRADestination
                  }
                />
              }
              disabled={!formik.values.disbursements[index].isRollover}
              label='Roth IRA Destination?'
            />
          </Grid>
        </Grid>

        <FormControl sx={{ mt: 3 }}>
          <FormLabel id='disbursement-method-radio-group'>
            Delivery Method
          </FormLabel>
          <RadioGroup
            aria-labelledby='disbursement-method-radio-group-label'
            name={`disbursements[${index}].disbursementMethod`}
            onChange={e => {
              formik.setFieldValue(
                `disbursements[${index}].disbursementMethod`,
                e.target.value
              );
              formik.setFieldValue(
                `disbursements[${index}].receivingBankAccountName`,
                ['ACH', 'WIRE'].includes(e.target.value)
                  ? `${formik.values.participantFirstName} ${formik.values.participantLastName}`
                  : undefined
              );
            }}
            row
            value={get(
              formik.values,
              `disbursements[${index}].disbursementMethod`
            )}>
            <FormControlLabel
              control={<Radio />}
              label='Check'
              onChange={formik.handleChange}
              value='CHECK'
            />
            <FormControlLabel
              control={<Radio />}
              label='ACH'
              onChange={formik.handleChange}
              value='ACH'
            />
            <FormControlLabel
              control={<Radio />}
              label='Wire'
              onChange={formik.handleChange}
              value='WIRE'
            />
          </RadioGroup>
          <FormHelperText
            error={
              get(
                formik.touched,
                `disbursements[${index}].disbursementMethod`
              ) &&
              Boolean(
                get(formik.errors, `disbursements[${index}].disbursementMethod`)
              )
            }>
            {get(formik.touched, `disbursements[${index}].disbursementMethod`)
              ? get(
                  formik.errors,
                  `disbursements[${index}].disbursementMethod`
                )?.toString() ?? ''
              : ''}
          </FormHelperText>
        </FormControl>

        {formik.values.disbursements[index].taxType &&
          formik.values.disbursements[index].disbursementMethod && (
            <>
              <Divider sx={{ mb: 2, mt: 3 }} textAlign='left'>
                Delivery Options
              </Divider>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        formik.values.disbursements[index].useParticipantAddress
                      }
                    />
                  }
                  label='Use Participant Address'
                  name={`disbursements[${index}].useParticipantAddress`}
                  onChange={(event, checked) => {
                    formik.setFieldValue(
                      `disbursements[${index}].useParticipantAddress`,
                      checked
                    );
                  }}
                />

                {!formik.values.disbursements[index].useParticipantAddress && (
                  <AddressGrid
                    keyPrefix={`disbursements[${index}].`}
                    type='payee'
                  />
                )}
                {formik.values.disbursements[index].disbursementMethod ===
                  'CHECK' && (
                  <>
                    <Box sx={{ display: 'flex', gap: 3 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              formik.values.disbursements[index].overnightCheck
                            }
                          />
                        }
                        label='Overnight Check (Fedex)'
                        name={`disbursements[${index}].overnightCheck`}
                        onChange={(event, checked) => {
                          formik.setFieldValue(
                            `disbursements[${index}].overnightCheck`,
                            checked
                          );
                        }}
                      />
                      {formik.values.disbursements[index].overnightCheck && (
                        <CardInfoField
                          fieldName='Overnight Account Number'
                          fieldValue={
                            get(
                              formik.values,
                              `disbursements[${index}].overnightAccountNumber`
                            ) || EMPTY_FIELD_PLACEHOLDER
                          }
                        />
                      )}
                    </Box>

                    <TextField
                      error={
                        get(
                          formik.touched,
                          `disbursements[${index}].referenceMemo`
                        ) &&
                        Boolean(
                          get(
                            formik.errors,
                            `disbursements[${index}].referenceMemo`
                          )
                        )
                      }
                      helperText={
                        (get(
                          formik.touched,
                          `disbursements[${index}].referenceMemo`
                        ) &&
                          get(
                            formik.errors,
                            `disbursements[${index}].referenceMemo`
                          )?.toString()) ||
                        ''
                      }
                      id={`disbursements[${index}].referenceMemo`}
                      label='Memo'
                      name={`disbursements[${index}].referenceMemo`}
                      onChange={formik.handleChange}
                      size='small'
                      sx={{ mt: 2 }}
                      value={get(
                        formik.values,
                        `disbursements[${index}].referenceMemo`
                      )}
                      variant='outlined'
                    />
                  </>
                )}
              </FormGroup>

              {['ACH', 'WIRE'].includes(
                formik.values.disbursements[index].disbursementMethod
              ) && (
                <BankAccountCard
                  disableCreditInfo={
                    formik.values.disbursements[index].disbursementMethod !=
                    'WIRE'
                  }
                  keyPrefix={`disbursements[${index}].`}
                />
              )}
            </>
          )}
        <Divider sx={{ mb: 2, mt: 3 }} textAlign='left'>
          Tax Details
        </Divider>

        <TaxDetailsCard
          disableRothInfo={formik.values.disbursements[index].taxType != 'roth'}
          keyPrefix={`disbursements[${index}].`}
        />

        <Box sx={{ display: 'flex', float: 'right', gap: 2, py: 2 }}>
          {WITHHOLDING_ACCEPT_CODES.includes(formik.values.withdrawalCode) && (
            <LoadingButton
              onClick={() => onClickWithholding()}
              sx={{ float: 'right' }}
              variant='contained'>
              Calculate Withholding
            </LoadingButton>
          )}

          {formik.values.disbursements.length > 1 && (
            <Button
              onClick={() => remove(index)}
              startIcon={<RemoveCircleOutlineOutlined />}
              sx={{ float: 'right' }}>
              REMOVE
            </Button>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};
export default WithdrawalDisbursementCard;
