import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, BreadcrumbsProps, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import clsx from 'clsx';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface Path {
  to: string;
  name: string;
}

interface NavigationBreadcrumbsProps extends BreadcrumbsProps {
  paths: Path[];
  'data-testid'?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex'
  },
  icon: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(0.2)
  },
  link: {
    '&:hover': {
      textDecoration: 'underline'
    },
    color: theme.palette.info.light,
    textDecoration: 'none'
  },
  root: {
    marginBottom: theme.spacing(1)
  },
  separator: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));

const NavigationBreadcrumbs: React.FunctionComponent<
  NavigationBreadcrumbsProps
> = (props: NavigationBreadcrumbsProps) => {
  const classes = useStyles();

  const { paths, 'data-testid': testId } = props;

  if (paths.length === 0) {
    return <></>;
  }

  return (
    <div
      className={clsx(classes.container, 'NavigationBreadcrumbs')}
      data-testid={testId}>
      <Breadcrumbs
        aria-label='breadcrumb'
        className={classes.root}
        separator={
          <NavigateNextIcon
            className={clsx(
              classes.separator,
              'NavigationBreadcrumbs__separator'
            )}
            fontSize='small'
          />
        }>
        {paths.map(path => (
          <RouterLink className={classes.link} key={path.to} to={path.to}>
            {path.name}
          </RouterLink>
        ))}
      </Breadcrumbs>
      <NavigateNextIcon
        className={clsx(classes.icon, 'NavigationBreadcrumbs__separator')}
        fontSize='small'
      />
    </div>
  );
};

export type { NavigationBreadcrumbsProps };
export default NavigationBreadcrumbs;
