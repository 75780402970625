import { useSnackbar } from '@/contexts/SnackBarContext';
import { FraudRankerReport } from '@/models/FraudrankerResult.model';
import { UpdateLoanDto } from '@/models/LoanDTO.model';
import ParticipantService from '@/services/Participant.service';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Stack, Switch } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ChangeEvent, FC } from 'react';
import { useToggle } from 'react-use';

type mutationProps = {
  calloutToParticipant: boolean;
  id: number;
  participantId?: number;
};

export const FraudrankerCalloutParticipantCell: FC<{
  data: FraudRankerReport;
}> = props => {
  const [edit, toggleEdit] = useToggle(false);
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const withdrawalMutation = useMutation(
    ['ParticipantService.updateFraudRank'],
    (params: mutationProps) =>
      ParticipantService.updateFraudRank({
        calloutToParticipant: params.calloutToParticipant,
        withdrawalId: params.id
      }),
    {
      onError: () => {
        showSnackbar({
          message: 'Error updating withdrawal callout to participant',
          severity: 'error'
        });
      },
      onSuccess: () => {
        toggleEdit();
        queryClient.refetchQueries(['FraudrankerService.getReport']);
        showSnackbar({
          message: 'Success!',
          severity: 'success'
        });
      }
    }
  );

  const loanMutation = useMutation(
    ['ParticipantService.updateLoan'],
    (params: mutationProps) =>
      ParticipantService.updateLoan(params.participantId, params.id, {
        attributes: { calloutToParticipant: params.calloutToParticipant },
        id: +params.id
      } as UpdateLoanDto),
    {
      onError: () => {
        showSnackbar({
          message: 'Error updating loan callout to participant',
          severity: 'error'
        });
      },
      onSuccess: () => {
        toggleEdit();
        queryClient.refetchQueries(['FraudrankerService.getReport']);
        showSnackbar({
          message: 'Success',
          severity: 'success'
        });
      }
    }
  );

  const handleSetCalloutToParticipant = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (props.data.reason === 'Loan') {
      loanMutation.mutate({
        calloutToParticipant: event.target.checked,
        id: props.data.id,
        participantId: props.data.participant.id
      });
    } else {
      withdrawalMutation.mutate({
        calloutToParticipant: event.target.checked,
        id: props.data.id
      });
    }
  };

  return (
    <Stack alignItems='center' direction='row'>
      {!edit &&
        (props.data.calloutParticipant ? (
          <CheckCircleIcon color='action' />
        ) : (
          <CloseIcon color='action' />
        ))}

      {edit ? (
        <Switch
          checked={props.data.calloutParticipant}
          disabled={withdrawalMutation.isLoading || loanMutation.isLoading}
          name='calloutToParticipant'
          onChange={handleSetCalloutToParticipant}
          size='small'
        />
      ) : (
        <IconButton aria-label='edit callout' onClick={toggleEdit} size='small'>
          <EditIcon fontSize='inherit' />
        </IconButton>
      )}
    </Stack>
  );
};
