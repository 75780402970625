import CollapsibleTable from '@/components/collapsible-table';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { ModelFundProps } from '@/routes/ops/investments/data-grid/RiskAndTargetUtil';
import {
  RiskSeriesData,
  RiskSeriesModelProps
} from '@/routes/ops/investments/RiskSeries.component';
import formatters from '@/utils/Formatters';
import { TabContext, TabPanel } from '@mui/lab';
import {
  alpha,
  Box,
  Card,
  CardContent,
  CardHeader,
  Tab,
  Tabs,
  Theme
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GridColDef } from '@mui/x-data-grid-pro';

import Decimal from 'decimal.js';
import React, { useMemo, useState } from 'react';

import RiskSeriesTableCell from './RiskSeriesCell.component';
import { RiskSeriesFields } from './types';

const mapDataRow = (option: ModelFundProps, maxTickerLength: number) => ({
  expenseRatio: new Decimal(option.expenseRatio || 0).toFixed(5),
  id: option.modelFundId,
  maxTickerLength,
  security: {
    cusip: option.cusip,
    fundName: option.fundName,
    id: option.symbol
  },
  totalAllocation: option.target,
  turnOffRowBorder: true
});

const useStyles = makeStyles((theme: Theme) => ({
  card: { padding: theme.spacing(3) },
  cardContext: { padding: 0, paddingTop: theme.spacing(2.25) },
  cardHeaderRoot: { padding: 0 },
  cardHeaderTitle: { fontWeight: 500 },
  headerCell: {
    color: alpha(theme.palette.common.black, 0.6),
    fontSize: theme.spacing(1.75),
    fontWeight: 500,
    paddingBottom: theme.spacing(1.25),
    paddingTop: theme.spacing(1.25)
  },
  rightSide: {
    borderLeft: '1px solid rgb(224, 224, 224)'
  },
  riskCardContext: {
    '&:first-child': {
      border: '1px solid rgb(224, 224, 224)'
    }
  },
  tab: {
    alignItems: 'start'
  },
  tabPanel: {
    padding: 0
  },
  tabs: {
    paddingTop: theme.spacing(2)
  }
}));

interface RiskSeriesTableProps {
  riskSeriesGrid: RiskSeriesModelProps[];
  riskSeries: RiskSeriesData;
}

const RiskSeriesTable: React.FC<RiskSeriesTableProps> = props => {
  const { riskSeries, riskSeriesGrid } = props;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentRiskLevel, setCurrentRiskLevel] = useState('1');
  const classes = useStyles();

  const handleTabChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: any
  ) => setCurrentRiskLevel(newValue);

  const tickerRows = useMemo(() => {
    return riskSeriesGrid.find(e => e.riskLevel === +currentRiskLevel);
  }, [riskSeriesGrid, currentRiskLevel]);

  const availableTabs = [...riskSeriesGrid]
    .sort((a, b) => a.riskLevel - b.riskLevel)
    .map(e => ({ label: `Risk level ${e.riskLevel}`, value: e.riskLevel }));

  const columns: GridColDef[] = useMemo(
    () => [
      {
        cellClassName: classes.headerCell,
        field: RiskSeriesFields.SECURITY,
        headerName: 'Security'
      },
      {
        cellClassName: classes.headerCell,
        field: RiskSeriesFields.TOTAL_ALLOCATION,
        headerName: 'Total Allocation'
      },
      {
        cellClassName: classes.headerCell,
        field: RiskSeriesFields.EXPENSE_RATIO,
        headerName: 'Expense Ratio'
      }
    ],
    [classes]
  );

  const tableData = useMemo(() => {
    if (!tickerRows) return [];

    const maxTickerLenght = Math.max(
      ...tickerRows.modelFunds.map(fund => fund.symbol?.length || 0)
    );
    return tickerRows.modelFunds.map(p => mapDataRow(p, maxTickerLenght));
  }, [tickerRows]);

  const programDesc = [
    { label: 'Owner', value: 'Vestwell' },
    {
      label: 'Description',
      value: formatters.chopStringLongerThan(riskSeries?.description, 300)
    }
  ];

  const riskDesc = [
    { label: 'Risk Level', value: currentRiskLevel },
    { label: 'Model Name', value: tickerRows?.modelName }
  ];

  return (
    <Box>
      <Card className={classes.card} elevation={0} variant='outlined'>
        <CardHeader
          classes={{
            root: classes.cardHeaderRoot,
            title: classes.cardHeaderTitle
          }}
          title={riskSeries?.name}
        />
        <CardContent className={classes.cardContext}>
          <TextStack direction='row' rowColumnWidth='dynamic'>
            {programDesc
              .filter(item => item.value)
              .map(item => (
                <TextStackItem key={item.label} maxWidth='100%'>
                  <TextLabel>{item.label}</TextLabel>
                  <TextValue>{item.value}</TextValue>
                </TextStackItem>
              ))}
          </TextStack>
        </CardContent>
      </Card>
      <Box alignItems='stretch' display='flex' flexDirection='row'>
        <TabContext value={currentRiskLevel}>
          <Box className={classes.tabs}>
            <Tabs
              onChange={handleTabChange}
              orientation='vertical'
              sx={{
                borderColor: 'divider',
                borderRight: 1,
                width: 240
              }}
              value={currentRiskLevel}
              variant='standard'>
              {availableTabs.map(tab => (
                <Tab
                  className={classes.tab}
                  data-testid={`risk-series-tab-button-${tab.value}`}
                  key={tab.label}
                  label={tab.label}
                  value={tab.value.toString()}
                />
              ))}
            </Tabs>
          </Box>
          <Box className={classes.rightSide} flexGrow={1}>
            <CardContent>
              <TextStack
                className={classes.riskCardContext}
                direction='row'
                divider
                rowColumnWidth='dynamic'>
                {riskDesc
                  .filter(item => item.value)
                  .map(item => (
                    <TextStackItem key={item.label}>
                      <TextLabel>{item.label}</TextLabel>
                      <TextValue>{item.value}</TextValue>
                    </TextStackItem>
                  ))}
              </TextStack>
            </CardContent>
            {availableTabs.map(tab => {
              return (
                <TabPanel
                  className={classes.tabPanel}
                  key={tab.label}
                  value={tab.value.toString()}>
                  <CollapsibleTable
                    backgroundPaperElevation={0}
                    cellComponent={RiskSeriesTableCell}
                    columns={columns}
                    pager={{
                      metaCount: tableData.length,
                      onPageNumberChanged: newPage =>
                        setPageNumber(newPage + 1),
                      onRowsPerPageChanged: newRowsPerPage =>
                        setRowsPerPage(newRowsPerPage),
                      pageNumber: pageNumber - 1,
                      rowsPerPage: rowsPerPage
                    }}
                    primaryKey='targetSeriesId'
                    rootPaperElevation={0}
                    tableData={tableData.slice(
                      rowsPerPage * pageNumber - rowsPerPage,
                      rowsPerPage * pageNumber
                    )}
                    useSquareBottomContainer
                  />
                </TabPanel>
              );
            })}
          </Box>
        </TabContext>
      </Box>
    </Box>
  );
};

export default RiskSeriesTable;
