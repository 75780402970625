import useHasPermissions from '@/components/access-control/useHasPermissions.hook';
import LinearLoading from '@/components/linear-loading';
import { Permissions } from '@/models/UserPermissions.model';
import { userService } from '@/services/User.service';

import { Navigate, RouteProps, useLocation } from 'react-router-dom';

type PrivateRouteProps = RouteProps & {
  component: any;
  accessRequires?: Permissions[];
  accessRequiresOneOf?: Permissions[];
  hideFromTPA?: boolean;
};

const PrivateRoute = (props: PrivateRouteProps): JSX.Element => {
  const {
    component: Component,
    accessRequires,
    accessRequiresOneOf,
    hideFromTPA
  } = props;
  const hasAccessToken = !!userService.getAccessToken();
  const { isAllowed, isLoading } = useHasPermissions({
    hideFromTPA,
    requires: accessRequires,
    requiresOneOf: accessRequiresOneOf
  });
  const shouldRender = !isLoading && hasAccessToken;
  const location = useLocation();
  const pathname = window.location.pathname;

  if (pathname.includes('//')) {
    const index = pathname.indexOf('//');
    const lastValidPathname = pathname.slice(0, index);
    window.location.replace(lastValidPathname);
  }

  return (
    <>
      {!shouldRender && <LinearLoading />}
      {shouldRender &&
        (isAllowed ? (
          <Component />
        ) : (
          <Navigate state={{ from: location }} to={{ pathname: '/' }} />
        ))}
    </>
  );
};

export default PrivateRoute;
