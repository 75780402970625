import { ContributionFileContents } from '@/models';
import { NormalizedFileContents } from '@/models/ContributionFileContents.model';
import { SponsorDocumentDto } from '@/models/SponsorDocumentDTO.model';
import { PlanService } from '@/services/Plan.service';
import SponsorService from '@/services/Sponsor.service';
import formatters from '@/utils/Formatters';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {
  Card,
  CardContent,
  IconButton,
  Theme,
  Tooltip,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';

import { last } from 'lodash';
import Papa from 'papaparse';
import React, { useMemo } from 'react';

const CONTRIBUTION_FILE_DOCUMENT_TYPE_ID = 7;
const OFF_CYCLE_CONTRIBUTION_FILE_DOCUMENT_TYPE_ID = 9;

const DEFAULT_NORMALIZED_FILENAME = 'normalized-contributions-file.csv';
const DEFAULT_RAW_FILENAME = 'payroll-contributions-file.csv';

const useStyles = makeStyles((theme: Theme) => ({
  downloadContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  downloadIcon: {
    '&.Mui-disabled': {
      pointerEvents: 'auto' // enable tooltip for disabled download button
    },
    color: theme.palette.info.main,
    cursor: 'pointer'
  },
  fileCard: {
    height: '100%'
  },
  fileDateField: {
    color: theme.palette.text.secondary,
    fontSize: theme.spacing(1.8),
    marginTop: theme.spacing(-1.4)
  },
  noFilesPlaceholder: {
    marginTop: theme.spacing(1)
  },
  textHeader: {
    paddingTop: theme.spacing(1.4)
  }
}));

interface PlanFileCardProps {
  sponsorId: number | string;
  sponsorPlanId: string;
  ucid: string;
}

const PlanFileCard: React.FunctionComponent<PlanFileCardProps> = (
  props: PlanFileCardProps
) => {
  const classes = useStyles();

  const { sponsorId, sponsorPlanId, ucid } = props;

  const sponsorDocumentsQuery = useQuery<SponsorDocumentDto[]>(
    ['sponsorContributionDocuments', sponsorId, ucid],
    async () => {
      const allDocuments = await SponsorService.getDocumentsBySponsor(
        Number(sponsorId)
      );

      return allDocuments.data.filter(
        docDto =>
          docDto.attributes.ucid === ucid &&
          [
            OFF_CYCLE_CONTRIBUTION_FILE_DOCUMENT_TYPE_ID,
            CONTRIBUTION_FILE_DOCUMENT_TYPE_ID
          ].includes(docDto.relationships['document-category'].data.id)
      );
    },
    {
      enabled: Boolean(sponsorId && ucid),
      staleTime: Infinity
    }
  );

  const contributionDoc = last(sponsorDocumentsQuery.data || []);

  const documentCategoryId =
    contributionDoc?.relationships['document-category'].data.id;

  const normalizedFile = contributionDoc;
  const rawFile = contributionDoc;

  const normalizedFileContentsQuery = useQuery<ContributionFileContents>(
    [
      PlanService.getNormalizedContributionsFileContents.name,
      sponsorPlanId,
      ucid,
      documentCategoryId
    ],
    async () => {
      if (!documentCategoryId) throw new Error('keep the compiler happy');

      return PlanService.getNormalizedContributionsFileContents(
        sponsorPlanId,
        ucid,
        documentCategoryId
      );
    },
    {
      enabled: Boolean(sponsorPlanId && ucid && documentCategoryId),
      retry: (failureCount, error: any) => error && failureCount <= 3,
      staleTime: Infinity
    }
  );

  const rawFileContentsQuery = useQuery<ArrayBuffer>(
    [
      PlanService.getRawContributionsFileContents.name,
      sponsorPlanId,
      ucid,
      documentCategoryId
    ],
    async () => {
      if (!documentCategoryId) throw new Error('keep the compiler happy');

      return PlanService.getRawContributionsFileContents(
        sponsorPlanId,
        ucid,
        documentCategoryId
      );
    },
    {
      enabled: Boolean(sponsorPlanId && ucid && documentCategoryId),
      retry: (failureCount, error: any) => error && failureCount <= 3,
      staleTime: Infinity
    }
  );

  const base64 = useMemo(
    () =>
      rawFileContentsQuery.data
        ? Buffer.from(rawFileContentsQuery.data).toString('base64')
        : '',
    [rawFileContentsQuery.data]
  );

  if (sponsorDocumentsQuery.isFetching) {
    return (
      <Card className={classes.fileCard} elevation={0} variant='outlined'>
        <CardContent>
          <Typography variant='h5'>File</Typography>
          <Typography className='Plan-Contribution-Details_Loading'>
            Loading...
          </Typography>
        </CardContent>
      </Card>
    );
  }

  let downloadRawFileButtonTooltipText = 'Loading file, please wait...';
  if (rawFileContentsQuery.isSuccess) {
    downloadRawFileButtonTooltipText = `click to download "${rawFile?.attributes.name}"`;
  } else if (rawFileContentsQuery.isError) {
    downloadRawFileButtonTooltipText = `could not find file contents for ucid: "${ucid}"`;
  }

  let downloadNormalizedFileButtonTooltipText = 'Loading file, please wait...';
  let normalizedFileName = DEFAULT_NORMALIZED_FILENAME;
  if (normalizedFileContentsQuery.isSuccess) {
    normalizedFileName = (
      normalizedFile?.attributes.name || DEFAULT_NORMALIZED_FILENAME
    ).replace(/(\.xlsx*)$/i, '.csv');
    downloadNormalizedFileButtonTooltipText = `click to download the normalized version of "${normalizedFileName}"`;
  } else if (normalizedFileContentsQuery.isError) {
    downloadNormalizedFileButtonTooltipText = `could not find file normalization for ucid: "${ucid}"`;
  }

  const normalizedFileTemplate = (
    <>
      <div
        className={classes.downloadContainer}
        data-testid='normalized-file-box'>
        <Typography className={classes.textHeader}>Normalized File</Typography>
        <Tooltip title={downloadNormalizedFileButtonTooltipText}>
          {normalizedFile && normalizedFileContentsQuery.data ? (
            <IconButton
              className={classes.downloadIcon}
              download={normalizedFileName}
              href={`data:application/octet-stream,${Papa.unparse(
                formatters.flattenFileContentsForCSV(
                  normalizedFileContentsQuery.data as NormalizedFileContents
                )
              )}`}
              size='large'>
              <CloudDownloadIcon />
            </IconButton>
          ) : (
            <div>
              <IconButton disabled size='large'>
                <CloudDownloadIcon />
              </IconButton>
            </div>
          )}
        </Tooltip>
      </div>
      <Typography
        className={classes.fileDateField}
        data-testid='normalized-file-createdAt'>
        {formatters.formatFromIsoDate(
          normalizedFile?.attributes.createdAt as string
        )}
      </Typography>
    </>
  );

  return (
    <Card className={classes.fileCard} elevation={0} variant='outlined'>
      <CardContent>
        <Typography variant='h5'>File</Typography>
        {!sponsorDocumentsQuery?.data?.length && (
          <Typography className={classes.noFilesPlaceholder}>
            No files for this contribution
          </Typography>
        )}
        {rawFile && (
          <>
            <div
              className={classes.downloadContainer}
              data-testid='raw-file-box'>
              <Typography className={classes.textHeader}>Raw File</Typography>
              <Tooltip title={downloadRawFileButtonTooltipText}>
                {rawFile && rawFileContentsQuery.data ? (
                  <IconButton
                    className={classes.downloadIcon}
                    download={rawFile?.attributes.name || DEFAULT_RAW_FILENAME}
                    href={`data:application/octet-stream;base64,${base64}`}
                    size='large'>
                    <CloudDownloadIcon />
                  </IconButton>
                ) : (
                  <div>
                    <IconButton disabled size='large'>
                      <CloudDownloadIcon />
                    </IconButton>
                  </div>
                )}
              </Tooltip>
            </div>
            <Typography
              className={classes.fileDateField}
              data-testid='raw-file-createdAt'>
              {formatters.formatFromIsoDate(
                rawFile?.attributes.createdAt as string
              )}
            </Typography>
          </>
        )}
        {normalizedFile && normalizedFileTemplate}
      </CardContent>
    </Card>
  );
};

export default PlanFileCard;
