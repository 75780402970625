import { CardPlaceholder } from '@/components/card';
import { useGetPlanScheduledChanges } from '@/components/scheduled-changes/useGetPlanScheduledChanges';
import { useSnackbar } from '@/contexts/SnackBarContext';
import useUpdateSponsor from '@/hooks/useUpdateSponsor';
import { SponsorDto, UpdateSponsorDto } from '@/models';
import {
  ScheduledChange,
  ScheduledChangePayloadItem
} from '@/models/ScheduledChangesDTO.model';
import { SponsorControlGroupBusiness } from '@/models/SponsorDTO.model';
import SponsorService from '@/services/Sponsor.service';
import formatters from '@/utils/Formatters';
import BusinessIcon from '@mui/icons-material/Business';
import { Box, Button, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';

import dayjs from 'dayjs';
import { FC, useEffect, useMemo, useState } from 'react';
import { useToggle } from 'react-use';
import { v4 } from 'uuid';
import * as yup from 'yup';

import ControlGroupItem from './ControlGroupItem.component';

interface ControlGroupTabProps {
  sponsorId: string | number;
  sponsorPlanId: number;
  isStateIRA: boolean;
}

export interface Business {
  business: string;
  ein: string;
  id: string;
}

export const validateControlGroup = (): yup.AnyObjectSchema => {
  const einRegex = new RegExp(`((^\\d{9}$)|(^\\d{2}-\\d{7}$))`);

  const blackListedEINs = [
    '000000000',
    '111111111',
    '222222222',
    '333333333',
    '444444444',
    '555555555',
    '666666666',
    '777777777',
    '888888888',
    '999999999',
    '123456789',
    '987654321'
  ];

  return yup.object({
    business: yup.string().required('Required'),
    ein: yup
      .string()
      .matches(einRegex, 'Please enter a valid EIN')
      .test({
        test(value) {
          if (value && blackListedEINs.includes(value.replaceAll('-', ''))) {
            return this.createError({
              message: 'Please enter a valid EIN',
              path: this.path
            });
          }

          if (
            this.parent.allBusinesses.find(
              (business: Business) =>
                business.ein.replace('-', '') === value?.replace('-', '') &&
                business.id !== this.parent.id
            )
          ) {
            return this.createError({
              message: 'EIN already exists',
              path: this.path
            });
          }

          return true;
        }
      })
      .required('Required')
  });
};

const ControlGroupTab: FC<ControlGroupTabProps> = props => {
  const [isNewOpen, toggleNewOpen] = useToggle(false);
  const sponsorMutation = useUpdateSponsor(false);
  const { showSnackbar } = useSnackbar();
  const [action, setAction] = useState<{
    name: string;
    business: string;
  } | null>(null);

  const scheduledChanges = useGetPlanScheduledChanges({
    planId: +props.sponsorPlanId
  });
  const scheduledChangesHistory = useGetPlanScheduledChanges({
    applied: true,
    enabled: false,
    planId: +props.sponsorPlanId
  });

  const sponsorQuery = useQuery<SponsorDto>(
    ['SponsorService.getSponsorById', props.sponsorId?.toString()],
    () => {
      return SponsorService.getSponsorById(props.sponsorId);
    },
    {
      enabled: Boolean(props.sponsorId),
      staleTime: Infinity
    }
  );

  const createScheduleChange = useMutation(
    ['SponsorService.createScheduleChange'],
    (params: Omit<ScheduledChange, 'createdAt' | 'createdBy' | 'id'>) => {
      return SponsorService.createScheduleChange(props.sponsorId, params);
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Error!',
          severity: 'error'
        });
      },
      onSuccess: () => {
        scheduledChanges.invalidate('company');

        showSnackbar({ message: 'Success!', severity: 'success' });
      }
    }
  );

  const updateSponsor = useMutation(
    ['SponsorService.updateSponsor'],
    (params: UpdateSponsorDto) => {
      return SponsorService.updateSponsor(props.sponsorId, params);
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Error!',
          severity: 'error'
        });
      },
      onSuccess: () => {
        sponsorQuery.refetch();
        scheduledChangesHistory.invalidate('company');

        showSnackbar({ message: 'Success!', severity: 'success' });
      }
    }
  );

  const controlGroupBusinesses = useMemo<Business[]>(() => {
    try {
      return yup
        .array()
        .of(validateControlGroup())
        .ensure()
        .cast(sponsorQuery.data?.data?.attributes?.controlGroupBusinesses)
        ?.map((business: SponsorControlGroupBusiness) => ({
          business: business.business,
          ein: business.ein.includes('-')
            ? business.ein
            : formatters.formatEinOrSdat(business.ein),
          id: v4()
        })) as Business[];
    } catch (e) {
      return [];
    }
  }, [sponsorQuery.data]);

  const handleSave = async (
    data: Business & { scheduledChangesEffectiveDate: string },
    payload: ScheduledChangePayloadItem[],
    removeId?: string
  ) => {
    setAction({ business: data.business, name: 'add' });

    const businessIdx = controlGroupBusinesses.findIndex(
      business => business.id == data.id
    );

    const businessesToSave: SponsorControlGroupBusiness[] = [
      ...controlGroupBusinesses
        .filter(business => business.id !== removeId)
        .map(business => ({
          business: business.business,
          ein: business.ein
        }))
    ];

    if (businessIdx > -1 && !removeId) {
      businessesToSave[businessIdx] = {
        business: data.business,
        ein: data.ein
      };
    } else if (!removeId) {
      businessesToSave.push({
        business: data.business,
        ein: data.ein
      });
    }

    const newPayload = [...payload];

    newPayload.push({
      fieldName: `controlGroupBusinesses`,
      uiCurrentValue: '',
      uiLabel: '',
      uiScheduledValue: '',
      value: JSON.stringify(businessesToSave)
    });

    if (dayjs(data.scheduledChangesEffectiveDate).isSameOrBefore(dayjs())) {
      await updateSponsor.mutateAsync({
        data: {
          attributes: {
            controlGroupBusinesses: JSON.stringify(businessesToSave)
          },
          id: Number(props.sponsorId),
          type: 'sponsor'
        }
      } as UpdateSponsorDto);
    }
    await createScheduleChange.mutateAsync({
      changeType: 'company',
      effectiveDate: dayjs(data.scheduledChangesEffectiveDate).isSameOrBefore(
        dayjs()
      )
        ? dayjs().format('YYYY-MM-DDTHH:mm:ssZ')
        : dayjs(data.scheduledChangesEffectiveDate).format(
            'YYYY-MM-DDTHH:mm:ssZ'
          ),
      entityId: +props.sponsorId,
      entityType: 'sponsor',
      payload: newPayload
    });
  };

  useEffect(() => {
    if (sponsorMutation.isError) {
      showSnackbar({
        message: `Couldn't ${
          action?.name === 'remove' ? 'remove' : 'update'
        } control group. Please try again`,
        severity: 'error'
      });

      setAction(null);
      sponsorMutation.reset();
    }
  }, [sponsorMutation.isError]);

  useEffect(() => {
    if (sponsorMutation.isSuccess) {
      showSnackbar({
        message:
          action?.name === 'remove'
            ? `Removed ${action?.business} from current plan's control group`
            : `Added ${action?.business} to current plan's control group`,
        severity: 'success'
      });

      setAction(null);
      toggleNewOpen(false);
      sponsorMutation.reset();
    }
  }, [sponsorMutation.isSuccess]);

  if (sponsorQuery.isFetching) {
    return <Typography>Loading...</Typography>;
  }

  if (sponsorQuery.isError) {
    return <Typography>Error</Typography>;
  }

  return (
    <Box p={2} width='100%'>
      {!controlGroupBusinesses?.length && !isNewOpen && (
        <Box display='flex' flexDirection='column' justifyContent='center'>
          <CardPlaceholder
            actionLabel='Add Company'
            icon={<BusinessIcon fontSize='inherit' />}
            onClickAction={() => toggleNewOpen(true)}
            title='No Control Group Companies'
          />
        </Box>
      )}

      <Box display='flex' flexDirection='column'>
        {!!controlGroupBusinesses?.length &&
          controlGroupBusinesses?.map(business => (
            <ControlGroupItem
              allBusinesses={controlGroupBusinesses}
              business={business}
              isLoading={sponsorMutation.isLoading || sponsorQuery.isFetching}
              isNew={false}
              isStateIRA={props.isStateIRA}
              key={business.id}
              onClose={() => toggleNewOpen(false)}
              onSave={handleSave}
              sponsorId={+props.sponsorId}
              sponsorPlanId={+props.sponsorPlanId}
            />
          ))}
      </Box>

      {isNewOpen && (
        <ControlGroupItem
          allBusinesses={controlGroupBusinesses}
          isLoading={sponsorMutation.isLoading || sponsorQuery.isFetching}
          isNew
          isStateIRA={props.isStateIRA}
          onClose={() => toggleNewOpen(false)}
          onSave={handleSave}
          sponsorId={+props.sponsorId}
          sponsorPlanId={+props.sponsorPlanId}
        />
      )}

      {(controlGroupBusinesses?.length || isNewOpen) && (
        <Button
          disabled={isNewOpen}
          onClick={() => toggleNewOpen(true)}
          variant='outlined'>
          Add Company
        </Button>
      )}
    </Box>
  );
};

export default ControlGroupTab;
