import LinearLoading from '@/components/linear-loading';
import {
  TargetSeriesData,
  TargetSeriesModelProps
} from '@/routes/ops/investments/TargetSeries.component';
import InvestmentService from '@/services/Investment.service';
import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import React from 'react';

import TargetSeriesTable from './TargetSeriesTable.component';

interface TargetSeriesTabProps {
  targetDateSeriesId: number;
}

const TargetSeriesTab: React.FC<TargetSeriesTabProps> = ({
  targetDateSeriesId
}) => {
  const targetSeriesQuery = useQuery<TargetSeriesData>(
    ['PlanService.getPlansPage', 'target-series', targetDateSeriesId],
    () => InvestmentService.getTargetModelSeries(targetDateSeriesId),
    {
      staleTime: Infinity
    }
  );

  const targetSeriesGridQuery = useQuery<TargetSeriesModelProps[]>(
    ['PlanService.getPlansPage', 'grid-models', targetDateSeriesId],
    () => InvestmentService.getTargetModels(targetDateSeriesId),
    {
      staleTime: Infinity
    }
  );

  const isLoading =
    targetSeriesQuery.isFetching || targetSeriesGridQuery.isFetching;

  if (isLoading) {
    return (
      <Box textAlign='center'>
        <LinearLoading />
      </Box>
    );
  }

  return (
    <TargetSeriesTable
      targetSeriesData={targetSeriesQuery.data}
      targetSeriesGridData={targetSeriesGridQuery.data}
    />
  );
};

export default TargetSeriesTab;
