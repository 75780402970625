import { PlanV2Dto } from '@/models';
import { usePlanType } from '@/routes/plans/plan-detail/usePlanType.hook';
import { PlanService } from '@/services/Plan.service';
import { useQuery } from '@tanstack/react-query';

import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const useFixPlanRoute = () => {
  const params = useParams<{ planType: string; sponsorPlanId: string }>();
  const navigate = useNavigate();
  const location = useLocation();

  const plan = useQuery<PlanV2Dto>(
    [PlanService.getPlanById.name, params.sponsorPlanId],
    () => PlanService.getPlanById(params.sponsorPlanId),
    {
      enabled: !!params.sponsorPlanId,
      staleTime: Infinity
    }
  );

  const planType = usePlanType({
    recordkeeper: plan.data?.data.attributes.recordkeeper,
    type: plan.data?.data.attributes.type
  });

  useEffect(() => {
    if (params.planType !== planType.urlPlansType && plan.data) {
      const locationPathArray = location.pathname.split('/');
      locationPathArray[1] = planType.urlPlansType;
      return navigate(locationPathArray.join('/'), { replace: true });
    }
  }, [params, location, planType, plan.data]);
};
