import LinearLoading from '@/components/linear-loading';
import {
  GLOBAL_SEARCH_RESULT_PAGE_SIZE,
  GLOBAL_SEARCH_RESULT_POOLED_PLANS_TYPE
} from '@/components/main-layout/GlobalSearch/GlobalSearchResults/constants';
import useShowMore from '@/hooks/useShowMore';
import {
  GlobalSearchDto,
  GlobalSearchPooledPlanDto
} from '@/models/GlobalSearchDTO.model';
import GlobalSearchService from '@/services/GlobalSearch.service';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Theme,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';

import React from 'react';
import { useNavigate } from 'react-router-dom';

interface GlobalSearchPooledPlansResultProps {
  setSearchTerm: (search: string) => void;
  searchTerm: string;
  isFocused: boolean;
  abortSignal?: AbortSignal;
}

const useStyles = makeStyles((theme: Theme) => ({
  globalSearchPooledPlanContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  globalSearchPooledPlanField: {
    color: theme.palette.text.secondary,
    fontSize: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    minHeight: theme.spacing(1.5)
  },
  globalSearchPooledPlanIdField: {
    color: theme.palette.text.secondary,
    fontSize: theme.spacing(1.2),
    paddingTop: theme.spacing(1.5),
    textAlign: 'right',
    width: '25%'
  },
  globalSearchPooledPlanImg: {
    color: theme.palette.error.light,
    paddingBottom: theme.spacing(2.5)
  },
  globalSearchPooledPlanItem: {
    '&:hover': {
      backgroundColor: grey[200]
    },
    cursor: 'pointer'
  },
  globalSearchPooledPlansHeader: {
    fontSize: theme.spacing(1.5),
    letterSpacing: '0.4px',
    marginLeft: theme.spacing(1.5),
    marginTop: theme.spacing(2)
  },
  globalSearchShowMoreBtn: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5)
  },
  noResults: {
    paddingLeft: theme.spacing(2)
  }
}));

const GlobalSearchPooledPlansResult = (
  props: GlobalSearchPooledPlansResultProps | any
): JSX.Element => {
  const classes = useStyles();

  const { setSearchTerm, searchTerm, abortSignal } = props;

  const navigate = useNavigate();

  const redirectToPooledPlanPage = (id: number) => {
    setSearchTerm('');
    navigate(`/pooled-plans/${id}`);
  };

  const globalSearchPooledPlanQuery = useShowMore<
    GlobalSearchDto,
    GlobalSearchPooledPlanDto
  >(
    ['globalSearchPooledPlanResultQuery', searchTerm],
    page =>
      GlobalSearchService.getGlobalSearchResult(
        searchTerm,
        page,
        GLOBAL_SEARCH_RESULT_PAGE_SIZE,
        GLOBAL_SEARCH_RESULT_POOLED_PLANS_TYPE,
        abortSignal
      ),
    Boolean(searchTerm),
    data => data.data.pooledPlans.data,
    data => Boolean(data?.data.pooledPlans.links.next),
    'pooledPlanId'
  );

  let loader;

  if (globalSearchPooledPlanQuery.isLoading || searchTerm === '') {
    loader = <LinearLoading />;
  } else {
    loader = null;
  }

  if (globalSearchPooledPlanQuery.isError) {
    return <p>Error</p>;
  }

  return (
    <>
      <Typography
        className={classes.globalSearchPooledPlansHeader}
        id='global-search-pooled-plans-header'>
        POOLED PLANS
      </Typography>
      {loader || (
        <div>
          {!globalSearchPooledPlanQuery.isLoading &&
          !!globalSearchPooledPlanQuery.data?.length ? (
            <List data-testid='gs-pooled-plans' id='global-search-plans'>
              {globalSearchPooledPlanQuery.data?.map(
                (plan: GlobalSearchPooledPlanDto) => {
                  return (
                    <ListItem
                      className={classes.globalSearchPooledPlanItem}
                      id='global-search-pooled-plans-item'
                      key={plan.pooledPlanId}
                      onClick={() =>
                        redirectToPooledPlanPage(plan.pooledPlanId)
                      }>
                      <ListItemAvatar
                        className={classes.globalSearchPooledPlanImg}>
                        <ContentCopyIcon id='global-search-pooled-plans-image' />
                      </ListItemAvatar>
                      <div
                        className={classes.globalSearchPooledPlanContainer}
                        id='global-search-pooled-plans-container'>
                        <div id='global-search-pooled-plans-wrapper'>
                          <ListItemText
                            id='global-search-pooled-plans-name'
                            primary={plan.pooledPlanName}
                          />
                          <Typography
                            className={classes.globalSearchPooledPlanField}
                            id='global-search-pooled-plans-type'>
                            Pooled Plan Type:{' '}
                            {plan.pooledPlanType.toUpperCase()}
                          </Typography>
                        </div>
                        <Typography
                          className={classes.globalSearchPooledPlanIdField}
                          id='global-search-pooled-plans-id'>
                          POOLED PLAN ID: {plan.pooledPlanId}
                        </Typography>
                      </div>
                    </ListItem>
                  );
                }
              )}
            </List>
          ) : (
            <p className={classes.noResults}>No results</p>
          )}
          {!globalSearchPooledPlanQuery.isLoading &&
            !!globalSearchPooledPlanQuery.data?.length &&
            globalSearchPooledPlanQuery.isPaginated && (
              <Button
                className={classes.globalSearchShowMoreBtn}
                disabled={globalSearchPooledPlanQuery.isPaginationLoading}
                id='global-search-pooled-plans-show-more-btn'
                onClick={globalSearchPooledPlanQuery.showMore}>
                SHOW MORE
              </Button>
            )}
        </div>
      )}
    </>
  );
};

export default GlobalSearchPooledPlansResult;
