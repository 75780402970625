import CircularLoading from '@/components/circular-loading';
import { useDialog } from '@/contexts/DialogContext';
import { DuplicateParticipantsDto } from '@/models/MergeParticipantsDto.model';
import { PlanService } from '@/services/Plan.service';
import SearchIcon from '@mui/icons-material/Search';
import {
  alpha,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Link,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { CardPlaceholder } from '../../../../../components/card';
import { EmployeeMatchesTable } from './EmployeeMatchesTable.component';

type EmployeeMatchesDialogProps = {
  participantData: Record<string, any>;
};

export const EmployeeMatchesDialog: FC<EmployeeMatchesDialogProps> = props => {
  const { closeDialog } = useDialog();
  const theme = useTheme();

  const duplicatesQuery = useQuery<DuplicateParticipantsDto[]>(
    [
      'PlanService.getParticipantDuplicates',
      props.participantData.sponsorPlanId,
      props.participantData.id
    ],
    () =>
      PlanService.getParticipantDuplicates(
        props.participantData.sponsorPlanId,
        props.participantData.id
      ),
    {
      staleTime: Infinity
    }
  );

  return (
    <div data-testid='employee-matches-dialog'>
      <DialogTitle>Merge employee accounts</DialogTitle>
      <Divider />
      <Box
        data-testid='main-employee-info-box'
        sx={{
          backgroundColor: alpha(theme.palette.primary.main, 0.1),
          padding: theme.spacing(3)
        }}>
        <Link
          component={RouterLink}
          data-testid='main-employee-info-name'
          to={`/participants/${props.participantData.id}/personal`}>
          {props.participantData.name}
        </Link>
        <Typography
          data-testid='main-employee-info-id-ssn'
          sx={{ fontSize: 12 }}>
          {`ID: ${props.participantData.id} | SSN: ${props.participantData.ssn}`}
        </Typography>
      </Box>
      <Stack gap={3}>
        <DialogContent>
          <DialogContentText sx={{ color: 'black', fontWeight: 'bold' }}>
            Potential matches
          </DialogContentText>
          {duplicatesQuery.isFetching ? (
            <Stack alignItems='center'>
              <CircularLoading />
              <Typography>Searching for match</Typography>
            </Stack>
          ) : !duplicatesQuery.data?.length ? (
            <CardPlaceholder
              data-testid='table-no-match-found'
              icon={<SearchIcon fontSize='inherit' />}
              subtitle='No match found'
            />
          ) : (
            <EmployeeMatchesTable
              duplicates={duplicatesQuery.data}
              originalParticipant={props.participantData}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            data-testid='employee-matches-cancel-btn'
            onClick={closeDialog}>
            Cancel
          </Button>
        </DialogActions>
      </Stack>
    </div>
  );
};

EmployeeMatchesDialog.displayName = 'EmployeeMatchesDialog';
