import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class ResidualFee {
  convertToApiState = Helper.convertValueFromNumericStringToNumber;

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.output = props.residualFee;
  }
}

export default ResidualFee;
