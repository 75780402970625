import Badge from '@/components/badge';
import DatePickerForm from '@/components/date-picker/DatePickerForm';
import {
  CommunicationFilters,
  initialCommunicationFilters
} from '@/models/PlanCommunicationsDTO.model';
import { PlanService } from '@/services/Plan.service';
import { ChevronLeft, Search } from '@mui/icons-material';
import CachedIcon from '@mui/icons-material/Cached';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  alpha,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';

import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import { isEqual } from 'lodash';
import React, { useState } from 'react';

import { EntityTypeValues } from './PlanCommunicationsTab.component';
import { StatusBadgeProps, statusLabels } from './utils';

interface PlanCommunicationFiltersProps {
  updateFilters: (filters: CommunicationFilters) => void;
  filters: CommunicationFilters;
  entity: string;
  planId: number;
  sponsorId?: number;
}

interface CommunicationFiltersForm {
  filter: string;
  category: string[];
  status: StatusBadgeProps[];
  startDate: string | null;
  endDate: string | null;
}

const PlanCommunicationFilters = (
  props: PlanCommunicationFiltersProps
): JSX.Element => {
  const { updateFilters, entity, planId, sponsorId, filters } = props;
  const [isSidePanelCollapsed, setSidePanelCollapsed] = useState(false);
  const theme = useTheme();

  const participantsCategoriesQuery = useQuery(
    ['PlanService.getPlanParticipantsEmailsCategories', planId],
    async () => PlanService.getPlanParticipantsEmailsCategories(planId),
    {
      enabled: Boolean(planId) && entity === EntityTypeValues.EMPLOYEES,
      refetchOnMount: false,
      staleTime: Infinity
    }
  );

  const sponsorCategoriesQuery = useQuery(
    ['PlanService.getPlanSponsorEmailsCategories', planId, sponsorId],
    async () => PlanService.getPlanSponsorEmailsCategories(planId, sponsorId),
    {
      enabled:
        Boolean(sponsorId) &&
        Boolean(planId) &&
        entity === EntityTypeValues.EMPLOYERS,
      refetchOnMount: false,
      staleTime: Infinity
    }
  );

  const category =
    entity === EntityTypeValues.EMPLOYEES
      ? participantsCategoriesQuery.data
      : sponsorCategoriesQuery.data;

  const handleSubmit = (values: CommunicationFiltersForm) => {
    const status = values.status.reduce((acc, curr) => {
      acc.push(...curr.values);
      return acc;
    }, [] as string[]);
    updateFilters({
      ...values,
      endDate: !values.endDate
        ? null
        : dayjs(values.endDate).add(1, 'day').format('MM/DD/YYYY'),
      filter: values.filter.trim(),
      order: filters.order,
      startDate: !values.startDate
        ? null
        : dayjs(values.startDate).format('MM/DD/YYYY'),
      status
    });
  };

  return (
    <Box sx={{ borderRight: `1px solid ${grey[300]}`, p: 2 }}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          pb: 2
        }}>
        {isSidePanelCollapsed ? (
          <IconButton
            aria-label='Show Filters'
            onClick={() => setSidePanelCollapsed(false)}>
            <FilterListIcon fontSize='inherit' />
          </IconButton>
        ) : (
          <>
            <Box sx={{ alignItems: 'center', display: 'flex', gap: 1.5 }}>
              <FilterListIcon
                fontSize='inherit'
                sx={{ fill: alpha('#000', 0.54) }}
              />
              <Typography variant='h6'>Filters</Typography>
            </Box>
            <IconButton
              aria-label='Hide Filters'
              onClick={() => setSidePanelCollapsed(true)}>
              <ChevronLeft fontSize='inherit' />
            </IconButton>
          </>
        )}
      </Box>
      <Collapse
        easing={theme.transitions.easing.sharp}
        in={!isSidePanelCollapsed}
        orientation='horizontal'>
        <Formik
          initialValues={initialCommunicationFilters}
          onSubmit={handleSubmit}>
          {({ setFieldValue, setFieldError, values, isValid, resetForm }) => {
            const areFiltersEmpty = isEqual(
              values,
              initialCommunicationFilters
            );

            return (
              <Form data-testid='filter-form'>
                <Box
                  sx={{
                    alignItems: 'flex-start',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    width: 272
                  }}>
                  <FormControl fullWidth>
                    <TextField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <Search />
                          </InputAdornment>
                        )
                      }}
                      data-testid='communications-search-bar-filter'
                      onChange={e => {
                        setFieldValue('filter', e.target.value);
                      }}
                      placeholder={
                        entity === EntityTypeValues.EMPLOYEES
                          ? 'Filter by Employee'
                          : 'Filter by Email'
                      }
                      size='small'
                      value={values.filter}
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <Autocomplete
                      data-testid='communications-category-filter'
                      disableCloseOnSelect
                      multiple
                      onChange={(_event, newValues) => {
                        setFieldValue('category', newValues);
                      }}
                      options={category || []}
                      renderInput={params => (
                        <TextField
                          {...params}
                          data-testid='communications-category-filter-input'
                          label='Category'
                          sx={{ p: 0 }}
                        />
                      )}
                      renderOption={(rootProps, option, { selected }) => (
                        <MenuItem
                          {...rootProps}
                          data-value={option}
                          style={{ whiteSpace: 'normal' }}>
                          <Checkbox checked={selected} sx={{ py: 0 }} />
                          {option}
                        </MenuItem>
                      )}
                      size='small'
                      value={values.category}
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <Autocomplete
                      data-testid='communications-status-filter'
                      disableCloseOnSelect
                      getOptionLabel={option => option.label}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                      }
                      multiple
                      onChange={(_event, newValues) => {
                        setFieldValue('status', newValues);
                      }}
                      options={statusLabels}
                      renderInput={params => {
                        return (
                          <TextField
                            {...params}
                            data-testid='communications-status-filter-input'
                            label='Status'
                            sx={{
                              p: 0
                            }}
                          />
                        );
                      }}
                      renderOption={(rootProps, option, { selected }) => {
                        return (
                          <MenuItem {...rootProps} data-value={option.label}>
                            <Checkbox
                              checked={selected}
                              sx={{ pl: 0, py: 0 }}
                            />
                            {option.label}
                          </MenuItem>
                        );
                      }}
                      renderTags={tagValue => {
                        return tagValue.map(option => {
                          return (
                            <Box
                              data-testid={`filter_status_badge_${option.label}`}
                              key={option.label}
                              sx={{ mb: 0.5, mr: 0.5 }}>
                              <Badge
                                color={option.color}
                                deleteable
                                handleClick={() => {
                                  const newValues = values.status.filter(
                                    (badge: StatusBadgeProps) =>
                                      badge.label !== option.label
                                  );
                                  setFieldValue('status', newValues);
                                }}>
                                {option.label}
                              </Badge>
                            </Box>
                          );
                        });
                      }}
                      size='small'
                      value={values.status}
                    />
                  </FormControl>

                  <Divider style={{ width: '100%' }} textAlign='left'>
                    Create Date
                  </Divider>

                  <FormControl fullWidth>
                    <DatePickerForm
                      data-testid='communications-create-date-from-filter'
                      disableFuture
                      format='MM/DD/YYYY'
                      fullWidth
                      handleError={error => {
                        if (error === 'invalidDate') {
                          setFieldError('startDate', '');
                        }
                      }}
                      inputProps={{
                        autoComplete: 'off'
                      }}
                      label='From'
                      name='startDate'
                      size='small'
                      value={values.startDate}
                      variant='outlined'
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <DatePickerForm
                      data-testid='communications-create-date-to-filter'
                      disableFuture
                      format='MM/DD/YYYY'
                      fullWidth
                      handleError={error => {
                        if (error === 'invalidDate') {
                          setFieldError('endDate', '');
                        }
                      }}
                      inputProps={{
                        autoComplete: 'off'
                      }}
                      label='To'
                      minDate={dayjs(values.startDate)}
                      name='endDate'
                      size='small'
                      value={values.endDate}
                      variant='outlined'
                    />
                  </FormControl>

                  <Box
                    sx={{
                      alignCenter: 'center',
                      display: 'flex',
                      gap: 2,
                      justifyContent: 'space-between',
                      width: '100%'
                    }}>
                    <Button
                      data-testid='submit'
                      disabled={!isValid}
                      type='submit'
                      variant='outlined'>
                      Apply
                    </Button>
                    {!areFiltersEmpty && (
                      <Button
                        data-testid='reset'
                        onClick={() => {
                          resetForm();
                          updateFilters(initialCommunicationFilters);
                        }}
                        startIcon={<CachedIcon />}
                        variant='text'>
                        Reset
                      </Button>
                    )}
                  </Box>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Collapse>
    </Box>
  );
};

export default PlanCommunicationFilters;
