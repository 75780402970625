import VisibilityToggle from '@/components/visibility-toggle';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import ParticipantInfo from '@/models/ParticipantInfo.model';
import { PlanV2 } from '@/models/PlanV2DTO.model';
import formatters from '@/utils/Formatters';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  Button,
  Card,
  CardContent,
  Theme,
  Typography,
  useTheme
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { formatSsn } from '@vestwell-frontend/helpers';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export interface ParticipantInfoCardProps {
  participant: Pick<
    ParticipantInfo,
    | 'participantId'
    | 'firstName'
    | 'middleName'
    | 'lastName'
    | 'ssn'
    | 'birthDate'
    | 'email'
    | 'userEmail'
    | 'eligibilityStatus'
    | 'phoneNumber'
  >;
  sponsorPlan: PlanV2;
  custodianNumber?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  arrowIcon: {
    fontSize: '1rem',
    marginLeft: '0.4rem'
  },
  border: {
    borderBottom: '1px solid lightgray',
    width: '100%'
  },
  contentBody: {
    paddingBottom: 0,
    paddingTop: 0
  },
  detailButton: {
    fontSize: '0.875rem',
    paddingBottom: '15px'
  },
  mutedText: {
    color: theme.palette.text.secondary
  },
  rowContainer: {
    display: 'flex'
  },
  tableContent: {
    display: 'flex',
    padding: '0'
  },
  titleText: {
    padding: '20px 0'
  },
  wrapper: {
    marginLeft: '30px'
  }
}));

const ParticipantInfoCard: React.FunctionComponent<ParticipantInfoCardProps> = (
  props: ParticipantInfoCardProps
) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme<Theme>();

  const [isMaskActive, setIsMaskActive] = useState(true);

  const { participant, sponsorPlan, custodianNumber } = props;

  const formattedSSN = participant.ssn
    ? formatSsn(participant.ssn)
    : EMPTY_FIELD_PLACEHOLDER;

  const maskedSSN = participant.ssn
    ? formatters.maskSSN(formattedSSN)
    : EMPTY_FIELD_PLACEHOLDER;

  const labelStyle = {
    color: 'rgba(0, 0 , 0, 0.6 )',
    fontSize: '0.875rem',
    padding: '7px'
  };

  const valueStyle = {
    color: theme.palette.text.primary,
    fontSize: '0.875rem',
    marginLeft: '1rem',
    padding: '7px 0'
  };

  return (
    <div
      className={classes.wrapper}
      data-testid='participant-identification-card'>
      <Card elevation={0} variant='outlined'>
        <CardContent className={classes.contentBody}>
          <div>
            <Typography
              sx={{
                padding: '20px 0'
              }}
              variant='h5'>
              Participant Info
            </Typography>
            <Typography variant='subtitle1'>
              {participant.firstName} {participant.lastName}
            </Typography>
            <Typography
              className={classes.mutedText}
              pb='15px'
              variant='subtitle1'>
              ID: {participant.participantId}
            </Typography>
          </div>
          <div className={classes.tableContent}>
            <div>
              <Typography sx={labelStyle} variant='body2'>
                SSN
              </Typography>
              <Typography sx={labelStyle} variant='body2'>
                Date of Birth
              </Typography>
              <Typography sx={labelStyle} variant='body2'>
                Eligibility Status
              </Typography>
            </div>
            <div>
              <Typography
                sx={{
                  color: theme.palette.text.primary,
                  fontSize: '0.875rem',
                  marginLeft: '1rem',
                  padding: '0'
                }}
                variant='body2'>
                {isMaskActive ? maskedSSN : formattedSSN}
                <VisibilityToggle
                  isMasked={isMaskActive}
                  toggleMask={() => setIsMaskActive(!isMaskActive)}
                />
              </Typography>
              <Typography sx={valueStyle} variant='body2'>
                {participant.birthDate || EMPTY_FIELD_PLACEHOLDER}
              </Typography>
              <Typography sx={valueStyle} variant='body2'>
                {participant.eligibilityStatus || EMPTY_FIELD_PLACEHOLDER}
              </Typography>
            </div>
          </div>
          <Button
            className={classes.detailButton}
            data-testid='personal-info-btn'
            onClick={() =>
              navigate(`/participants/${participant.participantId}/personal`)
            }>
            PERSONAL INFO <ArrowForwardIcon className={classes.arrowIcon} />
          </Button>
          <div className={classes.border} />
          <div>
            <Typography mt='15px' variant='subtitle1'>
              {sponsorPlan?.attributes?.name || EMPTY_FIELD_PLACEHOLDER}
            </Typography>
            <Typography
              className={classes.mutedText}
              pb='15px'
              variant='subtitle1'>
              ID: {sponsorPlan?.id?.toString() || EMPTY_FIELD_PLACEHOLDER}
            </Typography>
          </div>
          <div className={classes.tableContent}>
            <div>
              <Typography sx={labelStyle} variant='body2'>
                Sponsor Name
              </Typography>
              <Typography sx={labelStyle} variant='body2'>
                Custodian Account Number
              </Typography>
            </div>
            <div>
              <Typography sx={valueStyle} variant='body2'>
                {sponsorPlan?.attributes?.name || EMPTY_FIELD_PLACEHOLDER}
              </Typography>
              <Typography sx={valueStyle} variant='body2'>
                {custodianNumber || EMPTY_FIELD_PLACEHOLDER}
              </Typography>
            </div>
          </div>
          <Button
            className={classes.detailButton}
            data-testid='personal-info-btn'
            onClick={() => navigate(`/plans/${sponsorPlan.id}`)}>
            PLAN INFO <ArrowForwardIcon className={classes.arrowIcon} />
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default ParticipantInfoCard;
