import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps
} from '@mui/material';

import { Field, ErrorMessage as FormikErrorMessage, FormikProps } from 'formik';
import React from 'react';

type SimpleRadioOption =
  | string
  | {
      value: string;
      label: string;
    };

export interface SimpleRadioProps {
  fieldName: string;
  fieldId: string;
  fieldValues: SimpleRadioOption[];
  defaultValue?: string;
  onChange?: (e: { target: { value: string } }) => void;
  required?: boolean;
  row?: RadioGroupProps['row'];
}

const SimpleRadio = (props: SimpleRadioProps): JSX.Element => {
  const {
    fieldId,
    fieldName,
    fieldValues: unsafeOptions,
    onChange,
    defaultValue,
    required,
    row
  } = props;

  const options = unsafeOptions.map(opt =>
    typeof opt === 'string' ? { label: opt, value: opt } : opt
  );

  return (
    <FormControl component='fieldset' key={fieldId} required={required}>
      <Field name={fieldId}>
        {(fieldProps: { form: FormikProps<any> }) => {
          return (
            <>
              {fieldProps.form.touched[fieldId] &&
              fieldProps.form.errors[fieldId] ? (
                <FormHelperText component={FormLabel} error>
                  {fieldName}
                </FormHelperText>
              ) : (
                <FormLabel component='legend'>{fieldName}</FormLabel>
              )}
              <RadioGroup
                key={fieldId}
                row={row}
                {...(defaultValue ? { defaultValue } : {})}>
                {options.map(option => (
                  <FormControlLabel
                    control={
                      <Radio
                        onChange={
                          onChange ||
                          (event => {
                            const { value } = event.target;
                            return fieldProps.form.setFieldValue(
                              fieldId,
                              value
                            );
                          })
                        }
                      />
                    }
                    key={option.value}
                    label={option.label}
                    value={option.value}
                  />
                ))}
              </RadioGroup>
            </>
          );
        }}
      </Field>
      <FormikErrorMessage name={fieldId}>
        {msg => <FormHelperText error>{msg}</FormHelperText>}
      </FormikErrorMessage>
    </FormControl>
  );
};

export default SimpleRadio;
