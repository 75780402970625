import { useSnackbar } from '@/contexts/SnackBarContext';
import ContributionForTable from '@/models/ContributionForTable.model';
import ContributionService from '@/services/Contribution.service';
import { PlanService } from '@/services/Plan.service';
import formatters from '@/utils/Formatters';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';

import React, { FC, useCallback, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import * as yup from 'yup';

type SkipSubmissionProps = {
  isOpen: boolean;
  onClose: () => void;
  data: {
    sponsorPlanId: number;
    sponsorId: number;
    actualPayDate: string;
    division: string;
  };
};

const schema = yup.object().shape({
  otherReason: yup.string().when('reason', {
    is: (value: string) => value === 'Other',
    then: yup.string().required('Required')
  }),
  reason: yup.string().required('Required')
});

export const SkipSubmission: FC<SkipSubmissionProps> = props => {
  const [reason, setReason] = useState('Sponsor did not run the payroll');
  const [otherReason, setOtherReason] = useState('');
  const snackbar = useSnackbar();

  const skipSubmission = useMutation(
    (data: {
      sponsorPlanId: number;
      sponsorId: number;
      actualPayDate: string;
      skippingReason: string;
      division: string;
    }) => ContributionService.putSkipSubmission(data)
  );

  const contributionsQuery = useQuery<ContributionForTable[]>(
    ['PlanService.getContributions', props.data?.sponsorPlanId],
    async () => {
      const contributionsResponse: ContributionForTable[] =
        await PlanService.getContributions(props.data?.sponsorPlanId);
      return formatters
        .formatContributionsResponse(contributionsResponse)
        ?.filter(contribution => !contribution.parentUcid);
    },
    {
      enabled: false
    }
  );

  const isValid = useMemo(
    () => schema.isValidSync({ otherReason, reason }),
    [reason, otherReason]
  );

  const onReasonChange = useCallback((event, value) => setReason(value), []);

  const onOtherReasonChange = useCallback(
    event => setOtherReason(event.target?.value),
    []
  );

  const onSubmit = useCallback(() => {
    skipSubmission.mutateAsync({
      ...props.data,
      skippingReason: reason === 'Other' ? otherReason : reason
    });

    props.onClose();
  }, [reason, otherReason, props, skipSubmission]);

  useUpdateEffect(() => {
    if (!skipSubmission.isSuccess) return;

    contributionsQuery.refetch();

    snackbar.showSnackbar({
      message: 'The submission was successfully skipped',
      severity: 'success'
    });
  }, [skipSubmission.isSuccess]);

  useUpdateEffect(() => {
    if (!skipSubmission.isError) return;

    snackbar.showSnackbar({
      message: 'Something went wrong',
      severity: 'error'
    });
  }, [skipSubmission.isError]);

  return (
    <Dialog
      data-testid='skipSubmission'
      fullWidth
      maxWidth='sm'
      onClose={props.onClose}
      open={props.isOpen}>
      <DialogTitle data-testid='skipSubmission-title'>
        Skip {props.data?.actualPayDate} Contribution?
      </DialogTitle>
      <DialogContent sx={{ marginBottom: 2 }}>
        <DialogContentText>Select a reason:</DialogContentText>
        <RadioGroup
          data-testid='skipSubmission-radio'
          onChange={onReasonChange}
          value={reason}>
          <FormControlLabel
            control={<Radio />}
            data-testid='skipSubmission-radio-option'
            label='Sponsor did not run the payroll'
            value='Sponsor did not run the payroll'
          />
          <FormControlLabel
            control={<Radio />}
            data-testid='skipSubmission-radio-option'
            label='Employer requested'
            value='Employer requested'
          />
          <FormControlLabel
            control={<Radio />}
            data-testid='skipSubmission-radio-option'
            label='Uploaded via off cycle'
            value='Uploaded via off cycle'
          />
          <FormControlLabel
            control={<Radio />}
            data-testid='skipSubmission-radio-option'
            label='Other'
            value='Other'
          />
        </RadioGroup>
        {reason === 'Other' && (
          <TextField
            data-testid='skipSubmission-otherReason-input'
            fullWidth
            onChange={onOtherReasonChange}
            placeholder='Reason'
            value={otherReason}
            variant='standard'
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button data-testid='skipSubmission-cancel' onClick={props.onClose}>
          Cancel
        </Button>
        <Button
          data-testid='skipSubmission-submit'
          disabled={!isValid}
          onClick={onSubmit}
          variant='contained'>
          Skip Contribution
        </Button>
      </DialogActions>
    </Dialog>
  );
};
