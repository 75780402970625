import { useSnackbar } from '@/contexts/SnackBarContext';
import { useParticipantSuspiciousActivity } from '@/hooks/useParticipantSuspiciousActivity.hook';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import ParticipantService from '@/services/Participant.service';
import { Alert, Button, Grid, Stack, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useCallback } from 'react';

import AccessControl from '../../../components/access-control/AccessControl.component';

type BannerProps = {
  participantId: string;
};

export function ParticipantAccountWarningBanner(props: BannerProps) {
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const suspicious = useParticipantSuspiciousActivity(props.participantId, {
    suspense: true,
    useErrorBoundary: false
  });

  const updateSuspiciousActivity = useMutation(
    ['ParticipantService.putSuspiciousActivity', props.participantId],
    (suspicious: boolean) =>
      ParticipantService.putSuspiciousActivity(
        +props.participantId,
        suspicious
      ),
    {
      onError: () => {
        showSnackbar({
          message: 'Failed to update participant.',
          severity: 'error'
        });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          'ParticipantService.getSuspiciousActivity',
          props.participantId
        ]);
        showSnackbar({
          message: 'participant updated',
          severity: 'success'
        });
      }
    }
  );

  const onToggleActivate = useCallback(async () => {
    await updateSuspiciousActivity.mutateAsync(
      !suspicious.data?.hasSuspiciousActivity
    );
  }, [suspicious.data]);

  return (
    <Grid container>
      {suspicious.error && (
        <Grid item xs={12}>
          <Alert severity='error'>
            {suspicious.error?.message ?? 'Unknown Error Occurred'}
          </Alert>
        </Grid>
      )}
      <Grid item xs={2}>
        <Typography variant='body2'>Display Warning Banner</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography
          color={suspicious.data?.hasSuspiciousActivity ? 'red' : 'black'}
          variant='body1'>
          {suspicious.data?.hasSuspiciousActivity ? 'Active' : 'Inactive'}
        </Typography>
        <Typography color='gray' variant='caption'>
          Block Withdrawal / Loan processing & notify others of suspicious
          activity in this account
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Stack>
          <AccessControl
            requires={[FeatureLevelPermissions.WRITE_ACCOUNT_HOLD]}>
            <Button onClick={onToggleActivate} variant='text'>
              {suspicious.data?.hasSuspiciousActivity
                ? 'DEACTIVATE'
                : 'ACTIVATE'}
            </Button>
          </AccessControl>
        </Stack>
      </Grid>
    </Grid>
  );
}
