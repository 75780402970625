export const FundLineupFields = {
  BROAD_ASSET_CLASS: 'broadAssetClass' as const,
  EXPENSE_RATIO: 'expenseRatio' as const,
  SECURITY: 'security' as const
};

export interface FundLineupRow {
  maxTickerLength: number;
  id: string;
  [FundLineupFields.SECURITY]: {
    id: string;
    cusip: string;
    fundName: string;
  };
  [FundLineupFields.BROAD_ASSET_CLASS]: string;
  [FundLineupFields.EXPENSE_RATIO]: string;
}
