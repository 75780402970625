import useHasPermissions from '@/components/access-control/useHasPermissions.hook';
import Card, {
  CardContent,
  CardHeader,
  CardMainActionButtonProps
} from '@/components/card/Card.component';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import type { TickerLookupSearchResult } from '@/routes/ops/investments/investment-table/TickerSelect.component';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useQuery } from '@tanstack/react-query';

import { FormikHelpers, useFormik } from 'formik';
import { some, toUpper } from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';
import { unstable_usePrompt } from 'react-router-dom';
import { useBeforeUnload, useFullscreen, useToggle } from 'react-use';
import * as yup from 'yup';

import { RiskSeriesModel } from '../RiskSeries.component';
import { TargetSeriesModel } from '../TargetSeries.component';
import { InvestmentOptionTypes } from './InvestmentConstants';
import ModelProgramsModal from './ModelProgramsModal.component';
import ModelSeriesModal from './ModelSeriesImportModal.component';
import { RebalanceDialog } from './rebalance/RebalanceDialog.component';

export type ModelEditDetailViewProps = {
  content: JSX.Element;
  modelSeriesType: InvestmentOptionTypes;
  onSaveModel: () => any;
  accountId?: number;
  dirty: boolean;
  isSubmitting: boolean;
  isValid: boolean;
  errors: any;
  values: {
    name?: string;
    description?: string;
    allocations?: any[];
    fundLineupFunds?: any[];
    models?: (TargetSeriesModel | RiskSeriesModel)[];
    riskSeriesId?: number;
    targetSeriesId?: number;
  };
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  lookupTickerCallback?: (
    ticker: string,
    numberOfResults: number
  ) => Promise<TickerLookupSearchResult[]>;
  bulkField?: 'allocations' | 'fundLineupFunds';
  is5Year?: boolean;
  toggle5Year?: (value: boolean) => void;
  readonly: boolean;
  programCount?: number;
  investmentOptionId: number;
};

type SubmitValues = {
  tickers: string[];
};

const validationSchema = yup.object().shape({
  tickers: yup
    .array()
    .of(yup.string())
    .min(1, 'Required')
    .test(
      'Unique',
      'Duplicate tickers found',
      values => new Set(values).size === values.length
    )
});

const ModelEditDetailView = (props: ModelEditDetailViewProps): JSX.Element => {
  const {
    accountId,
    content,
    modelSeriesType,
    onSaveModel,
    dirty,
    isSubmitting,
    isValid,
    errors,
    setFieldValue,
    values,
    lookupTickerCallback,
    bulkField = 'allocations',
    is5Year,
    toggle5Year,
    programCount,
    investmentOptionId
  } = props;

  const [isModalOpen, toggleModalOpen] = useToggle(false);

  const fullscreenRef = useRef<HTMLDivElement>(null);
  const [isBulkModalOpen, toggleBulkModal] = useToggle(false);
  const [bulkTickers, setBulkTickers] = useState('');

  const [isRebalanceModalOpen, toggleRebalanceModal] = useToggle(false);
  const permissions = useHasPermissions({
    requires: [FeatureLevelPermissions.WRITE_INVESTMENTS_MODIFY]
  });

  const [show, toggle] = useToggle(false);
  const [openModelImport, toggleModelImport] = useToggle(false);

  const isSessionTimerDialogOpenQuery = useQuery(['isSessionTimerDialogOpen'], {
    initialData: false
  });

  const isFullscreen = useFullscreen(fullscreenRef, show, {
    onClose: () => toggle(false)
  });

  const snackbar = useSnackbar();

  const handleSubmit = useCallback(
    async (
      formValues: SubmitValues,
      formikHelpers: FormikHelpers<SubmitValues>
    ) => {
      const existingTickers: string[] = [];
      const lookupTickers: string[] = [];
      const notFoundTickers: string[] = [];
      const foundTickers: TickerLookupSearchResult[] = [];

      formValues.tickers.forEach((ticker: string) => {
        const existingTicker = values[bulkField]?.find(
          (allocation: { symbol?: string }) => allocation.symbol === ticker
        );

        if (existingTicker) {
          existingTickers.push(ticker);
        } else {
          lookupTickers.push(ticker);
        }
      });

      await Promise.all(
        lookupTickers.map((ticker: string) => {
          return lookupTickerCallback?.(ticker, 1).then(results => {
            if (results.length) {
              const foundTicker = results.find(
                result => result.symbol === ticker
              );

              if (foundTicker) {
                foundTickers.push(foundTicker);
              } else {
                notFoundTickers.push(ticker);
              }
            } else {
              notFoundTickers.push(ticker);
            }
          });
        })
      );

      if (notFoundTickers.length) {
        formikHelpers.setErrors({
          tickers: `${notFoundTickers.join(', ')} cannot be found.`
        });
        return;
      }

      toggleBulkModal(false);
      toggleRebalanceModal(false);
      setFieldValue(bulkField, [...(values[bulkField] || []), ...foundTickers]);

      let message = '';

      if (foundTickers.length) {
        message += `Added ${foundTickers.map(e => e.symbol).join(', ')}. `;
      }

      if (existingTickers.length) {
        message += `Skipped ${existingTickers.join(', ')}. `;
      }

      snackbar.showSnackbar({
        message,
        severity: foundTickers.length ? 'success' : 'error'
      });
    },
    [
      values,
      setFieldValue,
      lookupTickerCallback,
      toggleBulkModal,
      toggleRebalanceModal,
      snackbar,
      bulkField
    ]
  );

  const bulkForm = useFormik({
    initialValues: {
      tickers: []
    },
    onSubmit: handleSubmit,
    validationSchema
  });

  const onBulkModalClose = useCallback(() => {
    toggleBulkModal(false);
    bulkForm.resetForm();
  }, [toggleBulkModal, bulkForm]);

  const dirtyFn = useCallback(() => {
    return dirty && !isSessionTimerDialogOpenQuery.data;
  }, [dirty]);

  useBeforeUnload(dirtyFn, 'You have unsaved changes, are you sure?');

  const buttonEnabled = useMemo(() => {
    return dirty && isValid && !isSubmitting;
  }, [dirty, isValid, isSubmitting]);

  const subtitleError = useMemo(() => {
    if (
      typeof errors.goalFunds === 'object' &&
      errors.goalFunds !== undefined
    ) {
      return 'Missing Fund';
    }

    if (errors.fundLineupFunds && typeof errors.fundLineupFunds === 'string') {
      return errors.fundLineupFunds;
    }

    if (errors.models) {
      if (typeof errors.models === 'string') {
        return errors.models;
      }

      if (typeof errors.models === 'object' && errors.models !== undefined) {
        let message = '';
        if (
          some(
            errors.models,
            (error: { modelName?: string }) => error?.modelName
          )
        ) {
          message = 'All Funds Must Have A Name';
        }

        if (
          some(
            errors.models,
            (error: {
              targetRetireYearLow?: string;
              targetRetireYearHigh?: string;
            }) =>
              (error?.targetRetireYearLow || error?.targetRetireYearHigh) &&
              error?.targetRetireYearHigh !==
                'Duplicate and overlapping target years.'
          )
        ) {
          if (message.length) {
            message += ' and Valid Target Years';
          } else {
            message = 'All Funds Must Have Valid Target Years';
          }
        }

        if (
          some(
            errors.models,
            (error: { targetRetireYearHigh?: string }) =>
              error?.targetRetireYearHigh ===
              'Duplicate and overlapping target years.'
          )
        ) {
          if (message.length) {
            message += ' and Invalid years entered';
          } else {
            message = 'Invalid years entered';
          }
        }
        return message;
      }
    }
    return undefined;
  }, [errors]);

  const modelType = useMemo(() => {
    switch (modelSeriesType) {
      case 'Goal':
        return 'goal';
      case 'Fund Lineup':
        return 'fundLineup';
      case 'Target':
        return 'target';
      default:
        return 'risk';
    }
  }, [modelSeriesType]);

  const actionButtonsProps: CardMainActionButtonProps[] = useMemo(() => {
    const buttonsProps: Array<CardMainActionButtonProps> = [
      {
        label: (
          <>
            <span className='card-header__action-button-copy'>
              {isFullscreen
                ? 'Exit No Distraction Mode '
                : 'No Distraction Mode '}
            </span>
            {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </>
        ),
        onClick: toggle,
        variant: 'text'
      }
    ];
    if (!isFullscreen && values.models?.length === 0)
      buttonsProps.unshift({
        label: (
          <>
            <span className='card-header__action-button-copy'>
              Import Model Series
            </span>
          </>
        ),
        onClick: toggleModelImport,
        variant: 'text'
      });
    return buttonsProps;
  }, [values, isFullscreen, toggle, toggleModelImport]);

  const importModelSeries = (
    models: Record<string, any>[],
    allocations: Record<string, any>[]
  ) => {
    setFieldValue('models', models);
    setFieldValue('allocations', allocations);
  };

  unstable_usePrompt({
    message: 'Choose cancel to avoid losing the unsaved changes.',
    when: dirty
  });

  const onBulkTickersChange = useCallback(event => {
    const tickers = event.target.value
      .split('\n')
      .map((ticker: string) => toUpper(ticker.trim()))
      .filter((ticker: string) => ticker.length > 0);

    setBulkTickers(event.target.value);
    bulkForm.setFieldValue('tickers', tickers);
  }, []);

  return (
    <>
      <Modal onClose={() => toggleModalOpen(false)} open={isModalOpen}>
        <Box>
          <ModelProgramsModal
            handleCloseModal={() => toggleModalOpen(false)}
            investmentOptionId={investmentOptionId}
            modalTitle={'Programs with '.concat(values.name || 'Unnamed')}
            modelType={modelType}
          />
        </Box>
      </Modal>
      <Modal onClose={() => toggleModelImport(false)} open={openModelImport}>
        <Box>
          <ModelSeriesModal
            handleCloseModal={() => toggleModelImport(false)}
            importModelSeriesCallback={importModelSeries}
            investmentId={values.riskSeriesId || values.targetSeriesId || 0}
            type={values.riskSeriesId ? 'risk' : 'target'}
          />
        </Box>
      </Modal>
      <Dialog
        onClose={() => (bulkForm.isSubmitting ? null : onBulkModalClose())}
        open={isBulkModalOpen}>
        <DialogTitle>Add Bulk Tickers</DialogTitle>

        <DialogContent>
          <DialogContentText mb={4}>
            Paste a group of tickers from a spreadsheet. Each ticker should be
            on a separate line.
          </DialogContentText>
          <form onSubmit={bulkForm.handleSubmit}>
            <TextField
              autoFocus
              disabled={bulkForm.isSubmitting}
              error={!!bulkForm.errors.tickers}
              fullWidth
              helperText={bulkForm.errors.tickers}
              label='Tickers'
              multiline
              name='tickers'
              onChange={onBulkTickersChange}
              rows={5}
              sx={{ mb: 4 }}
              value={bulkTickers}
            />
            <DialogActions>
              <Button
                disabled={bulkForm.isSubmitting}
                onClick={onBulkModalClose}>
                Cancel
              </Button>
              <Button
                color='primary'
                disabled={
                  !bulkForm.isValid ||
                  bulkForm.isSubmitting ||
                  !bulkForm.values.tickers
                }
                type='submit'
                variant='contained'>
                {bulkForm.isSubmitting ? 'Adding...' : 'Add'}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      {['target', 'risk'].includes(modelType) && (
        <RebalanceDialog
          dirty={dirty}
          isRebalanceModalOpen={isRebalanceModalOpen}
          modelType={modelType === 'target' ? 'target' : 'risk'}
          models={
            values.models as typeof modelType extends 'target'
              ? TargetSeriesModel[]
              : RiskSeriesModel[]
          }
          toggleRebalanceModal={toggleRebalanceModal}
        />
      )}
      <Box
        p={0}
        ref={fullscreenRef}
        sx={{
          backgroundColor: 'white'
        }}>
        <Box mb={2.5}>
          <TextStack
            direction='column'
            sx={{
              li: {
                '&:last-of-type': { alignItems: 'end' },
                minWidth: '50%',
                width: '50%'
              },
              marginTop: 0,
              padding: 0,
              width: '100%'
            }}>
            <TextStackItem>
              <TextValue>
                <Typography data-testid='investmentOptionName' variant='h4'>
                  {values.name}
                </Typography>
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextValue>
                <Button
                  disabled={!buttonEnabled || props.readonly}
                  onClick={onSaveModel}
                  variant='contained'>
                  {`Sav${isSubmitting ? 'ing...' : 'e'}`}
                </Button>
              </TextValue>
            </TextStackItem>
          </TextStack>
        </Box>
      </Box>
      <Box mb={5}>
        <Card>
          <CardHeader title='Details' />
          <CardContent>
            <TextStack
              sx={{
                li: { width: '20.625rem' },
                margin: 0,
                marginBottom: 3,
                padding: 0
              }}>
              <TextStackItem>
                <TextField
                  data-testid='editInvestmentOptionName'
                  defaultValue={values.name || ''}
                  disabled={props.readonly}
                  error={!!errors.name}
                  helperText={errors.name}
                  label='Name'
                  onChange={event => setFieldValue('name', event.target.value)}
                  required
                  size='small'
                  sx={{ width: '100%' }}
                  title={values.name || 'Add Name'}
                />
              </TextStackItem>
              <TextStackItem>
                <TextField
                  data-testid='editInvestmentOptionDescription'
                  defaultValue={values.description || ''}
                  disabled={props.readonly}
                  label='Description'
                  onChange={event =>
                    setFieldValue('description', event.target.value)
                  }
                  placeholder='Add Description'
                  size='small'
                  sx={{ width: '100%' }}
                  title={values.description || 'Add Description'}
                />
              </TextStackItem>
              <>
                {accountId && (
                  <TextStackItem>
                    <TextField
                      defaultValue={accountId}
                      disabled
                      label='Account'
                      size='small'
                      sx={{ width: '100%' }}
                    />
                  </TextStackItem>
                )}
              </>
              <>
                {toggle5Year && !props.readonly && (
                  <TextStackItem>
                    <FormControl sx={{ width: '100%' }}>
                      <InputLabel id='target-years-breakdown-label'>
                        Target Years Breakdown
                      </InputLabel>
                      <Select
                        label='Target Years Breakdown'
                        labelId='target-years-breakdown-label'
                        onChange={event => {
                          const value = event.target.value as number;
                          toggle5Year(value === 5);
                        }}
                        size='small'
                        sx={{ width: '100%' }}
                        value={is5Year ? 5 : 10}>
                        <MenuItem value={5}>5 Years</MenuItem>
                        <MenuItem value={10}>10 Years</MenuItem>
                      </Select>
                    </FormControl>
                  </TextStackItem>
                )}
              </>
            </TextStack>
            <TextStack
              sx={{
                li: { width: '20.625rem' },
                margin: 0,
                marginBottom: 3,
                padding: 0
              }}>
              <TextStackItem>
                <TextField
                  disabled
                  label='Owner'
                  size='small'
                  sx={{ width: '100%' }}
                  value='Vestwell'
                />
              </TextStackItem>
              <TextStackItem>
                <TextField
                  disabled
                  label='Type'
                  size='small'
                  sx={{ width: '100%' }}
                  value={modelSeriesType}
                />
              </TextStackItem>
            </TextStack>
            <TextStack>
              <TextStackItem>
                <TextLabel>Programs</TextLabel>
                <TextValue>
                  {!!programCount && programCount > 0 ? (
                    <Link
                      component='button'
                      data-testid='modal-program-link'
                      onClick={() => toggleModalOpen(true)}
                      underline='hover'>
                      {programCount}
                    </Link>
                  ) : (
                    <Typography color='textSecondary' variant='body2'>
                      0
                    </Typography>
                  )}
                </TextValue>
              </TextStackItem>
            </TextStack>
          </CardContent>
        </Card>
      </Box>
      <Box
        ref={fullscreenRef}
        sx={{ '.card-header__action-button-copy': { marginRight: 1 } }}>
        <Card
          className='card-header'
          size='small'
          sx={{
            '.ag-cell-inline-editing .ag-cell-wrapper': { height: 0, width: 0 },
            '.ag-cell-inline-editing input[type="number"]': {
              display: 'flex',
              py: 1,
              width: '100%'
            },
            '.card-header': {
              backgroundColor: 'white',
              h6: {
                color: 'red'
              },
              position: isFullscreen ? 'sticky' : 'relative',
              top: 0,

              zIndex: 99
            },
            maxHeight: isFullscreen ? '100vh' : 'auto',
            minHeight: isFullscreen ? '100vh' : 'auto',
            overflow: 'scroll'
          }}>
          <CardHeader
            actionButtonsProps={actionButtonsProps}
            className='card-header'
            menuProps={
              lookupTickerCallback
                ? {
                    onClick: (value: string) => {
                      switch (value) {
                        case 'bulk-add':
                          toggleBulkModal(true);
                          break;
                        case 'bulk-rebalance':
                          toggleRebalanceModal(true);
                          break;
                      }
                    },
                    options: [
                      { label: 'Add Bulk Tickers', value: 'bulk-add' },
                      ['target', 'risk'].includes(modelType) &&
                        permissions.isAllowed && {
                          label: 'Rebalance',
                          value: 'bulk-rebalance'
                        }
                    ].filter(Boolean)
                  }
                : undefined
            }
            subtitle={subtitleError}
            title='Available Funds'
          />
          {content}
        </Card>
      </Box>
    </>
  );
};

ModelEditDetailView.defaultProps = {
  readonly: false
};

export default ModelEditDetailView;
