import { PlanDesignDto } from '@/models';
import {
  ScheduledChange,
  ScheduledChangePayloadItem,
  ScheduledChangesDTO
} from '@/models/ScheduledChangesDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { CompanyInfoTabData } from '@/routes/plans/plan-detail/PlanCompanyInfoCard/CompanyInfoTab.component';
import * as PlanCompanyInfoCardUtils from '@/routes/plans/plan-detail/PlanCompanyInfoCard/utils';
import * as PlanTabUtils from '@/routes/plans/plan-detail/PlanTab/utils';
import EditPlanValidations from '@/routes/plans/plan-detail/PlanTab/validations';
import formatters from '@/utils/Formatters';
import makeValidationSchema from '@/utils/validations/CompanyInfoValidationSchema.schema';

import * as yup from 'yup';
import { AnyObjectSchema } from 'yup';

export type CreateScheduledChangePayloadConfig = {
  planDesignData: PlanDesignDto;
  companyInfoTabData: CompanyInfoTabData;
  isVoyaPlan: boolean;
  isStateIRA: boolean;
};

type PayloadWithScheduledChanges = (ScheduledChangePayloadItem & {
  scheduledChanges: {
    uiCurrentValue: any;
    uiScheduledValue: any;
    scheduledFor: string;
  }[];
})[];

export const permissionsByChangeType = {
  company: FeatureLevelPermissions.WRITE_COMPANY_COMPANY_INFO,
  plan_design: FeatureLevelPermissions.WRITE_PLAN_DESIGN
};

const dependentFieldsPlanDesign = {
  allowEmployerProfitSharing: [
    'profitSharingStrategy',
    'formulaDefined',
    'firstTierPercent',
    'firstTierCap',
    'dollarCap',
    'secondTierPercent',
    'secondTierCap',
    'capped',
    'tier',
    'dollarCapId',
    'formula'
  ],
  allowHardshipWithdrawals: [
    'allowInServiceFromPartialVestedAccount',
    'allowHardshipWithdrawalsFromRothAccount',
    'hardshipCriteria'
  ],
  allowInServiceAtEarlyAge: [
    'allowInServiceFromPartialVestedAccount',
    'allowInServiceAtEarlyAgeFromAllAccounts'
  ],
  allowInServiceAtNormalAge: ['allowInServiceAtNormalAgeFromAllAccounts'],
  allowInServiceAtSpecifiedAge: [
    'allowInServiceFromPartialVestedAccount',
    'allowSpecifiedAgeFromRothAccount',
    'inServiceSpecifiedAge'
  ],
  allowInServiceUponDisability: ['allowInServiceFromPartialVestedAccount'],

  allowLoans: [
    'maxYearsResidencePurchase',
    'allowResidencePurchaseExtension',
    'maxOutstandingLoans'
  ],

  allowNonSafeHarborMatch: [
    'nonSafeHarborMatchType',
    'formulaDefined',
    'firstTierPercent',
    'firstTierCap',
    'dollarCap',
    'secondTierPercent',
    'secondTierCap',
    'capped',
    'tier',
    'dollarCapId',
    'formula'
  ],
  allowPermissibleWithdrawal: ['permissibleTimeframe'],
  allowResidencePurchaseExtension: ['maxYearsResidencePurchase'],
  allowRollover: ['allowInServiceFromRolloverAccount'],
  autoEnrollAmount: ['autoEnrollEffectiveDate', 'allowPermissibleWithdrawal'],
  autoEscalateAmount: ['autoEscalateMaximum'],
  conversionType: ['priorProvider'],
  earlyRetirementAgeType: ['allowInServiceAtEarlyAge', 'earlyRetirementAge'],
  isAllowed: ['excludeRollover', 'isAutomated', 'maxAmount', 'rolloverMin'],
  isSalaryRothDeferral: ['allowSpecifiedAgeFromRothAccount'],
  serviceCalculationType: ['lengthOfEmploymentRequired', 'hoursRequired'],
  vestingMethod: ['hoursOfServiceRequired']
};

const dependentFieldsCompany = {
  'New-business': ['New-ein'],
  'New-ein': ['New-business'],
  'Remove-business': ['Remove-ein'],
  'Remove-ein': ['Remove-business'],
  email: ['primaryContact'],
  isWiredAtWork: ['wiredAtWork'],
  phoneNumber: ['companyPhoneNumber']
};
const dependentFields = {
  company: dependentFieldsCompany,
  plan_design: dependentFieldsPlanDesign
};

const getValidationErrors = async (
  validationSchema: AnyObjectSchema,
  values: Record<string, any>
) => {
  let errors = {};
  try {
    await validationSchema.validate(values, { abortEarly: false });
  } catch (e) {
    errors = e.inner.reduce((acc, current) => {
      return {
        ...acc,
        [current.path]: current.errors
      };
    }, {});
  }
  return errors;
};

const getEditPlanPayloadAndErrors = async (
  updatedScheduledChange: ScheduledChange,
  config: CreateScheduledChangePayloadConfig
) => {
  const scheduledPlanDetailsFormValues: Record<string, any> = {};
  const currentPlanDetailsFormValues: Record<string, any> = {};

  updatedScheduledChange.payload.forEach(item => {
    scheduledPlanDetailsFormValues[item.fieldName] = item.uiScheduledValue;
    currentPlanDetailsFormValues[item.fieldName] = item.uiCurrentValue;
  });

  const planTemplate = PlanTabUtils.getPlanTemplate({
    planDesignData: config.planDesignData
  });

  const updatedPlanDesign = PlanTabUtils.getEditPlanDesignValues(
    config.planDesignData.data,
    planTemplate,
    scheduledPlanDetailsFormValues
  );

  const newFormValues = PlanTabUtils.planDataToFormValues(updatedPlanDesign);

  const errors = await getValidationErrors(
    EditPlanValidations.makeDesignTabFieldsSchema({
      isDesignDefaults: false
    }),
    newFormValues
  );

  const payload = PlanTabUtils.editPlanDetailsFormToScheduleChangesPayload(
    currentPlanDetailsFormValues,
    scheduledPlanDetailsFormValues,
    {
      planData: PlanTabUtils.getPlanTemplate({
        planDesignData: {
          data: updatedPlanDesign
        }
      }),
      planDesignData: config.planDesignData || { data: {} }
    }
  );

  return { errors, payload };
};

const getCompanyInfoErrors = async (
  updatedScheduledChange: ScheduledChange,
  config: CreateScheduledChangePayloadConfig
) => {
  const scheduledPlanDetailsValues: Record<string, any> = {};

  updatedScheduledChange.payload
    .filter(item => item.uiLabel)
    .forEach(item => {
      scheduledPlanDetailsValues[item.fieldName] = item.value;
    });

  const planDesignUpdated = { ...config.planDesignData };
  planDesignUpdated.data = {
    ...planDesignUpdated.data,
    ...scheduledPlanDetailsValues
  };

  const fields = PlanCompanyInfoCardUtils.getFormDialogStep(
    config.companyInfoTabData,
    config.isVoyaPlan,
    config.isStateIRA
  ).fields;
  const initialValues = PlanCompanyInfoCardUtils.getInitialValues(fields);

  const newFormValues = { ...initialValues, ...scheduledPlanDetailsValues };

  const payload =
    PlanCompanyInfoCardUtils.editCompanyInfoFormToScheduleChangesPayload(
      initialValues,
      newFormValues,
      { fields, isStateIRA: config.isStateIRA, isVoyaPlan: config.isVoyaPlan }
    );

  const errors = await getValidationErrors(
    makeValidationSchema({
      ein: config.companyInfoTabData?.ein || '',
      isStateIRA: config.isStateIRA,
      isVoyaPlan: config.isVoyaPlan,
      phoneNumber: config.companyInfoTabData.phone || '',
      requiredAlternateEmployerId: config.companyInfoTabData.firmId === 1001
    }),
    newFormValues
  );

  return { errors, payload };
};

export const getUpdatedPayloadUnschedulePayloadItemAndErrors = async (
  scheduledChange: ScheduledChange,
  unschedulePayloadItem: ScheduledChangePayloadItem,
  config: CreateScheduledChangePayloadConfig
) => {
  let errors = {};
  const updatedScheduledChange = { ...scheduledChange };
  updatedScheduledChange.payload = updatedScheduledChange.payload?.filter(
    field => field.fieldName !== unschedulePayloadItem?.fieldName
  );

  const deleteDependentFields =
    dependentFields[scheduledChange.changeType][
      unschedulePayloadItem?.fieldName
    ];
  if (deleteDependentFields) {
    updatedScheduledChange.payload = updatedScheduledChange.payload?.filter(
      field => !deleteDependentFields.includes(field.fieldName)
    );
  }

  switch (scheduledChange.changeType) {
    case 'plan_design': {
      const result = await getEditPlanPayloadAndErrors(
        updatedScheduledChange,
        config
      );
      updatedScheduledChange.payload = result.payload;
      errors = result.errors;
      break;
    }

    case 'company': {
      const result = await getCompanyInfoErrors(updatedScheduledChange, config);

      updatedScheduledChange.payload = result.payload;
      errors = result.errors;
      break;
    }
  }

  return { errors, updatedScheduledChange };
};

export const appendScheduledChangesToPayload = (
  payload: ScheduledChangePayloadItem[],
  scheduledChanges: ScheduledChangesDTO,
  changeType: string
): PayloadWithScheduledChanges => {
  const newPayloadWithScheduledChanges: PayloadWithScheduledChanges = [];

  const scheduledChangesByFieldName = new Map();
  scheduledChanges?.data
    .sort(
      (scheduledChangeA, scheduledChangeB) =>
        new Date(scheduledChangeA.effectiveDate).getTime() -
        new Date(scheduledChangeB.effectiveDate).getTime()
    )
    .forEach(scheduledChange => {
      if (scheduledChange.changeType === changeType) {
        scheduledChange.payload.forEach(payloadField => {
          scheduledChangesByFieldName.set(payloadField.fieldName, [
            ...(scheduledChangesByFieldName.get(payloadField.fieldName) || []),
            {
              scheduledFor: formatters.formatFromIsoDateCustom(
                scheduledChange.effectiveDate,
                'MM/DD/YYYY'
              ),
              uiCurrentValue: payloadField.uiCurrentValue,
              uiScheduledValue: payloadField.uiScheduledValue
            }
          ]);
        });
      }
    });

  payload.forEach(field => {
    newPayloadWithScheduledChanges.push({
      ...field,
      scheduledChanges: scheduledChangesByFieldName.get(field.fieldName) || []
    });
  });

  return newPayloadWithScheduledChanges;
};

export const makeScheduleChangeSchema = (
  makeScheduledChangesPayload: (
    currentValues: Record<string, any>,
    scheduledValues: Record<string, any>,
    config?: any
  ) => ScheduledChangePayloadItem[],
  makeScheduledChangesPayloadConfig: any,
  scheduledChanges: ScheduledChangesDTO,
  initialValues: Record<string, any>,
  changeType: string
): yup.AnyObjectSchema =>
  yup.object({
    scheduledChangesEffectiveDate: yup
      .string()
      .required('Effective Date is required')
      .test(
        'effective-date-in-use',
        'This effective date is already in use',
        (value, context) => {
          const payloadWithScheduledChanges = appendScheduledChangesToPayload(
            makeScheduledChangesPayload(
              initialValues,
              context.parent,
              makeScheduledChangesPayloadConfig
            ),
            scheduledChanges,
            changeType
          );

          return !payloadWithScheduledChanges.some(
            field =>
              field.scheduledChanges.length &&
              field.scheduledChanges.some(
                scheduledChange =>
                  scheduledChange.scheduledFor ===
                  formatters.formatFromIsoDateCustom(value || '', 'MM/DD/YYYY')
              )
          );
        }
      )
  });
