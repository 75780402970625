import SimpleTable, {
  SimpleTableColumnDef
} from '@/components/simple-table/SimpleTable.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { PlanService } from '@/services/Plan.service';
import SponsorService from '@/services/Sponsor.service';
import {
  alpha,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  Stack,
  styled,
  TextField,
  Typography
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Field, Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import type { BankAccountResponse, PayrollSetupCreateRequest } from 'scala-sdk';
import * as yup from 'yup';

type DeleteBankAccountDialogProps = {
  account?: Partial<BankAccountResponse>;
  open: boolean;
  onClose: () => void;
  sponsorId: number;
  sponsorPlanId: number;
};

const groupAssociationsCols: SimpleTableColumnDef[] = [
  {
    cellRenderer: params => <Typography>{params.data.payGroupName}</Typography>,
    field: 'payGroupName',
    header: `Name`
  },
  {
    cellRenderer: params => (
      <Typography
        color={
          params.data.integrationType === 1 ? 'error.main' : 'success.main'
        }
        variant='body2'>
        {params.data.integrationType === 1 ? 'Not Integrated' : 'Integrated'}
      </Typography>
    ),
    field: 'integrationType',
    header: 'Integration Status'
  }
];

const formInitialValues = {
  accountNumber: ''
};

const createDeleteAccountValidationSchema = (
  accountExistingNumber: string
): yup.AnyObjectSchema =>
  yup.object({
    accountNumber: yup
      .string()
      .required('Bank account is required')
      .equals(
        [accountExistingNumber],
        'Bank account number does not match, please enter correct bank account number'
      )
  });

const StyledColorContainer = styled('div')(props => ({
  backgroundColor: alpha(props.theme.palette.primary.main, 0.08),
  padding: `${props.theme.spacing(1)} ${props.theme.spacing(3)}`
}));

export const DeleteBankAccountDialog: React.FC<
  DeleteBankAccountDialogProps
> = props => {
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const validationSchema = useMemo(
    () => createDeleteAccountValidationSchema(props.account?.accountNumber),
    [props.account]
  );

  const deleteAccountQuery = useMutation(
    [
      `SponsorService.deleteSponsorBankAccount`,
      props.sponsorId,
      +props.account?.accountNumber
    ],
    () =>
      SponsorService.deleteSponsorBankAccount({
        bankAccountId: +props.account.bankAccountId,
        sponsorId: props.sponsorId
      }),
    {
      onError: () => {
        showSnackbar({
          message: 'Error deleting bank account',
          severity: 'error'
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries([
          'SponsorService.getSponsorBankAccounts',
          props.sponsorId
        ]);
        showSnackbar({
          message: 'Bank account was deleted',
          severity: 'success'
        });
        props.onClose();
      }
    }
  );

  const hasActivePayGroup = props.account?.payrollSetups?.filter(
    group => group.isActive
  ).length;

  const putPayrollSetupQuery = useMutation(
    [`PlanService.updatePayrollSetup`, props.sponsorId],
    (body: { paygroupId: number; payload: PayrollSetupCreateRequest }) =>
      PlanService.updatePayrollSetup(
        props.sponsorPlanId,
        body.paygroupId,
        body.payload
      ),
    {
      onError: () => {
        showSnackbar({
          message: 'Error removing deactivated pay group',
          severity: 'error'
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries([
          'SponsorService.getSponsorBankAccounts',
          props.sponsorId
        ]);
        showSnackbar({
          message: 'Deactivated pay group has been removed',
          severity: 'success'
        });

        deleteAccountQuery.mutateAsync();
      }
    }
  );

  return (
    <Dialog onClose={props.onClose} open={props.open}>
      <DialogTitle>Delete Bank Account</DialogTitle>
      <StyledColorContainer>
        <Typography>{props.account?.bankName}</Typography>
        <Typography color='textPrimary' variant='body2'>
          Routing Number: {props.account?.routingNumber}
        </Typography>
        <Typography color='textPrimary' variant='body2'>
          Account Number: {props.account?.accountNumber}
        </Typography>
      </StyledColorContainer>
      {props.account?.payrollSetups?.length > 0 ? (
        <Stack marginX={3} marginY={1}>
          {hasActivePayGroup ? (
            <>
              <Typography variant='body2'>
                This bank account cannot be deleted because it is currently
                being used by&nbsp;
                {props.account.payrollSetups.length} Pay Group(s). To delete the
                bank account, please remove pay group association(s).
              </Typography>
              <SimpleTable
                columnDefs={groupAssociationsCols}
                data={props.account.payrollSetups}
                data-testid='payrolls-table'
              />
              <DialogActions>
                <Button
                  data-testid='close-bank-account-dialog-btn'
                  onClick={props.onClose}>
                  Close
                </Button>
              </DialogActions>
            </>
          ) : (
            <>
              <Typography variant='body2'>
                This bank account is currently attached to a deactivated Pay
                Group. To delete the bank account, please enter the account
                number again.
              </Typography>
              <Formik
                initialValues={formInitialValues}
                onSubmit={async () => {
                  const deactivatedPayrollGroup =
                    props.account?.payrollSetups.filter(
                      group => group.isActive === false
                    );

                  await Promise.all(
                    deactivatedPayrollGroup.map(group => {
                      const payload: PayrollSetupCreateRequest = {
                        bankAccountId: null,
                        firstPayrollDate: group.firstPayrollDate,
                        frequency: group.frequency,
                        isActive: false,
                        nextPayrollDate: group.nextPayrollDate,
                        payAfterWeekend: group.payAfterWeekend,
                        payrollProviderId: group.payrollProviderId
                      };

                      putPayrollSetupQuery.mutateAsync({
                        paygroupId: group.id,
                        payload
                      });
                    })
                  );
                }}
                validateOnBlur
                validationSchema={validationSchema}>
                {formikProps => (
                  <Form>
                    <Stack marginX={3} marginY={1}>
                      <Grid container paddingTop={2}>
                        <Grid item sm={6} xs={12}>
                          <FormControl
                            error={Boolean(formikProps.errors.accountNumber)}
                            fullWidth>
                            <Field
                              as={TextField}
                              data-testid='account-number-field'
                              error={formikProps.errors.accountNumber}
                              label='Bank account number'
                              name='accountNumber'
                            />
                            <FormHelperText>
                              {formikProps.errors.accountNumber}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Stack>
                    <DialogActions>
                      <Button
                        data-testid='cancel-bank-account-delete-btn'
                        onClick={props.onClose}>
                        Cancel
                      </Button>
                      <Button
                        color='error'
                        data-testid='delete-bank-account-btn'
                        disabled={
                          !formikProps.isValid ||
                          !formikProps.values.accountNumber ||
                          deleteAccountQuery.isLoading
                        }
                        type='submit'
                        variant='contained'>
                        Delete
                      </Button>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </Stack>
      ) : (
        <Formik
          initialValues={formInitialValues}
          onSubmit={() => deleteAccountQuery.mutate()}
          validateOnBlur
          validationSchema={validationSchema}>
          {formikProps => (
            <Form>
              <Stack marginX={3} marginY={1}>
                <Typography variant='body2'>
                  {`This bank account is currently not being used by any Pay Groups.\n
                    To delete the bank account, please enter the account number again.`}
                </Typography>
                <Grid container paddingTop={2}>
                  <Grid item sm={6} xs={12}>
                    <FormControl
                      error={Boolean(formikProps.errors.accountNumber)}
                      fullWidth>
                      <Field
                        as={TextField}
                        data-testid='account-number-field'
                        error={formikProps.errors.accountNumber}
                        label='Bank account number'
                        name='accountNumber'
                      />
                      <FormHelperText>
                        {formikProps.errors.accountNumber}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Stack>
              <DialogActions>
                <Button
                  data-testid='cancel-bank-account-delete-btn'
                  onClick={props.onClose}>
                  Cancel
                </Button>
                <Button
                  color='error'
                  data-testid='delete-bank-account-btn'
                  disabled={
                    !formikProps.isValid ||
                    !formikProps.values.accountNumber ||
                    deleteAccountQuery.isLoading
                  }
                  type='submit'
                  variant='contained'>
                  Delete
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};
