import AccessControl from '@/components/access-control/AccessControl.component';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { SponsorTeammateData } from '@/models/SponsorTeammates.model';
import { PatchSponsorUserInviteBodyDto } from '@/models/UpdateSponsorUserInviteDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { UserMfaMethodDto } from '@/models/UsersDTO.model';
import SponsorService from '@/services/Sponsor.service';
import UserManagementService from '@/services/UserManagement.service';
import createSponsorTeamValidationSchema from '@/utils/validations/EmailValidationSchema.schema';
import { MoreVert } from '@mui/icons-material';
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import { Box, Button, IconButton, Menu, MenuItem } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { Sponsors } from '@vestwell-api/scala';

import dayjs from 'dayjs';
import React, { useCallback, useState } from 'react';
import { useCopyToClipboard } from 'react-use';

import { PlanUsersTableDisableTwoFactorAuthModal } from '../PlanUsersTableDisableTwoFactorAuthModal.component';
import { PlanUsersTableResetPasswordModal } from '../PlanUsersTableResetPasswordModal.component';

interface PlanUsersActionButtonsProps {
  rowKey?: string;
  firmId: number;
  isExpired: boolean;
  isLocked: boolean;
  isMainContact: boolean;
  isRegistered: boolean;
  isSnailInvite: boolean;
  teammate: SponsorTeammateData;
  sponsorTeammates: SponsorTeammateData[];
  sponsorUserInviteCode?: string | undefined;
  sponsorId: number;
  sponsorName: string | undefined;
  registrationInvite?: Sponsors.GetUserInvitesV2.ResponseBody[0];
  resendInvite: (email: string, isNewInvite?: boolean) => Promise<void>;
  resendMainContactWelcomeEmail: () => Promise<void>;
  refetchUsers: () => void;
  removeTeamMember: (key: string) => Promise<void>;
}

const PlanUsersActionButtons = (
  props: PlanUsersActionButtonsProps
): JSX.Element => {
  const {
    rowKey,
    firmId,
    isExpired,
    isLocked,
    isMainContact,
    isRegistered,
    isSnailInvite,
    teammate,
    sponsorTeammates,
    sponsorId,
    sponsorName,
    sponsorUserInviteCode,
    removeTeamMember,
    registrationInvite,
    resendInvite,
    resendMainContactWelcomeEmail,
    refetchUsers
  } = props;

  const ctx = useDialog();
  const snackbar = useSnackbar();
  const [, copyToClipboard] = useCopyToClipboard();

  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const handleClose = () => setAnchor(null);

  const mfaMethods = useQuery<UserMfaMethodDto[]>(
    [
      'UserManagementService.getUserMfaMethods',
      props.teammate?.relationships?.user?.data?.id
    ],
    () =>
      UserManagementService.getUserMfaMethods(
        props.teammate?.relationships?.user?.data?.id
      ),
    { enabled: !!props.teammate?.relationships?.user?.data?.id }
  );

  const updateUserInvites = useMutation(
    (data: PatchSponsorUserInviteBodyDto) => {
      return SponsorService.updateSponsorUserInvite(sponsorId, data);
    },
    {
      onError: () => {
        snackbar.showSnackbar({
          message: `Failure!`,
          severity: 'error'
        });
      },
      onSuccess: () => {
        refetchUsers();
      }
    }
  );

  const registrationUrl = useQuery<string>(
    [
      'SponsorService.getRegistrationURL',
      sponsorId,
      firmId,
      registrationInvite
    ],
    () => {
      return SponsorService.getRegistrationURL(
        sponsorId,
        firmId,
        registrationInvite?.inviteCode
      );
    },
    {
      enabled: !!registrationInvite?.inviteCode
    }
  );

  const handleCopy = useCallback(() => {
    copyToClipboard(registrationUrl.data as string);

    const expireDays = dayjs(registrationInvite?.expiresAt).diff(
      dayjs(),
      'days'
    );
    const expiration = dayjs(registrationInvite?.expiresAt).format(
      'MM/DD/YYYY'
    );

    snackbar.showSnackbar({
      message: `Invite Link copied. Link will expire in ${expireDays} days (on ${expiration})`,
      severity: 'success'
    });

    handleClose();
  }, [registrationUrl.data]);

  const onDisable = useCallback(() => {
    mfaMethods.refetch();
    ctx.closeDialog();
  }, [ctx, mfaMethods]);

  const removeUserContentText = (
    <span style={{ whiteSpace: 'pre-wrap' }}>
      This user will <strong>no longer have access</strong> to {sponsorName}.
      {'\n\n'}
      Do not perform this action if the user is having trouble accessing the
      portal (ex. Password or Login issues).
    </span>
  );

  const isVestwellUser =
    props.teammate?.attributes?.email?.includes('@vestwell.com');

  return (
    <Box display='flex'>
      {isSnailInvite ? (
        isRegistered ? (
          <>
            <IconButton
              aria-controls={anchor ? 'user-actions-menu' : undefined}
              aria-expanded={!!anchor}
              aria-haspopup='true'
              data-testid='user-actions-menu-btn'
              onClick={e => setAnchor(e.currentTarget)}
              size='small'
              sx={{ ml: 2 }}>
              <MoreVert />
            </IconButton>
            <Menu
              anchorEl={anchor}
              id='user-actions-menu'
              onClose={handleClose}
              open={!!anchor}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}>
              <MenuItem
                data-testid='remove-user-btn'
                onClick={() => {
                  handleClose();
                  ctx.openDialog({
                    actionButtons: {
                      cancelButton: { children: 'Cancel' },
                      submitButton: {
                        children: 'Remove'
                      }
                    },
                    onSubmit: async () => {
                      if (rowKey) {
                        await removeTeamMember(rowKey);
                      }
                    },
                    steps: [
                      {
                        contentText: removeUserContentText,
                        title: 'Remove User'
                      }
                    ]
                  });
                }}>
                Remove User
              </MenuItem>
              <AccessControl
                requires={[
                  FeatureLevelPermissions.WRITE_USER_MANAGEMENT_LOGIN
                ]}>
                {!isVestwellUser && (
                  <MenuItem
                    data-testid='manage-password-btn'
                    onClick={() => {
                      handleClose();
                      ctx.openDialog({
                        customContent: (
                          <PlanUsersTableResetPasswordModal
                            onClose={ctx.closeDialog}
                            userId={
                              props.teammate?.relationships?.user?.data?.id
                            }
                          />
                        ),
                        dialogProps: {
                          maxWidth: 'md'
                        }
                      });
                    }}>
                    Manage Password
                  </MenuItem>
                )}
                {!isVestwellUser && mfaMethods.data?.length ? (
                  <MenuItem
                    data-testid='disable-two-factor-auth-btn'
                    onClick={() => {
                      handleClose();
                      ctx.openDialog({
                        customContent: (
                          <PlanUsersTableDisableTwoFactorAuthModal
                            onDisable={onDisable}
                            teammate={props.teammate}
                          />
                        ),
                        dialogProps: {
                          maxWidth: 'md'
                        }
                      });
                    }}>
                    Disable 2-FA
                  </MenuItem>
                ) : (
                  <></>
                )}
              </AccessControl>
            </Menu>
          </>
        ) : isLocked ? (
          <span>
            <Button
              data-testid='unlock-user-invite-btn'
              onClick={() => {
                updateUserInvites.mutate({
                  inviteCode: sponsorUserInviteCode,
                  verificationAttempts: 3
                });
                snackbar.showSnackbar({
                  message: `Access Code unlocked`,
                  severity: 'success'
                });
              }}
              size='medium'
              variant='text'>
              UNLOCK
            </Button>
          </span>
        ) : (
          <IconButton
            data-testid='copy-user-access-code-btn'
            onClick={() => {
              copyToClipboard(sponsorUserInviteCode || '');
              snackbar.showSnackbar({
                message: 'Access code copied',
                severity: 'success'
              });
            }}
            size='medium'>
            <FileCopyOutlined fontSize='inherit' />
          </IconButton>
        )
      ) : (
        <>
          <IconButton
            aria-controls={anchor ? 'user-actions-menu' : undefined}
            aria-expanded={!!anchor}
            aria-haspopup='true'
            data-testid='user-actions-menu-btn'
            onClick={e => setAnchor(e.currentTarget)}
            size='small'
            sx={{ ml: 2 }}>
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={anchor}
            id='user-actions-menu'
            onClose={handleClose}
            open={!!anchor}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}>
            {isRegistered ? (
              <div>
                <MenuItem
                  data-testid='remove-user-btn'
                  onClick={() => {
                    handleClose();
                    ctx.openDialog({
                      actionButtons: {
                        cancelButton: { children: 'Cancel' },
                        submitButton: {
                          children: 'Remove'
                        }
                      },
                      onSubmit: async () => {
                        if (rowKey) {
                          await removeTeamMember(rowKey);
                        }
                      },
                      steps: [
                        {
                          contentText: removeUserContentText,
                          title: 'Remove User'
                        }
                      ]
                    });
                  }}>
                  Remove User
                </MenuItem>
                <AccessControl
                  requires={[
                    FeatureLevelPermissions.WRITE_USER_MANAGEMENT_LOGIN
                  ]}>
                  {!isVestwellUser && (
                    <MenuItem
                      data-testid='manage-password-btn'
                      onClick={() => {
                        handleClose();
                        ctx.openDialog({
                          customContent: (
                            <PlanUsersTableResetPasswordModal
                              onClose={ctx.closeDialog}
                              userId={
                                props.teammate?.relationships?.user?.data?.id
                              }
                            />
                          ),
                          dialogProps: {
                            maxWidth: 'md'
                          }
                        });
                      }}>
                      Manage Password
                    </MenuItem>
                  )}
                  {!isVestwellUser && mfaMethods.data?.length ? (
                    <MenuItem
                      data-testid='disable-two-factor-auth-btn'
                      onClick={() => {
                        handleClose();
                        ctx.openDialog({
                          customContent: (
                            <PlanUsersTableDisableTwoFactorAuthModal
                              onDisable={onDisable}
                              teammate={props.teammate}
                            />
                          ),
                          dialogProps: {
                            maxWidth: 'md'
                          }
                        });
                      }}>
                      Disable 2-FA
                    </MenuItem>
                  ) : (
                    <></>
                  )}
                </AccessControl>
              </div>
            ) : isExpired ? (
              <div>
                <MenuItem
                  data-testid='send-new-invite-btn'
                  onClick={async () => {
                    if (!isMainContact) {
                      const validationSchema =
                        createSponsorTeamValidationSchema(sponsorTeammates);
                      handleClose();
                      ctx.openDialog({
                        actionButtons: {
                          cancelButton: { children: 'Cancel' },
                          submitButton: {
                            children: 'Send Invite'
                          }
                        },
                        disableValueChangeCheck: true,
                        onSubmit: async values => {
                          await resendInvite(values.email, false);
                        },
                        steps: [
                          {
                            fields: {
                              email: {
                                initialValue: teammate.attributes.email,
                                label: 'Email'
                              }
                            },
                            headerText:
                              'The user will receive a new invite link to register.',
                            title: 'Send New Invite'
                          }
                        ],
                        validationSchema
                      });
                    } else {
                      await resendMainContactWelcomeEmail();
                    }
                  }}>
                  Send new invite
                </MenuItem>
                <MenuItem
                  data-testid='remove-invite-btn'
                  onClick={() => {
                    updateUserInvites.mutate({
                      invalidatedAt: new Date().toISOString(),
                      inviteCode: sponsorUserInviteCode
                    });
                    snackbar.showSnackbar({
                      message: 'Invite has been removed',
                      severity: 'success'
                    });
                    handleClose();
                  }}>
                  Remove Invite
                </MenuItem>
              </div>
            ) : (
              <div>
                <MenuItem
                  data-testid='copy-invite-btn'
                  disabled={!registrationUrl.data}
                  onClick={handleCopy}>
                  Copy invite link
                </MenuItem>

                {isMainContact ? (
                  <MenuItem
                    data-testid='resend-main-contact-email-btn'
                    onClick={async () => {
                      handleClose();
                      await resendMainContactWelcomeEmail();
                    }}>
                    Resend Main Contact Welcome Email
                  </MenuItem>
                ) : (
                  <MenuItem
                    data-testid='send-new-invite-btn'
                    onClick={() => {
                      const validationSchema =
                        createSponsorTeamValidationSchema(sponsorTeammates);
                      handleClose();
                      ctx.openDialog({
                        actionButtons: {
                          cancelButton: { children: 'Cancel' },
                          submitButton: {
                            children: 'Send Invite'
                          }
                        },
                        disableValueChangeCheck: true,
                        onSubmit: async values => {
                          await resendInvite(values.email, false);
                        },
                        steps: [
                          {
                            fields: {
                              email: {
                                initialValue: teammate.attributes.email,
                                label: 'Email'
                              }
                            },
                            headerText:
                              'The user will receive a new invite link to register.',
                            title: 'Send New Invite'
                          }
                        ],
                        validationSchema
                      });
                    }}>
                    Send new invite
                  </MenuItem>
                )}
                <MenuItem
                  data-testid='invalidate-and-remove-invite-btn'
                  onClick={() => {
                    handleClose();
                    ctx.openDialog({
                      actionButtons: {
                        cancelButton: { children: 'Cancel' },
                        submitButton: {
                          children: 'Remove'
                        }
                      },
                      onSubmit: async () => {
                        updateUserInvites.mutate({
                          invalidatedAt: new Date().toISOString(),
                          inviteCode: sponsorUserInviteCode
                        });
                        snackbar.showSnackbar({
                          message: 'Invite has been removed',
                          severity: 'success'
                        });
                      },
                      steps: [
                        {
                          contentText:
                            'This invite will be invalidated and the user will no longer be able to use it to register.',
                          title: 'Invalidate & Remove Invite'
                        }
                      ]
                    });
                  }}>
                  Invalidate & Remove invite
                </MenuItem>
              </div>
            )}
          </Menu>
        </>
      )}
    </Box>
  );
};

export default PlanUsersActionButtons;
