import { CardPlaceholder } from '@/components/card';
import CircularLoading from '@/components/circular-loading';
import { TextLabel, TextStackItem, TextValue } from '@/components/text-stack';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { InvestmentElection } from '@/models/ParticipantInfo.model';
import { Stats } from '@/models/ParticipantPositionsDTO.model';
import formatters from '@/utils/Formatters';
import { Box, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import React from 'react';

interface HoldingsSummarySectionProps {
  isLoading: boolean;
  stats?: Stats;
  investmentElections?: InvestmentElection;
  vestingTotal?: number;
  usingAsOfDate?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(2),
    width: '25%'
  }
}));

const modelIdToRiskSerie: Record<string, string> = {
  '1': 'Age-Based',
  '2': 'Risk-Based',
  '3': 'Managed Account ',
  '9': 'Custom Option'
};

const HoldingsSummarySection: React.FunctionComponent<
  HoldingsSummarySectionProps
> = ({
  stats,
  isLoading,
  investmentElections,
  vestingTotal,
  usingAsOfDate
}) => {
  const classes = useStyles();

  const modelAllocation = stats?.modelAllocations[0];
  const shouldShowData =
    modelAllocation || (!usingAsOfDate && investmentElections?.modelViewName);

  if (isLoading) {
    return (
      <Box className={classes.root} textAlign='center'>
        <CircularLoading />
      </Box>
    );
  }

  return (
    <div className={classes.root} data-testid='holdings-summary-section'>
      {shouldShowData && (
        <>
          <TextStackItem fieldName='total-balance' style={{ width: '100%' }}>
            <TextLabel>Total Balance</TextLabel>
            <TextValue>
              {formatters.formatDollars(
                modelAllocation?.balance
                  ? modelAllocation?.balance
                  : vestingTotal
              )}
            </TextValue>
          </TextStackItem>

          <TextStackItem
            fieldName='current-model'
            style={{ marginTop: '1.2em', width: '100%' }}>
            <TextLabel>Current Model</TextLabel>
            <TextValue
              detail={`${
                modelAllocation
                  ? modelIdToRiskSerie[
                      modelAllocation?.modelId.toString()[0]
                    ] || EMPTY_FIELD_PLACEHOLDER
                  : investmentElections
                    ? modelIdToRiskSerie[
                        investmentElections?.modelId.toString()[0]
                      ]
                    : EMPTY_FIELD_PLACEHOLDER
              } | ID: ${
                modelAllocation?.modelId ||
                investmentElections?.modelId ||
                EMPTY_FIELD_PLACEHOLDER
              }`}>
              {modelAllocation?.model || investmentElections?.modelViewName}
            </TextValue>
          </TextStackItem>
        </>
      )}
      {!shouldShowData && (
        <CardPlaceholder
          data-testid='no-investment-election-text'
          subtitle='No investment election'
        />
      )}
    </div>
  );
};
export default HoldingsSummarySection;
