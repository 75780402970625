import formatters from '@/utils/Formatters';
import { Checkbox, FormControl, FormControlLabel } from '@mui/material';

import { Field, FormikProps } from 'formik';
import { FunctionComponent } from 'react';

interface CheckBoxFormProps {
  name: string;
  label: string;
  'data-testid'?: string;
}

export const CheckBoxForm: FunctionComponent<CheckBoxFormProps> = props => {
  return (
    <FormControl>
      <Field name={props.name}>
        {(fieldProps: { form: FormikProps<any> }) => {
          return (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(
                      fieldProps.form.getFieldMeta(props.name).value
                    )}
                    data-testid={
                      props['data-testid'] ||
                      formatters.textToDataTestId(props.label)
                    }
                    onChange={() =>
                      fieldProps.form.setFieldValue(
                        props.name,
                        !fieldProps.form.getFieldMeta(props.name).value
                      )
                    }
                  />
                }
                label={props.label}
              />
            </>
          );
        }}
      </Field>
    </FormControl>
  );
};

export default CheckBoxForm;
