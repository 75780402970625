import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { AlertDto } from '@/models/ops/alerts/AlertDTO.model';
import formatters from '@/utils/Formatters';
import { AlertSubType } from '@vestwell-sub-accounting/models/common/AlertSubType';
import { AlertType } from '@vestwell-sub-accounting/models/common/AlertType';

import React, { useMemo } from 'react';

type ACHReversalAlertDetailFieldsProps = {
  alert: AlertDto;
};

export const ACHReversalAlertDetailFields: React.FC<
  ACHReversalAlertDetailFieldsProps
> = props => {
  // fetch friendly display name for alert type
  const displayType = formatters.getValueKey(AlertType, props.alert.alertType);
  // fetch friendly display name for alert sub type
  const displaySubType = formatters.getValueKey(
    AlertSubType,
    props.alert.alertSubType
  );

  const planLinks = useMemo(() => {
    const links = [];
    if (props.alert.planId) {
      links.push({
        label: `ID: ${props.alert.planId}`,
        target: '_blank',
        to: `/plans/${props.alert.planId}/plan`
      });
    }
    if (props.alert.parentAccountId) {
      links.push({
        label: 'View Parent Acct.',
        target: '_blank',
        to: `/ops/accounts/${props.alert.parentAccountId}/sub-accounts`
      });
    }
    return links;
  }, [props.alert.planId, props.alert.parentAccountId]);

  return (
    <TextStack direction='column' id='alert-detail-fields' spacing={1}>
      <TextStackItem>
        <TextLabel>Type / Sub Type</TextLabel>
        <TextValue>
          {formatters.displayCase(displayType)} /{' '}
          {formatters.displayCase(displaySubType)}
        </TextValue>
      </TextStackItem>
      <TextStackItem>
        <TextLabel>Plan</TextLabel>
        <TextValue links={planLinks}>{props.alert.plan?.name}</TextValue>
      </TextStackItem>
      <TextStackItem>
        <TextLabel>Custodian Account</TextLabel>
        <TextValue>{props.alert.custodianAccountNumber}</TextValue>
      </TextStackItem>
      <TextStackItem>
        <TextLabel>Amount</TextLabel>
        <TextValue>
          {props.alert.details?.event?.body?.[0]?.amount
            ? formatters.formatDollars(props.alert.details.event.body[0].amount)
            : ''}
        </TextValue>
      </TextStackItem>
      <TextStackItem>
        <TextLabel>Deposit Date</TextLabel>
        <TextValue>
          {props.alert.details?.event?.body?.[0]?.depositDate
            ? formatters.formatFromIsoDateCustom(
                props.alert.details.event.body[0].depositDate,
                'MM/DD/YYYY'
              )
            : ''}
        </TextValue>
      </TextStackItem>
      <TextStackItem>
        <TextLabel>Bank Account Number</TextLabel>
        <TextValue>
          {props.alert.details?.event?.body?.[0]?.bankAccountNumber}
        </TextValue>
      </TextStackItem>
      <TextStackItem>
        <TextLabel>Bank Routing Number</TextLabel>
        <TextValue>
          {props.alert.details?.event?.body?.[0]?.bankRoutingNumber}
        </TextValue>
      </TextStackItem>
      <TextStackItem>
        <TextLabel>Created</TextLabel>
        <TextValue>
          {props.alert.createdAt
            ? formatters.formatFromIsoDate(props.alert.createdAt)
            : null}
        </TextValue>
      </TextStackItem>
      <TextStackItem>
        <TextLabel>Updated</TextLabel>
        <TextValue>
          {props.alert.createdAt
            ? formatters.formatFromIsoDate(props.alert.updatedAt)
            : null}
        </TextValue>
      </TextStackItem>
    </TextStack>
  );
};
