import AccessControl from '@/components/access-control/AccessControl.component';
import LinearLoading from '@/components/linear-loading';
import {
  useCreateHoursOfService,
  useUpdateHoursOfService
} from '@/hooks/hours-of-service';
import {
  ParticipantHoursOfService,
  ParticipantHoursOfServiceDto
} from '@/models';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import ParticipantService from '@/services/Participant.service';
import { userService } from '@/services/User.service';
import {
  alpha,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';

import dayjs from 'dayjs';
import Decimal from 'decimal.js';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import CreateHoursLine from './CreateHoursLine.component';
import HoursFilterCalculator from './HoursFilterCalculator.component';
import HoursTableCell from './HoursTableCell.component';
import {
  DATA_MAIN_FORMAT,
  FORMATTED_EARLIEST_START_DATE,
  getCurrentDate,
  getDefaultStartDate
} from './utils';

interface ParticipantHoursOfServiceTileProps {
  participantId: string;
  planId?: number;
  planEffectiveDate?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  contentBody: {
    padding: '0 !important'
  },
  divider: {
    borderColor: theme.palette.grey[300]
  },
  headingContainer: {
    alignContent: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing(2.5)} ${theme.spacing(2)}`
  },
  root: {
    minWidth: '640px',
    position: 'relative',
    zIndex: 1
  },
  tableHeadRow: {
    color: alpha(theme.palette.common.black, 0.6),
    fontSize: theme.spacing(1.75),
    fontWeight: 500,
    paddingBottom: theme.spacing(1.25),
    paddingTop: theme.spacing(1.25)
  }
}));

const ParticipantHoursOfServiceTile: React.FunctionComponent<
  ParticipantHoursOfServiceTileProps
> = (props: ParticipantHoursOfServiceTileProps) => {
  const { participantId, planId, planEffectiveDate } = props;

  const classes = useStyles();

  const userHasEditPerms = userService.hasPermission(
    FeatureLevelPermissions.WRITE_EMPLOYMENT_HOURS
  );

  const tableColumns = useMemo(() => {
    const columns = [
      {
        cellClassName: classes.tableHeadRow,
        field: 'startDate',
        headerName: 'Start date',
        width: 130
      },
      {
        cellClassName: classes.tableHeadRow,
        field: 'endDate',
        headerName: 'End date',
        width: 130
      },
      {
        cellClassName: classes.tableHeadRow,
        field: 'hours',
        headerName: 'Hours',
        width: 130
      }
    ];
    if (userHasEditPerms) {
      columns.push({
        cellClassName: classes.tableHeadRow,
        field: 'action',
        headerName: '',
        width: 130
      });
    }
    return columns;
  }, [userHasEditPerms, classes.tableHeadRow]);

  const [startDate, setStartDate] = useState(getDefaultStartDate());
  const [endDate, setEndDate] = useState(getCurrentDate());
  const [pageNumber, setPageNumber] = useState(1);
  const [hoursInEditId, setHoursInEditId] = useState(0);
  const [hoursInSubmit, setHoursInSubmit] = useState<number[]>([]);
  const [isCreateHoursOpen, setCreateHoursOpen] = useState(false);

  const closeCreateHours = useCallback(() => {
    setCreateHoursOpen(false);
  }, []);

  const createHours = useCreateHoursOfService(
    participantId,
    closeCreateHours,
    planId
  );

  const updateHoursId = useCallback((id = 0) => {
    setHoursInEditId(id);
    if (id) {
      setCreateHoursOpen(false);
    }
  }, []);

  useEffect(() => {
    updateHoursId();
  }, [pageNumber, startDate, endDate, updateHoursId]);

  const updateStartDate = useCallback((newStartDate: string) => {
    setStartDate(newStartDate);
    setPageNumber(1);
  }, []);

  const updateEndDate = (newEndDate: string) => {
    setEndDate(newEndDate);
    setPageNumber(1);
  };

  const updateHoursInSubmit = useCallback(
    (id: number) => {
      if (hoursInSubmit.includes(id)) {
        return setHoursInSubmit(hoursInSubmit.filter(h => h !== id));
      }
      setHoursInSubmit([...hoursInSubmit, id]);
    },
    [hoursInSubmit]
  );

  const updateHours = useUpdateHoursOfService(
    participantId,
    planId,
    updateHoursId,
    updateHoursInSubmit
  );

  const participantHoursQuery = useQuery<ParticipantHoursOfServiceDto>(
    [
      'ParticipantService.getParticipantHoursOfService',
      participantId,
      planId,
      pageNumber,
      10,
      startDate,
      endDate
    ],
    async () => {
      const participantHours: ParticipantHoursOfServiceDto =
        await ParticipantService.getParticipantHoursOfService(
          +participantId,
          planId,
          pageNumber,
          10,
          startDate,
          endDate
        );

      return participantHours;
    },
    {
      enabled: !!planId && !!participantId,
      keepPreviousData: true,
      staleTime: Infinity
    }
  );

  const participantEarliestHoursQuery = useQuery<ParticipantHoursOfServiceDto>(
    [
      'ParticipantService.getParticipantHoursOfService',
      participantId,
      planId,
      1,
      1,
      FORMATTED_EARLIEST_START_DATE,
      getCurrentDate()
    ],
    async () => {
      const participantHours: ParticipantHoursOfServiceDto =
        await ParticipantService.getParticipantHoursOfService(
          +participantId,
          planId,
          1,
          1,
          FORMATTED_EARLIEST_START_DATE,
          getCurrentDate()
        );
      if (!participantHours.data.length) return participantHours;
      const firstStartDate = dayjs(participantHours.data[0].startDate);
      if (firstStartDate && firstStartDate.year() === dayjs(startDate).year()) {
        updateStartDate(firstStartDate.format(DATA_MAIN_FORMAT));
      }
      return participantHours;
    },
    {
      enabled: !!planId && !!participantId,
      keepPreviousData: true,
      staleTime: Infinity
    }
  );

  if (!(participantHoursQuery.isPreviousData || participantHoursQuery.data)) {
    return <Typography>Loading...</Typography>;
  }

  if (!participantHoursQuery.isSuccess) {
    return <Typography>Error retrieving hours of service</Typography>;
  }

  return (
    <div
      className={classes.root}
      data-testid='participant-hours-of-service-card'>
      <Card elevation={0} variant='outlined'>
        <CardContent className={classes.contentBody}>
          <Box className={classes.headingContainer}>
            <Typography variant='h5'>Hours of Service</Typography>
            <AccessControl
              requires={[FeatureLevelPermissions.WRITE_EMPLOYMENT_HOURS]}>
              <Button
                data-testid='open-create-hours-tile'
                disabled={createHours.isLoading}
                onClick={() => {
                  setCreateHoursOpen(true);
                  updateHoursId();
                }}>
                ADD HOURS
              </Button>
            </AccessControl>
          </Box>
          <Divider className={classes.divider} />
          <Box display='flex' overflow='auto'>
            <HoursFilterCalculator
              earliestHours={participantEarliestHoursQuery.data?.data}
              endDate={endDate}
              isCalculationDisabled={updateHours.isLoading}
              isLoading={participantEarliestHoursQuery.isFetching}
              participantId={participantId}
              planId={planId}
              startDate={startDate}
              updateEndDate={updateEndDate}
              updateStartDate={updateStartDate}
              wasDataUpdated={updateHours.isSuccess || createHours.isSuccess}
            />
            {!!participantHoursQuery && (
              <Box flexGrow={1}>
                <LinearLoading fadeIn={participantHoursQuery.isFetching} />
                <TableContainer>
                  <Table aria-label='hours table'>
                    <TableHead>
                      <TableRow>
                        {tableColumns.map(column => (
                          <TableCell
                            className={classes.tableHeadRow}
                            key={column.field}>
                            {column.headerName}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <AccessControl
                        requires={[
                          FeatureLevelPermissions.WRITE_EMPLOYMENT_HOURS
                        ]}>
                        <CreateHoursLine
                          cancelCreateHours={closeCreateHours}
                          columnsAmount={tableColumns.length + 1}
                          isLoading={participantEarliestHoursQuery.isFetching}
                          isOpen={isCreateHoursOpen}
                          isSubmitting={createHours.isLoading}
                          onSubmit={createHours.mutate}
                          participantId={participantId}
                          planEffectiveDate={planEffectiveDate}
                          planId={planId}
                          totalRecords={
                            participantEarliestHoursQuery.data?.meta.count
                          }
                        />
                      </AccessControl>
                      {participantHoursQuery.data.data
                        .map((p: ParticipantHoursOfService) => ({
                          ...p,
                          hours: +p.hours
                            ? new Decimal(p.hours).toFixed(2)
                            : p.hours
                        }))
                        .map(row => {
                          return (
                            <HoursTableCell
                              columns={tableColumns}
                              isEditable={
                                !!participantHoursQuery.data.meta.count
                              }
                              isSubmitting={
                                hoursInSubmit.includes(
                                  row.employeeHoursTracker
                                ) && updateHours.isLoading
                              }
                              key={row.employeeHoursTracker}
                              onSubmit={updateHours.mutate}
                              onToggle={updateHoursId}
                              row={row}
                              selected={
                                hoursInEditId === row.employeeHoursTracker
                              }
                              updateHoursInSubmit={updateHoursInSubmit}
                            />
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component='div'
                  count={participantHoursQuery.data.meta.count}
                  data-testid='table-pagination'
                  onPageChange={(event: unknown, newPage: number) => {
                    setPageNumber(newPage + 1);
                  }}
                  page={pageNumber - 1}
                  rowsPerPage={10}
                  rowsPerPageOptions={[10]}
                />
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

export default ParticipantHoursOfServiceTile;
