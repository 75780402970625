import { SponsorPlanV1 } from '@/routes/plans/plans/PlansIndex/PlansIndex.component';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  Box,
  Collapse,
  Fade,
  IconButton,
  LinearProgress,
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { GridColDef } from '@mui/x-data-grid-pro';

import clsx from 'clsx';
import { isFunction, isNaN, isNumber, isString } from 'lodash';
import React, { useCallback, useState } from 'react';

const useRowStyles = makeStyles({
  collapseButton: {
    width: '1em'
  },
  drawer: {
    display: 'contents'
  },
  modalRow: {
    '&:hover': {
      backgroundColor: '#E3F2FD',
      cursor: 'pointer'
    }
  },
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  },
  selectedRow: {
    backgroundColor: '#E3F2FD'
  },
  smallIcon: {
    height: '0.875rem'
  },
  turnOffRowBorder: {
    '& > *': {
      borderBottom: 'none'
    }
  }
});

interface CollapsibleRowProps {
  row: any;
  columns: (GridColDef & { sx?: SxProps; hide?: boolean })[];
  collapsibleComponent: any;
  cellComponent: any;
  key: any;
  onClick: () => void;
  selected: boolean;
  isModal: boolean;
  hideCollapsibleToggle?: (row: any) => boolean;
  collapsibleToggleSx?: SxProps;
  expanded?: boolean;
}
interface CellComponentProps {
  row: any;
  column: GridColDef & { sx?: SxProps; hide?: boolean };
  key: any;
}

const CollapsibleTableRow: React.FunctionComponent<CollapsibleRowProps> = (
  props: CollapsibleRowProps
) => {
  const {
    row,
    columns,
    collapsibleComponent: CollapsibleComponent,
    cellComponent: CellComponent,
    onClick,
    selected,
    isModal,
    hideCollapsibleToggle,
    collapsibleToggleSx,
    expanded
  } = props;
  const [open, setOpen] = React.useState(expanded ?? false);
  const classes = useRowStyles();

  return (
    <>
      <TableRow
        className={clsx(
          classes.root,
          isModal ? classes.modalRow : '',
          selected ? classes.selectedRow : ''
        )}
        onClick={onClick}>
        {CollapsibleComponent == null ? null : (
          <TableCell
            className={classes.collapseButton}
            sx={collapsibleToggleSx}>
            {hideCollapsibleToggle && hideCollapsibleToggle(row) ? (
              <></>
            ) : (
              <IconButton
                aria-label='expand row'
                className={clsx(hideCollapsibleToggle ? classes.smallIcon : '')}
                color='primary'
                onClick={() => setOpen(!open)}
                size='small'>
                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            )}
          </TableCell>
        )}
        {columns
          .filter(column => !column.hide)
          .map(column => (
            <CellComponent column={column} key={column.field} row={row} />
          ))}
      </TableRow>
      <TableRow
        className={clsx(row.turnOffRowBorder && classes.turnOffRowBorder)}>
        <TableCell
          colSpan={columns.length + 1}
          style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse
            className={classes.drawer}
            in={open}
            timeout='auto'
            unmountOnExit>
            <Box margin={1}>
              {CollapsibleComponent == null ? null : (
                <CollapsibleComponent {...props} />
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

interface ExternallyControlledPager {
  metaCount?: number;
  pageNumber?: number;
  onPageNumberChanged?: (pageNumber: number) => void;
  rowsPerPage?: number;
  onRowsPerPageChanged?: (rowsPerPage: number) => void;
}

type CollapsibleTableProps = {
  cellComponent: any;
  collapsibleComponent?: any;
  collapsibleToggleSx?: SxProps;
  columns: (GridColDef & { sx?: SxProps; hide?: boolean })[];
  'data-testid'?: string;
  disablePagination?: boolean;
  expandAll?: boolean;
  footerComponent?: JSX.Element;
  groupedHeader?: JSX.Element;
  headerComponent?: JSX.Element;
  headerPagination?: boolean;
  hideCollapsibleToggle?: (row: any) => boolean;
  isModal?: boolean;
  isLoading?: boolean;
  isSelectedRow?: (row: any) => boolean;
  noDataPlaceholderComponent?: JSX.Element;
  pager?: ExternallyControlledPager;
  primaryKey?: string;
  receivePlan?: (seletedPlan: SponsorPlanV1) => void;
  rootPaperElevation?: number;
  sorter?: ExternallyControlledSorter;
  tableData: any[];
  tablePaginationSx?: SxProps;
} & (
  | {
      backgroundPaperElevation?: number;
    }
  | {
      useDivAsBackground?: boolean;
      useSquareBottomContainer?: boolean;
    }
);

type Order = 'asc' | 'desc';

interface ExternallyControlledSorter {
  order: Order;
  orderBy: string;
  onSortOrderChanged: (newOrderBy: string, newOrder: Order) => void;
}

const isExternallyControlledPager = (
  obj: unknown
): obj is ExternallyControlledPager => {
  const pager = obj as any;

  if (!pager) return false;

  return (
    isNumber(pager.metaCount) &&
    isNumber(pager.pageNumber) &&
    isFunction(pager.onPageNumberChanged) &&
    isFunction(pager.onRowsPerPageChanged)
  );
};

const isExternallyControlledSorter = (
  obj: unknown
): obj is ExternallyControlledSorter => {
  const sorter = obj as any;

  if (!sorter) return false;

  return (
    isString(sorter.order) &&
    isString(sorter.orderBy) &&
    isFunction(sorter.onSortOrderChanged)
  );
};

const useTableStyles = makeStyles((theme: Theme) => ({
  tableBackgroundSquareBottomPaper: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  tableHeaders: {
    fontSize: theme.spacing(2),
    fontWeight: theme.typography.fontWeightBold
  },
  tableToolbar: {
    marginBottom: '0px',
    paddingLeft: theme.spacing(2)
  },
  tableToolbarDivider: {
    height: '1px',
    marginBottom: theme.spacing(20),
    marginTop: 0
  },
  tableToolbarTitle: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4)
  }
}));

const DEFAULT_ROW_PER_PAGE_OPTIONS = [10, 25, 50, 100];

const getRowPerPageOptions = (maxRowsPerPage: number) => {
  return DEFAULT_ROW_PER_PAGE_OPTIONS.filter(r => maxRowsPerPage >= r);
};

const CollapsibleTable: React.FunctionComponent<CollapsibleTableProps> = (
  props: CollapsibleTableProps
) => {
  const {
    cellComponent: CellComponent,
    collapsibleComponent: CollapsibleComponent,
    collapsibleToggleSx,
    columns,
    'data-testid': dataTestId,
    disablePagination,
    expandAll,
    footerComponent,
    groupedHeader,
    headerComponent,
    headerPagination,
    hideCollapsibleToggle,
    isLoading,
    isModal,
    isSelectedRow,
    noDataPlaceholderComponent,
    pager,
    primaryKey,
    receivePlan,
    rootPaperElevation,
    sorter,
    tableData,
    tablePaginationSx
  } = props;

  const DEFAULT_PAGE_SIZE = disablePagination ? tableData.length : 10;
  const DEFAULT_BACKGROUND_PAPER_ELEVATION = 1;

  const { useDivAsBackground } =
    'useDivAsBackground' in props ? props : { useDivAsBackground: false };
  const { useSquareBottomContainer } =
    'useSquareBottomContainer' in props
      ? props
      : { useSquareBottomContainer: false };
  const { backgroundPaperElevation } =
    'backgroundPaperElevation' in props
      ? props
      : { backgroundPaperElevation: DEFAULT_BACKGROUND_PAPER_ELEVATION };

  const classes = useTableStyles();

  const count = pager?.metaCount || tableData.length;

  const getRowsPerPage = useCallback(
    (externalPager?: ExternallyControlledPager) => {
      if (externalPager?.rowsPerPage) {
        return DEFAULT_PAGE_SIZE > externalPager?.rowsPerPage
          ? DEFAULT_PAGE_SIZE
          : externalPager?.rowsPerPage;
      }

      return DEFAULT_PAGE_SIZE;
    },
    [DEFAULT_PAGE_SIZE]
  );

  const [internalPagerPageNumber, setInternalPagerPageNumber] = useState(0);

  const pageNumber = pager?.pageNumber || internalPagerPageNumber;
  const [rowsPerPage, setRowsPerPage] = useState(getRowsPerPage(pager));

  const handlePageChange = useCallback(
    (event: unknown, newPage: number) => {
      if (pager && isFunction(pager.onPageNumberChanged)) {
        pager.onPageNumberChanged(newPage);
      } else {
        setInternalPagerPageNumber(newPage);
      }
    },
    [pager, setInternalPagerPageNumber]
  );

  const handleRowsPerPageChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const newRowsPerPage = parseInt(event.target.value, 10);

      if (isNaN(newRowsPerPage)) {
        throw new Error(`invalid setting for newRowsPerPage:${newRowsPerPage}`);
      }

      setRowsPerPage(newRowsPerPage);
      if (pager && isFunction(pager.onRowsPerPageChanged)) {
        pager.onRowsPerPageChanged(newRowsPerPage);
      }

      if (pager && isFunction(pager.onPageNumberChanged)) {
        pager.onPageNumberChanged(0);
      } else {
        setInternalPagerPageNumber(0);
      }
    },
    [pager, setInternalPagerPageNumber, setRowsPerPage]
  );

  const [order, setOrder] = useState<Order>(sorter?.order || 'asc');
  const [orderBy, setOrderBy] = useState(sorter?.orderBy || '');
  const isSortable = isExternallyControlledSorter(sorter);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    if (sorter && isFunction(sorter.onSortOrderChanged)) {
      sorter.onSortOrderChanged(property, newOrder);
    }
  };

  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  // mbildner: include an empty cell if the table has collapsible rows
  // because the open/closed toggle takes up space to the left of the first column
  const headerRowSpacer = CollapsibleComponent ? <TableCell /> : null;

  const rowsPaged = isExternallyControlledPager(pager)
    ? tableData
    : tableData.slice(
        (rowsPerPage || DEFAULT_PAGE_SIZE) * pageNumber,
        (rowsPerPage || DEFAULT_PAGE_SIZE) * (pageNumber + 1)
      );

  const rows = rowsPaged;

  const [rowId, setRowId] = useState<any>(null);

  const selectRow = (id: number) => {
    const rowIndex = rows.findIndex(row => row.sponsorPlanId === id);
    rows[rowIndex].selected = true;
    setRowId(id);
    if (receivePlan) {
      receivePlan(rows[rowIndex]);
    }
  };

  return (
    <>
      {!disablePagination && headerPagination && (
        <TablePagination
          component='div'
          count={count}
          data-testid='table-pagination-controls'
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={count === 0 ? 0 : pageNumber}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={getRowPerPageOptions(count)}
          sx={tablePaginationSx}
        />
      )}
      <Paper
        className={
          useSquareBottomContainer
            ? classes.tableBackgroundSquareBottomPaper
            : undefined
        }
        elevation={rootPaperElevation}
        square={false}>
        {headerComponent || null}
        <TableContainer
          component={
            useDivAsBackground
              ? 'div'
              : (backgroundProps: any) => (
                  <Paper
                    {...backgroundProps}
                    elevation={backgroundPaperElevation}
                  />
                )
          }>
          <Table
            aria-label='collapsible table'
            data-testid={dataTestId ? dataTestId : null}>
            <TableHead>
              {groupedHeader || null}
              <TableRow>
                {headerRowSpacer}
                {columns
                  .filter(column => !column.hide)
                  .map(column => (
                    <TableCell
                      align={column.align}
                      className={clsx(
                        classes.tableHeaders,
                        column.cellClassName ? column.cellClassName : ''
                      )}
                      key={column.field}
                      sx={column.sx}>
                      {isSortable && column.sortable !== false ? (
                        <TableSortLabel
                          active={sorter?.orderBy === column.field}
                          direction={orderBy === column.field ? order : 'asc'}
                          onClick={createSortHandler(column.field)}>
                          {column.headerName}
                        </TableSortLabel>
                      ) : (
                        column.headerName
                      )}
                    </TableCell>
                  ))}
              </TableRow>
              <Fade in={isLoading}>
                <TableRow>
                  <TableCell
                    colSpan={columns.length + (headerRowSpacer == null ? 1 : 0)}
                    style={{ border: 'none', padding: 0 }}>
                    <LinearProgress sx={{ width: '100%' }} />
                  </TableCell>
                </TableRow>
              </Fade>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => {
                return (
                  <CollapsibleTableRow
                    cellComponent={CellComponent}
                    collapsibleComponent={CollapsibleComponent}
                    collapsibleToggleSx={collapsibleToggleSx}
                    columns={columns}
                    expanded={expandAll}
                    hideCollapsibleToggle={hideCollapsibleToggle}
                    isModal={Boolean(isModal)}
                    key={`${i}-${
                      (primaryKey && row[primaryKey]) ||
                      row.id ||
                      row.ucid ||
                      row.psaId ||
                      row.sponsorPlanId ||
                      row.tpaId ||
                      row.fundLineupId ||
                      row.goalSeriesId ||
                      row.riskSeriesId ||
                      row.targetSeriesId ||
                      row.name ||
                      row.modelId ||
                      row.errorId
                    }`}
                    onClick={
                      isModal ? () => selectRow(row.sponsorPlanId) : () => {}
                    }
                    row={row}
                    selected={
                      row.sponsorPlanId === rowId ||
                      (isSelectedRow ? isSelectedRow(row) : false)
                    }
                  />
                );
              })}
            </TableBody>
            {footerComponent}
          </Table>
          {(rows.length === 0 && noDataPlaceholderComponent) || null}
        </TableContainer>
      </Paper>
      {!disablePagination && !headerPagination && (
        <TablePagination
          component='div'
          count={count}
          data-testid='table-pagination-controls'
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={count === 0 ? 0 : pageNumber}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={getRowPerPageOptions(count)}
          sx={tablePaginationSx}
        />
      )}
    </>
  );
};

export default CollapsibleTable;
export type { CollapsibleRowProps, CellComponentProps, Order };
