import { useUserToken } from '@/contexts/UserTokenContext';
import { ResultProps } from '@/models/GlobalSearchDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { Search } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import { InputAdornment, Paper, TextField, Theme } from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';

import React, { useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';

import GlobalSearchAdvisorsResult from './GlobalSearchResults/GlobalSearchAdvisorsResult.component';
import GlobalSearchFundLineupResult from './GlobalSearchResults/GlobalSearchFundLineupResult.component';
import GlobalSearchGoalSeriesResult from './GlobalSearchResults/GlobalSearchGoalSeriesResult.component';
import GlobalSearchParticipantsResult from './GlobalSearchResults/GlobalSearchParticipantsResult.component';
import GlobalSearchPlansResult from './GlobalSearchResults/GlobalSearchPlansResult.component';
import GlobalSearchPooledPlansResult from './GlobalSearchResults/GlobalSearchPooledPlansResult.component';
import GlobalSearchProgramsResult from './GlobalSearchResults/GlobalSearchProgramsResult.component';
import GlobalSearchRiskSeriesResult from './GlobalSearchResults/GlobalSearchRiskSeriesResult.component';
import GlobalSearchTargetSeriesResult from './GlobalSearchResults/GlobalSearchTargetSeriesResult.component';

interface GlobalSearchProps {
  isOpen: boolean;
  placeholder: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  clearItem: {
    cursor: 'pointer',
    paddingRight: theme.spacing(3.5)
  },
  globalSearchResult: {
    backgroundColor: grey[50],
    borderRadius: '0 0 2px 2px',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.24)',
    marginLeft: theme.spacing(10),
    maxHeight: '1000%',
    minHeight: '200%',
    overflowY: 'scroll',
    position: 'absolute',
    width: theme.spacing(80)
  },
  searchIcon: {
    paddingLeft: theme.spacing(1.2)
  },
  searchInput: {
    backgroundColor: grey[50],
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '2px 2px 0 0',
    color: theme.palette.info.main,
    height: theme.spacing(5),
    marginLeft: theme.spacing(10),
    paddingTop: theme.spacing(0.8),
    width: theme.spacing(80)
  }
}));

const GlobalSearch = (props: GlobalSearchProps): JSX.Element => {
  const classes = useStyles();

  const { isOpen, placeholder } = props;

  const [searchTerm, setSearchTerm] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [abortController, setAbortController] = useState(new AbortController());
  const searchTermNormalized = useMemo(() => {
    const searchTermNoCommas = searchTerm.replaceAll(',', '');
    return Number(searchTermNoCommas) ? `${searchTermNoCommas}` : searchTerm;
  }, [searchTerm]);
  const [debouncedSearchTerm] = useDebounce(searchTermNormalized, 500);

  const componentResultProps = {
    abortSignal: abortController.signal,
    isFocused,
    searchTerm: debouncedSearchTerm,
    setSearchTerm
  };

  const { token } = useUserToken();
  const canReadAdvisor = Boolean(
    token?.permissions.includes(FeatureLevelPermissions.READ_ADVISOR)
  );
  const canReadInvestments = Boolean(
    token?.permissions.includes(FeatureLevelPermissions.READ_INVESTMENTS)
  );

  const results = [
    (componentProps: ResultProps) => (
      <GlobalSearchParticipantsResult {...componentProps} />
    ),
    (componentProps: ResultProps) => (
      <GlobalSearchPlansResult {...componentProps} />
    ),
    (componentProps: ResultProps) => (
      <GlobalSearchPooledPlansResult {...componentProps} />
    )
  ];

  if (canReadAdvisor) {
    results.push((componentProps: ResultProps) => (
      <GlobalSearchAdvisorsResult {...componentProps} />
    ));
  }
  if (canReadInvestments) {
    results.push(
      (componentProps: ResultProps) => (
        <GlobalSearchProgramsResult {...componentProps} />
      ),
      (componentProps: ResultProps) => (
        <GlobalSearchFundLineupResult {...componentProps} />
      ),
      (componentProps: ResultProps) => (
        <GlobalSearchTargetSeriesResult {...componentProps} />
      ),
      (componentProps: ResultProps) => (
        <GlobalSearchRiskSeriesResult {...componentProps} />
      ),
      (componentProps: ResultProps) => (
        <GlobalSearchGoalSeriesResult {...componentProps} />
      )
    );
  }

  return (
    <div>
      <TextField
        InputProps={{
          'aria-placeholder': placeholder,
          disableUnderline: true,
          endAdornment: (
            <InputAdornment
              className={classes.clearItem}
              onClick={() => setSearchTerm('')}
              position='end'>
              <ClearIcon />
            </InputAdornment>
          ),
          placeholder,
          startAdornment: (
            <InputAdornment className={classes.searchIcon} position='start'>
              <Search />
            </InputAdornment>
          )
        }}
        className={classes.searchInput}
        data-testid='global-search-input'
        onBlur={() => {
          setIsFocused(false);
        }}
        onChange={e => {
          abortController.abort();
          setAbortController(new AbortController());
          setSearchTerm(e.target.value);
        }}
        onFocus={() => {
          setIsFocused(true);
        }}
        size='small'
        value={searchTerm}
        variant='standard'
      />
      {(isOpen || isFocused) && searchTerm.trim().length > 0 && (
        <Paper
          className={classes.globalSearchResult}
          data-testid='global-search-result'
          id='global-search-paper-result'>
          {results.map((component, index) =>
            component({ ...componentResultProps, key: index })
          )}
        </Paper>
      )}
    </div>
  );
};

export default GlobalSearch;
