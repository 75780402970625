import { useGetPlanScheduledChanges } from '@/components/scheduled-changes/useGetPlanScheduledChanges';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { ScheduledChangePayloadItem } from '@/models/ScheduledChangesDTO.model';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Stack, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { Form, useFormikContext } from 'formik';
import { FC, useMemo } from 'react';

import { appendScheduledChangesToPayload } from './utils';

const useStyles = makeStyles((theme: Theme) => ({
  currentAndScheduledArrow: {
    color: theme.palette.action.active,
    fontSize: theme.typography.pxToRem(18),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  currentAndScheduledContainer: {
    alignItems: 'center',
    display: 'flex',
    width: 'fit-content'
  }
}));

type CurrentAndScheduledProps = {
  current: any;
  scheduled: any;
  scheduledFor?: string;
  'data-testid'?: string;
};
const CurrentAndScheduled: FC<CurrentAndScheduledProps> = props => {
  const classes = useStyles();
  let currentDisplay = String(props.current);
  if (
    props.current === null ||
    props.current === undefined ||
    props.current === EMPTY_FIELD_PLACEHOLDER
  ) {
    currentDisplay = '';
  }

  let scheduledDisplay = String(props.scheduled);
  if (
    props.scheduled === null ||
    props.scheduled === undefined ||
    props.scheduled === EMPTY_FIELD_PLACEHOLDER
  ) {
    scheduledDisplay = '';
  }

  return (
    <Box
      className={classes.currentAndScheduledContainer}
      data-testid={props['data-testid']}>
      <Typography
        color={theme =>
          currentDisplay
            ? theme.palette.text.primary
            : theme.palette.text.disabled
        }
        variant='body2'>
        {currentDisplay || '(unset)'}
      </Typography>
      <ArrowForwardIcon className={classes.currentAndScheduledArrow} />
      <Typography
        color={theme =>
          props.scheduledFor
            ? theme.palette.warning.dark
            : scheduledDisplay
              ? theme.palette.text.primary
              : theme.palette.text.disabled
        }
        variant='body2'>
        {scheduledDisplay || '(unset)'}
        {props.scheduledFor && ` - Scheduled for ${props.scheduledFor}`}
      </Typography>
    </Box>
  );
};

type ScheduleChangesFormProps<T> = {
  sponsorPlanId: string | number;
  makeScheduledChangesPayload: (
    currentValues: Record<string, any>,
    scheduledValues: Record<string, any>,
    config?: T
  ) => ScheduledChangePayloadItem[];
  makeScheduledChangesPayloadConfig?: T;
  changeType: string;
};

export const ScheduleChangesForm = <T,>(props: ScheduleChangesFormProps<T>) => {
  const { initialValues, values } = useFormikContext<Record<string, any>>();

  const scheduledChanges = useGetPlanScheduledChanges({
    planId: +props.sponsorPlanId
  });

  const payloadWithScheduledChanges = useMemo(
    () =>
      scheduledChanges.data
        ? appendScheduledChangesToPayload(
            props.makeScheduledChangesPayload(
              initialValues,
              values,
              props.makeScheduledChangesPayloadConfig
            ),
            { data: scheduledChanges.data },
            props.changeType
          )
        : [],
    [
      initialValues,
      scheduledChanges.data,
      values,
      props.makeScheduledChangesPayloadConfig,
      props.changeType
    ]
  );

  return (
    <Form data-testid='schedule-changes-form'>
      <Stack spacing={2}>
        {payloadWithScheduledChanges
          .filter(payloadItem => payloadItem.uiLabel)
          .map(field => (
            <Box
              data-testid={`payload-${field.fieldName}`}
              display='flex'
              flexDirection='column'
              key={field.fieldName}>
              <Typography
                color={theme => theme.palette.text.primary}
                data-testid='ui-label'
                fontWeight={500}
                mb={0.5}
                variant='body2'>
                {field.uiLabel}
              </Typography>
              <Stack mb={1} spacing={1}>
                <CurrentAndScheduled
                  current={field.uiCurrentValue}
                  data-testid={`current-and-scheduled-${field.fieldName}`}
                  scheduled={field.uiScheduledValue}
                />
                {field.scheduledChanges.map((change, index) => (
                  <CurrentAndScheduled
                    current={change.uiCurrentValue}
                    data-testid={`current-and-scheduled-${field.fieldName}-history-${index}`}
                    key={field.fieldName + change.scheduledFor + index}
                    scheduled={change.uiScheduledValue}
                    scheduledFor={change.scheduledFor}
                  />
                ))}
              </Stack>
            </Box>
          ))}
      </Stack>
    </Form>
  );
};
