import { memoize } from 'lodash';

export const memoizedDebounce = (
  func: (...args: any[]) => Promise<boolean>,
  wait: number
): (() => Promise<any>) => {
  let timeout: NodeJS.Timeout | null;
  const memoizedMethod = memoize(func);
  return function (...query: any[]) {
    return new Promise(async resolve => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      timeout = setTimeout(async () => {
        const response = await memoizedMethod(...query);
        resolve(response);
        timeout = null;
      }, wait);
    });
  };
};
