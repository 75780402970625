import { DocumentDto } from '@/models';
import {
  CUSTOM,
  FUND,
  MANAGED,
  MS_MANAGED,
  Program,
  ProgramRequest,
  ProgramRule,
  RISK,
  RuleType,
  RuleValue,
  TARGET
} from '@/models/ops/investments/Program.model';
import InMemoryFileDownloadService from '@/services/InMemoryFileDownloadService.service';
import InvestmentService from '@/services/Investment.service';
import { ProgramService } from '@/services/ops/investments/Program.service';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';

import { FormikErrors, getIn, useFormik } from 'formik';
import { useEffect } from 'react';
import * as yup from 'yup';

const useStyles = makeStyles((theme: Theme) => ({
  checkBoxGroup: {
    paddingLeft: theme.spacing(1)
  },
  fieldContainer: {
    marginBottom: theme.spacing(2)
  },
  textfield: {
    width: '100%'
  }
}));

type InvestmentTypeIds = {
  fundLineupId?: number;
  goalSeriesId?: number;
  riskSeriesId?: number;
  targetDateSeriesId?: number;
};

export interface ProgramDetailsProps {
  program: ProgramRequest;
  setProgramValueCallback: (field: string, value: any) => void;
  formikErrors: FormikErrors<ProgramRequest>;
  typeIds?: InvestmentTypeIds;
}

const DYNAMIC = 'Dynamic';
const FULL = 'Full';

const getSchema = (initialProgram?: Program) =>
  yup.object().shape({
    defaultModelId: yup.number().when('programRule', {
      is: (value: ProgramRule[]) =>
        value.find(
          v =>
            v.ruleValue === RuleValue.model &&
            v.ruleType === RuleType.default_type
        ),
      then: yup.number().required('Default Model ID is required')
    }),
    managedAccount: yup.object().shape(
      {
        fallbackRule: yup.string().when('minimumAge', {
          is: (value: number) => Boolean(value),
          otherwise: yup.string(),
          then: yup
            .string()
            .required(
              'Investment for Participants Under Minimum Age is required'
            )
        }),
        minimumAge: yup.number().when('fallbackRule', {
          is: (value: RuleValue) => Boolean(value),
          otherwise: yup.number(),
          then: yup
            .number()
            .min(0, 'Incorrect Minimum Age (allowed 0-99)')
            .max(99, 'Incorrect Maximum Age (allowed 0-99)')
            .required('Minimum Age is required')
        })
      },
      [['minimumAge', 'fallbackRule']]
    ),
    name: yup
      .string()
      .required('Program Name is required')
      .test(
        'check program name',
        'The program name is already taken',
        value => {
          if (value === undefined || value === initialProgram?.name)
            return true;
          return ProgramService.getProgramsPage(1, 50, value, 'name', 'asc')
            .then(
              programList =>
                !programList.data.some(program => program.name === value)
            )
            .catch(() => false);
        }
      ),
    programRule: yup
      .array()
      .test(
        'check default_value',
        'Default Investment Type is required',
        values => {
          return Boolean(
            values?.find(
              v => (v.ruleType as RuleType) === RuleType.default_type
            )
          );
        }
      )
  });

export const validationSchema = getSchema();

export const validationSchemaWithInitial = (
  initialProgram: Program
): yup.AnyObjectSchema => getSchema(initialProgram);

const ProgramDetails = (props: ProgramDetailsProps): JSX.Element => {
  const classes = useStyles();
  const { program, setProgramValueCallback, formikErrors } = props;

  const { isFetching: isXLSDownloading, refetch: onExportButtonClick } =
    useQuery<DocumentDto>(
      ['InvestmentService.getInvestmentOptionsDownload', program.programId],
      () => InvestmentService.getInvestmentOptionsDownload(program.programId),
      {
        cacheTime: 0,
        enabled: false,
        onSuccess: dto => {
          InMemoryFileDownloadService.triggerFileDownload(
            dto.base64Data,
            dto.originalFileName
          );
        }
      }
    );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...program,
      defaultInvestmentSwitch: program.managedAccount ? DYNAMIC : FULL
    },
    onSubmit: () => {}
  });

  const isRuleValue = (ruleValue: RuleValue) => {
    return Boolean(
      program.programRule.find(rule => rule.ruleValue === ruleValue)
    );
  };

  const isRuleValueAndType = (ruleValue: RuleValue, ruleType: RuleType) => {
    return Boolean(
      program.programRule.find(
        rule => rule.ruleValue === ruleValue && rule.ruleType === ruleType
      )
    );
  };

  useEffect(() => formik.setErrors(formikErrors));

  return (
    <>
      <div className={classes.fieldContainer}>
        <TextField
          className={classes.textfield}
          error={Boolean(formik.errors.name)}
          helperText={formik.errors.name}
          inputProps={{
            'data-testid': 'programNameInput'
          }}
          label='Program Name'
          name='name'
          onChange={e => {
            setProgramValueCallback('name', e.target.value);
          }}
          value={program.name}
          variant='filled'
        />
      </div>
      <div className={classes.fieldContainer}>
        <TextField
          InputProps={{
            readOnly: true
          }}
          className={classes.textfield}
          defaultValue='Vestwell'
          label='Program Owner'
          variant='filled'
        />
      </div>
      <div className={classes.fieldContainer}>
        <FormControl>
          <FormLabel component='legend'>Available Investment Types</FormLabel>
          <FormGroup className={classes.checkBoxGroup}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isRuleValue(RuleValue.menu)}
                  disabled={Boolean(props.typeIds?.fundLineupId)}
                  onChange={e => {
                    setProgramValueCallback(`programRule`, {
                      checked: e.target.checked,
                      ruleType: RuleType.available_type,
                      ruleValue: RuleValue.menu
                    });
                  }}
                />
              }
              label={FUND}
            />
            {props.program.has401kManager && (
              <FormControlLabel
                control={<Checkbox checked disabled />}
                label={MS_MANAGED}
              />
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={isRuleValue(RuleValue.target)}
                  disabled={Boolean(props.typeIds?.targetDateSeriesId)}
                  onChange={e => {
                    setProgramValueCallback(`programRule`, {
                      checked: e.target.checked,
                      ruleType: RuleType.available_type,
                      ruleValue: RuleValue.target
                    });
                  }}
                />
              }
              label={TARGET}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isRuleValue(RuleValue.risk)}
                  disabled={Boolean(props.typeIds?.riskSeriesId)}
                  onChange={e => {
                    setProgramValueCallback(`programRule`, {
                      checked: e.target.checked,
                      ruleType: RuleType.available_type,
                      ruleValue: RuleValue.risk
                    });
                  }}
                />
              }
              label={RISK}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isRuleValue(RuleValue.dynamic_goal)}
                  disabled={Boolean(props.typeIds?.goalSeriesId)}
                  onChange={e => {
                    setProgramValueCallback(`programRule`, {
                      checked: e.target.checked,
                      ruleType: RuleType.available_type,
                      ruleValue: RuleValue.dynamic_goal
                    });
                  }}
                />
              }
              label={MANAGED}
            />
          </FormGroup>
        </FormControl>
      </div>
      <div className={classes.fieldContainer}>
        <FormControl
          className={classes.textfield}
          error={Boolean(formik.errors.programRule)}
          variant='filled'>
          <InputLabel>Default Investment Type</InputLabel>
          <Select
            data-testid='my-wrapper'
            onChange={e => {
              setProgramValueCallback(`programRule`, {
                checked: true,
                ruleType: RuleType.default_type,
                ruleValue: e.target.value as RuleValue
              });
            }}
            value={
              program.programRule.find(
                rule => rule.ruleType === RuleType.default_type
              )?.ruleValue || ''
            }>
            {isRuleValue(RuleValue.ms_managed) && (
              <MenuItem value={RuleValue.ms_managed}>{MS_MANAGED}</MenuItem>
            )}
            {isRuleValue(RuleValue.target) && (
              <MenuItem value={RuleValue.target}>{TARGET}</MenuItem>
            )}
            {isRuleValue(RuleValue.dynamic_goal) && (
              <MenuItem value={RuleValue.dynamic_goal}>{MANAGED}</MenuItem>
            )}
            <MenuItem value={RuleValue.model}>{CUSTOM}</MenuItem>
          </Select>
          <FormHelperText>
            {formik.errors.programRule?.toString() ?? ''}
          </FormHelperText>
        </FormControl>
      </div>
      {isRuleValueAndType(RuleValue.model, RuleType.default_type) && (
        <div className={classes.fieldContainer}>
          <TextField
            className={classes.textfield}
            error={Boolean(formik.errors.defaultModelId)}
            helperText={formik.errors.defaultModelId}
            label='Default Model ID'
            onChange={e =>
              setProgramValueCallback(`defaultModelId`, e.target.value)
            }
            type='number'
            value={program.defaultModelId}
            variant='filled'
          />
        </div>
      )}
      {isRuleValueAndType(RuleValue.dynamic_goal, RuleType.default_type) && (
        <div className={classes.fieldContainer}>
          <FormControl className={classes.textfield} variant='filled'>
            <InputLabel>Default Investment Switch</InputLabel>
            <Select
              name='defaultInvestmentSwitch'
              onChange={formik.handleChange}
              value={formik.values.defaultInvestmentSwitch}>
              <MenuItem value={DYNAMIC}>{DYNAMIC}</MenuItem>
              <MenuItem value={FULL}>{FULL}</MenuItem>
            </Select>
          </FormControl>
        </div>
      )}
      {Boolean(
        program.programRule.find(
          rule =>
            rule.ruleValue === RuleValue.dynamic_goal &&
            rule.ruleType === RuleType.default_type
        )
      ) &&
        formik.values.defaultInvestmentSwitch === DYNAMIC && (
          <>
            <div className={classes.fieldContainer}>
              <TextField
                className={classes.textfield}
                error={Boolean(
                  getIn(formik.errors, 'managedAccount.minimumAge')
                )}
                helperText={getIn(formik.errors, 'managedAccount.minimumAge')}
                label='Minimum Age'
                onChange={e => {
                  setProgramValueCallback(`minimumAge`, e.target.value);
                }}
                type='number'
                value={program.managedAccount?.minimumAge ?? ''}
                variant='filled'
              />
            </div>
            <div className={classes.fieldContainer}>
              <FormControl
                className={classes.textfield}
                error={Boolean(
                  getIn(formik.errors, 'managedAccount.fallbackRule')
                )}
                variant='filled'>
                <InputLabel>
                  Investment for Participants Under Minimum Age
                </InputLabel>
                <Select
                  disabled={!isRuleValue(RuleValue.target)}
                  onChange={e =>
                    setProgramValueCallback(`fallbackRule`, e.target.value)
                  }
                  value={program.managedAccount?.fallbackRule || ''}>
                  {isRuleValue(RuleValue.target) && (
                    <MenuItem value={RuleValue.target}>{TARGET}</MenuItem>
                  )}
                </Select>
                <FormHelperText>
                  {getIn(formik.errors, 'managedAccount.fallbackRule')}
                </FormHelperText>
              </FormControl>
            </div>
          </>
        )}
      {props.typeIds && (
        <Button
          disabled={isXLSDownloading}
          onClick={() => onExportButtonClick()}
          variant='outlined'>
          Export xls
        </Button>
      )}
    </>
  );
};

export default ProgramDetails;
