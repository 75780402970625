import { useSnackbar } from '@/contexts/SnackBarContext';
import { UpdateSponsorDto, UpdateSponsorPayload } from '@/models';
import SponsorService from '@/services/Sponsor.service';
import {
  useMutation,
  UseMutationResult,
  useQueryClient
} from '@tanstack/react-query';

const updateSponsor = (updateSponsorDto: UpdateSponsorDto) => {
  return SponsorService.updateSponsor(
    updateSponsorDto.data.id,
    updateSponsorDto
  );
};

const useUpdateSponsor = (
  snackbarAlert = true
): UseMutationResult<
  UpdateSponsorPayload,
  Error,
  UpdateSponsorDto,
  () => void
> => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation<UpdateSponsorPayload, Error, UpdateSponsorDto, () => void>(
    updateSponsorDto => updateSponsor(updateSponsorDto),
    {
      onError: () => {
        if (snackbarAlert) {
          showSnackbar({ message: 'Failed!', severity: 'error' });
        }
      },
      onSuccess: async (_, variables) => {
        await queryClient.refetchQueries([
          'SponsorService.getSponsorById',
          variables.data.id.toString()
        ]);
        if (snackbarAlert) {
          showSnackbar({ message: 'Success!', severity: 'success' });
        }
      }
    }
  );
};

export default useUpdateSponsor;
