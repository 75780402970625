import DatePickerForm from '@/components/date-picker/DatePickerForm';
import LinearLoading from '@/components/linear-loading';
import SimpleNumberField from '@/components/simple-number-field/SimpleNumberField';
import EARLIEST_POSSIBLE_DATE from '@/consts/dates.constants';
import {
  CreateParticipantHours,
  ParticipantHoursCreatedDto,
  ParticipantHoursOfServiceDto
} from '@/models';
import ParticipantService from '@/services/Participant.service';
import { getCreateHoursValidationSchema } from '@/utils/validations/HoursOfServiceValidation.schema';
import {
  Alert,
  Box,
  Button,
  Collapse,
  FormControl,
  FormHelperText,
  TableCell,
  TableRow,
  Theme
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { UseMutateFunction, useQuery } from '@tanstack/react-query';

import clsx from 'clsx';
import dayjs from 'dayjs';
import Decimal from 'decimal.js';
import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSharedStyles } from './styles';
import { FORMATTED_EARLIEST_START_DATE, getCurrentDate } from './utils';

const useStyles = makeStyles((theme: Theme) => ({
  fieldTooltip: {
    maxWidth: '100%'
  },
  formControl: {
    width: 240
  },
  hoursFormControl: {
    width: 270
  },
  warning: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.warning.light
    },
    alignItems: 'center'
  }
}));

interface CreateHoursLineProps {
  isOpen: boolean;
  columnsAmount: number;
  onSubmit: UseMutateFunction<
    ParticipantHoursCreatedDto,
    unknown,
    CreateParticipantHours,
    unknown
  >;
  isSubmitting: boolean;
  participantId: string;
  cancelCreateHours: () => void;
  isLoading: boolean;
  totalRecords?: number;
  planId?: number;
  planEffectiveDate?: string;
}

interface CreateHoursFormValues {
  hours: string;
  startDate: string;
  endDate: string;
}

const DATE_PICKER_FORMAT = 'MM/DD/YYYY';
const MIN_PAGE_SIZE_FOR_VALIDATION = 1;
const MIN_PAGE_NUMBER_FOR_VALIDATION = 1;

const getInitialEndDate = (
  latestRecord: ParticipantHoursOfServiceDto | undefined,
  planEffectiveDate?: string
): string => {
  const currentDate = getCurrentDate(DATE_PICKER_FORMAT);
  return !latestRecord?.data.length &&
    !!planEffectiveDate &&
    dayjs(planEffectiveDate).isAfter(currentDate)
    ? dayjs(planEffectiveDate).format(DATE_PICKER_FORMAT)
    : currentDate;
};

const getInitialStartDate = (
  latestRecord: ParticipantHoursOfServiceDto | undefined,
  planEffectiveDate?: string
): string => {
  const currentDate = getCurrentDate(DATE_PICKER_FORMAT);
  if (!latestRecord?.data.length)
    return !planEffectiveDate
      ? currentDate
      : dayjs(planEffectiveDate).format(DATE_PICKER_FORMAT);

  const latestEndDate = dayjs(latestRecord.data[0].endDate);

  return latestEndDate.isBefore(currentDate)
    ? latestEndDate.add(1, 'day').format(DATE_PICKER_FORMAT)
    : currentDate;
};

const CreateHoursLine: React.FunctionComponent<CreateHoursLineProps> = (
  props: CreateHoursLineProps
) => {
  const {
    isOpen,
    onSubmit,
    isSubmitting,
    cancelCreateHours,
    columnsAmount,
    planEffectiveDate,
    participantId,
    planId,
    totalRecords,
    isLoading
  } = props;
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const [isWarningValid, setWarningValid] = useState(false);
  const [isStartDateInvalid, setStartDateInvalid] = useState(false);
  const [isEndDateInvalid, setEndDateInvalid] = useState(false);

  useEffect(() => {
    setWarningValid(false);
  }, [isOpen]);

  const participantLatestRecordQuery = useQuery<
    ParticipantHoursOfServiceDto | undefined
  >(
    [
      'ParticipantService.getParticipantHoursOfService',
      participantId,
      planId,
      totalRecords,
      1,
      FORMATTED_EARLIEST_START_DATE,
      getCurrentDate()
    ],
    async () => {
      if (!totalRecords) return undefined;
      const participantHours: ParticipantHoursOfServiceDto =
        await ParticipantService.getParticipantHoursOfService(
          +participantId,
          planId,
          totalRecords,
          1,
          FORMATTED_EARLIEST_START_DATE,
          getCurrentDate()
        );
      return participantHours;
    },
    {
      enabled: !!planId && !!participantId && !!totalRecords,
      keepPreviousData: true,
      staleTime: Infinity
    }
  );

  const formInitialValues = useMemo(
    () => ({
      endDate: getInitialEndDate(
        participantLatestRecordQuery.data,
        planEffectiveDate
      ),
      hours: '',
      startDate: getInitialStartDate(
        participantLatestRecordQuery.data,
        planEffectiveDate
      )
    }),
    [participantLatestRecordQuery.data, planEffectiveDate]
  );

  const validationSchema = getCreateHoursValidationSchema();

  const validateInterval = useCallback(
    async (startDate: string, endDate: string) => {
      const interval = await ParticipantService.getParticipantHoursOfService(
        +participantId,
        planId,
        MIN_PAGE_NUMBER_FOR_VALIDATION,
        MIN_PAGE_SIZE_FOR_VALIDATION,
        startDate,
        endDate
      );
      if (interval.meta.count) {
        return setWarningValid(true);
      }
      setWarningValid(false);
    },
    [participantId, planId]
  );

  useEffect(() => {
    validateInterval(formInitialValues.startDate, formInitialValues.endDate);
  }, [isOpen, validateInterval, formInitialValues]);

  const handleFormSubmit = (values: CreateHoursFormValues) => {
    if (isStartDateInvalid || isEndDateInvalid) return;
    const formattedData: CreateParticipantHours = {
      endDate: dayjs(values.endDate).format('YYYY-MM-DD'),
      hours: +new Decimal(values.hours).toFixed(2),
      startDate: dayjs(values.startDate).format('YYYY-MM-DD')
    };
    onSubmit(formattedData);
  };

  return (
    <TableRow>
      <TableCell
        className={clsx(
          sharedClasses.collapseColumn,
          isOpen && sharedClasses.openCollapseColumn
        )}
        colSpan={columnsAmount}>
        <Collapse in={isOpen} timeout='auto' unmountOnExit>
          {isLoading || participantLatestRecordQuery.isFetching ? (
            <Box padding={1.5}>
              <LinearLoading />
            </Box>
          ) : (
            <Formik
              initialValues={formInitialValues}
              onSubmit={handleFormSubmit}
              validateOnMount
              validationSchema={validationSchema}>
              {({ handleSubmit, errors, values, setFieldValue, isValid }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <Box
                      display='flex'
                      flexDirection='column'
                      gap={2}
                      marginBottom={3}
                      marginTop={3}>
                      <Box display='flex' flexWrap='wrap' gap={1.5}>
                        <FormControl
                          className={classes.formControl}
                          error={
                            Boolean(errors.startDate) || isStartDateInvalid
                          }>
                          <DatePickerForm
                            className={sharedClasses.datePicker}
                            data-testid='create-hours-start-date'
                            format={DATE_PICKER_FORMAT}
                            handleError={error => {
                              if (error === 'invalidDate') {
                                setStartDateInvalid(true);
                                return setWarningValid(false);
                              }
                              setStartDateInvalid(false);
                            }}
                            inputProps={{
                              autoComplete: 'off',
                              className: sharedClasses.pickerInput
                            }}
                            label='Start date'
                            maxDate={dayjs(values.endDate)}
                            minDate={dayjs(EARLIEST_POSSIBLE_DATE)}
                            name='startDate'
                            onChange={(event: {
                              target: { name: string; value: string };
                            }) => {
                              validateInterval(
                                event.target.value,
                                values.endDate
                              );
                            }}
                            value={values.startDate}
                            variant='outlined'
                          />
                        </FormControl>
                        <FormControl
                          className={classes.formControl}
                          error={Boolean(errors.endDate) || isEndDateInvalid}>
                          <DatePickerForm
                            className={sharedClasses.datePicker}
                            data-testid='create-hours-end-date'
                            format={DATE_PICKER_FORMAT}
                            handleError={error => {
                              if (error === 'invalidDate') {
                                setEndDateInvalid(true);
                                return setWarningValid(false);
                              }
                              setEndDateInvalid(false);
                            }}
                            inputProps={{
                              autoComplete: 'off',
                              className: sharedClasses.pickerInput
                            }}
                            label='End date'
                            minDate={dayjs(values.startDate)}
                            name='endDate'
                            onChange={(event: {
                              target: { name: string; value: string };
                            }) => {
                              validateInterval(
                                values.startDate,
                                event.target.value
                              );
                            }}
                            value={values.endDate}
                            variant='outlined'
                          />
                        </FormControl>
                        <FormControl className={classes.hoursFormControl}>
                          <SimpleNumberField
                            allowNegative={false}
                            className={sharedClasses.inputField}
                            error={Boolean(errors.hours)}
                            inputProps={{
                              autoComplete: 'off'
                            }}
                            label='Hours'
                            name='hours'
                            onChange={value =>
                              setFieldValue('hours', value, true)
                            }
                            precision={2}
                            testId='create-hours-input'
                            value={values.hours}
                          />
                          <FormHelperText
                            className={clsx(
                              sharedClasses.inputTooltip,
                              classes.fieldTooltip
                            )}
                            error={Boolean(errors.hours)}>
                            {errors.hours || ' '}
                          </FormHelperText>
                        </FormControl>
                      </Box>

                      <Collapse
                        in={isWarningValid}
                        timeout='auto'
                        unmountOnExit>
                        <Alert className={classes.warning} severity='warning'>
                          This employment interval may exist already, please
                          verify
                        </Alert>
                      </Collapse>

                      <Box
                        alignContent='center'
                        alignItems='center'
                        display='flex'
                        gap={1}
                        height={40}>
                        <Button
                          className={sharedClasses.button}
                          data-testid='cancel-create-hours-btn'
                          disabled={isSubmitting}
                          onClick={cancelCreateHours}>
                          CANCEL
                        </Button>
                        <Button
                          className={sharedClasses.button}
                          data-testid='save-create-hours-btn'
                          disabled={
                            !isValid ||
                            isSubmitting ||
                            isStartDateInvalid ||
                            isEndDateInvalid
                          }
                          onClick={() => handleSubmit()}>
                          SAVE
                        </Button>
                      </Box>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          )}
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default CreateHoursLine;
