export const ContributionStatusColorMap: Record<
  string,
  'info' | 'neutral' | 'success' | 'warning'
> = {
  ['ACH Executed']: 'neutral',
  ['ACH Requested']: 'neutral',
  ['Awaiting Funds']: 'neutral',
  ['Complete']: 'success',
  ['Completed']: 'success',
  ['Funding Confirmed']: 'neutral',
  ['Investment Pending']: 'neutral',
  ['Payroll Due']: 'neutral',
  ['Processing']: 'neutral',
  ['Submission Started']: 'neutral',
  ['Trades Placed']: 'neutral',
  ['Trading']: 'neutral'
};
