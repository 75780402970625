import { QueueErrorCollapsible } from '@/components/queue-error-card/QueueErrorTableCell.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { QueueErrorDTO } from '@/models';
import QueueErrorService from '@/services/QueueError.service';
import { useMutation } from '@tanstack/react-query';

import { AgGridReact } from 'ag-grid-react';
import { useMemo, useRef, useState } from 'react';
import { useToggle } from 'react-use';

export type ReplayResponseDto = {
  error?: string;
  success: boolean;
  previouslyReplayed: boolean;
};

export const useQueueError = () => {
  const [expandAll, toggleExpandAll] = useToggle(false);
  const gridRef = useRef<AgGridReact>(null);
  const [updatedRow, setUpdatedRow] = useState<QueueErrorDTO>(null);
  const { showSnackbar } = useSnackbar();

  const archiveErrorMutation = useMutation(
    ['QueueErrorService.archiveQueueError'],
    (id: number) => QueueErrorService.archiveQueueError(id),
    {
      onError: () => {
        showSnackbar({
          message: 'Error archiving queue error',
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: 'Success!',
          severity: 'success'
        });
      }
    }
  );

  const detailCell = useMemo(() => {
    return QueueErrorCollapsible;
  }, []);

  const onExpandCollapseClick = () => {
    gridRef.current.api.forEachNode(node => {
      node.setExpanded(!expandAll);
    });
    toggleExpandAll();
  };

  const onNoteUpdate = (updatedRow: QueueErrorDTO) => {
    setUpdatedRow(updatedRow);
  };

  const onRowDataUpdated = () => {
    if (updatedRow) {
      gridRef.current.api.forEachNode(node => {
        if (node.data.errorId === updatedRow.errorId) {
          node.setExpanded(true);
        }
      });
      setUpdatedRow(null);
    }
  };

  const replayErrorMutation = useMutation<ReplayResponseDto, Error, number>(
    ['QueueErrorService.replayQueueError'],
    (id: number) => QueueErrorService.replayQueueError(id),
    {
      onError: () => {
        showSnackbar({
          message: 'Error replaying queue error',
          severity: 'error'
        });
      },
      onSuccess: data => {
        if (data.success === true && data.previouslyReplayed === false)
          showSnackbar({
            message: 'Success!',
            severity: 'success'
          });
        else if (data.previouslyReplayed === true) {
          showSnackbar({
            message: 'Queue error already replayed',
            severity: 'info'
          });
        } else if (data.success === false) {
          showSnackbar({
            message: 'Error replaying queue error',
            severity: 'error'
          });
        }
      }
    }
  );

  const unarchiveErrorMutation = useMutation(
    ['QueueErrorService.unarchiveQueueError'],
    (id: number) => QueueErrorService.unarchiveQueueError(id),
    {
      onError: () => {
        showSnackbar({
          message: 'Error unarchiving queue error',
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: 'Success!',
          severity: 'success'
        });
      }
    }
  );

  return {
    archiveErrorMutation,
    detailCell,
    expandAll,
    gridRef,
    onExpandCollapseClick,
    onNoteUpdate,
    onRowDataUpdated,
    replayErrorMutation,
    toggleExpandAll,
    unarchiveErrorMutation
  };
};
