import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import formatters from '@/utils/Formatters';
import { Box, Stack, Typography } from '@mui/material';

import lodash from 'lodash';

export const AlertDynamicDetails = (props: {
  detailsObj: unknown;
}): JSX.Element | null => {
  const { detailsObj } = props;

  const stringifyIfNotString = (value: unknown) => {
    if (typeof value !== 'string') {
      return JSON.stringify(value);
    }
    return value;
  };

  return detailsObj &&
    lodash.isPlainObject(detailsObj) &&
    Object.values(detailsObj).length > 0 ? (
    <TextStack direction='column' spacing={1} sx={{ p: 0 }}>
      {Object.entries(detailsObj).map(([key, value]) => {
        if (['tracerId', 'createdAt'].includes(key)) return null;

        const isValidArray = Array.isArray(value) && value.length > 0;
        const isValidArrayOfObjects =
          isValidArray &&
          value.every((item: unknown) => lodash.isPlainObject(item));
        const isValidObject =
          lodash.isPlainObject(value) && Object.values(value).length > 0;

        return isValidObject || isValidArrayOfObjects ? (
          <Box
            component='li'
            key={key}
            sx={{
              listStyle: 'none'
            }}>
            {isValidObject ? (
              <>
                {/** This would be a single object */}
                <Typography
                  sx={{
                    color: theme => theme.palette.grey[600],
                    fontSize: 14,
                    lineHeight: '24px',
                    mb: 1
                  }}>
                  {formatters.displayCase(key)}
                </Typography>
                <Box
                  sx={{
                    borderColor: theme => theme.palette.grey[300],
                    borderLeft: '1px solid',
                    pl: 1
                  }}>
                  <AlertDynamicDetails detailsObj={value} key={key} />
                </Box>
              </>
            ) : (
              <Stack spacing={1}>
                {/** This would be an array of one or more objects */}
                {value.map((item: unknown, index: number) => (
                  <Box key={`${key}_${JSON.stringify(item)}_${index}`}>
                    <Typography
                      sx={{
                        color: theme => theme.palette.grey[600],
                        fontSize: 14,
                        lineHeight: '24px',
                        mb: 1
                      }}>
                      {`${formatters.displayCase(key)}${
                        value.length > 1 ? ` ${index + 1}` : ''
                      }`}
                    </Typography>
                    <Box
                      sx={{
                        borderColor: theme => theme.palette.grey[300],
                        borderLeft: '1px solid',
                        pl: 1
                      }}>
                      <AlertDynamicDetails detailsObj={item} />
                    </Box>
                  </Box>
                ))}
              </Stack>
            )}
          </Box>
        ) : (
          <TextStackItem key={key}>
            <TextLabel>{formatters.displayCase(key)}</TextLabel>
            <TextValue>
              {isValidArray ? (
                <Box component='ul'>
                  {value.map((item: unknown, index: number) => (
                    <Box
                      component='li'
                      key={`${key}_${JSON.stringify(item)}_${index}`}
                      sx={{
                        listStyle: 'none'
                      }}>
                      {stringifyIfNotString(item)}
                    </Box>
                  ))}
                </Box>
              ) : (
                stringifyIfNotString(value)
              )}
            </TextValue>
          </TextStackItem>
        );
      })}
    </TextStack>
  ) : null;
};

export default AlertDynamicDetails;
