import Badge from '@/components/badge/Badge.component';
import { CellComponentProps } from '@/components/collapsible-table';
import formatters from '@/utils/Formatters';
import { Box, TableCell } from '@mui/material';

import React from 'react';

const LoanAmortizationScheduleTableCell: React.FunctionComponent<
  CellComponentProps
> = (props: CellComponentProps) => {
  const { row, column } = props;

  let field = <Box>{row[column.field] || '--'}</Box>;
  if (['dueDate', 'latestPaymentDate', 'updatedAt'].includes(column.field)) {
    field = (
      <Box>
        {row[column.field]
          ? formatters.formatFromIsoDateCustom(row[column.field], 'M/DD/YY')
          : '--'}
      </Box>
    );
  }
  if (['total', 'principal', 'interest', 'totalPaid'].includes(column.field)) {
    field = <Box>{formatters.formatDollars(row[column.field])}</Box>;
  }
  if (column.field === 'paymentStatus') {
    if (['Paid', 'Offset'].includes(row[column.field]))
      field = <Badge color='success'>{row[column.field]}</Badge>;
    else if (row[column.field] === 'Past Due')
      field = <Badge color='warning'>{row[column.field]}</Badge>;
    else if (row[column.field] === 'Default')
      field = <Badge color='error'>{row[column.field]}</Badge>;
    else field = <Badge>{row[column.field] || '--'}</Badge>;
  }

  return (
    <TableCell component='th' scope='row'>
      <Box paddingRight={2}>{field}</Box>
    </TableCell>
  );
};

export default LoanAmortizationScheduleTableCell;
