import Constants from '@/consts/planDesign.constants';
import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class EmployerMatch {
  convertToApiState = Helper.convertValueToApiState;

  options = Constants.vestingSchedules.map(s => s.name);

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.output = Helper.getVestingScheduleName(
      'Employer Match',
      props.fundingSources
    );
  }
}

export default EmployerMatch;
