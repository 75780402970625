import dayjs from 'dayjs';

const DATE_REGEX = /^\d{2}\/\d{2}\/\d{4}$/;

export const dateValidator = value => {
  if (['', null, undefined].includes(value)) return true;

  if (!DATE_REGEX.test(value)) return false;

  return dayjs(value).isValid();
};
