import LinearLoading from '@/components/linear-loading';
import { ProgramFund } from '@/models/ops/investments/Program.model';
import { SubaPlanFundStatusesDto } from '@/models/SubaccountingPlanFund.model';
import { ProgramService } from '@/services/ops/investments/Program.service';
import SubAccountingService from '@/services/SubAccounting.service';
import { useQuery } from '@tanstack/react-query';

import React from 'react';

import CustodianFundsTabContent from './CustodianFundsTabContent.componet';

interface CustodianFundsTabProps {
  programId: number;
  planId: number;
}

const CustodianFundsTab = (props: CustodianFundsTabProps): JSX.Element => {
  const { programId, planId } = props;

  const programFundsQuery = useQuery<ProgramFund[]>(
    ['CustodianTab.ProgramService.getProgramFunds', programId],
    () => ProgramService.getProgramFunds(programId)
  );

  const plunFundsStatusQuery = useQuery<SubaPlanFundStatusesDto>(
    ['CustodianTab.SubAccountingService.getPlanFundsAndStatuses', planId],
    () => SubAccountingService.getPlanFundsAndStatuses(planId)
  );

  if (programFundsQuery.isFetching || plunFundsStatusQuery.isFetching) {
    return <LinearLoading />;
  }

  const programFunds = programFundsQuery.data || [];
  const plunFundsStatus = plunFundsStatusQuery.data?.data || [];
  const programCusips = programFunds.map(item => item.cusip);
  const allCusips = programCusips.concat(
    plunFundsStatus.map(item => item.attributes.planFund.cusip)
  );

  const plunFundStatusByCusip = new Map(
    plunFundsStatus.map(item => [
      item.attributes.planFund.cusip,
      item.attributes
    ])
  );

  const cusips = allCusips.filter((n, i) => allCusips.indexOf(n) === i);

  return (
    <CustodianFundsTabContent
      cusips={cusips}
      planId={planId}
      plunFundStatusByCusip={plunFundStatusByCusip}
      programCusips={programCusips}
    />
  );
};

export default CustodianFundsTab;
