import { Typography } from '@mui/material';

import React from 'react';

import { SearchAlerts } from '../../common/SearchAlerts.component';

const SearchAlertsRoute = (): JSX.Element => {
  return (
    <>
      <Typography component='h2' mb={5} variant='h4'>
        Alert Center
      </Typography>
      <SearchAlerts />
    </>
  );
};
export default SearchAlertsRoute;
