import { SubaPlanDto } from '@/models/ops/suba-plans/SubaPlanDto.model';
import { SubaPlanGetRequest } from '@/models/ops/suba-plans/SubaPlanGetRequest.model';
import { SubaPlanSearchRequest } from '@/models/ops/suba-plans/SubaPlanSearchRequest.model';
import { PaginatedApiResponse } from '@/models/PaginatedApiResponse.model';
import ApiService from '@/services/Api.service';

export class SubaPlanService {
  static async getByPlanId(params: SubaPlanGetRequest) {
    return ApiService.getJson<SubaPlanDto>(`suba-plans/${params.planId}`, {
      ...params,
      planId: undefined
    });
  }

  static async search(params: SubaPlanSearchRequest) {
    return ApiService.getJson<PaginatedApiResponse<SubaPlanDto[]>>(
      `suba-plans/`,
      params
    );
  }
}
