import {
  EditorColDef,
  EditorComponentProps,
  EditorParams
} from '@/components/file-upload-table/FileUploadTable.component';
import { FileUploadTableEditorContext } from '@/components/file-upload-table/FileUploadTableEditorContext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  Button,
  IconButton,
  Paper,
  Popper,
  Stack,
  Typography
} from '@mui/material';

import { ValueSetterParams } from 'ag-grid-community';
import { get } from 'lodash';
import {
  ElementType,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useUpdateEffect } from 'react-use';

export function FileUploadTableEditor<T = string>(
  config: EditorColDef<T>,
  Component: ElementType
): any {
  const EditorComponent = (props: EditorComponentProps) => {
    const $input = useRef();

    const ctx = useContext(FileUploadTableEditorContext);

    const [isNextClicked, toggleIsNextClicked] = useState(false);
    const [isPreviousClicked, setPreviousClicked] = useState(false);
    const [isRemoveRowClicked, toggleIsRemoveRowClicked] = useState(false);
    const [inputValue, setInputValue] = useState(props.value);
    const [isOpen, setIsOpen] = useState(false);
    const [isSelectClosing, setSelectClosing] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);

    const primaryKey = props.data[ctx?.primaryKey ?? ''];
    const rowData = useMemo(() => {
      const tmpRowData: any[] = [];
      props.api.forEachNode(node => tmpRowData.push(node.data));
      return tmpRowData;
    }, [props]);

    /**
     * find the "real" index of the row, based on rowData;
     * props.rowIndex is based on the position in the grid,
     * which can be different if a sort is applied
     */
    const rowIndex = useMemo(
      () =>
        rowData.findIndex(
          row => row && ctx?.primaryKey && row[ctx.primaryKey] === primaryKey
        ),
      [ctx?.primaryKey, primaryKey, rowData]
    );

    const cell = props.api.getEditingCells()[0];
    const name = `[${rowIndex}].${props.colDef.field}`;
    const row: any = get(rowData, `[${rowIndex}]`, '');

    const error = get(
      ctx?.errorsHash,
      `${row[ctx?.primaryKey ?? '']}.${props.colDef.field}`,
      null
    ) as any;

    const validations = useMemo(
      () =>
        ctx?.validations?.map(validation => ({
          ...validation,
          pristineRow:
            props.api.getRowNode(validation.id)?.data.pristine ?? false,
          rowIndex: props.api.getRowNode(validation.id)?.rowIndex
        })) ?? [],
      [props.api, ctx?.validations]
    );

    const errorCount = useMemo(
      () => validations.filter(validation => !validation.pristineRow).length,
      [validations]
    );

    const errorIndex = useMemo(
      () =>
        validations.findIndex(validation => {
          return (
            error !== null &&
            !validation.pristineRow &&
            validation.colIndex === error.colIndex &&
            validation.rowIndex === error.rowIndex
          );
        }) ?? 0,
      [validations, error]
    );

    const sameRowValidations = useMemo(
      () =>
        validations.filter(
          validation => validation.rowIndex === props.rowIndex
        ),
      [validations, props.rowIndex]
    );

    const prevRowValidations = useMemo(
      () =>
        validations.filter(
          validation =>
            validation.rowIndex !== undefined &&
            validation.rowIndex !== null &&
            validation.rowIndex < props.rowIndex
        ),
      [validations, props.rowIndex]
    );

    const nextRowValidations = useMemo(
      () =>
        validations.filter(
          validation =>
            validation.rowIndex && validation.rowIndex > props.rowIndex
        ),
      [validations, props.rowIndex]
    );

    const validationIndex = useMemo(
      () =>
        sameRowValidations.findIndex(
          validation => validation.colId === props.colDef.field
        ),
      [sameRowValidations, props.colDef.field]
    );

    const nextCellInRow = sameRowValidations[validationIndex + 1];
    const previousCellInRow = sameRowValidations[validationIndex - 1];

    const isDisabled = isNextClicked || isRemoveRowClicked || errorCount === 0;

    const onChange = useCallback(
      componentValue => {
        setInputValue(componentValue);
        if (ctx && ctx.setValue) {
          ctx.setValue(name, componentValue);
          if (row?.pristine) ctx.setValue(`[${rowIndex}].pristine`, false);
        }

        //stop editing after a value is selected
        if (props.colDef.type === 'select') {
          setSelectClosing(!isSelectClosing);
        }
      },
      [ctx, isSelectClosing, name, props.colDef.type, row.pristine, rowIndex]
    );

    /**
     * we need to preserve the animations in popover
     * by setting the isNextClicked state when next is clicked
     * after the animations are complete, useUpdateEffect is triggered which will
     * perform the navigation
     */
    const onContinue = () => {
      toggleIsNextClicked(!isNextClicked);
      setIsOpen(false);
    };

    /**
     * we need to preserve the animations in popover
     * by setting the isPreviousClicked state when previous is clicked
     * after the animations are complete, useUpdateEffect is triggered which will
     * perform the navigation
     */
    const onPrevious = () => {
      setPreviousClicked(!isPreviousClicked);
      setIsOpen(false);
    };

    /**
     * we need to preserve the animations in popover
     * by setting the isRemoveRowClicked state when exclude is clicked
     * after the animations are complete, onClose is called which will
     * perform the removal
     */
    const onRemoveRow = () => {
      toggleIsRemoveRowClicked(true);
      const nextColKey =
        nextRowValidations[0]?.colId ?? validations[0]?.colId ?? null;
      const nextRowIndex =
        nextRowValidations[0]?.rowIndex ?? validations[0]?.rowIndex ?? null;

      /**
       * we cannot remove the row and startEditorCell at the same time,
       * the removal has to occur first and then the editor can be started
       * */
      if (ctx?.removeRow) {
        ctx?.removeRow(
          {
            ...props,
            data: row
          },
          nextColKey
            ? {
                colKey: nextColKey,
                rowIndex: nextRowIndex ? nextRowIndex - 1 : props.rowIndex - 1
              }
            : undefined
        );
      }
    };

    useUpdateEffect(() => {
      if (isSelectClosing) {
        props.api.stopEditing();
      }

      if (isNextClicked && nextCellInRow) {
        props.api.setFocusedCell(cell.rowIndex, nextCellInRow.colId);
        return props.api.startEditingCell({
          colKey: nextCellInRow.colId,
          rowIndex: cell.rowIndex
        });
      }

      if (
        isNextClicked &&
        !nextCellInRow &&
        nextRowValidations.length &&
        nextRowValidations[0].colId &&
        nextRowValidations[0].rowIndex
      ) {
        props.api.setFocusedCell(
          nextRowValidations[0].rowIndex,
          nextRowValidations[0].colId
        );
        return props.api.startEditingCell({
          colKey: nextRowValidations[0].colId,
          rowIndex: nextRowValidations[0].rowIndex
        });
      }

      if (isNextClicked && ctx?.validations && ctx?.validations[0]?.colId) {
        props.api.setFocusedCell(
          ctx.validations[0].rowIndex,
          ctx.validations[0].colId
        );
        return props.api.startEditingCell({
          colKey: ctx.validations[0].colId,
          rowIndex: ctx.validations[0].rowIndex
        });
      }

      if (isPreviousClicked && previousCellInRow) {
        props.api.setFocusedCell(cell.rowIndex, previousCellInRow.colId);
        return props.api.startEditingCell({
          colKey: previousCellInRow.colId,
          rowIndex: cell.rowIndex
        });
      }

      const lastCellFromPrevRow = prevRowValidations.slice(-1);
      if (
        isPreviousClicked &&
        !previousCellInRow &&
        lastCellFromPrevRow.length &&
        lastCellFromPrevRow[0].colId &&
        lastCellFromPrevRow[0].rowIndex !== undefined &&
        lastCellFromPrevRow[0].rowIndex !== null
      ) {
        props.api.setFocusedCell(
          lastCellFromPrevRow[0].rowIndex,
          lastCellFromPrevRow[0].colId
        );
        return props.api.startEditingCell({
          colKey: lastCellFromPrevRow[0].colId,
          rowIndex: lastCellFromPrevRow[0].rowIndex
        });
      }

      const lastError = ctx?.validations?.slice(-1);
      if (isPreviousClicked && lastError?.length && lastError[0].colId) {
        props.api.setFocusedCell(lastError[0].rowIndex, lastError[0].colId);
        return props.api.startEditingCell({
          colKey: lastError[0].colId,
          rowIndex: lastError[0].rowIndex
        });
      }
    }, [
      ctx,
      validations,
      props,
      cell,
      isNextClicked,
      isPreviousClicked,
      isSelectClosing,
      row,
      nextCellInRow,
      nextRowValidations,
      previousCellInRow,
      prevRowValidations
    ]);

    useEffect(() => {
      setIsOpen(errorIndex === -1 ? false : true);
    }, [errorIndex]);

    return (
      <div
        className='tooltip-editor'
        ref={anchorRef}
        role='presentation'
        style={{
          height: props.node.rowHeight ? props.node.rowHeight - 2 : '',
          width: '100%'
        }}>
        <Popper
          anchorEl={anchorRef.current ?? null}
          open={isOpen}
          placement={props.colDef.type === 'select' ? 'top' : 'bottom'}
          style={{ zIndex: 10000 }}>
          <Paper
            elevation={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '300px',
              overflow: 'auto',
              padding: 0
            }}>
            <Stack>
              <Stack
                alignItems='center'
                direction='row'
                justifyContent='space-between'
                sx={{
                  backgroundColor: 'rgb(253, 237, 237)',
                  paddingLeft: theme => theme.spacing(1)
                }}>
                <Stack direction='row' spacing={1}>
                  <WarningAmberIcon color='error' />
                  <Typography
                    sx={{
                      color: theme => theme.palette.text.primary,
                      fontWeight: 'bold'
                    }}>
                    {`Error ${errorIndex + 1}/${errorCount}`}
                  </Typography>
                </Stack>
                <div>
                  <IconButton aria-label='previous error' onClick={onPrevious}>
                    <NavigateBeforeIcon />
                  </IconButton>
                  <IconButton
                    aria-label='next error'
                    data-popover-close={true}
                    disabled={isDisabled}
                    onClick={onContinue}>
                    <NavigateNextIcon />
                  </IconButton>
                </div>
              </Stack>
              {error?.message && (
                <Typography margin={1} variant='subtitle1'>
                  {error.message}
                </Typography>
              )}
              {!!props.colDef.cellEditorParams?.showExcludeRowButton && (
                <Button
                  disabled={isDisabled}
                  onClick={onRemoveRow}
                  sx={{ margin: theme => theme.spacing(1) }}
                  variant='text'>
                  Exclude Row
                </Button>
              )}
            </Stack>
          </Paper>
        </Popper>
        <Component
          className='p-0'
          {...props}
          name={props.name}
          onChange={onChange}
          ref={$input}
          value={inputValue}
          values={props.data}
          width='100%'
        />
      </div>
    );
  };

  EditorComponent.displayName = 'FileUploadTableEditor';

  return {
    ...config,
    cellClassRules: {
      ['error-cell-background']: (params: EditorParams) => {
        return !!get(
          params.context?.errorsHash,
          params.data && !params.data.pristine
            ? `${params.data[params.context?.primaryKey ?? '']}.${
                params.colDef.field
              }`
            : '',
          false
        );
      },
      ...config?.cellClassRules
    },
    cellEditor: EditorComponent,
    editable: config.editable === undefined ? false : config.editable,
    valueSetter: (params: ValueSetterParams) =>
      config.valueSetter ? config.valueSetter(params) : false
  };
}
