export const NOT_ROLLOVER_ELIGIBLE_DISTRIBUTIONS = [
  'HARDSHIP',
  'QACA',
  'EACA',
  '402G',
  'REQUIRED_MINIMUM',
  'ACP_CORRECTIVE',
  'ADP_CORRECTIVE',
  '415'
];

export const EXCLUDED_FUNDING_SOURCES = ['SH', 'LN', 'AT'];

export const WITHHOLDING_ACCEPT_CODES = [
  'PARTICIPANT_TERMINATION',
  'HARDSHIP',
  'ROLLOVER',
  'IN_SERVICE',
  'QACA',
  'EACA',
  'QDRO',
  '402G',
  'REQUIRED_MINIMUM',
  'ACP_CORRECTIVE',
  'ADP_CORRECTIVE',
  '415',
  'DEATH',
  'CASH_OUT'
];

export const TAX_TYPES = [
  { option: 'Pre-Tax', value: 'preTax' },
  { option: 'Roth', value: 'roth' }
] as Array<{ value: string; option: string }>;

export const WITHDRAWAL_CODES = [
  { option: '402g Distribution', value: '402G' },
  { option: '415 Distribution', value: '415' },
  { option: 'ACP Corrective', value: 'ACP_CORRECTIVE' },
  { option: 'ADP Corrective', value: 'ADP_CORRECTIVE' },
  { option: 'Death Distribution', value: 'DEATH' },
  { option: 'EACA', value: 'EACA' },
  { option: 'Hardship Distribution', value: 'HARDSHIP' },
  { option: 'In Service Distribution', value: 'IN_SERVICE' },
  {
    option: 'Participant Termination Distribution',
    value: 'PARTICIPANT_TERMINATION'
  },
  { option: 'Plan Termination Distribution', value: 'PLAN_TERMINATION' },
  //{ value: 'QACA', option: 'QACA' },
  { option: 'QDRO', value: 'QDRO' },
  { option: 'Required Minimum Distribution', value: 'REQUIRED_MINIMUM' },
  { option: 'Rollover Withdrawal', value: 'ROLLOVER' },
  { option: 'Force Out', value: 'FORCE_OUT' },
  { option: 'Fee Out', value: 'FEE_OUT' },
  { option: 'Cash Out distribution', value: 'CASH_OUT' }
] as Array<{ value: string; option: string }>;
