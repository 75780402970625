import { DividendAnnouncementAddRequest } from '@/models/ops/dividend-declarations/DividendAnnouncementAddRequest.model';
import { DividendAnnouncementRemoveRequest } from '@/models/ops/dividend-declarations/DividendAnnouncementRemoveRequest.model';
import { DividendAnnouncementUpdateRequest } from '@/models/ops/dividend-declarations/DividendAnnouncementUpdateRequest.model';
import { DividendDeclarationDto } from '@/models/ops/dividend-declarations/DividendDeclarationDto.model';
import { DividendDeclarationsSearchParams } from '@/models/ops/dividend-declarations/DividendDeclarationsSearchParams.model';
import { HistoricalDividendProcessInput } from '@/models/ops/dividend-declarations/HistoricalDividendProcessInput.model';
import ApiService from '@/services/Api.service';

type ApiResponse = {
  results: DividendDeclarationDto[];
  pagination: {
    pageSize: number;
    page: number;
    total: number;
  };
};

class DividendDeclarationsService {
  static async search(
    params: DividendDeclarationsSearchParams
  ): Promise<ApiResponse> {
    for (const [key, value] of Object.entries(params)) {
      // filter empty params to avoid request validation errors
      if (value === undefined || value === '')
        delete params[key as keyof DividendDeclarationsSearchParams];
    }

    return ApiService.getJson<ApiResponse>(`/dividend-declarations`, {
      cusip: params.cusip,
      distributionType: params.distributionType,
      orderBy: params.orderBy,
      orderByDirection: params.orderByDirection,
      page: params.page,
      pageSize: params.pageSize,
      paymentEndDate: params.paymentEndDate,
      paymentStartDate: params.paymentStartDate,
      reinvestDate: params.reinvestDate
    });
  }

  static async add(params: DividendAnnouncementAddRequest): Promise<void> {
    return ApiService.postJson('/dividend-declarations/add', params);
  }

  static async update(
    params: DividendAnnouncementUpdateRequest
  ): Promise<void> {
    return ApiService.postJson('/dividend-declarations/update', params);
  }

  static async remove(
    params: DividendAnnouncementRemoveRequest
  ): Promise<void> {
    return ApiService.postJson('/dividend-declarations/remove', params);
  }

  static async startHistorialDividendProcessor(
    payload: HistoricalDividendProcessInput
  ): Promise<void> {
    return ApiService.postJson(
      '/dividend-declarations/start-historical-dividend-processor',
      payload
    );
  }
}

export default DividendDeclarationsService;
