import { AlertColor, Snackbar, SnackbarCloseReason } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';

// Will: React way of forwarding ref. Need this or the Alert will
// throw an error that will crash our website :D
const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
  return (
    <MuiAlert
      data-testid='snackbar-alert'
      elevation={6}
      ref={ref}
      variant='filled'
      {...props}
    />
  );
});

Alert.displayName = 'Alert';

const SnackBarImperative = forwardRef((props, ref) => {
  const [action, setAction] = useState<React.ReactNode | null>(null);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<AlertColor | undefined>();
  const hideSnackbar = () => setOpen(false);
  // Implementation of auto hide duration with timeouts to prevent the snackbar from closing if is opened again and the time is about to expire
  const [currentTimeout, setCurrentTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  useImperativeHandle(ref, () => ({
    hideSnackbar,
    showSnackbar(
      newMessage: string,
      newAutoHideDuration: number,
      newSeverity: AlertColor,
      action?: React.ReactNode
    ) {
      // Clear the previous timeout to prevent the snackbar to be opened and closed real quick
      if (currentTimeout) {
        clearTimeout(currentTimeout);
      }
      setCurrentTimeout(
        setTimeout(() => {
          setOpen(false);
        }, newAutoHideDuration || 6000)
      );
      setAction(action);
      setOpen(true);
      setMessage(newMessage);
      setSeverity(newSeverity);
    }
  }));

  useEffect(() => {
    // Clear the timeout if any when the component unmounts, is not likely to have this component unmounted but just in case
    return () => {
      if (currentTimeout) {
        clearTimeout(currentTimeout);
      }
    };
    // Dependency array has to be empty so it runs on unmount
  }, []);

  const handleClose = (
    _event: Event | React.SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    hideSnackbar();
  };

  return (
    <Snackbar
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom'
      }}
      onClose={handleClose}
      open={open}>
      <Alert action={action} onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
});

SnackBarImperative.displayName = 'SnackBarImperative';

export default SnackBarImperative;
