import LinearLoading from '@/components/linear-loading';
import useShowMore from '@/hooks/useShowMore';
import {
  GlobalSearchDto,
  GlobalSearchRiskSeriesDto
} from '@/models/GlobalSearchDTO.model';
import GlobalSearchService from '@/services/GlobalSearch.service';
import { QueryStats } from '@mui/icons-material';
import {
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Theme,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  GLOBAL_SEARCH_RESULT_PAGE_SIZE,
  GLOBAL_SEARCH_RISK_SERIES_TYPE
} from './constants';

interface GlobalSearchRiskSeriesResultProps {
  setSearchTerm: (search: string) => void;
  searchTerm: string;
  isFocused: boolean;
  abortSignal?: AbortSignal;
}

const useStyles = makeStyles((theme: Theme) => ({
  globalSearchRiskSeriesContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  globalSearchRiskSeriesField: {
    color: theme.palette.text.secondary,
    fontSize: theme.spacing(1.5),
    minHeight: theme.spacing(1.5)
  },
  globalSearchRiskSeriesHeader: {
    fontSize: theme.spacing(1.5),
    letterSpacing: '0.4px',
    marginLeft: theme.spacing(1.5),
    marginTop: theme.spacing(2)
  },
  globalSearchRiskSeriesIdField: {
    color: theme.palette.text.secondary,
    fontSize: theme.spacing(1.2),
    paddingTop: theme.spacing(1.5),
    textAlign: 'right',
    width: '25%'
  },
  globalSearchRiskSeriesImg: {
    color: '#ace600'
  },
  globalSearchRiskSeriesItem: {
    '&:hover': {
      backgroundColor: grey[200]
    },
    cursor: 'pointer'
  },
  globalSearchShowMoreBtn: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5)
  },
  noResults: {
    paddingLeft: theme.spacing(2)
  }
}));

const GlobalSearchRiskSeriesResult = (
  props: GlobalSearchRiskSeriesResultProps | any
): JSX.Element => {
  const classes = useStyles();

  const { setSearchTerm, searchTerm, abortSignal } = props;

  const navigate = useNavigate();

  const redirectToRiskSeriesPage = (id: number) => {
    setSearchTerm('');
    navigate(`/ops/investments/risk-series/${id}`);
  };

  const globalSearchRiskSeriesQuery = useShowMore<
    GlobalSearchDto,
    GlobalSearchRiskSeriesDto
  >(
    ['globalSearchRiskSeriesResultQuery', searchTerm],
    page =>
      GlobalSearchService.getGlobalSearchResult(
        searchTerm,
        page,
        GLOBAL_SEARCH_RESULT_PAGE_SIZE,
        GLOBAL_SEARCH_RISK_SERIES_TYPE,
        abortSignal
      ),
    Boolean(searchTerm),
    data => data.data.riskSeries.data,
    data => Boolean(data?.data.riskSeries.links.next),
    'riskSeriesId'
  );

  let loader;

  if (globalSearchRiskSeriesQuery.isLoading || searchTerm === '') {
    loader = <LinearLoading />;
  } else {
    loader = null;
  }

  if (globalSearchRiskSeriesQuery.isError) {
    return <p>Error</p>;
  }

  return (
    <>
      <Typography
        className={classes.globalSearchRiskSeriesHeader}
        id='global-search-riskSeries-header'>
        RISK SERIES
      </Typography>
      {loader || (
        <div>
          {!globalSearchRiskSeriesQuery.isLoading &&
          !!globalSearchRiskSeriesQuery.data?.length ? (
            <List data-testid='gs-riskSeries' id='global-search-riskSeries'>
              {globalSearchRiskSeriesQuery.data.map(
                (item: GlobalSearchRiskSeriesDto) => {
                  return (
                    <ListItem
                      className={classes.globalSearchRiskSeriesItem}
                      id='global-search-riskSeries-item'
                      key={item.riskSeriesId}
                      onClick={() =>
                        redirectToRiskSeriesPage(item.riskSeriesId)
                      }>
                      <ListItemAvatar
                        className={classes.globalSearchRiskSeriesImg}>
                        <QueryStats id='global-search-riskSeries-image' />
                      </ListItemAvatar>
                      <div
                        className={classes.globalSearchRiskSeriesContainer}
                        id='global-search-riskSeries-container'>
                        <div id='global-search-riskSeries-wrapper'>
                          <ListItemText
                            id='global-search-riskSeries-name'
                            primary={`${item.name}`}
                          />
                          <Typography
                            className={classes.globalSearchRiskSeriesField}
                            id='global-search-advisors-firm'>
                            {item?.description
                              ? `Description: ${item?.description}`
                              : ''}
                          </Typography>
                        </div>
                        <Typography
                          className={classes.globalSearchRiskSeriesIdField}
                          id='global-search-riskSeries-id'>
                          RISK SERIES ID: {item.riskSeriesId}
                        </Typography>
                      </div>
                    </ListItem>
                  );
                }
              )}
            </List>
          ) : (
            <p className={classes.noResults}>No results</p>
          )}
          {!globalSearchRiskSeriesQuery.isLoading &&
            !!globalSearchRiskSeriesQuery.data?.length &&
            globalSearchRiskSeriesQuery.isPaginated && (
              <Button
                className={classes.globalSearchShowMoreBtn}
                disabled={globalSearchRiskSeriesQuery.isPaginationLoading}
                id='global-search-riskSeries-show-more-btn'
                onClick={globalSearchRiskSeriesQuery.showMore}>
                SHOW MORE
              </Button>
            )}
        </div>
      )}
    </>
  );
};

export default GlobalSearchRiskSeriesResult;
