import AccessControl from '@/components/access-control/AccessControl.component';
import { PayrollAggregatorIcon } from '@/components/icon/PayrollAggregatorIcon';
import { ReceiveIcon } from '@/components/icon/ReceiveIcon';
import { SendIcon } from '@/components/icon/SendIcon';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import ContributionService from '@/services/Contribution.service';
import { PlanService } from '@/services/Plan.service';
import formatters from '@/utils/Formatters';
import {
  CheckCircle,
  KeyboardArrowDown,
  KeyboardArrowRight,
  TodayOutlined
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  Unstable_Grid2 as Grid2,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Payroll } from '@vestwell-api/scala';

import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { useToggle } from 'react-use';

import { PayGroupDeactivation } from './PayGroupDeactivation.component';

interface PayGroupsTableRowProps {
  row: Payroll.GetSetups.ResponseBody[0];
  toggleEditModal: (payGroup: Payroll.GetSetups.ResponseBody[0]) => void;
  sponsorPlanId: number | string;
  sponsorId: number;
}

export const PayGroupsTableRow = (
  props: PayGroupsTableRowProps
): JSX.Element => {
  const { showSnackbar } = useSnackbar();
  const [open, toggleOpen] = useToggle(false);
  const [isDeactivation, toggleDeactivation] = useToggle(false);

  const expectedPayrollDatesQuery =
    useQuery<Payroll.GetExpectedDates.ResponseBody>(
      ['PlanService.getExpectedPayrollDates', props.row.id],
      async () => {
        return PlanService.getExpectedPayrollDates(
          +props.row.id,
          Math.max(
            new Date(props.row.firstPayrollDate).getFullYear(),
            new Date().getFullYear()
          )
        );
      }
    );

  const contributions = useQuery<any>(
    [
      'ContributionService.getContributions',
      props.sponsorPlanId,
      props.row?.id,
      props.row?.division,
      0,
      true
    ],
    () =>
      ContributionService.getContributions({
        division: props.row?.division,
        nonCompleted: true,
        planId: +props.sponsorPlanId,
        sponsorId: props.sponsorId,
        version: 0
      }),
    {
      enabled: open
    }
  );

  const payrollSetups = useQuery(
    ['PlanService.getPayrollSetups', +props.sponsorPlanId],
    () => {
      return PlanService.getPayrollSetups(props.sponsorPlanId);
    },
    {
      enabled: open
    }
  );

  const isDeactivationDisabled = useMemo(
    () =>
      !props.row?.isActive ||
      payrollSetups.data?.filter(payrollSetup => payrollSetup?.isActive)
        ?.length === 1,
    [contributions.data, payrollSetups.data, props.row?.isActive]
  );

  const upcomingPayDate = useMemo(() => {
    const expectedPayDateRaw = expectedPayrollDatesQuery.data
      ?.filter(row => dayjs().isSameOrBefore(row.expectedPayDate, 'day'))
      .sort(
        (a, b) =>
          new Date(a.expectedPayDate).getTime() -
          new Date(b.expectedPayDate).getTime()
      )?.[0]?.expectedPayDate;
    const firstPayrollDate = dayjs(props.row.firstPayrollDate);
    const expectedPayDate = dayjs(expectedPayDateRaw);
    return firstPayrollDate < expectedPayDate
      ? expectedPayDateRaw
      : props.row.firstPayrollDate;
  }, [expectedPayrollDatesQuery.data]);

  const onEditModal = useCallback(
    () => props.toggleEditModal(props.row),
    [props.row]
  );

  const onSuccess = useCallback(() => {
    toggleDeactivation(false);
    toggleOpen(false);
  }, []);

  const onDeactivate = useCallback(async () => {
    const response = await contributions.refetch();

    if (response.data?.length) {
      return showSnackbar({
        message: `"${props.row?.payGroupName}" cannot be deactivated because it has an inflight contribution.`,
        severity: 'error'
      });
    }

    toggleDeactivation();
  }, [contributions.data]);

  return (
    <>
      <TableRow data-testid={`pay-group-row-${props.row.id}`}>
        <TableCell align='center' padding='none'>
          <IconButton aria-label='expand row' onClick={toggleOpen} size='small'>
            {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </IconButton>
        </TableCell>
        <TableCell align='left'>
          {props.row.payGroupName ? (
            <Typography
              className='pay-group-name'
              color={!props.row?.isActive ? 'gray' : ''}
              component='span'
              variant='body1'>
              {props.row.payGroupName}
            </Typography>
          ) : (
            <Typography
              className='pay-group-name'
              color='error'
              variant='body1'>
              Pay group name undefined
            </Typography>
          )}
          {!props.row?.isActive && (
            <Typography color='gray' component='span' variant='body1'>
              {' '}
              (Deactivated)
            </Typography>
          )}
          {upcomingPayDate && (
            <Grid alignItems='center' container direction='row' display='flex'>
              <TodayOutlined color='disabled' sx={{ mr: 0.5 }} />
              <Typography color='text.secondary' variant='body2'>
                {upcomingPayDate}{' '}
                {formatters.getAmountOfDaysBeforePayrollDate(upcomingPayDate)}
              </Typography>
            </Grid>
          )}
        </TableCell>
        <TableCell align='left' sx={{ px: 0, py: 1, verticalAlign: 'top' }}>
          <Typography className='payroll-provider-name' variant='body1'>
            {props.row.payrollProviderId == 65
              ? 'No Provider / In House'
              : props.row.payrollProviderName}
          </Typography>
          <Grid alignItems='center' container direction='row'>
            {props.row.payrollIntegrated ? (
              <CheckCircle color='success' />
            ) : (
              <Typography className='integration-type' variant='body2'>
                Not Integrated
              </Typography>
            )}
            {props.row.integrationMethod180 &&
              !props.row.integrationMethod360 && (
                <>
                  <Typography className='integration-type' variant='body2'>
                    180
                  </Typography>
                  <ReceiveIcon fill='none' />
                  <Typography variant='body2'>
                    {props.row.integrationMethod180Name}
                  </Typography>
                  {props.row.payrollAggregator180Name && (
                    <Tooltip
                      arrow
                      placement='top'
                      title={`via ${props.row.payrollAggregator180Name}`}>
                      <PayrollAggregatorIcon
                        sx={{
                          '&:hover': {
                            backgroundColor: theme =>
                              theme.palette.primary.light
                          }
                        }}
                      />
                    </Tooltip>
                  )}
                </>
              )}
            {props.row.integrationMethod360 && (
              <Typography
                className='integration-type'
                display='flex'
                justifyContent='center'
                variant='body2'>
                360
                <ReceiveIcon />
                {props.row.integrationMethod180Name}
                {props.row.payrollAggregator180Name && (
                  <Tooltip
                    arrow
                    placement='top'
                    title={`via ${props.row.payrollAggregator180Name}`}>
                    <PayrollAggregatorIcon
                      sx={{
                        '&:hover': {
                          backgroundColor: theme => theme.palette.primary.light
                        }
                      }}
                    />
                  </Tooltip>
                )}
                <SendIcon />
                {props.row.integrationMethod360Name}
                {props.row.payrollAggregator360Name && (
                  <Tooltip
                    arrow
                    placement='top'
                    title={`via ${props.row.payrollAggregator360Name}`}>
                    <PayrollAggregatorIcon
                      sx={{
                        '&:hover': {
                          backgroundColor: theme => theme.palette.primary.light
                        }
                      }}
                    />
                  </Tooltip>
                )}
              </Typography>
            )}
          </Grid>
        </TableCell>
      </TableRow>
      <TableRow data-testid={`expanded-pay-group-row-${props.row.id}`}>
        <TableCell colSpan={12} padding='none'>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box
              data-testid='expanded-pay-group-row-content'
              sx={{
                margin: theme => theme.spacing(1, 2, 1, 0),
                paddingLeft: theme => theme.spacing(7)
              }}>
              <Typography fontWeight='medium' variant='body1'>
                Pay frequency
              </Typography>
              <Typography variant='body2'>
                {formatters.formatPayrollSchedule(
                  props.row.frequencyName,
                  (upcomingPayDate || props.row.firstPayrollDate) as string
                )}
              </Typography>
              <Typography variant='body2'>Setup ID: {props.row.id}</Typography>
              <Divider sx={{ py: 0.5 }} />
              <Typography fontWeight='medium' variant='body1'>
                Payment Method
              </Typography>
              {props.row.alternateFundingSource || props.row.bankAccount ? (
                <>
                  <Typography variant='body2'>
                    {formatters.formatPaymentMethod(
                      props.row.alternateFundingSource
                    )}
                  </Typography>
                  {props.row.bankAccount && (
                    <>
                      <Typography variant='body2'>
                        Bank Name: {props.row.bankAccount?.bankName}
                      </Typography>
                      <Typography variant='body2'>
                        Routing Number: {props.row.bankAccount?.routingNumber}
                      </Typography>
                      <Typography variant='body2'>
                        Account Number: {props.row.bankAccount?.account}
                      </Typography>
                      {props.row.bankAccount.account.slice(-4) !=
                        props.row.bankAccount.plaidMask &&
                        !!props.row.bankAccount.plaidMask && (
                          <Typography variant='body2'>
                            Actual Account Number: *
                            {props.row.bankAccount?.plaidMask}
                          </Typography>
                        )}
                    </>
                  )}
                </>
              ) : (
                <Typography color='error' variant='body2'>
                  No payment method
                </Typography>
              )}
              <Divider sx={{ py: 0.5 }} />
              <Typography fontWeight='medium' variant='body1'>
                Payroll Contact
              </Typography>
              {props.row.payrollContact?.firstName ||
              props.row.payrollContact?.lastName ? (
                <>
                  <Typography variant='body2'>
                    {props.row.payrollContact?.firstName}{' '}
                    {props.row.payrollContact?.lastName}
                  </Typography>
                  <Typography variant='body2'>
                    {props.row.payrollContact?.phoneNumber}
                  </Typography>
                  <Typography variant='body2'>
                    {props.row.payrollContact?.email}
                  </Typography>
                </>
              ) : (
                <Typography variant='body2'>No contact</Typography>
              )}
              <Divider sx={{ py: 0.5 }} />
              <Grid2 container justifyContent='space-between'>
                <Grid2>
                  <AccessControl
                    requiresOneOf={[
                      FeatureLevelPermissions.WRITE_COMPANY_PAYROLL,
                      FeatureLevelPermissions.WRITE_PAYROLL_DEACTIVATION
                    ]}>
                    <Button
                      disabled={!props.row?.isActive}
                      onClick={onEditModal}>
                      EDIT
                    </Button>
                  </AccessControl>
                </Grid2>
                <Grid2>
                  <AccessControl
                    requires={[
                      FeatureLevelPermissions.WRITE_PAYROLL_DEACTIVATION
                    ]}>
                    <LoadingButton
                      color='warning'
                      disabled={isDeactivationDisabled}
                      loading={contributions.isFetching}
                      onClick={onDeactivate}>
                      Deactivate
                    </LoadingButton>
                  </AccessControl>
                </Grid2>
              </Grid2>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <PayGroupDeactivation
        isOpen={isDeactivation}
        onClose={toggleDeactivation}
        onSuccess={onSuccess}
        payrollSetupId={+props.row?.id}
        planId={+props.sponsorPlanId}
        sponsorId={+props.sponsorId}
      />
    </>
  );
};
