import Card, { CardContent, CardHeader } from '@/components/card';
import { ParticipantInfo } from '@/models';
import {
  ParticipantAccountsDto,
  ParticipantAccountTotalBalance
} from '@/models/ParticipantAccountsDTO.model';
import formatters from '@/utils/Formatters';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import clsx from 'clsx';
import React, { FC } from 'react';

interface ParticipantBalancesProps {
  accountsResponseData: ParticipantAccountsDto;
  participantsResponseData: ParticipantInfo;
}

const useStyles = makeStyles((theme: Theme) => ({
  bold: {
    fontWeight: 'bold'
  },
  date: {
    fontSize: theme.spacing(1.2),
    paddingLeft: theme.spacing(4)
  },
  groupedCell: {
    paddingLeft: theme.spacing(5.5)
  },
  groupedHeader: {
    paddingLeft: theme.spacing(4)
  },
  rowColor: {
    backgroundColor: theme.palette.grey[100]
  },
  totalRowColor: {
    backgroundColor: theme.palette.grey[300]
  }
}));

const getTestId = (id: string) =>
  `${id.toLocaleLowerCase().split(' ').join('-')}-row`;

const ParticipantBalances: FC<ParticipantBalancesProps> = props => {
  const classes = useStyles();

  const accountBalance: ParticipantAccountTotalBalance = {
    accountFields: [
      {
        amount: formatters.formatDollars(
          props.participantsResponseData.accountBalance.employeeBalance.total
        ),
        field: 'Employee Contributions Balance'
      },
      ...(props.participantsResponseData.accountBalance.employeeBalance.afterTax
        ? [
            {
              amount: formatters.formatDollars(
                props.participantsResponseData.accountBalance.employeeBalance
                  .afterTax
              ),
              field: 'After-Tax'
            }
          ]
        : []),
      {
        amount: formatters.formatDollars(
          props.participantsResponseData.accountBalance.employeeBalance.preTax
        ),
        field: 'Pre-Tax'
      },
      {
        amount: formatters.formatDollars(
          props.participantsResponseData.accountBalance.employeeBalance.roth
        ),
        field: 'Roth'
      },
      {
        amount: formatters.formatDollars(
          props.participantsResponseData.accountBalance.rolloverBalance.total
        ),
        field: 'Rollover Balance'
      },
      {
        amount: formatters.formatDollars(
          props.participantsResponseData.accountBalance.rolloverBalance.preTax
        ),
        field: 'Pre-Tax'
      },
      {
        amount: formatters.formatDollars(
          props.participantsResponseData.accountBalance.rolloverBalance.roth
        ),
        field: 'Roth'
      },
      {
        amount: formatters.formatDollars(
          props.accountsResponseData.stats.balance.employer
        ),
        field: 'Employer Contributions'
      },
      {
        amount: formatters.formatDollars(
          props.accountsResponseData.stats.vesting.blendedNotVested
        ),
        field: 'Unvested Employer Contribution'
      },
      {
        amount: formatters.formatDollars(
          props.accountsResponseData.stats.vesting.vestedTotal
        ),
        field: 'Vested Employer Contribution'
      },
      {
        amount: formatters.formatDollars(
          props.participantsResponseData.accountBalance.loanBalance.total
        ),
        field: 'Outstanding Loan Balance'
      }
    ],
    total: formatters.formatDollars(
      props.participantsResponseData.accountBalance.totalBalance
    )
  };

  const isGroupedCell = (fieldName: string): boolean =>
    [
      'Rollover Balance',
      'Outstanding Loan Balance',
      'Employer Contributions',
      'Employee Contributions Balance'
    ].includes(fieldName);

  return (
    <Card data-testid='participant-balances-card'>
      <CardHeader
        data-testid='balances-header'
        disableDivider
        title='Balances'
      />
      <CardContent>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow className={classes.totalRowColor}>
                <TableCell className={classes.bold} component='th' scope='row'>
                  Total
                  <span className={classes.date}>
                    AS OF{' '}
                    {props.participantsResponseData.accountBalance.updatedOn}
                  </span>
                </TableCell>
                <TableCell
                  align='right'
                  className={classes.bold}
                  component='th'
                  data-testid='total-value-cell'
                  scope='row'>
                  {formatters.formatDollars(
                    formatters.unformatCurrency(accountBalance.total)
                  )}
                </TableCell>
              </TableRow>
              {accountBalance.accountFields.map(balancesRow => (
                <TableRow
                  data-testid={getTestId(balancesRow.field)}
                  key={balancesRow.field}>
                  <TableCell
                    className={clsx({
                      [[
                        classes.groupedHeader,
                        classes.bold,
                        classes.rowColor
                      ].join(' ')]: isGroupedCell(balancesRow.field),
                      [classes.groupedCell]: !isGroupedCell(balancesRow.field)
                    })}>
                    {balancesRow.field}
                  </TableCell>
                  <TableCell
                    align='right'
                    className={clsx({
                      [[
                        classes.groupedHeader,
                        classes.bold,
                        classes.rowColor
                      ].join(' ')]: isGroupedCell(balancesRow.field),
                      [classes.groupedCell]: !isGroupedCell(balancesRow.field)
                    })}
                    data-testid={`${getTestId(balancesRow.field)}-value`}>
                    {balancesRow.amount}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default ParticipantBalances;
