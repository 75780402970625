import {
  einValidation,
  phoneNumberValidation,
  ssnValidation,
  taxIdValidation,
  zipCodeValidation
} from '@/utils/validations/commonYupValidations';

import * as yup from 'yup';

export const beneficiarySchema = yup.object({
  address1: yup.string().trim().required('Address Line 1 is required'),
  address2: yup.string().trim().optional(),
  beneficiaryType: yup.string().trim().required('Recipient Type is required'),
  businessName: yup
    .string()
    .trim()
    .when('beneficiaryType', {
      is: value => value === 'organization',
      then: schema => schema.required('Business Name is required')
    }),
  city: yup.string().trim().required('City is required'),
  ein: yup
    .string()
    .trim()
    .when('beneficiaryType', {
      is: value => value === 'organization',
      then: einValidation.required('EIN is required')
    }),
  email: yup.string().trim().optional().email('Must be a valid email'),
  firstName: yup
    .string()
    .trim()
    .when('beneficiaryType', {
      is: value => value === 'person',
      then: schema => schema.required('First Name is required')
    }),
  holdingFinancialInstitution: yup.string().trim().optional(),
  isPrimary: yup.boolean().required(),
  lastName: yup
    .string()
    .trim()
    .when('beneficiaryType', {
      is: value => value === 'person',
      then: schema => schema.required('Last Name is required')
    }),
  phoneNumber: phoneNumberValidation,
  relationship: yup
    .string()
    .trim()
    .when('beneficiaryType', {
      is: value => value === 'person',
      then: schema => schema.required('Relationship is required')
    }),
  ssn: ssnValidation.optional(),
  state: yup.string().trim().required('State is required'),
  trustId: taxIdValidation.optional(),
  trustName: yup
    .string()
    .trim()
    .when('beneficiaryType', {
      is: value => value === 'trust',
      then: schema => schema.required('Trust Name is required')
    }),
  zip: zipCodeValidation.required('Zip Code is Required')
});

const beneficiariesValidation = yup
  .array()
  .of(
    yup.object().shape({
      attributes: yup.object().shape({
        allocationPct: yup.number().when('$deleteBeneficiary', {
          is: value => {
            return !value;
          },
          then: schema => schema.moreThan(0, '')
        })
      })
    })
  )
  .test('primary-beneficiaries-total', 'primary', beneficiaries => {
    const primaryBeneficiaries = beneficiaries.filter(
      beneficiary => beneficiary.attributes.isPrimary
    );
    const total = primaryBeneficiaries.reduce(
      (accumulator, beneficiary) =>
        accumulator + +beneficiary.attributes.allocationPct,
      0
    );

    return primaryBeneficiaries.length ? total === 100 : true;
  })
  .test('secondary-beneficiaries-total', 'secondary', beneficiaries => {
    const secondaryBeneficiaries = beneficiaries.filter(
      beneficiary => !beneficiary.attributes.isPrimary
    );
    const total = secondaryBeneficiaries.reduce(
      (accumulator, beneficiary) =>
        accumulator + +beneficiary.attributes.allocationPct,
      0
    );

    return secondaryBeneficiaries.length ? total === 100 : true;
  });
export const allocationsSchema = yup.object().shape({
  beneficiaries: beneficiariesValidation
});

export const addBeneficiarySchema = yup.object({
  beneficiaries: beneficiariesValidation,
  beneficiaryAttributes: beneficiarySchema
});
