import { CardPlaceholder } from '@/components/card';
import CircularLoading from '@/components/circular-loading';
import { ModelService } from '@/services/ops/investments/Model.service';
import formatters from '@/utils/Formatters';
import {
  alpha,
  Box,
  Card,
  CardContent,
  CardHeader,
  Tab,
  Tabs,
  Theme,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';

import dayjs from 'dayjs';
import Decimal from 'decimal.js';
import React, { useState } from 'react';

import { InvestmentElectionHistorySummary } from './InvestmentElectionHistorySummary.component';

type ParticipantInvestmentElectionHistoryCardProps = {
  asOfDate?: string;
  participantId: number;
  modelId?: number;
};

const mapFundsRow = (fund, maxTickerLength: number) => ({
  allocation: `${new Decimal(fund.target).toFixed(2)}%`,
  id: fund.symbol,
  maxTickerLength,
  security: {
    cusip: fund.cusip,
    fundName: fund.fundName,
    id: fund.symbol
  }
});

const useStyles = makeStyles((theme: Theme) => ({
  cardContent: {
    '&:last-child': {
      padding: 0
    },
    borderTop: `1px solid ${grey[300]}`,
    padding: 0
  },
  tab: {
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, 0.04)
    },
    alignItems: 'start',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    textAlign: 'left'
  },
  tabsContainer: {
    direction: 'rtl',
    flexShrink: 0,
    maxHeight: 564,
    overflow: 'auto',
    padding: `${theme.spacing(2)} 0`,
    width: '33%'
  },
  tabsRoot: {
    direction: 'ltr'
  },
  timestamp: {
    fontSize: theme.spacing(1.6)
  },
  title: {
    margin: theme.spacing(2)
  }
}));

export const ParticipantInvestmentElectionHistoryCard = (
  props: ParticipantInvestmentElectionHistoryCardProps
): JSX.Element => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const classes = useStyles();

  const investmentElectionHistoryQuery = useQuery(
    ['ModelService.getModelHistoryByModelId', props.asOfDate],
    async () => {
      if (!props.participantId) throw new Error('keep compiler happy');

      const res = await ModelService.getModelHistoryByModelId(
        props.modelId,
        props.asOfDate
          ? dayjs(props.asOfDate)
              .endOf('day')
              .format('YYYY-MM-DDTHH:mm:ss.SSSZ')
          : undefined
      );

      const maxTickerLength = Math.max(
        ...res.map(model => model.state.map(fund => fund.symbol.length)).flat()
      );

      return res.map(model => ({
        ...model,
        state: model.state.map(statePiece =>
          mapFundsRow(statePiece, maxTickerLength)
        )
      }));
    },
    {
      enabled: !!props.modelId,
      staleTime: Infinity
    }
  );

  const handleChange = (event: React.SyntheticEvent, newIdx: number) => {
    setCurrentTabIndex(newIdx);
  };

  return (
    <Card
      data-testid='participant-investment-election-history-card'
      elevation={0}
      variant='outlined'>
      <CardHeader title='Investment Election History' />
      <CardContent
        className={classes.cardContent}
        data-testid='participant-investment-election-history-card-content'>
        {!investmentElectionHistoryQuery.data?.length &&
          !investmentElectionHistoryQuery.isFetching && (
            <CardPlaceholder
              data-testid='participant-investment-election-history-card-no-data'
              subtitle='No investment election changes'
            />
          )}

        {investmentElectionHistoryQuery.isFetching ? (
          <Box padding={2}>
            <CircularLoading />
          </Box>
        ) : (
          !!investmentElectionHistoryQuery.data &&
          !!investmentElectionHistoryQuery.data.length && (
            <Box display='flex'>
              <Box className={classes.tabsContainer}>
                <Tabs
                  className={classes.tabsRoot}
                  data-testid='participant-investment-election-history-card-tabs'
                  onChange={handleChange}
                  orientation='vertical'
                  value={currentTabIndex}>
                  {investmentElectionHistoryQuery.data.map((model, index) => {
                    return (
                      <Tab
                        className={classes.tab}
                        data-testid={`tab-${formatters.textToDataTestId(
                          model.modelName
                        )}-${model.modelId}-${formatters.textToDataTestId(
                          model.fromTs
                        )}`}
                        key={model.fromTs}
                        label={
                          <>
                            {model.modelName} <br />
                            <Typography className={classes.timestamp}>
                              {formatters.formatFromIsoDateCustom(
                                model.fromTs,
                                'MM/DD/YYYY HH:mm:ss'
                              )}
                            </Typography>
                          </>
                        }
                        value={index}
                        wrapped={model.modelName.length > 30}
                      />
                    );
                  })}
                </Tabs>
              </Box>
              <InvestmentElectionHistorySummary
                currentModel={
                  investmentElectionHistoryQuery.data[currentTabIndex]
                }
              />
            </Box>
          )
        )}
      </CardContent>
    </Card>
  );
};

ParticipantInvestmentElectionHistoryCard.displayName =
  'ParticipantInvestmentElectionHistoryCard;';
