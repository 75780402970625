import Constants from '@/consts/planDesign.constants';

import { isString } from 'lodash';

class Loans {
  convertToApiState = (value: string | undefined): string | undefined => {
    if (isString(value) && value) {
      return value === 'Yes' ? 'true' : 'false';
    }

    return undefined;
  };

  options = Constants.yesNoOptions;

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.output = this.transform(props.allowLoans);
  }

  private transform = (value: string | undefined): string | undefined => {
    if (value) {
      return value.toLowerCase() === 'true' ? 'Yes' : 'No';
    }
    return value;
  };
}

export default Loans;
