export const UNVERIFIED_PRIOR_PROVIDER_ID = -1;

export const PLAN_DATES_TYPES = {
  PARTICIPANTS_BLACKOUT_NOTICE: 'blackout_notices_sent',
  SPONSOR_BLACKOUT_NOTICE_SENT: 'sponsor_blackout_reminder_sent',
  WELCOME_EMAILS_SCHEDULED: 'welcome_emails_scheduled',
  WELCOME_EMAILS_SENT: 'welcome_emails_sent'
};

export const colorByPlanStatus: Record<
  string,
  | 'success'
  | 'neutral'
  | 'warning'
  | 'info'
  | 'lightWarning'
  | 'error'
  | undefined
> = {
  Abandoned: 'neutral',
  Active: 'success',
  'Deconversion Requested': 'warning',
  Deconverted: 'neutral',
  Deferred: 'neutral',
  Onboarding: 'warning',
  Pending: 'info',
  'Ready - Awaiting Effective Date': 'info',
  Terminated: 'neutral',
  'Termination Requested': 'warning'
};
