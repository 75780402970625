import { CardPlaceholder } from '@/components/card';
import Card, { CardHeader } from '@/components/card/Card.component';
import DataTable, {
  DataTableBadgeCell
} from '@/components/data-table/DataTable.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import TickerSelect, {
  TickerLookupSearchResult
} from '@/routes/ops/investments/investment-table/TickerSelect.component';
import { FUND_STATUS_LABELS } from '@/routes/ops/investments/ProgramSecuritiesTab/consts';
import InvestmentService from '@/services/Investment.service';
import SubAccountingService from '@/services/SubAccounting.service';
import DOMInteraction from '@/utils/DOMInteraction';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import { blue } from '@mui/material/colors';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useFormik } from 'formik';
import { times } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { unstable_usePrompt } from 'react-router-dom';
import { useToggle } from 'react-use';

import BulkModal from './BulkModal.component';
import ConfirmModalTable from './ConfirmTable.component';
import SecurityDetailCellRenderer from './SecurityDetailCellRenderer.component';
import type { SecurityTableRow } from './types';

const useStyles = makeStyles(theme =>
  createStyles({
    columnHeader: {
      color: blue[500]
    },
    paper: {
      marginTop: theme.spacing(4),
      width: '100%'
    }
  })
);

const statusColorMap = new Map<
  string,
  'info' | 'neutral' | 'success' | 'warning'
>([
  [FUND_STATUS_LABELS.NEW_STATUS, 'warning'],
  [FUND_STATUS_LABELS.PENDING_ACTIVATION_STATUS, 'neutral'],
  [FUND_STATUS_LABELS.ACTIVE_STATUS, 'success'],
  [FUND_STATUS_LABELS.PENDING_DEACTIVATION_STATUS, 'neutral'],
  [FUND_STATUS_LABELS.INACTIVE_STATUS, 'neutral']
]);

export type ProgramSecuritiesTabProps = {
  isInitiallyDirty: boolean;
  numberOfPlans: number;
  onSubmitSecurities: () => void;
  programId: number;
  rows: SecurityTableRow[];
};

export const SecuritiesTab: React.FC<ProgramSecuritiesTabProps> = props => {
  const gridRef = useRef<AgGridReact>(null);
  const classes = useStyles();
  const [isBulkModalOpen, toggleBulkModal] = useToggle(false);
  const snackbar = useSnackbar();
  const [initialRowsNumber] = useState(props.rows.length);
  const [isConfirmModalOpen, toggleConfirmModal] = useToggle(false);

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      rows: props.rows
    },
    onSubmit: async () => {
      try {
        await SubAccountingService.syncProgramPlanFunds(
          props.programId,
          newValues.map(e => ({
            cusip: e.fund.cusip,
            note: e.requestNote
          }))
        );
        snackbar.showSnackbar({
          message: `Success. Submitted account openings for ${
            newValues.length
          } ${newValues.length > 1 ? 'funds' : 'fund'} and ${
            props.numberOfPlans
          } ${props.numberOfPlans > 1 ? 'plans' : 'plan'}`,
          severity: 'success'
        });
        props.onSubmitSecurities();
      } catch (error) {
        snackbar.showSnackbar({
          message: `Failed!`,
          severity: 'error'
        });
      }

      toggleConfirmModal(false);
    }
  });

  const emptyRow: SecurityTableRow = useMemo(
    () => ({
      cusip: '',
      fundName: '',
      id: form.values.rows.length, // we need the id for an empty line to avoid constant rerendering when empty line detail renderer is open
      requestNote: '',
      symbol: '',
      ...times(props.numberOfPlans || 0, index => ({
        [`planId${index}`]: '',
        [`planName${index}`]: '',
        [`status${index}`]: ''
      }))
    }),
    [props.numberOfPlans, form.values.rows]
  );

  const newRow: SecurityTableRow = useMemo(
    () => ({
      cusip: '',
      fundName: '',
      id: form.values.rows.length,
      requestNote: '',
      symbol: '',
      ...(Array.from({ length: props.numberOfPlans }).reduce(
        (acc: object, _, index) => ({
          ...acc,
          [`planId${index}`]: form.values.rows[0][`planId${index}`],
          [`planName${index}`]: form.values.rows[0][`planName${index}`],
          [`status${index}`]: FUND_STATUS_LABELS.NEW_STATUS
        }),
        {}
      ) as object)
    }),
    [props.numberOfPlans, form.values.rows]
  );

  const handleSubmit = useCallback(
    async (formValues, formikHelpers) => {
      const existingTickers: string[] = [];
      const lookupTickers: string[] = [];
      const notFoundTickers: string[] = [];
      const foundTickers: TickerLookupSearchResult[] = [];

      formValues.tickers.forEach((ticker: string) => {
        const existingTicker = form.values.rows.find(
          (item: { symbol?: string }) => item.symbol === ticker
        );

        if (existingTicker) {
          existingTickers.push(ticker);
        } else {
          lookupTickers.push(ticker);
        }
      });

      await Promise.all(
        lookupTickers.map((ticker: string) => {
          return InvestmentService.searchSymbol(ticker).then(results => {
            if (results.length) {
              const foundTicker = results.find(
                result => result.symbol === ticker
              );

              if (foundTicker) {
                foundTickers.push(foundTicker);
              } else {
                notFoundTickers.push(ticker);
              }
            } else {
              notFoundTickers.push(ticker);
            }
          });
        })
      );

      if (notFoundTickers.length) {
        formikHelpers.setErrors({
          tickers: `${notFoundTickers.join(', ')} cannot be found.`
        });
        return;
      }

      toggleBulkModal(false);

      foundTickers.forEach((foundTicker, index) => {
        form.setFieldValue(`rows[${form.values.rows.length + index}]`, {
          ...newRow,
          cusip: foundTicker.cusip,
          fundName: foundTicker.fundName,
          id: form.values.rows.length + index,
          symbol: foundTicker.symbol
        });
      });

      let message = '';

      if (foundTickers.length) {
        message += `Added ${foundTickers.map(e => e.symbol).join(', ')}. `;
      }

      if (existingTickers.length) {
        const tickerNoun = existingTickers.length > 1 ? 'tickers' : 'ticker';
        message += `${existingTickers.length} duplicate ${tickerNoun} found`;
      }

      snackbar.showSnackbar({
        message,
        severity: foundTickers.length ? 'success' : 'error'
      });
    },
    [toggleBulkModal, newRow, snackbar, props]
  );

  const detailCellRenderer = useMemo(() => {
    const setRequestNote = (id: number, note?: string) => {
      const index = gridRef?.current?.props?.rowData?.findIndex(
        item => item.id === id
      ) as number;

      if (index !== -1) {
        const updatedData = {
          ...gridRef?.current?.props?.rowData?.[index],
          requestNote: note
        };
        gridRef?.current?.api?.applyTransaction({
          update: [updatedData]
        });
        form.setFieldValue(`rows[${index}]`, updatedData);
      }
    };
    return SecurityDetailCellRenderer(
      gridRef,
      setRequestNote,
      props.numberOfPlans
    );
  }, [props]);

  const onCommit = useCallback(
    params => {
      if (params.id === form.values.rows.length) {
        if (params.remove) {
          return;
        }

        const arry = form.values.rows || [];

        const existing = (form.values.rows || []).find(
          item => item.symbol === params.value?.symbol
        );

        if (!existing) {
          const newValue = {
            ...newRow,
            cusip: params.value.cusip,
            fundName: params.value.fundName,
            symbol: params.value.symbol
          };
          const newIndex = +form.values.rows[params.id]
            ? params.id
            : arry.length;

          form.setFieldValue(`rows[${newIndex}]`, newValue);
        } else {
          const isEmptyRow = !arry[parseInt(`${params.id}`, 10)];

          if (isEmptyRow) {
            snackbar.showSnackbar({
              message: 'Duplicate ticker found',
              severity: 'warning'
            });
          }
        }
      } else {
        if (params.remove) {
          const adjusted = (form.values.rows || []).filter(
            (_, index) => index !== params.id
          );
          form.setFieldValue('rows', adjusted);
        } else {
          const existing = (form.values.rows || []).find(
            item => item.symbol === params.value?.symbol
          );

          if (existing) {
            snackbar.showSnackbar({
              message: 'Duplicate ticker found',
              severity: 'warning'
            });
          } else {
            form.setFieldValue(`rows[${params.id}]`, {
              ...newRow,
              cusip: params.value.cusip,
              fundName: params.value.fundName,
              id: params.id,
              symbol: params.value.symbol
            });
          }
        }
      }
    },
    [props, snackbar, newRow]
  );

  const tickerRows = useMemo(
    () =>
      props.numberOfPlans ? [...form.values.rows, emptyRow] : form.values.rows,
    [form.values.rows, props.numberOfPlans]
  );

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        cellEditor: TickerSelect,
        cellEditorParams: {
          lookupTickerCallback: InvestmentService.searchSymbol,
          onCommit
        },
        cellEditorPopup: true,
        cellRenderer: params => {
          if (params.value) return params.value;

          return (
            <Typography color='rgba(0, 0, 0, 0.6)'>Enter Ticker</Typography>
          );
        },
        editable: params => {
          const rowIndex = params.node.rowIndex as number;
          return rowIndex >= initialRowsNumber;
        },
        field: 'symbol',
        headerName: 'Ticker',
        lockPinned: true,
        maxWidth: 130,
        minWidth: 130,
        pinned: 'left',
        resizable: false,
        sortable: true,
        suppressPaste: true,
        valueFormatter: params => params.data?.symbol || 'Enter Ticker'
      },
      {
        cellRenderer: params => {
          return (
            <Typography
              color='rgba(0, 0, 0, 0.6)'
              overflow='hidden'
              textOverflow='ellipsis'
              title={params.value || ''}>
              {params.value || ' '}
            </Typography>
          );
        },
        field: 'fundName',
        headerName: '',
        minWidth: 130,
        pinned: true
      },
      {
        cellRenderer: () => <></>,
        field: 'fundName',
        headerName: '',
        maxWidth: 75,
        minWidth: 75,
        pinned: true
      },
      ...times(props.numberOfPlans, columnIndex => {
        const planName = `planName${columnIndex}`;
        const headerName = form.values.rows[0][
          planName as keyof SecurityTableRow
        ] as string;
        const field = `status${columnIndex}`;

        return {
          cellRenderer: cellData => {
            return cellData.data[field] ? (
              <DataTableBadgeCell
                color={statusColorMap.get(cellData.data[field])}>
                {cellData.data[field]}
              </DataTableBadgeCell>
            ) : (
              ' '
            );
          },
          field,
          headerClass: () => classes.columnHeader,
          headerName,
          headerTooltip: headerName,
          minWidth: 75
        };
      })
    ],
    [
      props?.numberOfPlans,
      form.values.rows,
      classes.columnHeader,
      onCommit,
      initialRowsNumber
    ]
  );

  const newValues = useMemo(() => {
    return form.values.rows
      .filter(row =>
        Array.from({ length: props.numberOfPlans }).some(
          (_, i) => row[`status${i}`] === FUND_STATUS_LABELS.NEW_STATUS
        )
      )
      .map(row => ({
        fund: { cusip: row.cusip, symbol: row.symbol },
        id: row.symbol,
        plans: Array.from({ length: props.numberOfPlans }, (_, i) => i)
          .filter((_, i) => row[`status${i}`] === FUND_STATUS_LABELS.NEW_STATUS)
          .map(i => ({
            plan: row[`planId${i}`],
            planName: row[`planName${i}`]
          })),
        requestNote: row.requestNote
      }));
  }, [form.values.rows, props.numberOfPlans]);

  useEffect(() => {
    if (gridRef?.current) {
      gridRef.current.api.stopEditing();
    }
  }, [form.values.rows.length]); // to remove focus on the added row

  const onDialogClose = () => {
    if (form.isSubmitting) {
      return;
    }

    toggleConfirmModal(false);
  };

  unstable_usePrompt({
    message: 'Choose cancel to avoid losing the unsaved changes.',
    when: form.dirty
  });

  return (
    <>
      <BulkModal
        handleSubmit={handleSubmit}
        isBulkModalOpen={isBulkModalOpen}
        toggleBulkModal={toggleBulkModal}
      />
      <Dialog
        fullWidth
        maxWidth='md'
        onClose={onDialogClose}
        open={isConfirmModalOpen}>
        <DialogTitle>Confirm Opening Accounts</DialogTitle>
        <DialogContent>
          <ConfirmModalTable newValues={newValues} />
          <DialogActions>
            <Button disabled={form.isSubmitting} onClick={onDialogClose}>
              Keep Editing
            </Button>
            <Button
              color='primary'
              disabled={form.isSubmitting}
              onClick={form.submitForm}
              type='submit'
              variant='contained'>
              Confirm
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Paper className={classes.paper} elevation={0}>
        <Card>
          <CardHeader
            actionButtonsProps={[
              {
                disabled: !(form.dirty || props.isInitiallyDirty),
                label: 'Update',
                onClick: () => toggleConfirmModal(true),
                variant: 'contained'
              }
            ]}
            menuProps={
              props.numberOfPlans
                ? {
                    onClick: () => toggleBulkModal(true),
                    options: [
                      { label: 'Bulk add securities', value: 'bulk-add' }
                    ]
                  }
                : undefined
            }
            title='Manage Securities'
          />

          <DataTable
            animateRows={true}
            columnDefs={columnDefs}
            columnSizing='fit'
            data-testid='data-program-securities-table'
            defaultColDef={{
              suppressMenu: true,
              suppressMovable: true
            }}
            detailCellRenderer={detailCellRenderer}
            detailColumn={2}
            detailRowAutoHeight
            emptyPlaceholderComponent={
              <Stack
                alignItems='center'
                data-testid='no-data-program-securities-table'
                justifyContent='center'
                sx={{ height: '100%' }}>
                <CardPlaceholder
                  icon={<SearchIcon fontSize='inherit' />}
                  subtitle='No related data found.'
                />
              </Stack>
            }
            enterNavigatesVerticallyAfterEdit
            gridRef={gridRef}
            icons={{
              groupContracted: DOMInteraction.JSXToHTML(
                <ArrowRightIcon
                  className='mui-group-icon'
                  color='disabled'
                  fontSize='small'
                />
              ),
              groupExpanded: DOMInteraction.JSXToHTML(
                <ArrowDropDownIcon
                  className='mui-group-icon'
                  color='disabled'
                  fontSize='small'
                />
              )
            }}
            primaryKey='id'
            rowData={tickerRows as any[]}
            rowHeight={56}
            singleClickEdit
            stopEditingWhenCellsLoseFocus
            suppressRowVirtualisation
          />
        </Card>
      </Paper>
    </>
  );
};

SecuritiesTab.displayName = 'SecuritiesTab';
