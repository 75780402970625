import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

interface CustomProps {
  onChange: (event: { target: { name: string; value?: number } }) => void;
  name: string;
}

export const NumericDollarFormat = React.forwardRef<
  NumericFormatProps,
  CustomProps
>((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      decimalScale={2}
      fixedDecimalScale
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue
          }
        });
      }}
      thousandSeparator
      valueIsNumericString
      {...other}
    />
  );
});
