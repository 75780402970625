import { SubaExecutionStatus } from '@/models/SubaExecution.model';

export const SubaExecutionStatusDisplayMap: Record<
  SubaExecutionStatus,
  string
> = {
  CANCELED: 'Canceled',
  CREATED: 'Created',
  FAILED: 'Failed',
  REPLAYED: 'Replayed',
  STARTED: 'Started',
  SUBA_ABORTED: 'Workflow Aborted',
  SUBA_FAILED: 'Workflow Failed',
  SUBA_REVERSED: 'Reversed',
  SUBA_RUNNING: 'Workflow Running',
  SUBA_STARTED: 'Workflow Started',
  SUBA_SUCCEEDED: 'Workflow Succeeded',
  'SUBA_TIMED-OUT': 'Workflow Timed Out',
  SUCCEEDED: 'Succeeded'
};
