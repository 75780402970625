import { ForfeitureHistory } from '@/routes/plans/plan-detail/PlanForfeituresTab/ForfeitureHistory.component';
import { ForfeiturePendingTransactions } from '@/routes/plans/plan-detail/PlanForfeituresTab/ForfeiturePendingTransactions.component';
import { Stack } from '@mui/material';

import { FC } from 'react';

import { ForfeitureInfo } from './ForfeitureInfo.component';

interface PlanForfeituresTabProps {
  sponsorPlanId: number;
}

export const PlanForfeituresTab: FC<PlanForfeituresTabProps> = props => (
  <Stack spacing={4}>
    <ForfeitureInfo sponsorPlanId={props.sponsorPlanId} />
    <ForfeiturePendingTransactions sponsorPlanId={props.sponsorPlanId} />
    <ForfeitureHistory sponsorPlanId={props.sponsorPlanId} />
  </Stack>
);
