import AccessControl from '@/components/access-control/AccessControl.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { UsersItemDto } from '@/models/UsersDTO.model';
import UserManagementService from '@/services/UserManagement.service';
import { WarningAmberOutlined } from '@mui/icons-material';
import { Alert, Box, Button, List, ListItemText } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import React, { FC, useCallback } from 'react';

export interface UserManagementAccountAccessProps {
  badge?: {
    color: string;
    value: string;
  };
  refetchUser: () => void;
  selectedUser: UsersItemDto;
}

export const UserManagementAccountAccess: FC<
  UserManagementAccountAccessProps
> = props => {
  const { showSnackbar } = useSnackbar();

  const patchUserLock = useMutation(
    (isAccessRestricted: boolean) =>
      UserManagementService.patchUserAccess(
        isAccessRestricted,
        +props.selectedUser.userId
      ),
    {
      onError: () => {
        showSnackbar({
          message: `Failed! Couldn’t ${
            !props.selectedUser?.isAccessRestricted ? 'reinstate' : 'restricted'
          } access`,
          severity: 'error'
        });
      },
      onSuccess: data => {
        showSnackbar({
          message: `${
            props.selectedUser?.email
          }'s account access has been successfully ${
            data?.isAccessRestricted ? 'restricted' : 'reinstated'
          }`,
          severity: 'success'
        });
        props.refetchUser();
      }
    }
  );

  const onPatchuserLock = useCallback(async () => {
    await patchUserLock.mutateAsync(!props.selectedUser?.isAccessRestricted);
  }, [patchUserLock]);

  return (
    <Box>
      <AccessControl
        prohibitedAccessComponent={
          <Alert icon={<WarningAmberOutlined />} severity='warning'>
            You do not have permissions to restrict or allow account access,
            please contact a service manager.
          </Alert>
        }
        requires={[
          FeatureLevelPermissions.WRITE_USER_MANAGEMENT_ACCOUNT_ACCESS
        ]}>
        <List sx={{ listStyleType: 'disc', pl: 4 }}>
          <ListItemText sx={{ display: 'list-item' }}>
            Once restricted, user will not be able to access the account.
          </ListItemText>
          <ListItemText sx={{ display: 'list-item' }}>
            User can only access account again after the restriction is removed
            on this page.
          </ListItemText>
          <ListItemText sx={{ display: 'list-item' }}>
            <strong>
              User identity must be verified over a call before removing
              restriction.
            </strong>
          </ListItemText>
        </List>
        <Button
          color='error'
          disabled={patchUserLock.isLoading}
          onClick={onPatchuserLock}
          variant='outlined'>
          {props.selectedUser?.isAccessRestricted ? 'ALLOW' : 'RESTRICT'}{' '}
          ACCOUNT ACCESS
        </Button>
      </AccessControl>
    </Box>
  );
};
