import { Buffer } from 'buffer';
import { read, utils } from 'xlsx';

export const toJson = (file: Buffer): Record<string, any>[] => {
  const workbook = read(file, { raw: true });

  return utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
};

export const toCsv = (file: Buffer): string => {
  const workbook = read(file, { raw: true });

  return utils.sheet_to_csv(workbook.Sheets[workbook.SheetNames[0]]);
};
