import { useSnackbar } from '@/contexts/SnackBarContext';
import {
  ProgramRequest,
  ProgramRule,
  RuleType,
  RuleValue
} from '@/models/ops/investments/Program.model';
import ProgramDetails, {
  validationSchema
} from '@/routes/ops/investments/ProgramDetails.component';
import { ProgramService } from '@/services/ops/investments/Program.service';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import { useFormik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface ProgramModalProps {
  open: boolean;
  onClose: () => void;
}

const ProgramModal = (props: ProgramModalProps): JSX.Element => {
  const { open, onClose } = props;
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const programData = {
    name: '',
    programRule: []
  } as ProgramRequest;

  const { mutateAsync: submitProgram, isLoading: isSubmitting } = useMutation(
    ['ProgramService.postProgram'],
    (newProgram: ProgramRequest) => {
      return ProgramService.postProgram(newProgram);
    }
  );

  const form = useFormik({
    enableReinitialize: true,
    initialValues: programData,
    onSubmit: async values => {
      try {
        const res = await submitProgram(values);
        snackbar.showSnackbar({
          message: 'Save Successful',
          severity: 'success'
        });
        navigate(`/ops/investments/program/${res.programId}`);
        form.resetForm({ values: programData });
        onClose();
      } catch (error) {
        snackbar.showSnackbar({
          message: `Failed to save program: ${error}`,
          severity: 'error'
        });
      }
    },
    validationSchema: validationSchema
  });

  const onCloseModal = () => {
    form.resetForm({ values: programData });
    onClose();
  };

  const setProgramValue = (field: string, value: any) => {
    switch (field) {
      case 'name': {
        form.setFieldValue('name', value);
        break;
      }
      case `programRule`: {
        const ruleType = value?.ruleType as RuleType;
        const ruleValue = value?.ruleValue as RuleValue;
        const checked = value?.checked as boolean;
        if (checked && ruleType === RuleType.default_type) {
          const arr = form.values.programRule
            .filter(
              rule =>
                rule.ruleValue !== ruleValue &&
                rule.ruleValue !== RuleValue.model
            )
            .map(rule => ({
              ...rule,
              ...{ ruleType: RuleType.available_type }
            }));
          const newDefault =
            form.values.programRule.find(
              rule => rule.ruleValue === ruleValue
            ) ||
            programData.programRule.find(
              rule => rule.ruleValue === ruleValue
            ) ||
            ({ ruleType, ruleValue } as ProgramRule);

          form.setValues({
            ...form.values,
            defaultModelId:
              ruleValue === RuleValue.model
                ? programData.defaultModelId
                : undefined,
            managedAccount:
              ruleValue === RuleValue.dynamic_goal
                ? programData.managedAccount
                : undefined,
            programRule: arr.concat([{ ...newDefault, ruleType }])
          });
        } else if (checked) {
          const newRule =
            programData.programRule.find(
              rule => rule.ruleValue === ruleValue
            ) || ({ ruleType, ruleValue } as ProgramRule);
          form.setFieldValue(
            'programRule',
            form.values.programRule.concat([{ ...newRule, ruleType }])
          );
          if (ruleValue === RuleValue.target && form.values.managedAccount)
            form.setFieldValue('managedAccount', {
              ...form.values.managedAccount,
              fallbackRule: RuleValue.target
            });
        } else {
          form.setFieldValue(
            'programRule',
            form.values.programRule.filter(rule => rule.ruleValue !== ruleValue)
          );
          if (ruleValue === RuleValue.target && form.values.managedAccount)
            form.setFieldValue('managedAccount', {
              minimumAge: form.values.managedAccount?.minimumAge
            });
        }
        break;
      }
      case 'defaultModelId': {
        form.setFieldValue('defaultModelId', parseInt(value) || undefined);
        break;
      }
      case 'minimumAge': {
        const managedAccount = form.values.managedAccount
          ? form.values.managedAccount
          : {};
        form.setFieldValue('managedAccount', {
          ...managedAccount,
          minimumAge: parseInt(value) || undefined
        });
        break;
      }
      case 'fallbackRule': {
        const managedAccount = form.values.managedAccount
          ? form.values.managedAccount
          : {};
        form.setFieldValue('managedAccount', {
          ...managedAccount,
          fallbackRule: value as RuleValue
        });
        break;
      }
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      onClose={() => (isSubmitting ? () => {} : onCloseModal())}
      open={open}>
      <DialogTitle>Add New Program</DialogTitle>
      <DialogContent>
        <ProgramDetails
          formikErrors={form.errors}
          program={form.values}
          setProgramValueCallback={setProgramValue}
        />
      </DialogContent>

      <DialogActions sx={{ padding: '1.5rem' }}>
        <Button
          color='primary'
          disableRipple
          onClick={onCloseModal}
          variant='text'>
          Cancel
        </Button>
        <Button
          aria-label='Create Program'
          color='primary'
          disableRipple
          disabled={!(form.dirty && form.isValid)}
          onClick={() => form.handleSubmit()}
          variant='contained'>
          Create Program
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProgramModal;
