import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { TextField } from '@mui/material';

import { useField } from 'formik';
import { InputBaseProps } from 'formik-mui';
import React, { useEffect, useState } from 'react';

import formatters from '../../utils/Formatters';

type SimpleNumberFieldProps = {
  testId?: string;
  label?: string;
  name: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  value?: string;
  inputProps?: InputBaseProps['inputProps'];
  allowNegative?: boolean;
  precision?: number;
  className?: string;
  isDisabled?: boolean;
  error?: boolean;
  size?: 'small' | 'medium';
  format?: (value: string) => string;
};

const SimpleNumberField = React.forwardRef<
  HTMLInputElement,
  SimpleNumberFieldProps
>((props: SimpleNumberFieldProps, ref) => {
  const [, , helpers] = useField(props.name);
  const [currentValue, setCurrentValue] = useState(props.value);

  const handleChange = (event: { target: { value: string } }) => {
    const eventValue = formatters.parseDecimalInput(
      event.target.value,
      props.allowNegative,
      props.precision
    );

    if (!Number.isNaN(eventValue)) {
      setCurrentValue(String(eventValue));
      props.onChange(String(eventValue));
    }
  };

  const handleBlur = (event: { target: { name: string; value: string } }) => {
    const value =
      event.target.value === EMPTY_FIELD_PLACEHOLDER
        ? EMPTY_FIELD_PLACEHOLDER
        : event.target.value === '' || isNaN(+event.target.value)
        ? ''
        : Number(event.target.value).toFixed(props.precision);

    props.onChange(value);

    if (value !== '' && value !== EMPTY_FIELD_PLACEHOLDER) {
      setCurrentValue(props.format(value));
    }

    if (props?.onBlur) {
      return props.onBlur();
    }
    helpers.setTouched(true);
  };

  const handleFocus = () => {
    setCurrentValue(props.value);
  };

  useEffect(() => {
    if (props.format(props.value) !== props.format(currentValue)) {
      setCurrentValue(props.format(props.value));
    }
  }, [props.value]);

  return (
    <TextField
      className={props.className}
      data-testid={props.testId}
      disabled={props.isDisabled}
      error={props.error}
      inputProps={{
        readOnly: props.isDisabled,
        ...props.inputProps
      }}
      inputRef={ref}
      label={props.label}
      name={props.name}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      size={props.size}
      type='text'
      value={currentValue}
    />
  );
});

SimpleNumberField.displayName = 'SimpleNumberField';

SimpleNumberField.defaultProps = {
  allowNegative: true,
  format: value => value,
  precision: 0,
  size: 'medium'
};

export default SimpleNumberField;
