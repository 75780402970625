import LinearLoading from '@/components/linear-loading';
import NavigationBreadcrumbs from '@/components/navigation-breadcrumbs';
import RiskSeries, {
  RiskSeriesData,
  RiskSeriesGridData,
  RiskSeriesModelProps
} from '@/routes/ops/investments/RiskSeries.component';
import ApiService from '@/services/Api.service';
import InvestmentService from '@/services/Investment.service';
import { useQuery } from '@tanstack/react-query';

import { useParams } from 'react-router-dom';

type RiskSeriesEditRouteProps = {
  id: string;
};

const RiskSeriesEditRoute = (): JSX.Element => {
  const params = useParams<RiskSeriesEditRouteProps>();

  const paths = [
    {
      name: 'Ops',
      to: '/ops/investments'
    },
    {
      name: 'Investments',
      to: '/ops/investments'
    }
  ];

  const riskSeriesGridQuery = useQuery<RiskSeriesGridData>(
    ['RiskSeries.editRiskSeries', 'grid-models', params.id],
    () => {
      return InvestmentService.getRiskSeriesGrid(+params.id);
    },
    { cacheTime: 0 }
  );

  const riskSeriesQuery = useQuery<RiskSeriesData>(
    ['RiskSeries.editRiskSeries', 'risk-series', params.id],
    () => {
      return InvestmentService.getRiskModelSeries(+params.id);
    },
    { cacheTime: 0 }
  );

  const riskModelQuery = useQuery<RiskSeriesModelProps[]>(
    ['RiskSeries.editRiskSeries', 'risk-models', params.id],
    () => {
      return InvestmentService.getRiskModels(+params.id);
    },
    { cacheTime: 0 }
  );

  const programCount = useQuery<any>(
    ['RiskSeries.editRiskSeries', 'program-count', +params.id],
    () => {
      return ApiService.getJson(
        `/investments/program?riskSeriesId=${+params.id}&pageNumber=1&pageSize=0`
      );
    },
    { cacheTime: 0 }
  );

  const onChange = async (
    updatedRiskSeries: RiskSeriesData,
    updatedModelGridProps: RiskSeriesGridData
  ): Promise<[RiskSeriesData, RiskSeriesGridData]> => {
    const result = InvestmentService.putRiskSeries(updatedRiskSeries);
    const gridResult = InvestmentService.postRiskSeriesGrid(
      +params.id,
      updatedModelGridProps
    );

    const all = await Promise.all([result, gridResult]);
    return all;
  };

  const loading =
    riskSeriesQuery.isFetching ||
    riskModelQuery.isFetching ||
    riskSeriesGridQuery.isFetching ||
    programCount.isFetching;
  const canDisplay =
    !loading &&
    riskSeriesQuery.data !== undefined &&
    riskModelQuery.data !== undefined &&
    riskSeriesGridQuery.data !== undefined;
  const possibleError = !loading && !canDisplay;

  return (
    <>
      <NavigationBreadcrumbs paths={paths} />
      {loading && <LinearLoading />}
      {canDisplay && (
        <RiskSeries
          lookupTickerCallback={InvestmentService.searchSymbol}
          programCount={programCount.data?.meta?.count}
          riskSeries={riskSeriesQuery.data}
          riskSeriesGrid={riskSeriesGridQuery.data}
          saveCallback={onChange}
        />
      )}
      {possibleError && <div>Possible Error</div>}
    </>
  );
};

export default RiskSeriesEditRoute;
