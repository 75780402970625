import Constants from '@/consts/planDesign.constants';
import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class InServiceAtSpecifiedAge {
  convertToApiState = Helper.convertValueToApiState;

  options = Constants.inServiceAtSpecifiedAgeOptions;

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.output = props.allowInServiceAtSpecifiedAge;
  }
}

export default InServiceAtSpecifiedAge;
