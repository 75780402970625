import Badge from '@/components/badge';
import { CellComponentProps } from '@/components/collapsible-table';
import { Box, Link, TableCell, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { colorByStatus } from './helpers/colorMapping';

const useStyles = makeStyles((theme: Theme) => ({
  size: {
    fontSize: theme.spacing(2)
  }
}));

const RolloverTableCell: React.FunctionComponent<CellComponentProps> = (
  props: CellComponentProps
) => {
  const classes = useStyles();
  const { row, column } = props;
  const field =
    column.field === 'status' ? (
      <Badge color={colorByStatus[row[column.field]]}>
        {row[column.field]}
      </Badge>
    ) : (
      <Box className={classes.size}>
        {column.field === 'id' ? (
          <Link
            component={RouterLink}
            to={`/participant/${row.participantId}/rollovers/${row.id}`}>
            {row[column.field]}
          </Link>
        ) : (
          row[column.field]
        )}
      </Box>
    );

  return (
    <TableCell component='th' scope='row'>
      <Box>{field}</Box>
    </TableCell>
  );
};

export default RolloverTableCell;
