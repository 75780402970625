import {
  FraudrankerResultData,
  UpdatedFraudranker
} from './FraudrankerResult.model';

export enum WithdrawalReason {
  'RETIREMENT_AGE' = 'Retirement Age',
  'ROLLOVER' = 'Withdraw Rollover Funds',
  'HARDSHIP' = 'Hardship',
  'CHILD' = 'Birth/Adoption of Child',
  'DISABILITY' = 'Disability',
  'PLAN_TERMINATION' = 'Plan Termination',
  'PARTICIPANT_TERMINATION' = 'Termination of Employment'
}

export interface FormattedWithdrawalDto {
  id: number;
  reason: WithdrawalReason | string;
  amount: string;
  amountValue: number;
  dateRequested: string;
  status: string;
  participantId: number;
  participantFirstName?: string;
  participantLastName?: string;
}

interface WithdrawalDetailsAttributes {
  participantId: number;
  withdrawalReason: string;
  distributionMethod: string;
  status: string;
  context: {
    createWithdrawalDetails: WithdrawalDetailsDto;
    customWithdrawalDetails: CustomWithdrawalDetails;
    withdrawalDerivedAmounts: {
      withdrawalAmount: number;
      fee: number;
      cashWithdrawalDeliveryFee?: number;
      rolloverPreTaxDeliveryFee?: number;
      rolloverRothDeliveryFee?: number;
    };
    jiraTicketKey?: string;
    taxInfo?: WithdrawalTaxDto;
  };
  createdAt: string;
  notes?: string;
  fraudRankResult?: FraudrankerResultData;
  fraudNotes?: string;
  sftResult?: WithdrawalStp[];
  submitted: boolean;
  updatedFraudRankResult?: UpdatedFraudranker;
  validationErrors?: SubaValidationError[];
  calloutToParticipant: boolean;
  addressUpdate?: WithdrawalAddressUpdate;
}

export type WithdrawalTaxDto = {
  stateTax?: number;
  federalTax?: number;
  netEstimatedAmount?: number;
  totalFee?: number;
};

export interface WithdrawalDetailsDto {
  hardship?: string;
  esaReason?: string;
  rolloverAccount?: 'PRETAX' | 'ROTH' | 'BOTH';
  partialAmountPretax?: number;
  partialAmountRoth?: number;
  withdrawalAmount?: number;
  cashWithdrawalDeliveryMethod?: 'ACH' | 'CHECK' | 'WIRE' | 'OVERNIGHT_CHECK';
  cashWithdrawalAddress1?: string;
  cashWithdrawalAddress2?: string;
  cashWithdrawalCity?: string;
  cashWithdrawalState?: string;
  cashWithdrawalZip?: string;
  cashWithdrawalBankAccountId?: number;
  cashWithdrawalForCreditTo?: string;
  cashWithdrawalForFurtherCreditTo?: string;
  rolloverPretaxBankName?: string;
  rolloverPretaxPayee?: string;
  rolloverPretaxMemo?: string;
  rolloverPretaxAddress1?: string;
  rolloverPretaxAddress2?: string;
  rolloverPretaxCity?: string;
  rolloverPretaxState?: string;
  rolloverPretaxZip?: string;
  rolloverPretaxForCreditTo?: string;
  rolloverPretaxForFurtherCreditTo?: string;
  rolloverPreTaxDeliveryMethod: string;
  rolloverPretaxRoutingNumber?: string;
  rolloverPretaxAccountNumber?: string;
  rolloverPretaxBankAccountRegistration?: string;
  rolloverIsRothSameAddr?: boolean;
  rolloverRothBankName?: string;
  rolloverRothPayee?: string;
  rolloverRothMemo?: string;
  rolloverRothAddress1?: string;
  rolloverRothAddress2?: string;
  rolloverRothCity?: string;
  rolloverRothState?: string;
  rolloverRothZip?: string;
  rolloverRothDestination?: string;
  rolloverRothForCreditTo?: string;
  rolloverRothForFurtherCreditTo?: string;
  rolloverRothDeliveryMethod: string;
  rolloverRothBankAccountRegistration?: string;
  rolloverRothRoutingNumber?: string;
  rolloverRothAccountNumber?: string;
  rolloverPreTaxDestination?: string;
}

export interface WithdrawalDto {
  type: string;
  id: number;
  attributes: WithdrawalDetailsAttributes;
}

export interface WithdrawalResponseDto {
  data: WithdrawalDto;
}

export interface WithdrawalListDto {
  data: WithdrawalDto[];
}

export type WithdrawalTransactionDto = {
  id: number;
  tradeDate: string;
  effectiveDate: string;
  fundingSource: string;
  transactionSubtype: string;
  ticker: string;
  amount: number;
  status: string;
  tracerId: string;
};

export type WithdrawalSubAccounts = {
  coreAccountId: string;
  subAccountTaxType: string;
};

export interface WithdrawalTradeRequestDto {
  createdAt: string;
  id: number;
  participantId: string;
  participantName: string;
  status: string;
  workflowStatus?: string;
  errorMessage?: string;
  request?: {
    disbursements: CustomWithdrawalDisbursement[];
    subAccounts: WithdrawalSubAccounts[];
  };
  actionType: string;
  updatedAt: string;
  tracerId: string;
  subaResult?: any;
}

export interface CustomWithdrawalDisbursement {
  taxType?: string;
  amount?: number | string;
  fullAmount: boolean;
  feeAmount?: number;
  disbursementMethod?: string;
  disbursementType?: string;
  useParticipantAddress: boolean;
  payeeName?: string;
  payeeAddress?: string;
  payeeCity?: string;
  payeeZip?: string;
  payeeState?: string;
  payeeCountry?: string;
  overnightCheck: boolean;
  overnightAccountNumber?: string;
  receivingBankName?: string;
  receivingBankAccountName?: string;
  receivingBankAccountNumber?: string;
  receivingBankRoutingNumber?: string;
  furtherCreditToName?: string;
  furtherCreditToAccountNumber?: string;
  referenceMemo?: string;
  federalWithholdingPercent?: string;
  stateWithholdingCode?: string;
  stateWithholdingPercent?: string;
  _1099Code?: string;
  additional1099rCode?: string;
  rothInitialYear?: number;
  rothQualifiedWithdrawal?: boolean;
  rothOrAfterTaxBasisAmount?: string;
  isRollover?: boolean;
  rothIRADestination?: boolean;
}

export interface CustomWithdrawalAccount {
  accountId?: number;
  applyVesting: boolean;
  forfeitureFlag: boolean;
}

export interface CustomWithdrawalDetails {
  beneficiaryId?: number;
  contributionYear?: number;
  accounts: CustomWithdrawalAccount[];
  disbursements: CustomWithdrawalDisbursement[];
}

export type CustomWithdrawalDetailsDto = {
  participantId: number;
  reason: string;
  jiraTicket: string;
  details: CustomWithdrawalDetails;
  forceSkipLocationValidation: boolean;
  sendEmail: boolean;
};

export type CustomWithdrawalRothCostBasisDto = {
  participantId: number;
  reason: string;
  disbursements: CustomWithdrawalDisbursement[];
};

export interface DistributionAccount {
  accountId: number;
  source: { code: string; name: string };
  taxType: string;
  balance: number;
  loanBalance: number;
  previousWithdrawal: number;
  vestedPercent?: number;
}

export type DistributionAccountsDto = {
  data: DistributionAccount[];
};

export interface SubaValidationError {
  source: string;
  error: string;
  hint: string;
}

export interface DistributionRothBasisAmount {
  errors?: SubaValidationError[];
  amount?: number;
}

export type CustomWithdrawalRothBasisResponse = {
  data: DistributionRothBasisAmount[];
};

export type CustomWithdrawalResponse = {
  withdrawalId: number;
  attributes: WithdrawalDetailsAttributes;
  validationErrors: SubaValidationError[];
  submitted: boolean;
};

export type WithdrawalSierraRequest = {
  data: {
    validationErrors: SubaValidationError[];
    subaRequest?: {
      disbursements: CustomWithdrawalDisbursement[];
      subAccounts: WithdrawalSubAccounts[];
    };
  };
};

export type SubmitForceOutDistributionResponse = {
  data: {
    successes: number[];
    failed: number[];
    errorMessage?: string;
    results?: any[];
  };
};

export type ForceOutDistribution = {
  id?: number;
  participantIds: number[];
  status: string;
  reason: string;
  createdAt: string;
  updatedAt: string;
  approvedBy?: any;
  createdBy: any;
  version: number;
};

export type ForceOutDistributionResponse = {
  data: ForceOutDistribution;
};

export type CashDestination = {
  deliveryType: string;
  isFullAmount: boolean;
  accountNumber?: string;
  address1?: string;
  address2?: string;
  bankName?: string;
  city?: string;
  creditTo?: string;
  furtherCreditTo?: string;
  routingNumber?: string;
  state?: string;
  zip?: string;
  partialPreTaxAmount?: number;
  partialRothAmount?: number;
  afterTaxAmount?: number;
};

export type RolloverDestination = {
  deliveryType: string;
  isFullAmount: boolean;
  accountNumber?: string;
  address1?: string;
  address2?: string;
  bankName?: string;
  city?: string;
  creditTo?: string;
  destination?: string;
  furtherCreditTo?: string;
  routingNumber?: string;
  state?: string;
  zip?: string;
  amount?: number;
  memo?: string;
  payee?: string;
};

export type WithdrawalDestinationDto = {
  data: {
    isRothSameDestination: boolean;
    withdrawalId: number;
    cashDestination?: CashDestination;
    preTaxRolloverDestination?: RolloverDestination;
    rothRolloverDestination?: RolloverDestination;
  };
};

type baseUpdateAddressInfo = {
  status?: string;
  withdrawalAddress1?: string;
  withdrawalAddress2?: string;
  withdrawalCity?: string;
  withdrawalState?: string;
  withdrawalZip?: string;
  withdrawalMemo?: string;
  withdrawalPayee?: string;
};

export type WithdrawalAddressUpdate = {
  approvedBy?: any;
  cash?: baseUpdateAddressInfo;
  isRothSameAddress?: boolean;
  rolloverPretax?: baseUpdateAddressInfo;
  rolloverRoth?: baseUpdateAddressInfo;
  submittedBy: any;
  updatedAt?: string;
};

export type WithdrawalAddressUpdateRequest = {
  address1?: string;
  address2?: string;
  city?: string;
  deliveryType: 'Cash' | 'RolloverPretax' | 'RolloverRoth';
  memo?: string;
  payee?: string;
  state?: string;
  status: 'Approved' | 'Canceled' | 'Pending' | 'Rejected';
  withdrawalId?: number;
  zip?: string;
};

export type WithdrawalFraudRankUpdateRequest = {
  withdrawalId: number;
  calloutToParticipant?: boolean;
  fraudNotes?: string;
  latestFraudRank?: string;
};

type StpResult = {
  passed: boolean;
  reason: string;
  ruleName: string;
  ruleType: 'FRAUD' | 'DISTRIBUTION';
};

export type WithdrawalStp = {
  createdAt: string;
  passed: boolean;
  result: StpResult[];
  ruleType: 'FRAUD' | 'DISTRIBUTION';
  updatedAt?: string;
  withdrawalId: number;
};
