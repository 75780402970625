import SimpleDropdown from '@/components/simple-dropdown';
import { UNVERIFIED_PRIOR_PROVIDER_ID } from '@/consts/plan.constants';
import { PriorProvidersDto } from '@/models';
import {
  PriorProvider,
  PriorProviderService
} from '@/services/PriorProviders.service';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';

import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import React, { ReactElement, useEffect, useMemo } from 'react';

const useStyles = makeStyles(() => ({
  field: {
    marginTop: '1rem !important'
  },
  hidden: {
    display: 'none'
  }
}));

interface PriorProviderComponentProps {
  required?: boolean;
  withUnverified?: boolean;
}

const PriorProviderComponent = (
  props: PriorProviderComponentProps
): ReactElement => {
  const classes = useStyles();
  const ctx = useFormikContext<any>();

  const priorProvidersQuery = useQuery<PriorProvidersDto>(
    ['PriorProviders.service'],
    () => PriorProviderService.getPriorProviders()
  );

  const isHidden = useMemo(
    () =>
      ctx?.values?.conversionType !== 'Conversion' &&
      ctx?.values?.planConversionType !== 'Conversion',
    [ctx?.values?.conversionType, ctx?.values?.planConversionType]
  );

  const priorProviders = useMemo(() => {
    return [
      ...(priorProvidersQuery?.data?.data || []).map((pp: PriorProvider) => ({
        option: pp?.priorProviderName?.toString(),
        value: pp?.priorProviderId as any
      })),
      { option: 'Other', value: UNVERIFIED_PRIOR_PROVIDER_ID }
    ];
  }, [priorProvidersQuery.data]);

  const unverifiedPriorProvider = useMemo(() => {
    const verifiedPriorProvider = priorProvidersQuery.data?.data?.find(
      pp => pp.priorProviderId === ctx?.values?.priorProviderId
    );

    return !verifiedPriorProvider ? ctx?.values?.priorProvider : '';
  }, [ctx, priorProvidersQuery.data?.data]);

  useEffect(() => {
    if (
      props.withUnverified &&
      unverifiedPriorProvider &&
      ctx.values?.priorProviderId !== UNVERIFIED_PRIOR_PROVIDER_ID
    ) {
      ctx.setValues({
        ...ctx.values,
        priorProviderId: UNVERIFIED_PRIOR_PROVIDER_ID,
        priorProviderUnverified: unverifiedPriorProvider
      });
    }
  }, [unverifiedPriorProvider, ctx, props.withUnverified]);

  return (
    <Box className={isHidden ? classes.hidden : ''}>
      <SimpleDropdown
        fieldId='priorProviderId'
        fieldName='Prior Provider'
        fieldOptions={priorProviders}
        required={props.required}
      />
      {props.withUnverified &&
        ctx.values?.priorProviderId === UNVERIFIED_PRIOR_PROVIDER_ID && (
          <Field
            className={classes.field}
            component={TextField}
            fullWidth
            label='Other Prior Provider Name'
            name='priorProviderUnverified'
          />
        )}
    </Box>
  );
};

export default PriorProviderComponent;
