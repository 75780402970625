import BasePlan from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/BasePlan';
import StarterVestwellWorkplaceHardshipWithdrawals from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/templates/starter/vestwell-workplace/StarterVestwellWorkplaceHardshipWithdrawals';
import StarterVestwellWorkplaceServiceCalculationType from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/templates/starter/vestwell-workplace/StarterVestwellWorkplaceServiceCalculationType';

class StarterVestwellWorkplace extends BasePlan {
  allowHardshipWithdrawals: StarterVestwellWorkplaceHardshipWithdrawals;

  serviceCalculationType: StarterVestwellWorkplaceServiceCalculationType;

  constructor(props: Record<string, any>) {
    super(props);

    this.allowHardshipWithdrawals =
      new StarterVestwellWorkplaceHardshipWithdrawals(
        props.distributionFeatures.allowHardshipWithdrawals
      );

    this.serviceCalculationType =
      new StarterVestwellWorkplaceServiceCalculationType(
        props.eligibilityFeatures.eligibilityRules
      );
  }
}

export default StarterVestwellWorkplace;
