import { Stack, Typography } from '@mui/material';

import { FC } from 'react';

import { FraudrankerHeader } from './FraudrankerHeader.component';
import { FraudrankerTable } from './FraudrankerTable.component';

export const FraudrankerRoute: FC = () => {
  return (
    <Stack spacing={2}>
      <Typography marginBottom={2} variant='h4'>
        Withdrawal and Loan Fraud Dashboard
      </Typography>
      <FraudrankerHeader />
      <FraudrankerTable />
    </Stack>
  );
};

FraudrankerRoute.displayName = 'FraudrankerRoute';
