import SimpleTabs, { TabData } from '@/components/simple-tabs';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { userService } from '@/services/User.service';

import { FC } from 'react';

import { BulkRebalanceTab } from './tools/BulkRebalanceTab.component';
import { ForceOutDistributionTab } from './tools/ForceOutDistributionTab.component';

export const BulkInvestmentRoute: FC = () => {
  const tabs: TabData[] = [
    {
      component: <BulkRebalanceTab />,
      label: 'Rebalance',
      path: '/rebalance'
    },
    userService.hasPermission(
      FeatureLevelPermissions.WRITE_FORCE_OUT_DISTRIBUTIONS
    )
      ? {
          component: <ForceOutDistributionTab />,
          label: 'Force-Out Distribution',
          path: '/force-out-distribution'
        }
      : null
  ].filter(i => i != null);

  return (
    <section>
      <SimpleTabs tabs={tabs} tabsAriaLabel='plans-tabs' />
    </section>
  );
};

BulkInvestmentRoute.displayName = 'BulkInvestmentRoute';
