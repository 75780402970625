import CollapsibleTable, {
  CellComponentProps,
  Order
} from '@/components/collapsible-table';
import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import {
  ProgramConverter,
  ProgramPlan,
  ProgramPlanDto
} from '@/models/ops/investments/Program.model';
import { ProgramService } from '@/services/ops/investments/Program.service';
import { Search } from '@mui/icons-material';
import {
  Box,
  InputAdornment,
  Link,
  Paper,
  TableCell,
  TextField,
  Theme,
  Typography
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontSize: theme.spacing(3)
    },
    paper: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(4),
      width: '100%'
    },
    planIdWidth: {
      width: '10%'
    },
    planNameWidth: {
      width: '20%'
    },
    searchInput: {
      marginTop: theme.spacing(3),
      width: theme.spacing(65)
    },
    searchSection: {
      marginBottom: theme.spacing(3),
      marginLeft: theme.spacing(3),
      marginTop: theme.spacing(3)
    },
    size: {
      fontSize: theme.spacing(2)
    },
    sponsorNameWidth: {
      width: '20%'
    },
    statusWidth: {
      width: '15%'
    },
    typeWidth: {
      width: '10%'
    }
  })
);

export interface ProgramDetailsPlansProps {
  programId: number;
  planId: number;
}

const ProgramDetailsPlans = (props: ProgramDetailsPlansProps): JSX.Element => {
  const { programId, planId } = props;
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');

  const planTableColumns: GridColDef[] = [
    {
      cellClassName: classes.planIdWidth,
      field: 'id',
      headerName: 'Plan ID'
    },
    {
      cellClassName: classes.planNameWidth,
      field: 'name',
      headerName: 'Plan Name'
    },
    {
      cellClassName: classes.sponsorNameWidth,
      field: 'companyName',
      headerName: 'Sponsor Name'
    },
    {
      cellClassName: classes.statusWidth,
      field: 'status',
      headerName: 'Plan Status'
    },
    {
      cellClassName: classes.typeWidth,
      field: 'type',
      headerName: 'Plan Type'
    }
  ];

  const programPlansQuery = useQuery<{
    data: ProgramPlanDto[];
    meta: { count: number };
  }>(
    [
      'ProgramService.getProgramPlans',
      programId,
      pageNumber,
      rowsPerPage,
      searchTerm,
      orderBy,
      order,
      planId
    ],
    async () => {
      return ProgramService.getProgramPlans(
        programId,
        pageNumber,
        rowsPerPage,
        searchTerm,
        orderBy,
        order
      );
    }
  );

  const programPlanDtoData = programPlansQuery.data?.data || [];
  const programPlansData: ProgramPlan[] = programPlanDtoData.map(plan =>
    ProgramConverter.toProgramPlan(plan)
  );

  const { error } = programPlansQuery;
  if (error) {
    return redirectToErrorPage(error as Error);
  }

  const planTableCell: React.FunctionComponent<CellComponentProps> = (
    cellProps: CellComponentProps
  ) => {
    const { row, column } = cellProps;
    let field;

    if (column.field === 'name') {
      field = (
        <Link
          color='secondary'
          component={RouterLink}
          to={`/plans/${row.id}`}
          underline='hover'>
          {row[column.field]}
        </Link>
      );
    } else field = row[column.field];

    return (
      <TableCell className={classes.size} component='th' scope='row'>
        <Box>{field}</Box>
      </TableCell>
    );
  };

  const searchProgramPlans = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;
    setSearchTerm(search);
    setPageNumber(1);
  };

  return (
    <Paper className={classes.paper} elevation={0} variant='outlined'>
      <div className={classes.searchSection}>
        <Typography className={classes.header} variant='h5'>
          Plans
        </Typography>
        <TextField
          InputProps={{
            onChange: searchProgramPlans,
            placeholder: 'Search plans',
            startAdornment: (
              <InputAdornment position='start'>
                <Search />
              </InputAdornment>
            )
          }}
          className={classes.searchInput}
          data-testid='search-plans'
          value={searchTerm}
          variant='outlined'
        />
      </div>
      <CollapsibleTable
        cellComponent={planTableCell}
        columns={planTableColumns}
        pager={{
          metaCount: programPlansQuery.data?.meta.count,
          onPageNumberChanged: (zeroIndexedPageNumber: number) => {
            return setPageNumber(zeroIndexedPageNumber + 1);
          },
          onRowsPerPageChanged: (newRowsPerPage: number) => {
            return setRowsPerPage(newRowsPerPage);
          },
          pageNumber: pageNumber - 1,
          rowsPerPage
        }}
        sorter={{
          onSortOrderChanged: (newOrderBy: string, newOrder: Order) => {
            setOrderBy(newOrderBy);
            setOrder(newOrder);
          },
          order,
          orderBy
        }}
        tableData={programPlansData}
      />
    </Paper>
  );
};

export default ProgramDetailsPlans;
