import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps
} from '@mui/material/AccordionSummary';
import { alpha, styled } from '@mui/material/styles';

import React from 'react';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  '&:before': {
    display: 'none'
  },
  '&:not(:last-child)': {
    borderBottom: 0
  }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(4)
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '&.Mui-expanded': {
    backgroundColor: alpha(theme.palette.primary.main, 0.04)
  },
  backgroundColor: 'rgba(0, 0, 0, 0.04)',
  boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.12)',
  flexDirection: 'row-reverse'
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0)
}));

export default Accordion;
export { AccordionSummary, AccordionDetails };
