import Card, { CardContent, CardPlaceholder } from '@/components/card';
import DataTable, {
  DataTableBadgeCell,
  DataTableStackCell
} from '@/components/data-table/DataTable.component';
import Link from '@/components/link/Link.component';
import { AlertDto } from '@/models/ops/alerts/AlertDTO.model';
import { AlertStatusColorMap } from '@/models/ops/alerts/AlertStatusColorMap.model';
import AuthZService from '@/services/AuthZ.service';
import AlertService from '@/services/ops/alerts/Alert.service';
import formatters from '@/utils/Formatters';
import SearchIcon from '@mui/icons-material/Search';
import { Divider, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { AlertSubType } from '@vestwell-sub-accounting/models/common/AlertSubType';

import { ColDef } from 'ag-grid-community';
import { FC, useContext } from 'react';

import { AlertContext } from '../../AlertContext';
import { CardHeader } from '../common/CardHeader.component';

export const CashTransferRejectedExpandedRecentReversals: FC = () => {
  const alert = useContext(AlertContext);
  const alertSearchQuery = useQuery(
    [
      'AlertService.search',
      {
        alertSubType: AlertSubType.ACHReversal,
        orderBy: 'createdAt',
        orderByDirection: 'desc',
        page: 1,
        pageSize: 0,
        parentAccountId: alert.details?.request?.fromSubAccount?.parentAccountId
      }
    ],
    async () => {
      const alerts = await AlertService.search({
        alertSubType: AlertSubType.ACHReversal,
        orderBy: 'createdAt',
        orderByDirection: 'desc',
        page: 1,
        pageSize: 0,
        parentAccountId: alert.details?.request?.fromSubAccount?.parentAccountId
      });
      return alerts;
    }
  );
  const vestwellStaffQuery = useQuery(
    ['AuthZService.getVestwellStaff'],
    async () => {
      const staff = await AuthZService.getVestwellStaff();
      return AuthZService.formatVestwellStaffList(staff);
    }
  );

  const columnDefs: ColDef[] = [
    {
      cellRenderer: (cellData: { data: AlertDto }) => (
        <Link target='_blank' to={`/ops/alerts/${cellData.data.id}`}>
          {`${cellData.data.id} `}
        </Link>
      ),
      field: 'id',
      headerName: 'ID',
      minWidth: 75,
      suppressMenu: true
    },
    {
      autoHeight: true,
      cellRenderer: (cellData: { data: AlertDto }) => {
        return (
          <DataTableBadgeCell
            color={AlertStatusColorMap[cellData.data.alertStatus]}>
            {formatters.displayCase(cellData.data.alertStatus)}
          </DataTableBadgeCell>
        );
      },
      field: 'status',
      headerName: 'Status',
      minWidth: 100,
      suppressMenu: true
    },

    {
      autoHeight: true,
      cellRenderer: (cellData: { data: AlertDto }) => {
        if (!cellData.data.assignee) {
          return null;
        }
        const matchedAssignee = vestwellStaffQuery?.data?.find(
          staffUser => staffUser.userId === cellData.data.assignee
        );
        return (
          <DataTableStackCell
            primary={matchedAssignee?.label || cellData.data.assignee}
          />
        );
      },
      field: 'assignee',
      headerName: 'Assignee',
      minWidth: 100,
      suppressMenu: true
    },
    {
      autoHeight: true,
      headerName: 'Amount',
      minWidth: 100,
      suppressMenu: true,
      valueFormatter: (cellData: { data: AlertDto }) => {
        return formatters.formatDollars(
          cellData.data.details?.event?.body?.[0]?.amount
        );
      }
    },
    {
      autoHeight: true,
      field: 'date',
      headerName: 'Date',
      minWidth: 175,
      suppressMenu: true,
      valueFormatter: ({ value }: { value: string }) =>
        formatters.formatFromIsoDateCustom(value, 'MM/DD/YYYY')
    }
  ];

  return (
    <Card data-testid='cash-transfer-rejected-expanded-reconciliation-exceptions-card'>
      <CardHeader title='Recent ACH Reversals' />
      <Divider />
      <CardContent
        disablePadding
        loading={
          alertSearchQuery.isInitialLoading ||
          vestwellStaffQuery.isInitialLoading
        }>
        <DataTable
          columnDefs={columnDefs}
          emptyPlaceholderComponent={
            <Stack
              alignItems='center'
              data-testid='cash-transfer-rejected-expanded-recent-reversals-no-data'
              height='100%'
              justifyContent='center'>
              <CardPlaceholder
                icon={<SearchIcon fontSize='inherit' />}
                subtitle='No results found'
              />
            </Stack>
          }
          pageSize={3}
          pageSizeOptions={[3]}
          pagination={true}
          rowData={alertSearchQuery?.data?.results || []}></DataTable>
      </CardContent>
    </Card>
  );
};

CashTransferRejectedExpandedRecentReversals.displayName =
  'CashTransferRejectedExpandedRecentReversals';
