import AppConfig from '@/App.config';
import { userService } from '@/services/User.service';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { BroadcastChannel } from 'broadcast-channel';
import React, { FC, useState } from 'react';
import { EventsType, useIdleTimer } from 'react-idle-timer';

import { BroadcastSignal } from './Logout.component';

const events: EventsType[] = ['keydown', 'click'];

type SessionTimerDialogProps = {
  timeout: number;
  promptBeforeIdle: number;
  getAuth0AccessToken: (ignoreCache?: boolean) => void;
};

const SessionTimerDialog: FC<SessionTimerDialogProps> = ({
  timeout,
  promptBeforeIdle,
  getAuth0AccessToken
}) => {
  const logoutChannel = new BroadcastChannel('portal-logout');
  const { logout } = useAuth0();

  const queryClient = useQueryClient();

  const SIGNAL = {
    action: 'logout-all-tabs-due-to-inactivity',
    tabId: sessionStorage.getItem('tabId')
  };
  const performLogout = (page: 'login' | 'session-timeout') => {
    if (page === 'session-timeout') {
      sessionStorage.setItem('lastPage', window.location.pathname);
      sessionStorage.setItem(
        'logoutMessage',
        'You have been logged out due to inactivity.'
      );
    }
    logout({
      returnTo: window.location.origin
    });
    logoutChannel.close();
  };

  logoutChannel.onmessage = (
    signal: BroadcastSignal & { page: 'login' | 'session-timeout' }
  ) => {
    if (signal.action === SIGNAL.action && signal.tabId !== SIGNAL.tabId) {
      performLogout(signal.page);
    }
  };

  const postLogoutMessage = (page: 'login' | 'session-timeout') => {
    logoutChannel.postMessage({ page, ...SIGNAL });
    performLogout(page);
  };

  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);

  const onPrompt = () => {
    setOpenLogoutDialog(true);
    queryClient.setQueryData(['isSessionTimerDialogOpen'], true);
  };

  const onIdle = () => {
    setOpenLogoutDialog(false);
    queryClient.setQueryData(['isSessionTimerDialogOpen'], false);
    postLogoutMessage('session-timeout');
  };

  const onMessage = (data?: { closeDialog: boolean }) => {
    setOpenLogoutDialog(!data?.closeDialog);
    queryClient.setQueryData(['isSessionTimerDialogOpen'], !data?.closeDialog);
  };

  const onAction = () => {
    const tokenData = userService.getTokenData();
    const now = new Date().getTime() / 1000;
    let minTimeRefreshToken = 300;

    if (AppConfig.environment === 'qa') {
      minTimeRefreshToken = 60;
    }

    if (tokenData && tokenData.exp - now < minTimeRefreshToken) {
      getAuth0AccessToken(true);
    }
  };

  const { reset, message, activate } = useIdleTimer({
    crossTab: true,
    debounce: 1000,
    element: document,
    events,
    eventsThrottle: 200,
    immediateEvents: [],
    leaderElection: false,
    name: 'idle-timer',
    onAction,
    onIdle,
    onMessage,
    onPrompt,
    promptBeforeIdle,
    startManually: false,
    startOnMount: true,
    stopOnIdle: false,
    syncTimers: 1000,
    throttle: 0,
    timeout
  });

  const handleStillUsingPortal = () => {
    activate();
    setOpenLogoutDialog(false);
    queryClient.setQueryData(['isSessionTimerDialogOpen'], false);
    reset();
    message({ closeDialog: true });
  };

  return (
    <Dialog
      data-testid='still-using-portal-dialog'
      onClose={handleStillUsingPortal}
      open={openLogoutDialog}>
      <DialogContent data-testid='still-using-portal-dialog-content'>
        Are you still using this portal?
      </DialogContent>
      <DialogActions>
        <Button
          data-testid='still-using-portal-no-button'
          onClick={() => postLogoutMessage('login')}>
          NO
        </Button>
        <Button
          data-testid='still-using-portal-yes-button'
          onClick={handleStillUsingPortal}
          variant='contained'>
          YES
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionTimerDialog;
