import LinearLoading from '@/components/linear-loading';
import {
  RiskSeriesData,
  RiskSeriesModelProps
} from '@/routes/ops/investments/RiskSeries.component';
import InvestmentService from '@/services/Investment.service';
import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import React from 'react';

import RiskSeriesTable from './RiskSeriesTable.component';

interface RiskSeriesTabProps {
  riskSeriesId: number;
}

const RiskSeriesTab: React.FC<RiskSeriesTabProps> = ({ riskSeriesId }) => {
  const riskSeriesGridQuery = useQuery<RiskSeriesModelProps[]>(
    ['RiskSeries.editRiskSeries', 'risk-models', riskSeriesId],
    () => InvestmentService.getRiskModels(riskSeriesId),
    {
      staleTime: Infinity
    }
  );
  const riskSeriesQuery = useQuery<RiskSeriesData>(
    ['RiskSeries.editRiskSeries', 'risk-series', riskSeriesId],
    () => InvestmentService.getRiskModelSeries(riskSeriesId),
    {
      staleTime: Infinity
    }
  );

  const isLoading =
    riskSeriesGridQuery.isFetching || riskSeriesQuery.isFetching;

  if (isLoading) {
    return (
      <Box textAlign='center'>
        <LinearLoading />
      </Box>
    );
  }

  return (
    <RiskSeriesTable
      riskSeries={riskSeriesQuery.data as RiskSeriesData}
      riskSeriesGrid={riskSeriesGridQuery.data as RiskSeriesModelProps[]}
    />
  );
};

export default RiskSeriesTab;
