import useHasPermissions from '@/components/access-control/useHasPermissions.hook';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { PlanService } from '@/services/Plan.service';
import { FormControlLabel, Switch } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import React, { FC, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';

const ForfeiturePreferencesDTOSchema = yup.object().shape({
  data: yup.object().shape({
    offsetEmployerContribution: yup.boolean().required(),
    payPlanFees: yup.boolean().required()
  })
});

type ForfeiturePreferencesProps = {
  name: string;
  label: string;
  sponsorPlanId: number;
  planDesignData: Record<string, any>;
};

export const ForfeiturePreferences: FC<ForfeiturePreferencesProps> = props => {
  const forfeiturePreferences = useMemo(
    () => ({
      offsetEmployerContribution:
        props.planDesignData?.offsetEmployerContribution,
      payPlanFees: props.planDesignData?.payPlanFees
    }),
    [props.planDesignData]
  );

  const [uiState, setUIState] = useState<boolean>(
    !!forfeiturePreferences?.[props.name]
  );
  const accessControlCheck = useHasPermissions({
    requires: [FeatureLevelPermissions.WRITE_FORFEITURE_ACTION]
  });

  const queryClient = useQueryClient();

  const { showSnackbar } = useSnackbar();

  const forfeiturePreferencesMutation = useMutation(
    ['PlanService.updateForfeiturePref'],
    async (forfeitureUpdate: Record<string, boolean>) => {
      return PlanService.updateForfeiturePref(
        props.sponsorPlanId,
        ForfeiturePreferencesDTOSchema.validateSync({
          data: {
            ...forfeiturePreferences,
            ...forfeitureUpdate
          }
        })
      );
    },
    {
      onMutate(forfeitureUpdate: Record<string, boolean>) {
        // optimistically update UI
        setUIState(!!forfeitureUpdate?.[props.name]);
      },
      onSettled(data, error) {
        showSnackbar(
          error
            ? {
                message: 'Error updating plan!',
                severity: 'error'
              }
            : {
                message: 'Success!',
                severity: 'success'
              }
        );

        if (error) {
          // undo optimistic UI update
          setUIState(!!forfeiturePreferences?.[props.name]);
        }

        // either way force everyone to refetch plan design
        queryClient.invalidateQueries({
          predicate: ({ queryHash }) =>
            /PlanService.getPlanDesignById/.test(queryHash)
        });
      }
    }
  );

  useEffect(() => {
    setUIState(!!forfeiturePreferences?.[props.name]);
  }, [forfeiturePreferences]);

  return (
    <FormControlLabel
      checked={uiState}
      control={
        <Switch
          checked={uiState}
          onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
            await forfeiturePreferencesMutation.mutateAsync({
              [props.name]: event.target.checked
            });
          }}
        />
      }
      disabled={
        !accessControlCheck.isAllowed || forfeiturePreferences == null // forfeiturePreferences are optional
      }
      key={props.name}
      label={props.label}
    />
  );
};
