import PayrollIntegrationsService from '@/services/payroll-integrations.service';
import { useQuery } from '@tanstack/react-query';

export default function usePlanBlockers(sponsorPlanId: number) {
  const blockStatus = useQuery(
    ['PayrollIntegrationsService.getPayrollIntegrationBlockers', sponsorPlanId],
    () =>
      PayrollIntegrationsService.getPayrollIntegrationBlockers(sponsorPlanId),
    {
      select: data => {
        const blockReasons = data?.map(p => p.reason).join(', ');
        const isBlocked = !!data?.length;
        return { blockReasons, isBlocked };
      }
    }
  );

  return blockStatus;
}
