import { createSvgIcon, SvgIconProps } from '@mui/material';

import React, { FC } from 'react';

export const NoPlansIcon: FC<SvgIconProps> = createSvgIcon(
  <path
    d='M28 0.5H4C1.8 0.5 0 2.3 0 4.5V32.5H4V4.5H28V0.5ZM26 8.5H12C9.8 8.5 8.02 10.3 8.02 12.5L8 40.5C8 42.7 9.78 44.5 11.98 44.5H34C36.2 44.5 38 42.7 38 40.5V20.5L26 8.5ZM12 40.5V12.5H24V22.5H34V40.5H12Z'
    fill='#E0E0E0'
  />,
  'NoPlansIcon'
);

NoPlansIcon.defaultProps = {
  style: {
    height: 45,
    width: 38
  },
  viewBox: '0 0 38 45'
};

NoPlansIcon.displayName = 'NoPlansIcon';
