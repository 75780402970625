import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import PayrollIntegrationsService from '@/services/payroll-integrations.service';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography
} from '@mui/material';
import { styled } from '@mui/styles';
import { useMutation } from '@tanstack/react-query';

import React from 'react';

interface PayGroupsModalUnblockProps {
  sponsorPlanId: number | string;
  onUpdate: () => void;
}

const StyledDialogContent = styled(DialogContent)(() => ({
  display: 'flex',
  flexDirection: 'column'
}));

export const PayGroupsModalUnblock = (
  props: PayGroupsModalUnblockProps
): JSX.Element => {
  const ctx = useDialog();
  const { showSnackbar } = useSnackbar();

  const unblockIntegrationMutation = useMutation(
    ['PayrollIntegrationsService.unblockPayrollIntegration'],
    (sponsorPlanId: number) => {
      return PayrollIntegrationsService.unblockPayrollIntegration(
        sponsorPlanId
      );
    },
    {
      onError: () => {
        showSnackbar({
          message: `Failed to unblock plan from integration`,
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: `Plan is now unblocked from integration`,
          severity: 'success'
        });
      }
    }
  );

  const handleSubmit = async () => {
    await unblockIntegrationMutation.mutateAsync(+props.sponsorPlanId);
    ctx.closeDialog();
    props.onUpdate();
  };

  return (
    <React.Fragment>
      <StyledDialogContent data-testid='unblockPlanModalContent'>
        <DialogTitle sx={{ paddingLeft: '0px' }}>
          Unblock Plan from Integrating?
        </DialogTitle>
        <Typography color='text.primary' variant='body1'>
          The "Connect" button will be visible in the Employer Portal again. The
          client can now reconnect to the integration.
        </Typography>
        <Divider sx={{ marginTop: 2 }} />
        <DialogActions>
          <Button
            data-testid='cancelBtn'
            onClick={ctx.closeDialog}
            variant='text'>
            CANCEL
          </Button>
          <Button
            data-testid='submitBtn'
            onClick={handleSubmit}
            type='submit'
            variant='contained'>
            CONFIRM & UNBLOCK
          </Button>
        </DialogActions>
      </StyledDialogContent>
    </React.Fragment>
  );
};
