import LinearLoading from '@/components/linear-loading';
import SimpleDropdown from '@/components/simple-dropdown';
import { useUserToken } from '@/contexts/UserTokenContext';
import { useSubaErrors } from '@/hooks/useSubaErrors.hook';
import { ParticipantInfo } from '@/models';
import {
  ParticipantBeneficiary,
  ParticipantBeneficiaryResponse
} from '@/models/ParticipantDTO.model';
import { FormattedParticipantInfo } from '@/models/ParticipantInfo.model';
import {
  CustomWithdrawalAccount,
  CustomWithdrawalDetailsDto,
  CustomWithdrawalDisbursement,
  CustomWithdrawalResponse,
  DistributionAccountsDto,
  WithdrawalDto
} from '@/models/WithdrawalsDTO.model';
import ParticipantService from '@/services/Participant.service';
import {
  AddCircleOutlineOutlined,
  CheckCircleOutline,
  CloseOutlined
} from '@mui/icons-material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  styled,
  TextField,
  Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { FieldArray, Form, Formik, FormikProps } from 'formik';
import { useRef, useState } from 'react';
import { useToggle } from 'react-use';

import { WithdrawalSkipLocationValidationModal } from '../WithdrawalSkipLocationValidationModal';
import { BeneficaryCard } from './BeneficiaryCard.component';
import { WITHDRAWAL_CODES } from './constants';
import {
  sanitizeCustomRequest,
  saveValidationSchema,
  useSaveCustomWithdrawalRequest,
  useSubmitCustomWithdrawalRequest,
  useUpdateCustomWithdrawalRequest,
  useValidateCustomWithdrawal,
  validationSchema
} from './customWithdrawalRequest';
import ParticipantInfoCard from './ParticipantInfoCard.component';
import RMDCalculatorDialog from './RMDCalculator.component';
import SubAccountInfoCard from './SubAccountInfoCard.component';
import WithdrawalDisbursementCard from './WithdrawalDisbursementCard.component';

interface WithdrawalCustomRequestDialogProps {
  participantId: number;
  dto?: WithdrawalDto;
  isOpen: boolean;
  onClose: () => void;
}

const cardStyles = {
  selected: {
    border: '1px solid #2196F3',
    minWidth: 270
  },
  unselected: {
    minWidth: 270
  }
};

const initSubAcct = {
  accountId: undefined,
  applyVesting: false,
  error: undefined,
  forfeitureFlag: false,
  fundingSource: undefined,
  subAccountTaxType: undefined
};

const StyledCardAction = styled(CardActionArea)(() => ({
  height: '100%'
}));

const StyledCardContent = styled(CardContent)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 3,
  height: '100%'
}));

type InitDisbursement = {
  _1099Code: string;
  amount: number | string | undefined;
  disbursementMethod: string;
  error: string | undefined;
  federalWithholdingPercent: string;
  feeAmount: number | undefined;
  fullAmount: boolean;
  furtherCreditToAccountNumber: string;
  furtherCreditToName: string;
  isRollover: boolean;
  overnightAccountNumber: string;
  overnightCheck: boolean;
  payeeAddress: string;
  payeeCity: string;
  payeeForeignCountry: string;
  payeeName: string;
  payeeState: string;
  payeeZip: string;
  receivingBankAccountName: string;
  receivingBankAccountNumber: string;
  receivingBankName: string;
  receivingBankRoutingNumber: string;
  referenceMemo: string;
  rothInitialYear: number | undefined;
  rothQualifiedWithdrawal: boolean;
  stateWithholdingCode: string;
  stateWithholdingPercent: string;
  taxType: string | undefined;
  useParticipantAddress: boolean;
};

export type CustomWithdrawalFormData = {
  accounts: CustomWithdrawalAccount[] | (typeof initSubAcct)[];
  accountsResponse: undefined;
  beneficiaryId: number | undefined;
  contributionYear: number | undefined;
  disbursements:
    | (InitDisbursement & CustomWithdrawalDisbursement)[]
    | InitDisbursement[]
    | undefined;
  disbursementsResponse: undefined;
  earnings: boolean;
  jiraTicket: string;
  participantAddress: string | undefined;
  participantCity: string | undefined;
  participantFirstName: string | undefined;
  participantForeignCountry: string;
  participantId: number | undefined;
  participantLastName: string | undefined;
  participantSsn: string | undefined;
  participantState: string | undefined;
  participantZip: string | undefined;
  saveResponse: undefined;
  withdrawalCode: string | undefined;
};

const WithdrawalCustomRequestDialog = ({
  participantId,
  dto,
  isOpen,
  onClose
}: WithdrawalCustomRequestDialogProps): JSX.Element => {
  const { userHasValidToken } = useUserToken();
  const formRef = useRef<any>();
  const [withdrawalId, setWithdrawalId] = useState(dto?.id);
  const [isOpenPreview, setPreviewOpen] = useState(false);
  const [previewResponse, setPreviewData] = useState('');
  const [isRMDCalculatorOpen, setRMDCalculatorOpen] = useState(false);
  const [showForceSubmitModal, setShowForceSubmitModal] = useState(false);
  const [customWithdrawalDetailsDto, setCustomWithdrawalDetailsDto] = useState<
    CustomWithdrawalDetailsDto | undefined
  >(undefined);
  const [sendEmail, setSendEmail] = useState(true);
  const [isConfirmModalOpen, toogleConfirmModalOpen] = useToggle(false);
  const { checkSubaErrors, subaErrors, setSubaErrors } = useSubaErrors();

  const participantQuery = useQuery<ParticipantInfo>(
    ['ParticipantService.getParticipantById', participantId?.toString()],
    async () => ParticipantService.getParticipantById(participantId),
    {
      enabled: Boolean(participantId && userHasValidToken),
      staleTime: Infinity
    }
  );

  const distributionAccounts = useQuery<DistributionAccountsDto>(
    ['ParticipantService.getDistributionAccounts', participantId],
    async () => ParticipantService.getDistributionAccounts(participantId),
    {
      enabled: Boolean(participantId && userHasValidToken),
      staleTime: Infinity
    }
  );

  const beneficiaries = useQuery<ParticipantBeneficiaryResponse>(
    ['ParticipantService.getParticipantBeneficiaries', participantId],
    async () => ParticipantService.getParticipantBeneficiaries(participantId),
    {
      enabled: Boolean(participantId && userHasValidToken),
      staleTime: Infinity
    }
  );

  const initDisbursement = {
    _1099Code: '',
    amount: undefined,
    disbursementMethod: '',
    error: undefined,
    federalWithholdingPercent: '',
    feeAmount: undefined,
    fullAmount: false,
    furtherCreditToAccountNumber: '',
    furtherCreditToName: '',
    isRollover: false,
    overnightAccountNumber: '670413772',
    overnightCheck: false,
    payeeAddress: '',
    payeeCity: '',
    payeeForeignCountry: 'USA',
    payeeName: ``,
    payeeState: '',
    payeeZip: '',
    receivingBankAccountName: '',
    receivingBankAccountNumber: '',
    receivingBankName: '',
    receivingBankRoutingNumber: '',
    referenceMemo: '',
    rothInitialYear: undefined,
    rothQualifiedWithdrawal: false,
    stateWithholdingCode: participantQuery.data?.address?.state,
    stateWithholdingPercent: '',
    taxType: undefined,
    useParticipantAddress: true
  };

  const initialVals: CustomWithdrawalFormData = {
    accounts: dto?.attributes.context.customWithdrawalDetails.accounts.map(
      i => {
        return { ...initSubAcct, ...i };
      }
    ) ?? [initSubAcct],
    accountsResponse: undefined,
    beneficiaryId:
      dto?.attributes.context.customWithdrawalDetails.beneficiaryId,
    contributionYear:
      dto?.attributes.context.customWithdrawalDetails.contributionYear ??
      undefined,
    disbursements:
      dto?.attributes.context.customWithdrawalDetails.disbursements.map(i => {
        return { ...initDisbursement, ...i };
      }) ?? [initDisbursement],
    disbursementsResponse: undefined,
    earnings: false,
    jiraTicket: '',
    participantAddress: participantQuery.data?.address?.address1,
    participantCity: participantQuery.data?.address?.city,
    participantFirstName: participantQuery.data?.firstName,
    participantForeignCountry: 'USA',
    participantId: participantQuery.data?.participantId,
    participantLastName: participantQuery.data?.lastName,
    participantSsn: participantQuery.data?.ssn,
    participantState: participantQuery.data?.address?.state,
    participantZip: participantQuery.data?.address?.zip,
    saveResponse: undefined,
    withdrawalCode: dto?.attributes.withdrawalReason ?? ''
  };

  const onSubmitSettled = () => {
    setSendEmail(true);
    toogleConfirmModalOpen();
  };

  const onSave = useSaveCustomWithdrawalRequest(onClose, withdrawalId);
  const onSubmit = useSubmitCustomWithdrawalRequest(
    onClose,
    onSubmitSettled,
    setWithdrawalId,
    withdrawalId
  );
  const onUpdate = useUpdateCustomWithdrawalRequest(dto?.id);
  const previewRequest = useValidateCustomWithdrawal();

  const handleParticipantCard =
    (formik: FormikProps<any>, id?: number) => () => {
      formik.setFieldValue('beneficiaryId', id);
    };

  const displayBeneficiaries = (p: ParticipantBeneficiary) => {
    type PossibleValues = 'trustId' | 'ssn' | 'ein';
    const check = (beneficiaryType: string, value: PossibleValues) => {
      const attributeValue = p.attributes[value];
      return (
        p.attributes.beneficiaryType === beneficiaryType &&
        attributeValue &&
        attributeValue.trim().length > 0
      );
    };

    const isTrustBeneficiary = check('trust', 'trustId');
    const isPersonBeneficiary = check('person', 'ssn');
    const isOrgBeneficiary = check('organization', 'ein');

    return isTrustBeneficiary || isPersonBeneficiary || isOrgBeneficiary;
  };
  const displayCustomForceModal = (response: CustomWithdrawalResponse) => {
    if (!response.submitted && response.validationErrors.length > 0) {
      if (
        response.validationErrors.every(k =>
          k.source.includes('disbursementAddress')
        )
      )
        setShowForceSubmitModal(true);
    }
  };

  const submitHandler = async (v: CustomWithdrawalFormData) => {
    const sanitized = sanitizeCustomRequest({
      details: {
        accounts: v.accounts,
        beneficiaryId: v.beneficiaryId,
        contributionYear: v.contributionYear,
        disbursements: v.disbursements
      },
      forceSkipLocationValidation: false,
      jiraTicket: v.jiraTicket,
      participantId: participantId,
      reason: v.withdrawalCode,
      sendEmail: sendEmail
    } as CustomWithdrawalDetailsDto);

    const response: CustomWithdrawalResponse =
      await onSubmit.mutateAsync(sanitized);
    setCustomWithdrawalDetailsDto(sanitized);
    displayCustomForceModal(response);
    if (!response.submitted && response.validationErrors.length > 0) {
      checkSubaErrors(response.validationErrors);
    }
  };

  async function forceSubmitHandler() {
    const customDto: CustomWithdrawalDetailsDto = {
      ...customWithdrawalDetailsDto,
      forceSkipLocationValidation: true
    };
    const response: CustomWithdrawalResponse =
      await onSubmit.mutateAsync(customDto);
    if (!response.submitted && response.validationErrors.length > 0)
      checkSubaErrors(response.validationErrors);
  }

  const onConfirmSubmit = (formik: FormikProps<CustomWithdrawalFormData>) => {
    formik.submitForm();
    toogleConfirmModalOpen();
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth='lg'
        open={isOpen}
        sx={{
          m: 'auto',
          width: '100%'
        }}>
        <DialogContent
          sx={{
            p: 2,
            pb: 0.5
          }}>
          {participantQuery.isFetching ||
          distributionAccounts.isFetching ||
          beneficiaries.isFetching ? (
            <LinearLoading />
          ) : (
            <Formik
              initialValues={initialVals}
              innerRef={formRef}
              onSubmit={submitHandler}
              validationSchema={validationSchema}>
              {formik => (
                <Form data-testid='filter-form'>
                  <Typography sx={{ mb: 2 }} variant='h6'>
                    Custom Withdrawal Request
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1, maxWidth: 600, my: 3 }}>
                    <TextField
                      error={Boolean(formik.errors.jiraTicket)}
                      helperText={formik.errors.jiraTicket ?? ''}
                      id='jiraTicket'
                      label='Jira Ticket'
                      name='jiraTicket'
                      onChange={formik.handleChange}
                      size='small'
                      value={formik.values.jiraTicket}
                    />
                    {formik.values.jiraTicket !==
                      formik.initialValues.jiraTicket &&
                      !formik.errors.jiraTicket && (
                        <CheckCircleOutline color='success' fontSize='large' />
                      )}
                    {formik.errors.jiraTicket && (
                      <CloseOutlined
                        fontSize='large'
                        sx={{ color: theme => theme.palette.error.main }}
                      />
                    )}
                  </Box>
                  <Box sx={{ display: 'flex', gap: 2, maxWidth: 600, my: 3 }}>
                    <Box sx={{ width: 200 }}>
                      <SimpleDropdown
                        fieldId='withdrawalCode'
                        fieldName='Withdrawal Code*'
                        fieldOptions={WITHDRAWAL_CODES}
                        fullWidth
                        onChange={event => {
                          formik.setFieldValue(
                            'withdrawalCode',
                            event.target.value
                          );

                          formik.setFieldValue(
                            'beneficiaryId',
                            event.target.value === 'QDRO'
                              ? beneficiaries.data?.data?.[0]?.id
                              : undefined
                          );
                        }}
                        size='small'
                      />
                    </Box>

                    {formik.values.withdrawalCode === '402G' && (
                      <Stack alignItems='center' direction='row' spacing={2}>
                        <TextField
                          error={Boolean(formik.errors.contributionYear)}
                          fullWidth
                          helperText={
                            formik.errors.contributionYear?.toString() || ''
                          }
                          id='contributionYear'
                          label='Contribution Year'
                          name='contributionYear'
                          onChange={formik.handleChange}
                          size='small'
                          value={formik.values.contributionYear}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formik.values.earnings}
                              data-testid='is-rollover-checkbox'
                              name='earnings'
                              onChange={formik.handleChange}
                              value={formik.values.earnings}
                            />
                          }
                          label='Earnings?'
                          sx={{ width: 400 }}
                        />
                      </Stack>
                    )}
                  </Box>

                  <Typography sx={{ mb: 2 }} variant='h6'>
                    Participant info
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <Card
                      elevation={0}
                      sx={
                        !formik.values.beneficiaryId
                          ? cardStyles.selected
                          : cardStyles.unselected
                      }
                      variant='outlined'>
                      <StyledCardAction
                        disabled={formik.values.withdrawalCode === 'QDRO'}
                        onClick={handleParticipantCard(formik, undefined)}>
                        <StyledCardContent>
                          <ParticipantInfoCard
                            participant={
                              {
                                address: {
                                  address1:
                                    participantQuery.data?.address?.address1,
                                  address2:
                                    participantQuery.data?.address?.address2,
                                  city: participantQuery.data?.address?.city,
                                  state: participantQuery.data?.address?.state,
                                  zip: participantQuery.data?.address?.zip
                                },
                                firstName: participantQuery.data?.firstName,
                                id: participantQuery.data?.participantId,
                                isBeneficiary: false,
                                lastName: participantQuery.data?.lastName,
                                ssn: participantQuery.data?.ssn
                              } as FormattedParticipantInfo
                            }
                          />
                        </StyledCardContent>
                      </StyledCardAction>
                    </Card>
                    {beneficiaries.data?.data
                      .filter(displayBeneficiaries)
                      .map(i => {
                        return (
                          <Card
                            elevation={0}
                            key={`beneficiary-${i.id}`}
                            sx={
                              formik.values.beneficiaryId === i.id
                                ? cardStyles.selected
                                : cardStyles.unselected
                            }
                            variant='outlined'>
                            <StyledCardAction
                              disabled={['QACA', 'EACA'].includes(
                                formik.values.withdrawalCode
                              )}
                              onClick={handleParticipantCard(formik, i.id)}>
                              <StyledCardContent>
                                <BeneficaryCard beneficiary={i} />
                              </StyledCardContent>
                            </StyledCardAction>
                          </Card>
                        );
                      })}
                  </Box>
                  <Typography sx={{ mb: 2, mt: 5 }} variant='h6'>
                    Sub Account Info
                  </Typography>
                  {subaErrors.hasAccountErrors && (
                    <Alert severity='error'>{subaErrors.accountErrors}</Alert>
                  )}
                  <FieldArray name='accounts'>
                    {({ push, remove }) => (
                      <Box>
                        {formik.values.accounts.map((_, index) => (
                          <SubAccountInfoCard
                            errors={subaErrors}
                            index={index}
                            key={'sub-account' + index}
                            participantAccounts={
                              distributionAccounts.data?.data
                            }
                            remove={remove}
                          />
                        ))}

                        <Button
                          onClick={() => push(initSubAcct)}
                          startIcon={<AddCircleOutlineOutlined />}>
                          ADD MORE
                        </Button>
                      </Box>
                    )}
                  </FieldArray>
                  <Stack direction='row' justifyContent='space-between'>
                    <Box>
                      <Typography sx={{ mt: 5 }} variant='h6'>
                        Disbursements
                      </Typography>
                      <Typography
                        color={theme => theme.palette.grey[700]}
                        data-testid='maximum-4-heading'
                        sx={{ mb: 2 }}
                        variant='caption'>
                        Maximum 4
                      </Typography>
                    </Box>
                    {formik.values.withdrawalCode === 'REQUIRED_MINIMUM' && (
                      <Box sx={{ alignItems: 'center', display: 'flex' }}>
                        <Button
                          endIcon={<OpenInNewIcon />}
                          onClick={() => setRMDCalculatorOpen(true)}
                          variant='text'>
                          CALCULATE RMD
                        </Button>
                        <RMDCalculatorDialog
                          onClose={() => setRMDCalculatorOpen(false)}
                          open={isRMDCalculatorOpen}
                          participant={participantQuery.data}
                          participantId={participantId}
                        />
                      </Box>
                    )}
                  </Stack>

                  {subaErrors.hasDisbursementsErrors && (
                    <Alert severity='error'>
                      {subaErrors.disbursementsErrors}
                    </Alert>
                  )}
                  <FieldArray name='disbursements'>
                    {({ push, remove }) => (
                      <Box>
                        {formik.values.disbursements.map((_, index) => (
                          <WithdrawalDisbursementCard
                            errors={subaErrors}
                            index={index}
                            key={'disbursement' + index}
                            remove={remove}
                          />
                        ))}

                        {formik.values.disbursements.length < 4 && (
                          <Button
                            onClick={() => push(initDisbursement)}
                            startIcon={<AddCircleOutlineOutlined />}>
                            ADD MORE
                          </Button>
                        )}
                      </Box>
                    )}
                  </FieldArray>

                  <Box>
                    {subaErrors.hasOtherErrors && (
                      <Alert severity='error'>{subaErrors.otherErrors}</Alert>
                    )}
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 2,
                        justifyContent: 'right',
                        pb: 3,
                        width: '100%'
                      }}>
                      <Button onClick={() => onClose()}>CANCEL</Button>
                      <LoadingButton
                        loading={previewRequest.isLoading}
                        onClick={async () => {
                          const validData = saveValidationSchema.isValidSync(
                            formik.values
                          );

                          const sanitizedDto = sanitizeCustomRequest({
                            details: {
                              accounts: formik.values.accounts,
                              beneficiaryId: formik.values.beneficiaryId,
                              contributionYear: formik.values.contributionYear,
                              disbursements: formik.values.disbursements
                            },
                            forceSkipLocationValidation: false,
                            jiraTicket: formik.values.jiraTicket,
                            participantId: participantId,
                            reason: formik.values.withdrawalCode,
                            sendEmail: sendEmail
                          } as CustomWithdrawalDetailsDto);

                          if (validData) {
                            await previewRequest
                              .mutateAsync(sanitizedDto)
                              ?.then(data => {
                                if (data?.validationErrors.length > 0) {
                                  checkSubaErrors(data?.validationErrors);
                                } else {
                                  setPreviewOpen(true);
                                  setPreviewData(
                                    JSON.stringify(data?.subaRequest, null, 2)
                                  );
                                  setSubaErrors({
                                    accountErrors: '',
                                    disbursementsErrors: '',
                                    hasAccountErrors: false,
                                    hasDisbursementsErrors: false,
                                    hasOtherErrors: false,
                                    indexedErrors: {},
                                    otherErrors: ''
                                  });
                                }
                              })
                              .catch(e => {
                                setSubaErrors(prevState => ({
                                  ...prevState,
                                  hasOtherErrors: true,
                                  otherErrors: JSON.stringify(e?.errors)
                                }));
                              });
                          }
                        }}
                        variant='contained'>
                        Preview Request
                      </LoadingButton>
                      <LoadingButton
                        loading={onUpdate.isLoading || onSave.isLoading}
                        onClick={() => {
                          let response: Promise<CustomWithdrawalResponse>;

                          const validData = saveValidationSchema.isValidSync(
                            formik.values
                          );
                          formik.setFieldTouched('withdrawalCode', true, true);

                          const sanitizedDto = sanitizeCustomRequest({
                            details: {
                              accounts: formik.values.accounts,
                              beneficiaryId: formik.values.beneficiaryId,
                              contributionYear: formik.values.contributionYear,
                              disbursements: formik.values.disbursements
                            },
                            forceSkipLocationValidation: false,
                            jiraTicket: formik.values.jiraTicket,
                            participantId: participantId,
                            reason: formik.values.withdrawalCode,
                            sendEmail: sendEmail
                          } as CustomWithdrawalDetailsDto);

                          if (validData) {
                            if (dto)
                              response = onUpdate.mutateAsync(sanitizedDto);
                            else response = onSave.mutateAsync(sanitizedDto);
                          }

                          response
                            ?.then(data => {
                              if (
                                !data.submitted &&
                                data.validationErrors.length > 0
                              )
                                checkSubaErrors(data.validationErrors);
                            })
                            .catch(e => {
                              setSubaErrors(prevState => ({
                                ...prevState,
                                hasOtherErrors: true,
                                otherErrors: JSON.stringify(e?.errors)
                              }));
                            });
                        }}
                        variant='contained'>
                        {dto ? 'UPDATE DRAFT' : 'SAVE AS DRAFT'}
                      </LoadingButton>
                      {!(
                        formik.values.withdrawalCode === 'QDRO' &&
                        beneficiaries.data?.data.length === 0
                      ) && (
                        <LoadingButton
                          loading={onSubmit.isLoading}
                          onClick={toogleConfirmModalOpen}
                          variant='contained'>
                          SUBMIT
                        </LoadingButton>
                      )}
                    </Box>
                  </Box>
                  <Dialog
                    onClose={toogleConfirmModalOpen}
                    open={isConfirmModalOpen}>
                    <DialogTitle>Submit Withdrawal</DialogTitle>
                    <DialogContent>
                      <Stack spacing={2}>
                        <Typography variant='body1'>
                          Are you sure you want to submit the withdrawal?
                        </Typography>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={sendEmail}
                              onChange={() => setSendEmail(!sendEmail)}
                            />
                          }
                          label='Send email to participant'
                        />
                      </Stack>
                    </DialogContent>
                    <DialogActions>
                      <LoadingButton
                        loading={onSubmit.isLoading}
                        onClick={() => onConfirmSubmit(formik)}
                        variant='outlined'>
                        CONFIRM
                      </LoadingButton>
                      <Button
                        data-testid='tpa-plans-modal-cancel-btn'
                        onClick={toogleConfirmModalOpen}>
                        CANCEL
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Form>
              )}
            </Formik>
          )}
        </DialogContent>
      </Dialog>
      <Dialog fullWidth maxWidth='sm' open={isOpenPreview}>
        <DialogTitle>Preview Request</DialogTitle>
        <DialogContent>
          <Typography
            component='pre'
            sx={{ backgroundColor: '#F5F5F5', padding: '10px' }}
            variant='body2'>
            {previewResponse}
          </Typography>
        </DialogContent>

        <DialogActions sx={{ padding: '1.5rem' }}>
          <Button
            color='primary'
            onClick={() => setPreviewOpen(false)}
            variant='text'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <WithdrawalSkipLocationValidationModal
        backendErrors={subaErrors}
        isLoading={onSubmit.isLoading}
        showModal={showForceSubmitModal}
        showModalStateHandler={setShowForceSubmitModal}
        submitHandler={forceSubmitHandler}
      />
    </>
  );
};

export default WithdrawalCustomRequestDialog;
