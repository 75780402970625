import Badge from '@/components/badge';
import { CardPlaceholder } from '@/components/card';
import OpenInNewIcon from '@/components/icon/OpenInNewIcon';
import {
  CommunicationFilters,
  MailerLetters
} from '@/models/PlanCommunicationsDTO.model';
import { Search, WarningAmber } from '@mui/icons-material';
import { Box, Link, Stack, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';

import React, { Dispatch, FC, SetStateAction } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  getStatusLabelAndColor,
  mailerLetterEventTypeToTooltip
} from './utils';

type PlanCommunicationsPhysicalMailTableProps = {
  physicalMailsData?: MailerLetters[];
  totalPhysicalMails?: number;
  filters: CommunicationFilters;
  setFilters: Dispatch<SetStateAction<CommunicationFilters>>;
  pageNumber: number;
  setPageNumber: Dispatch<SetStateAction<number>>;
  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  isLoading?: boolean;
  isError?: boolean;
  isSponsor?: boolean;
};

const StyledBadge = styled(Badge)({
  '&:hover': {
    cursor: 'pointer'
  }
});

const columns: GridColDef[] = [
  {
    field: 'participant',
    flex: 0.35,
    headerName: 'Participant',
    minWidth: 150,
    renderCell: params => (
      <Box>
        <Link
          component={RouterLink}
          sx={{ fontSize: '16px' }}
          to={`/participants/${params.row.participantId}`}
          underline='hover'>
          {params.row.lastName}, {params.row.firstName}
        </Link>
        <Typography variant='body2'>ID: {params.row.participantId}</Typography>
      </Box>
    ),
    sortable: false
  },
  {
    field: 'address',
    flex: 0.5,
    headerName: 'Address',
    minWidth: 200,
    renderCell: params => (
      <Box>
        <Typography variant='body1'>{params.row.address1}</Typography>
        {params.row.address2 && (
          <Typography variant='body1'>{params.row.address2}</Typography>
        )}
        <Typography variant='body2'>{`${params.row.city}, ${params.row.state}, ${params.row.zip}`}</Typography>
      </Box>
    ),
    sortable: false
  },
  {
    field: 'mailType',
    flex: 0.35,
    headerName: 'Mail Type',
    minWidth: 150,
    renderCell: params => (
      <Typography variant='body1'>{params.row.mailType}</Typography>
    ),
    sortable: false
  },
  {
    field: 'description',
    flex: 0.5,
    headerName: 'Mail Description',
    minWidth: 150,
    renderCell: params => (
      <Box>
        <Typography>{params.row.description || '(no description)'}</Typography>
        <Stack
          alignContent='center'
          direction='row'
          justifyContent='flex-start'
          spacing={1}>
          <Typography variant='body2'>{params.row.letterId}</Typography>
          <Link
            href={`https://dashboard.lob.com/letters/${params.row.letterId}`}
            target='_blank'>
            <OpenInNewIcon fontSize='small' />
          </Link>
        </Stack>
      </Box>
    ),
    sortable: false
  },
  {
    field: 'status',
    flex: 0.4,
    headerName: 'Status',
    minWidth: 370,
    renderCell: params => (
      <Stack direction='row' spacing={2}>
        <StyledBadge color={getStatusLabelAndColor(params.row.status).color}>
          <Tooltip
            placement='top'
            title={mailerLetterEventTypeToTooltip(params.row.status)}>
            <Box
              sx={{
                '&:hover': {
                  cursor: 'pointer'
                }
              }}>
              {getStatusLabelAndColor(params.row.status).label}
            </Box>
          </Tooltip>
        </StyledBadge>
        <Typography variant='body2'>{params.row.eventCreatedAt}</Typography>
      </Stack>
    ),
    sortable: false
  },
  {
    field: 'createdAt',
    flex: 0.5,
    headerName: 'Create Date'
  }
];

export const PlanCommunicationsPhysicalMailTable: FC<
  PlanCommunicationsPhysicalMailTableProps
> = props => {
  return (
    <Box overflow='auto' width='100%'>
      <DataGridPro
        columns={columns}
        data-testid='planCommunicationsPhysicalMailTable'
        density='comfortable'
        disableColumnMenu
        disableRowSelectionOnClick
        getRowHeight={() => 'auto'}
        getRowId={row => row.letterId}
        loading={props.isLoading}
        onPaginationModelChange={model => {
          props.setPageNumber(model.page);
          props.setRowsPerPage(model.pageSize);
        }}
        onSortModelChange={model => {
          props.setFilters({ ...props.filters, order: model[0].sort });
          props.setPageNumber(0);
        }}
        pageSizeOptions={[10, 25, 50]}
        pagination
        paginationMode='server'
        paginationModel={{
          page: props.pageNumber,
          pageSize: props.rowsPerPage
        }}
        rowCount={props.totalPhysicalMails || 0}
        rows={props.physicalMailsData || []}
        slots={{
          noRowsOverlay: () => (
            <Stack
              alignItems='center'
              data-testid='noDataPlanCommunicationsPhysicalMailTable'
              height='100%'
              justifyContent='center'>
              <CardPlaceholder
                icon={
                  props.isError ? (
                    <WarningAmber fontSize='inherit' />
                  ) : (
                    <Search fontSize='inherit' />
                  )
                }
                subtitle={
                  props.isError
                    ? 'Error retrieving physical mails'
                    : 'No physical mails have been sent.'
                }
              />
            </Stack>
          )
        }}
        sortModel={[{ field: 'createdAt', sort: props.filters.order }]}
        sortingOrder={['desc', 'asc']}
        sx={{
          '.MuiDataGrid-columnSeparator': {
            display: 'none'
          },
          border: '0px !important'
        }}
      />
    </Box>
  );
};
