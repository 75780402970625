import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUserToken } from '@/contexts/UserTokenContext';
import { DocumentDto } from '@/models';
import TpaService from '@/services/Tpa.service';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQuery } from '@tanstack/react-query';

import { BroadcastChannel } from 'broadcast-channel';
import React, { useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import TermsOfUseContent from './TermsOfUseContent.component';

const useStyles = makeStyles(() => ({
  agreementContainer: {
    backgroundColor: 'white',
    border: 'solid 1px #d6d6d6',
    margin: '80px auto 0 auto',
    padding: '50px',
    textAlign: 'justify',
    width: '1000px',
    // eslint-disable-next-line
    '@media print': {
      margin: '0',
      padding: '0',
      width: '100%'
    }
  },
  buttonsContainer: {
    alignItems: 'center',
    backgroundColor: 'white',
    border: 'solid 1px #d6d6d6',
    bottom: '0',
    display: 'flex',
    justifyContent: 'right',
    left: '50%',
    margin: 'auto',
    padding: '10px',
    width: '1000px',
    // eslint-disable-next-line
    '@media print': {
      display: 'none'
    }
  },
  container: {
    backgroundColor: '#E3F2FD',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    left: '0px',
    margin: '0',
    position: 'absolute',
    top: '0px',
    width: '100vw',
    // eslint-disable-next-line
    '@media print': {
      display: 'block',
      margin: '0',
      padding: '0',
      width: '100%'
    }
  },
  content: {
    flex: '1',
    overflowX: 'auto'
  }
}));

const TermsOfUseRoute = (): JSX.Element => {
  const classes = useStyles();
  const listInnerRef = useRef(null);
  const navigate = useNavigate();
  const logoutChannel = new BroadcastChannel('portal-logout');
  const SIGNAL = {
    action: 'logout-all-tabs',
    tabId: sessionStorage.getItem('tabId')
  };
  const { logout } = useAuth0();
  const { userHasValidToken } = useUserToken();
  const { showSnackbar } = useSnackbar();
  const [agreementRead, setAgreementRead] = useState(false);
  const [searchParams] = useSearchParams();
  const disableAgreement = searchParams.get('disableAgreement');

  const termsOfUseQuery = useQuery<DocumentDto>(
    ['TpaService.getTpaLatestTermsOfUseDocument'],
    async () => {
      return TpaService.getTpaLatestTermsOfUseDocument();
    },
    {
      enabled: Boolean(userHasValidToken),
      staleTime: Infinity
    }
  );

  const saveUserTermsOfUseMutation = useMutation(
    () => {
      return TpaService.saveUserTermsOfUseAgreement();
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Failed',
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: 'Agreed to terms stated.',
          severity: 'success'
        });

        navigate('/plans', { replace: true });
      }
    }
  );

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const scrollSum = scrollTop + clientHeight;
      const acceptedVariance = 2; // this is to enable button for different zoom percentages
      if (Math.abs(scrollHeight - scrollSum) < acceptedVariance) {
        setAgreementRead(true);
      }
    }
  };

  const performLogout = () => {
    logoutChannel.postMessage(SIGNAL);
    logout({ returnTo: window.location.origin });
    logoutChannel.close();
  };
  const handleClose = () => {
    performLogout();
  };

  const handleAccept = () => {
    saveUserTermsOfUseMutation.mutate();
  };

  const isSuccess = termsOfUseQuery.isSuccess && termsOfUseQuery.data;

  return (
    <div className={classes.container}>
      {isSuccess && (
        <>
          <div
            className={classes.content}
            onScroll={onScroll}
            ref={listInnerRef}>
            <div
              className={classes.agreementContainer}
              data-testid='terms-of-use-container'>
              <TermsOfUseContent document={termsOfUseQuery.data} />
            </div>
          </div>
          {!disableAgreement && (
            <div style={{ overflowY: 'scroll' }}>
              <div className={classes.buttonsContainer}>
                <Button
                  data-testid='disagree-agreement-button'
                  onClick={handleClose}
                  size='large'
                  style={{ marginRight: '20px' }}
                  variant='outlined'>
                  Disagree
                </Button>
                <Button
                  data-testid='accept-agreement-button'
                  disabled={!agreementRead}
                  onClick={handleAccept}
                  size='large'
                  variant='contained'>
                  I agree to the terms stated
                </Button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TermsOfUseRoute;
