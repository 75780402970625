import { PlanV2Dto } from '@/models';
import { PlanService } from '@/services/Plan.service';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { AxiosError } from 'axios';

// moshe: remove this hook, replace with using `useQuery` directly
export default function usePlan<T>(
  planId: string | number,
  userHasValidToken: boolean,
  select?: (data: PlanV2Dto) => T
): UseQueryResult<T, AxiosError> {
  return useQuery(
    ['PlanService.getPlanById', planId?.toString()],
    () => PlanService.getPlanById(planId),
    {
      enabled:
        userHasValidToken && planId !== undefined && planId.toString() !== '0',
      select,
      staleTime: Infinity
    }
  );
}
