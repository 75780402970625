import { createSvgIcon, SvgIconProps } from '@mui/material';

import React, { FC } from 'react';

export const SendIcon: FC<SvgIconProps> = createSvgIcon(
  <>
    <path
      d='M8.75 3.49994H6C4.89543 3.49994 4 4.39537 4 5.49994V9.92371C4 12.3735 5.2807 14.6451 7.3768 15.9131L8.9648 16.8737C9.60124 17.2587 10.3988 17.2587 11.0352 16.8737L12.6232 15.9131C13.7917 15.2062 14.7067 14.1875 15.2878 12.9999C15.4344 12.7628 15.6713 12.2328 15.8312 11.5C15.872 11.3422 15.9424 10.9729 15.978 10.4999'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeWidth='1.5'
    />
    <path
      d='M16 7.49988V3.49988M16 3.49988H12M16 3.49988L8.5 10.9999'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
    />
  </>,
  'SendIcon'
);

SendIcon.defaultProps = {
  style: {
    height: 20,
    width: 20
  },
  viewBox: '0 0 20 20'
};

SendIcon.displayName = 'SendIcon';
