import {
  EsaParticipantGoal,
  EsaParticipantIncentives,
  EsaPlanCreatedDto,
  EsaPlanGroup,
  EsaPlanUpdatedDto,
  EsaProgram
} from '@/models';

import * as yup from 'yup';

import ApiService from './Api.service';

export const CreateEsaPlanSchema = yup.object({
  advisorId: yup.number().required(),
  companyName: yup.string().required(),
  esaProgramId: yup.number().required(),
  opportunityId: yup
    .string()
    .nullable()
    .transform(value => (!value ? null : value))
});

export type CreateEsaPlanDto = yup.InferType<typeof CreateEsaPlanSchema>;

export const UpdateEsaPlanSchema = yup.object({
  advisorId: yup.number().required(),
  companyName: yup.string().required(),
  effectiveDate: yup.string().nullable(),
  esaProgramId: yup.number().required(),
  planId: yup.number().required()
});

export type UpdateEsaPlanDto = yup.InferType<typeof UpdateEsaPlanSchema>;

export class EsaService {
  static async createEsaPlan(
    createEsaPlanDto: CreateEsaPlanDto
  ): Promise<EsaPlanCreatedDto> {
    return ApiService.postJson('/esa/plan/onboard', createEsaPlanDto);
  }

  static async updateEsaPlan(
    planId: string | number,
    body: UpdateEsaPlanDto
  ): Promise<EsaPlanUpdatedDto> {
    return ApiService.patchJson(`esa/plans/${planId}`, body);
  }

  static async getEsaPlanGroups(planId: number): Promise<EsaPlanGroup[]> {
    return ApiService.getJson(`/esa/plan/${planId}/groups`);
  }

  static async getEsaPrograms(): Promise<EsaProgram[]> {
    return ApiService.getJson(`/esa/programs`);
  }

  static async updateEsaPlanGroup(
    planId: number,
    groupId: number,
    data: EsaPlanGroup
  ): Promise<EsaPlanGroup> {
    return ApiService.patchJson(`/esa/plan/${planId}/groups/${groupId}`, data);
  }

  static async getEsaParticipantGoals(
    participantId: number
  ): Promise<EsaParticipantGoal[]> {
    return ApiService.getJson(`/esa/participant/${participantId}/goals`);
  }

  static async getEsaParticipantIncentives(
    participantId: number
  ): Promise<EsaParticipantIncentives[]> {
    return ApiService.getJson(`/esa/participant/${participantId}/incentives`);
  }
}
