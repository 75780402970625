import { Box, styled, Typography } from '@mui/material';

import Decimal from 'decimal.js';
import React, { useMemo } from 'react';

type AllocationCellProps = {
  colId: string;
  allocations: Record<string, string | number>[];
  backgroundColor?: string;
};

const StyledBox = styled(Box, {
  shouldForwardProp: propName => propName !== 'total'
})<{ backgroundColor?: string; total: Decimal }>(props => ({
  backgroundColor:
    props.backgroundColor ||
    (props.total.eq(new Decimal(100))
      ? props.theme.palette.success.main
      : props.theme.palette.error.main),
  height: 10,
  marginRight:
    props.total.div(2).toNumber() === 0 ? 0 : props.theme.spacing(0.5),
  maxWidth: 50,
  verticalAlign: 'middle',
  width: `${props.total.div(2).toNumber()}px`
}));

export const AllocationCell: React.FC<AllocationCellProps> = props => {
  const total = useMemo(
    () =>
      props.allocations.reduce((value, fund) => {
        return Decimal.add(fund[props.colId] || 0, value);
      }, new Decimal(0)),
    [props.allocations, props.colId]
  );

  return (
    <Box
      alignItems='center'
      data-test-id={`model-allocation-${props.colId}`}
      display='flex'>
      <StyledBox backgroundColor={props.backgroundColor} total={total} />
      <Typography
        color='rgba(0, 0, 0, 0.6)'
        variant='caption'>{`${total}%`}</Typography>
    </Box>
  );
};

AllocationCell.displayName = 'AllocationCell';
