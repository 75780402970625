import ProgramDocuments from '@/routes/ops/investments/ProgramDocuments.component';
import ProgramFactsheets from '@/routes/ops/investments/ProgramFactsheets.component';

import React from 'react';

export interface ProgramDocumentsProps {
  programId: number;
}

const ProgramDocumentsTab = (props: ProgramDocumentsProps): JSX.Element => {
  const { programId } = props;

  return (
    <>
      <ProgramFactsheets programId={programId} />
      <ProgramDocuments programId={programId} />
    </>
  );
};

export default ProgramDocumentsTab;
