import CollapsibleTable, {
  CellComponentProps,
  Order
} from '@/components/collapsible-table';
import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import NoInvestmentOptionLinked from '@/routes/ops/investments//NoInvestmentOptionLinked.component';
import {
  TargetDateSeriesSearchData,
  TargetDateSeriesService
} from '@/services/ops/investments/TargetDateSeries.service';
import { Box, Link, TableCell, Theme } from '@mui/material';
import Radio from '@mui/material/Radio';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import React, { useEffect, useState } from 'react';
import { useToggle } from 'react-use';

interface ProgramInvestmentTargetDateSeriesProps {
  programId: number;
  searchTerm: string;
  targetDateSeriesId: number | undefined;
  setTargetDateSeriesIdCallback: (name: number | undefined) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    createdDateWidth: {
      width: '15%'
    },
    radio: {
      paddingLeft: '0px'
    },
    selectWidth: {
      width: '5%'
    },
    size: {
      fontSize: theme.spacing(2)
    },
    targetDateSeriesIdWidth: {
      width: '15%'
    },
    targetDateSeriesNameWidth: {
      width: '25%'
    }
  })
);

const ProgramInvestmentTargetDateSeries = (
  props: ProgramInvestmentTargetDateSeriesProps
): JSX.Element => {
  const {
    programId,
    searchTerm,
    targetDateSeriesId,
    setTargetDateSeriesIdCallback
  } = props;
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [pageNumber, setPageNumber] = useState(1);
  const [isSnackDisplay, toggleSnackDisplay] = useToggle(false);
  const { showSnackbar } = useSnackbar();

  useEffect(() => setPageNumber(1), [searchTerm]);

  const [selectedValue, setSelectedValue] = useState(targetDateSeriesId);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLInputElement;
    const value =
      Number(target.value) === selectedValue ? undefined : Number(target.value);
    setSelectedValue(value);
    setTargetDateSeriesIdCallback(value);
    if (value) {
      toggleSnackDisplay();
    }
  };

  const controlProps = (item: number) => ({
    checked: selectedValue === item,
    onClick: handleClick,
    value: item
  });

  const isSelectedRow = (row: TargetDateSeriesSearchData) =>
    row.targetSeriesId === selectedValue;

  const targetDateSeriesTableColumns: GridColDef[] = [
    {
      cellClassName: classes.selectWidth,
      field: 'select',
      headerName: 'Select',
      sortable: false
    },
    {
      cellClassName: classes.targetDateSeriesIdWidth,
      field: 'targetSeriesId',
      headerName: 'ID'
    },
    {
      cellClassName: classes.targetDateSeriesNameWidth,
      field: 'name',
      headerName: 'Model Series Name'
    },
    {
      cellClassName: classes.createdDateWidth,
      field: 'createdAt',
      headerName: 'Created Date'
    },
    { field: 'programCount', headerName: 'Programs' }
  ];

  const selectedTargetDateSeriesResult = useQuery<{
    data: TargetDateSeriesSearchData[];
    meta: { count: number };
  }>(
    [
      'TargetDateSeriesService.getTargetDateSeriesPage',
      programId,
      1,
      1,
      orderBy,
      order,
      targetDateSeriesId
    ],
    async () => {
      return TargetDateSeriesService.getTargetDateSeriesPage(
        1,
        1,
        orderBy,
        order,
        undefined,
        targetDateSeriesId
      );
    },
    {
      enabled: Boolean(targetDateSeriesId)
    }
  );

  const targetDateSeriesSearchResult = useQuery<{
    data: TargetDateSeriesSearchData[];
    meta: { count: number };
  }>(
    [
      'TargetDateSeriesService.getTargetDateSeriesPage',
      programId,
      pageNumber,
      rowsPerPage,
      searchTerm,
      orderBy,
      order
    ],
    async () => {
      return TargetDateSeriesService.getTargetDateSeriesPage(
        pageNumber,
        rowsPerPage,
        orderBy,
        order,
        searchTerm
      );
    },
    {
      enabled: Boolean(searchTerm)
    }
  );

  const targetDateSeriesData =
    targetDateSeriesSearchResult.data?.data.filter(
      item => item.targetSeriesId !== targetDateSeriesId
    ) || [];
  const selectedTargetDateSeriesData =
    selectedTargetDateSeriesResult.data?.data[0];

  if (selectedTargetDateSeriesData) {
    targetDateSeriesData.unshift(selectedTargetDateSeriesData);

    if (isSnackDisplay) {
      showSnackbar({
        message: `You have selected ${selectedTargetDateSeriesData.name}`,
        severity: 'info'
      });
      toggleSnackDisplay();
    }
  }

  const { error } = targetDateSeriesSearchResult;
  if (error) {
    return redirectToErrorPage(error as Error);
  }

  const TargetDateSeriesTableCell: React.FunctionComponent<
    CellComponentProps
  > = (cellProps: CellComponentProps) => {
    const { row, column } = cellProps;
    let field;

    if (column.field === 'select') {
      field = (
        <Radio
          {...controlProps(row.targetSeriesId)}
          className={classes.radio}
        />
      );
    } else field = row[column.field];

    return (
      <TableCell className={classes.size} component='th' scope='row'>
        <Box>
          {column.field === 'name' ? (
            <Link
              href={`/ops/investments/target-series/${row.targetSeriesId}`}
              rel='noopener noreferrer'
              target='_blank'>
              {field}
            </Link>
          ) : (
            field
          )}
        </Box>
      </TableCell>
    );
  };

  return (
    <>
      <CollapsibleTable
        cellComponent={TargetDateSeriesTableCell}
        columns={targetDateSeriesTableColumns}
        isSelectedRow={isSelectedRow}
        noDataPlaceholderComponent={
          <NoInvestmentOptionLinked typeName='target series' />
        }
        pager={{
          metaCount: targetDateSeriesSearchResult.data?.meta.count,
          onPageNumberChanged: (zeroIndexedPageNumber: number) => {
            return setPageNumber(zeroIndexedPageNumber + 1);
          },
          onRowsPerPageChanged: (newRowsPerPage: number) => {
            return setRowsPerPage(newRowsPerPage);
          },
          pageNumber: pageNumber - 1,
          rowsPerPage
        }}
        sorter={{
          onSortOrderChanged: (newOrderBy: string, newOrder: Order) => {
            setOrderBy(newOrderBy);
            setOrder(newOrder);
          },
          order,
          orderBy
        }}
        tableData={targetDateSeriesData}
      />
    </>
  );
};

export default ProgramInvestmentTargetDateSeries;
