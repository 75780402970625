import dayjs from 'dayjs';
import * as yup from 'yup';

const checkForValidDateFormat = (value?: string) => {
  if (!value) return true;
  return dayjs(value, 'MM/DD/YYYY', true).isValid();
};

const checkForFutureDate = (value?: string) => {
  if (!value) return true;
  const enteredDate = new Date(value as string);
  return enteredDate.getTime() < new Date().getTime();
};

const checkIfDateIsBeforeDOB = (dateOfBirth?: string, value?: string) => {
  if (!value) return true;
  const enteredDate = new Date(value as string);
  return dateOfBirth
    ? enteredDate.getTime() > new Date(dateOfBirth).getTime()
    : true;
};

const createParticipantEmploymentDatesValidationSchema = (
  rehireDate: string | null,
  dateOfBirth?: string
): yup.AnyObjectSchema =>
  yup.object({
    rehireDate: yup
      .string()
      .test(
        'Date format',
        'Please enter a valid date as mm/dd/yyyy',
        checkForValidDateFormat
      )
      .test(
        'Check for future date',
        'Date cannot be in the future',
        checkForFutureDate
      )
      .test(
        'Check if date is before DOB',
        'Date cannot be before employee’s date of birth',
        value => checkIfDateIsBeforeDOB(dateOfBirth, value)
      ),
    terminationDate: yup
      .string()
      .test(
        'Date format',
        'Please enter a valid date as mm/dd/yyyy',
        checkForValidDateFormat
      )
      .test(
        'Check for future date',
        'Date cannot be in the future',
        checkForFutureDate
      )
      .test(
        'Check if date is before DOB',
        'Date cannot be before employee’s date of birth',
        value => checkIfDateIsBeforeDOB(dateOfBirth, value)
      )
      .when(['rehireDate'], (newRehireDate, schema) => {
        if (newRehireDate) {
          return schema.required(
            'Termination date cannot be removed because of existing rehire date'
          );
        }
      })
  });

export default createParticipantEmploymentDatesValidationSchema;
