import { useQuery } from '@tanstack/react-query';

import { PlanService } from '../services/Plan.service';

export const useGetPayrollProviders = () => {
  const payrolls = useQuery(['payrolls'], () =>
    PlanService.getPayrollProviders()
  );

  return payrolls;
};
