import {
  FormHelperText,
  TextFieldProps,
  Theme,
  useFormControl
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  DatePickerProps,
  DateValidationError,
  DesktopDatePicker,
  LocalizationProvider
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import dayjs, { Dayjs } from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import { useToggle } from 'react-use';

const useStyles = makeStyles((theme: Theme) => ({
  inputTooltip: {
    fontSize: theme.spacing(1.5),
    lineHeight: theme.spacing(1.5),
    margin: 0,
    marginTop: theme.spacing(0.75),
    maxWidth: '100%',
    width: 'max-content'
  }
}));

export type MuiDatePickerProps = Omit<
  DatePickerProps<any>,
  'onChange' | 'renderInput' | 'value'
> & {
  'data-testid'?: string;
  errorMessage?: string;
  handleError?: (
    errorReason: DateValidationError,
    value: dayjs.Dayjs | null
  ) => void;
  label?: string;
  name: string;
  onChange: (event: { target: { name: string; value: string } }) => void;
  value?: string | null;
};

export const ActionCenterDatePicker: FC<
  MuiDatePickerProps & TextFieldProps
> = ({
  'data-testid': testId,
  errorMessage = '',
  handleError,
  label,
  name,
  onChange,
  size,
  value,
  variant = 'standard',
  ...restProps
}) => {
  const classes = useStyles();
  const { error: formControlError } = useFormControl() || {};
  const [error, setError] = useState('');
  const [cleared, toggleCleared] = useToggle(false);
  const [dayJsValue, setDayJsValue] = useState<Dayjs | null>(
    value ? dayjs(value) : null
  );

  useEffect(() => {
    if (value !== dayJsValue?.format('YYYY-MM-DD')) {
      setDayJsValue(value ? dayjs(value) : null);
    }
  }, [value]);

  useEffect(() => {
    if (cleared) {
      const timeout = setTimeout(() => {
        toggleCleared();
      }, 1500);
      return () => clearTimeout(timeout);
    }
  }, [cleared]);

  const getErrorMessage = (currValue: dayjs.Dayjs | null): string => {
    if (currValue) {
      if (!currValue.isValid())
        return 'Please enter a valid date as mm/dd/yyyy';
      if (restProps.shouldDisableDate?.(currValue))
        return 'Please choose a valid date';
      if (restProps.disableFuture && currValue.isAfter(dayjs()))
        return 'Date cannot be in the future';
    }
    return '';
  };

  const internalHandleError = (
    errorReason: DateValidationError,
    currValue: dayjs.Dayjs | null
  ) => {
    const message = getErrorMessage(currValue);
    setError(message);
    handleError?.(errorReason, currValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        format='MM/DD/YYYY'
        label={label}
        onChange={(newValue: Dayjs | null) => {
          setDayJsValue(newValue);
          const newError = getErrorMessage(newValue);
          if (newError) return internalHandleError('invalidDate', newValue);
          setError('');
          onChange({
            target: {
              name,
              value: newValue ? newValue.format('YYYY-MM-DD') : ''
            }
          });
        }}
        onError={internalHandleError}
        slotProps={{
          field: { clearable: true, onClear: toggleCleared },
          textField: {
            InputLabelProps: { shrink: true },
            //@ts-expect-error - MUI DatePickerProps does not have this prop
            InputProps: { 'data-testid': testId },
            error: !!errorMessage || formControlError || !!error,
            fullWidth: true,
            name,
            size,
            variant
          }
        }}
        value={dayJsValue}
        {...restProps}
      />
      <FormHelperText
        className={classes.inputTooltip}
        error={!!errorMessage || formControlError || !!error}>
        {errorMessage || error}
      </FormHelperText>
    </LocalizationProvider>
  );
};
