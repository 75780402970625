import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export function useUrlStateParams<T>(
  initialState: T,
  paramName: string,
  serialize: (state: T) => string,
  deserialize: (state: string) => T
): [T, (state: T | ((prev: T) => T)) => void] {
  const [currentSearchParameters, setSearchParams] = useSearchParams();

  const existingValue = currentSearchParameters.get(paramName);
  const [state, setState] = useState<T>(
    existingValue ? deserialize(existingValue) : initialState
  );

  useEffect(() => {
    // Updates state when user navigates backwards or forwards in browser history
    if (existingValue === null && initialState !== state) {
      setState(initialState);
    } else if (existingValue !== null && deserialize(existingValue) !== state) {
      setState(deserialize(existingValue));
    }
  }, [existingValue]);

  const onChange = (newValue: T | ((prev: T) => T)) => {
    const newValueProcessed =
      newValue instanceof Function ? newValue(state) : newValue;
    setState(newValueProcessed);
    // create a new read of the search params so we don't end up with a race condition
    // where a sibling call updates the search params using a stale version
    const newSearchParams = new URLSearchParams(window.location.search);
    // if the new value is the same as the initial state or blank, remove the param from the url
    const serializedValue = serialize(newValueProcessed);
    if (
      newValueProcessed === initialState ||
      serializedValue === undefined ||
      serializedValue === ''
    ) {
      newSearchParams.delete(paramName);
    } else {
      newSearchParams.set(paramName, serializedValue);
    }
    // finally update the url
    setSearchParams(newSearchParams);
  };

  return [state, onChange];
}
