import { DialogOptions, useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import TpaService from '@/services/Tpa.service';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import React from 'react';
import * as yup from 'yup';

interface EditTPAButtonProps {
  size: 'medium' | 'large' | 'small';
  initialValue: string;
  tpaId: number | string;
}
const EditTPAButton: React.FunctionComponent<EditTPAButtonProps> = ({
  size,
  initialValue,
  tpaId
}) => {
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { openDialog } = useDialog();
  const validationSchema = yup.object({
    name: yup
      .string()
      .required('TPA Name is required')
      .min(3, 'TPA Name length must be at least 3 characters')
      .max(250)
  });
  const canEditTPA = userService.hasPermission(
    FeatureLevelPermissions.WRITE_TPA_ADD_TPA
  );

  const { mutateAsync: updateTPAName } = useMutation(
    ['TpaService.updateTPAName'],
    (name: string) => {
      return TpaService.updateTpaName(tpaId, name);
    },
    {
      onError: (_, name) => {
        showSnackbar({
          message: `Failed to update TPA ${formatters.chopStringLongerThan(
            initialValue,
            80
          )} to ${formatters.chopStringLongerThan(name, 80)}`,
          severity: 'error'
        });
      },
      onSuccess: async (_, name) => {
        showSnackbar({
          message: `TPA ${formatters.chopStringLongerThan(
            initialValue,
            80
          )} updated to ${formatters.chopStringLongerThan(name, 80)}`,
          severity: 'success'
        });

        await queryClient.refetchQueries(['TpaService.getAll']);
        await queryClient.refetchQueries([
          'TpaService.getTpa',
          tpaId.toString()
        ]);
      }
    }
  );

  const dialogConfig: DialogOptions = {
    actionButtons: {
      cancelButton: { children: 'Cancel' },
      submitButton: {
        children: 'Update'
      }
    },
    onSubmit: async values => {
      await updateTPAName(values.name);
    },
    preSubmitValidation: async (values, formikHelpers) => {
      try {
        const queryData = await queryClient.fetchQuery(
          ['TpaService.searchTPAbyName', values.name],
          () => TpaService.searchTpaByName(values.name)
        );

        if (
          queryData.data.filter(
            tpa =>
              tpa.tpaId !== tpaId &&
              tpa.name.toLowerCase() === values.name.toLowerCase()
          ).length
        ) {
          formikHelpers.setFieldError('name', 'TPA name must be unique.');
          return true;
        }
        return false;
      } catch (error) {
        return false;
      }
    },
    steps: [
      {
        fields: {
          name: {
            initialValue,
            label: 'Name'
          }
        },
        title: 'Edit TPA'
      }
    ],
    validationSchema
  };
  return canEditTPA ? (
    <IconButton
      aria-label='edit'
      data-testid={`button-edit-tpa-${tpaId}`}
      onClick={() => {
        openDialog(dialogConfig);
      }}
      size={size}
      sx={{
        color: theme => theme.palette.info.light
      }}>
      <EditIcon fontSize='inherit' />
    </IconButton>
  ) : null;
};

export default EditTPAButton;
