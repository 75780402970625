import useSponsor from '@/hooks/useSponsor';
import { PlanV2Dto } from '@/models';
import { CompanyInfoTabData } from '@/routes/plans/plan-detail/PlanCompanyInfoCard/CompanyInfoTab.component';
import { PlanService } from '@/services/Plan.service';
import { useQuery } from '@tanstack/react-query';

import { CreateScheduledChangePayloadConfig } from './utils';

export const useScheduledChangePayloadConfig = (
  planId: number
): CreateScheduledChangePayloadConfig => {
  const planDesignQuery = useQuery(
    ['PlanService.getPlanDesignById', +planId],
    () => {
      return PlanService.getPlanDesignById(planId);
    }
  );

  const sponsorPlanQuery = useQuery<PlanV2Dto>(
    ['PlanService.getPlanById', +planId],
    () => {
      return PlanService.getPlanById(planId);
    }
  );

  const sponsorId =
    sponsorPlanQuery.data?.data.relationships.sponsor.data.id.toString();

  const sponsorQuery = useSponsor<CompanyInfoTabData>(
    sponsorId,
    sponsorDto => ({
      address: sponsorDto.data.attributes.physicalAddress,
      companyEntityType: sponsorDto.data.attributes.companyEntityType,
      doingBusinessAs: sponsorDto.data.attributes.doingBusinessAs,
      ein: sponsorDto.data.attributes.ein,
      email: sponsorDto.data.attributes.primaryContact.email,
      exemptionId: sponsorDto.data.relationships.exemption?.data.id,
      firmId: sponsorDto.data.relationships.firm.data.id,
      fiscalYearEnd: sponsorDto.data.attributes.fiscalYearEnd,
      isPartOfControlGroup: sponsorDto.data.attributes.isPartOfControlGroup,
      isWiredAtWork: sponsorDto.data.attributes.isWiredAtWork,
      mailingAddress: sponsorDto.data.attributes.mailingAddress,
      name: sponsorDto.data.attributes.name,
      phone: sponsorDto.data.attributes.companyPhoneNumber,
      physicalAddress: sponsorDto.data.attributes.physicalAddress,
      primaryContact: sponsorDto.data.attributes.primaryContact,
      stateOfIncorporation: sponsorDto.data.attributes.stateOfIncorporation
    })
  );

  return {
    companyInfoTabData: sponsorQuery.data,
    isStateIRA:
      sponsorPlanQuery.data?.data.attributes.type === 'State IRA' ||
      sponsorPlanQuery.data?.data.attributes.recordkeeper ===
        'Surpas (State IRA)',
    isVoyaPlan: sponsorPlanQuery.data?.data.attributes.recordkeeper === 'Voya',
    planDesignData: planDesignQuery.data
  };
};
