import Accordion, {
  AccordionDetails,
  AccordionSummary
} from '@/components/accordion/Accordion.component';
import Badge from '@/components/badge';
import Card, {
  CardContent,
  CardHeader,
  CardPlaceholder
} from '@/components/card';
import {
  FieldDisplayController,
  FormContainer,
  FormDialog
} from '@/components/form-dialog/FormDialog.component';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import VisibilityToggle from '@/components/visibility-toggle';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { Beneficiary } from '@/models/ParticipantBeneficiary.model';
import ParticipantService from '@/services/Participant.service';
import formatters from '@/utils/Formatters';
import {
  addBeneficiarySchema,
  allocationsSchema,
  beneficiarySchema
} from '@/utils/validations/ParticipantBeneficiaries.schema';
import AddIcon from '@mui/icons-material/Add';
import TuneIcon from '@mui/icons-material/Tune';
import { Button, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useMutation, useQuery } from '@tanstack/react-query';
import { formatSsn } from '@vestwell-frontend/helpers';

import { capitalize, cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { patternFormatter } from 'react-number-format';
import { useToggle } from 'react-use';

import { BeneficiariesAllocationsFormTable } from './BeneficiariesAllocationsFormTable.component';
import { BeneficiaryForm } from './BeneficiaryForm.component';

type ParticipantBeneficiariesCardProps = {
  participantId?: number;
};

export const ParticipantBeneficiariesCard: React.FC<
  ParticipantBeneficiariesCardProps
> = (props: ParticipantBeneficiariesCardProps) => {
  const [beneficiaryEditFormDialogOpen, toggleBeneficiaryEditFormDialogOpen] =
    useToggle(false);
  const [beneficiaryAddFormDialogOpen, toggleBeneficiaryAddFormDialogOpen] =
    useToggle(false);
  const [reallocateFormDialogOpen, toggleReallocateFormDialogOpen] =
    useToggle(false);

  const [openBeneficiaryId, setOpenBeneficiaryId] = useState<
    number | undefined
  >();
  const [deleteBeneficiary, setDeleteBeneficiary] = useState<
    Beneficiary | undefined
  >();
  const [selectedBeneficiary, setSelectedBeneficiary] = useState<
    Beneficiary | undefined
  >();
  const [isMaskActive, toggleIsMaskActive] = useToggle(true);

  const { showSnackbar } = useSnackbar();

  const beneficiariesQuery = useQuery(
    ['ParticipantService.getBeneficiaries', props.participantId],
    () => ParticipantService.getBeneficiaries(props.participantId),
    {
      enabled: !!props.participantId,
      select: data => {
        return {
          data: data.data
            .map(beneficiary => {
              try {
                beneficiary.attributes.phoneNumber = patternFormatter(
                  beneficiary.attributes.phoneNumber.replace(/[^0-9]/g, ''),
                  { format: '###-###-####' }
                );
              } catch {
                beneficiary.attributes.phoneNumber = '';
              }
              return beneficiary;
            })
            .sort(
              (a, b) =>
                +b.attributes.isPrimary - +a.attributes.isPrimary ||
                b.attributes.allocationPct - a.attributes.allocationPct
            )
        };
      }
    }
  );

  const updateBeneficiariesMutation = useMutation(
    [['ParticipantService.getBeneficiaries', props.participantId]],
    (data: Parameters<typeof ParticipantService.updateBeneficiaries>['1']) =>
      ParticipantService.updateBeneficiaries(props.participantId, data),
    {
      onError: async () => {
        showSnackbar({
          message: beneficiaryEditFormDialogOpen
            ? `Couldn't ${selectedBeneficiary ? 'edit' : 'add'} new beneficiary at the moment, please try again`
            : `Couldn't update beneficiary allocation at the moment, please try again`,
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message:
            beneficiaryEditFormDialogOpen || beneficiaryAddFormDialogOpen
              ? `Successfully ${selectedBeneficiary ? 'edited' : 'added'} new beneficiary`
              : reallocateFormDialogOpen
                ? deleteBeneficiary
                  ? `Successfully deleted beneficiary`
                  : `Successfully update beneficiary allocation`
                : `Successfully deleted beneficiary`,
          severity: 'success'
        });
        toggleBeneficiaryAddFormDialogOpen(false);
        toggleBeneficiaryEditFormDialogOpen(false);
        toggleReallocateFormDialogOpen(false);
        setSelectedBeneficiary(undefined);
        setDeleteBeneficiary(undefined);
        beneficiariesQuery.refetch();
      }
    }
  );

  const handleSubmitEditBeneficiary = useCallback(
    async (attributes: Beneficiary['attributes']) => {
      const newBeneficiaries = cloneDeep(beneficiariesQuery.data.data);
      newBeneficiaries.map(beneficiary => {
        if (beneficiary.id === selectedBeneficiary.id) {
          beneficiary.attributes = attributes;
        }
        return beneficiary;
      });

      await updateBeneficiariesMutation.mutateAsync(newBeneficiaries);
    },
    [selectedBeneficiary, beneficiariesQuery.data]
  );

  const handleSubmitUpdateBeneficiaries = useCallback(
    async (data: { beneficiaries: Beneficiary[] }) => {
      await updateBeneficiariesMutation.mutateAsync(data.beneficiaries);
    },
    [selectedBeneficiary, beneficiariesQuery.data]
  );

  const handleDeleteBeneficiary = (deleteBeneficiary: Beneficiary) => {
    const filteredBeneficiaries = beneficiariesQuery.data.data.filter(
      beneficiary =>
        beneficiary.attributes.isPrimary ===
          deleteBeneficiary.attributes.isPrimary &&
        beneficiary.id !== deleteBeneficiary.id
    );

    if (!filteredBeneficiaries.length) {
      return updateBeneficiariesMutation.mutateAsync(
        beneficiariesQuery.data.data.map(beneficiary => {
          if (beneficiary.id === deleteBeneficiary.id) {
            beneficiary.attributes.allocationPct = 0;
          }
          return beneficiary;
        })
      );
    }
    setDeleteBeneficiary(deleteBeneficiary);
  };

  useEffect(() => {
    if (selectedBeneficiary) {
      toggleBeneficiaryEditFormDialogOpen(true);
    }
  }, [selectedBeneficiary]);

  useEffect(() => {
    if (deleteBeneficiary) {
      toggleReallocateFormDialogOpen(true);
    }
  }, [deleteBeneficiary]);

  return (
    <>
      <FormDialog
        data-testid='reallocate-or-delete-form-dialog'
        fullWidth
        initialValues={{
          beneficiaries: beneficiariesQuery.data?.data || [],
          deleteBeneficiary
        }}
        maxWidth='sm'
        onClose={() => {
          toggleReallocateFormDialogOpen();
          setDeleteBeneficiary(undefined);
        }}
        onSubmit={handleSubmitUpdateBeneficiaries}
        open={reallocateFormDialogOpen}
        title={`${deleteBeneficiary ? 'Delete' : 'Reallocate'} Beneficiary`}
        validationSchema={allocationsSchema}>
        <FormContainer continueButtonText='SAVE' name='beneficiaries'>
          {(deleteBeneficiary
            ? deleteBeneficiary.attributes.isPrimary
            : true) && <BeneficiariesAllocationsFormTable />}
          {(deleteBeneficiary
            ? !deleteBeneficiary.attributes.isPrimary
            : true) && <BeneficiariesAllocationsFormTable isContingent />}
        </FormContainer>
      </FormDialog>
      <FormDialog
        data-testid='add-beneficiary-form-dialog'
        fullWidth
        initialValues={{
          beneficiaries: beneficiariesQuery.data?.data || [],
          beneficiaryAttributes: {
            allocationPct: 0,
            beneficiaryType: 'person',
            isPrimary: false
          }
        }}
        maxWidth='sm'
        onClose={toggleBeneficiaryAddFormDialogOpen}
        onSubmit={handleSubmitUpdateBeneficiaries}
        open={beneficiaryAddFormDialogOpen}
        title='Add Beneficiary'
        validationSchema={addBeneficiarySchema}>
        <FormContainer
          ContainerElement={Grid2}
          ContainerProps={{ container: true, spacing: 2 }}
          name='beneficiaryAttributes'>
          <BeneficiaryForm useAddSchema />
        </FormContainer>
        <FormContainer continueButtonText='SAVE' name='beneficiaries'>
          <FieldDisplayController
            showWhen={values => values.beneficiaryAttributes.isPrimary}>
            <BeneficiariesAllocationsFormTable />
          </FieldDisplayController>
          <FieldDisplayController
            showWhen={values => !values.beneficiaryAttributes.isPrimary}>
            <BeneficiariesAllocationsFormTable isContingent />
          </FieldDisplayController>
        </FormContainer>
      </FormDialog>
      <FormDialog
        data-testid='edit-beneficiary-form-dialog'
        initialValues={{
          ...selectedBeneficiary?.attributes
        }}
        onClose={() => {
          toggleBeneficiaryEditFormDialogOpen();
          setSelectedBeneficiary(undefined);
        }}
        onSubmit={handleSubmitEditBeneficiary}
        open={beneficiaryEditFormDialogOpen}
        title='Edit Beneficiary'
        validationSchema={beneficiarySchema}>
        <FormContainer
          ContainerElement={Grid2}
          ContainerProps={{ container: true, spacing: 2 }}
          continueButtonText='SAVE'>
          <BeneficiaryForm />
        </FormContainer>
      </FormDialog>
      <Card data-testid='participant-beneficiaries-card'>
        <CardHeader
          actionButtonsProps={[
            {
              'data-testid': 'add-beneficiary-button',
              disabled: beneficiariesQuery.isFetching,
              label: 'Add',
              onClick: () => toggleBeneficiaryAddFormDialogOpen(),
              startIcon: <AddIcon />,
              variant: 'text'
            },
            {
              'data-testid': 'reallocate-beneficiaries-button',
              disabled:
                !beneficiariesQuery.data?.data.length ||
                beneficiariesQuery.isFetching,
              label: 'Reallocate',
              onClick: () => toggleReallocateFormDialogOpen(),
              startIcon: <TuneIcon />,
              variant: 'text'
            }
          ]}
          loading={beneficiariesQuery.isFetching}
          title='Beneficiaries'
        />
        <CardContent disablePadding>
          {beneficiariesQuery.data?.data.length ? (
            beneficiariesQuery.data.data.map(beneficiary => (
              <Accordion
                data-testid={`participant-beneficiaries-${beneficiary.id}-accordion`}
                expanded={openBeneficiaryId === beneficiary.id}
                key={beneficiary.id}>
                <AccordionSummary
                  data-testid={`participant-beneficiaries-${beneficiary.id}-accordion-summary`}
                  onClick={() =>
                    setOpenBeneficiaryId(prevId =>
                      prevId === beneficiary.id ? undefined : beneficiary.id
                    )
                  }
                  sx={{
                    '& .MuiAccordionSummary-content': {
                      marginLeft: 0
                    },
                    backgroundColor: 'white !important',
                    flexDirection: 'row'
                  }}>
                  <Stack
                    alignItems='center'
                    direction='row'
                    sx={{ width: '100%' }}>
                    <Typography
                      data-testid={`participant-beneficiaries-${beneficiary.id}-name`}
                      variant='h6'>
                      {formatters.getBeneficiaryName(beneficiary)}
                    </Typography>
                    {beneficiary.attributes.isPrimary && (
                      <Badge
                        color='success'
                        data-testid={`participant-beneficiaries-${beneficiary.id}-isPrimary-badge`}
                        size='small'
                        sx={{ ml: 1 }}>
                        Primary
                      </Badge>
                    )}
                    <Typography
                      color={grey[600]}
                      data-testid={`participant-beneficiaries-${beneficiary.id}-allocationPct`}
                      sx={{ ml: 'auto', mr: 1 }}
                      variant='body1'>
                      {beneficiary.attributes.allocationPct}% allocated
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails
                  data-testid={`participant-beneficiaries-${beneficiary.id}-accordion-details`}>
                  <Stack direction='row' p={2} spacing={2}>
                    <TextStack direction='column' sx={{ flex: 1 }}>
                      {beneficiary.attributes.beneficiaryType == 'person' && (
                        <TextStackItem
                          fieldName={`participant-beneficiaries-${beneficiary.id}-relationship`}>
                          <TextLabel>Relationship </TextLabel>
                          <TextValue>
                            {capitalize(beneficiary.attributes.relationship)}
                          </TextValue>
                        </TextStackItem>
                      )}

                      {beneficiary.attributes.beneficiaryType == 'person' && (
                        <TextStackItem
                          fieldName={`participant-beneficiaries-${beneficiary.id}-ssn`}>
                          <TextLabel>SSN</TextLabel>
                          <TextValue>
                            {beneficiary.attributes.ssn && (
                              <>
                                {isMaskActive
                                  ? formatters.maskSSN(
                                      formatSsn(beneficiary.attributes.ssn),
                                      true
                                    )
                                  : formatSsn(beneficiary.attributes.ssn)}
                                <VisibilityToggle
                                  isMasked={!isMaskActive}
                                  toggleMask={toggleIsMaskActive}
                                />
                              </>
                            )}
                          </TextValue>
                        </TextStackItem>
                      )}
                      {beneficiary.attributes.beneficiaryType ===
                        'organization' && (
                        <TextStackItem
                          fieldName={`participant-beneficiaries-${beneficiary.id}-ein`}>
                          <TextLabel>EIN</TextLabel>
                          <TextValue> {beneficiary.attributes.ein}</TextValue>
                        </TextStackItem>
                      )}
                      {beneficiary.attributes.beneficiaryType == 'trust' && (
                        <TextStackItem
                          fieldName={`participant-beneficiaries-${beneficiary.id}-trustId`}>
                          <TextLabel>Tax ID</TextLabel>
                          <TextValue>
                            {beneficiary.attributes.trustId}
                          </TextValue>
                        </TextStackItem>
                      )}
                      {beneficiary.attributes.beneficiaryType == 'trust' && (
                        <TextStackItem
                          fieldName={`participant-beneficiaries-${beneficiary.id}-holdingFinancialInstitution`}>
                          <TextLabel>Holding Financial Institution</TextLabel>
                          <TextValue>
                            {beneficiary.attributes.holdingFinancialInstitution}
                          </TextValue>
                        </TextStackItem>
                      )}
                      <TextStackItem
                        fieldName={`participant-beneficiaries-${beneficiary.id}-email`}>
                        <TextLabel>Email</TextLabel>
                        <TextValue> {beneficiary.attributes.email}</TextValue>
                      </TextStackItem>
                      <TextStackItem
                        fieldName={`participant-beneficiaries-${beneficiary.id}-phoneNumber`}>
                        <TextLabel>Phone</TextLabel>
                        <TextValue>
                          {beneficiary.attributes.phoneNumber}
                        </TextValue>
                      </TextStackItem>
                      <TextStackItem
                        fieldName={`participant-beneficiaries-${beneficiary.id}-address1`}>
                        <TextLabel>Address 1</TextLabel>
                        <TextValue>{beneficiary.attributes.address1}</TextValue>
                      </TextStackItem>
                      <TextStackItem
                        fieldName={`participant-beneficiaries-${beneficiary.id}-address2`}>
                        <TextLabel>Address 2</TextLabel>
                        <TextValue>{beneficiary.attributes.address2}</TextValue>
                      </TextStackItem>
                      <TextStackItem
                        fieldName={`participant-beneficiaries-${beneficiary.id}-city`}>
                        <TextLabel>City</TextLabel>
                        <TextValue>{beneficiary.attributes.city}</TextValue>
                      </TextStackItem>
                      <TextStackItem
                        fieldName={`participant-beneficiaries-${beneficiary.id}-state`}>
                        <TextLabel>State</TextLabel>
                        <TextValue> {beneficiary.attributes.state}</TextValue>
                      </TextStackItem>
                      <TextStackItem
                        fieldName={`participant-beneficiaries-${beneficiary.id}-zip`}>
                        <TextLabel>Zip</TextLabel>
                        <TextValue> {beneficiary.attributes.zip}</TextValue>
                      </TextStackItem>
                    </TextStack>
                  </Stack>
                  <Button
                    data-testid={`edit-participant-beneficiaries-${beneficiary.id}-button`}
                    disabled={beneficiariesQuery.isFetching}
                    onClick={() => setSelectedBeneficiary(beneficiary)}>
                    Edit
                  </Button>
                  <Button
                    data-testid={`delete-participant-beneficiaries-${beneficiary.id}-button`}
                    disabled={
                      beneficiariesQuery.isFetching ||
                      updateBeneficiariesMutation.isLoading
                    }
                    onClick={() => handleDeleteBeneficiary(beneficiary)}>
                    Delete
                  </Button>
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <CardPlaceholder
              data-testid='no-data-placeholder'
              subtitle='This user hasn’t added any beneficiaries.'
            />
          )}
        </CardContent>
      </Card>
    </>
  );
};
