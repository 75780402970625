import { useField, useFormikContext } from 'formik';
import { FC, useEffect } from 'react';

const EmployerContributionObserver: FC<{
  data: Record<string, any>;
}> = ({ data }: { data: Record<string, any> }) => {
  const { values }: { values: Record<string, any> } = useFormikContext();
  const [, , formulaDefinedHelpers] = useField('formulaDefined');
  const [, , nonSafeHarborMatchTypeHelpers] = useField(
    'nonSafeHarborMatchType'
  );
  const [, , profitSharingStrategyHelpers] = useField('profitSharingStrategy');
  const [, , matchFrequencyHelpers] = useField('matchFrequency');
  const [, , discretionaryMatchFundingIntentHelpers] = useField(
    'discretionaryMatchFundingIntent'
  );

  useEffect(() => {
    if (
      values.nonSafeHarborMatchType === 'Formula' &&
      values.formulaDefined !== 'Defined Formula'
    ) {
      formulaDefinedHelpers.setValue('Defined Formula');
    }

    if (
      values.profitSharingStrategy &&
      values.profitSharingStrategy !== 'N/A - No Profit Share' &&
      (!values.allowEmployerProfitSharing ||
        values.allowEmployerProfitSharing === "Don't allow")
    ) {
      profitSharingStrategyHelpers.setValue('N/A - No Profit Share');
    }

    data.profitSharingStrategy.setDisabled(values.allowEmployerProfitSharing);

    data.nonSafeHarborMatchType.setDisabled(
      !values.allowNonSafeHarborMatch ||
        values.allowNonSafeHarborMatch === "Don't allow"
    );

    if (
      (!values.allowNonSafeHarborMatch ||
        values.allowNonSafeHarborMatch === "Don't allow") &&
      values.nonSafeHarborMatchType
    ) {
      nonSafeHarborMatchTypeHelpers.setValue('');
      formulaDefinedHelpers.setValue('No Formula Defined');
    }

    data.profitSharingStrategy.setOptions(values.allowEmployerProfitSharing);

    data.matchFrequency.setDisabled(
      values.safeHarborMatchType === 'N/A - No Safe Harbor'
    );

    if (
      values.safeHarborMatchType === 'N/A - No Safe Harbor' &&
      values.matchFrequency
    ) {
      matchFrequencyHelpers.setValue(null);
    }

    data.discretionaryMatchFundingIntent.setDisabled(
      !values.allowNonSafeHarborMatch ||
        values.allowNonSafeHarborMatch === "Don't allow"
    );

    if (
      !data.discretionaryMatchFundingIntent.considerPredefinedValue &&
      (!values.discretionaryMatchFundingIntent ||
        values.allowNonSafeHarborMatch === "Don't allow" ||
        values.documentMatchFrequency !== 'Per pay-period')
    ) {
      data.discretionaryMatchFundingIntent.setConsiderPredefinedValue(true);
    }

    if (
      data.discretionaryMatchFundingIntent.considerPredefinedValue &&
      values.discretionaryMatchFundingIntent !== 'Per pay-period' &&
      values.allowNonSafeHarborMatch === 'Allow' &&
      values.documentMatchFrequency === 'Per pay-period'
    ) {
      discretionaryMatchFundingIntentHelpers.setValue('Per pay-period');
      data.discretionaryMatchFundingIntent.setConsiderPredefinedValue(false);
    }

    if (
      values.discretionaryMatchFundingIntent &&
      values.allowNonSafeHarborMatch === "Don't allow"
    ) {
      discretionaryMatchFundingIntentHelpers.setValue(null);
    }
  }, [
    data,
    formulaDefinedHelpers,
    nonSafeHarborMatchTypeHelpers,
    profitSharingStrategyHelpers,
    values
  ]);

  return null;
};

export default EmployerContributionObserver;
