import { ReceiveIcon } from '@/components/icon/ReceiveIcon';
import { SendIcon } from '@/components/icon/SendIcon';
import { PayGroupForm } from '@/routes/plans/plan-detail/PayGroups/PayGroupsModal.component';
import { PlanService } from '@/services/Plan.service';
import { CheckCircle } from '@mui/icons-material';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import type { Payroll } from '@vestwell-api/scala';

import { FormikProps } from 'formik';
import React, { useCallback, useMemo } from 'react';

export const PayGroupsModalIntegration: React.FunctionComponent<{
  formik: FormikProps<PayGroupForm>;
  payGroup?: Payroll.GetSetups.ResponseBody[0];
  integratedPayGroups?: Payroll.GetSetups.ResponseBody;
}> = props => {
  const providers = useQuery<Payroll.GetProviders.ResponseBody>(
    ['PlanService.getPayrollProviders'],
    () => {
      return PlanService.getPayrollProviders();
    }
  );

  const handleChange = useCallback(
    async e => {
      const pg =
        props.integratedPayGroups?.find(g => g.id === e.target.value) ?? null;

      if (pg) {
        await props.formik.setFieldValue(
          'integrationMethod180',
          pg.integrationMethod180
        );
        await props.formik.setFieldValue(
          'integrationMethod360',
          pg.integrationMethod360
        );
        await props.formik.setFieldValue(
          'payrollProviderId',
          pg.payrollProviderId
        );
      }
      props.formik.handleChange(e);
      await props.formik.validateForm();
    },
    [props.integratedPayGroups, props.formik]
  );

  const selectedPayGroup = useMemo<Payroll.GetSetups.ResponseBody[0] | null>(
    () =>
      props.integratedPayGroups?.find(
        g => g.id === props.formik.values.integratedPayGroupId
      ) ?? null,
    [props.formik, props.integratedPayGroups]
  );

  const isApiPlan = useMemo(
    () =>
      !!props.integratedPayGroups?.length &&
      props.integratedPayGroups?.every(p => p.integrationMethod180 === 3),
    [props.integratedPayGroups]
  );

  return (
    <Grid container display='flex' flexDirection='column' spacing={4}>
      {!!props.integratedPayGroups?.length && (
        <>
          <Grid item>
            <Typography
              borderBottom={theme => `1px solid ${theme.palette.grey[300]}`}
              color='GrayText'
              py={2}>
              {props.payGroup
                ? `Only pay groups of API-integrated or non-integrated plans should be edited individually. To edit pay groups
                of Concierge/SFTP plans, use the Integration Settings button to apply changes to all existing pay groups.`
                : 'This is an integrated plan. Please select from the existing pay groups to copy integration settings.'}
            </Typography>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <InputLabel id='integrated-pay-group-label' size='small'>
                Pay Group
              </InputLabel>
              <Select
                disabled={!!props.payGroup && !isApiPlan}
                displayEmpty
                error={
                  props.formik.touched.integratedPayGroupId &&
                  Boolean(props.formik.errors.integratedPayGroupId)
                }
                fullWidth
                id='integrated-pay-group'
                label='Pay Group'
                labelId='integrated-pay-group-label'
                name='integratedPayGroupId'
                onBlur={props.formik.handleBlur}
                onChange={handleChange}
                size='small'
                value={props.formik.values.integratedPayGroupId}>
                {props.integratedPayGroups
                  .sort((a, b) =>
                    (a.payGroupName ?? '').localeCompare(b.payGroupName ?? '')
                  )
                  ?.map(p => (
                    <MenuItem
                      key={p.id}
                      sx={{
                        alignItems: 'flex-start',
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                      value={p.id}>
                      {p.payGroupName || 'Pay group name undefined'}
                      <Grid
                        alignItems='flex-end'
                        container
                        display='flex'
                        spacing={1}>
                        <Grid item>
                          <Typography
                            className='integration-type'
                            variant='body2'>
                            {p.payrollProviderName} |
                          </Typography>
                        </Grid>
                        <Grid alignItems='flex-end' display='flex' item p={0}>
                          <CheckCircle color='success' fontSize='small' />
                        </Grid>
                        <Grid alignItems='flex-end' display='flex' item>
                          {p.integrationMethod180 &&
                            !p.integrationMethod360 && (
                              <>
                                <Typography
                                  display='inline'
                                  mr={1}
                                  variant='body2'>
                                  180
                                </Typography>
                                <Typography
                                  alignItems='flex-end'
                                  display='flex'
                                  variant='body2'>
                                  <ReceiveIcon />
                                  {p.integrationMethod180Name}
                                </Typography>
                              </>
                            )}

                          {p.integrationMethod360 && (
                            <>
                              <Typography
                                display='inline'
                                mr={1}
                                variant='body2'>
                                360
                              </Typography>
                              <Typography
                                alignItems='flex-end'
                                display='flex'
                                mr={1}
                                variant='body2'>
                                <ReceiveIcon />
                                {p.integrationMethod180Name}
                              </Typography>
                              <Typography
                                alignItems='flex-end'
                                display='flex'
                                variant='body2'>
                                <SendIcon />
                                {p.integrationMethod360Name}
                              </Typography>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </>
      )}

      {(!!props.formik.values.integratedPayGroupId ||
        !props.integratedPayGroups?.length) && (
        <Grid item>
          <FormControl fullWidth>
            <InputLabel id='payroll-provider-label' size='small'>
              Payroll System
            </InputLabel>
            <Select
              disabled={!!selectedPayGroup?.payrollProviderId}
              displayEmpty
              error={
                props.formik.touched.payrollProviderId &&
                !!props.formik.errors.payrollProviderId
              }
              fullWidth
              id='payroll-provider'
              label='Payroll System'
              labelId='payroll-provider-label'
              name='payrollProviderId'
              onBlur={props.formik.handleBlur}
              onChange={props.formik.handleChange}
              size='small'
              value={props.formik.values.payrollProviderId}>
              {providers.data
                ?.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))
                ?.map(p => (
                  <MenuItem key={p.id} value={p.id}>
                    {p.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      <Grid container item sm={12} spacing={2}>
        {!!selectedPayGroup?.integrationMethod180 && (
          <Grid item sm={6}>
            <FormControl fullWidth>
              <InputLabel id='integration-method-180-label' shrink size='small'>
                Receiving Data Method (180)
              </InputLabel>
              <Select
                disabled
                fullWidth
                id='integration-method-180'
                label='Sending Data Method (180)'
                labelId='integration-method-180-label'
                name='integrationMethod180'
                notched
                size='small'
                value={props.formik.values.integrationMethod180}>
                <MenuItem value={selectedPayGroup.integrationMethod180}>
                  {selectedPayGroup.integrationMethod180Name}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}

        {!!selectedPayGroup?.integrationMethod360 && (
          <Grid item sm={6}>
            <FormControl fullWidth>
              <InputLabel id='integration-method-360-label' shrink size='small'>
                Sending Data Method (360)
              </InputLabel>
              <Select
                disabled
                fullWidth
                id='integration-method-360'
                label='Sending Data Method (360)'
                labelId='integration-method-360-label'
                name='integrationMethod360'
                notched
                size='small'
                value={props.formik.values.integrationMethod360}>
                <MenuItem value={selectedPayGroup.integrationMethod360}>
                  {selectedPayGroup.integrationMethod360Name}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
