import Constants from '@/consts/planDesign.constants';
import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class EarlyRetirementAgeType {
  convertToApiState = Helper.convertValueToApiState;

  options = Constants.earlyRetirementAgeTypeOptions;

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.output = props.earlyRetirementAgeType;
  }
}

export default EarlyRetirementAgeType;
