import Badge from '@/components/badge';
import { CardPlaceholder } from '@/components/card';
import CollapsibleTable, {
  CellComponentProps
} from '@/components/collapsible-table';
import { FormattedWithdrawalDto } from '@/models/WithdrawalsDTO.model';
import { PlanService } from '@/services/Plan.service';
import {
  Box,
  Card,
  CardHeader,
  Link,
  TableCell,
  Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface PlanWithdrawalsProps {
  sponsorPlanId: number;
  setQueryData: React.Dispatch<React.SetStateAction<Record<string, any>>>;
}

const PlanWithdrawalsTableCell: React.FC<CellComponentProps> = (
  props: CellComponentProps
) => {
  const { row, column } = props;

  let field = <Box>{row[column.field] || '--'}</Box>;

  switch (column.field) {
    case 'id':
      field = (
        <Link
          component={RouterLink}
          sx={{ fontSize: theme => theme.spacing(2) }}
          to={`/participants/${row?.participantId}/withdrawals/${row?.id}/details`}>
          {row?.id}
        </Link>
      );
      break;
    case 'participant':
      field = (
        <>
          <Typography variant='body1'>
            {row?.participantFirstName} {row?.participantLastName}
          </Typography>
          <Typography variant='body2'>ID: {row?.participantId}</Typography>
        </>
      );
      break;
    case 'status':
      field = (
        <Badge
          color={row?.status === 'Completed' ? 'success' : 'neutral'}
          size='small'>
          {row?.status}
        </Badge>
      );
      break;
  }

  return (
    <TableCell component='th' scope='row'>
      <Box sx={{ '& .MuiBox-root': { fontSize: theme => theme.spacing(2) } }}>
        {field}
      </Box>
    </TableCell>
  );
};

const PlanWithdrawals = (props: PlanWithdrawalsProps): JSX.Element => {
  const columns = [
    { field: 'id', headerName: 'Withdrawal ID', width: 50 },
    { field: 'participant', headerName: 'Participant', width: 200 },
    { field: 'status', headerName: 'Status', width: 130 },
    { field: 'reason', headerName: 'Type', width: 130 },
    { field: 'amount', headerName: 'Amount', width: 130 },
    { field: 'requestDate', headerName: 'Created Date', width: 130 }
  ];
  // todo: pass query params once endpoint is done
  const withdrawalsQuery = useQuery<FormattedWithdrawalDto[]>(
    ['PlanService.getPlanWithdrawals', props.sponsorPlanId],
    () => {
      return PlanService.getPlanWithdrawals(+props.sponsorPlanId);
    },
    {
      enabled: Boolean(props.sponsorPlanId),
      staleTime: Infinity
    }
  );

  if (withdrawalsQuery.isFetching) {
    return <p>Loading....</p>;
  }

  if (!withdrawalsQuery.isSuccess) {
    return <p>Error</p>;
  }

  if (!withdrawalsQuery.data?.length) {
    return (
      <Card
        elevation={0}
        sx={{
          fontSize: theme => theme.spacing(2.4),
          padding: theme => theme.spacing(2),
          textAlign: 'center'
        }}>
        <Box
          sx={{
            alignContent: 'center',
            display: 'flex',
            justifyContent: 'space-between'
          }}>
          <Typography component='div' id='tableTitle' variant='h5'>
            Withdrawals
          </Typography>
        </Box>
        <CardPlaceholder
          data-testid='no-data-withdrawals-plan'
          subtitle='No data for this plan'
        />
      </Card>
    );
  }

  return (
    <Card className='Plan-Withdrawals_Data' elevation={0}>
      <CardHeader title='Withdrawals' />
      <CollapsibleTable
        backgroundPaperElevation={0}
        cellComponent={PlanWithdrawalsTableCell}
        columns={columns}
        rootPaperElevation={0}
        tableData={withdrawalsQuery.data}
      />
    </Card>
  );
};

export default PlanWithdrawals;
