import ContributionForTable from '@/models/ContributionForTable.model';

import { createContext } from 'react';

type ContextType = {
  context: { selectedContribution: ContributionForTable | undefined };
  setContext: React.Dispatch<
    React.SetStateAction<{
      selectedContribution: ContributionForTable | undefined;
    }>
  >;
};

const defaultContext: ContextType = {
  context: {
    selectedContribution: undefined
  },
  setContext: () => {
    return;
  }
};

export const AchReversalContext = createContext<ContextType>(defaultContext);
