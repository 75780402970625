import Badge from '@/components/badge';
import { CellComponentProps } from '@/components/collapsible-table';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import formatters from '@/utils/Formatters';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TableCell,
  Typography
} from '@mui/material';

import React, { useMemo, useState } from 'react';

const TradeRequestTableCell: React.FunctionComponent<CellComponentProps> = (
  props: CellComponentProps
) => {
  const [open, setOpen] = useState(false);
  const { row, column } = props;

  const isErrorStatus = useMemo(
    () =>
      ['FAILED', 'TIMED-OUT', 'ABORTED', 'ERROR'].some(status =>
        row?.status?.includes(status)
      ),
    [row.status]
  );

  const isWorkflowErrorStatus = useMemo(
    () =>
      ['FAILED', 'TIMED-OUT', 'ABORTED', 'ERROR'].some(status =>
        row?.workflowStatus?.includes(status)
      ),
    [row.workflowStatus]
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let field = <Box>{row[column.field] || '--'}</Box>;
  if (column.field === 'participantName') {
    field = (
      <Grid container direction='column'>
        <Grid item>{row.participantName || '--'}</Grid>
        <Grid item>
          <Typography variant='body2'>
            ID: {row.participantId || '--'}
          </Typography>
        </Grid>
      </Grid>
    );
  }
  if (column.field === 'amount') {
    field = <Box>{formatters.formatDollars(+row[column.field])}</Box>;
  } else if (column.field === 'createdAt' || column.field === 'updatedAt') {
    field = <Box>{formatters.formatFromIsoDate(row[column.field])}</Box>;
  } else if (column.field === 'status') {
    field = (
      <Box sx={{ display: 'flex' }}>
        {row.status ? (
          <Badge color={isErrorStatus ? 'warning' : 'success'} size='small'>
            {formatters.formatContributionStatusHeader(row.status)}
          </Badge>
        ) : (
          <Badge color='neutral' size='small'>
            {EMPTY_FIELD_PLACEHOLDER}
          </Badge>
        )}
        {isErrorStatus && (
          <IconButton onClick={handleClickOpen} size='small'>
            <OpenInNewOutlinedIcon fontSize='inherit' />
          </IconButton>
        )}
      </Box>
    );
  } else if (column.field === 'workflowStatus') {
    field = (
      <>
        {row.workflowStatus ? (
          <Badge
            color={isWorkflowErrorStatus ? 'warning' : 'success'}
            size='small'>
            {formatters.formatContributionStatusHeader(row.workflowStatus)}
          </Badge>
        ) : (
          <Badge color='neutral' size='small'>
            {EMPTY_FIELD_PLACEHOLDER}
          </Badge>
        )}
      </>
    );
  } else if (column.field === 'transactionType') {
    field = <Box>{formatters.displayCase(row[column.field])}</Box>;
  }

  return (
    <>
      <TableCell
        component='th'
        scope='row'
        sx={{ fontSize: theme => theme.spacing(2) }}>
        <Box>{field}</Box>
      </TableCell>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Error messages</DialogTitle>
        <DialogContent>
          <Typography variant='body1'>{row.errorMessage || '--'}</Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TradeRequestTableCell;
