import PrivateRoute from '@/components/private-route';
import FirmDetailsRoute from '@/routes/firms/firm-details/FirmDetails.route';
import LoginRoute from '@/routes/login';
import SearchAccountsRoute from '@/routes/ops/accounts';
import ConversionManagementRoute from '@/routes/ops/conversion/ConversionManagement.route';
import InvestmentDetailRoute from '@/routes/ops/investments';
import FundLineupEditRoute from '@/routes/ops/investments/FundLineupEdit.route';
import ManagedAccountEditRoute from '@/routes/ops/investments/ManagedAccountEdit.route';
import ProgramEditRoute from '@/routes/ops/investments/ProgramEdit.route';
import RiskSeriesEditRoute from '@/routes/ops/investments/RiskSeriesEdit.route';
import TargetSeriesEditRoute from '@/routes/ops/investments/TargetSeriesEdit.route';
import { WhitelabelManagement } from '@/routes/ops/whitelabel/WhitelabelManagement.route';
import ParticipantDetailRoute from '@/routes/participants/participant-detail';
import {
  PlanContributionCorrectionOverviewRoute,
  PlanContributionCorrectionRoute
} from '@/routes/plans/plan-contribution-corrections';
import PlanContributionDetailRoute from '@/routes/plans/plan-contribution-detail';
import {
  ContributionSubmissionConfirmationRoute,
  ContributionSubmissionRoute
} from '@/routes/plans/plan-contribution-submission';
import PlanDetailRoute from '@/routes/plans/plan-detail';
import PlansRoute from '@/routes/plans/plans';
import PooledPlanDetailRoute from '@/routes/plans/pooled-plan-detail';
import TermsOfUseRoute from '@/routes/tpa/terms-of-use';
import ServiceVersionRoute from '@/routes/version/site-version-info';

import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route
} from 'react-router-dom';

import App from './App';
import ErrorDetailPage from './components/error-detail';
import { FeatureLevelPermissions } from './models/UserPermissions.model';
import AdvisorDetails from './routes/advisors/advisor-details';
import FirmsRoute from './routes/firms/firms';
import { AccountsDetailRoute } from './routes/ops/accounts/accounts-detail/AccountsDetail.route';
import SubAccountsDetailRoute from './routes/ops/accounts/sub-accounts-detail/SubAccountsDetail.route';
import { SubaPlansDetailRoute } from './routes/ops/accounts/suba-plans-detail/SubaPlansDetail.route';
import { BulkInvestmentRoute } from './routes/ops/bulk-rebalance';
import SearchAlertsRoute from './routes/ops/conductor';
import AlertsDetailRoute from './routes/ops/conductor/alerts-detail';
import WorkflowsDetailRoute from './routes/ops/conductor/workflows-detail';
import FeeDetailsRoute from './routes/ops/fee-management/FeeDetails.route';
import FeeManagementRoute from './routes/ops/fee-management/FeeManagement.route';
import FeePreviewRoute from './routes/ops/fee-management/FeePreview.route';
import { FraudrankerRoute } from './routes/ops/fraudranker';
import { GlobalRatesRoute } from './routes/ops/global-rates';
import { ModelUpdateRoute } from './routes/ops/model-update';
import ParentAccountOrdersDetailRoute from './routes/ops/order-management/parent-account-orders-detail/ParentAccountOrdersDetail.route';
import { QueueErrorsRoute } from './routes/ops/queue-errors';
import {
  ReconExceptionsDetailRoute,
  SearchReconExceptionsRoute
} from './routes/ops/recon';
import SecuritiesDetailRoute from './routes/ops/securities/securities-detail/SecuritiesDetail.route';
import { SurpasRoute } from './routes/ops/surpas/Surpas.route';
import ActionCenterRoute from './routes/tech/reporting';
import ReportsRoute from './routes/tpa/reporting/Reports.route';
import TpaDetailPage from './routes/tpa/tpa-details/TpaDetailPage.component';
import TpaMainPage from './routes/tpa/tpa-main/TpaMainPage.component';
import UserManagement from './routes/user-management/UserManagement.route';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<App />}>
      <Route
        element={
          <PrivateRoute
            accessRequires={[FeatureLevelPermissions.WRITE_CONTRIBUTION]}
            component={ContributionSubmissionRoute}
          />
        }
        path='/plans/:planId/contributions/:ucid/submission/*'
      />
      <Route
        element={
          <PrivateRoute
            accessRequires={[FeatureLevelPermissions.WRITE_CONTRIBUTION]}
            component={ContributionSubmissionConfirmationRoute}
          />
        }
        path='/plans/:planId/contributions/:ucid/submission/confirmation/*'
      />
      <Route
        element={<PrivateRoute component={FirmDetailsRoute} hideFromTPA />}
        path='/firms/:firmId/*'
      />
      <Route
        element={<PrivateRoute component={() => <FirmsRoute />} hideFromTPA />}
        path='/firms'
      />
      <Route
        element={
          <PrivateRoute
            accessRequires={[FeatureLevelPermissions.READ_TPA]}
            component={() => <ReportsRoute />}
          />
        }
        path='/reports/plan-executive-summary/*'
      />
      <Route
        element={
          <PrivateRoute
            component={() => <Navigate to='/reports/plan-executive-summary' />}
          />
        }
        path='/reports'
      />
      <Route
        element={<PrivateRoute component={ParticipantDetailRoute} />}
        path='/participant/:participantId/withdrawals/:withdrawalId/details/*'
      />
      <Route
        element={<PrivateRoute component={ParticipantDetailRoute} />}
        path='/participants/:participantId/*'
      />
      <Route
        element={
          <PrivateRoute
            accessRequires={[
              FeatureLevelPermissions.WRITE_CONTRIBUTION_CORRECTIONS
            ]}
            component={PlanContributionCorrectionOverviewRoute}
          />
        }
        path='/:planType/:sponsorPlanId/contributions/:ucid/corrections/overview/*'
      />
      <Route
        element={
          <PrivateRoute
            accessRequires={[
              FeatureLevelPermissions.WRITE_CONTRIBUTION_CORRECTIONS
            ]}
            component={PlanContributionCorrectionRoute}
          />
        }
        path='/:planType/:sponsorPlanId/contributions/:ucid/corrections/*'
      />
      <Route
        element={<PrivateRoute component={PlanContributionDetailRoute} />}
        path='/:planType/:sponsorPlanId/contributions/:ucid/*'
      />
      <Route
        element={<PrivateRoute component={PlanDetailRoute} />}
        path='/:planType/:sponsorPlanId/*'
      />
      <Route
        element={
          <PrivateRoute
            accessRequires={[FeatureLevelPermissions.READ_POOLED_PLANS]}
            component={PooledPlanDetailRoute}
          />
        }
        path='/pooled-plans/:planId/*'
      />
      <Route
        element={<PrivateRoute component={ParticipantDetailRoute} />}
        path='/participant/:participantId/rollovers/:rolloverId/*'
      />
      <Route
        element={<PrivateRoute component={ParticipantDetailRoute} />}
        path='/participant/:participantId/loans/:loanId/*'
      />
      <Route
        element={<PrivateRoute component={TpaDetailPage} />}
        path='/tpa/:tpaId/*'
      />
      <Route element={<PrivateRoute component={TpaMainPage} />} path='/tpa' />
      <Route
        element={
          <PrivateRoute
            accessRequiresOneOf={[
              FeatureLevelPermissions.READ_USER_MANAGEMENT,
              FeatureLevelPermissions.READ_USER_MANAGEMENT_VSS_STATE_SAVER
            ]}
            component={UserManagement}
          />
        }
        path='/user-management/:userId/user/*'
      />
      <Route
        element={
          <PrivateRoute
            accessRequires={[FeatureLevelPermissions.READ_USER_MANAGEMENT]}
            component={UserManagement}
          />
        }
        path='/user-management'
      />
      <Route
        element={
          <PrivateRoute
            accessRequires={[FeatureLevelPermissions.READ_ADVISOR]}
            component={AdvisorDetails}
          />
        }
        path='/advisor/:advisorId/*'
      />
      <Route element={<ErrorDetailPage />} path='/entity-not-found' />
      <Route
        element={<PrivateRoute component={PlansRoute} />}
        path='/:planType'
      />
      <Route
        element={<PrivateRoute component={SearchAccountsRoute} />}
        path='/ops/accounts'
      />
      <Route
        element={
          <PrivateRoute
            accessRequires={[FeatureLevelPermissions.WRITE_INVESTMENTS_MODIFY]}
            component={TargetSeriesEditRoute}
          />
        }
        path='/ops/investments/target-series/:id/*'
      />
      <Route
        element={
          <PrivateRoute
            accessRequires={[FeatureLevelPermissions.WRITE_INVESTMENTS_MODIFY]}
            component={RiskSeriesEditRoute}
          />
        }
        path='/ops/investments/risk-series/:id/*'
      />
      <Route
        element={
          <PrivateRoute
            accessRequires={[FeatureLevelPermissions.WRITE_INVESTMENTS_MODIFY]}
            component={FundLineupEditRoute}
          />
        }
        path='/ops/investments/fund-lineup/:id/*'
      />
      <Route
        element={
          <PrivateRoute
            accessRequires={[FeatureLevelPermissions.WRITE_INVESTMENTS_MODIFY]}
            component={ManagedAccountEditRoute}
          />
        }
        path='/ops/investments/managed-account/:id/*'
      />
      <Route
        element={
          <PrivateRoute
            accessRequires={[FeatureLevelPermissions.WRITE_INVESTMENTS_MODIFY]}
            component={ProgramEditRoute}
          />
        }
        path='/ops/investments/program/:id/'
      />
      <Route
        element={
          <PrivateRoute
            accessRequires={[FeatureLevelPermissions.WRITE_INVESTMENTS_MODIFY]}
            component={ProgramEditRoute}
          />
        }
        path='/ops/investments/program/:id/:tab/*'
      />
      <Route
        element={
          <PrivateRoute
            accessRequires={[FeatureLevelPermissions.READ_SUBA_ALERTS]}
            component={AlertsDetailRoute}
          />
        }
        path='/ops/alerts/:alertId/*'
      />
      <Route
        element={
          <PrivateRoute
            accessRequires={[FeatureLevelPermissions.READ_SUBA_ALERTS]}
            component={SearchAlertsRoute}
          />
        }
        path='/ops/alerts'
      />
      <Route
        element={
          <PrivateRoute
            accessRequires={[FeatureLevelPermissions.READ_SUBA_ACCOUNTS]}
            component={ParentAccountOrdersDetailRoute}
          />
        }
        path='/ops/parent-account-orders/:parentAccountOrderId/*'
      />
      <Route
        element={<PrivateRoute component={ReconExceptionsDetailRoute} />}
        path='/ops/recon-exceptions/:exceptionId/*'
      />
      <Route
        element={<PrivateRoute component={SearchReconExceptionsRoute} />}
        path='/ops/recon-exceptions'
      />
      <Route
        element={
          <PrivateRoute
            accessRequires={[FeatureLevelPermissions.WRITE_INVESTMENTS_MODIFY]}
            component={InvestmentDetailRoute}
          />
        }
        path='/ops/investments'
      />
      <Route
        element={
          <PrivateRoute
            accessRequires={[FeatureLevelPermissions.READ_SUBA_ACCOUNTS]}
            component={WorkflowsDetailRoute}
          />
        }
        path='/ops/workflows/:workflowId/*'
      />
      <Route
        element={
          <PrivateRoute
            accessRequiresOneOf={[
              FeatureLevelPermissions.WRITE_BULK_EMAIL_ACTIONS,
              FeatureLevelPermissions.WRITE_BULK_UPLOAD_ACTIONS,
              FeatureLevelPermissions.WRITE_STATE_IRA_EMPLOYER_BULK_UPLOAD
            ]}
            component={ActionCenterRoute}
          />
        }
        path='/ops/action-center/*'
      />
      <Route
        element={<PrivateRoute component={TermsOfUseRoute} />}
        path='/terms-of-use'
      />
      <Route
        element={<PrivateRoute component={ServiceVersionRoute} />}
        path='/version'
      />
      <Route
        element={<PrivateRoute component={BulkInvestmentRoute} />}
        path='/ops/bulk-investment/*'
      />
      <Route
        element={
          <PrivateRoute
            accessRequiresOneOf={[
              FeatureLevelPermissions.WRITE_INVESTMENTS_MODIFY
            ]}
            component={ModelUpdateRoute}
          />
        }
        path='/ops/model-update/*'
      />
      <Route
        element={
          <PrivateRoute
            accessRequiresOneOf={[FeatureLevelPermissions.READ_SUBA_ACCOUNTS]}
            component={SubaPlansDetailRoute}
          />
        }
        path='/ops/accounts/:accountId/plans/:planId/:activeTab?'
      />
      <Route
        element={<PrivateRoute component={SubAccountsDetailRoute} />}
        path='/ops/accounts/:parentAccountId/sub-accounts/:subAccountId/:activeTab?'
      />
      <Route
        element={<PrivateRoute component={AccountsDetailRoute} />}
        path='/ops/accounts/:accountId/:activeTab?'
      />
      <Route
        element={
          <PrivateRoute
            accessRequiresOneOf={[
              FeatureLevelPermissions.READ_INVESTMENTS,
              FeatureLevelPermissions.READ_SUBA_ACCOUNTS,
              FeatureLevelPermissions.READ_SUBA_RECON
            ]}
            component={SecuritiesDetailRoute}
          />
        }
        path='/ops/securities'
      />
      <Route
        element={
          <PrivateRoute
            accessRequiresOneOf={[
              FeatureLevelPermissions.READ_INVESTMENTS,
              FeatureLevelPermissions.READ_SUBA_ACCOUNTS,
              FeatureLevelPermissions.READ_SUBA_RECON
            ]}
            component={SecuritiesDetailRoute}
          />
        }
        path='/ops/securities/:cusipOrSymbol/:activeTab?'
      />
      <Route
        element={<PrivateRoute component={PlanDetailRoute} />}
        path='/:planType/:sponsorPlanId/conversion/v2/*'
      />
      <Route element={<LoginRoute />} path='/' />
      <Route
        element={<PrivateRoute component={QueueErrorsRoute} />}
        path='/ops/queue-error/*'
      />
      <Route
        element={<PrivateRoute component={FeeManagementRoute} />}
        path='/ops/fee-management'
      />
      <Route
        element={<PrivateRoute component={FeePreviewRoute} />}
        path='/ops/fee-management/:billingPeriodId'
      />
      <Route
        element={<PrivateRoute component={FeeDetailsRoute} />}
        path='/ops/fee-management/:billingPeriodId/plan/:planId'
      />
      <Route
        element={<PrivateRoute component={FraudrankerRoute} />}
        path='/ops/fraudranker'
      />
      <Route
        element={<PrivateRoute component={ConversionManagementRoute} />}
        path='/ops/conversion-management'
      />
      <Route
        element={<PrivateRoute component={SurpasRoute} />}
        path='/ops/surpas'
      />
      <Route
        element={<PrivateRoute component={GlobalRatesRoute} />}
        path='/ops/global-rates'
      />
      <Route
        element={
          <PrivateRoute
            accessRequires={[
              FeatureLevelPermissions.READ_WHITELABEL_MANAGEMENT
            ]}
            component={WhitelabelManagement}
          />
        }
        path='/ops/whitelabel-management'
      />
    </Route>
  )
);
