import {
  AuditLogsFilters,
  initialAuditLogsFilters,
  TableTriggeredFilters
} from '@/models/AuditLogs.model';
import { Box, Divider, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

import React, { useCallback, useState } from 'react';

import { PlanAuditLogsFilters } from './PlanAuditLogsFilters.component';
import { PlanAuditLogTable } from './PlanAuditLogsTable.component';

type PlanAuditLogsTabProps = {
  planId: number;
};

const CommonContentWrapper = (props: {
  children: React.ReactNode;
}): React.ReactElement => {
  return (
    <Box
      sx={{
        alignContent: 'flex-start',
        display: 'flex',
        justifyContent: 'flex-start',

        overflow: 'hidden'
      }}>
      {props.children}
    </Box>
  );
};

export const PlanAuditLogsTab: React.FC<PlanAuditLogsTabProps> = props => {
  const [filters, setFilters] = useState<AuditLogsFilters>(
    initialAuditLogsFilters
  );
  const [tableTriggeredFilters, setTableTriggeredFilters] =
    useState<TableTriggeredFilters>({
      eventTypeNames: initialAuditLogsFilters.eventTypeNames,
      search: initialAuditLogsFilters.search
    });
  const [pageNumber, setPageNumber] = useState(0);

  const updateFilters = useCallback(newFilters => {
    setFilters(newFilters);
    setPageNumber(0);
  }, []);

  const triggerFiltersFromTable = useCallback(newFilters => {
    setTableTriggeredFilters(filters => ({ ...filters, ...newFilters }));
    setFilters(filters => ({ ...filters, ...newFilters }));
    setPageNumber(0);
  }, []);

  const updateTableTriggeredFilters = useCallback(newFilters => {
    setTableTriggeredFilters(filters => ({ ...filters, ...newFilters }));
  }, []);

  return (
    <Box border={`1px solid ${grey[300]}`} borderRadius={1}>
      <Box padding={2}>
        <Typography variant='h5'>Audit Log Activities</Typography>
      </Box>
      <Divider />
      <CommonContentWrapper>
        <PlanAuditLogsFilters
          filters={filters}
          planId={props.planId}
          setTableTriggeredFilters={updateTableTriggeredFilters}
          tableTriggeredFilters={tableTriggeredFilters}
          updateFilters={updateFilters}
        />
        <PlanAuditLogTable
          filters={filters}
          pageNumber={pageNumber}
          planId={props.planId}
          setPageNumber={setPageNumber}
          triggerFiltersFromTable={triggerFiltersFromTable}
        />
      </CommonContentWrapper>
    </Box>
  );
};
