import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUserToken } from '@/contexts/UserTokenContext';
import { DocumentDto } from '@/models';
import InMemoryFileDownloadService from '@/services/InMemoryFileDownloadService.service';
import { ModelService } from '@/services/ops/investments/Model.service';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {
  Button,
  CircularProgress,
  IconButton,
  Theme,
  Tooltip
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { last } from 'lodash';
import React, { useState } from 'react';

const DEFAULT_BUTTON_VARIANT = 'filenameOnly' as const;

type ModelFactsheetDownloadButtonProps = {
  modelId: string | number;
  uploadPath: string;
  precache?: boolean;
  variant?:
    | 'iconOnly'
    | 'iconWithFileExtension'
    | 'iconWithFileName'
    | typeof DEFAULT_BUTTON_VARIANT;
};

const getExtension = (uploadPath: string): string =>
  last(uploadPath.split('.')) || 'UNKNOWN';

const useStyles = makeStyles((theme: Theme) => ({
  body: {
    ...theme.typography.caption,
    color: theme.palette.grey[700],
    fontSize: theme.spacing(2),
    height: theme.spacing(6),
    padding: '0',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  defaultButton: {
    textTransform: 'none'
  },
  downloadContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  downloadIcon: {
    '&.Mui-disabled': {
      pointerEvents: 'auto' // enable tooltip for disabled download button
    },
    color: theme.palette.info.main,
    cursor: 'pointer'
  },
  size: {
    fontSize: theme.spacing(2)
  }
}));

type QueryLoadingIndicatorProps = {
  query: Pick<UseQueryResult, 'isFetching'>;
};

const QueryLoadingIndicator: React.FunctionComponent<
  QueryLoadingIndicatorProps
> = (props: QueryLoadingIndicatorProps) => {
  const {
    query: { isFetching }
  } = props;
  return (
    <CircularProgress
      size={10}
      sx={{ visibility: isFetching ? 'block' : 'hidden' }}
    />
  );
};

const ModelFactsheetDownloadButton: React.FunctionComponent<
  ModelFactsheetDownloadButtonProps
> = (props: ModelFactsheetDownloadButtonProps) => {
  const classes = useStyles();
  const {
    modelId,
    uploadPath,
    precache,
    variant = DEFAULT_BUTTON_VARIANT
  } = props;

  const [shouldDownloadContents, setShouldDownloadContents] =
    useState(precache);

  const { userHasValidToken } = useUserToken();

  const { showSnackbar } = useSnackbar();

  const queryEnabled =
    Boolean(modelId) &&
    Boolean(uploadPath) &&
    shouldDownloadContents &&
    userHasValidToken;

  const documentContentsQuery = useQuery<DocumentDto>(
    [`ModelService.downloadModelFactsheet`, uploadPath],
    async () => {
      return ModelService.downloadModelFactsheet(modelId);
    },
    {
      enabled: Boolean(queryEnabled),
      onError: () => {
        showSnackbar({
          message: 'Error downloading document',
          severity: 'error'
        });
      },
      onSuccess: (unknownDto: unknown) => {
        const dto = unknownDto as DocumentDto;
        InMemoryFileDownloadService.triggerFileDownload(
          dto.base64Data,
          dto.originalFileName
        );
      },
      refetchOnWindowFocus: false,
      staleTime: Infinity
    }
  );

  // wrap in useCallback
  const doDownload = () => {
    if (documentContentsQuery.isSuccess) {
      return InMemoryFileDownloadService.triggerFileDownload(
        documentContentsQuery.data.base64Data,
        documentContentsQuery.data.originalFileName
      );
    }

    if (!shouldDownloadContents) {
      return setShouldDownloadContents(true);
    }

    return documentContentsQuery.refetch();
  };

  if (variant === 'filenameOnly') {
    return (
      <Tooltip title={`Click to download ${uploadPath}`}>
        <Button
          className={classes.defaultButton}
          data-testid={`document-download-button-document-id-${modelId}`}
          disabled={documentContentsQuery.isFetching}
          endIcon={<QueryLoadingIndicator query={documentContentsQuery} />}
          key={modelId}
          onClick={doDownload}>
          {uploadPath}
        </Button>
      </Tooltip>
    );
  }

  if (variant === 'iconWithFileExtension' || variant === 'iconWithFileName') {
    return (
      <Tooltip title={`Click to download ${uploadPath}`}>
        <Button
          data-testid={`document-download-button-document-id-${modelId}`}
          disabled={documentContentsQuery.isFetching}
          endIcon={<QueryLoadingIndicator query={documentContentsQuery} />}
          key={modelId}
          onClick={doDownload}
          startIcon={<CloudDownloadIcon />}
          variant='text'>
          {variant === 'iconWithFileExtension'
            ? getExtension(uploadPath)
            : uploadPath}
        </Button>
      </Tooltip>
    );
  }

  return (
    <div
      className={classes.downloadContainer}
      data-testid={`document-download-button-document-id-${modelId}`}
      key={modelId}>
      <Tooltip title={`Click to download ${uploadPath}`}>
        <div>
          {documentContentsQuery.isFetching ? (
            <IconButton disabled size='large'>
              <CloudDownloadIcon />
            </IconButton>
          ) : (
            <IconButton
              className={classes.downloadIcon}
              onClick={doDownload}
              size='large'>
              <CloudDownloadIcon />
            </IconButton>
          )}
          <QueryLoadingIndicator query={documentContentsQuery} />
        </div>
      </Tooltip>
    </div>
  );
};

export default ModelFactsheetDownloadButton;
