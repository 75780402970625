import { DiscretionaryMatchDollarCap } from '@/models/PlanDesign.model';
import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class ContributionMatchFormula {
  convertToApiState = (values: Record<string, any>): Record<string, any> => {
    const formulaDefined = values.formulaDefined === 'Defined Formula';

    return {
      dollarCapId:
        formulaDefined && values.capped
          ? this.dollarCapOptions.find(
              option => option.dollarCap === values.dollarCap
            )?.dollarCapId
          : null,
      formulaDefined: formulaDefined,
      tiers: [
        {
          tierCap: formulaDefined ? parseInt(values.firstTierCap, 10) : null,
          tierIndex: 1,
          tierPercent: formulaDefined
            ? parseInt(values.firstTierPercent, 10)
            : null
        },
        ...(values.tier && formulaDefined
          ? [
              {
                tierCap: parseInt(values.secondTierCap, 10),
                tierIndex: 2,
                tierPercent: parseInt(values.secondTierPercent, 10)
              }
            ]
          : [])
      ]
    };
  };

  convertToUiState = (values: Record<string, any>): string | undefined => {
    if (!Object.values(values)?.length || !values.formulaDefined) {
      return undefined;
    }

    const firstTier = values.tiers?.find(
      (tier: { tierCap: number; tierIndex: number; tierPercent: number }) =>
        tier.tierIndex === 1 && tier.tierCap && tier.tierPercent
    );

    const secondTier = values.tiers?.find(
      (tier: { tierCap: number; tierIndex: number; tierPercent: number }) =>
        tier.tierIndex === 2 && tier.tierCap && tier.tierPercent
    );

    return values.tiers?.length
      ? `${secondTier ? 2 : 1} Tier - ${firstTier.tierPercent}% of ${
          firstTier.tierCap
        }%${
          secondTier
            ? ` + ${secondTier.tierPercent}% of ${firstTier.tierCap}-${secondTier.tierCap}%`
            : ''
        }${values.dollarCap ? `, capped at $${values.dollarCap}` : ''}`
      : undefined;
  };

  dollarCapOptions = [];

  setDollarCapOptions = (options: DiscretionaryMatchDollarCap[]): void => {
    this.dollarCapOptions = options;
  };

  output?: string;

  editModeValues: {
    capped?: boolean;
    dollarCap?: number;
    firstTierCap?: string;
    firstTierPercent?: string;
    formulaDefined?: string;
    secondTierCap?: string;
    secondTierPercent?: string;
    tier?: boolean;
    tiers?: { tierCap: number; tierIndex: number; tierPercent: number }[];
  };

  constructor(props: Record<string, any>) {
    this.output = this.convertToUiState(props.formula ?? {});

    const firstTier = props.formula?.tiers?.find(
      (tier: { tierCap: number; tierIndex: number; tierPercent: number }) =>
        tier.tierIndex === 1 && tier.tierCap && tier.tierPercent
    );
    const secondTier = props.formula?.tiers?.find(
      (tier: { tierCap: number; tierIndex: number; tierPercent: number }) =>
        tier.tierIndex === 2 && tier.tierCap && tier.tierPercent
    );

    this.editModeValues = {
      capped: !!props.formula?.dollarCap,
      dollarCap: props.formula?.dollarCap,
      firstTierCap: firstTier?.tierCap
        ? Helper.addPercentSign(firstTier?.tierCap)
        : undefined,
      firstTierPercent: firstTier?.tierPercent
        ? Helper.addPercentSign(firstTier?.tierPercent)
        : undefined,
      formulaDefined: props.formula?.formulaDefined
        ? 'Defined Formula'
        : 'No Formula Defined',
      secondTierCap: secondTier?.tierCap
        ? Helper.addPercentSign(secondTier?.tierCap)
        : undefined,
      secondTierPercent: secondTier?.tierCap
        ? Helper.addPercentSign(secondTier?.tierPercent)
        : undefined,
      tier: !!secondTier
    };
  }
}

export default ContributionMatchFormula;
