import { LinearProgress, Theme, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';

import React from 'react';

interface CardBarChartProps {
  numerator: number;
  denominator: number;
  title: string;
  description?: string;
  children?: JSX.Element;
}

const useStyles = makeStyles((theme: Theme) => ({
  barColorPrimary: {
    backgroundColor: theme.palette.info.light
  },
  barGraphField: {
    '& > *': {
      lineHeight: 'normal',
      marginBottom: theme.spacing(0),
      marginTop: theme.spacing(1),
      paddingBottom: 0
    },
    marginTop: theme.spacing(1)
  },
  barOverflowPrimary: {
    backgroundColor: theme.palette.error.lightBg
  },
  colorPrimary: {
    backgroundColor: grey[300]
  },
  contributionProgressBar: {
    height: 5,
    transform: 'none'
  },
  heavyCaption: {
    fontWeight: 500
  },
  overflowPrimary: {
    backgroundColor: theme.palette.error.main
  }
}));

const CardBarChart: React.FunctionComponent<CardBarChartProps> = (
  props: CardBarChartProps
) => {
  const classes = useStyles();
  const { numerator, denominator, title, description, children } = props;
  const isOverflowing = numerator > denominator;
  const value = numerator > 0 ? 100 * (numerator / denominator) : 0;
  const calculatedOverflow = isOverflowing ? 100 - (value - 100) : 0;
  const titleKebabCase = `${title.toLocaleLowerCase().replaceAll(' ', '-')}`;

  const standardLinear = {
    barColorPrimary: classes.barColorPrimary,
    colorPrimary: classes.colorPrimary
  };

  const overflowLinear = {
    barColorPrimary: classes.barOverflowPrimary,
    colorPrimary: classes.overflowPrimary
  };

  return (
    <div className={[classes.barGraphField, 'CardBarChart'].join(' ')}>
      <Typography data-testid={`${titleKebabCase}-FieldName`} variant='body2'>
        {title}
      </Typography>
      <Typography data-testid={`${titleKebabCase}-FieldValue`} variant='body1'>
        {description || children}
      </Typography>
      <LinearProgress
        className={classes.contributionProgressBar}
        classes={isOverflowing ? overflowLinear : standardLinear}
        data-testid='card-bar-chart-progress'
        value={isOverflowing ? calculatedOverflow : value}
        variant='determinate'
      />
    </div>
  );
};

export default CardBarChart;
