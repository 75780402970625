import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { PlanService } from '@/services/Plan.service';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import React, { Dispatch } from 'react';

interface DeleteDocDialogProps {
  planId: number;
  docId?: number;
  documentKey: string;
  groupId: number;
  categoryId: number;
  onClose?: Dispatch<React.SetStateAction<boolean>>;
}

const DeleteDocDialog = (props: DeleteDocDialogProps): JSX.Element => {
  const { planId, docId, documentKey, groupId, categoryId, onClose } = props;
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();
  const { closeDialog } = useDialog();

  const deleteFileQuery = useMutation(
    ['PlanService.deleteUploadedDoc'],
    () => {
      if (!docId) {
        throw new Error('no doc id available');
      }

      return PlanService.deleteUploadedDoc(planId, docId, groupId);
    },
    {
      onError: () => {
        closeDialog();
        showSnackbar({
          message: 'Failed!',
          severity: 'error'
        });
      },
      onSuccess: async () => {
        queryClient.invalidateQueries([
          'PlanService.getPlanDocUploadHist',
          planId,
          documentKey
        ]);
        queryClient.invalidateQueries([
          'PlanService.getDocumentCategoryGroupings',
          planId,
          categoryId
        ]);
        queryClient.invalidateQueries([
          'PlanService.getAllPlanDocuments',
          planId
        ]);
        queryClient.invalidateQueries([
          'PlanService.getDocumentCategoryLegacy',
          planId,
          categoryId
        ]);
        showSnackbar({
          message: 'Success! Document deleted',
          severity: 'success'
        });
        if (onClose) {
          onClose(false);
        } else {
          closeDialog();
        }
      }
    }
  );

  return (
    <>
      <DialogTitle>Delete Document</DialogTitle>
      <DialogContent>
        <DialogContentText>
          The file will be deleted! Are you sure?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={deleteFileQuery.isLoading}
          onClick={() => {
            if (onClose) {
              onClose(false);
            } else {
              closeDialog();
            }
          }}>
          CANCEL
        </Button>
        <Button
          disabled={deleteFileQuery.isLoading}
          onClick={() => {
            deleteFileQuery.mutate();
          }}
          type='submit'
          variant='contained'>
          DELETE
        </Button>
      </DialogActions>
    </>
  );
};

export default DeleteDocDialog;
