import {
  Attributes,
  SimpleAttributes,
  SponsorPlanRelationships
} from './ParticipantDTO.model';

export interface ParticipantsData {
  attributes: Attributes;
  type: string;
  id: number;
  relationships: SponsorPlanRelationships;
}

export interface PlanParticipantsDto {
  data: ParticipantsData[];
  links: {
    first: string;
    last: string;
    next?: string;
    self: string;
  };
  meta: {
    count: number;
    planId: number;
  };
}

interface ParticipantsSimpleData {
  attributes: SimpleAttributes;
  type: string;
  id: number;
}

export interface PlanParticipantsSimpleDto {
  data: ParticipantsSimpleData[];
  links: {
    first: string;
    last: string;
    next?: string;
    self: string;
  };
  meta: {
    count: number;
    planId: number;
  };
}

export interface PlanParticipantsInfo {
  participants: any[];
  count: number;
}

export type ParticipantInfo = {
  ageRequirement?: string;
  birthDate?: string;
  createdAt?: string;
  elStatus?: string;
  email?: string;
  entryDate?: string;
  historicalYears?: string;
  id: number;
  name?: string;
  overrideYears?: string;
  preTaxDeferral?: string;
  registeredAt?: string;
  rothDeferral?: string;
  serviceRequirement?: string;
  ssn?: string;
  stateIraAccountStatus?: string;
  stateIraCipStatus?: string;
  stateIraPerEmployerStatus?: string;
  waitingPeriod?: string;
  workEmail?: string;
};

export interface PlanParticipantInfoMapped {
  elStatus: string;
  email: string;
  historicalYears: string | number;
  id: number;
  name: string;
  overrideYears: string | number;
  preTaxDeferral: string;
  registeredAt: string;
  rothDeferral: string;
  ssn: string;
  stateIraCipStatus: string;
  stateIraPerEmployerStatus: string;
  waitingPeriod: string;
  workEmail: string;
}

export interface ParticipantWithWelcomeEmail {
  participantId: number;
  firstName?: string;
  lastName?: string;
  activationDate?: string;
  eligibilityStatus?: string;
  personalEmail?: string;
  workEmail?: string;
  userEmail?: string;
  welcomeEmailStatus?: string;
  welcomeEmailSentAt?: string;
}

export interface PlanEmployeeFilters {
  statuses?: string[];
  preTaxDeferralStart?: string;
  preTaxDeferralEnd?: string;
  rothDeferralStart?: string;
  rothDeferralEnd?: string;
}

export const initialPlanEmployeeFilters = {
  preTaxDeferralEnd: '',
  preTaxDeferralStart: '',
  rothDeferralEnd: '',
  rothDeferralStart: '',
  statuses: []
};
