import { Stack, Typography, useTheme } from '@mui/material';

import { ReactNode } from 'react';

type DotProps = {
  color?: 'info' | 'neutral' | 'success' | 'warning' | 'error' | 'primary';
  children?: ReactNode;
};

const Dot = (
  props: DotProps = {
    color: 'neutral'
  }
) => {
  const theme = useTheme();

  const DotElement = (
    <svg
      fill={
        {
          error: theme.palette.error.main,
          info: theme.palette.grey[800],
          neutral: theme.palette.grey[500],
          primary: theme.palette.primary.dark,
          success: theme.palette.success.dark,
          warning: theme.palette.warning.dark
        }[props.color]
      }
      height='8'
      viewBox='0 0 8 8'
      width='8'
      xmlns='http://www.w3.org/2000/svg'>
      <circle cx='4' cy='4' r='4' />
    </svg>
  );

  return props.children ? (
    <Stack alignItems='center' direction='row' spacing={0.5}>
      {DotElement}
      <Typography variant='caption'>{props.children}</Typography>
    </Stack>
  ) : (
    DotElement
  );
};

export default Dot;
