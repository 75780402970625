import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

export const useVerticalStepperStyles = makeStyles(theme => ({
  root: {
    '& .MuiCollapse-root': {
      padding: '15px 0px 0px 0px'
    },
    '& .MuiStep-root': {
      background: 'white',
      border: grey[300] + ' 1px solid',
      borderRadius: '4px',
      boxShadow: theme.shadows[1],
      padding: '8px 16px'
    },
    '& .MuiStep-root.disabled-step': {
      background: grey[100],
      boxShadow: 'none'
    },
    '& .MuiStepConnector-line': {
      borderLeft: '0px',
      minHeight: '15px'
    },
    '& .MuiStepContent-root': {
      borderLeft: '0px',
      margin: '0px',
      padding: '0px'
    },

    '& .MuiStepIcon-root': {
      color: grey[200]
    },
    '& .MuiStepIcon-root.Mui-completed': {
      color: theme.palette.primary.main
    },

    '& .MuiStepIcon-text': {
      fill: grey[800],
      fontSize: '14px',
      fontWeight: '500'
    },

    '& .MuiStepLabel-label': {
      fontSize: '20px',
      fontWeight: '500'
    },

    backgroundColor: grey[100],

    borderRight: grey[300] + ' 1px solid',

    padding: '16px'
  }
}));
