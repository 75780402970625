import useHasPermissions from '@/components/access-control/useHasPermissions.hook';
import {
  CreateEsaPlanModal,
  CreatePlanModal
} from '@/components/entity-modals/EntityModals.component';
import SimpleTabs, { SimpleTabsProps, TabData } from '@/components/simple-tabs';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { TpaPagePlanStats } from '@/routes/tpa/tpa-details/tpa-page-plan-stats/TpaPagePlanStats.component';
import { userService } from '@/services/User.service';
import { Stack } from '@mui/material';

import React from 'react';

import PlansIndex from './PlansIndex';
import PooledPlansIndex from './PooledPlansIndex';

const PlansRoute: React.FC = () => {
  const pooledPlanPermissions = useHasPermissions({
    requires: [FeatureLevelPermissions.READ_POOLED_PLANS]
  });

  const esaPlanPermissions = useHasPermissions({
    requires: [FeatureLevelPermissions.READ_ESA]
  });

  const isTPA = userService.isTpaUser();
  const tpaId = userService.getTokenData()?.tpaId;

  const registeredTabs: TabData[] = [
    {
      component: (
        <PlansIndex
          createPlanButton={<CreatePlanModal />}
          recordKeepers={[5]}
        />
      ),
      label: 'Workplace',
      path: '/plans'
    },
    {
      component: <PlansIndex recordKeepers={[4]} />,
      label: 'State Savings',
      path: '/vss-plans'
    },
    {
      component: <PlansIndex recordKeepers={[2]} />,
      label: 'Voya Plans',
      path: '/voya-plans'
    },
    ...(pooledPlanPermissions.isAllowed
      ? [
          {
            component: <PooledPlansIndex />,
            label: 'Pooled plans',
            path: '/pooled-plans'
          }
        ]
      : []),
    ...(esaPlanPermissions.isAllowed
      ? [
          {
            component: (
              <PlansIndex
                createPlanButton={<CreateEsaPlanModal />}
                hideProgram
                hideTier
                planTypes={['ESA']}
                recordKeepers={[6]}
              />
            ),

            label: 'Esa plans',
            path: '/esa-plans'
          }
        ]
      : [])
  ].filter(tab =>
    isTPA ? !['State Savings', 'Voya Plans'].includes(tab.label) : true
  );

  const tabs: SimpleTabsProps = {
    tabs: registeredTabs,
    tabsAriaLabel: 'plans-tabs'
  };

  return (
    <>
      <section>
        <Stack spacing={3}>
          {isTPA && <TpaPagePlanStats tpaId={tpaId} />}
          <SimpleTabs {...tabs} />
        </Stack>
      </section>
    </>
  );
};

export default PlansRoute;
