import { useSnackbar } from '@/contexts/SnackBarContext';
import { UsersItemDto } from '@/models/UsersDTO.model';
import UserManagementService from '@/services/UserManagement.service';
import formatters from '@/utils/Formatters';
import { Search } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Modal,
  Paper,
  TextField,
  Theme,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import React, { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import UserManagementEmailResult from './UserManagementEmailResult.component';
import { UserManagementSelectedUser } from './UserManagementSelectedUser.component';

type UserManagementUserProps = {
  userId: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  bold: {
    fontWeight: 'bold'
  },
  clearItem: {
    cursor: 'pointer',
    paddingRight: theme.spacing(1.2)
  },
  emailResult: {
    backgroundColor: '#FFFFFF',
    borderRadius: '0 0 2px 2px',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.24)',
    maxHeight: '380px',
    minHeight: '50px',
    overflow: 'auto',
    position: 'absolute',
    width: theme.spacing(50),
    zIndex: 1
  },
  modal: {
    backgroundColor: '#FAFAFA',
    boxShadow: '24px',
    height: 300,
    left: '50%',
    position: 'absolute',
    top: '45%',
    transform: 'translate(-50%, -50%)',
    width: '60%'
  },
  modalBtn: {
    '&:hover': {
      background: 'transparent'
    }
  },
  modalBtnRoot: {
    paddingRight: theme.spacing(1)
  },
  modalRoot: {
    padding: theme.spacing(3)
  },
  root: {
    backgroundColor: 'rgba(33, 150, 243, 0.04)',
    border: '1px solid #E0E0E0',
    borderRadius: theme.spacing(0.5),
    height: 148,
    marginTop: theme.spacing(5.5),
    width: '100%'
  },
  rootSelected: {
    backgroundColor: 'rgba(33, 150, 243, 0.04)',
    border: '1px solid #E0E0E0',
    borderRadius: theme.spacing(0.5),
    marginTop: theme.spacing(5.5),
    width: '100%'
  },
  searchIcon: {
    paddingLeft: theme.spacing(1.2)
  },
  searchInput: {
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
    height: theme.spacing(5),
    paddingTop: theme.spacing(0.8),
    width: theme.spacing(50)
  },
  warningIcon: {
    color: '#ED6C02',
    height: 15,
    width: 18
  }
}));

export const UserManagementEmail: FC = () => {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { userId } = useParams<UserManagementUserProps>();

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState<UsersItemDto | null>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);

  const checkEmailSearchBarState = (e: React.MouseEvent) => {
    const isClicked = Boolean(
      formatters.checkIfUserEmailSearchResultIsClicked(e as unknown as Event)
    );

    if (isClicked !== isOpen) {
      setIsOpen(isClicked);
    }
  };

  const selectUser = (user: UsersItemDto) => {
    setSelectedUser(user);
  };

  useQuery<UsersItemDto>(
    ['UserManagementService.getUserById', userId],
    async () => UserManagementService.getUserById(+userId),
    {
      enabled: Boolean(userId),
      onError: () => navigate('/user-management', { replace: true }),
      onSuccess: data => selectUser(data),
      staleTime: Infinity
    }
  );

  const handleCancel = () => {
    setOpen(false);
    setSearchTerm('');
  };

  const handleCreateUserMutation = useMutation(
    (email: string) => {
      return UserManagementService.createUser({ email });
    },
    {
      onError: () => {
        showSnackbar({
          message: `Failed! Couldn’t create a user for ${searchTerm}`,
          severity: 'error'
        });
      },
      onSuccess: async () => {
        const createdUser = (
          await UserManagementService.getUsers(searchTerm.trim())
        ).data[0];

        setSelectedUser(createdUser);
        setOpen(false);
        setSearchTerm('');
        showSnackbar({
          message: `Success! Created a user for ${searchTerm}`,
          severity: 'success'
        });
        navigate(`/user-management/${createdUser.userId}/user`);
      }
    }
  );

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries<UsersItemDto>([
      'UserManagementService.getUserById'
    ]);
  }, []);

  useEffect(() => {
    invalidateQuery();
  }, [selectedUser]);

  return (
    <>
      <Grid
        alignItems='center'
        className={selectedUser ? classes.rootSelected : classes.root}
        container
        data-testid='user-management-email-container'
        flexDirection='column'
        justifyContent='center'
        spacing={2}
        wrap='nowrap'>
        <Grid item>
          <Typography color='GrayText' variant='h5'>
            {selectedUser ? 'Selected user' : 'Find a user by email'}
          </Typography>
        </Grid>
        {!selectedUser && (
          <Grid item onMouseDown={checkEmailSearchBarState}>
            <div>
              <TextField
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment
                      className={classes.clearItem}
                      onClick={() => {
                        setSearchTerm('');
                      }}
                      position='end'>
                      <ClearIcon />
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment
                      className={classes.searchIcon}
                      position='start'>
                      <Search />
                    </InputAdornment>
                  )
                }}
                className={classes.searchInput}
                data-testid='user-management-email-input'
                onBlur={() => {
                  setIsFocused(false);
                }}
                onChange={e => setSearchTerm(e.target.value)}
                onFocus={() => {
                  setIsFocused(true);
                }}
                size='small'
                value={searchTerm}
                variant='standard'
              />
              {(isOpen || isFocused) && searchTerm.trim().length > 0 && (
                <Paper
                  className={classes.emailResult}
                  id='user-management-email-result'>
                  <UserManagementEmailResult
                    searchTerm={debouncedSearchTerm}
                    selectUser={selectUser}
                    setOpen={setOpen}
                  />
                </Paper>
              )}
            </div>
          </Grid>
        )}
        {selectedUser && (
          <UserManagementSelectedUser
            refetchUser={invalidateQuery}
            selectedUser={selectedUser}
            setSearchTerm={setSearchTerm}
            setSelectedUser={setSelectedUser}
          />
        )}
        <Modal
          id='user-management-email-result-create-modal'
          onClose={() => setOpen(false)}
          open={open}>
          <Box className={classes.modal}>
            <Grid
              className={classes.modalRoot}
              container
              direction='column'
              spacing={3}>
              <Grid item>
                <Typography variant='h6'>Create new user?</Typography>
              </Grid>
              <Grid item>
                <Grid container direction='column' spacing={1}>
                  <Grid item>
                    <Typography>
                      This will create a new user login with the following
                      email: <span className={classes.bold}>{searchTerm}</span>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      <WarningAmberOutlinedIcon
                        className={classes.warningIcon}
                      />{' '}
                      This <span className={classes.bold}>does not</span>{' '}
                      associate the user to any personas.
                    </Typography>
                    <Typography>
                      <WarningAmberOutlinedIcon
                        className={classes.warningIcon}
                      />{' '}
                      This <span className={classes.bold}>does not</span> send
                      the newly created user an email.
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      Linking the user to a persona and inviting the user
                      through a link or email are available in the next steps.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              className={classes.modalBtnRoot}
              container
              justifyContent='flex-end'
              spacing={1}>
              <Grid item>
                <Button className={classes.modalBtn} onClick={handleCancel}>
                  CANCEL
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={classes.modalBtn}
                  onClick={() =>
                    handleCreateUserMutation.mutateAsync(searchTerm)
                  }>
                  CREATE USER
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Grid>
    </>
  );
};
