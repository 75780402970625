import { useSnackbar } from '@/contexts/SnackBarContext';
import { PayGroupsModalContact } from '@/routes/plans/plan-detail/PayGroups/PayGroupsModalContact.component';
import { PayGroupsModalIntegration } from '@/routes/plans/plan-detail/PayGroups/PayGroupsModalIntegration.component';
import { PayGroupsModalPayment } from '@/routes/plans/plan-detail/PayGroups/PayGroupsModalPayment.component';
import { PayGroupsModalSchedule } from '@/routes/plans/plan-detail/PayGroups/PayGroupsModalSchedule.component';
import { PlanService } from '@/services/Plan.service';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogTitle, Tab } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { Payroll } from '@vestwell-api/scala';

import dayjs from 'dayjs';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';

export type PayGroupForm = {
  alternateFundingSource?: string;
  bankAccountId?: number | string;
  dayOfWeek?: string;
  firstPayrollDate?: string | null;
  frequency: string;
  integrationMethod180?: number;
  integrationMethod180Name?: string;
  integrationMethod360?: number;
  integrationMethod360Name?: string;
  nextPayrollDate?: string | null;
  payAfterWeekend?: boolean;
  payGroupName?: string;
  payrollContactId?: number | string;
  payrollProviderId: number;
  integratedPayGroupId?: any;
};

export const PayGroupsModal: React.FunctionComponent<{
  open: boolean;
  sponsorId: number;
  toggleOpen: () => void;
  payGroup?: Payroll.GetSetups.ResponseBody[0];
  integratedPayGroups?: Payroll.GetSetups.ResponseBody;
  isVoyaPlan?: boolean;
  isStatePlan?: boolean;
  sponsorPlanId: number;
  payGroups?: Payroll.GetSetups.ResponseBody;
}> = props => {
  const [currentTab, setTab] = useState('schedule');
  const nextStep = useCallback(() => {
    const tabs = [
      'schedule',
      'integration',
      ...(!props.isVoyaPlan ? ['payment'] : []),
      ...(!props.isVoyaPlan && !props.isStatePlan ? ['contact'] : [])
    ];
    const nextTab = tabs[tabs.indexOf(currentTab) + 1];

    if (nextTab !== currentTab) {
      setTab(nextTab);
    }
  }, [currentTab, props.isVoyaPlan, props.isStatePlan]);

  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const postPayGroup = useMutation(
    ['PlanService.postPayrollSetup'],
    (values: any) => {
      const { selectedPayGroupIdToIntegrateWith, ...body } = values;
      return PlanService.postPayrollSetup(
        props.sponsorPlanId,
        body,
        selectedPayGroupIdToIntegrateWith
      );
    },
    {
      onError: (error: Error) => {
        showSnackbar({
          autoHideDuration: 20000,
          message: `Encountered issue while creating pay group and/or calling downstream services: ${error?.message}. Please contact LEO for further investigation.`,
          severity: 'error'
        });
      },
      onSuccess: async values => {
        showSnackbar({
          message: `Pay Group ${values.payGroupName} created successfully`,
          severity: 'success'
        });

        await queryClient.invalidateQueries([
          'PlanService.getPayrollSetups',
          props.sponsorPlanId
        ]);
        await queryClient.invalidateQueries([
          'SponsorService.getSponsorBankAccounts',
          props.sponsorId
        ]);
      }
    }
  );

  const updatePayGroup = useMutation(
    ['PlanService.Put'],
    (values: any) => {
      const {
        payrollSetupId,
        payrollSetupIdIsIntegrated,
        selectedPayGroupIdToIntegrateWith,
        ...body
      } = values;
      return PlanService.updatePayrollSetup(
        props.sponsorPlanId,
        payrollSetupId,
        body,
        selectedPayGroupIdToIntegrateWith,
        payrollSetupIdIsIntegrated
      );
    },
    {
      onError: (error: Error) => {
        showSnackbar({
          autoHideDuration: 20000,
          message: `Encountered issue while updating pay group and calling downstream services: ${error?.message}. Please contact LEO for further investigation.`,
          severity: 'error'
        });
      },
      onSuccess: async values => {
        await queryClient.invalidateQueries([
          'PlanService.getPayrollSetups',
          props.sponsorPlanId
        ]);

        queryClient.invalidateQueries([
          'SponsorService.getSponsorBankAccounts',
          props.sponsorId
        ]);

        await queryClient.invalidateQueries([
          'PlanService.getExpectedPayrollDates',
          values.id
        ]);

        showSnackbar({
          message: `Pay Group ${values.payGroupName} updated successfully`,
          severity: 'success'
        });
      }
    }
  );

  const payGroupNames = useMemo(
    () =>
      props.payGroups
        ?.map(payGroup => payGroup.payGroupName)
        ?.filter(
          payGroupName => payGroupName !== props.payGroup?.payGroupName
        ) ?? [],
    [props.payGroup, props.payGroups]
  );

  const formik = useFormik<PayGroupForm>({
    initialValues: {
      alternateFundingSource: props.payGroup?.alternateFundingSource ?? 'null',
      bankAccountId: props.payGroup?.bankAccount?.bankAccountId ?? '',
      dayOfWeek: '',
      firstPayrollDate: props.payGroup?.firstPayrollDate ?? null,
      frequency: props.payGroup?.frequency ?? '',
      integratedPayGroupId: props.payGroup?.payrollIntegrated
        ? props.integratedPayGroups?.find(
            g => g.payGroupName === props.payGroup?.payGroupName
          )?.id ?? ''
        : '',
      integrationMethod180: props.payGroup?.integrationMethod180,
      integrationMethod360: props.payGroup?.integrationMethod360,
      nextPayrollDate: props.payGroup?.nextPayrollDate ?? null,
      payAfterWeekend: !!props.payGroup?.payAfterWeekend,
      payGroupName: props.payGroup?.payGroupName ?? '',
      payrollContactId: props.payGroup?.payrollContact?.id ?? '',
      payrollProviderId: props.payGroup?.payrollProviderId
    },
    onSubmit: async (values, formikHelpers) => {
      const body = {
        alternateFundingSource:
          !!values.alternateFundingSource &&
          values.alternateFundingSource !== 'null'
            ? values.alternateFundingSource
            : undefined,
        bankAccountId: values.bankAccountId ? +values.bankAccountId : undefined,
        firstPayrollDate: dayjs(values.firstPayrollDate).format('YYYY-MM-DD'),
        frequency: values.frequency,
        integrationMethod180: values.integrationMethod180
          ? values.integrationMethod180
          : undefined,
        integrationMethod360: values.integrationMethod360
          ? values.integrationMethod360
          : undefined,
        nextPayrollDate: values.nextPayrollDate
          ? dayjs(values.nextPayrollDate).format('YYYY-MM-DD')
          : undefined,
        payAfterWeekend: !!values.payAfterWeekend,
        payGroupName: values.payGroupName,
        payrollContactId: values.payrollContactId
          ? +values.payrollContactId
          : undefined,
        payrollProviderId: values.payrollProviderId
      };
      const selectedPayGroupIdToIntegrateWith =
        values.integratedPayGroupId &&
        values.integrationMethod180 === 3 &&
        (!props.payGroup || values.integratedPayGroupId !== props.payGroup.id)
          ? values.integratedPayGroupId
          : undefined;

      if (props.payGroup) {
        updatePayGroup.mutate({
          ...body,
          payrollSetupId: props.payGroup.id,
          payrollSetupIdIsIntegrated: props.payGroup.payrollIntegrated,
          selectedPayGroupIdToIntegrateWith
        });
      } else {
        postPayGroup.mutate({
          ...body,
          selectedPayGroupIdToIntegrateWith
        });
      }

      props.toggleOpen();
      formikHelpers.resetForm();
      setTab('schedule');
    },
    validationSchema: yup.object({
      firstPayrollDate: yup.string().required('Required'),
      frequency: yup.string().required('Required'),
      nextPayrollDate: yup.string().when('frequency', (frequency, schema) => {
        if (['SC', 'S'].includes(frequency)) {
          return schema.required();
        }

        return schema;
      }),
      payAfterWeekend: yup.boolean().required('Required'),
      payGroupName: yup
        .string()
        .required('Required')
        .test({
          message: () => 'The pay group name already exists',
          test(value) {
            return !payGroupNames.includes(value);
          }
        }),
      payrollProviderId: yup.number().required('Required'),
      ...(props.isVoyaPlan
        ? {}
        : {
            alternateFundingSource: yup.string().required('Required'),
            bankAccountId: yup
              .string()
              .when(
                'alternateFundingSource',
                (alternateFundingSource, schema) => {
                  if (alternateFundingSource === 'null') {
                    return schema.test(
                      'Bank Account Id when ACH Pull',
                      'Required',
                      (val: string) => !!val
                    );
                  }

                  return schema;
                }
              )
          })
    })
  });

  useEffect(() => {
    if (props.payGroup) {
      if (['W', 'B'].includes(props.payGroup.frequency.toString())) {
        formik.setFieldValue(
          'dayOfWeek',
          dayjs(props.payGroup.firstPayrollDate).day().toString()
        );
      }
    }
  }, [props.payGroup]);

  const lastStep = useMemo(() => {
    if (props.isVoyaPlan) {
      return 'integration';
    }
    if (props.isStatePlan) {
      return 'payment';
    }
    return 'contact';
  }, [props.isVoyaPlan, props.isStatePlan]);

  const validSchedule =
    !!formik.values.frequency &&
    !!formik.values.firstPayrollDate &&
    !!formik.values.payGroupName &&
    !formik.errors.frequency &&
    !formik.errors.firstPayrollDate &&
    !formik.errors.payGroupName;

  const validIntegration =
    !!formik.values.payrollProviderId && !formik.errors.payrollProviderId;

  const validPayment =
    !!formik.values.bankAccountId && !formik.errors.bankAccountId;

  const isTabValid = useMemo(() => {
    return (
      (currentTab === 'schedule' && validSchedule) ||
      (currentTab === 'integration' && validIntegration) ||
      (currentTab === 'payment' && validPayment)
    );
  }, [currentTab, validIntegration, validPayment, validSchedule]);

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      onClose={() => {
        props.toggleOpen();
        formik.resetForm();
        setTab('schedule');
      }}
      open={props.open}
      sx={{
        padding: 2
      }}>
      <DialogTitle
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}>
        {props.payGroup ? 'Edit Pay Group' : 'New Pay Group'}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <TabContext value={currentTab}>
          <TabList
            onChange={(_, val) => setTab(val)}
            sx={{
              borderBottom: theme => `1px solid ${theme.palette.grey[300]}`
            }}>
            <Tab label='schedule' value='schedule' />
            <Tab
              disabled={!validSchedule}
              label='integration'
              value='integration'
            />
            {!props.isVoyaPlan && (
              <Tab
                disabled={!validIntegration}
                label='payment'
                value='payment'
              />
            )}
            {!props.isVoyaPlan && !props.isStatePlan && (
              <Tab
                disabled={props.isVoyaPlan ? !validIntegration : !validPayment}
                label='contact'
                value='contact'
              />
            )}
          </TabList>

          <TabPanel value='schedule'>
            <PayGroupsModalSchedule
              formik={formik}
              payGroup={props.payGroup}
              sponsorPlanId={props.sponsorPlanId}
            />
          </TabPanel>
          <TabPanel value='integration'>
            <PayGroupsModalIntegration
              formik={formik}
              integratedPayGroups={props.integratedPayGroups}
              payGroup={props.payGroup}
            />
          </TabPanel>
          {!props.isVoyaPlan && (
            <TabPanel value='payment'>
              <PayGroupsModalPayment
                formik={formik}
                isStatePlan={props.isStatePlan}
                sponsorId={props.sponsorId}
              />
            </TabPanel>
          )}
          {!props.isVoyaPlan && !props.isStatePlan && (
            <TabPanel value='contact'>
              <PayGroupsModalContact
                formik={formik}
                sponsorId={props.sponsorId}
              />
            </TabPanel>
          )}
          <DialogActions
            sx={{
              pr: 4
            }}>
            <Button
              onClick={() => {
                props.toggleOpen();
                formik.resetForm();
                setTab('schedule');
              }}
              variant='text'>
              Cancel
            </Button>
            {currentTab !== lastStep && (
              <Button
                data-testid='next'
                disabled={!isTabValid}
                onClick={nextStep}
                variant='text'>
                Next
              </Button>
            )}
            {currentTab === lastStep && (
              <Button
                disabled={!formik.isValid}
                type='submit'
                variant='contained'>
                {props.payGroup ? 'Update' : 'Create Pay Group'}
              </Button>
            )}
          </DialogActions>
        </TabContext>
      </form>
    </Dialog>
  );
};
