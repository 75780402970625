/* eslint-disable sort-keys-plus/sort-keys */
import AccessControl from '@/components/access-control/AccessControl.component';
import CardInfoField from '@/components/card-info-field';
import SimpleDropdown from '@/components/simple-dropdown';
import VisibilityToggle from '@/components/visibility-toggle';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { ParticipantCensusDataDto } from '@/models/ParticipantCensusDataDTO.model';
import ParticipantInfo from '@/models/ParticipantInfo.model';
import { UpdateParticipantInfoDto } from '@/models/UpdateParticipantInfoDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import ParticipantService from '@/services/Participant.service';
import { PlanService } from '@/services/Plan.service';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';
import validationFunctions from '@/utils/validations/helpers/InputValidations';
import createParticipantIdentificationValidationSchema from '@/utils/validations/ParticipantIdentificationValidationSchema.schema';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Theme,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { formatSsn } from '@vestwell-frontend/helpers';

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

const dialogDescription =
  'The ability to edit this information exists outside of this portal. There is a chance this information can be changed at a later time. Editing the SSN can have negative downstream impacts. Please follow the proper operational procedures prior to editing.';

interface ParticipantIdentificationInfo {
  firstName: string;
  middleName: string;
  lastName: string;
  ssn: string;
  birthDate: string;
}

type ParticipantDetailRouteProps = {
  participantId: string;
};

export interface ParticipantIdentificationCardProps {
  isStateIRA: boolean;
  isVoyaPlan: boolean;
  participant: ParticipantInfo;
  employmentData: ParticipantCensusDataDto;
  planName: string;
  planType: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  contentBody: {
    paddingBottom: 0
  },
  editBtn: {
    margin: theme.spacing(0, 0, 1, 1)
  },
  ssnField: {
    alignItems: 'flex-end',
    display: 'flex'
  }
}));

const ParticipantIdentificationCard: React.FunctionComponent<
  ParticipantIdentificationCardProps
> = (props: ParticipantIdentificationCardProps) => {
  const classes = useStyles();
  const {
    isStateIRA,
    isVoyaPlan,
    participant,
    employmentData,
    planName,
    planType
  } = props;
  const { openDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const { participantId } = useParams<ParticipantDetailRouteProps>();
  const participantInfo: ParticipantIdentificationInfo = {
    birthDate: participant.birthDate || EMPTY_FIELD_PLACEHOLDER,
    firstName: participant.firstName || EMPTY_FIELD_PLACEHOLDER,
    lastName: participant.lastName || EMPTY_FIELD_PLACEHOLDER,
    middleName: participant.middleName || EMPTY_FIELD_PLACEHOLDER,
    ssn: participant.ssn || ''
  };

  const [isMaskActive, setIsMaskActive] = useState(true);

  const hasWriteSSNPermission = userService.hasPermission(
    FeatureLevelPermissions.WRITE_PERSONAL_IDENTIFICATION_SSN
  );

  const formattedSSN = participantInfo.ssn
    ? formatSsn(participantInfo.ssn)
    : EMPTY_FIELD_PLACEHOLDER;

  const maskedSSN = participantInfo.ssn
    ? formatters.maskSSN(formattedSSN)
    : EMPTY_FIELD_PLACEHOLDER;

  const queryClient = useQueryClient();

  const updateInfo = useMutation(
    (data: UpdateParticipantInfoDto) => {
      return ParticipantService.updateIdentificationInfo(
        Number(participantId),
        participant.sponsorPlanId as number,
        data,
        hasWriteSSNPermission
      );
    },
    {
      onSuccess: async () => {
        await queryClient.refetchQueries([
          'ParticipantService.getParticipantById',
          participant.participantId?.toString()
        ]);
      }
    }
  );

  const { dateOfHire } = employmentData.data;

  const editParticipantInformationModalContentText = (
    <span style={{ display: 'block', textAlign: 'center' }}>
      Are you sure you want to edit information for
      <b>
        {' ' + participant.firstName}, {participant.lastName}
      </b>{' '}
      (-{participant.ssn?.slice(-4)}) from {planName} {planType} plan?
      <br /> <br />
      <b>Any changes made will trigger an email to the participant</b>
    </span>
  );

  const openEditDialog = () => {
    const validationSchema =
      createParticipantIdentificationValidationSchema(dateOfHire);
    const editWarning = [];
    if (!isStateIRA && !isVoyaPlan) {
      editWarning.push({
        contentText: editParticipantInformationModalContentText,
        title: 'Edit Participant Information'
      });
    }

    return openDialog({
      actionButtons: {
        cancelButton: {
          children: 'CANCEL'
        },
        submitButton: {
          children: 'SAVE'
        }
      },
      hideBackOnFirstStep: true,
      preSubmitValidation: async (values, formikHelpers) => {
        try {
          if (
            participant.sponsorPlanId &&
            values?.ssn &&
            values?.ssn?.trim() !== formattedSSN
          ) {
            const checkResult = await PlanService.checkSsnWithinPlan(
              participant.sponsorPlanId,
              formatSsn(values?.ssn)
            );
            if (checkResult?.data) {
              formikHelpers.setFieldError(
                'ssn',
                'This SSN already exists in the plan.'
              );
              return true;
            }
          }
          return false;
        } catch (error) {
          return false;
        }
      },
      onSubmit: async values => {
        try {
          const plainSSN = values.ssn.replaceAll('-', '');
          const updatedParticipanInfo = {} as ParticipantIdentificationInfo;
          const keys = Object.keys(participantInfo);
          keys.forEach(key => {
            const participantKey = key as keyof ParticipantIdentificationInfo;
            if (participantKey === 'ssn') {
              if (hasWriteSSNPermission && participantInfo.ssn !== plainSSN) {
                updatedParticipanInfo.ssn = plainSSN;
              }
            } else if (
              participantInfo[participantKey] !== values[participantKey] &&
              values[participantKey] !== '--'
            ) {
              updatedParticipanInfo[participantKey] =
                values[participantKey].trim();
            }
          });
          const body = {
            data: {
              attributes: {
                ...updatedParticipanInfo,
                isDeceased: values.isDeceased == 'Yes',
                isDisabled: values.isDisabled === 'Yes'
              },
              id: Number(participantId),
              personalEmail: participant.personalEmail,
              type: 'participant',
              userEmail: participant.userEmail,
              workEmail: participant.workEmail,
              shouldSendNotification: !isStateIRA && !isVoyaPlan
            }
          };
          await updateInfo.mutateAsync(body);
          showSnackbar({
            message: 'Identification information has been saved!',
            severity: 'success'
          });
        } catch (err: any) {
          // alex: server throws 409 error if a user tries to add repeated ssn within one plan
          const isRepeatedSSN = err.message.includes('409');
          const noWriteSSNPermission = err.message.includes('403');
          if (isRepeatedSSN) {
            showSnackbar({
              message: 'This SSN already exists in the plan!',
              severity: 'error'
            });
          } else if (noWriteSSNPermission) {
            showSnackbar({
              message: 'Unable to change SSN!',
              severity: 'error'
            });
          } else {
            showSnackbar({
              message: 'Error!',
              severity: 'error'
            });
          }
        }
      },

      steps: [
        ...editWarning,
        {
          dialogDescription,
          fields: {
            firstName: {
              initialValue: participantInfo.firstName,
              label: 'First Name',
              maxLength: 32,
              validate: validationFunctions.validateNameField
            },
            middleName: {
              initialValue: participantInfo.middleName,
              label: 'Middle Name',
              maxLength: 32,
              validate: validationFunctions.validateNameField
            },
            lastName: {
              initialValue: participantInfo.lastName,
              label: 'Last Name',
              maxLength: 32,
              validate: validationFunctions.validateNameField
            },
            ssn: {
              initialValue: formattedSSN,
              isFieldDisabled: !hasWriteSSNPermission,
              label: 'Social Security Number',
              maxLength: 11,
              validate: validationFunctions.validateDateSsnField
            },
            birthDate: {
              initialValue: participantInfo.birthDate,
              label: 'Date of Birth',
              maxLength: 10,
              validate: validationFunctions.validateDateSsnField
            },
            isDeceased: {
              component: (
                <SimpleDropdown
                  fieldId='isDeceased'
                  fieldName='Deceased'
                  fieldValues={['Yes', 'No']}
                />
              ),
              initialValue: participant.isDeceased ? 'Yes' : 'No',
              label: 'Deceased'
            },
            isDisabled: {
              component: (
                <SimpleDropdown
                  fieldId='isDisabled'
                  fieldName='Disabled'
                  fieldValues={['Yes', 'No']}
                />
              ),
              initialValue: participant.isDisabled ? 'Yes' : 'No',
              label: 'Disabled'
            }
          },
          stepValidationSchema: validationSchema,
          title: 'Edit Personal Info'
        }
      ]
    });
  };

  return (
    <div data-testid='participant-identification-card'>
      <Card elevation={0} variant='outlined'>
        <CardContent className={classes.contentBody}>
          <Typography variant='h5'>Identification</Typography>
          <CardInfoField
            fieldName='First Name'
            fieldValue={participantInfo.firstName}
          />
          <CardInfoField
            fieldName='Middle Name'
            fieldValue={participantInfo.middleName}
          />
          <CardInfoField
            fieldName='Last Name'
            fieldValue={participantInfo.lastName}
          />
          <div className={classes.ssnField} data-testid='masked-ssn-field'>
            <CardInfoField
              fieldName='Social Security Number'
              fieldValue={isMaskActive ? maskedSSN : formattedSSN}
              monospace
            />
            <VisibilityToggle
              isMasked={!isMaskActive}
              toggleMask={() => setIsMaskActive(!isMaskActive)}
            />
          </div>
          <CardInfoField
            fieldName='Date of Birth'
            fieldValue={participantInfo.birthDate}
          />
          <CardInfoField
            fieldName='Deceased'
            fieldValue={participant.isDeceased ? 'Yes' : 'No'}
          />
          <CardInfoField
            fieldName='Disabled'
            fieldValue={participant.isDisabled ? 'Yes' : 'No'}
          />
        </CardContent>
        <AccessControl
          requiresOneOf={[
            FeatureLevelPermissions.WRITE_PERSONAL_IDENTIFICATION,
            FeatureLevelPermissions.WRITE_PERSONAL_IDENTIFICATION_SSN
          ]}>
          <CardActions>
            <Button
              className={classes.editBtn}
              data-testid='edit-identification-info-btn'
              onClick={openEditDialog}>
              EDIT
            </Button>
          </CardActions>
        </AccessControl>
      </Card>
    </div>
  );
};

export default ParticipantIdentificationCard;
