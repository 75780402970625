import { AgGridValidation, columnTypes } from '@/components/ag-grid';
import { AgGridApi } from '@/components/ag-grid/AgGridTypes';
import LinearLoading from '@/components/linear-loading';
import { Search, Warning } from '@mui/icons-material';
import {
  Alert,
  Button,
  Card,
  InputAdornment,
  Switch,
  TextField,
  Theme,
  Typography,
  useTheme
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { isEqual, uniq, without } from 'lodash';
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useToggle } from 'react-use';
import * as yup from 'yup';

import { GridRow } from '../types';
import FileUpload from './FileUpload.component';
import { getSpreadSheetColumns } from './SpreadSheetColumns';

const useStyles = makeStyles((theme: Theme) => ({
  errorSection: {
    color: theme.palette.error.main,
    display: 'flex'
  },
  filterSection: {
    display: 'flex'
  },
  filterSwitch: {
    alignItems: 'center',
    display: 'flex'
  },
  searchInput: {
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.info.main,
    color: theme.palette.info.main,
    width: 500
  },
  title: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    display: 'flex',
    justifyContent: 'space-between'
  },
  titleText: {
    marginBottom: 20,
    marginLeft: 16,
    marginTop: 20
  },
  toolsSection: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 16,
    marginRight: 16,
    marginTop: 10
  },
  upload: {
    alignItems: 'center',
    display: 'flex',
    marginRight: 10
  }
}));

type SpreadSheetProps = {
  initialRows: GridRow[];
  setInitialRows: (value: any) => void;
  rows: GridRow[];
  setRows: (value: any) => void;
  errors: Record<string, string[]>;
  setErrors: Dispatch<SetStateAction<Record<string, string>[]>>;
  info: {
    modifiedRowIndexes: number[];
  };
  setInfo: (value: any) => void;
  planId: string | number;
  sponsorId: string | number;
  ucid: string;
};

const SpreadSheet: FC<SpreadSheetProps> = props => {
  const theme = useTheme();
  const classes = useStyles();
  const gridRef = useRef<AgGridApi>(null);

  const [search, setSearch] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [isAlertDisplayed, toggleAlertDisplay] = useToggle(true);
  const [isFilterZeros, toggleFilterZeros] = useToggle(true);

  const searchRegExp = useMemo(
    () =>
      new RegExp(
        search
          .split(' ')
          ?.map(item => `(?=.*${item?.toLowerCase() ?? ''})`)
          ?.join('')
      ),
    [search]
  );

  const errorsLength = useMemo(
    () => Object.keys(props.errors ?? {})?.length,
    [props.errors]
  );

  const validations = useMemo(() => {
    return yup.array().of(
      yup.object().shape({
        at: yup.number().min(0, 'Value cannot be negative.'),
        em: yup.number().min(0, 'Value cannot be negative.'),
        ln: yup.number().min(0, 'Value cannot be negative.'),
        ps: yup.number().min(0, 'Value cannot be negative.'),
        qc: yup.number().min(0, 'Value cannot be negative.'),
        qm: yup.number().min(0, 'Value cannot be negative.'),
        rc: yup.number().min(0, 'Value cannot be negative.'),
        sd: yup.number().min(0, 'Value cannot be negative.'),
        sh: yup.number().min(0, 'Value cannot be negative.')
      })
    );
  }, []);

  const columns = useMemo(() => getSpreadSheetColumns(theme), [theme]);

  const onRowModified = useCallback(
    updatedRow => {
      const initialRow = props.initialRows?.find(
        row => row.uuid === updatedRow.uuid
      );

      if (!isEqual(initialRow, updatedRow)) {
        return props.setInfo((prev: { modifiedRowIndexes: number[] }) => ({
          ...prev,
          modifiedRowIndexes: uniq([
            ...prev.modifiedRowIndexes,
            updatedRow.uuid
          ])
        }));
      }

      return props.setInfo((prev: { modifiedRowIndexes: number[] }) => ({
        ...prev,
        modifiedRowIndexes: without(prev.modifiedRowIndexes, updatedRow.uuid)
      }));
    },
    [props]
  );

  const onCellChanged = useCallback(
    (index, field, value) => {
      const arr = [...props.rows];
      const updatedRow = {
        ...arr[index],
        [field]: value && value > 0 ? value : 0
      };
      arr[index] = updatedRow;

      props.setRows(arr);
      onRowModified(updatedRow);
    },
    [props, onRowModified]
  );

  const onError = useCallback(errors => props.setErrors(errors), [props]);

  const onSwitchChanged = useCallback(
    () => toggleFilterZeros(),
    [toggleFilterZeros]
  );

  const onSearchChange = useCallback(
    e => setSearch(e.target.value),
    [setSearch]
  );

  const onReverseClicked = useCallback(() => {
    gridRef.current?.api?.getSelectedNodes()?.forEach(row => {
      const updatedRow = {
        ...row.data,
        at: 0,
        em: 0,
        ln: 0,
        ps: 0,
        qc: 0,
        qm: 0,
        rc: 0,
        sd: 0,
        sh: 0
      };

      props.setRows((prev: GridRow[]) => {
        const arr = [...prev];
        arr[row.data?.uuid] = updatedRow;
        return arr;
      });
      onRowModified(updatedRow);
    });

    gridRef.current?.api?.deselectAll();
  }, [onRowModified, props]);

  const onSelectionChanged = useCallback(
    params => setSelectedRows(params.api.getSelectedNodes()),
    []
  );

  const isExternalFilterPresent = useCallback(
    () => !!search || !isFilterZeros,
    [search, isFilterZeros]
  );

  const filterName = useCallback(
    (firstName, lastName) =>
      searchRegExp.test(`${firstName} ${lastName}`.toLowerCase()),
    [searchRegExp]
  );

  const filterTotal = useCallback(
    ({ rc, sd, at, sh, em, ps, ln, qc, qm }) =>
      [rc, sd, at, sh, em, ps, ln, qc, qm].some(value => value != 0),
    []
  );

  const doesExternalFilterPass = useCallback(
    node => {
      if (!!search && !isFilterZeros) {
        return (
          filterName(node.data?.firstName, node.data?.lastName) &&
          filterTotal(node.data)
        );
      }

      if (search) {
        return filterName(node.data?.firstName, node.data?.lastName);
      }

      if (!isFilterZeros) {
        return filterTotal(node.data);
      }

      return true;
    },
    [search, isFilterZeros, filterName, filterTotal]
  );

  useEffect(() => {
    gridRef.current?.api?.deselectAll();
    gridRef.current?.api?.onFilterChanged();
  }, [search, isFilterZeros]);

  useEffect(() => {
    gridRef.current?.api?.refreshCells({ force: true });
  }, [props.initialRows]);

  return (
    <Card elevation={0} variant='outlined'>
      <div className={classes.title}>
        <Typography className={classes.titleText} variant='h5'>
          Submitted Contribution
        </Typography>
        <div className={classes.upload}>
          <FileUpload
            planId={props.planId}
            setInfo={props.setInfo}
            setInitialRows={props.setInitialRows}
            setRows={props.setRows}
            sponsorId={props.sponsorId}
            ucid={props.ucid}
          />
        </div>
      </div>

      <div className={classes.toolsSection}>
        <div className={classes.filterSection}>
          <TextField
            InputProps={{
              'aria-placeholder': 'Search employees',
              onChange: onSearchChange,
              placeholder: 'Search employees',
              startAdornment: (
                <InputAdornment position='start'>
                  <Search />
                </InputAdornment>
              )
            }}
            className={classes.searchInput}
            data-testid='correction-search-input'
            id='search-employees'
            variant='outlined'
          />
          <div className={classes.filterSwitch}>
            <Switch
              data-testid='correction-zeroContributions-toggle'
              defaultChecked
              onChange={onSwitchChanged}
            />
            <Typography color='GrayText'>
              Employees with $0 contribution
            </Typography>
          </div>
        </div>
        {!!errorsLength && (
          <div className={classes.errorSection}>
            <Warning />
            <Typography ml={1}>{errorsLength} error</Typography>
          </div>
        )}
      </div>

      {isAlertDisplayed && (
        <Alert
          onClose={toggleAlertDisplay}
          severity='info'
          style={{
            margin: 16
          }}
          sx={{
            bgcolor: 'lightgray',
            color: 'black'
          }}>
          Click a field to make a correction.
        </Alert>
      )}

      {!!selectedRows.length && (
        <Alert
          action={
            <Button
              data-testid='correction-reverseAllSubAccounts-button'
              onClick={onReverseClicked}
              size='small'>
              Reverse All Sub accounts
            </Button>
          }
          severity='info'>
          {selectedRows.length} selected
        </Alert>
      )}
      {props.rows ? (
        <AgGridValidation
          columnDefs={columns}
          columnTypes={columnTypes}
          data-testid='correction-grid'
          doesExternalFilterPass={doesExternalFilterPass}
          initialRows={props.initialRows}
          isExternalFilterPresent={isExternalFilterPresent}
          onCellChanged={onCellChanged}
          onError={onError}
          onSelectionChanged={onSelectionChanged}
          pagination
          paginationPageSize={20}
          primaryKey='uuid'
          ref={gridRef}
          rowData={props.rows}
          rowSelection='multiple'
          singleClickEdit
          suppressRowClickSelection
          validations={validations}
        />
      ) : (
        <LinearLoading />
      )}
    </Card>
  );
};

export default SpreadSheet;
