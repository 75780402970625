export enum DocumentCategoryIds {
  CONTRIBUTIONS_UPLOAD = 7,
  SUPPLEMENTAL_CONTRIBUTION = 9
}

export const YEAR_END_DATES = [
  '--',
  '12/31',
  '11/30',
  '10/31',
  '9/30',
  '8/31',
  '7/31',
  '6/30',
  '5/31',
  '4/30',
  '3/31',
  '2/28',
  '1/31'
];

export const COMPANY_ENTITY_TYPES = [
  { option: '--', value: '--' },
  { option: 'C-Corporation', value: 'ccorp' },
  { option: 'Corporation (Inc)', value: 'inc' },
  { option: 'LLC', value: 'llc' },
  {
    option: 'Limited Liability Company (LLC)-Taxed as C-Corp',
    value: 'llc-ccorp'
  },
  {
    option: 'Limited Liability Company (LLC)-Taxed as Partnership',
    value: 'llc-p'
  },
  {
    option: 'Limited Liability Company (LLC)-Taxed as S-Corp',
    value: 'llc-scorp'
  },
  { option: 'Limited Liability Partnership', value: 'llp' },
  { option: 'Limited Partnership', value: 'lp' },
  { option: 'Not-for-profit', value: 'np' },
  { option: 'Partnership', value: 'p' },
  {
    option: 'Professional Services Corporation',
    value: 'psc'
  },
  { option: 'S-Corp', value: 'scorp' },
  { option: 'Sole Proprietorship', value: 'sp' },
  { option: '501(c)(3)', value: '501(c)(3)' },
  { option: 'Cooperative', value: 'cooperative' },
  { option: 'Other', value: 'other' }
];

export const NULLABLE_DROP_DOWN_OPTIONS = [
  { option: '--', value: 'null' },
  { option: 'Yes', value: 'true' },
  { option: 'No', value: 'false' }
];
