import Constants from '@/consts/planDesign.constants';

class MatchFrequency {
  disabled: boolean;

  options = Constants.frequencyOptions;

  mapValueToOption: Record<string, string> = {
    annually: 'Annually',
    'per pay-period': 'Per pay-period',
    quarterly: 'Quarterly'
  };

  mapOptionToValue: Record<string, string> = {
    Annually: 'annually',
    'Per pay-period': 'per pay-period',
    Quarterly: 'quarterly'
  };

  convertToApiState = (value: string | undefined): string | undefined => {
    if (!value) {
      return undefined;
    }

    return this.mapOptionToValue[value] ?? null;
  };

  output: string | undefined;

  setDisabled = (value: boolean): void => {
    this.disabled = value;
  };

  constructor(props: Record<string, any>) {
    this.disabled = props.safeHarborMatchType === 'N/A - No Safe Harbor';

    this.output = this.disabled
      ? null
      : this.mapValueToOption[props.matchFrequency];
  }
}

export default MatchFrequency;
