import AccessControl from '@/components/access-control/AccessControl.component';
import CardInfoField from '@/components/card-info-field';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { PlanV2Dto } from '@/models';
import ParticipantInfo, {
  ParticipantRegistrationInfo
} from '@/models/ParticipantInfo.model';
import { UpdateParticipantRegistrationInfo } from '@/models/UpdateParticipantRegistrationInfo.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import ParticipantService from '@/services/Participant.service';
import { PlanService } from '@/services/Plan.service';
import formatters from '@/utils/Formatters';
import {
  Button,
  Card,
  CardContent,
  Divider,
  Theme,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Suspense, useEffect, useState } from 'react';

import { ParticipantAccountDistributionHold } from './ParticipantAccountDistributionHold';
import { ParticipantAccountWarningBanner } from './ParticipantAccountWarningBanner';

interface ParticipantAccountStatusInfo {
  participantId: string | number;
  createdAt: string;
  updatedAt: string;
  isRegistered: string;
  registeredOn: string;
}

interface ParticipantRegistrationStatusInfo {
  registrationLocked: string;
  participantId: number;
}
interface ParticipantAccountStatusInfoCardProps {
  participantId: string;
  participant: ParticipantInfo;
  sponsorPlanId?: number;
  isStateIRA: boolean;
  isVoyaPlan: boolean;
  registration: ParticipantRegistrationInfo;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginTop: theme.spacing(1)
  },
  contentBody: {
    paddingBottom: 0
  }
}));

const ParticipantAccountStatusCard: React.FunctionComponent<
  ParticipantAccountStatusInfoCardProps
> = (props: ParticipantAccountStatusInfoCardProps) => {
  const classes = useStyles();
  const { participant, isStateIRA, isVoyaPlan, registration, participantId } =
    props;
  const { showSnackbar } = useSnackbar();
  const [isRegistrationLocked, setIsRegistrationLocked] = useState(
    registration.verificationAttemptsLeft === 0
  );
  const [disabled, setDisabled] = useState(true);

  const participantInfo: ParticipantAccountStatusInfo = {
    createdAt: participant.createdAt || EMPTY_FIELD_PLACEHOLDER,
    isRegistered: participant.registeredOn ? 'Registered' : 'Not Registered',
    participantId: participant.participantId || EMPTY_FIELD_PLACEHOLDER,
    registeredOn: participant.registeredOn || EMPTY_FIELD_PLACEHOLDER,
    updatedAt: participant.updatedAt || EMPTY_FIELD_PLACEHOLDER
  };

  const participantRegistrationInfo: ParticipantRegistrationStatusInfo = {
    participantId: registration.participantId,
    registrationLocked: isRegistrationLocked ? 'Yes' : 'No'
  };

  const formattedUpdatedDate = formatters.formatFromIsoDateCustom(
    participantInfo.updatedAt,
    'MM/DD/YYYY [at] h:mm A'
  );

  const formattedCreatedDate = formatters.formatFromIsoDateCustom(
    participantInfo.createdAt,
    'MM/DD/YYYY'
  );

  const formattedRegistrationDate = participant.registeredOn
    ? formatters.formatFromIsoDateCustom(participant.registeredOn, 'MM/DD/YYYY')
    : EMPTY_FIELD_PLACEHOLDER;

  useEffect(() => {
    if (isRegistrationLocked === true) {
      setDisabled(false);
    } else if (isRegistrationLocked === false) {
      setDisabled(true);
    }
  }, [isRegistrationLocked, disabled]);

  const queryClient = useQueryClient();

  const sponsorPlanQuery = useQuery<PlanV2Dto>(
    ['PlanService.getPlanById', props.sponsorPlanId],
    () => PlanService.getPlanById(props.sponsorPlanId),
    {
      enabled: Boolean(props.sponsorPlanId),
      staleTime: Infinity
    }
  );

  const updateVerificationAttempts = useMutation(
    (data: UpdateParticipantRegistrationInfo) => {
      return ParticipantService.updateRegistrationStatus(
        Number(participant.participantId),
        data
      );
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Failed!',
          severity: 'error'
        });
      },
      onSuccess: () => {
        setIsRegistrationLocked(false);
        queryClient.invalidateQueries<ParticipantRegistrationInfo>([
          'ParticipantService.getRegistrationStatus',
          participant.participantId
        ]);
        showSnackbar({
          message: 'Success! Saver has been unlocked.',
          severity: 'success'
        });
      }
    }
  );

  const handleClick = async () => {
    const body = {
      ...registration,
      verificationAttemptsLeft: 3
    };
    await updateVerificationAttempts.mutateAsync(body);
  };

  return (
    <>
      <div data-testid='participant-account-status-card'>
        {!isStateIRA && !isVoyaPlan && (
          <Card elevation={0} variant='outlined'>
            <CardContent className={classes.contentBody}>
              <Typography variant='h5'>Account Status</Typography>
              <CardInfoField
                fieldName='Creation Date'
                fieldValue={formattedCreatedDate}
              />
              <CardInfoField
                fieldName='Last Updated At'
                fieldValue={formattedUpdatedDate}
              />
              <CardInfoField
                fieldName='Registration Status'
                fieldValue={participantInfo.isRegistered}
              />
              <CardInfoField
                fieldName='Registration Date'
                fieldValue={formattedRegistrationDate}
              />
              <CardInfoField
                fieldName='Registration Locked'
                fieldValue={participantRegistrationInfo.registrationLocked}
              />
              <CardInfoField
                fieldName='Custodian Account Number'
                fieldValue={
                  sponsorPlanQuery.data?.data?.attributes
                    ?.custodianAccountNumber
                }
              />
              <AccessControl
                requires={[
                  FeatureLevelPermissions.WRITE_PERSONAL_ACCOUNT_STATUS
                ]}>
                <Button
                  className={classes.button}
                  data-testid='unlock-registration-button'
                  disabled={disabled}
                  onClick={handleClick}>
                  Unlock Registration
                </Button>
              </AccessControl>
              <Divider flexItem orientation='horizontal' />
              <Suspense fallback={<p>Loading....</p>}>
                <ParticipantAccountWarningBanner
                  participantId={participantId}
                />
              </Suspense>
              <Divider flexItem orientation='horizontal' />
              <ParticipantAccountDistributionHold
                participant={participant}
                participantId={participantId}
              />
            </CardContent>
          </Card>
        )}
        {isVoyaPlan && (
          <Card elevation={0} variant='outlined'>
            <CardContent className={classes.contentBody}>
              <Typography variant='h5'>Account Status</Typography>
              <CardInfoField
                fieldName='Creation Date'
                fieldValue={formattedCreatedDate}
              />
              <CardInfoField
                fieldName='Last Updated At'
                fieldValue={formattedUpdatedDate}
              />
            </CardContent>
          </Card>
        )}
      </div>
    </>
  );
};

export default ParticipantAccountStatusCard;
