import { FormControl, FormHelperText, TextField } from '@mui/material';

import { Field, ErrorMessage as FormikErrorMessage, FormikProps } from 'formik';
import React from 'react';

export type SimpleMaskedInputProps = {
  fieldName: string;
  fieldId: string;
  placeholder?: string;
  inputTransform: (raw: string) => string | null;
  required?: boolean;
};

const SimpleMaskedInput: React.FunctionComponent<SimpleMaskedInputProps> = (
  props: SimpleMaskedInputProps
) => {
  const {
    fieldId,
    fieldName,
    inputTransform,
    placeholder = '',
    required
  } = props;

  return (
    <FormControl fullWidth>
      <Field name={fieldId}>
        {(fieldProps: {
          form: FormikProps<{ [key: typeof fieldId]: string }>;
        }) => {
          const isError = Boolean(
            fieldProps.form.touched[fieldId] && fieldProps.form.errors[fieldId]
          );

          const value = fieldProps.form.values[fieldId] || '';

          return (
            <>
              <TextField
                error={isError}
                label={fieldName}
                name={fieldId}
                onBlur={() => fieldProps.form.setFieldTouched(fieldId, true)}
                onChange={(event: any) => {
                  const transformed = inputTransform(event.target?.value || '');
                  if (transformed == null) return;

                  if (transformed === '') {
                    fieldProps.form.setFieldValue(fieldId, null);
                  } else {
                    fieldProps.form.setFieldValue(fieldId, transformed);
                  }
                }}
                placeholder={placeholder}
                required={required}
                value={value}
              />
              <FormikErrorMessage name={fieldId}>
                {msg => <FormHelperText error>{msg}</FormHelperText>}
              </FormikErrorMessage>
            </>
          );
        }}
      </Field>
    </FormControl>
  );
};

export default SimpleMaskedInput;
