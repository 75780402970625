import CheckBoxForm from '@/components/checkbox-form/CheckBoxForm.component';
import { FieldDisplayController } from '@/components/form-dialog/FormDialog.component';
import { PatternFormatForm } from '@/components/pattern-format-form/PatternFormatForm.component';
import SimpleDropdown from '@/components/simple-dropdown';
import STATE_CODES from '@/consts/states.constants';
import { Divider } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';

import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { get } from 'lodash';

type BeneficiaryFormProps = {
  useAddSchema?: boolean;
};

export const BeneficiaryForm: React.FC<BeneficiaryFormProps> = (
  props: BeneficiaryFormProps
) => {
  const pathName = props.useAddSchema ? `beneficiaryAttributes.` : '';
  return (
    <>
      <Grid2 xs={6}>
        <SimpleDropdown
          data-testid='beneficiary-form-beneficiaryType-dropdown'
          fieldId={`${pathName}beneficiaryType`}
          fieldName='Recipient Type'
          fieldOptions={[
            { option: 'Person', value: 'person' },
            { option: 'Trust', value: 'trust' },
            { option: 'Organization', value: 'organization' }
          ]}
        />
      </Grid2>
      <FieldDisplayController
        showWhen={values =>
          get(values, `${pathName}beneficiaryType`) === 'person'
        }>
        <Grid2 xs={6}>
          <SimpleDropdown
            data-testid='beneficiary-form-relationship-dropdown'
            fieldId={`${pathName}relationship`}
            fieldName='Relationship'
            fieldOptions={[
              { option: 'Parent', value: 'parent' },
              { option: 'Spouse', value: 'spouse' },
              { option: 'Child', value: 'child' },
              { option: 'Other', value: 'other' }
            ]}
          />
        </Grid2>
      </FieldDisplayController>
      <FieldDisplayController
        showWhen={values =>
          get(values, `${pathName}beneficiaryType`) !== 'person'
        }>
        <Grid2 xs={6}></Grid2>
      </FieldDisplayController>
      <Grid2 xs={6}>
        <CheckBoxForm
          data-testid='beneficiary-form-isPrimary-checkbox'
          label='Primary Beneficiary'
          name={`${pathName}isPrimary`}
        />
      </Grid2>
      <Grid2 xs={12}>
        <Divider />
      </Grid2>
      <FieldDisplayController
        showWhen={values =>
          get(values, `${pathName}beneficiaryType`) === 'person'
        }>
        <Grid2 xs={6}>
          <Field
            component={TextField}
            fullWidth
            inputProps={{ 'data-testid': 'beneficiary-form-firstName-input' }}
            label='First Name'
            name={`${pathName}firstName`}
          />
        </Grid2>
        <Grid2 xs={6}>
          <Field
            component={TextField}
            fullWidth
            inputProps={{ 'data-testid': 'beneficiary-form-lastName-input' }}
            label='Last Name'
            name={`${pathName}lastName`}
          />
        </Grid2>
      </FieldDisplayController>
      <FieldDisplayController
        showWhen={values =>
          get(values, `${pathName}beneficiaryType`) === 'trust'
        }>
        <Grid2 xs={12}>
          <Field
            component={TextField}
            fullWidth
            inputProps={{ 'data-testid': 'beneficiary-form-trustName-input' }}
            label='Trust Name'
            name={`${pathName}trustName`}
          />
        </Grid2>
      </FieldDisplayController>
      <FieldDisplayController
        showWhen={values =>
          get(values, `${pathName}beneficiaryType`) === 'organization'
        }>
        <Grid2 xs={12}>
          <Field
            component={TextField}
            fullWidth
            inputProps={{
              'data-testid': 'beneficiary-form-businessName-input'
            }}
            label='Business Name'
            name={`${pathName}businessName`}
          />
        </Grid2>
      </FieldDisplayController>
      <FieldDisplayController
        showWhen={values =>
          get(values, `${pathName}beneficiaryType`) === 'person'
        }>
        <Grid2 xs={6}>
          <PatternFormatForm
            label='SSN'
            name={`${pathName}ssn`}
            patternFormatProps={{
              'data-testid': 'beneficiary-form-ssn-input',
              format: '###-##-####'
            }}
            sx={{ width: '100%' }}
          />
        </Grid2>
      </FieldDisplayController>
      <FieldDisplayController
        showWhen={values =>
          get(values, `${pathName}beneficiaryType`) === 'trust'
        }>
        <Grid2 xs={6}>
          <Field
            component={TextField}
            fullWidth
            inputProps={{
              'data-testid': 'beneficiary-form-trustId-input'
            }}
            label='Tax ID'
            name={`${pathName}trustId`}
          />
        </Grid2>
      </FieldDisplayController>
      <FieldDisplayController
        showWhen={values =>
          get(values, `${pathName}beneficiaryType`) === 'organization'
        }>
        <Grid2 xs={6}>
          <Field
            component={TextField}
            fullWidth
            inputProps={{
              'data-testid': 'beneficiary-form-ein-input'
            }}
            label='EIN'
            name={`${pathName}ein`}
          />
        </Grid2>
      </FieldDisplayController>
      <FieldDisplayController
        showWhen={values =>
          get(values, `${pathName}beneficiaryType`) === 'trust'
        }>
        <Grid2 xs={6}>
          <Field
            component={TextField}
            fullWidth
            inputProps={{
              'data-testid':
                'beneficiary-form-holdingFinancialInstitution-input'
            }}
            label='Holding Financial Institution'
            name={`${pathName}holdingFinancialInstitution`}
          />
        </Grid2>
      </FieldDisplayController>
      <FieldDisplayController
        showWhen={values =>
          get(values, `${pathName}beneficiaryType`) !== 'trust'
        }>
        <Grid2 xs={6}></Grid2>
      </FieldDisplayController>
      <Grid2 xs={6}>
        <PatternFormatForm
          label='Phone Number'
          name={`${pathName}phoneNumber`}
          patternFormatProps={{
            'data-testid': 'beneficiary-form-phoneNumber-input',
            format: '###-###-####',
            mask: '#',
            returnFormattedValue: true
          }}
          sx={{ width: '100%' }}
        />
      </Grid2>
      <Grid2 xs={12}>
        <Field
          component={TextField}
          fullWidth
          inputProps={{
            'data-testid': 'beneficiary-form-email-input'
          }}
          label='Email'
          name={`${pathName}email`}
        />
      </Grid2>
      <Grid2 xs={12}>
        <Field
          component={TextField}
          fullWidth
          inputProps={{
            'data-testid': 'beneficiary-form-address1-input'
          }}
          label='Address Line 1'
          name={`${pathName}address1`}
        />
      </Grid2>
      <Grid2 xs={12}>
        <Field
          component={TextField}
          fullWidth
          inputProps={{
            'data-testid': 'beneficiary-form-address2-input'
          }}
          label='Address Line 2'
          name={`${pathName}address2`}
        />
      </Grid2>
      <Grid2 xs={4}>
        <Field
          component={TextField}
          fullWidth
          inputProps={{
            'data-testid': 'beneficiary-form-city-input'
          }}
          label='City'
          name={`${pathName}city`}
        />
      </Grid2>
      <Grid2 xs={3}>
        <SimpleDropdown
          data-testid='beneficiary-form-state-dropdown'
          fieldId={`${pathName}state`}
          fieldName='State'
          fieldValues={STATE_CODES}
        />
      </Grid2>
      <Grid2 xs={5}>
        <Field
          component={TextField}
          fullWidth
          inputProps={{
            'data-testid': 'beneficiary-form-zip-input'
          }}
          label='Zip Code'
          name={`${pathName}zip`}
        />
      </Grid2>
    </>
  );
};
