import { CardPlaceholder } from '@/components/card';
import { useParticipantSuspiciousActivity } from '@/hooks/useParticipantSuspiciousActivity.hook';
import { FormattedWithdrawalDto } from '@/models/WithdrawalsDTO.model';
import ParticipantService from '@/services/Participant.service';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Link,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { Link as RouterLink } from 'react-router-dom';
import { useToggle } from 'react-use';

import WithdrawalCustomRequestDialog from './WithdrawalCustomRequest/WithdrawalCustomRequestDialog.component';

interface WithdrawalRequestsProps {
  participantId: string;
  isVestwellSubaccounting: boolean;
}

const StyledTableCellBody = styled(TableCell)(({ theme }) => ({
  ...theme.typography.caption,
  color: theme.palette.grey[700],
  fontSize: theme.spacing(2),
  height: theme.spacing(7.8),
  padding: '0',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2)
}));

const WithdrawalRequests = (props: WithdrawalRequestsProps): JSX.Element => {
  const { participantId, isVestwellSubaccounting } = props;

  const [isCustomRequestOpen, toggleCustomRequestOpen] = useToggle(false);

  const headCells = [
    { id: 'id', label: 'ID' },
    {
      cellWidth: { width: '22%' },
      id: 'status',
      label: 'Status'
    },
    {
      cellWidth: { width: '32%' },
      id: 'withdrawalReason',
      label: 'Type'
    },
    { id: 'requestDate', label: 'Request Date' },
    { cellWidth: { width: '15%' }, id: 'amount', label: 'Amount' }
  ];

  const customReqDialog = (
    <WithdrawalCustomRequestDialog
      isOpen={isCustomRequestOpen}
      onClose={() => toggleCustomRequestOpen(false)}
      participantId={+participantId}
    />
  );

  const WithdrawalsQuery = useQuery<FormattedWithdrawalDto[]>(
    ['ParticipantService.getWithdrawals', participantId],
    () => {
      return ParticipantService.getWithdrawals(+participantId);
    },
    {
      enabled: Boolean(participantId)
    }
  );

  const suspiciousQuery = useParticipantSuspiciousActivity(participantId);
  const suspiciousActivity = suspiciousQuery.data?.hasSuspiciousActivity;

  if (WithdrawalsQuery.isFetching) {
    return <p>Loading....</p>;
  }

  if (!WithdrawalsQuery.isSuccess) {
    return <p>Error</p>;
  }

  if (!WithdrawalsQuery.data?.length) {
    return (
      <Card
        sx={{
          fontSize: theme => theme.spacing(2.4),
          padding: theme => theme.spacing(2),
          textAlign: 'center'
        }}>
        <Box
          sx={{
            alignContent: 'center',
            display: 'flex',
            justifyContent: 'space-between'
          }}>
          <Typography component='div' id='tableTitle' variant='h5'>
            Withdrawals
          </Typography>
          <Box sx={{ maxWidth: 400 }}>
            {isVestwellSubaccounting && (
              <>
                <LoadingButton
                  data-testid='open-custom-request'
                  disabled={suspiciousActivity}
                  onClick={() => {
                    toggleCustomRequestOpen(true);
                  }}>
                  CUSTOM REQUEST
                </LoadingButton>

                {customReqDialog}
              </>
            )}
          </Box>
        </Box>
        <CardPlaceholder
          data-testid='no-data-withdrawals-participant'
          subtitle='No data for this participant'
        />
      </Card>
    );
  }

  return (
    <Box>
      <Paper
        elevation={0}
        sx={{ marginBottom: theme => theme.spacing(2), width: '100%' }}
        variant='outlined'>
        <Box
          sx={{
            alignContent: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            padding: theme => `${theme.spacing(2.5)} ${theme.spacing(2)}`
          }}>
          <Typography component='div' id='tableTitle' variant='h5'>
            Withdrawals
          </Typography>
          {isVestwellSubaccounting && (
            <>
              <LoadingButton
                data-testid='open-custom-request'
                disabled={
                  WithdrawalsQuery.data.some(i =>
                    ['Processing', 'Pending'].includes(i.status)
                  ) || suspiciousActivity
                }
                onClick={() => {
                  toggleCustomRequestOpen(true);
                }}>
                CUSTOM REQUEST
              </LoadingButton>

              {customReqDialog}
            </>
          )}
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map(headCell => (
                  <TableCell
                    key={headCell.id}
                    sx={{
                      fontSize: theme => theme.spacing(2),
                      fontWeight: theme => theme.typography.fontWeightBold,
                      ...headCell.cellWidth
                    }}>
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {WithdrawalsQuery.data?.map(
                (withdrawal: FormattedWithdrawalDto) => {
                  return (
                    <TableRow key={withdrawal.id}>
                      <StyledTableCellBody>
                        <Link
                          component={RouterLink}
                          to={`/participant/${participantId}/withdrawals/${withdrawal.id}/details`}>
                          {withdrawal.id}
                        </Link>
                      </StyledTableCellBody>

                      <StyledTableCellBody>
                        {withdrawal.status}
                      </StyledTableCellBody>
                      <StyledTableCellBody>
                        {withdrawal.reason}
                      </StyledTableCellBody>
                      <StyledTableCellBody>
                        {withdrawal.dateRequested}
                      </StyledTableCellBody>
                      <StyledTableCellBody>
                        {withdrawal.amount}
                      </StyledTableCellBody>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default WithdrawalRequests;
