import Badge from '@/components/badge';
import { Unstable_Grid2 as Grid, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

import dayjs from 'dayjs';
import React, { FC, useMemo } from 'react';

type EmployeeInfoCardProps = {
  firstName: string;
  lastName: string;
  ssn: string;
  id: number;
  dob: string;
  createdAt: string;
  isOriginal?: boolean;
};

export const EmployeeInfoCard: FC<EmployeeInfoCardProps> = props => {
  const rows = useMemo(
    () => [
      { label: 'First Name', value: props.firstName },
      { label: 'Last Name', value: props.lastName },
      { label: 'SSN', value: `-${props.ssn.slice(-4)}` },
      { label: 'Participant ID', value: props.id },
      { label: 'Date of Birth', value: props.dob },
      {
        label: 'Created At',
        value: dayjs(props.createdAt).format('MM/DD/YYYY HH:mm')
      }
    ],
    [props]
  );

  return (
    <Grid
      border={1.5}
      borderColor={grey[200]}
      borderRadius={1}
      container
      data-testid={`employee-info-card-${props.id}`}
      flexDirection='column'
      padding={2}
      rowSpacing={2}
      xs={5.5}>
      <Grid alignItems='center' columnGap={2} container direction='row'>
        <Grid data-testid='account'>
          <Typography sx={{ color: 'black', fontWeight: 'bold' }}>
            Account {props.isOriginal ? 1 : 2}
          </Typography>
        </Grid>
        <Grid>
          <Badge
            color={props.isOriginal ? 'success' : 'warning'}
            data-testid='badge'>
            {props.isOriginal ? 'Active' : 'To be removed'}
          </Badge>
        </Grid>
      </Grid>

      <Grid container rowSpacing={1}>
        {rows.map(r => (
          <Grid columnSpacing={2} container key={r.label} xs={12}>
            <Grid xs={6}>
              <Typography
                data-testid={`${r.label.toLocaleLowerCase().replaceAll(' ', '-')}-FieldName`}
                variant='body2'>
                {r.label}
              </Typography>
            </Grid>
            <Grid xs={6}>
              <Typography
                color={theme => theme.palette.text.primary}
                data-testid={`${r.label.toLocaleLowerCase().replaceAll(' ', '-')}-FieldValue`}
                variant='body2'>
                {r.value}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

EmployeeInfoCard.defaultProps = {
  isOriginal: false
};

EmployeeInfoCard.displayName = 'EmployeeInfoCard';
