import { UNVERIFIED_PRIOR_PROVIDER_ID } from '@/consts/plan.constants';
import PlanDesignConstants from '@/consts/planDesign.constants';
import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

import * as yup from 'yup';

const makeDesignTabFieldsSchema = (args?: {
  isDesignDefaults?: boolean;
}): yup.AnyObjectSchema => {
  const validateDesignTabFields = yup.object({
    allowPermissibleWithdrawal: yup.string().test({
      test(value) {
        if (args?.isDesignDefaults) return true;

        return (!this.parent.autoEnrollAmount ||
          this.parent.autoEnrollAmount === 'None') &&
          value
          ? this.createError({
              message: 'Allow Permissible Withdrawal should not be provided',
              path: this.path
            })
          : true;
      }
    }),
    allowResidencePurchaseExtension: yup.string().test({
      test(value) {
        if (args?.isDesignDefaults) return true;

        return this.parent.partnerSystemName !== 'Voya' &&
          this.parent.allowLoans === 'Yes' &&
          !value
          ? this.createError({
              message: 'Required',
              path: this.path
            })
          : true;
      }
    }),
    autoEnrollEffectiveDate: yup.string().test({
      test(value) {
        if (args?.isDesignDefaults) {
          return value === 'Invalid Date'
            ? this.createError({
                message: 'Invalid Date',
                path: this.path
              })
            : true;
        }

        return this.parent.autoEnrollAmount &&
          this.parent.autoEnrollAmount !== 'None' &&
          (!value || value === 'Invalid Date')
          ? this.createError({
              message: 'Required',
              path: this.path
            })
          : true;
      }
    }),
    autoEscalateMaximum: yup.string().test({
      test(value) {
        if (args?.isDesignDefaults) return true;

        if (
          this.parent.autoEscalateAmount &&
          this.parent.autoEscalateAmount !== 'None' &&
          !value
        ) {
          return this.createError({
            message: 'Required',
            path: this.path
          });
        }

        if (
          this.parent.autoEscalateAmount &&
          this.parent.autoEscalateAmount !== 'None' &&
          parseInt(this.parent.autoEscalateAmount, 10) >
            parseInt(value as string, 10)
        ) {
          return this.createError({
            message: 'Should be greater than or equal to Auto Escalate Amount',
            path: this.path
          });
        }

        return true;
      }
    }),
    dollarCap: yup.string().test({
      test(value) {
        if (args?.isDesignDefaults) return true;

        return this.parent.formulaDefined === 'Defined Formula' &&
          this.parent.capped &&
          !value
          ? this.createError({
              message: 'Required',
              path: this.path
            })
          : true;
      }
    }),
    earlyRetirementAge: yup
      .number()
      .typeError('Should be a number')
      .test({
        test(value) {
          if (args?.isDesignDefaults) return true;

          return ['Age Only', 'Later of Age and Service'].includes(
            this.parent.earlyRetirementAgeType
          ) && !value
            ? this.createError({
                message: 'Required',
                path: this.path
              })
            : true;
        }
      }),
    firstTierCap: yup.string().test({
      test(value) {
        if (args?.isDesignDefaults) return true;

        return this.parent.formulaDefined === 'Defined Formula' && !value
          ? this.createError({
              message: 'Required',
              path: this.path
            })
          : true;
      }
    }),
    firstTierPercent: yup.string().test({
      test(value) {
        if (args?.isDesignDefaults) return true;

        return this.parent.formulaDefined === 'Defined Formula' && !value
          ? this.createError({
              message: 'Required',
              path: this.path
            })
          : true;
      }
    }),
    hasPriorLoans: args?.isDesignDefaults
      ? yup.string().optional()
      : yup.string().required('Required'),
    hoursOfServiceRequired: yup
      .number()
      .typeError('Should be a number')
      .min(1, 'Should be greater than or equal to 1')
      .max(1000, 'Should be less or equal to 1000')
      .test({
        test(value) {
          if (args?.isDesignDefaults) return true;
          if (this.parent.vestingMethod === 'Hours of Service') {
            if (!value) {
              return this.createError({
                message: 'Required',
                path: this.path
              });
            }
          }

          return true;
        }
      }),
    hoursRequired: yup
      .number()
      .typeError('Should be a number')
      .test({
        test(value) {
          if (args?.isDesignDefaults) return true;
          if (
            this.parent.serviceCalculationType &&
            ![
              'No Requirement',
              'Specified Months - Elapsed Time',
              'State IRA Eligibility'
            ].includes(this.parent.serviceCalculationType)
          ) {
            if (value === undefined) {
              return this.createError({
                message: 'Required',
                path: this.path
              });
            }

            if (value < 0) {
              return this.createError({
                message: 'Should be greater than or equal to 0',
                path: this.path
              });
            }

            if (value > 1000) {
              return this.createError({
                message: 'Should be less or equal to 1000',
                path: this.path
              });
            }
          }

          return true;
        }
      }),
    inServiceSpecifiedAge: yup
      .number()
      .typeError('Should be a number')
      .test({
        test(value) {
          if (args?.isDesignDefaults) return true;

          return this.parent.allowInServiceAtSpecifiedAge &&
            this.parent.allowInServiceAtSpecifiedAge !== 'No' &&
            !value
            ? this.createError({
                message: 'Required',
                path: this.path
              })
            : true;
        }
      }),
    matchFrequency: yup
      .string()
      .test({
        test(value) {
          if (args?.isDesignDefaults) return true;

          if (
            !value &&
            this.parent.safeHarborMatchType &&
            this.parent.safeHarborMatchType !== 'N/A - No Safe Harbor'
          ) {
            return this.createError({
              message: 'Required',
              path: this.path
            });
          }

          return true;
        }
      })
      .nullable(),
    maxAmount: yup
      .number()
      .typeError('Should be a number')
      .test({
        test(value) {
          if (args?.isDesignDefaults) return true;

          if (Number(value) && Number(value) > 7000) {
            return this.createError({
              message: 'Should be less than or equal to 7000',
              path: this.path
            });
          }

          return true;
        }
      }),
    maxOutstandingLoans: yup
      .number()
      .typeError('Should be a number')
      .test({
        test(value) {
          if (args?.isDesignDefaults) return true;

          if (
            this.parent.partnerSystemName !== 'Voya' &&
            this.parent.allowLoans === 'Yes'
          ) {
            if (!value) {
              return this.createError({
                message: 'Required',
                path: this.path
              });
            }

            if (value < 1) {
              return this.createError({
                message: 'Should be greater than or equal to 1',
                path: this.path
              });
            }

            if (value > 9) {
              return this.createError({
                message: 'Should be less or equal to 9',
                path: this.path
              });
            }
          }

          return true;
        }
      }),
    maxYearsResidencePurchase: yup
      .number()
      .typeError('Should be a number')
      .test({
        test(value) {
          if (args?.isDesignDefaults) return true;

          if (this.parent.allowResidencePurchaseExtension === 'Yes') {
            if (!value) {
              return this.createError({
                message: 'Required',
                path: this.path
              });
            }

            if (value < 6) {
              return this.createError({
                message: 'Should be greater than or equal to 6',
                path: this.path
              });
            }
          }

          return true;
        }
      }),
    nonSafeHarborMatchType: yup.string().when('allowNonSafeHarborMatch', {
      is: value => value === 'Allow',
      otherwise: schema => schema.optional(),
      then: schema => schema.required('Required')
    }),
    normalRetirementAge: yup.number().typeError('Should be a number'),
    permissibleTimeframe: yup
      .number()
      .typeError('Should be a number')
      .integer('Should be an integer')
      .min(
        30,
        'Should be greater than or equal to 30 and less than or equal to 90'
      )
      .max(
        90,
        'Should be greater than or equal to 30 and less than or equal to 90'
      )
      .test({
        test(value) {
          if (args?.isDesignDefaults) return true;

          return this.parent.autoEnrollAmount &&
            this.parent.autoEnrollAmount !== 'None' &&
            this.parent.allowPermissibleWithdrawal &&
            this.parent.allowPermissibleWithdrawal === 'Allow' &&
            !value
            ? this.createError({
                message: 'Required',
                path: this.path
              })
            : true;
        }
      }),
    profitSharingStrategy: yup.string().test({
      test: function (value) {
        const validValues = PlanDesignConstants.profitSharingTypes
          .filter(type => type.value)
          .map(type => type.option);

        if (
          this.parent.allowEmployerProfitSharing === 'Allow' &&
          !validValues.includes(value)
        ) {
          return this.createError({
            message: 'Required',
            path: this.path
          });
        }

        return true;
      }
    }),
    rolloverMin: yup
      .number()
      .typeError('Should be a number')
      .test({
        test(value) {
          if (args?.isDesignDefaults) return true;

          if (Number(value) && Number(value) > 1000) {
            return this.createError({
              message: 'Should be less or equal to 1000',
              path: this.path
            });
          }

          return true;
        }
      }),
    secondTierCap: yup.string().test({
      test(value) {
        if (args?.isDesignDefaults) return true;

        return this.parent.formulaDefined === 'Defined Formula' &&
          this.parent.tier &&
          !value
          ? this.createError({
              message: 'Required',
              path: this.path
            })
          : true;
      }
    }),
    secondTierPercent: yup.string().test({
      test(value) {
        if (args?.isDesignDefaults) return true;

        return this.parent.formulaDefined === 'Defined Formula' &&
          this.parent.tier &&
          !value
          ? this.createError({
              message: 'Required',
              path: this.path
            })
          : true;
      }
    })
  });

  return validateDesignTabFields;
};

const makeGeneralInfoTabFieldsSchema = (args?: {
  isDesignDefaults?: boolean;
}): Record<string, yup.AnyObjectSchema> => {
  const validateGeneralInfoStatus = yup.object({
    adminStatus: args?.isDesignDefaults
      ? yup.string().optional()
      : yup.string().required('Required'),
    deconvertedToMep: yup.string().test({
      test(value) {
        if (args?.isDesignDefaults) return true;

        return this.parent.adminStatus === 'Deconverted' && !value
          ? this.createError({
              message: 'Required',
              path: this.path
            })
          : true;
      }
    }),
    lastPayrollDate: yup
      .string()
      .test({
        test(value) {
          if (args?.isDesignDefaults) {
            return value === 'Invalid Date'
              ? this.createError({
                  message: 'Invalid Date',
                  path: this.path
                })
              : true;
          }

          return [
            'Terminated',
            'Termination Requested',
            'Deconverted'
          ].includes(this.parent.adminStatus) &&
            (!value || value === 'Invalid Date')
            ? this.createError({
                message: 'Required',
                path: this.path
              })
            : true;
        }
      })
      .nullable(),
    offboardingBlackoutEndDate: yup
      .string()
      .test({
        test(value) {
          if (args?.isDesignDefaults) {
            return value === 'Invalid Date'
              ? this.createError({
                  message: 'Invalid Date',
                  path: this.path
                })
              : true;
          }
          return this.parent.adminStatus === 'Deconverted' &&
            (!value || value === 'Invalid Date')
            ? this.createError({
                message: 'Required',
                path: this.path
              })
            : true;
        }
      })
      .nullable(),
    offboardingBlackoutStartDate: yup
      .string()
      .test({
        test(value) {
          if (args?.isDesignDefaults) {
            return value === 'Invalid Date'
              ? this.createError({
                  message: 'Invalid Date',
                  path: this.path
                })
              : true;
          }

          return this.parent.adminStatus === 'Deconverted' &&
            (!value || value === 'Invalid Date')
            ? this.createError({
                message: 'Required',
                path: this.path
              })
            : true;
        }
      })
      .nullable(),
    terminationDate: yup
      .string()
      .test({
        test(value) {
          if (args?.isDesignDefaults) {
            return value === 'Invalid Date'
              ? this.createError({
                  message: 'Invalid Date',
                  path: this.path
                })
              : true;
          }

          return this.parent.adminStatus === 'Terminated' &&
            (!value || value === 'Invalid Date')
            ? this.createError({
                message: 'Required',
                path: this.path
              })
            : true;
        }
      })
      .nullable()
  });

  const validateGeneralInfoRecordkeeperAndCustodian = yup.object({
    opportunityId: args?.isDesignDefaults
      ? yup.string().optional()
      : yup.string().required('Required'),
    priorProvider: yup.string().test({
      test(value) {
        if (args?.isDesignDefaults) return true;

        return this.parent.conversionType ===
          Helper.getConversionTypeName('true') && !value
          ? this.createError({
              message: 'Required',
              path: this.path
            })
          : true;
      }
    }),
    priorProviderUnverified: yup.string().test({
      test(value) {
        if (args?.isDesignDefaults) return true;
        return this.parent.conversionType ===
          Helper.getConversionTypeName('true') &&
          this.parent.priorProviderId === UNVERIFIED_PRIOR_PROVIDER_ID &&
          !value
          ? this.createError({
              message: 'Required',
              path: this.path
            })
          : true;
      }
    })
  });
  const validateGeneralInfoService = yup.object({});
  const validateGeneralInfoFee = yup.object({});

  return {
    fee: validateGeneralInfoFee,
    recordkeeperAndCustodian: validateGeneralInfoRecordkeeperAndCustodian,
    service: validateGeneralInfoService,
    status: validateGeneralInfoStatus
  };
};

export default {
  makeDesignTabFieldsSchema,
  makeGeneralInfoTabFieldsSchema
};
