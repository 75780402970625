import { Order } from '@/components/collapsible-table';
import ApiService from '@/services/Api.service';

export interface RiskSeriesSearchData {
  riskSeriesId: number;
  name: string;
  description?: string;
  createdAt?: string;
  programCount?: number;
}
type RiskSeriesListDto = {
  data: RiskSeriesSearchData[];
  meta: { count: number };
};

class RiskSeriesService {
  static async getRiskSeriesPage(
    pageNumber: number,
    pageSize: number,
    orderBy: string,
    order: Order,
    name?: string,
    id?: number
  ): Promise<RiskSeriesListDto> {
    const sort = (order === 'asc' ? '' : '-').concat(orderBy);
    return ApiService.getJson('/investments/risk-series', {
      id,
      name,
      pageNumber,
      pageSize,
      sort
    }) as unknown as RiskSeriesListDto;
  }
}

export type { RiskSeriesListDto };
export { RiskSeriesService };
