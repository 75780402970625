import { DocumentDto, TpaListDto } from '@/models';
import { OrganizationUser } from '@/models/OrganizationUser.model';
import { PlanV2ListDto } from '@/models/PlanV2DTO.model';
import { CreatedTpaDto, TpaDto } from '@/models/TpaDTO.model';
import ApiService from '@/services/Api.service';

import { isNumber } from 'lodash';

import { TokenData } from './User.service';

class TpaService {
  static async getAll(): Promise<TpaListDto> {
    const dto: TpaListDto = await ApiService.getJson<TpaListDto>('/tpas/list');

    if (!dto) {
      throw new Error('invalid JSON received from backend for tpas list');
    }

    return dto;
  }

  static async getTpaReportingDocuments(params: {
    documentKeyPrefix?: string;
    pageNumber?: number;
    pageSize?: number;
    entityType: string;
    fileNameContains?: string;
  }): Promise<{
    data: {
      documentKey: string;
      uploadHistoryId: number;
      fileName: number;
      createdAt: string;
      planName: string;
      planId: number;
    }[];
    meta: { count: number };
  }> {
    return ApiService.getJson(`/tpas/documents`, params);
  }

  static async createTpaOrganization(newTpaOrganization: {
    name: string;
  }): Promise<CreatedTpaDto> {
    const dto = await ApiService.postJson<{ name: string }, CreatedTpaDto>(
      '/authz-api/organization/setup',
      newTpaOrganization
    );

    if (!dto) {
      throw new Error('invalid JSON received from backend for creating TPA');
    }

    return dto;
  }

  static async getSelf(): Promise<TpaDto | TpaListDto> {
    return ApiService.getJson<TpaDto>('/tpas/self');
  }

  static async isCurrentUserTPA(token: TokenData | null): Promise<boolean> {
    return Promise.resolve(isNumber(token?.tpaId));
  }

  static async validateTpaPlanRelationship(
    tpaId: number,
    planId: number
  ): Promise<{ errors: string[] }> {
    const response = await ApiService.postJson(`/tpas/link/validate`, {
      planId,
      tpaId
    });
    return response as { errors: string[] };
  }

  static async replacePlanToTpaLink(params: {
    newTpaId: number;
    planId: number;
  }): Promise<unknown> {
    return ApiService.putJson('/tpas/link/replace', params);
  }

  static async linkPlanToTpa(tpaId: number, planId: number): Promise<unknown> {
    return ApiService.postJson(`/tpas/link`, {
      planId,
      tpaId
    });
  }

  static async removeTpaPlanRelationship(
    tpaId: number,
    planId: number
  ): Promise<unknown> {
    return ApiService.deleteJson(`/tpas/link?tpaId=${tpaId}&planId=${planId}`);
  }

  static async getPlansForTpa(
    tpaId: number,
    pageNumber: number,
    rowsPerPage: number,
    search?: string
  ): Promise<PlanV2ListDto> {
    return ApiService.getJson(`/tpas/${tpaId}/plans`, {
      pageNumber,
      rowsPerPage,
      search
    });
  }

  static async inviteTpaUser(
    email: string,
    organizationId: string,
    role: string
  ): Promise<unknown> {
    return ApiService.postJson(`/authz-api/organization/invite`, {
      email,
      organizationId,
      role
    });
  }

  static async revokeTpaUserInvite(
    organizationId: string,
    inviteId: string
  ): Promise<unknown> {
    return ApiService.deleteJson(
      `/authz-api/organization/${organizationId}/invite/${inviteId}`
    );
  }

  static async removeTpaMember(
    organizationId: string,
    userId: string
  ): Promise<unknown> {
    return ApiService.deleteJson(
      `/authz-api/organization/${organizationId}/members/${userId}`
    );
  }

  static async getTpa(id: number): Promise<TpaDto> {
    return ApiService.getJson(`/tpas/${id}`);
  }

  static async getTpaMembers(id: string): Promise<OrganizationUser[]> {
    return ApiService.getJson(`/authz-api/organization/${id}/members`);
  }

  static async getTpaInvites(id: string): Promise<OrganizationUser[]> {
    return ApiService.getJson(`/authz-api/organization/${id}/invites`);
  }

  static async backFillTpaOrganization(tpaId: string): Promise<CreatedTpaDto> {
    return ApiService.postJson(`/authz-api/organization/backfill`, { tpaId });
  }

  static async getTpaLatestTermsOfUseDocument(): Promise<DocumentDto> {
    return ApiService.getJson('/tpas/terms-of-use/document');
  }

  static async getTermsOfUseStatus(): Promise<unknown> {
    return ApiService.getJson('/tpas/terms-of-use/agreement');
  }

  static async saveUserTermsOfUseAgreement(): Promise<unknown> {
    return ApiService.postJson('/tpas/terms-of-use/accepted', {});
  }

  static async searchTpaByName(name: string): Promise<TpaListDto> {
    return ApiService.getJson(`/tpas?tpaName=${name}`);
  }

  static async updateTpaName(
    id: number | string,
    name: string
  ): Promise<TpaDto> {
    const dto = await ApiService.putJson<{ name: string }, TpaDto>(
      `/authz-api/organization/${id}`,
      {
        name
      }
    );

    if (!dto) {
      throw new Error('invalid JSON received from backend for creating TPA');
    }

    return dto;
  }
}

export default TpaService;
