import { Typography } from '@mui/material';

interface DetailLabelProps {
  value?: string;
}

const DetailLabel = (props: DetailLabelProps): JSX.Element => {
  const { value } = props;

  return (
    <Typography color='rgba(0, 0, 0, 0.87)' variant='body2'>
      {value || ''}
    </Typography>
  );
};

export default DetailLabel;
