import { useSnackbar } from '@/contexts/SnackBarContext';
import QueueErrorService from '@/services/QueueError.service';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import { FC, useState } from 'react';
import { useToggle, useUpdateEffect } from 'react-use';

type BulkErrorModalProps = {
  errorIds: string[];
  isBulkModalOpen: boolean;
  onSubmit: () => void;
  toggleBulkModal: (flag: boolean) => void;
  type: 'Replay' | 'Archive' | 'Notes' | '';
};

export const BulkErrorModal: FC<BulkErrorModalProps> = (
  props: BulkErrorModalProps
) => {
  const [rowBulkData, setRowBulkData] = useState('');
  const [notes, setNotes] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [openErrorDialog, toogleErrorDialog] = useToggle(false);
  const [errorIds, setErrorIds] = useState<number[]>([]);
  const { showSnackbar } = useSnackbar();

  useUpdateEffect(() => {
    setErrorIds(props.errorIds.map(id => +id));
  }, [props.errorIds]);

  const bulkNoteQueueErrorsMutation = useMutation(
    ['QueueErrorService.bulkNoteQueueErrors'],
    (data: { ids: number[]; note: string }) =>
      QueueErrorService.bulkNoteQueueErrors(data.ids, data.note),
    {
      onError: () => {
        showSnackbar({
          message: 'Error while bulk updating note',
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: 'Success!',
          severity: 'success'
        });
        props.toggleBulkModal(false);
        props.onSubmit();
        setRowBulkData('');
        setNotes('');
      }
    }
  );

  const bulkArchiveErrorMutation = useMutation(
    ['QueueErrorService.bulkArchiveQueueErrors'],
    (data: { ids: number[]; note: string }) =>
      QueueErrorService.bulkArchiveQueueErrors(data.ids, data.note),
    {
      onError: () => {
        showSnackbar({
          message: 'Error bulk archiving queue errors',
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: 'Success!',
          severity: 'success'
        });
        props.toggleBulkModal(false);
        props.onSubmit();
        setRowBulkData('');
        setNotes('');
      }
    }
  );

  const bulkReplayErrorMutation = useMutation(
    ['QueueErrorService.bulkReplayQueueErrors'],
    (ids: number[]) => QueueErrorService.bulkReplayQueueErrors(ids),
    {
      onError: () => {
        showSnackbar({
          message: 'Error bulk replaying queue errors',
          severity: 'error'
        });
      },
      onSuccess: data => {
        props.toggleBulkModal(false);
        props.onSubmit();
        setRowBulkData('');
        if (data.data.errorIds) {
          setErrorMessage(data.data.errorIds.join(', '));
          toogleErrorDialog();
        } else {
          showSnackbar({
            message: 'Success!',
            severity: 'success'
          });
        }
      }
    }
  );

  const bulkArchiveErrors = () =>
    bulkArchiveErrorMutation.mutate({ ids: errorIds, note: notes });

  const bulkReplayErrors = () => bulkReplayErrorMutation.mutate(errorIds);

  const bulkAnnotateErrors = () =>
    bulkNoteQueueErrorsMutation.mutate({ ids: errorIds, note: notes });

  const cancel = () => {
    if (props.errorIds.length === 0) {
      setErrorIds([]);
      setRowBulkData('');
    }
    props.toggleBulkModal(false);
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth='sm'
        onClose={() => props.toggleBulkModal(false)}
        open={props.isBulkModalOpen}>
        <DialogTitle>Bulk {props.type} Queue Errors</DialogTitle>

        <DialogContent>
          {['Archive', 'Notes'].includes(props.type) && (
            <TextField
              fullWidth
              label='Notes'
              minRows={4}
              multiline
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setNotes(event.target.value);
              }}
              sx={{ mb: 2 }}
              value={notes}
            />
          )}

          {props.errorIds.length ? (
            <Typography mb={2} variant='body1'>
              {props.errorIds.join(', ')}
            </Typography>
          ) : (
            <>
              <TextField
                fullWidth
                label='Queue Error IDs'
                minRows={8}
                multiline
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setRowBulkData(event.target.value);
                  const splitList = event.target.value
                    .split(/[\r?\n,]/)
                    .filter(element => element)
                    .map(element => +element);
                  setErrorIds(splitList);
                }}
                value={rowBulkData}
              />
              <Typography mb={2} variant='body2'>
                Comma or line-break delimited
              </Typography>
            </>
          )}

          <Typography>
            <strong>{errorIds.length}</strong> errors will be{' '}
            {props.type === 'Archive'
              ? 'archived'
              : props.type === 'Replay'
                ? 'replayed'
                : 'updated'}
            .
          </Typography>
          <DialogActions>
            <Button onClick={cancel}>Cancel</Button>
            {props.type === 'Replay' && (
              <Button
                color='primary'
                disabled={errorIds.length === 0}
                onClick={bulkReplayErrors}
                variant='contained'>
                Replay
              </Button>
            )}
            {props.type === 'Archive' && (
              <Button
                color='primary'
                disabled={errorIds.length === 0 || notes.length === 0}
                onClick={bulkArchiveErrors}
                variant='contained'>
                Archive
              </Button>
            )}
            {props.type === 'Notes' && (
              <Button
                color='primary'
                disabled={errorIds.length === 0 || notes.length === 0}
                onClick={bulkAnnotateErrors}
                variant='contained'>
                Submit
              </Button>
            )}
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog onClose={toogleErrorDialog} open={openErrorDialog}>
        <DialogTitle>The following errors weren&apos;t replayed:</DialogTitle>
        <DialogContent>
          <Typography mb={2} variant='body1'>
            {errorMessage}
          </Typography>
          <Typography mb={2} variant='body2'>
            Please review the IDs and try again.
          </Typography>
          <DialogActions>
            <Button onClick={toogleErrorDialog}>Ok</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};
