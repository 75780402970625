import CircularLoading from '@/components/circular-loading';
import { ForfeiturePreferences } from '@/routes/plans/plan-detail/PlanForfeituresTab/ForfeiturePreferences.component';
import { PlanService } from '@/services/Plan.service';
import formatters from '@/utils/Formatters';
import { Divider, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { FC } from 'react';

interface ForfeitureInfoProps {
  sponsorPlanId: number;
}

const forfeitureFields = [
  {
    key: 'offsetEmployerContribution',
    label: 'Offset employer contributions'
  },
  {
    key: 'payPlanFees',
    label: 'Pay plan fee'
  }
];

export const ForfeitureInfo: FC<ForfeitureInfoProps> = props => {
  const balance = useQuery(
    ['PlanService.getSubaPlanDetails', props.sponsorPlanId],
    () => {
      return PlanService.getSubaPlanDetails(props.sponsorPlanId);
    },
    {
      enabled: Boolean(props.sponsorPlanId),
      staleTime: Infinity
    }
  );

  const planDesignData = useQuery(
    ['PlanService.getPlanDesignById', props.sponsorPlanId],
    () => {
      return PlanService.getPlanDesignById(props.sponsorPlanId);
    },
    {
      enabled: Boolean(props.sponsorPlanId),
      staleTime: Infinity
    }
  );

  return (
    <Stack
      direction='row'
      divider={<Divider flexItem orientation='vertical' />}
      spacing={2}>
      <Stack data-testid='forfeitureBalance'>
        <Typography color={theme => theme.palette.grey[700]} variant='caption'>
          Total Balance
        </Typography>
        {balance.isLoading ? (
          <CircularLoading />
        ) : (
          <Typography variant='h4'>
            {formatters.formatDollars(balance.data?.forfeituresBalance ?? 0)}
          </Typography>
        )}
      </Stack>
      <Stack>
        {forfeitureFields.map(field => (
          <ForfeiturePreferences
            key={field.key}
            label={field.label}
            name={field.key}
            planDesignData={planDesignData.data?.data?.forfeiturePreferences}
            sponsorPlanId={props.sponsorPlanId}
          />
        ))}
      </Stack>
    </Stack>
  );
};
