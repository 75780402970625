import LinearLoading from '@/components/linear-loading';
import useShowMore from '@/hooks/useShowMore';
import {
  GlobalSearchDto,
  GlobalSearchGoalSeriesDto
} from '@/models/GlobalSearchDTO.model';
import GlobalSearchService from '@/services/GlobalSearch.service';
import { ContentPasteSearch } from '@mui/icons-material';
import {
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Theme,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  GLOBAL_SEARCH_GOAL_SERIES_TYPE,
  GLOBAL_SEARCH_RESULT_PAGE_SIZE
} from './constants';

interface GlobalSearchGoalSeriesResultProps {
  setSearchTerm: (search: string) => void;
  searchTerm: string;
  isFocused: boolean;
  abortSignal?: AbortSignal;
}

const useStyles = makeStyles((theme: Theme) => ({
  globalSearchGoalSeriesContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  globalSearchGoalSeriesField: {
    color: theme.palette.text.secondary,
    fontSize: theme.spacing(1.5),
    minHeight: theme.spacing(1.5)
  },
  globalSearchGoalSeriesHeader: {
    fontSize: theme.spacing(1.5),
    letterSpacing: '0.4px',
    marginLeft: theme.spacing(1.5),
    marginTop: theme.spacing(2)
  },
  globalSearchGoalSeriesIdField: {
    color: theme.palette.text.secondary,
    fontSize: theme.spacing(1.2),
    paddingTop: theme.spacing(1.5),
    textAlign: 'right',
    width: '25%'
  },
  globalSearchGoalSeriesImg: {
    color: '#ff8c1a'
  },
  globalSearchGoalSeriesItem: {
    '&:hover': {
      backgroundColor: grey[200]
    },
    cursor: 'pointer'
  },
  globalSearchShowMoreBtn: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5)
  },
  noResults: {
    paddingLeft: theme.spacing(2)
  }
}));

const GlobalSearchGoalSeriesResult = (
  props: GlobalSearchGoalSeriesResultProps | any
): JSX.Element => {
  const classes = useStyles();

  const { setSearchTerm, searchTerm, abortSignal } = props;

  const navigate = useNavigate();

  const redirectToGoalSeriesPage = (id: number) => {
    setSearchTerm('');
    navigate(`/ops/investments/managed-account/${id}`);
  };

  const globalSearchGoalSeriesQuery = useShowMore<
    GlobalSearchDto,
    GlobalSearchGoalSeriesDto
  >(
    ['globalSearchGoalSeriesResultQuery', searchTerm],
    page =>
      GlobalSearchService.getGlobalSearchResult(
        searchTerm,
        page,
        GLOBAL_SEARCH_RESULT_PAGE_SIZE,
        GLOBAL_SEARCH_GOAL_SERIES_TYPE,
        abortSignal
      ),
    Boolean(searchTerm),
    data => data.data.goalSeries.data,
    data => Boolean(data?.data.goalSeries.links.next),
    'goalSeriesId'
  );

  let loader;

  if (globalSearchGoalSeriesQuery.isLoading || searchTerm === '') {
    loader = <LinearLoading />;
  } else {
    loader = null;
  }

  if (globalSearchGoalSeriesQuery.isError) {
    return <p>Error</p>;
  }

  return (
    <>
      <Typography
        className={classes.globalSearchGoalSeriesHeader}
        id='global-search-goalSeries-header'>
        MANAGED ACCOUNT
      </Typography>
      {loader || (
        <div>
          {!globalSearchGoalSeriesQuery.isLoading &&
          !!globalSearchGoalSeriesQuery.data?.length ? (
            <List data-testid='gs-goalSeries' id='global-search-goalSeries'>
              {globalSearchGoalSeriesQuery.data.map(
                (item: GlobalSearchGoalSeriesDto) => {
                  return (
                    <ListItem
                      className={classes.globalSearchGoalSeriesItem}
                      id='global-search-goalSeries-item'
                      key={item.goalSeriesId}
                      onClick={() =>
                        redirectToGoalSeriesPage(item.goalSeriesId)
                      }>
                      <ListItemAvatar
                        className={classes.globalSearchGoalSeriesImg}>
                        <ContentPasteSearch id='global-search-goalSeries-image' />
                      </ListItemAvatar>
                      <div
                        className={classes.globalSearchGoalSeriesContainer}
                        id='global-search-goalSeries-container'>
                        <div id='global-search-goalSeries-wrapper'>
                          <ListItemText
                            id='global-search-goalSeries-name'
                            primary={`${item.name}`}
                          />
                          <Typography
                            className={classes.globalSearchGoalSeriesField}
                            id='global-search-advisors-firm'>
                            {item?.description
                              ? `Description: ${item?.description}`
                              : ''}
                          </Typography>
                        </div>
                        <Typography
                          className={classes.globalSearchGoalSeriesIdField}
                          id='global-search-goalSeries-id'>
                          GOAL SERIES ID: {item.goalSeriesId}
                        </Typography>
                      </div>
                    </ListItem>
                  );
                }
              )}
            </List>
          ) : (
            <p className={classes.noResults}>No results</p>
          )}
          {!globalSearchGoalSeriesQuery.isLoading &&
            !!globalSearchGoalSeriesQuery.data?.length &&
            globalSearchGoalSeriesQuery.isPaginated && (
              <Button
                className={classes.globalSearchShowMoreBtn}
                disabled={globalSearchGoalSeriesQuery.isPaginationLoading}
                id='global-search-goalSeries-show-more-btn'
                onClick={globalSearchGoalSeriesQuery.showMore}>
                SHOW MORE
              </Button>
            )}
        </div>
      )}
    </>
  );
};

export default GlobalSearchGoalSeriesResult;
