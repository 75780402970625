import { CellComponentProps } from '@/components/collapsible-table';
import formatters from '@/utils/Formatters';
import { Box, TableCell, Typography } from '@mui/material';

import React from 'react';

import RequestStatus from './ConversionMainComponents/RequestStatus.component';

interface ParticipantCellProps {
  row: any;
}

const ParticipantCell: React.FC<ParticipantCellProps> = (
  props: ParticipantCellProps
) => {
  return (
    <Box>
      <Box
        sx={{
          color: '#0288d1'
        }}>
        {props.row.firstName} {props.row.lastName}
      </Box>

      <Typography variant='body2'>ID: {props.row.participantId}</Typography>
    </Box>
  );
};

const ConversionRequestsTableCell: React.FunctionComponent<
  CellComponentProps
> = (props: CellComponentProps) => {
  const { row, column } = props;

  let field = <Box>{row[column.field] || '--'}</Box>;

  switch (column.field) {
    case 'createdAt':
    case 'updatedAt':
      field = (
        <Box>
          {formatters.formatFromIsoDateCustom(
            row[column.field],
            'MM/DD/YYYY hh:mm'
          )}
        </Box>
      );
      break;
    case 'amount':
      field = <Box>{formatters.formatDollars(row[column.field])}</Box>;
      break;
    case 'participant':
      field = <ParticipantCell row={row} />;
      break;
    case 'status':
      field = <RequestStatus status={row[column.field]} />;
      break;
  }

  return (
    <TableCell component='th' scope='row'>
      <Box sx={{ '& .MuiBox-root': { fontSize: theme => theme.spacing(2) } }}>
        {field}
      </Box>
    </TableCell>
  );
};

export default ConversionRequestsTableCell;
