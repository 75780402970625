import { useSnackbar } from '@/contexts/SnackBarContext';
import {
  TickerSelect,
  TickerSelectProps
} from '@/routes/ops/investments/investment-table/TickerSelect.component';

import { useFormik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import InvestmentTable from './investment-table/InvestmentTable.component';
import ModelEditDetailView from './models/ModelEditDetailView.component';

export interface AssetClass {
  goeAccountAssetClassId: number;
  assetClass: string;
  goeAccountId: number;
}

export interface GoalSeriesFund extends Omit<AssetClass, 'goeAccountId'> {
  cusip: string;
  symbol?: string;
  fundName?: string;
  fundId?: string;
  secId?: string;
  securityProviderName?: string;
  broadAssetClass?: string;
  expenseRatio?: number;
}

export interface ManagedAccountSeries {
  name: string;
  description?: string;
  goalSeriesId: number;
  goeAccountId: number;
}

export interface ManagedAccountProps
  extends Partial<Pick<TickerSelectProps, 'lookupTickerCallback'>> {
  managedAccountData: ManagedAccountSeries;
  managedAccountFund: GoalSeriesFund[];
  assetClasses: AssetClass[];
  saveManagedCallback?: (
    goalSeriesData: ManagedAccountSeries,
    goalFunds: GoalSeriesFund[]
  ) => Promise<[ManagedAccountSeries, GoalSeriesFund[]]>;
  programCount: number;
  readonly: boolean;
}

const managedAccountSchema = (max: number) =>
  Yup.object().shape({
    goalFunds: Yup.array()
      .of(
        Yup.object().shape({
          cusip: Yup.string().required('Required')
        })
      )
      .length(max, `Must have ${max} funds`),
    name: Yup.string().required('Required')
  });

export const ManagedAccount = (props: ManagedAccountProps): JSX.Element => {
  const {
    managedAccountData,
    managedAccountFund,
    assetClasses,
    lookupTickerCallback,
    saveManagedCallback,
    programCount
  } = props;

  const snackbar = useSnackbar();
  const [initialAccountData, setInitialAccountData] =
    React.useState<ManagedAccountSeries>(managedAccountData);
  const [initialFund, setInitialFund] =
    React.useState<GoalSeriesFund[]>(managedAccountFund);

  const initialValues = useMemo(
    () => ({ ...initialAccountData, goalFunds: initialFund }),
    [initialAccountData, initialFund]
  );

  const form = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async values => {
      await saveManagedCallback?.(values, values.goalFunds).then(
        ([updatedAccountData, updatedFund]) => {
          setInitialAccountData(updatedAccountData);
          setInitialFund(updatedFund);
          snackbar.showSnackbar({
            message: 'Managed Account Saved',
            severity: 'success'
          });
        }
      );
    },
    validateOnChange: true,
    validationSchema: managedAccountSchema(assetClasses.length)
  });

  const tickerRows = useMemo(() => {
    return assetClasses
      .map(ac => {
        return {
          ...(form.values.goalFunds.find(
            fund => fund.goeAccountAssetClassId === ac.goeAccountAssetClassId
          ) || ac),
          assetClass: ac.assetClass
        };
      })
      .sort((fundA, fundB) => {
        if ('symbol' in fundA && 'symbol' in fundB) {
          return (fundA.symbol as string)?.localeCompare(
            fundB.symbol as string
          );
        }

        return 0;
      });
  }, [assetClasses, form.values.goalFunds]);

  const onCommit = useCallback(
    (params: { value: any; id: number; goeAccountAssetClassId: number }) => {
      if (!params.goeAccountAssetClassId) return;

      const arry = form.values.goalFunds || [];

      // delete row if empty value
      if (!params.value || !params.value.cusip || !params.value.symbol) {
        const found = arry.find((_, index) => index === params.id);

        if (found) {
          const adjusted = arry.map((item: any) => {
            if (item.goeAccountAssetClassId !== params.goeAccountAssetClassId)
              return item;
            return {
              ...item,
              broadAssetClass: undefined,
              cusip: undefined,
              expenseRatio: undefined,
              fundName: undefined,
              symbol: undefined
            };
          });
          form.setFieldValue(`goalFunds`, adjusted);
          snackbar.showSnackbar({
            message: `Please add fund for asset class: ${
              assetClasses.find(
                ac =>
                  ac.goeAccountAssetClassId === params.goeAccountAssetClassId
              )?.assetClass || ''
            }`,
            severity: 'warning'
          });
        }

        // logic if value is not empty
      } else {
        const hasExistingAssetClass = (form.values.goalFunds || []).find(
          item => item.goeAccountAssetClassId === params.goeAccountAssetClassId
        );

        if (hasExistingAssetClass) {
          const adjusted = arry.map(item => {
            if (item.goeAccountAssetClassId !== params.goeAccountAssetClassId)
              return item;
            return {
              ...params,
              ...params.value
            };
          });
          form.setFieldValue(`goalFunds`, adjusted);
        } else {
          form.setFieldValue(`goalFunds`, [
            ...arry,
            {
              ...params,
              ...params.value
            }
          ]);
        }
      }
    },
    [form, snackbar, assetClasses]
  );

  return (
    <ModelEditDetailView
      accountId={managedAccountData.goeAccountId}
      content={
        <InvestmentTable
          columnDefs={[
            {
              field: 'assetClass',
              headerName: 'Recommended Asset Class',
              lockPinned: true,
              pinned: 'left',
              resizable: true,
              sortable: true,
              suppressMenu: true,
              suppressMovable: true,
              valueFormatter: params => params.data?.assetClass || ' '
            },
            {
              cellEditor: TickerSelect,
              cellEditorParams: {
                lookupTickerCallback,
                onCommit
              },
              cellEditorPopup: true,
              editable: !props.readonly,
              field: 'symbol',
              headerName: 'Ticker',
              lockPinned: true,
              maxWidth: 146,
              pinned: 'left',
              resizable: false,
              sortable: true,
              suppressMenu: true,
              suppressMovable: true,
              valueFormatter: params => {
                return params.data?.symbol || 'Enter Ticker';
              }
            },
            {
              field: 'fundName',
              headerName: 'Fund Name',
              lockPinned: true,
              resizable: true,
              sortable: true,
              suppressMenu: true,
              suppressMovable: true,
              valueFormatter: params => params.data?.fundName || ' '
            },
            {
              field: 'broadAssetClass',
              headerName: 'Broad Asset Class',
              lockPinned: true,
              resizable: true,
              sortable: true,
              suppressMenu: true,
              suppressMovable: true,
              valueFormatter: params => params.data?.broadAssetClass || ' '
            },
            {
              field: 'expenseRatio',
              headerName: 'Expense Ratio',
              lockPinned: true,
              resizable: true,
              sortable: true,
              suppressMenu: true,
              suppressMovable: true,
              valueFormatter: params => params.data?.expenseRatio || ' '
            }
          ]}
          rowData={tickerRows}
        />
      }
      dirty={form?.dirty}
      errors={form?.errors}
      investmentOptionId={managedAccountData.goalSeriesId}
      isSubmitting={form?.isSubmitting}
      isValid={form?.isValid}
      modelSeriesType='Goal'
      onSaveModel={() => {
        form?.handleSubmit();
        form?.setSubmitting(false);
      }}
      programCount={programCount}
      readonly={props.readonly}
      setFieldValue={form?.setFieldValue}
      values={form?.values}
    />
  );
};

ManagedAccount.defaultProps = {
  readonly: false
};
