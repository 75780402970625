import AccessControl from '@/components/access-control/AccessControl.component';
import useHasPermissions from '@/components/access-control/useHasPermissions.hook';
import Accordion, {
  AccordionDetails,
  AccordionSummary
} from '@/components/accordion/Accordion.component';
import { CardHeader } from '@/components/card';
import CircularLoading from '@/components/circular-loading';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import PlanDocumentCategoryGroup from '@/models/PlanDocumentCategoryGroupDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import InMemoryFileDownloadService from '@/services/InMemoryFileDownloadService.service';
import { PlanService } from '@/services/Plan.service';
import { userService } from '@/services/User.service';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
  Theme,
  Typography
} from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useMutation } from '@tanstack/react-query';

import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useToggle } from 'react-use';

import BulkUploadPlanDialog from './BulkUploadPlanDialog.component';
import PlanDocumentsTable from './PlanDocumentsTable.component';
import { useGetDocumentGroups } from './useGetDocumentGroups';

const SECTION_HEADER_HEIGHT = 50;

const MissingGroupsOrDocuments = (): JSX.Element => {
  return (
    <Box
      alignItems='center'
      data-testid='no-data'
      display='flex'
      flexDirection='column'
      height='100%'
      justifyContent='center'>
      <InsertDriveFileOutlinedIcon
        sx={{ color: grey[300], fontSize: '60px' }}
      />
      <Typography variant='body1'> No Documents</Typography>
      <Typography variant='body2'>
        Create a new document group to start
      </Typography>
    </Box>
  );
};

const DocumentsLoading = (): JSX.Element => {
  return (
    <Box data-testid='documents-loading'>
      <Stack
        alignItems='center'
        justifyContent='center'
        sx={{
          minHeight: '233px'
        }}>
        <CircularLoading size='32px' />
      </Stack>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  accordionDetails: {
    marginLeft: theme.spacing(6)
  },
  draftBody: {
    backgroundColor: alpha(blue[200], 0.3)
  },
  draftHeader: {
    backgroundColor: alpha(blue[300], 0.3)
  }
}));

interface PlanDocumentsGroupProps {
  sponsorPlanId: number;
  categoryId: number;
  useEffectiveDate: boolean;
  parentDocKey?: string;
  categoryName: string;
}

export type GroupsWithDates = PlanDocumentCategoryGroup & {
  effectiveDate?: string;
  createdAt?: string;
  nameWithEffectiveDate?: string;
};

const PlanDocumentsGroup: React.FunctionComponent<PlanDocumentsGroupProps> = ({
  sponsorPlanId,
  categoryId,
  categoryName,
  useEffectiveDate,
  parentDocKey
}) => {
  const { openDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const [hideEmptyDocuments, toggleHideEmptyDocuments] = useToggle(false);
  const isTPA = userService.isTpaUser();

  const { mutate: downloadBulkDocuments } = useMutation(
    ['PlanService.getBulkDocumentForPlanId', sponsorPlanId],
    (documentIds: number[]) => {
      return PlanService.getBulkDocumentsForPlanId({
        documentIds,
        planId: sponsorPlanId
      });
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Something went wrong with the document download',
          severity: 'error'
        });
      },
      onSuccess: data => {
        InMemoryFileDownloadService.triggerFileDownload(
          data.base64Data,
          data.originalFileName
        );
      }
    }
  );

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<number>();

  const { isAllowed: canCreateNewGrouping } = useHasPermissions({
    requires: [FeatureLevelPermissions.WRITE_DOCUMENTS_GROUPINGS_CREATE]
  });
  const rootRef = useRef<HTMLDivElement>(null);

  const uploadNewDocumentConfigPerCategory: Record<
    number,
    { documentKey: string; documentName: string }
  > = {
    1: {
      documentKey: 'Plan Documents/Plan Adoption Agreement',
      documentName: 'Plan Adoption Agreement'
    },
    2: {
      documentKey: 'Plan Documents/Plan Services Agreement',
      documentName: 'Plan Service Agreement'
    }
  };

  const {
    adHocDocsQuery,
    isError,
    isLoading,
    sortedGroups,
    documentCategoryGroups
  } = useGetDocumentGroups(
    sponsorPlanId,
    categoryId,
    useEffectiveDate,
    parentDocKey
  );

  const groups = useMemo(
    () =>
      isTPA
        ? sortedGroups.filter(group => group.name !== 'Draft')
        : sortedGroups,
    [sortedGroups, isTPA]
  );

  useEffect(() => {
    setExpanded(undefined);
  }, [categoryId]);

  const handleAccordionChange = useCallback(
    (groupIndex, groupingId) => (event, newIsExpanded) => {
      setExpanded(expanded === groupingId ? undefined : groupingId);
      if (newIsExpanded) {
        const topScroll =
          (rootRef?.current?.getBoundingClientRect()?.top || 0) +
          window.scrollY +
          groupIndex * SECTION_HEADER_HEIGHT;

        window.scrollTo({
          behavior: 'smooth',
          left: 0,
          top: topScroll
        });
      }
    },
    [expanded]
  );

  return (
    <div
      data-testid={`plan-documents-group-category-id-${categoryId}`}
      ref={rootRef}
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%'
      }}>
      <CardHeader
        actionButtonsProps={[
          {
            'data-testid': 'document-create-new-group',
            label: 'NEW GROUP',
            onClick: () =>
              openDialog({
                customContent: (
                  <BulkUploadPlanDialog
                    categoryId={categoryId}
                    categoryName={categoryName}
                    documentGroups={sortedGroups}
                    isNewGroup
                    planId={sponsorPlanId}
                    requiredDocumentKey={
                      uploadNewDocumentConfigPerCategory[categoryId]
                        ?.documentKey
                    }
                  />
                )
              }),
            variant: 'text',
            visible: canCreateNewGrouping
          }
        ]}
        title='Documents'>
        <FormControlLabel
          componentsProps={{
            typography: {
              sx: {
                // so that the label aligns with the button
                fontSize: 14,
                lineHeight: '24px',
                py: '5px'
              }
            }
          }}
          control={
            <Checkbox
              checked={hideEmptyDocuments}
              data-testid='hide-empty-documents-checkbox'
              onChange={toggleHideEmptyDocuments}
            />
          }
          label='Hide Empty Documents'
        />
      </CardHeader>
      {(isLoading || adHocDocsQuery.isFetching) && <DocumentsLoading />}

      {!(isLoading || adHocDocsQuery.isFetching) &&
        !!groups?.length &&
        groups.map((group, groupIndex) => (
          <Accordion
            data-testid={`plan-document-group-${group.groupingId}-accordion`}
            expanded={expanded === group.groupingId}
            key={group.groupingId}
            onChange={handleAccordionChange(groupIndex, group.groupingId)}>
            <AccordionSummary
              data-testid={`plan-document-group-${group.groupingId}-accordion-summary`}
              style={
                group.name === 'Draft'
                  ? { backgroundColor: 'rgba(33, 150, 243, 0.04)' }
                  : {}
              }>
              <Box
                alignItems='center'
                display='flex'
                flexDirection='row'
                width='100%'>
                <Typography
                  data-testid={`plan-document-group-${group.groupingId}-accordion-summary-name`}>
                  {group.nameWithEffectiveDate}
                </Typography>
                <Box marginLeft='auto'>
                  <Box
                    alignItems='center'
                    display='flex'
                    flexDirection='row'
                    marginLeft='auto'>
                    <Typography
                      data-testid={`plan-document-group-${group.groupingId}-accordion-summary-n-docs`}
                      variant='body2'>
                      {group.documents?.filter(d => !!d.fileName).length} docs
                    </Typography>
                    <IconButton
                      disabled={
                        !group.documents?.filter(d => !!d.fileName).length
                      }
                      onClick={event => {
                        event.stopPropagation();
                        downloadBulkDocuments(
                          group.documents
                            ?.filter(d => !!d.fileName)
                            .map(doc => doc.uploadHistoryId)
                        );
                      }}
                      title='Download All'>
                      <FileDownloadOutlinedIcon />
                    </IconButton>
                    {group.groupingId !== -1 && (
                      <AccessControl
                        requires={[
                          FeatureLevelPermissions.WRITE_DOCUMENTS_UPLOAD
                        ]}>
                        <IconButton
                          onClick={event => {
                            event.stopPropagation();
                            openDialog({
                              customContent: (
                                <BulkUploadPlanDialog
                                  categoryId={categoryId}
                                  categoryName={categoryName}
                                  documentGroups={sortedGroups}
                                  groupName={group.name}
                                  groupingId={group.groupingId}
                                  planId={sponsorPlanId}
                                />
                              )
                            });
                          }}
                          title='Bulk Upload'>
                          <FileUploadOutlinedIcon />
                        </IconButton>
                      </AccessControl>
                    )}
                  </Box>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails
              className={`${classes.accordionDetails} ${group.name === 'Draft' && classes.draftBody}`}
              data-testid={`plan-document-group-${group.groupingId}-accordion-details`}>
              <PlanDocumentsTable
                categoryId={categoryId}
                categoryName={categoryName}
                documentGroups={documentCategoryGroups}
                documents={group.documents.filter(doc =>
                  hideEmptyDocuments && group.name !== 'Draft'
                    ? doc.fileName
                    : true
                )}
                groupId={group.groupingId}
                isTPA={isTPA}
                parentDocKey={parentDocKey}
                sponsorPlanId={sponsorPlanId}
                useEffectiveDate={useEffectiveDate}
              />
            </AccordionDetails>
          </Accordion>
        ))}

      {isError ||
        adHocDocsQuery.isError ||
        (!(isLoading || adHocDocsQuery.isFetching) && !sortedGroups?.length && (
          <MissingGroupsOrDocuments />
        ))}
    </div>
  );
};
export default PlanDocumentsGroup;
