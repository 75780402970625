import Badge from '@/components/badge';
import { CellComponentProps } from '@/components/collapsible-table';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import formatters from '@/utils/Formatters';
import { Box, TableCell, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';

import React from 'react';
import { Link } from 'react-router-dom';

import { colorByBillingStatus } from './BillingTableCell.component';

interface CellProps {
  billingPeriodId: number;
  planId: number;
  planName: string;
}

export const PlanInfoCell: React.FC<CellProps> = (props: CellProps) => {
  return (
    <Box minWidth={200}>
      <Link
        to={`/ops/fee-management/${props.billingPeriodId}/plan/${props.planId}`}>
        <Typography color={blue[500]} fontWeight={700}>
          {props.planName}
        </Typography>
      </Link>
      <Typography variant='body2'>ID: {props.planId}</Typography>
    </Box>
  );
};

const BillingDetailsTableCell: React.FunctionComponent<CellComponentProps> = (
  props: CellComponentProps
) => {
  const { row, column } = props;
  let field = row[column.field];

  if (column.field === 'plan') {
    field = (
      <PlanInfoCell
        billingPeriodId={row.billingPeriodId}
        planId={row.planId}
        planName={row.planName}
      />
    );
  }

  if (column.field === 'status') {
    field = (
      <Badge
        color={colorByBillingStatus[row[column.field] || 'Not yet submitted']}>
        {row[column.field]}
      </Badge>
    );
  }

  if (
    column.field === 'requestedAmount' ||
    column.field === 'collectedAmount'
  ) {
    field = (
      <Box maxWidth={140} textAlign='right'>
        {formatters.formatDollars(row[column.field])}
      </Box>
    );
  }

  if (column.field === 'submittedDate') {
    field = (
      <Box minWidth={150}>
        {row.submittedDate
          ? formatters.formatFromIsoDateCustom(row.submittedDate, 'MM/DD/YYYY')
          : EMPTY_FIELD_PLACEHOLDER}
      </Box>
    );
  }

  return (
    <TableCell component='th' scope='row'>
      <Box>{!field ? EMPTY_FIELD_PLACEHOLDER : field}</Box>
    </TableCell>
  );
};

export default BillingDetailsTableCell;
