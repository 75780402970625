import useHasPermissions from '@/components/access-control/useHasPermissions.hook';
import { SnackbarOptions } from '@/contexts/SnackBarContext';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { ActionCenterService } from '@/services/ActionCenter.service';
import { QueryClient, useMutation } from '@tanstack/react-query';

import { useCallback, useMemo } from 'react';

import ActionCenterDetailsBulkUpload from './ActionCenterDetailsBulkUpload.component';
import {
  BulkActionsConfig,
  previewColumns,
  previewErrorColumns
} from './useBulkActionCenterConfig.hook';

export const useBulkUploadFilesConfig = (
  details: Record<string, any>,
  setDetails: React.Dispatch<React.SetStateAction<Record<string, any>>>,
  setActiveStep: React.Dispatch<React.SetStateAction<number>>,
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>,
  showSnackbar: (options: SnackbarOptions) => void,
  queryClient: QueryClient
) => {
  const permissions = useHasPermissions({
    requires: [FeatureLevelPermissions.WRITE_BULK_UPLOAD_ACTIONS]
  });

  const bulkUploadPlanDocumentsPreviewMutation = useMutation(
    ['ActionCenterService.bulkUploadPlanDocumentsPreview'],
    (data: FormData) =>
      ActionCenterService.bulkUploadPlanDocumentsPreview(data),
    {
      onError: () =>
        showSnackbar({
          message:
            'There was an error decompressing the file, please try again',
          severity: 'error'
        }),
      onSuccess: () => setDialogOpen(true)
    }
  );

  const bulkUploadPlanDocumentsMutation = useMutation(
    ['ActionCenterService.bulkUploadPlanDocuments'],
    () =>
      ActionCenterService.bulkUploadPlanDocuments(
        bulkUploadPlanDocumentsPreviewMutation.data?.folderId as string,
        details.effectiveDate
      ),
    {
      onError: () => {
        setDialogOpen(false);
        showSnackbar({
          message: 'Your bulk action failed to submit',
          severity: 'error'
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries([
          'ActionCenterService.getBulkUploadActions'
        ]);
        showSnackbar({
          message: 'Your bulk action submitted successfully',
          severity: 'success'
        });
        setDialogOpen(false);
      }
    }
  );

  const previewBulkUploadFiles = useCallback(() => {
    const formData = new FormData();
    formData.append('zipFile', details.zipFile, details.zipName);
    formData.append('documentKey', details.documentKey);
    formData.append('effectiveDate', details.effectiveDate || '');
    formData.append(
      'allowedDocumentTypes',
      details.extensions.toString() || ''
    );
    bulkUploadPlanDocumentsPreviewMutation.mutate(formData);
  }, [details]);

  const previewErrorData = useMemo(() => {
    const unGroupedErrors: { fileName: string; reason: string }[] = [];

    bulkUploadPlanDocumentsPreviewMutation.data?.errors.forEach(err => {
      err.errorMessages.forEach(errorMessage => {
        unGroupedErrors.push({
          fileName: err.documentName,
          reason: errorMessage
        });
      });
    });

    unGroupedErrors.sort((a, b) => {
      if (a.fileName === b.fileName) {
        return a.reason.localeCompare(b.reason);
      }
      return a.fileName.localeCompare(b.fileName);
    });
    return unGroupedErrors;
  }, [bulkUploadPlanDocumentsPreviewMutation.data?.errors]);

  const bulkUploadFilesConfig = useMemo<BulkActionsConfig>(
    () => ({
      isPreviewError:
        !!bulkUploadPlanDocumentsPreviewMutation.data?.errors.length,
      isPreviewLoading: bulkUploadPlanDocumentsPreviewMutation.isLoading,
      noPreviewDataMessage: 'There are no documents in the zip file',
      onConfirm: () => bulkUploadPlanDocumentsMutation.mutate(),
      onPreview: previewBulkUploadFiles,
      previewColumns,
      previewData: bulkUploadPlanDocumentsPreviewMutation.data?.plans,
      previewErrorColumns,
      previewErrorData,
      step2: (
        <ActionCenterDetailsBulkUpload
          details={details}
          setDetails={newDetails => {
            setDetails(newDetails);
            setActiveStep(newDetails.zipFile ? 3 : 1);
          }}
        />
      ),
      step3: `Plans with documents in the zip file above will have those
                documents uploaded to their portal.`,
      visible: permissions.isAllowed
    }),
    [
      bulkUploadPlanDocumentsMutation,
      bulkUploadPlanDocumentsPreviewMutation,
      permissions
    ]
  );

  return bulkUploadFilesConfig;
};
