import AccessControl from '@/components/access-control/AccessControl.component';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { WithdrawalDto, WithdrawalFees } from '@/models';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { WITHDRAWAL_PENDING_STATUSES } from '@/models/Withdrawals.model';
import ParticipantService from '@/services/Participant.service';
import formatters from '@/utils/Formatters';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import React from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

interface FeeInfoStackProps {
  isVestwellEsa?: boolean;
  withdrawalDetails: WithdrawalDto;
}

type FeeInfoStackParams = {
  participantId: string;
  withdrawalId: string;
};

const FeeInfoStack: React.FunctionComponent<FeeInfoStackProps> = (
  props: FeeInfoStackProps
) => {
  const { withdrawalDetails } = props;
  const { participantId, withdrawalId } = useParams<FeeInfoStackParams>();
  const { openDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const withdrawalFeeMutation = useMutation(
    ['ParticipantService.updateWithdrawalFees', participantId, withdrawalId],
    (fees: WithdrawalFees) =>
      ParticipantService.updateWithdrawalFees(
        +participantId,
        +withdrawalId,
        fees
      ),
    {
      onError: () => {
        showSnackbar({
          message: 'Error updating withdrawal fees',
          severity: 'error'
        });
      },
      onSuccess: () => {
        queryClient.refetchQueries([
          'ParticipantService.getWithdrawal',
          participantId,
          withdrawalId
        ]);
        showSnackbar({
          message: 'Success!',
          severity: 'success'
        });
      }
    }
  );

  return (
    <TextStack direction='column'>
      <Typography sx={{ fontSize: 15, fontWeight: 500 }}>Fees</Typography>
      <TextStackItem>
        <TextLabel>Base Fee</TextLabel>
        <TextValue data-testid='base-fee'>
          {formatters.formatDollars(
            withdrawalDetails.attributes.context.withdrawalDerivedAmounts.fee
          )}
        </TextValue>
      </TextStackItem>
      <TextStackItem>
        <TextLabel>Cash Delivery Fee</TextLabel>
        <TextValue data-testid='cash-fee'>
          {withdrawalDetails.attributes.context.withdrawalDerivedAmounts
            .cashWithdrawalDeliveryFee
            ? formatters.formatDollars(
                withdrawalDetails.attributes.context.withdrawalDerivedAmounts
                  .cashWithdrawalDeliveryFee
              )
            : null}
        </TextValue>
      </TextStackItem>
      {!props.isVestwellEsa && (
        <>
          <TextStackItem>
            <TextLabel>Rollover Pre-Tax Delivery Fee</TextLabel>
            <TextValue data-testid='rollover-pretax-fee'>
              {withdrawalDetails.attributes.context.withdrawalDerivedAmounts
                .rolloverPreTaxDeliveryFee
                ? formatters.formatDollars(
                    withdrawalDetails.attributes.context
                      .withdrawalDerivedAmounts.rolloverPreTaxDeliveryFee
                  )
                : null}
            </TextValue>
          </TextStackItem>
          <TextStackItem>
            <TextLabel>Rollover Roth Delivery Fee</TextLabel>
            <TextValue data-testid='rollover-roth-fee'>
              {withdrawalDetails.attributes.context.withdrawalDerivedAmounts
                .rolloverRothDeliveryFee
                ? formatters.formatDollars(
                    withdrawalDetails.attributes.context
                      .withdrawalDerivedAmounts.rolloverRothDeliveryFee
                  )
                : null}
            </TextValue>
          </TextStackItem>
        </>
      )}
      <AccessControl requires={[FeatureLevelPermissions.WRITE_WITHDRAWAL_FEES]}>
        <Box
          sx={{
            visibility: WITHDRAWAL_PENDING_STATUSES.includes(
              withdrawalDetails.attributes.status
            )
              ? 'visible'
              : 'hidden'
          }}>
          <Tooltip
            disableHoverListener={!!withdrawalDetails.attributes.notes}
            title='A note is required before fees can be edited'>
            <span>
              <Button
                disabled={!withdrawalDetails.attributes.notes}
                onClick={() => {
                  openDialog({
                    actionButtons: {
                      cancelButton: {
                        children: 'Cancel'
                      },
                      submitButton: {
                        children: 'Save'
                      }
                    },
                    onSubmit: async rawInputValues => {
                      const withdrawalFees = {
                        cashWithdrawalDeliveryFee:
                          +rawInputValues.cashWithdrawalDeliveryFee,
                        fee: +rawInputValues.baseFee,
                        rolloverPreTaxDeliveryFee:
                          +rawInputValues.rolloverPreTaxDeliveryFee,
                        rolloverRothDeliveryFee:
                          +rawInputValues.rolloverRothDeliveryFee
                      } as WithdrawalFees;
                      withdrawalFeeMutation.mutate(withdrawalFees);
                    },
                    steps: [
                      {
                        fields: {
                          baseFee: {
                            initialValue:
                              withdrawalDetails.attributes.context
                                .withdrawalDerivedAmounts.fee,
                            label: 'Base Fee'
                          },
                          ...(withdrawalDetails.attributes.context
                            .withdrawalDerivedAmounts
                            .cashWithdrawalDeliveryFee !== undefined
                            ? {
                                cashWithdrawalDeliveryFee: {
                                  initialValue:
                                    withdrawalDetails.attributes.context
                                      .withdrawalDerivedAmounts
                                      .cashWithdrawalDeliveryFee,
                                  label: 'Cash Delivery Fee'
                                }
                              }
                            : {}),
                          ...(withdrawalDetails.attributes.context
                            .withdrawalDerivedAmounts
                            .rolloverPreTaxDeliveryFee !== undefined
                            ? {
                                rolloverPreTaxDeliveryFee: {
                                  initialValue:
                                    withdrawalDetails.attributes.context
                                      .withdrawalDerivedAmounts
                                      .rolloverPreTaxDeliveryFee,
                                  label: 'Pre-tax Rollover Delivery Fee'
                                }
                              }
                            : {}),
                          ...(withdrawalDetails.attributes.context
                            .withdrawalDerivedAmounts
                            .rolloverRothDeliveryFee !== undefined
                            ? {
                                rolloverRothDeliveryFee: {
                                  initialValue:
                                    withdrawalDetails.attributes.context
                                      .withdrawalDerivedAmounts
                                      .rolloverRothDeliveryFee,
                                  label: 'Roth Rollover Delivery Fee'
                                }
                              }
                            : {})
                        },
                        title: 'Edit Fees'
                      }
                    ],
                    subcomponentProps: {
                      dialogContentProps: {
                        dividers: true
                      }
                    },
                    validationSchema: yup.object({
                      baseFee: yup
                        .number()
                        .typeError('Base Fee must be numeric')
                        .min(0, 'Base Fee must be a positive number')
                        .max(200, 'Maximum Base Fee is $200')
                        .required('Required'),
                      ...(withdrawalDetails.attributes.context
                        .withdrawalDerivedAmounts.cashWithdrawalDeliveryFee !==
                      undefined
                        ? {
                            cashWithdrawalDeliveryFee: yup
                              .number()
                              .typeError('Cash Delivery Fee must be numeric')
                              .min(
                                0,
                                'Cash Delivery Fee must be a positive number'
                              )
                              .max(100, 'Maximum Cash Delivery Fee is $100')
                              .required('Required')
                          }
                        : {}),
                      ...(withdrawalDetails.attributes.context
                        .withdrawalDerivedAmounts.rolloverPreTaxDeliveryFee !==
                      undefined
                        ? {
                            rolloverPreTaxDeliveryFee: yup
                              .number()
                              .typeError(
                                'Rollover Pre-Tax Delivery Fee must be numeric'
                              )
                              .min(
                                0,
                                'Rollover Pre-Tax Delivery Fee must be a positive number'
                              )
                              .max(
                                100,
                                'Maximum Rollover Pre-Tax Delivery Fee is $100'
                              )
                              .required('Required')
                          }
                        : {}),
                      ...(withdrawalDetails.attributes.context
                        .withdrawalDerivedAmounts.rolloverRothDeliveryFee !==
                      undefined
                        ? {
                            rolloverRothDeliveryFee: yup
                              .number()
                              .typeError(
                                'Rollover Roth Delivery Fee must be numeric'
                              )
                              .min(
                                0,
                                'Rollover Roth Delivery Fee must be a positive number'
                              )
                              .max(
                                100,
                                'Maximum Rollover Roth Delivery Fee is $100'
                              )
                              .required('Required')
                          }
                        : {})
                    })
                  });
                }}
                variant='text'>
                EDIT
              </Button>
            </span>
          </Tooltip>
        </Box>
      </AccessControl>
    </TextStack>
  );
};

export default FeeInfoStack;
