import CorrectedIcon from '@/components/icon/CorrectedIcon';
import formatter from '@/utils/Formatters';
import { PriceCheckOutlined } from '@mui/icons-material';
import { Card, CardContent, Link, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import Decimal from 'decimal.js';
import { useMemo } from 'react';
import type { FC } from 'react';

interface TotalContributionCardProps {
  total: number;
  forfeitureAmount?: number;
  hasCorrections?: boolean;
  hasLostGains?: boolean;
  recordkeeper?: string;
  rejectedAmount?: number;
  doNotAchEmployer?: boolean;
}

const TotalContributionCard: FC<TotalContributionCardProps> = (
  props: TotalContributionCardProps
) => {
  const achTransfer = useMemo(
    () => Decimal.sub(props.total ?? 0, props.forfeitureAmount ?? 0).toNumber(),
    [props.total, props.forfeitureAmount]
  );

  return (
    <Card elevation={0} sx={{ paddingBottom: 3 }} variant='outlined'>
      <CardContent>
        <Typography data-testid='total-contribution-heading' variant='h6'>
          Total Contribution
        </Typography>
        <Grid alignItems='center' display='flex' gap={1}>
          <Typography data-testid='total-contribution' variant='h3'>
            {formatter.formatDollars(props.total ?? 0)}
          </Typography>
          {props.hasLostGains && (
            <Tooltip data-testid='lostGainTooltip' title='Lost Gain'>
              <PriceCheckOutlined color='primary' />
            </Tooltip>
          )}
          {props.hasCorrections && (
            <Tooltip
              data-testid='correctionTooltip'
              title='This contribution has corrections'>
              <CorrectedIcon />
            </Tooltip>
          )}
        </Grid>
        <Grid my={2} xs={4}>
          <Grid
            data-testid='ach-transfer-heading'
            display='flex'
            justifyContent='space-between'>
            <Typography color='gray' variant='subtitle1'>
              ACH Transfer
            </Typography>
            <Typography variant='subtitle1'>
              {formatter.formatDollars(
                props.doNotAchEmployer ? 0 : achTransfer,
                2
              )}
            </Typography>
          </Grid>
          {props.doNotAchEmployer && (
            <Grid
              data-testid='planCash-heading'
              display='flex'
              justifyContent='space-between'>
              <Typography color='gray' variant='subtitle1'>
                Plan Cash
              </Typography>
              <Typography variant='subtitle1'>
                {formatter.formatDollars(achTransfer, 2)}
              </Typography>
            </Grid>
          )}
          <Grid
            data-testid='forfeiture-heading'
            display='flex'
            justifyContent='space-between'>
            <Typography color='gray' variant='subtitle1'>
              Forfeiture
            </Typography>
            <Typography variant='subtitle1'>
              {formatter.formatDollars(props.forfeitureAmount ?? 0, 2)}
            </Typography>
          </Grid>
          <Grid
            data-testid='suspense-heading'
            display='flex'
            justifyContent='space-between'>
            <Typography color='gray' variant='subtitle1'>
              Suspense
            </Typography>
            <Typography variant='subtitle1'>
              {formatter.formatDollars(0, 2)}
            </Typography>
          </Grid>
          <Grid
            data-testid='rejected-heading'
            display='flex'
            justifyContent='space-between'>
            <Typography color='gray' variant='subtitle1'>
              Rejected Amount
            </Typography>
            <Typography variant='subtitle1'>
              {formatter.formatDollars(props.rejectedAmount, 2)}
            </Typography>
          </Grid>
        </Grid>
        {props.hasCorrections && (
          <Link data-testid='correctionLink' href='#corrections'>
            View correction details
          </Link>
        )}
      </CardContent>
    </Card>
  );
};

export default TotalContributionCard;
