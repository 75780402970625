import useHasPermissions from '@/components/access-control/useHasPermissions.hook';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import {
  PlanTestResultDto,
  TestExecutionResultsDto
} from '@/models/YearEndTestingDTO.model';
import {
  ErrorOutline,
  KeyboardArrowDown,
  KeyboardArrowRight
} from '@mui/icons-material';
import {
  Box,
  Collapse,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { useFormikContext } from 'formik';
import { capitalize } from 'lodash';
import React, { FC, useCallback, useMemo } from 'react';
import { useToggle } from 'react-use';

import { HceField } from './HceField/HceField.component';

export interface TestExecutionDropdownProps {
  row: {
    hasHceJson: boolean;
    hasQnecTotal: boolean;
    label:
      | 'resultADP'
      | 'resultACP'
      | 'resultTopHeavyCurrentYear'
      | 'result402g'
      | 'result415';
    name: string;
  };
  data: PlanTestResultDto['testExecution'];
}

const StyledTableCell = styled(TableCell)(() => ({
  borderBottom: 'none'
}));

const StyledTableRow = styled(TableRow, {
  shouldForwardProp: propName => propName !== 'hasError'
})<{ hasError: boolean }>(props => ({
  backgroundColor: props.hasError ? 'rgba(211, 47, 47, 0.04)' : 'inherit'
}));

export const TestExecutionDropdown: FC<TestExecutionDropdownProps> = props => {
  const { errors, setFieldValue, values } =
    useFormikContext<TestExecutionResultsDto>();

  const [open, toggleOpen] = useToggle(false);

  const permissions = useHasPermissions({
    requires: [FeatureLevelPermissions.WRITE_ANNUAL_TESTING_ACTIONS]
  });

  const onResultChange = useCallback(
    event => setFieldValue(`${props.row.label}.result`, event.target.value),
    [props.row.label, setFieldValue]
  );

  const onQnecTotalChange = useCallback(
    event => {
      setFieldValue(
        props.row.label.includes('resultTopHeavy')
          ? `${props.row.label}.additionalData.minC`
          : `${props.row.label}.additionalData.qnec`,
        event.target.value
      );
    },
    [props.row.label, setFieldValue]
  );

  const options = useMemo(() => {
    return props.row.label.includes('resultTopHeavy')
      ? [
          {
            label: 'Top Heavy - Action Required',
            value: 'top-heavy-action-required'
          },
          {
            label: 'Top Heavy - No Action',
            value: 'top-heavy-no-action'
          },
          {
            label: 'Not Top Heavy',
            value: 'not-top-heavy'
          },
          {
            label: 'N/A',
            value: 'N/A'
          }
        ]
      : [
          {
            label: 'Pass',
            value: 'pass'
          },
          {
            label: 'Fail',
            value: 'fail'
          },
          {
            label: 'N/A',
            value: 'N/A'
          }
        ];
  }, [props.row.label]);

  const collapsedValues = useMemo(() => {
    return `${
      values[`${props.row.label}`].result === 'N/A'
        ? 'N/A'
        : values[`${props.row.label}`].result === 'top-heavy-action-required'
          ? 'Top Heavy - Action Required'
          : values[`${props.row.label}`].result === 'top-heavy-no-action'
            ? 'Top Heavy - No Action'
            : values[`${props.row.label}`].result === 'not-top-heavy'
              ? 'Not Top Heavy'
              : capitalize(values[`${props.row.label}`].result)
    } ${
      props.row.hasQnecTotal
        ? ` | QNEC Total: ${
            props.row.label === 'resultTopHeavyCurrentYear'
              ? values[`${props.row.label}`].additionalData?.minC
              : props.row.label === 'resultADP' ||
                  props.row.label === 'resultACP'
                ? values[`${props.row.label}`].additionalData?.qnec
                : ''
          }`
        : ''
    }`;
  }, [props.row.hasQnecTotal, props.row.label, values]);

  return (
    <>
      <StyledTableRow
        data-testid={`test-execution-${props.row.label}-dropdown-row`}
        hasError={!!errors[`${props.row.label}`]}>
        <StyledTableCell>
          <Stack alignItems='center' direction='row' spacing={1}>
            {errors[`${props.row.label}`] && <ErrorOutline color='error' />}
            <Typography color='textPrimary' variant='body2'>
              {props.row.name}
            </Typography>
          </Stack>
        </StyledTableCell>
        <StyledTableCell>
          {!open && (
            <Typography color='textSecondary' variant='body2'>
              {collapsedValues}
            </Typography>
          )}
        </StyledTableCell>
        <StyledTableCell align='right'>
          <IconButton
            aria-label='expand row'
            data-testid={`${props.row.label}-expand-row-button`}
            onClick={toggleOpen}
            size='small'>
            {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
      <TableRow data-testid='test-execution-dropdown-expandable-row'>
        <TableCell colSpan={12} padding='none'>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box>
              <Table aria-label='purchases' size='small'>
                <TableBody>
                  <TableRow>
                    <StyledTableCell width='10%'>Test Result</StyledTableCell>
                    <StyledTableCell>
                      <Select
                        data-testid={`${props.row.label}-dropdown`}
                        disabled={permissions.isForbidden}
                        displayEmpty
                        onChange={onResultChange}
                        size='small'
                        value={values[`${props.row.label}`].result}>
                        {options.map(item => (
                          <MenuItem key={item.label} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </StyledTableCell>
                  </TableRow>
                  {props.row.hasQnecTotal && (
                    <TableRow>
                      <StyledTableCell>QNEC Total</StyledTableCell>
                      <StyledTableCell>
                        <TextField
                          data-testid={`${props.row.label}-input`}
                          disabled={permissions.isForbidden}
                          onChange={onQnecTotalChange}
                          size='small'
                          type='number'
                          value={
                            props.row.label === 'resultTopHeavyCurrentYear'
                              ? values[`${props.row.label}`].additionalData
                                  ?.minC
                              : props.row.label === 'resultADP' ||
                                  props.row.label === 'resultACP'
                                ? values[`${props.row.label}`].additionalData
                                    ?.qnec
                                : 0
                          }
                          variant='outlined'
                        />
                      </StyledTableCell>
                    </TableRow>
                  )}
                  {props.row.hasHceJson && <HceField label={props.row.label} />}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
