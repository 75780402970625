import CollapsibleTable, {
  CellComponentProps
} from '@/components/collapsible-table';
import { FirmModal } from '@/components/entity-modals/EntityModals.component';
import { useUserToken } from '@/contexts/UserTokenContext';
import { DocumentMetadataListDto, FirmDto } from '@/models';
import FirmService, { FirmsListDto } from '@/services/Firm.service';
import { Search } from '@mui/icons-material';
import {
  Box,
  Divider,
  Grid,
  InputAdornment,
  Link,
  Paper,
  TableCell,
  TextField,
  Theme,
  Toolbar,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import clsx from 'clsx';
import { get, isNaN } from 'lodash';
import React, { memo, useCallback, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

const useStyles = makeStyles((theme: Theme) => ({
  cellSharedStyles: {
    fontSize: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
  firmIdCell: {
    width: theme.spacing(30)
  },
  firmNameCell: {
    width: theme.spacing(75)
  },
  margin: {
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(3)
  },
  noResultsTableBackground: {
    borderTop: '0px',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    paddingBottom: '100px',
    paddingTop: '100px'
  },
  noResultsTableMessage: {
    color: theme.palette.grey[700]
  },
  pageTitle: {
    marginBottom: theme.spacing(3.5),
    marginTop: theme.spacing(3.5)
  },
  planIdCellWidth: {
    width: theme.spacing(30)
  },
  planNameCellWidth: {
    width: theme.spacing(45)
  },
  textField: {
    width: '50ch'
  }
}));

const useTableStyles = makeStyles((theme: Theme) => ({
  tableInputStyles: {
    '& > *': {
      paddingRight: theme.spacing(1.5),
      paddingTop: theme.spacing(2)
    },
    paddingLeft: theme.spacing(1.5)
  },
  tableToolbar: {
    marginBottom: '0px'
  },
  tableToolbarDivider: {
    height: '1px',
    marginBottom: theme.spacing(0),
    marginTop: 0
  },
  tableToolbarGridContainer: {
    marginBottom: theme.spacing(2)
  },
  tableToolbarTitle: {
    marginBottom: theme.spacing(2.5),
    marginTop: theme.spacing(2.5),
    paddingLeft: theme.spacing(2)
  }
}));

const FirmTableCell: React.FunctionComponent<CellComponentProps> = memo(
  (props: CellComponentProps) => {
    const classes = useStyles();
    const { row, column } = props;

    const formatCell = useCallback(
      (
        r: FirmDto | FirmsListDto['data'][number],
        c: typeof column
      ): string | number | undefined => {
        if (c.field === 'id') {
          return 'id' in r ? r.id : r.data.id;
        }
        if (c.field === 'attributes.companyName') {
          if ('id' in r) {
            return r.attributes.companyName;
          } else {
            return r.data.attributes.companyName;
          }
        }
        if (c.field === 'attributes.homeOfficeName') {
          if ('id' in r) {
            return r.attributes.homeOfficeName;
          } else {
            return r.data.attributes.homeOfficeName;
          }
        }
        return get(r, c.field);
      },
      []
    );

    const value = formatCell(row, column);

    return (
      <TableCell
        className={clsx(classes.cellSharedStyles, column.cellClassName)}
        component='th'
        scope='row'
        {...(column.field === 'empty-spacer' && { align: 'right' })}>
        <Box>
          {column.field === 'id' ? (
            <Link
              component={RouterLink}
              to={`/firms/${value}`}
              underline='hover'>
              {value}
            </Link>
          ) : (
            value
          )}
        </Box>
      </TableCell>
    );
  }
);

const FirmsRoute = (): JSX.Element => {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const { userHasValidToken } = useUserToken();

  const defaultColumns: GridColDef<DocumentMetadataListDto[number]>[] = [
    {
      cellClassName: classes.firmIdCell,
      field: 'id',
      headerName: 'Firm ID'
    },
    {
      cellClassName: classes.firmNameCell,
      field: 'attributes.companyName',
      headerName: 'Firm Name'
    },
    {
      field: 'attributes.homeOfficeName',
      headerName: 'Home Office Name'
    }
  ];

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [searchTerm, setSearchTerm] = useState('');

  const [debouncedSearchTerm] = useDebounce(searchTerm, 150);

  const firmByIdQuery = useQuery<FirmDto>(
    ['FirmService.getById', debouncedSearchTerm],
    () => {
      if (!debouncedSearchTerm)
        throw new Error(
          `query cannot be run without a firmId, we got: ${debouncedSearchTerm}`
        );
      return FirmService.getFirmById(debouncedSearchTerm);
    },
    {
      enabled: Boolean(
        userHasValidToken && debouncedSearchTerm && !isNaN(+debouncedSearchTerm)
      ),
      staleTime: Infinity
    }
  );

  const firmsQuery = useQuery<FirmsListDto>(
    ['FirmService.getFirmsPage', pageNumber, pageSize, debouncedSearchTerm],
    () =>
      FirmService.getFirmsPage({
        pageNumber,
        pageSize,
        search: debouncedSearchTerm
      }),
    {
      enabled: userHasValidToken,
      keepPreviousData: true,
      staleTime: Infinity
    }
  );

  const combineAndDeDupe = useCallback(
    (searchMatches: FirmsListDto['data'], exactMatch: FirmDto | undefined) => {
      if (exactMatch === undefined) return searchMatches;

      return [
        exactMatch,
        ...searchMatches.filter(firm => firm.id !== exactMatch.data.id)
      ];
    },
    []
  );

  const allFirms = combineAndDeDupe(
    firmsQuery.data?.data || [],
    firmByIdQuery.data
  );

  return (
    <div>
      <Grid
        className={classes.pageTitle}
        container
        justifyContent='space-between'>
        <Grid item>
          <Typography variant='h4'>Firms</Typography>
        </Grid>
        <Grid item>
          <FirmModal action='create' variant='outlined' />
        </Grid>
      </Grid>
      <CollapsibleTable
        backgroundPaperElevation={0}
        cellComponent={FirmTableCell}
        columns={defaultColumns}
        disablePagination={!allFirms.length}
        headerComponent={
          <Toolbar className={tableClasses.tableToolbar} disableGutters>
            <Grid className={tableClasses.tableToolbarGridContainer} container>
              <Grid item lg={12} md={12} sm={12}>
                <Typography
                  className={tableClasses.tableToolbarTitle}
                  component='div'
                  id='collapsible-table-toolbar-title'
                  variant='h5'>
                  All Firms
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12}>
                <Divider
                  className={tableClasses.tableToolbarDivider}
                  flexItem
                />
              </Grid>
              <Grid item lg={12}>
                <div className={tableClasses.tableInputStyles}>
                  <TextField
                    InputProps={{
                      'aria-placeholder': 'Search Firms',
                      onChange: event => {
                        setSearchTerm(event.target.value || '');
                      },
                      placeholder: 'Search Firms',
                      startAdornment: (
                        <InputAdornment position='start'>
                          <Search />
                        </InputAdornment>
                      )
                    }}
                    className={clsx(classes.margin, classes.textField)}
                    id='search-all-firms'
                    variant='outlined'
                  />
                </div>
              </Grid>
            </Grid>
          </Toolbar>
        }
        pager={{
          metaCount: firmsQuery.data?.meta.count,
          onPageNumberChanged: (zeroIndexedPageNumber: number) => {
            return setPageNumber(zeroIndexedPageNumber + 1);
          },
          onRowsPerPageChanged: (newRowsPerPage: number) => {
            return setPageSize(newRowsPerPage);
          },
          pageNumber: pageNumber - 1,
          rowsPerPage: pageSize
        }}
        tableData={allFirms}
        useSquareBottomContainer={!allFirms.length}
      />
      {firmsQuery.isFetching && !allFirms.length && (
        <Paper className={classes.noResultsTableBackground}>
          <Typography
            align='center'
            className={classes.noResultsTableMessage}
            variant='subtitle1'>
            Loading firms...
          </Typography>
        </Paper>
      )}
      {firmsQuery.isSuccess && !allFirms.length && (
        <Paper className={classes.noResultsTableBackground}>
          <Typography
            align='center'
            className={classes.noResultsTableMessage}
            variant='subtitle1'>
            There are no firms available for the options selected.
          </Typography>
        </Paper>
      )}
      {firmsQuery.isError && !allFirms.length && (
        <Paper className={classes.noResultsTableBackground}>
          <Typography
            align='center'
            className={classes.noResultsTableMessage}
            variant='subtitle1'>
            We encountered an error retrieving firms.
          </Typography>
        </Paper>
      )}
    </div>
  );
};

export default FirmsRoute;
