import { ConfirmedTransactionDto } from '@/models/ops/transactions/ConfirmedTransaction.model';
import { ConfirmPendingTransactionRequest } from '@/models/ops/transactions/ConfirmPendingTransactionRequest.model';
import ApiService from '@/services/Api.service';

class PendingTransactionService {
  static async cancel(sourceTransactionId: string | string[]): Promise<void> {
    const result = await ApiService.patchJson<
      { sourceTransactionId: string | string[] },
      void
    >(`pending-transactions/cancel`, { sourceTransactionId });
    return result;
  }

  static async confirm(
    sourceTransactionId: string | undefined,
    body: ConfirmPendingTransactionRequest
  ): Promise<ConfirmedTransactionDto> {
    const requestBody: ConfirmPendingTransactionRequest & {
      pendingStatus: 'confirmed';
    } = {
      ...body,
      pendingStatus: 'confirmed'
    };
    const result = await ApiService.patchJson<
      ConfirmPendingTransactionRequest & {
        pendingStatus: 'confirmed';
      },
      ConfirmedTransactionDto
    >(`pending-transactions/${sourceTransactionId}`, requestBody);
    return result;
  }
}

export default PendingTransactionService;
