import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class LastPayrollDate {
  convertToApiState = Helper.transformDateToApiState;

  disabled: boolean;

  setDisabled = (value: boolean): void => {
    this.disabled = value;
  };

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.disabled = ![
      'Terminated',
      'Termination Requested',
      'Deconverted',
      'Deconversion Requested'
    ].includes(props.adminStatus);
    this.output = Helper.transformDate(props.lastPayrollDate);
  }
}

export default LastPayrollDate;
