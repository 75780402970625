import { OrderStatus } from '@vestwell-sub-accounting/models/orderManagement/OrderStatus';

export const orderStatusColorMap: Partial<
  Record<OrderStatus, 'info' | 'neutral' | 'success' | 'warning'>
> = {
  [OrderStatus.Rejected]: 'warning',
  [OrderStatus.Exited]: 'info',
  [OrderStatus.Deferred]: 'info',
  [OrderStatus.Open]: 'neutral',
  [OrderStatus.Accepted]: 'neutral',
  [OrderStatus.Executed]: 'success',
  [OrderStatus.Submitted]: 'neutral'
};
