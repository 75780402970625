import AccessControl from '@/components/access-control/AccessControl.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import ParticipantService from '@/services/Participant.service';
import { LoadingButton } from '@mui/lab';
import { Grid, Stack, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import dayjs from 'dayjs';

import ParticipantInfo from '../../../models/ParticipantInfo.model';

interface DistributionHoldProps {
  participantId: string;

  participant: ParticipantInfo;
}
export function ParticipantAccountDistributionHold(
  props: DistributionHoldProps
) {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();
  const removeDistributionHold = useMutation(
    ['ParticipantService.removeDistributionHold', props.participantId],
    () => ParticipantService.removeDistributionHold(+props.participantId),
    {
      onError: () => {
        showSnackbar({
          message: 'Couldn’t activate distribution hold on participant account',
          severity: 'error'
        });
      },
      onSuccess: async () => {
        await queryClient.refetchQueries([
          'ParticipantService.getParticipantById',
          props.participantId?.toString()
        ]);
        showSnackbar({
          message:
            'Successfully deactivated distribution hold on participant account!',
          severity: 'success'
        });
      }
    }
  );

  const isDistributionHold =
    (props.participant?.emailUpdatedAt &&
      dayjs().diff(dayjs(props.participant?.emailUpdatedAt), 'days') <= 7) ||
    (props.participant?.addressUpdatedAt &&
      dayjs().diff(dayjs(props.participant?.addressUpdatedAt), 'days') <= 7) ||
    (props.participant?.nameUpdatedAt &&
      dayjs().diff(dayjs(props.participant?.nameUpdatedAt), 'days') <= 7);

  return (
    <Grid container>
      <Grid item xs={2}>
        <Typography variant='body2'>Distribution Hold</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography
          color={isDistributionHold ? 'red' : 'black'}
          variant='body1'>
          {isDistributionHold ? 'Active' : 'Inactive'}
        </Typography>
        <Typography color='gray' variant='caption'>
          A seven day lock on the account that automatically triggers when a
          participant updates their name, email address, or physical address
        </Typography>
      </Grid>
      <Grid item xs={2}>
        {isDistributionHold && (
          <AccessControl
            requires={[
              FeatureLevelPermissions.WRITE_PARTICIPANT_DISTRIBUTION_HOLD
            ]}>
            <Stack>
              <LoadingButton
                data-testid='deactivate-distribution-hold-btn'
                loading={removeDistributionHold.isLoading}
                onClick={() => removeDistributionHold.mutateAsync()}>
                DEACTIVATE
              </LoadingButton>
            </Stack>
          </AccessControl>
        )}
      </Grid>
    </Grid>
  );
}
