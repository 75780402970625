import CollapsibleTable from '@/components/collapsible-table';
import ContributionTransactionsTableCell from '@/routes/plans/plan-detail/ContributionTransactions/ContributionTransactionsTableCell.component';
import ParticipantService from '@/services/Participant.service';
import { Close } from '@mui/icons-material';
import { Container, Grid, IconButton, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';

import React from 'react';
import { useParams } from 'react-router-dom';

interface ContributionTransactionsProps {
  participantId: number;
  participantName: string;
  closeDrawer: () => void;
}

type ContributionTransactionsParams = {
  ucid: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.text.secondary
  },
  titleContainer: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(3)
  }
}));

const ContributionTransactions = (
  props: ContributionTransactionsProps
): JSX.Element => {
  const { participantId, participantName, closeDrawer } = props;
  const { ucid } = useParams<ContributionTransactionsParams>();
  const classes = useStyles();

  const tradeQuery = useQuery(
    [ParticipantService.getContributionTransactions.name, participantId, ucid],
    async () => {
      return ParticipantService.getContributionTransactions(
        participantId,
        ucid
      );
    },
    {
      staleTime: Infinity
    }
  );

  const contributionTransactionsColumns = [
    { field: 'id', headerName: 'Tx ID', width: 130 },
    { field: 'tradeDate', headerName: 'Trade Date', width: 130 },
    {
      field: 'effectiveDate',
      headerName: 'Effective Date',
      width: 130
    },
    {
      field: 'fundingSource',
      headerName: 'Funding Source',
      width: 130
    },
    {
      field: 'transactionSubtype',
      headerName: 'Tx Subtype',
      width: 130
    },
    { field: 'ticker', headerName: 'Ticker', width: 130 },
    { field: 'amount', headerName: 'Amount', width: 130 },
    { field: 'status', headerName: 'Status', width: 130 },
    { field: 'tracerId', headerName: 'Tracer ID', width: 130 }
  ];

  const handleClickClose = () => {
    closeDrawer();
  };

  return (
    <Container maxWidth='xl'>
      <Grid
        className={classes.titleContainer}
        container
        justifyContent='space-between'>
        <Grid item>
          <Typography variant='subtitle1'>Contributions / Employee</Typography>
          <Typography variant='h4'>{participantName}</Typography>
        </Grid>
        <Grid item>
          <IconButton
            aria-label='close'
            className={classes.icon}
            onClick={handleClickClose}
            size='medium'>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
      <CollapsibleTable
        cellComponent={ContributionTransactionsTableCell}
        columns={contributionTransactionsColumns}
        tableData={tradeQuery.data || []}
      />
    </Container>
  );
};

export default ContributionTransactions;
