import AccessControl from '@/components/access-control/AccessControl.component';
import SimpleDropdown from '@/components/simple-dropdown';
import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { OrganizationUser } from '@/models/OrganizationUser.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import TpaService from '@/services/Tpa.service';
import { Search } from '@mui/icons-material';
import {
  Button,
  InputAdornment,
  TextField,
  Theme,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import React from 'react';
import * as yup from 'yup';

interface AddTpaUserToolbarProps {
  orgId: string;
  tpaUsers: OrganizationUser[];
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addBtn: {
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: theme.spacing(2),
      width: theme.spacing(22)
    }
  })
);

const roles = ['Manager', 'Agent'];

const AddTpaUserToolbar = (props: AddTpaUserToolbarProps): JSX.Element => {
  const classes = useStyles();
  const { openDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const { orgId, tpaUsers } = props;
  const queryClient = useQueryClient();

  const isUserGroupOrganization = orgId.includes('org_');

  const inviteUserMutation = useMutation(
    (data: { email: string; orgId: string; role: string }) => {
      return TpaService.inviteTpaUser(data.email, data.orgId, data.role);
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Something went wrong. Please try again',
          severity: 'error'
        });
      },
      onSuccess: async () => {
        await queryClient.refetchQueries([
          'TpaService.getTpaInvites',
          orgId?.toString()
        ]);
        showSnackbar({
          message: 'Invite was successfully sent!',
          severity: 'success'
        });
      }
    }
  );

  const inviteTpaUser = async (email: string, roleName: string) => {
    const role = roleName.toLowerCase();
    await inviteUserMutation.mutateAsync({ email, orgId, role });
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .trim()
      .required('Email is required')
      .email('Please provide a valid email')
      .test(
        'Check if email is already registered',
        'This email is already registered with an existing user',
        value => {
          return !tpaUsers.filter(
            user => user.status === 'Registered' && user.email === value
          ).length;
        }
      ),
    tpaRole: yup.string().when('email', {
      is: (email: string) => {
        const excludeSelf = tpaUsers.filter(user => user.email !== email);
        const hasInvitedTpaManager = excludeSelf.find(
          user => user.role === 'manager'
        );
        return !hasInvitedTpaManager;
      },
      otherwise: yup.string().required('TPA Role is required'),
      then: yup
        .string()
        .required('TPA Role is required')
        .oneOf(['Manager'], 'A TPA Manager must be invited before an Agent')
    })
  });

  return (
    <Grid
      container
      data-testid='tpa-manager-user-table-toolbar'
      p={2}
      width='100%'>
      <Grid alignItems='center' display='flex'>
        <Typography component='div' id='tableTitle' variant='h5'>
          Manage TPA Users
        </Typography>
      </Grid>
      <Grid style={{ flexGrow: '1' }} />
      <Grid>
        <TextField
          InputProps={{
            'aria-placeholder': 'Search Users',
            onChange: e => props.setSearchTerm(e.target.value?.trim()),
            placeholder: 'Search Users',
            startAdornment: (
              <InputAdornment position='start'>
                <Search />
              </InputAdornment>
            )
          }}
          data-testid='search-tpa-users'
          style={{ width: '40ch' }}
          variant='outlined'
        />
      </Grid>
      <Grid alignItems='center' display='flex'>
        <AccessControl
          requires={[FeatureLevelPermissions.WRITE_TPA_DETAILS_MANAGE_USERS]}>
          {isUserGroupOrganization && (
            <Button
              className={classes.addBtn}
              data-testid='tpa-manager-user-table-btn'
              onClick={() =>
                openDialog({
                  actionButtons: {
                    cancelButton: {
                      children: 'CANCEl'
                    },
                    submitButton: {
                      children: 'INVITE'
                    }
                  },
                  disableSubmitWhenInvalid: true,
                  onSubmit: values => {
                    inviteTpaUser(values.email.trim(), values.tpaRole);
                  },
                  steps: [
                    {
                      fields: {
                        email: {
                          initialValue: '',
                          label: 'Email'
                        },
                        tpaRole: {
                          component: (
                            <SimpleDropdown
                              fieldId='tpaRole'
                              fieldName='TPA Role'
                              fieldValues={roles}
                            />
                          ),
                          initialValue: '',
                          label: 'TPA Role'
                        }
                      },
                      title: 'Add User'
                    }
                  ],
                  validationSchema
                })
              }
              variant='contained'>
              ADD USER
            </Button>
          )}
        </AccessControl>
      </Grid>
    </Grid>
  );
};

export default AddTpaUserToolbar;
