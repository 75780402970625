import AccessControl from '@/components/access-control/AccessControl.component';
import Badge from '@/components/badge';
import { CardPlaceholder } from '@/components/card';
import DataTable, {
  DataTableBadgeCell
} from '@/components/data-table/DataTable.component';
import { AddRemoveAction } from '@/models/SubaccountingPlanFund.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { userService } from '@/services/User.service';
import DOMInteraction from '@/utils/DOMInteraction';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Link, Paper, Stack, Typography } from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { ColDef, RowNode } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useMemo, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import CustodianFundsDetailCellRenderer from './CustodianFundsDetailCellRenderer.component';
import {
  ACTIVE_SATAUS,
  CustodianFund,
  INACTIVE_SATAUS,
  NEW_SATAUS,
  PENDING_ACTIVATION_SATAUS,
  PENDING_DEACTIVATION_SATAUS
} from './CustodianFundsTabContent.componet';

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      width: '100%'
    }
  })
);

interface CustodianFundsTableProps {
  submitPlanFundCallback: (
    cusip: string,
    addRemove: AddRemoveAction,
    notes?: string
  ) => void;
  custodianFunds: CustodianFund[];
}

const CustodianFundsTable = (props: CustodianFundsTableProps): JSX.Element => {
  const { submitPlanFundCallback, custodianFunds } = props;
  const [jwt] = useState(userService.getAccessToken());
  const gridRef = useRef<AgGridReact>(null);
  const classes = useStyles();
  const detailCellRenderer = useMemo(() => {
    const setRequestNote = (cusip: string, note?: string) => {
      const index = custodianFunds.findIndex(item => item.cusip === cusip);
      gridRef?.current?.api?.applyTransaction({
        update: [{ ...custodianFunds[index], requestNote: note }]
      });
    };
    return CustodianFundsDetailCellRenderer(gridRef, setRequestNote);
  }, [custodianFunds]);

  const statusColorMap = new Map<
    string,
    'info' | 'neutral' | 'success' | 'warning'
  >([
    [NEW_SATAUS, 'warning'],
    [PENDING_ACTIVATION_SATAUS, 'warning'],
    [ACTIVE_SATAUS, 'success'],
    [PENDING_DEACTIVATION_SATAUS, 'neutral'],
    [INACTIVE_SATAUS, 'neutral']
  ]);

  const columnDefs: ColDef[] = [
    {
      cellRenderer: (cellData: { data: CustodianFund }) => {
        const symbol = cellData.data.symbol;
        const cusip = cellData.data.cusip;
        const fundName = cellData.data.fundName;
        const isProgram = cellData.data.isProgram;
        return (
          <>
            <Stack alignItems='center' direction='row' spacing={1}>
              <Link
                component={RouterLink}
                target='_blank'
                to={`/api/fi360?securityId=${cellData.data.secId}&jwt=${jwt}`}
                variant='body1'>
                {symbol} | {cusip}
              </Link>{' '}
              {!isProgram && <Badge color='neutral'>Not in program</Badge>}
            </Stack>
            <Typography sx={{ color: grey[700] }} variant='body2'>
              {fundName}
            </Typography>
          </>
        );
      },
      field: 'cusip',
      headerName: 'Security',
      minWidth: 75,
      suppressMenu: true
    },
    {
      cellRenderer: (cellData: { data: CustodianFund }) => {
        return (
          <DataTableBadgeCell color={statusColorMap.get(cellData.data.status)}>
            {cellData.data.status}
          </DataTableBadgeCell>
        );
      },
      field: 'status',
      headerName: 'Setup Status',
      minWidth: 75,
      suppressMenu: true
    },
    {
      field: 'custodianAccountNumber',
      headerName: 'Fund Company Account Number',
      suppressMenu: true
    },
    {
      cellRenderer: (cellData: { data: CustodianFund; node: RowNode }) => {
        if (cellData.data.responseNote) {
          const row = document.querySelectorAll(
            `[row-id="${cellData.data.cusip}"]`
          )[1];
          const noteIcons = row.getElementsByTagName('svg');
          for (const cell of noteIcons) {
            cell.style.color = `${blue[500]}`;
          }
        }
        return <></>;
      },
      field: 'cusip',
      headerName: '',
      maxWidth: 75,
      minWidth: 75,
      suppressMenu: true
    },
    {
      cellRenderer: (cellData: { data: CustodianFund }) => {
        const isNew = cellData.data.status === NEW_SATAUS;
        const isActive = cellData.data.status === ACTIVE_SATAUS;
        return (
          <AccessControl
            requires={[FeatureLevelPermissions.WRITE_INVESTMENTS_MODIFY]}>
            {isNew && (
              <Button
                onClick={() =>
                  submitPlanFundCallback(
                    cellData.data.cusip,
                    AddRemoveAction.add,
                    cellData.data.requestNote
                  )
                }
                variant='text'>
                Submit
              </Button>
            )}
            {isActive && (
              <Button
                onClick={() =>
                  submitPlanFundCallback(
                    cellData.data.cusip,
                    AddRemoveAction.remove
                  )
                }
                variant='text'>
                Deactivate
              </Button>
            )}
            {!isNew && !isActive && ' '}
          </AccessControl>
        );
      },
      maxWidth: 150,
      minWidth: 75,
      suppressMenu: true
    }
  ];

  return (
    <>
      <Paper className={classes.paper} elevation={0}>
        <DataTable
          animateRows={true}
          columnDefs={columnDefs}
          columnSizing='fit'
          data-testid='data-custodian-funds-table'
          detailCellRenderer={detailCellRenderer}
          detailColumn={3}
          detailRowAutoHeight
          emptyPlaceholderComponent={
            <Stack
              alignItems='center'
              data-testid='no-data-custodian-funds-table'
              justifyContent='center'
              sx={{ height: '100%' }}>
              <CardPlaceholder
                icon={<SearchIcon fontSize='inherit' />}
                subtitle='No related Custodian Funds found.'
              />
            </Stack>
          }
          gridRef={gridRef}
          icons={{
            groupContracted: DOMInteraction.JSXToHTML(
              <NoteAltOutlinedIcon
                className='mui-group-icon'
                color='disabled'
                fontSize='small'
              />
            ),
            groupExpanded: DOMInteraction.JSXToHTML(
              <NoteAltOutlinedIcon
                className='mui-group-icon'
                color='disabled'
                fontSize='small'
              />
            )
          }}
          pageSize={25}
          pagination
          paginationPosition='bottom'
          paginationSource='client'
          paginationSx={{ py: 0.375 }}
          primaryKey='cusip'
          rowData={custodianFunds}
          rowHeight={56}
          suppressCellFocus
        />
      </Paper>
    </>
  );
};

export default CustodianFundsTable;
