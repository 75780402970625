import formatters from '@/utils/Formatters';
import { ArrowDropDown } from '@mui/icons-material';
import { AppBar, Box, MenuItem, Popover, Tab, Tabs } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import React, { useEffect } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel: React.FunctionComponent<TabPanelProps> = (
  props: TabPanelProps
) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role='tabpanel'
      {...other}>
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
};

const a11yProps = (label: string) => {
  return {
    'aria-controls': `simple-tabpanel-${label}`,
    id: `simple-tab-${label}`
  };
};

const getHashLabel = (label: string) =>
  `#${label.toLowerCase().split(' ').join('-')}`;

const useStyles = makeStyles((theme: Theme) => ({
  customTabStyle: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  indicator: {
    backgroundColor: theme.palette.info.main
  },
  root: {
    backgroundColor: 'transparent',
    flexGrow: 1
  },
  tabBar: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    marginBottom: theme.spacing(3)
  },
  tabPanelWrapper: {
    padding: 0
  }
}));

interface TabData {
  component: JSX.Element | null;
  label: string;
  path?: string;
  isDropDown?: boolean;
  dropdownOptions?: { label: string; onClick: () => void }[];
  hash?: string;
}

interface SimpleTabsProps extends React.HTMLAttributes<HTMLBaseElement> {
  tabsAriaLabel: string;
  tabs: TabData[];
  defaultTabIdx?: number;
  'data-testid'?: string;
  isCustomAppBar?: boolean;
  disableStateInUrl?: boolean;
  enableNestedHash?: boolean;
  onChange?: (newValue: any) => void;
  parentTabPath?: string; // this param is necessary in case we use simple tabs nested in another simple tabs component
}

const SimpleTabs: React.FunctionComponent<SimpleTabsProps> = (
  props: SimpleTabsProps
) => {
  const classes = useStyles();
  const {
    tabsAriaLabel,
    tabs = [],
    defaultTabIdx,
    'data-testid': dataTestId,
    isCustomAppBar,
    style,
    disableStateInUrl = false,
    enableNestedHash = false,
    onChange
  } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const getTabIndex = () => {
    const tabIdx = tabs.findIndex(tab => {
      if (!location.pathname.includes('//')) {
        // TODO: Review Simple tabs implementations searching for the paths that are different to their label data test version just so the new matchPat implementation don't change the functionality and we remove the old label matching and use the matchPath
        /**
         * Example
         *
         * Before
         * {label:'Some Label', path: 'some-label'} Good
         * {label:'Some Label', path: 'different-path'} Bad
         * it will not allow you to have a specific route, it always has to be the data tests id version of the label, otherwise the tabs got confused
         *
         * matchPath changes
         * {label:'Some Label', path: 'some-label'} Good
         * {label:'Some Label', path: 'different-path'} Also Good
         */
        return (
          (tab?.path && !!matchPath(tab.path, location.pathname)) ||
          location.pathname
            .split('/')
            .includes(formatters.textToDataTestId(tab.label.toLowerCase()))
        );
      } else {
        return false;
      }
    });
    return tabIdx;
  };

  const tabIdx = defaultTabIdx !== undefined ? defaultTabIdx : getTabIndex();
  const [value, setValue] = React.useState(tabIdx < 0 ? 0 : tabIdx);
  const [pastTabs, setPastTabs] = React.useState(tabs);
  const [tabClicked, setTabClicked] = React.useState(false);

  useEffect(() => {
    const tabIndexFromLocation = tabs.findIndex(tab => {
      return enableNestedHash
        ? location.hash === getHashLabel(tab.hash)
        : location.pathname === tab.path;
    });
    if (tabIndexFromLocation >= 0) {
      setValue(tabIndexFromLocation);
      onChange?.(tabIndexFromLocation);
    }
  }, [location]);

  useEffect(() => {
    let navigateTo = tabs[value]?.path;

    if (!disableStateInUrl && tabs[0]?.path !== pastTabs[0]?.path) {
      setPastTabs(tabs);
      navigateTo = tabs[0]?.path;
      setValue(0);
      return navigate(navigateTo as string, { replace: true });
    } else if (!tabs.some(tab => tab.path?.includes(location.pathname))) {
      return;
    }

    if (
      navigateTo &&
      !tabClicked &&
      location.pathname !== navigateTo &&
      tabIdx !== -1 &&
      !disableStateInUrl &&
      !enableNestedHash
    ) {
      const index = tabs.findIndex(tab =>
        tab.path?.includes(location.pathname)
      );
      setValue(index);
      onChange?.(index);
    } else if (
      navigateTo &&
      (tabClicked || tabIdx === -1) &&
      location.pathname !== navigateTo &&
      !disableStateInUrl &&
      !enableNestedHash
    ) {
      setTabClicked(false);
      navigate(navigateTo as string, { replace: true });
    }
  }, [disableStateInUrl, navigate, value, tabs, enableNestedHash]);

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: any
  ) => {
    setValue(newValue);
    setTabClicked(true);
    if (onChange) {
      onChange(newValue);
    }
  };

  useEffect(() => {
    if (!enableNestedHash) return;

    // handle hash changes in the URL
    if (location.hash) {
      const cleanedLabel = location.hash.replace('#', '').replace(/-/g, ' ');
      const index = tabs.findIndex(
        tab => tab.label.toLowerCase() === cleanedLabel.toLowerCase()
      );

      if (index !== -1 && index !== value) {
        setValue(index);
        onChange?.(index);
      }
    } else {
      // if there's no hash, possibly navigate to the default tab
      if (props.parentTabPath || value === defaultTabIdx || value === 0) {
        navigate(
          `${props.parentTabPath || location.pathname}${getHashLabel(tabs[value].hash)}`,
          { replace: true }
        );
      }
    }
  }, []);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root} data-testid={dataTestId}>
      <AppBar
        className={
          !isCustomAppBar ? classes.tabBar : classes.customTabStyle || ''
        }
        color='transparent'
        elevation={0}
        position='static'
        style={{ ...style }}>
        <Tabs
          aria-label={tabsAriaLabel}
          classes={{
            indicator: classes.indicator
          }}
          color='transparent'
          onChange={handleChange}
          scrollButtons='auto'
          value={value}
          variant='scrollable'>
          {tabs.map((tab: TabData) => {
            return tab.isDropDown ? (
              <Tab
                key={`tab-header-${formatters.textToDataTestId(tab.label)}`}
                label={tab.label}
                {...a11yProps(tab.label)}
                icon={<ArrowDropDown />}
                iconPosition='end'
                onClick={handleClick}
                sx={{ minHeight: 'auto' }}
              />
            ) : (
              <Tab
                href={enableNestedHash ? getHashLabel(tab.hash) : ''}
                key={`tab-header-${formatters.textToDataTestId(tab.label)}`}
                label={tab.label}
                {...a11yProps(tab.label)}
              />
            );
          })}
        </Tabs>
      </AppBar>
      {tabs.map((tab: TabData, idx: number) => {
        const { component: Component } = tab;
        return (
          <TabPanel
            index={idx}
            key={`tab-${formatters.textToDataTestId(tab.label)}`}
            value={value}>
            <section className={classes.tabPanelWrapper}>{Component}</section>
          </TabPanel>
        );
      })}

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        onClose={handleClose}
        open={!!anchorEl}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top'
        }}>
        {(tabs[value]?.dropdownOptions || []).map(i => {
          return (
            <MenuItem
              key={`popover-item-${i.label}`}
              onClick={() => {
                i.onClick();
                handleClose();
              }}
              sx={{ minWidth: 270 }}>
              {i.label}
            </MenuItem>
          );
        })}
      </Popover>
    </div>
  );
};

export default SimpleTabs;
export type { SimpleTabsProps, TabData };
