import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { TaxDetails } from '@/models/Withdrawals.model';
import { Typography } from '@mui/material';

interface TaxDetailsStackProps {
  taxDetails: TaxDetails;
  isRoth?: boolean;
}

const TaxDetailsStack: React.FunctionComponent<TaxDetailsStackProps> = (
  props: TaxDetailsStackProps
) => {
  const { taxDetails, isRoth = false } = props;
  return (
    <TextStack direction='column' textLabelColumnWidth='160px'>
      <Typography sx={{ fontSize: 15, fontWeight: 500 }}>
        Tax Details
      </Typography>
      <TextStackItem>
        <TextLabel>Federal Withholding %</TextLabel>
        <TextValue data-testid='federal-withholding-percent'>{`${+taxDetails.federalWithholdingPercent}%`}</TextValue>
      </TextStackItem>
      <TextStackItem>
        <TextLabel>State Withholding %</TextLabel>
        <TextValue data-testid='state-withholding-percent'>{`${taxDetails.stateWithholdingPercent}%`}</TextValue>
      </TextStackItem>
      <TextStackItem>
        <TextLabel>State Withholding Code</TextLabel>
        <TextValue data-testid='state-withholding-code'>
          {taxDetails.stateWithholdingCode ?? null}
        </TextValue>
      </TextStackItem>
      <TextStackItem>
        <TextLabel>1099 Code</TextLabel>
        <TextValue data-testid='1099-code'>
          {taxDetails._1099Code ?? null}
        </TextValue>
      </TextStackItem>
      {isRoth && (
        <>
          <TextStackItem>
            <TextLabel>Roth Basis</TextLabel>
            <TextValue>{taxDetails.rothBasis ?? null}</TextValue>
          </TextStackItem>
          <TextStackItem>
            <TextLabel>Initial Roth Year</TextLabel>
            <TextValue data-testid='roth-initial-year'>
              {taxDetails.rothInitialYear ?? null}
            </TextValue>
          </TextStackItem>
          <TextStackItem>
            <TextLabel>Qualified Roth</TextLabel>
            <TextValue data-testid='roth-qualified'>
              {taxDetails.rothQualifiedWithdrawal !== undefined
                ? `${taxDetails.rothQualifiedWithdrawal}`
                : null}
            </TextValue>
          </TextStackItem>
        </>
      )}
    </TextStack>
  );
};

export default TaxDetailsStack;
