import { ArrowDropDown } from '@mui/icons-material';
import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from '@mui/material';

import React, { FC } from 'react';

type SplitButtonProps = {
  options?: {
    label: string | number;
    value: string | number;
    dataTestId?: string;
  }[];
  onClick?: () => void;
  onMenuItemClick: (index: number, value: string | number) => void;
  label: string;
  dataTestId?: string;
  disabled?: boolean;
  variant?: ButtonGroupProps['variant'];
  initialIndex?: number;
};

const SplitButton: FC<SplitButtonProps> = props => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(props.initialIndex);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleMenuItemClick = (index: number, value: string | number) => {
    setSelectedIndex(index);
    setOpen(false);
    props.onMenuItemClick(index, value);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        aria-label='split button'
        disabled={props.disabled}
        ref={anchorRef}
        variant={props.variant}>
        <Button
          data-testid={props.dataTestId}
          disabled={props.disabled}
          onClick={props.onClick}>
          {props.label}
        </Button>
        <Button
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='menu'
          aria-label='select merge strategy'
          data-testiid={`${props.dataTestId}-open-selectmenu`}
          disabled={props.disabled}
          onClick={handleToggle}
          size='small'>
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Popper
        anchorEl={anchorRef.current}
        disablePortal
        open={open}
        role={undefined}
        sx={{
          zIndex: 1
        }}
        transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem id='split-button-menu'>
                  {props.options?.map((option, index) => (
                    <MenuItem
                      data-testid={
                        option.dataTestId ||
                        option.value
                          .toString()
                          .replace(/\s/g, '_')
                          .toLowerCase()
                      }
                      key={option.label}
                      onClick={() => handleMenuItemClick(index, option.value)}
                      selected={index === selectedIndex}>
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

SplitButton.defaultProps = {
  initialIndex: 0
};

export default SplitButton;
