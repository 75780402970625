import { GroupsWithDates } from '@/routes/plans/plan-detail/PlanDocumentsTab/PlanDocumentsGroup.component';

import * as yup from 'yup';

import { getUniqueEffectivePerDocumentGroups } from './UploadPlanDocValidationSchema.schema';

export function getBulkUploadPlanDocValidationSchema(
  groups: GroupsWithDates[],
  requiredDocumentKey?: string
): yup.AnyObjectSchema {
  return yup.object().shape({
    documentKeys: yup
      .array()
      .of(yup.string())
      .required()
      .test(
        'unique-document-keys',
        'Document keys must be unique',
        value => new Set(value).size === value.length
      )
      .test(
        'required-document-key',
        `Document key ${requiredDocumentKey} is required`,
        value =>
          requiredDocumentKey ? value.includes(requiredDocumentKey) : true
      ),
    effectiveDate: getUniqueEffectivePerDocumentGroups(groups)
  });
}
