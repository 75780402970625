import LinearLoading from '@/components/linear-loading';
import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { PlanService } from '@/services/Plan.service';
import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';

import React from 'react';

type PlanSalesforceInfoProps = {
  sponsorPlanId: number;
};

const StyledStack = styled(Stack)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  borderTop: `1px solid ${theme.palette.grey[300]}`,
  margin: theme.spacing(3, 0)
}));

export const PlanSalesforceInfo: React.FC<PlanSalesforceInfoProps> = props => {
  const planSalesforceInfoQuery = useQuery({
    queryFn: () => PlanService.getPlanSalesforceInfo(props.sponsorPlanId),
    queryKey: ['PlanService.getPlanSalesforceInfo', props.sponsorPlanId]
  });

  return (
    <StyledStack data-testid='plan-salesforce-info'>
      <LinearLoading fadeIn={planSalesforceInfoQuery.isLoading} />

      <TextStack divider rowColumnWidth='dynamic' sx={{ px: 2, py: 3 }}>
        <TextStackItem>
          <TextLabel>Implementation Manager Name:</TextLabel>
          <TextValue data-testid='implementation-manager-name-text-value'>
            {planSalesforceInfoQuery.data?.implementationManager?.name}
          </TextValue>
        </TextStackItem>

        <TextStackItem>
          <TextLabel>Implementation Manager Email:</TextLabel>
          <TextValue data-testid='implementation-manager-email-text-value'>
            {planSalesforceInfoQuery.data?.implementationManager?.email}
          </TextValue>
        </TextStackItem>

        {planSalesforceInfoQuery.data?.holdStatus === 'On Hold' && (
          <TextStackItem>
            <TextLabel>Hold Status:</TextLabel>
            <TextValue data-testid='hold-status-text-value'>
              {planSalesforceInfoQuery.data?.holdStatus}
            </TextValue>
          </TextStackItem>
        )}

        <TextStackItem>
          <TextLabel>Plan Design Consultation Type:</TextLabel>
          <TextValue data-testid='plan-design-consultation-type-text-value'>
            {planSalesforceInfoQuery.data?.planDesignConsultationType}
          </TextValue>
        </TextStackItem>
      </TextStack>
    </StyledStack>
  );
};
