import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUserToken } from '@/contexts/UserTokenContext';
import ParentAccountService from '@/services/ops/accounts/ParentAccount.service';
import { useQuery } from '@tanstack/react-query';

import { AxiosError } from 'axios';

export const useParentAccountById = (params: { accountId?: string }) => {
  const { showSnackbar } = useSnackbar();
  const { userHasValidToken } = useUserToken();

  return useQuery(
    ['ParentAccountService.getById', params.accountId],
    () => ParentAccountService.getById(params.accountId),
    {
      enabled: !!(params.accountId && userHasValidToken),
      onError: (err: AxiosError) => {
        showSnackbar({
          message: `Failed to fetch Parent Account: ${err.response?.data ? err.response.data : err.message}`,
          severity: 'error'
        });
      }
    }
  );
};
