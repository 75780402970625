import AccessControl from '@/components/access-control/AccessControl.component';
import TooltipButton from '@/components/tool-tip-button';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { WorkflowProcessEventResponse } from '@/models/YearEndTestingDTO.model';
import { PlanService } from '@/services/Plan.service';
import { Check } from '@mui/icons-material';
import { TableCell, TableRow } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import React, { FC, useCallback } from 'react';

interface CompliancePackageRowProps {
  planId: number;
  onCompleteStep: (action: string) => void;
  event: WorkflowProcessEventResponse | undefined;
  completedEvents: (string | undefined)[];
}

export const CompliancePackageRow: FC<CompliancePackageRowProps> = props => {
  const { showSnackbar } = useSnackbar();

  const postCompliancePackageMutation = useMutation(
    () => PlanService.sendYETCompliancePackage(props.planId),
    {
      onError: () => {
        showSnackbar({
          message: 'Something went wrong!',
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: 'Compliance package has been emailed to sponsor!',
          severity: 'success'
        });
        props.onCompleteStep('completeCompliancePackage');
      }
    }
  );

  const sendPackage = useCallback(() => {
    postCompliancePackageMutation.mutate();
  }, [postCompliancePackageMutation]);

  return (
    <TableRow data-testid='compliance-package-row'>
      <TableCell />
      <TableCell align='center' padding='none'>
        {!!props.event && (
          <Check color='primary' data-testid='compliance-package-checkmark' />
        )}
      </TableCell>
      <TableCell>Compliance Package</TableCell>
      <TableCell data-testid='compliance-package-initiatedBy'>
        {props.event?.initiatedBy?.id}
      </TableCell>
      <TableCell>{props.event?.createdAt}</TableCell>
      <TableCell padding='none'>
        <AccessControl
          requires={[FeatureLevelPermissions.WRITE_ANNUAL_TESTING_ACTIONS]}>
          <TooltipButton
            disabled={
              postCompliancePackageMutation.isLoading ||
              !props.completedEvents?.includes('testExecutionCompleted')
            }
            handleOnClick={sendPackage}
            size='medium'
            testId='send-compliance-package-btn'
            tooltipMessage={
              props.completedEvents?.includes('testExecutionCompleted')
                ? ''
                : 'Please complete the previous step'
            }>
            SEND COMPLIANCE PACKAGE
          </TooltipButton>
        </AccessControl>
      </TableCell>
    </TableRow>
  );
};
