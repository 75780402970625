import { LookupOtherDataParams } from '@/models/ops/surpas/Surpas.model';
import ApiService from '@/services/Api.service';

export class SurpasService {
  static async getEmployerData(repId: string) {
    return ApiService.getJson('/surpas/employer', {
      repId
    });
  }

  static async getEmployeeData(participantId: string, programCode: string) {
    return ApiService.getJson('/surpas/employee', {
      participantId,
      programCode
    });
  }

  static async getOtherData(params: LookupOtherDataParams) {
    return ApiService.getJson('/surpas/other', {
      ...params
    });
  }
}
