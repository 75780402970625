import { useSnackbar } from '@/contexts/SnackBarContext';
import { PlanService } from '@/services/Plan.service';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import React, { FC, useCallback, useEffect } from 'react';

type RetryProps = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  type: 'ach' | 'advancedCorrectionRequests' | 'tradeRequests';
  planId: number;
  ucid: string;
};

const TYPES = {
  ach: 'ACH',
  advancedCorrectionRequests: 'Correction Requests',
  tradeRequests: 'Trade Requests'
};

export const Retry: FC<RetryProps> = props => {
  const { showSnackbar } = useSnackbar();

  const retryContribution = useMutation(() => {
    if (props.type === 'advancedCorrectionRequests') {
      return PlanService.retryAdvancedCorrectionRequests(
        props.planId,
        props.ucid
      );
    }

    if (props.type === 'ach') {
      return PlanService.retryAch(props.planId, props.ucid);
    }

    if (props.type === 'tradeRequests') {
      return PlanService.retryTradeRequests(props.planId, props.ucid);
    }
  });

  const onSubmit = useCallback(async () => {
    props.onClose();
    retryContribution.mutate();
    props.onSuccess();
  }, [retryContribution, props]);

  useEffect(() => {
    if (retryContribution.isError) {
      showSnackbar({
        message: 'Error: retry did not iniate',
        severity: 'error'
      });
    }
  }, [retryContribution.isError, showSnackbar]);

  useEffect(() => {
    if (retryContribution.isSuccess) {
      showSnackbar({
        message: 'Retry operation added to queue',
        severity: 'success'
      });
    }
  }, [retryContribution.isSuccess, showSnackbar]);

  return (
    <Dialog onClose={props.onClose} open={props.isOpen}>
      <DialogTitle>{`Are you sure you want to retry the contribution ${TYPES[props.type]}?`}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will only re-send requests to the subaccounting system that
          either have not been sent or failed to send. Requests that were
          already sent successfully will not be sent again. If an error occurred
          during the subaccounting system processing of the contribution, that
          error will need to be resolved via the Alerts page or the Conductor
          Flow details page.”
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button data-testid='retry-cancel' onClick={props.onClose}>
          Go Back
        </Button>
        <Button data-testid='retry-submit' onClick={onSubmit}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
