import AccessControl from '@/components/access-control/AccessControl.component';
import CopyToClipboard from '@/components/copy-to-clipboard';
import { EditAdvisorModal } from '@/components/entity-modals/EntityModals.component';
import NavigationBreadcrumbs from '@/components/navigation-breadcrumbs';
import TooltipButton from '@/components/tool-tip-button/TooltipButton.component';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { AdvisorDto, FirmDto } from '@/models';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import UserManagementService from '@/services/UserManagement.service';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import React from 'react';

interface AdvisorDetailsHeaderProps {
  advisor: AdvisorDto['data'];
  firm: FirmDto['data'];
}

const useStyles = makeStyles((theme: Theme) => ({
  copyToClipboardText: {
    color: theme.palette.text.secondary
  }
}));

const AdvisorDetailsHeader: React.FC<AdvisorDetailsHeaderProps> = ({
  advisor = {},
  firm = {}
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      data-testid='advisor-details-header'
      direction='column'
      mb='2rem'>
      <Grid item>
        <Grid container justifyContent='space-between' mb='2rem'>
          <Grid item>
            <Grid container direction='column'>
              <NavigationBreadcrumbs
                paths={[
                  { name: 'All Firms', to: '/firms' },
                  {
                    name:
                      firm.attributes?.companyName || EMPTY_FIELD_PLACEHOLDER,
                    to: `/firms/${firm.id}`
                  }
                ]}
              />

              <Typography variant='h4'>{advisor.attributes?.name}</Typography>

              <Grid alignItems='center' container>
                <Typography
                  className={classes.copyToClipboardText}
                  data-testid='advisor-details-header-title'
                  variant='subtitle1'>
                  ID: {advisor.id}
                </Typography>
                <Grid item ml='0.5rem'>
                  <CopyToClipboard
                    copyName='ID'
                    copyValue={String(advisor.id)}
                    size='small'
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container direction='row' justifyContent='space-between'>
              <Grid item mr='1rem'>
                <AccessControl
                  requires={[FeatureLevelPermissions.WRITE_MASQUERADE_ADVISOR]}>
                  <TooltipButton
                    handleOnClick={() => {
                      const url = UserManagementService.getSentinelRedirectUrl(
                        'advisor',
                        advisor.id as number
                      );
                      window.open(url, '_blank');
                    }}
                    testId='advisor-view-portal-btn'
                    variant='contained'>
                    View Portal
                  </TooltipButton>
                </AccessControl>
              </Grid>

              <Grid item>
                <AccessControl hideFromTPA>
                  <EditAdvisorModal
                    advisorId={advisor.id as number}
                    firmId={firm.id}
                  />
                </AccessControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdvisorDetailsHeader;
