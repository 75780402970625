import CardInfoField from '@/components/card-info-field';
import FileUploadTable from '@/components/file-upload-table/FileUploadTable.component';
import LinearLoading from '@/components/linear-loading';
import SimpleTabs, { TabData } from '@/components/simple-tabs';
import { CONVERSION_TYPES, DOCUMENT_TYPES } from '@/consts/uploads';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { Conversion, ConversionStatus } from '@/models/ConversionDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { NumericDollarFormat } from '@/routes/participants/participant-detail/NumericDollarFormat';
import ActionTableForm from '@/routes/plans/plan-detail/PlanActionTableV2/ConversionMainComponents/ActionTableForm.component';
import ParentAccountService from '@/services/ops/accounts/ParentAccount.service';
import TransactionService from '@/services/ops/transactions/Transaction.service';
import { PlanService } from '@/services/Plan.service';
import { userService } from '@/services/User.service';
import formatters from '@/utils/Formatters';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Theme
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useQuery } from '@tanstack/react-query';
import { formatSsn } from '@vestwell-frontend/helpers';

import Decimal from 'decimal.js';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import ConversionCardButton from './ConversionMainComponents/ConversionCardButton.component';
import ConversionDialog from './ConversionMainComponents/ConversionDialog';
import ConversionDialogHeader from './ConversionMainComponents/ConversionDialogHeader.component';
import ConversionRequestsTab from './ConversionRequestsTab.component';
import { useConversionAgGrid } from './useConversionAgGrid';
import { useConversionFile } from './useEditFile';
import useUploadOnboardingAgGridFile from './useUploadOnboardingAgGridFile.hook';

interface CashAllocationButtonProps {
  sponsorPlanId: number;
  conversion?: Conversion;
}

const columnDefs = [
  {
    alternates: [
      'social',
      'SS #',
      'ss#',
      'social security number',
      'social_security_number',
      'social security',
      'ssn#',
      'Taxpayer ID (SSN or Fed ID)',
      'socialsecuritynumber',
      'employee ssn',
      'national_identifier',
      'employee social',
      'national identifier',
      'ssn number',
      'ss number',
      'ssn/fein',
      'Social Security Number'
    ],
    cellEditorParams: {
      showExcludeRowButton: true
    },
    editable: true,
    field: 'ssn',
    headerName: 'SSN',
    minWidth: 250,
    required: true,
    type: 'ssn',
    valueParser: (value: string) => {
      if (/^(?!(000))\d{3}(?!00)\d{2}(?!0000)\d{4}$/.test(value)) {
        return formatSsn(value);
      }
      return value;
    }
  },
  {
    editable: true,
    field: 'fundingSource',
    headerName: 'Funding Source',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'text'
  },
  {
    editable: true,
    field: 'balance',
    headerName: 'Balance',
    minWidth: 250,
    required: true,
    resizable: true,
    type: 'currency',
    valueParser: (value: string) => {
      if (isNaN(parseFloat(value))) return value;
      const decimalValue = new Decimal(parseFloat(value.replace(/,/g, '')));
      return decimalValue.toFixed(2);
    }
  }
];

const submitButtonText = (status?: string) => {
  if (status === ConversionStatus.Draft || !status)
    return 'Submit for approval';
  else if (status === ConversionStatus.Failed) return 'Resubmit';
  else return 'Approve & Submit';
};
const EMPTY_ROWS = 10;

const CashAllocation: React.FunctionComponent<CashAllocationButtonProps> = ({
  sponsorPlanId,
  conversion
}: CashAllocationButtonProps) => {
  const [openPlanActionDialog, setOpenPlanActionDialog] = useState(false);
  const [openBalanceDialog, setOpenBalanceDialog] = useState(false);
  const [cashFields, setCashFields] = useState<{
    forfeiture?: number;
    suspense?: number;
  }>({ ...conversion?.context });
  const [errors, setError] = useState<any>({});
  const [tabIndex, setTabIndex] = useState(0);
  const conversionAgGrid = useConversionAgGrid(sponsorPlanId);
  const { showSnackbar } = useSnackbar();
  const userFileNameRef = useRef('');
  const userInfo = userService.getUser();
  const isUploadingUser = userInfo?.nickname === conversion?.submittedBy?.id;
  const { onClicked: editFileClicked, isLoading: isLoadingEditFile } =
    useConversionFile(
      conversionAgGrid.handleLoad,
      sponsorPlanId,
      CONVERSION_TYPES.CASH_ALLOCATION,
      conversion?.documentId,
      columnDefs
    );

  const {
    mutateAsync: uploadCashAllocation,
    isLoading,
    error
  } = useUploadOnboardingAgGridFile(
    sponsorPlanId as number,
    DOCUMENT_TYPES.CASH_ALLOCATION,
    userFileNameRef.current
  );

  const uniqueParticipantsCount = useMemo(() => {
    const ssnList = new Set();
    conversionAgGrid.gridRows.forEach((row: any) => {
      if (row.ssn) ssnList.add(row.ssn);
    });
    return ssnList.size;
  }, [conversionAgGrid.gridRows]);

  const totalBalance = useMemo(() => {
    const rowsTotal = conversionAgGrid.gridRows.reduce(
      (acc: Decimal, value: any) => {
        if (isNaN(value.balance)) {
          return acc;
        }
        return Decimal.add(+value.balance, acc);
      },
      new Decimal(0)
    ) as Decimal;

    return rowsTotal
      .add(
        new Decimal(
          cashFields?.forfeiture && !isNaN(cashFields?.forfeiture)
            ? cashFields?.forfeiture
            : 0
        )
      )
      .add(
        new Decimal(
          cashFields?.suspense && !isNaN(cashFields?.suspense)
            ? cashFields?.suspense
            : 0
        )
      );
  }, [conversionAgGrid.gridRows, cashFields]);

  const { data: parentAccountSearch } = useQuery(
    [
      'ParentAccountService.searchParentAccounts',
      { accountType: undefined, custodianId: undefined, query: sponsorPlanId }
    ],
    () => {
      const result = ParentAccountService.searchParentAccounts({
        accountType: undefined,
        custodianId: undefined,
        query: `${sponsorPlanId}`
      });
      return result;
    },
    {
      staleTime: Infinity
    }
  );

  const accountId = parentAccountSearch?.length
    ? parentAccountSearch[0].parentAccountId
    : undefined;

  const planAmount = useQuery(
    ['TransactionService.getPlanBalance', accountId],
    () => {
      return TransactionService.getPlanBalance(accountId);
    },
    {
      enabled: accountId !== undefined,
      onError: (err: any) => {
        showSnackbar({
          message: `plan available balance calc failed: ${err?.message}`,
          severity: 'error'
        });
      }
    }
  );

  const planAvailableBalance = useMemo(() => {
    return planAmount?.data?.toDecimalPlaces(2) ?? new Decimal(0);
  }, [planAmount.data]);

  const investmentRequests = useQuery(
    [`PlanService.getInvestmentRequests ${conversion?.id}`],
    async () => {
      return PlanService.getInvestmentRequests(sponsorPlanId, conversion?.id);
    },
    {
      enabled: conversion?.id !== undefined
    }
  );

  const totalRequestedAmount = useMemo(() => {
    return investmentRequests.data
      ?.map(i => i.amount)
      .reduce((acc: Decimal, v: any) => {
        if (isNaN(v)) {
          return acc;
        }
        return Decimal.add(+v, acc);
      }, new Decimal(0)) as Decimal;
  }, [investmentRequests.data]);

  const getRowsWithPlanId = () => {
    return conversionAgGrid.getDataForUpload({ planId: sponsorPlanId });
  };

  const cashAllocationFileExists = async (
    fileName: string
  ): Promise<boolean> => {
    userFileNameRef.current = fileName;
    return PlanService.conversionFileExists(sponsorPlanId, fileName);
  };

  const isViewMode =
    conversion?.status && conversion.status !== ConversionStatus.Draft;

  const isDraftStage =
    conversion?.status === ConversionStatus.Draft || !conversion?.status;

  const isForfeitureInvalid = useMemo(
    () => cashFields?.forfeiture <= 0,
    [cashFields?.forfeiture]
  );
  const isSuspenseInvalid = useMemo(
    () => cashFields?.suspense <= 0,
    [cashFields?.suspense]
  );

  const makeTabsElement = () => {
    const fileContentTab: TabData = {
      component: (
        <Box sx={{ marginTop: (theme: Theme) => theme.spacing(-3) }}>
          <ActionTableForm
            emptyRowsCount={!conversion ? EMPTY_ROWS : undefined}
            initialValues={conversionAgGrid.gridRows}
            onChange={conversionAgGrid.setGridRows}
            onErrors={conversionAgGrid.handleErrors}
            onSubmit={conversionAgGrid.handleSubmit}
            ref={conversionAgGrid.form}
            validateOnChange
            validateOnMount
            validationSchema={
              conversion?.status && conversion.status !== ConversionStatus.Draft
                ? undefined
                : conversionAgGrid.validationSchema
            }>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack direction='row' mb={2} ml={2} mt={5} spacing={2}>
                <TextField
                  InputProps={{
                    inputComponent: NumericDollarFormat as any,
                    startAdornment: (
                      <InputAdornment position='start'>$</InputAdornment>
                    )
                  }}
                  disabled={isViewMode}
                  error={!!errors?.forfeiture}
                  helperText={errors?.forfeiture ?? ''}
                  id='forfeiture'
                  label='Forfeiture'
                  onChange={e => {
                    const value = parseFloat(e.target.value);

                    if (isNaN(value) || (!isNaN(value) && value > 0))
                      setError({ ...errors, forfeiture: '' });
                    else
                      setError({
                        ...errors,
                        forfeiture: 'Forfeiture must be greater than 0'
                      });
                    setCashFields({
                      ...cashFields,
                      forfeiture: value
                    });
                  }}
                  required
                  size='small'
                  value={cashFields.forfeiture}
                />
                <TextField
                  InputProps={{
                    inputComponent: NumericDollarFormat as any,
                    startAdornment: (
                      <InputAdornment position='start'>$</InputAdornment>
                    )
                  }}
                  disabled={isViewMode}
                  error={errors?.suspense}
                  helperText={errors?.suspense ?? ''}
                  id='suspense'
                  label='Suspense'
                  onChange={e => {
                    const value = parseFloat(e.target.value);

                    if (isNaN(value) || (!isNaN(value) && value > 0))
                      setError({ ...errors, suspense: '' });
                    else
                      setError({
                        ...errors,
                        suspense: 'Suspense must be greater than 0'
                      });
                    setCashFields({
                      ...cashFields,
                      suspense: value
                    });
                  }}
                  required
                  size='small'
                  value={cashFields.suspense}
                />
              </Stack>
              <FileUploadTable
                columnDefs={columnDefs}
                errors={conversionAgGrid.gridErrors}
                isViewMode={isViewMode}
                onCellChanged={conversionAgGrid.handleCellChange}
                onRowsChanged={conversionAgGrid.handleRowsChange}
                rowData={conversionAgGrid.gridRows}
              />
              {isLoadingEditFile && (
                <Box sx={{ mt: 2 }}>
                  <LinearLoading />
                </Box>
              )}
            </LocalizationProvider>
          </ActionTableForm>
        </Box>
      ),
      label: 'FILE CONTENT'
    };
    const conversionRequestsTab: TabData = {
      component:
        investmentRequests.data && investmentRequests.data.length > 0 ? (
          <ConversionRequestsTab data={investmentRequests.data} />
        ) : null,
      label: 'IC REQUESTS'
    };

    const registeredTabs =
      investmentRequests.data && investmentRequests.data.length > 0
        ? [fileContentTab, conversionRequestsTab]
        : [fileContentTab];

    return {
      tabs: registeredTabs,
      tabsAriaLabel: 'conversion-data-grid-tabs'
    };
  };

  const onChangeTabs = (newValue: any) => {
    setTabIndex(newValue);
  };

  const tabs = makeTabsElement();

  const handleSubmit = async () => {
    try {
      await uploadCashAllocation({
        conversionDto: {
          ...conversion,
          context: {
            ...conversion?.context,
            forfeiture: cashFields?.forfeiture,
            suspense: cashFields?.suspense
          },
          documentId:
            conversion?.status && conversion?.status !== ConversionStatus.Draft
              ? conversion.documentId
              : undefined,
          status:
            conversion?.status === ConversionStatus.Draft || !conversion?.status
              ? ConversionStatus.PendingApproval
              : ConversionStatus.PendingInvestment
        },
        results: getRowsWithPlanId()
      });
    } catch (e) {
      if (error) {
        showSnackbar({
          message: error?.message ?? 'Error',
          severity: 'error'
        });
      }
    } finally {
      setOpenPlanActionDialog(false);
      conversionAgGrid.form.current?.setValues([]);
    }
  };

  const handleSaveDraft = useCallback(async () => {
    try {
      await uploadCashAllocation({
        conversionDto: {
          ...conversion,
          context: {
            ...conversion?.context,
            forfeiture: cashFields?.forfeiture,
            suspense: cashFields?.suspense
          },
          documentId:
            conversion?.status && conversion?.status !== ConversionStatus.Draft
              ? conversion.documentId
              : undefined,
          status: ConversionStatus.Draft
        },
        results: getRowsWithPlanId()
      });
    } catch (e) {
      if (error) {
        showSnackbar({
          message: error?.message ?? 'Error',
          severity: 'error'
        });
      }
    } finally {
      setOpenPlanActionDialog(false);
      conversionAgGrid.form.current?.setValues([]);
    }
  }, [cashFields, conversionAgGrid.form.current, conversion]);

  return (
    <>
      {conversion ? (
        <ConversionCardButton
          conversion={conversion}
          disableRequestsBtn={
            !investmentRequests.data ||
            (investmentRequests.data && investmentRequests.data.length === 0)
          }
          isLoading={isLoadingEditFile}
          onClick={() => {
            setOpenPlanActionDialog(true);
            setTabIndex(0);
            editFileClicked();
          }}
          setTabIndex={i => {
            setOpenPlanActionDialog(true);
            setTabIndex(i);
            editFileClicked();
          }}
          sponsorPlanId={sponsorPlanId}
        />
      ) : (
        <LoadingButton
          disabled={!sponsorPlanId || isLoading}
          loading={isLoading}
          onClick={() => {
            setOpenPlanActionDialog(true);
          }}
          startIcon={<FileUploadOutlinedIcon />}>
          Upload
        </LoadingButton>
      )}
      <ConversionDialog
        fullWidth
        maxWidth='lg'
        onClose={() => {
          setOpenPlanActionDialog(false);
          conversionAgGrid.form.current?.setValues([]);
        }}
        open={openPlanActionDialog}>
        <DialogTitle>
          <ConversionDialogHeader
            checkFileNameExists={cashAllocationFileExists}
            columnDefs={columnDefs}
            conversion={conversion}
            onUpload={conversionAgGrid.handleLoad}
            title='Cash Allocation'
            viewMode={isViewMode}
          />
        </DialogTitle>
        <DialogContent>
          <SimpleTabs
            {...tabs}
            defaultTabIdx={tabIndex}
            onChange={onChangeTabs}
          />
        </DialogContent>
        <DialogActions>
          {tabIndex === 0 && conversionAgGrid.gridRows.length > 0 ? (
            <Grid container>
              <Stack
                direction='row'
                divider={<Divider flexItem orientation='vertical' />}
                spacing={1}>
                <CardInfoField
                  fieldName='Plan Available Balance'
                  fieldValue={formatters.formatDollars(
                    planAvailableBalance.toNumber(),
                    2
                  )}
                />
                <CardInfoField
                  fieldName='Number of rows'
                  fieldValue={conversionAgGrid.rowCount}
                />
                <CardInfoField
                  fieldName='Unique participants'
                  fieldValue={`${uniqueParticipantsCount}`}
                />
                <CardInfoField
                  fieldName='Total balance'
                  fieldValue={formatters.formatDollars(
                    totalBalance.toNumber(),
                    2
                  )}
                />
              </Stack>
            </Grid>
          ) : (
            <Grid container>
              <CardInfoField
                fieldName='Total Requested Amount'
                fieldValue={formatters.formatDollars(
                  totalRequestedAmount?.toNumber(),
                  2
                )}
              />
            </Grid>
          )}
          {((conversion?.status &&
            [ConversionStatus.PendingApproval, ConversionStatus.Draft].includes(
              conversion.status
            )) ||
            !conversion?.status) && (
            <>
              <LoadingButton
                disabled={conversionAgGrid.rowCount === 0}
                loading={isLoading}
                onClick={handleSaveDraft}
                sx={{ width: 200 }}
                variant='text'>
                {isDraftStage ? 'Save as Draft' : 'Revert to Draft'}
              </LoadingButton>
              {((isUploadingUser && isDraftStage) ||
                (!isUploadingUser &&
                  userService.hasPermission(
                    FeatureLevelPermissions.WRITE_UPLOADS
                  ))) && (
                <LoadingButton
                  disabled={
                    conversionAgGrid.errorsCount > 0 ||
                    conversionAgGrid.rowCount === 0 ||
                    isForfeitureInvalid ||
                    isSuspenseInvalid
                  }
                  loading={isLoading}
                  onClick={() => {
                    if (planAvailableBalance.equals(totalBalance))
                      handleSubmit();
                    else setOpenBalanceDialog(true);
                  }}
                  sx={{ width: 300 }}
                  variant='contained'>
                  {submitButtonText(conversion?.status)}
                </LoadingButton>
              )}
            </>
          )}
        </DialogActions>
      </ConversionDialog>
      <Dialog
        onClose={() => setOpenBalanceDialog(false)}
        open={openBalanceDialog}>
        <DialogTitle>Balance allocation confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {planAvailableBalance.greaterThan(totalBalance) ? (
              <>
                <Alert severity='warning'>
                  Allocated balance is less than available balance
                </Alert>
                <p>Do you want to proceed?</p>
              </>
            ) : (
              <Alert severity='error'>
                Allocated balance can not be more than available balance
              </Alert>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenBalanceDialog(false)}>Cancel</Button>
          <LoadingButton
            disabled={totalBalance.greaterThan(planAvailableBalance)}
            loading={isLoading}
            onClick={() => {
              handleSubmit();
              setOpenBalanceDialog(false);
            }}>
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CashAllocation;
