import { CellComponentProps } from '@/components/collapsible-table';
import { Box, TableCell, Theme, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

import React, { useMemo } from 'react';

import { FundLineupFields, FundLineupRow } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  rootCell: {
    fontSize: theme.spacing(2),
    paddingBottom: theme.spacing(0.75),
    paddingTop: theme.spacing(0.75)
  },
  securityContent: {
    color: blue[500],
    display: 'flex'
  }
}));

interface CellProps {
  row: FundLineupRow;
}

export const SecurityCell: React.FC<CellProps> = (props: CellProps) => {
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.securityContent}>
        <Box style={{ width: props.row.maxTickerLength * 12 + 'px' }}>
          {props.row[FundLineupFields.SECURITY].id}
        </Box>
        <Box sx={{ ml: 1 }}>| {props.row[FundLineupFields.SECURITY].cusip}</Box>
      </Box>

      <Typography variant='body2'>
        {props.row[FundLineupFields.SECURITY].fundName}
      </Typography>
    </Box>
  );
};

const FundLineupTableCell: React.FC<CellComponentProps> = (
  props: CellComponentProps
) => {
  const { row, column } = props;
  const classes = useStyles();

  const CellComponent = useMemo(() => {
    switch (column.field) {
      case FundLineupFields.SECURITY:
        return <SecurityCell row={row} />;
      default:
        return <>{row[column.field]}</>;
    }
  }, [column, row]);

  return (
    <TableCell className={classes.rootCell} component='td' scope='row'>
      {CellComponent}
    </TableCell>
  );
};

export default FundLineupTableCell;
