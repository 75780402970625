import { nonStateOptions } from '@/components/override-vestwell-eligibility-form/OverrideVestwellEligibilityForm.component';

import dayjs from 'dayjs';
import * as yup from 'yup';

interface EligibilityValidationParams {
  isStateIRA: boolean;
  isBNYUser: boolean;
  initialSurpasAccount: string;
  initialSurpasDupId: string;
  initialOverrideStatus?: boolean;
  initialEligibilityStatus?: string;
  initialNote?: string;
  isSurpasAccountShowed?: boolean;
  isOverrideFormShowed: boolean;
  terminationDate?: string;
}

const createParticipantEligibilityValidationSchema = ({
  isStateIRA,
  isBNYUser,
  initialSurpasAccount,
  initialSurpasDupId,
  initialOverrideStatus,
  initialEligibilityStatus,
  initialNote,
  isSurpasAccountShowed,
  isOverrideFormShowed,
  terminationDate
}: EligibilityValidationParams): yup.AnyObjectSchema => {
  const overrideFormSchema = yup
    .object({
      overrideForm: yup.object({
        checked: yup.boolean(),
        entryDate: yup.mixed(),
        note: yup.string(),
        status: yup
          .string()
          .test('Required', 'Eligibility Status is required', value => {
            if (isBNYUser) {
              return true;
            }
            return Boolean(value);
          })
          .test(
            'Termination attempt with no termination date set',
            'Must add termination date to account before termination status override',
            value =>
              !isStateIRA && !terminationDate
                ? !nonStateOptions.includes(value)
                : true
          )
      }),
      surpasAccount: yup
        .string()
        .test(
          'Check if surpas account field',
          'Surpas Account Number is required',
          value => {
            if (initialSurpasAccount === '--' && value === undefined)
              return true;
            if (isSurpasAccountShowed) {
              return Boolean(value);
            }
            return true;
          }
        )
        .test(
          'Check for digits and length',
          'Surpas Account Number must contain 10 digits',
          value => {
            if (initialSurpasAccount === '--' && value === undefined)
              return true;
            if (value) {
              return /^\d{10}$/.test(value);
            }
            return true;
          }
        )
    })
    .test('Check if note is required', (value, options) => {
      if (isBNYUser) return true;
      if (!value.overrideForm.checked) return true;
      const initialParticipantNote = initialNote || '';
      const noteValue = value.overrideForm.note || '';
      const isStatusOverriden =
        value.overrideForm.status !== initialEligibilityStatus;
      const isNoteOverriden = initialParticipantNote !== noteValue;
      if (
        isStateIRA &&
        (!initialOverrideStatus || isStatusOverriden || isNoteOverriden) &&
        noteValue.trim().length === 0
      ) {
        return options.createError({
          message: `Note Required`,
          path: 'overrideForm.note'
        });
      }
      if (
        !isStateIRA &&
        value.overrideForm.checked &&
        noteValue.trim().length === 0
      ) {
        return options.createError({
          message: `Note Required`,
          path: 'overrideForm.note'
        });
      }
      return true;
    })
    .test('Check if date is valid', (value, options) => {
      if (isBNYUser) return true;
      if (
        !dayjs(value.overrideForm.entryDate).isValid() ||
        dayjs(value.overrideForm.entryDate).year() < 1901
      ) {
        return options.createError({
          message: `Invalid Date`,
          path: 'overrideForm.entryDate'
        });
      }
      return true;
    });

  const surpasAccountValidationSchema = yup.object({
    dupAddrId: yup
      .string()
      .test('Check if DUP ID field', 'Surpas DUP ID is required', value => {
        if (initialSurpasDupId === '--' && value === undefined) return true;
        if (isSurpasAccountShowed) {
          return Boolean(value);
        }
        return true;
      }),
    surpasAccount: yup
      .string()
      .min(10, 'Surpas Account Number must contain 10 digits')
      .max(10, 'Surpas Account Number must contain 10 digits')
      .required('Surpas Account Number is required')
  });

  if (isOverrideFormShowed && isSurpasAccountShowed) {
    return overrideFormSchema.concat(surpasAccountValidationSchema);
  } else if (isSurpasAccountShowed && !isOverrideFormShowed) {
    return surpasAccountValidationSchema;
  }
  return overrideFormSchema;
};

export default createParticipantEligibilityValidationSchema;
