import { PlanService } from '@/services/Plan.service';
import { formatSsn } from '@vestwell-frontend/helpers';

import { isEmpty } from 'lodash';
import * as yup from 'yup';

const formattedSSN = /^(?!(000))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/;
const plainSSN = /^(?!(000))\d{3}(?!00)\d{2}(?!0000)\d{4}$/;
const blacklistedSSNs = ['123-45-6789', '987-65-4321'];

const yearsOfServiceValidationSchema = (
  planId: number
): yup.ArraySchema<yup.AnySchema> => {
  return yup
    .array()
    .of(
      yup
        .object()
        .when({
          is: ({ ...row }) => !isEmpty(row),
          otherwise: schema => schema,
          then: schema =>
            schema.shape({
              ssn: yup
                .string()
                .required('SSN is required.')
                .test(
                  'Check for valid SSN format',
                  'Please enter a valid SSN',
                  (value?: string) => {
                    if (value) {
                      const ssnNoDashes = value.replace(/-/g, '');
                      const ssnDigits = new Set(ssnNoDashes.split(''));
                      return (
                        (formattedSSN.test(value) || plainSSN.test(value)) &&
                        ssnDigits.size > 1 &&
                        !blacklistedSSNs.includes(ssnNoDashes)
                      );
                    }
                    return true;
                  }
                )
                .test(
                  'Check if SSN exist within a plan',
                  'This SSN does not exist in plan.',
                  async value => {
                    let checkResult;
                    if (
                      planId &&
                      value &&
                      (formattedSSN.test(value) || plainSSN.test(value)) &&
                      !blacklistedSSNs.includes(value.replaceAll('-', '')) &&
                      new Set(value.replaceAll('-', '').split('')).size > 1
                    ) {
                      const ssnToValidate = formattedSSN.test(value)
                        ? value
                        : formatSsn(value);
                      checkResult = await PlanService.checkSsnWithinPlan(
                        planId,
                        ssnToValidate
                      );
                    } else {
                      await new Promise(resolve => {
                        setTimeout(resolve, 50);
                      });
                    }
                    return checkResult?.data ?? false;
                  }
                ),
              yearsOfService: yup
                .number()
                .typeError('yearsOfService must be numeric.')
                .integer('yearsOfService must be whole number.')
                .min(0, 'yearsOfService cannot be less than 0.')
                .max(100, 'yearsOfService cannot be greater than 100.')
                .required('yearsOfService is required.')
            })
        })
        .test({
          test: function (object) {
            const errorsList = (
              this.parent
                .filter((o: any) => o !== object)
                .map((o: any) => {
                  if (o?.ssn && o?.ssn === object?.ssn) {
                    return this.createError({
                      message: 'Duplicate SSN.',
                      path: `${this.path}.ssn`
                    });
                  }

                  return true;
                }) as (true | yup.ValidationError)[]
            ).filter(error => error instanceof yup.ValidationError);

            if (errorsList.length > 0) {
              return new yup.ValidationError(
                errorsList as yup.ValidationError[],
                object,
                this.path
              );
            }

            return true;
          }
        })
    )
    .min(1);
};

export default yearsOfServiceValidationSchema;
