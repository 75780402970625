import { useSnackbar } from '@/contexts/SnackBarContext';
import { useUserToken } from '@/contexts/UserTokenContext';
import {
  DocumentDto,
  DocumentMetadataListDto,
  ParticipantInfo
} from '@/models';
import InMemoryFileDownloadService from '@/services/InMemoryFileDownloadService.service';
import ParticipantService from '@/services/Participant.service';
import { CloudDownload } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonProps,
  Card,
  CardContent,
  CircularProgress,
  Fade,
  FormControl,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  Theme,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';

import { identity, orderBy, uniq } from 'lodash';
import React, { useState } from 'react';

interface ParticipantStatementCenterCardProps {
  participant: Pick<ParticipantInfo, 'participantId'>;
}

const useStyles = makeStyles((theme: Theme) => ({
  bigMarginTop: {
    marginTop: theme.spacing(4)
  },
  contentBody: {
    paddingBottom: 0
  },
  mediumMinWidth: {
    minWidth: theme.spacing(12.5)
  },
  noPaddingOrMargin: {
    margin: 0,
    padding: 0
  },
  smallMarginTop: {
    marginTop: theme.spacing(1)
  }
}));

const getYear = (document: DocumentMetadataListDto[number]): string => {
  return document.document_key.split('/')[1];
};

type DocumentDownloadButtonProps = ButtonProps & {
  doc: DocumentMetadataListDto[number];
  precache?: boolean;
};

const DocumentDownloadButton: React.FunctionComponent<
  DocumentDownloadButtonProps
> = (props: DocumentDownloadButtonProps) => {
  const { doc, precache } = props;

  const [shouldDownloadContents, setShouldDownloadContents] =
    useState(precache);

  const { userHasValidToken } = useUserToken();

  const { showSnackbar } = useSnackbar();

  const queryEnabled =
    Boolean(doc) && shouldDownloadContents && userHasValidToken;

  const documentContentsQuery = useQuery<DocumentDto>(
    ['ParticipantService.getStatementForParticipant', doc.id],
    async () => {
      return ParticipantService.getStatementForParticipant(
        doc.entity_id,
        doc.id
      );
    },
    {
      enabled: Boolean(queryEnabled),
      onError: () => {
        showSnackbar({
          message: 'Error downloading document',
          severity: 'error'
        });
      },
      onSuccess: (unknownDto: unknown) => {
        const dto = unknownDto as DocumentDto;
        InMemoryFileDownloadService.triggerFileDownload(
          dto.base64Data,
          dto.originalFileName
        );
      },
      refetchOnWindowFocus: false,
      staleTime: Infinity
    }
  );

  const checkIfFileNameHasYear = (
    document: DocumentMetadataListDto[number]
  ) => {
    if (document?.original_file_name) {
      const year = getYear(document);

      if (!document.original_file_name.includes(year)) {
        return year + ' ' + document.original_file_name;
      }

      return document.original_file_name;
    }
  };

  return (
    <Button
      data-testid={`document-download-button-document-id-${doc.id}`}
      disabled={documentContentsQuery.isFetching}
      endIcon={
        documentContentsQuery.isFetching && <CircularProgress size={10} />
      }
      key={doc.id}
      onClick={() => {
        if (documentContentsQuery.isSuccess) {
          return InMemoryFileDownloadService.triggerFileDownload(
            documentContentsQuery.data.base64Data,
            documentContentsQuery.data.originalFileName
          );
        }

        if (!shouldDownloadContents) {
          return setShouldDownloadContents(true);
        }

        return documentContentsQuery.refetch();
      }}
      startIcon={<CloudDownload />}>
      {checkIfFileNameHasYear(doc)}
    </Button>
  );
};

const ParticipantStatementCenterCard: React.FunctionComponent<
  ParticipantStatementCenterCardProps
> = (props: ParticipantStatementCenterCardProps) => {
  const classes = useStyles();

  const { userHasValidToken } = useUserToken();

  const { participant } = props;

  const { participantId } = participant;

  const participantStatementsQuery = useQuery<DocumentMetadataListDto>(
    ['ParticipantService.getStatementsForParticipant', participantId],
    async () => {
      if (!participantId) throw new Error(`keep the compiler happy`);

      return ParticipantService.getStatementsForParticipant(participantId);
    },
    {
      enabled: Boolean(participantId && userHasValidToken),
      staleTime: Infinity
    }
  );

  const allDocs = participantStatementsQuery.data || [];

  const years = orderBy(uniq(allDocs.map(getYear)), identity, 'desc');

  const [selectedYear, setSelectedYear] = useState<string>(years[0] || '');

  if (years.length > 0 && selectedYear === '') {
    setSelectedYear(years[0]);
  }

  const docsForChosenYear = orderBy(
    allDocs.filter(doc => getYear(doc) === selectedYear),
    'original_file_name',
    'desc'
  );

  return (
    <div data-testid='participant-quarterly-statement-card'>
      <Card elevation={0} variant='outlined'>
        <CardContent className={classes.contentBody}>
          <Typography variant='h5'>Statements</Typography>
          <div className={classes.bigMarginTop}>
            <FormControl data-testid='participant-statement-year-select-form'>
              <InputLabel id='participant-statement-year-select-label'>
                Year
              </InputLabel>
              <Select
                className={classes.mediumMinWidth}
                disabled={
                  !participantStatementsQuery.isSuccess ||
                  !participantStatementsQuery.data.length
                }
                id='participant-statement-year-select'
                label='Year'
                labelId='participant-statement-year-select-label'
                onChange={event => {
                  setSelectedYear(event.target.value as string);
                }}
                value={selectedYear}>
                {years.map(year => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <Box className={classes.smallMarginTop}>
              {allDocs.length === 0 && (
                <div>No statements found for participant</div>
              )}
              {allDocs.length > 0 && docsForChosenYear.length === 0 && (
                <div>No statements for selected year</div>
              )}
              {docsForChosenYear.map(doc => (
                <Fade in key={doc.id}>
                  <ListItem
                    className={classes.noPaddingOrMargin}
                    disableGutters>
                    <DocumentDownloadButton doc={doc} />
                  </ListItem>
                </Fade>
              ))}
            </Box>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default ParticipantStatementCenterCard;
