import { createSvgIcon, SvgIconProps } from '@mui/material';

import React, { FC } from 'react';

// Custom OpenInNew icon created by Derek in Figma to accomidate < 20px font size of this use cases
const OpenInNewIcon: FC<SvgIconProps> = createSvgIcon(
  <path
    d='M8 9.5H1V2.5H4V1.5H1C0.383333 1.5 0 1.88889 0 2.5V9.5C0 10.1111 0.383333 10.5 1 10.5H8C8.61111 10.5 9 10.1111 9 9.5V6.5H8V9.5ZM6 0.5V1.5H8.21667L3 6.71667L3.78333 7.5L9 2.28333V4.5H10V0.5H6Z'
    fill='#2196F3'
  />,
  'OpenInNewIcon'
);

OpenInNewIcon.defaultProps = {
  viewBox: '0 0 10 11'
};

OpenInNewIcon.displayName = 'OpenInNewIcon';

export default OpenInNewIcon;
