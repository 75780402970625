import CollapsibleTable, {
  CellComponentProps
} from '@/components/collapsible-table';
import LinearLoading from '@/components/linear-loading';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { PlanV2, PlanV2ListDto } from '@/models/PlanV2DTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import TpaService from '@/services/Tpa.service';
import { userService } from '@/services/User.service';
import { Box, Card, Paper, TableCell, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';

import ActionTpaPlanBtn from './ActionTpaPlanBtn.component';
import AddTpaPlansToolbar from './AddTpaPlansToolbar.component';

interface TpaPlansTableProps {
  tpaId: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionCell: {
      width: '40%'
    },
    actionHeader: {
      paddingLeft: theme.spacing(3)
    },
    body: {
      fontSize: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold
    },
    cell: {
      ...theme.typography.caption,
      color: theme.palette.grey[700],
      fontSize: theme.spacing(2),
      width: '25%'
    },
    noData: {
      fontSize: theme.spacing(2.4),
      marginTop: theme.spacing(4),
      padding: theme.spacing(2),
      textAlign: 'center'
    },
    paper: {
      marginBottom: theme.spacing(2),
      width: '100%'
    },
    root: {
      marginTop: theme.spacing(5),
      width: '100%'
    },
    statusCell: {
      width: '10%'
    },
    table: {
      minWidth: 750
    },
    title: {
      marginBottom: theme.spacing(1),
      textAlign: 'left'
    }
  })
);

const TpaPlansTable = (props: TpaPlansTableProps): JSX.Element => {
  const classes = useStyles();
  useSnackbar();

  const { tpaId } = props;

  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [lastOperationMadeAt, setLastOperationMadeAt] = useState<null | number>(
    null
  );
  const [lastOperationPage, setLastOperationPage] = useState<null | number>(
    null
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);

  const setLastOperation = () => {
    setLastOperationMadeAt(new Date().getTime());
    setLastOperationPage(pageNumber);
  };
  const goBackOnePage = useCallback(() => {
    if (pageNumber !== 1) {
      setPageNumber(pageNumber - 1);
    }
  }, [pageNumber]);

  const defaultColumns: GridColDef[] = [
    { field: 'id', headerName: 'Plan ID' },
    {
      field: 'name',
      headerName: 'Plan Name'
    },
    {
      field: 'status',
      headerName: 'Status'
    }
  ];

  const tpaManagerColumns = [
    ...defaultColumns,
    {
      cellClassName: classes.actionHeader,
      field: 'action',
      headerName: 'Action'
    }
  ];

  const canManageTpaPlans = userService.hasPermission(
    FeatureLevelPermissions.WRITE_TPA_DETAILS_MANAGE_USERS
  );

  const tpaPlansQuery = useQuery<PlanV2ListDto>(
    [
      'TpaService.getPlansForTpa',
      tpaId,
      pageNumber,
      rowsPerPage,
      debouncedSearchTerm
    ],
    () => {
      return TpaService.getPlansForTpa(
        tpaId,
        pageNumber,
        rowsPerPage,
        debouncedSearchTerm
      );
    },
    {
      enabled: Boolean(tpaId),
      keepPreviousData: true,
      staleTime: Infinity
    }
  );

  const toConsumablePlan = (
    plan: PlanV2
  ): { id: number; name: string; status: string } => {
    return {
      id: plan.id,
      name: plan.attributes.name || '--',
      status: plan.attributes.status
    };
  };

  const refetchPlans = async () => {
    await tpaPlansQuery.refetch();
  };

  const tpaPlans = useMemo(() => {
    return (tpaPlansQuery.data?.data || [])
      .map(toConsumablePlan)
      .filter(plan =>
        debouncedSearchTerm
          ? (plan.id + plan.status + plan.name)
              .toLocaleLowerCase()
              .includes(debouncedSearchTerm.toLocaleLowerCase())
          : true
      );
  }, [tpaPlansQuery.data, debouncedSearchTerm]);

  const refetchTPA = useCallback(refetchPlans, [tpaPlansQuery]);
  useEffect(() => {
    if (pageNumber !== lastOperationPage && lastOperationPage) {
      if (
        lastOperationMadeAt &&
        tpaPlansQuery.dataUpdatedAt < lastOperationMadeAt
      ) {
        refetchTPA();
      }
    }
  }, [
    lastOperationMadeAt,
    lastOperationPage,
    pageNumber,
    tpaPlansQuery.dataUpdatedAt,
    refetchTPA
  ]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setPageNumber(1);
    }
  }, [debouncedSearchTerm]);

  const TpaPlansTableCell: React.FunctionComponent<CellComponentProps> = (
    cellProps: CellComponentProps
  ) => {
    const { row, column } = cellProps;
    let cellClass = column.cellClassName
      ? clsx(classes.cell, column.cellClassName)
      : clsx(classes.cell);

    if (column.field === 'action') {
      cellClass = clsx(classes.cell, classes.actionCell);
    }

    return (
      <TableCell className={cellClass} component='th' scope='row'>
        <Box>
          {column.field === 'action' ? (
            <ActionTpaPlanBtn
              forceRefetch={refetchPlans}
              goBackOnePage={goBackOnePage}
              planId={row.id}
              setLastOperation={setLastOperation}
              tpaId={+tpaId}
              tpaPlanslength={tpaPlans.length}
            />
          ) : (
            row[column.field] || '--'
          )}
        </Box>
      </TableCell>
    );
  };

  const noData = <Card className={classes.noData}>No TPA Plans</Card>;

  return (
    <div className={classes.root} data-testid='tpa-detail-page-plans-table'>
      <Paper className={classes.paper} elevation={0} variant='outlined'>
        <AddTpaPlansToolbar
          forceRefetch={refetchPlans}
          setLastOperation={setLastOperation}
          setSearchTerm={setSearchTerm}
          tpaId={+tpaId}
        />
        {tpaPlansQuery.isFetching && <LinearLoading />}
        <div data-testid='tpa-plans-table'>
          <CollapsibleTable
            cellComponent={TpaPlansTableCell}
            columns={canManageTpaPlans ? tpaManagerColumns : defaultColumns}
            noDataPlaceholderComponent={
              tpaPlansQuery.isFetching ? <></> : noData
            }
            pager={{
              metaCount: searchTerm
                ? tpaPlans.length
                : tpaPlansQuery.data?.meta?.count,
              onPageNumberChanged: (zeroIndexedPageNumber: number) => {
                return setPageNumber(zeroIndexedPageNumber + 1);
              },
              onRowsPerPageChanged: (newRowsPerPage: number) => {
                return setRowsPerPage(newRowsPerPage);
              },
              pageNumber: pageNumber - 1,
              rowsPerPage
            }}
            tableData={tpaPlans}
            useDivAsBackground
          />
        </div>
      </Paper>
    </div>
  );
};

export default TpaPlansTable;
