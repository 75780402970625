import EARLIEST_POSSIBLE_DATE from '@/consts/dates.constants';

import dayjs from 'dayjs';

export const DATA_MAIN_FORMAT = 'YYYY-MM-DD';
export const FORMATTED_EARLIEST_START_DATE = dayjs(
  EARLIEST_POSSIBLE_DATE
).format(DATA_MAIN_FORMAT);

export const getDefaultStartDate = (): string =>
  `${dayjs(new Date()).year()}-01-01`;

export const getCurrentDate = (dateFormat = DATA_MAIN_FORMAT): string =>
  dayjs(new Date()).format(dateFormat);

export const getYearsRange = (
  startYear = dayjs(new Date()).year()
): number[] => {
  const res = [];
  const endYear = dayjs(new Date()).year();
  for (let i = endYear; i >= startYear; i--) {
    res.push(i);
  }
  return res;
};
