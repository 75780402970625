import {
  FUND,
  InvestmentOptionLabel,
  MANAGED,
  MS_MANAGED,
  Program,
  RISK,
  RuleValue,
  TARGET
} from '@/models/ops/investments/Program.model';
import { Program401kManager } from '@/routes/ops/investments/Program401kManager.component';
import { ProgramDetailsTab } from '@/routes/ops/investments/ProgramDetailsTab.component';
import ProgramDocumentsTab from '@/routes/ops/investments/ProgramDocumentsTab.component';
import { ProgramFundLineup } from '@/routes/ops/investments/ProgramFundLineup.component';
import ProgramManagedAccount from '@/routes/ops/investments/ProgramManagedAccount.component';
import { ProgramRiskSeries } from '@/routes/ops/investments/ProgramRiskSeries.component';
import { ProgramTargetDateSeries } from '@/routes/ops/investments/ProgramTargetDateSeries.component';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProgramFundChangesTab } from './FundChangesTab/ProgramFundChangesTab.component';
import { ProgramSecuritiesTab } from './ProgramSecuritiesTab/ProgramSecuritiesTab.component';

const DETAILS = 'Details';
const DOCUMENTS = 'Documents';
const FUND_CHANGES = 'Fund Changes';
const SECURITIES = 'Securities';

const TAB_VALUES = {
  [DETAILS]: 'details',
  [DOCUMENTS]: 'documents',
  [FUND]: 'fund',
  [FUND_CHANGES]: 'fund-changes',
  [MANAGED]: 'managed',
  [MS_MANAGED]: 'ms401kmanager',
  [RISK]: 'risk',
  [SECURITIES]: 'securities',
  [TARGET]: 'target'
};

const useStyles = makeStyles((theme: Theme) => ({
  saveButton: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  tabPanel: {
    padding: 0
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    marginBottom: theme.spacing(4)
  }
}));

type ProgramProps = {
  refetchProgram: () => void;
  programData: Program;
  tab?: string;
};

export const ProgramEditPage: FC<ProgramProps> = props => {
  const classes = useStyles();
  const { programData, tab = 'details' } = props;
  const navigate = useNavigate();

  const programRules: string[] = useMemo(() => {
    return programData.programRule.map(rule => rule.ruleValue);
  }, [programData.programRule]);

  const availableTabs: any[] = [
    { label: DETAILS },
    { label: FUND, programRule: RuleValue.menu },
    { label: TARGET, programRule: RuleValue.target },
    { label: RISK, programRule: RuleValue.risk },
    { label: MANAGED, programRule: RuleValue.dynamic_goal },
    { label: MS_MANAGED, programRule: RuleValue.ms_managed },
    { label: DOCUMENTS },
    { label: FUND_CHANGES },
    { label: SECURITIES }
  ]
    .filter(
      tabData =>
        !tabData.programRule ||
        programRules.includes(tabData.programRule as RuleValue)
    )
    .map(tabData => tabData.label);

  const availableOptions: InvestmentOptionLabel[] = availableTabs.filter(
    (value: InvestmentOptionLabel) =>
      [FUND, TARGET, RISK, MANAGED, MS_MANAGED].includes(value)
  );

  const handleTabChange = useCallback(
    (_, newValue) => {
      if (newValue === TAB_VALUES[DETAILS]) {
        navigate(`/ops/investments/program/${props.programData.programId}`, {
          replace: true
        });
        return;
      }

      navigate(
        `/ops/investments/program/${props.programData.programId}/${newValue}`,
        { replace: true }
      );
    },
    [navigate, props.programData.programId]
  );

  return (
    <>
      <Typography noWrap variant='h5'>
        {programData.name}
      </Typography>
      <TabContext value={tab}>
        <Box className={classes.tabs}>
          <Tabs onChange={handleTabChange} value={tab}>
            <Tab key={DETAILS} label={DETAILS} value={TAB_VALUES[DETAILS]} />
            {availableTabs.includes(FUND) && (
              <Tab key={FUND} label={FUND} value={TAB_VALUES[FUND]} />
            )}
            {availableTabs.includes(MS_MANAGED) && (
              <Tab
                key={MS_MANAGED}
                label={MS_MANAGED}
                value={TAB_VALUES[MS_MANAGED]}
              />
            )}
            {availableTabs.includes(TARGET) && (
              <Tab key={TARGET} label={TARGET} value={TAB_VALUES[TARGET]} />
            )}
            {availableTabs.includes(RISK) && (
              <Tab key={RISK} label={RISK} value={TAB_VALUES[RISK]} />
            )}
            {availableTabs.includes(MANAGED) && (
              <Tab key={MANAGED} label={MANAGED} value={TAB_VALUES[MANAGED]} />
            )}
            <Tab
              key={DOCUMENTS}
              label={DOCUMENTS}
              value={TAB_VALUES[DOCUMENTS]}
            />
            <Tab
              key={SECURITIES}
              label={SECURITIES}
              value={TAB_VALUES[SECURITIES]}
            />
            <Tab
              key={FUND_CHANGES}
              label={FUND_CHANGES}
              value={TAB_VALUES[FUND_CHANGES]}
            />
          </Tabs>
        </Box>
        {availableTabs.includes(FUND) && (
          <TabPanel
            className={classes.tabPanel}
            key={FUND}
            value={TAB_VALUES[FUND]}>
            <ProgramFundLineup
              programData={programData}
              refetchProgram={props.refetchProgram}
            />
          </TabPanel>
        )}
        {availableTabs.includes(MS_MANAGED) && (
          <TabPanel
            className={classes.tabPanel}
            key={MS_MANAGED}
            value={TAB_VALUES[MS_MANAGED]}>
            <Program401kManager
              programData={programData}
              refetchProgram={props.refetchProgram}
            />
          </TabPanel>
        )}
        {availableTabs.includes(TARGET) && (
          <TabPanel
            className={classes.tabPanel}
            key={TARGET}
            value={TAB_VALUES[TARGET]}>
            <ProgramTargetDateSeries
              programData={programData}
              refetchProgram={props.refetchProgram}
            />
          </TabPanel>
        )}
        {availableTabs.includes(RISK) && (
          <TabPanel
            className={classes.tabPanel}
            key={RISK}
            value={TAB_VALUES[RISK]}>
            <ProgramRiskSeries
              programData={programData}
              refetchProgram={props.refetchProgram}
            />
          </TabPanel>
        )}
        {availableTabs.includes(MANAGED) && (
          <TabPanel
            className={classes.tabPanel}
            key={MANAGED}
            value={TAB_VALUES[MANAGED]}>
            <ProgramManagedAccount
              programData={programData}
              refetchProgram={props.refetchProgram}
            />
          </TabPanel>
        )}
        <TabPanel
          className={classes.tabPanel}
          key={DOCUMENTS}
          value={TAB_VALUES[DOCUMENTS]}>
          <ProgramDocumentsTab programId={programData.programId} />
        </TabPanel>
        <TabPanel
          className={classes.tabPanel}
          key={DETAILS}
          value={TAB_VALUES[DETAILS]}>
          <ProgramDetailsTab
            programData={programData}
            refetchProgram={props.refetchProgram}
          />
        </TabPanel>
        <TabPanel
          className={classes.tabPanel}
          key={FUND_CHANGES}
          value={TAB_VALUES[FUND_CHANGES]}>
          <ProgramFundChangesTab availableOptions={availableOptions} />
        </TabPanel>
        <TabPanel
          className={classes.tabPanel}
          key={SECURITIES}
          value={TAB_VALUES[SECURITIES]}>
          <ProgramSecuritiesTab />
        </TabPanel>
      </TabContext>
    </>
  );
};

ProgramEditPage.displayName = 'ProgramEditPage';
