import DataTable from '@/components/data-table/DataTable.component';
import AddRowRenderer from '@/components/file-upload-table/AddRowRenderer';
import { FileUploadTableEditor } from '@/components/file-upload-table/FileUploadTableEditor';
import { FileUploadTableEditorContext } from '@/components/file-upload-table/FileUploadTableEditorContext';
import RemoveRowRenderer from '@/components/file-upload-table/RemoveRowRenderer';
import formatters from '@/utils/Formatters';
import { Checkbox, MenuItem, Select, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';

import {
  ColDef,
  ColGroupDef,
  ColSpanParams,
  Column,
  ColumnApi,
  GridApi,
  ICellEditorParams,
  ICellRendererParams,
  RowNode,
  ValueSetterParams
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import dayjs from 'dayjs';
import Decimal from 'decimal.js';
import { isNil, orderBy } from 'lodash';
import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react';
import { useUpdateEffect } from 'react-use';
import { v4 as uuidv4 } from 'uuid';

interface IsColumnFuncParams {
  node: RowNode;
  data: any;
  column: Column;
  colDef: ColDef;
  context: any;
  api: GridApi | null | undefined;
  columnApi: ColumnApi | null | undefined;
}

export interface ExtendedColDef extends Omit<ColDef, 'valueParser'> {
  alternates?: string[];
  cellEditorParams?: Record<string, unknown> & {
    editableOnlyWithErrors?: boolean;
    useTooltip?: boolean;
    showExcludeRowButton?: boolean;
  };
  options?: Array<string>;
  required?: boolean;
  valueParser?: (params: any) => any;
  type?: string;
}

export interface ExtendedGroupColDef extends Omit<ColGroupDef, 'children'> {
  children: ExtendedColDef[];
}

export type EditorColDef<T> = Omit<
  ExtendedColDef,
  'valueSetter' | 'cellEditorParams'
> & {
  stopEditingOnValueChange?: boolean;
  valueSetter?: (
    params: ValueSetterParams
  ) => Omit<ValueSetterParams, 'newValue'> & { newValue: T };
};

export type EditorComponentProps = {
  value?: any;
  name?: string;
} & Pick<ICellEditorParams, 'api' | 'data' | 'colDef' | 'rowIndex' | 'node'>;

export type ErrorsHash = Record<
  string,
  Record<
    string,
    {
      message?: string;
      rowError?: string;
      title?: string;
    }
  >
>;

export declare type FileUploadTableError = Partial<
  Record<string, unknown> & {
    errorField: string;
    errorMessage:
      | string
      | ((record: Record<string, unknown>) => ReactNode)
      | ReactNode;
    errorTitle?: string;
    rowNum: number;
    errorRow?: boolean;
  }
>;

export type FileUploadTableEditorContextProps = {
  addRow?(params: EditorComponentProps): void;
  errorsHash?: ErrorsHash;
  nextValidation?(): void;
  newRowCount?: number;
  onCellValueChanged?(params: EditorComponentProps): void;
  primaryKey?: string;
  removeRow?(
    params: EditorComponentProps,
    nextValidation?: { colKey: string; rowIndex: number }
  ): void;
  setValue?(key: string, value: any): void;
  validations?: {
    id: string;
    colId: string;
    colIndex: number;
    rowIndex: number;
    rowError?: boolean;
    message?: ReactNode;
    title?: ReactNode;
  }[];
};

export type EditorParams = Omit<IsColumnFuncParams, 'colDef' | 'context'> & {
  colDef: ExtendedColDef;
  context: FileUploadTableEditorContextProps;
};

type FileUploadTableProps = {
  addNewRowsCount?: number;
  defaultEmptyRowValues?: Record<string, any>;
  columnDefs: Array<ExtendedGroupColDef | ExtendedColDef>;
  errors: FileUploadTableError[];
  onCellChanged?(key: string, value: any): void;
  onRowsChanged?(value: any[]): void;
  rowData: Record<string, any>[];
  isViewMode?: boolean;
};

const columnTypes = {
  checkbox: FileUploadTableEditor(
    {
      cellClass: ['checkbox']
    },
    forwardRef<any, any>((params, ref) => {
      const isIndeterminate = typeof params.value !== 'boolean';

      return (
        <Checkbox
          autoFocus
          checked={params.value}
          color={isIndeterminate ? 'default' : 'primary'}
          indeterminate={isIndeterminate}
          onChange={event => {
            params.onChange(event.target.checked);
          }}
          ref={ref}
        />
      );
    })
  ),
  currency: FileUploadTableEditor(
    {
      cellClass: ['currency', 'text-right'],
      comparator: (a, b) =>
        new Decimal(a || '0').comparedTo(new Decimal(b || '0')),
      headerClass: ['flex-row-end'],
      valueFormatter: (params: any) => {
        //numbers and decimals only
        if (/^\d*\.?\d+$/.test(params.value)) {
          return formatters.formatDollars(params.value, 2);
        }
        //commas allowed
        else if (/^(\d+|\d{1,3}(,\d{3})*)(\.\d+)?$/.test(params.value)) {
          return formatters.formatDollars(params.value.replace(',', ''), 2);
        }

        return params.value;
      }
    },
    forwardRef<any, any>((params, ref) => {
      return (
        <TextField
          autoFocus
          error={params.eGridCell.className.includes('error')}
          fullWidth
          name={params.name}
          onChange={event =>
            params.onChange(event.target.value.replace(/,/g, ''))
          }
          ref={ref}
          size='small'
          value={params.value}
        />
      );
    })
  ),
  date: FileUploadTableEditor(
    {
      cellClass: ['date'],
      valueFormatter: params => {
        return params.value ? dayjs(params.value).format('YYYY-MM-DD') : '';
      },
      valueGetter: params => {
        const val = params.data[params.colDef.field];

        return val ? dayjs(val) : undefined;
      }
    },
    forwardRef<any, any>((params, ref) => {
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateField
            aria-describedby='date-description'
            autoFocus
            format='YYYY-MM-DD'
            fullWidth
            inputProps={{
              error: params.eGridCell.className.includes('error')
            }}
            name={params.name}
            onChange={value => params.onChange(value)}
            ref={ref}
            size='small'
            value={params.value}
          />
        </LocalizationProvider>
      );
    })
  ),
  number: FileUploadTableEditor(
    {
      cellClass: ['number']
    },
    forwardRef<any, any>((params, ref) => {
      return (
        <TextField
          autoFocus
          error={params.eGridCell.className.includes('error')}
          fullWidth
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          name={params.name}
          onChange={event => params.onChange(event.target.value)}
          ref={ref}
          size='small'
          value={params.value}
        />
      );
    })
  ),
  select: FileUploadTableEditor(
    {
      cellClass: ['select']
    },
    forwardRef<any, any>((params, ref) => {
      return (
        <Select
          fullWidth
          onChange={event => params.onChange(event.target.value)}
          ref={ref}
          size='small'
          value={params.value ?? ''}>
          {params.colDef.options.map((option: string, index: number) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      );
    })
  ),
  ssn: FileUploadTableEditor(
    {
      cellClass: ['ssn'],
      valueFormatter: params => {
        if ([undefined, ''].includes(params?.value)) {
          return '';
        } else if (
          /^(?!(000))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/.test(params.value)
        ) {
          return formatters.maskSSN(params.value);
        }
        return params.value;
      }
    },
    forwardRef<any, any>((params, ref) => {
      return (
        <TextField
          autoFocus
          error={params.eGridCell.className.includes('error')}
          fullWidth
          name={params.name}
          onChange={event => params.onChange(event.target.value)}
          ref={ref}
          size='small'
          value={params.value}
        />
      );
    })
  ),
  text: FileUploadTableEditor(
    {
      cellClass: ['text']
    },
    forwardRef<any, any>((params, ref) => {
      return (
        <TextField
          autoFocus
          error={params.eGridCell.className.includes('error')}
          fullWidth
          name={params.name}
          onChange={event => params.onChange(event.target.value)}
          ref={ref}
          size='small'
          value={params.value}
        />
      );
    })
  )
};

const FileUploadTable: React.FunctionComponent<FileUploadTableProps> = (
  props: FileUploadTableProps
) => {
  const [columns, setColumns] = useState<string[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const gridRef = useRef<AgGridReact>(null);
  const [nextValidation, setNextValidation] = useState<{
    colKey: string;
    rowIndex: number;
  } | null>(null);

  /**
   * create an ordered list of validations for navigation
   * ordered via left-to-right, top-to-bottom
   */
  const validations = useMemo(() => {
    return orderBy(
      columns
        ? props.errors.reduce(
            (acc, error, rowIndex) => [
              ...acc,
              ...(!error
                ? []
                : Object.keys(error).reduce(
                    (_acc, colId) => [
                      ..._acc,
                      ...(props.rowData[rowIndex]
                        ? [
                            {
                              colId,
                              colIndex: columns.indexOf(colId),
                              id: props.rowData[rowIndex].uuid,
                              message:
                                typeof error[colId] === 'object'
                                  ? error.errorMessage
                                  : error[colId],
                              ...(typeof error[colId] === 'object'
                                ? { title: error.errorTitle }
                                : {}),
                              rowError: !!error.errorRow,
                              rowIndex
                            }
                          ]
                        : [])
                    ],
                    [] as any[]
                  ))
            ],
            [] as any[]
          )
        : [],
      ['rowIndex', 'colIndex'],
      ['asc', 'asc']
    );
  }, [props.errors, props.rowData, columns]);

  /**
   * create a hash map of the validation errors
   */
  const errorsHash = useMemo(() => {
    return props.rowData.length
      ? validations.reduce((acc, error) => {
          const row = props.rowData[error.rowIndex];
          const primaryKey = row && (row.uuid as string);

          return {
            ...acc,
            ...(row && primaryKey
              ? {
                  [primaryKey]: {
                    ...(acc[primaryKey] || {}),
                    rowError:
                      error.colId === 'error_array' ||
                      !!(acc[primaryKey] || {}).rowError,
                    [error.colId]: error
                  }
                }
              : {})
          };
        }, {})
      : {};
  }, [props.rowData, validations]);

  const onGridColumnsChanged = useCallback(params => {
    setColumns(() =>
      params.columnApi.getAllGridColumns().map((col: Column) => col.getColId())
    );
  }, []);

  /**
   * calculate the validations for the validation errors
   */
  const onCellValueChanged = useCallback(
    params => {
      if (props.onCellChanged) {
        const rowsData = [];
        params.api.forEachNode(node => rowsData.push(node.data));
        const index = rowsData.findIndex(
          (row: any) => row.uuid === params.data.uuid
        );

        props.onCellChanged(
          `[${index}].${params.colDef.field}`,
          params.newValue
        );
      }
    },
    [props.onCellChanged]
  );

  const onCellEditingStarted = useCallback(() => {
    setIsEditing(true);
  }, []);

  const onCellEditingStopped = useCallback(() => {
    setIsEditing(false);
  }, []);

  const onRemoveRow = useCallback(
    (params, _nextValidation) => {
      if (props.onRowsChanged) {
        const rowsData = [];
        params.api.forEachNode(node => rowsData.push(node.data));
        props.onRowsChanged(
          rowsData.filter((row: any) => row.uuid !== params.data.uuid)
        );
      }

      if (_nextValidation) {
        setNextValidation(_nextValidation);
      }
    },
    [props.onRowsChanged]
  );

  const onAddRow = useCallback(
    params => {
      if (props.onRowsChanged) {
        const rowsData = [];
        params.api.forEachNode(node => rowsData.push(node.data));
        const newRows = props.addNewRowsCount
          ? Array(props.addNewRowsCount)
              .fill({})
              .map(() => {
                return {
                  ...props.defaultEmptyRowValues,
                  pristine: true,
                  uuid: uuidv4()
                };
              })
          : [{ pristine: true, uuid: uuidv4() }];

        if (isNil(params.node.rowIndex)) {
          return props.onRowsChanged([...rowsData, ...newRows]);
        }

        return props.onRowsChanged([
          ...rowsData.slice(0, params.node.rowIndex),
          ...newRows,
          ...rowsData.slice(params.node.rowIndex, rowsData.length)
        ]);
      }
    },
    [props.onRowsChanged, props.addNewRowsCount]
  );

  const editorContext = useMemo(
    () => ({
      addRow: onAddRow,
      errorsHash,
      newRowCount: props.addNewRowsCount,
      onCellValueChanged,
      primaryKey: 'uuid',
      removeRow: onRemoveRow,
      setValue: props.onCellChanged,
      validations
    }),
    [
      errorsHash,
      onAddRow,
      onCellValueChanged,
      onRemoveRow,
      props.onCellChanged,
      props.addNewRowsCount,
      validations
    ]
  );

  const gridContext = useMemo(
    () => ({
      errorsHash,
      primaryKey: 'uuid'
    }),
    [errorsHash]
  );

  /**
   * refresh the cells whenever context has changed;
   * this is required in order for cell components to re-render after context changes
   */
  useUpdateEffect(() => {
    if (!isEditing) {
      gridRef.current?.api?.refreshCells();
    }
  }, [gridContext, isEditing]);

  useUpdateEffect(() => {
    if (
      nextValidation &&
      gridRef.current &&
      !isEditing &&
      Object.keys(errorsHash).length > 0
    ) {
      gridRef.current.api.startEditingCell(nextValidation);
      setNextValidation(null);
    }
  }, [nextValidation, isEditing, errorsHash]);

  const newColumnDefs = useMemo(
    () => [
      {
        cellRenderer: (params: ICellRendererParams) => {
          if (!props.isViewMode)
            return params.node.lastChild ? (
              <AddRowRenderer {...params} />
            ) : (
              <RemoveRowRenderer {...params} />
            );
          else return null;
        },
        colSpan: (params: ColSpanParams) =>
          params.node?.lastChild
            ? params.columnApi.getColumns()?.length ?? 1
            : 1,
        maxWidth: 80,
        suppressMenu: true
      },
      ...props.columnDefs
    ],
    [props.columnDefs, props.isViewMode]
  );

  return (
    <>
      <FileUploadTableEditorContext.Provider value={editorContext}>
        <DataTable
          columnDefs={newColumnDefs}
          columnTypes={columnTypes}
          context={gridContext}
          data-testid='cash-allocation-table'
          gridRef={gridRef}
          onCellEditingStarted={onCellEditingStarted}
          onCellEditingStopped={onCellEditingStopped}
          onCellValueChanged={onCellValueChanged}
          onGridColumnsChanged={onGridColumnsChanged}
          primaryKey='uuid'
          rowData={props.rowData}
          singleClickEdit
          themeName='ag-theme-file-upload-table'
        />
      </FileUploadTableEditorContext.Provider>
    </>
  );
};

export default FileUploadTable;
