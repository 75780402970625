import { CellComponentProps } from '@/components/collapsible-table';
import formatters from '@/utils/Formatters';
import { Box, TableCell } from '@mui/material';

import React, { useMemo } from 'react';

import { ContributionCorrectionDtoWithUUID } from './PlanCorrectionsDetailsTable.component';

type CellProps = {
  row: ContributionCorrectionDtoWithUUID;
};

const ERROR_CODES: Record<string, string> = {
  ERR_CONTRIBUTIONS_RC_BAD_FORMAT: 'Invalid contribution amount',
  ERR_ELIGIBILITY_CONTRIBUTIONS_BY_INELIG: 'Eligibility requirements not met',
  ERR_IDENTITY_BAD_SSN_FORMAT: 'Improperly formatted social security number',
  ERR_IDENTITY_DUPLICATE_SSN: 'Duplicated social security number',
  ERR_IDENTITY_INVALID_SSN: 'Invalid social security number',
  ERR_IDENTITY_MISSING_NAMES: 'Missing a name',
  ERR_IDENTITY_MISSING_SSN: 'Missing a valid social security number',
  ERR_MISMATCHED_LOAN_PAYMENT: 'Mismatched loan repayment',
  ERR_STATE_IRA_OVER_IRS_LIMIT: 'Employee Contribution Limit',
  UNRECOGNIZED_EMPLOYEE: 'Unrecognized employee detected',
  WARN_CONTRIBUTIONS_INVALID_LOAN_REPAYMENT: 'Participant has no active loans',
  WARN_CONTRIBUTIONS_IRS_LIMITS: 'Employee and Employer Contribution Limits',
  WARN_CONTRIBUTIONS_UNESTABLISHED_NON_ROTH: 'Unestablished non-Roth Account',
  WARN_CONTRIBUTIONS_UNESTABLISHED_ROTH: 'Unestablished Roth Account'
};

const EmployeeCell: React.FC<CellProps> = (props: CellProps) => (
  <Box>
    {`${props.row.initialValue?.lastName || '--'}, ${
      props.row.initialValue?.firstName || '--'
    }`}
  </Box>
);

const TimeCell: React.FC<CellProps> = (props: CellProps) => (
  <Box>
    {formatters.formatFromIsoDateCustom(
      props.row.createdAt,
      'M/DD/YYYY h:mm A'
    )}
  </Box>
);

const TypeCell: React.FC<CellProps> = (props: CellProps) => (
  <Box>
    {formatters.capitalizeFirstChar(
      props.row.correctionType.replace('_', ' ')
    ) || '--'}
  </Box>
);

const FieldCell: React.FC<CellProps> = (props: CellProps) => (
  <Box>
    {props.row.hasMultipleFields
      ? 'Multiple Fields'
      : formatters.formatContributionCorrectionFields(
          props.row.fieldNames[0]
        ) || '--'}
  </Box>
);

const OldValueCell: React.FC<CellProps> = (props: CellProps) => (
  <Box>
    {props.row.hasMultipleFields
      ? 'See Details'
      : props.row.initialValue[props.row.fieldNames[0]] || '--'}
  </Box>
);

const NewValueCell: React.FC<CellProps> = (props: CellProps) => (
  <Box>
    {props.row.hasMultipleFields
      ? 'See Details'
      : props.row.correctionValue[props.row.fieldNames[0]] || '--'}
  </Box>
);

const ReasonCell: React.FC<CellProps> = (props: CellProps) => (
  <Box>
    {props.row.correctionValue?.reason ||
      ERROR_CODES[props.row.correctionValue?.errorCode] ||
      '--'}
  </Box>
);

const CorrectionTableCell: React.FunctionComponent<
  CellComponentProps
> = props => {
  const CellComponent = useMemo(() => {
    switch (props.column.field) {
      case 'employee':
        return <EmployeeCell row={props.row} />;
      case 'time':
        return <TimeCell row={props.row} />;
      case 'type':
        return <TypeCell row={props.row} />;
      case 'field':
        return <FieldCell row={props.row} />;
      case 'oldValue':
        return <OldValueCell row={props.row} />;
      case 'newValue':
        return <NewValueCell row={props.row} />;
      case 'reason':
        return <ReasonCell row={props.row} />;
      case 'reportedAmount':
        return <Box>{formatters.formatDollars(props.row.totals.initial)}</Box>;
      case 'processedAmount':
        return (
          <Box>{formatters.formatDollars(props.row.totals.corrected)}</Box>
        );
      default:
        return <>{props.row[props.column.field]}</>;
    }
  }, [props.column, props.row]);

  return (
    <TableCell component='th' scope='row'>
      {CellComponent}
    </TableCell>
  );
};

export default CorrectionTableCell;
