import { GlobalRateResponse } from '@/models/ops/global-rates/GlobalRates.model';
import ApiService from '@/services/Api.service';

export class GlobalRatesService {
  static async getLoanInterestRate() {
    return ApiService.getJson<GlobalRateResponse>('/global-rate/loan');
  }

  static async putLoanInterestRate(rate: number) {
    return ApiService.putJson('/global-rate/loan', {
      rate
    });
  }
}
