import { CellComponentProps } from '@/components/collapsible-table';
import { GroupedTransactionDto } from '@/models/TransactionDTO.model';
import formatters from '@/utils/Formatters';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import React, { FC } from 'react';

interface CompletedTransactionsCollapsibleProps {
  row: GroupedTransactionDto;
}

const headerCells = ['Fund', 'Fund Name', 'Amount', 'Shares'];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      ...theme.typography.caption,
      color: theme.palette.grey[700],
      fontSize: theme.spacing(2),
      height: theme.spacing(6),
      padding: '0',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    bold: {
      fontWeight: 'bold'
    },
    lastRowNoBorder: {
      '&:last-child th, &:last-child td': {
        borderBottom: 0
      }
    },
    table: {
      width: '50%'
    }
  })
);

export const CompletedTransactionsTableCell: FC<CellComponentProps> = props => {
  const classes = useStyles();

  return (
    <TableCell className={classes.body} component='th' scope='row'>
      <Box>
        {props.column.field === 'amount'
          ? formatters.formatDollars(props.row[props.column.field])
          : ['effectiveDate', 'tradeDate'].includes(props.column.field)
            ? props.row[props.column.field].format('MM/DD/YYYY')
            : props.row[props.column.field]}
      </Box>
    </TableCell>
  );
};

const CompletedTransactionsCollapsible: FC<
  CompletedTransactionsCollapsibleProps
> = props => {
  const classes = useStyles();

  return (
    <TableContainer
      className={classes.table}
      data-testid='completed-transactions-table'>
      <Table>
        <TableHead>
          <TableRow>
            {headerCells.map(cellName => {
              return (
                <TableCell className={classes.bold} key={cellName}>
                  {cellName}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.row.transactions.map(transaction => (
            <TableRow className={classes.lastRowNoBorder} key={transaction.id}>
              <TableCell>{transaction.security.ticker}</TableCell>
              <TableCell>{transaction.security.fundName}</TableCell>
              <TableCell>
                {formatters.formatDollars(transaction.dollars)}
              </TableCell>
              <TableCell>
                {formatters.formatDecimal(transaction.units, 3)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CompletedTransactionsCollapsible;
