import { createSvgIcon, SvgIconProps } from '@mui/material';

import React, { FC } from 'react';

// Custom ViewChildren icon created by Derek in Figma
const ViewChildrenIcon: FC<SvgIconProps> = createSvgIcon(
  <path
    clipRule='evenodd'
    d='M14 6H3V4H14V6ZM3 8V12C3 13.6569 4.34315 15 6 15H7V17L10 14L7 11V13H6C5.44772 13 5 12.5523 5 12V8H3ZM11 11H21V9H11V11ZM21 15H11V13H21V15ZM11 19H21V17H11V19Z'
    fill='black'
    fillOpacity='0.38'
    fillRule='evenodd'
  />,
  'ViewChildrenIcon'
);

ViewChildrenIcon.defaultProps = {
  viewBox: '0 0 24 24'
};

ViewChildrenIcon.displayName = 'ViewChildrenIcon';

export default ViewChildrenIcon;
