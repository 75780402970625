import { ChevronLeft, FilterList as FilterListIcon } from '@mui/icons-material';
import {
  alpha,
  Box,
  Collapse,
  IconButton,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { GridFilterListIcon } from '@mui/x-data-grid-pro';

import React, { useState } from 'react';

type DataGridFiltersProps = {
  children?: React.ReactNode;
};

export const DataGridFilters: React.FC<DataGridFiltersProps> = props => {
  const [isSidePanelCollapsed, setSidePanelCollapsed] = useState(false);
  const theme = useTheme();

  return (
    <Box borderRight={`1px solid ${grey[300]}`} p={2}>
      <Stack
        alignItems='center'
        flexDirection='row'
        justifyContent='space-between'
        pb={2}>
        {isSidePanelCollapsed ? (
          <IconButton
            aria-label='Show Filters'
            onClick={() => setSidePanelCollapsed(false)}>
            <GridFilterListIcon fontSize='inherit' />
          </IconButton>
        ) : (
          <>
            <Box sx={{ alignItems: 'center', display: 'flex', gap: 1.5 }}>
              <FilterListIcon
                fontSize='inherit'
                sx={{ fill: alpha('#000', 0.54) }}
              />
              <Typography variant='h6'>Filters</Typography>
            </Box>
            <IconButton
              aria-label='Hide Filters'
              onClick={() => setSidePanelCollapsed(true)}>
              <ChevronLeft fontSize='inherit' />
            </IconButton>
          </>
        )}
      </Stack>

      <Collapse
        easing={theme.transitions.easing.sharp}
        in={!isSidePanelCollapsed}
        orientation='horizontal'>
        {props.children}
      </Collapse>
    </Box>
  );
};
