import formatters from '@/utils/Formatters';
import { ArrowBackIos, ArrowForwardIos, Warning } from '@mui/icons-material';
import { Alert } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  IconButton,
  Popper,
  Theme,
  Typography
} from '@mui/material';
import { green } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

import { ColDef } from 'ag-grid-community';
import Decimal from 'decimal.js';
import React, {
  ElementType,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { AgGridEditorContext } from './AgGridEditorContext';
import { EditorComponentProps } from './AgGridTypes';

type Navigation = {
  rowIndex: number;
  colKey: string;
  pageIndex: number;
};

const useStyles = makeStyles((theme: Theme) => ({
  negativeAmount: {
    color: theme.palette.error.main
  },
  positiveAmount: {
    color: green[700]
  },
  warning: {
    '& .MuiAlert-icon': {
      alignItems: 'center',
      display: 'flex',
      padding: 0
    },
    '& .MuiAlert-message': {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      justifyContent: 'space-between'
    }
  }
}));

export function AgGridEditor(config: ColDef, Component: ElementType): ColDef {
  const EditorComponent = (props: EditorComponentProps) => {
    const ctx = useContext(AgGridEditorContext);
    const inputRef = useRef();
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);

    const originalValue =
      ctx?.initialRows?.[props.data?.uuid]?.[props.colDef.field ?? ''];
    const errorKey = `[${props.data?.uuid}].${props.colDef?.field}`;
    const error = ctx?.errors?.[errorKey]?.[0];

    const currentErrorIndex = useMemo(
      () => Object.keys(ctx?.errors ?? {}).indexOf(errorKey),
      [ctx?.errors, errorKey]
    );

    const errorsLength = useMemo(
      () => Object.keys(ctx?.errors ?? {}).length,
      [ctx?.errors]
    );

    const original = useMemo(
      () => formatters.formatDollars(originalValue),
      [originalValue]
    );

    const difference = useMemo(() => {
      const originalAmount = new Decimal(originalValue);
      const newAmount = new Decimal(
        props.node?.data?.[props.colDef?.field ?? '']
      );

      const value = originalAmount.lessThan(newAmount)
        ? newAmount.minus(originalAmount)
        : originalAmount.minus(newAmount).negated();

      return value.toNumber();
    }, [originalValue, props.node?.data, props.colDef?.field]);

    const formattedDifference = useMemo(
      () => (difference > 0 ? '+' : '') + formatters.formatDollars(difference),
      [difference]
    );

    const navigation = useMemo(() => {
      const getPathDetails = (path: string, pageSize: number): Navigation => {
        const rowIndex = +path[1];
        const colKey = path.slice(path.indexOf('.') + 1);
        const pageIndex = Decimal.div(rowIndex, pageSize).floor().toNumber();

        return { colKey, pageIndex, rowIndex };
      };

      const keys = Object.keys(ctx?.errors ?? {});

      if (!keys.length) {
        return {
          back: { colKey: '', pageIndex: 0, rowIndex: 0 },
          forward: { colKey: '', pageIndex: 0, rowIndex: 0 }
        };
      }

      const nextErrorIndex =
        currentErrorIndex + 1 >= keys.length ? 0 : currentErrorIndex + 1;
      const prevErrorIndex =
        currentErrorIndex - 1 < 0 ? keys.length - 1 : currentErrorIndex - 1;

      const pageSize = props.api?.paginationGetPageSize();

      const back = getPathDetails(keys?.[prevErrorIndex], pageSize);
      const forward = getPathDetails(keys?.[nextErrorIndex], pageSize);

      return { back, forward };
    }, [ctx?.errors, currentErrorIndex, props.api]);

    const onChange = useCallback(
      value => {
        ctx?.setValue(props.data?.uuid, props.colDef?.field, value);
      },
      [props.colDef, props.data?.uuid, ctx]
    );

    const onNavigate = useCallback(
      (nav: Navigation) => {
        const currentPageIndex = props.api?.paginationGetCurrentPage();

        if (nav.pageIndex !== currentPageIndex) {
          props.api?.paginationGoToPage(nav.pageIndex);
          setTimeout(() => {
            props.api?.startEditingCell(nav);
          }, 100);
          return;
        }

        props.api?.stopEditing();
        props.api?.startEditingCell(nav);
      },
      [props.api]
    );

    const onNavigateForward = useCallback(
      () => onNavigate(navigation.forward),
      [navigation, onNavigate]
    );

    const onNavigateBack = useCallback(
      () => onNavigate(navigation.back),
      [navigation, onNavigate]
    );

    const onRestore = useCallback(() => {
      ctx?.setValue(props.data?.uuid, props.colDef?.field, originalValue);
      props.api?.stopEditing();
    }, [props.api, originalValue, props.colDef?.field, props.data?.uuid, ctx]);

    useEffect(() => {
      setIsOpen(
        originalValue !== props.node?.data?.[props.colDef?.field ?? ''] ||
          !!error
      );
    }, [originalValue, error, props.node?.data, props.colDef?.field]);

    return (
      <>
        <Component {...props} onChange={onChange} ref={inputRef} />
        <Popper
          anchorEl={inputRef.current}
          id='ag-grid-editor-popover'
          open={isOpen}>
          {!!error && (
            <Card sx={{ minWidth: 160 }}>
              <Alert
                className={classes.warning}
                icon={<Warning />}
                severity='error'>
                <Typography variant='subtitle1'>
                  Error {currentErrorIndex + 1} / {errorsLength}
                </Typography>
                <Box sx={{ display: 'flex' }}>
                  <IconButton
                    disabled={errorsLength <= 1}
                    onClick={onNavigateBack}
                    size='small'>
                    <ArrowBackIos fontSize='inherit' />
                  </IconButton>
                  <IconButton
                    disabled={errorsLength <= 1}
                    onClick={onNavigateForward}
                    size='small'>
                    <ArrowForwardIos fontSize='inherit' />
                  </IconButton>
                </Box>
              </Alert>
              <Typography p={2}>{error}</Typography>
              <Button
                onClick={onRestore}
                sx={{ margin: 1, marginTop: 0 }}
                variant='text'>
                Restore Original Value
              </Button>
            </Card>
          )}
          {!error && (
            <>
              <Card sx={{ minWidth: 160, padding: 2 }}>
                <Typography variant='subtitle1'>Original</Typography>
                <Typography component='span' mr={1}>
                  {original}
                </Typography>
                <Typography
                  className={
                    difference > 0
                      ? classes.positiveAmount
                      : classes.negativeAmount
                  }
                  component='span'
                  variant='body2'>
                  {formattedDifference}
                </Typography>
              </Card>
            </>
          )}
        </Popper>
      </>
    );
  };

  EditorComponent.displayName = 'AgGridEditor';

  return {
    ...config,
    cellEditor: EditorComponent
  };
}
