import LinearLoading from '@/components/linear-loading';
import NavigationBreadcrumbs from '@/components/navigation-breadcrumbs';
import ApiService from '@/services/Api.service';
import InvestmentService from '@/services/Investment.service';
import { useQuery } from '@tanstack/react-query';

import { useParams } from 'react-router-dom';

import TargetSeries, {
  TargetSeriesData,
  TargetSeriesGridData
} from './TargetSeries.component';

type TargetSeriesEditRouteProps = {
  id: string;
};

const TargetSeriesEditRoute = (): JSX.Element => {
  const params = useParams<TargetSeriesEditRouteProps>();

  const paths = [
    {
      name: 'Ops',
      to: '/ops/investments'
    },
    {
      name: 'Investments',
      to: '/ops/investments'
    }
  ];

  const targetSeriesQuery = useQuery<TargetSeriesData>(
    ['PlanService.getPlansPage', 'target-series', params.id],
    () => {
      return InvestmentService.getTargetModelSeries(+params.id);
    },
    { cacheTime: 0 }
  );

  const targetSeriesGridQuery = useQuery<TargetSeriesGridData>(
    ['PlanService.getPlansPage', 'grid-models', params.id],
    () => {
      return InvestmentService.getTargetModelSeriesGrid(+params.id);
    },
    { cacheTime: 0 }
  );

  const programCount = useQuery<any>(
    ['PlanService.getPlansPage', 'program-count', params.id],
    () => {
      return ApiService.getJson(
        `/investments/program?targetDateSeriesId=${+params.id}&pageNumber=1&pageSize=0`
      );
    },
    { cacheTime: 0 }
  );

  const onChange = async (
    updatedTargetSeries: TargetSeriesData,
    updatedModelGridProps: TargetSeriesGridData
  ): Promise<[TargetSeriesData, TargetSeriesGridData]> => {
    const result = InvestmentService.putTargetModelSeries(updatedTargetSeries);
    const gridResult = InvestmentService.postTargetModelSeriesGrid(
      +params.id,
      updatedModelGridProps
    );

    const all = await Promise.all([result, gridResult]);
    return all;
  };

  const loading =
    targetSeriesQuery.isFetching ||
    targetSeriesGridQuery.isFetching ||
    programCount.isFetching;
  const canDisplay = !loading && targetSeriesQuery.data !== undefined;
  const possibleError = !loading && !canDisplay;

  return (
    <>
      <NavigationBreadcrumbs paths={paths} />
      {loading && <LinearLoading />}
      {canDisplay && (
        <TargetSeries
          lookupTickerCallback={InvestmentService.searchSymbol}
          programCount={programCount.data?.meta?.count}
          saveCallback={onChange}
          targetSeries={targetSeriesQuery.data}
          targetSeriesGrid={targetSeriesGridQuery.data}
        />
      )}
      {possibleError && <div>Possible Error</div>}
    </>
  );
};

export default TargetSeriesEditRoute;
