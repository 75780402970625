import { useSnackbar } from '@/contexts/SnackBarContext';
import { PlanService } from '@/services/Plan.service';
import { alpha, Box, Button, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useMutation } from '@tanstack/react-query';

import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${alpha(theme.palette.common.black, 0.12)}`,
    borderRadius: theme.spacing(0.5),
    marginTop: theme.spacing(4),
    padding: theme.spacing(2)
  }
}));

interface AnnualTestingNotificationsProps {
  sponsorPlanId: number;
}

const AnnualTestingNotifications = (
  props: AnnualTestingNotificationsProps
): JSX.Element => {
  const { sponsorPlanId } = props;
  const { showSnackbar } = useSnackbar();

  const classes = useStyles();

  const sendUnderfundedContributionsReport = useMutation(
    ['PlanService.sendUnderfundedContributionsCalcReport', +sponsorPlanId],
    () => {
      return PlanService.sendUnderfundedContributionsCalcReport(+sponsorPlanId);
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Failed!',
          severity: 'error'
        });
      },
      onSuccess: async () => {
        showSnackbar({
          autoHideDuration: 5000,
          message: 'Success!',
          severity: 'success'
        });
      }
    }
  );

  const sendOverfundedContributionsReport = useMutation(
    ['PlanService.sendOverfundedContributionsCalcReport', +sponsorPlanId],
    () => {
      return PlanService.sendOverfundedContributionsCalcReport(+sponsorPlanId);
    },
    {
      onError: () => {
        showSnackbar({
          message: 'Failed!',
          severity: 'error'
        });
      },
      onSuccess: async () => {
        showSnackbar({
          autoHideDuration: 5000,
          message: 'Success!',
          severity: 'success'
        });
      }
    }
  );

  return (
    <Box className={classes.root}>
      <Typography variant='h6'>Notifications</Typography>
      <Box display='flex' gap={1} marginTop={2}>
        <Button
          data-testid='annual-testing-send-underfunded-contributions-email-btn'
          disabled={sendUnderfundedContributionsReport.isLoading}
          onClick={() => sendUnderfundedContributionsReport.mutate()}
          variant='contained'>
          Send Underfunded Contributions Email
        </Button>
        <Button
          data-testid='annual-testing-send-overfunded-contributions-email-btn'
          disabled={sendOverfundedContributionsReport.isLoading}
          onClick={() => sendOverfundedContributionsReport.mutate()}
          variant='contained'>
          Send Overfunded Contributions Email
        </Button>
      </Box>
    </Box>
  );
};

export default AnnualTestingNotifications;
