import { NumberFormatForm } from '@/components/number-format-form/NumberFormatForm.component';
import { Beneficiary } from '@/models/ParticipantBeneficiary.model';
import formatters from '@/utils/Formatters';
import {
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { grey } from '@mui/material/colors';

import { useFormikContext } from 'formik';
import { cloneDeep } from 'lodash';
import { useEffect, useMemo } from 'react';

type BeneficiariesAllocationsFormTableProps = {
  isContingent?: boolean;
};

export const BeneficiariesAllocationsFormTable: React.FC<
  BeneficiariesAllocationsFormTableProps
> = (props: BeneficiariesAllocationsFormTableProps) => {
  const formik = useFormikContext<{
    beneficiaries: Beneficiary[];
    beneficiaryAttributes: Beneficiary['attributes'];
    deleteBeneficiary: Beneficiary;
  }>();

  const beneficiariesFiltered = useMemo(
    () =>
      formik.values.beneficiaries.filter(
        beneficiary => beneficiary.attributes.isPrimary === !props.isContingent
      ),
    [formik.values.beneficiaries]
  );
  const total = useMemo(
    () =>
      beneficiariesFiltered.reduce(
        (accumulator, beneficiary) =>
          accumulator + +beneficiary.attributes.allocationPct,
        0
      ),
    [formik.values]
  );

  useEffect(() => {
    if (formik.values.beneficiaryAttributes) {
      formik.setFieldValue(
        'beneficiaries',
        formik.values.beneficiaryAttributes
          ? [
              ...formik.initialValues.beneficiaries,
              {
                attributes: formik.values.beneficiaryAttributes,
                type: 'participant-beneficiary'
              }
            ]
          : [...formik.initialValues.beneficiaries]
      );
    }
  }, [formik.values.beneficiaryAttributes]);

  useEffect(() => {
    if (formik.values.deleteBeneficiary) {
      const newBeneficiaries = cloneDeep(
        formik.initialValues.beneficiaries
      ).map(beneficiary => {
        if (beneficiary.id === formik.values.deleteBeneficiary.id) {
          beneficiary.attributes.allocationPct = 0;
        }
        return beneficiary;
      });

      formik.setFieldValue('beneficiaries', newBeneficiaries);
    }
  }, [formik.values.deleteBeneficiary]);

  const dataTestId = `${props.isContingent ? `contingent` : `primary`}-beneficiaries-allocations-form-table`;
  return beneficiariesFiltered.length ? (
    <TableContainer>
      <Table data-testid={dataTestId} sx={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell data-testid={`${dataTestId}-title`}>
              {props.isContingent ? `Contingent` : `Primary`} Beneficiaries
            </TableCell>
            <TableCell align='right'>Allocation</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formik.values.beneficiaries.map((beneficiary, index) =>
            beneficiary.attributes.isPrimary === !props.isContingent ? (
              <TableRow
                key={beneficiary.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell
                  component='th'
                  data-testid={`${dataTestId}-${beneficiary.id}-name`}
                  scope='row'>
                  {formatters.getBeneficiaryName(beneficiary)}
                </TableCell>
                <TableCell align='right' sx={{ p: 0 }}>
                  <NumberFormatForm
                    disabled={
                      formik.values.deleteBeneficiary?.id &&
                      formik.values.deleteBeneficiary?.id === beneficiary.id
                    }
                    name={`beneficiaries[${index}].attributes.allocationPct`}
                    numericFormatProps={{
                      'data-testid': `${dataTestId}-participant-beneficiaries-${beneficiary.id}-allocationPct-input`,
                      style: { textAlign: 'right' },
                      suffix: '%'
                    }}
                    placeholder='Allocation'
                    size='small'
                    sx={{ width: '100px' }}
                  />
                </TableCell>
              </TableRow>
            ) : null
          )}
          <TableRow
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },
              backgroundColor: grey[100]
            }}>
            <TableCell component='th' scope='row' sx={{ p: 1, pl: 2 }}>
              Total
            </TableCell>
            <TableCell
              align='right'
              data-testid={`${dataTestId}-total`}
              sx={{ p: 0, pr: 2 }}>
              <b>{total}%</b>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {total !== 100 && (
        <Alert
          data-testid={`${dataTestId}-error-alert`}
          severity='error'
          sx={{ mt: 2 }}>
          Percentages must total to 100%
        </Alert>
      )}
    </TableContainer>
  ) : (
    <></>
  );
};
