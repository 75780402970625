import { FormattedVestwellStaff } from '@/services/AuthZ.service';
import formatters from '@/utils/Formatters';
import {
  Autocomplete,
  FormControl,
  Unstable_Grid2 as Grid,
  InputLabel,
  MenuItem,
  MenuProps,
  OutlinedInput,
  Select,
  TextField
} from '@mui/material';
import { AlertPriority } from '@vestwell-sub-accounting/models/common/AlertPriority';
import { AlertStatus } from '@vestwell-sub-accounting/models/common/AlertStatus';

import { useContext, useEffect, useState } from 'react';

import { AlertContext } from '../common/AlertContext';
import { useUpdateAlertMutation } from './useUpdateAlertMutation.hook';

type UpdateAlertFormProps = {
  dense?: boolean;
  hasWritePermissions: boolean;
  vestwellStaff?: FormattedVestwellStaff[];
};

export const UpdateAlertForm = (
  props: UpdateAlertFormProps
): JSX.Element | null => {
  const { dense, hasWritePermissions, vestwellStaff } = props;

  const alert = useContext(AlertContext);
  const [assigneeInputValue, setAssigneeInputValue] = useState('');

  const [assignee, setAssignee] = useState<{
    label: string;
    id: string;
  } | null>(null);

  const updateAlertMutation = useUpdateAlertMutation();

  useEffect(() => {
    if (alert?.assignee) {
      const recordAssignee = vestwellStaff?.find(user => {
        return user.userId === alert.assignee;
      });

      setAssignee(
        recordAssignee
          ? {
              id: String(recordAssignee.userId),
              label: recordAssignee.label
            }
          : null
      );
    }
  }, [alert, vestwellStaff]);

  const doStatusUpdate = (value: AlertStatus) => {
    if (alert)
      updateAlertMutation.mutate({
        alertId: alert.id,
        updateRequest: { alertStatus: value }
      });
  };

  const doPriorityUpdate = (value: AlertPriority) => {
    if (alert)
      updateAlertMutation.mutate({
        alertId: alert.id,
        updateRequest: { priority: value }
      });
  };

  return (
    <Grid container spacing={2} sx={{ maxWidth: 880 }}>
      <Grid sm={dense ? 6 : 3} xs={6}>
        <FormControl fullWidth size='small'>
          <InputLabel id='menu-alert-status-label' shrink>
            Status
          </InputLabel>
          <Select
            MenuProps={
              {
                'data-testid': 'menu-alert-status'
              } as Partial<MenuProps> // assertion needed to avoid typescript bug https://github.com/microsoft/TypeScript/issues/28960
            }
            data-testid='alert-status'
            displayEmpty
            input={<OutlinedInput label='Status' notched />}
            label='Status'
            labelId='menu-alert-status-label'
            onChange={e => {
              doStatusUpdate(e.target.value as AlertStatus);
            }}
            readOnly={!hasWritePermissions}
            value={alert?.alertStatus || ''}>
            {Object.values(AlertStatus).map(value => {
              const displayStatusOption = formatters.getValueKey(
                AlertStatus,
                value
              );
              return (
                <MenuItem key={value} value={value}>
                  {formatters.displayCase(displayStatusOption)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid sm={dense ? 6 : 3} xs={6}>
        <FormControl fullWidth size='small'>
          <InputLabel id='menu-alert-priority-label' shrink>
            Priority
          </InputLabel>
          <Select
            MenuProps={
              {
                'data-testid': 'menu-alert-priority'
              } as Partial<MenuProps> // assertion needed to avoid typescript bug https://github.com/microsoft/TypeScript/issues/28960
            }
            data-testid='alert-priority'
            displayEmpty
            input={<OutlinedInput label='Priority' notched />}
            label='Priority'
            labelId='menu-alert-priority-label'
            onChange={e => {
              doPriorityUpdate(e.target.value as AlertPriority);
            }}
            readOnly={!hasWritePermissions}
            value={alert?.priority || ''}>
            {!alert?.priority && <MenuItem value=''>{'\u2014'}</MenuItem>}
            {Object.values(AlertPriority).map(value => {
              const displayPriorityOption = formatters.getValueKey(
                AlertPriority,
                value
              );
              return (
                <MenuItem key={value} value={value}>
                  {formatters.displayCase(displayPriorityOption)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      {alert && vestwellStaff && (
        <Grid sm={dense ? 12 : 6} xs={12}>
          <FormControl fullWidth size='small'>
            <Autocomplete
              data-testid='alert-assignee'
              disablePortal
              inputValue={assigneeInputValue}
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
              onBlur={() => {
                if (assigneeInputValue === '' && alert?.assignee) {
                  // this must be firing after the user emptied the assignee field and then exited the field
                  // there was an assignee assignee so now it is safe to say they are wanting to clear the assignee
                  setAssignee(null);
                  updateAlertMutation.mutate({
                    alertId: alert.id,
                    updateRequest: {
                      assignee: ''
                    }
                  });
                }
              }}
              onChange={(
                event,
                newValue: { label: string; id: string },
                reason
              ) => {
                if (reason === 'clear' && event.type !== 'click') {
                  // this must be firing just from the user backspacing the input field so don't update the assignee yet
                  return;
                }
                setAssignee(newValue);
                updateAlertMutation.mutate({
                  alertId: alert.id,
                  updateRequest: {
                    assignee: newValue?.id || ''
                  }
                });
              }}
              onInputChange={(_event, newInputValue) => {
                setAssigneeInputValue(newInputValue);
              }}
              options={vestwellStaff.map(user => ({
                id: String(user.userId),
                label: user.label
              }))}
              readOnly={!hasWritePermissions}
              renderInput={params => (
                <TextField
                  {...params}
                  data-testid='alert-assignee-input-field'
                  label='Assignee'
                />
              )}
              size='small'
              value={assignee}
            />
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
};

export default UpdateAlertForm;
