import { Box, TextareaAutosize, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { useField } from 'formik';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => {
  return {
    input: {
      borderColor: theme.palette.text.primary,
      color: theme.palette.text.primary,
      padding: '.5rem',
      width: '100%'
    },
    label: {
      color: theme.palette.text.secondary
    }
  };
});

interface SimpleTextareaProps {
  label: string;
  name: string;
  placeholder?: string;
}

const SimpleTextarea: React.FunctionComponent<SimpleTextareaProps> = ({
  label,
  name,
  placeholder
}: SimpleTextareaProps) => {
  const classes = useStyles();
  const [field, , helpers] = useField<string>(name);
  const initialValue = field.value || '';

  return (
    <>
      <Box mb={1}>
        <Typography className={classes.label} variant='subtitle1'>
          {label}
        </Typography>
      </Box>
      <TextareaAutosize
        className={classes.input}
        minRows={3}
        name={name}
        onChange={event => helpers.setValue(event.target.value)}
        placeholder={placeholder}
        value={initialValue}
      />
    </>
  );
};

export default SimpleTextarea;
