import formatters from '@/utils/Formatters';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import React from 'react';

import { ContributionCorrectionDtoWithUUID } from './PlanCorrectionsDetailsTable.component';

interface CorrectionCollapsibleTableProps {
  row: ContributionCorrectionDtoWithUUID;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      ...theme.typography.caption,
      color: theme.palette.grey[700],
      fontSize: theme.spacing(2),
      height: theme.spacing(6),
      padding: '0',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    bold: {
      fontWeight: 'bold'
    },
    lastRowNoBorder: {
      '&:last-child th, &:last-child td': {
        borderBottom: 0
      }
    },
    table: {
      paddingLeft: '50px',
      width: '50%'
    }
  })
);

const CorrectionCollapsibleTable = (
  props: CorrectionCollapsibleTableProps
): JSX.Element => {
  const classes = useStyles();
  const { row } = props;

  return (
    <TableContainer
      className={classes.table}
      data-testid='completed-transactions-table'>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Field</TableCell>
            <TableCell>Old Value</TableCell>
            <TableCell>New Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {row.fieldNames
            .filter(name => (row.isStateIRA ? name === 'rc' : true))
            .map((name: string) => {
              return (
                <TableRow
                  className={classes.lastRowNoBorder}
                  key={`${row.uuid}_${name}`}>
                  <TableCell>
                    {formatters.formatContributionCorrectionFields(name)}
                  </TableCell>
                  <TableCell>{row.initialValue[name] || '--'}</TableCell>
                  <TableCell>{row.correctionValue[name] || '--'}</TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CorrectionCollapsibleTable;
