import { useAuth0 } from '@auth0/auth0-react';
import { ExitToApp } from '@mui/icons-material';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';

import { BroadcastChannel } from 'broadcast-channel';
import React from 'react';

export type BroadcastSignal = {
  action: string;
  tabId: string;
};

const Logout: React.FunctionComponent<Record<string, unknown>> = () => {
  const logoutChannel = new BroadcastChannel('portal-logout');
  const { logout } = useAuth0();
  const SIGNAL = {
    action: 'logout-all-tabs',
    tabId: sessionStorage.getItem('tabId')
  };

  logoutChannel.onmessage = (signal: BroadcastSignal) => {
    if (signal.action === SIGNAL.action && signal.tabId !== SIGNAL.tabId) {
      logout({
        returnTo: window.location.origin
      });
      logoutChannel.close();
    }
  };

  const performLogout = () => {
    logoutChannel.postMessage(SIGNAL);
    logout({ returnTo: window.location.origin });
    logoutChannel.close();
  };

  return (
    <ListItem button key='Log Out' onClick={performLogout}>
      <ListItemIcon>
        <ExitToApp />
      </ListItemIcon>
      <ListItemText>Log Out</ListItemText>
    </ListItem>
  );
};

export default Logout;
