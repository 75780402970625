import { useSnackbar } from '@/contexts/SnackBarContext';
import { PlanV2Dto } from '@/models/PlanV2DTO.model';
import ParticipantService from '@/services/Participant.service';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { FC } from 'react';

export type RemoveEmployeeRow = {
  id: number;
  name: string;
  pageNumber: number;
  planName: string;
  planType: string;
  rowsPerPage: number;
  sponsorPlanId: number;
  ssn: string;
  debouncedSearchTerm?: string;
  plan?: PlanV2Dto;
  birthDate: string;
  createdAt: string;
};

type DryRunErrors = {
  mergeParticipantErrors?: string[];
  removeParticipantErrors?: string[];
};

type DryRunDialogProps = {
  open: boolean;
  row: RemoveEmployeeRow;
  errors?: DryRunErrors;
  handleClose: () => void;
};

export const DryRunDialog: FC<DryRunDialogProps> = props => {
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const removeParticipantFromPlanMutation = useMutation(
    ['ParticipantService.removeParticipantFromPlan'],
    () => {
      return ParticipantService.removeParticipantFromPlan(
        props.row.id,
        props.row.sponsorPlanId
      );
    },
    {
      onError: (error: any) => {
        showSnackbar({
          message: error?.message ?? 'Failed!',
          severity: 'error'
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries([
          'PlanService.getParticipantsByPlanId',
          props.row.sponsorPlanId,
          props.row.pageNumber,
          props.row.rowsPerPage,
          props.row.debouncedSearchTerm
        ]);

        showSnackbar({
          message: 'Success! Saver removed.',
          severity: 'success'
        });
        props.handleClose();
      }
    }
  );
  return (
    <Dialog
      aria-labelledby='draggable-dialog-title'
      data-testid='dry-run-dialog'
      onClose={() => {
        if (!removeParticipantFromPlanMutation.isLoading) {
          props.handleClose();
        }
      }}
      open={props.open}>
      <DialogTitle id='draggable-dialog-title' style={{ cursor: 'move' }}>
        {props.errors?.mergeParticipantErrors?.length
          ? 'Merge Participant'
          : 'Remove Employee'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          data-testid='dry-run-text'
          style={{ textAlign: 'center' }}>
          {props.errors?.mergeParticipantErrors?.length ? (
            <Typography textAlign='left'>
              <b>{`${props.row.name} `}</b>({props.row.ssn}) cannot be merged
              for the following reasons:
              <ul>
                {props.errors?.mergeParticipantErrors?.map(error => {
                  return <li key={error}>{error}</li>;
                })}
              </ul>
            </Typography>
          ) : props.errors?.removeParticipantErrors?.length ? (
            <Typography textAlign='left'>
              <b>{`${props.row.name} `}</b>({props.row.ssn}) cannot be removed
              from the plan for the following reasons:
              <ul>
                {props.errors?.removeParticipantErrors?.map(error => {
                  return <li key={error}>{error}</li>;
                })}
              </ul>
            </Typography>
          ) : (
            <>
              Are you sure you want to remove employee{' '}
              <b>{`${props.row.name} `}</b>({props.row.ssn}) from{' '}
              {props.row.planName} {props.row.planType} plan?
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          data-testid='dont-remove-btn'
          disabled={removeParticipantFromPlanMutation.isLoading}
          onClick={props.handleClose}
          type='button'
          variant='text'>
          {props.errors?.mergeParticipantErrors?.length
            ? 'Close'
            : `Don't Remove`}
        </Button>
        {!props.errors?.mergeParticipantErrors?.length &&
          !props.errors?.removeParticipantErrors?.length && (
            <Box sx={{ m: 1, position: 'relative' }}>
              <Button
                data-testid='remove-btn'
                disabled={removeParticipantFromPlanMutation.isLoading}
                onClick={() => removeParticipantFromPlanMutation.mutate()}
                variant='contained'>
                Remove Employee
              </Button>
              {removeParticipantFromPlanMutation.isLoading && (
                <CircularProgress
                  size={24}
                  style={{
                    left: '50%',
                    marginLeft: '-12px',
                    marginTop: '-12px',
                    position: 'absolute',
                    top: '50%'
                  }}
                />
              )}
            </Box>
          )}
      </DialogActions>
    </Dialog>
  );
};
