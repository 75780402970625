import { AlertProps } from '@mui/material/Alert';

import * as React from 'react';
import { useRef } from 'react';

import SnackBarImperative from './SnackBarImperative.component';

export type SnackbarOptions = AlertProps & {
  autoHideDuration?: number;
  message: string;
};

type SnackbarProviderProps = { children: React.ReactNode };

type SnackbarContextType = {
  showSnackbar: (options: SnackbarOptions) => void;
  hideSnackbar: () => void;
};

const SnackbarContext = React.createContext<SnackbarContextType | undefined>(
  undefined
);

function SnackbarProvider({ children }: SnackbarProviderProps): JSX.Element {
  const snackbarRef = useRef<any>();
  const showSnackbar = (options: SnackbarOptions) => {
    snackbarRef?.current?.showSnackbar(
      options.message,
      options.autoHideDuration,
      options.severity,
      options.action
    );
  };
  const hideSnackbar = () => snackbarRef?.current?.hideSnackbar();

  const value = React.useMemo(() => ({ hideSnackbar, showSnackbar }), []);
  return (
    <SnackbarContext.Provider value={value}>
      <SnackBarImperative ref={snackbarRef} />
      {children}
    </SnackbarContext.Provider>
  );
}

function useSnackbar(): SnackbarContextType {
  const context = React.useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
}

export { SnackbarProvider, useSnackbar };
