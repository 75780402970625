import AccessControl from '@/components/access-control/AccessControl.component';
import { DocumentMetadataListDto } from '@/models';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { Box } from '@mui/material';

import React from 'react';

import AmendmentNotificationsCard from './AmendmentNotificationsCard.component';
import PlanDocumentsCard from './PlanDocumentsCard.component';

interface PlanDocuments {
  sponsorPlanId: number;
  planName: string;
}

export type SlotMetaData = {
  document_key: string;
  section: string;
  slotName: string;
  latest?: DocumentMetadataListDto[number];
  uploadHistory: DocumentMetadataListDto[];
};

const PlanDocumentsTab = (props: PlanDocuments): JSX.Element => {
  const { sponsorPlanId, planName } = props;

  return (
    <>
      <Box display='flex' flexDirection='row' margin='25px 0' width='100%'>
        <Box width='100%'>
          <AccessControl
            requires={[FeatureLevelPermissions.WRITE_AMENDMENTS_ACTIONS]}>
            <AmendmentNotificationsCard
              planName={planName}
              sponsorPlanId={sponsorPlanId}
            />
          </AccessControl>
        </Box>
        <Box width='100%'></Box>
      </Box>
      <PlanDocumentsCard sponsorPlanId={sponsorPlanId} />
    </>
  );
};

export default PlanDocumentsTab;
