const validateDateSsnField = (
  maxLength: number,
  e: React.ChangeEvent<HTMLInputElement>
): boolean => {
  const newValue = Number(
    e.target.value.replaceAll('/', '').replaceAll('-', '')
  );
  const valueLength = e.target.value.length;
  if (
    Number.isNaN(newValue) ||
    e.target.value.includes('.') ||
    valueLength > maxLength
  ) {
    return false;
  }
  return true;
};

const validateSurpasAccount = (
  maxLength: number,
  e: React.ChangeEvent<HTMLInputElement>
): boolean => {
  const valueLength = e.target.value.length;
  if (
    Number.isNaN(Number(e.target.value)) ||
    e.target.value.includes('.') ||
    valueLength > maxLength
  ) {
    return false;
  }
  return true;
};

const validateNameField = (
  maxLength: number,
  e: React.ChangeEvent<HTMLInputElement>
): boolean => {
  return e.target.value.length <= maxLength;
};

const validationFunctions = {
  validateDateSsnField,
  validateNameField,
  validateSurpasAccount
};

export default validationFunctions;
