import CollapsibleTable, {
  CellComponentProps,
  Order
} from '@/components/collapsible-table';
import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import { Program } from '@/models/ops/investments/Program.model';
import { ProgramService } from '@/services/ops/investments/Program.service';
import { Search } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  InputAdornment,
  Link,
  TableCell,
  TextField,
  Theme,
  Typography
} from '@mui/material';
import { blue } from '@mui/material/colors';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export interface ModelProgramData {
  programId: number;
  name: string;
}

interface ModelProgramsProps {
  investmentOptionId: number;
  modelType: string;
  modalTitle: string;
  handleCloseModal: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      backgroundColor: '#FAFAFA',
      boxShadow: '24px',
      left: '50%',
      maxHeight: '95%',
      minWidth: theme.spacing(60),
      overflowY: 'auto',
      padding: theme.spacing(3),
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: '65%'
    },
    modalCloseBtn: {
      '&:hover': {
        background: 'transparent'
      },
      border: 'none',
      color: blue[500],
      float: 'right',
      fontWeight: 'bold',
      marginRight: theme.spacing(3),
      marginTop: theme.spacing(2)
    },
    modelContainer: {
      marginTop: theme.spacing(4)
    },
    programIdWidth: {
      width: '15%'
    },
    searchInput: {
      width: '50ch'
    },
    searchSection: {
      marginBottom: theme.spacing(3)
    },
    size: {
      fontSize: theme.spacing(2)
    }
  })
);

const ModelProgramTableCell: React.FunctionComponent<CellComponentProps> = (
  props: CellComponentProps
) => {
  const classes = useStyles();
  const { row, column } = props;

  return (
    <TableCell className={classes.size} component='th' scope='row'>
      <Box>
        {column.field === 'name' ? (
          <Link
            component={RouterLink}
            to={`/ops/investments/program/${row.programId}`}
            underline='hover'>
            {row[column.field]}
          </Link>
        ) : (
          row[column.field]
        )}
      </Box>
    </TableCell>
  );
};

const toModelProgramData = (modelProgram: Program): ModelProgramData => {
  return {
    name: modelProgram.name,
    programId: modelProgram.programId
  };
};

const convertModelPrograms = (
  modelProgramsSearchResult: Program[]
): ModelProgramData[] => {
  return modelProgramsSearchResult.map(toModelProgramData);
};

const ModelProgramsModal = (props: ModelProgramsProps): JSX.Element => {
  const { investmentOptionId, modelType, modalTitle, handleCloseModal } = props;
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('name');

  const modelProgramTableColumns: GridColDef[] = [
    {
      cellClassName: classes.programIdWidth,
      field: 'programId',
      headerName: 'Program ID'
    },
    { field: 'name', headerName: 'Program Name' }
  ];

  const modelProgramsSearchResult = useQuery<{
    data: Program[];
    meta: { count: number };
  }>(
    [
      'ProgramService.getModelProgramsPage',
      investmentOptionId,
      modelType,
      pageNumber,
      rowsPerPage,
      searchTerm,
      orderBy,
      order
    ],
    async () => {
      const dto = await ProgramService.getModelProgramsPage(
        investmentOptionId,
        modelType,
        pageNumber,
        rowsPerPage,
        searchTerm,
        orderBy,
        order
      );
      return dto;
    },
    {
      cacheTime: 60000,
      keepPreviousData: true,
      staleTime: 60000
    }
  );

  const modelPrograms = convertModelPrograms(
    modelProgramsSearchResult.data?.data || []
  );

  const searchModelPrograms = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;
    setSearchTerm(search);
    setPageNumber(1);
  };

  const { error } = modelProgramsSearchResult;
  if (error) {
    return redirectToErrorPage(error as Error);
  }

  return (
    <>
      <Box className={classes.modal}>
        <Typography component='div' variant='h5'>
          {modalTitle}
        </Typography>
        <div className={classes.modelContainer}>
          <div className={classes.searchSection}>
            <TextField
              InputProps={{
                onChange: searchModelPrograms,
                placeholder: 'Search programs',
                startAdornment: (
                  <InputAdornment position='start'>
                    <Search />
                  </InputAdornment>
                )
              }}
              className={classes.searchInput}
              data-testid='search-model-program-field'
              variant='outlined'
            />
          </div>
          <Card elevation={0} variant='outlined'>
            <CollapsibleTable
              cellComponent={ModelProgramTableCell}
              columns={modelProgramTableColumns}
              pager={{
                metaCount: modelProgramsSearchResult.data?.meta.count,
                onPageNumberChanged: (zeroIndexedPageNumber: number) => {
                  return setPageNumber(zeroIndexedPageNumber + 1);
                },
                onRowsPerPageChanged: (newRowsPerPage: number) => {
                  return setRowsPerPage(newRowsPerPage);
                },
                pageNumber: pageNumber - 1,
                rowsPerPage
              }}
              sorter={{
                onSortOrderChanged: (newOrderBy: string, newOrder: Order) => {
                  setOrderBy(newOrderBy);
                  setOrder(newOrder);
                },
                order,
                orderBy
              }}
              tableData={modelPrograms}
            />
          </Card>
        </div>
        <Button
          className={classes.modalCloseBtn}
          data-testid='modal-close-btn'
          onClick={() => handleCloseModal()}>
          CLOSE
        </Button>
      </Box>
    </>
  );
};

export default ModelProgramsModal;
