import AccessControl from '@/components/access-control/AccessControl.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import PayrollProviderDto from '@/models/PayrollProviderDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { PlanService } from '@/services/Plan.service';
import { EditOutlined } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { PartnerSystem } from '@vestwell-api/scala';

import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import { sortBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useToggle } from 'react-use';
import * as yup from 'yup';

export const ExternalPlanIds: React.FunctionComponent<{
  sponsorPlanId: number;
  open: boolean;
  toggleOpen: () => void;
}> = props => {
  const [add, toggleAdd] = useToggle(false);
  const [edit, setEdit] = useState<number | undefined>();
  const { showSnackbar } = useSnackbar();

  const planMappings = useQuery(
    ['PlanService.getPartnerSystemMappings', 3, props.sponsorPlanId],
    () => {
      return PlanService.getPartnerSystemMappings(3, props.sponsorPlanId);
    }
  );
  const postPsm = useMutation(
    ['PlanService.postPartnerSystemMapping'],
    (data: { partnerSystemId: number; externalId: string }) => {
      return PlanService.postPartnerSystemMapping(
        props.sponsorPlanId,
        data.partnerSystemId,
        data.externalId
      );
    },
    {
      onError: (e: any) => {
        showSnackbar({
          message: e.message,
          severity: 'error'
        });
      },
      onSuccess: async () => {
        await planMappings.refetch();
        showSnackbar({
          message: `External ID added successfully`,
          severity: 'success'
        });
        toggleAdd(false);
      }
    }
  );

  const partnerSystems = useQuery(['PlanService.getPartnerSystems'], () => {
    return PlanService.getPartnerSystems(props.sponsorPlanId);
  });

  const payrollProviders = useQuery<PayrollProviderDto[]>(
    ['PlanService.getPayrollProviders'],
    async () => {
      return PlanService.getPayrollProviders();
    }
  );

  const sortedPartnerSystems = useMemo(() => {
    const filteredPartnerSystems = partnerSystems.data?.filter(partnerSystem =>
      payrollProviders.data?.some(
        payrollProvider => payrollProvider.name === partnerSystem.name
      )
    );
    return sortBy(filteredPartnerSystems, a => a.name);
  }, [partnerSystems.data, payrollProviders.data]);

  const putPsm = useMutation(
    ['PlanService.update'],
    (data: { psmId: number; values: PartnerSystem.PutMapping.RequestBody }) => {
      return PlanService.putPartnerSystemMapping(
        props.sponsorPlanId,
        data.psmId,
        data.values
      );
    },
    {
      onError: (e: any) => {
        showSnackbar({
          message: e.message,
          severity: 'error'
        });
      },
      onSuccess: async () => {
        await planMappings.refetch();
        showSnackbar({
          message: `External ID updated successfully`,
          severity: 'success'
        });
        setEdit(undefined);
      }
    }
  );

  return (
    <Dialog
      fullWidth
      maxWidth='lg'
      onClose={props.toggleOpen}
      open={props.open}>
      <DialogTitle
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: 0
        }}>
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: 2
          }}>
          <Typography variant='h6'>External Plan IDs</Typography>
          <AccessControl
            requires={[FeatureLevelPermissions.WRITE_PLAN_MAPPINGS]}>
            <Button onClick={toggleAdd} variant='text'>
              New
            </Button>
          </AccessControl>
        </Grid>
        {add && (
          <Grid
            container
            sx={{
              backgroundColor: theme => theme.palette.primary.light,
              display: 'flex',
              flexDirection: 'column',
              padding: 2
            }}>
            <Formik
              initialValues={{
                externalId: '',
                partnerSystemId: ''
              }}
              onSubmit={async values => {
                postPsm.mutate({
                  externalId: values.externalId,
                  partnerSystemId: +values.partnerSystemId
                });
              }}
              validateOnBlur
              validateOnChange
              validationSchema={yup.object({
                externalId: yup.string().required('Required'),
                partnerSystemId: yup.number().required('Required')
              })}>
              {({ values, handleChange, handleBlur, ...formik }) => (
                <Form>
                  <Grid container item spacing={1} xs={12}>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id='partner-system-label'>
                          Partner System
                        </InputLabel>
                        <Select
                          error={
                            formik.touched.partnerSystemId &&
                            Boolean(formik.errors.partnerSystemId)
                          }
                          id='partner-system-id'
                          label='Partner System'
                          labelId='partner-system-label'
                          name='partnerSystemId'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          sx={{
                            backgroundColor: 'white'
                          }}
                          value={values.partnerSystemId}>
                          {sortedPartnerSystems?.map(partnerSystem => (
                            <MenuItem
                              key={partnerSystem.partnerSystemId}
                              value={partnerSystem.partnerSystemId}>
                              {partnerSystem.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        error={
                          formik.touched.externalId &&
                          Boolean(formik.errors.externalId)
                        }
                        fullWidth
                        helperText={
                          formik.touched.externalId && formik.errors.externalId
                        }
                        label='External Plan ID'
                        name='externalId'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        sx={{
                          backgroundColor: 'white'
                        }}
                        value={values.externalId}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Button
                      onClick={() => toggleAdd()}
                      sx={{ marginRight: 2 }}
                      variant='text'>
                      Cancel
                    </Button>
                    <Button
                      disabled={postPsm.isLoading || !formik.isValid}
                      type='submit'
                      variant='text'>
                      Add
                    </Button>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        )}
      </DialogTitle>
      <DialogContent
        sx={{
          padding: 0
        }}>
        <TableContainer data-testid='external-plan-id-table'>
          <Table
            aria-label='external plan id table'
            aria-labelledby='tableTitle'>
            <TableHead>
              <TableRow>
                <TableCell>External Plan ID</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Updated</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {planMappings.data?.map(plan => (
                <React.Fragment key={plan.partnerSystemMappingId}>
                  <TableRow
                    sx={{
                      backgroundColor: theme =>
                        edit === plan.partnerSystemMappingId
                          ? theme.palette.primary.light
                          : undefined
                    }}>
                    <TableCell
                      sx={{
                        width: '60%'
                      }}>
                      <Typography>{plan.externalId}</Typography>
                      <Typography color={theme => theme.palette.grey[600]}>
                        {plan.partnerSystemName}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {dayjs
                        .utc(plan.createdAt)
                        .local()
                        .format('MM/DD/YYYY HH:mm:ss')}
                    </TableCell>
                    <TableCell>
                      {dayjs
                        .utc(plan.createdAt)
                        .local()
                        .format('MM/DD/YYYY HH:mm:ss')}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: 0,
                        width: 8
                      }}>
                      <AccessControl
                        requires={[
                          FeatureLevelPermissions.WRITE_PLAN_MAPPINGS
                        ]}>
                        <Button
                          onClick={() => setEdit(plan.partnerSystemMappingId)}>
                          <EditOutlined
                            sx={{ color: theme => theme.palette.grey[400] }}
                          />
                        </Button>
                      </AccessControl>
                    </TableCell>
                  </TableRow>
                  <AccessControl
                    requires={[FeatureLevelPermissions.WRITE_PLAN_MAPPINGS]}>
                    {edit === plan.partnerSystemMappingId && (
                      <TableRow
                        sx={{
                          backgroundColor: theme => theme.palette.primary.light
                        }}>
                        <TableCell colSpan={4}>
                          <Formik
                            initialValues={plan}
                            onSubmit={async (
                              values: PartnerSystem.PutMapping.RequestBody
                            ) => {
                              putPsm.mutate({
                                psmId: plan.partnerSystemMappingId,
                                values
                              });
                            }}
                            validateOnBlur
                            validateOnChange
                            validationSchema={yup.object({
                              externalId: yup.string().required('Required')
                            })}>
                            {({
                              values,
                              handleChange,
                              handleBlur,
                              touched,
                              errors,
                              isValid
                            }) => (
                              <Form>
                                <Grid
                                  container
                                  sx={{
                                    display: 'flex',
                                    flexFlow: 'column'
                                  }}>
                                  <TextField
                                    error={
                                      touched.externalId &&
                                      Boolean(errors.externalId)
                                    }
                                    helperText={
                                      touched.externalId && errors.externalId
                                    }
                                    label='External Plan ID'
                                    name='externalId'
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    sx={{
                                      backgroundColor: 'white'
                                    }}
                                    value={values.externalId}
                                  />
                                  <Grid item mt={2}>
                                    <Button
                                      onClick={() => setEdit(undefined)}
                                      sx={{ marginRight: 2 }}
                                      variant='text'>
                                      Cancel
                                    </Button>
                                    <Button
                                      disabled={putPsm.isLoading || !isValid}
                                      type='submit'
                                      variant='text'>
                                      Update
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Form>
                            )}
                          </Formik>
                        </TableCell>
                      </TableRow>
                    )}
                  </AccessControl>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.toggleOpen} variant='text'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
