import Card, { CardContent } from '@/components/card';
import {
  CashDeliveryDetails,
  DeliveryDetails,
  RolloverDeliveryDetails
} from '@/models';
import { ParticipantAccountDetails } from '@/models/ParticipantAccountsDTO.model';
import { WithdrawalTradeRequestDto } from '@/models/WithdrawalsDTO.model';
import { CardHeader, SxProps, Theme, useTheme } from '@mui/material';

import React, { useMemo } from 'react';

import CashDeliveryInfoAccordion from './CashDeliveryInfoAccordion.component';
import RolloverDeliveryInfoAccordion from './RolloverDeliveryInfoAccordion.component';

export interface WithdrawalDistributionsCardProps {
  deliveryDetails: DeliveryDetails;
  requestSubAccountIds?: number[];
  sx?: SxProps<Theme>;
  tradeRequests?: WithdrawalTradeRequestDto[];
  participantAccounts: ParticipantAccountDetails[];
}

const WithdrawalDistributionsCard: React.FunctionComponent<
  WithdrawalDistributionsCardProps
> = (props: WithdrawalDistributionsCardProps) => {
  const theme = useTheme();
  const { deliveryDetails, requestSubAccountIds, tradeRequests } = props;

  const withdrawalAccounts = useMemo(() => {
    const requestSubaccounts =
      tradeRequests && tradeRequests.length > 0
        ? tradeRequests[0].request?.subAccounts.map(i => +i.coreAccountId)
        : requestSubAccountIds;
    return (
      props.participantAccounts?.filter(acc => {
        return requestSubaccounts?.find(accId => +acc.id === accId);
      }) ?? []
    );
  }, [props.participantAccounts, requestSubAccountIds, tradeRequests]);

  const preTaxAccounts = useMemo(() => {
    return (
      withdrawalAccounts
        .filter(account => account.attributes.tax === 'PreTax')
        .map(
          account => `${account.attributes.sourceCode} (id: ${account.id})`
        ) ?? []
    );
  }, [withdrawalAccounts]);

  const rothAccounts = useMemo(() => {
    return (
      withdrawalAccounts
        .filter(account => account.attributes.tax === 'Roth')
        .map(
          account => `${account.attributes.sourceCode} (id: ${account.id})`
        ) ?? []
    );
  }, [withdrawalAccounts]);

  const afterTaxAccount = useMemo(() => {
    return (
      withdrawalAccounts
        .filter(account => account.attributes.tax === 'AfterTax')
        .map(
          account => `${account.attributes.sourceCode} (id: ${account.id})`
        ) ?? []
    );
  }, [withdrawalAccounts]);

  return (
    <Card>
      <CardHeader
        sx={{ borderBottom: `1px solid ${theme.palette.grey[300]}` }}
        title={`Distributions (${deliveryDetails.length})`}></CardHeader>
      <CardContent sx={{ backgroundColor: '#FAFAFA' }}>
        {deliveryDetails.map((delivery, index) => (
          <React.Fragment key={`Distribution-${index}`}>
            {delivery.deliveryType === 'Cash' && (
              <>
                {delivery.taxType === 'afterTax' && (
                  <CashDeliveryInfoAccordion
                    delivery={delivery as CashDeliveryDetails}
                    fundingSources={afterTaxAccount}
                    taxType='After-Tax'
                  />
                )}
                {delivery.taxType === 'preTax' && (
                  <CashDeliveryInfoAccordion
                    delivery={delivery as CashDeliveryDetails}
                    fundingSources={preTaxAccounts}
                    taxType='Pre-Tax'
                  />
                )}
                {delivery.isRoth && (
                  <CashDeliveryInfoAccordion
                    delivery={delivery as CashDeliveryDetails}
                    fundingSources={rothAccounts}
                    taxType='Roth'
                  />
                )}
              </>
            )}
            {delivery.deliveryType === 'Rollover' && (
              <>
                {!delivery.isRoth && (
                  <RolloverDeliveryInfoAccordion
                    delivery={delivery as RolloverDeliveryDetails}
                    fundingSources={preTaxAccounts}
                  />
                )}
                {delivery.isRoth && (
                  <RolloverDeliveryInfoAccordion
                    delivery={delivery as RolloverDeliveryDetails}
                    fundingSources={rothAccounts}
                    isRoth
                  />
                )}
              </>
            )}
          </React.Fragment>
        ))}
      </CardContent>
    </Card>
  );
};

export default WithdrawalDistributionsCard;
