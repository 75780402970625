import Constants from '@/consts/planDesign.constants';
import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';
import HardshipWithdrawals from '@/routes/plans/plan-detail/PlanTab/plan-design-templates/properties/distribution-features/HardshipWithdrawals';

class DefaultHardshipWithdrawals extends HardshipWithdrawals {
  convertToApiState = Helper.convertValueToApiState;

  output: string | undefined;

  constructor(allowHardshipWithdrawals: string | undefined) {
    super(Constants.hardshipWithdrawalsOptions);
    this.output = allowHardshipWithdrawals;
  }
}

export default DefaultHardshipWithdrawals;
