import { Correction, FileRow } from '../types';

export const generateFileRows = (corrections: Correction[]): FileRow[] =>
  corrections?.map(row => ({
    at: row.at || 0,
    at_original: row.at_original || 0,
    em: row.em || 0,
    em_original: row.em_original || 0,
    first_name: row.first_name,
    last_name: row.last_name,
    ln: row.ln || 0,
    ln_original: row.ln_original || 0,
    ps: row.ps || 0,
    ps_original: row.ps_original || 0,
    qc: row.qc || 0,
    qc_original: row.qc_original || 0,
    qm: row.qm || 0,
    qm_original: row.qm_original || 0,
    rc: row.rc || 0,
    rc_original: row.rc_original || 0,
    sd: row.sd || 0,
    sd_original: row.sd_original || 0,
    sh: row.sh || 0,
    sh_original: row.sh_original || 0,
    ssn: row.ssn
  })) ?? [];
