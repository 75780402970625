import Constants from '@/consts/planDesign.constants';
import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class SafeHarborNonElective {
  isHidden: boolean | undefined;

  convertToApiState = (
    editPlanDetails: Record<string, any>
  ): boolean | undefined => {
    return editPlanDetails.allowSafeHarborNonElective != null &&
      editPlanDetails.allowSafeHarborNonElective !== ''
      ? Helper.convertFromAllowDontAllowToBoolean(
          editPlanDetails.allowSafeHarborNonElective
        )
      : editPlanDetails.safeHarborMatchType
        ? editPlanDetails.safeHarborMatchType.includes('Non-Elective')
          ? true
          : editPlanDetails.safeHarborMatchType.includes('Match')
            ? false
            : editPlanDetails.safeHarborMatchType === 'N/A - No Safe Harbor'
              ? false
              : undefined
        : undefined;
  };

  options = Constants.allowDontAllowOptions;

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.isHidden = props.recordkeeperId !== Constants.voyaRecordkeeperId;

    this.output = this.isHidden
      ? undefined
      : Helper.convertFromBooleanToAllowDontAllow(
          props.allowSafeHarborNonElective
        );
  }
}

export default SafeHarborNonElective;
