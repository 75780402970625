import formatters from '@/utils/Formatters';
import { Link, TableCell, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { GridColDef } from '@mui/x-data-grid-pro';

import clsx from 'clsx';
import React, { FC, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

type PlanContributionReversalsTableCellProps = {
  row: any;
  column: GridColDef;
};

const useStyles = makeStyles((theme: Theme) => ({
  border: {
    borderLeft: `1px solid ${theme.palette.grey[300]}`
  },
  size: {
    fontSize: theme.spacing(2)
  },
  textRight: {
    textAlign: 'right'
  },
  total: {
    color: '#0B79D0'
  }
}));

const PlanContributionReversalsTableCell: FC<
  PlanContributionReversalsTableCellProps
> = props => {
  const { row, column } = props;

  const classes = useStyles();

  const value = useMemo(() => {
    const fieldValue = row?.[column.field] ?? '--';

    return typeof fieldValue === 'number'
      ? formatters.formatDollars(row?.[column.field])
      : fieldValue;
  }, [row, column]);

  return (
    <TableCell
      className={clsx(
        classes.size,
        ['sd', 'sh', 'ln'].includes(column.field) ? classes.border : ''
      )}
      component='th'
      scope='row'>
      {column.field === 'name' ? (
        <Link
          component={RouterLink}
          target='_blank'
          to={`/participants/${row.id}`}
          underline='hover'>
          {row[column.field]}
        </Link>
      ) : (
        <Typography
          className={clsx(
            classes.textRight,
            column.field === 'contributionTotal' && classes.total
          )}>
          {value}
        </Typography>
      )}
    </TableCell>
  );
};

export default PlanContributionReversalsTableCell;
