import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class Notes {
  convertToApiState = Helper.convertValueToApiState;

  isHidden: boolean | undefined;

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.isHidden = !!props.isTpaUser;
    this.output = this.isHidden ? undefined : props.notes;
  }
}

export default Notes;
