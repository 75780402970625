import Constants from '@/consts/planDesign.constants';
import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class InServiceAtEarlyAge {
  convertToApiState = Helper.convertFromYesNoToBoolean;

  disabled: boolean;

  options = Constants.yesNoOptions;

  output: string | undefined;

  setDisabled = (value: boolean): void => {
    this.disabled = value;
  };

  constructor(props: Record<string, any>) {
    this.disabled = !['Age Only', 'Later of Age and Service'].includes(
      props.earlyRetirementAgeType
    );

    this.output = Helper.convertFromBooleanToYesNo(
      props.allowInServiceAtEarlyAge
    );
  }
}

export default InServiceAtEarlyAge;
