import Badge from '@/components/badge';
import { CellComponentProps } from '@/components/collapsible-table';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { UpdateBillingPeriodDto } from '@/models/ops/fees/BillingDTO.model';
import { PlanService } from '@/services/Plan.service';
import formatters from '@/utils/Formatters';
import { DeleteOutlined as DeleteOutlinedIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Stack,
  TableCell,
  Typography
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import React from 'react';
import { Link } from 'react-router-dom';

interface CellProps {
  billingPeriodId: number;
  endDate: string;
  purpose: string;
  startDate: string;
  submittedDate: string;
}

export const colorByBillingStatus: Record<
  string,
  | 'success'
  | 'neutral'
  | 'warning'
  | 'info'
  | 'lightWarning'
  | 'error'
  | undefined
> = {
  Complete: 'success',
  Created: 'lightWarning',
  Done: 'success',
  Failed: 'error',
  'Not yet submitted': 'lightWarning',
  'Processed with Errors': 'warning',
  Processing: 'neutral',
  Submitted: 'info'
};

export const PeriodCell: React.FC<CellProps> = (props: CellProps) => {
  return (
    <Box minWidth={200}>
      <Link
        state={{ submittedDate: props.submittedDate }}
        to={`/ops/fee-management/${props.billingPeriodId}`}>
        <Typography color={blue[500]} fontWeight={700}>
          {props.startDate
            ? `${props.startDate} - ${props.endDate}`
            : props.endDate}
        </Typography>
      </Link>

      <Typography variant='body2'>
        ID: {props.billingPeriodId} | {props.purpose}
      </Typography>
    </Box>
  );
};

const BillingTableCell: React.FunctionComponent<CellComponentProps> = (
  props: CellComponentProps
) => {
  const { row, column } = props;
  let field = row[column.field];
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();

  const updateBillingPeriod = useMutation(
    ['PlanService.updateBillingPeriod'],
    (dto: UpdateBillingPeriodDto) => {
      return PlanService.updateBillingPeriod(row.id, dto);
    },
    {
      onError: () => {
        snackbar.showSnackbar({
          message: 'Failed to update a billing period request!',
          severity: 'error'
        });
      },
      onSuccess: () => {
        snackbar.showSnackbar({
          message: 'Successfully updated a billing period!',
          severity: 'success'
        });

        queryClient.invalidateQueries();
      }
    }
  );

  if (column.field === 'period') {
    field = (
      <PeriodCell
        billingPeriodId={row.id}
        endDate={formatters.formatFromIsoDateCustom(
          row.periodEndDate,
          'MM/DD/YYYY'
        )}
        purpose={row.purpose}
        startDate={
          row.periodStartDate &&
          formatters.formatFromIsoDateCustom(row.periodStartDate, 'MM/DD/YYYY')
        }
        submittedDate={
          row.submittedDate &&
          formatters.formatFromIsoDateCustom(row.submittedDate, 'MM/DD/YYYY')
        }
      />
    );
  }

  if (column.field === 'status') {
    field = row.status && (
      <Badge color={colorByBillingStatus[row[column.field]]}>
        {row.status}
      </Badge>
    );
  }

  if (
    column.field === 'requestedAmount' ||
    column.field === 'collectedAmount'
  ) {
    field = (
      <Box minWidth={150} textAlign='right'>
        {formatters.formatDollars(row[column.field])}
      </Box>
    );
  }

  if (column.field === 'submittedDate') {
    field = (
      <Box minWidth={150}>
        {row.submittedDate
          ? formatters.formatFromIsoDateCustom(row.submittedDate, 'MM/DD/YYYY')
          : EMPTY_FIELD_PLACEHOLDER}
      </Box>
    );
  }

  if (column.field === 'action') {
    field = (
      <Stack direction='row' minWidth={150} spacing={2}>
        <Button onClick={() => updateBillingPeriod.mutate({ isDone: true })}>
          Done
        </Button>
        {row.status === 'Not yet submitted' && (
          <IconButton
            data-testid='delete-image-button'
            disabled={updateBillingPeriod.isLoading}
            onClick={() => updateBillingPeriod.mutate({ isArchived: true })}
            size='small'>
            <DeleteOutlinedIcon color='error' />
          </IconButton>
        )}
      </Stack>
    );
  }

  return (
    <TableCell component='th' scope='row'>
      <Box>{!field ? EMPTY_FIELD_PLACEHOLDER : field}</Box>
    </TableCell>
  );
};

export default BillingTableCell;
