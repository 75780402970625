import Constants from '@/consts/planDesign.constants';
import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class InServiceAtSpecifiedAgeFromRothAccount {
  convertToApiState = Helper.convertFromYesNoToBoolean;

  disabled: boolean;

  options = Constants.yesNoOptions;

  output: string | undefined;

  setDisabled = (value: boolean): void => {
    this.disabled = value;
  };

  constructor(props: Record<string, any>) {
    this.disabled =
      !['Yes - All Accounts', 'Yes - Fully Vested Accounts Only'].includes(
        props.allowInServiceAtSpecifiedAge
      ) || !props.isSalaryRothDeferral;

    this.output = Helper.convertFromBooleanToYesNo(
      props.allowSpecifiedAgeFromRothAccount
    );
  }
}

export default InServiceAtSpecifiedAgeFromRothAccount;
