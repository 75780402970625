import LinearLoading from '@/components/linear-loading';
import {
  AssetClass,
  GoalSeriesFund,
  ManagedAccountSeries
} from '@/routes/ops/investments/ManagedAccount.component';
import InvestmentService from '@/services/Investment.service';
import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import React from 'react';

import ManagedAccountTable from './ManagedAccountTable.component';

interface ManagedAccountTabProps {
  goalSeriesId: number;
}

const ManagedAccountTab: React.FC<ManagedAccountTabProps> = ({
  goalSeriesId
}) => {
  const seriesQuery = useQuery<[ManagedAccountSeries, AssetClass[]]>(
    ['ManagedAccountEdit.seriesQuery', 'managed-account-series', goalSeriesId],
    async (): Promise<[ManagedAccountSeries, AssetClass[]]> => {
      const managedAccountSeries =
        await InvestmentService.getGoalSeries(goalSeriesId);
      const assetClasses = await InvestmentService.getGoalSeriesAssetClasses(
        managedAccountSeries.goeAccountId
      );
      return [managedAccountSeries, assetClasses];
    },
    {
      staleTime: Infinity
    }
  );
  const managedAccountFundsQuery = useQuery<GoalSeriesFund[]>(
    [
      'ManagedAccountEdit.managedAccountFundsQuery',
      'managed-account-funds',
      goalSeriesId
    ],
    () => InvestmentService.getGoalSeriesFunds(goalSeriesId),
    {
      staleTime: Infinity
    }
  );
  const isLoading =
    seriesQuery.isFetching || managedAccountFundsQuery.isFetching;

  if (isLoading) {
    return (
      <Box textAlign='center'>
        <LinearLoading />
      </Box>
    );
  }

  return (
    <ManagedAccountTable
      assetClasses={seriesQuery.data?.[1] as AssetClass[]}
      managedAccountData={seriesQuery.data?.[0] as ManagedAccountSeries}
      managedAccountFund={managedAccountFundsQuery.data as GoalSeriesFund[]}
    />
  );
};

export default ManagedAccountTab;
