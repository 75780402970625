import { CellComponentProps } from '@/components/collapsible-table';
import DocumentDownloadButton from '@/components/document-download-button';
import formatters from '@/utils/Formatters';
import { Box, TableCell, Typography } from '@mui/material';

import React, { FC } from 'react';

const DocumentsCell: FC<CellComponentProps> = props => {
  const { row, column } = props;

  let field = null;

  if (column.field === 'created_at') {
    field = (
      <Box>
        <Typography variant='body1'>
          {formatters.formatFromIsoDateCustom(
            row[column.field],
            'MM/DD/YY HH:mm'
          )}
        </Typography>
      </Box>
    );
  }

  if (column.field === 'original_file_name') {
    field = (
      <Box>
        <Typography variant='body1'>{row.original_file_name}</Typography>
      </Box>
    );
  }

  if (column.field === 'uploaded_by') {
    field = (
      <Box>
        <Typography variant='body1'>{row.initiated_by?.id}</Typography>
      </Box>
    );
  }

  if (column.field === 'download') {
    field = <DocumentDownloadButton doc={row} iconOnly />;
  }

  return (
    <TableCell component='th' scope='row'>
      {field}
    </TableCell>
  );
};

export default DocumentsCell;
