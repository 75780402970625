import SimpleUpload from '@/components/simple-upload';
import { DocumentKey } from '@/models/DocumentKey.model';
import { ActionCenterService } from '@/services/ActionCenter.service';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import {
  Autocomplete,
  Button,
  FormControl,
  TextField,
  Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { FC, useEffect, useState } from 'react';

import { ActionCenterDatePicker } from './ActionCenterDatePicker.component';

interface ActionCenterDetailsBulkUploadProps {
  setDetails: (details: Record<string, any>) => void;
  details: Record<string, any>;
}

const ActionCenterDetailsBulkUpload: FC<
  ActionCenterDetailsBulkUploadProps
> = props => {
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState(null);
  const { data: documentKeys } = useQuery(
    ['ActionCenterService.getDocumentKeys', 'plan'],
    () => ActionCenterService.getDocumentKeys('plan'),
    {
      staleTime: Infinity
    }
  );

  useEffect(() => {
    if (!props.details.documentKey) {
      setInputValue('');
      setValue(null);
    }
  }, [props.details]);

  return (
    <div data-testid='action-center-details-bulk-upload'>
      <FormControl fullWidth size='small'>
        <Autocomplete
          data-testid='details-document-key-select'
          getOptionLabel={(option: DocumentKey) => option.documentKey}
          inputValue={inputValue}
          onChange={(_e, newValue: DocumentKey) => {
            setValue(newValue);
            props.setDetails({
              ...props.details,
              documentKey: newValue?.documentKey,
              extensions: newValue?.config.extensions
            });
          }}
          onInputChange={(_event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={documentKeys || []}
          renderInput={params => <TextField {...params} label='Document Key' />}
          size='small'
          sx={{ marginBottom: '20px' }}
          value={value}
        />
        <ActionCenterDatePicker
          InputProps={{
            autoComplete: 'off'
          }}
          data-testid='details-effective-date-picker'
          label='Effective Date'
          name='effectiveDate'
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            props.setDetails({
              ...props.details,
              effectiveDate: event.target.value
            })
          }
          size='small'
          sx={{ marginY: '20px' }}
          value={props.details.effectiveDate}
          variant='outlined'
        />

        <SimpleUpload
          accept={{ 'application/zip': ['.zip'] }}
          data-testid='upload-zip-file'
          disabled={!props.details.documentKey}
          onSelect={files => {
            if (Array.isArray(files)) {
              props.setDetails({
                ...props.details,
                zipFile: files[0],
                zipName: files[0].name
              });
            }
          }}
          selectRawFiles
          style={{
            marginBottom: '15px'
          }}>
          <Button
            data-testid='details-file-upload-button'
            disabled={!props.details.documentKey}
            startIcon={<FileUploadOutlinedIcon />}
            variant='outlined'>
            Select .ZIP file
          </Button>
          <Typography
            sx={{
              color: theme => theme.palette.text.secondary,
              display: 'block',
              marginTop: '10px'
            }}
            variant='caption'>
            {props.details.zipName || 'No file chosen'}
          </Typography>
        </SimpleUpload>
      </FormControl>
    </div>
  );
};
export default ActionCenterDetailsBulkUpload;
