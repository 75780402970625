import Constants from '@/consts/planDesign.constants';
import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class EmployerProfitSharing {
  convertToApiState = Helper.convertFromAllowDontAllowToBoolean;

  options = Constants.allowDontAllowOptions;

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.output = Helper.convertFromBooleanToAllowDontAllow(
      props.allowEmployerProfitSharing
    );
  }
}

export default EmployerProfitSharing;
