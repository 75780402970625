import LinearLoading from '@/components/linear-loading';
import useShowMore from '@/hooks/useShowMore';
import {
  GlobalSearchDto,
  GlobalSearchParticipantDto
} from '@/models/GlobalSearchDTO.model';
import GlobalSearchService from '@/services/GlobalSearch.service';
import { userService } from '@/services/User.service';
import { createParticipantDynamicField } from '@/utils/GlobalSearchUtility';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import {
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Theme,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  GLOBAL_SEARCH_PARTICIPANTS_PLANS_TYPE,
  GLOBAL_SEARCH_RESULT_PAGE_SIZE
} from './constants';

interface GlobalSearchParticipantsResultProps {
  setSearchTerm: (search: string) => void;
  searchTerm: string;
  isFocused: boolean;
  abortSignal?: AbortSignal;
}

const useStyles = makeStyles((theme: Theme) => ({
  globalSearchEmployeeIdField: {
    color: theme.palette.text.secondary,
    fontSize: theme.spacing(1.2),
    paddingTop: theme.spacing(1.5),
    textAlign: 'right',
    width: '30%'
  },
  globalSearchParticipantContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  globalSearchParticipantField: {
    color: theme.palette.text.secondary,
    fontSize: theme.spacing(1.5),
    minHeight: theme.spacing(1.5)
  },
  globalSearchParticipantImg: {
    color: theme.palette.success.dark,
    paddingBottom: theme.spacing(2.5)
  },
  globalSearchParticipantItem: {
    '&:hover': {
      backgroundColor: grey[200]
    },
    cursor: 'pointer'
  },
  globalSearchParticipantsHeader: {
    fontSize: theme.spacing(1.5),
    letterSpacing: '0.4px',
    marginLeft: theme.spacing(1.5),
    marginTop: theme.spacing(2)
  },
  globalSearchShowMoreBtn: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5)
  },
  noResults: {
    paddingLeft: theme.spacing(2)
  }
}));

const GlobalSearchParticipantsResult = (
  props: GlobalSearchParticipantsResultProps | any
): JSX.Element => {
  const classes = useStyles();

  const { setSearchTerm, searchTerm, abortSignal } = props;

  const navigate = useNavigate();

  const [isVestwellUser, setIsVestwellUser] = useState(false);

  const redirectToParticipantPage = (id: number) => {
    setSearchTerm('');
    navigate(`/participants/${id}`);
  };

  const globalSearchParticipantQuery = useShowMore<
    GlobalSearchDto,
    GlobalSearchParticipantDto
  >(
    ['globalSearchParticipantResultQuery', searchTerm],
    page =>
      GlobalSearchService.getGlobalSearchResult(
        searchTerm,
        page,
        GLOBAL_SEARCH_RESULT_PAGE_SIZE,
        GLOBAL_SEARCH_PARTICIPANTS_PLANS_TYPE,
        abortSignal
      ),
    Boolean(searchTerm),
    data => data.data.participants.data,
    data => Boolean(data?.data.participants.links.next),
    'participantId'
  );

  useEffect(() => {
    if (!userService.getTokenData()?.tpaId) {
      setIsVestwellUser(true);
    }
  }, []);

  let loader;

  if (globalSearchParticipantQuery.isLoading || searchTerm === '') {
    loader = <LinearLoading />;
  } else {
    loader = null;
  }

  if (globalSearchParticipantQuery.isError) {
    return <p>Error</p>;
  }

  return (
    <>
      <Typography
        className={classes.globalSearchParticipantsHeader}
        id='global-search-participants-header'>
        {isVestwellUser ? 'PARTICIPANTS' : 'EMPLOYEES'}
      </Typography>
      {loader || (
        <div>
          {!globalSearchParticipantQuery.isLoading &&
          !!globalSearchParticipantQuery.data?.length ? (
            <List
              data-testid='global-search-participants'
              id='global-search-participants'>
              {globalSearchParticipantQuery.data.map(
                (participant: GlobalSearchParticipantDto) => {
                  return (
                    <ListItem
                      className={classes.globalSearchParticipantItem}
                      id='global-search-participants-item'
                      key={participant.participantId}
                      onClick={() =>
                        redirectToParticipantPage(participant.participantId)
                      }>
                      <ListItemAvatar
                        className={classes.globalSearchParticipantImg}>
                        <PersonOutlineIcon id='global-search-participants-image' />
                      </ListItemAvatar>
                      <div
                        className={classes.globalSearchParticipantContainer}
                        id='global-search-participants-container'>
                        <div id='global-search-participants-wrapper'>
                          <ListItemText
                            id='global-search-participants-name'
                            primary={`${participant.firstName} ${
                              participant.middleName || ''
                            } ${participant.lastName}`}
                          />
                          <Typography
                            className={classes.globalSearchParticipantField}
                            id='global-search-participants-plan-name'>
                            Plan: {participant.planName || '--'}
                          </Typography>
                          <Typography
                            className={classes.globalSearchParticipantField}
                            id='global-search-participants-personal-email'>
                            {createParticipantDynamicField(
                              searchTerm,
                              participant
                            ).key !== ''
                              ? `${
                                  createParticipantDynamicField(
                                    searchTerm,
                                    participant
                                  ).key
                                }: ${
                                  createParticipantDynamicField(
                                    searchTerm,
                                    participant
                                  ).value
                                }`
                              : ''}
                          </Typography>
                        </div>
                        <Typography
                          className={classes.globalSearchEmployeeIdField}
                          id='global-search-participants-id'>
                          {participant.externalId ? 'EMPLOYEE' : 'PARTICIPANT'}{' '}
                          ID: {participant.participantId}
                        </Typography>
                      </div>
                    </ListItem>
                  );
                }
              )}
            </List>
          ) : (
            <p className={classes.noResults}>No results</p>
          )}
          {!globalSearchParticipantQuery.isLoading &&
            !!globalSearchParticipantQuery.data?.length &&
            globalSearchParticipantQuery.isPaginated && (
              <Button
                className={classes.globalSearchShowMoreBtn}
                disabled={globalSearchParticipantQuery.isPaginationLoading}
                id='global-search-participants-show-more-btn'
                onClick={globalSearchParticipantQuery.showMore}>
                SHOW MORE
              </Button>
            )}
        </div>
      )}
    </>
  );
};

export default GlobalSearchParticipantsResult;
