import {
  ServiceNoteDto,
  ServiceNoteSourceDto
} from '@/models/ServiceNotesDTO.model';
import ParticipantService from '@/services/Participant.service';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const useNotesQuery = (participantId: string, source: string) => {
  const queryClient = useQueryClient();

  const noteSourceQuery = useQuery<ServiceNoteSourceDto>(
    ['ParticipantService.getServiceNoteSources', participantId],
    () => ParticipantService.getServiceNoteSources(participantId),
    {
      enabled: !!participantId
    }
  );

  const sourceId = noteSourceQuery.data?.data.find(
    sourceEntry => sourceEntry.source === source
  )?.serviceNoteSourceId;

  const notesQuery = useQuery<ServiceNoteDto>(
    ['ParticipantService.getServiceNote', participantId, sourceId],
    () => ParticipantService.getServiceNote(participantId, `${sourceId}`),
    {
      enabled: !!participantId && !!sourceId
    }
  );

  const notesMutation = useMutation(
    (notes: {
      id?: number;
      participantId: string;
      notes: string;
      serviceNoteSourceId: number;
    }) => {
      notes.serviceNoteSourceId = notes.serviceNoteSourceId || sourceId;

      if (notes.id) {
        return ParticipantService.updateServiceNote(
          notes.id,
          notes.participantId,
          notes.notes,
          notes.serviceNoteSourceId
        );
      } else {
        return ParticipantService.createServiceNote(
          notes.participantId,
          notes.notes,
          notes.serviceNoteSourceId
        );
      }
    },
    {
      onSuccess: async () => {
        await queryClient.refetchQueries([
          'ParticipantService.getServiceNote',
          participantId,
          sourceId
        ]);
      }
    }
  );

  return { notesMutation, notesQuery, sourceId };
};
