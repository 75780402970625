import { useSnackbar } from '@/contexts/SnackBarContext';
import ContributionService from '@/services/Contribution.service';
import { VisibilityOffOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import type { FC } from 'react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';

import { FLOW_SUBTYPES } from './consts';

type HideContributionProps = {
  ucid: string;
  sponsorPlanId: number;
  flowSubtype?: string;
  onSuccess?: () => void;
};

export const HideContribution: FC<HideContributionProps> = props => {
  const [isOpen, toggleIsOpen] = useToggle(false);
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const hideContribution = useMutation(
    () =>
      ContributionService.postContributionAction(
        { ucid: props.ucid },
        { actionCode: 'CANCEL' }
      ),
    {
      onError: (err: any) => {
        snackbar.showSnackbar({
          message: err.data?.message ?? 'Failed to hide contribution!',
          severity: 'error'
        });
      },
      onSuccess: () => {
        if (props.onSuccess) {
          return props.onSuccess();
        }

        snackbar.showSnackbar({
          message: 'Contribution successfully hidden!',
          severity: 'success'
        });

        navigate(`/plans/${props.sponsorPlanId}/contributions`, {
          replace: true
        });
      }
    }
  );

  const onSubmit = useCallback(() => hideContribution.mutateAsync(), []);

  return (
    <>
      <LoadingButton
        data-testid='hideContributionButton'
        loading={hideContribution.isLoading}
        onClick={toggleIsOpen}
        startIcon={<VisibilityOffOutlined />}
        variant='outlined'>
        Hide
      </LoadingButton>
      <Dialog
        data-testid='hideContribution-dialog'
        fullWidth
        maxWidth='xs'
        onClose={toggleIsOpen}
        open={isOpen}>
        <DialogTitle data-testid='hideContribution-dialog-title'>
          Hide {FLOW_SUBTYPES[props.flowSubtype] ?? ''} Contribution
        </DialogTitle>
        <DialogContent data-testid='hideContribution-dialog-content'>
          <Typography>
            Are you sure you want to hide the contribution?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Grid display='flex'>
            <Grid mr={2}>
              <Button
                data-testid='hideContribution-dialog-closeButton'
                onClick={toggleIsOpen}>
                Close
              </Button>
            </Grid>
            <Grid>
              <LoadingButton
                color='error'
                data-testid='hideContribution-dialog-submitButton'
                loading={hideContribution.isLoading}
                onClick={onSubmit}
                variant='contained'>
                Confirm
              </LoadingButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};
