import AccessControl from '@/components/access-control/AccessControl.component';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import { UserManagementReports } from '@/routes/user-management/user-management-reports/UserManagementReports.component';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import React from 'react';

import { UserManagementEmail } from './user-management-email/UserManagementEmail.component';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(1)
  }
}));

const UserManagement: React.FunctionComponent = (): JSX.Element => {
  const classes = useStyles();

  return (
    <AccessControl
      requiresOneOf={[
        FeatureLevelPermissions.READ_USER_MANAGEMENT,
        FeatureLevelPermissions.READ_USER_MANAGEMENT_VSS_STATE_SAVER
      ]}>
      <Grid
        className={classes.root}
        container
        data-testid='user-management-container'
        direction='column'
        spacing={2}>
        <Grid item>
          <Typography variant='h4'>User Management</Typography>
        </Grid>
        <Grid item>
          <UserManagementReports />
        </Grid>
      </Grid>
      <UserManagementEmail />
    </AccessControl>
  );
};

export default UserManagement;
