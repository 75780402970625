import { Order } from '@/components/collapsible-table';
import { FundLineupSearchData } from '@/routes/ops/investments/fund-lineup/InvestmentFundLineup.component';
import ApiService from '@/services/Api.service';

type FundLineupListDto = {
  data: FundLineupSearchData[];
  meta: { count: number };
};

class FundLineupService {
  static async getFundLineupPage(
    pageNumber: number,
    pageSize: number,
    orderBy: string,
    order: Order,
    name?: string,
    id?: number
  ): Promise<FundLineupListDto> {
    const sort = (order === 'asc' ? '' : '-').concat(orderBy);
    return ApiService.getJson('/investments/fund-lineup', {
      id,
      name,
      pageNumber,
      pageSize,
      sort
    }) as unknown as FundLineupListDto;
  }
}

export type { FundLineupListDto };
export { FundLineupService };
