import { PriorProvidersDto } from '@/models';
import ApiService from '@/services/Api.service';

interface PriorProvider {
  priorProviderId: number;
  priorProviderName: string;
  isVerified: boolean;
}

class PriorProviderService {
  static async getPriorProviders(): Promise<PriorProvidersDto> {
    return ApiService.getJson('/prior-providers');
  }

  static async postPriorProvider(
    priorProviderName: string
  ): Promise<{ data: PriorProvider }> {
    return ApiService.postJson('/prior-providers', { priorProviderName });
  }
}

export type { PriorProvider };
export { PriorProviderService };
