export type SurpasLookupFormValues = {
  category: 'employer' | 'employee' | 'other';
  acctNo?: string;
  brnId?: string;
  dlrId?: string;
  custId?: string;
  custType?: string;
  employerAccount?: string;
  externalFirmId?: string;
  getECommUser: boolean;
  method?: SurpasMethods;
  programCode?: string;
  participantId?: string;
  repId?: string;
};

export type LookupOtherDataParams = {
  method: SurpasMethods;
  employerAccount?: string;
  custType?: string;
  custId?: string;
  acctNo?: string;
  externalFirmId?: string;
  repId?: string;
  brnId?: string;
  dlrId?: string;
};

export enum SurpasMethods {
  GetAcct = 'GetAcct',
  GetAcctMemo = 'GetAcctMemo',
  GetAlloc = 'GetAlloc',
  GetCustInfo = 'GetCustInfo',
  GetDupAddr = 'GetDupAddr',
  GetLed = 'GetLed',
  GetPayInstr = 'GetPayInstr',
  GetPMFees = 'GetPMFees',
  GetRetPlanDet = 'GetRetPlanDet',
  GetSecAddr = 'GetSecAddr',
  GetRep = 'GetRep',
  GetSuitability = 'GetSuitability',
  GetSurpasStatus = 'GetSurpasStatus',
  SearchForPendingTransactions = 'SearchForPendingTransactions',
  ShellRep = 'ShellRep',
  GetEmployment = 'GetEmployment'
}

export const SurpasOtherMethodsConfig = {
  [SurpasMethods.GetAcct]: ['employerAccount'],
  [SurpasMethods.GetAcctMemo]: ['employerAccount'],
  [SurpasMethods.GetAlloc]: ['employerAccount'],
  [SurpasMethods.GetCustInfo]: ['custId', 'custType'],
  [SurpasMethods.GetDupAddr]: ['employerAccount'],
  [SurpasMethods.GetEmployment]: ['employerAccount'],
  [SurpasMethods.GetLed]: ['employerAccount'],
  [SurpasMethods.GetPMFees]: ['employerAccount'],
  [SurpasMethods.GetPayInstr]: ['employerAccount'],
  [SurpasMethods.GetRep]: ['externalFirmId', 'repId'],
  [SurpasMethods.GetRetPlanDet]: ['employerAccount'],
  [SurpasMethods.GetSecAddr]: ['acctNo'],
  [SurpasMethods.GetSuitability]: ['employerAccount'],
  [SurpasMethods.GetSurpasStatus]: [],
  [SurpasMethods.SearchForPendingTransactions]: ['employerAccount'],
  [SurpasMethods.ShellRep]: ['brnId', 'dlrId']
};
