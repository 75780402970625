import {
  Card,
  CardContent,
  Divider,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';

import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useRef } from 'react';

import { CustodianFund } from './CustodianFundsTabContent.componet';

const CustodianFundsDetailCell = (props: {
  gridRef: React.RefObject<AgGridReact<any>>;
  setRequestNoteCallback: (cusip: string, note?: string) => void;
  cellData: { data: CustodianFund };
}): JSX.Element => {
  const { gridRef, setRequestNoteCallback, cellData } = props;

  const resultsDivRef = useRef<any>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        resultsDivRef.current &&
        !resultsDivRef.current.contains(event.target)
      ) {
        gridRef.current?.api
          ?.getRowNode(cellData.data.cusip)
          ?.setExpanded(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [gridRef, resultsDivRef, cellData.data.cusip]);

  const isNew = cellData.data.status === 'New';

  return (
    <div ref={resultsDivRef}>
      {isNew && (
        <Card elevation={0} sx={{ padding: theme => theme.spacing(2) }}>
          <CardContent
            sx={{
              '&:last-child': {
                paddingBottom: '0px'
              },
              padding: 0
            }}>
            <TextField
              autoFocus
              label='Request Note'
              multiline
              onChange={e =>
                setRequestNoteCallback(cellData.data.cusip, e.target.value)
              }
              onFocus={e =>
                e.currentTarget.setSelectionRange(
                  e.currentTarget.value.length,
                  e.currentTarget.value.length
                )
              }
              rows={4}
              sx={{ width: '40%' }}
              value={cellData.data?.requestNote}
              variant='outlined'
            />
          </CardContent>
        </Card>
      )}
      {!isNew && (
        <Stack
          direction='row'
          divider={<Divider flexItem orientation='vertical' />}
          justifyContent='flex-start'
          spacing={2}
          sx={{ padding: theme => theme.spacing(2), width: '90%' }}>
          <Card elevation={0} sx={{ width: '100%' }}>
            <CardContent sx={{ padding: 0 }}>
              <Typography sx={{ color: grey[700] }} variant='body2'>
                Request Note
              </Typography>
              <Typography
                sx={{
                  marginTop: theme => theme.spacing(1),
                  whiteSpace: 'normal'
                }}
                variant='body1'>
                {cellData.data?.requestNote}
              </Typography>
              {!cellData.data?.requestNote && (
                <Typography
                  align='center'
                  sx={{ color: grey[700] }}
                  variant='body2'>
                  No request note
                </Typography>
              )}
            </CardContent>
          </Card>
          <Card elevation={0} sx={{ width: '100%' }}>
            <CardContent sx={{ padding: 0 }}>
              <Typography sx={{ color: grey[700] }} variant='body2'>
                Response Note
              </Typography>
              <Typography
                sx={{
                  marginTop: theme => theme.spacing(1),
                  whiteSpace: 'normal'
                }}
                variant='body1'>
                {cellData.data?.responseNote}
              </Typography>
              {!cellData.data?.responseNote && (
                <Typography
                  align='center'
                  sx={{ color: grey[700] }}
                  variant='body2'>
                  No response note
                </Typography>
              )}
            </CardContent>
          </Card>
        </Stack>
      )}
    </div>
  );
};

const CustodianFundsDetailCellRenderer =
  (
    gridRef: React.RefObject<AgGridReact<any>>,
    setRequestNoteCallback: (cusip: string, note?: string) => void
  ) =>
  (cellData: { data: CustodianFund }): JSX.Element => {
    return (
      <CustodianFundsDetailCell
        cellData={cellData}
        gridRef={gridRef}
        setRequestNoteCallback={setRequestNoteCallback}
      />
    );
  };

export default CustodianFundsDetailCellRenderer;
