import { useDialog } from '@/contexts/DialogContext';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { MergeParticipantsDto } from '@/models/MergeParticipantsDto.model';
import ParticipantService from '@/services/Participant.service';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Unstable_Grid2 as Grid,
  Stack,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import React, { FC } from 'react';

import { DryRunDialog } from '../RemoveEmployeeDialog.component';
import { EmployeeInfoCard } from './EmployeeInfoCard.component';
import { EmpoyeeMatchesRow } from './EmployeeMatchesTableCell.component';

type MergeParticipantDialogProps = {
  data: EmpoyeeMatchesRow;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const MergeParticipantDialog: FC<
  MergeParticipantDialogProps
> = props => {
  const dialog = useDialog();
  const { showSnackbar } = useSnackbar();
  const [openRemoveDialog, setOpenRemoveDialog] = React.useState(false);
  const queryClient = useQueryClient();
  const removeParticipantFromPlanDryRun = useMutation(
    [
      'ParticipantService.removeParticipantFromPlan',
      props.data.id,
      props.data.planId,
      true
    ],
    () => {
      return ParticipantService.removeParticipantFromPlan(
        props.data.id,
        props.data.planId,
        true
      );
    },
    {
      onSuccess: () => {
        setOpenRemoveDialog(true);
      }
    }
  );

  const mergeParticipantsMutation = useMutation(
    (data: MergeParticipantsDto) => ParticipantService.mergeParticipants(data),
    {
      onError: (error: { data: { errors: { details: string }[] } }) => {
        showSnackbar({
          message:
            error.data?.errors[0]?.details ||
            'Failed! Could not merge participants',
          severity: 'error'
        });
      },
      onSuccess: async () => {
        props.setOpen(true);
        await removeParticipantFromPlanDryRun.mutateAsync();
      }
    }
  );

  return (
    <div data-testid='merge-participant-dialog'>
      <DryRunDialog
        errors={{
          mergeParticipantErrors: mergeParticipantsMutation.data?.errors,
          removeParticipantErrors: removeParticipantFromPlanDryRun.data?.errors
        }}
        handleClose={() => {
          setOpenRemoveDialog(false);
          props.setOpen(false);
          dialog.closeDialog();
          queryClient.invalidateQueries([
            'PlanService.getParticipantDuplicates',
            props.data.originalParticipant.sponsorPlanId,
            props.data.originalParticipant.id
          ]);
        }}
        open={openRemoveDialog}
        row={{
          ...props.data.originalParticipant,
          id: props.data.id,
          name: `${props.data.lastName}, ${props.data.firstName}`,
          ssn: `-${props.data.ssn.slice(-4)}`
        }}
      />
      <Dialog
        fullWidth
        maxWidth='md'
        onClose={() => {
          if (!removeParticipantFromPlanDryRun.isLoading) {
            props.setOpen(false);
          }
        }}
        open={props.open}>
        <DialogTitle>Merge employee accounts</DialogTitle>

        <DialogContent>
          <Grid container flexDirection='column' rowGap={25}>
            <Grid container direction='column' rowGap={3}>
              <Alert
                data-testid='preview-warning'
                icon={false}
                severity='warning'>
                <Stack
                  alignItems='center'
                  direction='row'
                  justifyContent='center'
                  spacing={1}>
                  <WarningAmberIcon
                    fontSize='small'
                    sx={{ color: theme => theme.palette.warning.dark }}
                  />

                  <ul>
                    <li>
                      Merging account balances may take up to one day to reflect
                      in the system
                    </li>
                    <li>
                      You will be prompted to remove the old employee after
                      merging account data
                    </li>
                  </ul>
                </Stack>
              </Alert>
              <Grid>
                <Typography>Merge the following accounts?</Typography>
              </Grid>

              <Grid
                alignItems='center'
                columnGap={2}
                container
                flexDirection='row'
                flexWrap='nowrap'
                justifyContent='space-between'>
                <EmployeeInfoCard
                  createdAt={props.data.createdAt}
                  dob={props.data.dob}
                  firstName={props.data.firstName}
                  id={props.data.id}
                  key={props.data.id}
                  lastName={props.data.lastName}
                  ssn={props.data.ssn}
                />
                <Grid alignSelf='center'>
                  <ArrowForwardIcon sx={{ color: grey[600] }} />
                </Grid>
                <EmployeeInfoCard
                  createdAt={props.data.originalParticipant.createdAt}
                  dob={props.data.originalParticipant.birthDate}
                  firstName={props.data.originalParticipant.name.split(',')[1]}
                  id={props.data.originalParticipant.id}
                  isOriginal
                  key={props.data.originalParticipant.id}
                  lastName={props.data.originalParticipant.name.split(',')[0]}
                  ssn={props.data.originalParticipant.ssn}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            data-testid='merge-participant-dialog-back-btn'
            onClick={() => {
              props.setOpen(false);
            }}>
            Back
          </Button>
          <LoadingButton
            data-testid='merge-participant-dialog-merge-btn'
            loading={
              mergeParticipantsMutation.isLoading ||
              removeParticipantFromPlanDryRun.isLoading
            }
            onClick={() =>
              mergeParticipantsMutation.mutateAsync({
                newParticipantId: props.data.originalParticipant.id,
                oldParticipantId: props.data.id,
                planId: props.data.planId
              })
            }
            variant='contained'>
            Merge Accounts
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

MergeParticipantDialog.displayName = 'MergeParticipantDialog';
