import CollapsibleTable from '@/components/collapsible-table';
import { ContributionCorrectionDto } from '@/models';
import { styled, Typography } from '@mui/material';

import React from 'react';

import CorrectionCollapsibleTable from './CorrectionCollapsibleTable.component';
import CorrectionTableCell from './CorrectionTableCell.component';

export interface ContributionCorrectionDtoWithUUID
  extends ContributionCorrectionDto {
  uuid?: string;
  isStateIRA?: boolean;
  id: string;
  fieldNames?: string[];
  hasMultipleFields?: boolean;
  totals: {
    initial: number;
    corrected: number;
  };
}

interface PlanCorrectionsDetailsTableProps {
  data: ContributionCorrectionDtoWithUUID[];
  testId?: string;
}

const StyledTypography = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  textAlign: 'center'
}));

const headers = [
  { field: 'employee', headerName: 'Employee' },
  { field: 'type', headerName: 'Type' },
  { field: 'field', headerName: 'Field(s)' },
  { field: 'oldValue', headerName: 'Old Value' },
  { field: 'newValue', headerName: 'New Value' },
  { field: 'time', headerName: 'Time' },
  { field: 'reason', headerName: 'Reason' },
  { field: 'reportedAmount', headerName: 'Reported Amount' },
  { field: 'processedAmount', headerName: 'Processed Amount' }
];

const PlanCorrectionsDetailsTable: React.FunctionComponent<
  PlanCorrectionsDetailsTableProps
> = (props: PlanCorrectionsDetailsTableProps) =>
  props.data.length ? (
    <CollapsibleTable
      cellComponent={CorrectionTableCell}
      collapsibleComponent={CorrectionCollapsibleTable}
      columns={headers}
      data-testid={props.testId}
      hideCollapsibleToggle={row => {
        return !row.hasMultipleFields;
      }}
      tableData={props.data || []}
    />
  ) : (
    <StyledTypography data-testid='no-corrections-data' variant='h6'>
      No contribution corrections
    </StyledTypography>
  );

export default PlanCorrectionsDetailsTable;
