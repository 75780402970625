import { Typography } from '@mui/material';

import React from 'react';

import { getStatusBtnColor } from '../helpers/utils';

interface RequestStatusProps {
  status: string;
}

const RequestStatus: React.FunctionComponent<RequestStatusProps> = ({
  status
}: RequestStatusProps) => {
  return (
    <Typography
      sx={{
        backgroundColor: getStatusBtnColor(status).bg,
        borderRadius: '6.25rem',
        color: getStatusBtnColor(status).color,
        fontSize: '0.875rem',
        height: 33,
        padding: '0.375rem 0.75rem',
        textAlign: 'center'
      }}
      variant='body2'>
      {status}
    </Typography>
  );
};

export default RequestStatus;
