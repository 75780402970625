import DownloadCSVButton from '@/components/download-csv-button/DownloadCSVButton.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import {
  ForceOutDistributionResponse,
  SubmitForceOutDistributionResponse
} from '@/models/WithdrawalsDTO.model';
import ParticipantService from '@/services/Participant.service';
import { userService } from '@/services/User.service';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { FC, useCallback, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from './reportComponents';

export const ForceOutDistributionTab: FC = () => {
  const [rowBulkData, setRowBulkData] = useState('');
  const [participantIds, setParticipantIds] = useState<Array<number>>([]);
  const [reason, setReason] = useState('');
  const [forceOutResults, setForceOutResults] =
    useState<SubmitForceOutDistributionResponse>();
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const userInfo = userService.getUser();

  const forceOutRequest = useQuery(
    ['ParticipantService.getForceOutRequest'],
    () => ParticipantService.getForceOutRequest(),
    {
      onSuccess: (dto: ForceOutDistributionResponse) => {
        setRowBulkData(dto?.data?.participantIds.join(',') || '');
        setParticipantIds(dto?.data?.participantIds);
        setReason(dto?.data?.reason);
      }
    }
  );

  const upsert = useMutation(
    (id?: number) =>
      ParticipantService.upsertForceOutDistribution(
        participantIds,
        reason ?? 'FORCE_OUT',
        forceOutRequest?.data?.data?.version,
        id
      ),
    {
      onError: () => {
        snackbar.showSnackbar({
          message: 'Something went wrong!',
          severity: 'error'
        });
      },
      onSuccess: () => {
        queryClient.refetchQueries(['ParticipantService.getForceOutRequest']);
        snackbar.showSnackbar({
          message: 'Success! The force-out distribution request was upserted',
          severity: 'success'
        });
      }
    }
  );

  const submit = useMutation(
    (id?: number) =>
      ParticipantService.submitForceOutDistribution(
        forceOutRequest?.data?.data?.version,
        id
      ),
    {
      onError: () => {
        snackbar.showSnackbar({
          message: 'Failed',
          severity: 'error'
        });
      },
      onSuccess: () => {
        queryClient.refetchQueries(['ParticipantService.getForceOutRequest']);
        snackbar.showSnackbar({
          message:
            'Success! The force-out distribution request was approved and submitted',
          severity: 'success'
        });
      }
    }
  );

  const handleTextField = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowBulkData(event.target.value);
      const splitList = event.target.value
        .split(/[\r?\n,]/)
        .filter(element => element)
        .map(i => +i);
      setParticipantIds(splitList);
    },
    []
  );

  const onClickApprove = useCallback(async () => {
    const res = await submit.mutateAsync(forceOutRequest?.data?.data?.id);
    setForceOutResults(res);
  }, [forceOutRequest?.data?.data?.id]);

  const onClickUpsert = useCallback(
    () => upsert.mutate(forceOutRequest?.data?.data?.id),
    [forceOutRequest?.data?.data?.id]
  );

  const onClickReset = useCallback(() => {
    setRowBulkData('');
    setParticipantIds(undefined);
    setForceOutResults(undefined);
    setReason(undefined);
  }, []);

  const getCsvData = useCallback(
    async () => forceOutResults?.data?.results,
    [forceOutResults?.data?.results]
  );

  return (
    <>
      <Typography variant='h4'>Force-Out Distribution</Typography>
      <Grid container mt={2} spacing={2}>
        <Grid
          alignItems='flex-start'
          container
          direction='column'
          item
          spacing={2}
          xs={2}>
          <Grid container item>
            <Typography variant='body1'>Participant IDs</Typography>
            <Typography variant='body2'>
              Comma or line-break delimited
            </Typography>
            <TextField
              minRows={8}
              multiline
              onChange={handleTextField}
              value={rowBulkData}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              componentsProps={{
                typography: {
                  variant: 'caption'
                }
              }}
              control={
                <Checkbox
                  checked={reason === 'FEE_OUT'}
                  onChange={event =>
                    setReason(event.target.checked ? 'FEE_OUT' : 'FORCE_OUT')
                  }
                  sx={{ py: 0 }}
                />
              }
              label='These are fee-outs; do not send disbursement emails to participants.'
            />
          </Grid>
          <Grid container item pr={3}>
            <Stack direction='row' spacing={2}>
              <LoadingButton
                disabled={!participantIds || participantIds?.length === 0}
                loading={upsert.isLoading || forceOutRequest.isFetching}
                onClick={onClickUpsert}
                variant='contained'>
                {forceOutRequest?.data?.data?.id
                  ? 'SAVE EDIT'
                  : 'SUBMIT FOR APPROVAL'}
              </LoadingButton>

              {forceOutRequest?.data?.data?.id && (
                <LoadingButton
                  disabled={
                    userInfo?.nickname ===
                      forceOutRequest?.data?.data?.createdBy?.id ||
                    participantIds?.toString() !=
                      forceOutRequest?.data?.data?.participantIds?.toString() ||
                    reason != forceOutRequest?.data?.data?.reason
                  }
                  loading={submit.isLoading || forceOutRequest.isFetching}
                  onClick={onClickApprove}
                  variant='contained'>
                  APPROVE
                </LoadingButton>
              )}
              {!forceOutRequest?.data?.data?.id && (
                <Button
                  disabled={upsert.isLoading}
                  onClick={onClickReset}
                  sx={{ marginLeft: theme => theme.spacing(2) }}
                  variant='text'>
                  RESET
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>
        <Divider flexItem orientation='vertical'></Divider>
        <Grid item xs={8}>
          {forceOutResults && (
            <Box>
              <Accordion>
                <AccordionSummary
                  aria-controls='panel1a-content'
                  id='panel1a-header'>
                  <Stack direction='row' spacing={1}>
                    <Stack direction='row'>
                      <CheckCircleOutlinedIcon color='success' />
                      <Typography color='lightgreen'>Success</Typography>
                    </Stack>
                    <Typography sx={{ color: 'text.secondary' }}>
                      <>
                        {' '}
                        {forceOutResults.data.successes.length} participant(s)
                      </>
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {forceOutResults.data.successes.join()}
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  aria-controls='panel2a-content'
                  id='panel2a-header'>
                  <Stack direction='row' spacing={1}>
                    <Stack direction='row'>
                      <WarningAmberOutlinedIcon color='error' />
                      <Typography color='red'>Failed</Typography>
                    </Stack>
                    <Typography sx={{ color: 'text.secondary' }}>
                      <> {forceOutResults.data.failed.length} participant(s)</>
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ wordWrap: 'break-word' }}>
                    <strong>Participant IDs:</strong>{' '}
                    {forceOutResults.data.failed.join()}
                  </Typography>
                  {forceOutResults.data.errorMessage && (
                    <Typography>
                      <strong>Error message:</strong>{' '}
                      {forceOutResults.data.errorMessage}
                    </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
              <DownloadCSVButton
                buttonProps={{
                  sx: {
                    fontSize: 'small',
                    mt: 2
                  },
                  variant: 'outlined'
                }}
                fileName={`BulkDistributionResults_${new Date().toISOString()}`}
                getInfo={getCsvData}
                text='EXPORT CSV'
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

ForceOutDistributionTab.displayName = 'ForceOutDistributionTab';
