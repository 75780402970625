import formatters from '@/utils/Formatters';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { useField, useFormikContext } from 'formik';
import React, { FunctionComponent } from 'react';
import { PatternFormat, PatternFormatProps } from 'react-number-format';

export type PatternFormatFormProps = TextFieldProps & {
  name: string;
  label?: string;
  'data-testid'?: string;
  patternFormatProps: PatternFormatProps & {
    returnFormattedValue?: boolean;
    'data-testid'?: string;
  };
};

type CustomProps = {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  returnFormattedValue?: boolean;
};

const PatternFormatCustom = React.forwardRef<PatternFormatProps, CustomProps>(
  function PatternFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <PatternFormat
        format=''
        {...other}
        getInputRef={ref}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: props.returnFormattedValue
                ? values.formattedValue
                : values.value
            }
          });
        }}
      />
    );
  }
);

export const PatternFormatForm: FunctionComponent<
  PatternFormatFormProps
> = props => {
  const formik = useFormikContext();
  const [, meta] = useField(props.name);

  return (
    <TextField
      {...props}
      InputProps={{
        inputComponent: PatternFormatCustom as any,
        inputProps: {
          ...props.patternFormatProps,
          // off doesn't work and I get one password in the input
          autoComplete: 'new-password'
        }
      }}
      data-testid={
        props['data-testid'] ||
        formatters.textToDataTestId(props.label || props.name)
      }
      error={!!meta.error}
      helperText={`${meta.error || ''}`}
      label={props.label}
      name={props.name}
      onChange={event => formik.setFieldValue(props.name, event.target.value)}
      type='text'
      value={meta.value || ''}
    />
  );
};
