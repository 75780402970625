import BrandingSchema from '@/models/BrandingSchema.model';

const vestwellBranding: BrandingSchema = {
  colors: {
    success: '#70CC70'
  },
  logoAlt: 'Vestwell',
  logoPath: '/images/logo-vestwell-horizontal.svg'
};

export default vestwellBranding;
