import Constants from '@/consts/planDesign.constants';
import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class ServicePriorToPlanExistence {
  convertToApiState = Helper.convertFromYesNoToBoolean;

  options = Constants.yesNoOptions;

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.output = Helper.convertFromBooleanToYesNo(
      props.excludeServicePriorToPlanExistence
    );
  }
}

export default ServicePriorToPlanExistence;
