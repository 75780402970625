import { DataGridFilters } from '@/components/data-grid-filters/DataGridFilters.component';
import DatePickerForm from '@/components/date-picker/DatePickerForm';
import {
  initialPlanRolloverFilters,
  PlanRolloverFilters
} from '@/models/PlanDistributionFilters.model';
import { Search } from '@mui/icons-material';
import CachedIcon from '@mui/icons-material/Cached';
import {
  Autocomplete,
  Button,
  Checkbox,
  Divider,
  FormControl,
  InputAdornment,
  MenuItem,
  Stack,
  TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { Field, Form, Formik } from 'formik';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';

interface PlanRolloverDistributionsFiltersProps {
  filters: PlanRolloverFilters;
  updateFilters: (filters: PlanRolloverFilters) => void;
  type: string;
  queryData: Record<string, any>;
}

const StyledBtnStack = styled(Stack)(() => ({
  flexDirection: 'row',
  gap: 2,
  justifyContent: 'space-between',
  width: '100%'
}));

export const PlanRolloverDistributionsFilters = (
  props: PlanRolloverDistributionsFiltersProps
): JSX.Element => {
  const handleSubmit = useCallback((values: PlanRolloverFilters) => {
    props.updateFilters(values);
  }, []);

  const statusTypes = Array.from(new Set(props.queryData?.map(r => r.status)));

  // revisit once endpoint is done- is the useEffect necessary?
  const filters = useMemo(
    () => ({
      createdAtDateEnd: props.filters?.createdAtDateEnd,
      createdAtDateStart: props.filters?.createdAtDateStart,
      originalAmountEnd: props.filters?.originalAmountEnd,
      originalAmountStart: props.filters?.originalAmountStart,
      outstandingBalanceEnd: props.filters?.outstandingBalanceEnd,
      outstandingBalanceStart: props.filters?.outstandingBalanceStart,
      preTaxAmountEnd: props.filters?.preTaxAmountEnd,
      preTaxAmountStart: props.filters?.preTaxAmountStart,
      rothAmountEnd: props.filters?.rothAmountEnd,
      rothAmountStart: props.filters?.rothAmountStart,
      startDateEnd: props.filters?.startDateEnd,
      startDateStart: props.filters?.startDateStart,
      statuses: props.filters?.statuses,
      updatedAtDateEnd: props.filters?.updatedAtDateEnd,
      updatedAtDateStart: props.filters?.updatedAtDateStart
    }),
    [props.filters]
  );

  const prevFilters = useRef(initialPlanRolloverFilters);

  useEffect(() => {
    if (!isEqual(prevFilters.current, filters)) {
      prevFilters.current = filters;
      props.updateFilters(filters);
    }
  }, [filters]);

  return (
    <DataGridFilters>
      <Formik
        initialValues={initialPlanRolloverFilters}
        onSubmit={handleSubmit}>
        {formProps => {
          const areFiltersEmpty = isEqual(
            formProps.values,
            formProps.initialValues
          );

          return (
            <Form data-testid='distributions-filter-form'>
              <Stack gap={2} width={250}>
                <FormControl fullWidth size='small'>
                  <Autocomplete
                    data-testid='status-filter'
                    disableCloseOnSelect
                    multiple
                    onChange={(_event, newValues) => {
                      formProps.setFieldValue('statuses', newValues);
                    }}
                    options={statusTypes}
                    renderInput={params => (
                      <TextField
                        {...params}
                        data-testid='status-filter-option'
                        label='Status'
                      />
                    )}
                    renderOption={(rootProps, option, { selected }) => (
                      <MenuItem
                        {...rootProps}
                        data-testid={`menu-item-${option}`}
                        data-value={option}
                        style={{ whiteSpace: 'small' }}>
                        <Checkbox
                          checked={selected}
                          data-value={option}
                          sx={{ py: 0 }}
                        />
                        {option}
                      </MenuItem>
                    )}
                    size='small'
                    value={formProps.values.statuses}
                  />
                </FormControl>

                {props.type === 'Rollovers' ? (
                  <>
                    <Divider style={{ width: '100%' }} textAlign='left'>
                      Pre-tax Amount
                    </Divider>

                    {/* todo: fields should only take numbers */}
                    <FormControl fullWidth>
                      <Field
                        InputProps={{
                          'aria-placeholder': 'Filter by Pre-tax Amount',
                          endAdornment: (
                            <InputAdornment position='end'>
                              <Search />
                            </InputAdornment>
                          ),
                          sx: { '& .MuiOutlinedInput-input': { pb: 1, pt: 1 } }
                        }}
                        as={TextField}
                        data-testid='pre-tax-amount-start'
                        id='pre-tax-amount-start'
                        label='From'
                        name='preTaxAmountStart'
                        size='small'
                        variant='outlined'
                      />
                    </FormControl>

                    <FormControl fullWidth>
                      <Field
                        InputProps={{
                          'aria-placeholder': 'Filter by Pre-tax Amount',
                          endAdornment: (
                            <InputAdornment position='end'>
                              <Search />
                            </InputAdornment>
                          ),
                          sx: { '& .MuiOutlinedInput-input': { pb: 1, pt: 1 } }
                        }}
                        as={TextField}
                        data-testid='pre-tax-amount-end'
                        id='pre-tax-amount-end'
                        label='To'
                        name='preTaxAmountEnd'
                        size='small'
                        variant='outlined'
                      />
                    </FormControl>

                    <Divider style={{ width: '100%' }} textAlign='left'>
                      Roth Amount
                    </Divider>

                    <FormControl fullWidth>
                      <Field
                        InputProps={{
                          'aria-placeholder': 'Filter by Roth Amount',
                          endAdornment: (
                            <InputAdornment position='end'>
                              <Search />
                            </InputAdornment>
                          ),
                          sx: { '& .MuiOutlinedInput-input': { pb: 1, pt: 1 } }
                        }}
                        as={TextField}
                        data-testid='roth-amount-start'
                        id='roth-amount-start'
                        label='From'
                        name='rothAmountStart'
                        size='small'
                        variant='outlined'
                      />
                    </FormControl>

                    <FormControl fullWidth>
                      <Field
                        InputProps={{
                          'aria-placeholder': 'Filter by Roth Amount',
                          endAdornment: (
                            <InputAdornment position='end'>
                              <Search />
                            </InputAdornment>
                          ),
                          sx: { '& .MuiOutlinedInput-input': { pb: 1, pt: 1 } }
                        }}
                        as={TextField}
                        data-testid='roth-amount-end'
                        id='roth-amount-end'
                        label='To'
                        name='rothAmountEnd'
                        size='small'
                        variant='outlined'
                      />
                    </FormControl>

                    <Divider style={{ width: '100%' }} textAlign='left'>
                      Created Date
                    </Divider>

                    <FormControl fullWidth>
                      <DatePickerForm
                        data-testid='created-at-date-start-filter'
                        disableFuture
                        format='MM/DD/YYYY'
                        fullWidth
                        handleError={error => {
                          if (error === 'invalidDate') {
                            formProps.setFieldError('createdAtDateStart', '');
                          }
                        }}
                        inputProps={{
                          autoComplete: 'off'
                        }}
                        label='From'
                        name='createdAtDateStart'
                        size='small'
                        value={formProps.values.createdAtDateStart}
                        variant='outlined'
                      />
                    </FormControl>

                    <FormControl fullWidth>
                      <DatePickerForm
                        data-testid='created-at-date-end-to-filter'
                        disableFuture
                        format='MM/DD/YYYY'
                        fullWidth
                        handleError={error => {
                          if (error === 'invalidDate') {
                            formProps.setFieldError('createdAtDateEnd', '');
                          }
                        }}
                        inputProps={{
                          autoComplete: 'off'
                        }}
                        label='To'
                        name='createdAtDateEnd'
                        size='small'
                        value={formProps.values.createdAtDateEnd}
                        variant='outlined'
                      />
                    </FormControl>

                    <Divider style={{ width: '100%' }} textAlign='left'>
                      Last Updated Date
                    </Divider>

                    <FormControl fullWidth>
                      <DatePickerForm
                        data-testid='updated-at-date-start-from-filter'
                        disableFuture
                        format='MM/DD/YYYY'
                        fullWidth
                        handleError={error => {
                          if (error === 'invalidDate') {
                            formProps.setFieldError('updatedAtDateStart', '');
                          }
                        }}
                        inputProps={{
                          autoComplete: 'off'
                        }}
                        label='From'
                        name='updatedAtDateStart'
                        size='small'
                        value={formProps.values.updatedAtDateStart}
                        variant='outlined'
                      />
                    </FormControl>

                    <FormControl fullWidth>
                      <DatePickerForm
                        data-testid='updated-at-date-end-to-filter'
                        disableFuture
                        format='MM/DD/YYYY'
                        fullWidth
                        handleError={error => {
                          if (error === 'invalidDate') {
                            formProps.setFieldError('updatedAtDateEnd', '');
                          }
                        }}
                        inputProps={{
                          autoComplete: 'off'
                        }}
                        label='To'
                        name='updatedAtDateEnd'
                        size='small'
                        value={formProps.values.updatedAtDateEnd}
                        variant='outlined'
                      />
                    </FormControl>
                  </>
                ) : props.type === 'Loans' ? (
                  <>
                    <Divider style={{ width: '100%' }} textAlign='left'>
                      Original Amount
                    </Divider>

                    <FormControl fullWidth>
                      <Field
                        InputProps={{
                          'aria-placeholder': 'Filter by Amount',
                          endAdornment: (
                            <InputAdornment position='end'>
                              <Search />
                            </InputAdornment>
                          ),
                          sx: { '& .MuiOutlinedInput-input': { pb: 1, pt: 1 } }
                        }}
                        as={TextField}
                        data-testid='original-amount-start'
                        id='original-amount-start'
                        label='From'
                        name='originalAmountStart'
                        size='small'
                        variant='outlined'
                      />
                    </FormControl>

                    <FormControl fullWidth>
                      <Field
                        InputProps={{
                          'aria-placeholder': 'Filter by Amount',
                          endAdornment: (
                            <InputAdornment position='end'>
                              <Search />
                            </InputAdornment>
                          ),
                          sx: { '& .MuiOutlinedInput-input': { pb: 1, pt: 1 } }
                        }}
                        as={TextField}
                        data-testid='original-amount-end'
                        id='original-amount-end'
                        label='To'
                        name='originalAmountEnd'
                        size='small'
                        variant='outlined'
                      />
                    </FormControl>

                    <Divider style={{ width: '100%' }} textAlign='left'>
                      Outstanding Balance
                    </Divider>

                    <FormControl fullWidth>
                      <Field
                        InputProps={{
                          'aria-placeholder': 'Filter by Amount',
                          endAdornment: (
                            <InputAdornment position='end'>
                              <Search />
                            </InputAdornment>
                          ),
                          sx: { '& .MuiOutlinedInput-input': { pb: 1, pt: 1 } }
                        }}
                        as={TextField}
                        data-testid='outstanding-amount-start'
                        id='outstanding-amount-start'
                        label='From'
                        name='outstandingAmountStart'
                        size='small'
                        variant='outlined'
                      />
                    </FormControl>

                    <FormControl fullWidth>
                      <Field
                        InputProps={{
                          'aria-placeholder': 'Filter by Amount',
                          endAdornment: (
                            <InputAdornment position='end'>
                              <Search />
                            </InputAdornment>
                          ),
                          sx: { '& .MuiOutlinedInput-input': { pb: 1, pt: 1 } }
                        }}
                        as={TextField}
                        data-testid='outstanding-amount-end'
                        id='outstanding-amount-end'
                        label='To'
                        name='outstandingAmountEnd'
                        size='small'
                        variant='outlined'
                      />
                    </FormControl>

                    <Divider style={{ width: '100%' }} textAlign='left'>
                      Created Date
                    </Divider>

                    <FormControl fullWidth>
                      <DatePickerForm
                        data-testid='create-date-start'
                        disableFuture
                        format='MM/DD/YYYY'
                        fullWidth
                        handleError={error => {
                          if (error === 'invalidDate') {
                            formProps.setFieldError('createdAtDateStart', '');
                          }
                        }}
                        inputProps={{
                          autoComplete: 'off'
                        }}
                        label='From'
                        name='createdAtDateStart'
                        size='small'
                        value={formProps.values.createdAtDateStart}
                        variant='outlined'
                      />
                    </FormControl>

                    <FormControl fullWidth>
                      <DatePickerForm
                        data-testid='create-date-end'
                        disableFuture
                        format='MM/DD/YYYY'
                        fullWidth
                        handleError={error => {
                          if (error === 'invalidDate') {
                            formProps.setFieldError('createdAtDateEnd', '');
                          }
                        }}
                        inputProps={{
                          autoComplete: 'off'
                        }}
                        label='To'
                        name='createdAtDateEnd'
                        size='small'
                        value={formProps.values.createdAtDateEnd}
                        variant='outlined'
                      />
                    </FormControl>

                    <Divider style={{ width: '100%' }} textAlign='left'>
                      Start Date
                    </Divider>

                    <FormControl fullWidth>
                      <DatePickerForm
                        data-testid='start-date-start'
                        disableFuture
                        format='MM/DD/YYYY'
                        fullWidth
                        handleError={error => {
                          if (error === 'invalidDate') {
                            formProps.setFieldError('startDateStart', '');
                          }
                        }}
                        inputProps={{
                          autoComplete: 'off'
                        }}
                        label='From'
                        name='startDateStart'
                        size='small'
                        value={formProps.values.startDateStart}
                        variant='outlined'
                      />
                    </FormControl>

                    <FormControl fullWidth>
                      <DatePickerForm
                        data-testid='start-date-end'
                        disableFuture
                        format='MM/DD/YYYY'
                        fullWidth
                        handleError={error => {
                          if (error === 'invalidDate') {
                            formProps.setFieldError('startDateEnd', '');
                          }
                        }}
                        inputProps={{
                          autoComplete: 'off'
                        }}
                        label='To'
                        name='startDateEnd'
                        size='small'
                        value={formProps.values.startDateEnd}
                        variant='outlined'
                      />
                    </FormControl>
                  </>
                ) : (
                  <>
                    <Divider style={{ width: '100%' }} textAlign='left'>
                      Amount
                    </Divider>

                    <FormControl fullWidth>
                      <Field
                        InputProps={{
                          'aria-placeholder': 'Filter by Amount',
                          endAdornment: (
                            <InputAdornment position='end'>
                              <Search />
                            </InputAdornment>
                          ),
                          sx: { '& .MuiOutlinedInput-input': { pb: 1, pt: 1 } }
                        }}
                        as={TextField}
                        data-testid='original-amount-start'
                        id='original-amount-start'
                        label='From'
                        name='originalAmountStart'
                        size='small'
                        variant='outlined'
                      />
                    </FormControl>

                    <FormControl fullWidth>
                      <Field
                        InputProps={{
                          'aria-placeholder': 'Filter by Amount',
                          endAdornment: (
                            <InputAdornment position='end'>
                              <Search />
                            </InputAdornment>
                          ),
                          sx: { '& .MuiOutlinedInput-input': { pb: 1, pt: 1 } }
                        }}
                        as={TextField}
                        data-testid='original-amount-end'
                        id='original-amount-end'
                        label='To'
                        name='orignalAmountEnd'
                        size='small'
                        variant='outlined'
                      />
                    </FormControl>

                    <Divider style={{ width: '100%' }} textAlign='left'>
                      Created Date
                    </Divider>

                    <FormControl fullWidth>
                      <DatePickerForm
                        data-testid='created-at-date-start-filter'
                        disableFuture
                        format='MM/DD/YYYY'
                        fullWidth
                        handleError={error => {
                          if (error === 'invalidDate') {
                            formProps.setFieldError('createdAtDateStart', '');
                          }
                        }}
                        inputProps={{
                          autoComplete: 'off'
                        }}
                        label='From'
                        name='createdAtDateStart'
                        size='small'
                        value={formProps.values.createdAtDateStart}
                        variant='outlined'
                      />
                    </FormControl>

                    <FormControl fullWidth>
                      <DatePickerForm
                        data-testid='created-at-date-end-filter'
                        disableFuture
                        format='MM/DD/YYYY'
                        fullWidth
                        handleError={error => {
                          if (error === 'invalidDate') {
                            formProps.setFieldError('createdAtDateEnd', '');
                          }
                        }}
                        inputProps={{
                          autoComplete: 'off'
                        }}
                        label='To'
                        name='createdAtDateEnd'
                        size='small'
                        value={formProps.values.createdAtDateEnd}
                        variant='outlined'
                      />
                    </FormControl>
                  </>
                )}
                <StyledBtnStack>
                  <Button
                    data-testid='submit'
                    disabled={!formProps.isValid}
                    type='submit'
                    variant='outlined'>
                    Apply
                  </Button>
                  {!areFiltersEmpty && (
                    <Button
                      data-testid='reset'
                      onClick={() => {
                        formProps.resetForm();
                        props.updateFilters(initialPlanRolloverFilters);
                      }}
                      startIcon={<CachedIcon />}
                      variant='text'>
                      Reset
                    </Button>
                  )}
                </StyledBtnStack>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </DataGridFilters>
  );
};
