import Auth0Config from '@/models/Auth0Config.model';

const getAuth0Config = (env: string): Auth0Config => {
  if (env === 'production') {
    return {
      audience: 'vestwell-organizations-prod',
      clientId: 'c9LJdZ5CuEraZ7n17sPd4PEiebw6h5xY',
      domain: 'auth.vestwell.com',
      redirectUri: window.location.origin
    };
  }

  return {
    audience: 'vestwell-organizations-dev',
    clientId: 'k2jcuTHFSjpKhm3zsPO4lRsfXpQecxPP',
    domain: 'qa-vestwell.us.auth0.com',
    redirectUri: window.location.origin
  };
};

export default getAuth0Config;
