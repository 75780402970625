import AccessControl from '@/components/access-control/AccessControl.component';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import TpaService from '@/services/Tpa.service';
import { Button, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation } from '@tanstack/react-query';

import React from 'react';

// alex: types will be improved when real data is implemented
interface ActionTpaPlanBtnProps {
  tpaId: number;
  planId: number;
  forceRefetch: () => void;
  goBackOnePage: () => void;
  tpaPlanslength: number;
  setLastOperation: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionBtn: {
      fontSize: theme.spacing(2),
      textTransform: 'none'
    }
  })
);

const ActionTpaPlanBtn = (props: ActionTpaPlanBtnProps): JSX.Element => {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();
  const {
    planId,
    tpaId,
    forceRefetch,
    goBackOnePage,
    tpaPlanslength,
    setLastOperation
  } = props;

  const removeTpaPlanLink = useMutation(
    (data: { tpaId: number; planId: number }) => {
      return TpaService.removeTpaPlanRelationship(data.tpaId, data.planId);
    }
  );

  const removeTpaPlan = async () => {
    try {
      const goBack = tpaPlanslength === 1;

      await removeTpaPlanLink.mutateAsync({ planId, tpaId });
      setLastOperation();
      forceRefetch();
      if (goBack) {
        goBackOnePage();
      }

      showSnackbar({
        message: 'Plan was successfully removed',
        severity: 'success'
      });
    } catch (e) {
      showSnackbar({ message: 'Failed!', severity: 'error' });
    }
  };

  return (
    <AccessControl requires={[FeatureLevelPermissions.WRITE_TPA_DETAILS_PLANS]}>
      <Button
        className={classes.actionBtn}
        data-testid='tpa-remove-plan-btn'
        onClick={() => removeTpaPlan()}
        size='medium'>
        Remove
      </Button>
    </AccessControl>
  );
};

export default ActionTpaPlanBtn;
