import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class ConversionType {
  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.output = Helper.getConversionTypeName(props.conversionType);
  }
}

export default ConversionType;
