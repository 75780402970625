import Badge from '@/components/badge';
import Card, {
  CardContent,
  CardHeader,
  CardPlaceholder
} from '@/components/card/Card.component';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { ParticipantInfo } from '@/models';
import { EsaService } from '@/services/Esa.service';
import formatters from '@/utils/Formatters';
import { grey } from '@mui/material/colors';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { useMemo } from 'react';

type AppliedIncentivesCardProps = {
  participant: ParticipantInfo;
};
const AppliedIncentivesCard: React.FC<AppliedIncentivesCardProps> = (
  props: AppliedIncentivesCardProps
) => {
  const esaIncentivesQuery = useQuery({
    queryFn: () =>
      EsaService.getEsaParticipantIncentives(props.participant.participantId),
    queryKey: [
      'EsaService.getEsaParticipantIncentives',
      props.participant.participantId
    ]
  });
  const esaPlanGroupsQuery = useQuery({
    queryFn: () => EsaService.getEsaPlanGroups(props.participant.sponsorPlanId),
    queryKey: ['EsaService.getEsaPlanGroups', props.participant.sponsorPlanId]
  });

  const esaGroup = useMemo(
    () =>
      esaPlanGroupsQuery.data?.filter(
        group => group.groupId === props.participant.esaGroupId
      )[0],
    [esaPlanGroupsQuery.data, props.participant]
  );

  const tableData = useMemo(() => {
    if (esaIncentivesQuery.data && esaGroup) {
      const data = [
        {
          amount: esaGroup.match.dollarAmount,
          contributionType: 'Employer Match',
          createdAt: undefined,
          received: false
        },
        {
          amount: esaGroup.initialBonus,
          contributionType: 'Initial Deposit Bonus',
          createdAt: undefined,
          received: false
        },
        {
          amount: esaGroup.milestoneBonus.amount,
          contributionType: 'Milestone Bonus',
          createdAt: undefined,
          received: false
        }
      ];
      [...esaIncentivesQuery.data].forEach(incentive => {
        switch (incentive.contributionType) {
          case 'Employer Match': {
            data[0].received =
              incentive.contributionValue === esaGroup.match.dollarAmount;
            data[0].createdAt = data[0].received
              ? formatters.formatFromIsoDateCustom(
                  incentive.createdAt,
                  'MM/DD/YYYY'
                )
              : undefined;
            break;
          }
          case 'Initial Deposit Bonus': {
            data[1].received =
              incentive.contributionValue === esaGroup.initialBonus;
            data[1].createdAt = data[1].received
              ? formatters.formatFromIsoDateCustom(
                  incentive.createdAt,
                  'MM/DD/YYYY'
                )
              : undefined;
            break;
          }
          case 'Milestone Bonus': {
            data[2].received =
              incentive.contributionValue === esaGroup.milestoneBonus.amount;
            data[2].createdAt = data[2].received
              ? formatters.formatFromIsoDateCustom(
                  incentive.createdAt,
                  'MM/DD/YYYY'
                )
              : undefined;
            break;
          }

          default:
            break;
        }
      });
      return data;
    }
    return [];
  }, [esaIncentivesQuery.data, esaGroup]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'contributionType',
        headerName: 'Incentive',
        minWidth: 170
      },
      {
        field: 'amount',
        headerName: 'Amount',
        minWidth: 100,
        valueFormatter: params =>
          params.value
            ? formatters.formatDollars(params.value)
            : EMPTY_FIELD_PLACEHOLDER
      },
      {
        field: 'received',
        headerName: 'Received',
        minWidth: 50,
        renderCell: params => (
          <Badge color='neutral' size='small'>
            {params.row.received ? 'Yes' : 'No'}
          </Badge>
        )
      },
      {
        field: 'createdAt',
        headerName: 'Date',
        minWidth: 100,
        valueFormatter: params => params.value || EMPTY_FIELD_PLACEHOLDER
      }
    ],
    []
  );

  return (
    <Card data-testid='participant-applied-incentives-card'>
      <CardHeader
        disableDivider
        loading={esaPlanGroupsQuery.isFetching || esaIncentivesQuery.isFetching}
        title='Applied Incentives'
      />

      <CardContent sx={{ pt: 0 }}>
        {!tableData.length ? (
          <CardPlaceholder
            data-testid='no-data-placeholder'
            subtitle='There are no applied incentives'
          />
        ) : (
          <DataGridPro
            autoHeight
            columnBuffer={5}
            columns={columns}
            disableColumnResize
            disableRowSelectionOnClick
            getDetailPanelHeight={() => 'auto'}
            getRowId={row => row.contributionType}
            hideFooter
            rows={tableData}
            sx={{
              '.MuiDataGrid-cell': {
                color: grey['900']
              },
              '.MuiDataGrid-columnSeparator': {
                display: 'none'
              },
              border: '0px !important'
            }}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default AppliedIncentivesCard;
