export const doesNotExceedPrecision =
  (precision: number) => (value?: number) => {
    const regex = new RegExp(`^-?\\d+(\\.\\d{0,${precision}})?$`);
    return regex.test(value?.toString() || '');
  };

export const hasExactPrecision =
  (numberOfDecimals: number) => (value?: number) =>
    new RegExp(
      `^-?\\s*(?=.*[0-9])\\d*(?:\\.\\d{1,${numberOfDecimals}})?\\s*$`
    ).test(value !== undefined ? value.toString() : '');
