import Constants from '@/consts/planDesign.constants';
import Helper from '@/routes/plans/plan-detail/PlanTab/Helper';

class VestingMethod {
  convertToApiState = Helper.convertValueToApiState;

  options = Constants.vestingMethod;

  output: string | undefined;

  constructor(props: Record<string, any>) {
    this.output = props.vestingMethod;
  }
}

export default VestingMethod;
