import { useSnackbar } from '@/contexts/SnackBarContext';
import { AlertDto } from '@/models/ops/alerts/AlertDTO.model';
import { AlertUpdateRequest } from '@/models/ops/alerts/AlertUpdateRequest.model';
import AlertService from '@/services/ops/alerts/Alert.service';
import {
  useMutation,
  UseMutationResult,
  useQueryClient
} from '@tanstack/react-query';

export const useUpdateAlertMutation = (): UseMutationResult<
  AlertDto,
  Error,
  {
    alertId: number;
    updateRequest: AlertUpdateRequest;
  }
> => {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation(
    ['AlertService.update'],
    ({ alertId, updateRequest }) => {
      return AlertService.update(alertId, updateRequest);
    },
    {
      onError: (_err, _updateRequest, context: any) => {
        const previousAlert = context?.previousAlert;

        showSnackbar({
          message: `Error updating alert ${previousAlert?.id} - ${previousAlert?.alertName}`,
          severity: 'error'
        });

        // Rollback to snapshot
        queryClient.setQueryData(
          ['AlertService.getById', previousAlert?.id],
          previousAlert
        );
      },
      // Following sequence performs an optimistic update as described here: https://tanstack.com/query/v3/docs/react/guides/optimistic-updates
      onMutate: async ({ alertId, updateRequest }) => {
        // Cancel refetches in flight to avoid conflict
        await queryClient.cancelQueries({
          queryKey: ['AlertService.getById', alertId]
        });

        // Snapshot current data
        const previousAlert = queryClient.getQueryData<AlertDto>([
          'AlertService.getById',
          alertId
        ]);

        // Optimistically update cache
        queryClient.setQueryData(['AlertService.getById', alertId], {
          ...previousAlert,
          ...updateRequest,
          details: {
            ...previousAlert?.details,
            ...(updateRequest.details || {})
          }
        });

        // Return snapshot in context object
        return { previousAlert };
      },
      onSuccess: updatedAlert => {
        showSnackbar({
          message: `${updatedAlert?.id} - ${updatedAlert?.alertName} updated`,
          severity: 'success'
        });
        // Invalidate the alert search query so that we get fresh results
        queryClient.invalidateQueries(['AlertService.search']);
      }
    }
  );
};

export default useUpdateAlertMutation;
