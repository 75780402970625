import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { RolloverDeliveryDetails } from '@/models';
import formatters from '@/utils/Formatters';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Stack,
  Typography
} from '@mui/material';

import React from 'react';

import AccountNumber from './AccountNumber.component';
import TaxDetailsStack from './TaxDetailsStack.component';

type RolloverDeliveryInfoAccordionProps = {
  fundingSources: string[];
  delivery: RolloverDeliveryDetails;
  isRoth?: boolean;
};

const RolloverDeliveryInfoAccordion: React.FunctionComponent<
  RolloverDeliveryInfoAccordionProps
> = (props: RolloverDeliveryInfoAccordionProps) => {
  const { fundingSources, delivery, isRoth = false } = props;

  const label = isRoth ? 'Roth' : 'Pre-Tax';

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ flexShrink: 0, width: '33%' }} variant='h6'>
          {`Rollover (${label})`}
        </Typography>
        <Typography>
          {`${
            delivery.fullAmount
              ? 'FULL'
              : formatters.formatDollars(delivery.partialAmount)
          }, ${delivery.deliveryDestination?.type}`}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack
          direction='row'
          divider={<Divider flexItem orientation='vertical' />}
          spacing={2}>
          <TextStack direction='column' sx={{ m: 0, p: 0, width: '41%' }}>
            <Typography sx={{ fontSize: 15, fontWeight: 500 }}>
              Delivery Options
            </Typography>
            <TextStackItem>
              <TextLabel>Delivery Method</TextLabel>
              <TextValue data-testid='rollover-delivery-type'>
                {delivery.deliveryDestination?.type}
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Amount</TextLabel>
              <TextValue data-testid='rollover-amount'>
                {delivery.fullAmount
                  ? 'FULL'
                  : formatters.formatDollars(delivery.partialAmount)}
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Destination</TextLabel>
              <TextValue data-testid='rollover-destination'>
                {delivery.destination}
              </TextValue>
            </TextStackItem>

            {(delivery.deliveryDestination?.type === 'CHECK' ||
              delivery.deliveryDestination?.type === 'OVERNIGHT_CHECK') && (
              <>
                <TextStackItem>
                  <TextLabel>Payee</TextLabel>
                  <TextValue data-testid='rollover-payee'>
                    {delivery.payee}
                  </TextValue>
                </TextStackItem>
                <TextStackItem>
                  <TextLabel>Memo</TextLabel>
                  <TextValue data-testid='rollover-memo'>
                    {delivery.memo}
                  </TextValue>
                </TextStackItem>
                <TextStackItem>
                  <TextLabel>Address</TextLabel>
                  <TextValue data-testid='rollover-address'>
                    {`${delivery.deliveryDestination.address1}`}
                    <br />
                    {!!delivery.deliveryDestination?.address2 && (
                      <>
                        {`${delivery.deliveryDestination?.address2 ?? ''}`}
                        <br />
                      </>
                    )}
                    {`${delivery.deliveryDestination.city} ${delivery.deliveryDestination.state} ${delivery.deliveryDestination.zip}`}
                  </TextValue>
                </TextStackItem>
              </>
            )}
            {(delivery.deliveryDestination?.type === 'ACH' ||
              delivery.deliveryDestination?.type === 'WIRE') && (
              <>
                <TextStackItem>
                  <TextLabel>Bank Account Registered To</TextLabel>
                  <TextValue data-testid='rollover-bank-account-registered-to'>
                    {delivery.bankAccountRegistration ?? null}
                  </TextValue>
                </TextStackItem>
                <TextStackItem>
                  <TextLabel>Bank Name</TextLabel>
                  <TextValue data-testid='rollover-bank-name'>
                    {delivery.deliveryDestination.institutionName}
                  </TextValue>
                </TextStackItem>
                <TextStackItem>
                  <TextLabel>Routing Number</TextLabel>
                  <TextValue data-testid='rollover-routing-number'>
                    {delivery.deliveryDestination.routingNumber}
                  </TextValue>
                </TextStackItem>
                {!!delivery.deliveryDestination.accountNumber && (
                  <AccountNumber
                    accountNumber={delivery.deliveryDestination.accountNumber}
                    cardTitle='Account Number'
                    isShowNumber={true}
                  />
                )}
              </>
            )}
            {delivery.deliveryDestination?.type === 'WIRE' && (
              <>
                <TextStackItem>
                  <TextLabel>Credit To</TextLabel>
                  <TextValue>{delivery.deliveryDestination.creditTo}</TextValue>
                </TextStackItem>
                <TextStackItem>
                  <TextLabel>Further Credit To</TextLabel>
                  <TextValue>
                    {delivery.deliveryDestination.furtherCreditTo}
                  </TextValue>
                </TextStackItem>
              </>
            )}
          </TextStack>
          {isRoth ? (
            <TaxDetailsStack isRoth taxDetails={delivery.taxDetails} />
          ) : (
            <TaxDetailsStack taxDetails={delivery.taxDetails} />
          )}
          <Box>
            <Typography mb={2} sx={{ fontSize: 15, fontWeight: 500 }}>
              Funding Source(s)
            </Typography>
            {fundingSources.map(fundingSource => (
              <Typography
                key={fundingSource}
                sx={{ color: theme => theme.palette.text.primary }}
                variant='body2'>
                {fundingSource}
              </Typography>
            ))}
          </Box>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default RolloverDeliveryInfoAccordion;
