import Badge from '@/components/badge';
import CollapsibleTable, {
  CellComponentProps
} from '@/components/collapsible-table';
import formatters from '@/utils/Formatters';
import { alpha, Box, TableCell, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import React, { useMemo } from 'react';

import { SecurityCell } from '../PlanInvestmentsTab/FundLineupTab/FundLineupCell.component';

interface DeconversionRequestsCollapsibleProps {
  row: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  headerCell: {
    color: alpha(theme.palette.common.black, 0.6),
    fontSize: theme.spacing(1.75),
    fontWeight: 500,
    paddingBottom: theme.spacing(1.25),
    paddingTop: theme.spacing(1.25)
  }
}));

const DeconversionTransactionsTableCell: React.FC<CellComponentProps> = (
  props: CellComponentProps
) => {
  const { row, column } = props;

  let field = <Box>{row[column.field] || '--'}</Box>;

  switch (column.field) {
    case 'tradeDate':
      field = (
        <Box>
          {formatters.formatFromIsoDateCustom(row[column.field], 'M/DD/YYYY')}
        </Box>
      );
      break;
    case 'tradePrice':
    case 'dollars':
      field = <Box>{formatters.formatDollars(row[column.field])}</Box>;
      break;
    case 'security':
      field = (
        <SecurityCell
          row={{
            ...row,
            security: { ...row?.security, id: row?.security?.ticker }
          }}
        />
      );
      break;
    case 'status':
      field = row[column.field] && (
        <Badge size='small'>{row[column.field]}</Badge>
      );
      break;
    case 'type':
      field = (
        <>
          <Typography variant='body1'>{row.transactionType}</Typography>
          <Typography variant='body2'>{row.transactionSubtype}</Typography>
        </>
      );
      break;
  }

  return (
    <TableCell component='th' scope='row'>
      <Box sx={{ '& .MuiBox-root': { fontSize: theme => theme.spacing(2) } }}>
        {field}
      </Box>
    </TableCell>
  );
};

const DeconversionRequestsCollapsible = (
  props: DeconversionRequestsCollapsibleProps
): JSX.Element => {
  const classes = useStyles();
  const { row } = props;

  const deconversionRequestColumns = useMemo(
    () => [
      {
        cellClassName: classes.headerCell,
        field: 'status',
        headerName: 'Status',
        width: 130
      },

      {
        cellClassName: classes.headerCell,
        field: 'type',
        headerName: 'Base Type / Type',
        width: 130
      },
      {
        cellClassName: classes.headerCell,
        field: 'security',
        headerName: 'Security',
        width: 260
      },
      {
        cellClassName: classes.headerCell,
        field: 'units',
        headerName: 'Unit',
        width: 130
      },
      {
        cellClassName: classes.headerCell,
        field: 'tradePrice',
        headerName: 'Price',
        width: 130
      },
      {
        cellClassName: classes.headerCell,
        field: 'dollars',
        headerName: 'Amount',
        width: 130
      },
      {
        cellClassName: classes.headerCell,
        field: 'tradeDate',
        headerName: 'Trade Date',
        width: 130
      }
    ],
    [classes]
  );

  return (
    <Box sx={{ ml: 5 }}>
      <Typography sx={{ mb: 2 }} variant='h5'>
        Transactions
      </Typography>
      <CollapsibleTable
        backgroundPaperElevation={0}
        cellComponent={DeconversionTransactionsTableCell}
        columns={deconversionRequestColumns}
        headerPagination
        rootPaperElevation={0}
        tableData={row.transactions}
        tablePaginationSx={{
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          borderTop: '1px solid rgba(0, 0, 0, 0.12)'
        }}
      />
    </Box>
  );
};

export default DeconversionRequestsCollapsible;
