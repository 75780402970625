import { SnackbarOptions } from '@/contexts/SnackBarContext';
import { ParticipantInfo } from '@/models';
import Psa from '@/models/Psa.model';
import { checkForAccountOverride } from '@/utils/VestingOverride';
import { QueryClient } from '@tanstack/react-query';

export const checkAdditionalHoursValidations = (
  queryClient: QueryClient,
  participantId: string,
  showSnackbar: (options: SnackbarOptions) => void
): void => {
  const previousParticipant = queryClient.getQueryData<ParticipantInfo>([
    'ParticipantService.getParticipantById',
    participantId?.toString()
  ]);
  const previousPSA = queryClient.getQueryData<Psa>([
    'PsaService.getPsa',
    participantId?.toString()
  ]);

  if (previousParticipant?.isOverride) {
    showSnackbar({
      message: `Please note that there is an eligibility override on the account.
          If you’d like to recalculate eligibility, please take the override off and press “Rerun” under eligibility.`,
      severity: 'warning'
    });
  }

  if (previousPSA && checkForAccountOverride(previousPSA)) {
    showSnackbar({
      message:
        'Please note that there are historical/total years of service entered. Please review and ensure they are still correct.',
      severity: 'warning'
    });
  }
};
