import DataTable from '@/components/data-table/DataTable.component';
import { Box, Typography } from '@mui/material';
import { blue, grey } from '@mui/material/colors';

import { ColDef } from 'ag-grid-community';
import React from 'react';

const ConfirmTableDetailCellRenderer =
  (columnHeaderClassName: string) =>
  (cellData: { data: any }): JSX.Element => {
    const columnDefs: ColDef[] = [
      {
        cellRenderer: (params: any) => {
          return <Typography color={blue[500]}>{params.value}</Typography>;
        },
        field: 'plan',
        headerClass: () => columnHeaderClassName,
        headerName: 'Plan',
        maxWidth: 100,
        minWidth: 85,
        suppressMenu: true
      },
      {
        field: 'planName',
        flex: 1,
        headerClass: () => columnHeaderClassName,
        headerName: 'Plan Name',
        suppressMenu: true
      }
    ];

    return (
      <Box
        sx={{
          backgroundColor: grey[50],
          padding: 2
        }}>
        <DataTable
          columnDefs={columnDefs}
          columnSizing='fit'
          data-testid='data-security-confirm-modal-detail-table'
          primaryKey='plan'
          rowData={cellData.data.plans}
          rowHeight={48}
        />
      </Box>
    );
  };

export default ConfirmTableDetailCellRenderer;
