import { PayGroupForm } from '@/routes/plans/plan-detail/PayGroups/PayGroupsModal.component';
import SponsorService from '@/services/Sponsor.service';
import { InfoOutlined } from '@mui/icons-material';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography
} from '@mui/material';
import { red } from '@mui/material/colors';
import { useQuery } from '@tanstack/react-query';
import { Payroll, Sponsors } from '@vestwell-api/scala';

import { FormikProps } from 'formik';
import React from 'react';

export const PayGroupsModalPayment: React.FunctionComponent<{
  formik: FormikProps<PayGroupForm>;
  payGroup?: Payroll.GetSetups.ResponseBody[0];
  integratedPayGroups?: Payroll.GetSetups.ResponseBody;
  sponsorId: number;
  isStatePlan?: boolean;
}> = props => {
  const bankAccounts = useQuery<Sponsors.GetBankAccounts.ResponseBody>(
    ['SponsorService.getSponsorBankAccounts'],
    () => {
      return SponsorService.getSponsorBankAccounts(props.sponsorId);
    }
  );

  return (
    <Grid
      container
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
      spacing={4}>
      <Grid item sm={6}>
        <FormControl fullWidth>
          <InputLabel
            id='alternate-funding-source-label'
            shrink={!props.isStatePlan ? true : undefined}
            size='small'>
            Payment Method
          </InputLabel>

          <Select
            disabled={!props.isStatePlan}
            displayEmpty
            error={
              props.formik.touched.alternateFundingSource &&
              Boolean(props.formik.errors.alternateFundingSource)
            }
            fullWidth
            id='alternate-funding-source-label'
            label='Payment Method'
            labelId='alternate-funding-source-label'
            name='alternateFundingSource'
            notched={!props.isStatePlan ? true : undefined}
            onBlur={props.formik.handleBlur}
            onChange={async e => {
              await props.formik.setFieldValue('bankAccountId', '');
              props.formik.handleChange(e);
            }}
            size='small'
            value={props.formik.values.alternateFundingSource}>
            <MenuItem value='null'>ACH Pull</MenuItem>
            <MenuItem value='ach_push'>ACH Push</MenuItem>
            <MenuItem value='paper_check'>Paper Check</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {props.formik.values.alternateFundingSource === 'null' && (
        <>
          <Grid flexGrow={1} item>
            <FormControl>
              <FormLabel
                id='demo-radio-buttons-group-label'
                sx={{
                  marginBottom: 2
                }}>
                Bank Accounts
              </FormLabel>
              <RadioGroup
                name='bankAccountId'
                onBlur={props.formik.handleBlur}
                onChange={props.formik.handleChange}
                value={props.formik.values.bankAccountId}>
                {bankAccounts.data?.map(b => (
                  <FormControlLabel
                    control={<Radio />}
                    key={b.bankAccountId}
                    label={
                      <Grid item>
                        <Typography variant='body1'>{b.bankName}</Typography>
                        <Typography variant='body2'>
                          Account ending in *
                          {b.isPlaid ? b.plaidMask : b.accountNumber.slice(-4)}
                        </Typography>
                      </Grid>
                    }
                    sx={{
                      py: 2
                    }}
                    value={b.bankAccountId}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item sm={6}>
            <Typography variant='body2'>
              New bank account can be added via the "Bank Accounts" tab, under
              the "Company Info" section.
            </Typography>
          </Grid>
        </>
      )}
      {!bankAccounts.isFetching &&
        !bankAccounts.data?.length &&
        props.formik.values.alternateFundingSource === 'null' && (
          <Grid display='flex' item sm={12}>
            <Typography
              borderRadius={2}
              display='flex'
              p={4}
              sx={{
                backgroundColor: red[50],
                color: '#4d2c16'
              }}
              variant='body1'>
              <InfoOutlined
                color='error'
                sx={{
                  marginRight: 2
                }}
              />
              No bank account available. Please add a bank account first. New
              bank account can be added via the "Bank Accounts" tab, under the
              "Company Info" section.
            </Typography>
          </Grid>
        )}
    </Grid>
  );
};
