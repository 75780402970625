import { CardPlaceholder } from '@/components/card';
import { EMPTY_FIELD_PLACEHOLDER } from '@/consts/formatting';
import { PlanService } from '@/services/Plan.service';
import formatters from '@/utils/Formatters';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  LinearProgress,
  Stack,
  Typography
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import { FC, useMemo } from 'react';
import { useToggle } from 'react-use';

type RothDepositReportModalProps = {
  onClose: () => void;
  open: boolean;
  planId: string;
};

export const RothDepositReportModal: FC<
  RothDepositReportModalProps
> = props => {
  const [filter, toggleFilter] = useToggle(true);

  const rothReportQuery = useQuery(
    ['PlanService.getRothDepositReport', props.planId],
    () => {
      return PlanService.getRothDepositReport(props.planId);
    },
    { enabled: props.open && !!props.planId }
  );

  const filteredData = useMemo(() => {
    return (
      rothReportQuery.data?.filter(row =>
        filter
          ? row.rothCostBasis === undefined ||
            row.rothEstablishedDate === undefined
          : !!row.rothCostBasis && !!row.rothEstablishedDate
      ) ?? []
    );
  }, [filter, rothReportQuery.data]);

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: 'coreAccountId',
        flex: 1,
        headerName: `${filter ? 'Account ID' : 'Participant'}`,
        renderCell: params =>
          filter ? (
            <Typography color={blue[500]}>
              {params.row.coreAccountId}
            </Typography>
          ) : (
            <Stack>
              <Typography
                color={
                  blue[500]
                }>{`${params.row.lastName}, ${params.row.firstName}`}</Typography>
              <Typography variant='body2'>
                {params.row.coreAccountId}
              </Typography>
            </Stack>
          )
      },
      {
        field: 'amount',
        flex: 1,
        headerName: 'Roth Deposit',
        valueFormatter: params => {
          return formatters.formatDollars(params.value);
        }
      },
      {
        field: 'rothCostBasis',
        flex: 1,
        headerName: 'Roth Basis',
        valueFormatter: params => {
          return params.value
            ? formatters.formatDollars(params.value)
            : EMPTY_FIELD_PLACEHOLDER;
        }
      },
      {
        align: 'right',
        field: 'rothEstablishedDate',
        flex: 1,
        headerAlign: 'right',
        headerName: 'Roth First Year',
        valueFormatter: params => {
          return params.value
            ? formatters.formatFromIsoDateCustom(params.value, 'YYYY')
            : EMPTY_FIELD_PLACEHOLDER;
        }
      }
    ];
  }, [filter]);

  return (
    <Dialog fullWidth maxWidth='sm' onClose={props.onClose} open={props.open}>
      <DialogTitle>Roth Deposit Report</DialogTitle>
      <DialogContent sx={{ px: 0 }}>
        <DataGridPro
          autoHeight
          columns={columns}
          getRowId={row => row.coreAccountId}
          loading={rothReportQuery.isLoading}
          pageSizeOptions={[10, 20]}
          pagination
          paginationMode='client'
          paginationModel={{
            page: 0,
            pageSize: 10
          }}
          rows={filteredData ?? []}
          slots={{
            loadingOverlay: LinearProgress,
            noRowsOverlay: () => (
              <Stack
                alignItems='center'
                data-testid='no-data-roth-deposit-table'
                height='100%'
                justifyContent='center'>
                <CardPlaceholder
                  data-testid='no-data-roth-report'
                  subtitle='No data available'
                />
              </Stack>
            ),
            toolbar: () => (
              <FormControlLabel
                control={<Checkbox checked={filter} onChange={toggleFilter} />}
                label='Show missing Basis / First year only'
                sx={{ pl: 2 }}
              />
            )
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
