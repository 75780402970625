import CollapsibleTable, {
  CellComponentProps,
  Order
} from '@/components/collapsible-table';
import { redirectToErrorPage } from '@/components/error-detail/ErrorDetailPage.component';
import {
  ProgramConverter,
  ProgramPlan,
  ProgramPlanDto
} from '@/models/ops/investments/Program.model';
import { ProgramService } from '@/services/ops/investments/Program.service';
import { Search } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  InputAdornment,
  Link,
  TableCell,
  TextField,
  Theme,
  Typography
} from '@mui/material';
import { blue } from '@mui/material/colors';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      backgroundColor: '#FAFAFA',
      boxShadow: '24px',
      left: '50%',
      maxHeight: '95%',
      minWidth: theme.spacing(60),
      overflowY: 'auto',
      padding: theme.spacing(3),
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: '65%'
    },
    modalCloseBtn: {
      '&:hover': {
        background: 'transparent'
      },
      border: 'none',
      color: blue[500],
      float: 'right',
      fontWeight: 'bold',
      marginRight: theme.spacing(3),
      marginTop: theme.spacing(2)
    },
    modelContainer: {
      marginTop: theme.spacing(4)
    },
    programIdWidth: {
      width: '15%'
    },
    searchInput: {
      width: '50ch'
    },
    searchSection: {
      marginBottom: theme.spacing(3)
    },
    size: {
      fontSize: theme.spacing(2)
    }
  })
);

interface ProgramPlansProps {
  programId: number;
  modalTitle: string;
  handleCloseModal: () => void;
}

const ProgramPlansModal = (props: ProgramPlansProps): JSX.Element => {
  const { programId, modalTitle, handleCloseModal } = props;
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');

  const planTableColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Plan ID'
    },
    {
      field: 'name',
      headerName: 'Plan Name'
    },
    {
      field: 'status',
      headerName: 'Plan Status'
    }
  ];

  const programPlansQuery = useQuery<{
    data: ProgramPlanDto[];
    meta: { count: number };
  }>(
    [
      'ProgramService.getProgramPlans',
      programId,
      pageNumber,
      rowsPerPage,
      searchTerm,
      orderBy,
      order
    ],
    async () => {
      return ProgramService.getProgramPlans(
        programId,
        pageNumber,
        rowsPerPage,
        searchTerm,
        orderBy,
        order
      );
    }
  );

  const programPlanDtoData = programPlansQuery.data?.data || [];
  const programPlansData: ProgramPlan[] = programPlanDtoData.map(plan =>
    ProgramConverter.toProgramPlan(plan)
  );

  const { error } = programPlansQuery;
  if (error) {
    return redirectToErrorPage(error as Error);
  }

  const planTableCell: React.FunctionComponent<CellComponentProps> = (
    cellProps: CellComponentProps
  ) => {
    const { row, column } = cellProps;
    let field;

    if (column.field === 'name') {
      field = (
        <Link
          component={RouterLink}
          to={`/plans/${row.id}/plan`}
          underline='hover'>
          {row[column.field]}
        </Link>
      );
    } else field = row[column.field];

    return (
      <TableCell className={classes.size} component='th' scope='row'>
        <Box>{field}</Box>
      </TableCell>
    );
  };

  const searchProgramPlans = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;
    setSearchTerm(search);
    setPageNumber(1);
  };

  return (
    <>
      <Box className={classes.modal}>
        <Typography component='div' variant='h5'>
          {modalTitle}
        </Typography>
        <div className={classes.modelContainer}>
          <div className={classes.searchSection}>
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Search />
                  </InputAdornment>
                ),
                onChange: searchProgramPlans
              }}
              className={classes.searchInput}
              data-testid='search-program-plans'
              label='Search plans'
              value={searchTerm}
              variant='outlined'
            />
          </div>
          <Card elevation={0} variant='outlined'>
            <CollapsibleTable
              cellComponent={planTableCell}
              columns={planTableColumns}
              pager={{
                metaCount: programPlansQuery.data?.meta.count,
                onPageNumberChanged: (zeroIndexedPageNumber: number) => {
                  return setPageNumber(zeroIndexedPageNumber + 1);
                },
                onRowsPerPageChanged: (newRowsPerPage: number) => {
                  return setRowsPerPage(newRowsPerPage);
                },
                pageNumber: pageNumber - 1,
                rowsPerPage
              }}
              sorter={{
                onSortOrderChanged: (newOrderBy: string, newOrder: Order) => {
                  setOrderBy(newOrderBy);
                  setOrder(newOrder);
                },
                order,
                orderBy
              }}
              tableData={programPlansData}
            />
          </Card>
        </div>
        <Button
          className={classes.modalCloseBtn}
          data-testid='modal-close-btn'
          onClick={() => handleCloseModal()}>
          CLOSE
        </Button>
      </Box>
    </>
  );
};

export default ProgramPlansModal;
