import STATE_CODES from '@/consts/states.constants';
import { AdvisorsByFirmDto, FirmCreatedDto, FirmDto } from '@/models';
import ApiService from '@/services/Api.service';

import * as yup from 'yup';

export type FirmsListDto = {
  data: {
    type: 'firm';
    id: number;
    attributes: {
      homeOfficeName: string;
      companyName: string;
    };
  }[];
  meta: {
    count: number;
  };
};

export const CreateFirmSchema = yup.object({
  companyName: yup.string().required('Company Name is a required field'),
  contactName: yup.string().required('Contact Name is a required field'),
  homeOfficeName: yup.string().optional(),
  mainPhoneNumber: yup
    .string()
    .matches(
      /^\(\d{3}\) \d{3}-\d{4}$/,
      'Phone Number must match (XXX) XXX-XXXX'
    )
    .required('Phone Number is a required field')
});

export const makeUpdateFirmSchema = (
  fields: Record<string, any>
): yup.AnyObjectSchema => {
  const fieldIdInitialValueMap: Record<string, any> = {};
  Object.keys(fields).forEach(fieldId => {
    fieldIdInitialValueMap[fieldId] = fields[fieldId].initialValue;
  });

  const requiredIf = (
    fieldId: string,
    validationIfPresent: yup.AnySchema,
    validationIfAbsent: yup.AnySchema
  ) => {
    return fieldIdInitialValueMap[fieldId]
      ? validationIfPresent
      : validationIfAbsent;
  };

  return yup.object({
    address1: requiredIf(
      'address1',
      yup.string().required('Field is required'),
      yup.string().optional()
    ),
    address2: yup.string().optional(),
    city: requiredIf('city', yup.string().required(), yup.string().optional()),
    // We need this logic to support plans created before implementing this field
    companyName: requiredIf(
      'companyName',
      yup.string().required('Field is required'),
      yup.string().optional()
    ),
    firmId: yup.number().required(),
    homeOfficeName: yup.string().optional(),
    // We need this logic to support plans created before implementing this field
    mainFirmContactName: requiredIf(
      'mainFirmContactName',
      yup.string().required('Field is required'),
      yup.string().optional()
    ),
    // We need this logic to support plans created before implementing this field
    mainPhoneNumber: requiredIf(
      'homeOfficeName',
      yup
        .string()
        .transform(s => s || '')
        .matches(
          /^\(\d{3}\) \d{3}-\d{4}$/,
          'Phone Number must match (XXX) XXX-XXXX'
        )
        .required(),
      yup.string().optional()
    ),
    state: requiredIf(
      'city',
      yup.string().oneOf(STATE_CODES).required('Field is required'),
      yup.string().optional()
    ),
    whitelabelId: yup.number().required('Field is required'),
    zip: requiredIf(
      'zip',
      yup
        .string()
        .transform(s => s || '')
        .matches(/^\d{5}(?:-\d{4})?$/, 'Zip must match XXXXX or XXXXX-XXXX')
        .required(),
      yup
        .string()
        .matches(/^\d{5}(?:-\d{4})?$/, 'Zip must match XXXXX or XXXXX-XXXX')
        .nullable()
    )
  });
};

export type CreateFirmFormDto = yup.InferType<typeof CreateFirmSchema>;
export type UpdateFirmFormDto = yup.InferType<
  ReturnType<typeof makeUpdateFirmSchema>
>;

export type CreateFirmDto = Omit<CreateFirmFormDto, 'homeOfficeName'> & {
  homeOfficeName: string | null;
};

export type UpdateFirmDto = Omit<UpdateFirmFormDto, 'homeOfficeName'> & {
  homeOfficeName: string | null;
};

class FirmService {
  static getHomeOfficeNames(): string[] | Promise<string[]> {
    return ApiService.getJson<string[]>('/firms/home-office-names') as Promise<
      string[]
    >;
  }

  static async createFirm(dto: CreateFirmDto): Promise<FirmCreatedDto> {
    const resultDto = (await ApiService.postJson(
      `/firms`,
      dto
    )) as FirmCreatedDto;

    return resultDto;
  }

  static async updateFirm(
    firmId: number,
    dto: UpdateFirmDto
  ): Promise<FirmCreatedDto> {
    const resultDto = (await ApiService.putJson(
      `/firms/${firmId}`,
      dto
    )) as FirmCreatedDto;

    return resultDto;
  }

  static async getFirmById(
    firmId: string | number | undefined
  ): Promise<FirmDto> {
    if (firmId === undefined) {
      throw new Error(`invalid input type`);
    }

    const dto = (await ApiService.getJson(`/firms/${firmId}`)) as FirmDto;

    if (!dto || !dto.data || !dto.data.attributes) {
      throw new Error(
        `invalid JSON received from backend for firmId=${firmId}`
      );
    }

    return dto;
  }

  static async getFirmAdvisors({
    firmId,
    page,
    size,
    filter
  }: {
    firmId: number;
    page?: number;
    size?: number;
    filter?: string;
  }): Promise<AdvisorsByFirmDto> {
    return ApiService.getJson(`/firms/${firmId}/advisors`, {
      filter,
      page,
      size
    });
  }

  static async getFirmsPage(params: {
    pageSize: number;
    pageNumber: number;
    search: string;
  }): Promise<FirmsListDto> {
    const { pageSize, pageNumber, search } = params;
    return ApiService.getJson('/firms/list', {
      pageNumber,
      pageSize,
      search
    });
  }
}

export default FirmService;
