import { SearchReconExceptions } from '@/routes/ops/common/SearchReconExceptions.component';
import { Typography } from '@mui/material';

export const SearchReconExceptionsRoute = (): JSX.Element => {
  return (
    <>
      <Typography component='h2' mb={5} variant='h4'>
        Reconciliation
      </Typography>

      <SearchReconExceptions></SearchReconExceptions>
    </>
  );
};
