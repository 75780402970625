import {
  alpha,
  Box,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';

import React from 'react';

export interface SimpleTableColumnDef {
  header: string;
  field: string;
  /**
   * Defaults to being treated as a stringColumn.
   */
  type?: 'numericColumn' | 'stringColumn';
  /**
   * This is used as opposed to cellRenderer for doing simple value formatting.
   */
  valueFormatter?: ({ value }: { value: any }) => string;
  /**
   * This is used as opposed to valueFormatter for doing more complex formatting
   * or rendering that may involve other data in the row.
   */
  cellRenderer?: ({ data }: { data: any }) => any;
  /**
   * If the total width of your columns results in a table that is wider than
   * the container, the table will scroll horizontally.
   */
  minWidth?: number;
  /**
   * If you set a maxWidth on any columns, the table will shrink to fit the
   * content. If you want the table to have a specific size, use the sx prop
   * on the SimpleTable component to override the width style.
   */
  maxWidth?: number;
  sx?: SxProps;
}

interface SimpleTableProps extends React.HTMLAttributes<HTMLBaseElement> {
  columnDefs: SimpleTableColumnDef[];
  data: Record<string, any>[];
  /**
   * You can set this to true to optionally color code and control plus
   * and minus signs being added to the non-first cells in the last row as
   * your visually distinct comparison of the previous rows.
   */
  applyComparisonStyle?: boolean;
  /**
   * Custom styling for the table which is the most common use case.
   */
  sx?: SxProps;
  /**
   * Optional access to the component's root element styling.
   */
  containerSx?: SxProps;
  'data-testid'?: string;
}

const SimpleTable: React.FunctionComponent<SimpleTableProps> = (
  props: SimpleTableProps
) => {
  const {
    columnDefs,
    data,
    applyComparisonStyle = false,
    sx,
    containerSx,
    'data-testid': testId
  } = props;

  // check if any columnDefs have a maxWidth so that we can default table width
  const hasMaxWidth = columnDefs.some(columnDef => columnDef.maxWidth);

  return (
    <Box
      sx={{
        maxWidth: '100%',
        overflowX: 'auto',
        ...containerSx
      }}>
      {columnDefs.length > 0 && (
        <Table
          data-testid={testId}
          sx={{
            mb: 5.625,
            mt: 3,
            width: hasMaxWidth ? 'max-content' : undefined,
            ...sx
          }}>
          <TableHead>
            <TableRow>
              {columnDefs.map(columnDef => (
                <TableCell
                  align={
                    columnDef.type === 'numericColumn' ? 'right' : undefined
                  }
                  component='th'
                  key={columnDef.field}
                  size='small'
                  sx={{
                    color: theme => theme.palette.grey.A700,
                    maxWidth: columnDef.maxWidth,
                    minWidth: columnDef.minWidth,
                    overflow: 'hidden'
                  }}>
                  {columnDef.header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, rowIndex) => (
              <TableRow
                key={row[columnDefs[0].field] as string}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                {columnDefs.map((columnDef, columnDefIndex) => {
                  const rawValue = row[columnDef.field];

                  let displayValue = rawValue;
                  if (typeof columnDef.cellRenderer === 'function') {
                    displayValue = columnDef.cellRenderer({ data: row });
                  } else if (typeof columnDef.valueFormatter === 'function') {
                    displayValue = columnDef.valueFormatter({
                      value: displayValue
                    });
                  }

                  // since the value is unknown we stringify it to ensure
                  // we have a string to convert to a float for comparison
                  const numericValue = parseFloat(String(rawValue));

                  // determine if the numericValue is neutral, positive, or negative
                  let valueCharge = 'neutral';
                  if (numericValue > 0) {
                    valueCharge = 'positive';
                  } else if (numericValue < 0) {
                    valueCharge = 'negative';
                  }

                  // if the table is a comparison table and this is the last row, then
                  // add a plus sign for positive values and a minus sign for negative values
                  if (
                    applyComparisonStyle &&
                    columnDefIndex > 0 && // dont change the value of the first column
                    rowIndex === data.length - 1 && // only change the value of the last row
                    typeof columnDef.cellRenderer !== 'function' // can't really do this for custom cell renderers since they could be anything
                  ) {
                    // ensure we have a string and aren't working with non-zero falsy values
                    displayValue =
                      displayValue !== 0 && !displayValue
                        ? ''
                        : String(displayValue).trim();
                    // remove the minus sign from the displayValue if it is negative and the first character
                    // is a minus sign so that we can add our own in a standardized format
                    if (valueCharge === 'negative') {
                      displayValue = displayValue.replace(/^-/, '');
                    }
                    // compile the displayValue with the plus or minus sign
                    displayValue = `${valueCharge === 'positive' ? '+ ' : ''}${
                      valueCharge === 'negative' ? '- ' : ''
                    }${displayValue}`;
                  }

                  return (
                    <TableCell
                      align={
                        columnDef.type === 'numericColumn' ? 'right' : undefined
                      }
                      key={columnDef.field}
                      sx={{
                        backgroundColor:
                          applyComparisonStyle &&
                          columnDefIndex > 0 && // dont change the background color of the first column
                          rowIndex === data.length - 1 // only change the background color of the last row
                            ? theme =>
                                ({
                                  negative: alpha(
                                    theme.palette.error.light,
                                    0.08
                                  ),
                                  neutral: alpha(
                                    theme.palette.info.light,
                                    0.08
                                  ),
                                  positive: alpha(
                                    theme.palette.success.light,
                                    0.08
                                  )
                                })[valueCharge]
                            : undefined,
                        borderBottom: 'none',
                        maxWidth: columnDef.maxWidth,
                        minWidth: columnDef.minWidth,
                        overflow: 'hidden',
                        ...columnDef.sx
                      }}>
                      {displayValue}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Box>
  );
};

export default SimpleTable;
export type { SimpleTableProps };
