import {
  ExtendedColDef,
  ExtendedGroupColDef
} from '@/components/file-upload-table/FileUploadTable.component';
import SimpleUpload from '@/components/simple-upload';
import { parseDateToZonedTime } from '@/utils/Dates';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography
} from '@mui/material';
import { formatSsn } from '@vestwell-frontend/helpers';

import { camelCase, identity } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { read, utils } from 'xlsx';

const customFormatters = {
  date: (value: string) => (value ? parseDateToZonedTime(value) : value),
  ssn: (value: string) => formatSsn(value.toString().replace(/\s/g, ''))
};

export type PlanActionTableUploadBtnProps = {
  columnDefs?: (ExtendedGroupColDef | ExtendedColDef)[];
  onUpload: (data: Record<string, unknown>[], uploadedFile?: File) => void;

  checkFileNameExists?: (fileName: string) => Promise<any>;
};

interface ColumnConf extends ExtendedColDef {
  field?: string;
  required: boolean;
  excelHeader: unknown;
  found: boolean;
}

const UploadButton: React.FunctionComponent<PlanActionTableUploadBtnProps> = (
  props: PlanActionTableUploadBtnProps
) => {
  const [file, setFile] = useState<File | null>(null);
  const [fileData, setFileData] = useState<any[]>([]);
  const [selectOptions, setSelectOptions] = useState<string[] | undefined>([]);
  const [fileColumns, setFileColumns] = useState<ColumnConf[] | undefined>([]);
  const [fileValues, setFileValues] = useState<Record<string, any> | undefined>(
    {}
  );
  const [isOpen, toggleIsOpen] = useState(false);
  const hasUnmapped = useMemo(() => {
    return fileColumns?.some(col => {
      return col.required && !col.found;
    });
  }, [fileColumns]);

  const [showMatched, toggleShowMatched] = useState(!hasUnmapped);

  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  function isGroupColumn(
    column: ExtendedGroupColDef | ExtendedColDef
  ): column is ExtendedGroupColDef {
    return (column as ExtendedGroupColDef).children !== undefined;
  }

  const columnDefReducer = useCallback(
    (
      acc: Record<string, ExtendedColDef>,
      column: ExtendedGroupColDef | ExtendedColDef
    ): Record<string, ExtendedColDef> => {
      return {
        ...acc,
        ...(isGroupColumn(column)
          ? column.children.reduce(
              columnDefReducer,
              {} as Record<string, ExtendedColDef>
            )
          : { [column.field ?? '']: column })
      };
    },
    []
  );

  const columnsHash = useMemo(
    () =>
      props.columnDefs?.reduce(
        columnDefReducer,
        {} as Record<string, ExtendedColDef>
      ) ?? {},
    [props.columnDefs, columnDefReducer]
  );

  const handleUpload = useCallback(
    (data: unknown[], values, file) => {
      const rows = data.map(row => ({
        ...Object.keys(values).reduce(
          (acc, key) => ({
            ...acc,
            [key]: (
              columnsHash[key].valueParser ??
              customFormatters[columnsHash[key].type] ??
              identity
            )(row[values[key]])
          }),
          {}
        )
      }));

      props.onUpload(rows, file);
    },
    [columnsHash, props]
  );

  const handleContinue = useCallback(() => {
    toggleIsOpen(false);
    handleUpload(fileData, fileValues, file);
  }, [toggleIsOpen, handleUpload, fileData, fileValues]);

  const onMapField = useCallback((value, name) => {
    setFileValues(values => ({
      ...values,
      [name]: value
    }));
  }, []);

  const canContinue = useCallback((values, columns?: ColumnConf[]) => {
    const requiredFields = columns
      ? columns
          .filter((col: ColumnConf) => col.required)
          .map(({ field }) => field)
      : [];
    return requiredFields.every(field => field && values[field]);
  }, []);

  const onSelect = useCallback(
    async files => {
      setErrorMsg(null);
      setFile(files[0]);
      try {
        const result = await props.checkFileNameExists?.(files[0].name);
        if (result) {
          setErrorMsg(`${files[0].name} has already been uploaded`);
          return;
        }

        const { Sheets, SheetNames } = read(files[0].data, {
          cellDates: true,
          dateNF: 'string',
          raw: true,
          type: 'binary'
        });

        const [headers, ...rawData]: any[] = utils.sheet_to_json(
          Sheets[SheetNames[0]],
          {
            dateNF: 'string',
            header: 1,

            raw: true,
            // Setting this to true will break zip codes
            // with leading zeros
            rawNumbers: false
          }
        );

        const columnNames = props.columnDefs?.reduce(
          (acc, col) =>
            isGroupColumn(col)
              ? [
                  ...acc,
                  ...(col.children.map(child => {
                    return {
                      fields: [child.field, ...(child.alternates ?? [])],
                      headerName: `${col.headerName} ${child.headerName}`
                    };
                  }) ?? [])
                ]
              : [
                  ...acc,
                  {
                    fields: [col.field, ...(col.alternates ?? [])],
                    headerName: col.headerName,
                    required: col.required
                  }
                ],
          []
        );

        if (
          headers &&
          headers.length <
            columnNames?.filter(column => column.required !== false).length
        ) {
          setErrorMsg(
            `Missing columns: ${columnNames
              .filter(column => {
                return !headers.some(header =>
                  column.fields.some(
                    col =>
                      camelCase(header).toLowerCase().trim() ===
                      camelCase(col).toLowerCase()
                  )
                );
              })
              .map(missing => missing.headerName)
              .join(', ')}`
          );
          throw new Error('');
        }

        const data = rawData.flatMap(row => {
          if (Array.isArray(row) && !row.length) return [];
          return (Array.isArray(row) ? row : []).reduce((obj, value, index) => {
            if (value || typeof value == 'boolean') {
              obj[headers[index]] = value;
            }
            return obj;
          }, {});
        });

        const headersHash = (Array.isArray(headers) ? headers : []).reduce(
          (hash, header) => {
            hash[camelCase(header).toLowerCase().trim()] = header;
            return hash;
          },
          {}
        );

        function parseToColumnConf(col: ExtendedColDef): ColumnConf {
          let excelHeader = headersHash[camelCase(col.field).toLowerCase()];

          if (!excelHeader && Array.isArray(col.alternates)) {
            for (let i = 0; i < col.alternates.length; i++) {
              const alternateHeader =
                headersHash[camelCase(col.alternates[i]).toLowerCase()];
              if (alternateHeader) {
                excelHeader = alternateHeader;
                break;
              }
            }
          }

          return {
            ...col,
            excelHeader,
            field: col.field,
            found: !!excelHeader,
            required: col.required ?? true
          } as ColumnConf;
        }

        const columns = props.columnDefs?.reduce<ColumnConf[]>(
          (acc, column) => {
            return [
              ...acc,
              ...(isGroupColumn(column)
                ? column.children.map(val => parseToColumnConf(val))
                : [parseToColumnConf(column)])
            ];
          },
          []
        );

        const values = columns?.reduce(
          (acc, col) => ({
            ...acc,
            [col.field ?? '']: col.found ? col.excelHeader : ''
          }),
          {} as Record<string, unknown>
        );

        setFileValues(() => values);
        setFileData(() => data);

        if (canContinue(values, columns)) {
          handleUpload(data, values, files[0]);
          return;
        }

        setFileValues(() => values);
        setFileData(() => data);
        setFileColumns(() => columns);

        setSelectOptions(() =>
          Object.keys(headersHash).reduce(
            (acc, key) => [
              ...acc,
              ...(columns?.some(col => col.excelHeader === headersHash[key])
                ? []
                : [headersHash[key]])
            ],
            [] as string[]
          )
        );

        toggleIsOpen(true);
      } catch (e) {
        console.error(
          'an error occurred while parsing a file [ csv, xlsx ]',
          e
        );
      }
    },
    [props, toggleIsOpen, canContinue, handleUpload]
  );

  const rows = useMemo(() => {
    return fileColumns?.filter(({ required, found }) => {
      if (showMatched) {
        return found || required;
      } else {
        return required && !found;
      }
    });
  }, [showMatched, fileColumns]);

  const hasMissingColumns = useMemo(() => {
    return !canContinue(fileValues, fileColumns);
  }, [fileColumns, fileValues, canContinue]);

  return (
    <>
      <Stack alignItems='center' direction='row' spacing={1}>
        <SimpleUpload
          accept={{
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
              ['.xlsx'],
            'text/csv': ['.csv']
          }}
          disabled={!props.columnDefs}
          error={errorMsg}
          multiple={false}
          onSelect={onSelect}>
          <Button component='label' variant='outlined'>
            Upload CSV / XLSX
          </Button>
        </SimpleUpload>
      </Stack>
      <Dialog
        aria-label='fio'
        key={isOpen ? '1' : '2'}
        onClose={() => toggleIsOpen(false)}
        open={isOpen}
        role='presentation'>
        <DialogTitle>Matching Headers From Your Files</DialogTitle>
        <DialogContent>
          <Typography mb={2} variant='subtitle1'>
            Select a column from your file to match required fields:
          </Typography>
          <Stack divider={<Divider flexItem orientation='horizontal' />}>
            <Grid container justifyContent='space-between' pb={1}>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    color: theme => theme.palette.text.primary,
                    fontWeight: 'bold'
                  }}
                  variant='body2'>
                  Required Fields
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    color: theme => theme.palette.text.primary,
                    fontWeight: 'bold'
                  }}
                  variant='body2'>
                  Fields In Your File
                </Typography>
              </Grid>
            </Grid>
            {rows?.map(col => (
              <Grid
                alignItems='center'
                container
                justifyContent='space-between'
                key={col.field}
                pb={1}
                pt={1}>
                <Grid item xs={6}>
                  <Typography
                    className='text-right'
                    color='gray1'
                    mb={0}
                    variant='body1'>
                    {col.headerName}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  {col.found && (
                    <Typography
                      className='text-left'
                      color='gray1'
                      mb={0}
                      variant='body2'>
                      {fileValues && fileValues[col.field]}
                    </Typography>
                  )}
                  {!col.found && (
                    <Select
                      fullWidth
                      onChange={event => {
                        onMapField(event.target.value, col.field);
                      }}
                      size='small'
                      sx={{ minWidth: '60%' }}
                      value={fileValues && fileValues[col.field]}>
                      {selectOptions?.map(option => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Grid>
              </Grid>
            ))}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ paddingLeft: theme => theme.spacing(2) }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showMatched}
                onChange={() => toggleShowMatched(!showMatched)}
              />
            }
            label='Display automatically matched fields'
          />
          <Button onClick={() => toggleIsOpen(false)} variant='text'>
            Cancel
          </Button>
          <Button
            disabled={hasMissingColumns}
            onClick={handleContinue}
            variant='text'>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UploadButton;
